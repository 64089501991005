import Strings from '../../systemVariables/languageStrings';

export function adjustmentTypeLocalization(adjustmentType) {
    switch (adjustmentType) {
        case 'DOUBLE DISPENSATION':
            adjustmentType = Strings.transaction.adjustmentTypes.doubleDispensation;
            break;
        case 'NOT DETECTED':
            adjustmentType = Strings.transaction.adjustmentTypes.notDetected;
            break;
        case 'BAD PROVISIONING':
            adjustmentType = Strings.transaction.adjustmentTypes.badProvisioning;
            break;
        case 'OVERSTOCK AMENDMENT':
            adjustmentType = Strings.transaction.adjustmentTypes.overstockAmendment;
            break;
        case 'PRODUCT CHANGE':
            adjustmentType = Strings.transaction.adjustmentTypes.productChange;
            break;
        case 'MISSING PRODUCT':
            adjustmentType = Strings.transaction.adjustmentTypes.missingProduct;
            break;
        case 'OVERSTOCK CANCEL':
            adjustmentType = Strings.transaction.adjustmentTypes.overstockCancel;
            break;
        default:
            break;
    }
    return adjustmentType;
}





