import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Server URL
const resource = "/rulebyuser";

export const DISPENSATIONRULE  = 'DISPENSATIONRULE';
export const LIST_DISPENSATIONRULES  = 'LIST_DISPENSATIONRULES';
export const LIST_DISPENSATIONRULESTABLE  = 'LIST_DISPENSATIONRULESTABLE';

export const ERROR = 'ERROR';

// Dispensation rules by user
export function listRuleByUser(scDependencyId, scDepartmentId, scUserProfileId, identityCardNumber, page, pageSize, attribute, order, callback) {
  scDepartmentId = scDepartmentId ? scDepartmentId : '';
  scUserProfileId = scUserProfileId ? scUserProfileId : '';
    return (dispatch) => {
      axios.get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId,
          scdepartmentid: scDepartmentId,
          scuserprofileid: scUserProfileId,
          identitycardnumber: identityCardNumber,
          page,
          size: pageSize,
          attribute,
          order
        },
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          callback(response)
        })
        .catch(function (error) {
          return error;
        });
    }
  }

  export function getRuleByUserId(userId, callback) {
    return (dispatch) => {
      axios.get(host + port + path + version + resource, {
        params: {
          userid: userId
        },
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          callback(response)
        })
        .catch(function (error) {
          return error;
        });
    }
  }

  export function exportRuleByUserReport(soDependencyId, scDependencyId, scDepartmentId, scUserProfileId, identityCardNumber, attribute, order, fileType, columnHeaders, callback) {
    scDepartmentId = scDepartmentId == null ? "" : scDepartmentId;
    scUserProfileId = scUserProfileId == null ? "" : scUserProfileId;
    return () => {
      axios.get(host + port + path + version + resource, {
        params: {
          sodependencyid: soDependencyId,
          scdependencyid: scDependencyId,
          scdepartmentid: scDepartmentId,
          scuserprofileid: scUserProfileId,
          identitycardnumber: identityCardNumber,
          attribute,
          order,
          filetype: fileType,
          columnheaders: encodeURIComponent(columnHeaders)
        },
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          callback(response)
        })
        .catch(function (error) {
          return error;
        });
    }
  }


  export function insertRuleByUsers(ruleByUserToAdd, callback) {
    return (dispatch) => {
      const data = JSON.stringify(ruleByUserToAdd)
      axios({
        method: 'post',
        url: host + port + path + version + resource,
        data,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`
        }
      }).then((response) => {
        callback(response);
      })
        .catch(function (error) {
          dispatch({
            type: ERROR,
            payload: error.response
          });
        });
    }
  }

  export function disableRuleByUser(ruleByUserId, callback) {
    return () => {
      axios({
        method: 'delete',
        url: host + port + path + version + resource,
        params: {
          rulebyuserid : ruleByUserId
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
        }
      })
        .then(response => {
          callback(response);
        })
        .catch(error => {
          callback(error);
        });
    };
  }