import { Icon, Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Strings from "../../../systemVariables/languageStrings";
import NormalText from "../text/NormalText";
import { SelectGeneral } from "./selectGeneral";

import {
  getAllRelatedVendingMachinesToSoDependencyIdFn,
  listScDependenciesBySoDependencyIdFn,
  listSoDependencyBySoDependencyTypeFn,
  listVendingMachinesByScDependencyIdFn,
} from "../../../actions/index";

import soScActions from "../../../reducers/soscselection/actions";
import usePrevious from "./../../../Utils/usePrevious";

const { soScSelection } = soScActions;

const SoScVmDependencySelectorFn = (props) => {
  const {
    userDependency: { dependencyId, dependencyType, dependencyTypeId, dependencyName },
  } = props;
  const { soScSelectionProps, onSoDependencyChange, onScDependencyChange } = props;

  const [soDependencies, setSoDependencies] = useState([]);
  const [selectedSoDependencyId, setSelectedSoDependencyId] = useState(null);
  const [selectedSoDependencyName, setSelectedSoDependencyName] = useState(null);
  const [isSoDependencyLoading, setIsSoDependencyLoading] = useState(false);
  const [isSoDependencyDisabled, setIsSoDependencyDisabled] = useState(true);

  const [scDependencies, setScDependencies] = useState([]);
  const [selectedScDependencyId, setSelectedScDependencyId] = useState(null);
  const [selectedScDependencyName, setSelectedScDependencyName] = useState(null);
  const [isScDependencyLoading, setIsScDependencyLoading] = useState(false);
  const [isScDependencyDisabled, setIsScDependencyDisabled] = useState(true);

  const [vendingMachines, setVendingMachines] = useState([]);
  const [selectedVendingMachineId, setSelectedVendingMachineId] = useState(null);
  const [isVendingMachineLoading, setIsVendingMachineLoading] = useState(false);
  const [isVendingMachineDisabled, setIsVendingMachineDisabled] = useState(true);

  const prevSelectedSoDependencyId = usePrevious({ selectedSoDependencyId, setSelectedSoDependencyId });
  const prevSelectedVendingMachineId = usePrevious({ selectedVendingMachineId, setSelectedVendingMachineId });

  useEffect(() => {
    onComponentDidMount();
  }, []);

  const onComponentDidMount = async () => {
    const { hasSoAll, hasScAll, hasVmAll, hasVm, VmproductSlotNotAllMachine } = props;

    if (dependencyId) {
      onSoDependencyChange({ value: dependencyId, label: dependencyName });
    }

    // SO Noatec user
    if (dependencyType === "so" && dependencyId === 1) {
      // Get soDependencies to fill filter
      let soDependencyAns = await listSoDependencyBySoDependencyTypeFn(soScSelectionProps.so ? soScSelectionProps.so.id : dependencyId);
      const initialSoDependencies = hasSoAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
      const soDependencies = initialSoDependencies.concat(
        soDependencyAns.data.data.map((obj) => {
          return { value: obj.id, label: obj.name };
        })
      );

      setSoDependencies(soDependencies);
      setIsSoDependencyLoading(false);
      setIsSoDependencyDisabled(false);

      // Get scDependencies by soId
      let scDependencyAns = await listScDependenciesBySoDependencyIdFn(soScSelectionProps.so ? soScSelectionProps.so.id : dependencyId);
      let initialScDependencies = [];

      if (hasScAll) {
        initialScDependencies = [{ value: -1, label: Strings.generalTerms.all }];
        // this.setState({ selectedScDependencyId: -1 });
      }

      const scDependencies = initialScDependencies.concat(
        scDependencyAns.data.data.map((obj) => {
          return { value: obj.id, label: obj.name };
        })
      );

      setScDependencies(scDependencies);
      setIsScDependencyLoading(false);
      setIsScDependencyDisabled(false);
      setSelectedSoDependencyId(soScSelectionProps.so ? soScSelectionProps.so.id : dependencyId);
      onSoDependencyChange({
        value: soScSelectionProps.so ? soScSelectionProps.so.id : dependencyId,
        label: soScSelectionProps.so ? soScSelectionProps.so.label : dependencyName,
      });

      if (soScSelectionProps.sc && soScSelectionProps.sc.id !== -1) {
        setSelectedScDependencyId(soScSelectionProps.sc.id);
        onScDependencyChange({ value: soScSelectionProps.sc.id, label: soScSelectionProps.sc.label });

        let vmAns = await listVendingMachinesByScDependencyIdFn(soScSelectionProps.sc.id);
        let initialVendingMachines = [];

        if (hasVmAll) {
          initialVendingMachines = [{ value: -1, label: Strings.generalTerms.all }];
          setSelectedVendingMachineId(-1);
        }
        const vendingMachines = initialVendingMachines.concat(
          vmAns.data.data.map((obj) => ({
            value: obj.id,
            label: obj.friendlyName,
          }))
        );
        setVendingMachines(vendingMachines);
        setIsVendingMachineLoading(false);
        setIsVendingMachineDisabled(false);
      } else {
        let vmAns = await getAllRelatedVendingMachinesToSoDependencyIdFn(soScSelectionProps.so ? soScSelectionProps.so.id : dependencyId);
        const initialVendingMachines = hasVmAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
        const vendingMachines = initialVendingMachines.concat(
          vmAns.data.data.map((obj) => ({
            value: obj.id,
            label: obj.friendlyName,
          }))
        );
        setVendingMachines(vendingMachines);
        setIsVendingMachineLoading(false);
      }
    }

    // StockOwner user
    if (dependencyType === "so" && dependencyId !== 1) {
      setSelectedSoDependencyId(dependencyId);
      onSoDependencyChange({ value: dependencyId, label: dependencyName });
      setIsScDependencyLoading(true);

      let scDependencyAns = await listScDependenciesBySoDependencyIdFn(dependencyId);
      let initialScDependencies = [];

      if (hasScAll) {
        initialScDependencies = [{ value: -1, label: Strings.generalTerms.all }];
        setSelectedScDependencyId(-1);
      }

      const scDependencies = initialScDependencies.concat(
        scDependencyAns.data.data.map((obj) => {
          return { value: obj.id, label: obj.name };
        })
      );
      setScDependencies(scDependencies);
      setIsScDependencyLoading(false);
      setIsScDependencyDisabled(false);

      if (hasVm) {
        if ((soScSelectionProps.sc === null || soScSelectionProps.sc.id === -1) && !VmproductSlotNotAllMachine) {
          setIsVendingMachineLoading(false);
          setIsVendingMachineDisabled(true);

          let vmAns = await getAllRelatedVendingMachinesToSoDependencyIdFn(dependencyId);
          const initialVendingMachines = hasVmAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
          const vendingMachines = initialVendingMachines.concat(
            vmAns.data.data.map((obj) => ({
              value: obj.id,
              label: obj.friendlyName,
            }))
          );
          setVendingMachines(vendingMachines);
          setIsVendingMachineLoading(false);
          setIsVendingMachineDisabled(false);
        }
      }

      if (soScSelectionProps.sc !== null && soScSelectionProps.sc.id !== -1) {
        setSelectedScDependencyId(soScSelectionProps.sc.id);
        onScDependencyChange({ value: soScSelectionProps.sc.id, label: soScSelectionProps.sc.label });

        let vmScAns = await listVendingMachinesByScDependencyIdFn(soScSelectionProps.sc.id);
        const initialVendingMachines = hasVmAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
        const vendingMachines = initialVendingMachines.concat(
          vmScAns.data.data.map((obj) => ({
            value: obj.id,
            label: obj.friendlyName,
          }))
        );
        setVendingMachines(vendingMachines);
        setIsVendingMachineLoading(false);
        setIsVendingMachineDisabled(false);
      }
    }

    // ScDependency user
    if (dependencyType === "sc") {
      setSelectedScDependencyId(dependencyId);
      onScDependencyChange({ value: dependencyId, label: dependencyName });
      setIsVendingMachineLoading(true);
      if (hasVm === true) {
        let vmScAns = await listVendingMachinesByScDependencyIdFn(dependencyId);
        let initialVendingMachines = [];
        if (hasVmAll) {
          initialVendingMachines = [{ value: -1, label: Strings.generalTerms.all }];
          setSelectedVendingMachineId(-1);
        }
        const vendingMachines = initialVendingMachines.concat(
          vmScAns.data.data.map((obj) => ({
            value: obj.id,
            label: obj.friendlyName,
          }))
        );
        setVendingMachines(vendingMachines);
        setIsVendingMachineLoading(false);
        setIsVendingMachineDisabled(false);
      }
    }
  };

  useEffect(() => {
    const { soDependencyId: selectedSoDependencyId, vendingMachineId: selectedVendingMachineId } = props;

    // if the prop soDependency coming from the parent is NULL, set the component to its initial state
    if (selectedSoDependencyId !== prevSelectedSoDependencyId && selectedSoDependencyId !== undefined && selectedSoDependencyId === null) {
      setSelectedSoDependencyId(selectedScDependencyId);
      onSoDependencyChange({ value: selectedScDependencyId, label: selectedScDependencyName });
      setScDependencies([]);
      setSelectedScDependencyId(null);
      setIsScDependencyLoading(false);
      setIsScDependencyDisabled(true);
      setVendingMachines([]);
      setSelectedVendingMachineId(null);
      setIsVendingMachineLoading(false);
      setIsVendingMachineDisabled(true);
      // if props soDependency coming from the parent is Not NULL, set that prop to the state selectedSoDependencyId
    } else if (selectedSoDependencyId !== prevSelectedSoDependencyId && selectedSoDependencyId !== undefined) {
      setSelectedSoDependencyId(selectedScDependencyId);
      onSoDependencyChange({ value: selectedScDependencyId, label: selectedScDependencyName });
    }

    if (selectedVendingMachineId !== undefined && selectedVendingMachineId !== prevSelectedVendingMachineId) {
      setSelectedVendingMachineId(null);
    }
  }, [
    props,
    selectedSoDependencyId,
    scDependencies,
    selectedScDependencyId,
    isScDependencyLoading,
    isScDependencyDisabled,
    vendingMachines,
    selectedVendingMachineId,
    isVendingMachineLoading,
    isVendingMachineDisabled,
  ]);

  const handleSoDependencyChange = async (event) => {
    const { soDependencyHtmlTag, soScSelectionProps, loadVmsOnSoChange } = props;

    soScSelectionProps.so = {
      id: event.value,
      label: event.label,
    };
    soScSelectionProps.sc = null;
    soScSelectionProps.vm = null;

    onSoDependencyChange(event);
    onScDependencyChange({ label: null, value: null });

    // Save soDepId in the store
    soScSelection(soScSelectionProps);

    setSelectedSoDependencyId(event.value);
    setSelectedSoDependencyName(event.label);
    setScDependencies([]);
    setSelectedScDependencyId(null);
    setIsScDependencyDisabled(true);
    setVendingMachines([]);
    setSelectedVendingMachineId(null);
    setIsVendingMachineDisabled(true);

    if (event.value !== -1) {
      setIsScDependencyLoading(true);

      let scBySoAns = await listScDependenciesBySoDependencyIdFn(event.value);
      setScDependencies(
        scBySoAns.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
      setIsScDependencyLoading(false);
      setIsScDependencyDisabled(false);

      if (loadVmsOnSoChange) {
        setIsVendingMachineLoading(true);

        let vmSoAns = await getAllRelatedVendingMachinesToSoDependencyIdFn(event.value);
        const initialVendingMachines = [{ value: -1, label: Strings.generalTerms.all }];
        const vendingMachines = initialVendingMachines.concat(
          vmSoAns.data.data.map((obj) => ({
            value: obj.id,
            label: obj.friendlyName,
          }))
        );
        setVendingMachines(vendingMachines);
        setIsVendingMachineLoading(false);
        setIsVendingMachineDisabled(false);
        setVendingMachines(
          vmSoAns.data.data.map((item) => ({
            value: item.id,
            label: item.friendlyName,
          }))
        );
        setIsVendingMachineLoading(false);
        setIsVendingMachineDisabled(false);
      }
    }
  };

  const handleScDependencyChange = async (event) => {
    const {
      onScDependencyChange,
      scDependencyHtmlTag,
      soScSelectionProps,
      listVendingMachinesByScDependencyId,
      getAllRelatedVendingMachinesToSoDependencyId,
      hasVm,
      hasVmAll,
    } = props;

    onScDependencyChange(event, scDependencyHtmlTag);

    // Save soDepId in the store
    soScSelectionProps.sc = {
      id: event.value,
      label: event.label,
    };
    soScSelection(soScSelectionProps);

    setSelectedScDependencyId(event.value);
    setSelectedScDependencyName(event.label);
    setVendingMachines([]);
    setSelectedVendingMachineId(null);
    setIsVendingMachineDisabled(false);

    if (hasVm === true) {
      if (event.value !== -1) {
        setIsVendingMachineLoading(true);

        let vmScAns = await listVendingMachinesByScDependencyIdFn(soScSelectionProps.sc.id);
        const initialVendingMachines = hasVmAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
        const vendingMachines = initialVendingMachines.concat(
          vmScAns.data.data.map((obj) => ({
            value: obj.id,
            label: obj.friendlyName,
          }))
        );
        setVendingMachines(vendingMachines);
        setIsVendingMachineLoading(false);
        setIsVendingMachineDisabled(false);
      } else {
        let vmSoAns = await getAllRelatedVendingMachinesToSoDependencyIdFn(selectedSoDependencyId);
        const initialVendingMachines = hasVmAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
        const vendingMachines = initialVendingMachines.concat(
          vmSoAns.data.data.map((obj) => ({
            value: obj.id,
            label: obj.friendlyName,
          }))
        );
        setVendingMachines(vendingMachines);
        setIsVendingMachineLoading(false);
        setIsVendingMachineDisabled(false);
      }
    }
  };

  const handleVendingMachineChange = (event) => {
    const { onVendingMachineChange, vendingMachineHtmlTag } = props;
    setSelectedVendingMachineId(event.value);

    onVendingMachineChange(event, vendingMachineHtmlTag);
  };

  const renderForNoatecSession = (hasSc, hasVm) => {
    const { soDependencyHtmlTag } = props;

    return (
      <>
        <div className="vertSpace col-md-6">
          <SelectGeneral
            id="soSelect"
            text={Strings.soDependency.directClient}
            tooltip={Strings.soDependency.directClientTooltip}
            isReq
            options={soDependencies}
            name={soDependencyHtmlTag}
            defaultValue={selectedSoDependencyId}
            onChange={handleSoDependencyChange}
            isLoading={isSoDependencyLoading}
            disabled={isSoDependencyDisabled}
          />
        </div>
        {renderScDependency(hasSc)}
        {renderVendingMachine(hasVm)}
      </>
    );
  };

  const renderForStockOwnerSession = (hasSc, hasVm) => {
    return (
      <>
        <div className="vertSpace col-xs-12 col-md-6">
          <div className="col-md-4" style={{ textAlign: "left", padding: 0 }}>
            <span>{Strings.soDependency.directClient}:</span>
            <Tooltip title={Strings.soDependency.directClientTooltip}>
              <Icon className="icon" type="info-circle" style={{ float: "none" }} />
            </Tooltip>
          </div>
          <div className="col-md-8" style={{ textAlign: "left", padding: 0 }}>
            {dependencyName}
          </div>
        </div>

        {renderScDependency(hasSc)}
        {renderVendingMachine(hasVm)}
      </>
    );
  };

  const renderForScDependencySession = (hasVm) => {
    return (
      <>
        <div className="vertSpace col-xs-12 col-md-12">
          <NormalText text={Strings.scDependency.indirectClient} tooltip={Strings.scDependency.indirectClientTooltip} name={dependencyName} />
        </div>
        {renderVendingMachine(hasVm)}
      </>
    );
  };

  const renderScDependency = (isRendered) => {
    let render = null;
    if (isRendered) {
      const { scDependencyHtmlTag, soScSelectionProps } = props;
      render = (
        <div className="vertSpace col-xs-12 col-md-6">
          <SelectGeneral
            id="scSelect"
            text={Strings.scDependency.indirectClient}
            tooltip={Strings.scDependency.indirectClientTooltip}
            isReq
            options={scDependencies}
            name={scDependencyHtmlTag}
            defaultValue={soScSelectionProps.sc !== null ? soScSelectionProps.sc.id : selectedScDependencyId}
            onChange={handleScDependencyChange}
            isLoading={isScDependencyLoading}
            disabled={isScDependencyDisabled}
          />
        </div>
      );
    }
    return render;
  };

  const renderVendingMachine = (isRendered) => {
    let render = null;
    if (isRendered) {
      const { vendingMachineHtmlTag } = props;
      render = (
        <div className="vertSpace col-xs-12 col-md-6">
          <SelectGeneral
            id="vmSelect"
            text={Strings.machine.vendingMachine}
            tooltip={Strings.machine.vendingMachinesTooltip}
            options={vendingMachines}
            name={vendingMachineHtmlTag}
            defaultValue={selectedVendingMachineId}
            onChange={handleVendingMachineChange}
            isLoading={isVendingMachineLoading}
            disabled={isVendingMachineDisabled}
          />
        </div>
      );
    }
    return render;
  };

  const render = () => {
    const { hasSc, hasVm } = props;

    let soDependencySelector = null;

    // Noatec Session
    if (dependencyType === "so" && dependencyId === 1) {
      soDependencySelector = renderForNoatecSession(hasSc, hasVm);
    } // Stock Owner Different to Noatec Session
    else if (dependencyType === "so" && dependencyId !== 1) {
      soDependencySelector = renderForStockOwnerSession(hasSc, hasVm);
    } // Indirect Client
    else if (dependencyType === "sc") {
      soDependencySelector = renderForScDependencySession(hasVm);
    }

    return soDependencySelector;
  };

  return render();
};

SoScVmDependencySelectorFn.propTypes = {
  userDependency: PropTypes.shape({
    dependencyName: PropTypes.string,
    dependencyType: PropTypes.string,
    dependencyId: PropTypes.number,
    dependencyTypeId: PropTypes.number,
  }).isRequired,
  soDependencyHtmlTag: PropTypes.string,
  scDependencyHtmlTag: PropTypes.string,
  vendingMachineHtmlTag: PropTypes.string,
  onSoDependencyChange: PropTypes.func.isRequired,
  onScDependencyChange: PropTypes.func,
  onVendingMachineChange: PropTypes.func,
  hasSc: PropTypes.bool,
  hasVm: PropTypes.bool,
  hasSoAll: PropTypes.bool,
  loadVmsOnSoChange: PropTypes.bool,

  soDependencyId: PropTypes.number,
  vendingMachineId: PropTypes.number,

  listScDependenciesBySoDependencyId: PropTypes.func,
  listSoDependencyBySoDependencyType0: PropTypes.func,
  listVendingMachinesByScDependencyId: PropTypes.func,
  getAllRelatedVendingMachinesToSoDependencyId: PropTypes.func,
};

SoScVmDependencySelectorFn.defaultProps = {
  soDependencyHtmlTag: "",
  scDependencyHtmlTag: "",
  vendingMachineHtmlTag: "",
  onScDependencyChange: null,
  onVendingMachineChange: null,
  hasSc: false,
  hasVm: false,
  hasSoAll: false,

  loadVmsOnSoChange: false,

  soDependencyId: undefined,
  vendingMachineId: undefined,

  listScDependenciesBySoDependencyId: null,
  listSoDependencyBySoDependencyType0: null,
  listVendingMachinesByScDependencyId: null,
  getAllRelatedVendingMachinesToSoDependencyId: null,
};

function mapStateToProps(state) {
  return {
    userDependency: state.dependencyByUser.dependencyObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps)(SoScVmDependencySelectorFn);

/* export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listVendingMachinesByScDependencyId,
  getAllRelatedVendingMachinesToSoDependencyId,
})(SoScVmDependencySelector); */
