//Dependencies
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";

//Components
import { GeneralInput } from "../../GenericComponents/InputComponent/generalInput";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";
import { YesNoButton } from "../../GenericComponents/buttons/yesNoButton";
import { setVmSetupWithMeasurementUnit } from "../../../Utils/LanguageLocalization/vmSetupMeasurementUnit";

// Actions
import { updateVmSetupById } from "../../../actions";

//Language localization
import Strings from "../../../systemVariables/languageStrings";

class VendingMachineUpdateSetupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setupVar: null,
      setupVarNewValue: null,
      isNewValValid: false,
      loading: false,
      isNewValSet: false,
      isUpdateButtonDisabled: false,

      booleanButtonStatus: null,
    };
  }

  componentDidUpdate() {
    //Update state variables from parent props
    if (this.props.vmSetupSel != null && this.state.setupVar !== this.props.vmSetupSel) {
      this.setState({
        setupVar: this.props.vmSetupSel,
        booleanButtonStatus: this.props.vmSetupSel.measurementUnit === "boolean" && this.props.vmSetupSel.stringValue === "1",
      });
    }
  }

  handleOk = () => {
    if (this.props.vmSetupSel.regex) {
      if (this.state.setupVarNewValue.match(this.props.vmSetupSel.regex)) {
        this.setState({
          loading: true,
        });
        this.props.updateVmSetupById(this.props.vmSetupSel.id, this.state.setupVarNewValue, (response) => {
          this.setState({
            isNewValValid: true,
            loading: false,
            isNewValSet: true,
            isUpdateButtonDisabled: true,
          });
        });
      } else {
        this.setState({
          isNewValValid: false,
          isNewValSet: true,
        });
      }
    } else if (this.props.vmSetupSel.min && this.props.vmSetupSel.max) {
      var min = parseInt(this.props.vmSetupSel.min);
      var max = parseInt(this.props.vmSetupSel.max);
      if (this.state.setupVarNewValue >= min && this.state.setupVarNewValue <= max) {
        this.setState({
          loading: true,
        });
        this.props.updateVmSetupById(this.props.vmSetupSel.id, this.state.setupVarNewValue, (response) => {
          this.setState({
            isNewValValid: true,
            loading: false,
            isNewValSet: true,
            isUpdateButtonDisabled: true,
          });
        });
      } else {
        this.setState({
          isNewValValid: false,
          isNewValSet: true,
        });
      }
    } else if (this.props.vmSetupSel.measurementUnit === "boolean") {
      var newVal = this.state.booleanButtonStatus ? 1 : 0;
      this.props.updateVmSetupById(this.props.vmSetupSel.id, newVal, (response) => {
        this.setState({
          isNewValValid: true,
          loading: false,
          isNewValSet: true,
          isUpdateButtonDisabled: true,
        });
      });
    }
  };

  handleCancel = () => {
    this.setState({
      setupVarNewValue: null,
      isNewValSet: false,
      isNewValValid: false,
      setupVar: null,
      isUpdateButtonDisabled: false,
    });
    this.props.hideModal(this.state.isNewValValid);
  };

  handleSetupValChange = (val) => {
    this.setState({
      setupVarNewValue: val.target.value,
    });
  };

  handleBooleanSetupValChange = () => {
    this.setState({
      booleanButtonStatus: !this.state.booleanButtonStatus,
    });
  };

  render() {
    if (this.props.vmSetupSel == null) {
      return "";
    } else {
      return (
        <Modal
          title={Strings.vmSetup.editVmsetup}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText={Strings.generalTerms.update}
          okButtonProps={{ disabled: this.state.isUpdateButtonDisabled }}
          cancelText={Strings.generalTerms.cancel}
          width={430}
          visible={this.props.isVisible}
        >
          <Fragment>
            <div className="row">
              <div className="col-3" style={{ paddingRight: "0px" }}>
                <b>{Strings.vmSetup.parameter + ":"}</b>
              </div>
              <div className="col-8">
                <TextWithInfoTooltip name={this.props.vmSetupSel.name} tooltip={this.props.vmSetupSel.description} />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-3" style={{ paddingRight: "0px" }}>
                <b>{Strings.vmSetup.currentValue + ": "}</b>
              </div>
              <div className="col-8">
                <span>{setVmSetupWithMeasurementUnit(this.props.vmSetupSel.stringValue, this.props.vmSetupSel.measurementUnit)}</span>
              </div>
            </div>
            <br />
            <div className="row" style={{ displayFlex: "true", alignItems: "center" }}>
              {this.props.vmSetupSel.measurementUnit !== "boolean" ? (
                <div className="col-10" style={{ paddingLeft: "4px" }}>
                  <GeneralInput text={<b>{Strings.vmSetup.newValue}</b>} value={this.state.setupVarNewValue} onChange={(val) => this.handleSetupValChange(val)} />
                </div>
              ) : (
                <Fragment>
                  <div className="col-4">
                    <b>{Strings.vmSetup.newValue + ": "}</b>
                  </div>
                  <div className="col-8">
                    <YesNoButton status={this.state.booleanButtonStatus} onClick={this.handleBooleanSetupValChange} />
                  </div>
                </Fragment>
              )}
            </div>
            <br />
            <div className="row">
              <div className="col-12">
                {this.state.isNewValSet ? (!this.state.isNewValValid ? Strings.generalResponses.invalidValue : Strings.generalResponses.successfulUpdate) : ""}
              </div>
            </div>
          </Fragment>
        </Modal>
      );
    }
  }
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, {
  updateVmSetupById,
})(VendingMachineUpdateSetupModal);
