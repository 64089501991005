import { Icon, Table, Tooltip } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  addVmContract,
  getLockerInfoByWingId,
  getLockerSlotPriceBySizeInfo,
  getProductInfoByWingId,
  getWingTypeById,
  listScDependenciesBySoDependencyId,
  listWingInfoByMachineId,
  updateSoDependencyVmAsync,
} from "../../actions";

import { ValidatePermissionForComponentPart } from "../../Utils/validatePermissionForComponentPart";
import ColumnTitleWithSortButtons from "../GenericComponents/columnTitleWithSortButtons";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";
import MachineDetailsModal from "./machineDetailsModal";
import VmContractModal from "./vmContractModal";
import VmDisableConfirmModal from "./vmDisableConfirmModal";

import Strings from "../../systemVariables/languageStrings";
import VmSoDependencyModal from "./VmSoDependencyModal";

const { Column } = Table;

class MachineTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      machineToAssignContract: null,
      contractModalVisible: false,
      redirectToVmSetup: false,
      machineToSetup: null,
      directClientSelectorVisible: false,
      machineToAssignSoDependency: null,
    };
  }

  // --------Events--------
  handleContractAssignation(row) {
    this.setState({ machineToAssignContract: row, contractModalVisible: true });
  }

  handleDirectClientAssign(row) {
    this.setState({ directClientSelectorVisible: true, machineToAssignSoDependency: row.id });
  }

  handleCloseDirectClientModal = () => {
    this.setState({ directClientSelectorVisible: false, machineToAssignSoDependency: null });
  };

  handleVmSetup(row) {
    this.setState({ machineToSetup: row, redirectToVmSetup: true });
  }

  handleDisable(vm) {
    const { vmToDisable } = this.props;
    vmToDisable(vm);
  }

  setContractModalVisible = (isVisible) => {
    const { queryRefresh } = this.props;
    queryRefresh();
    this.setState({ contractModalVisible: isVisible });
  };

  connectionStatus = (lastConnDateStr) => {
    const lastConnDate = new Date(lastConnDateStr);
    const currDate = new Date();
    const diff = Math.abs(lastConnDate - currDate);
    const minutes = Math.floor(diff / 1000 / 60);

    let statusColorCode = "";
    if (minutes <= 5) {
      statusColorCode = "#62D32B";
    } else if (minutes <= 30) {
      statusColorCode = "#F3F033";
    } else if (minutes <= 60) {
      statusColorCode = "#F57409";
    } else if (minutes <= 1440) {
      statusColorCode = "#F82222";
    } else {
      statusColorCode = "#B0968D";
    }

    return statusColorCode;
  };

  setSortOrder = (order, attribute) => {
    let sortOrder = order;
    let sortAttribute = attribute;
    if (order === "NONE") {
      sortOrder = "DESC";
      sortAttribute = "id";
    }
    const { setSortBy } = this.props;
    setSortBy(sortAttribute, sortOrder);
  };

  render() {
    const {
      permissions: { data },
      isLoading,
      scDependencyList,
      cleanComponent,
      flag,
      machines,
      soDependencyTypes,
      headQuarters,
      logisticCenters,
      queryRefresh,
    } = this.props;

    const { redirectToVmSetup, machineToSetup, contractModalVisible, machineToAssignContract, directClientSelectorVisible, machineToAssignSoDependency } = this.state;

    let vendingMachines = machines.content ? machines.content : null;

    if (flag === 0) {
      vendingMachines = machines.content ? machines.content : null;
    } else {
      vendingMachines = machines.content ? machines.content : null;
    }

    // Redirects to Vmsetup
    if (redirectToVmSetup) {
      return (
        <Redirect
          to={{
            pathname: "/vminfo",
            props: {
              vm: machineToSetup,
            },
          }}
        />
      );
    }
    return (
      <>
        <Table dataSource={vendingMachines} bordered loading={isLoading} pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="id">
          <Column
            width="15%"
            title={<ColumnTitleWithSortButtons name={Strings.generalTerms.name} attribute="friendlyName" setSortOrder={(order) => this.setSortOrder(order, "friendlyName")} />}
            dataIndex="friendlyName"
            align="center"
          />
          <Column width="10%" title={Strings.machine.serialNumber} dataIndex="serialNumber" align="center" />
          <Column
            width="10%"
            title={
              <ColumnTitleWithSortButtons
                name={Strings.machine.machineGeneration}
                attribute="generationString"
                setSortOrder={(order) => this.setSortOrder(order, "generationString")}
              />
            }
            dataIndex="generationString"
            align="center"
          />
          <Column
            width="15%"
            title={<TextWithInfoTooltip name={Strings.machine.contract} tooltip={Strings.machine.contractTooltip} />}
            render={(row) =>
              ValidatePermissionForComponentPart("PRIVILEGE VM CONTRACT ASSIGNATION", data) && row.scDependencyId == null ? (
                <div role="button" tabIndex={0} style={{ color: "#004173", cursor: "pointer" }} onClick={() => this.handleContractAssignation(row)}>
                  {Strings.generalTerms.assign}
                </div>
              ) : (
                <div>{row.scDependencyName ?? Strings.generalTerms.notAssigned}</div>
              )
            }
            align="center"
          />
          <Column
            width="15%"
            title={<TextWithInfoTooltip name={Strings.soDependency.directClient} tooltip={Strings.soDependency.directClientProvisionerTooltip} />}
            render={(row) =>
              ValidatePermissionForComponentPart("PRIVILEGE VM UPDATE SO DEPENDENCY", data) && row.sodLogisticId === 2 ? (
                <div tabIndex={0} role="button" style={{ color: "#004173", cursor: "pointer" }} onClick={() => this.handleDirectClientAssign(row)}>
                  {row.sodLogisticName}
                </div>
              ) : (
                <div>{row.sodLogisticName}</div>
              )
            }
            align="center"
          />
          <Column
            width="13%"
            title={
              <ColumnTitleWithSortButtons
                name={Strings.machine.connectionStatus}
                tooltip={Strings.machine.connectionStatusTooltip}
                attribute="lastConnectionDate"
                setSortOrder={(order) => this.setSortOrder(order, "lastConnectionDate")}
              />
            }
            align="center"
            dataIndex="lastConnectionDate"
            render={(lastConnDate) => (
              <span>
                <Tooltip title={`Última conexión: ${new Date(lastConnDate).toLocaleDateString("es-CO", { hour: "numeric", minute: "numeric" })}`}>
                  <Icon type="clock-circle" theme="twoTone" twoToneColor={this.connectionStatus(lastConnDate)} />
                </Tooltip>
              </span>
            )}
          />
          <Column
            width="10%"
            title={Strings.generalTerms.details}
            align="center"
            render={(row) => (row.isConfigured === "1" ? <MachineDetailsModal vendingMachineSelected={row} /> : <span>{Strings.machine.unconfigured}</span>)}
          />
          {ValidatePermissionForComponentPart("PRIVILEGE VM INFO CONFIG ACCESS", data) ? (
            <Column
              title={<TextWithInfoTooltip name={Strings.machine.setup} tooltip={Strings.machine.setupTooltip} />}
              align="center"
              render={(row) => <Icon style={{ cursor: "pointer", fontSize: 16 }} type="setting" theme="twoTone" onClick={() => this.handleVmSetup(row)} />}
            />
          ) : null}
          {ValidatePermissionForComponentPart("PRIVILEGE DISABLE MACHINE", data) ? (
            <Column
              title={<TextWithInfoTooltip name={Strings.generalTerms.disable} tooltip={Strings.machine.disableMachineTooltip} />}
              align="center"
              render={(row) => <VmDisableConfirmModal vmInfo={row} vmToDisable={(vm) => this.handleDisable(vm)} />}
            />
          ) : null}
        </Table>

        <VmContractModal
          scDependencyList={scDependencyList || null}
          isVisible={contractModalVisible}
          setIsVisible={this.setContractModalVisible}
          vendingmachineId={machineToAssignContract ? machineToAssignContract.id : null}
          cleanComponent={cleanComponent}
          isLoading={isLoading}
        />

        <VmSoDependencyModal
          queryRefresh={queryRefresh}
          handleCloseDirectClientModal={this.handleCloseDirectClientModal}
          directClientSelectorVisible={directClientSelectorVisible}
          machineToAssignSoDependency={machineToAssignSoDependency}
          soDependencyTypes={soDependencyTypes}
          headQuarters={headQuarters}
          logisticCenters={logisticCenters}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    scDependencies: state.scDependencies,
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  listScDependenciesBySoDependencyId,
  addVmContract,
  updateSoDependencyVmAsync,
  ValidatePermissionForComponentPart,
  listWingInfoByMachineId,
  getLockerSlotPriceBySizeInfo,
  getWingTypeById,
  getLockerInfoByWingId,
  getProductInfoByWingId,
})(MachineTable);
