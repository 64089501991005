import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Server URL
const resource = "/vmproductslot";

export function listVmProductSlotByVendingMachineId(vendingMachineId, callback) {
  // to change by vmtray. Pending
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingmachineid: vendingMachineId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function listFullVmProductSlotByVendingMachineId(scDependencyId, vendingMachineId, callback) {
  // to delete
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingmachineid: vendingMachineId,
          scdependencyid: scDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function listFullVmProductSlotByWingId2(wingId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          wingid: wingId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function listFullVmProductSlotByWingId(wingId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          wingid: wingId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function listFullLockerVmProductSlotByWingId(wingId, wingTypeId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          wingid: wingId,
          wingtypeid: wingTypeId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function insertVmProductSlot(vmProductSlot, callback) {
  return () => {
    const data = JSON.stringify(vmProductSlot);
    axios({
      method: "post",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function addVmProductSlot(vmProductSlotToAdd, callback) {
  return () => {
    const data = JSON.stringify(vmProductSlotToAdd);
    axios({
      method: "POST",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function editVmProductSlot(vmProductSlotToEdit, callback) {
  return () => {
    const data = JSON.stringify(vmProductSlotToEdit);
    axios({
      method: "PUT",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function disableVmProductSlot(vmProductSlotToDisable, callback) {
  return () => {
    const data = JSON.stringify(vmProductSlotToDisable);
    axios({
      method: "DELETE",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function exportVmInventoryReport(vendingMachineId, scDependencyId, soDependencyId, fileType, columnHeaders, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingmachineid: vendingMachineId,
          scdependencyid: scDependencyId,
          sodependencyid: soDependencyId,
          filetype: fileType,
          columnheaders: encodeURIComponent(columnHeaders),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function exportVmInventoryByProductReport(vendingMachineId, scDependencyId, soDependencyId, fileType, columnHeaders, callback) {
  return () => {
    const urlPath = "/inventorybyproduct";
    axios
      .get(host + port + path + version + resource + urlPath, {
        params: {
          vendingmachineid: vendingMachineId,
          scdependencyid: scDependencyId,
          sodependencyid: soDependencyId,
          filetype: fileType,
          columnheaders: encodeURIComponent(columnHeaders),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function exportLockerInventoryReport(vendingMachineId, scDependencyId, soDependencyId, fileType, columnHeaders, callback) {
  return () => {
    const urlPath = "/inventorybylocker";
    axios
      .get(host + port + path + version + resource + urlPath, {
        params: {
          vendingmachineid: vendingMachineId,
          scdependencyid: scDependencyId,
          sodependencyid: soDependencyId,
          filetype: fileType,
          columnheaders: encodeURIComponent(columnHeaders),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function addLockerVmProductSlot(lockerVmProductSlot, callback) {
  return () => {
    const data = JSON.stringify(lockerVmProductSlot);
    const urlPath = "/insertlockervmprodslot";
    axios({
      method: "POST",
      url: host + port + path + version + resource + urlPath,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getUncollectedLockerProducts(vendingMachineId, minutes, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingMachineId,
          minutes,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getUncollectedLockerProductsReport(sodependencyid, scdependencyid, vendingMachineId, minutes, fileType, columnHeaders, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        responseType: "blob",
        params: {
          sodependencyid,
          scdependencyid,
          vendingMachineId,
          minutes,
          filetype: fileType,
          columnheaders: encodeURIComponent(columnHeaders),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
        return error;
      });
  };
}

export function disableFreeVmProductSlotByWingId(wingId, callback) {
  return () => {
    const data = JSON.stringify(wingId);
    const urlPath = "/disablefreevmproductslotbywingid";
    axios({
      method: "delete",
      url: host + port + path + version + resource + urlPath,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error
      });
  };
}
