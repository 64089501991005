import { SOPRODUCTADDED, LIST_SOPRODUCT, LIST_UNASSIGNEDSOPRODUCT } from '../actions'

const initialState = {
  soProductObj: [],
  listSoProductObj: [],
  listUnassignedSoProductObj: []
}

export function soProduct(state = [], action) {
  switch (action.type) {
    case SOPRODUCTADDED:
      return Object.assign({}, state, {soProductObj: action.payload})
    case LIST_SOPRODUCT:
      return Object.assign({}, state=[], {listSoProductObj: action.payload})
    case LIST_UNASSIGNEDSOPRODUCT:
      return Object.assign({}, state=[], {listUnassignedSoProductObj: action.payload})
    default:
      return state
  }
}