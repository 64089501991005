import React from "react";
import { connect } from "react-redux";

// Components
import { Alert, Card, Timeline } from "antd";
import Strings from "../../../systemVariables/languageStrings";

// Images
import Patient from "../../Global/images/ComponentsSVG/Patient";

const { Meta } = Card;

const PatientInformationSummary = ({ patientInformation, locationInformation }) => {
  const patientInformationTitleRender = () => {
    return (
      <>
        <div className="row" style={{ margin: "10px" }}>
          <Meta
            avatar={<Patient width={40} fill={"#004173"} />}
            style={{ margin: "5px" }}
            title={
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-12">
                  <h2 className="paragraphSubTitle">{Strings.smartVendingClinical.patientInformation}</h2>
                </div>
              </div>
            }
          />
        </div>
      </>
    );
  };

  const patientInformationInfoRender = (patientInformation, locationInformation) => {
    return (
      <div className="row">
        <Card className="customCard" title={patientInformationTitleRender()}>
          {patientInformation || locationInformation ? (
            <>
              <Meta
                title={<strong>{Strings.smartVendingClinical.patientInformation.toUpperCase()}</strong>}
                description={patientInformationRender()}
              />
              <Meta
                title={<strong>{Strings.smartVendingClinical.locations.toUpperCase()}</strong>}
                description={locationInformationRender()}
              />
            </>
          ) : (
            <Alert message={Strings.generalTerms.informationNotAvailable} type="info" showIcon style={{ alignContent: "center" }} />
          )}
        </Card>
      </div>
    );
  };

  const patientInformationRender = () => {
    return (
      <div style={{ padding: "10px" }}>
      {patientInformation ? (
        <Timeline>
          {patientInformation.identityCardNumber ? 
            <p>{`${Strings.users.identityCardNumber}: ${patientInformation.identityCardNumber}`}</p> : null}
          {patientInformation.medicalHistoryNumber ?
            <p>{`${Strings.smartVendingClinical.medicalHistory}: ${patientInformation.medicalHistoryNumber}`}</p> : null}
          {patientInformation.name ?
            <Timeline.Item color="green">{`${Strings.users.name}: ${patientInformation.name}`}</Timeline.Item> : null}
          {patientInformation.lastname ? 
            <Timeline.Item color="green">{`${Strings.users.lastName}: ${patientInformation.lastname}`}</Timeline.Item> : null}
          {patientInformation.birthDate ? 
            <Timeline.Item color="green">{`${Strings.users.dateOfBirth}: ${patientInformation.birthDate}`}</Timeline.Item> : null}
          {patientInformation.gender ? 
            <Timeline.Item color="green">{`${Strings.users.gender}: ${patientInformation.gender}`}</Timeline.Item> : null}
        </Timeline>
      ) : null}
      </div>
    );
  };

  const locationInformationRender = () => {
    return (
      <div style={{ padding: "10px" }}>
      {locationInformation ? (
        <Timeline>
          {locationInformation.location ?
            <Timeline.Item color="green">{`${Strings.smartVendingClinical.location}: ${locationInformation.location}`}</Timeline.Item> : null}
          {locationInformation.sector ? 
            <Timeline.Item color="green">{`${Strings.smartVendingClinical.sector}: ${locationInformation.sector}`}</Timeline.Item> : null}
          {locationInformation.building ? 
            <Timeline.Item color="green">{`${Strings.smartVendingClinical.building}: ${locationInformation.building}`}</Timeline.Item> : null}
          {locationInformation.floor ? 
            <Timeline.Item color="green">{`${Strings.smartVendingClinical.floor}: ${locationInformation.floor}`}</Timeline.Item> : null}
        </Timeline>
      ) : null}
      </div>
    );
  };

  return patientInformationInfoRender(patientInformation, locationInformation);
};

const mapStateToProps = (state) => ({
  permissions: state.userSystemPrivileges.privilegeObj,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientInformationSummary);