/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
// Dependencies
import React, {Component} from 'react';
import { connect } from "react-redux";
import { Divider, Modal } from "antd";
import 'react-datepicker/dist/react-datepicker.css';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import {
  addVmProductGroup,
  listSoProductsBySoDependency
} from "../../../actions";

// Components
import Titles from "../../GenericComponents/titles";
import { GeneralInput } from "../../GenericComponents/InputComponent/generalInput"
import { ReturnButton, SaveButton } from '../../GenericComponents/buttons/index';
import AvailableSoProductTable from "../SoProduct/AvailableSoProductTable";
import AssignedSoProductTable from "./assignedSoProductTable";

// CSS
import './vmProductGroup.css';

// Language localization
import Strings from '../../../systemVariables/languageStrings'

let soProductFullList = [];
let soProductsByVmProductGroup = [];
let soProductToAdd = [];
let soProductToRemove = [];

class AddVmProductGroup extends Component {
  constructor (props) {
    super(props)
    const { location } = this.props;
    this.state = {
      soDependency:{
        id: location.props ? location.props.soDependency.id : null,
        name: location.props ? location.props.soDependency.name : null
      },
      vmProductGroupToAdd: {
        id: null,
        name: null,
        description: null,
        soDependencyId: location.props ? location.props.soDependency.id : null,
      },
      // frequencyUnits: location.props.frequencyUnits ? location.props.frequencyUnits : null,
      redirectBackToVmProductGroup: false,
      saveButtonIsDisabled: true,
      loading: false,
      soProductList: location.props ? location.props.soProductList : null,
      soProductAssignedByVmProductGroup: [],
      isDisabledReturnBtn: false
    };
    soProductFullList = [];
    soProductsByVmProductGroup = [];
    soProductToAdd = [];
    soProductToRemove = [];

    this.removeSelectedProductEvent = this.removeSelectedProductEvent.bind(this);
  };
  
  // -----------------------Events-----------------------
  nameInputHandleChange(event){
    const value = _.trimStart(event.target.value)
    const { vmProductGroupToAdd } = this.state;
    const vmProductGroup = vmProductGroupToAdd;
    vmProductGroup.name = value;
    this.setState({ vmProductGroupToAdd : vmProductGroup })
    if (value.length > 0) {
      this.setState({ saveButtonIsDisabled: false });
    } else {
      this.setState({ saveButtonIsDisabled: true });;
    }
  }

  descriptionInputHandleChange(event) {
    const value = _.trimStart(event.target.value);
    const { vmProductGroupToAdd } = this.state;
    const vmProductGroup = vmProductGroupToAdd;
    vmProductGroup.description = value;
    this.setState({ vmProductGroupToAdd: vmProductGroup });
  }

  handleOk = () => {
    this.setState({ 
      redirectBackToVmProductGroup: true 
    });
  }

  addVmProductGroupOnClick() {
    const { soProductAssignedByVmProductGroup, vmProductGroupToAdd } = this.state;
    const { addVmProductGroup } = this.props;
    if(soProductAssignedByVmProductGroup.length === 0) {
      this.setState({ loading: false, isDisabledReturnBtn: false });
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.dispensationRule.errorNoProducts,
      });
      return;
    } 
    if (vmProductGroupToAdd.name != null) {
      this.setState({ loading: true, isDisabledReturnBtn: true });
      const vmProductGroupDTO = {};
      vmProductGroupDTO.vmProductGroup = vmProductGroupToAdd;
      vmProductGroupDTO.soProductIdToAdd = soProductToAdd;

      addVmProductGroup(vmProductGroupDTO, (response) => {
        this.setState({ loading: false, isDisabledReturnBtn: false});
        if (!response.data && String(response).includes("Error:")) { // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError
          })
        } else if (response.data.code === 4008) { // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: `${Strings.vmProductGroup.alreadyExistsProductGroupError + vmProductGroupDTO.vmProductGroup.name  }.`
          })
        } else if (response.data && response.data.status === "SUCCESS") { // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.handleOk,
          })
        this.setState({ loading: false, isDisabledReturnBtn: false});
        } else { // Other error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError
          })
        }
      });
    } else {
      Modal.error({ // Invalid parameters
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError
      })
      this.setState({ isDisabledReturnBtn: false });
    }
  }

  handleOkNoProductGroupWarning = () => {
    this.setState({ redirectBackToVmProductGroup: true });
  }

  noVmProductGroupWarning() {
    Modal.warning({
      title: Strings.soDependency.noDirectClientSelected,
      content: Strings.soDependency.noDirectClientSelectedMsg,
      onOk: this.handleOkNoProductGroupWarning,
    })
  }

  renderSoProductsAssignedTable = (soProductAssignedByVmProductGroup) => {
    if(soProductAssignedByVmProductGroup){
      soProductsByVmProductGroup = soProductAssignedByVmProductGroup.map(obj=>{
        const soProduct = {};
        soProduct.id = obj.id;
        soProduct.soProductName = obj.soProductName;
        soProduct.erpProductCode = obj.erpProductCode;
        soProduct.soProductByProductGroupId = obj.soProductByProductGroupId?obj.soProductByProductGroupId:null;
        return soProduct;
      });
    }
    return soProductsByVmProductGroup
  }

  renderSoProductsTable = (soProductAssignedByVmProductGroup) => {
    const { soProductList } = this.state;
    if(soProductList){
      soProductFullList = soProductList;
      soProductFullList = _.differenceBy(soProductFullList, soProductAssignedByVmProductGroup,'id')
    } 
    return soProductFullList
  }

  onRowClick = row => ({
    onClick: () => {
     this.addSelectedProductEvent(row)
    }
  })

  addSelectedProductEvent(soProductSelected) {
    const { soProductList } = this.state;

    soProductsByVmProductGroup.push(soProductSelected);
    soProductFullList = _.differenceBy(soProductList, soProductsByVmProductGroup, 'id')
    this.setState({ soProductList: soProductFullList, soProductAssignedByVmProductGroup: soProductsByVmProductGroup })
    
    if(!soProductSelected.soProductByProductGroupId){
      soProductToAdd.push(soProductSelected.id);
    } else{
      soProductToRemove.forEach(item => {
        _.remove(soProductToRemove, obj => (_.isEqual(obj,soProductSelected.id)))
      })
    }
  }

  removeSelectedProductEvent(soProductSelected, row) {
    soProductFullList.push(soProductSelected);
    soProductsByVmProductGroup = _.differenceBy(soProductsByVmProductGroup, soProductFullList, 'id')
    this.setState ({ soProductList: soProductFullList, soProductAssignedByVmProductGroup: soProductsByVmProductGroup })

    if(soProductSelected.soProductByProductGroupId){
      soProductToRemove.push(soProductSelected.id);
    } else{
      soProductToAdd.forEach(item => {
        _.remove(soProductToAdd, obj => (_.isEqual(obj,soProductSelected.id)))
      })
    }
  }

  render() {
    const { redirectBackToVmProductGroup, soDependency, soProductList, vmProductGroupToAdd, soProductAssignedByVmProductGroup, isLoading, loading, isDisabledReturnBtn } = this.state;
    if (redirectBackToVmProductGroup) { // Redirects to vmProductGroup from No ProductGroup Modal Warning
      return (<Redirect to="/listVmProductGroups" />);
    }
  
    if (!soDependency.id || !soProductList) { // Shows No ProductGroup Modal Warning
      return (<div>{this.noVmProductGroupWarning()}</div>);
    } 
    
    return (
      <div>
        <Titles title={Strings.vmProductGroup.addVmProductGroup}  tooltip={Strings.vmProductGroup.addVmProductGroupTooltip}/>
        <div  className="col-md-12">
          <div> 
            <Divider orientation="left"><h3>{Strings.soDependency.directClient} </h3></Divider>
          </div>
          <div className="col-md-6">
            <h3>{soDependency.name}</h3>
          </div>
          <br/>
        </div>

        <div className="col-md-12">
          <Divider orientation="left">
            <h3> 1. {Strings.generalTerms.generalParameters}</h3>
          </Divider>
          <div className="col-md-12">
            <div className="col-md-6">
              <GeneralInput 
                text={Strings.generalTerms.name} 
                tooltip ={Strings.vmProductGroup.vmProductGroupNameToolTip}
                placeholder={Strings.generalTerms.name} 
                onChange={(e) => this.nameInputHandleChange(e)}
                value={vmProductGroupToAdd.name}
                isReq
              />
            </div>

            <div className="col-md-6">
              <GeneralInput 
                text={Strings.generalTerms.description} 
                tooltip ={Strings.vmProductGroup.vmProductGroupDescriptionToolTip}
                placeholder={Strings.generalTerms.description} 
                onChange={(e) => this.descriptionInputHandleChange(e)}
                value={vmProductGroupToAdd.description}
                isReq={false}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <br/>
          <Divider orientation="left">
            <h3> 2. {Strings.vmProductGroup.soProductAssignmentToVmProductGroup}</h3>
          </Divider>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <h4>{Strings.product.availableProducts}</h4>
                <div className="smallTable" >
                  <AvailableSoProductTable 
                    availableSoProducts = {this.renderSoProductsTable(soProductAssignedByVmProductGroup)} 
                    isLoading = {isLoading}
                    onRowClick = {this.onRowClick}
                  />
                </div> 
                <br/>
            </div>
            <div className="col-md-6">
              <h4>{Strings.product.assignedProducts}</h4>
                <div className="smallTable" >
                  <AssignedSoProductTable 
                    assignedSoProducts = {this.renderSoProductsAssignedTable(soProductAssignedByVmProductGroup)}  
                    isLoading = {isLoading} 
                    removeSelectedProduct={this.removeSelectedProductEvent} 
                  />
                </div>  
                <br/>
            </div>
          </div> 
        </div> 

        <div className="col-md-12">
          <br/> 
          <div className="row">
            <div className="col-md-9"/>
            <div className="col-md-1">
              <ReturnButton 
                link="/listVmProductGroups"
                isDisabled = {isDisabledReturnBtn}
                />
            </div>
            <div className="col-md-2">
              <SaveButton 
                onClick={(e) => this.addVmProductGroupOnClick(e)} 
                loading={loading}
              />
            </div>
          </div>
        </div> 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps, {
    addVmProductGroup,
    listSoProductsBySoDependency,
}) (AddVmProductGroup)
