// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Divider, Modal, Tabs, Spin, Icon, Alert } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import "../GenericComponents/queryResponse/queryResponse.css"
// Actions
import {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listVendingMachinesByScDependencyId,
  listWingsByVmId2,
  listFullVmProductSlotByWingId2,
  // listWingsByVmId,
  // listFullVmProductSlotByWingId,
  listVmTraysByWingId,
  // listFullLockerVmProductSlotByWingId,
  getVendingMachinesByIsPlanogramAssigned,
  exportVmInventoryReport,
  exportVmInventoryByProductReport,
  exportLockerInventoryReport,
} from "../../actions";

// Components
import Titles from "../GenericComponents/titles";
import { SelectGeneral } from "../GenericComponents/selectComponent/index";
import { ValidateDependency } from "../../Utils/validateDependency";
import ExportReportsModal from "../GenericComponents/exportReportModal";
import { ExportButton } from "../GenericComponents/buttons/index";
import { maxRegistersPerReport } from "../../systemVariables/serverInformation";
import { ValidatePermissionForComponent } from "../../Utils/validatePermissionForComponent";
// import Loading from '../GenericComponents/loadingComponent/loading'
import MachineGui from "./MachineGui.jsx";
import LockerGui from "../LockerInventory/LockerGui.jsx";
import ProductInventory from "./ProductInventory.jsx";

// Language localization
import Strings from "../../systemVariables/languageStrings";

const { TabPane } = Tabs;
let privileges;
const planogramSetupSize = 64;

class VendingMachineInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soDependencyId: 0,
      // soDependencyName: null,
      scDependencies: [],
      scDependencyId: null,
      scDependencyName: null,
      disabledScDependency: true,
      vendingMachines: [],
      vendingMachineId: null,
      vendingMachineName: null,
      disabledVendingMachine: true,
      wingId: null,
      // wingTypes: [],
      wingTabs: [],
      isLoadingInfo: false,
      // planogramInfo: [],
      // vmTrays: [],
      // vmSlots: [],
      // amountAvailable: [],
      // loading: false,

      // Export Report Modal
      isExportModalVisible: false,
      exportLoading: false,
      selReport: 1,
      reportOptions: [
        { value: 0, label: Strings.machineInventory.slotInventory },
        { value: 1, label: Strings.machineInventory.productInventary },
      ],
    };
  }

  componentDidMount() {
    this.updatePlanogramSetupSize();
    window.addEventListener("resize", this.updatePlanogramSetupSize);

    const {
      soDependencies,
      listSoDependencyBySoDependencyType0,
      userDependency,
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      userDependency: { dependencyTypeId },
      userDependency: { dependencyName },
      listScDependenciesBySoDependencyId,
      soDependencyParent,

      // soScSelectionProps,
      // clearProducts,
      // getScProductInformationByScDependency,
      // fetchProducts,
    } = this.props;

    // Noatec User
    if ((dependencyType === "so" && dependencyId === 1 && !soDependencies) || soDependencies.length === 0) {
      listSoDependencyBySoDependencyType0(dependencyId);
    }
    // StockOwner User
    if (dependencyType === "so" && dependencyId !== 1 && dependencyTypeId === 0) {
      const soDependencyId = dependencyId;
      this.setState({
        soDependencyId: userDependency ? (userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyId : null) : null,
        // soDependencyName: userDependency ? (userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? props.userDependency.dependencyName : null) : null,
        scDependencies: [],
        scDependencyId: null,
        vendingMachines: [],
        vendingMachineId: null,
        vendingMachineName: null,
        disabledScDependency: false,
        disabledVendingMachine: true,
      });
      listScDependenciesBySoDependencyId(soDependencyId, (response) => {
        this.setState({
          scDependencies: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }

    // Headquarter/LogisticCenter User
    if (dependencyType === "so" && dependencyId !== 1 && (dependencyTypeId === 1 || dependencyTypeId === 2)) {
      const soDependencyId = dependencyId;
      this.setState({
        disabledScDependency: false,
        soDependencyId,
        soDependencyName: soDependencyParent ? (soDependencyParent.soDependenctTypeId === 0 ? soDependencyParent.name : null) : null,
      });
      this.props.listScDependenciesBySoDependencyId(soDependencyId, (response) => {
        this.setState({
          scDependencies: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }

    // StockCustomer User
    if (dependencyType === "sc") {
      const scDependencyId = sessionStorage.getItem("dependencyId");
      this.setState({
        scDependencyId: userDependency ? (dependencyType === "sc" ? dependencyId : null) : null,
        scDependencyName: userDependency ? (dependencyType === "sc" ? dependencyName : null) : null,
        vendingMachineId: null,
        vendingMachines: [],
        vendingMachineName: "",
        disabledVendingMachine: false,
        amountAvailable: [],
        loading: false,
      });
      this.props.getVendingMachinesByIsPlanogramAssigned(scDependencyId, 1, (response) => {
        this.setState({
          vendingMachines: response.data.data.map((item) => ({
            value: item.id,
            label: item.friendlyName,
          })),
        });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePlanogramSetupSize);
  }

  updatePlanogramSetupSize = () => {
    this.setState({
      height: window.innerHeight - planogramSetupSize,
    });
  };

  // -----------Events------------
  selectInputEvent(event, dependencyType) {
    const { value } = event;
    const { listScDependenciesBySoDependencyId, listVendingMachinesByScDependencyId, listWingsByVmId2, listFullVmProductSlotByWingId2, listVmTraysByWingId } = this.props;

    switch (dependencyType.name) {
      case "soDependencyType0":
        this.setState({
          soDependencyId: value,
          scDependencyId: null,
          vendingMachineId: null,
          disabledScDependency: false,
          disabledVendingMachine: true,
          scDependencies: [],
        });
        listScDependenciesBySoDependencyId(value, (response) => {
          this.setState({
            scDependencies: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        });
        break;

      case "scDependency":
        this.setState({
          scDependencyId: value,
          vendingMachineId: null,
          disabledVendingMachine: false,
          vendingMachines: [],
          wingTabs: [],
        });
        listVendingMachinesByScDependencyId(value, (response) => {
          this.setState({
            vendingMachines: response.data.data.map((item) => ({
              value: item.id,
              label: item.friendlyName,
            })),
          });
        });
        break;

      case "vendingMachine":
        const { value: vendingMachineId } = event;

        this.setState({
          vendingMachineName: event.label,
          vendingMachineId,

          wingTabs: [],
          isLoadingInfo: true,
        });

        listWingsByVmId2(vendingMachineId, (response) => {
          const wings = response.data.data;
          const wingTabs = [];

          if (wings.length > 0) {
            wings.forEach((wing) => {
              const wingTabInfo = {
                wingInfo: wing,
                planogramInfo: {
                  id: wing.wingId,
                  depth: 0,
                  minWidth: 0,
                  minHeight: 0,
                  material: 0,
                  numRows: 0,
                  numCols: 0,
                  maxWidhtMultiplier: 0,
                  maxHeightMultiplier: 0,
                  modalities: [],
                  slotGroups: [],
                  slots: [],
                  isLoaded: false,
                },
                wingVmTrays: [],
                amountAvailable: 0,
              };

              if (wing.wingTypeId !== 3) {
                this.setState({
                  isLoadingInfo: true,
                });
                listVmTraysByWingId(wing.wingId, (response) => {
                  if (response.data.data) {
                    wingTabInfo.wingVmTrays = response.data.data;
                    this.setState({
                      isLoadingInfo: false,
                    });
                  }
                });
              }
              listFullVmProductSlotByWingId2(wing.wingId, (response) => {
                if (response.data.data) {
                  const products = _.groupBy(response.data.data.slots, "soProductId");
                  const productIds = [...new Set(_.map(response.data.data.slots, "soProductId"))];
                  const amountInfo = [];
                  let spaces = 0;
                  let amountAvailable = 0;
                  let productName;
                  let productCode;
                  let product;
                  let availabilityPercentage = 0;

                  for (let i = 0; i < _.size(products); i++) {
                    for (let j = 0; j < products[productIds[i]].length; j++) {
                      productName = [...new Set(_.map(products[productIds[i]], "soProductName"))];
                      productCode = [...new Set(_.map(products[productIds[i]], "soProductCode"))];
                      spaces += products[productIds[i]][j].helixSpaces;
                      amountAvailable += products[productIds[i]][j].amountAvailable;
                      availabilityPercentage = _.round((amountAvailable * 100) / spaces, 1);
                      product = {
                        name: productName[0],
                        code: productCode[0],
                        spaces,
                        amountAvailable,
                        availabilityPercentage,
                      };
                    }
                    amountInfo.push(product);
                    amountAvailable = 0;
                    spaces = 0;
                  }

                  wingTabInfo.planogramInfo = response.data.data;
                  wingTabInfo.planogramInfo.isLoaded = true;
                  wingTabInfo.amountAvailable = _.orderBy(amountInfo, "availabilityPercentage", "asc"); // Sort by availability perentage

                  this.setState({
                    amountAvailable: _.orderBy(amountInfo, "availabilityPercentage", "asc"), // Sort by availability perentage
                    planogramInfo: response.data.data.slots,
                    isLoadingInfo: false,
                  });
                } else {
                  this.setState({
                    isLoadingInfo: false,
                  });
                }
              });
              wingTabs.push(wingTabInfo);
            });
            this.setState({
              isLoadingInfo: true,
            });
          }

          this.setState({
            wingTabs: wingTabs.sort((a, b) => (a.wingInfo.wingNumber > b.wingInfo.wingNumber ? 1 : b.wingInfo.wingNumber > a.wingInfo.wingNumber ? -1 : 0)),
          });
        });
        break;
      default:
        break;
    }
  }

  // Export methods
  showExportReportsModal = () => {
    const { dispensationsCount } = this.state;
    if (dispensationsCount >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };
  showExportLockerReportsModal = () => {
    const { dispensationsCount } = this.state;

    if (dispensationsCount >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
        selReport: 2,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
        selReport: 2,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible, isExceedModalVisible) => {
    this.setState({
      isExportModalVisible,
      isExceedModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    const { exportVmInventoryReport, exportVmInventoryByProductReport, exportLockerInventoryReport } = this.props;

    const { exportLoading, selReport, vendingMachineId, scDependencyId, soDependencyId } = this.state;

    if (!exportLoading) {
      // Start loading animation on Export button
      this.setState({ exportLoading: true });

      let columnHeaders = [];

      // Check the selected report to export
      switch (selReport) {
        case 0: // Per position inventory report
          // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
          columnHeaders = [
            Strings.machine.wing,
            Strings.machine.wingType,
            Strings.planogram.slot,
            Strings.generalTerms.tray,
            Strings.planogram.size,
            Strings.planogram.connectionLeft,
            Strings.planogram.connectionRight,
            Strings.planogram.motor,
            Strings.generalTerms.capacity,
            Strings.machineInventory.amountAvailable,
            Strings.product.soProductType,
            Strings.product.product,
            Strings.generalTerms.code,
            Strings.product.price,
            Strings.product.taxValue,
          ];

          // Consume report export method
          exportVmInventoryReport(vendingMachineId, scDependencyId, soDependencyId, fileType, columnHeaders, (response) => {
            if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
              // Connection error
              Modal.error({
                title: Strings.generalResponses.failedTransaction,
                content: Strings.generalResponses.reportGenerationError,
              });
              this.setState({
                exportLoading: false,
              });
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;

              const today = new Date();
              link.setAttribute("download", `${Strings.machineInventory.slotInventory} ${today}.csv`);
              document.body.appendChild(link);
              link.click();
              this.setState({
                exportLoading: false,
              });
            }
          });
          break;
        case 1: // Per product inventory report
          // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
          columnHeaders = [
            Strings.product.product,
            Strings.generalTerms.code,
            Strings.machineInventory.amountAvailable,
            Strings.generalTerms.capacity,
            Strings.machineInventory.availability,
          ];

          // Consume report export method
          exportVmInventoryByProductReport(vendingMachineId, scDependencyId, soDependencyId, fileType, columnHeaders, (response) => {
            if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
              // Connection error
              Modal.error({
                title: Strings.generalResponses.failedTransaction,
                content: Strings.generalResponses.reportGenerationError,
              });
              this.setState({
                exportLoading: false,
              });
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;

              const today = new Date();
              link.setAttribute("download", `${Strings.machineInventory.productInventary} ${today}.csv`);
              document.body.appendChild(link);
              link.click();
              this.setState({
                exportLoading: false,
              });
            }
          });
          break;

        case 2: // Per locker position inventory report
          // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
          columnHeaders = [
            Strings.machine.wing,
            Strings.machine.wingType,
            Strings.planogram.slot,
            Strings.planogram.harness,
            Strings.planogram.harnessConnection,
            Strings.generalTerms.state,
            Strings.machineInventory.amountAvailable,
            Strings.product.product,
            Strings.generalTerms.code,
            Strings.product.price,
            Strings.product.taxValue,
          ];

          // Consume report export method
          exportLockerInventoryReport(vendingMachineId, scDependencyId, soDependencyId, fileType, columnHeaders, (response) => {
            if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
              // Connection error
              Modal.error({
                title: Strings.generalResponses.failedTransaction,
                content: Strings.generalResponses.reportGenerationError,
              });
              this.setState({
                exportLoading: false,
              });
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;

              const today = new Date();
              link.setAttribute("download", `${Strings.machineInventory.lockerInventory} ${today}.csv`);
              document.body.appendChild(link);
              link.click();
              this.setState({
                exportLoading: false,
              });
            }
          });
          break;
        default:
          break;
      }
    }
  };

  onInventoryReportSel = (selReport) => {
    this.setState({
      selReport: selReport.value,
    });
  };

  // ---INFORMATION TO RENDER---//

  /** *
   * Function to render the wing tab
   */
  renderTabHeader(wingTabs) {
    const res = [];

    wingTabs.forEach((wingTab) => {
      const the_tab = (
        <TabPane tab={`${Strings.machine.wing} ${wingTab.wingInfo.wingNumber}: ${wingTab.wingInfo.wingTypeName}`} key={wingTab.wingInfo.wingNumber}>
          {wingTab.wingInfo.wingTypeId !== 3 ? <div className="row">{this.renderVmInventory(wingTab)}</div> : this.renderLockerInventory(wingTab)}
        </TabPane>
      );
      res.push(the_tab);
    });
    return res;
  }

  /** *
   * Function to render the wing planogram by tab
   */
  renderVmInventory(wingTab) {
    privileges = this.props.permissions.data;
    const { scDependencyId, vendingMachineId, vendingMachineName, reportOptions, disabledVendingMachine, selReport, exportLoading } = this.state;

    const planogram = (
      <>
        <div className="vertSpace col-md-5">
          <ProductInventory scDependencyId={scDependencyId} vmId={vendingMachineId} products={wingTab.amountAvailable} />
        </div>
        <div className="vertSpace col-md-7" style={{ padding: "5px" }}>
          <MachineGui
            scDependencyId={scDependencyId}
            vmId={vendingMachineId}
            vmTrays={wingTab.wingVmTrays}
            vmSetup={wingTab.wingInfo.maxNumSlots}
            vmName={vendingMachineName}
            planogramInfo={wingTab.planogramInfo}
          />
          <ValidatePermissionForComponent permission="PRIVILEGE MACHINE INVENTORY REPORT GET" permissions={privileges}>
            {wingTab.planogramInfo && (
              <div className="row" style={{ marginTop: 30, display: "flex", alignItems: "center" }}>
                <div className="col-xs-0 col-md-2" />
                <div className="col-md-6">
                  <SelectGeneral
                    text={Strings.generalTerms.reportType}
                    options={reportOptions}
                    onChange={(e) => this.onInventoryReportSel(e)}
                    disabled={disabledVendingMachine}
                    defaultValue={selReport}
                  />
                </div>
                <div className="col-md-2" style={{ paddingLeft: 0 }}>
                  <ExportButton isDisabled={!vendingMachineId} onClick={() => this.showExportReportsModal()} loading={exportLoading} />
                </div>
                <div className="col-xs-0 col-md-2" />
              </div>
            )}
          </ValidatePermissionForComponent>
        </div>
      </>
    );
    return planogram;
  }

  /** *
   * Function to render the wing planogram by tab
   */
  renderLockerInventory(wingTab) {
    privileges = this.props.permissions.data;
    const { vendingMachineName, vendingMachineId, exportLoading } = this.state;
    const planogram = (
      <div className="row">
        <div className="row" style={{ width: "100%", margin: "auto" }}>
          <LockerGui lockerInfoList={wingTab} vmName={vendingMachineName} previousPathDisabled planogramInfo={wingTab.planogramInfo} />
        </div>
        <div className="row" style={{ width: "100%", margin: "auto" }}></div>
        <ValidatePermissionForComponent permission="PRIVILEGE MACHINE INVENTORY REPORT GET" permissions={privileges}>
          {true && (
            <div className="col" style={{ marginTop: 30, alignItems: "center" }}>
              <div className="col-xs-0 col-md-4" />

              <div className="col-xs-12 col-md-8">
                <ExportButton isDisabled={!vendingMachineId} onClick={() => this.showExportLockerReportsModal()} loading={exportLoading} />
              </div>
            </div>
          )}
        </ValidatePermissionForComponent>
      </div>
    );
    return planogram;
  }

  render() {
    const {
      // permissions: { data },
      soDependencies,
    } = this.props;

    const {
      height,
      isExportModalVisible,
      isExceedModalVisible,
      soDependencyId,
      scDependencies,
      scDependencyId,
      scDependencyName,
      disabledScDependency,
      vendingMachines,
      disabledVendingMachine,
      vendingMachineId,

      isLoadingInfo,

      wingTabs,
    } = this.state;

    return (
      <div className="content-container" style={{ minHeight: height }}>
        <ExportReportsModal
          isExportVisible={isExportModalVisible}
          isExceedVisible={isExceedModalVisible}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
        />

        <div className="row">
          <Titles title={Strings.machineInventory.vendingMachineInventory} tooltip={Strings.machineInventory.vendingMachineInventoryTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.parameterization} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <ValidateDependency
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            soDependencies={soDependencies}
            soDependencyId={soDependencyId}
            soDependencyHtmlTag="soDependencyType0"
            scDependencies={scDependencies}
            scDependencyId={scDependencyId}
            scDependency={scDependencyName}
            scDependencyHtmlTag="scDependency"
            disabledScDependency={disabledScDependency}
            soDependencyHandleChange={(e, soDependency) => this.selectInputEvent(e, soDependency)}
            scDependencyHandleChange={(e, scDependency) => this.selectInputEvent(e, scDependency)}
          />
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.machine.vendingMachine}
              tooltip={Strings.machine.machineTooltip}
              name="vendingMachine"
              isReq
              options={vendingMachines}
              onChange={(e, name) => this.selectInputEvent(e, name)}
              disabled={disabledVendingMachine}
              defaultValue={vendingMachineId}
            />
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col">
            <Divider orientation="left">
              <h3>{Strings.machineInventory.inventary}</h3>
            </Divider>

            <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} spinning={isLoadingInfo}>
              {vendingMachineId !== null ? (
                <Tabs style={{ overflow: "visible" }}>{this.renderTabHeader(wingTabs)}</Tabs>
              ) : (
                <div className="style-alert-msn">
                  <Alert message={Strings.generalTerms.information} description={Strings.generalResponses.queryResponse} type="info" showIcon />
                  <div className="footer-style" />
                </div>
              )}
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    soDependencyParent: state.soDependencyParent.soParent,
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listVendingMachinesByScDependencyId,
  listWingsByVmId2,
  listFullVmProductSlotByWingId2,
  // listWingsByVmId,
  // listFullVmProductSlotByWingId,
  listVmTraysByWingId,
  // listFullLockerVmProductSlotByWingId,
  getVendingMachinesByIsPlanogramAssigned,
  exportVmInventoryReport,
  exportVmInventoryByProductReport,
  exportLockerInventoryReport,
})(VendingMachineInventory);
