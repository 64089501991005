import React, { Fragment } from "react";
import { Divider } from "antd";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import Strings from "../../../systemVariables/languageStrings";

const ScEmployeeInfoForm = (props) => {
  const { user, scDepartments, scUserProfiles, scCostCenters } = props;
  return (
    <>
      <div className="row">
        <div className="col">
          <Divider orientation="left">
            <h3>{Strings.scEmployeeInformation.scEmployeeInformation} </h3>
          </Divider>
        </div>
      </div>

      <div className="row">
        <div className="vertSpace col-md-6">
          <SelectGeneral
            text={Strings.scDepartment.department}
            name="scDepartmentId"
            tooltip={Strings.scDepartment.departmentTooltip}
            options={scDepartments}
            defaultValue={user.scDepartmentId}
            placeholder={Strings.generalTerms.selectState}
            onChange={(option, event) => props.onSelect(option, event)}
          />
        </div>
        <div className="vertSpace col-md-6">
          <SelectGeneral
            text={Strings.scUserProfile.userProfile}
            name="scUserProfileId"
            tooltip={Strings.scUserProfile.userProfileTooltip}
            options={scUserProfiles}
            defaultValue={user.scUserProfileId}
            placeholder={Strings.generalTerms.selectState}
            onChange={(option, event) => props.onSelect(option, event)}
          />
        </div>
        <div className="vertSpace col-md-6">
          <SelectGeneral
            text={Strings.scCostCenter.costCenter}
            name="scCostCenterId"
            tooltip={Strings.scCostCenter.costCenterTooltip}
            options={scCostCenters}
            defaultValue={user.scCostCenterId}
            placeholder={Strings.generalTerms.selectState}
            onChange={(option, event) => props.onSelect(option, event)}
          />
        </div>
      </div>
    </>
  );
};
export default ScEmployeeInfoForm;
