import React from "react";
import { Divider } from "antd";
import PasswordChange from "./PasswordChangeComponent.jsx";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

const SecurityConfig = () => {
  return (
    <div className="content-container">
      <div className="row">
        <div className="col">
          <Divider orientation="left">
            <h3>{Strings.generalTerms.changePassword}</h3>
          </Divider>
        </div>
      </div>
      <PasswordChange />
      <div className="row">
        <div className="col">
          <Divider orientation="left" />
        </div>
      </div>
    </div>
  );
};
export default SecurityConfig;
