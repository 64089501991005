import { LIST_PRODUCTTAXTYPE } from "../actions";

const initialState = {
  productTaxTypeObj: [],
};

export function productTaxType(state = [], action) {
  switch (action.type) {
    case LIST_PRODUCTTAXTYPE:
      var productTaxTypes = action.payload.data.map((item) => ({ value: item.id, label: item.value + "%", countryName: item.countryName }));
      return productTaxTypes;
    default:
      return state;
  }
}
