import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/scdepartments";

export function listScDepartmentByScDependencyId(scDependencyId, callback){
  return () => {
    axios.get(host+ port + path  + version + resource,{
      params: {
        scdependency_id: scDependencyId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
      },
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      return(error);
    });
  }
}

export function listScDepartmentByScDependencyIdPaginated (scDependencyId, name, page, pageSize, attribute, order, callback){ 
  return () => {
    axios.get(host+ port + path  + version + resource,{
      params: {
        scDependencyId: scDependencyId,
        name: name,
        page: page,
        pageSize: pageSize,
        attribute: attribute,
        order: order
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      return error;
    });
  }
}

export function addScDepartment(scDepartmentDTO, callback){
  return () => {
    let data = JSON.stringify(scDepartmentDTO)
    axios.post(host + port + path + version + resource, data, {
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error) {
      return(error);
    });
  }
}

export function editScDepartment(scDepartmentDTO, callback){
  return () => {
    let data = JSON.stringify(scDepartmentDTO)
    axios(
      {method:'put',
      url : host+ port + path  + version + resource,
      data : data,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      return(error);
    });
  }
}

export function disableScDepartmentById(scDepartmentId, callback){
  return () => {
    let data = JSON.stringify(scDepartmentId)
    axios({
      method: 'delete',
      url: host + port + path + version + resource,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    }).then((response) => {
      callback(response);
    })
    .catch(function (error) {
      return(error);
    });
  }
}