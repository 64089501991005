import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Icon, Modal, Divider } from "antd";

import { GeneralInput } from "../GenericComponents/InputComponent/generalInput";

import { editPeripheralDevice } from "../../actions";

import Strings from "../../systemVariables/languageStrings";

const PERIPHERAL_TYPES = { MEMS_SENSOR: 9, MOTORS_CONTROLLER: 20, TEMPERATURE_CONTROLLER: 29 };

class PeripheralDeviceEditModal extends Component {
  constructor(props) {
    super(props);
    const {
      peripheralDevice: { id, alternativeSerial, mpn, modbusId, peripheralDeviceAlternativeSerialNumber },
    } = this.props;
    this.state = {
      isModalLoading: false,
      isModalVisible: false,
      peripheralDeviceToEdit: {
        id,
        alternativeSerial,
        mpn,
        peripheralDeviceAlternativeSerialNumber,
        modbusId,
      },
    };
  }

  resetComponent = () => {
    const {
      peripheralDevice: { id, alternativeSerial, mpn, modbusId, peripheralDeviceAlternativeSerialNumber },
    } = this.props;
    this.setState({
      isModalLoading: false,
      isModalVisible: false,
      peripheralDeviceToEdit: {
        id,
        alternativeSerial,
        mpn,
        peripheralDeviceAlternativeSerialNumber,
        modbusId,
      },
    });
  };

  resetAndReloadInfo = () => {
    const { reloadTable } = this.props;
    this.resetComponent();
    reloadTable();
  };

  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { peripheralDeviceToEdit } = { ...this.state };

    peripheralDeviceToEdit[id] = value;

    this.setState({
      peripheralDeviceToEdit,
    });
  };

  handleEditPeripheralDevice = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleOk = () => {
    this.setState({ isModalLoading: true });
    const {
      peripheralDeviceToEdit: { id, peripheralDeviceAlternativeSerialNumber, mpn, modbusId },
    } = this.state;
    const { editPeripheralDevice } = this.props;
    const dto = {
      id,
      alternativeSerial: peripheralDeviceAlternativeSerialNumber,
      mpn,
      modbusId,
    };
    editPeripheralDevice(dto, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data && response.data.status === "SUCCESS") {
        //  Entity successfully saved
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
          onOk: this.resetAndReloadInfo(),
        });
      } else {
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      }
      this.resetComponent();
    });
  };

  handleCancel = () => {
    this.resetComponent();
  };

  render() {
    const {
      peripheralDevice: { peripheralDeviceTypeId, currentPeripheralDevicesGeneration, reference, peripheralDeviceSerialNumber },
    } = this.props;
    const {
      isModalVisible,
      isModalLoading,
      peripheralDeviceToEdit: { peripheralDeviceAlternativeSerialNumber, mpn, modbusId },
    } = this.state;

    return (
      <>
        <Icon style={{ cursor: "pointer" }} type="edit" theme="twoTone" onClick={this.handleEditPeripheralDevice} />
        <Modal
          title={<h1 className="text-center">{Strings.peripheralDevice.peripheralEdition}</h1>}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okButtonProps={{ loading: isModalLoading }}
          width={600}
          visible={isModalVisible}
        >
          <div>
            <div className="row">
              <div className="col-md-4 text-center">
                <b> {Strings.machine.machineGeneration}</b>
                <p className="text-center"> {currentPeripheralDevicesGeneration ? currentPeripheralDevicesGeneration : Strings.peripheralDevice.peripheralAllGeneration}</p>
              </div>
              <div className="col-md-4 text-center">
                <b>{Strings.peripheralDevice.peripheralDeviceModel}</b>
                <p className="text-center">{reference}</p>
              </div>
              <div className="col-md-4 text-center">
                <b>{Strings.peripheralDevice.peripheralDeviceSerialNumber}</b>
                <p className="text-center">{peripheralDeviceSerialNumber}</p>
              </div>
            </div>
            <Divider orientation="left">
              <h3>{Strings.generalTerms.parameterization} </h3>
            </Divider>
            <div className="row">
              <div className="col-md-12">
                <GeneralInput
                  id="peripheralDeviceAlternativeSerialNumber"
                  text={Strings.peripheralDevice.alternativeSerial}
                  placeholder={Strings.peripheralDevice.alternativeSerial}
                  value={peripheralDeviceAlternativeSerialNumber}
                  onChange={this.handleFormChange}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <GeneralInput id="mpn" text={Strings.peripheralDevice.mpn} placeholder={Strings.peripheralDevice.mpn} value={mpn} onChange={this.handleFormChange} />
              </div>
            </div>
            <br />
            {Object.values(PERIPHERAL_TYPES).includes(peripheralDeviceTypeId) ? (
              <div className="row">
                <div className="col-md-12">
                  <GeneralInput id="modbusId" text="Modbus" placeholder="Modbus id" value={modbusId} onChange={this.handleFormChange} />
                </div>
              </div>
            ) : null}
          </div>
        </Modal>
      </>
    );
  }
}

PeripheralDeviceEditModal.propTypes = {
  peripheralDevice: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    alternativeSerial: PropTypes.string,
    mpn: PropTypes.string,
    modbusId: PropTypes.number,
    peripheralDeviceAlternativeSerialNumber: PropTypes.string,
    peripheralDeviceTypeId: PropTypes.number,
    currentPeripheralDevicesGeneration: PropTypes.string,
    reference: PropTypes.string,
    peripheralDeviceSerialNumber: PropTypes.string,
  }).isRequired,
  reloadTable: PropTypes.func.isRequired,
  editPeripheralDevice: PropTypes.func,
};

PeripheralDeviceEditModal.defaultProps = {
  editPeripheralDevice: null,
};

export default connect(null, {
  editPeripheralDevice,
})(PeripheralDeviceEditModal);
