import { LIST_COUNTRIES } from "../actions";

export function country(state = [], action) {
  switch (action.type) {
    case LIST_COUNTRIES:
      return action.payload.data.map((item) => ({ value: item.id, label: item.name, code: item.alpha2Code }));
    default:
      return state;
  }
}
