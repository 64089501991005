import React, { Component } from "react";
import { Modal, Divider, Spin, Icon, Card } from "antd";
import moment from "moment";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { identityCardTypeLocalization } from "../../../Utils/LanguageLocalization/identityCardTypeLocalization";

import "./addClientUser.css";

const dateFormat2 = "YYYY/MM/DD hh:mm:ss";
const { Meta } = Card;
let renderRfid;

class UserEnrollmentModal extends Component {
  handleOk = () => {
    this.props.setVisible(false);
    this.props.clearImage();
    this.props.clearModal();
  };

  handleCancel = () => {
    this.props.setVisible(false);
    this.props.clearImage();
    this.props.clearModal();
  };

  disableRFID = (e, row) => {
    const { disableRFID, userInfo } = this.props;
    disableRFID(userInfo.id, row.userEnrollmentInformationId);
  };

  renderTitle = (userInfo) => {
    const identityCardType = userInfo.identityCardType ? userInfo.identityCardType.name : " ";
    const { identityCardNumber } = userInfo;

    return (
      <>
        <h2 className="text-center">
          {userInfo.firstName ? `${userInfo.firstName} ` : null}
          {userInfo.middleName ? `${userInfo.middleName} ` : null}
          {userInfo.lastName1 ? `${userInfo.lastName1} ` : null}
          {userInfo.lastName2 ? userInfo.lastName2 : null}
        </h2>
        <h3 className="text-center">
          <div>
            {identityCardTypeLocalization(identityCardType)}: <span className="text-secondary">{identityCardNumber}</span>
          </div>
        </h3>
      </>
    );
  };

  renderEnrollmentData = (userEnrollmentInfo) => {
    let renderFingerPrint;
    let renderBarcode;

    if (userEnrollmentInfo && userEnrollmentInfo.length !== 0) {
      const fpList = userEnrollmentInfo.filter((row) => row.enrollmenttype === "FINGERPRINT");
      const barcodeList = userEnrollmentInfo.filter((row) => row.enrollmenttype === "BARCODE");
      const rfidList = userEnrollmentInfo.filter((row) => row.enrollmenttype === "RFID_CARD");

      if (fpList && fpList.length !== 0) {
        const fpAmount = userEnrollmentInfo.filter((row) => row.enrollmenttype === "FINGERPRINT").length;
        const fpEnrollDate = fpList[0].enrollmentDate;

        renderFingerPrint = (
          <>
            <p>
              <Icon type="paper-clip" />
              <b>{Strings.userEnrollment.fpAmount}:</b> {fpAmount}
            </p>
            <p>
              <b>{Strings.userEnrollment.enrollmentDate}:</b> {moment(fpEnrollDate).format(dateFormat2)}
            </p>
            <Divider />
          </>
        );
      }

      if (barcodeList && barcodeList.length !== 0) {
        const barcodeAmount = userEnrollmentInfo.filter((row) => row.enrollmenttype === "BARCODE").length;
        const bcEnrollDate = barcodeList[0].enrollmentDate;

        renderBarcode = (
          <>
            <p>
              <Icon type="barcode" /> <b>{Strings.userEnrollment.barcodeAmount}:</b> {barcodeAmount}
            </p>
            <p>
              <b>{Strings.userEnrollment.enrollmentDate}:</b> {moment(bcEnrollDate).format(dateFormat2)}
            </p>
            <Divider />
          </>
        );
      }

      if (rfidList && rfidList.length !== 0) {
        const rfidCodes = [];
        rfidList.forEach((row) => {
          rfidCodes.push(
            <p>
              {row.rfidCardUid}{" "}
              {this.props.deleting ? <Spin size="small" /> : <Icon style={{ fontSize: "18px" }} type="close-circle" theme="twoTone" onClick={(e) => this.disableRFID(e, row)} />}
            </p>
          );
        });

        const rfidEnrollDate = rfidList[0].enrollmentDate;
        renderRfid = (
          <>
            <div>
              <Icon type="wifi" /> <b>{Strings.userEnrollment.rfidCardUid}:</b> {rfidCodes}
            </div>
            <div>
              <b>{Strings.userEnrollment.enrollmentDate}:</b> {moment(rfidEnrollDate).format(dateFormat2)}
            </div>
          </>
        );
      }
    }

    return (
      <>
        {renderFingerPrint}
        {renderBarcode}
        {renderRfid}
      </>
    );
  };

  renderFpImage = (fpImage) => {
    let image = null;
    if (fpImage && fpImage !== null) {
      image = (
        <Card size="small" cover={<img alt="Fingerprint" src={fpImage} />}>
          <Meta title={Strings.userEnrollment.fpImage} />
        </Card>
      );
    }
    return image;
  };

  render() {
    const { isModalDataComplete, userInfo, isVisible, userEnrollment, fpImage } = this.props;
    return isModalDataComplete === false ? (
      <Modal title={Strings.generalTerms.loading} visible={isVisible} onOk={this.handleOk} onCancel={this.handleCancel} width="45%" footer={null}>
        <div className="row">
          <div className="col text-center">
            <Spin />
          </div>
        </div>
      </Modal>
    ) : (
      <Modal title={this.renderTitle(userInfo)} visible={isVisible} onOk={this.handleOk} onCancel={this.handleCancel} width="45%" footer={null}>
        <div>
          {userEnrollment.length > 0 ? (
            <div className="row">
              <div className="col-md-7" style={{ margin: "0px, 10px" }}>
                {this.renderEnrollmentData(userEnrollment)}
              </div>
              <div className="col-md-5" style={{ maxWidth: "170px", margin: "auto" }}>
                {this.renderFpImage(fpImage)}
              </div>
            </div>
          ) : (
            <div className="row">{Strings.userEnrollment.notEnrolled}</div>
          )}
        </div>
      </Modal>
    );
  }
}

export default UserEnrollmentModal;
