import { Button, Icon, Input, Popover, Spin, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { adjustmentTypeLocalization } from "../../Utils/LanguageLocalization";
import { DATE_TIME_FORMAT } from "../../systemVariables/TimeConfig";
import Strings from "../../systemVariables/languageStrings";
import QueryResponse from "../GenericComponents/queryResponse/QueryResponse";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";

const AdjustmentReportsTable = (props) => {
  // States

  const { adjustmentReportList, isLoading } = props;
  const [flag, setFalg] = useState(false);
  const [adjustmentReportDetail, setadjustmentReportDetail] = useState([]);

  // Search methods

  function handleReset(dataIndex) {
    // clearFilters();
    props.onSelectFilter(dataIndex, "");
  }
  function handleSearch(dataIndex, selectedKeys) {
    if (selectedKeys.length) props.onSelectFilter(dataIndex, selectedKeys);
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`${Strings.generalTerms.search} by ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => handleSearch(dataIndex, selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => handleReset(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  // More info details

  const adjustmentReportDetails = (row) => {
    setFalg(false);
    setadjustmentReportDetail([]);

    if (row.scProductName !== undefined) {
      setadjustmentReportDetail(
        <p>
          <b>{Strings.product.scProductInfo}:</b> {row.scProductName}
        </p>
      );
    } else {
      setadjustmentReportDetail(
        <div>
          <p>
            <b>{Strings.product.preTaxPrice}:</b> {row.scPreTaxPrice}
          </p>
          <p>
            <b>{Strings.transaction.adjustedBy}:</b> {row.fullName}
          </p>
          <p>
            <b>{Strings.dispensationReport.transactionDate}:</b> {moment(row.transactionDate).format(DATE_TIME_FORMAT)}
          </p>
        </div>
      );
    }

    setFalg(true);
  };

  // Table columns
  const columns = [
    {
      title: Strings.transaction.adjustmentType,
      dataIndex: "adjustmentTypeName",
      width: "20%",
      align: "center",
      render: (adjustmentTypeName) => <span>{adjustmentTypeLocalization(adjustmentTypeName)} </span>,
    },
    {
      title: Strings.product.soProduct,
      dataIndex: "soProductName",
      key: "soProductName",
      width: "20%",
      align: "center",
      ...getColumnSearchProps("soProductName", Strings.product.product),
    },
    {
      title: Strings.product.scProductCode,
      dataIndex: "erpProductCode",
      width: "20%",
      align: "center",
      ...getColumnSearchProps("erpProductCode", Strings.product.scProductCode),
    },
    {
      title: Strings.planogram.vmSlotPosition,
      dataIndex: "slotNumber",
      width: "10%",
      align: "center",
      ...getColumnSearchProps("slotNumber", Strings.planogram.vmSlotPosition),
    },
    {
      title: Strings.dispensationRule.amount,
      width: "10%",
      dataIndex: "amount",
      align: "center",
    },

    {
      title: Strings.transaction.adjustmentDate,
      width: "5%",
      dataIndex: "storageDateUtc",
      align: "center",
      render: (storageDateUtc) => <span> {moment(storageDateUtc).format(DATE_TIME_FORMAT)} </span>,
    },

    {
      title: Strings.generalTerms.details,
      align: "center",
      width: "5%",
      render: (row) => (
        <div style={{ align: "center", color: "#004173", cursor: "pointer" }} onClick={() => adjustmentReportDetails(row)}>
          <Popover content={flag ? adjustmentReportDetail : <Spin />} trigger="click" placement="left" title={<TextWithInfoTooltip name={Strings.generalTerms.details} />}>
            <Icon type="profile" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
          </Popover>
        </div>
      ),
    },
  ];
  function tableRenderMethod(columns, adjustmentReportList, isLoading) {
    return (
      <div>
        <Table
          style={{ cursor: "pointer" }}
          columns={columns}
          dataSource={adjustmentReportList}
          loading={isLoading}
          bordered
          pagination={false}
          size="middle"
          scroll={{ x: "auto" }}
          rowKey="vmProductTransactionAdjustmentId"
        />
      </div>
    );
  }

  return <QueryResponse isLoading={isLoading} callback={tableRenderMethod(columns, adjustmentReportList, isLoading)} dataSourceLength={adjustmentReportList.length} />;
};
export default AdjustmentReportsTable;
