// Dependencies
import { Button, Divider, Icon, Input, Modal, Pagination, Popconfirm, Popover, Spin, Table } from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Column from "antd/lib/table/Column";
import {
  disableVmProductGroup,
  listSoDependencyBySoDependencyType0,
  listSoProductByVmProductGroupId,
  listSoProductsBySoDependency,
  listVmProductGroupBySoDependencyId,
  listVmProductGroupBySoDependencyIdPaginated,
} from "../../../actions";

// Components
import { ValidateDependency } from "../../../Utils/validateDependency";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import { AddButton } from "../../GenericComponents/buttons/index";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";
import Titles from "../../GenericComponents/titles";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

let soProductInfo = [];

class ListVmProductGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flag: false,
      addButtonDisabled: true,
      vmProductGroups: [],
      vmProductGroupToEdit: null,
      editConfirm: false,
      searchText: "",
      soDependency: {
        id: null,
        name: null,
      },
      soProductList: [],
      soProductAssignedByVmProductGroup: null,
      page: 1,
      pageSize: 10,
      attribute: "name",
      order: "ASC",
      totalElements: 0,
      isLoading: false,
    };
    this.showPagination = this.showPagination.bind(this);
    this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
  }

  componentDidMount() {
    const { listSoDependencyBySoDependencyType0, listVmProductGroupBySoDependencyIdPaginated, listSoProductsBySoDependency, userDependency } = this.props;
    const { page, pageSize, attribute, order } = this.state;
    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") === "1") {
      listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
    }
    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") !== "1") {
      const value = sessionStorage.getItem("dependencyId");
      this.setState({
        soDependency: {
          id: userDependency.dependencyType === "so" && userDependency.dependencyId !== "1" ? userDependency.dependencyId : null,
          name: userDependency.dependencyType === "so" && userDependency.dependencyId !== "1" ? userDependency.dependencyName : null,
        },
        isLoading: true,
      });
      listVmProductGroupBySoDependencyIdPaginated("", value, page, pageSize, attribute, order, (response) => {
        this.setState({
          vmProductGroups: response.data.data.content,
          totalElements: response.data.data.totalElements,
          addButtonDisabled: false,
          isLoading: false,
          searchText: "",
        });
      });
      listSoProductsBySoDependency(value, (response) => {
        if (response.data.data) {
          const soProductList = response.data.data.map((obj) => {
            const soProduct = {};
            soProduct.id = obj.id;
            soProduct.soProductName = obj.name;
            soProduct.erpProductCode = obj.erpProductCode;
            soProduct.soProductByProductGroupId = null;
            return soProduct;
          });
          this.setState({ soProductList });
        }
      });
    }
  }

  /* ----------------------- Events -----------------------*/
  soDependencyInputEvent(event) {
    this.setState({
      page: 1,
      totalElements: 0,
      vmProductGroups: [],
    });

    const { listVmProductGroupBySoDependencyIdPaginated, listSoProductsBySoDependency } = this.props;
    const { pageSize, attribute, order } = this.state;

    this.setState({
      soDependency: {
        id: event.value,
        name: event.label,
      },
      isLoading: true,
    });
    listVmProductGroupBySoDependencyIdPaginated("", event.value, 1, pageSize, attribute, order, (response) => {
      this.setState({
        vmProductGroups: response.data.data.content,
        totalElements: response.data.data.totalElements,
        addButtonDisabled: false,
        isLoading: false,
        searchText: "",
      });
    });

    listSoProductsBySoDependency(event.value, (response) => {
      if (response.data.data) {
        const soProductList = response.data.data.map((obj) => {
          const soProduct = {};
          soProduct.id = obj.id;
          soProduct.soProductName = obj.name;
          soProduct.erpProductCode = obj.erpProductCode;
          soProduct.soProductByProductGroupId = null;
          return soProduct;
        });
        this.setState({ soProductList });
      }
    });
  }

  showPagination(page, pageSize) {
    this.setState({
      isLoading: true,
      page,
    });
    const { searchText, attribute, order, soDependency } = this.state;
    const { listVmProductGroupBySoDependencyIdPaginated } = this.props;
    listVmProductGroupBySoDependencyIdPaginated(searchText, soDependency.id, page, pageSize, attribute, order, (response) => {
      this.setState({
        vmProductGroups: response.data.data.content,
        totalElements: response.data.data.totalElements,
        isLoading: false,
      });
    });
  }

  vmProductGroupDetail(event, productGroup) {
    const { listSoProductByVmProductGroupId } = this.props;

    this.setState({
      flag: false,
    });
    listSoProductByVmProductGroupId(productGroup.id, (response) => {
      soProductInfo = [];
      if (response.data.data.length < 1) {
        soProductInfo = Strings.vmProductGroup.soProductByvmProductGroupNotFound;
      } else {
        response.data.data.forEach((elem) => {
          soProductInfo.push(
            // eslint-disable-next-line react/jsx-filename-extension
            <div>
              <p>
                - {elem.soProductName} ({elem.erpProductCode})
              </p>
            </div>
          );
        });
      }
      this.setState({
        flag: true,
      });
    });
  }

  getColumnSearchProps = (dataIndex, text, searchText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} ${text}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />,
  });

  handleSearch = (selectedKeys) => {
    const { soDependency, page, pageSize, attribute, order } = this.state;
    const { listVmProductGroupBySoDependencyIdPaginated } = this.props;
    if (selectedKeys.length > 0) {
      this.setState({
        isLoading: true,
        searchText: selectedKeys[0],
      });
      listVmProductGroupBySoDependencyIdPaginated(selectedKeys[0], soDependency.id, page, pageSize, attribute, order, (response) => {
        this.setState({
          vmProductGroups: response.data.data.content,
          totalElements: response.data.data.totalElements,
          isLoading: false,
        });
      });
    }
  };

  handleReset = (clearFilters) => {
    const { soDependency, page, pageSize, attribute, order } = this.state;
    const { listVmProductGroupBySoDependencyIdPaginated } = this.props;
    clearFilters();
    listVmProductGroupBySoDependencyIdPaginated("", soDependency.id, page, pageSize, attribute, order, (response) => {
      this.setState({
        vmProductGroups: response.data.data.content,
        totalElements: response.data.data.totalElements,
        addButtonDisabled: false,
        isLoading: false,
        searchText: "",
      });
    });
  };

  handleEditConfirm(row) {
    const { listSoProductByVmProductGroupId } = this.props;
    listSoProductByVmProductGroupId(row.id, (response) => {
      if (response.data.data.length > 0) {
        const soProductsAssigned = response.data.data.map((obj) => {
          const SoProductByProductGroup = {};
          SoProductByProductGroup.id = obj.soProductId;
          SoProductByProductGroup.soProductName = obj.soProductName;
          SoProductByProductGroup.erpProductCode = obj.erpProductCode;
          SoProductByProductGroup.vmProductGroupId = obj.vmProductGroupId;
          SoProductByProductGroup.vmProductGroupName = obj.vmProductGroupName;
          SoProductByProductGroup.soProductByProductGroupId = obj.id;
          return SoProductByProductGroup;
        });
        this.setState({
          soProductAssignedByVmProductGroup: soProductsAssigned,
          editConfirm: true,
          vmProductGroupToEdit: row,
        });
      } else {
        this.setState({
          editConfirm: true,
          vmProductGroupToEdit: row,
        });
      }
    });
  }

  disableVmProductGroup(e, row) {
    const { soDependency, page, pageSize, attribute, order } = this.state;
    const { disableVmProductGroup, listVmProductGroupBySoDependencyIdPaginated } = this.props;
    const vmProductGroupToDisable = {
      id: null,
      name: null,
      description: null,
      isEnabled: null,
    };

    vmProductGroupToDisable.id = row.id;
    vmProductGroupToDisable.name = row.name;
    vmProductGroupToDisable.description = !row.description ? null : row.description;
    vmProductGroupToDisable.isEnabled = 0;

    // Validate Object
    disableVmProductGroup(vmProductGroupToDisable, (response) => {
      this.setState({ isLoading: true });
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        this.setState({ isLoading: false });
      } else if (response.data.code === 4008) {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({ isLoading: false });
      } else if (response.data && response.data.status === "SUCCESS") {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.successfulDelete,
        });
        listVmProductGroupBySoDependencyIdPaginated("", soDependency.id, page, pageSize, attribute, order, (response) => {
          this.setState({
            vmProductGroups: response.data.data.content,
            totalElements: response.data.data.totalElements,
            addButtonDisabled: false,
            isLoading: false,
            searchText: "",
          });
        });
      } else {
        // Other error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({ isLoading: false });
      }
    });
  }

  methodListVmProductTable(vmProductGroups, isLoading, searchText, flag, data, totalElements, page) {
    return (
      <div className="vertSpace col-12">
        <Table dataSource={vmProductGroups} bordered loading={isLoading} pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="id">
          <Column
            style={{ width: "20%" }}
            title={<TextWithInfoTooltip name={Strings.generalTerms.name} tooltip={Strings.vmProductGroup.vmProductGroupNameToolTip} />}
            dataIndex="name"
            {...this.getColumnSearchProps("name", Strings.generalTerms.name, searchText)}
          />
          <Column
            style={{ width: "20%" }}
            title={<TextWithInfoTooltip name={Strings.generalTerms.description} tooltip={Strings.vmProductGroup.vmProductGroupDescriptionToolTip} />}
            dataIndex="description"
          />
          <Column
            style={{ width: "20%" }}
            title={<TextWithInfoTooltip name={Strings.generalTerms.details} tooltip={Strings.vmProductGroup.vmProductGroupAssignedTooltip} />}
            align="center"
            render={(row) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div style={{ align: "center", color: "#004173", cursor: "pointer" }} onClick={(e) => this.vmProductGroupDetail(e, row)}>
                <Popover
                  content={flag ? soProductInfo : <Spin />}
                  trigger="click"
                  placement="left"
                  title={<TextWithInfoTooltip name={Strings.product.assignedProducts} tooltip={Strings.vmProductGroup.vmProductGroupAssignedTooltip} />}
                >
                  <Icon type="profile" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
                </Popover>
              </div>
            )}
          />
          {ValidatePermissionForComponentPart("PRIVILEGE VM PRODUCT GROUP PUT", data) ? (
            <Column
              style={{ width: "20%" }}
              align="center"
              placement="left"
              cursor="pointer"
              title={<div>{Strings.generalTerms.edit}</div>}
              render={(row) => (
                <div style={{ cursor: "pointer" }}>
                  <Popconfirm
                    title={Strings.vmProductGroup.editVmProductGroupWarn}
                    onConfirm={() => this.handleEditConfirm(row)}
                    okText={Strings.generalTerms.yes}
                    cancelText={Strings.generalTerms.no}
                  >
                    <Icon type="edit" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
                  </Popconfirm>
                </div>
              )}
            />
          ) : (
            ""
          )}
          {ValidatePermissionForComponentPart("PRIVILEGE VM PRODUCT GROUP DELETE", data) ? (
            <Column
              style={{ width: "20%" }}
              align="center"
              title={<div>{Strings.generalTerms.remove}</div>}
              accessor="remove"
              render={(row) => (
                <div>
                  <Popconfirm title={Strings.vmProductGroup.deleteVmProductGroupWarn} onConfirm={(e) => this.disableVmProductGroup(e, row)} okText="Sí" cancelText="No">
                    <Icon type="delete" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
                  </Popconfirm>
                </div>
              )}
            />
          ) : (
            ""
          )}
        </Table>
        <div className="row">
          <div className="col-xs-0 col-lg-6" />
          <div className="vertSpace col-lg-6">
            {totalElements > 0 ? (
              <Pagination
                size="small"
                total={totalElements}
                showSizeChanger
                onChange={this.showPagination}
                onShowSizeChange={this.showPagination}
                pageSizeOptions={["10", "25", "50", "100", "250"]}
                current={page}
                disabled={vmProductGroups ? !(vmProductGroups.length > 0) : true}
                hideOnSinglePage={false}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  // Information to render
  render() {
    const {
      permissions: { data },
      soDependencies,
    } = this.props;
    const {
      page,
      totalElements,
      editConfirm,
      flag,
      isLoading,
      vmProductGroups,
      addButtonDisabled,
      soDependency,
      searchText,
      vmProductGroupToEdit,
      soProductList,
      soProductAssignedByVmProductGroup,
    } = this.state;
    // Redirects to addVmProductGroup for editing
    if (editConfirm) {
      return (
        <Redirect
          to={{
            pathname: "/editVmProductGroup",
            props: {
              soDependency,
              vmProductGroupToEdit,
              soProductList,
              soProductAssignedByVmProductGroup,
            },
          }}
        />
      );
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.vmProductGroup.vmProductGroup} tooltip={Strings.vmProductGroup.vmProductGroupTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <ValidateDependency
            soDependencyHtmlTag="soDependencyType0"
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            soDependencies={soDependencies}
            soDependencyId={soDependency.id}
            soDependency={soDependency.name}
            soDependencyHandleChange={(e) => this.soDependencyInputEvent(e)}
            hasOnlySO
            isReq
          />
        </div>

        <ValidatePermissionForComponent permission="PRIVILEGE DISPENSATION RULES POST" permissions={data}>
          <div className="row">
            <div className="vertSpace col-md-6" />
            <div className="vertSpace col-md-6">
              <AddButton
                tooltip={Strings.vmProductGroup.addVmProductGroupTooltip}
                link={addButtonDisabled ? "" : "./addVmProductGroup"}
                props={addButtonDisabled ? "" : { soDependency, soProductList }}
                disabled={addButtonDisabled}
              />
            </div>
          </div>
        </ValidatePermissionForComponent>
        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.vmProductGroup.vmProductGroups} </h3>
            </Divider>
          </div>
        </div>
        <QueryResponse
          callback={this.methodListVmProductTable(vmProductGroups, isLoading, searchText, flag, data, totalElements, page)}
          dataSourceLength={vmProductGroups.length}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listVmProductGroupBySoDependencyId,
  listSoProductByVmProductGroupId,
  listVmProductGroupBySoDependencyIdPaginated,
  disableVmProductGroup,
  listSoProductsBySoDependency,
})(ListVmProductGroups);
