// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash";

// Components
import { Modal, Divider, message, Checkbox, DatePicker } from "antd";
import moment from "moment";
import Titles from "../GenericComponents/titles";
import { GeneralInput } from "../GenericComponents/InputComponent/generalInput";
import { ReturnButton, SaveButton } from "../GenericComponents/buttons/index";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";
import AvailableSoProductTable from "../Products/SoProduct/AvailableSoProductTable.jsx";
import AvailableVmProductGroupTable from "../Products/vmProductGroup/availableVmProductGroupTable";
import SoProductConfigToDispensationRuleTable from "./SoProductConfigToDispensationRuleTable";
import VmProductGroupConfigToDispensationRuleTable from "./VmProductGroupConfigToDispensationRuleTable.jsx";
import Loading from "../GenericComponents/loadingComponent/loading";

// Actions
import {
  listVwScProductInformationByScDependency,
  listVmProductGroup,
  listFrequencyUnits,
  listSoProductByVmProductGroupId,
  addScDispensationRuleAndProductAssignment,
  listSoProductByVmProductGroupBySoDependencyId,
  listSoProductByVmProductGroupByScDependencyId,
  listVmProductGroupByScDependencyId,
} from "../../actions/index";

// CSS
import "./dispensationRule.css";
import "./addDispensationRules.css";

// Language localization
import Strings from "../../systemVariables/languageStrings";
import { FreqUnitNameLocalizationPlural } from "../../Utils/LanguageLocalization/frequencyUnitNameLocalization";

class AddDispensationRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialProps: null,
      soDependencyId: null,
      scDependency: {
        id: null,
        name: null,
      },

      scDispensationRule: {
        id: null,
        stockCustomerId: null,
        name: "",
        description: "",
        isEnabled: 1,
      },

      frequencyUnits: [],
      startDate: null,
      endDate: null,

      soProductList: [],
      vmProductGroupList: [],
      soProductByVmProductGroupList: [],
      soProductsToConfig: [],
      vmProductGroupsToConfig: [],

      isChecked: false,
      isLoadingButton: false,
      isLoading: false,

      saveButtonIsDisabled: true,
      disableStartDatePicker: false,
      disableEndDatePicker: true,
    };
  }

  /* --------------------Life cycle----------------------*/
  componentDidMount() {
    const { location, listVwScProductInformationByScDependency, listVmProductGroupByScDependencyId, listFrequencyUnits } = this.props;

    const { scDispensationRule } = this.state;

    if (location.props && location.props.scDependency ? false : true) {
      // Shows No SC Modal Warning
      return this.noScWarning();
    }

    const initialProps = location.props;
    const soDependencyId = location.props.soDependencyId;
    const scDependency = {
      id: location.props.scDependency.id,
      name: location.props.scDependency.name,
    };

    let scDispensationRuleCopy = scDispensationRule;
    scDispensationRuleCopy.stockCustomerId = location.props.scDependency.id;

    listVwScProductInformationByScDependency(location.props.scDependency.id, (response) => {
      this.setState({
        soProductList: response.data.data
          .map((item) => {
            return {
              id: item.id,
              soProductId: item.soProductId,
              soProductName: item.soProductName,
              erpProductCode: item.erpProductCode,
              selectedAmount: 1,
              selectedFrequency: 1,
              selectedFrequencyUnit: null,
            };
          })
          .sort((a, b) => (a.erpProductCode > b.erpProductCode ? 1 : b.erpProductCode > a.erpProductCode ? -1 : 0)),
      });
    });

    listVmProductGroupByScDependencyId(location.props.scDependency.id, (response) => {
      this.setState({
        vmProductGroupList: response.data.data
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
              description: item.description,
              selectedAmount: 1,
              selectedFrequency: 1,
              selectedFrequencyUnit: null,
              isIlimited: false,
            };
          })
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
      });
    });

    // Get all product groups information by stock owner to validate the assignation of products.
    if (soDependencyId !== 0) {
      listSoProductByVmProductGroupBySoDependencyId(soDependencyId).then((value) => {
        this.setState({
          soProductByVmProductGroupList: value.data.data,
          isLoading: false,
        });
      });
    } else {
      listSoProductByVmProductGroupByScDependencyId(scDependency.id).then((value) => {
        this.setState({
          soProductByVmProductGroupList: value.data.data,
          isLoading: false,
        });
      });
    }

    listFrequencyUnits((response) => {
      if (response.data.data) {
        // Allows only HOURS(0), DAYS(1), WEEKS(2), MONTH(3)
        const allowedFrequencyUnitIds = [0, 1, 2, 3];
        const nativeFrequencyUnits = response.data.data
          .filter((item) => allowedFrequencyUnitIds.includes(item.id))
          .map((item) => ({
            value: item.id,
            label: item.name,
          }));

        this.setState({
          frequencyUnits: FreqUnitNameLocalizationPlural(nativeFrequencyUnits),
        });
      }
    });

    const date = moment.utc(moment()).local().format();
    this.setState({
      startDate: `${_.split(date, "T")[0]} 00:00:00`,
      endDate: `${_.split(date, "T")[0]} 00:00:00`,
      isEnabled: false,
      initialProps,
      soDependencyId,
      scDependency,
      scDispensationRule: scDispensationRuleCopy,
    });
  }

  /* -----------------------Events-----------------------*/
  nameInputHandleChange(event) {
    const value = _.trimStart(event.target.value);

    if (value.length <= 45) {
      const { scDispensationRule } = this.state;
      scDispensationRule.name = value;
      this.setState({ scDispensationRule });
      if (value.length > 0) {
        this.setState({ saveButtonIsDisabled: false });
      } else {
        this.setState({ saveButtonIsDisabled: true });
      }
    }
  }

  descriptionInputHandleChange(event) {
    const value = _.trimStart(event.target.value);
    if (value.length <= 45) {
      const { scDispensationRule } = this.state;
      scDispensationRule.description = value;
      this.setState({ scDispensationRule });
    }
  }

  startDateEvent = (date, dateString) => {
    const { isChecked, endDate, startDate } = this.state;
    const startDateLocal = moment(date).format();
    if (isChecked === true) {
      if (endDate < startDateLocal) {
        this.setState({ startDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidStartDateError,
        });
      } else {
        this.setState({ startDate: _.split(startDateLocal, "T")[0] });
      }
    } else {
      this.setState({ startDate: _.split(startDateLocal, "T")[0] });
    }
  };

  endDateEvent = (date, dateString) => {
    const { isChecked, startDate, endDate } = this.state;
    const endDateLocal = moment(date).format();
    if (isChecked === true) {
      if (endDateLocal < startDate) {
        this.setState({ endDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidEndDateError,
        });
      } else {
        this.setState({ endDate: _.split(endDateLocal, "T")[0] });
      }
    } else {
      this.setState({ endDate: _.split(endDateLocal, "T")[0] });
    }
  };

  nullEndDateHandleChange(e) {
    const { disableEndDatePicker, startDate } = this.state;
    const endDateLocal = moment(startDate).format();
    if (e.target.checked === true) {
      this.setState({
        endDate: _.split(endDateLocal, "T")[0],
        disableEndDatePicker: !disableEndDatePicker,
        isChecked: true,
      });
    } else {
      this.setState({
        endDate: _.split(endDateLocal, "T")[0],
        disableEndDatePicker: !disableEndDatePicker,
        isChecked: false,
      });
    }
  }

  noScWarning() {
    Modal.warning({
      title: Strings.soDependency.noDirectClientSelected,
      onOk: this.handleOkNoSCWarning,
      content: Strings.soDependency.noDirectClientSelectedMsg,
    });
  }

  handleOkNoSCWarning = () => {
    this.setState({ redirectBackToDispRules: true });
  };

  addSelectedSoProduct = (row) => ({
    onClick: () => {
      const { soProductByVmProductGroupList, vmProductGroupsToConfig, soProductList, soProductsToConfig } = this.state;
      const soProductByVmProductGroupFilteredBySoProductId = soProductByVmProductGroupList.filter((item) => item.soProductId === row.soProductId);

      /// / Validate if the selected product is associated with any of the selected product groups
      let validationList = [];
      let soProductFlag = false;
      let vmProductGroupName = null;
      if (soProductByVmProductGroupFilteredBySoProductId.length > 0) {
        vmProductGroupsToConfig.forEach((element) => {
          validationList = soProductByVmProductGroupFilteredBySoProductId.filter((item) => item.vmProductGroupId === element.id);
          if (validationList.length > 0) {
            soProductFlag = true;
            vmProductGroupName = element.name;
          }
        });
      }

      if (soProductFlag === true) {
        message.warning(Strings.dispensationRule.selectedProductWarningMsg1 + vmProductGroupName + Strings.dispensationRule.selectedProductWarningMsg2);
      } else {
        this.setState({
          soProductList: soProductList
            .filter((item) => item.soProductId !== row.soProductId)
            .sort((a, b) => (a.erpProductCode > b.erpProductCode ? 1 : b.erpProductCode > a.erpProductCode ? -1 : 0)),
          soProductsToConfig: soProductsToConfig.concat(row),
        });
      }
    },
  });

  addSelectedVmProductGroup = (row) => ({
    onClick: () => {
      const { soProductsToConfig, vmProductGroupsToConfig, soProductByVmProductGroupList, vmProductGroupList } = this.state;
      const soProductByVmProductGroupFilteredByGroupId = soProductByVmProductGroupList.filter((item) => item.vmProductGroupId === row.id);
      /// / Validate if the product group has assigned any of the individually selected products
      let soProductFlag = false;
      let soProductName = null;
      let soProductToConfigFiltered = [];
      if (soProductByVmProductGroupFilteredByGroupId.length > 0) {
        soProductByVmProductGroupFilteredByGroupId.forEach((element) => {
          soProductToConfigFiltered = soProductsToConfig.filter((item) => item.soProductId === element.soProductId);
          if (soProductToConfigFiltered.length > 0) {
            soProductFlag = true;
            soProductName = soProductToConfigFiltered[0].soProductName;
          }
        });
      }

      /// / Validate if a product group containing products in common has already been selected
      let soProductByVmProductGroupFilteredByGroupIdRef = [];
      let soProductByVmProductGroupIntersected = [];
      let vmProductGroupFlag = false;
      let vmProductGroupToConfigFiltered = null;
      let vmProductGroupId = null;
      if (vmProductGroupsToConfig.length > 0) {
        vmProductGroupsToConfig.forEach((element) => {
          soProductByVmProductGroupFilteredByGroupIdRef = soProductByVmProductGroupList.filter((item) => item.vmProductGroupId === element.id);
          soProductByVmProductGroupIntersected = _.intersectionBy(soProductByVmProductGroupFilteredByGroupIdRef, soProductByVmProductGroupFilteredByGroupId, "soProductId");
          if (soProductByVmProductGroupIntersected.length > 0) {
            vmProductGroupFlag = true;
            vmProductGroupId = soProductByVmProductGroupIntersected[0].vmProductGroupId;
            vmProductGroupToConfigFiltered = vmProductGroupsToConfig.filter((item) => item.id === vmProductGroupId);
          }
        });
      }
      if (soProductFlag === true) {
        message.warning(Strings.dispensationRule.selectedVmProductGroupWarningMsg1 + soProductName + Strings.dispensationRule.selectedVmProductGroupWarningMsg2);
      }
      if (vmProductGroupFlag === true) {
        message.warning(`${Strings.dispensationRule.selectedVmProductGroupWarningMsg3} (${vmProductGroupToConfigFiltered[0].name}).`);
      }
      if (soProductFlag !== true && vmProductGroupFlag !== true) {
        this.setState({
          vmProductGroupList: vmProductGroupList.filter((item) => item.id !== row.id).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
          vmProductGroupsToConfig: vmProductGroupsToConfig.concat(row),
        });
      }
    },
  });

  selectHandleEvent(row, name) {
    const { soProductsToConfig, vmProductGroupsToConfig } = this.state;
    switch (name) {
      case "soProductToAdd":
        this.setState({
          soProductsToConfig: soProductsToConfig.map((item) => {
            if (item.soProductId === row.soProductId) {
              item = row;
            }
            return item;
          }),
        });
        break;
      default:
      case "vmProductGroupToAdd":
        this.setState({
          vmProductGroupsToConfig: vmProductGroupsToConfig.map((item) => {
            if (item.id === row.id) {
              item = row;
            }
            return item;
          }),
        });
        break;
    }
  }

  removeHandleEvent(row, name) {
    const { soProductList, soProductsToConfig, vmProductGroupList, vmProductGroupsToConfig } = this.state;
    switch (name) {
      case "soProductToAdd":
        this.setState({
          soProductList: soProductList.concat(row).sort((a, b) => (a.erpProductCode > b.erpProductCode ? 1 : b.erpProductCode > a.erpProductCode ? -1 : 0)),
          soProductsToConfig: soProductsToConfig.filter((item) => item.soProductId !== row.soProductId),
        });
        break;
      default:
      case "vmProductGroupToAdd":
        this.setState({
          vmProductGroupList: vmProductGroupList.concat(row).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
          vmProductGroupsToConfig: vmProductGroupsToConfig.filter((item) => item.id !== row.id),
        });
        break;
    }
  }

  ilimithandleEvent(row, name) {
    const { soProductsToConfig, vmProductGroupsToConfig } = this.state;
    switch (name) {
      case "soProductToAdd":
        this.setState({
          soProductsToConfig: soProductsToConfig.map((item) => {
            if (item.soProductId === row.soProductId) {
              item = row;
            }
            return item;
          }),
        });
        break;
      default:
      case "vmProductGroupToAdd":
        this.setState({
          vmProductGroupsToConfig: vmProductGroupsToConfig.map((item) => {
            if (item.id === row.id) {
              item = row;
            }
            return item;
          }),
        });
        break;
    }
  }

  handleOk = () => {
    const { initialProps } = this.state;
    this.setState({
      scDispensationRule: {
        id: null,
        stockCustomerId: initialProps ? initialProps.scDependency.id : null,
        name: "",
        description: "",
        isEnabled: 1,
      },
      soProductList: initialProps
        ? initialProps.scProducts
            .map((item) => {
              return {
                id: item.id,
                soProductId: item.soProductId,
                soProductName: item.soProductName,
                erpProductCode: item.erpProductCode,
                selectedAmount: 1,
                selectedFrequency: 1,
                selectedFrequencyUnit: null,
              };
            })
            .sort((a, b) => (a.erpProductCode > b.erpProductCode ? 1 : b.erpProductCode > a.erpProductCode ? -1 : 0))
        : null,
      vmProductGroupList: initialProps
        ? initialProps.vmProductGroups
            .map((item) => {
              return {
                id: item.id,
                name: item.name,
                description: item.description,
                selectedAmount: 1,
                selectedFrequency: 1,
                selectedFrequencyUnit: null,
              };
            })
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        : null,
      soProductsToConfig: [],
      vmProductGroupsToConfig: [],
      saveButtonIsDisabled: true,
    });
  };

  addScDispensationRuleHandleChange(event) {
    const { soProductsToConfig, vmProductGroupsToConfig, soProductByVmProductGroupList, soProductList } = this.state;
    let msg = null;
    let frequencyUnitflag = false;
    this.setState({ isLoadingButton: true });

    // Validate if there are assignments by product group or individual
    if (soProductsToConfig.length === 0 && vmProductGroupsToConfig.length === 0) {
      this.setState({ isLoadingButton: false });
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.dispensationRule.errorNoProducts,
      });
    }

    // Validate if any individual assignments don't have the frequency unit configured
    if (soProductsToConfig.length > 0) {
      frequencyUnitflag = soProductsToConfig.some((item) => {
        if (item.selectedFrequencyUnit == null) {
          msg = Strings.dispensationRule.editScDispensationRuleErrorMsg1 + item.soProductName;
          return true;
        }
        return false;
      });
    }
    // Validate if any assignments per product group don't have the frequency unit configured
    if (vmProductGroupsToConfig.length > 0 && frequencyUnitflag === false) {
      frequencyUnitflag = vmProductGroupsToConfig.some((item) => {
        if (item.selectedFrequencyUnit == null) {
          msg = Strings.dispensationRule.editScDispensationRuleErrorMsg2 + item.name;
          return true;
        }
      });
    }

    let productAssignmentByRule = [];
    let soProductByGroupsToAsigne = [];

    if (frequencyUnitflag === true) {
      // Show parameter configuration error message
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: msg + Strings.dispensationRule.editScDispensationRuleErrorMsg3,
      });
      this.setState({ isLoadingButton: false });
    } else {
      const { addScDispensationRuleAndProductAssignment } = this.props;
      // Build the list with the objects to insert (individual products)
      if (soProductsToConfig.length > 0) {
        productAssignmentByRule = soProductsToConfig.map((item) => {
          return {
            id: null,
            scDispensationRuleId: null,
            scProductInformationId: item.id,
            vmProductGroupId: null,
            allowedAmount: item.selectedAmount,
            frequencyValue: item.selectedFrequency,
            frequencyUnitId: item.selectedFrequencyUnit.value,
            isEnabled: 1,
          };
        });
      }
      // Build the list with the objects to insert (products by group)
      if (vmProductGroupsToConfig.length > 0) {
        vmProductGroupsToConfig.forEach((row) => {
          soProductByGroupsToAsigne = soProductByVmProductGroupList.filter((item) => item.vmProductGroupId === row.id);

          soProductByGroupsToAsigne.forEach((element) => {
            let scProductInfo = soProductList.filter((product) => product.soProductId === element.soProductId);

            if (scProductInfo != null && scProductInfo.length > 0) {
              scProductInfo = scProductInfo[0];
              const obj = {
                id: null,
                scDispensationRuleId: null,
                scProductInformationId: scProductInfo.id,
                vmProductGroupId: row.id,
                allowedAmount: row.selectedAmount,
                frequencyValue: row.selectedFrequency,
                frequencyUnitId: row.selectedFrequencyUnit.value,
                isEnabled: 1,
              };
              productAssignmentByRule = productAssignmentByRule.concat(obj);
            }
          });
        });
      }

      // Build the DTO with new product assignments (with and without group)
      const { scDispensationRule, startDate, endDate, isChecked } = this.state;
      scDispensationRule.startDate = moment(startDate).format();
      if (isChecked === true) {
        scDispensationRule.endDate = moment(endDate).format();
      } else {
        scDispensationRule.endDate = null;
      }

      const dispensationRuleDTO = {};
      dispensationRuleDTO.scDispensationRule = scDispensationRule;
      dispensationRuleDTO.productAssignmentByRule = productAssignmentByRule;
      // Post Object
      addScDispensationRuleAndProductAssignment(dispensationRuleDTO, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          this.setState({ isLoadingButton: false });
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: `${Strings.generalResponses.saveError} ${Strings.generalResponses.connectionError}`,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          this.setState({ isLoadingButton: false });
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: `${Strings.dispensationRule.alreadyExistsDispensationRuleError} ${dispensationRuleDTO.scDispensationRule.name}.`,
          });
        } else if (response.data && response.data.code === 0) {
          // Successfull entity save
          this.setState({ isLoadingButton: false });

          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.handleOkNoSCWarning,
          });
        } else {
          // Other error
          this.setState({ isLoadingButton: false });
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.serverError,
          });
        }
      });
    }
  }

  render() {
    const {
      redirectBackToDispRules,
      scDependency,
      scDispensationRule,
      startDate,
      disableStartDatePicker,
      isChecked,
      endDate,
      disableEndDatePicker,
      isLoading,
      vmProductGroupList,
      isLoadingButton,
      soProductList,
      soProductsToConfig,
      vmProductGroupsToConfig,
      frequencyUnits,
      saveButtonIsDisabled,
    } = this.state;

    if (redirectBackToDispRules) {
      // Redirects to VMList from No SC Modal Warning
      return <Redirect to="/dispensationRules" />;
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.dispensationRule.addDispensationRule} tooltip={Strings.dispensationRule.addDispensationRuleTooltip} />
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.scDependency.indirectClient} </h3>
            </Divider>
            <div>
              <h3>{scDependency.name}</h3>
            </div>
          </div>
        </div>

        {/* General parameterization of the dispensing rule: name, description and start and end dates */}
        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3> 1. {Strings.generalTerms.generalParameters}</h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <GeneralInput
              text={Strings.generalTerms.name}
              tooltip={Strings.dispensationRule.dispensationRuleNameToolTip}
              placeholder={Strings.generalTerms.name}
              onChange={(e) => this.nameInputHandleChange(e)}
              value={scDispensationRule.name}
              isReq
            />
            <p style={{ float: "right" }}>{scDispensationRule.name.length}/45</p>
          </div>
          <div className="col-md-6">
            <GeneralInput
              text={Strings.generalTerms.description}
              tooltip={Strings.dispensationRule.dispensationRuleDescriptionToolTip}
              placeholder={Strings.generalTerms.description}
              onChange={(e) => this.descriptionInputHandleChange(e)}
              value={scDispensationRule.description}
              isReq={false}
            />
            <p style={{ float: "right" }}>{scDispensationRule.description.length}/45</p>
          </div>
        </div>

        <div className="vertSpe row">
          <div className="col-md-2">
            <TextWithInfoTooltip name={`${Strings.dispensationRule.startDate}: `} tooltip={Strings.dispensationRule.startDateTooltip} />
          </div>
          <div className="col-md-4">
            <DatePicker value={moment(startDate)} onChange={(date, dateString) => this.startDateEvent(date, dateString)} required disabled={disableStartDatePicker} />
          </div>
          <div className="col-md-2">
            <TextWithInfoTooltip name={`${Strings.dispensationRule.endDate}: `} tooltip={Strings.dispensationRule.endDateTooltip} />
          </div>
          <div className="col-md-4">
            <Checkbox checked={isChecked} onChange={(e) => this.nullEndDateHandleChange(e)} />
            &nbsp; &nbsp;
            <DatePicker value={moment(endDate)} onChange={(date, dateString) => this.endDateEvent(date, dateString)} required={false} disabled={disableEndDatePicker} />
          </div>
        </div>

        <div className="vertSpe row">
          <div className="col">
            <Divider orientation="left">
              <h3> 2. {Strings.dispensationRule.soProductAssignmentToDispensationRule}</h3>
            </Divider>
          </div>
        </div>

        {!isLoading ? (
          /* Selection of products or product groups to assign to the rule being created */
          <div className="vertSpace row">
            <div className="vertSpace col-md-6">
              <h3>{Strings.vmProductGroup.availableProductGroups}</h3>
              <div className="smallTable">
                <AvailableVmProductGroupTable availableVmProductGroups={vmProductGroupList} isLoadingButton={isLoadingButton} onRowClick={this.addSelectedVmProductGroup} />
              </div>
            </div>

            <div className="vertSpace col-md-6">
              <h3>{Strings.product.availableProducts}</h3>
              <div className="smallTable">
                <AvailableSoProductTable availableSoProducts={soProductList} isLoadingButton={isLoadingButton} onRowClick={this.addSelectedSoProduct} />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <Loading />
          </div>
        )}

        {/* Setting the rule dispensing parameters: Quantity, frequency, frequency unit (fixed or unlimited).
          It is displayed when a product or group of products is selected. */}
        {soProductsToConfig.length > 0 || vmProductGroupsToConfig.length > 0 ? (
          <div className="vertSpace row">
            <div className="col-12">
              <Divider orientation="left">
                <h3>
                  <TextWithInfoTooltip name={`3. ${Strings.dispensationRule.configProducts}`} tooltip={Strings.dispensationRule.configProductsTooltip} />
                </h3>
              </Divider>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* If at least one of the individual products was selected, populate the configuration table by product */}
        {soProductsToConfig.length > 0 ? (
          <div className="vertSpace row">
            <div className="col-12">
              <h3>{Strings.product.individualConfigBySelectedSoProduct}</h3>
              <SoProductConfigToDispensationRuleTable
                soProductToConfig={soProductsToConfig}
                frequencyUnits={frequencyUnits}
                editSelectedProduct={(row) => this.selectHandleEvent(row, "soProductToAdd")}
                removeSelectedProduct={(row) => this.removeHandleEvent(row, "soProductToAdd")}
                ilimitedProductSelected={(row) => this.ilimithandleEvent(row, "soProductToAdd")}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        {/* If at least one of the product groups was selected, populate the configuration table by group */}
        {vmProductGroupsToConfig.length > 0 ? (
          <div className="vertSpace row">
            <div className="col-12">
              <h3>{Strings.vmProductGroup.configurationBySelectedVmProductGroup}</h3>
              <VmProductGroupConfigToDispensationRuleTable
                vmProductGroupsAssigned={vmProductGroupsToConfig}
                frequencyUnits={frequencyUnits}
                editSelectedProductGroup={(row) => this.selectHandleEvent(row, "vmProductGroupToAdd")}
                removeSelectedVmProductGroup={(row) => this.removeHandleEvent(row, "vmProductGroupToAdd")}
                ilimitedProductGroupSelected={(row) => this.ilimithandleEvent(row, "vmProductGroupToAdd")}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link="/dispensationRules" />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton onClick={(e) => this.addScDispensationRuleHandleChange(e)} loading={isLoadingButton} isDisabled={saveButtonIsDisabled} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vmProductGroup: state.vmProductGroup,
    frequencyUnits: state.frequencyUnits.frequencyUnitsObj,
  };
}

export default connect(mapStateToProps, {
  listVwScProductInformationByScDependency,
  listVmProductGroup,
  listFrequencyUnits,
  listSoProductByVmProductGroupId,
  addScDispensationRuleAndProductAssignment,
  listSoProductByVmProductGroupBySoDependencyId,
  listSoProductByVmProductGroupByScDependencyId,
  listVmProductGroupByScDependencyId,
})(AddDispensationRules);
