//Dependencies
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Divider, Icon} from 'antd';
import { DATE_TIME_FORMAT } from '../../../systemVariables/TimeConfig';
import moment from 'moment';
import TextWithInfoTooltip from '../../GenericComponents/textWithInfoTooltip';
import DispensationRuleDetailsModal from '../../DispensationsRules/dispensationRuleDetailsModal'


// Actions
import {
    getDispensationRuleByUserId,
    listProductAssignmentByDispensationRuleId,
    getScDispensationRuleById
} from '../../../actions';

//Language localization
import Strings from '../../../systemVariables/languageStrings';

const dateFormat = 'DD/MM/YYYY hh:mm:ss a';

let productAssignmentsWithProductGroupId = [];
let productAssignmentsWithoutProductGroupId = [];
let vmProductGroupDispensationRule = [];

class DispensationRulesInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
        userId: this.props.userId,
        dispRulesInfo: [],
        detailsModalIsVisible: false,
    };
  }

  componentDidMount() {
    this.getDispensationRuleByUserId();
  }

  getDispensationRuleByUserId() {
    this.setState({
      loadingInfo: true,
    });
     this.props.getDispensationRuleByUserId(this.state.userId, (response) => {
        this.setState({
            dispRulesInfo : response.data.data,
            scDispRuleId: response.data.data.scDispRuleId,
            loadingInfo: false,
        });
    }); 
  }

  moreInfoDetails = () => {
    //Query all productAssignment info by scDispensationRule identifier
    productAssignmentsWithProductGroupId = [];
    productAssignmentsWithoutProductGroupId = [];
    vmProductGroupDispensationRule = [];

    this.props.listProductAssignmentByDispensationRuleId(this.state.scDispRuleId, response => {
      if (response.data.data.length > 0) {
        productAssignmentsWithProductGroupId = response.data.data.filter(item => item.vmProductGroupId != null);
        productAssignmentsWithoutProductGroupId = response.data.data.filter(item => item.vmProductGroupId == null);

        productAssignmentsWithoutProductGroupId.forEach(obj => {
          if (obj.allowedAmount == null) {
            obj.frequencyValue = '--';
            obj.frequencyUnitId = '--';
            obj.frequencyUnitName = '--';
            obj.allowedAmount = Strings.generalTerms.ilimited;
          } else {
            obj.frequencyValue = obj.frequencyValue;
            obj.frequencyUnitId = obj.frequencyUnitId;
            obj.frequencyUnitName = obj.frequencyUnitName;
            obj.allowedAmount = obj.allowedAmount;
          }
        })

        productAssignmentsWithProductGroupId.forEach(obj => {
          if (vmProductGroupDispensationRule.filter(item => item.name === obj.vmProductGroupName).length === 0) {
            let vmProductGroutInfo = {};
            vmProductGroutInfo.name = obj.vmProductGroupName;
            vmProductGroutInfo.description = obj.vmProductGroupDescription;
            vmProductGroutInfo.allowedAmount = obj.allowedAmount == null ? Strings.generalTerms.ilimited : obj.allowedAmount;
            vmProductGroutInfo.frequencyValue = obj.allowedAmount == null ? '--' : obj.frequencyValue;
            vmProductGroutInfo.frequencyUnitName = obj.allowedAmount == null ? '--' : obj.frequencyUnitName;
            vmProductGroupDispensationRule = vmProductGroupDispensationRule.concat(vmProductGroutInfo);
          };
        });
      }
      if (productAssignmentsWithoutProductGroupId.length > 0 || productAssignmentsWithProductGroupId.length > 0) {
        this.props.getScDispensationRuleById(this.state.scDispRuleId, response => {
          let scDispInfo = {};
          scDispInfo.id = response.data.data.id;
          scDispInfo.name = response.data.data.name;
          scDispInfo.description = response.data.data.description;
          scDispInfo.startDate = moment(response.data.data.startDate).format(dateFormat);
          scDispInfo.endDate = response.data.data.endDate ? moment(response.data.data.endDate).format(dateFormat) : '--';
          this.setState({
            detailsModalIsVisible: true,
            scDispensationRuleInfo: scDispInfo
          })
        })
      }
    });
  }

  setModalVisible = (isVisible) => {
    this.setState({ detailsModalIsVisible: isVisible })
    productAssignmentsWithProductGroupId = [];
    productAssignmentsWithoutProductGroupId = [];
  }

  render() {
    return (

      <Fragment>

        <DispensationRuleDetailsModal
        isVisible={this.state.detailsModalIsVisible}
        setIsVisible={this.setModalVisible}
        scDispRuleRowSelected={this.state.scDispensationRuleInfo}
        //scDependency={this.state.scDependencyName}
        productAssignmentsWithoutProductGroupId={productAssignmentsWithoutProductGroupId}
        productAssignmentsWithProductGroupId={productAssignmentsWithProductGroupId}
        vmProductGroupDispensationRule={vmProductGroupDispensationRule}
      />

          <div className="row">

          <div className='col-xs-12 col-md-12'>
            <Divider orientation="left">
               <TextWithInfoTooltip name={Strings.myAccount.dispensationRulesInfo} tooltip={Strings.dispensationRule.dispensationByUserTooltip}></TextWithInfoTooltip>
            </Divider>

            {this.state.dispRulesInfo && this.state.dispRulesInfo.length > 0 ?                 

                        <div>
                            <div className="row">
                                <div className="col-6 col-md-4">{Strings.generalTerms.name}:</div>
                                <div className="col-6 col-md-2">{this.state.dispRulesInfo.scDispRuleName}</div>
                                <Icon
                                    type="profile"
                                    theme="twoTone"
                                    style={{ cursor: 'pointer', fontSize: 16 }}
                                    className="addEditRemoveButton"
                                    onClick={(e) => this.moreInfoDetails(e)}
                                />
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-4">{Strings.dispensationRule.startDate}:</div>
                                <div className="col-6 col-md-5">{moment(this.state.dispRulesInfo.startDate).format(DATE_TIME_FORMAT)}</div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-4">{Strings.dispensationRule.endDate}:</div>
                                {this.state.dispRulesInfo.endDate ?
                                    <div className="col-6 col-md-5">{moment(this.state.dispRulesInfo.endDate).format(DATE_TIME_FORMAT)}</div>
                                    :
                                    <div className="col-6 col-md-5">{Strings.generalTerms.ilimited}</div>
                                }
                            </div>

                        </div>
                   :
                   <div>
                    <p>{Strings.myAccount.noDispensationRulesInfoText}</p>
                  </div>
                  }
          </div>

        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}
export default connect(mapStateToProps, {
    getDispensationRuleByUserId,
    listProductAssignmentByDispensationRuleId,
    getScDispensationRuleById
})(DispensationRulesInfo);
