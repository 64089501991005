import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Tooltip, Icon, Button } from "antd";
import "./buttons.css";
import Strings from "../../../systemVariables/languageStrings";

export function ReturnButton(props) {
  var isDisabled = false;
  if (props.isDisabled !== null) {
    isDisabled = props.isDisabled;
  }
  return (
    <Fragment>
      <Link
        to={{
          pathname: props.link,
          props: props.props,
        }}
      >
        <Tooltip title={props.tooltip}>
          <Button id="returnButton" onClick={props.onClick} className="button" disabled={isDisabled}>
            <Icon type="left-circle" theme="twoTone" twoToneColor="#eb2f96" />
            <span>{Strings.generalTerms.return}</span>
          </Button>
        </Tooltip>
      </Link>
    </Fragment>
  );
}
