// Dependencies
import React, {Fragment} from 'react';
import {
    Tooltip,
    Icon
} from 'antd'
import { SelectGeneral } from "../components/GenericComponents/selectComponent/selectGeneral"
import NormalText from '../components/GenericComponents/text/NormalText'

// Language Localization
import Strings from '../systemVariables/languageStrings'

export function ValidateDependency(props) {
    let generalParameters = "";
    const hasOnlySO=!!(props.hasOnlySO);

    // Noatec and has only SO selection
    if ( hasOnlySO === true && props.dependencyType === "so" && props.dependencyId == 1 ){
        generalParameters=
            <div className='vertSpace col-md-6'>
                <SelectGeneral 
                    name={props.soDependencyHtmlTag}
                    text={Strings.soDependency.directClient}
                    tooltip={Strings.soDependency.directClientTooltip}
                    isReq
                    disabled={props.disabledSoDependency}
                    options={props.soDependencies} 
                    defaultValue = {props.soDependencyId}
                    onChange={props.soDependencyHandleChange}
                />
            </div>
    } 
    // Noatec and has So and Sc selection
    if ( hasOnlySO === false && props.dependencyType === "so" && props.dependencyId == 1 ){
        generalParameters=
            <>
                <div className="vertSpace col-md-6">
                    <SelectGeneral 
                        name={props.soDependencyHtmlTag}
                        text={Strings.soDependency.directClient}
                        tooltip={Strings.soDependency.directClientTooltip}
                        isReq
                        disabled={props.disabledSoDependency}
                        options={props.soDependencies} 
                        defaultValue = {props.soDependencyId}
                        onChange={props.soDependencyHandleChange}
                    />
                </div>
                <div className="vertSpace col-md-6">
                    <SelectGeneral 
                        name={props.scDependencyHtmlTag}
                        text={Strings.scDependency.indirectClient} 
                        tooltip = {Strings.scDependency.indirectClientTooltip}
                        isReq
                        disabled={props.disabledScDependency}
                        options={props.scDependencies} 
                        defaultValue = {props.scDependencyId}
                        onChange={props.scDependencyHandleChange}
                    />
                </div>
            </>
    }

    // Different to Noatec, only So selection
    if ( hasOnlySO === true && props.dependencyType === "so" && props.dependencyId != 1 ) {
        generalParameters=
            <div className='vertSpace col-md-6'>
                <NormalText 
                    name = {props.soDependency}
                    text = {Strings.soDependency.directClient}
                    tooltip = {Strings.soDependency.directClientTooltip}
                />
            </div>
    }

    // Different to Noatec, with Sc selection
    if ( hasOnlySO === false && props.dependencyType === "so" && props.dependencyId != 1 ) {
        generalParameters=
            <div className='vertSpace col-md-6'>
                <SelectGeneral 
                    name={props.scDependencyHtmlTag}
                    text={Strings.scDependency.indirectClient} 
                    tooltip = {Strings.scDependency.indirectClientTooltip}
                    isReq
                    disabled = {props.disabledScDependency}
                    options={props.scDependencies} 
                    defaultValue = {props.scDependencyId}
                    onChange={props.scDependencyHandleChange}
                />
            </div>

    }
    // Indirect Client
    if ( hasOnlySO === false && props.dependencyType == "sc" ) {
        generalParameters=
            <div className='vertSpace col-md-6'>
                <NormalText 
                    text = {Strings.scDependency.indirectClient}
                    tooltip = {Strings.scDependency.indirectClientTooltip}
                    name = {props.scDependency}
                />
            </div>
    }
    return generalParameters
}



