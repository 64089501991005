import { 
  LOGIN_INFORMATION,
} from '../actions'
  
export function loginInfo(state = [], action) {
  switch (action.type) {
    case LOGIN_INFORMATION:
      return Object.assign({}, state, {loginObj: action.payload})
    default:
      return state
  }
}