//Dependencies
import { Button, Divider, Icon, Input, Modal, Table } from "antd";
import React, { Component, Fragment } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

//Actions
import { getVmScDependencyUsersByIsAssigned, insertVendingMachineByUser } from "../../../actions";

//Components
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import Titles from "../../GenericComponents/titles.js";

//CSS

//Language localization
import Strings from "../../../systemVariables/languageStrings";

let userToAssignTemporaly = [];
let userToAssign = [];

function mapUserByVM(user, vendingMachineId) {
  let unassignedVendingMachineUsers = {
    userId: user,
    vendingMachineId: vendingMachineId,
  };
  return unassignedVendingMachineUsers;
}

class AssignUsersToVendingMachine extends Component {
  columns = [
    {
      title: Strings.users.identityCardNumber,
      dataIndex: "identityCardNumber",
    },
    {
      title: Strings.generalTerms.name,
      render: (row) => (
        <div>
          <span>
            {row.firstName} {row.middleName} {row.lastName1} {row.lastName2}
          </span>
        </div>
      ),
    },
    {
      title: Strings.scCostCenter.costCenter,
      dataIndex: "scCostCenterName",
    },
    {
      title: Strings.scUserProfile.userProfile,
      dataIndex: "scUserProfileName",
    },
    {
      title: Strings.scDepartment.department,
      dataIndex: "scDepartmentName",
    },
  ];

  // start = () => {
  //   this.setState({ loading: true });  // ajax request after empty completing
  //   setTimeout(() => {
  //     this.setState({
  //       selectedRowKeys: [],
  //       loading: false,
  //     });
  //   }, 1000);
  // }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedRowKeys: [], // Check here to configure the default column
      vendingMachineId: this.props.location.props ? this.props.location.props.vendingMachineId : null,
      vendingMachineName: this.props.location.props ? this.props.location.props.vendingMachineName : null,
      scDependencyId: this.props.location.props ? this.props.location.props.scDependencyId : null,
      scDependencyName: this.props.location.props ? this.props.location.props.scDependencyName : null,
      addButtonDisabled: true,
      isLoading: true,

      //Flag used to redirect to /usersByVendingMachine if any scDependency was selected
      redirectBackToListUsersByVendingMachine: false,
    };

    this.handleOkNoVMIdWarning = this.handleOkNoVMIdWarning.bind(this);
  }

  componentDidMount() {
    this.props.getVmScDependencyUsersByIsAssigned(this.state.vendingMachineId, this.state.scDependencyId, false, (response) => {
      this.setState({
        unassignedVendingMachineUsers: response.data.data,
        isLoading: false,
      });
    });
  }

  //-----------Events------------
  onSelectChange = (selectedRowKeys, selectedRows) => {
    if (selectedRowKeys.length == 0) {
      this.setState({
        addButtonDisabled: true,
      });
    } else {
      this.setState({
        selectedRowKeys,
        addButtonDisabled: false,
      });
    }
    userToAssignTemporaly = selectedRows;
  };

  //reload unassigned users
  handleOk = () => {
    this.setState({
      isLoading: true,
    });
    this.props.getVmScDependencyUsersByIsAssigned(this.state.vendingMachineId, this.state.scDependencyId, false, (response) => {
      this.setState({
        addButtonDisabled: true,
        unassignedVendingMachineUsers: response.data.data,
        isLoading: false,
      });
    });
  };

  assignUserEvent(e) {
    this.setState({
      loading: true,
    });
    userToAssignTemporaly.forEach((elem) => userToAssign.push(mapUserByVM(elem.id, this.state.vendingMachineId)));

    //Validate Object
    if (userToAssign.length > 0) {
      //Post Object
      this.props.insertVendingMachineByUser(userToAssign, (response) => {
        this.setState({ messageResponse: response, loading: false });
        if (!response.data && String(response).includes("Error:")) {
          //Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError + response,
          });
          this.setState({
            loading: false,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          //Successfull entity save
          this.setState({
            unassignedVendingMachineUsers: [],
          });
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.handleOk,
          });
          this.setState({
            loading: false,
            selectedRowKeys: [],
          });
        } else {
          //Other errors
          Modal.error({
            title: Strings.generalResponses.invalidParameters,
            content: Strings.generalResponses.invalidError,
          });
          this.setState({
            loading: false,
          });
        }
      });
    } else {
      //Invalid parameters
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
      this.setState({ loading: false });
    }
    userToAssign = [];
    this.forceUpdate();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[this.state.searchText]} autoEscape textToHighlight={text.toString()} />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  noVmIdWarning() {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOkNoVMIdWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  }
  handleOkNoVMIdWarning() {
    this.setState({ redirectBackToListUsersByVendingMachine: true });
  }

  //Information to render
  render() {
    if (this.state.redirectBackToListUsersByVendingMachine) {
      //Redirects to SoProductSlotType from No Product Modal Warning
      return <Redirect to="/usersByVendingMachine" />;
    }
    if (!this.state.vendingMachineId) {
      //Shows No Product Modal Warning
      return <div> {this.noVmIdWarning()} </div>;
    }
    // const { data } = this.props.unassignedVendingMachineUser;
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <Fragment>
        <Titles title={Strings.UsersByVendingMachine.addUsersByVendingMachine} tooltip={Strings.UsersByVendingMachine.addUsersByVendingMachineTooltip} />

        <div className="vertSpace col-xs-12">
          <div className="col-xs-12 col-md-6">
            <Divider orientation="left">
              <h3>{Strings.scDependency.indirectClient} </h3>
            </Divider>
            <h3>{this.state.scDependencyName}</h3>
          </div>
          <div className="col-xs-12 col-md-6">
            <Divider orientation="left">
              <h3>{Strings.machine.vendingMachine} </h3>
            </Divider>
            <h3>{this.state.vendingMachineName}</h3>
          </div>
        </div>

        <div className="vertSpace col-xs-12" style={{ overflowY: "scroll", height: 500 }}>
          <Table
            columns={this.columns}
            dataSource={this.state.unassignedVendingMachineUsers}
            loading={this.state.isLoading}
            rowSelection={rowSelection}
            pagination={false}
            scroll={{ x: "auto" }}
            size="middle"
          />
        </div>

        <div className="vertSpace col-xs-12">
          <div className="col-xs-0 col-md-6" />
          <div className="col-xs-0 col-md-6">
            <div className="col-xs-6">
              <ReturnButton link="/usersByVendingMachine" />
            </div>
            <div className="col-xs-6">
              <SaveButton onClick={(e) => this.assignUserEvent(e)} loading={this.state.loading} isDisabled={this.state.addButtonDisabled} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    unassignedVendingMachineUser: state.users.unassignedVendingMachineUser,
  };
}

export default connect(mapStateToProps, {
  getVmScDependencyUsersByIsAssigned,
  insertVendingMachineByUser,
})(AssignUsersToVendingMachine);
