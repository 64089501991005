import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/sccostcenters";

export const LIST_COSTCENTERS  = 'LIST_COSTCENTERS';

export function listScCostCentersByScDependencyId(scDependencieId, callback){
  return (dispatch) => {
    axios.get(host+ port + path + version  + resource,{
      params: {
        scdependency_id: scDependencieId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    .then((response) => {
      dispatch( { type: LIST_COSTCENTERS, payload: response.data } );
      callback(response);
    })
    .catch(function (error){
      callback(error);
      return error;
    });
  }
}

export function listScCostCenterByScDependencyIdPaginated (scDependencyId, name, code, page,pageSize,attribute,order,callback){ 
  return () => {
    axios.get(host+ port + path  + version + resource,{
      params: {
        scdependency_id: scDependencyId,
        name: name,
        code: code,
        page: page,
        pageSize: pageSize,
        attribute: attribute,
        order: order
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      return error;
    });
  }
}

export function addScCostCenter(scCostCenterDTO, callback){
  return () => {
    let data = JSON.stringify(scCostCenterDTO)
    axios.post(host + port + path + version + resource, data, {
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return(error);
      });
  }
}

export function editScCostCenter(scCostCenterDTO, callback){
  return () => {
    let data = JSON.stringify(scCostCenterDTO)
    axios(
      {method:'put',
      url : host+ port + path  + version + resource,
      data : data,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      return(error);
    });
  }
}

export function disableScCostCenterById(scCostCenterId, callback){
  return () => {
    let data = JSON.stringify(scCostCenterId)
    axios({
      method: 'delete',
      url: host + port + path + version + resource,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    }).then((response) => {
      callback(response);
    })
    .catch(function (error) {
      return(error);
    });
  }
}
