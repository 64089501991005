import renderEmpty from "antd/lib/config-provider/renderEmpty";
import React, { createContext, useState , useEffect} from "react";

// Language localization
import Strings from "../systemVariables/languageStrings";

export const DataContext = createContext();

export const DataProvider = (props) => {

  const DEFAULT_NUM_SLOTS_ROWS = 8;
  const DEFAULT_NUM_SLOTS_COLS = 8;
  
  const MIN_SLOT_WIDTH = 30;
  const MIN_SLOT_HEIGHT = 30;

  const [planogramInfo, setPlanogramInfo] = useState({
    slots: [],
    slotGroups: [],
    modalities: [],
  });

  // Slot sizes
  const [slotWidthSize, setSlotWidthSize] = useState(null);
  const [slotHeightSize, setSlotHeightSize] = useState(null);

  const [changeLockerConfigMessage, setChangeLockerConfigMessage] = useState("");

  // Slot Drag creation
  const [slotIniRow, setSlotIniRow] = useState(null);
  const [slotIniCol, setSlotIniCol] = useState(null);
  const [slotCurrRow, setSlotCurrRow] = useState(null);
  const [slotCurrCol, setSlotCurrCol] = useState(null);

  // New slot config
  const [slotToConfig, setSlotToConfig] = useState(null);
  const [showSlotConfigModal, setShowSlotConfigModal] = useState(false);

  useEffect(() => {

    let slots = [];
    let planogramInfo = {
      slots: [],
      slotGroups: [],
      modalities: [],
    };

    planogramInfo = props.lockerInfoList && props.lockerInfoList.planogramInfo;

    // If there is no Planogram create a default empty one
    if (planogramInfo.slots.length === 0) {
      planogramInfo.numRows = DEFAULT_NUM_SLOTS_ROWS;
      planogramInfo.numCols = DEFAULT_NUM_SLOTS_COLS;
      for (let r = 0; r < DEFAULT_NUM_SLOTS_ROWS; r++) {
        for (let c = 0; c < DEFAULT_NUM_SLOTS_COLS; c++) {
          slots.push({
            row: r,
            col: c,
            mulWidth: 1,
            mulHeight: 1,
            losckerSlotTypeId: 8,
            lockerType: "EMPTY",
            tray: null,
            columnLId: null,
            slotNum: null,
            statusId: 1,
            trayDenomination: null,
            slotGroup: null,
            slotGroupId: null,
          });
        }
      }
      planogramInfo.slots = slots;
      planogramInfo.modalities = [];

      // Add new slotGroup
      const slotGroup = { id: null, num: 1 /* , motorsControllerId: null */ };
      planogramInfo.slotGroups.push(slotGroup);
    }

    // Add Planogram frame (To avoid multiRow or multiSpan collapsing rows and cols if no slots are present in them)
    for (let r = 0; r < planogramInfo.numRows; r++) {
      // Left frame
      planogramInfo.slots.push({
        row: r,
        col: -1,
        mulWidth: 1,
        mulHeight: 1,
        lockerType: "V_FRAME_L",
      });
    }
    for (let c = -1; c < planogramInfo.numCols; c++) {
      // Top frame
      planogramInfo.slots.push({
        row: -1,
        col: c,
        mulWidth: 1,
        mulHeight: 1,
        lockerType: c === -1 ? "C_FRAME_TL" : "H_FRAME_T",
      });
    }
    for (let r = -1; r < planogramInfo.numRows; r++) {
      // Right frame
      planogramInfo.slots.push({
        row: r,
        col: planogramInfo.numCols,
        mulWidth: 1,
        mulHeight: 1,
        lockerType: r === -1 ? "C_FRAME_TR" : "V_FRAME_R",
      });
    }
    for (let c = -1; c < planogramInfo.numCols + 1; c++) {
      // Bottom frame
      planogramInfo.slots.push({
        row: planogramInfo.numRows,
        col: c,
        mulWidth: 1,
        mulHeight: 1,
        lockerType: c === -1 ? "C_FRAME_BL" : c === planogramInfo.numCols ? "C_FRAME_BR" : "H_FRAME_B",
      });
    }

    // Adjust slot sizes
    let slotWidthSize = planogramInfo.minWidth;
    let slotHeightSize = planogramInfo.minHeight;
    if (planogramInfo.minWidth < MIN_SLOT_WIDTH) {
      slotWidthSize = MIN_SLOT_WIDTH;
      slotHeightSize = Math.ceil(planogramInfo.minHeight * (MIN_SLOT_WIDTH / planogramInfo.minWidth));
    }
    if (planogramInfo.minHeight < MIN_SLOT_HEIGHT) {
      slotHeightSize = MIN_SLOT_HEIGHT;
      slotWidthSize = Math.ceil(planogramInfo.minWidth * (MIN_SLOT_HEIGHT / planogramInfo.minHeight));
    }

    setPlanogramInfo( planogramInfo );
    setSlotWidthSize( slotWidthSize );
    setSlotHeightSize( slotHeightSize);
  }, [props.lockerInfoList.planogramInfo]);

  const addNumRows = () => {
    let { slots } = planogramInfo;
    let newRowsVal = planogramInfo.numRows + 1;
    
    // Move Bottom frame down
    slots.forEach((s) => {
        if (s.lockerType === "H_FRAME_B" || s.lockerType === "C_FRAME_BR" || s.lockerType === "C_FRAME_BL") {
        s.row = newRowsVal;
        }
    });
    for (let r = planogramInfo.numRows; r < newRowsVal; r++) {
        slots.push({
        row: r,
        col: -1,
        mulWidth: 1,
        mulHeight: 1,
        lockerType: "V_FRAME_L",
        });
        slots.push({
        row: r,
        col: planogramInfo.numCols,
        mulWidth: 1,
        mulHeight: 1,
        lockerType: "V_FRAME_R",
        });
        // Add new row
        for (let c = 0; c < planogramInfo.numCols; c++) {
        slots.push({
            columnLId: null,
            row: r,
            col: c,
            mulWidth: 1,
            mulHeight: 1,
            lockerType: "EMPTY",
            statusId: 1,
            trayDenomination: planogramInfo.slots[0].trayDenomination,
            slotGroup: planogramInfo.slotGroups[0].num,
            slotGroupId: planogramInfo.slotGroups[0].id,
            slotNumber: null,
        });
        }
    }
    setPlanogramInfo(planogramInfo => ({
        ...planogramInfo,
        numRows: newRowsVal,
        slots: slots,
    }));
    setChangeLockerConfigMessage("");
  };

  const subtractNumRows = () => {
    let { slots } = planogramInfo;
    let newRowsVal = planogramInfo.numRows - 1;
    setChangeLockerConfigMessage("");

    // Check if only EMPTY slots in rows to delete
    const slotsFound = slots.filter((s) => s.row >= newRowsVal && s.row < planogramInfo.numRows && s.col >= 0 && s.col < planogramInfo.numCols && s.lockerType === "EMPTY");
    if (slotsFound.length === planogramInfo.numCols) {
        // Delete rows
        slots = slots.filter((s) => s.row < newRowsVal || s.row > planogramInfo.numRows - 1);

        // Move bottom frame up
        slots.forEach((s) => {
        if (s.lockerType === "H_FRAME_B" || s.lockerType === "C_FRAME_BR" || s.lockerType === "C_FRAME_BL") {
            s.row = newRowsVal;
            s.statusId = 3;
            // s.vmProductSlotId =
        }
        });

        setPlanogramInfo(planogramInfo => ({
            ...planogramInfo,
            numRows: newRowsVal,
            slots: slots,
        }));
    } else {
        setChangeLockerConfigMessage("Error. Las Filas a eliminar ya se encuentran ocupadas.");
    }
  };

  const addNumCols = () => {
    let { slots } = planogramInfo;
    let newColsVal = planogramInfo.numCols + 1;

    // Add col
    // Move Right frame left
    slots.forEach((s) => {
        if (s.lockerType === "V_FRAME_R" || s.lockerType === "C_FRAME_BR" || s.lockerType === "C_FRAME_TR") {
        s.col = newColsVal;
        }
    });
    for (let c = planogramInfo.numCols; c < newColsVal; c++) {
        slots.push({
        row: -1,
        col: c,
        mulWidth: 1,
        mulHeight: 1,
        lockerType: "H_FRAME_T",
        });
        slots.push({
        row: planogramInfo.numRows,
        col: c,
        mulWidth: 1,
        mulHeight: 1,
        lockerType: "H_FRAME_B",
        });
        // Add new col
        for (let r = 0; r < planogramInfo.numRows; r++) {
        slots.push({
            columnLId: null,
            row: r,
            col: c,
            mulWidth: 1,
            mulHeight: 1,
            lockerType: "EMPTY",
            statusId: 1,
            trayDenomination: planogramInfo.slots[0].trayDenomination,
            slotGroup: planogramInfo.slotGroups[0].num,
            slotGroupId: planogramInfo.slotGroups[0].id,
            slotNumber: null,
        });
        }
    }
    setPlanogramInfo(planogramInfo => ({
            ...planogramInfo,
            numCols: newColsVal,
            slots: slots,
        }));
    setChangeLockerConfigMessage("");
  };

  const subtractNumCols = () => {
    let { slots } = planogramInfo;
    let newColsVal = planogramInfo.numCols - 1;
    setChangeLockerConfigMessage("");

    // Check if only EMPTY slots in cols to delete
    const slotsFound = slots.filter((s) => s.col >= newColsVal && s.col < planogramInfo.numCols && s.row >= 0 && s.row < planogramInfo.numRows && s.lockerType === "EMPTY");

    if (slotsFound.length === planogramInfo.numRows) {
        // Delete cols
        slots = slots.filter((s) => s.col < newColsVal || s.col > planogramInfo.numCols - 1);

        // Move Right frame right
        slots.forEach((s) => {
        if (s.lockerType === "V_FRAME_R" || s.lockerType === "C_FRAME_BR" || s.lockerType === "C_FRAME_TR") {
            s.col = newColsVal;
            s.statusId = 3;
        }
        });
        setPlanogramInfo(planogramInfo => ({
            ...planogramInfo,
            numCols: newColsVal,
            slots: slots,
        }));
    } else {
        setChangeLockerConfigMessage("Error. Las Columnas a eliminar ya se encuentran ocupadas.");
    }
  };

  const changeSlotGroupsNum = (val) => {
    let newSlotGroups = planogramInfo.slotGroups;
    setChangeLockerConfigMessage("");

    // Check only changes
    if (val !== planogramInfo.slotGroups.length) {
        // Add slotGroups
        if (val > planogramInfo.slotGroups.length) {
        for (let i = planogramInfo.slotGroups.length + 1; i <= val; i++) {
            newSlotGroups.push({
            id: null,
            num: i,
            // motorsControllerId: null
            });
        }
        } else {
        // Remove slotGroups
        // Validate not removing slotGroups of existing slots
        if (!planogramInfo.slots.find((s) => s.slotGroup > val)) {
            newSlotGroups = newSlotGroups.filter((s) => s.num <= val);
        } else {
            setChangeLockerConfigMessage(Strings.machine.slotGroupErrorDeleteing);
        }
        }
        setPlanogramInfo(planogramInfo => ({
            ...planogramInfo,
            slotGroups: newSlotGroups
        }));
      }
  };

  const onCloseErrorMsg = () => {
    setChangeLockerConfigMessage("");
  };

  const startSlotCreation = (rc) => {
    let rcSplit = rc.split(",");
    let r = parseInt(rcSplit[0] - 1);
    let c = parseInt(rcSplit[1] - 1);

    setSlotIniRow(r);
    setSlotIniCol(c);
  };

  const dragSlotCreation = (e, rc) => {
    let rcSplit = rc.split(",");
    let currRow = parseInt(rcSplit[0] - 1);
    let currCol = parseInt(rcSplit[1] - 1);

    e.preventDefault();

    let {slots} = planogramInfo;

    let iniRow = slotIniRow;
    let iniCol = slotIniCol;

    // Validate if there is a Slot change in the drag
    let isDragChange = slotCurrCol !== currCol || slotCurrRow !== currRow;

    // Validate if the drag is done foreward
    let isDragForward = iniRow <= currRow && iniCol <= currCol;

    if (isDragChange && isDragForward) {
      // Validate if the drag is inside the Planogram
      const isDragInside = currCol >= 0 && currCol < planogramInfo.numCols && currRow >= 0 && currRow < planogramInfo.numRows;

      // Validate if the drag does not surpass the multipliers
      const isDragSmallerThanMultipliers = currRow - iniRow + 1 <= planogramInfo.maxHeightMultiplier && currCol - iniCol + 1 <= planogramInfo.maxWidhtMultiplier;

      // Validate if the drag only includes "EMPTY", "NEW_SLOT_DRAG", "ERROR_SLOT_DRAG"
      let isDragOnlyIncludesEmpty = true;
      let abort = false;

      for (let r = iniRow; r <= currRow && !abort; r++) {
        for (let c = iniCol; c <= currCol && !abort; c++) {
          if (
            slots.filter((s) => s.row === r && s.col === c && (s.lockerType === "EMPTY" || s.lockerType === "NEW_SLOT_DRAG" || s.lockerType === "ERROR_SLOT_DRAG"))
              .length === 0
          ) {
            isDragOnlyIncludesEmpty = false;
            abort = true;
          }
        }
      }

      if (isDragInside && isDragForward && isDragSmallerThanMultipliers && isDragOnlyIncludesEmpty) {
        // Set all Slots selected under drag as NEW_SLOT_DRAG
        slots.forEach((s) => {
          if (s.row >= iniRow && s.row <= currRow && s.col >= iniCol && s.col <= currCol && (s.lockerType === "EMPTY" || s.lockerType === "NEW_SLOT_DRAG" || "ERROR_SLOT_DRAG")) {
            s.lockerType = "NEW_SLOT_DRAG";
          } else if (s.lockerType === "NEW_SLOT_DRAG" || s.lockerType === "ERROR_SLOT_DRAG") {
            s.lockerType = "EMPTY";
          }
        });
      } else {
        // Set all Slots selected under drag as ERROR_SLOT_DRAG
        slots.forEach((s) => {
          if (
            s.row >= iniRow &&
            s.row <= currRow &&
            s.col >= iniCol &&
            s.col <= currCol &&
            (s.lockerType === "EMPTY" || s.lockerType === "ERROR_SLOT_DRAG" || s.lockerType === "NEW_SLOT_DRAG")
          ) {
            s.lockerType = "ERROR_SLOT_DRAG";
          } else if (s.lockerType === "ERROR_SLOT_DRAG" || s.lockerType === "NEW_SLOT_DRAG") {
            s.lockerType = "EMPTY";
          }
        });
      }

      setSlotCurrCol(currCol);
      setSlotCurrRow(currRow);
      setPlanogramInfo(planogramInfo => ({
        ...planogramInfo,
        slots: slots,
      }));
    }
  };

  const endSlotCreation = () => {

    let {slots} = planogramInfo;

    // Check for no ERROR_SLOT_DRAG and if there is at least one NEW_SLOT_DRAG
    if (slots.filter((s) => s.lockerType === "ERROR_SLOT_DRAG").length === 0 && slots.filter((s) => s.lockerType === "NEW_SLOT_DRAG").length > 0) {
      // Remove Slots enclosed in selection zone NEW_SLOT_DRAG
      slots = slots.filter((s) => !(s.row >= slotIniRow && s.row <= slotCurrRow && s.col >= slotIniCol && s.col <= slotCurrCol));

      // Create new slot as NEW_SLOT
      const newSlot = {
        row: slotIniRow,
        col: slotIniCol,
        mulWidth: slotCurrCol - slotIniCol + 1,
        mulHeight: slotCurrRow - slotIniRow + 1,
        slotGroup: planogramInfo.slotGroups[0].num,
        slotGroupId: planogramInfo.slotGroups[0].id,
        lockerType: "NEW SLOT",
        statusId: 1,
        columnLId: null,
        trayDenomination: null,
        slotNumber: null,
      };
      slots.push(newSlot);
    } else {
      // Change lockerType for ERROR_SLOT_DRAG
      slots.forEach((s) => {
        if (s.lockerType === "ERROR_SLOT_DRAG") {
          s.lockerType = "EMPTY";
        }
      });
    }

    setPlanogramInfo(planogramInfo => ({
      ...planogramInfo,
      slots: slots,
    }));
  };

  const slotCreationUponClick = (rc) => {
    let rcSplit = rc.split(",");
    let currRow = parseInt(rcSplit[0] - 1);
    let currCol = parseInt(rcSplit[1] - 1);

    let {slots} = planogramInfo;

    // Remove Slots enclosed in selection zone NEW_SLOT_DRAG
    slots.forEach((s) => {
      if (s.row === currRow && s.col === currCol && s.lockerType === "EMPTY") {
        s.lockerSlotTypeId = 8;
        s.lockerType = "NEW SLOT";
        s.statusId = s.id ? 2 : 1;
        s.slotGroup = planogramInfo.slotGroups[0].num;
        s.slotGroupId = planogramInfo.slotGroups[0].id;
        s.columnLId = 0;
        s.trayDenomination = 0;
        s.slotNumber = null;
      }
    });

    setPlanogramInfo(planogramInfo => ({
      ...planogramInfo,
      slots: slots,
    }));
  };

  const setSlotInfo = (r, c) => {
    setShowSlotConfigModal( true );
    setSlotToConfig({...planogramInfo.slots.find((s) => s.row === r && s.col === c)});
  };

  const onCloseSlotInfoModal = () => {
    setShowSlotConfigModal( false );
  };

  const changePlanogramInfoFromSlotInfoModal = (planogramInfo) => {
    setPlanogramInfo( ...planogramInfo );
  };

  return <DataContext.Provider value={{planogramInfo, setPlanogramInfo, changeLockerConfigMessage, addNumRows, subtractNumRows, addNumCols, subtractNumCols, changeSlotGroupsNum, onCloseErrorMsg, slotWidthSize, slotHeightSize, startSlotCreation, dragSlotCreation, endSlotCreation, slotCreationUponClick, setSlotInfo, showSlotConfigModal, slotToConfig, setSlotToConfig, onCloseSlotInfoModal, changePlanogramInfoFromSlotInfoModal }}>{props.children}</DataContext.Provider>;
};
