// Dependencies
import { Button, Divider, Icon, Input, Modal, Pagination, Popconfirm, Table } from "antd";
import Column from "antd/lib/table/Column";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// CSS
import "react-datepicker/dist/react-datepicker.css";
import "./scEmployeeInformation.css";

// Actions
import {
  disableScCostCenterById,
  disableScDepartmentById,
  disableScUserProfileById,
  exportScEmployeeInformationReport,
  listScCostCenterByScDependencyIdPaginated,
  listScDepartmentByScDependencyIdPaginated,
  listScDependenciesBySoDependencyId,
  listScUserProfileByScDependencyIdPaginated,
  listSoDependencyBySoDependencyType0,
} from "../../actions";

// Components
import { ValidateDependency } from "../../Utils/validateDependency";
import { ValidatePermissionForComponent } from "../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../Utils/validatePermissionForComponentPart";
import { maxRegistersPerReport } from "../../systemVariables/serverInformation";
import { AddButton, ExportButton } from "../GenericComponents/buttons/index";
import ExportReportsModal from "../GenericComponents/exportReportModal";
import QueryResponse from "../GenericComponents/queryResponse/QueryResponse";
import { SelectGeneral } from "../GenericComponents/selectComponent/index";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";
import Titles from "../GenericComponents/titles";
import CostCenterIcon from "../Global/images/costCenterIcon.png";
import DepartmentIcon from "../Global/images/departmentIcon.png";
import UserProfileIcon from "../Global/images/userProfileIcon.png";
// Language localization
import Strings from "../../systemVariables/languageStrings";

class ListScEmployeeInformation extends Component {
  constructor(props) {
    super(props);
    const { userDependency } = this.props;
    this.state = {
      soDependencyId: 0,
      soDependencyName: null,
      scDependencyId: null,
      scDependencyName: null,
      scInformationList: [],
      soDependenciesState: [],
      structureCategory: [
        { value: 1, label: Strings.scDepartment.department },
        { value: 2, label: Strings.scUserProfile.userProfile },
        { value: 3, label: Strings.scCostCenter.costCenter },
      ],
      categoryId: null,
      categoryName: null,
      categoryDisabled: userDependency.dependencyType !== "sc",
      scDependencyDisabled: true,
      addButtonDisabled: true,
      addButtonToolTip: null,
      isLoading: false,
      redirectRoute: false,
      elementToEdit: {},

      subtitle: "",
      icon: "",
      searchNameText: "",
      searchCodeText: "",

      page: 1,
      pageSize: 10,
      attribute: "name",
      order: "ASC",
      totalElements: 0,

      // Export Report Modal
      isExportModalVisible: false,
      isExceedModalVisible: false,
      exportLoading: false,
    };
    this.showPagination = this.showPagination.bind(this);
    this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
  }

  componentDidMount() {
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      soScSelectionProps,
    } = this.props;
    const { listSoDependencyBySoDependencyType0, listScDependenciesBySoDependencyId, userDependency } = this.props;

    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") === 1) {
      listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
    }
    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") !== 1) {
      const value = sessionStorage.getItem("dependencyId");

      this.setState({
        soDependencyId: userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyId : null,
        soDependencyName: userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyName : null,
        scDependencies: [],
        scDependencyId: null,
        scDependencyName: null,
        scInformationList: [],
        scDependencyDisabled: false,
        categoryDisabled: true,
        subtitle: "",
        addButtonDisabled: true,
        addButtonToolTip: null,
        isLoading: false,
        icon: "",
        elementToEdit: {},
      });

      if (dependencyType === "so" && dependencyId === 1) {
        listSoDependencyBySoDependencyType0(dependencyId, (response) => {
          const initialSoDependencies = [];
          const soDependenciesState = initialSoDependencies.concat(
            response.data.data.map((obj) => {
              return { value: obj.id, label: obj.name };
            })
          );
          this.setState({
            soDependenciesState,
          });
        });
      }
      listScDependenciesBySoDependencyId(value, (response) => {
        this.setState({
          scDependencies: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }
    if (sessionStorage.getItem("dependencyType") === "sc") {
      this.setState({
        scDependencyId: userDependency.dependencyType === "sc" ? userDependency.dependencyId : null,
        scDependencyName: userDependency.dependencyType === "sc" ? userDependency.dependencyName : null,
        subtitle: "",
        addButtonDisabled: true,
        scInformationList: [],
        isLoading: false,
        searchNameText: "",
        searchCodeText: "",
      });
    }
  }

  // -----------Events------------
  selectHandleEvent(event, htmlId) {
    const { scDependencyId, pageSize, attribute, order } = this.state;
    const {
      listScDependenciesBySoDependencyId,
      listScDepartmentByScDependencyIdPaginated,
      listScUserProfileByScDependencyIdPaginated,
      listScCostCenterByScDependencyIdPaginated,
    } = this.props;
    switch (htmlId.name) {
      case "soDependencyType0":
        this.setState({
          soDependencyId: event.value,
          scDependencies: [],
          scDependencyId: null,
          scDependencyDisabled: false,
          addButtonDisabled: true,
          scInformationList: [],
          categoryId: null,
          categoryDisabled: true,
          isLoading: false,
          subtitle: "",
          icon: "",
          elementToEdit: {},
        });
        listScDependenciesBySoDependencyId(event.value, (response) => {
          this.setState({
            scDependencies: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        });
        break;

      case "scDependency":
        this.setState({
          scDependencyId: event.value,
          scDependencyName: event.label,
          scInformationList: [],
          addButtonDisabled: true,
          categoryDisabled: false,
          categoryId: null,
          isLoading: false,
          subtitle: "",
          icon: "",
          elementToEdit: {},
        });
        break;

      case "category":
        this.setState({
          categoryId: event.value,
          categoryName: event.label,
          scInformationList: [],
          addButtonDisabled: false,
          isLoading: true,
          subtitle: "",
          icon: "",
          elementToEdit: {},
          page: 1,
        });

        switch (event.value) {
          case 1: // departments
            this.setState({
              subtitle: Strings.scDepartment.departments,
              icon: DepartmentIcon,
              addButtonToolTip: Strings.scDepartment.addScDepartment,
            });
            listScDepartmentByScDependencyIdPaginated(scDependencyId, "", 1, pageSize, attribute, order, (response) => {
              this.setState({
                scInformationList: response.data.data.content,
                totalElements: response.data.data.totalElements,
                isLoading: false,
              });
            });
            break;
          case 2: // userProfiles
            this.setState({
              subtitle: Strings.scUserProfile.userProfiles,
              icon: UserProfileIcon,
              addButtonToolTip: Strings.scUserProfile.addScUserProfile,
            });
            listScUserProfileByScDependencyIdPaginated(scDependencyId, "", 1, pageSize, attribute, order, (response) => {
              this.setState({
                scInformationList: response.data.data.content,
                totalElements: response.data.data.totalElements,
                isLoading: false,
              });
            });
            break;
          case 3: // costCenters
            this.setState({
              subtitle: Strings.scCostCenter.costCenters,
              icon: CostCenterIcon,
              addButtonToolTip: Strings.scCostCenter.addScCostCenter,
            });
            listScCostCenterByScDependencyIdPaginated(scDependencyId, "", "", 1, pageSize, attribute, order, (response) => {
              this.setState({
                scInformationList: response.data.data.content,
                totalElements: response.data.data.totalElements,
                isLoading: false,
              });
            });
            break;
          default:
        }
        break;
      default:
    }
  }

  handleSearch = (dataIndex, selectedKeys) => {
    const { categoryId, scDependencyId, page, pageSize, attribute, order } = this.state;
    const { listScDepartmentByScDependencyIdPaginated, listScUserProfileByScDependencyIdPaginated, listScCostCenterByScDependencyIdPaginated } = this.props;
    switch (categoryId) {
      case 1: // departments
        listScDepartmentByScDependencyIdPaginated(scDependencyId, selectedKeys[0], page, pageSize, attribute, order, (response) => {
          this.setState({
            scInformationList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            searchNameText: selectedKeys[0],
            searchCodeText: "",
            page: 1,
          });
        });
        break;
      case 2: // userProfiles
        listScUserProfileByScDependencyIdPaginated(scDependencyId, selectedKeys[0], page, pageSize, attribute, order, (response) => {
          this.setState({
            scInformationList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            searchNameText: selectedKeys[0],
            searchCodeText: "",
            page: 1,
          });
        });
        break;
      case 3: // costCenters
        if (dataIndex === "name") {
          listScCostCenterByScDependencyIdPaginated(scDependencyId, selectedKeys[0], "", page, pageSize, attribute, order, (response) => {
            this.setState({
              scInformationList: response.data.data.content,
              totalElements: response.data.data.totalElements,
              isLoading: false,
              searchNameText: selectedKeys[0],
              searchCodeText: "",
              page: 1,
            });
          });
        }
        if (dataIndex === "code") {
          listScCostCenterByScDependencyIdPaginated(scDependencyId, "", selectedKeys[0], page, pageSize, attribute, order, (response) => {
            this.setState({
              scInformationList: response.data.data.content,
              totalElements: response.data.data.totalElements,
              isLoading: false,
              searchNameText: "",
              searchCodeText: selectedKeys[0],
              page: 1,
            });
          });
        }
        break;
      default:
    }
  };

  handleReset = (clearFilters) => {
    const { categoryId, scDependencyId, page, pageSize, attribute, order } = this.state;
    const { listScDepartmentByScDependencyIdPaginated, listScUserProfileByScDependencyIdPaginated, listScCostCenterByScDependencyIdPaginated } = this.props;
    clearFilters();
    this.setState({
      searchNameText: "",
      searchCodeText: "",
    });
    switch (categoryId) {
      case 1: // departments
        listScDepartmentByScDependencyIdPaginated(scDependencyId, "", page, pageSize, attribute, order, (response) => {
          this.setState({
            scInformationList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            page: 1,
          });
        });
        break;
      case 2: // userProfiles
        listScUserProfileByScDependencyIdPaginated(scDependencyId, "", page, pageSize, attribute, order, (response) => {
          this.setState({
            scInformationList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            page: 1,
          });
        });
        break;
      case 3: // costCenters
        listScCostCenterByScDependencyIdPaginated(scDependencyId, "", "", page, pageSize, attribute, order, (response) => {
          this.setState({
            scInformationList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            page: 1,
          });
        });
        break;
      default:
    }
  };

  getColumnSearchProps = (dataIndex, text) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      // eslint-disable-next-line react/jsx-filename-extension
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} by ${text}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(dataIndex, selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(dataIndex, selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  showPagination(page, pageSize) {
    const { categoryId, scDependencyId, attribute, order } = this.state;
    const { listScDepartmentByScDependencyIdPaginated, listScUserProfileByScDependencyIdPaginated, listScCostCenterByScDependencyIdPaginated } = this.props;

    this.setState({
      isLoading: true,
      page,
    });
    switch (categoryId) {
      case 1: // departments
        listScDepartmentByScDependencyIdPaginated(scDependencyId, "", page, pageSize, attribute, order, (response) => {
          this.setState({
            scInformationList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
          });
        });
        break;
      case 2: // userProfiles
        listScUserProfileByScDependencyIdPaginated(scDependencyId, "", page, pageSize, attribute, order, (response) => {
          this.setState({
            scInformationList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
          });
        });
        break;
      case 3: // costCenters
        listScCostCenterByScDependencyIdPaginated(scDependencyId, "", "", page, pageSize, attribute, order, (response) => {
          this.setState({
            scInformationList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
          });
        });
        break;
      default:
    }
  }

  handleDeleteConfirm(row) {
    const { categoryId } = this.state;
    switch (categoryId) {
      case 1: // departments
        this.scDeparmentDeleteEvent(row);
        break;
      case 2: // userProfiles
        this.scUserProfileDeleteEvent(row);
        break;
      case 3: // costCenters
        this.scCostCenterDeleteEvent(row);
        break;
      default:
    }
  }

  handleEditConfirm(row) {
    const { categoryId, scDependencyId } = this.state;

    this.setState({ redirectRoute: true });
    const elementToEdit = {};
    elementToEdit.id = row.id;
    elementToEdit.name = row.name;
    elementToEdit.description = row.description;
    elementToEdit.scDependencyId = scDependencyId;

    if (categoryId === 3) {
      elementToEdit.code = row.code;
    }
    this.setState({ elementToEdit });
  }

  scDeparmentDeleteEvent(row) {
    const { disableScDepartmentById, listScDepartmentByScDependencyIdPaginated } = this.props;
    const { scDependencyId, page, pageSize, attribute, order } = this.state;
    this.setState({ isLoading: true });
    // Validate Object
    disableScDepartmentById(row.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        this.setState({ isLoading: false });
      } else if (response.data.code === 4008) {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({ isLoading: false });
      } else if (response.data.code === 4013) {
        // Assigned as a foreing key
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.keyIsAssigned,
        });
        this.setState({ isLoading: false });
      } else if (response.data && response.data.status === "SUCCESS") {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.successfulDelete,
        });
        listScDepartmentByScDependencyIdPaginated(scDependencyId, "", page, pageSize, attribute, order, (response) => {
          this.setState({
            scInformationList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
          });
        });
      } else {
        // Other error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({ isLoading: false });
      }
    });
  }

  scUserProfileDeleteEvent(row) {
    const { disableScUserProfileById, listScUserProfileByScDependencyIdPaginated } = this.props;
    const { scDependencyId, page, pageSize, attribute, order } = this.state;
    this.setState({ isLoading: true });
    // Validate Object
    disableScUserProfileById(row.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        this.setState({ isLoading: false });
      } else if (response.data.code === 4008) {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({ isLoading: false });
      } else if (response.data.code === 4013) {
        // Assigned as a foreing key
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.keyIsAssigned,
        });
        this.setState({ isLoading: false });
      } else if (response.data && response.data.status === "SUCCESS") {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.successfulDelete,
        });
        listScUserProfileByScDependencyIdPaginated(scDependencyId, "", page, pageSize, attribute, order, (response) => {
          this.setState({
            scInformationList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
          });
        });
      } else {
        // Other error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({ isLoading: false });
      }
    });
  }

  scCostCenterDeleteEvent(row) {
    const { disableScCostCenterById, listScCostCenterByScDependencyIdPaginated } = this.props;
    const { scDependencyId, page, pageSize, attribute, order } = this.state;
    this.setState({ isLoading: true });
    // Validate Object
    disableScCostCenterById(row.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        this.setState({ isLoading: false });
      } else if (response.data.code === 4008) {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({ isLoading: false });
      } else if (response.data.code === 4013) {
        // Assigned as a foreing key
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.keyIsAssigned,
        });
        this.setState({ isLoading: false });
      } else if (response.data && response.data.status === "SUCCESS") {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.successfulDelete,
        });
        listScCostCenterByScDependencyIdPaginated(scDependencyId, "", "", page, pageSize, attribute, order, (response) => {
          this.setState({
            scInformationList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
          });
        });
      } else {
        // Other error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({ isLoading: false });
      }
    });
  }

  // Export methods
  showExportReportsModal = () => {
    const { totalElements } = this.state;
    if (totalElements >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible) => {
    this.setState({
      isExportModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    const { exportLoading, categoryId, soDependencyId, scDependencyId, searchNameText, searchCodeText, attribute, order } = this.state;
    const { exportScEmployeeInformationReport } = this.props;
    if (!exportLoading) {
      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
      const columnHeaders = [Strings.generalTerms.name, Strings.generalTerms.description];

      let categoryInfo = null;
      let title = null;

      switch (categoryId) {
        case 1: // departments
          categoryInfo = "Department";
          title = Strings.scDepartment.scDepartmentReport;
          break;
        case 2: // userProfiles
          categoryInfo = "UserProfile";
          title = Strings.scUserProfile.scUserProfileReport;
          break;
        case 3: // costCenters
          categoryInfo = "CostCenter";
          title = Strings.scCostCenter.scCostCenterReport;
          columnHeaders.push(Strings.generalTerms.code);
          break;
        default:
      }

      // Start loading animation on Export button
      this.setState({ exportLoading: true });

      // Consume report export method
      exportScEmployeeInformationReport(soDependencyId, scDependencyId, categoryInfo, searchNameText, searchCodeText, attribute, order, fileType, columnHeaders, (response) => {
        if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.reportGenerationError,
          });
          this.setState({
            exportLoading: false,
          });
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          const today = new Date();
          link.setAttribute("download", `${title} ${today}.csv`);
          document.body.appendChild(link);
          link.click();
          this.setState({
            exportLoading: false,
            searchNameText: "",
            searchCodeText: "",
          });
        }
      });
    }
  };

  methodRenderTable(subtitle, categoryId, totalElements, scInformationList, isLoading, data, page, scDependencyId, exportLoading) {
    return (
      <div className="row">
        <Divider orientation="left">
          <h2> {subtitle} </h2>
        </Divider>

        {categoryId ? (
          <div className="vertSpace col-12">
            <Table dataSource={totalElements > 0 ? scInformationList : null} bordered loading={isLoading} pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="id">
              <Column
                title={<TextWithInfoTooltip name={Strings.generalTerms.name} tooltip={Strings.scDepartment.scDepartmentNameToolTip} />}
                style={{ width: "35%" }}
                dataIndex="name"
                {...this.getColumnSearchProps("name", Strings.generalTerms.name)}
              />
              {categoryId === 3 ? (
                <Column
                  style={{ width: "35%" }}
                  title={<TextWithInfoTooltip name={Strings.generalTerms.code} tooltip={Strings.scCostCenter.scCostCenterCodeToolTip} />}
                  dataIndex="code"
                  {...this.getColumnSearchProps("code", Strings.generalTerms.code)}
                />
              ) : (
                ""
              )}
              <Column
                style={{ width: "10%" }}
                title={<TextWithInfoTooltip name={Strings.generalTerms.description} tooltip={Strings.scDepartment.scDepartmentDescriptionToolTip} />}
                dataIndex="description"
              />
              {ValidatePermissionForComponentPart("PRIVILEGE SC EMPLOYEE INFORMATION PUT", data) ? (
                <Column
                  title={Strings.generalTerms.edit}
                  style={{ width: "10%" }}
                  align="center"
                  render={(row) => (
                    <div style={{ cursor: "pointer" }}>
                      <Popconfirm
                        title={Strings.scEmployeeInformation.editScEmployeeInformationWarn}
                        onConfirm={() => this.handleEditConfirm(row)}
                        okText={Strings.generalTerms.yes}
                        cancelText={Strings.generalTerms.no}
                      >
                        <Icon type="edit" theme="twoTone" className="addEditRemoveButton" style={{ cursor: "pointer", fontSize: 16 }} />
                      </Popconfirm>
                    </div>
                  )}
                />
              ) : (
                ""
              )}
              {ValidatePermissionForComponentPart("PRIVILEGE SC EMPLOYEE INFORMATION DELETE", data) ? (
                <Column
                  title={Strings.generalTerms.delete}
                  align="center"
                  style={{ width: "10%" }}
                  render={(row) => (
                    <div>
                      <Popconfirm
                        title={Strings.scEmployeeInformation.deleteScEmployeeInformationWarn}
                        onConfirm={() => this.handleDeleteConfirm(row)}
                        okText={Strings.generalTerms.yes}
                        cancelText={Strings.generalTerms.no}
                      >
                        <Icon type="delete" theme="twoTone" className="addEditRemoveButton" style={{ cursor: "pointer", fontSize: 16 }} />
                      </Popconfirm>
                    </div>
                  )}
                />
              ) : (
                ""
              )}
            </Table>
            <div className="col-xs-0 col-lg-6" />
            <div className="vertSpace col-lg-6">
              <Pagination
                size="small"
                total={totalElements}
                showSizeChanger
                onChange={this.showPagination}
                onShowSizeChange={this.showPagination}
                pageSizeOptions={["10", "25", "50", "100", "250"]}
                showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total} ${Strings.generalTerms.items}`}
                current={page}
                disabled={!(totalElements > 0)}
              />
            </div>
            <ValidatePermissionForComponent permission="PRIVILEGE SC EMPLOYEE INFORMATION REPORT GET" permissions={data}>
              <div className="row">
                <div className="vertSpace col-12">
                  <div className="col-xs-12 col-lg-6" />
                  <div className="col-xs-12 col-lg-6">
                    <ExportButton isDisabled={!scDependencyId} onClick={() => this.showExportReportsModal()} loading={exportLoading} />
                  </div>
                </div>
              </div>
            </ValidatePermissionForComponent>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    const {
      permissions: { data },
    } = this.props;
    const {
      redirectRoute,
      categoryId,
      categoryName,
      soDependencyId,
      soDependencyName,
      scDependencyId,
      scDependencyName,
      scDependencyDisabled,
      scDependencies,
      structureCategory,
      categoryDisabled,
      icon,
      elementToEdit,
      isExportModalVisible,
      isExceedModalVisible,
      addButtonDisabled,
      addButtonToolTip,
      subtitle,
      totalElements,
      scInformationList,
      isLoading,
      page,
      exportLoading,
      soDependenciesState,
    } = this.state;
    const { soDependencies } = this.props;
    // Redirects to editScEmployInformation
    if (redirectRoute === true) {
      return (
        <Redirect
          to={{
            pathname: "/editScEmployeeInformation",
            props: {
              categoryId,
              categoryName,
              scDependencyId,
              scDependencyName,
              icon,
              elementToEdit,
            },
          }}
        />
      );
    }

    return (
      <div className="content-container">
        <ExportReportsModal
          isExportVisible={isExportModalVisible}
          isExceedVisible={isExceedModalVisible}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
        />

        <div className="row">
          <Titles title={Strings.scDependency.businessConstruct} tooltip={Strings.scDependency.businessConstructTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>
        <div className="row">
          <ValidateDependency
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            soDependencyHtmlTag="soDependencyType0"
            scDependencyHtmlTag="scDependency"
            soDependencyId={soDependencyId}
            soDependency={soDependencyName}
            scDependencyId={scDependencyId}
            scDependency={scDependencyName}
            disabledScDependency={scDependencyDisabled}
            soDependencies={soDependencies.length > 0 ? soDependencies : soDependenciesState}
            scDependencies={scDependencies}
            soDependencyHandleChange={(e, soId) => this.selectHandleEvent(e, soId)}
            scDependencyHandleChange={(e, soId) => this.selectHandleEvent(e, soId)}
          />
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.scDependency.structureCategory}
              tooltip={Strings.scDependency.structureCategoryTooltip}
              options={structureCategory}
              defaultValue={categoryId}
              disabled={categoryDisabled}
              isReq
              name="category"
              onChange={(e, info) => this.selectHandleEvent(e, info)}
            />
          </div>
        </div>

        <ValidatePermissionForComponent permission="PRIVILEGE SC EMPLOYEE INFORMATION POST" permissions={data}>
          <div className="row">
            <div className="vertSpace col-md-6" />
            <div className="vertSpace col-md-6">
              <AddButton
                link={addButtonDisabled ? "" : "/addScEmployeeInformation"}
                tooltip={addButtonToolTip}
                props={
                  addButtonDisabled
                    ? ""
                    : {
                        categoryId,
                        categoryName,
                        scDependencyId,
                        scDependencyName,
                        icon,
                      }
                }
                disabled={addButtonDisabled}
              />
            </div>
          </div>
        </ValidatePermissionForComponent>
        {scInformationList.length === 0 ? <Divider /> : null}
        <QueryResponse
          dataSourceLength={scInformationList.length}
          isLoading={isLoading}
          callback={this.methodRenderTable(subtitle, categoryId, totalElements, scInformationList, isLoading, data, page, scDependencyId, exportLoading)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listScDepartmentByScDependencyIdPaginated,
  listScUserProfileByScDependencyIdPaginated,
  listScCostCenterByScDependencyIdPaginated,
  disableScDepartmentById,
  disableScUserProfileById,
  disableScCostCenterById,
  exportScEmployeeInformationReport,
})(ListScEmployeeInformation);
