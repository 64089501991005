// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import moment from "moment";
import { Modal } from "antd";

// CSS style
import "./components/Global/css/app.css";

import {
  getToken,
  refreshToken,
  listPrivilegesBySystemUser,
  getUserSessionInformation,
  getDependencyBySystemUser,
  getLowerRelationsBySoDependencyId,
  getSoDependencyParentBySoDependencyId,
  getSoDependencyParentByScDependencyId,
} from "./actions";

// Server information
import { host, port, clientId } from "./systemVariables/serverInformation";

// Components
import MenuBar from "./components/Global/MainContent/MenuBar";
import Content from "./components/Global/MainContent/Content";
import { LoadingMainContent } from "./components/GenericComponents/loadingComponent/LoadingMainContent";
import Strings from "./systemVariables/languageStrings";

let chronTime = 0;

const initAuthFlowUrl = `${host + port}/oauth/authorize?response_type=code&client_id=${clientId}`;

class App extends Component {
  componentDidMount() {
    // If Token is not present in the session storage get token
    if (sessionStorage.getItem("access_token") == null) {
      let code = null;
      code = queryString.parse(window.location.search);

      // If Code is not present init authorization flow
      if (code.code) {
        const { getToken } = this.props;
        getToken(code.code, (response) => {
          if (!response || String(response).includes("Error:")) {
            window.location.href = initAuthFlowUrl;
          } else {
            this.userSessionInformation();
          }
        });
      } else {
        window.location.href = initAuthFlowUrl;
      }
    } else {
      this.userSessionInformation();
    }
  }

  // Function to get all the user information
  userSessionInformation = () => {
    const {
      listPrivilegesBySystemUser,
      getUserSessionInformation,
      getDependencyBySystemUser,
      getLowerRelationsBySoDependencyId,
      getSoDependencyParentBySoDependencyId,
      getSoDependencyParentByScDependencyId,
    } = this.props;

    // Get token info
    const accessToken = sessionStorage.getItem("access_token");

    const base64Url = accessToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join("")
    );

    const payload = JSON.parse(jsonPayload);

    const expDateMilliseconds = payload.exp * 1000;
    const dateInMilliseconds = moment().unix() * 1000;

    // Chrone time with which the access token is going to be queried again
    chronTime = expDateMilliseconds - dateInMilliseconds - 800000;

    // Token chrone function
    this.getNewToken();

    getDependencyBySystemUser((response) => {
      if (!response) {
        this.showError();
      } else {
        const { dependencyId } = response.data.data;
        const { dependencyType } = response.data.data;
        const { dependencyTypeId } = response.data.data;

        if (dependencyType === "so") {
          getLowerRelationsBySoDependencyId(dependencyId);
          if (dependencyTypeId !== 0) {
            getSoDependencyParentBySoDependencyId(dependencyId);
          }
        } else {
          getSoDependencyParentByScDependencyId(dependencyId);
        }
      }
    });

    // Get Privileges with the Token
    listPrivilegesBySystemUser((response) => {
      if (!response) {
        this.showError();
      }
    });

    getUserSessionInformation((response) => {
      if (!response) {
        this.showError();
      }
    });
  };

  getNewToken = () => {
    const { refreshToken } = this.props;
    const tokenInterval = setInterval(() => {
      refreshToken((response) => {
        if (!response.data && String(response).includes("Error:")) {
          clearInterval(tokenInterval);
          // Connection error
          Modal.error({
            title: Strings.generalResponses.authError,
            content: Strings.generalResponses.authErrorTooltip,
            onOk() {
              localStorage.clear();
              sessionStorage.clear();
              window.location.reload(false);
            },
          });
        }
      });
    }, chronTime);
  };

  renderMainContent = (children) => (
    <div className="col-12 content">
      <MenuBar style={{ zIndex: 1 }} />
      <Content body={children} style={{ zIndex: -1 }} />
    </div>
  );

  renderLoading = () => {
    return <LoadingMainContent />;
  };

  render() {
    const { children, permissions, userDependency, userInfo, soDependencyParent } = this.props;

    let view = null;
    if (soDependencyParent) {
      view = !(permissions.length !== 0 && userDependency != null && userInfo?.data != null && soDependencyParent != null)
        ? this.renderLoading()
        : this.renderMainContent(children);
    } else {
      view = !(permissions.length !== 0 && userDependency != null && userInfo?.data != null) ? this.renderLoading() : this.renderMainContent(children);
    }

    return <div className="App">{view}</div>;
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
    userDependency: state.dependencyByUser.dependencyObj,
    userInfo: state.users.userSessionObj,
    soDependencies: state.soDependencies,
    soDependencyParent: state.soDependencyParent.soParent,
  };
}

export default connect(mapStateToProps, {
  getToken,
  refreshToken,
  listPrivilegesBySystemUser,
  getUserSessionInformation,
  getDependencyBySystemUser,
  getLowerRelationsBySoDependencyId,
  getSoDependencyParentBySoDependencyId,
  getSoDependencyParentByScDependencyId,
})(App);
