// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Icon, Card, Table, Button } from "antd";
import moment from "moment";
import "moment/min/locales";
import { Chart, Axis, Tooltip, Geom, Legend } from "bizcharts";
import _ from "lodash";

// Actions
import { getTransactionsStatistics } from "../../../actions";

// Components
import Titles from "../../GenericComponents/titles";
import SoScVmDependencySelector from "../../GenericComponents/selectComponent/SoScVmDependencySelector";
import TimeUnitSelector from "../../GenericComponents/TimeUnitSelector";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";
import { moneyToStringFormatter } from "../../../Utils/LanguageLocalization/MoneyFormatter";
import Loading from "../../GenericComponents/loadingComponent/loading";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";

// Language Localization
import Strings from "../../../systemVariables/languageStrings";

const { Column } = Table;

// const hourFormat = "dddd YYYY/MM/DD HH:mm";
// const dayFormat = "dddd YYYY/MM/DD";
const hourFormat = "YYYY/MM/DD HH:mm";
const dayFormat = "YYYY/MM/DD";
const monthFormat = "YYYY/MM";
const yearFormat = "YYYY";

class TransactionsMonitor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Select values
      soDependencyId: -1,
      scDependencyId: -1,
      vendingMachineId: -1,

      // Time values
      startDate: new Date(new Date()).setHours(0, 0, 0),
      endDate: new Date(new Date()).setHours(23, 59, 59),
      selFreqUnit: 0, // 0:Hours, 1:Days, 2:Weeks, 3:Months, 4:Years

      // -----Plot info-----
      // --General vars
      graphSel: 0, // Selected information to show. 0=total dispensations graph, 1=dispensations by VM table, 2=dispensations by Product table
      loadingData: false,
      titleDateFormat: dayFormat,

      // --Total transactions
      transactions: [],
      totalTrans: null,
      totalSales: null,
      histColsTrans: null,

      // --Vm transactions
      vmTransactions: [],
      topVM: null,
      vmSalesMean: 0,

      // --Prod transactions
      productTransactions: [],
      topProduct: null,
    };
  }

  componentDidMount() {
    const {
      soScSelectionProps: { so, sc },
      userDependency: { dependencyId, dependencyType },
    } = this.props;

    if (so !== null) {
      this.setState({
        soDependencyId: so.id,
      });
    } else if (dependencyType === "so") {
      this.setState({
        soDependencyId: dependencyId,
      });
    }
    if (so === null && dependencyType === "so" && dependencyId === 1 && sc === null) {
      this.setState({
        soDependencyId: -1,
      });
    }

    if (sc !== null) {
      // Query SC data here
      this.setState({
        scDependencyId: sc.id,
      });
    }
  }

  // Events
  soDependencyHandleChange = (event) => {
    const { value: soDependencyId } = event;
    this.setState({
      soDependencyId,
      scDependencyId: -1,
      vendingMachineId: -1,
    });
  };

  scDependencyHandleChange = (event) => {
    const { value: scDependencyId } = event;
    const {
      soScSelectionProps: { so },
      userDependency: { dependencyId },
    } = this.props;

    if (scDependencyId === -1) {
      this.setState({
        soDependencyId: so === null ? dependencyId : so.id,
        scDependencyId: -1,
        vendingMachineId: -1,
      });
    } else {
      this.setState({
        soDependencyId: -1,
        scDependencyId,
        vendingMachineId: -1,
      });
    }
  };

  vendingMachineHandleChange = (event) => {
    const { value: vendingMachineId } = event;
    this.setState({
      soDependencyId: -1,
      scDependencyId: -1,
      vendingMachineId,
    });
  };

  setTimeInformation = (startDate, endDate, selFreqUnit) => {
    let dateFormat;
    switch (selFreqUnit) {
      case 0:
        dateFormat = dayFormat;
        break;
      case 1:
        dateFormat = monthFormat;
        break;
      case 2:
        dateFormat = yearFormat;
        break;
      case 3:
        dateFormat = yearFormat;
        break;
      default:
        break;
    }
    this.setState({
      startDate,
      endDate,
      selFreqUnit,
      titleDateFormat: dateFormat,
    });
  };

  graphTransactionStatistics = (soDependencyId, scDependencyId, vendingMachineId, startDate, endDate, selFreqUnit, isRangeSelected) => {
    this.setState({
      loadingData: true,
    });
    const { getTransactionsStatistics } = this.props;
    getTransactionsStatistics(soDependencyId, scDependencyId, vendingMachineId, moment(startDate).format(), moment(endDate).format(), selFreqUnit, "-5", (response) => {
      const queryData = response.data.data;

      // Organize hist cols
      let histColAlias; // Freq unit name
      let setDateStep; // Function used to get the Date for each data sample according a time step given by the freq unit
      let tickFormat; // Chart ticks format
      switch (selFreqUnit) {
        case 0:
          histColAlias = Strings.timeUnits.hour;
          setDateStep = (step) => {
            const iniDate = new Date(startDate);
            const dateStep = moment(iniDate.setHours(iniDate.getHours() + step)).format(hourFormat);
            return dateStep;
          };
          tickFormat = isRangeSelected ? "DD HH:mm" : "HH:mm";
          break;
        case 1:
          histColAlias = Strings.timeUnits.day;
          setDateStep = (step) => {
            const iniDate = new Date(startDate);
            const dateStep = moment(iniDate.setDate(iniDate.getDate() + step)).format(dayFormat);
            return dateStep;
          };
          tickFormat = isRangeSelected ? "MM/DD" : "DD";
          break;
        case 2:
          histColAlias = Strings.timeUnits.week;
          break;
        case 3:
          histColAlias = Strings.timeUnits.month;
          setDateStep = (step) => {
            const iniDate = new Date(startDate);
            const dateStep = moment(iniDate.setMonth(iniDate.getMonth() + step)).format(monthFormat);
            return dateStep;
          };
          tickFormat = isRangeSelected ? "YYYY/MM" : "MM";
          break;
        default:
          break;
      }
      const histColsTrans = {
        transactions: { alias: Strings.transaction.dispensations },
        sales: { alias: Strings.generalTerms.sales },
        time: { alias: histColAlias, type: "time", mask: tickFormat },
      };

      // Organize data for plot
      const transactionsRaw = queryData.transactionsAmount;
      const salesRaw = queryData.sales;
      const transactions = [];
      let totalTrans = 0;
      let totalSales = 0;
      for (let i = 0; i < transactionsRaw.length; i += 1) {
        totalTrans += transactionsRaw[i];
        totalSales += salesRaw[i];
        transactions.push({
          transactions: transactionsRaw[i],
          sales: salesRaw[i],
          time: setDateStep(i),
        });
      }

      // --VM transactions
      let vmTransactions = [];
      // Get sales mean
      let vmSalesMean = 0;
      queryData.vmSales.forEach((element) => {
        vmSalesMean += element;
      });
      vmSalesMean = queryData.vmSales.length !== 0 ? vmSalesMean / queryData.vmSales.length : 0;
      for (let i = 0; i < queryData.vmTransactions.length; i += 1) {
        // Get performance color (Performance measured comparing against the mean value)
        let perfColor;
        if (queryData.vmSales[i] < vmSalesMean / 2) {
          perfColor = "#A10";
        } else if (queryData.vmSales[i] < vmSalesMean) {
          perfColor = "#A81";
        } else if (queryData.vmSales[i] < (3 * vmSalesMean) / 2) {
          perfColor = "#9A1";
        } else {
          perfColor = "#1A1";
        }
        vmTransactions.push({
          vmName: queryData.vmNames[i],
          vmTrans: queryData.vmTransactions[i],
          vmSales: queryData.vmSales[i],
          vmPerformance: queryData.vmSales[i] - vmSalesMean,
          vmPerformanceColor: perfColor,
        });
      }
      vmTransactions = _.orderBy(vmTransactions, ["vmSales", "vmName"], ["desc"]);

      // --Product transactions
      // Get Product transactions and sort
      let prodTransactions = [];
      for (let i = 0; i < queryData.prodTransactions.length; i += 1) {
        prodTransactions.push({
          prodName: queryData.prodNames[i],
          prodCode: queryData.prodCodes[i],
          prodTrans: queryData.prodTransactions[i],
          prodSales: queryData.prodSales[i],
          prodCost: queryData.prodCosts[i],
        });
      }
      prodTransactions = _.orderBy(prodTransactions, ["prodSales", "prodName"], ["desc"]);

      // --Set state variables according graphSel
      this.setState({
        // --Total transactions
        totalTrans,
        totalSales,
        transactions,
        histColsTrans,

        // --VM transactions
        vmTransactions,
        topVM: vmTransactions[0],
        vmSalesMean,

        // --Product transactions
        productTransactions: prodTransactions,
        topProduct: prodTransactions[0],

        loadingData: false,
      });
      const { graphSel } = this.state;
      this.graphChangeHandle(graphSel);
    });
  };

  informationToRender = () => {
    const { graphSel, transactions, histColsTrans, vmTransactions, vendingMachineId, vmSalesMean, productTransactions } = this.state;
    switch (graphSel) {
      case 0:
        return (
          <Chart style={{ width: "100%" }} forceFit data={transactions} scale={histColsTrans} padding={[80, 80, 120, 80]}>
            <Axis name="transactions" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Axis name="time" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Axis name="sales" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Tooltip />
            <Geom
              type="interval"
              position="time*transactions"
              tooltip={[
                "time*transactions",
                (time, transactions) => {
                  return {
                    // Custom tooltip on the title display and so on.
                    name: Strings.transaction.dispensations,
                    title: time,
                    value: transactions,
                  };
                },
              ]}
            />
            <Legend position="bottom" title={null} />
            <Geom
              type="line"
              position="time*sales"
              size={3}
              shape="smooth"
              color="#fdae6b"
              tooltip={[
                "time*sales",
                (time, sales) => {
                  return {
                    // Custom tooltip on the title display and so on.
                    name: Strings.generalTerms.sales,
                    title: time,
                    value: moneyToStringFormatter(sales),
                  };
                },
              ]}
            />
          </Chart>
        );
      case 1:
        return (
          <div style={{ marginTop: "10px", width: "100%" }}>
            <Table dataSource={vmTransactions} bordered pagination={{ pageSize: 10 }} size="middle" style={{ width: "100%" }} scroll={{ x: "auto" }} key="vmName">
              <Column title={Strings.machine.machine} dataIndex="vmName" />
              <Column title={Strings.dispensationReport.dispensations} dataIndex="vmTrans" sorter={(a, b) => a.vmTrans - b.vmTrans} />
              <Column
                title={<TextWithInfoTooltip name={Strings.generalTerms.sales} tooltip={Strings.transactionMonitor.salesValue} />}
                dataIndex="vmSales"
                render={(vmSales) => <span>{moneyToStringFormatter(vmSales)}</span>}
                sorter={(a, b) => a.vmSales - b.vmSales}
                defaultSortOrder="descend"
              />
              {vendingMachineId == null ? (
                <Column
                  title={<TextWithInfoTooltip name={Strings.transactionMonitor.performance} tooltip={Strings.transactionMonitor.performanceTooltip} />}
                  render={(vmInfo) => (
                    <span style={{ color: vmInfo.vmPerformanceColor }}>
                      {vmInfo.vmPerformance > 0
                        ? `+${((100 * vmInfo.vmPerformance) / vmSalesMean).toFixed(2)}% (+${moneyToStringFormatter(vmInfo.vmPerformance)})`
                        : `-${((100 * Math.abs(vmInfo.vmPerformance)) / vmSalesMean).toFixed(2)}% (-${moneyToStringFormatter(Math.abs(vmInfo.vmPerformance))})`}
                    </span>
                  )}
                  sorter={(a, b) => a.vmPerformance - b.vmPerformance}
                  defaultSortOrder="descend"
                />
              ) : null}
            </Table>
          </div>
        );
      case 2:
        return (
          <div style={{ marginTop: "10px", width: "100%" }}>
            <Table dataSource={productTransactions} bordered pagination={{ pageSize: 10 }} size="middle" key="prodName" scroll={{ x: "100%" }}>
              <Column
                title={Strings.product.product}
                dataIndex="prodName"
                render={(prodName) => <span>{window.innerWidth > 600 ? prodName : `${prodName.substring(0, 15)}...`}</span>}
              />
              <Column title={Strings.generalTerms.code} dataIndex="prodCode" />
              <Column
                title={window.innerWidth > 600 ? Strings.dispensationReport.dispensations : Strings.dispensationReport.dispensations.substring(0, 4)}
                dataIndex="prodTrans"
                sorter={(a, b) => a.prodTrans - b.prodTrans}
              />
              <Column
                title={<TextWithInfoTooltip name={Strings.product.price} tooltip={Strings.transactionMonitor.productPriceTooltip} />}
                dataIndex="prodCost"
                render={(prodCost) => <span>{moneyToStringFormatter(prodCost)}</span>}
                sorter={(a, b) => a.prodCost - b.prodCost}
              />
              <Column
                title={<TextWithInfoTooltip name={Strings.generalTerms.sales} tooltip={Strings.transactionMonitor.salesValue} />}
                dataIndex="prodSales"
                render={(prodSales) => <span>{moneyToStringFormatter(prodSales)}</span>}
                sorter={(a, b) => a.prodSales - b.prodSales}
                defaultSortOrder="descend"
              />
            </Table>
          </div>
        );
      default:
        return null;
    }
  };

  graphChangeHandle = (graphSel) => {
    this.setState({
      graphSel,
    });
  };

  onSeeChartButton = () => {
    const { soDependencyId, scDependencyId, vendingMachineId, startDate, endDate, selFreqUnit } = this.state;
    this.graphTransactionStatistics(soDependencyId, scDependencyId, vendingMachineId, startDate, endDate, selFreqUnit);
  };

  methodTransactionMonitor(startDate, titleDateFormat, isRangeSelected, endDate, graphSel, totalTrans, totalSales, vmTransactions, vmSalesMean, topVM, topProduct) {
    return (
      <>
        <div className="col-12">
          <Divider orientation="left" />
        </div>
        <div className="col-12">
          <h2 style={{ color: "#555" }}>
            {`${Strings.transaction.dispensations}  ${moment(startDate).format(titleDateFormat)}`}
            {isRangeSelected ? ` - ${moment(endDate).format(titleDateFormat)}` : ""}
          </h2>
        </div>
        <div className="row" style={{ alignItems: "center", width: "100%" }}>
          <div className="col-md-9">{this.informationToRender()}</div>
          <div className="col-md-3">
            <div className="col">
              <Card
                size="small"
                title={graphSel === 0 ? <b>{Strings.transactionMonitor.totalTransactions}</b> : Strings.transactionMonitor.totalTransactions}
                extra={<Icon type="plus-circle" theme="twoTone" twoToneColor="#477fb7" style={{ fontSize: "20px" }} onClick={() => this.graphChangeHandle(0)} />}
                headStyle={{ backgroundColor: "#97bff7", color: "#444", fontSize: 15 }}
                style={{ marginTop: "5px", fontSize: "13px" }}
              >
                <p>{`${Strings.dispensationReport.dispensations}: ${totalTrans}`}</p>
                <p>{`${Strings.generalTerms.sales}: ${moneyToStringFormatter(totalSales)}`}</p>
                <p>{`${Strings.transactionMonitor.machinesVending}: ${vmTransactions.length}`}</p>
                <p>{`${Strings.transactionMonitor.machineSalesAvg}: ${moneyToStringFormatter(vmSalesMean)}`}</p>
              </Card>
            </div>
            <div className="col">
              <Card
                size="small"
                title={graphSel === 1 ? <b>{Strings.transactionMonitor.topVM}</b> : Strings.transactionMonitor.topVM}
                extra={<Icon type="plus-circle" theme="twoTone" twoToneColor="#50b88e" style={{ fontSize: "20px" }} onClick={() => this.graphChangeHandle(1)} />}
                headStyle={{ backgroundColor: "#b0ecbe", color: "#444", fontSize: 15 }}
                style={{ marginTop: "15px", fontSize: "13px" }}
              >
                <p>{topVM ? topVM.vmName : "---"}</p>
                <p>{`${Strings.dispensationReport.dispensations}: ${topVM ? topVM.vmTrans : "---"}`}</p>
                <span>{`${Strings.generalTerms.sales}: ${topVM ? moneyToStringFormatter(topVM.vmSales) : "---"}`}</span>
              </Card>
            </div>
            <div className="col">
              <Card
                size="small"
                title={graphSel === 2 ? <b>{Strings.transactionMonitor.topProduct}</b> : Strings.transactionMonitor.topProduct}
                extra={<Icon type="plus-circle" theme="twoTone" twoToneColor="#db6f66" style={{ fontSize: "20px" }} onClick={() => this.graphChangeHandle(2)} />}
                headStyle={{ backgroundColor: "#f3afb4", color: "#444", fontSize: 15 }}
                style={{ marginTop: "15px", fontSize: "13px" }}
              >
                <p>{topProduct ? topProduct.prodName : "---"}</p>
                <p>{`${Strings.dispensationReport.dispensations}: ${topProduct ? topProduct.prodTrans : "---"}`}</p>
                <span>{`${Strings.generalTerms.sales}: ${topProduct ? moneyToStringFormatter(topProduct.prodSales) : "---"}`}</span>
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      loadingData,
      isRangeSelected,
      startDate,
      endDate,
      titleDateFormat,
      graphSel,
      totalTrans,
      totalSales,
      vmSalesMean,
      vmTransactions,
      topVM,
      topProduct,
      transactions,
    } = this.state;
    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.transactionMonitor.transactionMonitor} tooltip={Strings.transactionMonitor.transactionMonitorTooltip} />
        </div>

        <div className="vertSpace row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <SoScVmDependencySelector
            onSoDependencyChange={this.soDependencyHandleChange}
            onScDependencyChange={this.scDependencyHandleChange}
            onVendingMachineChange={this.vendingMachineHandleChange}
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            hasSc
            hasVm
            hasSoAll
            hasScAll
          />
        </div>

        <div className="vertSpace row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.transactionMonitor.analysisIntervalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-md-10">
            <TimeUnitSelector setTimeInformation={this.setTimeInformation} rangeSelectionEnabled />
          </div>
          <div className="vertSpace col-md-2">
            <Button onClick={this.onSeeChartButton} loading={loadingData} className="button">
              {Strings.eventType.seeChart}
            </Button>
          </div>
        </div>

        <div className="row" style={{ marginTop: "5px", justifyContent: "center" }}>
          {loadingData ? (
            <Loading />
          ) : (
            <QueryResponse
              callback={this.methodTransactionMonitor(
                startDate,
                titleDateFormat,
                isRangeSelected,
                endDate,
                graphSel,
                totalTrans,
                totalSales,
                vmTransactions,
                vmSalesMean,
                topVM,
                topProduct
              )}
              dataSourceLength={transactions.length}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
    userDependency: state.dependencyByUser.dependencyObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  getTransactionsStatistics,
})(TransactionsMonitor);
