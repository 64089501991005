import React, { useContext } from "react";
import { DataContext } from "../../context/DataContext";

import { Tooltip, Icon, Button, InputNumber } from "antd";

// Language localization
import Strings from "../../systemVariables/languageStrings";

import "./lockerGui.css";

const LockerInventariConf = (props) => {
  const MAX_SLOT_GROUPS = 5;
  const { planogramInfo, addNumRows, subtractNumRows, addNumCols, subtractNumCols, changeSlotGroupsNum } = useContext(DataContext);

  return (
    <div className="col-xs-12 col-md-4">
      <br />
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="aligning">
                <div style={{ margin: "auto" }}>
                  <span>{Strings.generalTerms.rows}:</span>
                  <Tooltip title={Strings.generalTerms.rowTooltip}>
                    <Icon className="icon" type="info-circle" />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="col-6">
              {props.previousPathDisabled ? (
                <div className="col" style={{ margin: "auto" }}>
                  <span>{planogramInfo.numRows}</span>
                </div>
              ) : (
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      margin: "auto",
                      textAlignLast: "end",
                    }}
                  >
                    <span>{planogramInfo.numRows}</span>
                  </div>
                  <div className="col-6" style={{ margin: "auto" }}>
                    <div>
                      <Button onClick={addNumRows} style={styles.button}>
                        <Icon
                          type="plus"
                          style={{
                            display: "contents",
                          }}
                        />
                      </Button>
                    </div>
                    <div>
                      <Button onClick={subtractNumRows} style={styles.button}>
                        <Icon
                          type="minus"
                          style={{
                            display: "contents",
                          }}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <div className="aligning">
                <div style={{ margin: "auto" }}>
                  <span>{Strings.generalTerms.columns}:</span>
                  <Tooltip title={Strings.generalTerms.columnTooltip}>
                    <Icon className="icon" type="info-circle" />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="col-6">
              {props.previousPathDisabled ? (
                <div className="col" style={{ margin: "auto" }}>
                  <span>{planogramInfo.numCols}</span>
                </div>
              ) : (
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      margin: "auto",
                      textAlignLast: "end",
                    }}
                  >
                    <span>{planogramInfo.numCols}</span>
                  </div>
                  <div className="col-6" style={{ margin: "auto" }}>
                    <div>
                      <Button onClick={addNumCols} style={styles.button}>
                        <Icon
                          type="plus"
                          style={{
                            display: "contents",
                          }}
                        />
                      </Button>
                    </div>
                    <div>
                      <Button onClick={subtractNumCols} style={styles.button}>
                        <Icon
                          type="minus"
                          style={{
                            display: "contents",
                          }}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <span>{Strings.machine.slotGroup}: </span>
            </div>
            <div className="col-6">
              <InputNumber
                min={1}
                max={MAX_SLOT_GROUPS}
                value={planogramInfo.slotGroups.length}
                onChange={(val) => changeSlotGroupsNum(val)}
                disabled={props.previousPathDisabled}
              />
            </div>
          </div>
          <br />
          {/* <div className="row">
            <div className="col">
              <span>{Strings.machine.lockerDimensions}</span>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <span>{Strings.product.height}: </span>
            </div>
            <div className="col-6">
              <span>{`${planogramInfo.numRows * planogramInfo.minHeight}cm`}</span>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <span>{Strings.product.width}: </span>
            </div>
            <div className="col-6">
              <span>{`${planogramInfo.numCols * planogramInfo.minWidth}cm`}</span>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <div className="aligning">
                <div style={{ margin: "auto" }}>
                  <span>{Strings.planogram.slotDimension}</span>
                  <Tooltip title={Strings.planogram.slotDimensionTooltip}>
                    <Icon className="icon" type="info-circle" />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <span>{Strings.product.height}: </span>
            </div>
            <div className="col-6">
              <span>{`${planogramInfo.minHeight}cm`}</span>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <span>{Strings.product.width}: </span>
            </div>
            <div className="col-6">
              <span>{`${planogramInfo.minWidth}cm`}</span>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              {changeLockerConfigMessage && <Alert message={changeLockerConfigMessage} type="error" closeText="X" afterClose={onCloseErrorMsg} />}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const styles = {
  button: {
    height: "20px",
    width: "20px",
    padding: "0px",
    border: "0px",
  },
  colorGreen: {
    height: "10px",
  },
};

export default LockerInventariConf;
