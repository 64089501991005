import React from 'react';
import { Divider } from 'antd';

import PersonalData from './PersonalDataComponent.jsx';

// Language localization
import Strings from '../../../systemVariables/languageStrings';

const GeneralConfig = () => {
  return (
    <div className="content-container">
      <div className="row">
        <div className="col">
          <Divider orientation="left">
            <h3>{Strings.generalTerms.myPersonalInformation}</h3>
          </Divider>
        </div>
      </div>
      <PersonalData />
      <div className="row">
        <div className="col">
          <Divider orientation="left" />
        </div>
      </div>
    </div>
  );
};
export default GeneralConfig;
