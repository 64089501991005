import axios from 'axios';
import { host, port, path, version } from '../systemVariables';

const resource = '/transactioncategory';

export function getTransactionCategories(callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        callback(response);
      })
      .catch(error => {
         callback(error);
      });
  };
}