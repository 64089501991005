// Dependencies
import React, { Component, Fragment } from 'react';
import {
    Progress
} from 'antd';

// Language Localization
import Strings from '../../systemVariables/languageStrings'
import TextWithInfoTooltip from '../GenericComponents/textWithInfoTooltip';


class ProductInventory extends Component {

    // Events
    renderProductsAmount() {
        const {products} = this.props;
        const productsAmount = [];

        products.forEach(elem => {
            productsAmount.push(
                <div className='col'>
                    <div className='row'>
                        <p style={{ margin: '0px' }}>{elem.code} - {elem.name}</p>

                        {elem.availabilityPercentage <= 20 &&
                            <Progress
                                strokeColor={{
                                    from: '#bc3f36',
                                    to: '#ff5f56'
                                }}
                                percent={elem.availabilityPercentage}
                                status="active"
                            />
                        }
                        {elem.availabilityPercentage > 20 && elem.availabilityPercentage <= 40 &&
                            <Progress
                                strokeColor={{
                                    from: '#bf8c46',
                                    to: '#ffac56'
                                }}
                                percent={elem.availabilityPercentage}
                                status="active"
                            />
                        }
                        {elem.availabilityPercentage > 40 && elem.availabilityPercentage <= 70 &&
                            <Progress
                                strokeColor={{
                                    from: '#bcbf46',
                                    to: '#fcff56'
                                }}
                                percent={elem.availabilityPercentage}
                                status="active"
                            />
                        }
                        {elem.availabilityPercentage > 70 &&
                            <Progress
                                strokeColor={{
                                    from: '#49a101',
                                    to: '#63da02'
                                }}
                                percent={elem.availabilityPercentage}
                                status="active"
                            />
                        }
                        <div className="col-6">
                            <span>{Strings.machineInventory.amountAvailable}: {elem.amountAvailable}</span>
                        </div>
                        <div className="col-6">
                            <span>{Strings.machineInventory.totalAmount}: {elem.spaces}</span>
                        </div>
                    </div>
                    <hr style={{ margin: '8px 0px' }} />
                </div>
            )
        })
        return productsAmount;
    }


    //Information to render
    render() {
        const { scDependencyId, vmId, products } = this.props

        if (!scDependencyId || !vmId || !products) {
            return ""
        }
        return (
            <Fragment>
                <h3><TextWithInfoTooltip name={Strings.machineInventory.productInventary} tooltip={Strings.machineInventory.productInventaryTooltip} /></h3>
                <br />
                {this.renderProductsAmount()}
            </Fragment>
        );
    }
}

export default (ProductInventory)
