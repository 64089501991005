import { Card, Icon, InputNumber, Select, Tooltip } from "antd";
import React, { Component } from "react";
import Strings from "../../../../systemVariables/languageStrings";

class AdjustmentSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adjustmentType: null,
      amountSelected: 1,
    };
  }

  onInputAmountChange = (value) => {
    this.setState({
      amountSelected: value,
    });
  };

  onInputAmountChange = (value) => {
    this.setState({
      amountSelected: value,
    });
  };

  onSelectAdjustmentType = (value) => {
    const { adjustmentTypes } = this.props;
    const adjustmentType = adjustmentTypes.filter((obj) => obj.id === value);
    this.setState({
      adjustmentType: adjustmentType[0],
    });
  };

  onAddGeneralAdjustment(amountSelected, adjustmentType) {
    const { onAddAdjustmentFromSelect } = this.props;
    onAddAdjustmentFromSelect(amountSelected, adjustmentType);
  }

  render() {
    const { adjustmentType, amountSelected } = this.state;
    const { adjustmentTypes, pendingAmount } = this.props;

    return (
      <div>
        <Card>
          <div className="row align-items-center">
            <div className="col-8 col-md-4" style={{ textAlign: "center" }}>
              <span>{Strings.transaction.discardTransaction}:</span>
              <Tooltip placement="right" title={Strings.transaction.discardTransactionTooltip}>
                <Icon className="icon" type="info-circle" />
              </Tooltip>
            </div>
            <div className="col-4 col-md-2" style={{ textAlign: "center" }}>
              <Tooltip placement="top" title={Strings.transaction.maxValueToAdjust}>
                <InputNumber size="small" style={{ width: "80px" }} min={0} max={pendingAmount} value={amountSelected} onChange={this.onInputAmountChange} />
              </Tooltip>
            </div>
            <div className="col-9 col-md-4" style={{ textAlign: "center" }}>
              <Tooltip placement="top" title={Strings.transaction.adjustmentTypeTooltip}>
                <Select defaultValue={Strings.transaction.adjustmentSelection} style={{ width: "100%", alignItems: "center" }} onChange={this.onSelectAdjustmentType}>
                  <Select.Option value={adjustmentTypes[2].id}>{adjustmentTypes[2].name}</Select.Option>
                  <Select.Option value={adjustmentTypes[3].id}>{adjustmentTypes[3].name}</Select.Option>
                  <Select.Option value={adjustmentTypes[4].id}>{adjustmentTypes[4].name}</Select.Option>
                </Select>
              </Tooltip>
            </div>
            <div className="col-3 col-md-2" style={{ textAlign: "center" }}>
              <Tooltip placement="top" title={Strings.transaction.adjustmentClic}>
                <Icon type="plus-circle" style={{ fontSize: 15, color: "#004173" }} onClick={() => this.onAddGeneralAdjustment(amountSelected, adjustmentType)} />
              </Tooltip>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
export default AdjustmentSelect;
