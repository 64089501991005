import { Icon, Table, Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { DATE_TIME_FORMAT } from "../../../../systemVariables/TimeConfig";
import Strings from "../../../../systemVariables/languageStrings";

const adjustments = ["DISPENSED", "DISPENSATION FAILED", "DISPENSED WITH LAST PUSH"];
const provisioningTypes = ["STOCKED", "OVERSTOCK AMENDMENT", "UNDERSTOCK AMENDMENT"];

let uuidSelected = null;

class AdjustmentTable extends Component {
  columns = [
    {
      title: (
        <div>
          <Tooltip title={Strings.transaction.moreTransactions} placement="topLeft">
            <Icon type="plus-circle" style={{ fontSize: 16 }} />
          </Tooltip>
        </div>
      ),
      key: "More",
      align: "center",
      width: "5%",
      render: (row) => this.renderBringVmTransaction(row),
    },
    {
      title: (
        <div className="row">
          <div className="col text-center">
            <Tooltip arrowPointAtCenter placement="top" title={() => this.renderTooltipType()}>
              {Strings.transaction.transactionType}
            </Tooltip>
          </div>
        </div>
      ),
      key: "productTransactionTypeName",
      align: "center",
      width: "15%",
      render: (row) => (
        <Tooltip arrowPointAtCenter placement="top" title={Strings.transaction.transactionIdentifier + ": " + row.uuid}>
          <span> {row.productTransactionTypeName} </span>
        </Tooltip>
      ),
    },
    {
      title: Strings.product.product,
      dataIndex: "soProductName",
      key: "product",
      align: "center",
      width: "15%",
    },
    {
      title: Strings.planogram.vmSlotPosition,
      dataIndex: "vmSlotNumber",
      key: "vmSlotNumber",
      align: "center",
      width: "10%",
    },
    {
      title: Strings.dispensationRule.amount,
      dataIndex: "amount",
      align: "center",
      key: "amount",
      width: "10%",
    },
    {
      title: Strings.users.user,
      dataIndex: "userName",
      key: "userName",
      align: "center",
      width: "10%",
    },
    {
      title: Strings.dispensationReport.transactionDate,
      key: "transactionDate",
      align: "center",
      width: "20%",
      render: (row) => <span> {moment(row.transactionDate).format(DATE_TIME_FORMAT)} </span>,
    },
    {
      key: "Image",
      align: "center",
      width: "5%",
      render: (row) => this.renderShowProductImage(row),
    },
    {
      title: (
        <div className="row">
          <div className="col text-center">
            <Tooltip arrowPointAtCenter placement="top" title={Strings.transaction.registerDoubleDispensingTooltip}>
              {Strings.transaction.registerDoubleDispensing}
            </Tooltip>
          </div>
        </div>
      ),
      key: "Add",
      align: "center",
      width: "15%",
      render: (row) => this.renderAddTransaction(row),
    },
  ];

  renderTooltipType = () => {
    return (
      <div className="h6">
        <div>{Strings.transactionTypeLocalization.stocked + ": " + Strings.transaction.info.info1}</div>
        <br />
        <div>{Strings.transactionTypeLocalization.understockAmendment + ": " + Strings.transaction.info.info2}</div>
        <br />
        <div>{Strings.transactionTypeLocalization.overstockAmendment + ": " + Strings.transaction.info.info3}</div>
        <br />
        <div>{Strings.transactionTypeLocalization.dispensed + ": " + Strings.transaction.info.info4}</div>
        <br />
        <div>{Strings.transactionTypeLocalization.dispensedWithLastPush + ": " + Strings.transaction.info.info5}</div>
        <br />
        <div>{Strings.transactionTypeLocalization.dispensationFailed + ": " + Strings.transaction.info.info6}</div>
      </div>
    );
  };

  renderBringVmTransaction = (row) => {
    let isRendered = false;
    let isOtherSlotNumber = false;

    if (!provisioningTypes.includes(row.productTransactionTypeName)) {
      const { selectedTransactionToAdjust } = this.props;
      isRendered = true;
      if (row.vmSlotNumber === selectedTransactionToAdjust.vmSlotNumber) {
        isOtherSlotNumber = true;
      }
    }

    if (isRendered) {
      const { onBringOneVmTransactionBefore, onBringOneVmTransactionAfter, onRemoveOneRecordFromAdjustmentTable } = this.props;
      return (
        <div>
          {isOtherSlotNumber ? (
            <div className="row">
              <div className="col-12 text-center">
                <Icon type="up-circle" style={{ fontSize: 15, color: "#004173" }} onClick={() => onBringOneVmTransactionBefore(row)} />
              </div>
              <div className="col-12 text-center">
                <Icon type="down-circle" style={{ fontSize: 15, color: "#004173" }} onClick={() => onBringOneVmTransactionAfter(row)} />
              </div>
            </div>
          ) : (
            <div className="col-12 text-center">
              <Icon type="plus-circle" rotate={45} style={{ fontSize: 15, color: "#004173" }} onClick={() => onRemoveOneRecordFromAdjustmentTable(row.id)} />
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  renderAddTransaction = (row) => {
    const { onAddAdjustmentFromAdjustmentTable } = this.props;
    let isRendered = false;
    if (adjustments.includes(row.productTransactionTypeName)) {
      isRendered = true;
    }
    return (
      <div>
        {isRendered ? (
          <div className="row">
            <div className="col text-center">
              <Icon type="plus-circle" style={{ fontSize: 16, color: "#004173" }} onClick={() => onAddAdjustmentFromAdjustmentTable(row)} />
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  };

  renderShowProductImage = (row) => {
    const { showProductImage } = this.props;
    let isRendered = false;
    if (!provisioningTypes.includes(row.productTransactionTypeName)) {
      isRendered = true;
    }
    return (
      <div>
        {isRendered ? (
          <div className="row">
            <div className="col text-center">
              <Icon type="picture" style={{ fontSize: 16, color: "#004173" }} onClick={() => showProductImage(row)} />
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  };

  renderAdjustmentType = (row) => {
    const { adjustmentTypes } = this.props;
    let isRendered = false;
    if (adjustments.includes(row.productTransactionTypeName)) {
      isRendered = true;
    }
    let adjustmentType;
    if (row.productTransactionTypeName === "DISPENSED" || row.productTransactionTypeName === "DISPENSED WITH LAST PUSH") {
      adjustmentType = adjustmentTypes[0].name;
    } else if (row.productTransactionTypeName === "DISPENSATION FAILED") {
      adjustmentType = adjustmentTypes[1].name;
    }
    return <div>{isRendered ? adjustmentType : <div />}</div>;
  };

  rowClassName = (record, selectedTransactionId) => {
    let className = null;
    if (record.uuid == selectedTransactionId) {
      className = "blue";
    }
    return className;
  };

  onRowClick = (row) => ({
    onDoubleClick: () => {
      const { showProductImage } = this.props;
      showProductImage(row);
    },
  });

  render() {
    const { isLoading, tableData, selectedTransactionId } = this.props;
    return (
      <Table
        className="adjustmentTable"
        style={{ cursor: "pointer" }}
        size="small"
        pagination={{ pageSize: 8, defaultCurrent: 1 }}
        loading={isLoading}
        onRow={this.onRowClick}
        dataSource={tableData}
        columns={this.columns}
        rowClassName={(record) => this.rowClassName(record, selectedTransactionId)}
        scroll={{ x: "auto" }}
      />
    );
  }
}
export default AdjustmentTable;
