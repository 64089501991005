import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/identitycardtypes";

export const LIST_IDENTITYCARDTYPES  = 'LIST_IDENTITYCARDTYPES';

export function listIdentityCardTypes(callback){
  return (dispatch) => {
    axios.get(host+ port + path + version + resource,{
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    .then((response) => {
      dispatch( { type: LIST_IDENTITYCARDTYPES, payload: response.data } );
      callback(response);
    })
    .catch(function (error){
      callback(error)
      return error;
    });
  }
}