import actions from './actions'
//Language localization
import Strings from "../../systemVariables/languageStrings";

const initState = {
   so: null,
   sc: null,
   vm: null,
   scDepartment: null,
   scUserProfile: null,
   userType: {
      label: Strings.userEnrollment.enrolledPlu,
      id: 2
   }
};

export default function (state = initState, action) {
   switch (action.type) {
      case actions.SO_SC_SELECTION:
         return {
            ...state,
            so: action.payload.so,
            sc: action.payload.sc,
            vm: action.payload.vm,
            scDepartment: action.payload.scDepartment,
            scUserProfile: action.payload.scUserProfile,
            userType: action.payload.userType
         };
      default:
         return state;
   }
}











