import axios from "axios";
import { host, port, path, version } from "../../systemVariables/serverInformation"

// Server URL
const resource = "/soproductslottypes";

const actions = {
    FETCH_PRODUCTS_BY_SC: 'FETCH_PRODUCTS_BY_SC',
    RECEIVE_PRODUCTS_BY_SC: 'RECEIVE_PRODUCTS_BY_SC',
    SELECT_ITEM: 'SELECT_ITEM',
    SELECT_VARIATION: 'SELECT_VARIATION',
    UPDATE_MACHINE_PRODUCTS: 'UPDATE_MACHINE_PRODUCTS',

    requestProducts: (sc) => (
        {
            type: actions.FETCH_PRODUCTS_BY_SC,
            sc: sc,
        }
    ),

    receiveProductsBySc: (res) => (
        {
            type: actions.RECEIVE_PRODUCTS_BY_SC,
            data: res
        }
    ),


    selectProduct: (product_id) => (
        {
            type: actions.SELECT_ITEM,
            id: product_id
        }
    ),


    selectVariation: (variation) => (
        {
            type: actions.SELECT_VARIATION,
            variation
        }
    ),

    updateMachineProducts: (products) => (
        {
            type: actions.UPDATE_MACHINE_PRODUCTS,
            products : products
        }
    ),

    fetchProducts: (scDependencyId) => {
        return (dispatch) => {
            dispatch(actions.requestProducts(scDependencyId));
            axios.get(host + port + path + version + resource, {
                    params: {
                        scdependencyid: scDependencyId,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
                    }
                })
                .then((response) => {
                    dispatch(actions.receiveProductsBySc(response.data.data))
                })
                .catch(function (error) {
                    //dispatch( { type: ERROR, payload: error.response } );
                });
        }
    }
};

export default actions;
