import { LIST_USERTRANSACTION } from '../actions'

const userInitialState = {
  userAccountTransactionObj: []
}

export function userTransaction(state = userInitialState, action) {

  switch (action.type) {
    case LIST_USERTRANSACTION:
      return Object.assign({}, state, {userAccountTransactionObj: action.payload})
    default:
      return state
  }
}
