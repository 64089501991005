import axios from "axios";
import {
  host,
  port,
  path,
  version
} from "../systemVariables"

// Method path
const resource = "/vmcontract";

export function addVmContract(vmContract, callback) {
  return () => {
    let data = JSON.stringify(vmContract)
    axios({
        method: 'post',
        url: host + port + path + version + resource,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  }
}