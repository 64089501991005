import axios from "axios";

import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/dispensingfrequencyunits";

export const LIST_FREQUENCYUNITS  = 'LIST_FREQUENCYUNITS';

export function listFrequencyUnits(callback){
  return (dispatch) => {
    axios.get(host+ port + path  + version + resource,{
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      dispatch( { type: LIST_FREQUENCYUNITS, payload: response.data } );
      callback(response);
    })
    .catch(function (error){
      return error;
    });
  }
}