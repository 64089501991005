import { LIST_COSTCENTERS } from '../actions'

const initialState = {
  scCostCentersObj: []
}

export function scCostCenters(state = [], action) {

  switch (action.type) {
    case LIST_COSTCENTERS:
      var scCostCenter = action.payload.data.map(item => ({value:item.id, label:item.name}))
      return scCostCenter;
    default:
      return state
  }
}
