import { LIST_VMSLOTSIZE, ERROR } from '../actions';

const initialState = {
  vmSlotSizeObj: [],
  errorObj: []
}

export function vmSlotSize(state = initialState, action) {
  switch (action.type) {
    case LIST_VMSLOTSIZE:
      return Object.assign({}, state, {vmSlotSizeObj: action.payload.map(item => ({value:item.id, label:item.name}))})
    case ERROR:
      return Object.assign({}, state, {errorObj: action.payload})
    default:
      return state
  }
}