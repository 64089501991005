import { LIST_VMSETUP } from '../actions'

const userInitialState = {
  vmSetupObj: []
}

export function vmSetup(state = userInitialState, action) {

  switch (action.type) {
    case LIST_VMSETUP:
    let res = parseInt(action.payload.data.stringValue)
      return Object.assign({}, state, {vmSetupObj: res})
    default:
      return state
  }
}