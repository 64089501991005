import { Button, Icon, Input, Popconfirm, Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";

import Strings from "../../../systemVariables/languageStrings";

const { Column } = Table;

const getColumnSearchProps = (dataIndex, filter, handleSearch, handleReset) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, filter)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, filter)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
        {Strings.generalTerms.search}
      </Button>
      <Button onClick={handleReset} size="small" style={{ width: 90 }}>
        {Strings.generalTerms.restart}
      </Button>
    </div>
  ),
  filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
});

const ExecutiveUsersTable = (props) => {
  const { dataSource, loading, isProvisioner, handleSearch, handleReset, disableUser } = props;

  const {
    permissions: { data },
  } = props;

  return (
    <Table dataSource={dataSource} loading={loading} bordered pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="id">
      <Column
        align="center"
        style={{ width: "20%" }}
        title={Strings.users.identityCardNumber}
        dataIndex="identityCardNumber"
        {...getColumnSearchProps("identityCardNumber", 1, handleSearch, handleReset)}
      />
      <Column
        align="center"
        style={{ width: "20%" }}
        title={Strings.generalTerms.name}
        render={(row) => (
          <div>
            {row.firstName} {row.middleName}
          </div>
        )}
      />
      <Column
        align="center"
        style={{ width: "20%" }}
        title={Strings.users.lastName}
        render={(row) => (
          <div>
            {row.lastName1} {row.lastName2}
          </div>
        )}
      />
      <Column align="center" style={{ width: "20%" }} title={Strings.users.username} dataIndex="userName" {...getColumnSearchProps("userName", 2, handleSearch, handleReset)} />
      <Column align="center" style={{ width: "10%" }} title={Strings.soDependency.directClient} render={(row) => <div>{row.soDependencyName}</div>} />
      {ValidatePermissionForComponentPart("PRIVILEGE EXECUTIVE USERS PUT", data) && (
        <Column
          title={Strings.generalTerms.edit}
          style={{ width: "5%" }}
          align="center"
          render={(row) => (
            <Link
              to={{
                pathname: "/editExecutiveUser",
                props: {
                  user: row,
                  isProvisioner,
                },
              }}
            >
              <Icon type="edit" theme="twoTone" className="addEditRemoveButton" />
            </Link>
          )}
        />
      )}
      {ValidatePermissionForComponentPart("PRIVILEGE EXECUTIVE USERS DELETE", data) && (
        <Column
          title={Strings.generalTerms.delete}
          style={{ width: "5%" }}
          align="center"
          render={(row) => (
            <Popconfirm
              title={Strings.generalResponses.confirmDeleteMessage}
              onConfirm={() => disableUser(row)}
              okText={Strings.generalTerms.yes}
              cancelText={Strings.generalTerms.no}
            >
              <Icon type="delete" theme="twoTone" className="addEditRemoveButton" />
            </Popconfirm>
          )}
        />
      )}
    </Table>
  );
};

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, null)(ExecutiveUsersTable);
