// Dependencies
import { Button, Divider, Icon, Input, Modal, Pagination, Popconfirm, Popover, Spin, Table } from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import {
  disableSoProduct,
  getAllSoProductTypes,
  listAllCurrencyCodesByCountry,
  listAllProductTaxTypeAction,
  listSoProductsBySoDependencyPaginated,
  listSoProductsReport,
} from "../../../actions";

// ------Components-----
import { maxRegistersPerReport } from "../../../systemVariables/serverInformation";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import { AddButton, ExportButton } from "../../GenericComponents/buttons";
import ExportReportsModal from "../../GenericComponents/exportReportModal";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";
import { SelectGeneral } from "../../GenericComponents/selectComponent/selectGeneral";
import SoScVmDependencySelector from "../../GenericComponents/selectComponent/SoScVmDependencySelector";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";
import Titles from "../../GenericComponents/titles";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { locateSoProductTypeName } from "../../../Utils/LanguageLocalization/soProductTypeLocalization";

const { Column } = Table;

let additionalInfo = [];

class ListSoProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soDependencyId: null,
      soDependencyName: null,
      soCountryName: null,
      soProductTypes: [],
      soProductTypeId: -1,
      isSoProdTypesDisabled: true,
      erpCode: "",
      page: 1,
      pageSize: 10,
      attribute: "id",
      order: "ASC",
      addButtonDisabled: true,
      soProducts: null,
      soProductsCount: 0,
      isTableLoading: false,
      // Export Report Modal
      isExportModalVisible: false,
      isExceedModalVisible: false,
      exportLoading: false,
      searchText: "",
      flag: false,
    };
  }

  componentDidMount() {
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      userDependency: { dependencyName },
      userDependency: { stockOwnerParentId },
      userDependency: { stockOwnerCountryName },
      soScSelectionProps: { so },

      prodTaxType,
      listAllProductTaxTypeAction,
      listAllCurrencyCodesByCountry,
      listSoProductsBySoDependencyPaginated,
      getAllSoProductTypes,
    } = this.props;
    const { soProductTypeId, page, pageSize, attribute, order } = this.state;
    // ---Query Backend if variables are not yet in reducer
    if (!prodTaxType || prodTaxType.length === 0) {
      listAllProductTaxTypeAction();
      listAllCurrencyCodesByCountry(this.props.userDependency.stockOwnerCountryName);
    }

    // StockOwner user different to Noatec
    if (dependencyType === "so" && dependencyId !== 1) {
      this.setState({
        soDependencyId: stockOwnerParentId,
        soDependencyName: dependencyName,
        soCountryName: stockOwnerCountryName,
        isTableLoading: true,
      });
      this.querylistSoProductsBySoDependencyPaginated(listSoProductsBySoDependencyPaginated, stockOwnerParentId, soProductTypeId, page, pageSize, attribute, order);
    } else if (so) {
      this.setState({
        soDependencyId: so.id,
        soDependencyName: so.label,
        soCountryName: so.countryName,
        isTableLoading: true,
      });
      this.querylistSoProductsBySoDependencyPaginated(listSoProductsBySoDependencyPaginated, so.id, soProductTypeId, page, pageSize, attribute, order);
    } else {
      this.setState({
        soDependencyId: stockOwnerParentId,
        soDependencyName: dependencyName,
        soCountryName: stockOwnerCountryName,
        isTableLoading: true,
      });
      this.querylistSoProductsBySoDependencyPaginated(listSoProductsBySoDependencyPaginated, stockOwnerParentId, soProductTypeId, page, pageSize, attribute, order);
    }

    getAllSoProductTypes((response) => {
      if (response.data) {
        const soProductTypes = response.data.data.map((item) => ({
          value: item.id,
          label: locateSoProductTypeName(item.name),
        }));
        soProductTypes.unshift({
          value: -1,
          label: Strings.generalTerms.all,
        });
        this.setState({
          soProductTypes,
        });
      }
    });
  }

  soDependencyHandleChange = (event) => {
    const { value } = event;
    const { listSoProductsBySoDependencyPaginated } = this.props;
    const { pageSize, attribute, order } = this.state;
    this.setState({
      soDependencyId: value,
      soDependencyName: event.label,
      soCountryName: event.countryName,
      soProductTypeId: -1,
      isTableLoading: true,
      isSoProdTypesDisabled: false,
      page: 1,
    });
    listSoProductsBySoDependencyPaginated(value, -1, "", 1, pageSize, attribute, order, (response) => {
      this.setState({
        soProducts: response.data.data.content,
        soProductsCount: response.data.data.totalElements,
        addButtonDisabled: false,
        isTableLoading: false,
        page: 1,
      });
    });
  };

  soProdTypeHandleChange = (event) => {
    const { value } = event;
    const { listSoProductsBySoDependencyPaginated } = this.props;
    const { soDependencyId, pageSize, attribute, order } = this.state;

    this.setState({
      soProductTypeId: value,
      isTableLoading: true,
      page: 1,
    });

    listSoProductsBySoDependencyPaginated(soDependencyId, value, "", 1, pageSize, attribute, order, (response) => {
      this.setState({
        soProducts: response.data.data.content,
        soProductsCount: response.data.data.totalElements,
        page: 1,
        addButtonDisabled: false,
        isTableLoading: false,
      });
    });
  };

  getColumnSearchProps = (dataIndex, searchText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />,
  });

  handleSearch = (selectedKeys) => {
    const { listSoProductsBySoDependencyPaginated } = this.props;
    const { soDependencyId, soProductTypeId, page, pageSize, attribute, order } = this.state;
    if (selectedKeys.length > 0) {
      this.setState({
        isTableLoading: true,
        erpCode: selectedKeys[0],
      });
      listSoProductsBySoDependencyPaginated(soDependencyId, soProductTypeId, selectedKeys[0], page, pageSize, attribute, order, (response) => {
        this.setState({
          soProducts: response.data.data.content,
          soProductsCount: response.data.data.totalElements,
          isTableLoading: false,
        });
      });
    }
  };

  handleReset = (clearFilters) => {
    const { listSoProductsBySoDependencyPaginated } = this.props;
    const { soDependencyId, soProductTypeId, page, pageSize, attribute, order } = this.state;

    clearFilters();
    this.setState({
      isTableLoading: true,
      erpCode: "",
    });
    listSoProductsBySoDependencyPaginated(soDependencyId, soProductTypeId, "", page, pageSize, attribute, order, (response) => {
      this.setState({
        soProducts: response.data.data.content,
        soProductsCount: response.data.data.totalElements,
        isTableLoading: false,
      });
    });
  };

  showPagination = (page, pageSize) => {
    const { listSoProductsBySoDependencyPaginated } = this.props;
    const { soDependencyId, soProductTypeId, erpCode, attribute, order } = this.state;
    this.setState({
      isTableLoading: true,
      page,
      pageSize,
    });
    listSoProductsBySoDependencyPaginated(soDependencyId, soProductTypeId, erpCode, page, pageSize, attribute, order, (response) => {
      this.setState({
        soProducts: response.data.data.content,
        soProductsCount: response.data.data.totalElements,
        isTableLoading: false,
      });
    });
  };

  querylistSoProductsBySoDependencyPaginated(listSoProductsBySoDependencyPaginated, stockOwnerParentId, soProductTypeId, page, pageSize, attribute, order) {
    listSoProductsBySoDependencyPaginated(stockOwnerParentId, soProductTypeId, "", page, pageSize, attribute, order, (response) => {
      if (response.data) {
        this.setState({
          soProducts: response.data.data.content,
          soProductsCount: response.data.data.totalElements,
          addButtonDisabled: false,
          isTableLoading: false,
          isSoProdTypesDisabled: false,
        });
      }
    });
  }

  handleButtonConfirm(event, row) {
    const { disableSoProduct, listSoProductsBySoDependencyPaginated } = this.props;
    const { soDependencyId, soProductTypeId, erpCode, page, pageSize, attribute, order } = this.state;
    disableSoProduct(row.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 0) {
        // Successfully entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
        });
        listSoProductsBySoDependencyPaginated(soDependencyId, soProductTypeId, erpCode, page, pageSize, attribute, order, (response) => {
          this.setState({
            soProducts: response.data.data.content,
            soProductsCount: response.data.data.totalElements,
            isTableLoading: false,
          });
        });
      } else {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsErrorSoproductRepeat,
        });
      }
    });
  }

  // Export methods
  showExportReportsModal = () => {
    const { soProductsCount } = this.state;
    if (soProductsCount >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible, isExceedModalVisible) => {
    this.setState({
      isExportModalVisible,
      isExceedModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    const { listSoProductsReport } = this.props;
    const { exportLoading, soDependencyId, erpCode, attribute, order } = this.state;
    if (!exportLoading) {
      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
      const columnHeaders = [
        Strings.product.erpCode,
        Strings.generalTerms.name,
        Strings.generalTerms.description,
        Strings.generalTerms.details,
        Strings.product.soProductType,
        Strings.product.manufacturingCode,
        Strings.product.preTaxCost,
        Strings.product.preTaxPrice,
        Strings.product.taxValue,
        Strings.product.price,
        Strings.product.currency,
        Strings.product.minAgeRequired,
      ];
      // Start loading animation on Export button
      this.setState({ exportLoading: true });

      // Consume report export method
      listSoProductsReport(soDependencyId, erpCode, attribute, order, fileType, columnHeaders, (response) => {
        if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.reportGenerationError,
          });
          this.setState({
            exportLoading: false,
          });
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", `${Strings.product.soProductReport}.csv`);
          document.body.appendChild(link);
          link.click();
          this.setState({
            exportLoading: false,
          });
        }
      });
    }
  };

  productDetails(row) {
    this.setState({
      flag: false,
    });
    additionalInfo = [];
    additionalInfo.push(
      <div>
        <div className="row">
          <div className="vertSpace col-8">
            <p>
              <TextWithInfoTooltip name={Strings.product.minAgeRequired} tooltip={Strings.product.minAgeRequiredTooltip} />
            </p>
          </div>
          <div className="vertSpace col-4">
            <p aling="right">{row.minAgeRequired ? <span>{`${row.minAgeRequired} ${Strings.timeUnits.years}`}</span> : Strings.users.identityCardType2.none}</p>
          </div>
        </div>
        <div className="row">
          <div className="vertSpace col-1" />
          <p>
            <b>{Strings.generalTerms.details}</b>
          </p>
        </div>
      </div>
    );
    if (row.details !== "") {
      const json = JSON.parse(row.details);
      json.forEach((obj) =>
        additionalInfo.push(
          <div className="row">
            <div className="col-2" />
            <div className="col-6">
              <p>{obj.attribute} </p>
            </div>
            <div className="col-4">
              <p aling="right">{obj.value}</p>
            </div>
          </div>
        )
      );
    } else {
      additionalInfo.push(
        <div className="row">
          <div className="col-2" />
          <div className="col-10">
            <p>{Strings.product.noDetails}</p>
          </div>
        </div>
      );
    }
    this.setState({
      flag: true,
    });
  }

  methodListSoProductsTable(soProducts, isTableLoading, searchText, data, soDependencyName, soCountryName, soProductsCount, page, soDependencyId, exportLoading, flag) {
    return (
      <div>
        <div className="row">
          <div className="col">
            <Table dataSource={soProducts} bordered loading={isTableLoading} pagination={false} size="middle" style={{ width: "100%" }} scroll={{ x: "auto" }} rowKey="id">
              <Column
                title={<TextWithInfoTooltip name={Strings.product.erpCode} tooltip={Strings.product.erpCodeTooltip} />}
                dataIndex="erpProductCode"
                align="center"
                {...this.getColumnSearchProps("erpProductCode", searchText)}
              />
              <Column title={Strings.generalTerms.name} align="center" dataIndex="name" />
              <Column title={Strings.product.soProductType} align="center" dataIndex="soProductType" render={(soProdType) => <span>{locateSoProductTypeName(soProdType)}</span>} />
              <Column
                title={<TextWithInfoTooltip name={Strings.product.soPreTaxPrice} tooltip={Strings.product.soPreTaxPriceTooltip} />}
                align="center"
                render={(row) => <span>{`${row.currencySymbol}${row.preTaxPrice} ${row.currencyCode}`}</span>}
              />
              <Column
                title={<TextWithInfoTooltip name={Strings.product.taxValue} tooltip={Strings.product.soTaxValueTooltip} />}
                align="center"
                dataIndex="producttaxtype"
                render={(producttaxtype) => <span>{producttaxtype.value + `%`}</span>}
              />
              <Column
                title={<TextWithInfoTooltip name={Strings.product.price} tooltip={Strings.product.priceTooltip} />}
                align="center"
                render={(row) => <span>{`${row.currencySymbol}${row.price} ${row.currencyCode}`}</span>}
              />
              <Column
                title={Strings.generalTerms.additionalInfo}
                width="12%"
                align="center"
                render={(row) => (
                  <a style={{ alignContent: "center", color: "#004173", cursor: "pointer" }} onClick={() => this.productDetails(row)}>
                    <Popover
                      style={{ width: 500 }}
                      content={flag ? additionalInfo : <Spin />}
                      trigger="click"
                      placement="left"
                      title={
                        <div>
                          <b>{Strings.generalTerms.additionalInfo}</b>
                        </div>
                      }
                    >
                      <Icon type="profile" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
                    </Popover>
                  </a>
                )}
              />
              {ValidatePermissionForComponentPart("PRIVILEGE SO PRODUCT PUT", data) ? (
                <Column
                  title={Strings.generalTerms.edit}
                  width="5%"
                  align="center"
                  render={(row) => (
                    <Link
                      to={{
                        pathname: "/editSoProduct",
                        props: { soProductId: row.id, soDependencyName, soCountryName },
                      }}
                    >
                      <Icon type="edit" theme="twoTone" className="addEditRemoveButton" />
                    </Link>
                  )}
                />
              ) : null}
              {ValidatePermissionForComponentPart("PRIVILEGE SO PRODUCT DELETE", data) && (
                <Column
                  title={Strings.generalTerms.delete}
                  width="5%"
                  align="center"
                  render={(row) => (
                    <Popconfirm
                      title={Strings.product.editSoProductWarn}
                      onConfirm={(event) => this.handleButtonConfirm(event, row, "delete")}
                      okText={Strings.generalTerms.yes}
                      cancelText={Strings.generalTerms.no}
                    >
                      <Icon type="delete" theme="twoTone" className="addEditRemoveButton" />
                    </Popconfirm>
                  )}
                />
              )}
            </Table>
          </div>

          <div className="vertSpace col-12">
            <div className="col-md-6" />
            <div className="vertSpace col-md-6">
              {soProductsCount > 0 ? (
                <Pagination
                  size="small"
                  total={soProductsCount}
                  showSizeChanger
                  onChange={this.showPagination}
                  onShowSizeChange={this.showPagination}
                  hideOnSinglePage={false}
                  pageSizeOptions={["10", "25", "50", "100", "250"]}
                  showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total}  ${Strings.generalTerms.items}`}
                  current={page}
                  disabled={!soProducts}
                />
              ) : null}
            </div>
          </div>
        </div>

        <ValidatePermissionForComponent permission="PRIVILEGE SO PRODUCT REPORT GET" permissions={data}>
          <div className="row">
            <div className="vertSpace col">
              <div className="col-md-6" />
              <div className="col-md-6">
                <ExportButton isDisabled={soDependencyId == null} onClick={() => this.showExportReportsModal()} loading={exportLoading} />
              </div>
            </div>
          </div>
        </ValidatePermissionForComponent>
      </div>
    );
  }

  render() {
    const {
      soDependencyName,
      soProducts,
      isExportModalVisible,
      isExceedModalVisible,
      soDependencyId,
      soCountryName,
      addButtonDisabled,
      soProductsCount,
      page,
      searchText,
      exportLoading,
      isTableLoading,
      soProductTypes,
      isSoProdTypesDisabled,
      soProductTypeId,
      flag,
    } = this.state;

    const {
      permissions: { data },
    } = this.props;

    return (
      <div className="content-container">
        <ExportReportsModal
          isExportVisible={isExportModalVisible}
          isExceedVisible={isExceedModalVisible}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
        />

        <div className="row">
          <Titles title={Strings.product.soProducts} tooltip={Strings.product.soProductTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <SoScVmDependencySelector onSoDependencyChange={this.soDependencyHandleChange} />

          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.product.soProductType}
              tooltip={Strings.product.soProductTypeTooltip1}
              options={soProductTypes}
              onChange={(e) => this.soProdTypeHandleChange(e)}
              disabled={isSoProdTypesDisabled}
              defaultValue={soProductTypeId}
            />
          </div>

          <div className="vertSpace col-12">
            <div className="col-xs-0 col-md-6" />
            <ValidatePermissionForComponent permission="PRIVILEGE SO PRODUCT POST" permissions={data}>
              <div className="vertSpace col-md-6">
                <AddButton
                  link={addButtonDisabled ? "" : "/addSoProduct"}
                  tooltip={Strings.product.addSoProductTooltip}
                  props={
                    addButtonDisabled
                      ? null
                      : {
                          soDependencyId,
                          soDependencyName,
                          soCountryName,
                        }
                  }
                  disabled={addButtonDisabled}
                />
              </div>
            </ValidatePermissionForComponent>
          </div>
        </div>
        <Divider orientation="left">
          <h3>{Strings.product.soProducts}</h3>
        </Divider>
        <QueryResponse
          dataSourceLength={soProducts === null ? 0 : soProducts.length}
          isLoading={isTableLoading}
          callback={this.methodListSoProductsTable(
            soProducts,
            isTableLoading,
            searchText,
            data,
            soDependencyName,
            soCountryName,
            soProductsCount,
            page,
            soDependencyId,
            exportLoading,
            flag
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDependency: state.dependencyByUser.dependencyObj,
    soDependencyParent: state.soDependencyParent.soParent,
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listSoProductsBySoDependencyPaginated,
  listAllProductTaxTypeAction,
  listAllCurrencyCodesByCountry,
  disableSoProduct,
  listSoProductsReport,
  getAllSoProductTypes,
})(ListSoProducts);
