import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/scdependencytype";

export const LIST_SCDEPENDENCYTYPE  = 'LIST_SCDEPENDENCYTYPE';

export function listScDependencyType(callback){
  return (dispatch) => {
    axios.get(host+ port + path + version  + resource,{
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    .then((response) => {
      dispatch( { type: LIST_SCDEPENDENCYTYPE, payload: response.data } );
      callback(response);
    })
    .catch(function (error){
      return error;
    });
  }
}
