import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/vmstatus";

export const LIST_VMSTATUS = "LIST_VMSTATUS";

export function getPendingRegistersByVm(vmId, callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          machineid: vmId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: LIST_VMSTATUS, payload: response.data });
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}
