import { DISPENSATIONRULE, LIST_DISPENSATIONRULES, LIST_DISPENSATIONRULESTABLE, ERROR } from '../actions'

const initialState = {
  dispensationRuleObj: [],
  dispensationRulesObj: [],
  errorObj: []
}

export function scDispensationRule(state = [], action) {
  switch (action.type) {
    case LIST_DISPENSATIONRULES:
      var dispRules = action.payload.data.map(item => ({value:item.dispensationRuleId, label:item.dispensationRuleName}))
      return dispRules;
    case ERROR:
      return Object.assign({}, state, {errorObj: action.payload})
    default:
      return state
  }
}

export function scDispensationRulesTable(state = initialState, action) {
  switch (action.type) {
    case DISPENSATIONRULE :
      return Object.assign({}, state, {dispensationRuleObj: action.payload})
    case LIST_DISPENSATIONRULESTABLE:
      return Object.assign({}, state, {dispensationRulesObj: action.payload})
    case ERROR:
      return Object.assign({}, state, {errorObj: action.payload})
    default:
      return state
  }
}