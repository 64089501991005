import { combineReducers } from "redux";

import { loginInfo } from "./login.reducer";
import { userSystemPrivileges } from "./privilege.reducer";
import soScSelection from "./soscselection/reducer";
import { user, users } from "./users.reducer";

import { dependencyFilters } from "./dependencyFilters.reducer";

import { city } from "./city.reducer";
import { country } from "./country.reducer";
import { dispRulesByUser } from "./dispensationRulesByUser.reducer";
import { firmwareVersion } from "./firmwareVersions.reducer";
import { identityCardTypes } from "./identityCardType.reducer";
import { noahubKeylist } from "./noahubKeylist.reducer";
import { noahub } from "./peripheral.reducer";
import { province } from "./province.reducer";
import { scDependencies } from "./scDependencies.reducer";
import { scDispensationRule, scDispensationRulesTable } from "./scDispensationRule.reducer";
import { soDependencies, soDependenciesType0, soDependenciesType0WithAllOption, soDependencyParent } from "./soDependency.reducer";
import { userTransaction } from "./userTransaction.reducer";
import { vendingMachines } from "./vendingMachine.reducer";
import { vmKeylist } from "./vmKeylist.reducer";
import { vmModel } from "./vmModel.reducer";
import { vmSetup } from "./vmSetup.reducer";

import { frequencyUnits } from "./frequencyUnit.reducer";
import { helixType } from "./helixType.reducer";
import { motorType } from "./motorType.reducer";
import { productTaxType } from "./productTaxType.reducer";
import { scCostCenters } from "./scCostCenters.reducer";
import { scEmployeeInformation } from "./scEmployeeInformation.reducer";
import { soProduct } from "./soProduct.reducer";
import { soProductSlotType } from "./soProductSlotType.reducer";
import { vmProductGroup } from "./vmProductGroup.reducer";
import { vmSlotPosition } from "./vmSlotPosition.reducer";
import { vmSlotSize } from "./vmSlotSize.reducer";
import { vmTrayPosition } from "./vmTrayPosition.reducer";
//import { soRole } from './soRole.reducer'

import planogramProducts from "../components/Products/Planogram/Components/reducer";
import productsMachine from "./products/reducer";

import { dependencyByUser } from "./dependency.reducer";

import { currencyCode } from "./currencyCode.reducer";
import { currencyCodeByCountry } from "./currencyCodeByCountry.reducer";
import { wingTypes } from "./wingType.reducer";

const rootReducer = combineReducers({
  //Session reducers
  loginInfo: loginInfo,
  userSystemPrivileges: userSystemPrivileges,
  dependencyByUser: dependencyByUser,
  dependencyFilters: dependencyFilters,

  soScSelection,

  //soDependency
  soDependencies: soDependencies,
  soDependencyParent: soDependencyParent,

  users: users,

  userDependencies: scDependencies,
  scDependencies: scDependencies,
  identityCardTypes: identityCardTypes,
  countries: country,
  provinces: province,
  cities: city,
  scCostCenters: scCostCenters,
  scDispensationRule: scDispensationRule,
  scDispensationRulesTable: scDispensationRulesTable,
  user: user,
  dispRulesByUser: dispRulesByUser,
  vmModel: vmModel,
  vmKeylist: vmKeylist,
  noahubKeylist: noahubKeylist,
  noahub: noahub,
  vmSetup: vmSetup,
  soDependenciesType0: soDependenciesType0,
  soDependenciesAllOption: soDependenciesType0WithAllOption,
  frequencyUnits: frequencyUnits,
  firmwareVersion: firmwareVersion,

  vendingMachines: vendingMachines,

  userAccountTransaction: userTransaction,

  scEmployeeInformation: scEmployeeInformation,
  productTaxType: productTaxType,
  currencyCode: currencyCode,
  currencyCodeByCountry: currencyCodeByCountry,
  soProduct: soProduct,
  vmTrayPosition: vmTrayPosition,
  vmSlotPosition: vmSlotPosition,
  motorType: motorType,
  helixType: helixType,
  vmSlotSize: vmSlotSize,
  soProductSlotType: soProductSlotType,
  vmProductGroup: vmProductGroup,
  //soRole: soRole,

  //planogram reducers
  products_machine: productsMachine,
  planogramProducts: planogramProducts,

  wingTypes: wingTypes,
});

export default rootReducer;
