import { LIST_CURRENCYCODE } from '../actions'

const initialState = {
  currencyCodeObj: []
}

export function currencyCode(state = [], action) {
  switch (action.type) {
    case LIST_CURRENCYCODE:
      let currencyCodes = action.payload.data.map(item => ({
        country: item.country,
        alphaCode: item.currencyAlphaCode,
        numericCode: item.currencyNumericCode,
        symbol: item.symbol,
        value: item.id,
        label: item.symbol + " " + item.currencyAlphaCode
      }))
      return currencyCodes;
    default:
      return state
  }
}
