import axios from 'axios';
import { host, port, path, version } from '../systemVariables';

// Server URL
const resource = '/vendingmachinebyuser';

export function getVendingMachineByUserId(userId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          id: userId
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  };
}

export function insertVendingMachineByUser(vendingmachinebyuser, callback) {
  return () => {
    const data = JSON.stringify(vendingmachinebyuser);
    axios
      .post(host + port + path + version + resource, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  };
}

export function disableUserFromVM(userId, vmId, callback) {
  return () => {
    axios
      .delete(host + port + path + version + resource, {
        params: {
          userid: userId,
          vmid: vmId
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  };
}

export function disableUserFromAllVms(userId, callback) {
  return () => {
    axios
      .delete(host + port + path + version + resource, {
        params: {
          userid: userId
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  };
}
