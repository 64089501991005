// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Card, Table, Button, Alert } from "antd";
import moment from "moment";
import "moment/min/locales";
import { Chart, Axis, Tooltip, Geom, Legend } from "bizcharts";
import _ from "lodash";

// Actions
import ColumnGroup from "antd/lib/table/ColumnGroup";
import {
  getTransactionsStatistics,
  getCommTrafficStatementsByVmIdAndFreqUnitIdAndDateInterval,
  getCommTrafficStatementsByScIdAndFreqUnitIdAndDateInterval,
  getCommTrafficStatementsBySoIdAndFreqUnitIdAndDateInterval,
} from "../../actions";

// Components
import Titles from "../GenericComponents/titles";
import SoScVmDependencySelector from "../GenericComponents/selectComponent/SoScVmDependencySelector";
import TimeUnitSelector from "../GenericComponents/TimeUnitSelector.jsx";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";
import Loading from "../GenericComponents/loadingComponent/loading";
import { SelectGeneral } from "../GenericComponents/selectComponent";

// Language Localization
import Strings from "../../systemVariables/languageStrings";

const { Column } = Table;

class CommunicationsMonitor extends Component {
  constructor(props) {
    super(props);

    // Select values
    this.soDependencyId = -1;
    this.scDependencyId = -1;
    this.vendingMachineId = -1;

    // Time values
    this.startDate = new Date(new Date()).setHours(0, 0, 0);
    this.endDate = new Date(new Date()).setHours(23, 59, 59);
    this.selFreqUnit = 0; // 0:Hours, 1:Days, 2:Weeks, 3:Months, 4:Years

    this.state = {
      renderType: -1, // -1: No info, 0: Vm charts, 1: SO/SC table
      chartButtonEnabled: false,

      // -----Plot info-----
      // --Vm metrics
      metricSel: 0, // Selected information to show. 0=Request, 1=Ping, 2=Speed, 3=Traffic 4=SignalIntensity
      metrics: [
        { value: 0, label: Strings.communications.requests },
        { value: 1, label: Strings.communications.ping },
        { value: 2, label: Strings.communications.speed },
        { value: 3, label: Strings.communications.traffic },
        { value: 4, label: Strings.communications.signalIntensity },
      ],
      commStatements: [], //  Statements raw data
      vmMetrics: {
        metricsSum: {
          totalReq: 0,
          totalSuccessReq: 0,
          totalNoResponseReq: 0,
          totalFailReq: 0,
          connectionServiceIndex: 0,
          totalUpTraffic: 0,
          totalDownTraffic: 0,
          avgPing: 0,
          avgUpSpeed: 0,
          avgDownSpeed: 0,
          avgSignalIntensity: 0,
        },
        requestMetrics: [], // Request metrics to plot
        pingMetrics: [], // ing metrics to plot
        speedMetrics: [], // Speed metrics to plot
        trafficMetrics: [], // Traffic metrics to plot
        signalIntensityMetrics: [], // Signal intensity metrics to plot
      },

      // --SC/SO metrics
      vmGroupMetrics: {},

      loadingData: false,
    };
  }

  componentDidMount() {
    const {
      soScSelectionProps: { so, sc },
      userDependency: { dependencyId, dependencyType },
    } = this.props;

    if (so !== null) {
      this.soDependencyId = so.id;
      this.setState({
        chartButtonEnabled: true,
      });
    } else if (dependencyType === "so") {
      this.soDependencyId = dependencyId;
      this.setState({
        chartButtonEnabled: true,
      });
    }
    if (so === null && dependencyType === "so" && dependencyId === 1 && sc === null) {
      this.soDependencyId = -1;
      this.setState({
        chartButtonEnabled: true,
      });
    }

    if (sc !== null) {
      // Query SC data here
      this.scDependencyId = sc.id;
      this.setState({
        chartButtonEnabled: true,
      });
    }
  }

  // Events
  soDependencyHandleChange = (event) => {
    const { value } = event;
    this.soDependencyId = value;
    this.scDependencyId = -1;
    this.vendingMachineId = -1;
    this.setState({ chartButtonEnabled: true });
  };

  scDependencyHandleChange = (event) => {
    const { value } = event;
    if (value === -1) {
      const {
        userDependency: { dependencyId },
      } = this.props;
      this.soDependencyId = dependencyId;
      this.scDependencyId = -1;
      this.vendingMachineId = -1;
    } else {
      this.soDependencyId = -1;
      this.scDependencyId = value;
      this.vendingMachineId = -1;
    }
    this.setState({ chartButtonEnabled: true });
  };

  vendingMachineHandleChange = (event) => {
    const { value } = event;
    this.soDependencyId = -1;
    this.scDependencyId = -1;
    this.vendingMachineId = value;
    this.setState({ chartButtonEnabled: true });
  };

  setTimeInformation = (startDate, endDate, selFreqUnit) => {
    this.startDate = startDate;
    this.endDate = endDate;
    this.selFreqUnit = selFreqUnit;
  };

  metricTypeChange = (event) => {
    this.setState({ metricSel: event.value });
  };

  onSeeChartButton = async () => {
    this.setState({ loadingData: true });
    if (this.vendingMachineId !== -1) {
      await this.getVmCommStatements(this.vendingMachineId, this.selFreqUnit, this.startDate, this.endDate);
      this.setState({ renderType: 0, loadingData: false });
    } else if (this.scDependencyId !== -1) {
      await this.getScCommStatements(this.scDependencyId, this.selFreqUnit, this.startDate, this.endDate);
      this.setState({ renderType: 1, loadingData: false });
    } else {
      await this.getSoCommStatements(this.soDependencyId, this.selFreqUnit, this.startDate, this.endDate);
      this.setState({ renderType: 1, loadingData: false });
    }
  };

  calculateVmMetrics = (commStatements) => {
    if (commStatements.length > 0) {
      const startDate = new Date(JSON.parse(JSON.stringify(this.startDate)));

      // Get number of steps depending on FreqUnit
      let numTimeIntervals;
      switch (this.selFreqUnit) {
        case 0: // days
          numTimeIntervals = 24;
          break;
        case 1: // Months
          numTimeIntervals = moment(startDate).daysInMonth();
          break;
        case 3: // Years
          numTimeIntervals = 12;
          break;
      }

      // Initialize arrays
      const successReqMetrics = Array(numTimeIntervals);
      const failReqMetrics = Array(numTimeIntervals);
      const noResponseReqMetrics = Array(numTimeIntervals);
      const pingMetrics = Array(numTimeIntervals);
      const upSpeedMetrics = Array(numTimeIntervals);
      const downSpeedMetrics = Array(numTimeIntervals);
      const upTrafficMetrics = Array(numTimeIntervals);
      const downTrafficMetrics = Array(numTimeIntervals);
      const signalIntensityMetrics = Array(numTimeIntervals);

      // Iterate through statements
      let date;
      let timeStepWindow = 0;
      for (let timeInterval = 0; timeInterval < numTimeIntervals; timeInterval++) {
        // Get date depending on freqUnitId and timeInterval
        switch (this.selFreqUnit) {
          case 0: // days
            date = moment(startDate).hours(timeInterval).toDate();
            break;
          case 1: // Months
            date = startDate.setDate(timeInterval + 1);
            break;
          case 3: // Years
            date = startDate.setMonth(timeInterval);
            break;
        }

        // Fill metrics arrays
        successReqMetrics[timeInterval] = { value: 0, type: "success", time: date, timeStep: timeInterval };
        failReqMetrics[timeInterval] = { value: 0, type: "fail", time: date, timeStep: timeInterval };
        noResponseReqMetrics[timeInterval] = { value: 0, type: "noResponse", time: date, timeStep: timeInterval };
        pingMetrics[timeInterval] = { value: 0, time: date, timeStep: timeInterval };
        upSpeedMetrics[timeInterval] = { value: 0, type: "upload", time: date, timeStep: timeInterval };
        downSpeedMetrics[timeInterval] = { value: 0, type: "download", time: date, timeStep: timeInterval };
        upTrafficMetrics[timeInterval] = { value: 0, type: "upload", time: date, timeStep: timeInterval };
        downTrafficMetrics[timeInterval] = { value: 0, type: "download", time: date, timeStep: timeInterval };
        signalIntensityMetrics[timeInterval] = { value: 0, time: date, timeStep: timeInterval };

        // Get statement depending on freqUnitId and timeInterval
        let timeIntervalStatement = [];
        switch (this.selFreqUnit) {
          case 0: // days
            timeIntervalStatement = commStatements.filter((cs) => moment(cs.date).hour() === timeInterval);
            break;
          case 1: // Months
            const intervalOfInterestDate = moment(JSON.parse(JSON.stringify(this.startDate))).add(timeInterval + 1, "days");
            timeIntervalStatement = commStatements.filter(
              (cs) => moment(cs.date).date() === intervalOfInterestDate.date() && moment(cs.date).month() == intervalOfInterestDate.month()
            );
            break;
          case 3: // Years
            timeIntervalStatement = commStatements.filter((cs) => moment(cs.date).month() === timeInterval);
            break;
        }

        if (timeIntervalStatement.length !== 0) {
          timeStepWindow++;
        }
        timeIntervalStatement.forEach((cs) => {
          // Requests Metrics
          if (cs.httpErrorCodeId === 0 || cs.httpErrorCodeId === 1) {
            // Success
            successReqMetrics[timeInterval].value += cs.quantity;
          } else if (cs.httpErrorCodeId === 11) {
            // No response
            noResponseReqMetrics[timeInterval].value += cs.quantity;
          } else {
            // Fail
            failReqMetrics[timeInterval].value += cs.quantity;
          }

          // Traffic
          upTrafficMetrics[timeInterval].value += Math.round(cs.upSize / 1000);
          downTrafficMetrics[timeInterval].value += Math.round(cs.downSize / 1000);

          // Ping
          pingMetrics[timeInterval].value = cs.ping;

          // Speed
          upSpeedMetrics[timeInterval].value = cs.uploadSpeed ? cs.uploadSpeed : 0;
          downSpeedMetrics[timeInterval].value = cs.downloadSpeed ? cs.downloadSpeed : 0;

          // Signal intensity
          signalIntensityMetrics[timeInterval].value = cs.signalIntensity ? cs.signalIntensity : 0;
        });
      }

      // Get metrics summary
      const sumReducer = (accumulator, currentValue) => {
        return accumulator + currentValue.value;
      };
      const totalSuccessReq = successReqMetrics.reduce(sumReducer, 0);
      const totalFailReq = failReqMetrics.reduce(sumReducer, 0);
      const totalNoResponseReq = noResponseReqMetrics.reduce(sumReducer, 0);
      const totalReq = totalSuccessReq + totalFailReq + totalNoResponseReq;
      const metricsSum = {
        totalSuccessReq,
        totalFailReq,
        totalNoResponseReq,
        totalReq,
        connectionServiceIndex: totalReq ? Math.round((10000 * totalSuccessReq) / totalReq) / 100 : 0,
        totalUpTraffic: upTrafficMetrics.reduce(sumReducer, 0),
        totalDownTraffic: downTrafficMetrics.reduce(sumReducer, 0),
        avgPing: Math.round(pingMetrics.reduce(sumReducer, 0) / timeStepWindow),
        avgUpSpeed: Math.round(upSpeedMetrics.reduce(sumReducer, 0) / timeStepWindow),
        avgDownSpeed: Math.round(downSpeedMetrics.reduce(sumReducer, 0) / timeStepWindow),
        avgSignalIntensity: Math.round(signalIntensityMetrics.reduce(sumReducer, 0) / timeStepWindow),
      };

      // Set metrics arrays
      const requestMetrics = successReqMetrics.concat(failReqMetrics.concat(noResponseReqMetrics));
      const speedMetrics = upSpeedMetrics.concat(downSpeedMetrics);
      const trafficMetrics = upTrafficMetrics.concat(downTrafficMetrics);
      const vmMetrics = {
        metricsSum,
        requestMetrics,
        speedMetrics,
        trafficMetrics,
        pingMetrics,
        signalIntensityMetrics,
      };
      this.setState({ vmMetrics });
    }
  };

  calculateScSoMetrics = (commStatements) => {
    if (commStatements.length > 0) {
      // Sort commStatements
      commStatements.sort((a, b) => new Date(a.calculationDate) - new Date(b.calculationDate));

      // Group statements by vendingMachine and iterate
      const vmGroupMetrics = [];
      let numTimeIntervals = 0;
      const commStatementsGroupedByVm = _.groupBy(commStatements, "vendingMachineName");
      Object.keys(commStatementsGroupedByVm).map((vmName) => {
        const vmCommStatements = commStatementsGroupedByVm[vmName];

        // Metrics
        let successReq = 0;
        let failReq = 0;
        let noResponseReq = 0;
        let ping = 0;
        let downTraffic = 0;
        let upTraffic = 0;
        let downSpeed = 0;
        let upSpeed = 0;
        let signalIntensity = 0;

        for (let i = 0; i < vmCommStatements.length; i++) {
          const vmCommStatement = vmCommStatements[i];

          // Get Requests data
          if (vmCommStatement.httpErrorCodeId === 0 || vmCommStatement.httpErrorCodeId === 1) {
            successReq += vmCommStatement.quantity;
          } else if (vmCommStatement.httpErrorCodeId === 11) {
            noResponseReq += vmCommStatement.quantity;
          } else {
            failReq += vmCommStatement.quantity;
          }

          // Check if statement data is different to the previous one (See statements register information) and store metrics data
          if (i > 0) {
            if (moment(vmCommStatement.calculationDate).diff(moment(vmCommStatements[i - 1].calculationDate), "minutes") > 50) {
              numTimeIntervals++;
              ping += vmCommStatement.ping;
              downSpeed += vmCommStatement.downSpeed;
              upSpeed += vmCommStatement.upSpeed;
              signalIntensity += vmCommStatement.signalIntensity;
            }
          } else {
            numTimeIntervals++;
            ping += vmCommStatement.ping;
            downSpeed += vmCommStatement.downSpeed;
            upSpeed += vmCommStatement.upSpeed;
            signalIntensity += vmCommStatement.signalIntensity;
          }
          downTraffic += Math.round(vmCommStatement.downSize / 1000);
          upTraffic += Math.round(vmCommStatement.upSize / 1000);
        }

        // Calculate metrics
        const totalReq = successReq + failReq + noResponseReq;
        const connectionServiceIndex = Math.round((10000 * successReq) / totalReq) / 100;
        const pingAvg = Math.round(ping / numTimeIntervals);
        const downSpeedAvg = Math.round(downSpeed / numTimeIntervals);
        const upSpeedAvg = Math.round(upSpeed / numTimeIntervals);
        const signalIntensityAvg = Math.round(signalIntensity / numTimeIntervals);
        vmGroupMetrics.push({
          vmName,
          connectionServiceIndex,
          totalReq,
          successReq,
          noResponseReq,
          failReq,
          pingAvg,
          downSpeedAvg,
          upSpeedAvg,
          downTraffic,
          upTraffic,
          signalIntensityAvg,
        });
      });
      this.setState({ vmGroupMetrics });
    }
  };

  getVmCommStatements = async (vmId, freqUnitId, startDate, endDate) => {
    const startDateAdjusted = moment(startDate);
    const endDateAdjusted = moment(endDate);
    if (freqUnitId == 1) {
      startDateAdjusted.add(30, "minutes");
      endDateAdjusted.add(30, "minutes");
    }
    const commAns = await getCommTrafficStatementsByVmIdAndFreqUnitIdAndDateInterval(vmId, freqUnitId, startDateAdjusted.format(), endDateAdjusted.format());
    if (commAns.status === "ok") {
      this.calculateVmMetrics(commAns.data);
      this.setState({ commStatements: commAns.data });
    } else {
    }
  };

  getScCommStatements = async (scId, freqUnitId, startDate, endDate) => {
    const commAns = await getCommTrafficStatementsByScIdAndFreqUnitIdAndDateInterval(scId, freqUnitId, moment(startDate).format(), moment(endDate).format());
    if (commAns.status === "ok") {
      this.calculateScSoMetrics(commAns.data);
      this.setState({ commStatements: commAns.data });
    } else {
    }
  };

  getSoCommStatements = async (soId, freqUnitId, startDate, endDate) => {
    const commAns = await getCommTrafficStatementsBySoIdAndFreqUnitIdAndDateInterval(soId, freqUnitId, moment(startDate).format(), moment(endDate).format());
    if (commAns.status === "ok") {
      this.calculateScSoMetrics(commAns.data);
      this.setState({ commStatements: commAns.data });
    } else {
    }
  };

  getScaleInfo = () => {
    // Organize hist cols
    let histColAlias; // Freq unit name
    let tickFormat; // Chart ticks format
    switch (this.selFreqUnit) {
      case 0:
        histColAlias = Strings.timeUnits.hour;
        tickFormat = "HH:mm";
        break;
      case 1:
        histColAlias = Strings.timeUnits.day;
        tickFormat = "DD";
        break;
      case 3:
        histColAlias = Strings.timeUnits.month;
        tickFormat = "MM";
        break;
      default:
        break;
    }
    return {
      transactions: { alias: Strings.transaction.dispensations },
      sales: { alias: Strings.generalTerms.sales },
      time: { alias: histColAlias, type: "time", mask: tickFormat },
    };
  };

  informationToRender = () => {
    const { renderType } = this.state;
    const { commStatements } = this.state;
    if (commStatements.length === 0) {
      return (
        <div className="style-alert-msn">
          <Alert message={Strings.generalResponses.searchInformation} description={Strings.generalResponses.queryResponse} type="info" showIcon />
          <div className="footer-style" />
        </div>
      );
    }
    if (renderType == 0) {
      return this.renderVmCommStatementsInfo();
    }
    if (renderType == 1) {
      return this.renderScSoCommStatementsTable();
    }
  };

  renderVmCommStatementsInfo = () => {
    const { metrics, metricSel, vmMetrics } = this.state;
    const { metricsSum } = vmMetrics;
    return (
      <>
        <div className="col-12">
          <div className="row">
            <div className="col-md-4">
              <SelectGeneral
                tooltip={Strings.communications.metricTypeTooltip}
                text={Strings.communications.metricType}
                options={metrics}
                onChange={(sel) => this.metricTypeChange(sel)}
                disabled={false}
                defaultValue={metricSel}
              />
            </div>
          </div>
        </div>
        <div className="row" style={{ alignItems: "center", width: "100%" }}>
          <div className="col-md-9">
            <div className="col-md-12">{this.renderVmCommStatementsPlot()}</div>
          </div>
          <div className="col-md-3">
            <div className="col">
              <Card
                size="small"
                title={<b>{Strings.communications.connectionIndicators}</b>}
                headStyle={{ backgroundColor: "#97bff7", color: "#444", fontSize: 15 }}
                style={{ fontSize: "13px" }}
              >
                <TextWithInfoTooltip
                  name={`${Strings.communications.connectionServiceIndex}: ${metricsSum.connectionServiceIndex}%`}
                  tooltip={Strings.communications.connectionServiceIndexTooltip}
                  style={{ marginTop: "10px" }}
                />
                <TextWithInfoTooltip
                  name={`${Strings.communications.requests}: ${metricsSum.totalReq}`}
                  tooltip={Strings.communications.requestsTooltip}
                  style={{ marginTop: "10px" }}
                />
                <TextWithInfoTooltip
                  name={`${Strings.communications.successRequests}: ${metricsSum.totalSuccessReq}`}
                  tooltip={Strings.communications.successRequestsTooltip}
                  style={{ marginTop: "10px" }}
                />
                <TextWithInfoTooltip
                  name={`${Strings.communications.failRequests}: ${metricsSum.totalFailReq}`}
                  tooltip={Strings.communications.failRequestsTooltip}
                  style={{ marginTop: "10px" }}
                />
                <TextWithInfoTooltip
                  name={`${Strings.communications.noResponseRequests}: ${metricsSum.totalNoResponseReq}`}
                  tooltip={Strings.communications.noResponseRequestsTooltip}
                  style={{ marginTop: "10px" }}
                />
                <TextWithInfoTooltip
                  name={`${Strings.communications.uploadTraffic}: ${metricsSum.totalUpTraffic}kB`}
                  tooltip={Strings.communications.uploadTrafficTooltip}
                  style={{ marginTop: "10px" }}
                />
                <TextWithInfoTooltip
                  name={`${Strings.communications.downloadTraffic}: ${metricsSum.totalDownTraffic}kB`}
                  tooltip={Strings.communications.downloadTrafficTooltip}
                  style={{ marginTop: "10px" }}
                />
                <TextWithInfoTooltip
                  name={`${Strings.communications.uploadSpeedAverage}: ${metricsSum.avgUpSpeed}`}
                  tooltip={Strings.communications.uploadSpeedAverageTooltip}
                  style={{ marginTop: "10px" }}
                />
                <TextWithInfoTooltip
                  name={`${Strings.communications.downloadSpeedAverage}: ${metricsSum.avgDownSpeed}`}
                  tooltip={Strings.communications.downloadSpeedAverageTooltip}
                  style={{ marginTop: "10px" }}
                />
                <TextWithInfoTooltip
                  name={`${Strings.communications.pingAverage}: ${metricsSum.avgPing}ms`}
                  tooltip={Strings.communications.pingAverageTooltip}
                  style={{ marginTop: "10px" }}
                />
                <TextWithInfoTooltip
                  name={`${Strings.communications.signalIntensityAverage}: ${metricsSum.avgSignalIntensity}`}
                  tooltip={Strings.communications.signalIntensityAverageTooltip}
                  style={{ marginTop: "10px" }}
                />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderVmCommStatementsPlot = () => {
    const { metricSel, vmMetrics } = this.state;
    const scaleInformation = this.getScaleInfo();
    switch (metricSel) {
      case 0: // Requests
        const { requestMetrics } = vmMetrics;
        return (
          <Chart style={{ width: "100%" }} forceFit data={requestMetrics} scale={scaleInformation} padding={[80, 80, 120, 80]}>
            <Axis name="requests" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Axis name="time" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Tooltip />
            <Geom type="intervalStack" position="time*value" color={["type", ["#5bd968", "#db5f46", "#f7e436"]]} />
            <Legend position="bottom" title={null} />
          </Chart>
        );
      case 1: // Ping
        const { pingMetrics } = vmMetrics;
        return (
          <Chart style={{ width: "100%" }} forceFit data={pingMetrics} scale={scaleInformation} padding={[80, 80, 120, 80]}>
            <Axis name="ping" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Axis name="time" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Tooltip />
            <Geom type="line" position="time*value" shape="smooth" />
            <Legend position="bottom" title={null} />
          </Chart>
        );
      case 2: // Speed
        const { speedMetrics } = vmMetrics;
        return (
          <Chart style={{ width: "100%" }} forceFit data={speedMetrics} scale={scaleInformation} padding={[80, 80, 120, 80]}>
            <Axis name="speed" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Axis name="time" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Tooltip />
            <Geom type="line" position="time*value" shape="smooth" color="type" />
            <Legend position="bottom" title={null} />
          </Chart>
        );
      case 3: // Traffic
        const { trafficMetrics } = vmMetrics;
        return (
          <Chart style={{ width: "100%" }} forceFit data={trafficMetrics} scale={scaleInformation} padding={[80, 80, 120, 80]}>
            <Axis name="traffic" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Axis name="time" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Tooltip />
            <Geom type="line" position="time*value" shape="smooth" color="type" />
            <Legend position="bottom" title={null} />
          </Chart>
        );
      case 4: // Signal Intensity
        const { signalIntensityMetrics } = vmMetrics;
        return (
          <Chart style={{ width: "100%" }} forceFit data={signalIntensityMetrics} scale={scaleInformation} padding={[80, 80, 120, 80]}>
            <Axis name="signal" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Axis name="time" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Tooltip />
            <Geom type="line" position="time*value" shape="smooth" />
            <Legend position="bottom" title={null} />
          </Chart>
        );
    }
  };

  renderScSoCommStatementsTable = () => {
    const { vmGroupMetrics } = this.state;
    return (
      <div className="row">
        <Table dataSource={vmGroupMetrics} bordered pagination={{ pageSize: 10 }} size="middle" style={{ width: "100%" }} scroll={{ x: "auto" }} key="vmName">
          <Column title={Strings.machine.machine} dataIndex="vmName" />
          <Column
            title={<TextWithInfoTooltip name="CSI" tooltip={Strings.communications.connectionServiceIndexTooltip} />}
            dataIndex="connectionServiceIndex"
            width={85}
            render={(csi) => <span>{`${csi}%`}</span>}
            sorter={(a, b) => a.connectionServiceIndex - b.connectionServiceIndex}
          />
          <ColumnGroup title={Strings.communications.requests}>
            <Column
              title={<TextWithInfoTooltip name="Total" tooltip={Strings.communications.requestsTooltip} />}
              dataIndex="totalReq"
              width={95}
              sorter={(a, b) => a.totalReq - b.totalReq}
            />
            <Column
              title={<TextWithInfoTooltip name={Strings.communications.success} tooltip={Strings.communications.successRequestsTooltip} />}
              dataIndex="successReq"
              width={114}
              sorter={(a, b) => a.successReq - b.successReq}
            />
            <Column
              title={<TextWithInfoTooltip name={Strings.communications.noResponse} tooltip={Strings.communications.noResponseRequestsTooltip} />}
              dataIndex="noResponseReq"
              width={110}
              sorter={(a, b) => a.noResponseReq - b.noResponseReq}
            />
            <Column
              title={<TextWithInfoTooltip name={Strings.communications.fail} tooltip={Strings.communications.failRequestsTooltip} />}
              dataIndex="failReq"
              width={95}
              sorter={(a, b) => a.failReq - b.failReq}
            />
          </ColumnGroup>
          <ColumnGroup title={Strings.communications.traffic}>
            <Column
              title={<TextWithInfoTooltip name={Strings.communications.download} tooltip={Strings.communications.uploadTrafficTooltip} />}
              dataIndex="upTraffic"
              width={105}
              render={(item) => <span>{`${item}kB`}</span>}
              sorter={(a, b) => a.upTraffic - b.upTraffic}
            />
            <Column
              title={<TextWithInfoTooltip name={Strings.communications.upload} tooltip={Strings.communications.downloadTrafficTooltip} />}
              dataIndex="downTraffic"
              width={105}
              render={(item) => <span>{`${item}kB`}</span>}
              sorter={(a, b) => a.downTraffic - b.downTraffic}
            />
          </ColumnGroup>
          <ColumnGroup title={Strings.communications.speed}>
            <Column
              title={<TextWithInfoTooltip name={Strings.communications.download} tooltip={Strings.communications.downloadSpeedAverageTooltip} />}
              dataIndex="downSpeedAvg"
              width={105}
              render={(item) => <span>{item || "--"}</span>}
              sorter={(a, b) => a.downSpeedAvg - b.downSpeedAvg}
            />
            <Column
              title={<TextWithInfoTooltip name={Strings.communications.upload} tooltip={Strings.communications.uploadSpeedAverageTooltip} />}
              dataIndex="upSpeedAvg"
              width={105}
              render={(item) => <span>{item || "--"}</span>}
              sorter={(a, b) => a.upSpeedAvg - b.upSpeedAvg}
            />
          </ColumnGroup>
          <Column
            title={<TextWithInfoTooltip name={Strings.communications.pingAverage} tooltip={Strings.communications.pingAverageTooltip} />}
            dataIndex="pingAvg"
            width={120}
            render={(item) => <span>{item ? `${item}ms` : "--"}</span>}
            sorter={(a, b) => a.pingAvg - b.pingAvg}
          />
          <Column
            title={<TextWithInfoTooltip name={Strings.communications.signal} tooltip={Strings.communications.signalIntensityAverageTooltip} />}
            dataIndex="signalIntensityAvg"
            render={(item) => <span>{item || "--"}</span>}
            width={115}
            sorter={(a, b) => a.signalIntensityAvg - b.signalIntensityAvg}
          />
        </Table>
      </div>
    );
  };

  render() {
    const { loadingData, chartButtonEnabled } = this.state;
    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.communications.communicationsMonitor} tooltip={Strings.communications.communicationsMonitorTooltip} />
        </div>

        <div className="vertSpace row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <SoScVmDependencySelector
            onSoDependencyChange={this.soDependencyHandleChange}
            onScDependencyChange={this.scDependencyHandleChange}
            onVendingMachineChange={this.vendingMachineHandleChange}
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            hasSc
            hasVm
            hasSoAll
            hasScAll
          />
        </div>

        <div className="vertSpace row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.transactionMonitor.analysisIntervalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-md-8">
            <TimeUnitSelector setTimeInformation={this.setTimeInformation} rangeSelectionEnabled={false} />
          </div>
          <div className="vertSpace col-md-2">
            <Button onClick={this.onSeeChartButton} loading={loadingData} className="button" disabled={!chartButtonEnabled}>
              {Strings.communications.seeMetrics}
            </Button>
          </div>
        </div>

        <div className="row" style={{ marginTop: "5px", justifyContent: "center" }}>
          {loadingData ? (
            <Loading />
          ) : (
            <>
              <div className="col-12">
                <Divider orientation="left" />
              </div>
              <div className="col-12">{this.informationToRender()}</div>
            </>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
    userDependency: state.dependencyByUser.dependencyObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  getTransactionsStatistics,
})(CommunicationsMonitor);
