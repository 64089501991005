import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Input, Tooltip, Select, Icon, Typography, Divider } from "antd";
import { connect } from "react-redux";
import Strings from "../../../systemVariables/languageStrings";

const { Option } = Select;
const { Title } = Typography;

const MachineSyncModal = ({ serialNumber, recovery, vmId, dataApiApp, generationIdMachine }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText] = useState(Strings.machine.machineRecoveryLogsContentModal);
  const [input] = useState(true);
  const [listSelectFimwareVersion, setlistSelectFimwareVersion] = useState({ id: [], versionString: [] });
  const [option, setOption] = useState(null);
  const inputRef = useRef(null);

  const statusOk = 200;
  const statusFail = 500;

  const sharedProps = {
    style: {
      width: "80%",
      align: "center",
    },
    defaultValue: ``,
    ref: inputRef,
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = async () => {
    setVisible(false);
    inputRef.current.state.value = null;
  };

  const resetStates = async () => {
    setConfirmLoading(false);
    setVisible(false);
    inputRef.current.state.value = null;
  };

  const dataApiAppChild = async () => {
    try {
      await dataApiApp(generationIdMachine, (response) => {
        setlistSelectFimwareVersion({ id: response.data.data.map((data) => data.id), versionString: response.data.data.map((data) => data.versionString) });
      });
    } catch (e) {
      resetStates();
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: Strings.generalResponses.saveError,
      });
    }
  };

  useEffect(() => {
    // Run! Like go get some data from an API.
    dataApiAppChild();
  }, []);

  const onChangeSelect = (value) => {
    setOption(value);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    if (serialNumber === inputRef.current.state.value && option !== null) {
      let recoveryAwait = statusFail;
      await recovery(vmId, option, (response) => {
        if (response.status === statusOk) {
          // Entity Successfully  saved
          const DATE_OPTIONS = { year: "numeric", month: "numeric", day: "numeric" };
          const startDateStr = new Date(response.data.data.recoveryDate).toLocaleDateString("es-CO", DATE_OPTIONS);
          Modal.success({
            title: Strings.generalResponses.creationSuccess,
            content: `${Strings.machine.configuration.generalInformation}: token ${response.data.data.recoveryToken}, ${Strings.generalTerms.dateRecovery} ${startDateStr}, ${Strings.generalTerms.updatedBy} ${response.data.data.nameUser},  serial ${Strings.generalTerms.of} ${Strings.machine.machine} ${serialNumber}`,
          });
          setOption(null);
          resetStates();
          recoveryAwait = statusOk;
        }
      });
      // Connection error
      if (recoveryAwait === statusFail) {
        resetStates();
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      }
    } else if (serialNumber !== inputRef.current.state.value) {
      resetStates();
      Modal.error({
        title: `${Strings.machine.serialError.title}`,
        content: ` ${Strings.machine.serialError.content} ${serialNumber}`,
      });
    } else if (option === null) {
      resetStates();
      Modal.error({
        title: `${Strings.machine.invalidVersion.title}`,
        content: ` ${Strings.machine.invalidVersion.content} !`,
      });
    }
  };

  return (
    <>
      <Button icon="cloud-download" type="primary" onClick={showModal} loading={confirmLoading}>
        {Strings.machine.machineData}
      </Button>
      <Modal visible={visible} onOk={handleOk} confirmLoading={confirmLoading} cancelButtonProps={{ disabled: confirmLoading }} onCancel={handleCancel}>
        <Title level={4}>
          {Strings.machine.recoveryMachine}{" "}
          <Tooltip title={Strings.machine.machineRecoveryTooltipButton}>
            <Icon type="exclamation-circle" />
          </Tooltip>
        </Title>
        <Divider />
        <p>{modalText}</p>
        <div className="row vertSpace ">
          <div>
            <div className="selectAligning  col-12">
              <span>
                {Strings.machine.firmwareVersion}
                <b>:</b>
                <Tooltip title={Strings.machine.firmwareVersionTooltip}>
                  <Icon className="icon" type="info-circle" style={{ float: "none" }} />
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-3">
            <Select
              showSearch
              style={{ width: 110 }}
              placeholder={Strings.machine.invalidVersion.title}
              optionFilterProp="children"
              onChange={onChangeSelect}
              value={option}
              filterOption={(inputValue, option) => option.props.children.toLowerCase().includes(inputValue.toLowerCase())}
            >
              {listSelectFimwareVersion.versionString.map((option) => (
                <Option key={listSelectFimwareVersion.id.filter((obj) => listSelectFimwareVersion.id.indexOf(obj) === listSelectFimwareVersion.versionString.indexOf(option))}>
                  {option}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="row vertSpace">
          <div>
            <div className="selectAligning  col-12">
              {Strings.machine.disableMachineMsg}:{"   "}
            </div>
          </div>
          <div className="col-5"> {input ? <Input {...sharedProps} /> : <Input.TextArea {...sharedProps} />}</div>
        </div>
      </Modal>
    </>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(MachineSyncModal);
