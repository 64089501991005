//Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./soProductSlotType.css";
import { Modal, Divider } from "antd";
import _ from "lodash";

// Actions
import { addSoProductSlotTypeAction } from "../../../actions";

//Components
import Titles from "../../GenericComponents/titles";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import { GeneralCheckbox } from "../../GenericComponents/checkBox/GeneralCheckBox";
import { SaveButton, ReturnButton } from "../../GenericComponents/buttons/index";

//Language localization
import Strings from "../../../systemVariables/languageStrings";
import {
  SlotSizeNameLocalizationArray,
  motorTypeConfigLocalization,
  motorTypeConfigLocalizationArray,
  helixTypeLocalizationArray,
  trayPositionNameLocalizationArray,
  slotPositionNameLocalizationArray,
} from "../../../Utils/LanguageLocalization/index";

//Selections
let vmSLotSizeSel = null;
let motorConfigSelId = null;
let motorSel = null;
let helixSel = null;
let trayPosSel = null;
let slotPosSel = null;
let hasAlignerSel = 0;
let hasSliderSel = 0;
let helixOptions = [];

class AddSoProductSlotTypeForm extends Component {
  constructor(props) {
    super(props);
    //Set state variables
    this.state = {
      soProductSlotTypeDto: {
        id: null,
        vmslotsizeId: null,
        motorLtypeId: null,
        motorHtypeId: null,
        helixLtypeId: null,
        helixHtypeId: null,
        soproductId: null,
        vmslotpositionId: null,
        vmtraypositionId: null,
        hasHelixAligner: 0,
        hasProductAligner: 0,
        hasSlidingBase: 0,
      },
      soDependencyId: null,

      //SoProdSlotType Product (retrieved from previous view)
      selectedProduct: this.props.location.props ? this.props.location.props.selectedProd : null,

      //SoProdSlotType to edit in Raw form
      soProductSlotTypeToEditRaw: this.props.location.props ? this.props.location.props.soProdSlotTypeToEdit : null,

      //Options arrays
      motorConfigOptions: [],
      motorOptionsFiltered: [],
      motorOptions: this.conformMotorOptions(),
      helixOptions: [],

      //Flag used to redirect to /soProductSlotType if any product was selected
      redirectBackToSoProdSlotType: false,

      loading: false,
    };
    //Bind Modal button callback so View state variables can be used
    this.handleOkNoProductWarning = this.handleOkNoProductWarning.bind(this);

    //Clean selection variables
    vmSLotSizeSel = null;
    motorConfigSelId = null;
    motorSel = null;
    helixSel = null;
    trayPosSel = null;
    slotPosSel = null;
    hasAlignerSel = 0;
    hasSliderSel = 0;
  }
  //---------LifeCycle----------
  componentDidMount() {
    this.mapSoProdSlotTypeToEdit();
  }
  //-----------Events------------
  sizeHandleChange(e) {
    vmSLotSizeSel = e;
    this.setState({
      //Filter options according size
      motorConfigOptions: motorTypeConfigLocalizationArray(e.value),
    });
    //Set property in object to return
    let soProductSlotTypeCpy = Object.assign({}, this.state.soProductSlotTypeDto);
    var sizeIdx = this.props.vmSlotSizes[e.value].value;
    soProductSlotTypeCpy.vmslotsizeId = sizeIdx; //Set vmslotsizeId
    soProductSlotTypeCpy.soproductId = this.state.selectedProduct.value; //Set soproductId
    soProductSlotTypeCpy.id = this.state.soProductSlotTypeToEditRaw != null ? this.state.soProductSlotTypeToEditRaw.id : null; //Set id

    //Reset motorL to force to select it again
    soProductSlotTypeCpy.motorLtypeId = null;

    this.setState({
      soProductSlotTypeDto: soProductSlotTypeCpy,
    });
    return soProductSlotTypeCpy;
  }
  motorConfigHandleChange(e) {
    //Set selection
    motorConfigSelId = e.value;

    //Reset motorSel default value
    motorSel = null;

    //Filter options according ConfigMotor
    if (e.value == 2) {
      this.setState({
        motorOptionsFiltered: this.state.motorOptions.filter((elem) => elem.label.includes("DOUBLE")),
      });
    } else {
      this.setState({
        motorOptionsFiltered: this.state.motorOptions.filter((elem) => !elem.label.includes("DOUBLE")),
      });
    }

    //Reset motorL to force to select it again
    let soProductSlotTypeCpy = Object.assign({}, this.state.soProductSlotTypeDto);
    soProductSlotTypeCpy.motorLtypeId = null;
    this.setState({
      soProductSlotTypeDto: soProductSlotTypeCpy,
    });
  }
  motorHandleChange(e) {
    //Set selection
    motorSel = e;

    //Reset helixSel default value
    helixSel = null;

    if (motorConfigSelId == 0) {
      var helixTypesRaw = this.props.helixTypes.map((item) => ({ value: item.value, label: item.label }));
      helixOptions = helixTypeLocalizationArray(helixTypesRaw);
      this.setState({
        helixOptions: helixOptions,
      });
    } else {
      var helixTypesRaw = [];
      var helixNameNoTurnType;
      //Remove TurnType from name (Double motor and two motor options have both turn types)
      this.props.helixTypes.forEach((item) => {
        let label = _.split(item.label, " - ");
        helixNameNoTurnType = label[0].substring(0, label[0].lastIndexOf("-"));
        if (helixTypesRaw.filter((elem) => elem.label == helixNameNoTurnType + " - " + label[1]) == 0) {
          helixTypesRaw.push({
            value: item.value,
            label: helixNameNoTurnType + " - " + label[1],
          });
        }
      });
      helixOptions = helixTypesRaw;
      this.setState({
        helixOptions: helixTypesRaw,
      });
    }
    //Reset motorL to force to select it again
    let soProductSlotTypeCpy = Object.assign({}, this.state.soProductSlotTypeDto);
    soProductSlotTypeCpy.motorLtypeId = null;
    this.setState({
      soProductSlotTypeDto: soProductSlotTypeCpy,
    });
  }
  helixHandleChange(e) {
    //Set selection
    helixSel = e;

    //Set property in object to return
    let soProductSlotTypeCpy = Object.assign({}, this.state.soProductSlotTypeDto);

    //HelixL
    var helixL = this.props.helixTypes.filter((item) => item.value === e.value)[0];
    soProductSlotTypeCpy.helixLtypeId = helixL.value;

    //For MotorConfig single set MotorL opposite to HelixL turn type
    if (motorConfigSelId == 0) {
      //HelixH
      soProductSlotTypeCpy.helixHtypeId = null;

      //MotorL
      var helixLTurn = helixL.label.includes("LEFT") ? "LEFT" : "RIGHT";
      var motorL = this.props.motorTypes.filter((item) => item.name === motorSel.label && item.turnType !== helixLTurn)[0];
      soProductSlotTypeCpy.motorLtypeId = motorL.id;
      soProductSlotTypeCpy.motorHtypeId = null;
    }
    //For MotorConfig double or two motors find pair for selected helixL and set both motors
    else {
      //HelixH
      var helixH = this.props.helixTypes.filter((item) => item.value !== helixL.value && _.split(item.label, " - ")[0].includes(_.split(e.label, " - ")[0]))[0];
      soProductSlotTypeCpy.helixHtypeId = helixH.value;
      //Motors
      var motors = this.props.motorTypes.filter((item) => item.name === motorSel.label);
      soProductSlotTypeCpy.motorLtypeId = motors[0].id;

      if (motorConfigSelId == 1)
        //MotorConfig two motors
        soProductSlotTypeCpy.motorHtypeId = motors[1].id;
      //MotorConfig Double
      else soProductSlotTypeCpy.motorHtypeId = null;
    }
    this.setState({
      soProductSlotTypeDto: soProductSlotTypeCpy,
    });
    return soProductSlotTypeCpy;
  }
  vmTrayPositionHandleChange(e) {
    //Set Selection
    trayPosSel = e;

    //Set property in object to return
    let soProductSlotTypeCpy = Object.assign({}, this.state.soProductSlotTypeDto);
    var vmTrayPositionIdx = this.props.vmTrayPositions[e.value].value;
    soProductSlotTypeCpy.vmtraypositionId = vmTrayPositionIdx;

    this.setState({
      soProductSlotTypeDto: soProductSlotTypeCpy,
    });
    return soProductSlotTypeCpy;
  }
  vmSlotPositionHandleChange(e) {
    //Set Selection
    slotPosSel = e;

    //Set property in object to return
    let soProductSlotTypeCpy = Object.assign({}, this.state.soProductSlotTypeDto);
    var vmSlotPositionIdx = this.props.vmSlotPositions[e.value].value;
    soProductSlotTypeCpy.vmslotpositionId = vmSlotPositionIdx;
    this.setState({
      soProductSlotTypeDto: soProductSlotTypeCpy,
    });
    return soProductSlotTypeCpy;
  }
  alignerHandleChange(e) {
    //Set property in object to return
    let soProductSlotTypeCpy = Object.assign({}, this.state.soProductSlotTypeDto);
    var hasAligner;
    if (e.target) {
      hasAligner = e.target.checked ? 1 : 0;
      hasAlignerSel = e.target.checked;
    } else {
      hasAligner = e ? 1 : 0;
      hasAlignerSel = e;
    }
    soProductSlotTypeCpy.hasProductAligner = hasAligner;
    this.setState({
      soProductSlotTypeDto: soProductSlotTypeCpy,
    });
    return soProductSlotTypeCpy;
  }
  sliderHandleChange(e) {
    //Set property in object to return
    let soProductSlotTypeCpy = Object.assign({}, this.state.soProductSlotTypeDto);
    var hasSlider;
    if (e.target) {
      hasSlider = e.target.checked ? 1 : 0;
      hasSliderSel = e.target.checked;
    } else {
      hasSlider = e;
      hasSliderSel = e;
    }
    soProductSlotTypeCpy.hasSlidingBase = hasSlider;
    this.setState({
      soProductSlotTypeDto: soProductSlotTypeCpy,
    });
    return soProductSlotTypeCpy;
  }

  handleOk = () => {
    this.setState({
      soProductSlotTypeDto: {
        id: null,
        vmslotsizeId: null,
        motorLtypeId: null,
        motorHtypeId: null,
        helixLtypeId: null,
        helixHtypeId: null,
        soproductId: null,
        vmslotpositionId: null,
        vmtraypositionId: null,
        hasHelixAligner: 0,
        hasProductAligner: 0,
        hasSlidingBase: 0,
      },
    });
    vmSLotSizeSel = null;
    motorConfigSelId = null;
    motorSel = null;
    helixSel = null;
    trayPosSel = null;
    slotPosSel = null;
    hasAlignerSel = 0;
    hasSliderSel = 0;
  };

  addSoProductSlotTypeHandleEvent(e) {
    this.setState({
      loading: true,
    });
    //Validate Object
    let motorOk = this.state.soProductSlotTypeDto.motorLtypeId != null;
    let sizeOk = this.state.soProductSlotTypeDto.vmslotsizeId != null;
    let productOk = this.state.soProductSlotTypeDto.soproductId != null;
    let helixOk = this.state.soProductSlotTypeDto.helixLtypeId != null;
    let trayOk = this.state.soProductSlotTypeDto.vmtraypositionId != null;
    let slotOk = this.state.soProductSlotTypeDto.vmslotpositionId != null;
    let alignerOk = this.state.soProductSlotTypeDto.hasProductAligner != null;
    let sliderOK = this.state.soProductSlotTypeDto.hasSlidingBase != null;
    if (motorOk && sizeOk && productOk && helixOk && trayOk && slotOk && alignerOk && sliderOK) {
      //Post Object
      this.props.addSoProductSlotTypeAction(this.state.soProductSlotTypeDto, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          //Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            loading: false,
          });
        } else {
          //Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.handleOk,
          });
          this.setState({
            loading: false,
          });
        }
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
      this.setState({
        loading: false,
      });
    }
  }
  noProductWarning() {
    Modal.warning({
      title: Strings.product.noProductSelected,
      onOk: this.handleOkNoProductWarning,
      content: Strings.product.noProductSelectedMsg,
    });
  }
  handleOkNoProductWarning() {
    this.setState({ redirectBackToSoProdSlotType: true });
  }

  //----------Functions--------------
  conformMotorOptions() {
    //Conform motor options
    var motorOptionsRaw = [];
    this.props.motorTypes.forEach((item) => {
      if (motorOptionsRaw.filter((elem) => elem.label === item.name) == 0 && item.turnType) {
        motorOptionsRaw.push({
          value: motorOptionsRaw.length,
          label: item.name,
        });
      }
    });
    return motorOptionsRaw;
  }

  mapSoProdSlotTypeToEdit() {
    //Set properties from SoProductSlotTypeToEdit to Select options using the handle events
    if (this.props.location.props) {
      let soProdSlotTypeToEditRaw = this.props.location.props.soProdSlotTypeToEdit;
      if (soProdSlotTypeToEditRaw) {
        //Given that setState called in each HandleChange are grouped, create object copy and set attributes, finally call setState with complete object
        let soProductSlotTypeCpy = Object.assign({}, this.state.soProductSlotTypeDto);
        //Map VmSlotSize
        let vmSlotSize = this.props.vmSlotSizes.filter((item) => item.label === soProdSlotTypeToEditRaw.slotSizeName)[0];
        let soProdSlotType = this.sizeHandleChange(vmSlotSize);
        //Assgin vmslotsizeId, soproductId, id to copy
        soProductSlotTypeCpy.vmslotsizeId = soProdSlotType.vmslotsizeId;
        soProductSlotTypeCpy.soproductId = soProdSlotType.soproductId;
        soProductSlotTypeCpy.id = soProdSlotType.id;
        //Map MotorConfig
        let motorConfig = motorTypeConfigLocalization(soProdSlotTypeToEditRaw.motorL, soProdSlotTypeToEditRaw.motorH);
        this.motorConfigHandleChange(motorConfig);
        //Map Motor
        let motor = this.state.motorOptions.filter((item) => item.label === soProdSlotTypeToEditRaw.motorL)[0];
        this.motorHandleChange(motor);
        //Map Helix
        let helix = helixOptions.filter((item) => item.value !== soProdSlotTypeToEditRaw.helixL.value && _.split(item.label, " - ")[0].includes(_.split(item.label, " - ")[0]))[0];
        soProdSlotType = this.helixHandleChange(helix);
        //Assgin motorLtypeId, motorHtypeId, helixLtypeId, helixHtypeId to copy
        soProductSlotTypeCpy.motorLtypeId = soProdSlotType.motorLtypeId;
        soProductSlotTypeCpy.motorHtypeId = soProdSlotType.motorHtypeId;
        soProductSlotTypeCpy.helixLtypeId = soProdSlotType.helixLtypeId;
        soProductSlotTypeCpy.helixHtypeId = soProdSlotType.helixHtypeId;
        //Map TrayPosition
        let trayPosition = this.props.vmTrayPositions.filter((item) => item.value === soProdSlotTypeToEditRaw.defaultVmTrayPositionId)[0];
        soProdSlotType = this.vmTrayPositionHandleChange(trayPosition);
        //Assgin vmtraypositionId to copy
        soProductSlotTypeCpy.vmtraypositionId = soProdSlotType.vmtraypositionId;
        //Map SlotPosition
        let slotPosition = this.props.vmSlotPositions.filter((item) => item.value === soProdSlotTypeToEditRaw.defaultVmSlotPositionId)[0];
        soProdSlotType = this.vmSlotPositionHandleChange(slotPosition);
        //Assgin vmslotpositionId to copy
        soProductSlotTypeCpy.vmslotpositionId = soProdSlotType.vmslotpositionId;
        //Map Aligner
        soProdSlotType = this.alignerHandleChange(soProdSlotTypeToEditRaw.hasProductAligner);
        //Assgin hasProductAligner to copy
        soProductSlotTypeCpy.hasProductAligner = soProdSlotType.hasProductAligner;
        //Map Slider
        soProdSlotType = this.sliderHandleChange(soProdSlotTypeToEditRaw.hasSlidingBase);
        //Assgin hasSlidingBase to copy
        soProductSlotTypeCpy.hasSlidingBase = soProdSlotType.hasSlidingBase;
        //setState to Dto object
        this.setState({
          soProductSlotTypeDto: soProductSlotTypeCpy,
        });
      }
    }
  }

  render() {
    if (this.state.redirectBackToSoProdSlotType) {
      //Redirects to SoProductSlotType from No Product Modal Warning
      return <Redirect to="/soProductSlotType" />;
    }
    if (!this.state.selectedProduct) {
      //Shows No Product Modal Warning
      return <div>{this.noProductWarning()}</div>;
    }
    return (
      <div className="content-container">
        <div className="row">
          <Titles
            title={this.state.soProductSlotTypeToEditRaw == null ? Strings.product.newSoProductSlotType : Strings.product.editSoProductSlotType}
            tooltip={Strings.product.soProductSlotTypeTooltip}
          />
        </div>

        <div className="row">
          <div className="col-12">
            <Divider orientation="left">
              <h3> {this.state.selectedProduct.label} </h3>
            </Divider>
          </div>

          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.planogram.size}
              tooltip={Strings.planogram.sizeTooltip}
              options={SlotSizeNameLocalizationArray(this.props.vmSlotSizes)}
              onChange={(e) => this.sizeHandleChange(e)}
              defaultValue={vmSLotSizeSel != null ? vmSLotSizeSel.value : null}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.planogram.motorConfig}
              tooltip={Strings.planogram.motorConfigTooltip}
              options={this.state.motorConfigOptions}
              onChange={(e) => this.motorConfigHandleChange(e)}
              defaultValue={motorConfigSelId}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.planogram.motor}
              tooltip={Strings.planogram.motorTooltip}
              options={this.state.motorOptionsFiltered}
              onChange={(e) => this.motorHandleChange(e)}
              defaultValue={motorSel != null ? motorSel.value : null}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.planogram.helix}
              tooltip={Strings.planogram.helixTooltip}
              options={this.state.helixOptions}
              onChange={(e) => this.helixHandleChange(e)}
              defaultValue={helixSel != null ? helixSel.value : null}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.planogram.defaultTrayPosition}
              tooltip={Strings.planogram.defaultTrayPositionTooltip}
              options={trayPositionNameLocalizationArray(this.props.vmTrayPositions)}
              onChange={(e) => this.vmTrayPositionHandleChange(e)}
              defaultValue={trayPosSel != null ? trayPosSel.value : null}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.planogram.defaultSlotPosition}
              tooltip={Strings.planogram.defaultSlotPositionTooltip}
              options={slotPositionNameLocalizationArray(this.props.vmSlotPositions)}
              onChange={(e) => this.vmSlotPositionHandleChange(e)}
              defaultValue={slotPosSel != null ? slotPosSel.value : null}
            />
          </div>
          <div className="vertSpace col-md-6">
            <GeneralCheckbox
              text={Strings.planogram.productAligner}
              tooltip={Strings.planogram.productAlignerTooltip}
              onChange={(e) => this.alignerHandleChange(e)}
              isChecked={hasAlignerSel}
            />
          </div>
          <div className="vertSpace col-md-6">
            <GeneralCheckbox
              text={Strings.planogram.slidingBase}
              tooltip={Strings.planogram.slidingBaseTooltip}
              onChange={(e) => this.sliderHandleChange(e)}
              isChecked={hasSliderSel}
            />
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="vertSpace col-6 col-md-3">
            <ReturnButton link="/soProductSlotType" />
          </div>
          <div className="vertSpace col-6 col-md-3">
            <SaveButton onClick={(e) => this.addSoProductSlotTypeHandleEvent(e)} loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vmTrayPositions: state.vmTrayPosition.vmTrayPositionObj,
    vmSlotPositions: state.vmSlotPosition.vmSlotPositionObj,
    motorTypes: state.motorType.motorTypeObj,
    helixTypes: state.helixType.helixTypeObj,
    vmSlotSizes: state.vmSlotSize.vmSlotSizeObj,
  };
}

export default connect(mapStateToProps, {
  addSoProductSlotTypeAction,
})(AddSoProductSlotTypeForm);
