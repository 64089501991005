import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/peripheraldevicetypes";

export function getAllPeripheralDeviceTypes(callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

/**
 * It's an async function that makes a GET request to an API endpoint, and returns the response.
 * @returns The response object from the axios call.
 */
export async function getAllPeripheralDeviceTypesAsync() {
  try {
    const response = await axios.get(host + port + path + version + resource, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export function getMissingPeripheralDevicesInVendingMachine(vendingMachineId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/vmmissingperipherals`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
        params: { vendingMachineId },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getMissingPeripheralDevicesInWings(vendingMachineId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/wingsmissingperipherals`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
        params: { vendingMachineId },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getMissingPeripheralDevicesInSlotGroups(vendingMachineId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/slotgroupmissingperipherals`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
        params: { vendingMachineId },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
