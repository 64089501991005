import axios from "axios";

import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/eventtype";

export function getEventTypesByCategoryId(categoryId, callback) {
    return () => {
        //axios.get(host + path + resource,{
        axios.get(host + port + path + version + resource, {
            params: {
                categoryid: categoryId
            },
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
            },
        })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                callback(error);
            })
    }
}