/* eslint-disable camelcase */
import { Divider, Icon, Modal, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";

// -----Components-----
import Loading from "../GenericComponents/loadingComponent/loading";
import { SelectGeneral } from "../GenericComponents/selectComponent/index";

// -----Actions-------
import { getLockerInfoByWingId, getProductInfoByWingId, getWingTypeById, listWingInfoByMachineId } from "../../actions";

// Machine images
import vm_ambienti from "../Global/images/vm_ambienti1.png";
import vm_chill_frozen from "../Global/images/vm_chill_frozen.png";
import vmLockerStandAlone from "../Global/images/vmLockerStandAlone.png";

// Language localization
import Strings from "../../systemVariables/languageStrings";
import { lockerModalitiesLocalization } from "../../Utils/LanguageLocalization";

const { Column } = Table;

class MachineDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isLoading: false,
      isPlanogramInfoLoading: false,
      isConfigured: false,
      isSingleWing: true,
      showSection: 0,
      wingType: null,
      lockerTypeDTO: [],
      lockerSlotTypeDTO: [],
      productReferences: null,
      vmTraysTotal: null,
      productsAmountAvailable: null,
      multipleWingInfo: [],
      wingId: null,
    };
  }

  handleCancel = () => {
    this.setState({
      isVisible: false,
      isLoading: false,
      isPlanogramInfoLoading: false,
      isConfigured: false,
      isSingleWing: true,
      showSection: 0,
      wingType: null,
      lockerTypeDTO: [],
      lockerSlotTypeDTO: [],
      productReferences: null,
      vmTraysTotal: null,
      productsAmountAvailable: null,
      multipleWingInfo: [],
      wingId: null,
    });
  };

  handleDetails = (vendingMachineSelected) => {
    this.setState({ isVisible: true, isLoading: true });
    const { listWingInfoByMachineId, getProductInfoByWingId, getWingTypeById, getLockerInfoByWingId } = this.props;

    // If machine is configured, check if it's a single module o if it has multiple modules
    if (vendingMachineSelected.isConfigured === "1") {
      this.setState({ isConfigured: true });

      listWingInfoByMachineId(vendingMachineSelected.id, (response) => {
        const wingsInfo = response.data.data;

        // When the vm only has one module and the mv is configured
        if (wingsInfo.length > 0 && wingsInfo.length === 1) {
          this.setState({ isSingleWing: true, showSection: 1 });
          getWingTypeById(wingsInfo[0].wingTypeId, (response) => {
            this.setState({ wingType: response.data.data });
          });

          // This section is used when the module is locker type
          if (wingsInfo[0].wingTypeId === 3) {
            getLockerInfoByWingId(wingsInfo[0].id, (response) => {
              this.setState({
                lockerTypeDTO: response.data.data.lockerTypeDTO,
                lockerSlotTypeDTO: response.data.data.lockerSlotTypeDTO,
              });
            });
          }
          this.setState({ isPlanogramInfoLoading: true });

          getProductInfoByWingId(wingsInfo[0].id, (response) => {
            // This section is used when there is a  configured planogram
            if (response.data.data.code === 0 && response.data.status === "SUCCESS") {
              this.setState({
                productReferences: response.data.data.data[0],
                vmTraysTotal: response.data.data.data[1],
                productsAmountAvailable: response.data.data.data[2],
                isPlanogramInfoLoading: false,
              });
            }
          });
        }

        // When the vm has multiple modules and it's configured
        if (wingsInfo.length > 0 && wingsInfo.length > 1) {
          this.setState({ isSingleWing: false, showSection: 0 });
          const multipleWingInfo = [];
          wingsInfo.forEach((wing) => {
            multipleWingInfo.push({
              value: wing.id,
              label: wing.wingNumber,
              wingTypeId: wing.wingTypeId,
            });
          });
          this.setState({ multipleWingInfo });
        }
        this.setState({ isLoading: false });
      });
    } else {
      // If machine isn't congifured, check only the basic information
      this.setState({ isConfigured: false });

      listWingInfoByMachineId(vendingMachineSelected.id, (response) => {
        const wingsInfo = response.data.data;

        // When the vm only has one module and the mv is not configured
        if (wingsInfo.length > 0 && wingsInfo.length === 1) {
          this.setState({ isSingleWing: true, showSection: 1 });
          getWingTypeById(wingsInfo[0].wingTypeId, (response) => {
            this.setState({ wingType: response.data.data });
          });

          // This section is used when the module is locker type
          if (wingsInfo[0].wingTypeId === 3) {
            getLockerInfoByWingId(wingsInfo[0].id, (response) => {
              this.setState({
                lockerTypeDTO: response.data.data.lockerTypeDTO,
                lockerSlotTypeDTO: response.data.data.lockerSlotTypeDTO,
              });
            });
          }
        }

        if (wingsInfo.length > 0 && wingsInfo.length > 1) {
          // When the vm has multiple modules but it's not configured
          this.setState({ isSingleWing: false, showSection: 0 });
          const multipleWingInfo = [];
          wingsInfo.forEach((wing) => {
            multipleWingInfo.push({
              value: wing.id,
              label: wing.wingNumber,
              wingTypeId: wing.wingTypeId,
            });
          });
          this.setState({ multipleWingInfo });
        }
        this.setState({ isLoading: false });
      });
    }
  };

  handleWingSelection(event) {
    const { getWingTypeById, getLockerInfoByWingId, getProductInfoByWingId } = this.props;
    this.setState({ showSection: 1, wingId: event.value });

    getWingTypeById(event.wingTypeId, (response) => {
      this.setState({ wingType: response.data.data });
    });

    // This section is used when the module is locker type
    if (event.wingTypeId === 3) {
      getLockerInfoByWingId(event.value, (response) => {
        this.setState({
          lockerTypeDTO: response.data.data.lockerTypeDTO,
          lockerSlotTypeDTO: response.data.data.lockerSlotTypeDTO,
        });
      });
    }

    this.setState({ isPlanogramInfoLoading: true });
    getProductInfoByWingId(event.value, (response) => {
      if (response.data) {
        this.setState({ isLoading: false });
      }
      if (response.data && response.data.status === "SUCCESS") {
        this.setState({
          productReferences: response.data.data.data[0],
          vmTraysTotal: response.data.data.data[1],
          productsAmountAvailable: response.data.data.data[2],
          isPlanogramInfoLoading: false,
        });
      }
    });
  }

  render() {
    const { vendingMachineSelected } = this.props;
    const {
      isVisible,
      isLoading,
      isPlanogramInfoLoading,
      isConfigured,
      isSingleWing,
      showSection,
      wingId,
      wingType,
      productReferences,
      vmTraysTotal,
      productsAmountAvailable,
      multipleWingInfo,
      lockerTypeDTO,
      lockerSlotTypeDTO,
    } = this.state;

    return (
      <div>
        <Modal title={Strings.machine.machineDetails} onCancel={this.handleCancel} width="55%" footer={[]} visible={isVisible} centered>
          {isSingleWing === false ? (
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-6">
                <SelectGeneral
                  text={<b>{Strings.machine.wingNumber}</b>}
                  options={multipleWingInfo || null}
                  isReq
                  onChange={(e) => this.handleWingSelection(e)}
                  defaultValue={wingId}
                />
              </div>
              <div className="col-md-5" />
            </div>
          ) : (
            ""
          )}

          {showSection === 1 ? (
            <div>
              {isLoading === true ? (
                <div>
                  <div className="row">{isSingleWing === false ? <Divider /> : ""}</div>
                  <div className="row">
                    <Loading />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row">{isSingleWing === false ? <Divider /> : ""}</div>
                  <div className="row ">
                    <div className="col-md-5 col-xs-12">
                      <div>
                        {wingType ? (
                          <div>
                            {wingType.id === 0 ? (
                              <div className="col-md-12" style={{ textAlign: "center" }}>
                                <img src={vm_ambienti} id="vendingMachine" alt="" width={220} height={120} />
                              </div>
                            ) : (
                              ""
                            )}
                            {wingType.id === 1 ? (
                              <div className="col-md-12" style={{ textAlign: "center" }}>
                                <img src={vm_chill_frozen} id="vendingMachine" alt="" width={290} height={100} />
                              </div>
                            ) : (
                              ""
                            )}
                            {wingType.id === 2 ? (
                              <div className="col-md-12" style={{ textAlign: "center" }}>
                                <img src={vm_chill_frozen} id="vendingMachine" alt="" width={290} height={100} />
                              </div>
                            ) : (
                              ""
                            )}
                            {wingType.id === 3 ? (
                              <div className="col-md-12" style={{ textAlign: "center" }}>
                                <img src={vmLockerStandAlone} id="locker" alt="" width={290} height={300} />
                              </div>
                            ) : (
                              ""
                            )}
                            {wingType.id === 4 ? (
                              <div className="col-md-12" style={{ textAlign: "center" }}>
                                <img src={vm_ambienti} id="vendingMachine" alt="" width={220} height={120} />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-7 col-xs-12">
                      <div>
                        {wingType ? (
                          <div>
                            <div className="row">
                              <div className="col-md-12">
                                <p>
                                  <b>{Strings.machine.wingType}:</b> {wingType.name}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <p>
                                  <b>{Strings.generalTerms.description}:</b> {wingType.description}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                {isConfigured === true ? (
                                  <p>
                                    <b>{Strings.generalTerms.state}:</b> {Strings.machine.machineConfigured}{" "}
                                  </p>
                                ) : (
                                  <p>
                                    <b>{Strings.generalTerms.state}:</b> {Strings.machine.machineNoConfigured}{" "}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div>
                              {wingType.id === 3 ? (
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>
                                        <b>{Strings.machine.lockerType}:</b> {lockerTypeDTO.name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <p>{lockerTypeDTO.description}</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      {lockerSlotTypeDTO ? (
                                        <Table dataSource={lockerSlotTypeDTO} pagination={false} bordered size="middle" footer={null} rowKey="id" style={{ margin: "auto" }}>
                                          <Column
                                            key="id"
                                            width={170}
                                            title={<b>{Strings.machine.lockerModalities}</b>}
                                            render={(row) => <div>{lockerModalitiesLocalization(row.name)} </div>}
                                          />
                                          <Column width={230} title={<b>{Strings.generalTerms.description}</b>} dataIndex="description" />
                                        </Table>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {isConfigured === true ? (
                          <div>
                            {isPlanogramInfoLoading === true ? (
                              <div>
                                <Divider orientation="left"> {Strings.planogram.planogramReview} </Divider>
                                <div className="row">
                                  <Loading />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <Divider orientation="left"> {Strings.planogram.planogramReview} </Divider>
                                <div className="row">
                                  <div className="col-md-12">
                                    <p>
                                      <b>{Strings.product.productQuantity}:</b> {productReferences}
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <p>
                                      <b>{Strings.machine.trayQuantity}:</b> {vmTraysTotal}
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <p>
                                      <b>{Strings.product.availableProducts}:</b> {productsAmountAvailable}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <Divider orientation="left"> {Strings.planogram.planogramReview} </Divider>
                            <div className="vertSpace row">
                              <div className="col-md-1" />
                              <div className="col-md-11">
                                <span>{Strings.planogram.noWingPlanogram}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </Modal>

        <div style={{ align: "center", color: "#004173", cursor: "pointer" }}>
          <Icon
            type="profile"
            theme="twoTone"
            style={{ cursor: "pointer", fontSize: 16 }}
            className="addEditRemoveButton"
            onClick={() => this.handleDetails(vendingMachineSelected)}
          />
        </div>
      </div>
    );
  }
}

export default connect(null, {
  getWingTypeById,
  getLockerInfoByWingId,
  getProductInfoByWingId,
  listWingInfoByMachineId,
})(MachineDetailsModal);
