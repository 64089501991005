/* eslint-disable no-unused-vars */
// Dependencies
import { Checkbox, Divider, Input, Modal, Spin, Table } from "antd";
import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Queries
import {
  getVendingMachineInfoById,
  getVmEventMessagingRegistersByVmId,
  listVmSetupByVmId,
  updateVmEventMessaging,
  updateVmEventMessagingList,
  updateVmSetupById,
} from "../../../actions";

// Components
// import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import { AddButton } from "../../GenericComponents/buttons/index";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

const { Column } = Table;

class VmEventMessaging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectBackToListVendingMachine: false,
      vendingMachineInfo: {
        id: null,
        name: null,
        scDependencyId: null,
        scDepdencyName: null,
      },
      phoneConfig: {
        vmSetupId: null,
        value: null,
      },
      emailConfig: {
        vmSetupId: null,
        value: null,
      },

      isSaveEmailButtonLoading: false,
      isSavePhoneButtonLoading: false,

      isLoadingInfo: true,
      vmEventMessaging: [],

      countryCode: "co",
      isSaveButtonLoading: false,
    };
  }

  componentDidMount() {
    const { vm, getVendingMachineInfoById, getVmEventMessagingRegistersByVmId, listVmSetupByVmId } = this.props;

    if (vm.id) {
      getVendingMachineInfoById(vm.id, (response) => {
        const vmInfo = response.data.data;
        const vendingMachineInfo = {
          id: vmInfo.vmId,
          name: vmInfo.friendlyName,
          scDepdencyId: vmInfo.scDependencyId,
          scDepdencyName: vmInfo.scDependencyName,
        };
        listVmSetupByVmId(vm.id, 0, 0, (response) => {
          const vmSetupParameters = response.data.data;

          let { phoneConfig } = this.state;
          let { emailConfig } = this.state;

          vmSetupParameters.forEach((obj) => {
            if (obj.setupparameterid === 104) {
              phoneConfig.vmSetupId = obj.id;
              phoneConfig.value = obj.stringValue ? obj.stringValue : null;
            }

            if (obj.setupparameterid === 105) {
              emailConfig.vmSetupId = obj.id;
              emailConfig.value = obj.stringValue ? obj.stringValue : null;
            }
          });
          this.setState({
            phoneConfig,
            emailConfig,
          });
        });
        getVmEventMessagingRegistersByVmId(vm.id, (response) => {
          let vmEventMessaging = response.data.data;

          vmEventMessaging.forEach((obj) => {
            obj.loadingSms = false;
            obj.loadingEmail = false;
            obj.loadingAll = false;
          });

          this.setState({
            vendingMachineInfo,
            vmEventMessaging,
            isLoadingInfo: false,
          });
        });
      });
    } else {
      this.noVMWarning();
    }
  }

  //-----Events-----
  handleNoVMAcceptButton = () => {
    this.setState({ redirectToVMList: true });
  };
  noVMWarning = () => {
    Modal.warning({
      title: Strings.machine.noVMSelected,
      onOk: this.handleNoVMAcceptButton,
      content: Strings.machine.noVMSelectedMsg,
    });
  };

  sendSmsCheckbox = (e, id) => {
    const checked = e.target.checked;
    const { updateVmEventMessaging } = this.props;
    const { vmEventMessaging } = this.state;

    vmEventMessaging.find((obj) => {
      if (obj.id === id) {
        obj.loadingSms = true;
        obj.loadingAll = true;
      }
      return null;
    });

    this.setState({
      vmEventMessaging,
    });

    const dto = {
      id,
      sendSms: checked ? 1 : 0,
    };

    updateVmEventMessaging(dto, (response) => {
      const vmEventMessagingSaved = response.data.data;

      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        vmEventMessaging.find((obj) => {
          if (obj.id === vmEventMessagingSaved.id) {
            obj.loadingSms = false;
            obj.loadingAll = false;
          }
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      } else if (response.data.code === 4001) {
        // Entity Already exists and is enabled
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        vmEventMessaging.find((obj) => {
          if (obj.id === vmEventMessagingSaved.id) {
            obj.loadingSms = false;
            obj.loadingAll = false;
          }
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      } else if (response.data.code === 4013) {
        // Entity Already exists and is disabled
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        vmEventMessaging.find((obj) => {
          if (obj.id === vmEventMessagingSaved.id) {
            obj.loadingSms = false;
            obj.loadingAll = false;
          }
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      } else if (response.data.code === 0) {
        // // Entity successfully saved
        // Modal.success({
        //   title: Strings.generalResponses.successfulUpdate,
        //   content: `${Strings.generalResponses.successfulUpdate}`,
        // });

        vmEventMessaging.find((obj) => {
          if (obj.id === vmEventMessagingSaved.id) {
            obj.sendSms = vmEventMessagingSaved.sendSms;
            obj.loadingSms = false;
            obj.loadingAll = false;
          }
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      }
    });
  };

  sendEmailCheckbox = (e, id) => {
    const checked = e.target.checked;
    const { updateVmEventMessaging } = this.props;
    const { vmEventMessaging } = this.state;

    vmEventMessaging.find((obj) => {
      if (obj.id === id) {
        obj.loadingEmail = true;
        obj.loadingAll = true;
      }
      return null;
    });

    this.setState({
      vmEventMessaging: vmEventMessaging,
    });

    const dto = {
      id,
      sendEmail: checked ? 1 : 0,
    };

    updateVmEventMessaging(dto, (response) => {
      const vmEventMessagingSaved = response.data.data;

      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        vmEventMessaging.find((obj) => {
          if (obj.id === vmEventMessagingSaved.id) {
            obj.loadingEmail = false;
            obj.loadingAll = false;
          }
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      } else if (response.data.code === 4001) {
        // Entity Already exists and is enabled
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        vmEventMessaging.find((obj) => {
          if (obj.id === vmEventMessagingSaved.id) {
            obj.loadingEmail = false;
            obj.loadingAll = false;
          }
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      } else if (response.data.code === 4013) {
        // Entity Already exists and is disabled
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        vmEventMessaging.find((obj) => {
          if (obj.id === vmEventMessagingSaved.id) {
            obj.loadingEmail = false;
            obj.loadingAll = false;
          }
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      } else if (response.data.code === 0) {
        vmEventMessaging.find((obj) => {
          if (obj.id === vmEventMessagingSaved.id) {
            obj.sendEmail = vmEventMessagingSaved.sendEmail;
            obj.loadingEmail = false;
            obj.loadingAll = false;
          }
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      }
    });
  };

  onChangePhone = (phone, country) => {
    const { phoneConfig } = this.state;
    const { countryCode } = country;
    const value = phone;

    phoneConfig.value = `+${value}`;

    this.setState({ phoneConfig, countryCode });
  };

  onChangeEmail = (event) => {
    const { emailConfig } = this.state;
    const { value } = event.target;

    emailConfig.value = value;

    this.setState({ emailConfig });
  };

  onClickUpdateGeneralParameters = (id) => {
    const { updateVmSetupById } = this.props;
    let vmsetupid = null;
    let newvalue = null;
    let validateEmail = true;

    if (id === 1) {
      const { phoneConfig } = this.state;
      vmsetupid = phoneConfig.vmSetupId;
      newvalue = phoneConfig.value;

      this.setState({
        isSavePhoneButtonLoading: true,
      });
    } else if (id === 2) {
      const { emailConfig } = this.state;
      let empyEmail = false;
      let estructurateEmail = false;

      const regexEmail = /^[\w._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      estructurateEmail = regexEmail.test(emailConfig.value);

      empyEmail = emailConfig.value === "";

      validateEmail = estructurateEmail || empyEmail;

      vmsetupid = emailConfig.vmSetupId;
      newvalue = emailConfig.value;

      this.setState({
        isSaveEmailButtonLoading: true,
      });
    }

    if (validateEmail) {
      updateVmSetupById(vmsetupid, newvalue, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            isSavePhoneButtonLoading: false,
            isSaveEmailButtonLoading: false,
          });
        } else if (response.data.code === 4001) {
          // Entity Already exists and is enabled
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            isSavePhoneButtonLoading: false,
            isSaveEmailButtonLoading: false,
          });
        } else if (response.data.code === 4013) {
          // Entity Already exists and is disabled
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            isSavePhoneButtonLoading: false,
            isSaveEmailButtonLoading: false,
          });
        } else if (response.data.code === 0) {
          // Entity successfully saved
          Modal.success({
            title: Strings.generalResponses.successfulUpdate,
            content: `${Strings.generalResponses.successfulUpdate}`,
          });
          this.setState({
            isSavePhoneButtonLoading: false,
            isSaveEmailButtonLoading: false,
          });
        }
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
      this.setState({
        isSavePhoneButtonLoading: false,
        isSaveEmailButtonLoading: false,
      });
    }
  };

  onClickApplyToAll = (row) => {
    const { vendingMachineInfo: scDepdencyId, vmEventMessaging } = this.state;
    const { updateVmEventMessagingList } = this.props;

    vmEventMessaging.find((obj) => {
      obj.id === row.id && (obj.loadingAll = true);
      return null;
    });

    this.setState({
      vmEventMessaging,
    });

    updateVmEventMessagingList(scDepdencyId.scDepdencyId, row.eventType.id, row.sendEmail, row.sendSms, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });

        vmEventMessaging.find((obj) => {
          obj.id === row.id && (obj.loadingAll = false);
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      } else if (response.data.code === 4001) {
        // Entity Already exists and is enabled
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });

        vmEventMessaging.find((obj) => {
          obj.id === row.id && (obj.loadingAll = false);
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      } else if (response.data.code === 4013) {
        // Entity Already exists and is disabled
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        vmEventMessaging.find((obj) => {
          obj.id === row.id && (obj.loadingAll = false);
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      } else if (response.data.code === 0) {
        // Entity successfully saved
        Modal.success({
          title: Strings.generalResponses.successfulUpdate,
          content: `${Strings.generalResponses.successfulUpdate}`,
        });
        vmEventMessaging.find((obj) => {
          obj.id === row.id && (obj.loadingAll = false);
          return null;
        });

        this.setState({
          vmEventMessaging,
        });
      }
    });
  };

  render() {
    const { redirectToVMList, isLoadingInfo, vmEventMessaging, phoneConfig, emailConfig, isSavePhoneButtonLoading, isSaveEmailButtonLoading } = this.state;

    if (redirectToVMList) return <Redirect to={{ pathname: "/listVM" }} />;

    return (
      <div className="row">
        <div className="col">
          <Divider orientation="left">
            <h3>{Strings.generalTerms.generalConfigs}</h3>
          </Divider>

          <div className="row ">
            <div className="col-md-6">
              <div className="row vertSpace">
                <div className="col-md-2">
                  <TextWithInfoTooltip name={Strings.generalTerms.phone} tooltip={Strings.vmEventMessaging.smsPhone} />
                </div>
                <div className="col-md-6" style={{ alignSelf: "center" }}>
                  <PhoneInput
                    country={"co"}
                    value={phoneConfig.value}
                    // autoFormat={false}
                    disableDropdown
                    countryCodeEditable={false}
                    onChange={(phone, country) => this.onChangePhone(phone, country)}
                  />
                </div>
                <div className="col-md-2">
                  <AddButton loading={isSavePhoneButtonLoading} onClick={() => this.onClickUpdateGeneralParameters(1)} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row vertSpace">
                <div className="col-md-2">
                  <TextWithInfoTooltip name={Strings.generalTerms.email} tooltip={Strings.vmEventMessaging.email} />
                </div>
                <div className="col-md-6" style={{ alignSelf: "center" }}>
                  <Input type={"email"} className="basic-single" placeholder={Strings.generalTerms.email} onChange={(e) => this.onChangeEmail(e)} value={emailConfig.value} />
                </div>
                <div className="col-md-2">
                  <AddButton loading={isSaveEmailButtonLoading} onClick={() => this.onClickUpdateGeneralParameters(2)} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 vertSpace" style={{ maxHeight: "400px", overflowY: "auto" }}>
            <Table dataSource={vmEventMessaging} bordered loading={isLoadingInfo} pagination={false} size="middle" scroll={{ y: 250 }} rowKey="id">
              <Column
                title={Strings.vmEvent.vmEvent}
                render={(row) => (
                  <div>
                    {row.eventType.name} {row.loadingSms && "hola"}
                  </div>
                )}
              />
              <Column
                title={Strings.vmEventMessaging.sendSms}
                align="center"
                render={(row) => (row.loadingSms ? <Spin /> : <Checkbox checked={row.sendSms} onChange={(e) => this.sendSmsCheckbox(e, row.id)} />)}
              />
              <Column
                title={Strings.vmEventMessaging.sendEmail}
                align="center"
                render={(row) => (row.loadingEmail ? <Spin /> : <Checkbox checked={row.sendEmail} onChange={(e) => this.sendEmailCheckbox(e, row.id)} />)}
              />
              <Column
                title={Strings.generalTerms.applyToAllVm}
                align="center"
                render={(row) => (
                  <div role="button" tabIndex={0} style={{ color: "#004173", cursor: "pointer" }} onClick={() => this.onClickApplyToAll(row)}>
                    {Strings.generalTerms.assign}
                  </div>
                )}
              />
            </Table>
          </div>
          <Divider />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  getVendingMachineInfoById,
  getVmEventMessagingRegistersByVmId,
  updateVmEventMessaging,
  listVmSetupByVmId,
  updateVmSetupById,
  updateVmEventMessagingList,
})(VmEventMessaging);
