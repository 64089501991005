import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/wings";

export function listWingsByVmId2(vmId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingMachineId2: vmId,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  };
}

export function listWingsByVmId(vmId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingMachineId: vmId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getWingsByVmId(vmId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vmId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function listWingInfoByMachineId(mId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          machineId: mId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getLockerInfoByWingId(wingId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          wingid: wingId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getProductInfoByWingId(wingId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          wid: wingId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function addWingToMachine(wingDto, callback) {
  return () => {
    const data = JSON.stringify(wingDto);
    axios({
      method: "post",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function updateLocker(lockerDto, callback) {
  return () => {
    const data = JSON.stringify(lockerDto);
    axios({
      method: "put",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function disableWingById(wingId, callback) {
  return () => {
    const data = JSON.stringify(wingId);
    axios({
      method: "delete",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error
      });
  };
}
