import axios from "axios";

import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/privileges";

export const LIST_PRIVILEGES  = 'LIST_PRIVILEGES';

export function listPrivilegesBySystemUser(callback){
  return (dispatch) => {
    axios(host+ port + path  + version + resource,{
      method:'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    .then((response) => {
      dispatch( { type: LIST_PRIVILEGES, payload: response.data } );
      callback(response)
    })
    .catch((error) => {
      return(error)
    });
  }
}

export function listPrivilegesByPermissionTypeId(permissionTypeId, callback){
  return () => {
    axios.get(host+ port + path  + version + resource,{
      params: {
        permissiontypeid: permissionTypeId,
    },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    .then((response) => {
      callback(response)
    })
    .catch((error) => {
      callback(error)
    });
  }
}

