import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/rolebyuser";

export const LIST_ROLEBYUSER  = 'LIST_ROLEBYUSER';

export function getRolesByUserId(userId, callback){
  return () => {
    axios.get(host+ port + path  + version + resource,{
      params : {
        id: userId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response)
    })
    .catch(function (error){
      callback(error);
    });
  }
}

export function getUsersByRoleId(
  dependencyType,
  dependencyId,
  roleId,
  identityCardNumber,
  page,
  size,
  attribute,
  order,
  callback){
  return () => {
    axios.get(host+ port + path  + version + resource,{
      params : {
        dependencytype: dependencyType,
        dependencyid: dependencyId,
        identitycardnumber: identityCardNumber,
        roleid: roleId,
        page: page,
        size: size,
        attribute: attribute,
        order: order
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response)
    })
    .catch(function (error){
      callback(error);
    });
  }
}
