import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/scemployeeinformations";

export const LIST_SCEMPLOYEEINFORMATION  = 'LIST_SCEMPLOYEEINFORMATION'
export const ERROR = 'ERROR';

export function addUserScEmployeeInformation(scEmployeeInformation){
  return (dispatch) => {
    let data = JSON.stringify(scEmployeeInformation)
    axios.post(host+ port + path  + version + resource, data, {
      method:'POST',
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': 'true',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
      credentials: 'same-origin',
    })
    .then((response) => {
      dispatch( { type: LIST_SCEMPLOYEEINFORMATION, payload: response.data } );
    })
    .catch(function (error){
      dispatch( { type: ERROR, payload: error.response } );
    });
  }
}

export function exportScEmployeeInformationReport(soDependencyId, scDependencyId, categoryInfo, name, code, attribute, order, fileType, columnHeaders, callback) {
  return (dispatch) => {
    axios.get(host + port + path + version + resource, {
      params: {
        sodependencyid: soDependencyId,
        scdependencyid: scDependencyId,
        categoryinfo: categoryInfo,
        name: name,
        code: code,
        attribute: attribute,
        order: order,
        filetype: fileType,
        columnheaders: encodeURIComponent(columnHeaders)
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        callback(response)
      })
      .catch(function (error) {
        return error;
      });
  }
}