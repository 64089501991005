import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/vmtrayposition";

export const LIST_VMTRAYPOSITION  = 'LIST_VMTRAYPOSITION';
export const ERROR = 'ERROR';

export function listVmTrayPosition(callback){
  return (dispatch) => {
    axios.get(host+ port + path  + version + resource,{
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {      
      dispatch( { type: LIST_VMTRAYPOSITION, payload: response.data.data } );
      callback(response);
    })
    .catch(function (error){
      dispatch( { type: ERROR, payload: error } );
    });
  }
}