import React from "react";
import { GeneralInput } from "../../GenericComponents/InputComponent/index";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import PhoneNumberInput from "../../GenericComponents/InputComponent/PhoneNumberInput";
import Strings from "../../../systemVariables/languageStrings";

const UserForm = (props) => {
  const { user, identityCardTypes, countries, provinces, cities, isIdEditEnabled, countryCode } = props;
  return (
    <div className="row">
      <div className="vertSpace col-md-6 ">
        <GeneralInput
          text={Strings.users.firstName}
          id="firstName"
          value={user.firstName}
          defaultValue={user.firstName}
          placeholder={Strings.users.firstName}
          isReq
          onChange={(e) => props.onChange(e)}
        />
      </div>
      <div className="vertSpace col-md-6">
        <GeneralInput
          text={Strings.users.middleName}
          id="middleName"
          value={user.middleName}
          defaultValue={user.middleName}
          placeholder={Strings.users.middleName}
          onChange={(e) => props.onChange(e)}
        />
      </div>
      <div className="vertSpace col-md-6">
        <GeneralInput
          text={Strings.users.lastName1}
          id="lastName1"
          value={user.lastName1}
          defaultValue={user.lastName1}
          placeholder={Strings.users.lastName1}
          isReq
          onChange={(e) => props.onChange(e)}
        />
      </div>
      <div className="vertSpace col-md-6">
        <GeneralInput
          text={Strings.users.lastName2}
          id="lastName2"
          value={user.lastName2}
          defaultValue={user.lastName2}
          placeholder={Strings.users.lastName2}
          onChange={(e) => props.onChange(e)}
        />
      </div>
      <div className="vertSpace col-md-6">
        <SelectGeneral
          text={Strings.users.identityCardType}
          name="identityCardTypeId"
          options={identityCardTypes}
          defaultValue={user.identityCardTypeId}
          isReq
          onChange={(option, event) => props.onSelect(option, event)}
          disabled={isIdEditEnabled}
        />
      </div>
      <div className="vertSpace col-md-6">
        <GeneralInput
          text={Strings.users.identityCardNumber}
          id="identityCardNumber"
          value={user.identityCardNumber}
          placeholder={Strings.users.identityCardNumber}
          isReq
          onChange={(e) => props.onChange(e)}
          disabled={isIdEditEnabled}
        />
      </div>
      <div className="vertSpace col-md-6">
        <SelectGeneral
          text={Strings.generalTerms.country}
          name="countryId"
          defaultValue={user.countryId}
          options={countries}
          isReq
          onChange={(option, event) => props.onSelect(option, event)}
        />
      </div>
      <div className="vertSpace col-md-6">
        <SelectGeneral
          text={Strings.generalTerms.province}
          name="provinceId"
          defaultValue={user.provinceId}
          options={provinces}
          isReq
          onChange={(option, event) => props.onSelect(option, event)}
        />
      </div>
      <div className="vertSpace col-md-6">
        <SelectGeneral
          text={Strings.generalTerms.city}
          name="cityId"
          defaultValue={user.cityId}
          options={cities}
          isReq
          onChange={(option, event) => props.onSelect(option, event)}
        />
      </div>
      <div className="vertSpace col-md-6">
        <PhoneNumberInput text={Strings.users.phone1} id="phone1" value={user.phone1} countryCode={countryCode} isReq={false} onChange={(e) => props.onChange(e)} />
      </div>
      <div className="vertSpace col-md-6">
        <GeneralInput
          text={Strings.users.phone2}
          id="phone2"
          value={user.phone2}
          defaultValue={user.phone2}
          placeholder={Strings.users.phone2}
          isReq={false}
          onChange={(e) => props.onChange(e)}
        />
      </div>
      <div className="vertSpace col-md-6">
        <GeneralInput
          text={Strings.generalTerms.address}
          id="address"
          value={user.address}
          defaultValue={user.address}
          placeholder={Strings.generalTerms.address}
          isReq={false}
          onChange={(e) => props.onChange(e)}
        />
      </div>
      <div className="vertSpace col-md-6">
        <GeneralInput
          text={Strings.generalTerms.email}
          id="email"
          value={user.email}
          defaultValue={user.email}
          placeholder={Strings.generalTerms.email}
          isReq={false}
          onChange={(e) => props.onChange(e)}
        />
      </div>
      {/* <div className="vertSpace col-md-6">
          <GeneralInput
            text={Strings.users.dateOfBirth}
            id="birthdayDate"
            value={user.birthDate}
            defaultValue={user.birthDate}
            placeholder={Strings.generalTerms.birthDate}
            isReq={false}
            onChange={e => props.onChange(e)}
          />
        </div> */}
    </div>
  );
};
export default UserForm;
