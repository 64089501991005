// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Table, Spin, Button } from "antd";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../systemVariables/TimeConfig";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";
import MachineSyncModal from "./MachineSyncModal";
import RecoveryMachineSyncModal from "./RecoveryMachineSyncModal";
import CleanLogsButton from "./CleanLogsButton";
// Actions
import { getPendingRegistersByVm, recoverVendingMachine, getRecoverVendingMachine, getFirmwareVersionsByMachineGenerationId, cleanTestRegister } from "../../../actions";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";

const { Column } = Table;

class MachineSync extends Component {
  constructor(props) {
    super(props);
    this.state = {
      machine: props.vm,
      spiner: false,
      recoveryToken: null,
      recoveryDate: null,
      nameUser: null,
    };
  }

  componentDidMount() {
    this.getPendingRegistersByVm();
  }

  getPendingRegistersByVm() {
    const { getPendingRegistersByVm } = this.props;
    const { machine } = this.state;
    this.setState({
      loadingInfo: true,
      spiner: true,
    });
    getPendingRegistersByVm(machine.id, (response) => {
      if (response.data !== null && response.data !== undefined) {
        this.setState({
          regsToDownload: response.data.data.regsToDownload,
          regsToUpload: response.data.data.regsToUpload,
          loadingInfo: false,
          spiner: false,
        });
      }
    });
    this.getRecoverVendingMachine(machine);
  }

  getRecoverVendingMachine(machine) {
    const { getRecoverVendingMachine } = this.props;
    getRecoverVendingMachine(machine.id, (response) => {
      if (response.data !== null && response.data !== undefined && response.data !== "") {
        this.setState({
          recoveryToken: response.data.data.recoveryToken,
          recoveryDate: response.data.data.recoveryDate,
          nameUser: response.data.data.nameUser,
        });
      }
    });
  }

  RegsToDownload() {
    const { regsToDownload } = this.state;

    if (regsToDownload && regsToDownload.length) {
      return (
        <Table dataSource={regsToDownload} pagination={false} rowKey="tableName" scroll={{ y: 250 }} bordered size="middle">
          <Column title={Strings.vmSetup.tableName} dataIndex="tableName" width="35%" align="center" />
          <Column title={Strings.vmSetup.regsQty} dataIndex="regsQty" width="20%" align="center" />
          <Column
            title={<TextWithInfoTooltip name={Strings.vmSetup.oldestDate} tooltip={Strings.vmSetup.oldestDateTooltip2} />}
            render={(row) => <span> {moment(row.oldestDate).format(DATE_TIME_FORMAT)} </span>}
            width="25%"
            align="center"
          />
        </Table>
      );
    }

    return (
      <div>
        <p>{Strings.vmSetup.noRegistersToSync}</p>
      </div>
    );
  }

  RegsToUpload() {
    const { regsToUpload } = this.state;
    if (regsToUpload && regsToUpload.length) {
      return (
        <Table dataSource={regsToUpload} pagination={false} rowKey="tableName" scroll={{ y: 250 }} bordered size="middle">
          <Column title={Strings.vmSetup.tableName} dataIndex="tableName" width="35%" align="center" />
          <Column title={Strings.vmSetup.regsQty} dataIndex="regsQty" width="20%" align="center" />
          <Column
            title={<TextWithInfoTooltip name={Strings.vmSetup.oldestDate} tooltip={Strings.vmSetup.oldestDateTooltip} />}
            render={(row) => <span> {moment(row.oldestDate).format(DATE_TIME_FORMAT)} </span>}
            width="25%"
            align="center"
          />
          <Column
            title={<TextWithInfoTooltip name={Strings.generalTerms.reportDate} tooltip={Strings.machine.statusReportDateTooltip} />}
            render={(row) => <span> {moment.utc(row.statusDate).local().format(DATE_TIME_FORMAT)} </span>}
            width="25%"
            align="center"
          />
        </Table>
      );
    }

    return (
      <div>
        <p>{Strings.vmSetup.noRegistersToSync}</p>
      </div>
    );
  }

  update = () => {
    this.getPendingRegistersByVm();
  };

  newMethodGenerateData(loadingInfo, data) {
    const {
      machine: { generationId, scDependencyId, serialNumber, id },
      recoveryToken,
      recoveryDate,
      nameUser,
    } = this.state;
    const { recoverVendingMachine, getRecoverVendingMachine, getFirmwareVersionsByMachineGenerationId, cleanTestRegister } = this.props;
    const validatePrivilegeVmsRecovey = ValidatePermissionForComponentPart("PRIVILEGE VMS SERVICE GENERATE DATA", data);
    const validatePrivilegeVmsDeleteLogs = ValidatePermissionForComponentPart("PRIVILEGE DELETE TEST RECORDS", data);
    return (
      <>
        <div style={{ padding: "0.7%", justifyContent: "center" }} className="col-md-12">
          {scDependencyId && validatePrivilegeVmsRecovey === true ? (
            <div className="col-md-4" style={{ padding: "0.7%", justifyContent: "center" }}>
              <MachineSyncModal
                serialNumber={serialNumber}
                vmId={id}
                recovery={recoverVendingMachine}
                dataApiApp={getFirmwareVersionsByMachineGenerationId}
                generationIdMachine={generationId}
              />{" "}
              {recoveryToken !== undefined && recoveryToken !== null ? (
                <RecoveryMachineSyncModal
                  recoveryToken={recoveryToken}
                  recoveryDate={recoveryDate}
                  nameUser={nameUser}
                  serialNumber={serialNumber}
                  getRecoverVendingMachine={getRecoverVendingMachine}
                />
              ) : null}
            </div>
          ) : (
            <div className="col-md-4" />
          )}
          {validatePrivilegeVmsDeleteLogs === true ? (
            <div className="col-md-4" style={{ padding: "0.7%", justifyContent: "center" }}>
              <CleanLogsButton vmId={id} serialNumber={serialNumber} cleanApiApp={cleanTestRegister} />
            </div>
          ) : (
            <div className="col-md-4" style={{ padding: "0.7%", justifyContent: "center" }} />
          )}
          <div className="col-md-4" style={{ padding: "0.7%", justifyContent: "center" }}>
            <Button type="primary" size="default" onClick={this.update} icon="reload" loading={loadingInfo}>
              {Strings.generalTerms.update}
            </Button>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { spiner, loadingInfo } = this.state;
    const {
      permissions: { data },
    } = this.props;
    return (
      <>
        <div className="row">
          {this.newMethodGenerateData(loadingInfo, data)}
          <Spin spinning={spiner}>
            <div className="col-xs-12 col-md-6">
              <Divider orientation="left">
                <TextWithInfoTooltip name={Strings.machine.regsToDownload} tooltip={Strings.machine.regsToDownloadTooltip} />
              </Divider>
              {this.RegsToDownload()}
            </div>
            <div className="col-xs-12 col-md-6">
              <Divider orientation="left">
                <TextWithInfoTooltip name={Strings.machine.regstToUpload} tooltip={Strings.machine.regstToUploadTooltip} />
              </Divider>
              {this.RegsToUpload()}
            </div>
          </Spin>
        </div>
        <Divider />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}
export default connect(mapStateToProps, {
  getPendingRegistersByVm,
  recoverVendingMachine,
  getRecoverVendingMachine,
  getFirmwareVersionsByMachineGenerationId,
  cleanTestRegister,
})(MachineSync);
