//Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Chart, Axis, Tooltip, Geom, Coord } from "bizcharts";
import _ from "lodash";

//Actions
import { getVmEventsGrouped } from "../../actions";

//Components
import Loading from "../GenericComponents/loadingComponent/loading";

//Language Localization
import Strings from "../../systemVariables/languageStrings";

class VmEventTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      soDependencyId: null,
      scDependencyId: null,
      vendingMachineId: null,
      priorityLow: null,
      priorityHigh: null,
      eventCategoryId: null,
      eventTypeId: null,
      startDate: null,
      endDate: null,

      dataForChart: [],
      histLabels: [],
    };
  }

  componentWillReceiveProps(props) {
    if (props.refreshChartsInfo) {
      this.setState({
        soDependencyId: props.soDependencyId,
        scDependencyId: props.scDependencyId,
        vendingMachineId: props.vendingMachineId,
        priorityLow: props.priorityLow,
        priorityHigh: props.priorityHigh,
        eventCategoryId: props.eventCategoryTypeId,
        eventTypeId: props.eventTypeId,
        startDate: props.startDate,
        endDate: props.endDate,
        loadingInfo: true,
      });

      this.queryVmEventsGrouped(
        props.soDependencyId,
        props.scDependencyId,
        props.vendingMachineId,
        props.priorityLow,
        props.priorityHigh,
        props.eventCategoryTypeId,
        props.eventTypeId,
        props.startDate,
        props.endDater
      );
    }
  }

  queryVmEventsGrouped(soDependencyId, scDependencyId, vendingMachineId, priorityLow, priorityHigh, eventCategoryId, eventTypeId, startDate, endDate) {
    this.props.getVmEventsGrouped(
      soDependencyId,
      scDependencyId,
      vendingMachineId,
      priorityLow,
      priorityHigh,
      eventCategoryId,
      eventTypeId,
      moment(startDate).format(),
      moment(endDate).format(),
      (response) => {
        //Conform data for chart
        var dataRaw = response.data.data;
        var dataForChart = [];
        for (var i = 0; i < dataRaw.eventsQuantity.length; i++) {
          dataForChart.push({
            value: dataRaw.eventsQuantity[i],
            label: dataRaw.groupingName[i],
          });
        }
        dataForChart = _.orderBy(dataForChart, ["value", "label"], ["asc"]);

        //Get x label according groups
        var xLabel;
        if (eventCategoryId == null && eventTypeId == null) {
          xLabel = Strings.eventType.eventCategory;
        } else if (eventCategoryId != null && eventTypeId == null) {
          xLabel = Strings.eventType.eventType;
        } else if (eventTypeId != null) {
          xLabel = Strings.machine.machine;
        }

        var histLabels = {
          value: { alias: Strings.eventType.eventQuantity },
          label: { alias: xLabel },
        };

        this.setState({
          dataForChart: dataForChart,
          histLabels: histLabels,
          loadingInfo: false,
        });
      }
    );
  }

  onHistBinClick = (ev) => {
    if (ev.data != null && ev.data._origin != null && ev.data._origin.label != null) {
      this.props.onHistBinClick(ev.data._origin.label);
    }
  };

  newMethodChartRender() {
    return (
      <div style={{ display: this.props.refreshChartsInfo ? "block" : "none" }}>
        {this.state.loadingInfo ? (
          <Loading skeleton alert />
        ) : (
          <Chart
            height={this.state.dataForChart.length <= 10 ? 400 : this.state.dataForChart.length * 18}
            forceFit
            data={this.state.dataForChart}
            scale={this.state.histLabels}
            padding={[30, 20, 120, "auto"]}
            onPlotClick={(ev) => this.onHistBinClick(ev)}
          >
            <Coord transpose />
            <Axis name="value" title={{ textStyle: { fill: "#888", fontSize: "13" } }} />
            <Axis name="label" />
            <Tooltip />
            <Geom
              size={12}
              style={{ cursor: "pointer" }}
              type="interval"
              position="label*value"
              tooltip={[
                "label*value",
                (label, value) => {
                  return {
                    // Custom tooltip on the title display and so on.
                    name: Strings.eventType.eventQuantity,
                    title: label,
                    value,
                  };
                },
              ]}
            />
          </Chart>
        )}
      </div>
    );
  }

  render() {
    return this.newMethodChartRender();
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  getVmEventsGrouped,
})(VmEventTable);
