import React, { useEffect } from 'react';
import { Modal, Spin, List, Alert} from 'antd';
import 'antd/dist/antd.css';

const QueryComponent = ({ queryResponses, showModal, setShowModal, loadingResponses}) => {
  
  useEffect(() => {
  }, [queryResponses, showModal]);

  return (
    <Modal
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
      maskClosable= {false} // Allow closing the modal by clicking outside
      closable= {true} // Display the close (X) button on the top-right corner
      width= {600} // Set a specific width to the modal
      zIndex= {1001} // Increase the z-index to ensure the modal is above other elements
      centered // Center the modal on the screen
    >
      <Spin size="large" spinning={!queryResponses.length }> 
        <>
        <List
          dataSource={queryResponses}
          renderItem={(response, index) => (            
            <List.Item>{
              response.name?
                (response.value?
                <Alert message={response.name + ": " + response.value} type="error" />
                :
                <Alert message={response.name} type="success" />)
              :
              (<strong>{response}</strong>)
              }
            </List.Item>
          )}
        />
        <Spin size="large" style={{justifyContent: "center"}} spinning={loadingResponses }/>
        </>
      </Spin>
    </Modal>
  );
};

export default QueryComponent;