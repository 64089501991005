/* eslint-disable no-unused-vars */
// Dependencies
import React, {Component} from 'react';
import { connect } from "react-redux";
import { Divider, Modal, Button, Spin, List, Alert } from "antd";
import 'react-datepicker/dist/react-datepicker.css';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import { 
  editVmProductGroup,
  editVmProductGroupInsertProducts,
  editVmProductGroupRemoveProducts,
  listSoProductByVmProductGroupId
} from "../../../actions";

// Components
import Titles from "../../GenericComponents/titles";
import { GeneralInput } from "../../GenericComponents/InputComponent/generalInput"
import { ReturnButton, SaveButton } from '../../GenericComponents/buttons/index';
import AvailableSoProductTable from "../SoProduct/AvailableSoProductTable";
import AssignedSoProductTable from "./assignedSoProductTable";
import QueryComponent from './QueryComponent.jsx';

// CSS
import './vmProductGroup.css';

// Language localization
import Strings from '../../../systemVariables/languageStrings'
import { object } from 'prop-types';

let soProductFullList = [];
let soProductsByVmProductGroup = [];
let soProductToAdd = [];
let soProductToRemove = [];

class EditVmProductGroup extends Component {
  constructor(props) {
    super(props)
    const { location } = this.props;
    this.state = {
      soDependency: {
        id: location.props ? location.props.soDependency.id : null,
        name: location.props ? location.props.soDependency.name : null,
      },
      vmProductGroupToEdit: {
        id:  location.props  ? location.props.vmProductGroupToEdit.id : null,
        name: location.props ? location.props.vmProductGroupToEdit.name : null,
        description: location.props ? location.props.vmProductGroupToEdit.description : null,
        soDependencyId: location.props ? location.props.soDependency.id : null,
      },
      redirectBackToVmProductGroup: false,
      saveButtonIsDisabled: false,
      soProductList: location.props ? location.props.soProductList : null,
      soProductAssignedByVmProductGroup: location.props ? location.props.soProductAssignedByVmProductGroup : null,
      isDisabledReturnBtn: false,
      isVisibleQueryComponent : false,
      queryResponses : [],
      isRespondedUpdate: true,
      isRespondedInsert: true,
      isRespondedRemove: true,
    };

    soProductFullList = [];
    soProductsByVmProductGroup = [];
    soProductToAdd = [];
    soProductToRemove = [];

    this.removeSelectedProductEvent = this.removeSelectedProductEvent.bind(this);
  };

  /* -----------------------Events-----------------------*/
  nameInputHandleChange(event){
    const value = _.trimStart(event.target.value)
    const { vmProductGroupToEdit } = this.state;
    const vmProductGroup = vmProductGroupToEdit;
    vmProductGroup.name = value;
    this.setState({ vmProductGroupToEdit : vmProductGroup })
    if (value.length > 0) {
      this.setState({ saveButtonIsDisabled: false });
    } else {
      this.setState({ saveButtonIsDisabled: true });;
    }
  }

  descriptionInputHandleChange(event) {
    const value = _.trimStart(event.target.value);
    const { vmProductGroupToEdit } = this.state;
    const vmProductGroup = vmProductGroupToEdit;
    vmProductGroup.description = value;
    this.setState({ vmProductGroupToEdit : vmProductGroup })
  }

  // handleOk = () => {
  //   this.setState({ 
  //     redirectBackToVmProductGroup: true 
  //   });
  // }

  async editVmProductGroupOnClick() {
    const { soProductAssignedByVmProductGroup, vmProductGroupToEdit, soProductList } = this.state;
    
    if(soProductAssignedByVmProductGroup.length === 0) {
      this.setState({ loading: false, isDisabledReturnBtn: false });
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.dispensationRule.errorNoProducts,
      });
      return;
    }

    if (vmProductGroupToEdit.name != null) {
      this.setState({ loading: true, isDisabledReturnBtn: true });
      const vmProductGroupDTO = {};
      let queryResponsesList = [];
      
      queryResponsesList.push(Strings.generalResponses.updatingGeneralParameters);
      vmProductGroupDTO.vmProductGroup = vmProductGroupToEdit;
      this.setState({ isRespondedUpdate: false });
      let responseEdit = await editVmProductGroup(vmProductGroupToEdit);
      if (!responseEdit.data && String(responseEdit).includes("Error:")) { // Connection error
        queryResponsesList.push({name: Strings.generalResponses.saveError});
        this.setState({ isRespondedUpdate: true });
      } else if (responseEdit.data.code === 4008) { // Repeated entity error
        queryResponsesList.push({name: `${Strings.vmProductGroup.alreadyExistsProductGroupError + vmProductGroupDTO.vmProductGroup.name  }.`});
        this.setState({ isRespondedUpdate: true });
      } else if (responseEdit.data && responseEdit.data.status === "SUCCESS") { // Successful entity save
        queryResponsesList.push({name: Strings.generalResponses.saveSuccess});
        this.setState({ isRespondedUpdate: true});
      } else { // Other error
        queryResponsesList.push({name:Strings.generalResponses.errorUpdating});
        this.setState({ isRespondedUpdate: true });
      }

      this.setState({ isVisibleQueryComponent: true, queryResponses: queryResponsesList});

      if (soProductToAdd.length > 0) {
        queryResponsesList.push(Strings.generalResponses.assignedProducts)
        vmProductGroupDTO.soProductIdToAdd = soProductToAdd;
        this.setState({ isRespondedInsert: false });
        let responseInsert = await editVmProductGroupInsertProducts(vmProductGroupToEdit.id, soProductToAdd);
        if (!responseInsert.data && String(responseInsert).includes("Error:")) { // Connection error
          queryResponsesList.push({name:Strings.generalResponses.saveError});
          this.setState({ isRespondedInsert: true });
        } else if (responseInsert.data.code === 4008) { // Repeated entity error
          queryResponsesList.push({name:`${Strings.vmProductGroup.alreadyExistsProductGroupError + vmProductGroupDTO.vmProductGroup.name  }.`});
          this.setState({ isRespondedInsert: true });
        } else if (responseInsert.data && responseInsert.data.status === "SUCCESS") { // Successfull entity save
          let keys = Object.keys(responseInsert.data.data);
          keys.forEach((key) => {
            let product = soProductAssignedByVmProductGroup.filter(object => { return object.id === Number(key) })[0];
            if(responseInsert.data.data[key].statusCode === "OK") {
              queryResponsesList.push({name: product.soProductName});
            } else {
              queryResponsesList.push({name: product.soProductName, value: responseInsert.data.data[key].body.description});
            }
          });
          this.setState({ isRespondedInsert: true });
        } else { // Other error
          queryResponsesList.push({name:Strings.generalResponses.errorUpdating});
          this.setState({ isRespondedInsert: true });
        }
      }

      this.setState({ isVisibleQueryComponent: true, queryResponses: queryResponsesList});

      if (soProductToRemove.length > 0) {
        queryResponsesList.push(Strings.generalResponses.unassignedProducts)
        vmProductGroupDTO.soProductIdToRemove = soProductToRemove;
        this.setState({ isRespondedRemove: false });
        let responseRemove = await editVmProductGroupRemoveProducts(vmProductGroupToEdit.id, soProductToRemove);
        if (!responseRemove.data && String(responseRemove).includes("Error:")) { // Connection error
          queryResponsesList.push({name:Strings.generalResponses.saveError});
          this.setState({ isRespondedRemove: true });
        } else if (responseRemove.data.code === 4008) { // Repeated entity error
          queryResponsesList.push({name:`${Strings.vmProductGroup.alreadyExistsProductGroupError + vmProductGroupDTO.vmProductGroup.name  }.`});
          this.setState({ isRespondedRemove: true });
        } else if (responseRemove.data && responseRemove.data.status === "SUCCESS") { // Successfull entity save
          let keys = Object.keys(responseRemove.data.data);
          keys.forEach((key) => {
            let product = soProductList.filter(object => { return object.id === Number(key)})[0];
            if (responseRemove.data.data[key].statusCode === "OK") {
              queryResponsesList.push({name: product.soProductName})
            } else {
              queryResponsesList.push({name: product.soProductName, value: responseRemove.data.data[key].statusCode})
            }
          });
          this.setState({ isRespondedRemove: true });
        } else { // Other error
          queryResponsesList.push({name:Strings.generalResponses.errorUpdating});
          this.setState({ isRespondedRemove: true });
        }
      }

      this.setState({isVisibleQueryComponent: true, queryResponses: queryResponsesList, loading: false, isDisabledReturnBtn: false})

    } else {
      Modal.error({ // Invalid parameters
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError
      })
      this.setState({ isDisabledReturnBtn: false });
    }
  }

  handleOkNoProductGroupWarning = () => {
    this.setState({ redirectBackToVmProductGroup: true });
  }

  noVmProductGroupWarning() {
    Modal.warning({
      title: Strings.vmProductGroup.noVmProductGroupSelected,
      content: Strings.vmProductGroup.noVmProductGroupSelectedMsg,
      onOk: this.handleOkNoProductGroupWarning,
    })
  }

  renderSoProductsAssignedTable = (soProductAssignedByVmProductGroup) => {
    if(soProductAssignedByVmProductGroup){
      soProductsByVmProductGroup = soProductAssignedByVmProductGroup.map(obj=>{
        const soProduct={};
        soProduct.id=obj.id;
        soProduct.soProductName = obj.soProductName;
        soProduct.erpProductCode = obj.erpProductCode;
        soProduct.soProductByProductGroupId = obj.soProductByProductGroupId?obj.soProductByProductGroupId:null;
        return soProduct;
      });
    }
    return soProductsByVmProductGroup
  }

  renderSoProductsTable = (soProductAssignedByVmProductGroup) => {
    const { soProductList } = this.state;
    if(soProductList){
      soProductFullList = soProductList;
      soProductFullList = _.differenceBy(soProductFullList, soProductAssignedByVmProductGroup,'id')
    } 
    return soProductFullList
  }

  onRowClick = row => ({
    onClick: () => {
     this.addSelectedProductEvent(row)
    }
  });

  addSelectedProductEvent(soProductSelected) {
    const { soProductList } = this.state;
    soProductsByVmProductGroup.push(soProductSelected);
    soProductFullList = _.differenceBy(soProductList, soProductsByVmProductGroup, 'id')
    this.setState({ soProductList: soProductFullList, soProductAssignedByVmProductGroup: soProductsByVmProductGroup })
    
    if(!soProductSelected.soProductByProductGroupId){
      soProductToAdd.push(soProductSelected.id);
    } else{
      soProductToRemove.forEach(item => {
        _.remove(soProductToRemove, obj => (_.isEqual(obj,soProductSelected.id)))
      })
    }
  }

  removeSelectedProductEvent (soProductSelected, row)  {
    soProductFullList.push(soProductSelected)
    soProductsByVmProductGroup = _.differenceBy(soProductsByVmProductGroup, soProductFullList, 'id')
    this.setState ({ soProductList: soProductFullList, soProductAssignedByVmProductGroup: soProductsByVmProductGroup })

    if(soProductSelected.soProductByProductGroupId){
      soProductToRemove.push(soProductSelected.id);
    } else{
      soProductToAdd.forEach(item => {
        _.remove(soProductToAdd, obj => (_.isEqual(obj,soProductSelected.id)))
      })
  
    }
  }

  setIsVisibleQueryComponent() {
    this.setState({ isVisibleQueryComponent: false, redirectBackToVmProductGroup: true });
  }
  
  render() {
    const { redirectBackToVmProductGroup, vmProductGroupToEdit, soDependency,  soProductAssignedByVmProductGroup, isDisabledReturnBtn, isLoading, loading, saveButtonIsDisabled, queryResponses,isVisibleQueryComponent, isRespondedUpdate, isRespondedInsert, isRespondedRemove} = this.state;

    if (redirectBackToVmProductGroup) { // Redirects to vmProductGroup from No ProductGroup Modal Warning
        return (<Redirect to="/listVmProductGroups" />);
    }
    
    if (!vmProductGroupToEdit.id) { // Shows No ProductGroup Modal Warning
      return (<div>{this.noVmProductGroupWarning()}</div>);
    }

    return (
      <div>
        <Titles title={Strings.vmProductGroup.editVmProductGroup}  tooltip={Strings.vmProductGroup.editVmProductGroupTooltip}/>
        <div  className="col-md-12">
          <div> 
            <Divider orientation="left"><h3>{Strings.soDependency.directClient} </h3></Divider>
          </div>
          <div className="col-md-6">
            <h3>{soDependency.name}</h3>
          </div>
          <br/>
        </div>

        <div>
          <QueryComponent
            queryResponses={queryResponses}
            showModal={isVisibleQueryComponent}
            setShowModal={(e) => this.setIsVisibleQueryComponent(e)}
            loadingResponses={!(isRespondedUpdate && isRespondedInsert && isRespondedRemove)}
          >
          </QueryComponent>
        </div>

        <div className="col-md-12">
          <Divider orientation="left">
            <h3>1. {Strings.generalTerms.generalParameters}</h3>
          </Divider>
          <div className="col-md-12">
            <div className="col-md-6">
            
              <GeneralInput 
                text={Strings.generalTerms.name} 
                tooltip ={Strings.vmProductGroup.vmProductGroupNameToolTip}
                placeholder={Strings.generalTerms.name} 
                onChange={(e) => this.nameInputHandleChange(e)}
                value={vmProductGroupToEdit.name}
                isReq
              />
            </div>

            <div className="col-md-6">
              <GeneralInput 
                text={Strings.generalTerms.description} 
                tooltip ={Strings.vmProductGroup.vmProductGroupDescriptionToolTip}
                placeholder={Strings.generalTerms.description} 
                onChange={(e) => this.descriptionInputHandleChange(e)}
                value={vmProductGroupToEdit.description}
                isReq={false}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <br/>
          <Divider orientation="left">
            <h3> 2. {Strings.vmProductGroup.soProductAssignmentToVmProductGroup}</h3>
          </Divider>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <h4>{Strings.product.availableProducts}</h4>
                <div className="smallTable" >
                  <AvailableSoProductTable 
                    availableSoProducts = {this.renderSoProductsTable(soProductAssignedByVmProductGroup)} 
                    isLoading = {isLoading}
                    onRowClick = {this.onRowClick}
                  />
                </div> 
                <br/>
            </div>
            <div className="col-md-6">
              <h4>{Strings.product.assignedProducts}</h4>
                <div className="smallTable" >
                  <AssignedSoProductTable 
                    assignedSoProducts = {this.renderSoProductsAssignedTable(soProductAssignedByVmProductGroup)}  
                    isLoading = {isLoading} 
                    removeSelectedProduct={this.removeSelectedProductEvent} 
                  />  
                </div>  
                <br/>
            </div>
          </div> 
        </div> 

        <div className="col-md-12">
          <div className="col-md-9" />
          <div className="col-md-1">
            <ReturnButton 
              link="/listVmProductGroups"
              isDisabled = {!(isRespondedUpdate && isRespondedInsert && isRespondedRemove) && isDisabledReturnBtn}
            /> 
          </div>
          <div className="col-md-2">
            <SaveButton 
              loading={!(isRespondedUpdate && isRespondedInsert && isRespondedRemove) && loading}
              isDisabled = {!(isRespondedUpdate && isRespondedInsert && isRespondedRemove) && saveButtonIsDisabled}
              onClick={(e) => this.editVmProductGroupOnClick(e, !(isRespondedUpdate && isRespondedInsert && isRespondedRemove))}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {
  }
}

export default connect(mapStateToProps, {
  editVmProductGroup,
  editVmProductGroupInsertProducts,
  editVmProductGroupRemoveProducts,
  listSoProductByVmProductGroupId
}) (EditVmProductGroup)
