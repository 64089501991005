import axios from "axios";

import {
  host,
  port,
  path,
  version
} from "../systemVariables"


// Method path
let resource = "/dispensationrulebyuser";

export const ERROR = 'ERROR';

// Dispensation rules by user
export function listScDispensationRuleByUser(scDependencyId, scDepartmentId, scUserProfileId, identityCardNumber, page, pageSize, attribute, order, callback) {
  return (dispatch) => {
    axios.get(host + port + path + version + resource, {
      params: {
        scdependencyid: scDependencyId,
        scdepartmentid: scDepartmentId,
        scuserprofileid: scUserProfileId,
        identitycardnumber: identityCardNumber,
        page: page,
        size: pageSize,
        attribute: attribute,
        order: order
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        callback(response)
      })
      .catch(function (error) {
        return error;
      });
  }
}

export function insertDispensationRulesByUser(usersRuleDispToAdd, callback) {
  return (dispatch) => {
    let data = JSON.stringify(usersRuleDispToAdd)
    axios({
      method: 'post',
      url: host + port + path + version + resource,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token")
      }
    }).then((response) => {
      callback(response);
    })
      .catch(function (error) {
        dispatch({
          type: ERROR,
          payload: error.response
        });
      });
  }
}

export function getDispensationRuleByUserId(userId, callback) {
  return (dispatch) => {
    axios.get(host + port + path + version + resource, {
      params: {
        id: userId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
      .then((response) => {
        callback(response)
      })
      .catch(function (error) {
        dispatch({
          type: ERROR,
          payload: error.response
        });
      });
  }
}

export function exportDispensationRuleByUserReport(soDependencyId, scDependencyId, scDepartmentId, scUserProfileId, identityCardNumber, attribute, order, fileType, columnHeaders, callback) {
  return (dispatch) => {
    axios.get(host + port + path + version + resource, {
      params: {
        sodependencyid: soDependencyId,
        scdependencyid: scDependencyId,
        scdepartmentid: scDepartmentId,
        scuserprofileid: scUserProfileId,
        identitycardnumber: identityCardNumber,
        attribute,
        order,
        filetype: fileType,
        columnheaders: encodeURIComponent(columnHeaders)
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        callback(response)
      })
      .catch(function (error) {
        return error;
      });
  }
}


export function disableDispensationRuleByUser(userId,scDispensationRuleId, callback) {
  return () => {
    axios({
      method: 'delete',
      url: host + port + path + version + resource,
      params: {
        userid: userId,
        scdispensationruleid : scDispensationRuleId
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  };
}