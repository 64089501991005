import Strings from '../../systemVariables/languageStrings';

export function identityCardTypeLocalization(identityCardType) {
    // eslint-disable-next-line default-case
    switch (identityCardType) {
        case 'NONE':
            identityCardType = Strings.users.identityCardType2.none;
            break;
        case 'CITIZEN CARD':
            identityCardType = Strings.users.identityCardType2.citizenCard;
            break;
        case 'BIRTH RECORD':
            identityCardType = Strings.users.identityCardType2.birthRecord;
            break;
        case 'PASSPORT':
            identityCardType = Strings.users.identityCardType2.passport;
            break;
        case 'EMPLOYEE ID':
            identityCardType = Strings.users.identityCardType2.employeeId;
            break;
    }
    return identityCardType;
}