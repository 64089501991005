import React from "react";
import { Tooltip, Icon, Checkbox } from "antd";

export function GeneralCheckbox(props) {
  const { tooltip, isChecked, onChange, isDisabled, text } = props;
  return (
    <>
      <div className="row container-fluid">
        <div className="selectAligning col-2">
        {tooltip ? (
            <Tooltip title={tooltip}>
              <span>{text}</span>
              <Icon className="icon" type="info-circle" style={{ float: "none" }} />
            </Tooltip>
          ) : null}
        </div>
        <div className="col-1">
          <Checkbox checked={isChecked} onChange={onChange} disabled={isDisabled} />
        </div>
      </div>
    </>
  );
}
