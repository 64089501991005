// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Tooltip, Button } from "antd";
import _ from "lodash";

// Components
import moment from "moment";
import Titles from "../GenericComponents/titles";
import Loading from "../GenericComponents/loadingComponent/loading";
import { SelectGeneral } from "../GenericComponents/selectComponent/selectGeneral";
import { ValidateDependency } from "../../Utils/validateDependency";
import TimeUnitSelector from "../GenericComponents/TimeUnitSelector";
import VmEventTable from "./vmEventTable.jsx";
import VmEventCharts from "./VmEventCharts.jsx";

// Actions
import {
  listSoDependencyBySoDependencyType0,
  getEventTypeCategory,
  getEventTypesByCategoryId,
  listScDependenciesBySoDependencyId,
  listVendingMachinesByScDependencyId,
} from "../../actions";

// Language Localization
import Strings from "../../systemVariables/languageStrings";
import { GeneralCheckbox } from "../GenericComponents/checkBox/GeneralCheckBox";

const priorityRange = [
  { label: "Todas", value: null },
  { label: "Baja", value: "0@40" },
  { label: "Media", value: "41@70" },
  { label: "Alta", value: "71@100" },
];

class VmEventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventCategoryType: [],
      eventCategoryTypeId: null,

      soDependencyId: null,
      soDependencyName: null,

      scDependencies: [],
      scDependencyId: null,
      scDependencyName: null,
      scDependencyDisabled: true,

      vendingMachines: [],
      vendingMachineId: null,
      vendingMachineDisabled: true,

      eventType: [],
      eventTypeId: null,
      eventTypeDisabled: true,

      showInfoAsTable: null,
      showInfoAsChart: null,
      // Time values
      startDate: moment().hour(0).minutes(0).format(),
      endDate: moment().hour(23).minutes(59).format(),

      stateClickHandleEvent: false,
      priority: null,
      lowerPriority: null,
      higherPriority: null,
      loadingChild: true,
    };
  }

  componentDidMount() {
    // Query eventTypeCategories
    const { getEventTypeCategory, soScSelectionProps } = this.props;
    getEventTypeCategory((response) => {
      this.setState({
        eventCategoryType: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });

    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") === 1) {
      if (this.props.soDependencies.length <= 0) {
        this.props.listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
      }
    } else {
      let value = this.props.userDependency.dependencyId;

      this.setState({
        soDependencyName: this.props.userDependency
          ? this.props.userDependency.dependencyType === "so" && this.props.userDependency.dependencyId !== 1
            ? this.props.userDependency.dependencyName
            : null
          : null,
        soDependencyId: this.props.userDependency
          ? this.props.userDependency.dependencyType === "so" && this.props.userDependency.dependencyId !== 1
            ? this.props.userDependency.dependencyId
            : null
          : null,
        scDependencyDisabled: false,
        vendingMachineDisabled: true,
      });
      this.props.listScDependenciesBySoDependencyId(value, (response) => {
        this.setState({
          scDependencies: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
          scDependencyDisabled: false,
        });
      });
    }

    if (sessionStorage.getItem("dependencyType") === "sc") {
      let value = this.props.userDependency
        ? this.props.userDependency.dependencyType === "sc" && this.props.userDependency.dependencyId !== 1
          ? this.props.userDependency.dependencyId
          : ""
        : "";

      this.setState({
        // Selects
        soDependencyId: this.props.soDependencyParent,

        scDependencyId: this.props.userDependency ? (this.props.userDependency.dependencyType === "sc" ? this.props.userDependency.dependencyId : null) : null,
        scDependencyName: this.props.userDependency ? (this.props.userDependency.dependencyType === "sc" ? this.props.userDependency.dependencyName : null) : null,

        vendingMachineDisabled: false,
      });
      this.props.listVendingMachinesByScDependencyId(value, (response) => {
        this.setState({
          vendingMachines: response.data.data.map((item) => ({
            value: item.id,
            label: item.friendlyName,
          })),
          vendingMachineDisabled: false,
        });
      });
    }
    this.setState({
      // By default show info as table
      showInfoAsTable: true,
      showInfoAsChart: false,
    });
  }

  // Events
  selectHandleEvent(event, tag) {
    const { value } = event;
    const { label } = event;
    const tagName = tag.name;

    const { listScDependenciesBySoDependencyId, listVendingMachinesByScDependencyId, getEventTypesByCategoryId } = this.props;
    switch (tagName) {
      case "soDependencyType0": // SoDependency direct client select
        this.setState({
          soDependencyId: value,
          soDependencyName: label,

          scDependencyId: null,
          scDependencyName: null,

          vendingMachineId: null,

          scDependencyDisabled: false,
          vendingMachineDisabled: true,
        });

        listScDependenciesBySoDependencyId(value, (response) => {
          this.setState({
            scDependencies: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            scDependencyDisabled: false,
          });
        });

        break;

      case "scDependency":
        this.setState({
          scDependencyId: value,
          scDependencyName: label,

          vendingMachineId: null,

          vendingMachineDisabled: true,
        });
        listVendingMachinesByScDependencyId(value, (response) => {
          this.setState({
            vendingMachines: response.data.data.map((item) => ({
              value: item.id,
              label: item.friendlyName,
            })),
            vendingMachineDisabled: false,
          });
        });

        break;

      case "vendingMachineId":
        this.setState({
          vendingMachineId: value,
        });

        break;

      case "priority":
        if (value != null) {
          this.setState({
            priority: value,
            lowerPriority: _.split(value, "@")[0],
            higherPriority: _.split(value, "@")[1],
          });
        } else {
          this.setState({
            lowerPriority: "",
            higherPriority: "",
          });
        }

        break;

      case "eventTypeCategory":
        this.setState({
          eventCategoryTypeId: value,
          eventTypeId: null,
        });

        getEventTypesByCategoryId(value, (response) => {
          this.setState({
            eventType: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            eventTypeDisabled: false,
          });
        });

        break;
      case "eventType":
        this.setState({
          eventTypeId: value,
        });
        break;
      default:
    }
  }

  methodClickHandleEvent = () => {
    const { stateClickHandleEvent } = this.state;
    this.setState({
      stateClickHandleEvent: !stateClickHandleEvent,
    });
  };

  setTimeInformation = (startDate, endDate) => {
    this.setState({
      startDate,
      endDate,
    });
  };

  onChangeInfoShowMethod = () => {
    const { showInfoAsTable, showInfoAsChart } = this.state;
    this.setState({
      showInfoAsTable: !showInfoAsTable,
      showInfoAsChart: !showInfoAsChart,
    });
  };

  onHistChartBinClick = (binLabel) => {
    const { getEventTypesByCategoryId } = this.props;
    const { eventCategoryTypeId } = this.state;

    // Search selected bin by eventTypeId
    if (eventCategoryTypeId != null && (this.state.eventTypeId == null || this.state.eventTypeId === -1)) {
      var eventTypeId = _.findIndex(this.state.eventType, ["label", binLabel]);
      this.setState({
        eventTypeId: this.state.eventType[eventTypeId].value,
      });
    }

    // Search selected bin by eventCategoryTypeId
    if (eventCategoryTypeId == null && this.state.eventTypeId == null) {
      var eventCatId = _.findIndex(this.state.eventCategoryType, ["label", binLabel]);
      this.setState({
        eventCategoryTypeId: eventCatId,
        eventTypeId: null,
      });

      getEventTypesByCategoryId(eventCatId, (response) => {
        this.setState({
          eventType: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
          eventTypeDisabled: false,
        });
      });
    }
  };

  getResponse = (loading, response) => {
    const { showInfoAsTable } = this.state;
    if (showInfoAsTable === true) {
      if (response !== undefined) {
        this.setState({
          loadingChild: response.status === 200 ? false : true,
        });
      } else {
        this.setState({
          loadingChild: true,
        });
      }
    }
  };
  //----------------------

  // Information to render
  render() {
    const {
      props: { soDependencies },
    } = this;

    const {
      soDependencyId,
      soDependencyName,
      scDependencies,
      scDependencyId,
      scDependencyName,
      scDependencyDisabled,
      vendingMachines,
      vendingMachineDisabled,
      vendingMachineId,
      disabledScEmployeeInformationFilter,
      priority,
      eventCategoryType,
      eventCategoryTypeId,
      eventType,
      eventTypeDisabled,
      eventTypeId,
      showInfoAsTable,
      showInfoAsChart,
      lowerPriority,
      higherPriority,
      startDate,
      endDate,
      stateClickHandleEvent,
      loadingChild,
    } = this.state;

    if (!soDependencies) {
      return (
        <>
          <Loading />
        </>
      );
    }
    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.vmEvent.vmEvent} tooltip={Strings.vmEvent.vmEventTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <ValidateDependency
            soDependencyHtmlTag="soDependencyType0"
            scDependencyHtmlTag="scDependency"
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            soDependencies={soDependencies}
            soDependencyId={soDependencyId}
            soDependency={soDependencyName}
            scDependencies={scDependencies}
            scDependencyId={scDependencyId}
            scDependency={scDependencyName}
            disabledScDependency={scDependencyDisabled}
            soDependencyHandleChange={(e, name) => this.selectHandleEvent(e, name)}
            scDependencyHandleChange={(e, name) => this.selectHandleEvent(e, name)}
          />
          <div className="vertSpace col-md-6">
            <SelectGeneral
              name="vendingMachineId"
              text={Strings.machine.vendingMachine}
              tooltip={Strings.machine.vendingMachinesTooltip}
              options={vendingMachines}
              onChange={(e, name) => this.selectHandleEvent(e, name)}
              disabled={vendingMachineDisabled}
              defaultValue={vendingMachineId}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              name="priority"
              text={Strings.eventType.priority}
              tooltip={Strings.eventType.priorityTooltip}
              options={priorityRange}
              onChange={(e, name) => this.selectHandleEvent(e, name)}
              disabled={disabledScEmployeeInformationFilter}
              defaultValue={priority}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              name="eventTypeCategory"
              text={Strings.eventType.eventCategory}
              options={eventCategoryType}
              onChange={(e, name) => this.selectHandleEvent(e, name)}
              disabled={disabledScEmployeeInformationFilter}
              defaultValue={eventCategoryTypeId}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              name="eventType"
              text={Strings.eventType.eventType}
              options={eventType}
              onChange={(e, name) => this.selectHandleEvent(e, name)}
              disabled={eventTypeDisabled}
              defaultValue={eventTypeId}
            />
          </div>
          <br />
        </div>

        <div className="row selectAligning">
          <div className="col-md-10 vertSpace selectAligning" style={{ padding: "1%" }}>
            <TimeUnitSelector setTimeInformation={this.setTimeInformation} />
          </div>
          <div className="col-md-2 vertSpace selectAligning" style={{ alignItems: "center", margin: "0 auto", padding: "10px" }}>
            <Tooltip title={Strings.transaction.getTransactionsToolTip}>
              <Button
                type="primary"
                size="default"
                icon="search"
                onClick={this.methodClickHandleEvent}
                // eslint-disable-next-line no-unneeded-ternary
                disabled={showInfoAsChart}
                loading={loadingChild}
              >
                {Strings.generalTerms.check}
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-6" />
        </div>

        <div className="row">
          <div className="col-12">
            <Divider orientation="left">
              <h3>{Strings.vmEvent.vmEvent}</h3>
            </Divider>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-xs-6 col-md-6">
            <GeneralCheckbox
              text={Strings.eventType.seeTable}
              tooltip={Strings.eventType.seeTableTooltip}
              isChecked={showInfoAsTable}
              isDisabled={false}
              onChange={() => this.onChangeInfoShowMethod()}
            />
          </div>
          <div className="col-xs-6 col-md-6">
            <GeneralCheckbox
              text={Strings.eventType.seeChart}
              tooltip={Strings.eventType.seeChartTooltip}
              isChecked={showInfoAsChart}
              isDisabled={false}
              onChange={() => this.onChangeInfoShowMethod()}
            />
          </div>
        </div>
        <br />
        <div className="row">
          {showInfoAsTable === true ? (
            <div className="col-12">
              <VmEventTable
                refreshTableInfo={showInfoAsTable}
                soDependencyId={soDependencyId}
                scDependencyId={scDependencyId}
                vendingMachineId={vendingMachineId}
                priorityLow={lowerPriority}
                priorityHigh={higherPriority}
                eventCategoryTypeId={eventCategoryTypeId}
                eventTypeId={eventTypeId}
                startDate={startDate}
                endDate={endDate}
                eventClick={stateClickHandleEvent}
                methodCallbackLoadingInfo={this.getResponse}
              />
            </div>
          ) : null}

          <div className="col-12">
            <VmEventCharts
              refreshChartsInfo={showInfoAsChart}
              soDependencyId={soDependencyId}
              scDependencyId={scDependencyId}
              vendingMachineId={vendingMachineId}
              priorityLow={lowerPriority}
              priorityHigh={higherPriority}
              eventCategoryTypeId={eventCategoryTypeId}
              eventTypeId={eventTypeId}
              startDate={startDate}
              endDate={endDate}
              onHistBinClick={this.onHistChartBinClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    soDependencyParent: state.soDependencyParent.soParent,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  getEventTypeCategory,
  getEventTypesByCategoryId,
  listScDependenciesBySoDependencyId,
  listVendingMachinesByScDependencyId,
})(VmEventList);
