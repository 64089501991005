/* eslint-disable react/jsx-filename-extension */

// Dependencies
import React, { Component } from 'react';
import { connect } from "react-redux";
import 'react-datepicker/dist/react-datepicker.css';
import { Redirect } from 'react-router-dom';
import { Divider, Modal } from 'antd';
import "./scEmployeeInformation.css";

import {
    editScUserProfile,
    editScCostCenter,
    editScDepartment
} from "../../actions/index"

// Components
import Titles from "../GenericComponents/titles";
import { GeneralInput } from "../GenericComponents/InputComponent/generalInput";

import {
    AcceptButton,
    ReturnButton
} from "../GenericComponents/buttons/index";

// Language localization
import Strings from "../../systemVariables/languageStrings";

class EditScEmployeeInformation extends Component {
    constructor(props) {
        super(props)
        const {location} = this.props;
        this.state = {
            categoryId: location.props ? location.props.categoryId : null,
            categoryName: location.props ? location.props.categoryName : null,
            scDependency: location.props ? location.props.scDependencyName : null,
            elementToEdit: location.props ? location.props.elementToEdit : null,
            redirectBackToScEmployeeInformation: true,
            loading: false
        };

    }

    nameInputEvent(event) {
        const {elementToEdit} = this.state;
        elementToEdit.name =  event.target.value;
        if (elementToEdit.name.length === 0) {
            elementToEdit.name = null
        }
        this.setState({ elementToEdit })
    }

    descriptionInputEvent(event) {
        const {elementToEdit} = this.state;
        elementToEdit.description = event.target.value
        if (elementToEdit.description.length === 0) {
            elementToEdit.description = null
        }
        this.setState({ elementToEdit })
    }

    codeInputEvent(event) {
        const {elementToEdit} = this.state;
        elementToEdit.code = event.target.value
        if (elementToEdit.code.length === 0) {
            elementToEdit.code = null
        }
        this.setState({ elementToEdit })
    }

    editScEmployeeInfoHandleEvent() {
        const {categoryId, elementToEdit} = this.state;
        const {editScDepartment, editScUserProfile, editScCostCenter} = this.props;

        if (categoryId === 1) { // To departments
            if (elementToEdit.name != null) {
                this.setState({ loading: true });
                editScDepartment(elementToEdit, (response) => {
                    this.setState({ loading: false })
                    if (!response.data && String(response).includes("Error:")) { 
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.saveError
                        })
                    } else if (response.data.code === 4008) { // Repeated entity error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    } else if (response.data && response.data.status === "SUCCESS") { // Successfull entity save
                        Modal.success({
                            title: Strings.generalResponses.successfulTransaction,
                            content: Strings.generalResponses.saveSuccess,
                            onOk: this.handleOk,
                        })
                    } else { // Other error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    }
                });
            } else {
                Modal.error({ // Invalid parameters
                    title: Strings.generalResponses.invalidParameters,
                    content: Strings.generalResponses.invalidError
                })
            }
        }

        if (categoryId === 2) { // To userProfile
            if (elementToEdit.name != null) {
                this.setState({ loading: true });
                editScUserProfile(elementToEdit, (response) => {
                    this.setState({ loading: false })
                    if (!response.data && String(response).includes("Error:")) { 
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.saveError
                        })
                    } else if (response.data.code === 4008) { // Repeated entity error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    } else if (response.data && response.data.status === "SUCCESS") { // Successfull entity save
                        Modal.success({
                            title: Strings.generalResponses.successfulTransaction,
                            content: Strings.generalResponses.saveSuccess,
                            onOk: this.handleOk,
                        })
                    } else { // Other error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    }
                });
            } else {
                Modal.error({
                    title: Strings.generalResponses.invalidParameters,
                    content: Strings.generalResponses.invalidError
                })
            }
        }

        if (categoryId === 3) { // To cost centers
            if (elementToEdit.name != null) {
                this.setState({ loading: true });
                editScCostCenter(elementToEdit, (response) => {
                    this.setState({ loading: false })
                    if (!response.data && String(response).includes("Error:")) { 
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.saveError
                        })
                    } else if (response.data.code === 4008) { // Repeated entity error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    } else if (response.data && response.data.status === "SUCCESS") { // Successfull entity save
                        Modal.success({
                            title: Strings.generalResponses.successfulTransaction,
                            content: Strings.generalResponses.saveSuccess,
                            onOk: this.handleOk,
                        })
                    } else { // Other error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    }
                });
            } else {
                Modal.error({
                    title: Strings.generalResponses.invalidParameters,
                    content: Strings.generalResponses.invalidError
                })
            }
        }
    }

    handleOkNoElementInfoWarning = () => {
        this.setState({ redirectBackToScEmployeeInformation: false });
    }

    noElementInfoWarning() {
        Modal.warning({
            title: Strings.soDependency.noDirectClientSelected,  
            content: Strings.soDependency.noDirectClientSelectedMsg, 
            onOk: this.handleOkNoElementInfoWarning,
        })
    }

    handleOk = () => {
        this.setState({
            redirectBackToScEmployeeInformation: false
        });
    }

    render() {
        const { redirectBackToScEmployeeInformation, elementToEdit, categoryId, categoryName, scDependency, loading } = this.state;
        
        // Redirects to vmProductGroup from No ProductGroup Modal Warning
        if (redirectBackToScEmployeeInformation === false) { 
            return (<Redirect to = "/listScEmployeeInformation"/>);
        }

        if (elementToEdit == null) {
            return (<div>{this.noElementInfoWarning()}</div>);
        }

        let nameToolTip;
        let descriptionToolTip;
        let codeToolTip;
        let titlePage;
        let titlePageToolTip;

        if (categoryId === 1) { // department
            nameToolTip = Strings.scDepartment.scDepartmentNameToolTip;
            descriptionToolTip = Strings.scDepartment.scDepartmentDescriptionToolTip;
            titlePage = Strings.scDepartment.editScDepartment;
            titlePageToolTip = Strings.scDepartment.editScDepartmentTooltip;
        }

        if (categoryId === 3) { // costCenter
            nameToolTip = Strings.scCostCenter.scCostCenterNameToolTip;
            descriptionToolTip = Strings.scCostCenter.scCostCenterDescriptionToolTip;
            codeToolTip = Strings.scCostCenter.scCostCenterCodeToolTip;
            titlePage = Strings.scCostCenter.editScCostCenter;
            titlePageToolTip = Strings.scCostCenter.editScCostCenterTooltip;
        }

        if (categoryId === 2) { // userProfile
            nameToolTip = Strings.scUserProfile.scUserProfileNameToolTip;
            descriptionToolTip = Strings.scUserProfile.scUserProfileDescriptionToolTip;
            titlePage = Strings.scUserProfile.editScUserProfile;
            titlePageToolTip = Strings.scUserProfile.editScUserProfileTooltip;
        }

        return (
            <div className="content-container">

                <div className="row">
                    <Titles
                        title={titlePage}
                        tooltip={titlePageToolTip}
                    />
                </div>

                <div className="row">
                    <div className="vertSpace col-12">
                        <div className="col-xs-12 col-md-6">
                            <Divider orientation="left"><h3>{Strings.scDependency.structureCategory} </h3></Divider>
                            <h3>{categoryName}</h3>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <Divider orientation="left"><h3>{Strings.scDependency.indirectClient} </h3></Divider>
                            <h3>{scDependency}</h3>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="vertSpace col-12">
                        <Divider orientation="left">
                            <h3>{Strings.generalTerms.parameterization} </h3>
                        </Divider>
                        {categoryId !== 3 ? (// !costCenter
                            <div className="col-12">
                                <div className="vertSpace col-xs-12 col-md-6">
                                    <GeneralInput
                                        text={Strings.generalTerms.name}
                                        tooltip={nameToolTip}
                                        onChange={(e) => this.nameInputEvent(e)}
                                        value={elementToEdit.name}
                                        isReq
                                        placeholder={Strings.generalTerms.name}
                                    />
                                </div>
                                <div className="vertSpace col-xs-12 col-md-6">
                                    <GeneralInput
                                        text={Strings.generalTerms.description}
                                        tooltip={descriptionToolTip}
                                        onChange={(e) => this.descriptionInputEvent(e)}
                                        value={elementToEdit.description}
                                        placeholder={Strings.generalTerms.description}
                                    />
                                </div>
                            </div>
                        ) : (
                                <div className="col-12">
                                    <div className="vertSpace col-xs-12 col-md-6">
                                        <GeneralInput
                                            text={Strings.generalTerms.name}
                                            tooltip={nameToolTip}
                                            onChange={(e) => this.nameInputEvent(e)}
                                            value={elementToEdit.name}
                                            isReq
                                            placeholder={Strings.generalTerms.name}
                                        />
                                    </div>
                                    <div className="vertSpace col-xs-12 col-md-6">
                                        <GeneralInput
                                            text={Strings.generalTerms.code}
                                            tooltip={codeToolTip}
                                            onChange={(e) => this.codeInputEvent(e)}
                                            value={elementToEdit.code}
                                            placeholder={Strings.generalTerms.code}
                                        />
                                    </div>
                                    <div className="vertSpace col-xs-12 col-md-6">
                                        <GeneralInput
                                            text={Strings.generalTerms.description}
                                            tooltip={descriptionToolTip}
                                            onChange={(e) => this.descriptionInputEvent(e)}
                                            value={elementToEdit.description}
                                            placeholder={Strings.generalTerms.description}
                                        />
                                    </div>
                                </div>
                            )}
                    </div>
                </div>

                <div className="row justify-content-end">
                    <div className="vertSpace col-6 col-md-3">
                        <ReturnButton link="/listScEmployeeInformation" />
                    </div>
                    <div className="vertSpace col-6 col-md-3">
                        <AcceptButton onClick={(e) => this.editScEmployeeInfoHandleEvent(e)} loading={loading} />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {
    }
}

export default connect(mapStateToProps, {
    editScUserProfile,
    editScCostCenter,
    editScDepartment
})(EditScEmployeeInformation)