import Strings from "../../systemVariables/languageStrings";

export function slotPositionNameLocalizationArray(slotPositions) {
  slotPositions.forEach((item) => {
    switch (item.label) {
      case "Careless":
        item.label = Strings.planogram.slotPositions.careless;
        break;
      case "MostLeft":
        item.label = Strings.planogram.slotPositions.mostLeft;
        break;
      case "Left":
        item.label = Strings.planogram.slotPositions.left;
        break;
      case "Middle":
        item.label = Strings.planogram.slotPositions.middle;
        break;
      case "Right":
        item.label = Strings.planogram.slotPositions.right;
        break;
      case "MostRight":
        item.label = Strings.planogram.slotPositions.mostRight;
        break;
      default:
        break;
    }
  });
  return slotPositions;
}
