import React from "react";
import { Tooltip, Icon, Typography } from "antd";

import "./menuTitle.css";

const { Title } = Typography;

const Titles = ({ title, tooltip }) => {
  return tooltip ? (
    <div className="vertSpace alignTitle col">
      <Title level={2} style={{ color: "#0F2938", marginBottom: 0 }}>
        {title}
      </Title>
      <Tooltip title={tooltip} arrowPointAtCenter>
        <Icon style={{ fontSize: "2vh", color: "#0F2938" }} className="icon centeredIcon" type="info-circle" />
      </Tooltip>
    </div>
  ) : (
    <Title style={{ color: "#0F2938" }} level={2}>
      {title}
    </Title>
  );
};

export default Titles;
