// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Divider, Modal } from "antd";
import { Redirect } from "react-router-dom";

// Actions
import {
  getVendingMachineInfoById,
  listWingInfoByMachineId,
  getWingTypeById,
  getLockerInfoByWingId,
  getProductInfoByWingId,
  disableWingById,
  disableFreeVmProductSlotByWingId,
} from "../../../actions";

// Components
import Loading from "../../GenericComponents/loadingComponent/loading";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import { AddButton, DeleteButton } from "../../GenericComponents/buttons/index";
import EditButton from "../../GenericComponents/buttons/editButton";
import WingConfigModal from "./wingConfigModal";
import EditLockerModal from "./editLockerModal";
import CardBasicInformation from "./CardBasicInformation";
import WingTypeId from "./WingTypeId";

import MachinesForWingTypeImages from "./MachinesForWingTypeImages";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

const { Meta } = Card;

class VendingMachineGeneralInfo extends Component {
  constructor(props) {
    super(props);
    const { vm } = this.props;
    this.state = {
      vm: vm || null,
      lockerTypeDTO: [],
      productReferences: null,
      wingModalIsVisible: false,
      lockerModalIsVisible: false,
      redirectBackToListVendingMachine: false, // Flag used to redirect to /listVendingMachine if any VM was selected
      loading2: true,
      isLoadingAdd: false,
      isLoadingDisabled: false,
      isLoadingDisabledVmPS: false,
      isVisibleDisabled: false,
      isVisibleDisabledVmPS: false,
      wingInfoList: null,
    };
    this.setStateAsync = this.setStateAsync.bind(this);
  }

  componentDidMount() {
    const { getVendingMachineInfoById, listWingInfoByMachineId } = this.props;
    const { vm } = this.state;
    if (vm) {
      getVendingMachineInfoById(vm.id, (response) => {
        this.setState({
          vmInfo: response.data.data,
        });
      });

      listWingInfoByMachineId(vm.id, (response) => {
        const wingInfoList = [];
        let counter = 1;
        if (response.data.data.length > 0) {
          const wingInfo = response.data.data;
          wingInfo.forEach((wing) => {
            wingInfoList.push({
              value: wing.id, // Unique WingId
              label: counter++,
              wingTypeId: wing.wingTypeId,
            });
          });
          this.setState({ wingInfoList });
        }
        if (response.data.data.length) {
          this.wingNumberSelection(wingInfoList[0]);
        }
      });
    }

    this.handleOkNoVMWarning = this.handleOkNoVMWarning.bind(this);
  }

  // -----------Update /vmSetup when module info changes------------
  updatePage = () => {
    const { listWingInfoByMachineId } = this.props;
    const { vm, wingType } = this.state;
    listWingInfoByMachineId(vm.id, (response) => {
      const wingInfoList = [];
      let counter = 1;
      if (response.data.data.length > 0) {
        const wingInfo = response.data.data;
        wingInfo.forEach((wing) => {
          wingInfoList.push({
            value: wing.id, // Unique WingId
            label: counter++,
            wingTypeId: wing.wingTypeId,
          });
        });
        this.setState({
          wingId: null,
          wingType,
          productReferences: null,
          vmTraysTotal: null,
          productsAmountAvailable: null,
          lockerTypeDTO: [],
          lockerSlotTypeDTO: [],
          wingInfoList,
          isLoadingAdd: false,
          isVisibleDisabled: false,
        });
      }
      if (response.data.data.length) {
        this.wingNumberSelection(wingInfoList[0]);
        this.setState(
          {
            isLoadingAdd: false,
            isLoadingDisabled: false,
          },
          () => this.state.isLoadingDisabled
        );
      }
    });
  };

  // -----------Events------------
  wingNumberSelection(event) {
    const { value } = event;
    const { getWingTypeById, getLockerInfoByWingId, getProductInfoByWingId } = this.props;

    this.setState({
      wingId: value,
      productReferences: null,
      vmTraysTotal: null,
      productsAmountAvailable: null,
    });

    getWingTypeById(event.wingTypeId, async (response) => {
      const wingType = response.data.data;
      this.setState({ wingType, loading2: true }, () => this.state.wingType);

      if (wingType.id === 3) {
        getLockerInfoByWingId(event.value, (response) => {
          const { lockerTypeDTO } = response.data.data;
          const { lockerSlotTypeDTO } = response.data.data;
          this.setState({ lockerTypeDTO, lockerSlotTypeDTO, loading2: false });
        });
      }

      getProductInfoByWingId(event.value, (response) => {
        if (response.data && response.data.data.status === "SUCCESS") {
          this.setState({
            productReferences: response.data.data.data[0],
            vmTraysTotal: response.data.data.data[1],
            productsAmountAvailable: response.data.data.data[2],
            loading2: false,
          });
        } else if (response.data.data.code === "4007") {
          this.setState({ loading2: false });
        } else {
          this.setState({ loading2: false });
        }
      });
    });
  }

  wingConfigModal() {
    this.setState({ wingModalIsVisible: true });
  }

  editLockerModal() {
    this.setState({ lockerModalIsVisible: true });
  }

  disableWingWarning = () => {
    const { productReferences } = this.state;
    if (productReferences) {
      this.setState({
        isVisibleDisabled: false,
        isVisibleDisabledVmPS: true,
      });
    } else {
      this.setState({
        isVisibleDisabled: true,
      });
    }
  };

  handleCancelDisableWingWarning = () => {
    this.setState({ isVisibleDisabled: false });
  };

  handleCancelDisableWingWarningVmPS = () => {
    this.setState({ isVisibleDisabledVmPS: false });
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  disableFreeVmProductSlot = async () => {
    const { disableFreeVmProductSlotByWingId } = this.props;
    const { wingId, wingInfoList } = this.state;
    await this.setStateAsync({
      isLoadingDisabledVmPS: true,
      isVisibleDisabledVmPS: true,
    });
    await disableFreeVmProductSlotByWingId(wingId, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
          onOk: () =>
            this.setStateAsync({
              isLoadingDisabledVmPS: false,
              isVisibleDisabledVmPS: false,
              isVisibleDisabled: false,
            }),
        });
      } else if (response.data.code === 4004) {
        // Wing not empty
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.planogram.planogramBusy,
          onOk: () =>
            this.setStateAsync({
              isLoadingDisabledVmPS: false,
              isVisibleDisabledVmPS: false,
              isVisibleDisabled: false,
            }),
        });
      } else if (response.data.code === 4008) {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
          onOk: () =>
            this.setStateAsync({
              isLoadingDisabledVmPS: false,
              isVisibleDisabledVmPS: false,
              isVisibleDisabled: false,
            }),
        });
      } else if (response.data && response.data.status === "SUCCESS") {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
          onOk: () => {
            this.wingNumberSelection(wingInfoList.filter((wingInfo) => wingInfo.value === wingId).at(0));
            this.setStateAsync({
              isLoadingDisabledVmPS: false,
              isVisibleDisabledVmPS: false,
              isVisibleDisabled: true,
            });
          },
        });
      } else {
        // Other error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
          onOk: () =>
            this.setStateAsync({
              isLoadingDisabledVmPS: false,
              isVisibleDisabledVmPS: false,
              isVisibleDisabled: false,
            }),
        });
      }
    });
  };

  disableWing = async () => {
    const { disableWingById } = this.props;
    const { wingId } = this.state;
    await this.setStateAsync({
      loading: false,
      isLoadingDisabled: true,
    });
    await disableWingById(wingId, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
          onOk: () =>
            this.setStateAsync({
              isLoadingDisabled: false,
              isVisibleDisabled: false,
            }),
        });
      } else if (response.data.code === 4004) {
        // Wing not empty
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.machine.wingDisabledError,
          onOk: () =>
            this.setStateAsync({
              isLoadingDisabled: false,
              isVisibleDisabled: false,
            }),
        });
      } else if (response.data.code === 4008) {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
          onOk: () =>
            this.setStateAsync({
              isLoadingDisabled: false,
              isVisibleDisabled: false,
            }),
        });
      } else if (response.data && response.data.status === "SUCCESS") {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
          // onOk: this.refreshPage,
          onOk: this.updatePage(),
        });
      } else {
        // Other error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
          onOk: () =>
            this.setStateAsync({
              isLoadingDisabled: false,
              isVisibleDisabled: false,
            }),
        });
      }
    });
  };

  setWingModalVisible = (isVisible) => {
    this.setState({ wingModalIsVisible: isVisible });
  };

  setLockerModalVisible = (isVisible) => {
    this.setState({ lockerModalIsVisible: isVisible });
  };

  noVMyWarning() {
    Modal.warning({
      title: Strings.machine.noVMSelected,
      onOk: this.handleOkNoVMWarning,
      content: Strings.machine.noVMSelectedMsg,
    });
  }

  handleOkNoVMWarning = () => {
    this.setState({
      redirectBackToListVendingMachine: true,
    });
  };

  /* A function that is being called by the parent component. */
  getResponseChangefriendlyName = (result) => {
    const { getResponseChangefriendlyName } = this.props;
    getResponseChangefriendlyName(result);
  };

  methodWingInfoList = (wingType, loading, lockerTypeDTO, lockerSlotTypeDTO, loading2, productReferences, productsAmountAvailable, vmTraysTotal) => {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <Card
        style={{
          Maxwidth: 510,
        }}
        cover={<MachinesForWingTypeImages wingType={wingType} />}
      >
        <Meta title={Strings.machine.configuration.wingsInfo} />
        <Divider />
        <WingTypeId
          wingType={wingType}
          lockerTypeDTO={lockerTypeDTO}
          lockerSlotTypeDTO={lockerSlotTypeDTO}
          loading={loading}
          loading2={loading2}
          productReferences={productReferences}
          productsAmountAvailable={productsAmountAvailable}
          vmTraysTotal={vmTraysTotal}
        />
      </Card>
    );
  };

  // INFORMATION TO RENDER

  render() {
    const {
      vm,
      vmInfo,
      redirectBackToListVendingMachine,
      wingModalIsVisible,
      lockerModalIsVisible,
      lockerTypeDTO,
      lockerSlotTypeDTO,
      wingId,
      wingInfoList,
      wingInfo,
      wingType,
      loading,
      loading2,
      productReferences,
      productsAmountAvailable,
      vmTraysTotal,
      isLoadingAdd,
      isLoadingDisabled,
      isLoadingDisabledVmPS,
      isVisibleDisabled,
      isVisibleDisabledVmPS,
    } = this.state;
    const { permissions } = this.props;

    const actionsCardMethod = [
      <>{Strings.machine.wingNumber} </>,
      wingInfoList && wingInfoList.length > 1 ? (
        <div className="col-12" style={{ padding: "0.2em" }}>
          <SelectGeneral isNotText text={Strings.machine.wingNumber} options={wingInfoList} isReq={false} onChange={(e) => this.wingNumberSelection(e)} defaultValue={wingId} />
        </div>
      ) : (
        <div className="col-12" style={{ padding: "0.2em", fontSize: "25px" }}>
          {" "}
          1
        </div>
      ),

      ValidatePermissionForComponentPart("PRIVILEGE VMSETUP PUT MODULES", permissions.data) ? (
        <div className="col-12" style={{ padding: "0.2em" }}>
          <AddButton isIconButton tooltip={Strings.vmSetup.addModuleTooltip} onClick={(e) => this.wingConfigModal(e)} wingInfo={wingInfo} loading={isLoadingAdd} />
        </div>
      ) : (
        <div />
      ),

      ValidatePermissionForComponentPart("PRIVILEGE VMSETUP PUT MODULES", permissions.data) ? (
        <>
          {wingType && wingType.id === 3 ? (
            <div className="col-12" style={{ padding: "0.2em" }}>
              <EditButton isIconButton tooltip={Strings.vmSetup.editLockerTooltip} isDisabled={loading} onClick={() => this.editLockerModal()} />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <div />
      ),
      ValidatePermissionForComponentPart("PRIVILEGE VMSETUP PUT MODULES", permissions.data) ? (
        <>
          {wingInfoList && wingInfoList.length > 1 && wingType && wingInfoList.filter((wingInfo) => wingInfo.value === wingId).at(0)?.label > 1 ? (
            <div className="col-12" style={{ padding: "0.2em" }}>
              <DeleteButton
                isIconButton
                tooltip={Strings.vmSetup.removeModuleTooltip}
                onClick={() => this.disableWingWarning()}
                isDisabled={loading2}
                hideIcon={loading2}
                loading={isLoadingDisabled || isLoadingDisabledVmPS}
              />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <div />
      ),
    ];

    if (!vmInfo) {
      // eslint-disable-next-line react/jsx-filename-extension
      return <Loading />;
    }
    if (redirectBackToListVendingMachine) {
      return (
        <Redirect
          to={{
            pathname: "/listVM",
          }}
        />
      );
    }
    return (
      <>
        <WingConfigModal
          setIsVisible={this.setWingModalVisible}
          isVisible={wingModalIsVisible}
          vm={vm}
          principalWing={wingInfoList?.length > 0 ? wingInfoList[0] : null}
          updatePage={this.updatePage}
          setStateAsync={this.setStateAsync}
        />

        <EditLockerModal
          setIsVisible={this.setLockerModalVisible}
          isVisible={lockerModalIsVisible}
          lockerType={lockerTypeDTO}
          lockerSlotType={lockerSlotTypeDTO}
          wingId={wingId}
          updatePage={this.updatePage}
        />
        <Modal
          visible={isVisibleDisabledVmPS}
          onOk={this.disableFreeVmProductSlot}
          onCancel={isLoadingDisabledVmPS ? null : this.handleCancelDisableWingWarningVmPS}
          confirmLoading={isLoadingDisabledVmPS}
          cancelButtonProps={{ disabled: isLoadingDisabledVmPS }}
        >
          <p style={{ textAlign: "center", margin: "4%", marginBottom: "-3%" }}>{Strings.machine.wingDisabledError}</p>
          <p style={{ textAlign: "center", margin: "4%", marginBottom: "-3%" }}>{Strings.planogram.removePlanogramContent}</p>
        </Modal>
        <Modal
          visible={isVisibleDisabled}
          onOk={this.disableWing}
          onCancel={isLoadingDisabled ? null : this.handleCancelDisableWingWarning}
          confirmLoading={isLoadingDisabled}
          cancelButtonProps={{ disabled: isLoadingDisabled }}
        >
          <p style={{ textAlign: "center", margin: "4%", marginBottom: "-3%" }}>{Strings.vmSetup.removeModuleContent}</p>
        </Modal>

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                {" "}
                <CardBasicInformation wingType={wingType} vmInfo={vmInfo} vm={vm} getResponseChangefriendlyName={this.getResponseChangefriendlyName} actions={actionsCardMethod} />
              </div>
              <div className="col-md-6">
                {this.methodWingInfoList(wingType, loading, lockerTypeDTO, lockerSlotTypeDTO, loading2, productReferences, productsAmountAvailable, vmTraysTotal)}
              </div>
            </div>
            <Divider />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  getVendingMachineInfoById,
  listWingInfoByMachineId,
  getWingTypeById,
  getLockerInfoByWingId,
  getProductInfoByWingId,
  disableWingById,
  disableFreeVmProductSlotByWingId,
})(VendingMachineGeneralInfo);
