// Dependencies
import React, { Component } from "react";
import { Modal, InputNumber, Alert, Popconfirm } from "antd";

// Components
import { SaveButton } from "../../../GenericComponents/buttons/saveButton";
import { DeleteButton } from "../../../GenericComponents/buttons/deleteButton";
import Strings from "../../../../systemVariables/languageStrings";

let slotInfo = {
  slotNumber: null,
};
let planogramInfo = [];

class VmSlotInfoConfigModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveSlotConfigStatus: 0,
      saveStatusMsg: null,
      enableSaveButton: false,
    };
  }

  saveSlotConfig() {
    const { newSlotToSetup, slotToEdit, unblockedPlaceCurrentProduct } = this.props;
    let saveSlotConfigStatus;
    let saveStatusMsg;

    // Validadores
    let isSlotNumberEmpty = 0;
    let isColLEmpty = 0;
    let isColHEmpty = 0;
    let connInfoEquals = 0;
    let slotNumAssigned = [];
    let colLIdAssigned = [];
    let colHIdAssigned = [];

    // Valida si slotNumber está vacio
    if (slotInfo.slotNumber === null) {
      isSlotNumberEmpty = 1;
      saveStatusMsg = Strings.planogram.slotNumberRequired;
      saveSlotConfigStatus = -1;
    }

    // Valida si el slotNumber ya fue asignado en el planograma
    slotNumAssigned = planogramInfo.filter((slot) => (slot.slotNumber === slotInfo.slotNumber) & (slot.initialSlot !== slotInfo.initialSlot) /* & slot.vmProductSlotId !== null */)
      .length;

    if (slotNumAssigned > 0) {
      saveStatusMsg = Strings.planogram.slotNumberBusy;
      saveSlotConfigStatus = -1;
      this.setState({ saveStatusMsg, saveSlotConfigStatus, enableSaveButton: false });
    }

    if (slotInfo.vmSlotSizeId >= 2) {
      // Valida si COlL está vacio
      if (slotInfo.columnLId === null) {
        isColLEmpty = 1;
        saveStatusMsg = Strings.planogram.leftHarnessConnectionRequires;
        saveSlotConfigStatus = -1;
      } else {
        // Valida si el ColumnLId ya fue asignado en la bandeja
        colLIdAssigned = planogramInfo.filter(
          (slot) =>
            ((slot.columnLId === slotInfo.columnLId) | (slot.columnHId === slotInfo.columnLId)) &
            (slot.initialSlot !== slotInfo.initialSlot) &
            (slot.trayDenomination === slotInfo.trayDenomination)
        ).length;

        if (colLIdAssigned > 0) {
          saveStatusMsg = Strings.planogram.leftHarnessConnectionBusy;
          saveSlotConfigStatus = -1;
          this.setState({ saveStatusMsg, saveSlotConfigStatus, enableSaveButton: false });
        } else {
          // Valida si COlH está vacio
          if (slotInfo.columnHId === null) {
            isColHEmpty = 1;
            saveStatusMsg = Strings.planogram.rigthHarnessConnectionRequires;
            saveSlotConfigStatus = -1;
          } else {
            // Valida si ColL == ColH
            if (slotInfo.columnLId === slotInfo.columnHId) {
              connInfoEquals = 1;
              saveStatusMsg = Strings.planogram.harnessConnectionsEquals;
              saveSlotConfigStatus = -1;
            } else {
              // Valida si el ColumnHId ya fue asignado en la bandeja
              colHIdAssigned = planogramInfo.filter(
                (slot) =>
                  ((slot.columnLId === slotInfo.columnHId) | (slot.columnHId === slotInfo.columnHId)) &
                  (slot.initialSlot !== slotInfo.initialSlot) &
                  (slot.trayDenomination === slotInfo.trayDenomination)
              ).length;
              if (colHIdAssigned > 0) {
                saveStatusMsg = Strings.planogram.rigthHarnessConnectionBusy;
                saveSlotConfigStatus = -1;
              }
            }
          }
        }
      }
    } else {
      isColHEmpty = 0;
      connInfoEquals = 0;
      colHIdAssigned = 0;
      // Valida si COlL está vacio
      if (slotInfo.columnLId === null) {
        isColLEmpty = 1;
        saveStatusMsg = Strings.planogram.leftHarnessConnectionRequires;
        saveSlotConfigStatus = -1;
      } else {
        // Valida si el ColumnLId ya fue asignado en la bandeja
        colLIdAssigned = planogramInfo.filter(
          (slot) =>
            ((slot.columnLId === slotInfo.columnLId) | (slot.columnHId === slotInfo.columnLId)) &
            (slot.initialSlot !== slotInfo.initialSlot) &
            (slot.trayDenomination === slotInfo.trayDenomination)
        ).length;
        if (colLIdAssigned > 0) {
          saveStatusMsg = Strings.planogram.leftHarnessConnectionBusy;
          saveSlotConfigStatus = -1;
          this.setState({ saveStatusMsg, saveSlotConfigStatus, enableSaveButton: false });
        }
      }
    }

    if (isSlotNumberEmpty === 0 && slotNumAssigned === 0 && isColLEmpty === 0 && colLIdAssigned === 0 && isColHEmpty === 0 && connInfoEquals === 0 && colHIdAssigned === 0) {
      if (this.props.isEditFlag === false) {
        newSlotToSetup(slotInfo);
      } else {
        slotToEdit(slotInfo);
      }
      this.setState({ saveStatusMsg: "", saveSlotConfigStatus: 0, enableSaveButton: false });
      unblockedPlaceCurrentProduct();
      // this.props.onClose();
    } else {
      this.setState({ saveStatusMsg, saveSlotConfigStatus, enableSaveButton: false });
    }
  }

  deleteSlotConfig() {
    this.props.slotToDisable(slotInfo);
    this.props.onClose();
  }

  handleCancel = () => {
    this.setState({ saveStatusMsg: "", saveSlotConfigStatus: 0, enableSaveButton: false });
    this.props.onClose();
  };

  changeSlotNumber = (val) => {
    slotInfo.slotNumber = val;
    this.setState({ saveStatusMsg: "", saveSlotConfigStatus: 0, enableSaveButton: true });
    this.forceUpdate();
  };

  changeSlotConnL = (val) => {
    slotInfo.columnLId = val;
    this.setState({ saveStatusMsg: "", saveSlotConfigStatus: 0, enableSaveButton: true });
    this.forceUpdate();
  };

  changeSlotConnH = (val) => {
    slotInfo.columnHId = val;
    this.setState({ saveStatusMsg: "", saveSlotConfigStatus: 0, enableSaveButton: true });
    this.forceUpdate();
  };

  render() {
    const { isVisible, maxNumSlots, isEditFlag, isLoadingSaveButton, isLoadingDisableButton, slotInfo: slotInfoProps, planogramInfo: planogramInfoProps } = this.props;
    const { enableSaveButton, saveSlotConfigStatus, saveStatusMsg } = this.state;

    slotInfo = slotInfoProps;
    planogramInfo = planogramInfoProps;

    if (!isVisible || !slotInfo || planogramInfo.length <= 0) {
      return "";
    }

    return (
      <Modal
        visible={isVisible}
        onCancel={this.handleCancel}
        width={500}
        title={Strings.planogram.slotConfiguration}
        footer={[
          <div className="row" key={1}>
            <div className="col-6">
              <SaveButton isDisabled={!enableSaveButton} onClick={() => this.saveSlotConfig()} loading={isLoadingSaveButton} />
            </div>
            <div className="col-6">
              <Popconfirm
                title={Strings.generalResponses.confirmDeleteMessage}
                onConfirm={() => this.deleteSlotConfig()}
                okText={Strings.generalTerms.yes}
                cancelText={Strings.generalTerms.no}
              >
                <DeleteButton loading={isLoadingDisableButton} />
              </Popconfirm>
            </div>
          </div>,
        ]}
      >
        <div className="col-12 slotInfoRow">
          {isEditFlag === true ? (
            <div className="row">
              <div className="col-md-5">
                <span>{Strings.planogram.slotNumber}:</span>
              </div>
              <div className="col-md-7">
                <span>{slotInfo.slotNumber}</span>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-5">
                <span>{Strings.planogram.slotNumber}:</span>
              </div>
              <div className="col-md-7">
                <InputNumber
                  min={(slotInfo.trayDenomination + 1) * maxNumSlots}
                  max={(slotInfo.trayDenomination + 2) * maxNumSlots - 1}
                  value={slotInfo.slotNumber}
                  onChange={(val) => this.changeSlotNumber(val)}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-5">
              <span>{Strings.generalTerms.tray}:</span>
            </div>
            <div className="col-md-7">
              <span>{slotInfo.trayDenomination + 1}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <span>{Strings.product.scProductCode}:</span>
            </div>
            <div className="col-md-7">
              <span>{slotInfo.soProductCode}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <span>{Strings.product.soProduct}:</span>
            </div>
            <div className="col-md-7">
              <span>{slotInfo.soProductName}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <span>{Strings.planogram.connectionLeft}:</span>
            </div>
            <div className="col-md-7">
              <InputNumber min={0} max={maxNumSlots - 1} value={slotInfo.columnLId} onChange={(val) => this.changeSlotConnL(val)} />
            </div>
          </div>
          {slotInfo.vmSlotSizeId >= 2 && slotInfo.columnHId >= 0 && (
            <div className="row">
              <div className="col-md-5">
                <span>{Strings.planogram.connectionRight}:</span>
              </div>
              <div className="col-md-7">
                <InputNumber min={0} max={maxNumSlots - 1} value={slotInfo.columnHId} onChange={(val) => this.changeSlotConnH(val)} />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12" style={{ marginTop: "10px" }}>
              {saveSlotConfigStatus === 1 ? (
                <Alert message={saveStatusMsg} type="success" showIcon />
              ) : (
                saveSlotConfigStatus === -1 && <Alert message={saveStatusMsg} type="error" showIcon />
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default VmSlotInfoConfigModal;
