import { Alert, Button, Modal, Popconfirm } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import Strings from "../../systemVariables/languageStrings";
import SoScVmDependencySelector from "../GenericComponents/selectComponent/SoScVmDependencySelector";
import { SelectGeneral } from "../GenericComponents/selectComponent/index";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";

import {
  deleteVmPeripheralDevice,
  getAllRelatedVendingMachinesToSoDependencyId,
  getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId,
  getPendingRegistersByVm,
  getSlotGroupByWingId,
  getWingsByVmId,
  insertVmPeripheralDevice,
  listVendingMachinesByScDependencyId,
  listVendingMachinesByScDependencyIdAndGenerationId,
} from "../../actions";

const INITIAL_STATE = {
  isAssignment: null,
  modalMsg: "",
  isModalVisible: false,
  isModalLoading: false,

  scDependencyId: null,

  vendingMachines: [],
  vendingMachineId: null,
  isVendingMachineDisabled: true,

  wings: [],
  wingId: null,
  isWingVisible: false,
  isWingRequired: false,

  slotGroups: [],
  slotGroupId: null,
  isSlotGroupSelectorVisible: false,
  isSlotGroupRequired: false,

  okText: "",
};

const SCOPES = { MACHINE: 0, WING: 1, SLOT_GROUP: 2, MACHINE_WING: 3 };

const WING_TYPE_LOCKER = 3;

class VmPeripheralDeviceModal extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.state = {
      vendingMachines: [],
      regsToUpload: [],
    };
  }

  async componentDidMount() {
    const {
      peripheralInfo: { currentPeripheralDeviceIdsGeneration, vmId },
      soScSelectionProps,
      getPendingRegistersByVm,
    } = this.props;

    if (soScSelectionProps.so !== null && soScSelectionProps.sc === null) {
      if (soScSelectionProps.so !== null && currentPeripheralDeviceIdsGeneration === undefined)
        await this.getAllRelatedVendingMachinesToSoDependencyIdPeripheral(soScSelectionProps.so.id);
      if (soScSelectionProps.so !== null && currentPeripheralDeviceIdsGeneration !== undefined)
        await this.getAllRelatedVendingMachinesToSoDependencyIdAndGenerationIdPeripheral(soScSelectionProps.so.id, currentPeripheralDeviceIdsGeneration);
    }

    if (soScSelectionProps.sc !== null && currentPeripheralDeviceIdsGeneration === undefined) await this.getListVendingMachinesByScDependencyId(soScSelectionProps.sc.id);
    if (soScSelectionProps.sc !== null && currentPeripheralDeviceIdsGeneration !== undefined)
      await this.getlistVendingMachinesByScDependencyIdAndGenerationId(soScSelectionProps.sc.id, currentPeripheralDeviceIdsGeneration);

    if (vmId != null) {
      getPendingRegistersByVm(vmId, (response) => {
        if (response.data !== null && response.data !== undefined) {
          this.setState({
            regsToUpload: response.data.data.regsToUpload,
          });
        }
      });
    }
  }

  resetState() {
    this.setState(INITIAL_STATE);
  }

  resetSelectors() {
    this.setState({
      vendingMachineId: null,
      wingId: null,
      slotGroupId: null,
      isWingVisible: false,
      isSlotGroupSelectorVisible: false,
      modalMsg: "",
    });
  }

  handleDependencyChangeSo = (event) => {
    const {
      peripheralInfo: { currentPeripheralDeviceIdsGeneration },
    } = this.props;
    if (currentPeripheralDeviceIdsGeneration === undefined) this.getAllRelatedVendingMachinesToSoDependencyIdPeripheral(event.value);
    else this.getAllRelatedVendingMachinesToSoDependencyIdAndGenerationIdPeripheral(event.value, currentPeripheralDeviceIdsGeneration);
    this.resetSelectors();
  };

  getAllRelatedVendingMachinesToSoDependencyIdPeripheral = async (soDependenciId) => {
    const { getAllRelatedVendingMachinesToSoDependencyId } = this.props;
    await getAllRelatedVendingMachinesToSoDependencyId(soDependenciId, (response) => {
      this.setState({
        vendingMachines: response.data.data.map((item) => ({
          value: item.id,
          label: item.friendlyName,
        })),
      });
    });
  };

  getAllRelatedVendingMachinesToSoDependencyIdAndGenerationIdPeripheral = async (soDependenciId, generationId) => {
    const { getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId } = this.props;
    await getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId(soDependenciId, generationId, (response) => {
      this.setState({
        vendingMachines: response.data.data.map((item) => ({
          value: item.id,
          label: item.friendlyName,
        })),
      });
    });
  };

  handleDependencyChangeSc = async (event) => {
    const {
      peripheralInfo: { currentPeripheralDeviceIdsGeneration },
    } = this.props;
    if (currentPeripheralDeviceIdsGeneration === undefined) this.getListVendingMachinesByScDependencyId(event.value);
    else this.getlistVendingMachinesByScDependencyIdAndGenerationId(event.value, currentPeripheralDeviceIdsGeneration);
    this.resetSelectors();
  };

  getListVendingMachinesByScDependencyId = async (scDependenciId) => {
    const { listVendingMachinesByScDependencyId } = this.props;
    await listVendingMachinesByScDependencyId(scDependenciId, (response) => {
      this.setState({
        vendingMachines: response.data.data.map((item) => ({
          value: item.id,
          label: item.friendlyName,
        })),
      });
    });
  };

  getlistVendingMachinesByScDependencyIdAndGenerationId = async (scDependenciId, generationId) => {
    const { listVendingMachinesByScDependencyIdAndGenerationId } = this.props;
    await listVendingMachinesByScDependencyIdAndGenerationId(scDependenciId, generationId, (response) => {
      this.setState({
        vendingMachines: response.data.data.map((item) => ({
          value: item.id,
          label: item.friendlyName,
        })),
      });
    });
  };

  handleVendingMachineChange = (event) => {
    const { value: vendingMachineId } = event;

    this.setState({
      vendingMachineId,
      wingId: null,
      slotGroupId: null,
      isSlotGroupSelectorVisible: false,
      modalMsg: "",
    });
    const {
      peripheralInfo: { peripheralDeviceScopeId },
      getWingsByVmId,
    } = this.props;

    if (peripheralDeviceScopeId !== SCOPES.MACHINE) {
      getWingsByVmId(vendingMachineId, (response) => {
        if (response.data !== null && response.data !== undefined) {
          this.setState({
            wings: response.data.data.map((item) => ({
              value: item.id,
              label: `${Strings.machine.wing} ${item.wingNumber} - ${item.wingTypeName}`,
              wingTypeId: item.wingTypeId,
            })),
          });
        }
      });
    }
    // Show wing if
    if (peripheralDeviceScopeId === SCOPES.WING || peripheralDeviceScopeId === SCOPES.MACHINE_WING || peripheralDeviceScopeId === SCOPES.SLOT_GROUP) {
      this.setState({
        isWingRequired: true,
        isWingVisible: true,
      });
    }
  };

  handleWingChange = (event) => {
    const { value: wingId, wingTypeId } = event;
    this.setState({
      wingId,
      slotGroups: [],
      slotGroupId: null,
      isSlotGroupSelectorVisible: false,
      isSlotGroupRequired: false,
      modalMsg: "",
    });

    const {
      peripheralInfo: { peripheralDeviceScopeId },
      getSlotGroupByWingId,
    } = this.props;
    // Show the SlotGroup if the peripheral has this scope and is a Locker Wing
    if (peripheralDeviceScopeId === SCOPES.SLOT_GROUP && wingTypeId === WING_TYPE_LOCKER) {
      getSlotGroupByWingId(wingId, (response) => {
        if (response.data !== null && response.data !== undefined) {
          this.setState({
            slotGroups: response.data.data.map((item) => ({
              value: item.id,
              label: `${Strings.machine.slotGroup} ${item.number}`,
            })),
            isSlotGroupSelectorVisible: true,
            isSlotGroupRequired: true,
          });
        }
      });
    }
  };

  handleSlotGroupChange = (event) => {
    const { value: slotGroupId } = event;
    this.setState({
      slotGroupId,
      modalMsg: "",
    });
  };

  handleCancel = () => {
    const { isModalLoading } = this.state;
    if (!isModalLoading) {
      this.setState({ isModalVisible: false });
    }
    this.componentDidMount();
    this.resetState();
  };

  handleOk = () => {
    const { vendingMachineId, wingId, slotGroupId, isAssignment } = this.state;
    const {
      peripheralInfo: { id: peripheralDeviceId, vmPeripheralDeviceId },
    } = this.props;
    if (isAssignment) {
      this.assignVmPeripheralDevice(peripheralDeviceId, vendingMachineId, wingId, slotGroupId);
    } else {
      this.unAssignVmPeripheralDevice(vmPeripheralDeviceId);
    }
  };

  validateParameters(wingId, slotGroupId) {
    const { isWingRequired, isSlotGroupRequired } = this.state;
    let isValid = true;
    if (isWingRequired) {
      if (wingId === null || wingId === undefined) {
        isValid = false;
      }
    }

    if (isSlotGroupRequired) {
      if (slotGroupId === null || slotGroupId === undefined) {
        isValid = false;
      }
    }
    return isValid;
  }

  assignVmPeripheralDevice = (deviceId, vendingMachineId, wingId, slotGroupId) => {
    let modalMsg;
    const isValid = this.validateParameters(wingId, slotGroupId);
    if (isValid) {
      this.setState({
        isModalLoading: true,
      });
      const { insertVmPeripheralDevice, reloadTable } = this.props;
      insertVmPeripheralDevice(deviceId, vendingMachineId, wingId, slotGroupId, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          modalMsg = Strings.generalResponses.saveError;
        } else if (response.data.code === 0) {
          reloadTable();
          this.resetState();
        } else if (response.data.code === 4008) {
          modalMsg = this.getModalResponse(response.data.description);
        } else if (response.data.status === "FAILURE") {
          modalMsg = Strings.generalResponses.saveError;
        }
        this.setState({
          isModalLoading: false,
          modalMsg,
        });
      });
    } else {
      modalMsg = Strings.generalResponses.invalidError;
      this.setState({
        modalMsg,
      });
    }
  };

  getModalResponse = (description) => {
    let modalMsg = null;
    if (description.includes("Peripheral already assigned to vm")) {
      modalMsg = Strings.peripheralDevice.peripheralAlreadyAssignedToVmMsg;
    } else if (description.includes("Bill Acceptor or Bill Recycler already assigned to vm")) {
      modalMsg = Strings.peripheralDevice.billAcceptorOrReciclerAlreadyAssignedToVmMsg;
    } else if (description.includes("Peripheral already assigned to wing")) {
      modalMsg = Strings.peripheralDevice.peripheralAlreadyAssignedToWingMsg;
    } else if (description.includes("The slotgroup record with id")) {
      modalMsg = "El periférico ya fue asignado al slotGroup";
    }
    return <Alert message="Warning" description={modalMsg} type="warning" showIcon />;
  };

  unAssignVmPeripheralDevice = (vmPeripheralDeviceId) => {
    const { reloadTable, deleteVmPeripheralDevice } = this.props;
    this.setState({
      isModalLoading: true,
    });
    deleteVmPeripheralDevice(vmPeripheralDeviceId, (response) => {
      let modalMsg;
      if ((!response.data && String(response).includes("Error:")) || response.data.status === "FAILURE") {
        modalMsg = Strings.generalResponses.saveError;
      } else if (response.data.code === 0) {
        reloadTable();
        this.resetState();
      }
      this.setState({
        modalMsg,
        isModalLoading: false,
      });
    });
  };

  renderTitle = (isAssignment) => {
    let render = null;
    if (isAssignment) {
      render = <TextWithInfoTooltip name={Strings.peripheralDevice.assignPeripheralDevice} tooltip={Strings.peripheralDevice.assignPeripheralDeviceTooltip} />;
    } else {
      render = <TextWithInfoTooltip name={Strings.peripheralDevice.unassignPeripheralDevice} tooltip={Strings.peripheralDevice.unassignPeripheralDeviceTooltip} />;
    }
    return render;
  };

  renderAssignPeripheral = () => {
    const {
      wings,
      wingId,
      isWingRequired,
      isWingVisible,
      slotGroups,
      slotGroupId,
      isSlotGroupSelectorVisible,
      isSlotGroupRequired,
      vendingMachines,
      vendingMachineId,
    } = this.state;

    return (
      <>
        <SoScVmDependencySelector
          onSoDependencyChange={this.handleDependencyChangeSo}
          onScDependencyChange={this.handleDependencyChangeSc}
          dependencyType={sessionStorage.getItem("dependencyType")}
          dependencyId={sessionStorage.getItem("dependencyId")}
          hasSc
          callbackSoId={this.getCallbackSoId}
        />

        <div className="col-xs-12 col-md-6">
          <SelectGeneral
            text={Strings.machine.machine}
            tooltip={Strings.machine.machinesTooltip}
            options={vendingMachines}
            onChange={this.handleVendingMachineChange}
            disabled={vendingMachines.length > 0 ? false : true}
            defaultValue={vendingMachineId}
          />
        </div>
        {isWingVisible ? (
          <div className="col-xs-12 col-md-6">
            <SelectGeneral
              text={Strings.machine.wing}
              tooltip={Strings.machine.wingTooltip}
              options={wings}
              isReq={isWingRequired}
              onChange={this.handleWingChange}
              defaultValue={wingId}
            />
          </div>
        ) : null}
        {isSlotGroupSelectorVisible ? (
          <div className="col-xs-12 col-md-6">
            <SelectGeneral
              text={Strings.machine.slotGroup}
              tooltip={Strings.machine.slotGroupToolTip}
              options={slotGroups}
              isReq={isSlotGroupRequired}
              onChange={(e) => this.handleSlotGroupChange(e)}
              defaultValue={slotGroupId}
            />
          </div>
        ) : null}
      </>
    );
  };

  renderAssignationInfo = (vendingMachine, wingNumber, wingTypeId, wingTypeName, slotGroupNumber, peripheralDeviceScopeId) => {
    let renderWingInfo = null;
    let renderSlotGroupInfo = null;

    if (peripheralDeviceScopeId === SCOPES.WING || peripheralDeviceScopeId === SCOPES.MACHINE_WING || peripheralDeviceScopeId === SCOPES.SLOT_GROUP) {
      renderWingInfo = (
        <>
          <div className="col-xs-12 col-md-6">
            <p>
              <b>{Strings.machine.wing + ":"}</b>
            </p>
          </div>
          <div className="col-xs-12 col-md-6">
            <p>{`Number ${wingNumber} - ${wingTypeName}`}</p>
          </div>
        </>
      );
    }

    // Show the SlotGroup if the peripheral has this scope and is a Locker Wing
    if (peripheralDeviceScopeId === SCOPES.SLOT_GROUP && wingTypeId === WING_TYPE_LOCKER) {
      renderSlotGroupInfo = (
        <>
          <div className="col-xs-12 col-md-6">
            <p>
              <b>{Strings.machine.slotGroup + ":"}</b>
            </p>
          </div>
          <div className="col-xs-12 col-md-6">
            <p>{`${slotGroupNumber}`}</p>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="col-xs-12 col-md-6">
          <p>
            <b>{Strings.machine.machine + ":"}</b>
          </p>
        </div>
        <div className="col-xs-12 col-md-6">
          <p>{`${vendingMachine}`}</p>
        </div>
        {renderWingInfo}
        {renderSlotGroupInfo}
      </>
    );
  };

  WarnigInfoMessage = (isAssignment, peripheralDeviceTypeId) => {
    const { regsToUpload } = this.state;

    if (isAssignment) {
      return Strings.peripheralDevice.assignPeripheralDeviceWarn;
    } else {
      if (peripheralDeviceTypeId == 35) {
        return (
          <>
            <span>{Strings.peripheralDevice.unassignPeripheralDeviceWarn}</span>
            <br />
            <span style={{ whiteSpace: "normal" }}>{Strings.peripheralDevice.unassingnNecWarning}</span>
            <br />
            <span>{<b>{Strings.vmSetup.regsQty + ": " + regsToUpload.length}</b>}</span>
          </>
        );
      } else {
        return Strings.peripheralDevice.unassignPeripheralDeviceWarn;
      }
    }
  };

  handleOnClickAssign = () => {
    this.setState({ isModalVisible: true, isAssignment: true });
  };

  handleOnClickAssignationInfo = () => {
    this.setState({ isModalVisible: true, isAssignment: false });
  };

  methodVerificationModal = (vendingMachineId, isAssignment) => {
    if (vendingMachineId) return false;
    if (isAssignment === false) return false;

    return true;
  };

  render() {
    const { isAssignment, isModalVisible, isModalLoading, modalMsg, vendingMachines, vendingMachineId, vmPeripheralDeviceId } = this.state;
    const {
      peripheralInfo: {
        name = "-",
        reference = "-",
        peripheralDeviceSerialNumber = "-",
        peripheralDeviceAlternativeSerialNumber = "-",
        peripheralDeviceScopeId,
        vmName,
        wingNumber = "",
        wingTypeId,
        wingTypeName = "",
        slotGroupNumber = "",
        currentPeripheralDevicesGeneration,
      },
    } = this.props;
    return (
      <>
        {vmName != null ? (
          <div
            style={{ color: "#004173", cursor: "pointer" }}
            role="button"
            tabIndex={0}
            onClick={this.handleOnClickAssignationInfo}
            onKeyPress={this.handleOnClickAssignationInfo}
          >
            {vmName}
          </div>
        ) : (
          <div style={{ color: "#004173", cursor: "pointer" }} role="button" tabIndex={0} onClick={this.handleOnClickAssign} onKeyPress={this.handleOnClickAssign}>
            {Strings.generalTerms.assign}
          </div>
        )}
        <Modal
          title={this.renderTitle(isAssignment)}
          visible={isModalVisible}
          cancelText={Strings.generalTerms.cancel}
          cancelButtonProps={{ disabled: isModalLoading }}
          onCancel={this.handleCancel}
          footer={[
            <Popconfirm
              title={this.WarnigInfoMessage(isAssignment, this.props.peripheralInfo.peripheralDeviceTypeId)}
              onConfirm={this.handleOk}
              okText={Strings.generalTerms.accept}
              cancelText={Strings.generalTerms.cancel}
            >
              <Button type="primary" style={{ margin: "5px" }} disabled={this.methodVerificationModal(vendingMachineId, isAssignment, isModalLoading)}>
                <b>{isAssignment ? Strings.generalTerms.assign : Strings.generalTerms.unassign}</b>
              </Button>
            </Popconfirm>,
          ]}
          width={window.innerWidth >= 1000 && isAssignment ? 1000 : 500}
        >
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <p>
                <b>{Strings.peripheralDevice.peripheralDeviceType + ":"}</b>
              </p>
            </div>
            <div className="col-xs-12 col-md-6">
              <p>{`${name}`}</p>
            </div>
            <div className="col-xs-12 col-md-6">
              <p>
                <b>{Strings.peripheralDevice.peripheralDeviceModel + ":"}</b>
              </p>
            </div>
            <div className="col-xs-12 col-md-6">
              <p>{`${reference}`}</p>
            </div>
            <div className="col-xs-12 col-md-6">
              <p>
                <b>{Strings.peripheralDevice.peripheralDeviceSerialNumber + ":"}</b>
              </p>
            </div>
            <div className="col-xs-12 col-md-6">
              <p>{`${peripheralDeviceSerialNumber || ""}`}</p>
            </div>
            <div className="col-xs-12 col-md-6">
              <p>
                <b>{Strings.peripheralDevice.peripheralDeviceAlternativeSerialNumber + ":"}</b>
              </p>
            </div>
            <div className="col-xs-12 col-md-6">
              <p>{`${peripheralDeviceAlternativeSerialNumber || ""}`}</p>
            </div>
            <div className="col-xs-12 col-md-6">
              <p>
                <b>{Strings.machine.machineGeneration + ":"}</b>
              </p>
            </div>
            <div className="col-xs-12 col-md-6">
              <p>{`${currentPeripheralDevicesGeneration !== undefined ? currentPeripheralDevicesGeneration : Strings.peripheralDevice.peripheralAllGeneration}`}</p>
            </div>
            {isAssignment ? this.renderAssignPeripheral() : this.renderAssignationInfo(vmName, wingNumber, wingTypeId, wingTypeName, slotGroupNumber, peripheralDeviceScopeId)}
          </div>
          <div style={{ marginTop: "20px" }}>
            <b>{modalMsg}</b>
          </div>
          <div>{vendingMachines.length < 0 ? <Alert message="Warning" description="no machines" type="warning" showIcon /> : null}</div>
        </Modal>
      </>
    );
  }
}

VmPeripheralDeviceModal.propTypes = {
  peripheralInfo: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    reference: PropTypes.string,
    peripheralDeviceSerialNumber: PropTypes.string,
    peripheralDeviceAlternativeSerialNumber: PropTypes.string,
    peripheralDeviceScopeId: PropTypes.number,
    vmPeripheralDeviceId: PropTypes.number,
    vmName: PropTypes.string,
    wingNumber: PropTypes.number,
    wingTypeId: PropTypes.number,
    wingTypeName: PropTypes.string,
    slotGroupNumber: PropTypes.number,
    currentPeripheralDevicesGeneration: PropTypes.string,
    currentPeripheralDeviceIdsGeneration: PropTypes.number,
  }).isRequired,
  reloadTable: PropTypes.func.isRequired,
  insertVmPeripheralDevice: PropTypes.func,
  deleteVmPeripheralDevice: PropTypes.func,
  getWingsByVmId: PropTypes.func,
  getSlotGroupByWingId: PropTypes.func,
  getAllRelatedVendingMachinesToSoDependencyId: PropTypes.func,
  getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId: PropTypes.func,
  listVendingMachinesByScDependencyId: PropTypes.func,
  listVendingMachinesByScDependencyIdAndGenerationId: PropTypes.func,
};

VmPeripheralDeviceModal.defaultProps = {
  insertVmPeripheralDevice: null,
  deleteVmPeripheralDevice: null,
  getWingsByVmId: null,
  getSlotGroupByWingId: null,
  getAllRelatedVendingMachinesToSoDependencyId: null,
  getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId: null,
  listVendingMachinesByScDependencyId: null,
  listVendingMachinesByScDependencyIdAndGenerationId: null,
};

function mapStateToProps(state) {
  return {
    userDependency: state.dependencyByUser.dependencyObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  insertVmPeripheralDevice,
  deleteVmPeripheralDevice,
  getWingsByVmId,
  getSlotGroupByWingId,
  getAllRelatedVendingMachinesToSoDependencyId,
  getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId,
  listVendingMachinesByScDependencyId,
  listVendingMachinesByScDependencyIdAndGenerationId,
  getPendingRegistersByVm,
})(VmPeripheralDeviceModal);
