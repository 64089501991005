import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/vwadjustmentreport";

export function getAdjustmentReportByVendingMachineIdPaginated(
  vendingMachineId,
  adjustmentTypeId,
  soProductName,
  erpProductCode,
  slotNumber,
  startDate,
  endDate,
  page,
  size,
  attribute,
  order,
  callback
) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingmachineid: vendingMachineId,
          adjustmenttypeid: adjustmentTypeId,
          soproductname: soProductName,
          erpproductcode: erpProductCode,
          slotnumber: slotNumber,
          startdate: startDate,
          enddate: endDate,
          page,
          size,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getAdjustmentReportByScDependencyIdPaginated(
  scDependencyId,
  adjustmentTypeId,
  soProductName,
  erpProductCode,
  slotNumber,
  startDate,
  endDate,
  page,
  size,
  attribute,
  order,
  callback
) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId,
          adjustmenttypeid: adjustmentTypeId,
          soproductname: soProductName,
          erpproductcode: erpProductCode,
          slotnumber: slotNumber,
          startdate: startDate,
          enddate: endDate,
          page,
          size,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getAdjustmentReportBySoDependencyIdPaginated(
  soDependencyId,
  adjustmentTypeId,
  soProductName,
  erpProductCode,
  slotNumber,
  startDate,
  endDate,
  page,
  size,
  attribute,
  order,
  callback
) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          sodependencyid: soDependencyId,
          adjustmenttypeid: adjustmentTypeId,
          soproductname: soProductName,
          erpproductcode: erpProductCode,
          slotnumber: slotNumber,
          startdate: startDate,
          enddate: endDate,
          page,
          size,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function exportAdjustmentReport(
  stockOwnerId,
  scDependencyId,
  vendingMachineId,
  adjustmentTypeId,
  soProductName,
  erpProductCode,
  slotNumber,
  startDate,
  endDate,
  attribute,
  order,
  fileType,
  columnHeaders,
  callback
) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        responseType: "blob",
        params: {
          stockownerid: stockOwnerId,
          scdependencyid: scDependencyId,
          vendingmachineid: vendingMachineId,
          adjustmenttypeid: adjustmentTypeId,
          soproductname: soProductName,
          erpproductcode: erpProductCode,
          slotnumber: slotNumber,
          startdate: startDate,
          enddate: endDate,
          attribute,
          order,
          filetype: fileType,
          columnheaders: encodeURIComponent(columnHeaders),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${  sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}
