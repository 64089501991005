import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
let resource = "/poductassignmentbyrule";

export function listDispensationRuleInfoByScDispensationRuleId(dispRuleId, callback){
  return () => {
    axios.get(host+ port + path  + version + resource,{
      params: {
        dispensationrule_id: dispRuleId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      callback(error);
    });
  }
}
