export function resizeImage(uri) {
   let imageFile;
   var byteString = atob(uri.split(',')[1]);
   var ab = new ArrayBuffer(byteString.length);
   var ia = new Uint8Array(ab);
   for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
   }
   var blob = new Blob([ia], { type: 'image/jpeg' });
   var file = new File([blob], "image.jpg");

   imageFile = file

   return imageFile
}