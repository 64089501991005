// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "./soProductSlotType.css";
import { Divider } from "antd";

// Actions
import {
  listVmTrayPosition,
  listVmSlotPosition,
  listMotorTypes,
  listHelixType,
  listVmSlotSize,
  listSoDependencyBySoDependencyType0,
  listSoProductsBySoDependency,
  listSoProductSlotTypeBySoProductId,
} from "../../../actions";

// Components
import Titles from "../../GenericComponents/titles";
import { SelectGeneral } from "../../GenericComponents/selectComponent/selectGeneral";
import { AddButton } from "../../GenericComponents/buttons/index";
// eslint-disable-next-line import/extensions
import SoProductSlotTypeTable from "./SoProductSlotTypeTable.jsx";
import { ValidateDependency } from "../../../Utils/validateDependency";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { motorTypeConfigLocalization, helixTypeLocalization } from "../../../Utils/LanguageLocalization/index";

class SoProductSlotType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soDependencyId: null,
      soDependencyName: null,
      // soProductSlotType: {
      //   motortypeId: 0,
      //   helixtypeId: 0,
      //   soProductId: 0,
      //   vmslotpositionId: 0,
      //   vmslotsizeId: 0,
      //   vmtraypositionId: 0,
      //   hasHelixAligner: 0,
      //   hasProductAligner: 0,
      //   hasSlidingBase: 0,
      //   hasSingleMotor: 0,
      // },
      selectedProduct: {
        label: null,
        value: null,
      },
      soProductsNameList: [],
      soProductSlotTypes: null,
      isLoading: false,
      addButtonDisabled: true,
      disabledProduct: true,
    };
  }

  componentDidMount() {
    const {
      vmSlotSizes,
      motorTypes,
      helixTypes,
      vmTrayPositions,
      vmSlotPositions,
      userDependency,
      listVmSlotSize,
      listMotorTypes,
      listHelixType,
      listVmTrayPosition,
      listVmSlotPosition,
      listSoDependencyBySoDependencyType0,
      listSoProductsBySoDependency,
    } = this.props;
    // ---Query Backend SoProductSlotType related variables if they are not yet in reducer---
    // VmSlotSize
    if (!vmSlotSizes || vmSlotSizes.length == 0) {
      listVmSlotSize();
    }
    // Motors
    if (!motorTypes || motorTypes.length == 0) {
      listMotorTypes();
    }
    // Helices
    if (!helixTypes || helixTypes.length == 0) {
      listHelixType();
    }
    // VmTrayPositions
    if (!vmTrayPositions || vmTrayPositions.length == 0) {
      listVmTrayPosition();
    }
    // VmSlotPositionsvmSlotPositions
    if (!vmSlotPositions || vmSlotPositions.length == 0) {
      listVmSlotPosition();
    }

    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") == "1") {
      listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
    }
    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") !== "1") {
      const value = sessionStorage.getItem("dependencyId");
      this.setState({
        soDependencyId: userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyId : null,
        soDependencyName: userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyName : null,
        disabledProduct: false,
      });
      listSoProductsBySoDependency(value, (response) => {
        this.setState({
          // Map SoProducts to name list to print in select
          soProductsNameList: response.data.data.map((item) => ({ value: item.id, label: item.name })),
        });
      });
    }
  }

  // -----------Events------------
  soDependencyHandleChange(event) {
    const { value } = event;
    const { listSoProductsBySoDependency } = this.props;
    this.setState({
      soDependencyId: value,
      soProductId: null,
      disabledProduct: false,
      soProductsNameList: [],
      soProductSlotTypes: null,
      selectedProduct: {
        label: null,
        value: null,
      },
    });
    listSoProductsBySoDependency(value, (response) => {
      this.setState({
        // Map SoProducts to name list to print in select
        soProductsNameList: response.data.data.map((item) => ({ value: item.id, label: item.name })),
      });
    });
  }

  soProductHandleChange(event) {
    const { value } = event;
    const { listSoProductSlotTypeBySoProductId } = this.props;

    this.setState({
      isLoading: true,
      soProductId: value,
      selectedProduct: {
        label: null,
        value: null,
      },
    });

    listSoProductSlotTypeBySoProductId(value, (response) => {
      const soProductSlotTypeBySo = response.data.data;
      if (soProductSlotTypeBySo.length > 0) {
        // Filter to get only one SoProductSlotType
        const soProductSlotTypeBySoTotal = [];
        soProductSlotTypeBySo.forEach((elem) => {
          if (soProductSlotTypeBySoTotal.filter((e) => e.id === elem.id).length == 0) {
            soProductSlotTypeBySoTotal.push(elem);
          }
        });

        // Map motors to MotorConfig in motorH
        soProductSlotTypeBySoTotal.forEach((elem) => {
          elem.motorConfig = motorTypeConfigLocalization(elem.motorL, elem.motorH).label;
        });

        // Map helix type
        soProductSlotTypeBySoTotal.forEach((elem) => {
          elem.helixL = helixTypeLocalization(elem.helixL, elem.helixH, elem.spaces, elem.turnTypeNameL, elem.length);
        });
        this.setState({
          addButtonDisabled: false,
          selectedProduct: event,
          soProductSlotTypes: soProductSlotTypeBySoTotal,
          isLoading: false,
        });
      } else {
        this.setState({
          addButtonDisabled: false,
          selectedProduct: event,
          soProductSlotTypes: null,
          isLoading: false,
        });
      }
    });
  }

  render() {
    const {
      permissions: { data },
      soDependencies,
    } = this.props;
    const { soDependencyId, soDependencyName, soProductsNameList, disabledProduct, soProductId, selectedProduct, addButtonDisabled, soProductSlotTypes, isLoading } = this.state;
    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.product.soProductSlotType} tooltip={Strings.product.soProductSlotTypeTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-xs-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <ValidateDependency
            soDependencyHtmlTag="soDependencyType0"
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            soDependencies={soDependencies}
            soDependencyId={soDependencyId}
            soDependency={soDependencyName}
            soDependencyHandleChange={(e) => this.soDependencyHandleChange(e)}
            hasOnlySO
          />
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.product.product}
              tooltip={Strings.product.soProductTooltip}
              isReq
              options={soProductsNameList}
              onChange={(e) => this.soProductHandleChange(e)}
              disabled={disabledProduct}
              defaultValue={soProductId}
            />
          </div>
        </div>
        <ValidatePermissionForComponent permission="PRIVILEGE SO PRODUCT SLOT TYPE POST" permissions={data}>
          <div className="row">
            <div className="vertSpace col-md-6" />
            <br />
            <div className="vertSpace col-md-6">
              <AddButton
                link={addButtonDisabled ? "" : "/addSoProductSlotType"}
                tooltip={Strings.planogram.addSoProductSlotType}
                props={addButtonDisabled ? "" : { selectedProd: selectedProduct }}
                disabled={addButtonDisabled}
              />
            </div>
          </div>
        </ValidatePermissionForComponent>
        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.product.availableSoProductSlotType} </h3>
            </Divider>
          </div>
          {selectedProduct.label ? (
            <div className="col-12">
              <h3> {selectedProduct.label} </h3>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <SoProductSlotTypeTable soProductSlotTypes={soProductSlotTypes} isLoading={isLoading} soProductId={soProductId} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    soProducts: state.soProduct.listSoProductObj,
    vmTrayPositions: state.vmTrayPosition.vmTrayPositionObj,
    vmSlotPositions: state.vmSlotPosition.vmSlotPositionObj,
    motorTypes: state.motorType.motorTypeObj,
    helixTypes: state.helixType.helixTypeObj,
    vmSlotSizes: state.vmSlotSize.vmSlotSizeObj,

    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  listVmTrayPosition,
  listVmSlotPosition,
  listMotorTypes,
  listHelixType,
  listVmSlotSize,
  listSoDependencyBySoDependencyType0,
  listSoProductsBySoDependency,
  listSoProductSlotTypeBySoProductId,
})(SoProductSlotType);
