import { LIST_FIRMWAREVERSIONS } from '../actions'

export function firmwareVersion(state = [], action) {
  switch (action.type) {
    case LIST_FIRMWAREVERSIONS:
      var firmwareVersions = action.payload.data.map(item => ({value:item.id, label:item.versionString}))
      return firmwareVersions;
    default:
      return state
  }
}