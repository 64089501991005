import { Button, DatePicker, Divider, Icon, Modal, Popconfirm, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { addPatientInformation, editPatientInformation, freePatientLocation, getAvailableLocationsByScDependencyOrUuid } from "../../../actions";
import Strings from "../../../systemVariables/languageStrings";
import { GeneralInput } from "../../GenericComponents/InputComponent";
import { ReturnButton } from "../../GenericComponents/buttons";
import { SelectGeneral } from "../../GenericComponents/selectComponent";
import Titles from "../../GenericComponents/titles";
import "./patientInformation.css";

const PatientInformationForm = (props) => {
  const { isEdit } = props.location.props ?? false;
  const { row } = props.location.props ?? {};
  const { basicProps } = props.location.props ?? {};
  const { id: scDependencyId, name: scDependencyName } = basicProps ?? { scDependencyId: "", scDependencyName: "" };
  const [identityCardNumber, setIdentityCardNumber] = useState(row?.identityCardNumber ?? "");
  const [name, setName] = useState(row?.name ?? "");
  const [lastname, setLastname] = useState(row?.lastname ?? "");
  const [birthDate, setBirthDate] = useState(row?.birthDate ? moment(row.birthDate.slice(0, 10)) : null);
  const [gender, setGender] = useState(row?.gender ?? "");
  const [medicalHistoryNumber, setMedicalHistoryNumber] = useState(row?.medicalHistoryNumber ?? "");
  const [admissionCode, setAdmissionCode] = useState(row?.admissionCode ?? "");

  const [completedRequest, setCompletedRequest] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [showError, setShowError] = useState(false);
  const {
    permissions: { data },
  } = props;

  const [scLocations, setScLocations] = useState([{ value: "", label: Strings.generalTerms.selectLocation }]);
  const [selectedLocation, setSelectedLocation] = useState({ value: "", data: {} });

  useEffect(() => {
    getLocations();
  }, []);

  const stateEnum = {
    identityCardNumber: setIdentityCardNumber,
    name: setName,
    lastname: setLastname,
    birthDate: setBirthDate,
    gender: setGender,
    medicalHistoryNumber: setMedicalHistoryNumber,
    admissionCode: setAdmissionCode,
    location: setSelectedLocation,
  };

  const getLocations = async () => {
    let locations = [{ value: "", label: Strings.generalTerms.selectLocation }];
    let locationsAns = await getAvailableLocationsByScDependencyOrUuid(scDependencyId, row?.locationInformationDto?.uuid);
    if (locationsAns?.data?.status === "SUCCESS") {
      locationsAns.data.data.map((item) => {
        locations.push({
          value: item.uuid,
          label: `${item.location}  ${item.sector ? `~  ${item.sector}` : ""}  ${item.building ? `~  ${item.building}` : ""}  ${item.floor ? `~  ${item.floor}` : ""}`,
          additionalData: item,
        });
      });
      setScLocations(locations);
      setSelectedLocation({
        value: row?.locationInformationDto?.uuid ?? "",
        data: {
          value: row?.locationInformationDto?.uuid ?? "",
          label: row?.locationInformationDto?.location ?? "",
          additionalData: row?.locationInformationDto ?? {},
        },
      });
    } else {
      Modal.error({
        title: Strings.smartVendingClinical.errorGetitngLocations,
        content: Strings.smartVendingClinical.errorGettingLocationsMessage,
      });
    }
  };

  const onChange = (e) => {
    stateEnum[e.target.id](e.target.value);
  };

  const onSelect = (o, e) => {
    stateEnum[e.name](o.value);
  };

  const onLocationSelect = (o) => {
    setSelectedLocation({ value: o.value, data: o });
  };

  const onDateChange = (date) => {
    setBirthDate(date);
  };

  const freeLocation = async () => {
    let response = await freePatientLocation(row);
    if (response?.data?.status === "SUCCESS") {
      row.locationInformationDto = { uuid: null };
      setSelectedLocation({ value: "", data: {} });
      Modal.success({
        title: Strings.generalResponses.successfulUpdate,
        content: Strings.generalResponses.infoSuccessfullyRegistered,
      });
    } else {
      Modal.error({
        title: isEdit ? Strings.generalResponses.errorUpdating : Strings.generalResponses.creationError,
        content: Strings.generalResponses.saveError,
      });
    }
  };

  const savePatient = async (e) => {
    setIsLoadingSave(true);
    let patientData = {
      uuid: row?.uuid ?? null,
      scDependency: { id: scDependencyId },
      identityCardNumber: identityCardNumber ? identityCardNumber : null,
      name: name ? name : null,
      lastname: lastname ? lastname : null,
      birthDate: birthDate ? birthDate.toDate().toISOString().split("T")[0] : null,
      gender: gender ? gender : null,
      admissionCode: admissionCode ? admissionCode : null,
      medicalHistoryNumber: medicalHistoryNumber ? medicalHistoryNumber : null,
      locationInformationDto: {
        uuid: selectedLocation.value ? selectedLocation.value : null,
      },
    };
    if (identityCardNumber || admissionCode || medicalHistoryNumber) {
      setShowError(false);

      let patientAns = isEdit ? await editPatientInformation(patientData) : await addPatientInformation(patientData);
      if (patientAns?.data?.status === "SUCCESS") {
        Modal.success({
          title: isEdit ? Strings.generalResponses.successfulUpdate : Strings.generalResponses.successfullCreation,
          content: Strings.generalResponses.infoSuccessfullyRegistered,
          onOk: () => setCompletedRequest(true),
        });
      } else {
        Modal.error({
          title: isEdit ? Strings.generalResponses.errorUpdating : Strings.generalResponses.creationError,
          content: patientAns.response.data.description, //Strings.generalResponses.saveError,
        });
      }
    } else {
      setShowError(true);
    }
    setIsLoadingSave(false);
  };

  return (
    <div className="content-container">
      {(!scDependencyName || !scDependencyId || completedRequest) && <Redirect to="/listPatientInformation" />}
      <div className="row">
        <Titles
          title={isEdit ? Strings.smartVendingClinical.editPatientInformation : Strings.smartVendingClinical.addPatientInformation}
          tooltip={isEdit ? Strings.smartVendingClinical.editPatientInformationTooltip : Strings.smartVendingClinical.addPatientInformationTooltip}
        />
      </div>

      <div className="row">
        <div className="col">
          <Divider orientation="left">
            <h3>{Strings.scDependency.indirectClient} </h3>
          </Divider>
          <h3>{scDependencyName}</h3>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Divider orientation="left">
            <h3>{Strings.generalTerms.parameterization} </h3>
          </Divider>
        </div>
      </div>

      <div className="row">
        <div className="vertSpace col-md-6">
          <GeneralInput text={Strings.users.name} id="name" value={name} defaultValue={name} placeholder={Strings.users.name} onChange={(e) => onChange(e)} />
        </div>
        <div className="vertSpace col-md-6 border-div">
          <GeneralInput text={Strings.users.lastName} id="lastname" value={lastname} defaultValue={lastname} placeholder={Strings.users.lastName} onChange={(e) => onChange(e)} />
        </div>
        <div className="vertSpace col-md-6">
          <GeneralInput
            text={Strings.users.identityCardNumber}
            id="identityCardNumber"
            value={identityCardNumber}
            defaultValue={identityCardNumber}
            placeholder={Strings.users.identityCardNumber}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="vertSpace col-md-6  border-div">
          <GeneralInput
            text={Strings.smartVendingClinical.medicalHistory}
            id="medicalHistoryNumber"
            value={medicalHistoryNumber}
            defaultValue={medicalHistoryNumber}
            placeholder={Strings.smartVendingClinical.medicalHistory}
            onChange={(e) => onChange(e)}
            //disabled={isIdEditEnabled}
          />
        </div>
        <div className="vertSpace col-md-6">
          <GeneralInput
            text={Strings.smartVendingClinical.admission}
            id="admissionCode"
            value={admissionCode}
            defaultValue={admissionCode}
            placeholder={Strings.smartVendingClinical.admission}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="vertSpace col-md-6 border-div">
          <SelectGeneral
            text={Strings.users.gender}
            name="gender"
            options={[
              { value: "", label: Strings.generalTerms.selectGender },
              { value: "M", label: "M" },
              { value: "F", label: "F" },
            ]}
            defaultValue={gender}
            onChange={(option, event) => onSelect(option, event)}
            //disabled={isIdEditEnabled}
          />
        </div>
        <div className="vertSpace col-md-6 outer">
          <div className="container-fluid middle">
            <div className="row inner">
              <span className="selectAligning col-4">{`${Strings.users.dateOfBirth}: `}</span>
              <DatePicker defaultValue={birthDate} placeholder={"YYYY-MM-DD"} onChange={(date) => onDateChange(date)} />
            </div>
          </div>
          {/* <GeneralInput
            text={Strings.users.dateOfBirth}
            id="birthDate"
            value={birthDate}
            defaultValue={birthDate}
            placeholder={Strings.users.dateOfBirth}
            onChange={(e) => onChange(e)}
          /> */}
        </div>
      </div>

      {showError && <div className="row justify-content-center vertSpace error-message">{Strings.smartVendingClinical.patientSaveCondition}</div>}

      <div className="row">
        <div className="col">
          <Divider orientation="left">
            <h3>{Strings.smartVendingClinical.location} </h3>
          </Divider>
        </div>
      </div>

      <div className="row">
        <div className="vertSpace col-md-6">
          <SelectGeneral
            text={Strings.smartVendingClinical.location}
            name="location"
            options={scLocations}
            defaultValue={selectedLocation.value}
            tooltip={Strings.smartVendingClinical.availableLocationsOnly}
            onChange={(option, event) => onLocationSelect(option, event)}
            //disabled={isIdEditEnabled}
          />
        </div>
        <div className="vertSpace col-md-6 location-div">
          {row?.locationInformationDto?.uuid ? (
            <Popconfirm title={Strings.smartVendingClinical.removeLocationWarn} onConfirm={() => freeLocation()} okText="Sí" cancelText="No">
              <span>
                <Tooltip title={Strings.smartVendingClinical.removeLocationTooltip}>
                  <Icon style={{ marginLeft: 10 }} type="minus-square" theme="twoTone" className="addEditRemoveButton" twoToneColor="#ff0000" />
                </Tooltip>
              </span>
            </Popconfirm>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="vertSpace row justify-content-end">
        <div className="col-6 col-md-3">
          <ReturnButton link="/listPatientInformation" />
        </div>
        <ValidatePermissionForComponent permission={`PRIVILEGE PATIENTS INFORMATION ${isEdit ? "PUT" : "POST"}`} permissions={data}>
          <div className="col-6 col-md-3">
            <Button onClick={async (e) => await savePatient(e)} loading={isLoadingSave} className="button">
              {isLoadingSave ? "" : <Icon type="save" theme="twoTone" twoToneColor="#52c41a" />}
              <span>{Strings.generalTerms.save}</span>
            </Button>
          </div>
        </ValidatePermissionForComponent>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps)(PatientInformationForm);
