import languageStrings from '../../systemVariables/languageStrings';

export function motorTypeConfigLocalization(motorL, motorH) {
    var motorConfig = {value: null, label: null};
    if (motorL.includes("DOUBLE")) {
        motorConfig.label = languageStrings.planogram.motorType.double;
        motorConfig.value = 2;
    } else if (!motorH) {
        motorConfig.label = languageStrings.planogram.motorType.single;
        motorConfig.value = 0;
    } else {
        motorConfig.label = languageStrings.planogram.motorType.twoMotors;
        motorConfig.value = 1;
    }
    return motorConfig;
}

export function motorTypeConfigLocalizationArray(sizeIdx) {
    var motorConfigOptions = [];
    
    if(sizeIdx !== 3 && sizeIdx !== 4) { //Add single option if sizeIdx is not Double and a Half and triple
        motorConfigOptions.push({
            value: 0,
            label: languageStrings.planogram.motorType.single,
        });
    }
    if(sizeIdx !== 0 && sizeIdx !== 1){ //Add twoMotors and Doubel options if sizeIdx is not Single and One and a half
        motorConfigOptions.push({
            value: 1,
            label: languageStrings.planogram.motorType.twoMotors,
        });
        motorConfigOptions.push({
            value: 2,
            label: languageStrings.planogram.motorType.double,
        });
    }
    return motorConfigOptions;
}