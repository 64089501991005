// Dependencies
import { Button, Divider, Icon, Input, Modal, Pagination, Popconfirm, Table, Tooltip } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Components
import { AddButton, ExportButton, ImportButton } from "../../GenericComponents/buttons/index";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";
import { SelectGeneral } from "../../GenericComponents/selectComponent/selectGeneral";
import Titles from "../../GenericComponents/titles";
import MoreInfoDetailsModal from "./MoreInfoDetailsModal";
import UserEnrollmentModal from "./UserEnrollmentModal";

import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import NotAvailableImg from "../../Global/images/NotAvailableImg.png";
import SelectImg from "../../Global/images/SelectImg.png";

import { maxRegistersPerReport } from "../../../systemVariables/serverInformation";
import ExportReportsModal from "../../GenericComponents/exportReportModal";
import SoScVmDependencySelector from "../../GenericComponents/selectComponent/SoScVmDependencySelector";

// Actions
import {
  bashImportUsers,
  disableUser,
  disableUserEnrollmentInfoById,
  exportDirectUserClientReport,
  getFile,
  getRolesByUserId,
  getRuleByUserId,
  getUserById,
  getUserEnrollmentInfoByUserId,
  getVendingMachineByUserId,
  listEnrolledUsersByScDependencyId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
} from "../../../actions";

// Language Localization
import Strings from "../../../systemVariables/languageStrings";
import ImportReportModal from "../../GenericComponents/importReportModal";

const { Column } = Table;

const enrollmentStatus = [
  { value: 2, label: Strings.generalTerms.all },
  { value: 1, label: Strings.userEnrollment.enrolledPlu },
  { value: 0, label: Strings.userEnrollment.notEnrolledPlu },
];

class ListClientUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Selects
      soDependencyId: 0,
      scDependencyId: null,
      scDependencyName: null,
      scDepartments: [],
      scDepartmentId: "",
      scUserProfiles: [],
      scUserProfileId: "",
      disabledScEmployeeInformationFilter: true,
      disabledEnrollmentFilter: true,
      // Table Info
      users: [],
      isTableLoading: false,
      addButtonDisabled: false,
      exportButtonDisabled: true,
      enrollmentFilterId: 2,
      identityCardNumber: "",
      // Details Modal
      userInfo: {},
      roleByUser: [],
      userEnrollment: [],
      ruleByUser: [],
      vendingMachineByUser: [],
      fpImage: SelectImg,
      isDetailsModalVisible: false,
      isRFIDModalVisible: false,
      moreInfo: null,
      deletingRFID: false,
      // Pagination
      pageSize: 10,
      page: 1,
      attribute: "id",
      order: "ASC",
      // isLoading MoreInfoDetails
      isCompleteUserInfo: false,
      isCompleteRoleByUser: false,
      isCompleteUserEnrollment: false,
      isCompleteFingerprint: false,
      isCompleteRuleByUser: false,
      isCompleteVendingMachine: false,
      // Import bash
      isImportVisible: false,
      columnHeaders: [],
      importFinished: false,
      failedImportRegs: [],
      omitedImportRegs: [],
      // Export Report Modal
      isExportModalVisible: false,
      isExceedModalVisible: false,
      exportLoading: false,
    };
    this.showPagination = this.showPagination.bind(this);
  }

  componentDidMount() {
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      userDependency: { dependencyName },
      soScSelectionProps: { sc },
      listScDepartmentByScDependencyId,
      listScUserProfilesByScDependencyId,
    } = this.props;

    if (dependencyType === "so") {
      this.setState({
        soDependencyId: dependencyId,
        disabledScEmployeeInformationFilter: false,
        disabledEnrollmentFilter: false,
        addButtonDisabled: true,
        exportButtonDisabled: true,
      });

      // Get scDependencyId from store
      if (sc) {
        if (sc.id !== -1) {
          this.setState({
            scDependencyId: sc.id,
            scDependencyName: sc.label,
            isTableLoading: false,
            addButtonDisabled: false,
            exportButtonDisabled: true,
          });

          listScDepartmentByScDependencyId(sc.id, (response) => {
            this.setState({
              scDepartments: response.data.data.map((item) => ({
                value: item.id,
                label: item.name,
              })),
            });
          });

          listScUserProfilesByScDependencyId(sc.id, (response) => {
            this.setState({
              scUserProfiles: response.data.data.map((item) => ({
                value: item.id,
                label: item.name,
              })),
            });
          });
        }
      }
    }

    if (dependencyType === "sc") {
      this.setState({
        scDependencyId: dependencyId,
        scDependencyName: dependencyName,
        isTableLoading: false,
        addButtonDisabled: false,
        exportButtonDisabled: true,
        disabledScEmployeeInformationFilter: false,
        disabledEnrollmentFilter: false,
      });

      listScDepartmentByScDependencyId(dependencyId, (response) => {
        this.setState({
          scDepartments: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });

      listScUserProfilesByScDependencyId(dependencyId, (response) => {
        this.setState({
          scUserProfiles: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }
  }

  // Events
  soDependencyHandleChange = (event) => {
    const { value } = event;

    this.setState({
      soDependencyId: value,
      scDependencyId: null,
      scDependencyName: null,
      scUserProfileId: "",
      scDepartmentId: "",
      enrollmentFilterId: 2,
      addButtonDisabled: true,
      exportButtonDisabled: true,
      disabledScEmployeeInformationFilter: true,
      disabledEnrollmentFilter: false,
      isTableLoading: false,
      users: [],
      usersCount: 0,
      page: 1,
      pageSize: 10,
    });
  };

  scDependencyHandleChange = (event) => {
    const { value, label } = event;
    const { listScDepartmentByScDependencyId, listScUserProfilesByScDependencyId } = this.props;

    this.setState({
      scDependencyId: value,
      scDependencyName: label,
      disabledScEmployeeInformationFilter: false,
      disabledEnrollmentFilter: false,
      addButtonDisabled: false,
      exportButtonDisabled: false,
      enrollmentFilterId: 2,
      scDepartments: [],
      scDepartmentId: "",
      scUserProfiles: [],
      scUserProfileId: "",
      users: [],
      usersCount: 0,
      page: 1,
      pageSize: 10,
      isTableLoading: false,
    });

    listScDepartmentByScDependencyId(value, (response) => {
      this.setState({
        scDepartments: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });

    listScUserProfilesByScDependencyId(value, (response) => {
      this.setState({
        scUserProfiles: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });
  };

  scDepartmentHandleChange = (event) => {
    const { value } = event;
    this.setState({
      isTableLoading: false,
      page: 1,
      addButtonDisabled: false,
      exportButtonDisabled: false,
      enrollmentFilterId: 2,
      scUserProfileId: "",
      scDepartmentId: value,
    });
  };

  scUserProfileHandleChange(event) {
    const { value } = event;
    this.setState({
      isTableLoading: false,
      scDepartmentId: "",
      scUserProfileId: value,
      enrollmentFilterId: 2,
      page: 1,
      addButtonDisabled: false,
      exportButtonDisabled: false,
    });
  }

  enrolmentFilterHandleChange(event) {
    const { value } = event;
    this.setState({
      enrollmentFilterId: value,
      isTableLoading: false,
      page: 1,
    });
  }

  moreInfoDetails = (e, moreInfoDetails) => {
    const userId = moreInfoDetails.id;
    const { getUserById, getRolesByUserId, getRuleByUserId, getVendingMachineByUserId } = this.props;

    getUserById(userId, (response) => {
      this.setState({
        userInfo: response.data.data,
        isCompleteUserInfo: true,
      });
    });

    getRolesByUserId(userId, (response) => {
      this.setState({
        roleByUser: response.data.data,
        isCompleteRoleByUser: true,
      });
    });

    getRuleByUserId(userId, (response) => {
      this.setState({
        ruleByUser: response.data.data,
        isCompleteRuleByUser: true,
      });
    });

    getVendingMachineByUserId(userId, (response) => {
      this.setState({
        vendingMachineByUser: response.data.data,
        isCompleteVendingMachine: true,
      });
    });

    this.setState({
      moreInfo: moreInfoDetails,
      isDetailsModalVisible: true,
    });
  };

  enrollmentInfoDetails = (e, user) => {
    const { getUserById, getUserEnrollmentInfoByUserId, getFile } = this.props;
    const userId = user.id;

    getUserById(userId, (response) => {
      this.setState({
        userInfo: response.data.data,
        isCompleteUserInfo: true,
      });
    });

    getUserEnrollmentInfoByUserId(userId, (response) => {
      this.setState({
        userEnrollment: response.data.data,
        isCompleteUserEnrollment: true,
      });
      let fpImageKey = response.data.data.filter((obj) => obj.enrollmenttype === "FINGERPRINT").length;
      if (fpImageKey && fpImageKey !== 0) {
        fpImageKey = response.data.data.filter((obj) => obj.enrollmenttype === "FINGERPRINT")[0].fpPathS3;
        getFile(fpImageKey, (ans) => {
          if (!String(ans).includes("Error:")) {
            this.setState({
              fpImage: `data:${ans.fileType};base64,${ans.data}`,
              isCompleteFingerprint: true,
            });
          } else {
            this.setState({
              fpImage: NotAvailableImg,
              isCompleteFingerprint: true,
            });
          }
        });
      } else {
        this.setState({
          fpImage: null,
          isCompleteFingerprint: true,
        });
      }
    });

    this.setState({
      moreInfo: user,
      isRFIDModalVisible: true,
    });
  };

  isModalDataComplete = (isCompleteUserInfo, isCompleteRoleByUser, isCompleteRuleByUser, isCompleteVendingMachine) => {
    let isModalDataComplete = false;
    if (isCompleteUserInfo && isCompleteRoleByUser && isCompleteRuleByUser && isCompleteVendingMachine) {
      isModalDataComplete = true;
    }
    return isModalDataComplete;
  };

  enrollmentModalDataComplete = (isCompleteUserInfo, isCompleteUserEnrollment, isCompleteFingerprint) => {
    let isModalDataComplete = false;
    if (isCompleteUserInfo && isCompleteUserEnrollment && isCompleteFingerprint) {
      isModalDataComplete = true;
    }
    return isModalDataComplete;
  };

  clearModal = () => {
    this.setState({
      userInfo: {},
      roleByUser: [],
      userEnrollment: [],
      ruleByUser: [],
      vendingMachineByUser: [],
      isCompleteUserInfo: false,
      isCompleteRoleByUser: false,
      isCompleteUserEnrollment: false,
      isCompleteFingerprint: false,
      isCompleteRuleByUser: false,
      isCompleteVendingMachine: false,
    });
  };

  disableRFID = (userId, userEnrolmentInfoId) => {
    const { getUserEnrollmentInfoByUserId } = this.props;

    this.setState({
      deletingRFID: true,
    });

    disableUserEnrollmentInfoById(userEnrolmentInfoId, () => {
      getUserEnrollmentInfoByUserId(userId, (response) => {
        this.setState({
          userEnrollment: response.data.data,
          deletingRFID: false,
        });
      });
    });
  };

  setDetailsModalVisible = (isDetailsModalVisible) => {
    this.setState({
      isDetailsModalVisible,
    });
  };

  setRFIDModalVisible = (isRFIDModalVisible) => {
    this.setState({
      isRFIDModalVisible,
    });
  };

  clearImage = () => {
    this.setState({
      fpImage: SelectImg,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearchButtonOnClick = () => {
    this.setState({
      addButtonDisabled: true,
      isTableLoading: true,
    });

    const { listEnrolledUsersByScDependencyId } = this.props;
    const { scDependencyId, scDepartmentId, scUserProfileId, enrollmentFilterId, page, pageSize, attribute, order } = this.state;

    listEnrolledUsersByScDependencyId(scDependencyId, scDepartmentId, scUserProfileId, enrollmentFilterId, "", page, pageSize, attribute, order, (response) => {
      this.setState({
        users: response.data.data.content,
        usersCount: response.data.data.totalElements,
        isTableLoading: false,
        addButtonDisabled: false,
        exportButtonDisabled: false,
      });
    });
  };

  handleSearch = (selectedKeys) => {
    const { listEnrolledUsersByScDependencyId } = this.props;
    const { scDependencyId, scDepartmentId, scUserProfileId, enrollmentFilterId, page, pageSize, attribute, order } = this.state;

    if (selectedKeys.length > 0) {
      this.setState({
        isTableLoading: true,
      });

      listEnrolledUsersByScDependencyId(scDependencyId, scDepartmentId, scUserProfileId, enrollmentFilterId, selectedKeys[0], page, pageSize, attribute, order, (response) => {
        this.setState({
          users: response.data.data.content,
          usersCount: response.data.data.totalElements,
          isTableLoading: false,
          addButtonDisabled: false,
          exportButtonDisabled: true,
        });
      });
    }
  };

  handleReset = (clearFilters) => {
    const { listEnrolledUsersByScDependencyId } = this.props;
    const { scDependencyId, scDepartmentId, scUserProfileId, enrollmentFilterId, page, pageSize, attribute, order } = this.state;

    this.setState({
      isTableLoading: true,
    });

    clearFilters();
    listEnrolledUsersByScDependencyId(scDependencyId, scDepartmentId, scUserProfileId, enrollmentFilterId, "", page, pageSize, attribute, order, (response) => {
      this.setState({
        users: response.data.data.content,
        usersCount: response.data.data.totalElements,
        isTableLoading: false,
      });
    });
  };

  disableUser(e, row) {
    const { disableUser, listEnrolledUsersByScDependencyId } = this.props;
    const { scDependencyId, scDepartmentId, scUserProfileId, page, pageSize, attribute, order, enrollmentFilterId } = this.state;
    this.setState({
      isTableLoading: true,
    });
    disableUser(row.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 0) {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
        });
        listEnrolledUsersByScDependencyId(scDependencyId, scDepartmentId, scUserProfileId, enrollmentFilterId, "", page, pageSize, attribute, order, (response) => {
          const users = [...this.state.users];
          this.setState({
            users: users.filter((item) => item.id !== row.id),
          });
        });
      } else {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      }
      this.setState({
        isTableLoading: false,
      });
    });
  }

  showPagination(page, pageSize) {
    const { listEnrolledUsersByScDependencyId } = this.props;
    const { scDependencyId, scDepartmentId, scUserProfileId, enrollmentFilterId, identityCardNumber, attribute, order } = this.state;

    this.setState({
      isTableLoading: true,
      page,
      pageSize,
    });
    listEnrolledUsersByScDependencyId(scDependencyId, scDepartmentId, scUserProfileId, enrollmentFilterId, identityCardNumber, page, pageSize, attribute, order, (response) => {
      this.setState({
        users: response.data.data.content,
        usersCount: response.data.data.totalElements,
        isTableLoading: false,
      });
    });
  }

  // ----Import data----
  handleOnImport = () => {
    const columnHeaders = [
      { key: "idTypeName", value: Strings.users.documentType.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: true },
      { key: "id", value: Strings.users.documentNumber.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: true },
      { key: "firstName", value: Strings.users.firstName.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: true },
      { key: "middleName", value: Strings.users.middleName.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "lastName1", value: Strings.users.lastName1.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: true },
      { key: "lastName2", value: Strings.users.lastName2.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "scDepartmentName", value: Strings.scDepartment.department.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "scUserProfile", value: Strings.scUserProfile.userProfile.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "scCostCenter", value: Strings.scCostCenter.costCenter.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "scDispensationRuleName", value: Strings.dispensationRule.dispensationRule.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      // { key: 'scDispensationRuleStart', value: Strings.dispensationRule.startDate.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "country", value: Strings.generalTerms.country.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: true },
      { key: "province", value: Strings.generalTerms.province.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: true },
      { key: "city", value: Strings.generalTerms.city.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: true },
      { key: "phoneNumber", value: Strings.generalTerms.phone.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: true },
      { key: "email", value: Strings.generalTerms.email.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: true },
      { key: "dateOfBirth", value: Strings.users.dateOfBirth.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "address", value: Strings.generalTerms.address.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "vendingMachine", value: Strings.machine.machine.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      // { key: "hidFC", value: "HID-FC", isReq: false },
      // { key: "hidCardNumber", value: "HID-Card Number", isReq: false },
      { key: "cardNumber", value: Strings.userEnrollment.cardNumber.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "vmRole", value: Strings.Roles.vmRol.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: true }, // ?
    ];
    this.setState({
      isImportVisible: true,
      columnHeaders,
    });
  };

  setImportModalVisible = (isVisible) => {
    this.setState({
      isImportVisible: isVisible,
    });
  };

  onDataImport = (data, updateFlag, dRuleOption, enableUserOption) => {
    const { bashImportUsers, listEnrolledUsersByScDependencyId } = this.props;
    const { soDependencyId, scDependencyId, scDepartmentId, scUserProfileId, enrollmentFilterId, identityCardNumber, page, pageSize, attribute, order } = this.state;

    this.setState({
      importFinished: false,
    });
    bashImportUsers(soDependencyId, scDependencyId, updateFlag, data, dRuleOption, enableUserOption, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // *! Connection error

        this.setState({ importFinished: true });
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 0) {
        // ** Successfull entity save

        this.setState({
          failedImportRegs: response.data.data.failedRegIds,
          omitedImportRegs: response.data.data.repeatedRegIds,
          importFinished: true,
        });

        listEnrolledUsersByScDependencyId(scDependencyId, scDepartmentId, scUserProfileId, enrollmentFilterId, identityCardNumber, page, pageSize, attribute, order, (response) => {
          this.setState({
            users: response.data.data.content,
          });
        });
      }
    });
  };

  // Export methods
  showExportReportsModal = () => {
    const { usersCount } = this.state;
    if (usersCount >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible, isExceedModalVisible) => {
    this.setState({
      isExportModalVisible,
      isExceedModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    const {
      soDependencyId,
      scDependencyId,
      scDependencyName,
      scDepartmentId,
      scUserProfileId,
      enrollmentFilterId,
      identityCardNumber,
      exportLoading,
      attribute,
      order,
    } = this.state;

    if (!exportLoading) {
      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
      const columnHeaders = [
        Strings.users.identityCardNumber,
        Strings.generalTerms.name,
        Strings.scCostCenter.costCenter,
        Strings.scCostCenter.scCostCenterCodeToolTip,
        Strings.scUserProfile.userProfile,
        Strings.scDepartment.department,
        Strings.dispensationRule.dispensationRule,
        Strings.vmByUser.vmByUser,
        Strings.userEnrollment.enrollmentStatus,
        Strings.userEnrollment.enrollmentType,
        Strings.userEnrollment.enrollmentDate,
        Strings.Roles.vmRol,
      ];

      // Start loading animation on Export button
      this.setState({ exportLoading: true });

      // Consume report export method
      const { exportDirectUserClientReport } = this.props;
      exportDirectUserClientReport(
        soDependencyId,
        scDependencyId,
        scDepartmentId,
        scUserProfileId,
        enrollmentFilterId,
        identityCardNumber,
        attribute,
        order,
        fileType,
        columnHeaders,
        (response) => {
          if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
            // Connection error
            Modal.error({
              title: Strings.generalResponses.failedTransaction,
              content: Strings.generalResponses.reportGenerationError,
            });
            this.setState({
              exportLoading: false,
            });
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${Strings.users.usersReport}: ${scDependencyName}.csv`);
            document.body.appendChild(link);
            link.click();
            this.setState({
              exportLoading: false,
            });
          }
        }
      );
    }
  };

  // Information to render
  render() {
    const {
      permissions: { data },
    } = this.props;

    const {
      scDependencyId,
      scDependencyName,
      scDepartments,
      scDepartmentId,
      disabledScEmployeeInformationFilter,
      scUserProfiles,
      scUserProfileId,
      enrollmentFilterId,
      isCompleteVendingMachine,
      isCompleteRuleByUser,
      isCompleteUserInfo,
      isCompleteRoleByUser,
      users,
      isTableLoading,
      usersCount,
      isDetailsModalVisible,
      userInfo,
      roleByUser,
      ruleByUser,
      vendingMachineByUser,
      moreInfo,
      fpImage,
      userEnrollment,
      disabledEnrollmentFilter,
      isRFIDModalVisible,
      isCompleteUserEnrollment,
      deletingRFID,
      isCompleteFingerprint,
      isImportVisible,
      columnHeaders,
      importFinished,
      failedImportRegs,
      omitedImportRegs,
      isExportModalVisible,
      isExceedModalVisible,
      addButtonDisabled,
      exportButtonDisabled,
      page,
      exportLoading,
    } = this.state;

    return (
      <div className="content-container">
        <MoreInfoDetailsModal
          isVisible={isDetailsModalVisible}
          setVisible={this.setDetailsModalVisible}
          userInfo={userInfo}
          roleByUser={roleByUser}
          ruleByUser={ruleByUser}
          vendingMachineByUser={vendingMachineByUser}
          clearImage={this.clearImage}
          dataSource={moreInfo}
          fpImage={fpImage}
          isModalDataComplete={this.isModalDataComplete(isCompleteUserInfo, isCompleteRoleByUser, isCompleteRuleByUser, isCompleteVendingMachine)}
          clearModal={this.clearModal}
        />

        <UserEnrollmentModal
          isVisible={isRFIDModalVisible}
          setVisible={this.setRFIDModalVisible}
          userInfo={userInfo}
          userEnrollment={userEnrollment}
          disableRFID={this.disableRFID}
          deleting={deletingRFID}
          clearImage={this.clearImage}
          fpImage={fpImage}
          isModalDataComplete={this.enrollmentModalDataComplete(isCompleteUserInfo, isCompleteUserEnrollment, isCompleteFingerprint)}
          clearModal={this.clearModal}
        />

        <ImportReportModal
          importTitle={Strings.users.importUsersBash}
          importTitleTooltip={Strings.users.importUsersBashTooltip}
          isImportVisible={isImportVisible}
          setVisible={this.setImportModalVisible}
          columnHeaders={columnHeaders}
          onDataImport={this.onDataImport}
          importEntitySpecificsFlag="clientUsers"
          importFinished={importFinished}
          failedImportRegs={failedImportRegs}
          omitedImportRegs={omitedImportRegs}
          importOptions={true}
        />
        <ExportReportsModal
          isExportVisible={isExportModalVisible}
          isExceedVisible={isExceedModalVisible}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
        />

        <div className="row">
          <Titles title={Strings.users.listClientUsers} tooltip={Strings.users.listClientUsersTooltip} />
        </div>

        <div className="vertSpace row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <SoScVmDependencySelector hasSc onSoDependencyChange={this.soDependencyHandleChange} onScDependencyChange={this.scDependencyHandleChange} />

          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.scDepartment.department}
              tooltip={Strings.scDepartment.departmentTooltip}
              options={scDepartments}
              onChange={(e) => this.scDepartmentHandleChange(e)}
              disabled={disabledScEmployeeInformationFilter}
              defaultValue={scDepartmentId}
            />
          </div>

          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.scUserProfile.userProfile}
              tooltip={Strings.scUserProfile.userProfileTooltip}
              options={scUserProfiles}
              onChange={(e) => this.scUserProfileHandleChange(e)}
              disabled={disabledScEmployeeInformationFilter}
              defaultValue={scUserProfileId}
            />
          </div>

          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.generalTerms.filterBy}
              tooltip={Strings.userEnrollment.showEnrollmentFilterTooltip}
              options={enrollmentStatus}
              onChange={(e) => this.enrolmentFilterHandleChange(e)}
              disabled={disabledEnrollmentFilter}
              defaultValue={enrollmentFilterId}
            />
          </div>
          <div className="vertSpace col-md-6" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="col-md-3">
              <Tooltip title={Strings.transaction.getTransactionsToolTip} placement="bottom">
                <Button
                  type="primary"
                  size="large"
                  icon="search"
                  onClick={this.handleSearchButtonOnClick}
                  disabled={!scDependencyId}
                  loading={isTableLoading}
                  style={{ margin: "5px" }}
                >
                  {Strings.generalTerms.check}
                </Button>
              </Tooltip>
            </div>
            <ValidatePermissionForComponent permission="PRIVILEGE CLIENT USERS POST" permissions={data}>
              <div className="col-md-3">
                <AddButton
                  link={addButtonDisabled ? "" : "/addClientUser"}
                  tooltip={Strings.users.addClientUsersTooltip}
                  props={
                    addButtonDisabled
                      ? null
                      : {
                          scDependency: {
                            value: scDependencyId,
                            label: scDependencyName,
                          },
                        }
                  }
                  disabled={addButtonDisabled}
                />
              </div>
            </ValidatePermissionForComponent>
            <ValidatePermissionForComponent permission="PRIVILEGE MASS CLIENT USERS IMPORT" permissions={data}>
              <div className="col-md-3">
                <ImportButton tooltip={Strings.fileImport.importRegistersCSV} disabled={addButtonDisabled} onClick={this.handleOnImport} />
              </div>
            </ValidatePermissionForComponent>
          </div>
        </div>

        <Divider orientation="left">
          <h3>{Strings.users.listClientUsers}</h3>
        </Divider>
        <QueryResponse
          isLoadig={isTableLoading}
          dataSourceLength={users.length}
          callback={this.methodListClientUsers(users, isTableLoading, data, scDependencyId, usersCount, page, exportLoading, addButtonDisabled, exportButtonDisabled)}
        />
      </div>
    );
  }

  methodListClientUsers(users, isTableLoading, data, scDependencyId, usersCount, page, exportLoading, addButtonDisabled, exportButtonDisabled) {
    return (
      <div>
        <div className="row">
          <div className="col">
            <Table dataSource={users} bordered loading={isTableLoading} pagination={false} size="middle" style={{ width: "100%" }} scroll={{ x: "auto" }} rowKey="id">
              <Column
                align="center"
                title={Strings.users.identityCardNumber}
                dataIndex="identityCardNumber"
                {...this.getColumnSearchProps(Strings.users.identityCardNumber)}
                filtered
              />
              <Column
                align="center"
                title={Strings.generalTerms.name}
                render={(row) => (
                  <div>
                    {row.firstName ? `${row.firstName} ` : " "}
                    {row.secondName ? `${row.secondName} ` : " "}
                    {row.lastName1 ? `${row.lastName1} ` : " "}
                    {row.lastName2 ? row.lastName2 : " "}
                  </div>
                )}
              />
              <Column align="center" title={Strings.scDepartment.department} render={(row) => (row.scDepartmentName ? row.scDepartmentName : <p>--</p>)} />
              <Column align="center" title={Strings.scUserProfile.userProfile} render={(row) => (row.scUserProfileName ? row.scUserProfileName : <p>--</p>)} />
              <Column align="center" title={Strings.scCostCenter.costCenter} render={(row) => (row.scCostCenterName ? row.scCostCenterName : <p>--</p>)} />

              <Column // More Info Details
                title={Strings.generalTerms.details}
                tooltip={Strings.userEnrollment.moreInfoDetailsTooltip}
                align="center"
                render={(row) => <Icon type="profile" className="addEditRemoveButton" onClick={(e) => this.moreInfoDetails(e, row)} />}
              />
              <Column // Enrollment Info
                title={Strings.vmSetup.categories.enrollment}
                tooltip={Strings.users.enrollmentAndCreditByUserTooltip}
                align="center"
                render={(row) =>
                  row.enrollmentStatus === "Enrolado" ? <Icon type="idcard" className="addEditRemoveButton" onClick={(e) => this.enrollmentInfoDetails(e, row)} /> : <p>--</p>
                }
              />
              {ValidatePermissionForComponentPart("PRIVILEGE CLIENT USERS PUT", data) && (
                <Column
                  title={Strings.generalTerms.edit}
                  width="60px"
                  align="center"
                  render={(row) => (
                    <Link
                      to={{
                        pathname: "/editClientUser",
                        state: {
                          userId: row.id,
                          scDependencyId,
                          countryId: row.countryId,
                          provinceId: row.provinceId,
                          cityId: row.cityId,
                          scDepartmentId: row.scDepartmentId,
                          scUserProfileId: row.scUserProfileId,
                          scCostCenterId: row.scCostCenterId,
                        },
                      }}
                    >
                      <Icon type="edit" theme="twoTone" className="addEditRemoveButton" />
                    </Link>
                  )}
                />
              )}
              {ValidatePermissionForComponentPart("PRIVILEGE CLIENT USERS DELETE", data) && (
                <Column
                  title={Strings.generalTerms.delete}
                  align="center"
                  render={(row) => (
                    <Popconfirm
                      title={Strings.generalResponses.confirmDeleteMessage}
                      onConfirm={(e) => this.disableUser(e, row)}
                      okText={Strings.generalTerms.yes}
                      cancelText={Strings.generalTerms.no}
                    >
                      <Icon type="delete" theme="twoTone" className="addEditRemoveButton" />
                    </Popconfirm>
                  )}
                />
              )}
            </Table>

            <div className="vertSpace col-12">
              <div className="col-md-6" />
              <div className="vertSpace col-md-6">
                <Pagination
                  size="small"
                  total={usersCount}
                  showSizeChanger
                  onChange={this.showPagination}
                  onShowSizeChange={this.showPagination}
                  hideOnSinglePage={false}
                  pageSizeOptions={["10", "25", "50", "100", "250"]}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  current={page}
                  disabled={users.length <= 0}
                />
              </div>
            </div>
          </div>
        </div>

        <ValidatePermissionForComponent permission="PRIVILEGE CLIENT USERS REPORT GET" permissions={data}>
          <div className="row">
            <div className="vertSpace col">
              <div className="col-md-6" />
              <div className="col-md-6">
                <ExportButton isDisabled={exportButtonDisabled} onClick={() => this.showExportReportsModal()} loading={exportLoading} />
              </div>
            </div>
          </div>
        </ValidatePermissionForComponent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  listEnrolledUsersByScDependencyId,
  getUserById,
  getRuleByUserId,
  disableUser,
  getRolesByUserId,
  getUserEnrollmentInfoByUserId,
  getVendingMachineByUserId,
  getFile,
  bashImportUsers,
  exportDirectUserClientReport,
  disableUserEnrollmentInfoById,
})(ListClientUsers);
