import _ from "lodash";

export function moneyToStringFormatter(val) {
  const thMillions = Math.floor(val / 1000000000);
  const millions = Math.floor(val / 1000000) - thMillions * 1000;
  const thousands = Math.floor(val / 1000) - thMillions * 1000000 - millions * 1000;
  let smallVal = val - thMillions * 1000000000 - millions * 1000000 - thousands * 1000;

  smallVal = Math.floor(smallVal); // Remove decimal places

  let fMoney = "$";

  if (thMillions !== 0) {
    fMoney += `${thMillions}.`;
  }

  if (millions !== 0) {
    if (thMillions !== 0) {
      fMoney += `${_.padStart(millions, 3, 0)}'`;
    } else {
      fMoney += `${millions}\'`;
    }
  } else if (thMillions !== 0) {
    fMoney += "000'";
  }

  if (thousands !== 0) {
    if (thMillions !== 0 || millions !== 0) {
      fMoney += `${_.padStart(thousands, 3, 0)}.`;
    } else {
      fMoney += `${thousands}.`;
    }
  } else if (thMillions !== 0 || millions !== 0) {
    fMoney += "000.";
  }

  if (smallVal !== 0) {
    if (millions !== 0 || thousands !== 0) {
      fMoney += _.padStart(smallVal, 3, 0);
    } else {
      fMoney += smallVal;
    }
  } else if (thMillions !== 0 || millions !== 0 || thousands !== 0) {
    fMoney += "000";
  } else {
    fMoney += "0";
  }
  return fMoney;
}
