import {
    LIST_PRIVILEGES,
} from '../actions'

const initialState = {
    privilegeObj: [],
}

export function userSystemPrivileges(state = initialState, action) {
    switch (action.type) {

        case LIST_PRIVILEGES:
            return Object.assign({}, state, {
                privilegeObj: action.payload
            })
        default:
            return state
    }
}