// Dependencies
import React, { Component } from "react";
import { DatePicker } from "antd";

// Components
import moment from "moment";
import { SelectGeneral } from "./selectComponent/index";
import { FreqUnitNameLocalizationPlural } from "../../Utils/LanguageLocalization/frequencyUnitNameLocalization";
import { GeneralCheckbox } from "./checkBox/GeneralCheckBox";

// Language Localization
import Strings from "../../systemVariables/languageStrings";

const { MonthPicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";
const monthFormat = "MM/YYYY";

class TimeUnitSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeUnit: 0, // Used to pick in the calendar. 0:Day, 1:Month, 2:Year
      timeUnitOptions: [],
      rangeAnalysis: false,
      calendarPicker: "",
      yearOptions: [],
      selYearIni: 0,
      selYearFinal: 0,
      iniDate: new Date(),
      endDate: null,
    };
  }

  componentDidMount() {
    const { timeUnit, rangeAnalysis, iniDate } = this.state;
    // Initialize and localize timeUnits options
    const tUnits = ["DAYS", "MONTHS", "YEARS"];
    const tUnitsOptions = [];
    for (let i = 0; i < tUnits.length; i += 1) {
      tUnitsOptions.push({ value: i, label: tUnits[i] });
    }
    const tUnitsOptionsL = FreqUnitNameLocalizationPlural(tUnitsOptions);

    // Initialize calendar picker (default: current day)
    this.setCalendarPicker(timeUnit, rangeAnalysis, iniDate);

    // Initialize year options
    const iniYear = 2018;
    const currYear = new Date().getFullYear();
    const yearOptions = [];
    for (let y = iniYear; y <= currYear; y += 1) {
      yearOptions.push({ value: y - iniYear, label: y });
    }

    this.setState({
      timeUnitOptions: tUnitsOptionsL,
      yearOptions,
      endDate: new Date(iniDate.getFullYear(), iniDate.getMonth(), iniDate.getDate() + 1, 0),
    });
  }

  timeUnitHandleChange = (event) => {
    const { yearOptions } = this.state;
    const { setTimeInformation } = this.props;

    const currDate = new Date();
    let iniDate;
    let endDate;
    let selFreqUnit;
    let selYearIni;

    const { value } = event;
    switch (value) {
      case 0:
        iniDate = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate(), 0);
        endDate = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate() + 1, 0);
        selFreqUnit = 0;
        break;
      case 1:
        iniDate = new Date(currDate.getFullYear(), currDate.getMonth(), 1, 0);
        endDate = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 1, 0);
        selFreqUnit = 1;
        break;
      case 2:
        iniDate = new Date(currDate.getFullYear(), 0, 1, 0);
        endDate = new Date(currDate.getFullYear() + 1, 0, 1, 0);
        selFreqUnit = 3;
        selYearIni = yearOptions.length - 1;
        break;
      default:
        break;
    }
    this.setState({
      timeUnit: value,
      rangeAnalysis: false,
      iniDate,
      endDate,
      selYearIni,
      selYearFinal: selYearIni,
    });
    this.setCalendarPicker(value, false, iniDate, endDate, selYearIni);
    setTimeInformation(iniDate, endDate, selFreqUnit, false);
  };

  rangeAnalysisHandleChange = (event) => {
    const { target } = event;
    const { timeUnit, iniDate, selYearIni } = this.state;
    this.setCalendarPicker(timeUnit, target.checked, iniDate, iniDate, selYearIni, selYearIni, true);
    this.setState({
      rangeAnalysis: target.checked,
    });
  };

  initialDayHandleChange(date, dateStr) {
    const { rangeAnalysis, timeUnit, selYearIni } = this.state;
    const { setTimeInformation } = this.props;
    const dateStrSplit = dateStr.split("-");
    const iniDate = new Date(dateStrSplit[0], dateStrSplit[1] - 1, dateStrSplit[2]);
    let { endDate } = this.state;

    if (!rangeAnalysis) {
      endDate = new Date(new Date(dateStrSplit[0], dateStrSplit[1] - 1, parseInt(dateStrSplit[2], 10) + 1) - 1000);
      setTimeInformation(iniDate, endDate, 0, rangeAnalysis);
    } else {
      setTimeInformation(iniDate, endDate, 0, rangeAnalysis);
    }

    let validRange = true;
    if (endDate < iniDate) {
      validRange = false;
    }

    this.setState({
      iniDate,
      endDate,
    });
    this.setCalendarPicker(timeUnit, rangeAnalysis, iniDate, endDate, selYearIni, selYearIni, validRange);
  }

  finalDayHandleChange = (date, dateStr) => {
    const { iniDate, rangeAnalysis, timeUnit, selYearIni } = this.state;
    const { setTimeInformation } = this.props;
    const dateStrSplit = dateStr.split("-");
    const endDate = new Date(new Date(dateStrSplit[0], dateStrSplit[1] - 1, parseInt(dateStrSplit[2], 10) + 1) - 1000);

    setTimeInformation(iniDate, endDate, 0, rangeAnalysis);

    let validRange = true;
    if (endDate < iniDate) {
      validRange = false;
    }

    this.setState({
      endDate,
    });
    this.setCalendarPicker(timeUnit, rangeAnalysis, iniDate, endDate, selYearIni, selYearIni, validRange);
  };

  initialMonthHandleChange(date, dateStr) {
    const { rangeAnalysis, timeUnit, selYearIni } = this.state;
    const { setTimeInformation } = this.props;
    const dateStrSplit = dateStr.split("-");
    const iniDate = new Date(dateStrSplit[0], parseInt(dateStrSplit[1], 10) - 1);
    let { endDate } = this.state;

    if (!rangeAnalysis) {
      endDate = new Date(new Date(dateStrSplit[0], parseInt(dateStrSplit[1], 10)) - 1000);
      setTimeInformation(iniDate, endDate, 1, rangeAnalysis);
    } else {
      setTimeInformation(iniDate, endDate, 1, rangeAnalysis);
    }

    let validRange = true;
    if (endDate < iniDate) {
      validRange = false;
    }

    this.setState({
      iniDate,
      endDate,
    });
    this.setCalendarPicker(timeUnit, rangeAnalysis, iniDate, endDate, selYearIni, selYearIni, validRange);
  }

  finalMonthHandleChange = (date, dateStr) => {
    const { iniDate, rangeAnalysis, timeUnit, selYearIni } = this.state;
    const { setTimeInformation } = this.props;
    const dateStrSplit = dateStr.split("-");
    const endDate = new Date(new Date(dateStrSplit[0], parseInt(dateStrSplit[1], 10)) - 1000);

    setTimeInformation(iniDate, endDate, 1, rangeAnalysis);

    let validRange = true;
    if (endDate < iniDate) {
      validRange = false;
    }

    this.setState({
      endDate,
    });
    this.setCalendarPicker(timeUnit, rangeAnalysis, iniDate, endDate, selYearIni, selYearIni, validRange);
  };

  initialYearHandleChange = (dateStr) => {
    const { rangeAnalysis, timeUnit, selYearFinal } = this.state;
    const { setTimeInformation } = this.props;
    const iniDate = new Date(parseInt(dateStr.label, 10), 0);
    let { endDate } = this.state;

    if (!rangeAnalysis) {
      endDate = new Date(new Date(parseInt(dateStr.label, 10) + 1, 0) - 1000);
      setTimeInformation(iniDate, endDate, 3, rangeAnalysis);
    } else {
      setTimeInformation(iniDate, endDate, 3, rangeAnalysis);
    }

    let validRange = true;
    if (endDate < iniDate) {
      validRange = false;
    }

    this.setState({
      iniDate,
      endDate,
      selYearIni: dateStr.value,
    });
    this.setCalendarPicker(timeUnit, rangeAnalysis, 0, 0, dateStr.value, selYearFinal, validRange);
  };

  finalYearHandleChange = (dateStr) => {
    const { iniDate, rangeAnalysis, timeUnit, selYearIni } = this.state;
    const { setTimeInformation } = this.props;
    const endDate = new Date(new Date(parseInt(dateStr.label, 10) + 1, 0) - 1000);

    setTimeInformation(iniDate, endDate, 3, rangeAnalysis);

    let validRange = true;
    if (endDate < iniDate) {
      validRange = false;
    }
    this.setState({
      endDate,
      selYearFinal: dateStr.value,
    });
    this.setCalendarPicker(timeUnit, rangeAnalysis, 0, 0, selYearIni, dateStr.value, validRange);
  };

  setCalendarPicker = (timeUnit, rangeAnalysis, iniDate, endDate, selIniYearId, selFinalYearId, validRange) => {
    const { yearOptions } = this.state;
    let calendarPicker;
    switch (timeUnit) {
      case 0: // Day
        if (!rangeAnalysis) {
          // Single selection
          calendarPicker = (
            <div className="row">
              <span className="selectAligning col-4">{`${Strings.generalTerms.selection}: `}</span>
              <DatePicker value={moment(iniDate, dateFormat)} onChange={(date, dateString) => this.initialDayHandleChange(date, dateString)} />
            </div>
          );
        } else {
          // Range selection
          calendarPicker = (
            <div className="row">
              <div className="col-6">
                <span>{`${Strings.generalTerms.start}: `}</span>
                <DatePicker defaultValue={moment(iniDate, dateFormat)} onChange={(date, dateString) => this.initialDayHandleChange(date, dateString)} />
              </div>
              <div className="col-6">
                <span>{`   ${Strings.generalTerms.end}: `}</span>
                <DatePicker defaultValue={moment(endDate, dateFormat)} onChange={(date, dateString) => this.finalDayHandleChange(date, dateString)} />
              </div>
              <div className="col-12">
                {validRange ? null : (
                  <>
                    <span>*</span>
                    <span>{Strings.generalResponses.invalidParameters}</span>
                  </>
                )}
              </div>
            </div>
          );
        }
        break;
      case 1: // Month
        if (!rangeAnalysis) {
          // Single selection
          calendarPicker = (
            <div className="row">
              <span className="selectAligning col-4">{`${Strings.generalTerms.selection}: `}</span>
              <MonthPicker value={moment(iniDate, monthFormat)} onChange={(date, dateString) => this.initialMonthHandleChange(date, dateString)} />
            </div>
          );
        } else {
          // Range selection
          calendarPicker = (
            <div className="row">
              <div className="col-6">
                <span>{`${Strings.generalTerms.start}: `}</span>
                <MonthPicker defaultValue={moment(iniDate, monthFormat)} onChange={(date, dateString) => this.initialMonthHandleChange(date, dateString)} />
              </div>
              <div className="col-6">
                <span>{`      ${Strings.generalTerms.end}: `}</span>
                <MonthPicker defaultValue={moment(endDate, monthFormat)} onChange={(date, dateString) => this.finalMonthHandleChange(date, dateString)} />
              </div>
              <div className="col-12">
                {validRange ? null : (
                  <>
                    <span>*</span>
                    <span>{Strings.generalResponses.invalidParameters}</span>
                  </>
                )}
              </div>
            </div>
          );
        }
        break;
      case 2: // Year
        if (!rangeAnalysis) {
          // Single selection
          calendarPicker = (
            <div className="row">
              <div className="col-xs-12 col-md-8" style={{ margin: "auto" }}>
                <SelectGeneral
                  text={Strings.generalTerms.selection}
                  options={yearOptions}
                  onChange={(sel) => this.initialYearHandleChange(sel)}
                  disabled={false}
                  defaultValue={selIniYearId}
                />
              </div>
            </div>
          );
        } else {
          // Range selection
          calendarPicker = (
            <div className="row">
              <div className="col-xs-12 col-md-5">
                <SelectGeneral
                  text={Strings.generalTerms.start}
                  options={yearOptions}
                  onChange={(sel) => this.initialYearHandleChange(sel)}
                  disabled={false}
                  defaultValue={selIniYearId}
                />
              </div>
              <div className="col-xs-0 col-md-2" />
              <div className="col-xs-12 col-md-5">
                <SelectGeneral
                  text={Strings.generalTerms.end}
                  options={yearOptions}
                  onChange={(sel) => this.finalYearHandleChange(sel)}
                  disabled={false}
                  defaultValue={selFinalYearId}
                />
              </div>
              <div className="col">
                {validRange ? null : (
                  <>
                    <span>*</span>
                    <span>{Strings.generalResponses.invalidParameters}</span>
                  </>
                )}
              </div>
            </div>
          );
        }
        break;
      default:
        break;
    }
    this.setState({
      calendarPicker,
    });
  };

  render() {
    const { timeUnitOptions, timeUnit, rangeAnalysis, calendarPicker } = this.state;
    const { rangeSelectionEnabled } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className={rangeSelectionEnabled ? "col-md-4" : "col-md-6"} style={{ padding: "0px" }}>
            <SelectGeneral
              text={Strings.transactionMonitor.timeUnit}
              tooltip={Strings.transactionMonitor.timeUnitTooltip}
              options={timeUnitOptions}
              onChange={(e) => this.timeUnitHandleChange(e)}
              disabled={false}
              defaultValue={timeUnit}
            />
          </div>
          {true ? (
            <div className="col-md-5" style={{ margin: "auto" , padding: "0px 0px" }}>
              <GeneralCheckbox
                text={Strings.transactionMonitor.rangeAnalysis}
                tooltip={Strings.transactionMonitor.rangeAnalysisTooltip}
                isChecked={rangeAnalysis}
                isDisabled={false}
                onChange={(e) => this.rangeAnalysisHandleChange(e)}
              />
            </div>
          ) : (
            <></>
          )}
          <div className={rangeSelectionEnabled ? "col-md-5" : "col-md-6"} style={timeUnit !== 2 ? { paddingTop: "5px", paddingLeft: "55px"} : { paddingTop: "0px" , paddingLeft: "25px"}}>
            <div className="container-fluid">{calendarPicker}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default TimeUnitSelector;
// const adjustEndDate = new Date(endDate - 1000);
