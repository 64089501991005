import React from 'react';
import Select from 'react-select';
import { Tooltip, Icon } from 'antd';
import './selectComponent.css';

// Language localization
import Strings from '../../../systemVariables/languageStrings'

export function SelectIndirectClient(props) {
    const { isReq, scDependencies, onChange, loading, disabled } = props;
    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <div className="aligning">
            <div className="aligning col-4">  
                    <Tooltip title={Strings.scDependency.indirectClientTooltip}>
                        <Icon className="icon" type="info-circle" />                
                    </Tooltip>
                <span>{Strings.scDependency.indirectClient}<b>{isReq === true ? ": *" : ""}</b></span>
            </div>
            <div className="col-8">
                <Select
                    className="basic-single test"
                    options={scDependencies}
                    placeholder={Strings.generalTerms.select}
                    onChange={onChange}
                    isLoading={loading}
                    isDisabled={disabled}
                />
            </div>
        </div>
    )
}