// Dependencies
import React, { Component } from "react";
import { Layout, notification } from "antd";
import { connect } from "react-redux";
import moment from "moment";

import HeaderContent from "./HeaderContent";
import Footer from "./Footer";
import "../css/content.css";

import { getVmEventsNotifications } from "../../../actions/index";

const dateFormat = "YYYY/MM/DD hh:mm:ss";
const contentSize = 64; /* header */

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentHeight: 0,
      eventCategoryQuery: "",
      soDependencyIdQuery: "",
      scDependencyIdQuery: "",
      vendingMachineIdQuery: "",
      eventTypeQuery: "",
      dateQuery: moment().format(),
    };
    this.openNotificationWithIcon = this.openNotificationWithIcon.bind(this);
  }

  componentDidMount() {
    const { userDependency, permissions, soDependencyParent } = this.props;

    window.addEventListener("resize", this.updateWindowDimensions);
    this.setState({
      contentHeight: window.innerHeight - contentSize,
    });

    if (userDependency.dependencyType === "so" && userDependency.dependencyId === 1) {
      this.setState({
        soDependencyIdQuery: userDependency ? (userDependency.dependencyType === "so" ? userDependency.dependencyId : null) : null,
      });
      for (let i = 0; i < permissions.data.length; i++) {
        const permission = permissions.data[i];
        if (permission.name === "PRIVILEGE VM EVENT NOTIFICATIONS GET") {
          setInterval(this.chronEvent, 120000);
        }
      }
    }

    if (userDependency.dependencyType === "so" && userDependency.dependencyId !== 1) {
      this.setState({
        soDependencyIdQuery: userDependency ? (userDependency.dependencyType === "so" ? userDependency.dependencyId : null) : null,
      });
      for (let i = 0; i < permissions.data.length; i++) {
        const permission = permissions.data[i];
        if (permission.name === "PRIVILEGE VM EVENT NOTIFICATIONS GET") {
          setInterval(this.chronEvent, 120000);
        }
      }
    }

    if (userDependency.dependencyType === "sc") {
      this.setState({
        soDependencyIdQuery: soDependencyParent,
        scDependencyIdQuery: userDependency ? (userDependency.dependencyType === "sc" ? userDependency.dependencyId : null) : null,
      });
      for (let i = 0; i < permissions.data.length; i++) {
        const permission = permissions.data[i];
        if (permission.name === "PRIVILEGE VM EVENT NOTIFICATIONS GET") {
          setInterval(this.chronEvent, 120000);
        }
      }
    }
  }

  chronEvent = () => {
    const { getVmEventsNotifications } = this.props;
    const { soDependencyIdQuery, scDependencyIdQuery, vendingMachineIdQuery, eventCategoryQuery, eventTypeQuery, dateQuery } = this.state;

    getVmEventsNotifications(soDependencyIdQuery, scDependencyIdQuery, vendingMachineIdQuery, 75, 100, eventCategoryQuery, eventTypeQuery, dateQuery, (response) => {
      if (response && response.data && response.data.data) {
        this.openNotificationWithIcon("warning", response.data.data);
      }
    });
  };

  openNotificationWithIcon = (type, eventList) => {
    this.setState({
      dateQuery: moment().format(),
    });
    eventList.map((item) =>
      notification[type]({
        message: `${item.soDependencyName} - ${item.scDependencyName}`,
        description: `${item.vendingMachine} - ${item.eventType} - ${moment(item.eventDate).format(dateFormat)}`,
        duration: 10,
      })
    );
  };

  updateWindowDimensions = () => {
    this.setState({
      contentHeight: window.innerHeight - contentSize,
    });
  };

  render() {
    const { body } = this.props;
    const { contentHeight } = this.state;

    return (
      <Layout className="col-lg-10 main-content">
        <HeaderContent className="col-12" />
        <div className="col-12 main-content-size" style={{ height: contentHeight }} id="body">
          {body}
        </div>
        <Footer />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soDependencyParent: state.soDependencyParent.soParent,
  };
}

export default connect(mapStateToProps, {
  getVmEventsNotifications,
})(Content);
