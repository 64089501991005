import React from "react";
import { connect } from "react-redux";

// Components
import { Alert, Card } from "antd";
import Strings from "../../../systemVariables/languageStrings";

// Images
import Product from "../../Global/images/ComponentsSVG/Product";

const { Meta } = Card;

const ProductTransactionSummary = ({ transactionInfo, productImage }) => {
  const productTitleRender = () => {
    return (
      <>
        <div className="row" style={{ margin: "10px " }}>
          <Meta
            avatar={<Product width={40} fill={"#004173"} />}
            style={{ margin: "5px" }}
            title={
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-12">
                  <h2 className="paragraphSubTitle">{Strings.product.productInformation}</h2>
                </div>
              </div>
            }
          />
        </div>
      </>
    );
  };

  const productTransactionInfoRender = () => {
    return (
      <div className="row">
        <Card className="customCard" title={productTitleRender()}>
          {transactionInfo.soProductName ? (
            <>
              {productImageRender()}
              <Meta title={<strong>{transactionInfo?.soProductName}</strong>} description={Strings.generalTerms.code + ": " + transactionInfo?.soProductErpCode} />
            </>
          ) : (
            <Alert message={Strings.generalTerms.informationNotAvailable} type="info" showIcon style={{ alignContent: "center" }} />
          )}
        </Card>
      </div>
    );
  };

  const productImageRender = () => {
    return <>{productImage === "/static/media/NotAvailableImg.d670ef9a.png" ? null : <img className="productPhoto" alt="User" src={productImage} />}</>;
  };

  return productTransactionInfoRender();
};

const mapStateToProps = (state) => ({
  permissions: state.userSystemPrivileges.privilegeObj,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTransactionSummary);
