import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/sodependencytype";

export function listAllSoDependencyTypes(callback){
  return () => {
    axios.get(host+ port + path  + version + resource,{
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    .then((response) => {
        callback(response);
    })
    .catch(function (error){

        callback("Error " + error);
    });
  }
}

export async function listAllSoDependencyTypesFn(){
  try {
    let response = await axios({
      method: "GET",
      url: host+ port + path  + version + resource,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    return response
  } catch (error) {
    return error
  }
}
