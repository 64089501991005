import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, Spin, Modal } from "antd";
import UserForm from "../../Users/Commons/UserForm";
import { identityCardTypeLocalization } from "../../../Utils/LanguageLocalization";
import EditButton from "../../GenericComponents/buttons/editButton";
import CancelButton from "../../GenericComponents/buttons/CancelButton.jsx";
import { SaveButton } from "../../GenericComponents/buttons/index";
import UserInformation from "./UserInformationComponent.jsx";
import Strings from "../../../systemVariables/languageStrings";
import { getUserById, listIdentityCardTypes, listCountries, listProvincesByCountryId, listCitesByProvincesId, updateClientUser, updateExecutiveUser } from "../../../actions";

class PersonalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      identityCardTypes: [],
      provinces: [],
      cities: [],
      isUserDataLoaded: false,
      isSaveButtonLoading: false,
      showEditForm: false,
    };
  }

  componentDidMount() {
    const { userSessionInfo, getUserById, listIdentityCardTypes, listCountries, listProvincesByCountryId, listCitesByProvincesId } = this.props;
    getUserById(userSessionInfo.id, (response) => {
      this.setState({
        user: this.mapUserInfo(response.data.data),
        isUserDataLoaded: true,
      });

      const countryId = response.data.data.country.id;
      const provinceId = response.data.data.province.id;

      listIdentityCardTypes((response) => {
        this.setState({
          identityCardTypes: response.data.data
            .filter((obj) => obj.id !== 0)
            .map((item) => ({
              value: item.id,
              label: identityCardTypeLocalization(item.name),
            })),
        });
      });

      listCountries();

      listProvincesByCountryId(countryId, (response) => {
        this.setState({
          provinces: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });

      listCitesByProvincesId(provinceId, (response) => {
        this.setState({
          cities: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    });
  }

  componentWillUnmount() {
    this.setState({
      user: {},
      identityCardTypes: [],
      provinces: [],
      cities: [],
      isUserDataLoaded: false,
      isSaveButtonLoading: false,
      showEditForm: false,
    });
  }

  mapUserInfo = (userInitialInfo) => {
    const { user } = { ...this.state };

    user.id = userInitialInfo.id;
    user.firstName = userInitialInfo.firstName;
    user.middleName = userInitialInfo.middleName;
    user.lastName1 = userInitialInfo.lastName1;
    user.lastName2 = userInitialInfo.lastName2;
    user.identityCardNumber = userInitialInfo.identityCardNumber;
    user.identityCardTypeId = userInitialInfo.identityCardType.id;
    user.identityCardTypeName = identityCardTypeLocalization(userInitialInfo.identityCardType.name);
    user.phone1 = userInitialInfo.phone1;
    user.phone2 = userInitialInfo.phone2;
    user.countryId = userInitialInfo.country.id;
    user.countryName = userInitialInfo.country.name;
    user.provinceId = userInitialInfo.province.id;
    user.provinceName = userInitialInfo.province.name;
    user.cityId = userInitialInfo.city.id;
    user.cityName = userInitialInfo.city.name;
    user.address = userInitialInfo.address;
    user.email = userInitialInfo.email;
    user.birthDate = userInitialInfo.birthDate;

    return user;
  };

  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { user } = { ...this.state };

    user[id] = value;

    this.setState({
      user,
    });
  };

  handleSelect = (option, event) => {
    const { value } = option;
    const { listProvincesByCountryId, listCitesByProvincesId } = this.props;
    const { user } = { ...this.state };

    user[event.name] = value;

    this.setState({
      user,
    });

    switch (event.name) {
      case "countryId":
        listProvincesByCountryId(value, (response) => {
          this.setState({
            provinces: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        });
        break;
      case "provinceId":
        listCitesByProvincesId(value, (response) => {
          this.setState({
            cities: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        });
        break;
      default:
    }
  };

  handleEdit = () => {
    this.setState({
      showEditForm: true,
    });
  };

  handleSave = () => {
    const { userDependency } = this.props;
    const { user } = this.state;
    this.setState({
      isSaveButtonLoading: true,
    });
    if (userDependency.dependencyType === "so") {
      this.updateExecutiveUser(user);
    } else if (userDependency.dependencyType === "sc") {
      this.updateClientUser(user);
    }
  };

  handleOk = (userToUpdate) => {
    const { getUserById } = this.props;

    getUserById(userToUpdate.id, (response) => {
      this.setState({
        user: this.mapUserInfo(response.data.data),
        showEditForm: false,
      });
    });
  };

  handleCancel = () => {
    this.setState({
      showEditForm: false,
    });
  };

  updateClientUser(userToUpdate) {
    const { updateClientUser, getUserById } = this.props;

    updateClientUser(userToUpdate, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 4001) {
        // Identity card number already exists in the Sc
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      } else if (response.data.code === 0) {
        // Entity Successfully  saved
        getUserById(userToUpdate.id, (response) => {
          this.setState({
            user: this.mapUserInfo(response.data.data),
          });
        });
        Modal.success({
          title: Strings.generalResponses.successfulCreation,
          content: `${Strings.generalResponses.saveSuccess}: ${response.data.data.identityCardNumber}`,
          onOk: this.handleOk(userToUpdate),
        });
      }
      this.setState({
        isSaveButtonLoading: false,
      });
    });
  }

  updateExecutiveUser(userToUpdate) {
    const { updateExecutiveUser } = this.props;

    updateExecutiveUser(userToUpdate, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 4001) {
        // Identity card number already exists in the So
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      } else if (response.data.code === 0) {
        // Entity Successfully saved
        Modal.success({
          title: Strings.generalResponses.successfulCreation,
          content: `${Strings.generalResponses.creationSuccess}: ${response.data.data.firstName} ${response.data.data.lastName1}`,
          onOk: this.handleOk(userToUpdate),
        });
      }
      this.setState({
        isSaveButtonLoading: false,
      });
    });
  }

  renderUserForm() {
    const { countries } = this.props;
    const { user, identityCardTypes, provinces, cities, isSaveButtonLoading } = this.state;
    return (
      <>
        <UserForm
          user={user}
          identityCardTypes={identityCardTypes}
          countries={countries}
          provinces={provinces}
          cities={cities}
          onChange={this.handleFormChange}
          onSelect={this.handleSelect}
          isIdEditEnabled
        />
        <br />
        <div className="row justify-content-md-end">
          <div className="col-6 col-md-2">
            <SaveButton loading={isSaveButtonLoading} onClick={this.handleSave} />
          </div>
          <div className="col-6 col-md-2">
            <CancelButton onClick={this.handleCancel} />
          </div>
        </div>
      </>
    );
  }

  renderPersonalData() {
    const { user } = this.state;
    return (
      <div className="row align-items-center">
        <div className="col-md-9 text-left">
          <UserInformation user={user} />
        </div>
        <div className="col-md-3 text-left">
          <EditButton onClick={this.handleEdit} />
        </div>
      </div>
    );
  }

  render() {
    const { showEditForm, isUserDataLoaded } = this.state;
    return (
      <>
        {isUserDataLoaded !== false ? (
          <> {showEditForm === false ? this.renderPersonalData() : this.renderUserForm()}</>
        ) : (
          <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} />
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    userSessionInfo: state.users.userSessionObj.data,
    userDependency: state.dependencyByUser.dependencyObj,
    countries: state.countries,
  };
}

export default connect(mapStateToProps, {
  getUserById,
  listIdentityCardTypes,
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  updateClientUser,
  updateExecutiveUser,
})(PersonalData);
