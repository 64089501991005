import axios from "axios";
import base64 from "base-64";

import { host, port, clientId, clientSecret } from "../systemVariables/serverInformation";

const encoded = base64.encode(`${clientId}:${clientSecret}`);
const resource = "/oauth/token";

// const qs = require("qs");

export const LOGIN_INFORMATION = "LOGIN_INFORMATION";

export function getToken(code, callback) {
  return (dispatch) => {
    axios({
      method: "POST",
      url: `${host + port + resource}`,
      params: {
        code,
        grant_type: "authorization_code",
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${encoded}`,
      },
    })
      .then((response) => {
        if (!response.error) {
          sessionStorage.setItem("access_token", String(response.data.access_token));
          sessionStorage.setItem("refresh_token", String(response.data.refresh_token));
          sessionStorage.setItem("dependencyId", parseInt(response.data.dependency_id, 10));
          sessionStorage.setItem("dependencyType", String(response.data.dependency_type));
        }
        dispatch({ type: LOGIN_INFORMATION, payload: response.data });
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function refreshToken(callback) {
  // const data = qs.stringify({
  //   grant_type: "refresh_token",
  //   refresh_token: sessionStorage.getItem("refresh_token"),
  // });
  return () => {
    axios({
      method: "POST",
      url: host + port + resource,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${encoded}`,
      },
      params: {
        grant_type: "refresh_token",
        refresh_token: sessionStorage.getItem("refresh_token"),
      },
    })
      .then((response) => {
        if (!response.error) {
          sessionStorage.setItem("access_token", String(response.data.access_token));
          sessionStorage.setItem("refresh_token", String(response.data.refresh_token));
          sessionStorage.setItem("dependencyId", parseInt(response.data.dependency_id, 10));
          sessionStorage.setItem("dependencyType", String(response.data.dependency_type));
        }
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
