import { Icon, Modal } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import { GeneralInput } from "../GenericComponents/InputComponent/generalInput";

// Language localization
import Strings from "../../systemVariables/languageStrings";

class VmDisableConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      serialNumberInput: null,
      msgErrorFlag: false,
    };
  }

  handleShowConfirmModal = () => {
    this.setState({ isVisible: true });
  };

  handleOk = (vmInfo) => {
    const { vmToDisable } = this.props;
    const { serialNumberInput } = this.state;
    this.setState({ msgErrorFlag: false });
    if (vmInfo.serialNumber === serialNumberInput) {
      vmToDisable(vmInfo);
      this.setState({
        isVisible: false,
        serialNumberInput: null,
        msgErrorFlag: false,
      });
    } else {
      this.setState({ msgErrorFlag: true });
    }
  };

  handleCancel = () => {
    this.setState({
      isVisible: false,
      serialNumberInput: null,
      msgErrorFlag: false,
    });
  };

  nameInputHandleChange(event) {
    const serialNumber = _.trimStart(event.target.value);
    if (serialNumber.length > 0) {
      this.setState({ serialNumberInput: serialNumber });
    }
  }

  render() {
    const { vmInfo } = this.props;
    const { isVisible, serialNumberInput, msgErrorFlag } = this.state;

    return (
      <div>
        <Modal
          title={
            <div>
              <Icon type="warning" theme="outlined" style={{ cursor: "pointer", fontSize: 23 }} className="addEditRemoveButton" />{" "}
              <span>
                <b> {Strings.generalTerms.caution} </b>
              </span>
            </div>
          }
          onCancel={this.handleCancel}
          onOk={() => this.handleOk(vmInfo)}
          width="55%"
          visible={isVisible}
          centered
          ok
        >
          <div>
            <div className="vertSpace row">
              <div className="col-md-12">
                <p>
                  <b>{Strings.machine.disableMachineWarningMsg}</b>
                </p>
                <br />
              </div>
            </div>
            <div className="vertSpace row">
              <div className="col-md-12">
                <GeneralInput
                  text={Strings.machine.disableMachineMsg}
                  placeholder={Strings.machine.serialNumber}
                  onChange={(e) => this.nameInputHandleChange(e)}
                  value={serialNumberInput}
                  isReq
                />
              </div>
            </div>
            {msgErrorFlag === true ? (
              <div className="vertSpace row">
                <div className="col-md-4" />
                <div className="col-md-8">
                  <p style={{ color: "#BE0032" }}>
                    <b>
                      {"* "}
                      {Strings.generalResponses.invalidValue}
                    </b>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal>
        <div style={{ align: "center", color: "#004173", cursor: "pointer" }}>
          <Icon type="delete" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" onClick={this.handleShowConfirmModal} />
        </div>
      </div>
    );
  }
}

export default connect(null, {})(VmDisableConfirmModal);
