import Strings from '../../systemVariables/languageStrings';

export function locateSoProductTypeName(soProductType) {
  switch (soProductType) {
    case 0:
    case 'VENDING':
      soProductType = 'Vending';
      break;
    case 1:
    case 'LOCKER':
      soProductType = 'Locker';
      break;
    case 2:
    case 'MIXED':
      soProductType = 'Vending-Locker';
      break;
    default:
      break;
  }
  return soProductType;
}

