import React, { Component } from "react";
import { connect } from "react-redux";
import TreeSelectGeneral from "./TreeSelectGeneral";
import { SelectGeneral } from "./selectGeneral";
import NormalText from "../text/NormalText";
import Strings from "../../../systemVariables/languageStrings";

import {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  getLowerRelationsBySoDependencyId,
  getHeadquartersByStockOwnerId,
  getLogisticCentersByStockOwnerId,
} from "../../../actions/index";

class SoScFullDependencySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soDependencies: [],
      selectedSoDependencyId: null,
      isSoDependencyDisabled: true,

      scDependencies: [],
      selectedScDependencyId: null,
      isScDependencyLoading: false,
      isScDependencyDisabled: true,
    };
  }

  componentDidMount() {
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      listSoDependencyBySoDependencyType0,
      listScDependenciesBySoDependencyId,
    } = this.props;

    // Noatec user
    if (dependencyType === "so" && dependencyId === 1) {
      listSoDependencyBySoDependencyType0(dependencyId, (response) => {
        const soDependencies = this.mapSoDependenciesAsTreeData(response.data.data);
        this.setState({
          soDependencies,
          isSoDependencyDisabled: false,
        });
      });
      // StockOwner user
    } else if (dependencyType === "so" && dependencyId !== 1) {
      listScDependenciesBySoDependencyId(dependencyId, (response) => {
        this.setState({
          scDependencies: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
            type: "sc",
          })),
          isScDependencyDisabled: false,
        });
      });
    }
  }

  mapSoDependenciesAsTreeData = (soDependencies) => {
    let treeData = [];

    const soDepAsTreeData = soDependencies.map((obj) => {
      return { id: obj.id, pId: 0, value: obj.id, title: obj.name, type: "so" };
    });
    treeData = treeData.concat(soDepAsTreeData);

    const soHqTypesAsTreeData = soDependencies.map((obj) => {
      return { id: `hq-${obj.id}`, pId: obj.id, value: `hq-${obj.id}`, title: "Headquarters", type: "hq" };
    });
    treeData = treeData.concat(soHqTypesAsTreeData);

    const soLcAsTreeData = soDependencies.map((obj) => {
      return { id: `lc-${obj.id}`, pId: obj.id, value: `lc-${obj.id}`, title: "Logistic Centers", type: "lc" };
    });
    treeData = treeData.concat(soLcAsTreeData);

    return treeData;
  };

  onLoadData = (treeNode) =>
    new Promise((resolve) => {
      const { id, pId, title } = treeNode.props;
      const { getHeadquartersByStockOwnerId, getLogisticCentersByStockOwnerId } = this.props;

      if (title === "Headquarters") {
        getHeadquartersByStockOwnerId(pId, (response) => {
          const headQuarters = response.data.data.map((obj) => {
            return { id: obj.id, pId: id, value: obj.id, title: obj.name, isLeaf: true };
          });
          let { soDependencies } = this.state;
          soDependencies = soDependencies.concat(headQuarters);
          this.setState({
            soDependencies,
          });
          resolve();
        });
      } else if (title === "Logistic Centers") {
        getLogisticCentersByStockOwnerId(pId, (response) => {
          const logisticCenters = response.data.data.map((obj) => {
            return { id: obj.id, pId: id, value: obj.id, title: obj.name, isLeaf: true };
          });
          let { soDependencies } = this.state;
          soDependencies = soDependencies.concat(logisticCenters);
          this.setState({
            soDependencies,
          });
          resolve();
        });
      } else {
        resolve();
      }
    });

  handleSoDependencyChange = (value) => {
    if (typeof value === "number") {
      const { soDependencies } = this.state;
      const { onSoDependencyChange, soDependencyHtmlTag, listScDependenciesBySoDependencyId } = this.props;

      this.setState({
        selectedSoDependencyId: value,

        scDependencies: [],
        selectedScDependencyId: null,
        isScDependencyLoading: true,
        isScDependencyDisabled: true,
      });

      listScDependenciesBySoDependencyId(value, (response) => {
        this.setState({
          scDependencies: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
          isScDependencyLoading: false,
          isScDependencyDisabled: false,
        });
      });

      if (onSoDependencyChange !== undefined) {
        const soDependency = soDependencies.find((obj) => obj.id === value);
        const event = { value: soDependency.id, label: soDependency.title, type: soDependency.type };
        onSoDependencyChange(event, soDependencyHtmlTag);
      }
    }
  };

  handleScDependencyChange = (event) => {
    const { value } = event;
    const { onScDependencyChange, scDependencyHtmlTag } = this.props;
    if (onScDependencyChange !== undefined) {
      onScDependencyChange(event, scDependencyHtmlTag);
    }

    this.setState({
      selectedScDependencyId: value,
    });
  };

  renderNoatecAndOnlySoSelector() {
    const { soDependencies, selectedSoDependencyId, isSoDependencyDisabled } = this.state;
    return (
      <div className="vertSpace col-xs-12 col-md-6">
        <TreeSelectGeneral
          text={Strings.soDependency.directClient}
          tooltip={Strings.soDependency.directClientTooltip}
          isReq
          value={selectedSoDependencyId}
          onChange={this.handleSoDependencyChange}
          loadData={this.onLoadData}
          treeData={soDependencies}
          disabled={isSoDependencyDisabled}
        />
      </div>
    );
  }

  renderNoatecWithScSelector() {
    const { soDependencies, selectedSoDependencyId, isSoDependencyDisabled } = this.state;
    const { scDependencies, selectedScDependencyId, isScDependencyLoading, isScDependencyDisabled } = this.state;
    return (
      <>
        <div className="vertSpace col-xs-12 col-md-6">
          <TreeSelectGeneral
            text={Strings.soDependency.directClient}
            tooltip={Strings.soDependency.directClientTooltip}
            isReq
            value={selectedSoDependencyId}
            onChange={this.handleSoDependencyChange}
            loadData={this.onLoadData}
            treeData={soDependencies}
            disabled={isSoDependencyDisabled}
          />
        </div>
        <div className="vertSpace col-xs-12 col-md-6">
          <SelectGeneral
            text={Strings.scDependency.indirectClient}
            tooltip={Strings.scDependency.indirectClientTooltip}
            isReq
            options={scDependencies}
            defaultValue={selectedScDependencyId}
            onChange={this.handleScDependencyChange}
            isLoading={isScDependencyLoading}
            disabled={isScDependencyDisabled}
          />
        </div>
      </>
    );
  }

  renderStockOwnerAndOnlySoSelector() {
    const {
      userDependency: { dependencyName },
    } = this.props;
    return (
      <div className="vertSpace col-xs-12 col-md-6">
        <NormalText text={Strings.soDependency.directClient} tooltip={Strings.soDependency.directClientTooltip} name={dependencyName} />
      </div>
    );
  }

  renderStockOwnerWithScSelector() {
    const { scDependencies, selectedScDependencyId, isScDependencyLoading, isScDependencyDisabled } = this.state;
    const { scDependencyHtmlTag } = this.props;
    return (
      <div className="vertSpace col-xs-12 col-md-6">
        <SelectGeneral
          text={Strings.scDependency.indirectClient}
          tooltip={Strings.scDependency.indirectClientTooltip}
          isReq
          options={scDependencies}
          name={scDependencyHtmlTag}
          defaultValue={selectedScDependencyId}
          onChange={this.handleScDependencyChange}
          isLoading={isScDependencyLoading}
          disabled={isScDependencyDisabled}
        />
      </div>
    );
  }

  renderOnlyScSelector() {
    const {
      userDependency: { dependencyName },
    } = this.props;
    return (
      <div className="vertSpace col-xs-12 col-md-6">
        <NormalText text={Strings.scDependency.indirectClient} tooltip={Strings.scDependency.indirectClientTooltip} name={dependencyName} />
      </div>
    );
  }

  render() {
    const {
      hasOnlySO,
      userDependency: { dependencyId },
      userDependency: { dependencyType },
    } = this.props;

    let soDependencySelector = null;

    // Noatec and has only So selection
    if (hasOnlySO === true && dependencyType === "so" && dependencyId === 1) {
      soDependencySelector = this.renderNoatecAndOnlySoSelector();
    } // Noatec and has Sc selection
    else if (hasOnlySO === false && dependencyType === "so" && dependencyId === 1) {
      soDependencySelector = this.renderNoatecWithScSelector();
    } // Different to Noatec and has only So selection
    else if (hasOnlySO === true && dependencyType === "so" && dependencyId !== 1) {
      soDependencySelector = this.renderStockOwnerAndOnlySoSelector();
    } // Different to Noatec and has Sc selection
    else if (hasOnlySO === false && dependencyType === "so" && dependencyId !== 1) {
      soDependencySelector = this.renderStockOwnerWithScSelector();
    } // Indirect Client
    else if (hasOnlySO === false && dependencyType === "sc") {
      soDependencySelector = this.renderOnlyScSelector();
    }

    return soDependencySelector;
  }
}
function mapStateToProps(state) {
  return {
    userDependency: state.dependencyByUser.dependencyObj,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  getLowerRelationsBySoDependencyId,
  getHeadquartersByStockOwnerId,
  getLogisticCentersByStockOwnerId,
})(SoScFullDependencySelector);
