// Dependencies
import { Button, Divider, Icon, Input, Modal, Pagination, Popconfirm, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./dispensationRule.css";

// Components
import Column from "antd/lib/table/Column";
import moment from "moment";
import { ValidateDependency } from "../../Utils/validateDependency";
import { ValidatePermissionForComponent } from "../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../Utils/validatePermissionForComponentPart";
import { AddButton, ExportButton } from "../GenericComponents/buttons/index";
import QueryResponse from "../GenericComponents/queryResponse/QueryResponse";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";
import Titles from "../GenericComponents/titles.js";

import { maxRegistersPerReport } from "../../systemVariables/serverInformation";
import ExportReportsModal from "../GenericComponents/exportReportModal";
import DispensationRuleDetailsModal from "./dispensationRuleDetailsModal";

// Actions
import {
  disableScDispensationRule,
  exportDispensationRuleReport,
  listDispensationRulesByScIdPaginated,
  listProductAssignmentByDispensationRuleId,
  listScDependenciesBySoDependencyId,
  listSoDependencyBySoDependencyType0,
  listVwScProductInformationByScDependency,
} from "../../actions/index.js";

// Language localization
import Strings from "../../systemVariables/languageStrings";

let productAssignmentsWithProductGroupId = [];
let productAssignmentsWithoutProductGroupId = [];
let vmProductGroupDispensationRule = [];

const dateFormat = "DD/MM/YYYY hh:mm:ss a";

class DispensationRules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      soDependencyId: 0,
      soDependencyName: null,
      scDependency: {
        id: null,
        name: null,
      },
      vmProductGroups: [],
      soProductByVmProductGroupList: [],
      scDispensationRules: [],
      loadingInfo: false,
      addButtonDisabled: true,
      disabledScDependency: true,
      scDispensationRulesCount: 0,
      scDispRuleName: "",
      startDate: null,
      endDate: null,

      page: 1,
      pageSize: 10,
      attribute: "name",
      order: "ASC",
      isEnabledFilter: 1,
      onlyDisabledFlag: false,

      // Details Modal
      detailsModalIsVisible: false,
      scDispRuleRowSelected: null,
      soProductConfigured: [],

      // Export Report Modal
      isExportModalVisible: false,
      isExceedModalVisible: false,
      exportLoading: false,
    };
    this.showPagination = this.showPagination.bind(this);
  }

  componentDidMount() {
    const { listSoDependencyBySoDependencyType0, userDependency, listScDependenciesBySoDependencyId, listDispensationRulesByScIdPaginated } = this.props;

    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") == 1) {
      listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
    }
    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") != 1) {
      this.setState({
        soDependencyId: userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyId : null,
        soDependencyName: userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyName : null,
        scDependency: {
          id: null,
          name: null,
        },
        disableDisable: true,
        disabledScDependency: false,
        page: 1,
        pageSize: 10,
        attribute: "name",
        order: "ASC",
        isEnabledFilter: 1,
      });
      const value = sessionStorage.getItem("dependencyId");
      this.setState({
        soDependencyId: value,
        scDependency: { id: null },
        disableDisable: true,
        disabledScDependency: false,
      });
      listScDependenciesBySoDependencyId(value, (response) => {
        this.setState({
          scDependencies: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }
    if (sessionStorage.getItem("dependencyType") === "sc") {
      const value = sessionStorage.getItem("dependencyId");
      this.setState({
        scDependency: {
          id: userDependency.dependencyType === "sc" ? userDependency.dependencyId : null,
          name: userDependency.dependencyType === "sc" ? userDependency.dependencyName : null,
        },
        loadingInfo: true,
      });
      const { isEnabledFilter, page, pageSize, attribute, order } = this.state;

      listDispensationRulesByScIdPaginated(value, "", isEnabledFilter, page, pageSize, attribute, order, (response) => {
        this.setState({
          scDispensationRules: response.data.data.content.map((item) => {
            return {
              id: item.id,
              name: item.name,
              description: item.description,
              startDate: moment.utc(item.startDate).local().format(dateFormat),
              startDate2: moment.utc(item.startDate).local().format(),
              endDate: item.endDate != null ? moment.utc(item.endDate).local().format(dateFormat) : "--",
              endDate2: item.endDate != null ? moment.utc(item.endDate).local().format() : "--",
              isEnabled: item.isEnabled,
            };
          }),
          scDispensationRulesCount: response.data.data.totalElements,
          loadingInfo: false,
          addButtonDisabled: false,
          disableDisable: false,
        });
      });
    }
  }

  // Events
  soDependencyHandleChange(event) {
    const { value } = event;
    this.setState({
      soDependencyId: value,
      scDependency: { id: null },
      disableDisable: true,
      disabledScDependency: false,
    });

    const { listScDependenciesBySoDependencyId } = this.props;
    listScDependenciesBySoDependencyId(value, (response) => {
      this.setState({
        scDependencies: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });
  }

  scDependencyHandleChange(event) {
    const { value } = event;
    this.setState({
      scDependency: {
        id: event.value,
        name: event.label,
      },
      disableDisable: false,
      loadingInfo: true,
    });
    const { listDispensationRulesByScIdPaginated } = this.props;
    const { isEnabledFilter, page, pageSize, attribute, order } = this.state;
    listDispensationRulesByScIdPaginated(value, "", isEnabledFilter, page, pageSize, attribute, order, (response) => {
      this.setState({
        scDispensationRules: response.data.data.content.map((item) => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            startDate: moment.utc(item.startDate).local().format(dateFormat),
            startDate2: moment.utc(item.startDate).local().format(),
            endDate: item.endDate != null ? moment.utc(item.endDate).local().format(dateFormat) : "--",
            endDate2: item.endDate != null ? moment.utc(item.endDate).local().format() : "--",
            isEnabled: item.isEnabled,
          };
        }),
        scDispensationRulesCount: response.data.data.totalElements,
        loadingInfo: false,
        addButtonDisabled: false,
      });
    });
  }

  showPagination(page, pageSize) {
    this.setState({
      loadingInfo: true,
      page,
      pageSize,
    });
    const { listDispensationRulesByScIdPaginated } = this.props;
    const { scDependency, isEnabledFilter, scDispRuleName, attribute, order } = this.state;
    listDispensationRulesByScIdPaginated(scDependency.id, scDispRuleName, isEnabledFilter, page, pageSize, attribute, order, (response) => {
      this.setState({
        scDispensationRules: response.data.data.content.map((item) => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            startDate: moment.utc(item.startDate).local().format(dateFormat),
            startDate2: moment.utc(item.startDate).local().format(),
            endDate: item.endDate != null ? moment.utc(item.endDate).local().format(dateFormat) : "--",
            endDate2: item.endDate != null ? moment.utc(item.endDate).local().format() : "--",
            isEnabled: item.isEnabled,
          };
        }),
        scDispensationRulesCount: response.data.data.totalElements,
        loadingInfo: false,
      });
    });
  }

  disableProductRule(e, row) {
    const { scDependency } = this.state;
    const scDipsRule = {};
    scDipsRule.id = row.id;
    scDipsRule.stockCustomerId = scDependency.id;
    scDipsRule.name = row.name;
    scDipsRule.description = row.description;
    scDipsRule.isEnabled = 0;
    const { disableScDispensationRule, listDispensationRulesByScIdPaginated } = this.props;
    this.setState({
      loadingInfo: true,
    });
    disableScDispensationRule(scDipsRule, (response, error) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 0) {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
        });
        const { scDependency, scDispRuleName, isEnabledFilter, page, pageSize, attribute, order } = this.state;
        listDispensationRulesByScIdPaginated(scDependency.id, scDispRuleName, isEnabledFilter, page, pageSize, attribute, order, (response) => {
          this.setState({
            scDispensationRules: response.data.data.content.map((item) => {
              return {
                id: item.id,
                name: item.name,
                description: item.description,
                startDate: moment.utc(item.startDate).local().format(dateFormat),
                startDate2: moment.utc(item.startDate).local().format(),
                endDate: item.endDate != null ? moment.utc(item.endDate).local().format(dateFormat) : "--",
                endDate2: item.endDate != null ? moment.utc(item.endDate).local().format() : "--",
                isEnabled: item.isEnabled,
              };
            }),
          });
        });
      } else {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      }
      this.setState({
        loadingInfo: false,
      });
    });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: "100%", marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: "45%", marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: "50%" }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearch = (selectedKeys) => {
    if (selectedKeys.length > 0) {
      this.setState({
        loadingInfo: true,
        scDispRuleName: selectedKeys[0],
      });
      const { listDispensationRulesByScIdPaginated } = this.props;
      const { scDependency, isEnabledFilter, page, pageSize, attribute, order } = this.state;
      listDispensationRulesByScIdPaginated(scDependency.id, selectedKeys[0], isEnabledFilter, page, pageSize, attribute, order, (response) => {
        this.setState({
          scDispensationRules: response.data.data.content.map((item) => {
            return {
              id: item.id,
              name: item.name,
              description: item.description,
              startDate: moment.utc(item.startDate).local().format(dateFormat),
              startDate2: moment.utc(item.startDate).local().format(),
              endDate: item.endDate != null ? moment.utc(item.endDate).local().format(dateFormat) : "--",
              endDate2: item.endDate != null ? moment.utc(item.endDate).local().format() : "--",
              isEnabled: item.isEnabled,
            };
          }),
          scDispensationRulesCount: response.data.data.totalElements,
          loadingInfo: false,
        });
      });
    }
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      loadingInfo: true,
      scDispRuleName: "",
    });
    const { listDispensationRulesByScIdPaginated } = this.props;
    const { scDependency, isEnabledFilter, page, pageSize, attribute, order } = this.state;
    listDispensationRulesByScIdPaginated(scDependency.id, "", isEnabledFilter, page, pageSize, attribute, order, (response) => {
      this.setState({
        scDispensationRules: response.data.data.content.map((item) => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            startDate: moment.utc(item.startDate).local().format(dateFormat),
            startDate2: moment.utc(item.startDate).local().format(),
            endDate: item.endDate != null ? moment.utc(item.endDate).local().format(dateFormat) : "--",
            endDate2: item.endDate != null ? moment.utc(item.endDate).local().format() : "--",
            isEnabled: item.isEnabled,
          };
        }),
        scDispensationRulesCount: response.data.data.totalElements,
        loadingInfo: false,
      });
    });
  };

  // Export methods
  showExportReportsModal = () => {
    const { dispensationsCount } = this.state;
    if (dispensationsCount >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible, isExceedModalVisible) => {
    this.setState({
      isExportModalVisible,
      isExceedModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    const { exportLoading } = this.state;
    if (!exportLoading) {
      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
      const columnHeaders = [
        Strings.dispensationRule.dispensationRule,
        Strings.product.soProduct,
        Strings.product.erpCode,
        Strings.product.scProductInfo,
        Strings.product.scProductCode,
        Strings.vmProductGroup.vmProductGroup,
        Strings.dispensationRule.amount,
        Strings.dispensationRule.frequency,
        Strings.dispensationRule.frequencyUnit,
        Strings.dispensationRule.startDate,
        Strings.dispensationRule.endDate,
      ];

      // Start loading animation on Export button
      this.setState({ exportLoading: true });

      // Consume report export method

      const { exportDispensationRuleReport } = this.props;
      const { soDependencyId, scDependency, scDispRuleName, order } = this.state;
      exportDispensationRuleReport(soDependencyId, scDependency.id, scDispRuleName, "scDispensationRuleId", order, fileType, columnHeaders, (response) => {
        if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.reportGenerationError,
          });
          this.setState({
            exportLoading: false,
          });
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", `${Strings.dispensationRule.dispensationRuleReport}: ${scDependency.name}.csv`);
          document.body.appendChild(link);
          link.click();
          this.setState({
            exportLoading: false,
          });
        }
      });
    }
  };

  moreInfoDetails = (e, row) => {
    // Query all productAssignment info by scDispensationRule identifier
    productAssignmentsWithProductGroupId = [];
    productAssignmentsWithoutProductGroupId = [];
    vmProductGroupDispensationRule = [];
    const { listProductAssignmentByDispensationRuleId } = this.props;
    listProductAssignmentByDispensationRuleId(row.id, (response) => {
      if (response.data.data.length > 0) {
        productAssignmentsWithProductGroupId = response.data.data.filter((item) => item.vmProductGroupId != null);
        productAssignmentsWithoutProductGroupId = response.data.data.filter((item) => item.vmProductGroupId == null);

        productAssignmentsWithoutProductGroupId.forEach((obj) => {
          if (obj.allowedAmount == null) {
            obj.frequencyValue = "--";
            obj.frequencyUnitId = "--";
            obj.frequencyUnitName = "--";
            obj.allowedAmount = Strings.generalTerms.ilimited;
          } else {
            obj.frequencyValue = obj.frequencyValue;
            obj.frequencyUnitId = obj.frequencyUnitId;
            obj.frequencyUnitName = obj.frequencyUnitName;
            obj.allowedAmount = obj.allowedAmount;
          }
        });

        productAssignmentsWithProductGroupId.forEach((obj) => {
          if (vmProductGroupDispensationRule.filter((item) => item.name === obj.vmProductGroupName).length === 0) {
            const vmProductGroutInfo = {};
            vmProductGroutInfo.name = obj.vmProductGroupName;
            vmProductGroutInfo.description = obj.vmProductGroupDescription;
            vmProductGroutInfo.allowedAmount = obj.allowedAmount == null ? Strings.generalTerms.ilimited : obj.allowedAmount;
            vmProductGroutInfo.frequencyValue = obj.allowedAmount == null ? "--" : obj.frequencyValue;
            vmProductGroutInfo.frequencyUnitName = obj.allowedAmount == null ? "--" : obj.frequencyUnitName;
            vmProductGroupDispensationRule = vmProductGroupDispensationRule.concat(vmProductGroutInfo);
          }
        });
      }

      if (productAssignmentsWithoutProductGroupId.length > 0 || productAssignmentsWithProductGroupId.length > 0) {
        this.setState({
          detailsModalIsVisible: true,
          scDispRuleRowSelected: row,
        });
      }
    });
  };

  setModalVisible = (isVisible) => {
    this.setState({ detailsModalIsVisible: isVisible });
    productAssignmentsWithProductGroupId = [];
    productAssignmentsWithoutProductGroupId = [];
  };

  TagColumnDelete = (onlyDisabledFlag, data) => {
    if (!onlyDisabledFlag) {
      if (ValidatePermissionForComponentPart("PRIVILEGE DISPENSATION RULES DELETE", data)) {
        return (
          <Column
            title={Strings.generalTerms.delete}
            style={{ width: "10%" }}
            align="center"
            accessor="remove"
            render={(row) => (
              <div align="center">
                <Popconfirm
                  title={`${Strings.generalTerms.important}: ${Strings.dispensationRule.disableScDispensationRuleMsgConfirmaiton}`}
                  onConfirm={(e) => this.disableProductRule(e, row)}
                  okText="Sí"
                  cancelText="No"
                >
                  <Icon type="delete" theme="twoTone" className="addEditRemoveButton" style={{ cursor: "pointer", fontSize: 16 }} />
                </Popconfirm>
              </div>
            )}
          />
        );
      }
      return " ";
    }
    return " ";
  };

  methodDispensationRules(scDispensationRules, loadingInfo, data, onlyDisabledFlag, scDispensationRulesCount, page, exportLoading) {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <div className="vertSpace col-12">
              <Table dataSource={scDispensationRules} bordered loading={loadingInfo} pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="id">
                <Column
                  title={<TextWithInfoTooltip name={Strings.generalTerms.name} tooltip={Strings.dispensationRule.scDispensationRuleName} />}
                  dataIndex="name"
                  {...this.getColumnSearchProps("name")}
                  style={{ width: "20%" }}
                />
                <Column
                  title={<TextWithInfoTooltip name={Strings.generalTerms.description} tooltip={Strings.dispensationRule.scDispensationRuleDescription} />}
                  dataIndex="description"
                  style={{ width: "20%" }}
                />
                <Column
                  title={<TextWithInfoTooltip name={Strings.dispensationRule.startDate} tooltip={Strings.dispensationRule.startDateTooltip} />}
                  dataIndex="startDate"
                  style={{ width: "10%" }}
                />
                <Column
                  title={<TextWithInfoTooltip name={Strings.dispensationRule.endDate} tooltip={Strings.dispensationRule.endDateTooltip} />}
                  dataIndex="endDate"
                  style={{ width: "10%" }}
                />
                <Column
                  title={Strings.generalTerms.details}
                  align="center"
                  style={{ width: "20%" }}
                  render={(row) => (
                    <div>
                      <Icon
                        type="profile"
                        theme="twoTone"
                        style={{ cursor: "pointer", fontSize: 16 }}
                        className="addEditRemoveButton"
                        onClick={(e) => this.moreInfoDetails(e, row)}
                      />
                    </div>
                  )}
                />

                {ValidatePermissionForComponentPart("PRIVILEGE DISPENSATION RULES PUT", data) ? (
                  <Column
                    title={Strings.generalTerms.edit}
                    align="center"
                    style={{ width: "10%" }}
                    render={(row) => (
                      <div align="center">
                        <Link
                          to={{
                            pathname: "/editDispensationRules",
                            props: {
                              soDependencyId: this.state.soDependencyId,
                              scDependency: this.state.scDependency,
                              vmProductGroups: this.state.vmProductGroups,
                              soProductByVmProductGroups: this.state.soProductByVmProductGroupList,
                              scDispensationRule: row,
                              onlyDisabledFlag: this.state.onlyDisabledFlag,
                              startDate: this.state.startDate,
                              endDate: this.state.endDate,
                            },
                          }}
                        >
                          <Icon type="edit" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
                        </Link>
                      </div>
                    )}
                  />
                ) : (
                  ""
                )}
                {this.TagColumnDelete(onlyDisabledFlag, data)}
              </Table>
            </div>

            <div className="col-xs-0 col-lg-6" />
            <div className="vertSpace col-lg-6">
              <Pagination
                size="small"
                total={scDispensationRulesCount}
                showQuickJumper
                showSizeChanger
                onChange={this.showPagination}
                onShowSizeChange={this.showPagination}
                hideOnSinglePage={false}
                pageSizeOptions={["10", "25", "50", "100", "250"]}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                current={page}
                disabled={scDispensationRulesCount === 0}
              />
            </div>
          </div>
        </div>

        <ValidatePermissionForComponent permission="PRIVILEGE DISPENSATION REPORT GET" permissions={data}>
          <div className="row">
            <div className="vertSpace col">
              <div className="col-md-6" />
              <div className="col-md-6">
                <ExportButton isDisabled={scDispensationRulesCount <= 0} onClick={() => this.showExportReportsModal()} loading={exportLoading} />
              </div>
            </div>
          </div>
        </ValidatePermissionForComponent>
      </div>
    );
  }

  // Information to render

  render() {
    const {
      scDispensationRules,
      isExportModalVisible,
      isExceedModalVisible,
      detailsModalIsVisible,
      scDispRuleRowSelected,
      scDependency,
      soDependencyId,
      soDependencyName,
      scDependencies,
      disabledScDependency,
      loadingInfo,
      onlyDisabledFlag,
      addButtonDisabled,
      scDispensationRulesCount,
      page,
      exportLoading,
    } = this.state;
    const {
      permissions: { data },
      soDependencies,
    } = this.props;

    return (
      <div className="content-container">
        <ExportReportsModal
          isExportVisible={isExportModalVisible}
          isExceedVisible={isExceedModalVisible}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
        />

        <DispensationRuleDetailsModal
          isVisible={detailsModalIsVisible}
          setIsVisible={this.setModalVisible}
          scDispRuleRowSelected={scDispRuleRowSelected}
          scDependency={scDependency.name}
          productAssignmentsWithoutProductGroupId={productAssignmentsWithoutProductGroupId}
          productAssignmentsWithProductGroupId={productAssignmentsWithProductGroupId}
          vmProductGroupDispensationRule={vmProductGroupDispensationRule}
        />

        <div className="row">
          <Titles title={Strings.dispensationRule.dispensationRules} tooltip={Strings.dispensationRule.dispensationRulesTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <ValidateDependency
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            soDependencies={soDependencies}
            soDependencyId={soDependencyId}
            soDependency={soDependencyName}
            scDependencies={scDependencies}
            scDependencyId={scDependency.id}
            scDependency={scDependency.name}
            disabledScDependency={disabledScDependency}
            soDependencyHandleChange={(e) => this.soDependencyHandleChange(e)}
            scDependencyHandleChange={(e) => this.scDependencyHandleChange(e)}
          />
        </div>

        <ValidatePermissionForComponent permission="PRIVILEGE DISPENSATION RULES POST" permissions={data}>
          <div className="row">
            <div className="vertSpace col-md-6" />
            <div className="vertSpace col-md-6">
              <AddButton
                tooltip={Strings.dispensationRule.addDispensationRuleTooltip}
                link={addButtonDisabled ? "" : "/addDispensationRules"}
                props={
                  addButtonDisabled
                    ? ""
                    : {
                        soDependencyId: this.state.soDependencyId,
                        scDependency: this.state.scDependency,
                        vmProductGroups: this.state.vmProductGroups,
                        soProductByVmProductGroups: this.state.soProductByVmProductGroupList,
                      }
                }
                disabled={addButtonDisabled}
              />
            </div>
          </div>
        </ValidatePermissionForComponent>
        <Divider orientation="left">
          <h3>{Strings.dispensationRule.assignedDispensationRules} </h3>
        </Divider>
        <QueryResponse
          isLoading={loadingInfo}
          dataSourceLength={scDispensationRules.length}
          callback={this.methodDispensationRules(scDispensationRules, loadingInfo, data, onlyDisabledFlag, scDispensationRulesCount, page, exportLoading)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listDispensationRulesByScIdPaginated,
  disableScDispensationRule,
  listProductAssignmentByDispensationRuleId,
  exportDispensationRuleReport,
  listVwScProductInformationByScDependency,
})(DispensationRules);
