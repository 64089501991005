// Dependencies
import { Component } from "react";

export class ValidatePermissionForComponent extends Component {
  validatePermissions = (toValidate) => {
    let isValid = false;
    // if(sessionStorage.getItem("dependencyType")===this.props.dependency){
    //     isValid=true;
    // }
    if (this.props.permissions != null) {
      this.props.permissions.forEach((element) => {
        if (element.name === toValidate) isValid = true;
      });
      return isValid;
    }
  };

  render() {
    return this.validatePermissions(this.props.permission) ? this.props.children : "";
  }
}
