import axios from "axios";

import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/helixtypes";


export const LIST_HELIXTYPE  = 'LIST_HELIXTYPE';

export function listHelixType(){
  return (dispatch) => {
    axios.get(host+ port + path + version  + resource,{
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
      }
    })
    .then((response) => {
      dispatch( { type: LIST_HELIXTYPE, payload: response.data.data } );
    })
    .catch(function (error){
      return error;
    });
  }
}