import React from "react";
import { Icon, Modal, Typography, Tooltip } from "antd";
import Strings from "../../../systemVariables/languageStrings";

const { Text } = Typography;

const RecoveryMachineSyncModal = (props) => {
  const { nameUser, recoveryToken, recoveryDate, serialNumber } = props;
  const DATE_OPTIONS = { year: "numeric", month: "numeric", day: "numeric" };
  const startDateStr = new Date(recoveryDate).toLocaleDateString("es-CO", DATE_OPTIONS);
  return (
    <Tooltip title={Strings.machine.machiveRecoveryInfo} color="189EFF" style={{ padding: "2%" }}>
      <a
        href="#"
        onClick={() => {
          Modal.success({
            title: `${Strings.machine.configuration.generalInformation}`,
            content: `${Strings.machine.configuration.generalInformation}: token ${recoveryToken}, ${Strings.generalTerms.dateRecovery} ${startDateStr},  ${Strings.generalTerms.updatedBy} ${nameUser}, serial ${Strings.generalTerms.of} ${Strings.machine.machine} ${serialNumber}`,
          });
        }}
      >
        <Text style={{ color: "#189EFF", margin: "1%" }}>
          <Icon type="profile" style={{ margin: "1%" }} />
          {Strings.generalTerms.details}
        </Text>
      </a>
    </Tooltip>
  );
};

export default RecoveryMachineSyncModal;
