/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from "react";
import { Tooltip, Modal, Input, Icon, Typography, Divider, Alert } from "antd";
import { connect } from "react-redux";
import Strings from "../../../systemVariables/languageStrings";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";

// *Querys
import { editFriendlyNameVmAsync } from "../../../actions";

const { Title } = Typography;

const EditNameVm = ({ name, generalTermsName, vmId, callbackFriendlyName, permissions }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const inputRef = useRef(null);
  const [input] = useState(true);
  const [isAlertError, setIsAlertError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCangeNameOk, setIsCangeNameOk] = useState(false);

  const onChangeInput = (e) => {
    const {
      target: { value },
    } = e;
    if (value.length >= 100) {
      setIsAlertError(true);
    } else {
      setIsAlertError(false);
    }
  };

  const sharedProps = {
    style: {
      width: "100%",
      align: "center",
    },
    defaultValue: name,
    ref: inputRef,
    maxLength: 100,
    onChange: (e) => {
      onChangeInput(e);
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const callback = () => {
    callbackFriendlyName(inputRef.current.state.value);
  };

  const checkingError = async (ans, saveSuccess) => {
    const {
      generalResponses: { connectionError, FIELD_VALUE_IS_EMPTY, connectionErrorTooltip, serverError },
    } = Strings;
    try {
      const SWITCH_ERROR =
        (ans.status || ans.response.status,
        {
          200: () => {
            const {
              data: { code },
            } = ans;
            const ERROR_INTERNAL_SERVER_SUCCESS = {
              0: () => {
                const {
                  data: {
                    data: { friendlyName },
                  },
                } = ans;

                Modal.success({
                  title: saveSuccess,
                  content: friendlyName,
                  onOk: () => {
                    setIsModalVisible(false);
                    setIsLoading(false);
                    setIsCangeNameOk(true);
                    callback();
                  },
                });
              },
              4018: () => {
                Modal.error({
                  title: "Error message",
                  content: FIELD_VALUE_IS_EMPTY,
                  onOk: () => {
                    setIsModalVisible(false);
                    setIsLoading(false);
                  },
                });
              },
              4007: () => {
                Modal.error({
                  title: "Error message",
                  content: "The record was not found in the database.",
                  onOk: () => {
                    setIsModalVisible(false);
                    setIsLoading(false);
                  },
                });
              },
            };
            ERROR_INTERNAL_SERVER_SUCCESS[code]();
          },
          500: () => {
            Modal.error({
              title: " Error 500",
              content: serverError,
              onOk: () => {
                setIsModalVisible(false);
                setIsLoading(false);
              },
            });
          },
        });

      await SWITCH_ERROR[ans.status || ans.response.status]();
    } catch (e) {
      Modal.error({
        title: connectionError,
        content: connectionErrorTooltip,
        onOk: () => {
          setIsModalVisible(false);
          setIsLoading(false);
        },
      });
    }
  };

  const handleOk = async () => {
    const {
      current: {
        state: { value },
      },
    } = inputRef;
    const {
      generalResponses: { saveSuccess },
    } = Strings;
    setIsLoading(true);

    const response = await editFriendlyNameVmAsync(vmId, value.trim() === "" ? "" : value);
    await checkingError(response, saveSuccess);
  };

  const handleCancel = () => {
    if (isLoading !== true) {
      setIsModalVisible(false);
    }
  };

  function isEdit() {
    const {
      machine: { infoTextFriendlyNameContents, changeFriendlyName, changeFriendlyNameContents, ErrorChangeFriendlyNameContents },
    } = Strings;
    return (
      <div className=" col-md-12">
        <div className={ValidatePermissionForComponentPart("PRIVILEGE VMSETUP PUT MODULES", permissions.data) ? "col-md-12" : ""} style={{ padding: "1px 1px" }}>
          <div className="row">
            <div className="col-12" style={{ textAlign: "left" }}>
              <span className="inputText">{generalTermsName}:</span>
            </div>
            <div className="col-12" style={{ textAlign: "left" }}>
              {isCangeNameOk === true ? inputRef.current.state.value : name}
              {ValidatePermissionForComponentPart("PRIVILEGE VMSETUP PUT MODULES", permissions.data) ? (
                <Tooltip title={changeFriendlyName}>
                  <Icon type="edit" style={{ margin: "1%" }} theme="twoTone" size="small" className="addEditRemoveButton" onClick={showModal} />
                </Tooltip>
              ) : null}
            </div>
          </div>
        </div>
        <Modal visible={isModalVisible} onOk={handleOk} confirmLoading={isLoading} cancelButtonProps={{ disabled: isLoading }} onCancel={handleCancel}>
          <Title level={4}>{changeFriendlyName} </Title>
          <Divider />
          <div className="vertSpace col-12">
            <p>{changeFriendlyNameContents}</p>
          </div>
          <div className="col-9"> {input ? <Input {...sharedProps} /> : <Input.TextArea {...sharedProps} />}</div>
          <div className="vertSpace col-12">
            <Alert description={infoTextFriendlyNameContents} showIcon type="info" />
          </div>
          {isAlertError ? (
            <div className="vertSpace col-12">
              <Alert message="Error Text" showIcon description={ErrorChangeFriendlyNameContents} type="error" closable />
            </div>
          ) : null}
        </Modal>
      </div>
    );
  }

  return <>{isEdit()}</>;
};

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {})(EditNameVm);
