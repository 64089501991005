// Dependencies
import { Button, Icon, Input, Modal, Popconfirm, Table } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { disablePeripheralDevice, enablePeripheralDevice } from "../../actions/index";

// Components
import { ValidatePermissionForComponentPart } from "../../Utils/validatePermissionForComponentPart";
import PeripheralDeviceEditModal from "./PeripheralDeviceEditModal";
import VmPeripheralDeviceModal from "./VmPeripheralDeviceModal";
// Localization
import Strings from "../../systemVariables/languageStrings";

const { Column } = Table;

class PeripheralDeviceTable extends Component {
  componentDidMount() {
    this.handleReset();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys[0])}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys[0])} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset()} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearch = (selectedKeys) => {
    const { setAlternativeSerialFilter } = this.props;
    if (selectedKeys !== undefined) setAlternativeSerialFilter(selectedKeys.toString());
  };

  handleReset() {
    const { setAlternativeSerialFilter } = this.props;
    setAlternativeSerialFilter("");
  }

  handleEnablePeripheralDevice = (device) => {
    const { enablePeripheralDevice, reloadTable } = this.props;
    enablePeripheralDevice(device.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.status === 200) {
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
        });
        reloadTable();
      } else {
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      }
    });
  };

  handleDisablePeripheralDevice = (row) => {
    const { disablePeripheralDevice, reloadTable } = this.props;
    disablePeripheralDevice(row.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 0) {
        // Entity successfully disabled
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
        });
        // Force query again
        reloadTable();
      }
    });
  };

  render() {
    const { peripheralDevices, loading, filterType, reloadTable } = this.props;
    const {
      permissions: { data },
    } = this.props;
    let noahubKeyListId = false;
    let modbusId = false;
    let modelPeripheral = false;
    peripheralDevices.forEach((obj) => {
      if (obj.noahubKeyListId) {
        noahubKeyListId = true;
      }
      if (obj.modbusId) {
        modbusId = true;
      }
      if (obj.peripheralDeviceModel) {
        modelPeripheral = true;
      }
    });
    return (
      <>
        <div className="vertSpace col-12">
          <Table dataSource={peripheralDevices} loading={loading} bordered pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="id">
            {modelPeripheral === true ? (
              <Column style={{ width: "50%" }} align="center" title={Strings.peripheralDevice.peripheralDeviceModel} dataIndex="peripheralDeviceModel" />
            ) : null}
            <Column
              style={{ width: "15%" }}
              align="center"
              title={Strings.machine.machineGeneration}
              dataIndex="currentPeripheralDevicesGeneration"
              render={(currentPeripheralDevicesGeneration) =>
                currentPeripheralDevicesGeneration !== undefined ? currentPeripheralDevicesGeneration : Strings.peripheralDevice.peripheralAllGeneration
              }
            />
            {!noahubKeyListId ? (
              <Column style={{ width: "50%" }} align="center" title={Strings.peripheralDevice.peripheralDeviceSerialNumber} dataIndex="peripheralDeviceSerialNumber" />
            ) : (
              "--"
            )}
            {noahubKeyListId ? <Column style={{ width: "50%" }} align="center" title={Strings.machine.noahubSerial} dataIndex="noaHubKeyListSerialNumber" /> : "--"}
            <Column
              style={{ width: "20%" }}
              align="center"
              title={Strings.peripheralDevice.peripheralDeviceAlternativeSerialNumber}
              dataIndex="peripheralDeviceAlternativeSerialNumber"
              {...this.getColumnSearchProps("peripheralDeviceAlternativeSerialNumber")}
              render={(p) => (p === undefined ? "--" : p)}
              filtered
            />
            <Column style={{ width: "20%" }} align="center" title={Strings.peripheralDevice.mpn} dataIndex="mpn" render={(p) => (p === undefined ? "--" : p)} />
            {modbusId ? <Column title="Modbus" dataIndex="modbusId" /> : "--"}
            <Column style={{ width: "15%" }} align="center" title={Strings.peripheralDevice.peripheralDeviceModelManufacturer} dataIndex="manufacturer" />
            {filterType !== 2 ? (
              <Column
                style={{ width: "10%" }}
                align="center"
                title={Strings.machine.assignedMachine}
                render={(row) => <VmPeripheralDeviceModal peripheralInfo={row} reloadTable={reloadTable} peripheralDevices={peripheralDevices} />}
              />
            ) : null}
            {filterType === 1 || filterType === 3 ? (
              <Column
                style={{ width: "10%" }}
                title={Strings.generalTerms.assignationDate}
                align="center"
                dataIndex="assignationDate"
                render={(assignationDate) =>
                  assignationDate != null ? <div>{new Date(assignationDate).toLocaleDateString("es-CO", { hour: "numeric", minute: "numeric" })}</div> : "--"
                }
              />
            ) : null}
            {filterType === 2 ? (
              <Column
                style={{ width: "5%" }}
                title={Strings.generalTerms.enable}
                align="center"
                render={(row) => (
                  <div
                    style={{ color: "#004173", cursor: "pointer" }}
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => this.handleEnablePeripheralDevice(row)}
                    onClick={() => this.handleEnablePeripheralDevice(row)}
                  >
                    {Strings.generalTerms.enable}
                  </div>
                )}
              />
            ) : null}
            {filterType !== 2 && ValidatePermissionForComponentPart("PRIVILEGE PERIPHERAL DEVICE PUT", data) ? (
              <Column
                title={Strings.generalTerms.edit}
                style={{ width: "2.5%" }}
                render={(row) => <PeripheralDeviceEditModal peripheralDevice={row} reloadTable={reloadTable} />}
                align="center"
              />
            ) : null}

            {filterType !== 2 && ValidatePermissionForComponentPart("PRIVILEGE PERIPHERAL DEVICE DELETE", data) ? (
              <Column
                title={Strings.generalTerms.delete}
                style={{ width: "2.5%" }}
                align="center"
                render={(row) => (
                  <Popconfirm
                    title={
                      row.vmName != null && row.vmName !== "" && (filterType === 1 || filterType === 3)
                        ? Strings.peripheralDevice.peripheralAndVmPeripheralDeleteWarn
                        : Strings.peripheralDevice.peripheralDeviceDeleteWarn
                    }
                    onConfirm={() => this.handleDisablePeripheralDevice(row)}
                    okText="Sí"
                    cancelText="No"
                  >
                    <Icon type="delete" theme="twoTone" className="addEditRemoveButton" />
                  </Popconfirm>
                )}
              />
            ) : null}
          </Table>
        </div>
      </>
    );
  }
}

PeripheralDeviceTable.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.any),
  peripheralDevices: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool.isRequired,
  filterType: PropTypes.number.isRequired,
  setAlternativeSerialFilter: PropTypes.func.isRequired,
  reloadTable: PropTypes.func.isRequired,
  enablePeripheralDevice: PropTypes.func,
  disablePeripheralDevice: PropTypes.func,
};

PeripheralDeviceTable.defaultProps = {
  permissions: null,
  enablePeripheralDevice: null,
  disablePeripheralDevice: null,
};

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  enablePeripheralDevice,
  disablePeripheralDevice,
})(PeripheralDeviceTable);
