import Strings from "../../systemVariables/languageStrings";

export function lockerModalitiesLocalization(lockerModalities) {
  switch (lockerModalities) {
    case "SELL":
      lockerModalities = Strings.machine.lockerModalitiesLocalization.sell;
      break;
    case "DELIVERY":
      lockerModalities = Strings.machine.lockerModalitiesLocalization.delivery;
      break;
    case "LOAN":
      lockerModalities = Strings.machine.lockerModalitiesLocalization.loan;
      break;
    case "BORROW":
      lockerModalities = Strings.machine.lockerModalitiesLocalization.borrow;
      break;
    case "FIXED":
      lockerModalities = Strings.machine.lockerModalitiesLocalization.fixed;
      break;
    case "FREE":
      lockerModalities = Strings.machine.lockerModalitiesLocalization.free;
      break;
    case "SEND":
      lockerModalities = Strings.machine.lockerModalitiesLocalization.send;
      break;
    case "EMPTY":
      lockerModalities = Strings.machine.lockerModalitiesLocalization.empty;
      break;
    case "NEW SLOT":
      lockerModalities = Strings.machine.lockerModalitiesLocalization.newSlot;
      break;
    case "RESERVED":
      lockerModalities = Strings.machine.lockerModalitiesLocalization.reserved;
      break;
    case "CONTROL MODULE":
      lockerModalities = Strings.machine.lockerModalitiesLocalization.controlModule;
      break;
    default:
      break;
  }
  return lockerModalities;
}
