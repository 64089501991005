import { LIST_VMPRODUCTGROUP } from '../actions'


export function vmProductGroup(state = [], action) {
  switch (action.type) {
    case LIST_VMPRODUCTGROUP:
      var vmProductGroup = action.payload.data.map(item => ({value:item.id, label:item.name}))
      return vmProductGroup;
    default:
      return state
  }
}