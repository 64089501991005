import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/vmeventmessaging";

export function getVmEventMessagingRegistersByVmId(vmId, callback) {
   return () => {
      axios.get(host + port + path + version + resource, {
         params: {
            vendingmachineid: vmId
         },
         headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
         },
      }).then((response) => {
         callback(response)
      }).catch(error => {
         callback(error);
      });
   }
}

export function updateVmEventMessaging(entity, callback) {
   return () => {
      const data = JSON.stringify(entity);
      axios({
         method: 'put',
         url: host + port + path + version + resource,
         data,
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
         }
      }).then(response => {
         callback(response);
      }).catch(error => {
         callback(error);
      });
   };
}

export function updateVmEventMessagingList(scDependencyId, eventTypeId, sendEmail, sendSms, callback) {
   return () => {
      axios({
         method: 'put',
         url: host + port + path + version + resource,
         params: {
            scdependencyid: scDependencyId,
            eventtypeid: eventTypeId, 
            sendemail: sendEmail, 
            sendsms: sendSms
         },
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
         }
      }).then(response => {
         callback(response);
      }).catch(error => {
         callback(error);
      });
   };
}