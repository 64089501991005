import React from "react";

import Strings from "../../../systemVariables/languageStrings";
import "./loadingMainContent.css";

import NoatecLogo from "../../Global/images/NoatecLogo.png";

export function LoadingMainContent() {
  return (
    <div className="col-12" style={{ position: "absolute", height: "100vh" }}>
      <div style={{top: "25%", position: "relative", width: "100%"}}>
        <div className="loading-container">
          <div className="loadingSubContainer">
            <img src={NoatecLogo} className="App-logo" alt="logo" />
          </div>
          <div className="loadingSubContainerText">
            <div className="loadingViewText">{Strings.generalResponses.loadingViewMessage}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
