import React from "react";
import { Tooltip, Icon, Input } from "antd";
import "./input.css";

export function GeneralInput(props) {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 selectAligning ">
          <span className="inputText">
            {props.text}
            <b>{props.isReq === true ? ": *" : ":"}</b>
          </span>
          {props.tooltip ? (
            <Tooltip title={props.tooltip}>
              <Icon className="icon" type="info-circle" /> <span />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-8 input">
          <Input
            type={props.type}
            id={props.id}
            className="basic-single"
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onKeyPress={props.onKeyPress}
            disabled={props.disabled}
            value={props.value}
          />
        </div>
      </div>
    </div>
  );
}
