import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Server URL
const resource = "/users";

export const USER_SESSION = "USER_SESSION";
export const LIST_USERS = "LIST_USERS";
export const LIST_USERSWITHOUTDISPRULE = "LIST_USERSWITHOUTDISPRULE";
export const LIST_USERSBYVENDINGMACHINE = "LIST_USERSBYVENDINGMACHINE";
export const LIST_USERSWITHOUTCREDIT = "LIST_USERSWITHOUTCREDIT";

export const LIST_USER = "LIST_USER";
export const ERROR = "ERROR";

export function getUserSessionInformation(callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: USER_SESSION, payload: response.data });
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getUserById(userId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}`, {
        params: {
          userId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getClientUserById(userId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/client`, {
        params: {
          userId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getExecutiveUserById(userId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/executive`, {
        params: {
          userId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// Search similar SoUsers by identityCardNumber or username
export function getSoUsersBySoDependencyIdAndSoDependencyTypeIdAndIdentityCardNumberOrUsernamePaginatedByAttribute(
  soDependencyId,
  isProvisioner,
  identityCardNumber,
  username,
  page,
  pageSize,
  attribute,
  order,
  callback
) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          stockOwnerId: soDependencyId,
          isProvisioner,
          identitycardnumberlike: identityCardNumber,
          username,
          page,
          size: pageSize,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: LIST_USERS,
          payload: response.data,
        });
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// Get ScDependencyUsers to fill table
export function getUsersByScDependencyIdPaginatedByAttribute(
  scDependencyId,
  scDepartmentId,
  scUserProfileId,
  roleName,
  enrollmentstatus,
  page,
  pageSize,
  attribute,
  order,
  callback
) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId,
          scdepartmentid: scDepartmentId,
          scuserprofileid: scUserProfileId,
          rolename: roleName,
          enrollmentstatus,
          page,
          size: pageSize,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: LIST_USERS,
          payload: response.data,
        });
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
// Search similar ScUsers by identityCardNumber
export function getUsersByIdentityCardNumberAndScDependencyIdPaginatedByAttribute(
  identityCardNumber,
  scDependencyId,
  scDepartmentId,
  scUserProfileId,
  page,
  pageSize,
  attribute,
  order,
  callback
) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          identitycardnumberlike: identityCardNumber,
          scdependencyid: scDependencyId,
          scdepartmentid: scDepartmentId,
          scuserprofileid: scUserProfileId,
          page,
          size: pageSize,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: LIST_USERS,
          payload: response.data,
        });
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getUserByIdentityCardNumber(identityCardNumber, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/${identityCardNumber}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function insertClientUser(user, callback) {
  return () => {
    const data = JSON.stringify(user);
    axios({
      method: "post",
      url: `${host + port + path + version + resource}/client`,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function insertExecutiveUser(user, callback) {
  return () => {
    const data = JSON.stringify(user);
    axios({
      method: "post",
      url: `${host + port + path + version + resource}/executive`,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function updateClientUser(userToUpdate, callback) {
  return () => {
    const data = JSON.stringify(userToUpdate);
    axios({
      method: "put",
      url: `${host + port + path + version + resource}/client`,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function updateExecutiveUser(userToUpdate, callback) {
  return () => {
    const data = JSON.stringify(userToUpdate);
    axios({
      method: "put",
      url: `${host + port + path + version + resource}/executive`,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function disableUser(userId, callback) {
  return () => {
    axios({
      method: "delete",
      url: host + port + path + version + resource,
      params: {
        userId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function listEnrolledUsersByScDependencyId(scDependencyId, scDepartmentId, scUserProfileId, enrollmentStatus, identityCardNumber, page, size, attribute, order, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId != null ? scDependencyId : "",
          scdepartmentid: scDepartmentId != null ? scDepartmentId : "",
          scuserprofileid: scUserProfileId != null ? scUserProfileId : "",
          enrollmentstatus: enrollmentStatus,
          identitycardnumber: identityCardNumber,
          page,
          size,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function listUserWithoutDispensationRule(scDependencyId, scDepartmentId, scUserProfileId, callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId,
          scdepartmentid: scDepartmentId,
          scuserprofileid: scUserProfileId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: LIST_USERSWITHOUTDISPRULE, payload: response.data });
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getUsersByVendingMachineId(vendingMachineId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingMachineId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// Get Users from scDependency that ara assigned or not to a Vm
export function getVmScDependencyUsersByIsAssigned(vendingMachineId, scDependencyId, isAssignedToVm, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingMachineId,
          scDependencyId,
          isAssignedToVm,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// Get Users from soDependency that ara assigned or not to a Vm
export function getVmSoDependencyUsersByIsAssigned(vendingMachineId, soDependencyId, isAssignedToVm, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingMachineId,
          soDependencyId,
          isAssignedToVm,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// Get the Users of a So dependency that are assigned to one or more Vms
export function getVendingMachineUsersBySoDependencyId(soDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          soDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// Get the Users of a Sc dependency that are assigned to one or more Vms
export function getVendingMachineUsersByScDependencyId(scDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// Get SoDependencyUsers to fill table
export function getUsersBySoDependencyIdPaginatedByAttribute(soDependencyId, page, pageSize, attribute, order, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          sodependencyid: soDependencyId,
          page,
          size: pageSize,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// Bash import users method
export function bashImportUsers(soDependencyId, scDependencyId, updateFlag, usersData, drOptions, reenableUserOption, callback) {
  return () => {
    axios({
      method: "post",
      url: host + port + path + version + resource,
      data: usersData,
      params: {
        sodependencyid: soDependencyId,
        scdependencyid: scDependencyId,
        updateflag: updateFlag,
        dispensationruleoption: drOptions,
        reenableuseroption: reenableUserOption,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// EXport direct client users method
export function exportDirectUserClientReport(
  soDependencyId,
  scDependencyId,
  scDepartmentId,
  scUserProfileId,
  enrollmentStatus,
  identityCardNumber,
  attribute,
  order,
  fileType,
  columnHeaders,
  callback
) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          sodependencyid: soDependencyId,
          scdependencyid: scDependencyId,
          scdepartmentid: scDepartmentId,
          scuserprofileid: scUserProfileId,
          enrollmentstatus: enrollmentStatus,
          identitycardnumber: identityCardNumber,
          attribute,
          order,
          filetype: fileType,
          columnheaders: encodeURIComponent(columnHeaders),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function switchProfile(dependency) {
  return (dispatch) => {
    dispatch({ type: "SWITCH_DEPENDENCY", payload: dependency });
  };
}

export function listUsersWithoutCredit(scDependencyId, scDepartmentId, scUserProfileId, identityCardNumber, callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId,
          scdepartmentid: scDepartmentId,
          scuserprofileid: scUserProfileId,
          identitycardnumber: identityCardNumber,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: LIST_USERSWITHOUTCREDIT, payload: response.data });
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function listUsersWithoutLockerLoanException(scDependencyId, scDepartmentId, scUserProfileId, identityCardNumber, callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId,
          departmentid: scDepartmentId,
          userprofileid: scUserProfileId,
          identitycardnumber: identityCardNumber,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: LIST_USERSWITHOUTCREDIT, payload: response.data });
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
