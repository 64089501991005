import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Checkbox, Icon } from "antd";
import TextWithInfoTooltip from '../GenericComponents/textWithInfoTooltip';

// -----Actions-------
import { updateVmBillAssignation} from "../../actions";

// Language localization
import Strings from "../../systemVariables/languageStrings";

class EditCashAuditModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      escrow: null,
      recycle: null,
      highSecurity: null,
      isEnabled: null,
      isVisible : false,
    };
  }

  editConfig() {
    const{data} = this.props;
    if (data.id) {
      this.setState({ isVisible: true });
    } else {
      Modal.info({
        title: Strings.generalResponses.invalidParameters,
      });
    }
  }

   onChangeCheckbox(event, dataIndex) {
     
     if (event.target.checked === true) {
       if (dataIndex === "escrow") {
         this.setState({ escrow: 1 });
       }
       if (dataIndex === "recycle") {
        this.setState({ recycle: 1 });
      }
       if (dataIndex === "highSecurity") {
         this.setState({ highSecurity: 1 });
       }
       if (dataIndex === "isEnabled") {
         this.setState({ isEnabled: 1 });
       }
     } else {
       if (dataIndex === "escrow") {
         this.setState({ escrow: 0 });
       }
       if (dataIndex === "recycle") {
        this.setState({ recycle: 0 });
      }
       if (dataIndex === "highSecurity") {
         this.setState({ highSecurity: 0 });
       }
       if (dataIndex === "isEnabled") {
         this.setState({ isEnabled: 0 });
       }
     }
    
  }

  handleOk = () => {
    const {data, updateVmBillAssignation, updatePage} = this.props;
    const { escrow, recycle, highSecurity, isEnabled, isVisible} = this.state;

    this.setState({ isLoading: true });

    if (isVisible) {
      const vmBillToEditDto = {
       id : data.id,
       escrow: escrow != null ? escrow : data.escrow,
       recycle: recycle != null ? recycle : data.recycle,
       highSecurity: highSecurity != null ? highSecurity : data.highSecurity,
       isEnabled: isEnabled != null ? isEnabled : data.isEnabled,
      };
      
      updateVmBillAssignation(vmBillToEditDto, response => {
                if (!response.data && String(response).includes("Error:")) { // Connection error
                    Modal.error({
                        title: Strings.generalResponses.failedTransaction,
                        content: Strings.generalResponses.saveError
                    })
                } else if (response.data && response.data.status === "SUCCESS") { // Successfull entity save
                    Modal.success({
                        title: Strings.generalResponses.successfulTransaction,
                        content: Strings.generalResponses.saveSuccess,
                         onOk: updatePage(),
                    })
                } else {
                    Modal.error({
                        title: Strings.generalResponses.failedTransaction,
                        content: Strings.generalResponses.saveError
                    })
                }
                this.setState({isLoading: false, isVisible: false})
            }) 
    } 
  };

  handleCancel = () => {
    const {updatePage} = this.props;

    this.setState({isVisible: false})

    updatePage();
  };

  render() {
    const {data} = this.props;
    const {isLoading, isVisible} = this.state;

    return (
      <div>
      <Icon type="edit" className="addEditRemoveButton" onClick={(e) => this.editConfig(e)} />
    
      <Modal
        title={Strings.machine.configurationParameters}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        okButtonProps={{ loading: isLoading }}
        width="20%"
        visible={isVisible}
        centered
      >
        <div>
          <div className="row">
              <div className="col-md-8">
                <TextWithInfoTooltip name={Strings.vmcashvaulttype.escrow} tooltip={Strings.vmcashvaulttype.escrowTooltip}/>
              </div>
              <div className="alignCalendars col-md-4">
                <Checkbox defaultChecked={data ? data.escrow : null} onChange={(e) => this.onChangeCheckbox(e, 'escrow')} />
              </div>
          </div>
          <br />

          <div className="row">
              <div className="col-md-8">
                <TextWithInfoTooltip name={Strings.vmcashvaulttype.recycler} tooltip={Strings.vmcashvaulttype.recycleTooltip}/>
              </div>
              <div className="alignCalendars col-md-4">
                <Checkbox defaultChecked={data ? data.recycle : null} onChange={(e) => this.onChangeCheckbox(e, 'recycle')} />
              </div>
          </div>
          <br />

          <div className="row">
              <div className="col-md-8">
                <TextWithInfoTooltip name={Strings.vmcashvaulttype.highSecurity} tooltip={Strings.vmcashvaulttype.highSecurityTooltip}/>
              </div>
              <div className="alignCalendars col-md-4">
                <Checkbox defaultChecked={data ? data.highSecurity : null}  onChange={(e) => this.onChangeCheckbox(e, 'highSecurity')} />
              </div>
          </div>
          <br />

          <div className="row">
              <div className="col-md-8">
                <TextWithInfoTooltip name={Strings.generalTerms.enabled} tooltip={Strings.vmcashvaulttype.enabledTooltip}/>
              </div>
              <div className="alignCalendars col-md-4">
                <Checkbox defaultChecked={data ? data.isEnabled : null} onChange={(e) => this.onChangeCheckbox(e, 'isEnabled')} />
              </div>
          </div>
        </div>
      </Modal>
    </div>

    );
  }
}

export default connect(null, {
  updateVmBillAssignation
})(EditCashAuditModal);
