// Dependencies
import { Button, Divider, Icon, Input, Modal, Pagination, Popconfirm, Table } from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { disableScProduct, exportScProductInformationReport, getAllSoProductTypes, listScProductInformationByScDependencyAndCodeOrName } from "../../../actions/index";

// Components
import { maxRegistersPerReport } from "../../../systemVariables/serverInformation";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import { AddButton, ExportButton } from "../../GenericComponents/buttons/index";
import ExportReportsModal from "../../GenericComponents/exportReportModal";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";
import { SelectGeneral } from "../../GenericComponents/selectComponent/selectGeneral";
import SoScVmDependencySelector from "../../GenericComponents/selectComponent/SoScVmDependencySelector";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";
import Titles from "../../GenericComponents/titles";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { locateSoProductTypeName } from "../../../Utils/LanguageLocalization/soProductTypeLocalization";

const { Column, ColumnGroup } = Table;

class ScProductInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soDependencyId: 0,
      soDependencyName: null,
      soCountryName: null,
      scDependencyId: null,
      scDependencyName: null,
      scCountryName: null,
      scCurrencyCodeName: null,
      scProductInfo: null,
      scProductCount: 0,
      addButtonDisabled: true,
      loadingInfo: false,
      soProductName: "",
      scProductName: "",
      erpCode: "",
      scCode: "",
      page: 1,
      pageSize: 10,
      attribute: "scProductInformationId",
      order: "ASC",
      soProdTypes: [],
      soProdTypeId: -1,
      soProdTypeDisabled: true,
      // Export Report Modal
      isExportModalVisible: false,
      isExceedModalVisible: false,
      exportLoading: false,
      searchText: "",
      scDependencyHandleChangeStatus: false,
    };
  }

  componentDidMount() {
    const { soProductName, scProductName, erpCode, scCode, soProdTypeId, page, pageSize, attribute, order } = this.state;
    const { soScSelectionProps, listScProductInformationByScDependencyAndCodeOrName, getAllSoProductTypes, userDependency } = this.props;

    if (userDependency.dependencyType == "so") {
      if (soScSelectionProps.so) {
        if (soScSelectionProps.so !== -1) {
          this.setState({
            soDependencyId: soScSelectionProps.so.id,
            soDependencyName: soScSelectionProps.so.label,
            soCountryName: soScSelectionProps.so.countryName,
          });
        }
      } else {
        this.setState({
          soDependencyId: userDependency.dependencyId,
          soDependencyName: userDependency.dependencyName,
          soCountryName: userDependency.stockOwnerCountryName,
        });
      }
      // ScDependency user
      if (soScSelectionProps.sc != null) {
        this.setState({
          scDependencyId: soScSelectionProps.sc.id,
          scDependencyName: soScSelectionProps.sc.label,
          scCountryName: soScSelectionProps.sc.countryName,
          scCurrencyCodeName: soScSelectionProps.sc.scCurrencyCodeName,
          soProdTypeDisabled: false,
          addButtonDisabled: false,
          loadingInfo: true,
        });

        if (soScSelectionProps.sc.id !== -1) {
          listScProductInformationByScDependencyAndCodeOrName(
            soScSelectionProps.sc.id,
            soProductName,
            scProductName,
            erpCode,
            scCode,
            soProdTypeId,
            page,
            pageSize,
            attribute,
            order,
            (response) => {
              this.setState({
                scProductInfo: response.data.data.content,
                scProductCount: response.data.data.totalElements,
                scDependencyId: soScSelectionProps.sc.id,
                scDependencyName: soScSelectionProps.sc.label,
                scCountryName: soScSelectionProps.sc.countryName,
                scCurrencyCodeName: soScSelectionProps.sc.currencyCodeName,
                addButtonDisabled: false,
                loadingInfo: false,
                soProdTypeId: -1,
              });
            }
          );
        }
      }
    }

    if (userDependency.dependencyType == "sc") {
      this.setState({
        addButtonDisabled: false,
        loadingInfo: false,
        soDependencyId: userDependency.stockOwnerParentId,
        soDependencyName: userDependency.stockOwnerParentName,
        scDependencyId: userDependency.dependencyId,
        scDependencyName: userDependency.dependencyName,
        soProdTypeDisabled: false,
      });

      listScProductInformationByScDependencyAndCodeOrName(
        userDependency.dependencyId,
        soProductName,
        scProductName,
        erpCode,
        scCode,
        soProdTypeId,
        page,
        pageSize,
        attribute,
        order,
        (response) => {
          this.setState({
            scProductInfo: response.data.data.content,
            scProductCount: response.data.data.totalElements,
            addButtonDisabled: false,
            loadingInfo: false,
            soProdTypeId: -1,
          });
        }
      );
    }

    getAllSoProductTypes((response) => {
      const soProdTypes = response.data.data.map((item) => ({
        value: item.id,
        label: locateSoProductTypeName(item.name),
      }));
      soProdTypes.unshift({
        value: -1,
        label: Strings.generalTerms.all,
      });
      this.setState({ soProdTypes });
    });
  }

  // Events
  soDependencyHandleChange = (event) => {
    const { value: soDependencyId, label: soDependencyName, countryName: soCountryName } = event;
    this.setState({
      soDependencyId,
      soDependencyName,
      soCountryName,
      scDependencyId: null,
      scDependencyName: null,
      scCountryName: null,
      scCurrencyCodeName: null,
      soProdTypeDisabled: true,
      scProductInfo: [],
      soProdTypeId: -1,
      page: 1,
      scProductCount: 0,
    });
  };

  scDependencyHandleChange = (event) => {
    const { value: scDependencyId, label: scDependencyName, countryName: scCountryName, currencyCodeName: scCurrencyCodeName } = event;
    const { scDependencyHandleChangeStatus } = this.state;
    this.setState({
      scDependencyId,
      scDependencyName,
      scCountryName,
      scCurrencyCodeName,
      loadingInfo: true,
      soProdTypeDisabled: false,
      soProdTypeId: -1,
      searchText: "",
      erpCode: "",
      scCode: "",
      soProductName: "",
      scProductName: "",
      scDependencyHandleChangeStatus: !scDependencyHandleChangeStatus,
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { scDependencyId, scDependencyHandleChangeStatus } = this.state;
    const { listScProductInformationByScDependencyAndCodeOrName } = this.props;
    const { soProductName, scProductName, erpCode, scCode, soProdTypeId, pageSize, attribute, order } = this.state;
    if (prevState.scDependencyHandleChangeStatus !== scDependencyHandleChangeStatus) {
      // Now fetch the new data here.
      await listScProductInformationByScDependencyAndCodeOrName(
        scDependencyId,
        soProductName,
        scProductName,
        erpCode,
        scCode,
        soProdTypeId,
        1,
        pageSize,
        attribute,
        order,
        (response) => {
          if (response.data !== undefined) {
            this.setState({
              scProductInfo: response.data.data.content,
              scProductCount: response.data.data.totalElements,
              addButtonDisabled: false,
              loadingInfo: false,
              page: 1,
            });
          } else {
            Modal.error({
              title: Strings.generalResponses.connectionError,
              content: Strings.generalResponses.connectionErrorTooltip,
            });
            this.setState({
              loadingInfo: false,
            });
          }
        }
      );
    }
  }

  soProdTypeHandleChange = (event) => {
    const { value } = event;
    const { listScProductInformationByScDependencyAndCodeOrName } = this.props;
    const { scDependencyId, soProductName, scProductName, erpCode, scCode, pageSize, attribute, order } = this.state;
    this.setState({
      page: 1,
    });
    if (scDependencyId !== -1) {
      listScProductInformationByScDependencyAndCodeOrName(scDependencyId, soProductName, scProductName, erpCode, scCode, value, 1, pageSize, attribute, order, (response) => {
        this.setState({
          scProductInfo: response.data.data.content,
          scProductCount: response.data.data.totalElements,
          addButtonDisabled: false,
          loadingInfo: false,
        });
      });
      this.setState({ soProdTypeId: value, loadingInfo: true });
    }
  };

  getColumnSearchProps = (dataIndex, filter, text, searchText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} ${text}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, filter, clearFilters)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text} />,
  });

  handleSearch = (selectedKeys, confirm, filter) => {
    const { listScProductInformationByScDependencyAndCodeOrName } = this.props;
    const { scDependencyId, soProdTypeId, page, pageSize, attribute, order } = this.state;
    this.setState({
      loadingInfo: true,
    });

    let soProductName;
    let scProductName;
    let erpCode;
    let scCode;

    if (filter === 1) {
      soProductName = "";
      scProductName = "";
      [erpCode] = selectedKeys;
      scCode = "";
      this.setState({
        soProductName: "",
        scProductName: "",
        erpCode: selectedKeys[0],
        scCode: "",
      });
    }
    if (filter === 2) {
      [soProductName] = selectedKeys;
      scProductName = "";
      erpCode = "";
      scCode = "";
      this.setState({
        soProductName: selectedKeys[0],
        scProductName: "",
        erpCode: "",
        scCode: "",
      });
    }
    if (filter === 3) {
      soProductName = "";
      scProductName = "";
      erpCode = "";
      [scCode] = selectedKeys;
      this.setState({
        soProductName: "",
        scProductName: "",
        erpCode: "",
        scCode: selectedKeys[0],
      });
    }
    if (filter === 4) {
      soProductName = "";
      [scProductName] = selectedKeys;
      erpCode = "";
      scCode = "";
      this.setState({
        soProductName: "",
        scProductName: selectedKeys[0],
        erpCode: "",
        scCode: "",
      });
    }

    this.forceUpdate();

    listScProductInformationByScDependencyAndCodeOrName(
      scDependencyId,
      soProductName,
      scProductName,
      erpCode,
      scCode,
      soProdTypeId,
      page,
      pageSize,
      attribute,
      order,
      (response) => {
        if (response.data !== undefined) {
          this.setState({
            scProductInfo: response.data.data.content,
            scProductCount: response.data.data.totalElements,
            addButtonDisabled: false,
            loadingInfo: false,
          });
        } else {
          Modal.error({
            title: Strings.generalResponses.connectionError,
            content: Strings.generalResponses.connectionErrorTooltip,
          });
          this.setState({
            loadingInfo: false,
          });
        }
      }
    );
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    const { listScProductInformationByScDependencyAndCodeOrName } = this.props;
    const { scDependencyId, page, pageSize, attribute, order, soProdTypeId } = this.state;
    listScProductInformationByScDependencyAndCodeOrName(scDependencyId, "", "", "", "", soProdTypeId, page, pageSize, attribute, order, (response) => {
      this.setState({
        scProductInfo: response.data.data.content,
        scProductCount: response.data.data.totalElements,
        addButtonDisabled: false,
        loadingInfo: false,
      });
    });
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleButtonConfirm = (event, row) => {
    const { disableScProduct, listScProductInformationByScDependencyAndCodeOrName } = this.props;
    const { scDependencyId, soProductName, scProductName, erpCode, scCode, soProdTypeId, page, pageSize, attribute, order } = this.state;

    disableScProduct(row.scProductInformationId, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 0) {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
        });
        listScProductInformationByScDependencyAndCodeOrName(
          scDependencyId,
          soProductName,
          scProductName,
          erpCode,
          scCode,
          soProdTypeId,
          page,
          pageSize,
          attribute,
          order,
          (response) => {
            this.setState({
              scProductInfo: response.data.data.content,
              scProductCount: response.data.data.totalElements,
              addButtonDisabled: false,
              loadingInfo: false,
            });
          }
        );
      } else if (response.data.code === 4020) {
        const machines = [];
        response.data.data.forEach((vm) => {
          machines.push(<li>{vm}</li>);
        });

        Modal.warning({
          title: Strings.generalResponses.failedTransaction,
          content: (
            <>
              <p>{Strings.generalResponses.deleteingScProdInfoVmAssigned}</p>
              <p>{Strings.machine.vendingMachine}:</p>
              <ul>{machines}</ul>
            </>
          ),
        });
      } else {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      }
    });
  };

  showPagination = (page, pageSize) => {
    const { listScProductInformationByScDependencyAndCodeOrName } = this.props;
    const { scDependencyId, soProductName, soProdTypeId, scProductName, erpCode, scCode, attribute, order } = this.state;
    this.setState({
      loadingInfo: true,
      page,
      pageSize,
    });
    listScProductInformationByScDependencyAndCodeOrName(
      scDependencyId,
      soProductName,
      scProductName,
      erpCode,
      scCode,
      soProdTypeId,
      page,
      pageSize,
      attribute,
      order,
      (response) => {
        if (response.data !== undefined) {
          this.setState({
            scProductInfo: response.data.data.content,
            scProductCount: response.data.data.totalElements,
            loadingInfo: false,
          });
        } else {
          Modal.error({
            title: Strings.generalResponses.connectionError,
            content: Strings.generalResponses.connectionErrorTooltip,
          });
          this.setState({
            loadingInfo: false,
          });
        }
      }
    );
  };

  // Export methods
  showExportReportsModal = () => {
    const { scProductCount } = this.state;
    if (scProductCount >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible, isExceedModalVisible) => {
    this.setState({
      isExportModalVisible,
      isExceedModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    const { exportScProductInformationReport } = this.props;
    const { scDependencyId, soProductName, scProductName, erpCode, scCode, order, exportLoading } = this.state;
    if (!exportLoading) {
      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
      const columnHeaders = [
        Strings.product.erpCode,
        Strings.product.soProduct,
        Strings.generalTerms.description,
        Strings.product.soProductType,
        Strings.product.scProductCode,
        Strings.product.scProductInfo,
        Strings.product.soPreTaxPrice,
        Strings.product.soTaxValue,
        Strings.product.price,
        Strings.product.currency,
        Strings.product.scPreTaxPrice,
        Strings.product.scTaxValue,
        Strings.product.price,
        Strings.product.currency,
        Strings.product.minAgeRequired,
      ];

      // Start loading animation on Export button
      this.setState({ exportLoading: true });

      // Consume report export method
      exportScProductInformationReport(scDependencyId, soProductName, scProductName, erpCode, scCode, "id", order, fileType, columnHeaders, (response) => {
        if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.reportGenerationError,
          });
          this.setState({
            exportLoading: false,
          });
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", `${Strings.product.scProductInfoReport}.csv`);
          document.body.appendChild(link);
          link.click();
          this.setState({
            exportLoading: false,
          });
        }
      });
    }
  };

  calculatePrice = (price, taxValue) => {
    const value = price;
    const calculatedPrice = value + value * (taxValue / 100);
    const decimalPart = calculatedPrice - Math.trunc(calculatedPrice);
    return decimalPart == 0 ? calculatedPrice.toFixed(0) : calculatedPrice.toFixed(2);
  };

  renderTable = (data, scProductInfo, loadingInfo, searchText, soDependencyId, soDependencyName, scDependencyId, scDependencyName, scCountryName, scCurrencyCodeName) => {
    return (
      <Table dataSource={scProductInfo} bordered loading={loadingInfo} pagination={false} size="middle" style={{ width: "100%" }} scroll={{ x: "auto" }} rowKey="erpProductCode">
        <ColumnGroup title={Strings.product.soProduct} key="soProductId">
          <Column
            key={"erpProductCode"}
            title={<TextWithInfoTooltip name={Strings.product.erpCode} tooltip={Strings.product.erpCodeTooltip} />}
            dataIndex="erpProductCode"
            align="center"
            {...this.getColumnSearchProps("erpProductCode", 1, Strings.product.erpCode, searchText)}
          />
          <Column
            key={"soProductName"}
            title={<TextWithInfoTooltip name={Strings.generalTerms.name} tooltip={Strings.product.soProductNameTooltip} />}
            dataIndex="soProductName"
            align="center"
            {...this.getColumnSearchProps("soProductName", 2, Strings.product.product, searchText)}
          />
          <Column
            key={"soProductTypeId"}
            title={<TextWithInfoTooltip name={Strings.product.soProductType} tooltip={Strings.product.soProductTypeTooltip1} />}
            dataIndex="soProductTypeId"
            align="center"
            render={(soProdType) => <span>{locateSoProductTypeName(soProdType)}</span>}
          />
        </ColumnGroup>
        <ColumnGroup title={Strings.product.scProductInfo} key="scProductInformationId">
          <Column
            key={"scProductCode"}
            title={<TextWithInfoTooltip name={Strings.product.scProductCode} tooltip={Strings.product.scProductCodeToltip} />}
            align="center"
            {...this.getColumnSearchProps("scProductCode", 3, Strings.product.scProductCode, searchText)}
            render={(row) => (row.scProductCode ? <span>{row.scProductCode} </span> : <span>--</span>)}
          />
          <Column
            key={"scProductName"}
            title={<TextWithInfoTooltip name={Strings.generalTerms.name} tooltip={Strings.product.scProductNameTooltip} />}
            align="center"
            {...this.getColumnSearchProps("scProductName", 4, Strings.product.product, searchText)}
            render={(row) => (row.scProductName ? <span>{row.scProductName} </span> : <span>--</span>)}
          />
          <Column
            align="center"
            key={"scPreTaxPrice"}
            title={<TextWithInfoTooltip name={Strings.product.scPreTaxPrice} tooltip={Strings.product.scPreTaxPriceTooltip} />}
            render={(row) =>
              row.scPreTaxPrice ? (
                <span>
                  {(row.scCurrencySymbol ? row.scCurrencySymbol : row.soCurrencySymbol) +
                    `${row.scPreTaxPrice ? row.scPreTaxPrice : row.soPreTaxPrice} ` +
                    (row.scCurrencyAlphaCode ? row.scCurrencyAlphaCode : row.soCurrencyAlphaCode)}
                </span>
              ) : (
                "--"
              )
            }
          />
          <Column
            key={"taxValue"}
            align="center"
            title={<TextWithInfoTooltip name={Strings.product.taxValue} tooltip={Strings.product.scTaxValueTooltip} />}
            render={(row) => (row.scTaxValue != undefined ? <span>{`${row.scTaxValue}%`}</span> : "--")}
          />
          <Column
            key={"scPrice"}
            align="center"
            title={<TextWithInfoTooltip name={Strings.product.price} tooltip={Strings.product.priceTooltip} />}
            render={(row) =>
              row.scPreTaxPrice && row.scTaxValue != undefined ? (
                <span>
                  {(row.scCurrencySymbol ? row.scCurrencySymbol : row.soCurrencySymbol) +
                    `${this.calculatePrice(row.scPreTaxPrice, row.scTaxValue)} ` +
                    (row.scCurrencyAlphaCode ? row.scCurrencyAlphaCode : row.soCurrencyAlphaCode)}
                </span>
              ) : (
                "--"
              )
            }
          />
          <Column
            key={"minAgeRequired"}
            title={<TextWithInfoTooltip name={Strings.product.minAgeRequired} tooltip={Strings.product.minAgeRequiredTooltip} />}
            align="center"
            width="10%"
            render={(row) => (row.scMinAgeRequired ? <span>{`${row.scMinAgeRequired} ${Strings.timeUnits.years}`}</span> : "--")}
          />

          {ValidatePermissionForComponentPart("PRIVILEGE SC PRODUCT INFORMATION PUT", data) && (
            <Column
              key={"edit"}
              title={Strings.generalTerms.edit}
              width="5%"
              align="center"
              render={(row) => (
                <Link
                  to={{
                    pathname: "/editScProductInformation",
                    props: { scProductToEdit: row, soDependency: { soDependencyId, soDependencyName }, scDependencyId, scDependencyName, scCountryName, scCurrencyCodeName },
                  }}
                >
                  <Icon type="edit" theme="twoTone" className="addEditRemoveButton" />
                </Link>
              )}
            />
          )}

          {ValidatePermissionForComponentPart("PRIVILEGE SC PRODUCT INFORMATION DELETE", data) && (
            <Column
              key={"remove"}
              title={Strings.generalTerms.remove}
              width="5%"
              align="center"
              render={(row) => (
                <Popconfirm
                  title={Strings.generalResponses.confirmDeleteMessage}
                  onConfirm={(event) => this.handleButtonConfirm(event, row, "delete")}
                  okText={Strings.generalTerms.yes}
                  cancelText={Strings.generalTerms.no}
                >
                  <Icon type="close-circle" theme="twoTone" className="addEditRemoveButton" style={{ cursor: "pointer", fontSize: 16 }} />
                </Popconfirm>
              )}
            />
          )}
        </ColumnGroup>
      </Table>
    );
  };

  // Information to render
  render() {
    const {
      scProductInfo,
      loadingInfo,
      searchText,
      soDependencyId,
      soDependencyName,
      soCountryName,
      scDependencyId,
      scDependencyName,
      scCountryName,
      scCurrencyCodeName,
      isExportModalVisible,
      isExceedModalVisible,
      soProdTypes,
      soProdTypeId,
      soProdTypeDisabled,
      scProductCount,
      addButtonDisabled,
      page,
      exportLoading,
    } = this.state;
    const {
      permissions: { data },
    } = this.props;

    return (
      <div className="content-container">
        <ExportReportsModal
          isExportVisible={isExportModalVisible}
          isExceedVisible={isExceedModalVisible}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
        />

        <div className="row">
          <Titles title={Strings.product.scProductInfo} tooltip={Strings.product.scProductsInfoTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <SoScVmDependencySelector hasSc onSoDependencyChange={this.soDependencyHandleChange} onScDependencyChange={this.scDependencyHandleChange} />
          </div>
          <div className="vertSpace col-md-12">
            <div className="col-md-6">
              <SelectGeneral
                text={Strings.product.soProductType}
                tooltip={Strings.product.soProductTypeTooltip1}
                options={soProdTypes}
                onChange={(e) => this.soProdTypeHandleChange(e)}
                disabled={soProdTypeDisabled}
                defaultValue={soProdTypeId}
              />
            </div>
            <div className="col-xs-0 col-md-6" />
            <ValidatePermissionForComponent permission="PRIVILEGE SC PRODUCT INFORMATION POST" permissions={data}>
              <div className="col-md-6">
                <AddButton
                  link={addButtonDisabled ? "" : "/AssignScProductInformation"}
                  props={addButtonDisabled ? null : { soDependencyId, soDependencyName, soCountryName, scDependencyId, scDependencyName, scCountryName, scCurrencyCodeName }}
                  disabled={addButtonDisabled || scDependencyId === -1 || scDependencyId === null}
                />
              </div>
            </ValidatePermissionForComponent>
          </div>
        </div>

        <Divider orientation="left">
          <h3>{Strings.product.scProductInfo}</h3>
        </Divider>

        <div className="row">
          <div className="col-md-12">
            <QueryResponse
              dataSourceLength={scProductInfo === null ? 0 : scProductInfo.length}
              isLoading={loadingInfo}
              callback={this.renderTable(
                data,
                scProductInfo,
                loadingInfo,
                searchText,
                soDependencyId,
                soDependencyName,
                scDependencyId,
                scDependencyName,
                scCountryName,
                scCurrencyCodeName
              )}
            />
          </div>
        </div>

        {scProductCount > 0 ? (
          <>
            <div className="row">
              <div className="col">
                <div className="vertSpace col-lg-6" />
                <div className="vertSpace col-lg-6">
                  <Pagination
                    size="small"
                    total={scProductCount}
                    showSizeChanger
                    onChange={this.showPagination}
                    onShowSizeChange={this.showPagination}
                    hideOnSinglePage={false}
                    pageSizeOptions={["10", "25", "50", "100", "250"]}
                    showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total}  ${Strings.generalTerms.items}`}
                    current={page}
                    disabled={scProductCount === 0}
                  />
                </div>
              </div>
            </div>

            <ValidatePermissionForComponent permission="PRIVILEGE SC PRODUCT INFORMATION REPORT GET" permissions={data}>
              <div className="row">
                <div className="vertSpace col-12">
                  <div className="col-md-6" />
                  <div className="col-md-6">
                    <ExportButton isDisabled={scDependencyId === null || scDependencyId === -1} onClick={() => this.showExportReportsModal()} loading={exportLoading} />
                  </div>
                </div>
              </div>
            </ValidatePermissionForComponent>
          </>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listScProductInformationByScDependencyAndCodeOrName,
  disableScProduct,
  exportScProductInformationReport,
  getAllSoProductTypes,
})(ScProductInformation);
