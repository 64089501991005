import Strings from "../../systemVariables/languageStrings";

export function financeAuditingLocalization(name, value) {
  let strName;
  switch (name) {
    case "DEPOSIT":
      strName = Strings.generalTerms.deposit;
      break;
    case "WITHDRAWAL":
      strName = Strings.generalTerms.withdrawal;
      break;
    case "COIN TUBES":
      strName = Strings.vmcashvaulttype.coinTubes;
      break;
    case "COIN BOX":
      strName = Strings.vmcashvaulttype.coinBox;
      break;
    case "BILL ESCROW":
      strName = Strings.vmcashvaulttype.billEscrow;
      break;
    case "BILL BOX":
      strName = Strings.vmcashvaulttype.billBox;
      break;
    case "CREDIT":
      strName = Strings.vmcashvaulttype.credit;
      break;
    case "CASHLESS":
      strName = Strings.vmcashvaulttype.cashless;
      break;
    default:
      break;
  }
  return strName;
}
