// Dependencies
import { Button, Checkbox, Divider, Icon, Input, Modal, Table } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import DispensationRuleDetailsModal from "../../DispensationsRules/dispensationRuleDetailsModal";

// Components
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import Titles from "../../GenericComponents/titles.js";

// Actions
import {
  getScDispensationRuleById,
  insertDispensationRulesByUser,
  insertRuleByUsers,
  listDispensationRuleInfoByScDispensationRuleId,
  listDispensationRulesByScId,
  listProductAssignmentByDispensationRuleId,
  listScDepartmentByScDependencyId,
  listScDependenciesBySoDependencyId,
  listScUserProfilesByScDependencyId,
  listSoDependencyBySoDependencyType0,
  listUserWithoutDispensationRule,
} from "../../../actions";

// Localization
import Strings from "../../../systemVariables/languageStrings";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";

const { Column } = Table;
let usersToAsignDispRuleAddTemporaly = [];
let usersDispRule = [];
let usersArray = [];

// MoreInfoDetails
let productAssignmentsWithProductGroupId = [];
let productAssignmentsWithoutProductGroupId = [];
let vmProductGroupDispensationRule = [];

function UserDispRuleObj(userid, userIdentityCardNumber, userFullName, dispRuleId, dispRule) {
  this.userId = userid;
  this.userIdentityCardNumber = userIdentityCardNumber;
  this.userName = userFullName;
  this.dispRuleId = dispRuleId;
  this.dispRule = dispRule;
}

const dateFormat = "DD/MM/YYYY hh:mm:ss a";

class AssignDispensationRulesToUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scDependencies: [],
      usersWithoutDispensationRule: null,
      scDepartments: [],
      scDepartmentSelected: null,
      scUserProfiles: [],
      scUserProfileSelected: null,
      scDispRules: [],
      dispRuleInfo: [],
      startDate: null,
      endDate: null,
      loadingAdd: false,
      usersChecked: false,
      isChecked: false,
      searchTerm: "",
      disabledSelectors: true,
      disableCheckbox: true,
      disableAssignButton: true,
      scDependencyId: this.props.location.props ? this.props.location.props.scDependencyId : null, // variable to filter the users
      scDependencyName: this.props.location.props ? this.props.location.props.scDependencyName : null, // variable to filter the users
      usersArray: null,
      infoToAdd: null,
      // Flag used to redirect to /listClientusers if any scDependency was selected
      redirectBackToListDispRulesByUsers: false,

      // Details Modal
      detailsModalIsVisible: false,
      scDispRuleRowSelected: null,
      soProductConfigured: [],
      disabledDetails: true,
    };
    this.onSearchUserEvent = this.onSearchUserEvent.bind(this);
    this.handleOkNoScDependencyWarning = this.handleOkNoScDependencyWarning.bind(this);
  }

  componentDidMount() {
    if (!this.state.scDependencyId) {
      // Shows No Product Modal Warning
      return <div> {this.noScDependencyWarning()} </div>;
    }

    this.props.listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
    if (this.state.scDependencyId != null) {
      this.props.listUserWithoutDispensationRule(this.state.scDependencyId, "", "", (response) => {
        this.setState({
          usersWithoutDispensationRule: response.data.data,
        });
      });
      this.props.listDispensationRulesByScId(this.state.scDependencyId, (response) => {
        this.setState({
          scDispRules: response.data.data.map((item) => ({
            value: item.dispensationRuleId,
            label: item.dispensationRuleName,
          })),
        });
      });
      this.props.listScDepartmentByScDependencyId(this.state.scDependencyId, (response) => {
        this.setState({
          scDepartments: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      this.props.listScUserProfilesByScDependencyId(this.state.scDependencyId, (response) => {
        this.setState({
          scUserProfiles: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }
  }

  // Events
  scDepartmentInputEvent(event) {
    usersToAsignDispRuleAddTemporaly = [];

    const { value } = event;
    this.setState({
      scDepartmentSelected: value,
      scUserProfileSelected: null,
    });

    this.props.listUserWithoutDispensationRule("", value, "", (response) => {
      this.setState({
        usersWithoutDispensationRule: response.data.data,
      });
    });
  }

  scUserProfileInputEvent(event) {
    usersToAsignDispRuleAddTemporaly = [];
    const { value } = event;
    this.setState({
      scUserProfileSelected: value,
      scDepartmentSelected: null,
    });

    this.props.listUserWithoutDispensationRule("", "", value, (response) => {
      this.setState({
        usersWithoutDispensationRule: response.data.data,
      });
    });
  }

  onSearchUserEvent(event) {
    this.setState({ users: this.props.users.data });
    this.setState({ searchTerm: event.target.value });
  }

  scDispRuleEvent(dispRuleSelected, e) {
    this.setState({
      disabledDetails: false,
      disableCheckbox: false,
      disabledSelectors: false,
    });

    this.props.listDispensationRuleInfoByScDispensationRuleId(dispRuleSelected.value, (response) => {
      this.setState({
        dispRuleInfo: response.data.data,
      });
    });
    this.setState({
      dispRuleId: dispRuleSelected.value,
    });
  }

  selectedUserEvent(e, user) {
    usersDispRule = [];
    usersArray = [];

    if (e.target.checked === true) {
      usersToAsignDispRuleAddTemporaly.push(user);
    } else {
      usersToAsignDispRuleAddTemporaly = usersToAsignDispRuleAddTemporaly.filter((item) => item.userId !== user.userId);
    }

    usersToAsignDispRuleAddTemporaly.length === 0
      ? this.setState({
          disableAssignButton: true,
        })
      : this.setState({
          disableAssignButton: false,
        });

    if (usersToAsignDispRuleAddTemporaly.length > 0) {
      for (let i = 0; i < usersToAsignDispRuleAddTemporaly.length; i++) {
        const userDispRuleObj = new UserDispRuleObj(
          usersToAsignDispRuleAddTemporaly[i].userId,
          usersToAsignDispRuleAddTemporaly[i].identityCardNumber,
          usersToAsignDispRuleAddTemporaly[i].fullName,
          this.state.dispRuleId
        );
        usersDispRule.push(userDispRuleObj);
        usersArray.push(usersToAsignDispRuleAddTemporaly[i].userId);
      }
    }
  }

  addDispRuleByUsersEvent = (e) => {
    this.setState({
      usersWithoutDispensationRule: [],
      loadingAdd: true,
      disableAssignButton: true,
      disableCheckbox: true,
    });

    /// ////////////////////////////////////////////// OLD VERSION //////////////////////////////////////////////////////////////////

    const { dispRuleId } = this.state;

    this.props.getScDispensationRuleById(dispRuleId, (response) => {
      const dateInfo = {};
      dateInfo.startDate = response.data.data.startDate;
      dateInfo.endDate = response.data.data.endDate ? response.data.data.endDate : null;

      this.setState({
        startDate: moment(dateInfo.startDate).format(),
        endDate: dateInfo.endDate ? moment(dateInfo.endDate).format() : null,
      });
    });

    const infoToAdd = { ...this.state.infoToAdd };
    infoToAdd.usersId = usersArray;
    infoToAdd.dispRuleId = dispRuleId;
    infoToAdd.startDate = this.state.startDate;
    infoToAdd.endDate = this.state.endDate ? this.state.endDate : null;
    this.setState({ infoToAdd });

    usersDispRule = [];
    usersToAsignDispRuleAddTemporaly = [];

    if (usersArray && dispRuleId) {
      this.props.insertDispensationRulesByUser(infoToAdd, (response, error) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          this.setState({
            disableCheckbox: false,
            infoToAdd: null,
            dispRuleId: null,
            disableAssignButton: true,
            dispRuleInfo: null,
            loadingAdd: false,
            isChecked: false,
          });
          usersToAsignDispRuleAddTemporaly = [];
          usersDispRule = [];
          usersArray = [];
          const startDateUtc = moment.utc().format();
          const startDateLocal = moment.utc(startDateUtc).local().format();
          this.setState({
            startDate: _.split(startDateLocal, "T")[0],
          });
          this.props.listUserWithoutDispensationRule(this.state.scDependencyId, "", "", (response) => {
            this.setState({
              usersWithoutDispensationRule: response.data.data,
            });
          });
        } else {
          // Other error
          this.setState({
            loadingAdd: false,
          });
        }
      });
    } else {
      // Other error
      this.setState({
        loadingAdd: false,
      });
    }

    //  NEW VERSION

    const dispenRuleId = this.state.dispRuleId;

    const infoToInsert = { ...this.state.infoToInsert };
    infoToInsert.usersId = usersArray;
    infoToInsert.dispRuleId = dispenRuleId;
    infoToInsert.startDate = this.state.startDate;
    infoToInsert.endDate = this.state.endDate ? this.state.endDate : null;
    this.setState({ infoToInsert });

    usersDispRule = [];
    usersToAsignDispRuleAddTemporaly = [];

    if (usersArray && dispenRuleId) {
      this.props.insertRuleByUsers(infoToInsert, (response, error) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
          });
          this.setState({
            disableCheckbox: false,
            infoToInsert: null,
            dispenRuleId: null,
            disableAssignButton: true,
            dispRuleInfo: null,
            loadingAdd: false,
            isChecked: false,
          });
          usersToAsignDispRuleAddTemporaly = [];
          usersDispRule = [];
          usersArray = [];

          this.props.listUserWithoutDispensationRule(this.state.scDependencyId, "", "", (response) => {
            this.setState({
              usersWithoutDispensationRule: response.data.data,
            });
          });
        } else {
          // Other error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        }
      });
    } else {
      // Other error
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: Strings.generalResponses.alreadyExistsError,
      });
      this.setState({
        loadingAdd: false,
      });
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[this.state.searchText]} autoEscape textToHighlight={text.toString()} />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  noScDependencyWarning() {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOkNoScDependencyWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  }

  handleOkNoScDependencyWarning() {
    this.setState({ redirectBackToListDispRulesByUsers: true });
  }

  moreInfoDetails = (e) => {
    if (this.state.dispRuleId) {
      // Query all productAssignment info by scDispensationRule identifier
      productAssignmentsWithProductGroupId = [];
      productAssignmentsWithoutProductGroupId = [];
      vmProductGroupDispensationRule = [];

      this.props.listProductAssignmentByDispensationRuleId(this.state.dispRuleId, (response) => {
        if (response.data.data.length > 0) {
          productAssignmentsWithProductGroupId = response.data.data.filter((item) => item.vmProductGroupId != null);
          productAssignmentsWithoutProductGroupId = response.data.data.filter((item) => item.vmProductGroupId == null);

          productAssignmentsWithoutProductGroupId.forEach((obj) => {
            if (obj.allowedAmount == null) {
              obj.frequencyValue = "--";
              obj.frequencyUnitId = "--";
              obj.frequencyUnitName = "--";
              obj.allowedAmount = Strings.generalTerms.ilimited;
            } else {
              obj.frequencyValue = obj.frequencyValue;
              obj.frequencyUnitId = obj.frequencyUnitId;
              obj.frequencyUnitName = obj.frequencyUnitName;
              obj.allowedAmount = obj.allowedAmount;
            }
          });

          productAssignmentsWithProductGroupId.forEach((obj) => {
            if (vmProductGroupDispensationRule.filter((item) => item.name === obj.vmProductGroupName).length === 0) {
              const vmProductGroutInfo = {};
              vmProductGroutInfo.name = obj.vmProductGroupName;
              vmProductGroutInfo.description = obj.vmProductGroupDescription;
              vmProductGroutInfo.allowedAmount = obj.allowedAmount == null ? Strings.generalTerms.ilimited : obj.allowedAmount;
              vmProductGroutInfo.frequencyValue = obj.allowedAmount == null ? "--" : obj.frequencyValue;
              vmProductGroutInfo.frequencyUnitName = obj.allowedAmount == null ? "--" : obj.frequencyUnitName;
              vmProductGroupDispensationRule = vmProductGroupDispensationRule.concat(vmProductGroutInfo);
            }
          });
        }

        if (productAssignmentsWithoutProductGroupId.length > 0 || productAssignmentsWithProductGroupId.length > 0) {
          this.props.getScDispensationRuleById(this.state.dispRuleId, (response) => {
            const scDispInfo = {};
            scDispInfo.id = response.data.data.id;
            scDispInfo.name = response.data.data.name;
            scDispInfo.description = response.data.data.description;
            scDispInfo.startDate = moment(response.data.data.startDate).format(dateFormat);
            scDispInfo.endDate = response.data.data.endDate ? moment(response.data.data.endDate).format(dateFormat) : "--";
            this.setState({
              detailsModalIsVisible: true,
              scDispensationRuleInfo: scDispInfo,
            });
          });
        }
      });
    }
  };

  setModalVisible = (isVisible) => {
    this.setState({ detailsModalIsVisible: isVisible });
    productAssignmentsWithProductGroupId = [];
    productAssignmentsWithoutProductGroupId = [];
  };

  // Information to render
  render() {
    if (this.state.redirectBackToListDispRulesByUsers) {
      // Redirects to SoProductSlotType from No Product Modal Warning
      return <Redirect to="/listDispensationRuleByUser" />;
    }
    return (
      <div className="content-container">
        <DispensationRuleDetailsModal
          isVisible={this.state.detailsModalIsVisible}
          setIsVisible={this.setModalVisible}
          scDispRuleRowSelected={this.state.scDispensationRuleInfo}
          scDependency={this.state.scDependencyName}
          productAssignmentsWithoutProductGroupId={productAssignmentsWithoutProductGroupId}
          productAssignmentsWithProductGroupId={productAssignmentsWithProductGroupId}
          vmProductGroupDispensationRule={vmProductGroupDispensationRule}
        />

        <div className="row">
          <Titles title={Strings.dispensationRuleByUser.addDispensationRuleByUser} tooltip={Strings.dispensationRule.dispensationByUserTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col">
            <Divider orientation="left">
              <h3>{Strings.scDependency.indirectClient} </h3>
            </Divider>
            <h3>{this.state.scDependencyName}</h3>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.dispensationRuleByUser.dispensationRuleToAssign} </h3>
            </Divider>
            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.dispensationRule.dispensationRule}
                options={this.state.scDispRules}
                defaultValue={this.state.dispRuleId}
                isReq
                onChange={(e) => this.scDispRuleEvent(e)}
              />
            </div>

            {this.state.dispRuleId ? (
              <div className="vertSpace col-md-1">
                <h5>{Strings.generalTerms.details} </h5>
                <Icon type="profile" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" onClick={(e) => this.moreInfoDetails(e)} />
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.users.userSelection}</h3>
            </Divider>
            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.scDepartment.department}
                tooltip={Strings.scDepartment.departmentTooltipp}
                options={this.state.scDepartments}
                onChange={(e) => this.scDepartmentInputEvent(e)}
                defaultValue={this.state.scDepartmentSelected}
                disabled={this.state.disabledSelectors}
              />
            </div>
            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.scUserProfile.userProfile}
                tooltip={Strings.scUserProfile.userProfileTooltip}
                options={this.state.scUserProfiles}
                onChange={(e) => this.scUserProfileInputEvent(e)}
                defaultValue={this.state.scUserProfileSelected}
                disabled={this.state.disabledSelectors}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Table
              className="smallTable"
              dataSource={this.state.usersWithoutDispensationRule}
              loading={this.state.loading}
              pagination={false}
              size="middle"
              scroll={{ x: "auto" }}
              rowKey="id"
            >
              <Column key="id" title={Strings.users.identityCardNumber} dataIndex="identityCardNumber" {...this.getColumnSearchProps("identityCardNumber")} filtered />
              <Column title={Strings.users.user} dataIndex="fullName" {...this.getColumnSearchProps("fullName")} filtered />
              <Column title={<TextWithInfoTooltip name={Strings.scDepartment.department} />} dataIndex="scDepartmentName" />
              <Column title={<TextWithInfoTooltip name={Strings.scUserProfile.userProfile} />} dataIndex="scUserProfileName" />
              <Column
                title={Strings.dispensationRuleByUser.selectUser}
                render={(row) => <Checkbox defaultChecked={false} value={row.checked} onChange={(e) => this.selectedUserEvent(e, row)} disabled={this.state.disableCheckbox} />}
              />
            </Table>
          </div>
          <br />
          {usersToAsignDispRuleAddTemporaly.length > 0 ? (
            <div>
              <h5 align="right">
                <b>{`${Strings.users.usersSelected}:`}</b> {` ${usersToAsignDispRuleAddTemporaly.length}`}
              </h5>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row justify-content-end">
          <div className="vertSpace col-6 col-md-3">
            <ReturnButton link="/listDispensationRuleByUser" />
          </div>
          <div className="vertSpace col-6 col-md-3">
            <SaveButton onClick={(e) => this.addDispRuleByUsersEvent(e)} isDisabled={this.state.disableAssignButton} loading={this.state.loadingAdd} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    scDependencies: state.scDependencies,
    scdepartments: state.scDepartments,
    scuserprofiles: state.scUserProfiles,
    users: state.users.userWithoutDispRuleObj,
    dispRules: state.scDispensationRule,

    products: state.soProduct.listSoProductObj,
    prodTaxType: state.productTaxType.productTaxTypeObj,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  listUserWithoutDispensationRule,
  listDispensationRulesByScId,
  listDispensationRuleInfoByScDispensationRuleId,
  insertDispensationRulesByUser,
  listProductAssignmentByDispensationRuleId,
  getScDispensationRuleById,
  insertRuleByUsers,
})(AssignDispensationRulesToUser);
