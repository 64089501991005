import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Server URL
const resource = "/poductassignmentbyrule";

// Redux variables
export const ERROR = "ERROR";

export function listProductAssignmentByDispensationRuleId(scDispensationRuleId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          dispensationrule_id: scDispensationRuleId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function disableProductAssigmentByRule(productAssigmentIds, callback) {
  return (dispatch) => {
    let data = JSON.stringify(productAssigmentIds);
    axios({
      method: "delete",
      url: host + port + path + version + resource,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
      });
  };
}

export function updateProductAssigmentByRule(productAssigment, callback) {
  return (dispatch) => {
    let data = JSON.stringify(productAssigment);
    axios({
      method: "put",
      url: host + port + path + version + resource,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
      });
  };
}
