import axios from "axios";
import { host, path, port, version } from "../systemVariables";

// Method path
const resource = "/firmwareversions";

export const LIST_FIRMWAREVERSIONS = "LIST_FIRMWAREVERSIONS";

export function listFirmwareVersions(callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getFirmwareVersionsByMachineGenerationId(machineGenerationId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          machineGenerationId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getFirmwareVersionsByMachineGenerationIdAndVersionString(machineGenerationId, versionString, page, size, attribute, order, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          machineGenerationId,
          versionString,
          page,
          size,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function addFirmwareVersion(firmwareVersion, callback) {
  return () => {
    const data = JSON.stringify(firmwareVersion);
    axios({
      method: "put",
      url: `${host + port + path + version + resource}/fwupload.confirm`,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function updateFirmwareVersion(firmwareVersionUpdateDTO, callback) {
  return () => {
    const data = JSON.stringify(firmwareVersionUpdateDTO);
    axios({
      method: "post",
      url: `${host + port + path + version + resource}`,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function disableFirmwareVersion(id, callback) {
  return () => {
    const data = JSON.stringify(id);
    axios({
      method: "delete",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}
