import React, { Component, Fragment } from "react";
import { Modal, Button, Icon, Tooltip, Alert, Typography, Divider } from "antd";

// Components
import { SelectGeneral } from "./selectComponent/selectGeneral";
import { SelectGeneralAux } from "./selectComponent/SelectGeneralAux.jsx";
import { GeneralCheckbox } from "./checkBox/GeneralCheckBox";

// Language localization
import Strings from "../../systemVariables/languageStrings";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";

const dateFormat = "dd-MM-yyyy";
const { Title } = Typography;

class ImportReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReqColHeaderNames: [],
      additionalColHeaderNames: [],
      validFile: false,
      validColumnHeader: true,
      reqColumnHeaders: [],
      fileColumnOptions: [],
      pairedColumnHeaders: [],
      selectedSelectId: "",
      validColPairing: true,
      successPairing: false,
      fileData: null,
      failedRegisters: [], // Registers with less fields than the number of required fields
      updateRegFlag: true,
      readyToUpload: false,
      uploadingData: false,
      mappedObjects: [],
      cancelDisabled: false,
      importFinished: false,
      failedImportRegisters: [], // Registers that failed after import (saved as Map (id,failureReason))
      failedImportRegisterIds: [], // Registers ids that failed after import
      omitedImportRegisters: [], // Registers that were omited after import
      // -----Entity import specifics-----
      importEntitySpecificsFlag: null,
      FlagAuxConstructor: false,
      // Client Users
      reenableUserInfoFlag: false,
      drOption: 0,
      KeyFailvmRol: [],
    };
    this.setState({
      FlagAuxConstructor: false,
    });
  }

  componentDidMount() {
    this.setState({
      FlagAuxConstructor: false,
    });
  }

  handleImport = () => {
    this.setState({
      uploadingData: true,
      cancelDisabled: true,
      readyToUpload: false,
      FlagAuxConstructor: false,
      KeyFailvmRol: [],
    });
    // Reset file input
    document.getElementById("fileInput").value = "";

    const { importEntitySpecificsFlag, mappedObjects, updateRegFlag, drOption, reenableUserInfoFlag } = this.state;
    const { onDataImport } = this.props;

    switch (importEntitySpecificsFlag) {
      case "clientUsers":
        onDataImport(mappedObjects, updateRegFlag, drOption, reenableUserInfoFlag);
        break;
      case "patientsInformation":
        onDataImport(mappedObjects)
      default:
    }
  };

  handleCancel = () => {
    const { cancelDisabled } = this.state;
    const { setVisible } = this.props;

    if (!cancelDisabled) {
      setVisible(false);
      this.setState({
        pairedColumnHeaders: [],
        validFile: false,
        validColumnHeader: true,
        readyToUpload: false,
        successPairing: false,
        importFinished: false,
        updateRegFlag: true,
        FlagAuxConstructor: false,
        KeyFailvmRol: [],
      });
      // Reset file input
      document.getElementById("fileInput").value = "";
    }
  };

  handleFileSelect = (event) => {
    const input = event.target;
    const reader = new FileReader();
    const scope = this;
    reader.onload = () => {
      const lines = reader.result.split(/[\r\n]+/g);
      const fileColHeaders = lines[0].split(";");
      // Validate if input file columnHeaders are enough to fit required columnHeaders
      const numReqHeaders = scope.state.reqColumnHeaders.filter((rCH) => rCH.isReq).length;
      if (fileColHeaders.length < numReqHeaders) {
        scope.setState({
          validColumnHeader: false,
          validFile: false,
          successPairing: false,
          importFinished: false,
        });
      } else {
        const colOptions = [{ value: 0, label: "Select" }];
        for (let i = 0; i < fileColHeaders.length; i++) {
          colOptions.push({ value: i + 1, label: fileColHeaders[i] });
        }
        scope.setState({
          validColumnHeader: true,
          validFile: true,
          fileColumnOptions: colOptions,
          pairedColumnHeaders: new Array(scope.state.reqColumnHeaders.length).fill(0),
          fileData: lines,
          failedRegisters: [],
          successPairing: false,
          importFinished: false,
          FlagAuxConstructor: false,
          KeyFailvmRol: [],
        });
        scope.automaticColumnsPairing();
      }
    };
    reader.readAsText(input.files[0], "ISO-8859-1");
  };

  dispensationAmountError = (isVisible) => {
    if (isVisible) {
      Modal.warning({
        title: "Demasiados registros",
        content: "Póngase en contacto con soporte técnico",
        onOk: this.handleCancel,
      });
    }
  };

  generateColumnPairComponents = () => {
    const colPair = [];
    let div1;
    let div2;
    for (let i = 0; i < this.state.reqColumnHeaders.length; i += 2) {
      div1 = (
        <div className="col-xs-12 col-md-6" id={i} onClick={this.setSelectedSelect} style={{ marginBottom: "10px" }}>
          <SelectGeneral
            isReq={this.state.reqColumnHeaders[i].isReq}
            text={this.state.reqColumnHeaders[i].value}
            options={this.state.fileColumnOptions}
            onChange={(e) => this.pairComponent(e)}
            defaultValue={this.state.pairedColumnHeaders[i]}
            size="small"
          />
        </div>
      );
      const j = i + 1;
      div2 = "";
      if (j < this.state.reqColumnHeaders.length) {
        div2 = (
          <div className="col-xs-12 col-md-6" id={j} onClick={this.setSelectedSelect} style={{ marginBottom: "10px" }}>
            <SelectGeneral
              isReq={this.state.reqColumnHeaders[j].isReq}
              text={this.state.reqColumnHeaders[j].value}
              options={this.state.fileColumnOptions}
              onChange={(e) => this.pairComponent(e)}
              defaultValue={this.state.pairedColumnHeaders[j]}
              size="small"
            />
          </div>
        );
      }
      colPair.push(
        <div key={"div" + i} className="row">
          {div1}
          {div2}
        </div>
      );
    }

    return colPair;
  };

  pairComponent = (e) => {
    const { pairedColumnHeaders, selectedSelectId } = this.state;
    // Set paired selection
    const pairedColumnHeadersCpy = pairedColumnHeaders;
    pairedColumnHeadersCpy[selectedSelectId] = e.value;
    // Check if pairing completed
    this.checkIfPairingIsfinished(pairedColumnHeadersCpy);
    this.setState({
      pairedColumnHeaders: pairedColumnHeadersCpy,
    });
  };

  setSelectedSelect = (e) => {
    this.setState({
      selectedSelectId: e.currentTarget.id,
    });
  };

  mapFileToFields = (pairedColumnHeaders) => {
    const { KeyFailvmRol } = this.state;
    const data = this.state.fileData;
    const mappedInfo = [];
    const failedRegisters = [];
    let FlagAux = false;

    for (let i = 1; i < data.length; i++) {
      const sLine = data[i].split(";");

      // * Code new
      // *? Check if register has enough fields

      const numReqHeaders = this.state.reqColumnHeaders.filter((rCH) => rCH.isReq).length;

      if (sLine.length >= numReqHeaders) {
        const obj = {};
        for (let j = 0; j < this.state.reqColumnHeaders.length; j++) {
          obj[this.state.reqColumnHeaders[j].key] = sLine[pairedColumnHeaders[j] - 1];
        }
        mappedInfo.push(obj);
      } else if (i < data.length - 1) {
        // *! Omit last line error print given that csv export programs add a blank line at the bottom
        failedRegisters.push(i + 1);
      }
    }

    Object.keys(mappedInfo).forEach((key) => {
      if (
        mappedInfo[key].vmRole &&
        mappedInfo[key].vmRole.split(" ").join("").toUpperCase() !== "VMBUYER" &&
        mappedInfo[key].vmRole.split(" ").join("").toUpperCase() !== "LOCKERBUYER" &&
        mappedInfo[key].vmRole.split(" ").join("").toUpperCase() !== "COMPRADORVENDING" &&
        mappedInfo[key].vmRole.split(" ").join("").toUpperCase() !== "COMPRADORLOCKER"
      ) {
        FlagAux = true;
        KeyFailvmRol.push(parseInt(key, 10) + 1);
      }
    });

    if (FlagAux === true) {
      this.setState({
        FlagAuxConstructor: FlagAux,
      });
    } else {
      FlagAux = false;
      this.setState({
        FlagAuxConstructor: FlagAux,
      });
    }

    if (failedRegisters.length > 0) {
      this.setState({
        failedRegisters,
        readyToUpload: false,
      });
    } else {
      FlagAux = false;
      this.setState({
        failedRegisters,
        readyToUpload: true,
        mappedObjects: mappedInfo,
      });
    }
  };

  handleOnChangeUpdatingOption = (e) => {
    this.setState({
      updateRegFlag: e.target.checked,
    });
  };

  handleOnRecoverUserInfoOption = (e) => {
    this.setState({
      reenableUserInfoFlag: e.target.checked,
    });
  };

  automaticColumnsPairing = () => {
    const { reqColumnHeaders, fileColumnOptions } = this.state;
    const pairedColumns = new Array(reqColumnHeaders.length).fill(0);
    const remainingFileColOptions = fileColumnOptions;
    for (let i = 0; i < reqColumnHeaders.length; i++) {
      const reqColName = reqColumnHeaders[i].value;
      const rerColNameNorm = reqColName
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, ""); // Normalize string
      for (let j = 1; j < remainingFileColOptions.length; j++) {
        const fileColName = remainingFileColOptions[j].label;
        const fileColNameNorm = fileColName
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""); // Normalize string

        if (rerColNameNorm === fileColNameNorm) {
          pairedColumns[i] = j;
          break;
        }
      }
    }
    // Check if pairing completed
    this.checkIfPairingIsfinished(pairedColumns);
    this.setState({
      pairedColumnHeaders: pairedColumns,
    });
  };

  checkIfPairingIsfinished = (pairedColumnHeaders) => {
    const { reqColumnHeaders } = this.state;
    for (let i = 0; i < reqColumnHeaders.length; i++) {
      if (reqColumnHeaders[i].isReq && pairedColumnHeaders[i] == 0) {
        return;
      }
    }
    // Check no repeated fields
    const repeated = pairedColumnHeaders.filter((item, index) => (item != 0 ? pairedColumnHeaders.indexOf(item) != index : ""));
    if (repeated.length == 0) {
      this.setState({
        validColPairing: true,
        successPairing: true,
      });
      // Map registers to fields
      this.mapFileToFields(pairedColumnHeaders);
    } else {
      this.setState({
        validColPairing: false,
        successPairing: false,
      });
    }
  };

  setImportSpecifics = () => {
    const { importEntitySpecificsFlag, reenableUserInfoFlag, pairedColumnHeaders, reqColumnHeaders, drOption } = this.state;

    if (importEntitySpecificsFlag) {
      // !? Reenable user and info
      const divUserReenable = <GeneralCheckbox onChange={(e) => this.handleOnRecoverUserInfoOption(e)} isChecked={reenableUserInfoFlag} />;
      // Dispensation rule option
      let dispRuleProvided = false;
      reqColumnHeaders.forEach((rCH, idx) => {
        if (rCH.key === "scDispensationRuleName" && pairedColumnHeaders[idx] !== 0) {
          dispRuleProvided = true;
        }
      });
      let divDRule = "";
      if (dispRuleProvided) {
        const drOptions = [
          { value: 0, label: Strings.generalTerms.yes },
          { value: 1, label: Strings.generalTerms.no },
        ];
        divDRule = (
          <div>
            <div>
              <SelectGeneralAux options={drOptions} onChange={(e) => this.setState({ drOption: e.value })} defaultValue={drOption} size="small" />
            </div>
          </div>
        );
      }
      return (
        <div>
          <div className="row vertSpace">
            <div className=" col-md-6 " style={{ width: "100%" }}>
              <TextWithInfoTooltip name={Strings.fileImport.reenableUserEnrollment} tooltip={Strings.fileImport.reenableUserEnrollmentTooltip} />
            </div>
            <div className="col-md-6">{divUserReenable}</div>
          </div>
          <div className="row vertSpace">
            <div className="col-md-6">
              <TextWithInfoTooltip name={Strings.fileImport.dispensationRuleUpdateOptions} tooltip={Strings.fileImport.dispensationRuleUpdateOptionsTooltip} />
            </div>
            <div className="col-md-6">{divDRule}</div>
          </div>
        </div>
      );
    }
  };

  onTemplateDownload = () => {
    // Create header
    let templateStr = "";
    const { reqColumnHeaders } = this.state;
    reqColumnHeaders.forEach((rCH) => {
      templateStr += `${rCH.value};`;
    });
    templateStr = templateStr.replace(new RegExp(";" + "$"), "\n");

    // Adjust download file options
    const url = window.URL.createObjectURL(new Blob([templateStr]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template.csv");
    document.body.appendChild(link);
    link.click();
  };

  onFailedRegistersFileDownload = () => {
    // Create header
    let fileStr = "";
    fileStr += "id;";

    const { reqColumnHeaders, failedImportRegisterIds, mappedObjects, failedImportRegisters } = this.state;

    reqColumnHeaders.forEach((rCH) => {
      fileStr += `${rCH.value};`;
    });
    fileStr += `${Strings.generalTerms.failureType}\n`;

    // Insert registers info
    for (let i = 0; i < failedImportRegisterIds.length; i++) {
      const regIdx = failedImportRegisterIds[i];
      let reg = mappedObjects[regIdx - 1];
      fileStr += `${regIdx};`;
      fileStr += Object.keys(reg)
        .map((k) => {
          return reg[k];
        })
        .join(";");
      fileStr += `;${failedImportRegisters[regIdx]}`;
      fileStr += "\n";
    }

    // Adjust download file options
    const url = window.URL.createObjectURL(new Blob([fileStr]));
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", "failedRegisters.csv");
    document.body.appendChild(link);
    link.click();
  };

  Checkallregistersareok = () => {
    const { failedRegisters, updateRegFlag, importFinished, failedImportRegisterIds, successPairing, fileData } = this.state;
    const { importOptions } = this.props;

    if (failedRegisters.length > 0) {
      return (
        <>
          <div className="row">
            <div className="col">
              <Alert message="Error" description={`${Strings.fileImport.registersBadFormat} ${failedRegisters}`} type="error" showIcon />
            </div>
          </div>
        </>
      );
    }

    if (successPairing) {
      return (
        <>
          <div className="row space-align-container">
            {importOptions ? 
              <>
                <div className="col-12" style={{ padding: "1%" }}>
                  <>
                    <Title level={3}>{`3. ${Strings.fileImport.selectImportOptions}`} </Title>
                  </>
                </div>
                <div className="row vertSpace">
                  <div className="row vertSpace">
                    <div className="  col-md-6 ">
                      <TextWithInfoTooltip name={Strings.fileImport.updateExitingRegisters} tooltip={Strings.fileImport.updateExitingRegistersTooltip} />
                    </div>
                    <div className=" col-md-6 ">
                      <GeneralCheckbox onChange={(e) => this.handleOnChangeUpdatingOption(e)} isChecked={updateRegFlag} />
                    </div>
                  </div>
                  {this.setImportSpecifics()}
                </div>
              </>
            :
              ""
           }

            {importFinished ? (
              <div className="col-12" style={{ marginTop: "2%" }}>
                <div className="col-12">
                  <Alert message="Informational Notes" description={Strings.fileImport.importFinished} type="info" showIcon />
                </div>
                {failedImportRegisterIds.length > 0 && Math.abs(fileData.filter((i) => i !== "").length - failedImportRegisterIds.length - 1) > 0 ? (
                  <>
                    <div className="col-12" style={{ marginTop: "2%" }}>
                      <Alert
                        message="Successfull"
                        style={{ marginTop: "2%" }}
                        description={` ${Strings.fileImport.SuccessFull} ${Math.abs(fileData.filter((i) => i !== "").length - failedImportRegisterIds.length - 1)} ${
                          Strings.generalTerms.of
                        } ${Math.abs(fileData.filter((i) => i !== "").length - 1)} ${Strings.fileImport.registers} `}
                        type="success"
                        showIcon
                      />
                      <Alert
                        message="Warning"
                        style={{ marginTop: "2%" }}
                        description={`${Strings.fileImport.registersFailed} 
                         `}
                        type="warning"
                        showIcon
                      />
                    </div>
                    <div className="col-12" style={{ marginTop: "2%" }}>
                      <Button className="button" disabled={false} onClick={this.onFailedRegistersFileDownload}>
                        <Icon type="download" />
                        <span>{Strings.fileImport.downloadFailedRegistersFile}</span>
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {failedImportRegisterIds.length === 0 ? (
                  <div className="col-12" style={{ marginTop: "2%" }}>
                    <Alert
                      message="Successfull"
                      description={` ${Strings.fileImport.SuccessFull} ${Math.abs(fileData.filter((i) => i !== "").length - 1)} ${Strings.generalTerms.of} ${Math.abs(
                        fileData.filter((i) => i !== "").length - 1
                      )} ${Strings.fileImport.registers}  `}
                      type="success"
                      showIcon
                    />
                  </div>
                ) : (
                  ""
                )}
                {failedImportRegisterIds.length > 0 && Math.abs(fileData.filter((i) => i !== "").length - failedImportRegisterIds.length - 1) === 0 ? (
                  <div className="col-12" style={{ marginTop: "2%" }}>
                    <Alert
                      message="Warning"
                      style={{ marginTop: "2%" }}
                      description={`${Strings.fileImport.registersFailed} 
                         `}
                      type="warning"
                      showIcon
                    />
                    <div className="col-12" style={{ marginTop: "2%" }}>
                      <Button className="button" disabled={false} onClick={this.onFailedRegistersFileDownload}>
                        <Icon type="download" />
                        <span>{Strings.fileImport.downloadFailedRegistersFile}</span>
                      </Button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      );
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    // Initializes export modal
    const {
      reqColumnHeaders,
      uploadingData,
      readyToUpload,
      cancelDisabled,
      isReqColHeaderNames,
      additionalColHeaderNames,
      validColumnHeader,
      validFile,
      validColPairing,
    } = this.state;
    const {
      columnHeaders,
      importEntitySpecificsFlag,
      importFinished,
      failedImportRegs,
      omitedImportRegs,
      isExceedVisible,
      importTitle,
      importTitleTooltip,
      isImportVisible,
      customDateFormat,
    } = this.props;
    if (reqColumnHeaders.length == 0 && columnHeaders.length > 0) {
      const isReqColHeaderNames = [];
      const additionalColHeaderNames = [];

      columnHeaders.forEach((cH) => {
        if (cH.isReq) {
          isReqColHeaderNames.push(cH.value);
        } else {
          additionalColHeaderNames.push(cH.value);
        }
      });
      this.setState({
        isReqColHeaderNames,
        additionalColHeaderNames,
        reqColumnHeaders: columnHeaders,
        validFile: false,
        validColumnHeader: true,
        cancelDisabled: false,
        importEntitySpecificsFlag,
        successPairing: false,
      });
    }
    // Set import finished
    if (uploadingData && importFinished && failedImportRegs) {
      // Get registers Ids that failed
      const failedRegIds = [];
      Object.keys(failedImportRegs).forEach((key) => failedRegIds.push(key));
      this.setState({
        importFinished: true,
        uploadingData: false,
        cancelDisabled: false,
        failedImportRegisters: failedImportRegs,
        omitedImportRegisters: omitedImportRegs,
        failedImportRegisterIds: failedRegIds,
      });
    }

    return (
      <>
        {this.dispensationAmountError(isExceedVisible)}
        <Modal
          visible={isImportVisible}
          onOk={this.handleImport}
          onCancel={this.handleCancel}
          width={window.innerWidth >= 800 ? 800 : 500}
          okButtonProps={{
            disabled: !readyToUpload,
            loading: uploadingData,
          }}
          cancelButtonProps={{
            disabled: cancelDisabled,
          }}
          okText={Strings.generalTerms.import}
        >
          <div className="container-fluid vertSpace">
            <Title level={2}>
              {importTitle}
              {window.innerWidth >= 300 ? "" : <div style={{ padding: "20 px" }} />}
              <Tooltip title={importTitleTooltip}>
                <Icon className="icon" type="info-circle" />
              </Tooltip>
            </Title>
          </div>
          <Divider />
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <p>
                  <Title level={3}>{`1. ${Strings.fileImport.selectFileWithFields}`} </Title>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p>
                  <b>{Strings.fileImport.mandatoryFields}*</b>: {isReqColHeaderNames.join(", ")}
                </p>
                <p>
                  <b>{Strings.fileImport.additionalFields}</b>: {additionalColHeaderNames.join(", ")}
                </p>
                <p style={{ textAlign: "right", fontSize: "12px" }}>
                  <i>{`* ${Strings.fileImport.dateFormat}: ${customDateFormat ?? dateFormat}`}</i>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">
                <input type="file" name="file" id="fileInput" onChange={this.handleFileSelect} />
              </div>
              <div className="col-md-3">
                <Button className="button" disabled={false} onClick={this.onTemplateDownload}>
                  <Icon type="download" />
                  <span>{Strings.fileImport.downloadTemplate}</span>
                </Button>
              </div>
            </div>
            {!validColumnHeader ? (
              <div className="row">
                <div className="col">
                  <a>* {Strings.generalResponses.invalidNumberOfFields}</a>
                </div>
              </div>
            ) : (
              ""
            )}
            <br />
            {validFile ? (
              <div>
                <div className="row">
                  <div className="col">
                    <p>
                      <Title level={3}>{`2. ${Strings.fileImport.pairFields}`} </Title>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col"> {this.generateColumnPairComponents()}</div>
                </div>
              </div>
            ) : (
              ""
            )}
            {!validColPairing ? (
              <div className="row">
                <div className="col">
                  <Alert message="Error" description={Strings.fileImport.invalidColPairing} type="error" showIcon />
                </div>
              </div>
            ) : (
              ""
            )}
            {this.Checkallregistersareok()}
            <br />
          </div>
        </Modal>
      </>
    );
  }
}
export default ImportReportModal;
