import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Modal } from 'antd';
import { SaveButton } from '../../GenericComponents/buttons/index';
import EditButton from '../../GenericComponents/buttons/editButton';
import CancelButton from '../../GenericComponents/buttons/CancelButton.jsx';
import Strings from '../../../systemVariables/languageStrings';
import { updateUserPassword } from '../../../actions';

class PasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      formHelp: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      validateStatus: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      showEditPassword: true
    };
  }

  componentWillUnmount() {
    this.setState({
      password: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      formHelp: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      validateStatus: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      showEditPassword: false
    });
  }

  handleEdit = () => {
    this.setState({
      isSaveButtonLoading: false,
      showEditPassword: true
    });
  };

  handleCancel = () => {
    this.setState({
      password: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      formHelp: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      validateStatus: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      showEditPassword: false
    });
  };

  handleOk = () => {
    this.setState({
      password: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      formHelp: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      validateStatus: { currentPassword: '', newPassword: '', repeatedNewPassword: '' },
      showEditPassword: false
    });
  };

  handleSave = () => {
    const { updateUserPassword } = this.props;
    const { password } = this.state;
    const areFieldsEmpty = this.areFieldsEmpty(password.currentPassword, password.newPassword, password.repeatedNewPassword);

    if (!areFieldsEmpty) {
      const isPasswordValid = this.isPasswordValid(password.newPassword);
      if (isPasswordValid) {
        updateUserPassword(password, response => {
          if (!response.data && String(response).includes('Error:')) {
            // Connection error
            Modal.error({
              title: Strings.generalResponses.failedTransaction,
              content: Strings.generalResponses.saveError
            });
          } else if (response.data.code === 4006) {
            // Identity card number already exists in the So
            Modal.error({
              title: Strings.generalResponses.failedTransaction,
              content: 'La contraseña nueva es invalida'
            });
          } else if (response.data.code === 4015) {
            // Incorrect current password
            Modal.error({
              title: Strings.generalResponses.failedTransaction,
              content: 'La contraseña actual no es correcta'
            });
          } else if (response.data.code === 0) {
            // Entity Successfully saved
            Modal.success({
              title: Strings.generalResponses.successfulCreation,
              content: 'La contraseña ha sido cambiada correctamente',
              onOk: this.handleOk
            });
          }
        });
      }
    }
  };

  handleChange = event => {
    const { id, value } = event.target;
    const { password, validateStatus, formHelp } = { ...this.state };
    validateStatus[id] = '';
    formHelp[id] = '';
    password[id] = value;

    this.setState({
      validateStatus,
      formHelp,
      password
    });

    switch (id) {
      case 'currentPassword':
        break;
      case 'newPassword':
        break;
      case 'repeatedNewPassword':
        break;
      default:
    }
  };

  areFieldsEmpty = (currentPassword, newPassword, repeatedNewPassword) => {
    let areEmpty = false;
    const { validateStatus, formHelp } = { ...this.state };
    validateStatus.currentPassword = 'success';
    formHelp.currentPassword = '';

    if (currentPassword.length <= 0) {
      areEmpty = true;
      validateStatus.currentPassword = 'error';
      formHelp.currentPassword = 'The field is empty';
    }

    if (newPassword.length <= 0) {
      areEmpty = true;
      validateStatus.newPassword = 'error';
      formHelp.newPassword = 'The field is empty';
    }

    if (repeatedNewPassword.length <= 0) {
      areEmpty = true;
      validateStatus.repeatedNewPassword = 'error';
      formHelp.repeatedNewPassword = 'The field is empty';
    }

    this.setState({
      validateStatus,
      formHelp
    });

    return areEmpty;
  };

  // The password length must have at least 8 characters.
  // The password must not have special characters.
  // The password must have at least one upper case character (A to Z).
  // The password must have at least one lower case character (a to z).
  // The password must have at least one digit character (0 to 9).
  isPasswordValid = pass => {
    let isValid = true;
    const { password, validateStatus, formHelp } = { ...this.state };
    validateStatus.newPassword = 'success';
    formHelp.newPassword = '';
    validateStatus.repeatedNewPassword = 'success';
    formHelp.repeatedNewPassword = '';

    if (password.repeatedNewPassword !== pass) {
      isValid = false;
      validateStatus.newPassword = 'error';
      formHelp.newPassword = 'The password are not equal';
      validateStatus.repeatedNewPassword = 'error';
      formHelp.repeatedNewPassword = 'The password are not equal';
    } else if (pass.length < 8) {
      isValid = false;
      validateStatus.newPassword = 'error';
      formHelp.newPassword = 'The password length must have at least 8 characters';
      validateStatus.repeatedNewPassword = 'error';
      formHelp.repeatedNewPassword = 'The password length must have at least 8 characters';
    } else if (/[^a-zA-Z0-9 ]/.test(pass)) {
      isValid = false;
      validateStatus.newPassword = 'error';
      formHelp.newPassword = 'The password must not have special characters';
      validateStatus.repeatedNewPassword = 'error';
      formHelp.repeatedNewPassword = 'The password must not have special characters';
    } else if (!/[A-Z ]/.test(pass)) {
      isValid = false;
      validateStatus.newPassword = 'error';
      formHelp.newPassword = 'The password must have at least one upper case character (A to Z)';
      validateStatus.repeatedNewPassword = 'error';
      formHelp.repeatedNewPassword = 'The password must have at least one upper case character (A to Z)';
    } else if (!/[a-z ]/.test(pass)) {
      isValid = false;
      validateStatus.newPassword = 'error';
      formHelp.newPassword = 'The password must have at least one lower case character (a to z)';
      validateStatus.repeatedNewPassword = 'error';
      formHelp.repeatedNewPassword = 'The password must have at least one lower case character (a to z)';
    } else if (!/[0-9 ]/.test(pass)) {
      isValid = false;
      validateStatus.newPassword = 'error';
      formHelp.newPassword = 'The password must have at least one digit character (0 to 9)';
      validateStatus.repeatedNewPassword = 'error';
      formHelp.repeatedNewPassword = 'The password must have at least one digit character (0 to 9)';
    }
    this.setState({
      validateStatus,
      formHelp
    });

    return isValid;
  };

  renderPasswordForm() {
    const labelCol = {
      // sm: { span: 12 },
      md: { span: 12 },
      xl: { span: 11 }
    };
    const wrapperCol = {
      // sm: { span: 9 },
      md: { span: 12 },
      xl: { span: 10 }
    };

    const { formHelp, validateStatus, isSaveButtonLoading } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-md.6 passwordForm">
            <Form labelCol={labelCol} wrapperCol={wrapperCol}>
              <Form.Item
                validateStatus={validateStatus.currentPassword}
                help={formHelp.currentPassword}
                label="Contraseña Actual"
                labelAlign="left"
                hasFeedback
              >
                <Input.Password id="currentPassword" onChange={this.handleChange} />
              </Form.Item>
              <Form.Item
                validateStatus={validateStatus.newPassword}
                help={formHelp.newPassword}
                label="Contraseña Nueva"
                labelAlign="left"
                hasFeedback
              >
                <Input.Password id="newPassword" onChange={this.handleChange} />
              </Form.Item>
              <Form.Item
                validateStatus={validateStatus.repeatedNewPassword}
                help={formHelp.repeatedNewPassword}
                label="Repetir Contraseña Nueva"
                labelAlign="left"
                hasFeedback
              >
                <Input.Password id="repeatedNewPassword" onChange={this.handleChange} />
              </Form.Item>
            </Form>
          </div>
          <div className="col-md-6 align-self-center">
            <div className="row justify-content-center">
              <div className="col-6 col-md-4 col-lg-3">
                <SaveButton loading={isSaveButtonLoading} onClick={this.handleSave} />
              </div>
              <div className="col-6 col-md-4 col-lg-3">
                <CancelButton onClick={this.handleCancel} />
              </div>
            </div>
            <br />
          </div>
        </div>
      </>
    );
  }

  renderPasswordInfo() {
    return (
      <div className="row align-items-center">
        <div className="col-9 text-left">Se recomienda usar una contraseña segura que no uses en ningún otro sitio.</div>
        <div className="col-3 text-left">
          <EditButton onClick={this.handleEdit} />
        </div>
      </div>
    );
  }

  render() {
    const { showEditPassword } = this.state;
    return <>{showEditPassword === false ? this.renderPasswordInfo() : this.renderPasswordForm()}</>;
  }
}
function mapStateToProps(state) {
  return {
    userSessionInfo: state.users.userSessionObj.data
  };
}
export default connect(mapStateToProps, {
  updateUserPassword
})(PasswordChange);
