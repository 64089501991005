import React, { Component } from "react";
import { Modal, Divider, Spin, Icon } from "antd";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { identityCardTypeLocalization } from "../../../Utils/LanguageLocalization/identityCardTypeLocalization";

import "./addClientUser.css";

class MoreInfoDetailsModal extends Component {
  handleOk = () => {
    this.props.setVisible(false);
    this.props.clearImage();
    this.props.clearModal();
  };

  handleCancel = () => {
    this.props.setVisible(false);
    this.props.clearImage();
    this.props.clearModal();
  };

  renderTitle = (userInfo) => {
    const identityCardType = userInfo.identityCardType ? userInfo.identityCardType.name : " ";
    const { identityCardNumber } = userInfo;
    const identityCardTypeLocalizated = identityCardTypeLocalization(identityCardType);

    return (
      <div>
        <h2 className="text-center">
          {userInfo.firstName ? `${userInfo.firstName} ` : " "}
          {userInfo.middleName ? `${userInfo.middleName} ` : " "}
          {userInfo.lastName1 ? `${userInfo.lastName1} ` : " "}
          {userInfo.lastName2 ? `${userInfo.lastName2} ` : " "}
        </h2>
        <h3 className="text-center">
          <div>
            {identityCardTypeLocalizated}: <span className="text-secondary">{identityCardNumber}</span>
          </div>
        </h3>
      </div>
    );
  };

  renderUserInfoData = (userInfo, vendingMachineByUser, roleByUser, ruleByUser) => {
    let renderUsername;
    let renderVM;
    let renderVmRole;
    let renderSysRole;
    let renderRuleByUser;

    if (userInfo && userInfo.length !== 0) {
      const { userName } = userInfo;
      renderUsername = (
        <div className="vertSpace col">
          <div className="col-md-1">
            <p>
              <b>
                <Icon type="user" />
              </b>
            </p>
          </div>
          <div className="col-md-5">
            <p>
              <b>{Strings.users.username}:</b>
            </p>
          </div>
          <div className="col-md-6">
            <p>{userName}</p>
          </div>
          <br />
          <Divider />
        </div>
      );
    }

    if (vendingMachineByUser && vendingMachineByUser.length !== 0) {
      const vmByUser = [];
      vendingMachineByUser.forEach((obj) => {
        vmByUser.push(<div>{obj.vendingMachineFriendlyName}</div>);
      });
      renderVM = (
        <div className="vertSpace col">
          <div className="col-md-1">
            <p>
              <b>
                <Icon type="appstore" />
              </b>
            </p>
          </div>
          <div className="col-md-5">
            <p>
              <b>{Strings.machine.assignedMachine}:</b>{" "}
            </p>
          </div>
          <div className="col-md-6">
            <p>{vmByUser}</p>
          </div>
          <br />
          <Divider />
        </div>
      );
    }

    if (roleByUser && roleByUser.length !== 0) {
      const vmRole = roleByUser.filter((row) => row.permissionTypeId === 3);
      const sysRole = roleByUser.filter((row) => row.permissionTypeId !== 3);

      if (vmRole && vmRole.length !== 0) {
        const vmRoleList = [];
        vmRole.forEach((row) => {
          vmRoleList.push(<div>{row.roleName}</div>);
        });
        renderVmRole = (
          <div className="vertSpace col">
            <div className="col-md-1">
              <p>
                <b>
                  <Icon type="solution" />
                </b>
              </p>
            </div>
            <div className="col-md-5">
              <p>
                <b>{Strings.Roles.vmRol}:</b>{" "}
              </p>
            </div>
            <div className="col-md-6">
              <p>{vmRoleList}</p>
            </div>
            <br />
            <Divider />
          </div>
        );
      }

      if (sysRole && sysRole.length !== 0) {
        const sysRoleList = [];
        sysRole.forEach((row) => {
          sysRoleList.push(<div>{row.roleName}</div>);
        });
        renderSysRole = (
          <div className="vertSpace col">
            <div className="col-md-1">
              <p>
                <b>
                  <Icon type="database" />
                </b>
              </p>
            </div>
            <div className="col-md-5">
              <p>
                <b>{Strings.Roles.sysRol}:</b>{" "}
              </p>
            </div>
            <div className="col-md-6">
              <p>{sysRoleList}</p>
            </div>
            <br />
            <Divider />
          </div>
        );
      }
    }

    if (ruleByUser) {
      const rule = ruleByUser.name;

      renderRuleByUser = (
        <div className="vertSpace col">
          <div className="col-md-1">
            <p>
              <b>
                <Icon type="setting" />
              </b>
            </p>
          </div>
          <div className="col-md-5">
            <p>
              <b>{Strings.dispensationRule.dispensationRule}:</b>
            </p>
          </div>
          <div className="col-md-6">
            <p>{rule}</p>
          </div>
          <br />
          <Divider />
        </div>
      );
    }

    return (
      <>
        {renderUsername}
        {renderVM}
        {renderVmRole}
        {renderSysRole}
        {renderRuleByUser}
      </>
    );
  };

  render() {
    const { isModalDataComplete, isVisible, userInfo, vendingMachineByUser, roleByUser, ruleByUser } = this.props;
    return isModalDataComplete === false ? (
      <Modal title={Strings.generalTerms.loading} visible={isVisible} onOk={this.handleOk} onCancel={this.handleCancel} centered width="40%" footer={null}>
        <div className="row">
          <div className="col text-center">
            <Spin />
          </div>
        </div>
      </Modal>
    ) : (
      <Modal title={this.renderTitle(userInfo)} visible={isVisible} onOk={this.handleOk} onCancel={this.handleCancel} centered width="40%" footer={null}>
        <div className="row">
          <div className="vertSpace col">{this.renderUserInfoData(userInfo, vendingMachineByUser, roleByUser, ruleByUser)}</div>
        </div>
      </Modal>
    );
  }
}
export default MoreInfoDetailsModal;
