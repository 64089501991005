import React from "react";
import {Popover} from "antd";

// Components
import RenderSlotInfo from "./RenderSlotInfo";

// Language localization
import Strings from "../../systemVariables/languageStrings";

const RenderSlot = (props) => {

    const FRAME_SIZE = 10;

    const FREE_SLOT_COLOR = "#ade08b";
    const FIXED_SLOT_COLOR = "#f7c8a1";
    const BORROW_SLOT_COLOR = "#f7a1bf";
    const LOAN_SLOT_COLOR = "#98d5d9";
    const SELL_SLOT_COLOR = "#a395bf";
    const DELIVERY_SLOT_COLOR = "#f7f0a1";
    const EMPTY_SLOT_COLOR = "#EEEEEE";
    const RESERVED_SLOT_COLOR = "#ff9292";
    const CONTROL_MODULE_SLOT_COLOR = "#949494";
    const NEW_SLOT_COLOR_DRAG = "#CED9EE";
    const NEW_SLOT_COLOR = "#BACBF5";
    const ERROR_SLOT_COLOR_DRAG = "#EED0CE";

    let slots = [];
    const controlModule = "CONTROL MODULE";


    // Get Slots info and sort it
    const planogram = [];
    for (let r = -1; r < props.planogramInfo.numRows + 1; r++) {
      const planogramRow = [];
      for (let c = -1; c < props.planogramInfo.numCols + 1; c++) {
        slots = props.planogramInfo.slots.filter((s) => s.col === c && s.row === r);
        if (slots.length > 0) {
          for (let s = 0; s < slots.length; s++) {
            planogramRow[c + 1] = {
              mulWidth: slots[s].mulWidth,
              mulHeight: slots[s].mulHeight,
              lockerType: slots[s].lockerType,
              slotNumber: slots[s].slotNumber,
            };
          }
          planogram[r + 1] = planogramRow;
        }
      }
    }

    // Generate html tags according planogram
    const planogramToPlot = [];
    let slotStyle;
    let showSlotInfo;
    for (let r = 0; r < props.planogramInfo.numRows + 2; r++) {
      const planogramRowToPlot = [];
      for (let c = 0; c < props.planogramInfo.numCols + 2; c++) {
        if (planogram[r] && planogram[r][c]) {
          switch (planogram[r][c].lockerType) {
            case "EMPTY":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: EMPTY_SLOT_COLOR,
                border: "1px dashed black",
                cursor: "nw-resize",
              };
              showSlotInfo = false;
              break;
            case "FREE":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: FREE_SLOT_COLOR,
                border: "1px solid black",
                cursor: "pointer",
              };
              showSlotInfo = true;
              break;
            case "LOAN":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: LOAN_SLOT_COLOR,
                border: "1px solid black",
                cursor: "pointer",
              };
              showSlotInfo = true;
              break;
            case "BORROW":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: BORROW_SLOT_COLOR,
                border: "1px solid black",
                cursor: "pointer",
              };
              showSlotInfo = true;
              break;
            case "SELL":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: SELL_SLOT_COLOR,
                border: "1px solid black",
                cursor: "pointer",
              };
              showSlotInfo = true;
              break;
            case "DELIVERY":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: DELIVERY_SLOT_COLOR,
                border: "1px solid black",
                cursor: "pointer",
              };
              showSlotInfo = true;
              break;
            case "FIXED":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: FIXED_SLOT_COLOR,
                border: "1px solid black",
                cursor: "pointer",
              };
              showSlotInfo = true;
              break;
            case "RESERVED":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: RESERVED_SLOT_COLOR,
                border: "1px solid black",
                cursor: "pointer",
              };
              showSlotInfo = true;
              break;
            case "CONTROL MODULE":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: CONTROL_MODULE_SLOT_COLOR,
                border: "1px solid black",
                cursor: "no-drop",
              };
              showSlotInfo = true;
              break;
            case "NEW_SLOT_DRAG":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: NEW_SLOT_COLOR_DRAG,
                border: "1px dashed black",
                cursor: "grabbing",
              };
              showSlotInfo = false;
              break;
            case "ERROR_SLOT_DRAG":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: ERROR_SLOT_COLOR_DRAG,
                border: "1px dashed black",
                cursor: "no-drop",
              };
              showSlotInfo = false;
              break;
            case "NEW SLOT":
              slotStyle = {
                width: props.slotWidthSize,
                height: props.slotHeightSize,
                backgroundColor: NEW_SLOT_COLOR,
                border: "1px solid black",
                cursor: "pointer",
              };
              showSlotInfo = true;
              break;
            case "V_FRAME_L":
            case "V_FRAME_R":
              slotStyle = {
                width: FRAME_SIZE,
                height: props.slotHeightSize,
                backgroundColor: "transparent",
                border: "none",
                cursor: "default",
              };
              showSlotInfo = false;
              break;
            case "H_FRAME_T":
            case "H_FRAME_B":
              slotStyle = {
                width: props.slotWidthSize,
                height: FRAME_SIZE,
                backgroundColor: "transparent",
                border: "none",
                cursor: "default",
              };
              showSlotInfo = false;
              break;
            case "C_FRAME_TR":
            case "C_FRAME_TL":
            case "C_FRAME_BR":
            case "C_FRAME_BL":
              slotStyle = {
                width: FRAME_SIZE,
                height: FRAME_SIZE,
                backgroundColor: "transparent",
                border: "none",
                cursor: "default",
              };
              showSlotInfo = false;
              break;
            default:
              break;
          }
          // Show slot info
          if (showSlotInfo) {
            planogramRowToPlot.push(
              props.previousPathDisabled ? (
                <Popover
                  title={`${Strings.machineInventory.slotInformation} (${
                    {
                      ...props.planogramInfo.slots.find((s) => s.row === r - 1 && s.col === c - 1),
                    }.slotNumber
                  })`}
                  content={ <RenderSlotInfo 
                              slotToConfig={props.planogramInfo.slots.find((s) => s.row === r - 1 && s.col === c - 1)}
                            />
                  }
                  // trigger="hover"
                  // visible={this.state.hoverPopoverVisible}
                  // onVisibleChange={this.onHoverPopover}
                >
                  {/* <Popover
                              title={Strings.machineInventory.slotInformation + " (" + Object.assign({}, this.state.props.planogramInfo.slots.find(s => s.row == (r - 1) && s.col == (c - 1))).slotNumber + ")"}
                              content={props.renderSlotInfo(Object.assign({}, this.state.props.planogramInfo.slots.find(s => s.row == (r - 1) && s.col == (c - 1))))}
                              trigger="click"
                              visible={this.state.clickedPopoverVisible}
                              onVisibleChange={this.onClickPopover}
                           > */}
                  <td id={`${r},${c}`} style={slotStyle} colSpan={planogram[r][c].mulWidth} rowSpan={planogram[r][c].mulHeight} onClick={() => props.setSlotInfo(r - 1, c - 1)}>
                    <div style={{ textAlign: "left" }}>{planogram[r][c].lockerType === controlModule ? <b>--</b> : <b>{planogram[r][c].slotNumber}</b>}</div>
                  </td>
                  {/* </Popover> */}
                </Popover>
              ) : (
                <td
                  key={r + c}
                  id={`${r},${c}`}
                  style={slotStyle}
                  colSpan={planogram[r][c].mulWidth}
                  rowSpan={planogram[r][c].mulHeight}
                  onClick={() => {
                    planogram[r][c].lockerType !== controlModule && props.setSlotInfo(r - 1, c - 1);
                  }}
                >
                  <div style={{ textAlign: "left" }}>{planogram[r][c].lockerType === controlModule ? <b>--</b> : <b>{planogram[r][c].slotNumber}</b>}</div>
                </td>
              )
            );
          } else {
            // Hide slot info
            planogramRowToPlot.push(<td key={r + c} id={`${r},${c}`} style={slotStyle} colSpan={planogram[r][c].mulWidth} rowSpan={planogram[r][c].mulHeight} />);
          }
        }
      }
      planogramToPlot.push(<tr key={r}>{planogramRowToPlot}</tr>);
    }
    return (planogramToPlot);
}
 
export default RenderSlot;  