// Dependencies
import { Badge, Button, Icon, Tooltip, message } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import actions2 from "./actions2";
// Actions

// Components
import VmSlotInfoConfigModal from "./VmSlotInfoConfigModal.jsx";

// css
import "./machineGui.css";

// Language localization
import Strings from "../../../../systemVariables/languageStrings";

const { addProduct, updateProduct, removeProduct } = actions2;

let productsToEditTotal = [];

class MachineGui extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBlockedPlaceCurrentProduct: false,
      slotFound: null,
      showSlotConfigModal: false,
      isEditFlag: false,
      slot: null,
      vmTray: null,
    };
  }

  verifyProductPosition = (productToPlace, columnLView, vmTray, wingInfo) => {
    const { planogramInfo } = this.props;
    // Obtiene la cantidad de divisiones (1/2 slot) requeridas por tipo de slotsize
    const slotSize = this.slotSize(productToPlace.slotSizeId);
    // Valida si hay restricciones de ubicaión por slot o bandeja
    const allowedSlots = this.getSuggestionsSlot(productToPlace.defaultVmSlotPositionId);
    const allowedTrays = this.getSuggestionsTray(productToPlace.defaultVmTrayPositionId);
    const allowedSlotsIndex = allowedSlots.indexOf(columnLView);
    const allowedTrayIndex = allowedTrays.indexOf(vmTray.trayDenomination);

    let isSlotEmpty = true;
    let fitInVM = true;
    const slotsOccupiedByProductToAdd = [];

    // Lista las divisiones que requiere para ser ubicado partiendo desde la posición seleccionada
    for (let i = columnLView; i < columnLView + slotSize; i++) {
      slotsOccupiedByProductToAdd.push(i);
    }

    // Verifica si el máx valor pde slot por asignar es menor que el máx numero de slot por bandeja
    const maxTraySlot = parseInt(wingInfo.maxNumSlots, 10) * 2 - 1;
    const maxProdSlot = slotsOccupiedByProductToAdd.length - 1;
    if (slotsOccupiedByProductToAdd[maxProdSlot] > maxTraySlot) {
      fitInVM = false;
    }

    // Lista las divisiones ocupadas por cada producto asignado en el planograma
    planogramInfo.slots.forEach((item) => {
      if (item.trayDenomination === vmTray.trayDenomination) {
        const slotsOccupiedByProduct = [];
        const slotsize1 = item.initialSlot + item.vmSlotSize;

        for (let i = item.initialSlot; i < slotsize1; i++) {
          slotsOccupiedByProduct.push(i);
        }
        // Valida si hay interferencia entre las divisiones requeridas y las divisiones asignadas
        const productSlotIntersection = _.intersection(slotsOccupiedByProductToAdd, slotsOccupiedByProduct);
        if (productSlotIntersection.length > 0) {
          return (isSlotEmpty = false);
        }
      }
    });

    const vProductLimits =
      isSlotEmpty && // Indica si las divisiones requeridas están disponibles
      fitInVM && // Indica el slot si las divisiones requeridas caben dentro de las divisiones máx de la maquina
      allowedSlotsIndex !== -1 &&
      allowedTrayIndex !== -1; // Indica si las el producto puede ser colocado en un slot válido

    if (!vProductLimits) {
      message.error(
        `Slot invalido, revise si en la bandeja ${vmTray.trayDenomination + 1} y el slot ${columnLView / 2} cabe un producto de ${slotSize / 2}${
          productToPlace.slotSizeId === 0 ? " espacio" : " espacios"
        }`
      );
    }

    return vProductLimits;
  };

  placeCurrentProduct = (slot, vmTray, wingInfo) => {
    const {
      products: { selected_variation: selectedVariation },
      planogramInfo,
    } = this.props;
    const { isBlockedPlaceCurrentProduct } = this.state;

    // Verifiy product placement
    if (!this.verifyProductPosition(selectedVariation, slot, vmTray, wingInfo) || isBlockedPlaceCurrentProduct) {
      return false;
    }

    const slotSize = this.slotSize(selectedVariation.slotSizeId);

    const productToVisualize = {};
    productToVisualize.amountAvailable = 0;
    productToVisualize.columnHId = null;
    productToVisualize.columnLId = null;
    productToVisualize.hasHelixAligner = selectedVariation.hasHelixAligner;
    productToVisualize.hasProductAligner = selectedVariation.hasProductAligner;
    productToVisualize.hasSlidingBase = selectedVariation.hasSlidingBase;
    productToVisualize.helixDiameter = selectedVariation.helixH;
    productToVisualize.helixSpaces = selectedVariation.spaces;
    productToVisualize.initialSlot = slot;
    productToVisualize.isEnabled = 1;
    productToVisualize.isWorkingProperly = 1;
    productToVisualize.motorHname = selectedVariation.motorH;
    productToVisualize.motorLname = selectedVariation.motorL;
    productToVisualize.slotNumber = null;
    productToVisualize.soProductCode = selectedVariation.erpProductCode;
    productToVisualize.soProductId = null;
    productToVisualize.soProductName = selectedVariation.productName;
    productToVisualize.soProductSlotTypeId = selectedVariation.id;
    // productToVisualize.trayNumber = vmTray.trayNumber.toString(10);
    productToVisualize.trayDenomination = vmTray.trayDenomination;
    productToVisualize.vmProductSlotId = null;
    productToVisualize.vmSlotSize = slotSize;
    productToVisualize.vmSlotSizeId = selectedVariation.slotSizeId;

    planogramInfo.slots.push(productToVisualize);

    this.setState({ isBlockedPlaceCurrentProduct: true });
    this.forceUpdate();
  };

  getSuggestionsTray = (pos) => {
    const { vmTrays } = this.props;
    switch (pos) {
      case 0:
        return Array.from(Array(vmTrays.length).keys());
      case 1:
        return [0];
      case 2:
        return [0, 1];
      case 3:
        const array = Array.from(Array(vmTrays.length).keys());
        array.splice(array.length - 2, array.length - 1);
        array.splice(0, 2);
        return array;
      case 4:
        return [vmTrays.length - 2, vmTrays.length - 1];
      case 5:
        return [vmTrays.length - 1];
      default:
        return [];
    }
  };

  getSuggestionsSlot = (pos) => {
    const { wingInfo } = this.props;
    const vmSetup = parseInt(wingInfo.maxNumSlots, 10) * 2;
    switch (pos) {
      case 0:
        return Array.from(Array(vmSetup).keys());
      case 1:
        return [0, 1];
      case 2:
        return [0, 1, 2, 3];
      case 3:
        const array = Array.from(Array(vmSetup).keys());
        array.splice(array.length - 2, array.length - 1);
        array.splice(0, 2);
        return array;
      case 4:
        return [vmSetup - 4, vmSetup - 3, vmSetup - 2, vmSetup - 1];
      case 5:
        return [vmSetup - 2, vmSetup - 1];
      default:
        return [];
    }
  };

  slotSize = (slotSizeId) => {
    let slotSize;
    switch (slotSizeId) {
      case 0:
      default:
        return (slotSize = 2);

      case 1:
        return (slotSize = 3);

      case 2:
        return (slotSize = 4);

      case 3:
        return (slotSize = 5);

      case 4:
        return (slotSize = 6);
    }
  };

  showEditModal(slotFound, isEditFlag, slot, tray, vmTray) {
    this.setState({ showSlotConfigModal: true, slotFound, isEditFlag, slot, tray, vmTray });
  }

  onCloseSlotInfoModal = () => {
    this.setState({ showSlotConfigModal: false });
  };

  onSetupNewSlot = (slotToInsert) => {
    const { vmTray, slot } = this.state;
    const { planogramInfo, vmId, saveVmProduct } = this.props;
    let newSlotAlreadyInPlanogram = null;

    planogramInfo.slots.forEach((obj) => {
      if (obj.vmProductSlotId === null && obj.initialSlot === slotToInsert.initialSlot && obj.trayDenomination === vmTray.trayDenomination) {
        newSlotAlreadyInPlanogram = obj;
      }
    });

    const productToAdd = {};
    productToAdd.id = null;
    productToAdd.vendingMachineId = vmId;
    productToAdd.vmTrayId = vmTray.id;
    productToAdd.initialSlot = slot;
    productToAdd.slotNumber = newSlotAlreadyInPlanogram.slotNumber;
    productToAdd.columnLId = newSlotAlreadyInPlanogram.columnLId;
    productToAdd.columnHId = newSlotAlreadyInPlanogram.columnHId;
    productToAdd.amountAvailable = newSlotAlreadyInPlanogram.amountAvailable;
    productToAdd.isWorkingProperly = newSlotAlreadyInPlanogram.isWorkingProperly;
    productToAdd.soProductSlotTypeId = newSlotAlreadyInPlanogram.soProductSlotTypeId;
    productToAdd.isEnabled = newSlotAlreadyInPlanogram.isEnabled;

    // Save one product per time
    saveVmProduct(productToAdd);

    this.setState({ showSlotConfigModal: false });
  };

  setUnblockedPlaceCurrentProduct = () => {
    this.setState({ isBlockedPlaceCurrentProduct: false });
  };

  onEditSlot = (slotToEdit) => {
    const { planogramInfo, vmId, updateVmProduct } = this.props;
    let vmptAlreadyInDB = null;

    planogramInfo.slots.forEach((obj) => {
      if (
        obj.vmProductSlotId !== null &&
        obj.vmProductSlotId === slotToEdit.vmProductSlotId &&
        obj.initialSlot === slotToEdit.initialSlot &&
        obj.trayDenomination === slotToEdit.trayDenomination
      ) {
        vmptAlreadyInDB = obj;
      }
    });

    if (vmptAlreadyInDB != null) {
      // Remove product from list to edit
      productsToEditTotal = productsToEditTotal.filter(
        (obj) =>
          !(
            obj.vmProductSlotId === vmptAlreadyInDB.vmProductSlotId &&
            (obj.initialSlot === vmptAlreadyInDB.initialSlot) &
              (obj.slotNumber === vmptAlreadyInDB.slotNumber) &
              (obj.soProductSlotTypeId === vmptAlreadyInDB.soProductSlotTypeId) &
              (obj.trayDenomination === vmptAlreadyInDB.trayDenomination)
          )
      );

      // Change existing product in DB planogram
      vmptAlreadyInDB.vendingMachineId = vmId;
      vmptAlreadyInDB.vmProductSlotId = slotToEdit.vmProductSlotId;
      vmptAlreadyInDB.columnLId = slotToEdit.columnLId;
      vmptAlreadyInDB.columnHId = slotToEdit.columnHId ? slotToEdit.columnHId : null;
    }

    // Update one product per time
    updateVmProduct(vmptAlreadyInDB);
  };

  onDeleteSlot = (slotToDisable) => {
    const { planogramInfo, disableVmProduct } = this.props;
    let vmptAlreadyInDB = null;

    planogramInfo.slots.forEach((obj) => {
      if (
        obj.vmProductSlotId !== null &&
        obj.vmProductSlotId === slotToDisable.vmProductSlotId &&
        obj.initialSlot === slotToDisable.initialSlot &&
        obj.trayDenomination === slotToDisable.trayDenomination
      ) {
        vmptAlreadyInDB = obj;
      }
    });

    if (vmptAlreadyInDB != null) {
      // Disable (remove) existing product in DB planogram
      vmptAlreadyInDB.isEnabled = 0;
      disableVmProduct(vmptAlreadyInDB.vmProductSlotId);
    } else {
      this.setState({ isBlockedPlaceCurrentProduct: false });
    }

    // Remove product from planogram display on front
    _.remove(planogramInfo.slots, (obj) => _.isEqual(obj, slotToDisable));
  };

  disableTray = (wingId, vmTray) => {
    const { planogramInfo, vmTrays, removeVmTrays } = this.props;
    const newVmTrays = [];
    let vmTrayToRemove = null;

    const productInTray = planogramInfo.slots.filter((obj) => obj.vmTrayId === vmTray.id);

    // Verify if the tray is busy
    if (productInTray.length > 0) {
      message.error(Strings.planogram.vmTrayBusy);
    } else {
      vmTrays.forEach((obj) => {
        if (obj.trayDenomination === vmTray.trayDenomination) {
          vmTrayToRemove = obj;
        } else {
          newVmTrays.push(obj);
        }
      });
      removeVmTrays(wingId, newVmTrays, vmTrayToRemove);
    }
  };

  addTray = (wingId) => {
    const { addVmTray } = this.props;
    const vmTraysToAdd = [];
    const vmTray = {
      id: null,
      trayDenomination: this.props.vmTrays.length,
      isEnabled: 1,
    };

    const vmTrays = [...this.props.vmTrays];

    vmTrays.push(vmTray);
    vmTraysToAdd.push(vmTray);

    addVmTray(wingId, vmTrays);
  };

  /** *
   * Function to render the Vending Machine header (normal slots)
   */
  renderHeader = (wingInfo) => {
    const res = [];
    // Encabezado de la máquina
    for (let slot = 0; slot < parseInt(wingInfo.maxNumSlots, 10); slot++) {
      const theSlot = (
        <th key={`title_${slot + 1}`} colSpan={2} className="trayHeader">
          {`${slot}`}
        </th>
      );
      res.push(theSlot);
    }
    return res;
  };

  /** *
   * Function to render the Vending Machine subheader (divide the slots by 2 to get the half spaces)
   */
  renderSubHeader = (wingInfo) => {
    const res = [];
    // Encabezado de la máquina
    for (let slot = 0; slot < parseInt(wingInfo.maxNumSlots, 10) * 2; slot++) {
      const theSlot = (
        <th key={`title_${slot + 1}`} colSpan={0.5} className="traySubHeader">
          <div />
        </th>
      );
      res.push(theSlot);
    }
    return res;
  };

  /** *
   * Function that render the Vending Machine Slots with its product and available amount
   ** */
  renderSlot(wingInfo, vmTrays, planogramInfo) {
    const res = [];
    const slotDefaultSize = 1;
    let theSlot;

    const {
      products: { selected_variation: selectedVariation },
    } = this.props;

    const slotSuggestions = this.getSuggestionsSlot(selectedVariation.defaultVmSlotPositionId);
    const traySuggestions = this.getSuggestionsTray(selectedVariation.defaultVmTrayPositionId);

    for (let tray = 0; tray < vmTrays.length; tray++) {
      const trayHeader = (
        <th className="trayHeader">
          {`${tray + 1}`}
          {tray + 1 === vmTrays.length && (
            <Tooltip title={Strings.machine.disableTray}>
              <Icon type="minus-circle" theme="twoTone" twoToneColor="#c4541a" onClick={() => this.disableTray(wingInfo.wingId, vmTrays[tray])} />
            </Tooltip>
          )}
        </th>
      );

      let fullTray = [];
      const isSuggestedTray = traySuggestions.indexOf(tray) !== -1;
      const clsSuggestedTray = isSuggestedTray ? "suggested_tray" : "";

      for (let slot = 0; slot < parseInt(wingInfo.maxNumSlots, 10) * 2; slot++) {
        const isSuggested = slotSuggestions.indexOf(slot) !== -1;

        // eslint-disable-next-line no-loop-func
        const slotFound = planogramInfo.filter((slotFound) => slotFound.initialSlot === slot && slotFound.trayDenomination === tray);

        const clsSuggested = isSuggested && isSuggestedTray ? "suggested_slot" : "emptySlot";
        if (slotFound[0]) {
          // Renderisa cada uno de los slots existentes en el planograma
          let slotSize = 0;
          switch (slotFound[0].vmSlotSizeId) {
            case 0:
            default:
              slotFound[0].vmSlotSize = 2;
              slotSize = 2;
              break;

            case 1:
              slotFound[0].vmSlotSize = 3;
              slotSize = 3;
              break;

            case 2:
              slotFound[0].vmSlotSize = 4;
              slotSize = 4;
              break;

            case 3:
              slotFound[0].vmSlotSize = 5;
              slotSize = 5;
              break;

            case 4:
              slotFound[0].vmSlotSize = 6;
              slotSize = 6;
              break;
          }

          const amountAvailable = slotFound[0].amountAvailable !== 0 ? Math.round((slotFound[0].amountAvailable * 100) / slotFound[0].helixSpaces) : 0;

          theSlot = (
            <td key={slot} colSpan={slotSize} className="product_slot">
              {slotFound[0].vmProductSlotId ? (
                // Mostra el contenido de un slot existente en planograma
                <div className="productPlaced" onClick={this.showEditModal.bind(this, slotFound[0], true, slot, tray, vmTrays[tray])}>
                  <div className="slotText1">{slotFound[0].soProductCode}</div>
                  <div className="slotText">{amountAvailable}%</div>
                  <div className="slotText"># {slotFound[0].slotNumber}</div>
                </div>
              ) : (
                // Mostra el contenido de un slot sin configurar en planograma
                <div className="productPlacedWihtoutSetup" onClick={this.showEditModal.bind(this, slotFound[0], false, slot, tray, vmTrays[tray])}>
                  <div className="slotText1">{slotFound[0].soProductCode}</div>
                  <div className="slotText">{amountAvailable}%</div>
                  <div className="slotText"># {slotFound[0].slotNumber}</div>
                </div>
              )}
            </td>
          );
        } else {
          theSlot = (
            <td className={`${clsSuggested}`} key={`${tray},${slot}`} colSpan={slotDefaultSize} onClick={this.placeCurrentProduct.bind(this, slot, vmTrays[tray], wingInfo)}>
              <div> {Strings.generalTerms.free} </div>
            </td>
          );
        }
        fullTray.push(theSlot);
        theSlot = [];

        let slotSize = 0;
        if (slotFound[0]) {
          slotSize = slotFound[0].vmSlotSize - 1;
        }
        slot += slotSize;
      }
      res.push(
        <tr className={`${clsSuggestedTray}`} key={`${tray + 1}`}>
          {trayHeader}
          {fullTray}
        </tr>
      );
    }
    return res;
  }

  render() {
    const { wingInfo, scDependencyId, vmId, vmTrays, planogramInfo, isLoadingDisableButton, isLoadingSaveButton } = this.props;
    const { showSlotConfigModal, isEditFlag, slotFound, setUnblockedPlaceCurrentProduct } = this.state;

    if (!wingInfo || !scDependencyId || !vmId || vmTrays.length <= 0 || !planogramInfo) {
      return null;
    }

    return (
      <>
        <VmSlotInfoConfigModal
          isVisible={showSlotConfigModal}
          isEditFlag={isEditFlag}
          planogramInfo={planogramInfo.slots}
          slotInfo={slotFound}
          maxNumSlots={wingInfo.maxNumSlots}
          unblockedPlaceCurrentProduct={this.setUnblockedPlaceCurrentProduct}
          onClose={this.onCloseSlotInfoModal}
          newSlotToSetup={this.onSetupNewSlot}
          slotToEdit={this.onEditSlot}
          slotToDisable={this.onDeleteSlot}
          isLoadingDisableButton={isLoadingDisableButton}
          isLoadingSaveButton={isLoadingSaveButton}
        />

        <div style={{ padding: 0 }}>
          <div style={{ padding: 0 }}>
            <table className="machine" border="1" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td className="trayHeader" />
                  {this.renderHeader(wingInfo)}
                </tr>

                <tr>
                  <td className="trayHeader" />
                  {this.renderSubHeader(wingInfo)}
                </tr>
              </thead>
              <tbody>{this.renderSlot(wingInfo, vmTrays, planogramInfo.slots)}</tbody>
            </table>
          </div>

          {/* REVISAR ESTA IGUALDAD, SE DEBEN CONSULTAR LA CANTIDAD MÁXIMA DE BANDEJAS PARA EL TIPO DE MÁQUINA */}
          {vmTrays.length < wingInfo.maxNumTrays && (
            <Button className="addTray" onClick={() => this.addTray(wingInfo.wingId)}>
              <Icon type="plus-circle" theme="twoTone" twoToneColor="#52c41a" />
              <span>{Strings.machine.addTray}</span>
            </Button>
          )}
        </div>
        <div className="vertSpace col">
          <div className="col-xs-4">
            <Badge count="." style={{ width: "5px", backgroundColor: "#a7ffb7" }} />
            <span> {Strings.generalTerms.available} </span>
          </div>
          <div className="col-xs-4">
            <Badge count="." style={{ width: "5px", backgroundColor: "#BACBF5" }} />
            <span> {Strings.generalTerms.notAvailable} </span>
          </div>
          <div className="col-xs-4">
            <Badge count="." style={{ width: "5px", backgroundColor: "#f7d5af" }} />
            <span> {Strings.generalTerms.inConfiguration} </span>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    products: state.products_machine,
    productsToAdd: state.planogramProducts.productsToAdd,
    productsToEdit: state.planogramProducts.productsToEdit,
    productsToRemove: state.planogramProducts.productsToRemove,
  };
}

export default connect(mapStateToProps, {
  addProduct,
  updateProduct,
  removeProduct,
})(MachineGui);
