import axios from "axios";
import { host, path, port, version } from "../systemVariables";

// Method path
const resource = "/currencycodes";

export const LIST_CURRENCYCODE = "LIST_CURRENCYCODE";

export function listAllCurrencyCodes(callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        dispatch({ type: LIST_CURRENCYCODE, payload: response.data });
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}
