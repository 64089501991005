//Dependencies
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Divider} from 'antd';
import { DATE_TIME_FORMAT } from '../../../systemVariables/TimeConfig';
import moment from 'moment';
import TextWithInfoTooltip from '../../GenericComponents/textWithInfoTooltip';

// Actions
import {
  getCreditByUserId,
} from '../../../actions';

//Language localization
import Strings from '../../../systemVariables/languageStrings';
import { FreqUnitNameLocalization } from '../../../Utils/LanguageLocalization/frequencyUnitNameLocalization'

class CreditInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userId,
      creditInfo: [],
    };
  }

  componentDidMount() {
    this.getCreditByUserId();
  }

  getCreditByUserId() {
    this.setState({
      loadingInfo: true,
    });
     this.props.getCreditByUserId(this.state.userId, (response) => {
        this.setState({
            creditInfo : response.data.data,
            loadingInfo: false,
        });
    }); 
  }

  render() {
    return (
      <Fragment>

          <div className="row">

          <div className='col-xs-12 col-md-12'>
            <Divider orientation="left">
               <TextWithInfoTooltip name={Strings.myAccount.creditInfo} tooltip={Strings.users.creditByUser.creditAssignationTooltip}></TextWithInfoTooltip>
            </Divider>

             {this.state.creditInfo ?  

              <div>
                <div className="row">
                  <div className="col-6 col-md-4">{Strings.users.creditByUser.creditAssigned}:</div>
                  <div className="col-6 col-md-5">${this.state.creditInfo.creditAssigned}</div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">{Strings.dispensationRule.frequency}:</div>
                  <div className="col-6 col-md-5">{Strings.generalTerms.every} {this.state.creditInfo.frequencyValue} {FreqUnitNameLocalization(this.state.creditInfo.frequencyUnitName)}</div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">{Strings.users.creditByUser.creditAvailable}:</div>
                  <div className="col-6 col-md-5">${this.state.creditInfo.creditAvailable}</div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">{Strings.dispensationRule.startDate}:</div>
                  <div className="col-6 col-md-5">{moment(this.state.creditInfo.startDate).format(DATE_TIME_FORMAT)}</div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-4">{Strings.dispensationRule.endDate}:</div>
                {this.state.creditInfo.endDate ?
                  <div className="col-6 col-md-5">{moment(this.state.creditInfo.endDate).format(DATE_TIME_FORMAT)}</div>
                  :
                  <div className="col-6 col-md-5">{Strings.generalTerms.ilimited}</div>
                }
                </div>

              </div>
                   :
                   <div>
                    <p>{Strings.myAccount.noCreditInfoText}</p>
                  </div>
                  } 
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}
export default connect(mapStateToProps, {
  getCreditByUserId
})(CreditInfo);
