import React, {useState } from "react";
import { DataProvider } from "../../context/DataContext";

// Components
import { Icon, Spin, Divider, Button } from "antd";
import LockerSlotInfoConfig from "./LockerSlotInfoConfig";
import LockerInventariConf from "./LockerInventariConf";
import LockerInventariView from "./LockerInvetariView";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";

// Language localization
import Strings from "../../systemVariables/languageStrings";

import "./lockerGui.css";

const LockerGui = (props) => {

  return ( 
    <div className="col">
      <DataProvider lockerInfoList={props.lockerInfoList}>
        <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} spinning={!props.lockerInfoList.planogramInfo.isLoaded}> 
          <LockerSlotInfoConfig
            showJustInfo={props.previousPathDisabled}
          />
          <div className="col">
            <Divider orientation="left">
              <h3>
                {props.previousPathDisabled ? (
                  <TextWithInfoTooltip name={Strings.machineInventory.lockerInventory} tooltip={Strings.machineInventory.lockerInventoryTooltip} />
                ) : (
                  <TextWithInfoTooltip name={Strings.machine.lockerConfiguration} tooltip={Strings.machine.lockerConfigurationTooltip} />
                )}
              </h3>
            </Divider>
            { props.lockerInfoList.wingInfo.lockerTypeId === -1 
            ? <LockerInventariConf lockerInfoList={props.lockerInfoList} />
            : ""
            }
            <LockerInventariView 
              previousPathDisabled={props.previousPathDisabled}
            />
          </div>
        </Spin>
      </DataProvider>
    </div>
  );
}
 
export default LockerGui;