import axios from "axios";

import { host, port, path, version } from "../systemVariables";

const resource = "/commstatement";

export async function getCommTrafficStatementsByVmIdAndFreqUnitIdAndDateInterval(vmId, freqUnitId, startDate, endDate) {
  try {
    let response = await axios({
      method: "get",
      url: host + port + path + version + resource,
      params: { vendingmachineid: vmId, frequencyunitid: freqUnitId, startdate: startDate, enddate: endDate },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return { status: "ok", data: response.data.data };
  } catch (error) {
    return error;
  }
}

export async function getCommTrafficStatementsByScIdAndFreqUnitIdAndDateInterval(scId, freqUnitId, startDate, endDate) {
  try {
    let response = await axios({
      method: "get",
      url: host + port + path + version + resource,
      params: { scid: scId, frequencyunitid: freqUnitId, startdate: startDate, enddate: endDate },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return { status: "ok", data: response.data.data };
  } catch (error) {
    return error;
  }
}

export async function getCommTrafficStatementsBySoIdAndFreqUnitIdAndDateInterval(soId, freqUnitId, startDate, endDate) {
  try {
    let response = await axios({
      method: "get",
      url: host + port + path + version + resource,
      params: { soid: soId, frequencyunitid: freqUnitId, startdate: startDate, enddate: endDate },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return { status: "ok", data: response.data.data };
  } catch (error) {
    return error;
  }
}
