import axios from "axios";
import { host, path, port, version } from "../systemVariables";

const resource = "/scproductcinformation";

/**
 * Author: Daniel Molina Giraldo / Esteban E. Patiño
 * Retrieve a list of all the records from the Scproductinformation table filtered by ScId paged.
 *
 * @return Error code 200 when succeed.
 * - Used at scProductInfo.js
 */
export function listScProductInformationByScDependencyAndCodeOrName(
  scDependencyId,
  soProductName,
  scProductName,
  erpCode,
  scCode,
  soProductType,
  page,
  pageSize,
  attribute,
  order,
  callback
) {
  return async () => {
    await axios
      .get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId,
          soproductname: soProductName,
          scproductname: scProductName,
          erpcode: erpCode,
          sccode: scCode,
          soprodtype: soProductType,
          page,
          size: pageSize,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

/**
 * Author: Daniel Molina Giraldo
 * Retrieve a list of all the records from the Scproductinformation filtered by ScId.
 *
 * @return Error code 200 when succeed.
 */
export function getScProductInformationByScDependency(scDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function exportScProductInformationReport(scId, soProuctName, scProductName, erpCode, scCode, attribute, order, fileType, columnHeaders, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        responseType: "blob",
        params: {
          scdependencyid: scId,
          soproductname: soProuctName,
          scproductname: scProductName,
          erpcode: erpCode,
          sccode: scCode,
          attribute,
          order,
          filetype: fileType,
          columnheaders: encodeURIComponent(columnHeaders),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
        return error;
      });
  };
}

export function addScproductInformationAction(products, callback) {
  return () => {
    const data = JSON.stringify(products);
    axios({
      method: "post",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function editScProductInformation(scProduct, callback) {
  return () => {
    const data = JSON.stringify(scProduct);
    axios({
      method: "PUT",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function disableScProduct(scProductId, callback) {
  return () => {
    axios({
      method: "DELETE",
      url: `${host + port + path + version + resource}/${scProductId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}
