import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/vmtrays";

// export function listVmTraysByVmId(vmId, callback){ //To delete
//     return (dispatch, getState) => {
//         axios.get(host+ port + path  + version + resource, {
//             params : {
//                 vmId: vmId
//             },
//             headers: {
//             'Content-Type': 'application/json',
//             "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
//             },
//         })
//         .then((response) => {
//             callback(response)
//         })
//         .catch(function (error){
//             return error;
//         });
//     }   
// }

export function listVmTraysByWingId(wingId, callback) {
   return () => {
      axios.get(host + port + path + version + resource, {
         params: {
            wingid: wingId
         },
         headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
         },
      })
         .then((response) => {
            callback(response)
         })
         .catch(function (error) {
            return error;
         });
   }
}

export function updateVmTray(vmTray, callback) {
   return () => {
      let data = JSON.stringify(vmTray)
      axios({
         method: 'PUT',
         url: host + port + path + version + resource,
         data: data,
         headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
         }
      })
         .then((response) => {
            callback(response);
         })
         .catch(function (error) {
            return error
         });
   }
}

export function disbaleVmTrays(vmTrays, callback) {
   return () => {
      let data = JSON.stringify(vmTrays)
      axios({
         method: 'DELETE',
         url: host + port + path + version + resource,
         data: data,
         headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
         }
      })
         .then((response) => {
            callback(response);
         })
         .catch(function (error) {
            return error
         });
   }
}