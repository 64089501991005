import React, { useState, useRef } from "react";
import { Modal, Button, Input, Tooltip, Checkbox, Icon, Typography, Divider, DatePicker, TimePicker, RangePicker } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { isNull } from "lodash";
import Strings from "../../../systemVariables/languageStrings";

const { Title } = Typography;

const STRAT_DATE = moment().seconds(0);
const END_DATE = moment().seconds(0);
const Dates = { StartDate: null, StartTime: null, EndDate: null, EndTime: null };

const CleanLogsButton = (props) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(Strings.machine.machineCleanContentModal);
  const [input, setInput] = useState(true);
  const [eventRegisterClear, seteventRegisterClear] = useState(false);
  const [isDateSelectorDisabled, setisDateSelectorDisabled] = useState(false);
  const [startDate, setstartDate] = useState(STRAT_DATE);
  const [endDate, setendDate] = useState(END_DATE);
  const [dates, setDates] = useState(Dates);

  const inputRef = useRef(null);

  const statusOk = 201;
  const statusFail = 500;

  const { serialNumber, cleanApiApp, vmId } = props;

  let additionalInfo = [];

  const sharedProps = {
    style: {
      width: "70%",
      align: "center",
    },
    defaultValue: ``,
    ref: inputRef,
  };

  const onChangeCheckbox = (e) => {
    seteventRegisterClear(e.target.checked);
  };

  const onChangeCheckboxDate = (e) => {
    setisDateSelectorDisabled(e.target.checked);
    setDates((prevState) => ({
      ...prevState,
      EndDate: null,
      EndTime: null,
    }));
  };

  const handleDateChange = (date, stringdate, tipe) => {
    if (stringdate == "") {
      setDates((prevState) => ({
        ...prevState,
        [tipe]: null,
      }));
    } else {
      setDates((prevState) => ({
        ...prevState,
        [tipe]: stringdate,
      }));
    }
    if (!isDateSelectorDisabled) {
      setstartDate(
        moment(dates.StartDate + dates.StartTime, "YYYY-MM-DDHH:mm")
          .utc()
          .format()
      );
      setendDate(moment().seconds(0).utc().format());
    } else {
      setstartDate(
        moment(dates.StartDate + dates.StartTime, "YYYY-MM-DDHH:mm")
          .utc()
          .format()
      );
      setendDate(
        moment(dates.EndDate + dates.EndTime, "YYYY-MM-DDHH:mm")
          .utc()
          .format()
      );
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = async () => {
    setVisible(false);
    setConfirmLoading(false);
    inputRef.current.state.value = null;
  };

  const contentSuccessResponse = (data) => {
    additionalInfo = [];
    additionalInfo.push(
      <div className="row">
        <div className="vertSpace col-1" />
        <p>
          <b>{Strings.machine.recordsUpdatedByTable}</b>
        </p>
      </div>
    );
    data.responseUpdateRegistersDto.forEach((obj) =>
      additionalInfo.push(
        <div className="row">
          <div className="col-2" />
          <div className="col-8">
            <p>{obj.tableName} </p>
          </div>
          <div className="col-2">
            <p aling="right">{obj.quantityRegisters}</p>
          </div>
        </div>
      )
    );
    additionalInfo.push(
      <div className="row">
        <div className="vertSpace col-1" />
        <p>
          <b>{Strings.machine.recordsDeletedByTable}</b>
        </p>
      </div>
    );
    data.responseDeleteRegistersDto.forEach((obj) =>
      additionalInfo.push(
        <div className="row">
          <div className="col-2" />
          <div className="col-8">
            <p>{obj.tableName} </p>
          </div>
          <div className="col-2">
            <p aling="right">{obj.quantityRegisters}</p>
          </div>
        </div>
      )
    );

    return additionalInfo;
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    if (serialNumber === inputRef.current.state.value) {
      if (!isNull(dates.StartDate) && !isNull(dates.StartTime)) {
        if (!isDateSelectorDisabled || (!isNull(dates.EndDate) && !isNull(dates.EndTime))) {
          if (endDate >= startDate) {
            let recoveryAwait = statusFail;
            await cleanApiApp(vmId, startDate, endDate, eventRegisterClear, (response) => {
              if (response.status === statusOk) {
                recoveryAwait = statusOk;
                Modal.success({
                  title: `${Strings.generalResponses.successfulTransaction}`,
                  content: contentSuccessResponse(response.data.data),
                });
                handleCancel();
              } else {
                alert("Error");
                handleCancel();
              }
            });
            if (recoveryAwait === statusFail) {
              Modal.error({
                title: `${Strings.generalResponses.connectionError}`,
                content: `${Strings.generalResponses.connectionErrorTooltip}`,
              });
              handleCancel();
            }
          } else {
            Modal.error({
              title: `${Strings.machine.invalidDateTimeInformation.title}`,
              content: `${Strings.machine.invalidDateTimeInformation.errorStartDateGreaterByEndDate}`,
            });
            handleCancel();
          }
        } else {
          Modal.error({
            title: `${Strings.machine.invalidDateTimeInformation.title}`,
            content: `${Strings.machine.invalidDateTimeInformation.invalidEndDate}`,
          });
          handleCancel();
        }
      } else {
        Modal.error({
          title: `${Strings.machine.invalidDateTimeInformation.title}`,
          content: `${Strings.machine.invalidDateTimeInformation.invalidStartDate}`,
        });
        handleCancel();
      }
    } else {
      setConfirmLoading(false);
      setVisible(false);
      inputRef.current.state.value = null;
      Modal.error({
        title: `${Strings.machine.serialError.title}`,
        content: ` ${Strings.machine.serialError.content} ${serialNumber}`,
      });
    }
  };

  return (
    <>
      <Tooltip title={Strings.machine.machineCleanTooltipButton}>
        <Button icon="delete" type="primary" onClick={showModal}>
          {Strings.machine.cleanLogsButton}
        </Button>
      </Tooltip>
      <Modal visible={visible} onOk={handleOk} confirmLoading={confirmLoading} cancelButtonProps={{ disabled: confirmLoading }} onCancel={handleCancel}>
        <Title level={4}>
          {Strings.machine.machineClean}{" "}
          <Tooltip title={Strings.machine.machineCleanTooltipButton}>
            <Icon type="exclamation-circle" />
          </Tooltip>
        </Title>
        <Divider />
        <p>{modalText}</p>
        <div className="row vertSpace">
          <div className="col-12 selectAligning ">
            <Checkbox checked={eventRegisterClear} onChange={onChangeCheckbox}>
              {Strings.machine.deleteMachineEventlogs}
            </Checkbox>
          </div>
        </div>
        <div className="row vertSpace">
          <Checkbox checked={isDateSelectorDisabled} onChange={onChangeCheckboxDate}>
            {Strings.machine.enableRangeDates}
          </Checkbox>
        </div>
        <div className="row vertSpace">
          <div className="selectAligning col-12">
            <div className="row col-4">
              <p>
                <b> {`${Strings.vmServiceRequest.startDate}: `}</b>
              </p>
            </div>
            <div className="row col-4">
              <DatePicker placeholder={Strings.generalTerms.date} onChange={(eMoment, eString) => handleDateChange(eMoment, eString, "StartDate")} size="default" />
            </div>
            <div className="row col-4">
              <TimePicker format="HH:mm" placeholder={Strings.timeUnits.hour} onChange={(eMomnt, eString) => handleDateChange(eMomnt, eString, "StartTime")} size="default" />
            </div>
          </div>
          {!isDateSelectorDisabled ? (
            <></>
          ) : (
            <div className="selectAligning  col-12">
              <div className="row col-4">
                <p>
                  <b> {`${Strings.vmServiceRequest.endDate}: `}</b>
                </p>
              </div>
              <div className="row col-4">
                <DatePicker placeholder={Strings.generalTerms.date} onChange={(eMoment, eString) => handleDateChange(eMoment, eString, "EndDate")} size="default" />
              </div>
              <div className="row col-4">
                <TimePicker format="HH:mm" placeholder={Strings.timeUnits.hour} onChange={(eMoment, eString) => handleDateChange(eMoment, eString, "EndTime")} size="default" />
              </div>
            </div>
          )}
        </div>
        <div className="row vertSpace">
          <div>
            <div className="selectAligning  col-12">
              {Strings.machine.disableMachineMsg}:{"   "}
            </div>
          </div>
          <div className="col-5"> {input ? <Input {...sharedProps} /> : <Input.TextArea {...sharedProps} />}</div>
        </div>
      </Modal>
    </>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(CleanLogsButton);
