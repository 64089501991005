// Dependencies
import { Divider, Input, InputNumber, Modal, Table } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import { SaveButton } from "../../GenericComponents/buttons/index";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";

// Actions
import { listAllProductTaxTypeAction, listFrequencyUnits, updateLockerSlotPriceBySizeInfo } from "../../../actions";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { FreqUnitNameLocalizationPlural } from "../../../Utils/LanguageLocalization/frequencyUnitNameLocalization";

const { Column, ColumnGroup } = Table;

class LockerLoanConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vm: this.props.vm,
      lockerLoanConfigInfo: this.props.lockerLoanConfigInfo,
      redirectToVMList: false,
      infoToUpdate: [],
      productTaxTypeId: this.props.lockerLoanConfigInfo[0].productTaxTypeId ? this.props.lockerLoanConfigInfo[0].productTaxTypeId : null,
      scDependencyId: this.props.lockerLoanConfigInfo[0].scDependencyId ? this.props.lockerLoanConfigInfo[0].scDependencyId : null,
      vendingMachineId: this.props.lockerLoanConfigInfo[0].vendingMachineId ? this.props.lockerLoanConfigInfo[0].vendingMachineId : null,
      disabledSaveButton: true,
      isScDependencyFirstChecked: !!this.props.lockerLoanConfigInfo[0].scDependencyId,
      isScDependencyChecked: !!this.props.lockerLoanConfigInfo[0].scDependencyId,
    };
  }

  componentDidMount() {
    const { lockerLoanConfigInfo } = this.state;
    const { listFrequencyUnits, listAllProductTaxTypeAction } = this.props;

    if (!lockerLoanConfigInfo) {
      this.setState({ redirectToVMList: true });
    } else {
      // Get the list of frequency units.
      listFrequencyUnits((response) => {
        if (response.data.data) {
          const nativeFrequencyUnits = response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          this.setState({
            frequencyUnits: FreqUnitNameLocalizationPlural(nativeFrequencyUnits),
          });
        }
      });
      listAllProductTaxTypeAction((response) => {
        if (response.data.data) {
          const prodTaxTypes = response.data.data.map((item) => ({
            value: item.id,
            label: `${item.value}%`,
          }));
          this.setState({ prodTaxTypes });
        }
      });
    }
  }

  inputHandleEvent(value, row, name) {
    // general info update
    const { lockerLoanConfigInfo } = this.state;

    if (name === "frequencyValue" && value === null) {
      value = 1;
    }

    row[name] = value;
    this.setState({
      lockerLoanConfigInfo: lockerLoanConfigInfo.map((item) => {
        if (item.id === row.id) {
          item = row;
        }
        return item;
      }),
    });

    const { infoToUpdate } = this.state;
    const infoToUpdateValidation = infoToUpdate.filter((item) => item.id === row.id);
    if (infoToUpdateValidation.length > 0) {
      _.remove(infoToUpdate, (obj) => obj.id === row.id);
    }
    infoToUpdate.push(row);
    this.setState({ infoToUpdate });

    if (infoToUpdate.length > 0) {
      this.setState({ disabledSaveButton: false });
    } else {
      this.setState({ disabledSaveButton: true });
    }
  }

  onSelect(option) {
    // ProductTaxType
    this.setState({ productTaxTypeId: option.value });

    if (option.value !== this.props.lockerLoanConfigInfo[0].productTaxTypeId) {
      this.setState({ disabledSaveButton: false });
    } else {
      this.setState({ disabledSaveButton: true });
    }
  }

  handleCheckboxOnChange(e) {
    // Scdependency logic
    if (e.target.checked) {
      this.setState({
        scDependencyId: this.state.vm.scDependencyId,
        vendingMachineId: null,
        isScDependencyChecked: true,
      });
    } else {
      this.setState({
        scDependencyId: null,
        vendingMachineId: this.state.vm.id,
        isScDependencyChecked: false,
      });
    }

    if (e.target.checked !== this.state.isScDependencyFirstChecked) {
      this.setState({ disabledSaveButton: false });
    } else {
      this.setState({ disabledSaveButton: true });
    }
  }

  handleOnEditButton(event) {
    const infoToUpdateDTO = {
      lockerSlotPriceBySizeDtos: [],
      scDependencyId: null,
      oldScId: this.state.vm.scDependencyId,
      vendingMachineId: null,
      oldVmId: this.state.vm.id,
      productTaxTypeId: null,
    };

    // If ProductTaxType Changes
    if (this.state.productTaxTypeId !== this.props.lockerLoanConfigInfo[0].productTaxTypeId) {
      infoToUpdateDTO.productTaxTypeId = this.state.productTaxTypeId;
    }
    // If SC setup changed (not used)
    if (this.state.scDependencyId !== this.props.lockerLoanConfigInfo[0].scDependencyId) {
      infoToUpdateDTO.scDependencyId = this.state.scDependencyId;
    }
    // If VM setup changed (not used)
    if (this.state.vendingMachineId !== this.props.lockerLoanConfigInfo[0].vendingMachineId) {
      infoToUpdateDTO.vendingMachineId = this.state.vendingMachineId;
    }
    // If setup config changes
    if (this.state.infoToUpdate.length > 0) {
      infoToUpdateDTO.lockerSlotPriceBySizeDtos = this.state.infoToUpdate;
    }

    if (infoToUpdateDTO) {
      this.props.updateLockerSlotPriceBySizeInfo(infoToUpdateDTO, (response, error) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
          });
          this.setState({ redirectBackToListLockerLoanExceptionByUser: true });
        } else {
          // Other error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        }
      });
    } else {
      // Other error
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: Strings.generalResponses.alreadyExistsError,
      });
      this.setState({
        loadingAdd: false,
      });
    }
  }

  render() {
    const { prodTaxTypes, productTaxTypeId, lockerLoanConfigInfo, loading, frequencyUnits, isSaveButtonLoading, disabledSaveButton, redirectToVMList } = this.state;

    if (redirectToVMList) {
      return (
        <Redirect
          to={{
            pathname: "/listVM",
          }}
        />
      );
    }
    return (
      <>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <Divider orientation="left">
              <TextWithInfoTooltip
                name={Strings.machine.lockerSlotPriceBySize.lockerLoanPriceConfig}
                tooltip={Strings.machine.lockerSlotPriceBySize.lockerLoanPriceConfigTooltip}
              />
            </Divider>

            <div className="row">
              <div className="vertSpace col-md-7">
                <SelectGeneral
                  text={Strings.product.selectTaxValue}
                  name="productTaxTypeId"
                  options={prodTaxTypes}
                  defaultValue={productTaxTypeId}
                  tooltip={Strings.product.soTaxValueTooltip}
                  onChange={(option, event) => this.onSelect(option, event)}
                  isReq
                />
              </div>
              <div className="vertSpace col-md-2" />
              <div className="vertSpace col-md-3">
                <SaveButton onClick={(e) => this.handleOnEditButton(e)} loading={isSaveButtonLoading} isDisabled={disabledSaveButton} />
              </div>
            </div>

            <div className="row">
              <div className="vertSpace col-12" />
              <Table dataSource={lockerLoanConfigInfo} bordered loading={loading} pagination={false} size="middle" scroll={{ y: 250 }} rowKey="id">
                <ColumnGroup title={Strings.planogram.slotDimension} key="slotDimension">
                  <Column title={Strings.product.width} dataIndex="widthSpan" />
                  <Column title={Strings.product.height} dataIndex="heightSpan" />
                </ColumnGroup>

                <ColumnGroup title={Strings.generalTerms.parameterization} key="slotParameterization">
                  <Column
                    title={Strings.generalTerms.name}
                    align="center"
                    render={(row) => (
                      <div>
                        <Input loading={false} defaultValue={row.customName} className="basic-single" onChange={(e) => this.inputHandleEvent(e.target.value, row, "customName")} />
                      </div>
                    )}
                  />

                  <Column
                    title={Strings.generalTerms.description}
                    align="center"
                    render={(row) => (
                      <div>
                        <Input
                          loading={false}
                          defaultValue={row.description}
                          className="basic-single"
                          onChange={(e) => this.inputHandleEvent(e.target.value, row, "description")}
                        />
                      </div>
                    )}
                  />

                  <Column
                    title={Strings.product.preTaxPrice}
                    align="center"
                    render={(row) => (
                      <div>
                        <InputNumber min={0} defaultValue={row.preTaxPrice} onChange={(value) => this.inputHandleEvent(value, row, "preTaxPrice")} />
                      </div>
                    )}
                  />

                  <Column
                    title={Strings.dispensationRule.frequency}
                    align="center"
                    render={(row) => (
                      <div>
                        <InputNumber min={1} defaultValue={row.frequencyValue} onChange={(value) => this.inputHandleEvent(value, row, "frequencyValue")} />
                      </div>
                    )}
                  />

                  <Column
                    title={Strings.dispensationRule.frequencyUnit}
                    align="center"
                    render={(row) => (
                      <div>
                        {frequencyUnits ? (
                          <Select
                            className="basic-single"
                            options={frequencyUnits}
                            value={row.frequencyUnitId >= 0 ? frequencyUnits.filter((item) => item.value === row.frequencyUnitId) : null}
                            //  value={row.frequencyUnitId >= 0 ? this.state.frequencyUnits[row.frequencyUnitId] : null}
                            onChange={(value) => this.inputHandleEvent(value.value, row, "frequencyUnitId")}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  />
                </ColumnGroup>
              </Table>
              <br />
            </div>

            {/*          <div className="row">
          <div className="vertSpace col-md-6">
                    <GeneralCheckbox
                      text={Strings.machine.lockerSlotPriceBySize.lockerLoanPriceConfigByScDependency}
                      tooltip={Strings.machine.lockerSlotPriceBySize.lockerLoanPriceConfigByScDependencyTooltip}
                      isChecked={this.state.isScDependencyChecked}
                      onChange={e => this.handleCheckboxOnChange(e)}
                    />
                  </div>
          </div> */}
            <br />
            <Divider />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}
export default connect(mapStateToProps, {
  listFrequencyUnits,
  listAllProductTaxTypeAction,
  updateLockerSlotPriceBySizeInfo,
})(LockerLoanConfig);
