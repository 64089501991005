import Strings from "../../systemVariables/languageStrings";

export function transactionTypeLocalization(transactionType) {
  switch (transactionType) {
    case "STOCKED":
      transactionType = Strings.transactionType.stocked;
      break;
    case "DISPENSED":
      transactionType = Strings.transactionType.dispensed;
      break;
    case "UNDERSTOCK AMENDMENT":
      transactionType = Strings.transactionType.understockAmendment;
      break;
    case "OVERSTOCK AMENDMENT":
      transactionType = Strings.transactionType.overstockAmendment;
      break;
    case "DISPENSATION FAILED":
      transactionType = Strings.transactionType.dispensationFailed;
      break;
    case "DISPENSED WITH LAST PUSH":
      transactionType = Strings.transactionType.dispensedWithLastPush;
      break;
    case "DISPENSATION ADJUSTMENT":
      transactionType = Strings.transactionType.dispensationAdjustment;
      break;
    case "DISPENSATION FAILED CASHLESS TELIUM":
      transactionType = Strings.transactionType.dispensationFailedCashless;
      break;
    case "NEQUI REFUND":
      transactionType = Strings.transactionType.dispensationFailedNequi;
      break;
    case "CANCELLED BY USER":
      transactionType = Strings.transactionType.dispensationCancelledByUser;
      break;
    case "CANCELLED FOR INACTIVITY":
      transactionType = Strings.transactionType.dispensationCancelledForInactivity;
      break;
    default:
      break;
  }
  return transactionType;
}
