/* eslint-disable no-use-before-define */
import React from "react";
import { connect } from "react-redux";

// Components
import { Card, Timeline } from "antd";
import Strings from "../../../systemVariables/languageStrings";

// Images
import User from "../../Global/images/ComponentsSVG/User";

// Language localization
import { paymentTypeLocalization } from "../../../Utils/LanguageLocalization";

const { Meta } = Card;

const UserTransactionSummary = ({ transactionInfo, userImage }) => {
  const UserTitleRender = () => {
    return (
      <>
        <div className="row" style={{ margin: "10px" }}>
          <Meta
            avatar={<User width={40} fill="#004173" />}
            style={{ margin: "5px" }}
            title={
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-12">
                  <h2 className="paragraphSubTitle">{Strings.users.userInformation}</h2>
                </div>
              </div>
            }
          />
        </div>
      </>
    );
  };

  const userTransactionInfoRender = () => {
    return (
      <div className="row">
        <Card className="customCard" title={UserTitleRender()}>
          <>
            {userImageRender()}
            <Meta
              title={
                <strong>
                  {transactionInfo.userIdentityCardNumber === "--" || transactionInfo.userIdentityCardNumber === "9998"
                    ? Strings.users.anonymousUser.toUpperCase()
                    : transactionInfo?.userCompleteName.toUpperCase()}
                </strong>
              }
              description={
                transactionInfo.userIdentityCardNumber === "--" || transactionInfo.userIdentityCardNumber === "9998"
                  ? null
                  : `${Strings.users.identityCardNumber}: ${transactionInfo?.userIdentityCardNumber}`
              }
            />
            <br />
            {userInfoRender()}
          </>
        </Card>
      </div>
    );
  };

  const userImageRender = () => {
    return (
      <>
        {userImage === "/static/media/NotAvailableImg.d670ef9a.png" ? null : (
          <div>
            <img className="userImg" alt="User" src={userImage} />
          </div>
        )}
      </>
    );
  };

  const userInfoRender = () => {
    return (
      <>
        {transactionInfo.scUserProfileName || transactionInfo.scDepartmentName || transactionInfo.scCostCenterName || transactionInfo.scDispensationRuleName ? (
          <Timeline>
            {transactionInfo.scUserProfileName ? <Timeline.Item color="green">{`${Strings.scUserProfile.userProfile}: ${transactionInfo.scUserProfileName}`}</Timeline.Item> : null}
            {transactionInfo.scDepartmentName ? <Timeline.Item color="green">{`${Strings.scDepartment.department}: ${transactionInfo.scDepartmentName}`}</Timeline.Item> : null}
            {transactionInfo.scCostCenterName ? <Timeline.Item color="green">{`${Strings.scCostCenter.costCenter}: ${transactionInfo.scCostCenterName}`}</Timeline.Item> : null}
            {transactionInfo.scDispensationRuleName ? (
              <Timeline.Item color="green">{`${Strings.dispensationRule.dispensationRule}: ${transactionInfo.scDispensationRuleName}`}</Timeline.Item>
            ) : null}
            {transactionInfo.paymentTypeId ? (
              <Timeline.Item color="green">{`${Strings.reports.userEnrollmentInfo}: ${paymentTypeLocalization(transactionInfo.paymentTypeId).toUpperCase()}`}</Timeline.Item>
            ) : null}
          </Timeline>
        ) : null}
      </>
    );
  };

  return userTransactionInfoRender();
};

const mapStateToProps = (state) => ({
  permissions: state.userSystemPrivileges.privilegeObj,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserTransactionSummary);
