import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Tooltip, Icon, Button } from "antd";
import "./buttons.css";
import Strings from "../../../systemVariables/languageStrings";

export function AssignButton(props) {
  return (
    <Fragment>
      {props.link ? (
        <Link
          to={{
            pathname: props.link,
            props: props.props,
          }}
        >
          <Tooltip title={props.tooltip}>
            <Button className="button" disabled={props.disabled} onClick={props.onClick} loading={props.loading}>
              {props.loading ? "" : <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" hidden={props.hideIcon} />}
              <span>{Strings.generalTerms.assign}</span>
            </Button>
          </Tooltip>
        </Link>
      ) : (
        <Tooltip title={props.tooltip}>
          <Button className="button" disabled={props.disabled} onClick={props.onClick} loading={props.loading}>
            {props.loading ? "" : <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" hidden={props.hideIcon} />}
            <span>{Strings.generalTerms.assign}</span>
          </Button>
        </Tooltip>
      )}
    </Fragment>
  );
}
