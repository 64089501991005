// Dependencies
import React, { Component } from 'react';
import Select from 'react-select';
import { Table, Icon, InputNumber, Checkbox } from 'antd';

// Components
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";

// Language localization
import Strings from '../../systemVariables/languageStrings';

class VmProductGroupConfigToDispensationRuleTable extends Component {
  inputNumberHandleEvent (value, row, name) {
    row[name] = value
    this.props.editSelectedProductGroup(row);
  }

  selectHandleEvent(value, row, name) {
    row[name.name] = value;
    this.props.editSelectedProductGroup(row);
  }
  
  onChange = (e, row) => {
    row.isIlimited = e.target.checked;
    if(e.target.checked === true){
      row.selectedAmount = null;
      row.selectedFrequency = null;
      row.selectedFrequencyUnit = this.props.frequencyUnits[0];
    } else {
      row.selectedAmount = 1;
      row.selectedFrequency = 1;
      row.selectedFrequencyUnit = null;
    }
    this.props.ilimitedProductGroupSelected(row);
  }

  render() {
    const columns = [
      {
          title: <TextWithInfoTooltip name={Strings.vmProductGroup.vmProductGroup} tooltip={Strings.vmProductGroup.vmProductGroupNameToolTip} />,
          dataIndex: "name",
      },
      {
          title: Strings.generalTerms.description,
          dataIndex: "description",
      },
      {
        title: Strings.generalTerms.ilimited,
        align: 'center',
        render: row => (
          
          <div>
            <Checkbox 
              onChange={(e) => this.onChange(e, row)}
              checked= {row.isIlimited}
               />
          </div>
        )
      },
      {
        title: Strings.dispensationRule.amount,
        align: 'center',
        render: row => (
          this.props.isEditFlag === false ?
            <div>
              <InputNumber 
                min={1}
                defaultValue={1} 
                onChange={(value)=>this.inputNumberHandleEvent(value, row, 'selectedAmount')} 
                disabled = {row.isIlimited}         
              />
            </div>
          :  
            <div>
              <InputNumber 
                min={1}
                value={row.selectedAmount} 
                onChange={(value)=>this.inputNumberHandleEvent(value, row, 'selectedAmount')} 
                disabled = {row.isIlimited}         
              />
            </div>
        )
      },
      {
        title: Strings.dispensationRule.frequency,
        align: 'center',
        render: row => (
          this.props.isEditFlag === false ?
          <div>
            <InputNumber 
              min={1} 
              defaultValue={1} 
              onChange={(value)=>this.inputNumberHandleEvent(value, row, 'selectedFrequency')} 
              disabled = {row.isIlimited}         
            />
          </div>
          :
          <div>
            <InputNumber 
              min={1} 
              value= {row.selectedFrequency} 
              onChange={(value)=>this.inputNumberHandleEvent(value, row, 'selectedFrequency')} 
              disabled = {row.isIlimited}         
            />
          </div>
        )
      },
      {
        title: Strings.dispensationRule.frequencyUnit,
        align: 'center',
        render: row => (
          this.props.isEditFlag === false ?
          <div>
            <Select
              loading={false}
              name="selectedFrequencyUnit"
              className="basic-single"
              options={this.props.frequencyUnits ? this.props.frequencyUnits: null}
              placeholder={Strings.generalTerms.select}
              onChange={(value, name)=>this.selectHandleEvent(value, row, name.name)}
              isDisabled= {row.isIlimited}
            />
          </div>
          :
          <div>
            <Select
              loading={false}
              name="selectedFrequencyUnit"
              className="basic-single"
              options={this.props.frequencyUnits ? this.props.frequencyUnits: null}
              value={row.selectedFrequencyUnit ? (row.selectedFrequencyUnit.value >= 0 ? this.props.frequencyUnits[row.selectedFrequencyUnit.value] : null) : null}
              onChange={(value, name)=>this.selectHandleEvent(value, row, name)}
              isDisabled= {row.isIlimited}
            />
          </div>
        )
      },
      {
        // title: Strings.generalTerms.remove,
        align: 'center',
        render: row => (
          <div>
            <Icon 
              style={{ cursor: 'pointer', fontSize: 20 }} 
              type="close-circle" 
              theme="twoTone" 
              className="addEditRemoveButton" 
              onClick={()=> this.props.removeSelectedVmProductGroup(row)}
           />
          </div>
        )
      },
    ];

    return (
      <div className="row">
        <div className="col">
          <Table
            dataSource={this.props.vmProductGroupsAssigned}
            pagination = {false} 
            columns={columns}
            loading={this.props.isLoading}
            bordered
            rowKey='id'
            scroll={{x:'auto'}}
          />
        </div>
      </div>
    );
  }
}

export default VmProductGroupConfigToDispensationRuleTable;

