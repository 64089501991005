import { LIST_SODEPENDENCIESTYPE0, SODEPENDENCYPARENT, LIST_SODEPENDENCYLOWERELEMENTS } from "../actions";

export function soDependencies(state = [], action) {
  switch (action.type) {
    case LIST_SODEPENDENCYLOWERELEMENTS:
      var soDependenciesLowerElements = action.payload.data.soDependencyGetDTO.map((item) => ({ value: item.id + "@" + item.soDependenctTypeId, label: item.name }));
      return soDependenciesLowerElements;
    default:
      return state;
  }
}

export function soDependencyParent(state = [], action) {
  switch (action.type) {
    case SODEPENDENCYPARENT:
      return Object.assign({}, state, { soParent: action.payload.data });
    default:
      return state;
  }
}

export function soDependenciesType0(state = [], action) {
  switch (action.type) {
    case LIST_SODEPENDENCIESTYPE0:
      var soDependenciesType0 = action.payload.data.map((item) => ({ value: item.id + item.soDependenctTypeId, label: item.name }));
      return soDependenciesType0;
    default:
      return state;
  }
}

export function soDependenciesType0WithAllOption(state = [], action) {
  switch (action.type) {
    case LIST_SODEPENDENCIESTYPE0:
      var soDependenciesType0 = action.payload.data.map((item) => ({ value: item.id + item.soDependenctTypeId, label: item.name }));
      return soDependenciesType0;
    default:
      return state;
  }
}
