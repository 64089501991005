import React from "react";
import { Divider } from "antd";
import { SelectGeneralMultiple } from "../../GenericComponents/selectComponent/index";
import Strings from "../../../systemVariables/languageStrings";

const AssignUsersToVendingMachineForm = (props) => {
  const { vendingMachines, user } = props;
  return (
    <>
      <div className="row">
        <div className="vertSpace col">
          <Divider orientation="left">
            <h3>{Strings.machine.userByVendingMachine} </h3>
          </Divider>
        </div>
      </div>
      <div className="row">
        <div className="vertSpace col-md-6">
          <SelectGeneralMultiple
            tooltip={Strings.machine.machineTooltip}
            text={Strings.machine.machine}
            style={{ width: "100%" }}
            options={vendingMachines}
            selectedOptions={user.vendingMachineIds}
            isReq={false}
            placeholder={Strings.generalTerms.select}
            onChange={(options) => props.onMultiSelect(options, "vendingMachineIds")}
            allowClear
          />
        </div>
      </div>
    </>
  );
};
export default AssignUsersToVendingMachineForm;
