//Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "../../../../reducers/products/actions";
import { Table } from "antd";
import Select from "react-select";
import { listSoProductSlotTypeBySoProductId } from "../../../../actions/index";

// Components
import Loading from "../../../GenericComponents/loadingComponent/loading";
import SelectOption from "../../../GenericComponents/selectOption";
import TextWithInfoTooltip from "../../../GenericComponents/textWithInfoTooltip";

//Language localization
import Strings from "../../../../systemVariables/languageStrings";
import { helixTypeLocalization, SlotSizeNameLocalization } from "../../../../Utils/LanguageLocalization/index";

const { Column } = Table;
const { fetchProducts, selectProduct, selectVariation } = actions;

class ProductTable extends Component {
  columns = [
    {
      title: <TextWithInfoTooltip name={Strings.planogram.size} tooltip={Strings.planogram.sizeTooltip} />,
      render: (row) => {
        //Map helix type
        return <div>{SlotSizeNameLocalization(row.slotSizeName)} </div>;
      },
    },
    {
      title: <TextWithInfoTooltip name={Strings.planogram.helix} tooltip={Strings.planogram.helixTooltip} />,
      render: (row) => {
        //Map helix type
        return <div>{helixTypeLocalization(row.helixL, row.helixH, row.spaces, row.turnTypeNameL, row.length)} </div>;
      },
    },
    {
      title: <TextWithInfoTooltip name={Strings.planogram.motorConfig} tooltip={Strings.planogram.motorConfigTooltip} />,
      dataIndex: "motorL",
      key: "motorL",
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      scProducts: [],
      soProductSlots: [],

      selected: {},
      selectedRowKeys: [],

      loadingInfo: true,
      flag: true,
    };
  }

  componentDidMount() {
    this.setState({ selectedRowKeys: [] });
  }

  componentDidUpdate() {
    const { scProducts2, listSoProductSlotTypeBySoProductId } = this.props;
    const { flag } = this.state;

    if (flag === true) {
      if (scProducts2.length > 0) {
        listSoProductSlotTypeBySoProductId(scProducts2[0].value, (response) => {
          this.setState({
            soProductSlots: response.data.data,
            flag: false,
            loadingInfo: false,
          });
        });
      }
    }
  }

  //-----------Events------------
  selectHandleChange = (item_id) => {
    const { listSoProductSlotTypeBySoProductId } = this.props;

    listSoProductSlotTypeBySoProductId(item_id.value, (response) => {
      this.setState({
        soProductSlots: response.data.data,
        loadingInfo: false,
      });
    });
  };

  handleRowSelect = (index, record) => {
    const { selectVariation } = this.props;

    selectVariation(record);
  };

  render() {
    const {
      products: { selected_variation },
      scProducts2,
    } = this.props;

    const { soProductSlots, loadingInfo } = this.state;

    if (scProducts2.length === 0) {
      return <SelectOption />;
    }
    if (!scProducts2) {
      return <Loading />;
    }

    const rowSelection = {
      hideDefaultSelections: false,
      type: "radio",
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.handleRowSelect(selectedRowKeys, selectedRows[0]);
        this.setState({ selectedRowKeys });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };

    return (
      <div className="row">
        <div className="vertSpace col-md-12">
          <Select options={scProducts2} defaultValue={scProducts2.length > 0 ? scProducts2[0] : null} onChange={this.selectHandleChange} />
        </div>
        <div className="vertSpace col-md-12">
          <br />
          <Table
            dataSource={soProductSlots.length > 0 ? soProductSlots : null}
            bordered
            loading={loadingInfo}
            pagination={false}
            style={{ whiteSpace: "normal" }}
            rowKey={"id"}
            rowSelection={rowSelection}
            selections={selected_variation}
            scroll={{ x: "100%",  y: 300 }}
            size={"middle"}
          >
            <Column
              title={<TextWithInfoTooltip name={Strings.planogram.size} tooltip={Strings.planogram.sizeTooltip} />}
              render={(row) => <div>{SlotSizeNameLocalization(row.slotSizeName)} </div>}
            />
            <Column
              title={<TextWithInfoTooltip name={Strings.planogram.helix} tooltip={Strings.planogram.helixTooltip} />}
              render={(row) => <div>{helixTypeLocalization(row.helixL, row.helixH, row.spaces, row.turnTypeNameL, row.length)} </div>}
            />
            <Column title={<TextWithInfoTooltip name={Strings.planogram.motorConfig} tooltip={Strings.planogram.motorConfigTooltip} />} dataIndex="motorL" key="motorL" />
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products_machine,
});

export default connect(mapStateToProps, {
  listSoProductSlotTypeBySoProductId,

  fetchProducts,
  selectProduct,
  selectVariation,
})(ProductTable);
