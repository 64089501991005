/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";

// -----Components-----
import { SelectIndirectClient } from "../GenericComponents/selectComponent/index";

// -----Actions-------
import { listScDependenciesBySoDependencyId, addVmContract } from "../../actions";

// Language localization
import Strings from "../../systemVariables/languageStrings";

class VmContractModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsgFlag: false,
      scDependencyId: null,
      isLoading: false,
    };
  }

  scDependencyHandleChange(event) {
    this.setState({ scDependencyId: event.value, errorMsgFlag: false });
  }

  handleOkVmContract = () => {
    this.setState({ isLoading: true });
    const { isVisible, addVmContract, vendingmachineId, setIsVisible } = this.props;
    const { scDependencyId } = this.state;
    if (isVisible) {
      const vmContractDto = {
        vendingmachineId,
        scdependencyId: scDependencyId,
      };
      if (scDependencyId) {
        this.setState({ isLoading: true });
        addVmContract(vmContractDto, (response) => {
          if (!response.data && String(response).includes("Error:")) {
            // Connection error
            Modal.error({
              title: Strings.generalResponses.failedTransaction,
              content: Strings.generalResponses.saveError,
            });
          } else if (response.data.code === 4001) {
            // Identity card number already exists in the Sc
            Modal.error({
              title: Strings.generalResponses.failedTransaction,
              content: Strings.generalResponses.alreadyExistsError,
            });
          } else if (response.data.code === 0) {
            // Entity Successfully  saved
            Modal.success({
              title: Strings.generalResponses.successfulTransaction,
              content: Strings.generalResponses.saveSuccess,
            });
            setIsVisible(false);
            this.setState({ isLoading: false });
          }
        });
      } else {
        this.setState({
          isLoading: false,
          errorMsgFlag: true,
        });
      }
    }
  };

  handleCancel = () => {
    const { setIsVisible } = this.props;
    this.setState({ isLoading: false });
    setIsVisible(false);
  };

  render() {
    const { isLoading, errorMsgFlag } = this.state;
    const { isVisible, scDependencyList } = this.props;
    return (
      <Modal title={Strings.machine.assignContract} onOk={this.handleOkVmContract} onCancel={this.handleCancel} okButtonProps={{ loading: isLoading }} visible={isVisible} centered>
        <div>
          {errorMsgFlag ? (
            <div className="row">
              <div id="div_center_msgError" className="col-md-12">
                <h3>{Strings.scDependency.noDependencySelected}</h3>
                <hr />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-md-12">
              <SelectIndirectClient scDependencies={scDependencyList} onChange={(e) => this.scDependencyHandleChange(e)} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    scDependencies: state.scDependencies,
  };
}

export default connect(mapStateToProps, {
  listScDependenciesBySoDependencyId,
  addVmContract,
})(VmContractModal);
