import { Button, Divider, Icon, Input, Modal, Table, Typography } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { productTransactionTypeLocalizationLocalization } from "../../Utils/LanguageLocalization";
import { DATE_TIME_FORMAT } from "../../systemVariables/TimeConfig";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";

// Components
import Loading from "../GenericComponents/loadingComponent/loading";

// Language localization
import Strings from "../../systemVariables/languageStrings";

const { Column } = Table;
const { Paragraph } = Typography;

class ProvisioningSummaryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCancel = () => {
    const { setIsVisible } = this.props;
    setIsVisible(false);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} by ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(clearFilters, selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearch = (selectedKeys, dataIndex) => {
    const searchObj2 = {};
    const { searchObj } = this.props;

    searchObj2.selectedKeys = selectedKeys;
    searchObj2.dataIndex = dataIndex;
    searchObj(searchObj2);
  };

  handleReset = (clearFilters) => {
    const { searchObj } = this.props;
    const searchObj2 = {};
    searchObj2.selectedKeys = "";
    searchObj2.dataIndex = "";
    searchObj(searchObj2);
    clearFilters();
  };

  renderTitle = (provisioningId) => {
    return (
      <>
        <Paragraph className="paragraphTitle" style={{ whiteSpace: "normal", padding: "0.5em" }}>
          <h2 style={{ margin: 0 }}>{Strings.transaction.provisioningDetails}</h2>
        </Paragraph>
      </>
    );
  };

  render() {
    const {
      provisioningInfo,
      vmTransactionInfo,
      isLoading,
      lockerTransactionInfo,
      isVisible,
      vendingMachineName,
      totalStockedAmount,
      totalUnderstockAmendmentAmount,
      totalOverstockAmendmentAmount,
    } = this.props;
    return provisioningInfo != null && (vmTransactionInfo != null || lockerTransactionInfo != null) ? (
      <>
        <Modal
          title={this.renderTitle(provisioningInfo.provisioningId)}
          visible={isVisible}
          onCancel={this.handleCancel}
          centered
          width="80%"
          footer={null}
          size="middle"
          scroll={{ x: "80%" }}
          rowKey="id"
        >
          <div className="detailRow">
            <div>
              <h2 className="paragraphSubTitle" style={{ alignItems: "center" }}>
                {Strings.transaction.transactionIdentifier}:
              </h2>
              <span className="text-secondary">{provisioningInfo.provisioningId}</span>
            </div>
            <div>
              <h2 className="paragraphSubTitle">{Strings.machine.vendingMachine}:</h2> <span className="text-secondary">{provisioningInfo.vmSerialNumber}</span>
            </div>
            <div>
              <h2 className="paragraphSubTitle">{Strings.machine.wingType}:</h2>{" "}
              <span className="text-secondary">
                {String(provisioningInfo.productTransactionTypeName).includes("LOCKER") ? Strings.machine.locker : Strings.machine.vendingMachine}
              </span>
            </div>
            <div>
              <h2 className="paragraphSubTitle">{Strings.Roles.provisioner}:</h2> <span className="text-secondary">{provisioningInfo.user}</span>
            </div>
            <div>
              <h2 className="paragraphSubTitle">{Strings.transaction.provisioningProcessDate}:</h2>{" "}
              <span className="text-secondary">{moment(provisioningInfo.transactionDate).local().format(DATE_TIME_FORMAT)}</span>
            </div>
          </div>
          <Divider></Divider>
          {/*  Vending machine provisioning table */}
          {vmTransactionInfo ? (
            <div>
              <div className="row">
                <div className="col-md-12">
                  <Table
                    className="adjustmentTable"
                    dataSource={vmTransactionInfo}
                    loading={isLoading}
                    bordered
                    pagination={false}
                    scroll={{ y: 400 }}
                    rowKey="slotNumber"
                    headerStyle={() => ({ backgroundColor: "red" })}
                  >
                    <Column title={Strings.planogram.slot} align="center" width="12%" dataIndex="slotNumber" {...this.getColumnSearchProps("vmSlotNumber")} />

                    <Column title={Strings.product.soProduct} align="center" width="26%" dataIndex="soProductName" {...this.getColumnSearchProps("soProductName")} />
                    <Column
                      title={Strings.product.scProductInfo}
                      render={(row) => <div>{!row.scProductName ? "--" : row.scProductName}</div>}
                      align="center"
                      width="26%"
                      {...this.getColumnSearchProps("scProductName")}
                    />
                    <Column
                      title={<TextWithInfoTooltip name={Strings.transactionType.overstockAmendment} tooltip={Strings.transaction.overstockAmendmentAmountTooltip} />}
                      align="center"
                      width="12%"
                      dataIndex="overstockAmendmentAmount"
                    />
                    <Column
                      title={<TextWithInfoTooltip name={Strings.transactionType.understockAmendment} tooltip={Strings.transaction.understockAmendmentAmountTooltip} />}
                      align="center"
                      width="12%"
                      dataIndex="understockAmendmentAmount"
                    />
                    <Column
                      title={<TextWithInfoTooltip name={Strings.transactionType.stocked} tooltip={Strings.transaction.stockedAmountTooltip} />}
                      align="center"
                      width="12%"
                      dataIndex="stockedAmount"
                    />
                  </Table>
                </div>
              </div>
              <Divider></Divider>
              <div className="detailRow">
                <div>
                  <h2 className="paragraphSubTitle" style={{ alignItems: "center" }}>
                    {Strings.transactionType.overstockAmendmentReview}:
                  </h2>
                  <span className="text-secondary">{`${totalOverstockAmendmentAmount} ${Strings.generalTerms.units} `}</span>
                </div>
                <div>
                  <h2 className="paragraphSubTitle">{Strings.transactionType.understockAmendmentReview}:</h2>{" "}
                  <span className="text-secondary">{`${totalUnderstockAmendmentAmount} ${Strings.generalTerms.units} `}</span>
                </div>
                <div>
                  <h2 className="paragraphSubTitle">{Strings.transactionType.stockedReview}:</h2>{" "}
                  <span className="text-secondary">{`${totalStockedAmount} ${Strings.generalTerms.units} `}</span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/*   Lockers provisioning table   */}
          {lockerTransactionInfo ? (
            <div className="row">
              <div className="col-md-12">
                <Table dataSource={lockerTransactionInfo} loading={isLoading} bordered pagination={false} key="slotNumber" size="middle" scroll={{ x: "auto", y: 300 }}>
                  <Column title={Strings.planogram.slot} align="center" width="10%" dataIndex="slotNumber" {...this.getColumnSearchProps("lockerSlotNumber")} />
                  <Column
                    title={<TextWithInfoTooltip name={Strings.machineInventory.productAuditOrProvisioned} tooltip={Strings.machineInventory.productAuditOrProvisionedTooltip} />}
                    align="center"
                    width="40%"
                    render={(row) => <div>{!row.productExpectedName ? "--" : row.productExpectedName}</div>}
                  />
                  <Column
                    title={<TextWithInfoTooltip name={Strings.machineInventory.productFound} tooltip={Strings.machineInventory.productFoundTooltip} />}
                    render={(row) => <div>{!row.productFoundName ? "--" : row.productFoundName}</div>}
                    align="center"
                    width="40%"
                  />
                  <Column
                    title={<TextWithInfoTooltip name={Strings.transactionType.foundAndState} tooltip={Strings.machineInventory.lockerStateTooltip} />}
                    align="center"
                    width="10%"
                    render={(row) => <div>{productTransactionTypeLocalizationLocalization(row.productTransactionTypeName)}</div>}
                    // filters={this.props.lockerProvisioningTransactionTypes}
                  />
                </Table>
              </div>
            </div>
          ) : (
            ""
          )}
        </Modal>
      </>
    ) : (
      <>
        <Modal title={Strings.transaction.provisioningDetails} onCancel={this.handleButton} width="80%" visible={isVisible} footer={null} centered>
          <div className="row">
            <Loading />
          </div>
        </Modal>
      </>
    );
  }
}
export default connect(null, {})(ProvisioningSummaryModal);
