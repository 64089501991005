// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Alert, Pagination, Modal, Tooltip, Button } from "antd";
import moment from "moment";
import Loading from "../GenericComponents/loadingComponent/loading";
import { SelectGeneral } from "../GenericComponents/selectComponent/selectGeneral";
import { RangeDate } from "../GenericComponents/DateComponent/RangeDate.jsx";
import AdjustmentReportsTable from "./AdjustmentReportsTableComponent.jsx";
import { ValidatePermissionForComponent } from "../../Utils/validatePermissionForComponent";
import { ExportButton } from "../GenericComponents/buttons/index";
import ExportReportsModal from "../GenericComponents/exportReportModal";
import { maxRegistersPerReport } from "../../systemVariables/serverInformation";

import {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listVendingMachinesByScDependencyId,
  getAdjustmentReportByVendingMachineIdPaginated,
  getAdjustmentReportByScDependencyIdPaginated,
  getAdjustmentReportBySoDependencyIdPaginated,
  getAllAdjustmentTypes,
  exportAdjustmentReport,
} from "../../actions";

// Components
import Titles from "../GenericComponents/titles";
import { ValidateDependency } from "../../Utils/validateDependency";

// Language localization
import Strings from "../../systemVariables/languageStrings";
import { adjustmentTypeLocalization } from "../../Utils/LanguageLocalization";

class ListAdjustmentReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: Strings.transaction.adjustmentReports,
      adjustmentReportList: [],
      adjustmentTypes: [],
      soDependencies: [],
      scDependencies: [],
      vendingMachines: [],
      soDependencyId: null,
      scDependencyId: null,
      vendingMachineId: null,
      adjustmentTypeId: null,
      isScDependencySelectDisabled: true,
      isVendingMachineSelectDisabled: true,
      isAdjustmentTypeSelectDisabled: false,
      isDatesSelectDisabled: false,
      isLoading: false,
      errorMsj: "",
      isErrorVisible: false,

      // Pagination
      pageSize: 10,
      page: 1,
      attribute: "vmProductTransactionAdjustmentId",
      order: "DESC",

      totalElements: 0,
      toError401: false,
      toError500: false,

      // Filters
      soProductName: "",
      erpProductCode: "",
      slotNumber: 0,

      // Start values
      startDate: moment().subtract(3, "months"),
      endDate: moment(),
    };
    this.showPagination = this.showPagination.bind(this);
    this.dateOnChange = this.dateOnChange.bind(this);
  }

  componentDidMount() {
    const soDependencyId = sessionStorage.getItem("dependencyId");
    const dependencyType = sessionStorage.getItem("dependencyType");
    // If is a Noatec user
    if (dependencyType === "so" && soDependencyId === "1") {
      this.props.listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"), (response) => {
        this.setState({
          soDependencies: response.data.data.map((obj) => {
            return { value: obj.id, label: obj.name };
          }),
        });
      });
    } else {
      this.props.listScDependenciesBySoDependencyId(soDependencyId, (response) => {
        const scDependencies = response.data.data.map((obj) => ({
          value: obj.id,
          label: obj.name,
        }));
        scDependencies.push({ value: "All", label: "Todos" });
        this.setState({
          scDependencies,
          ScDependencyId: "All",
          isScDependencySelectDisabled: false,
        });
      });
    }

    this.props.getAllAdjustmentTypes((response) => {
      this.setState({
        adjustmentTypes: response.data.data.map((item) => ({
          value: item.id,
          label: adjustmentTypeLocalization(item.name),
        })),
      });
    });
  }

  onSelectDirectClient = (soDependency) => {
    this.setState({
      soDependencyId: soDependency.value,
      isScDependencySelectDisabled: false,
      isVendingMachineSelectDisabled: true,
      vendingMachineId: null,
      scDependencyId: null,
      adjustmentTypeId: -1,
    });
    this.props.listScDependenciesBySoDependencyId(soDependency.value, (response) => {
      this.setState({
        scDependencies: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });
  };

  onSelectIndirectClient = (scDependency) => {
    this.setState({
      scDependencyId: scDependency.value,
      scDependencyName: scDependency.label,
      isVendingMachineSelectDisabled: false,
      vendingMachineId: null,
      adjustmentTypeId: -1,
    });

    this.props.listVendingMachinesByScDependencyId(scDependency.value, (response) => {
      this.setState({
        vendingMachines: response.data.data.map((item) => ({
          value: item.id,
          label: item.friendlyName,
        })),
      });
    });
  };

  onSelectVendingMachine = (vendingMachine) => {
    this.setState({
      vendingMachineId: vendingMachine.value,
      adjustmentTypeId: -1,
    });
  };

  adjustmentTypeOnChange(adjustmentType) {
    this.setState({
      adjustmentTypeId: adjustmentType.value
    });
  }

  dateOnChange(date) {
    if (date.length === 0) {
      date[0] = moment().subtract(3, "months");
      date[1] = moment();
    }
    this.setState({
      startDate: date[0],
      endDate: date[1],
    });
  }

  onClickCheckButton = () => {
    const {soDependencyId, scDependencyId, vendingMachineId} = this.state
    this.setState({
      page: 1,
      isErrorVisible: false,
      isLoading: true,
      adjustmentReportList: [],
      totalElements: 0,
      soProductName: "",
      erpProductCode: "",
      slotNumber: 0,
    });
    if (vendingMachineId) {
      this.props.getAdjustmentReportByVendingMachineIdPaginated(
        vendingMachineId,
        this.state.adjustmentTypeId,
        this.state.soProductName,
        this.state.erpProductCode,
        this.state.slotNumber,
        this.state.startDate.format(),
        this.state.endDate.format(),
        this.state.page,
        this.state.pageSize,
        this.state.attribute,
        this.state.order,
        (response) => {
          this.setState({
            adjustmentReportList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
          });
        }
      );
    } else if (scDependencyId) {
      this.props.getAdjustmentReportByScDependencyIdPaginated(
        scDependencyId,
        this.state.adjustmentTypeId,
        this.state.soProductName,
        this.state.erpProductCode,
        this.state.slotNumber,
        this.state.startDate.format(),
        this.state.endDate.format(),
        this.state.page,
        this.state.pageSize,
        this.state.attribute,
        this.state.order,
        (response) => {
          this.setState({
            adjustmentReportList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
          });
        }
      );
    } else if (soDependencyId) {
      this.props.getAdjustmentReportBySoDependencyIdPaginated( 
        soDependencyId,
        this.state.adjustmentTypeId,
        this.state.soProductName,
        this.state.erpProductCode,
        this.state.slotNumber,
        this.state.startDate.format(),
        this.state.endDate.format(),
        this.state.page,
        this.state.pageSize,
        this.state.attribute,
        this.state.order,
        (response) => {
          this.setState({
            adjustmentReportList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
          });
        }
      );
    }
  }

  onSelectFilter = (dataIndex, selectedKeys) => {
    this.setState({
      isLoading: true,
      adjustmentReportList: [],
    });

    if (dataIndex === "soProductName" && selectedKeys !== "") {
      this.props.getAdjustmentReportByVendingMachineIdPaginated(
        this.state.vendingMachineId,
        this.state.adjustmentTypeId,
        selectedKeys[0],
        this.state.erpProductCode,
        this.state.slotNumber,
        this.state.startDate.format(),
        this.state.endDate.format(),
        this.state.page,
        this.state.pageSize,
        this.state.attribute,
        this.state.order,
        (response) => {
          this.setState({
            adjustmentReportList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            soProductName: selectedKeys[0],
            erpProductCode: "",
            slotNumber: 0,
            page: 1,
          });
        }
      );
    }
    if (dataIndex === "erpProductCode" && selectedKeys !== "") {
      this.props.getAdjustmentReportByVendingMachineIdPaginated(
        this.state.vendingMachineId,
        this.state.adjustmentTypeId,
        this.state.soProductName,
        selectedKeys[0],
        this.state.slotNumber,
        this.state.startDate.format(),
        this.state.endDate.format(),
        this.state.page,
        this.state.pageSize,
        this.state.attribute,
        this.state.order,
        (response) => {
          this.setState({
            adjustmentReportList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            soProductName: "",
            erpProductCode: selectedKeys[0],
            slotNumber: 0,
            page: 1,
          });
        }
      );
    }
    if (dataIndex === "slotNumber" && selectedKeys !== "") {
      this.props.getAdjustmentReportByVendingMachineIdPaginated(
        this.state.vendingMachineId,
        this.state.adjustmentTypeId,
        this.state.soProductName,
        this.state.erpProductCode,
        selectedKeys[0],
        this.state.startDate.format(),
        this.state.endDate.format(),
        this.state.page,
        this.state.pageSize,
        this.state.attribute,
        this.state.order,
        (response) => {
          this.setState({
            adjustmentReportList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            soProductName: "",
            erpProductCode: "",
            slotNumber: selectedKeys[0],
            page: 1,
          });
        }
      );
    }

    /// ////// Reset methods according to each filter  ////////

    if (dataIndex === "soProductName" && selectedKeys === "") {
      this.props.getAdjustmentReportByVendingMachineIdPaginated(
        this.state.vendingMachineId,
        this.state.adjustmentTypeId,
        "",
        this.state.erpProductCode,
        this.state.slotNumber,
        this.state.startDate.format(),
        this.state.endDate.format(),
        this.state.page,
        this.state.pageSize,
        this.state.attribute,
        this.state.order,
        (response) => {
          this.setState({
            adjustmentReportList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            soProductName: "",
            erpProductCode: this.state.erpProductCode,
            slotNumber: this.state.slotNumber,
            page: 1,
          });
        }
      );
    }
    if (dataIndex === "erpProductCode" && selectedKeys === "") {
      this.props.getAdjustmentReportByVendingMachineIdPaginated(
        this.state.vendingMachineId,
        this.state.adjustmentTypeId,
        this.state.soProductName,
        "",
        this.state.slotNumber,
        this.state.startDate.format(),
        this.state.endDate.format(),
        this.state.page,
        this.state.pageSize,
        this.state.attribute,
        this.state.order,
        (response) => {
          this.setState({
            adjustmentReportList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            soProductName: this.state.soProductName,
            erpProductCode: "",
            slotNumber: this.state.slotNumber,
            page: 1,
          });
        }
      );
    }
    if (dataIndex === "slotNumber" && selectedKeys === "") {
      this.props.getAdjustmentReportByVendingMachineIdPaginated(
        this.state.vendingMachineId,
        this.state.adjustmentTypeId,
        this.state.soProductName,
        this.state.erpProductCode,
        0,
        this.state.startDate.format(),
        this.state.endDate.format(),
        this.state.page,
        this.state.pageSize,
        this.state.attribute,
        this.state.order,
        (response) => {
          this.setState({
            adjustmentReportList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            soProductName: this.state.soProductName,
            erpProductCode: this.state.erpProductCode,
            slotNumber: 0,
            page: 1,
          });
        }
      );
    }
  };

  showPagination(page, pageSize) {
    this.setState({
      isLoading: true,
      page,
      pageSize,
      adjustmentReportList: [],
    });

    this.props.getAdjustmentReportByVendingMachineIdPaginated(
      this.state.vendingMachineId,
      this.state.adjustmentTypeId,
      this.state.soProductName,
      this.state.erpProductCode,
      this.state.slotNumber,
      this.state.startDate.format(),
      this.state.endDate.format(),
      page,
      pageSize,
      this.state.attribute,
      this.state.order,
      (response) => {
        this.setState({
          adjustmentReportList: response.data.data.content,
          totalElements: response.data.data.totalElements,
          isLoading: false,
        });
      }
    );
  }

  // Export methods
  showExportReportsModal = () => {
    if (this.state.totalElements >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible, isExceedModalVisible) => {
    this.setState({
      isExportModalVisible,
      isExceedModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    if (!this.state.exportLoading) {
      const DATE_OPTIONS = { year: "numeric", month: "numeric", day: "numeric" };
      const startDateStr = new Date(this.state.startDate).toLocaleDateString("es-CO", DATE_OPTIONS);
      const endDateStr = new Date(this.state.endDate).toLocaleDateString("es-CO", DATE_OPTIONS);

      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
      const columnHeaders = [
        Strings.transaction.adjustmentType,
        Strings.product.product,
        Strings.product.scProductCode,
        Strings.planogram.vmSlotPosition,
        Strings.dispensationRule.amount,
        Strings.product.price,
        Strings.transaction.adjustedBy,
        Strings.dispensationReport.transactionDate,
        Strings.transaction.adjustmentDate,
      ];

      // Start loading animation on Export button
      this.setState({ exportLoading: true });

      // Consume report export method
      this.props.exportAdjustmentReport(
        this.state.soDependencyId,
        this.state.scDependencyId,
        this.state.vendingMachineId,
        this.state.adjustmentTypeId,
        this.state.soProductName,
        this.state.erpProductCode,
        this.state.slotNumber,
        this.state.startDate.format(),
        this.state.endDate.format(),
        this.state.attribute,
        this.state.order,
        fileType,
        columnHeaders,
        (response) => {
          if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
            // Connection error
            Modal.error({
              title: Strings.generalResponses.failedTransaction,
              content: Strings.generalResponses.reportGenerationError,
            });
            this.setState({
              exportLoading: false,
            });
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            link.setAttribute("download", `${Strings.transaction.provisioningReport} ${startDateStr} - ${endDateStr}.csv`);
            document.body.appendChild(link);
            link.click();
            this.setState({
              exportLoading: false,
            });
          }
        }
      );
    }
  };

  render() {
    if (this.state.toError500 === true) {
      return <Redirect to="/error500" />;
    }
    if (this.state.toError401 === true) {
      return <Redirect to="/error401" />;
    }

    const {
      props: { soDependencies },
    } = this;
    const { adjustmentReportList, soDependencyId } = this.state;
    const {
      permissions: { data },
    } = this.props;

    if (!soDependencies) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    return (
      <div className="content-container">
        <ExportReportsModal
          isExportVisible={this.state.isExportModalVisible}
          isExceedVisible={this.state.isExceedModalVisible}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
        />
        <div className="row">
          <Titles title={this.state.title} tooltip={Strings.transaction.underStockedTransactionAdjustmentTooltip} />
        </div>

        <hr />

        <div className="row">
          <ValidateDependency
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            soDependencyId={this.state.soDependencyId}
            scDependencyId={this.state.scDependencyId}
            scDependency={this.state.scDependencyName}
            soDependency={this.state.soDependencyName}
            soDependencies={this.props.soDependencies}
            scDependencies={this.state.scDependencies}
            disabledScDependency={this.state.isScDependencySelectDisabled}
            soDependencyHandleChange={(soDependency) => this.onSelectDirectClient(soDependency)}
            scDependencyHandleChange={(scDependency) => this.onSelectIndirectClient(scDependency)}
          />
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.machine.vendingMachine}
              tooltip={Strings.machine.machineTooltip}
              options={this.state.vendingMachines}
              onChange={(vendingMachineId) => this.onSelectVendingMachine(vendingMachineId)}
              disabled={this.state.isVendingMachineSelectDisabled}
              defaultValue={this.state.vendingMachineId}
              value={this.state.vendingMachineId}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.transaction.adjustmentType}
              tooltip={Strings.transaction.adjustmentType}
              options={this.state.adjustmentTypes}
              onChange={(adjustmentType) => this.adjustmentTypeOnChange(adjustmentType)}
              disabled={this.state.isAdjustmentTypeSelectDisabled}
              value={this.state.adjustmentTypeId}
              defaultValue={this.state.adjustmentTypeId}
            />
          </div>
          <div className="vertSpace col-md-6">
            <RangeDate
              text={Strings.generalTerms.date}
              tooltip={Strings.dispensationReport.dispensationDateTooltip}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.dateOnChange}
              isDisabled={this.state.isDatesSelectDisabled}
              format="MMMM Do YYYY, h:mm:ss a"
            />
          </div>
          <div className="vertSpace col-md-6">
            <div className="col-md-5"/>
            <div className="col-md-6">
              <Tooltip title={Strings.transaction.getTransactionsToolTip}>
                <Button
                  style={{ margin: "5px" }}
                  type="primary"
                  size={!true ? "large" : "default"}
                  onClick={() => this.onClickCheckButton()}
                  icon="search"
                  disabled={!soDependencyId}
                  loading={this.state.isLoading}
                >
                  {Strings.generalTerms.check}
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className="col">
            {this.state.isErrorVisible ? (
              <div>
                <br />
                <Alert message={this.state.errorMsj} type="error" showIcon />
              </div>
            ) : null}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col">
            <AdjustmentReportsTable
              adjustmentReportList={this.state.adjustmentReportList}
              isLoading={this.state.isLoading}
              adjustmentTypes={this.state.adjustmentTypes}
              onSelectFilter={this.onSelectFilter}
            />
          </div>
        </div>
        {this.state.adjustmentReportList.length > 0 ? (
          <div>
            <div className="row">
              <div className="vertSpace col text-right">
                <Pagination
                  size="small"
                  total={this.state.totalElements}
                  showQuickJumper
                  showSizeChanger
                  onChange={this.showPagination}
                  onShowSizeChange={this.showPagination}
                  hideOnSinglePage={false}
                  pageSizeOptions={["10", "25", "50", "100", "250"]}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  current={this.state.page}
                  disabled={adjustmentReportList ? !(adjustmentReportList.length > 0) : true}
                />
              </div>
            </div>
            <ValidatePermissionForComponent permission="PRIVILEGE PROVISIONING REPORT GET" permissions={data}>
              <div className="row">
                <div className="vertSpace col-12">
                  <div className="col-md-6" />
                  <div className="col-md-6">
                    <ExportButton
                      isDisabled={adjustmentReportList ? !(adjustmentReportList.length > 0) : true}
                      onClick={() => this.showExportReportsModal()}
                      loading={this.state.exportLoading}
                    />
                  </div>
                </div>
              </div>
            </ValidatePermissionForComponent>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    soDependenciesNotType0: state.soDependencies,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    scdependencytype: state.scdependencytypeid,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listVendingMachinesByScDependencyId,
  getAdjustmentReportByVendingMachineIdPaginated,
  getAdjustmentReportByScDependencyIdPaginated,
  getAdjustmentReportBySoDependencyIdPaginated,
  getAllAdjustmentTypes,
  exportAdjustmentReport,
})(ListAdjustmentReports);
