import { Icon, Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Strings from "../../../systemVariables/languageStrings";
import NormalText from "../text/NormalText";
import { SelectGeneral } from "./selectGeneral";

import {
  getAllRelatedVendingMachinesToSoDependencyId,
  getCountryNameScDependencyById,
  listScDependenciesBySoDependencyId,
  listSoDependencyBySoDependencyType0,
  listVendingMachinesByScDependencyId,
} from "../../../actions/index";

import soScActions from "../../../reducers/soscselection/actions";

const { soScSelection } = soScActions;

class SoScVmDependencySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soDependencies: [],
      selectedSoDependencyId: null,
      isSoDependencyLoading: false,
      isSoDependencyDisabled: true,

      scDependencies: [],
      selectedScDependencyId: null,
      isScDependencyLoading: false,
      isScDependencyDisabled: true,

      vendingMachines: [],
      selectedVendingMachineId: null,
      isVendingMachineLoading: false,
      isVendingMachineDisabled: true,
    };
  }

  componentDidMount() {
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      listSoDependencyBySoDependencyType0,
      listScDependenciesBySoDependencyId,
      getCountryNameScDependencyById,
      listVendingMachinesByScDependencyId,
      getAllRelatedVendingMachinesToSoDependencyId,
      hasSoAll,
      hasScAll,
      hasVmAll,
      hasVm,
      soScSelectionProps,
      VmproductSlotNotAllMachine,
    } = this.props;

    // SO Noatec user
    if (dependencyType === "so" && dependencyId === 1) {
      // Get soDependencies to fill filter
      listSoDependencyBySoDependencyType0(soScSelectionProps.so ? soScSelectionProps.so.id : dependencyId, (response) => {
        const initialSoDependencies = hasSoAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
        const soDependencies = initialSoDependencies.concat(
          response.data.data.map((obj) => {
            return { value: obj.id, label: obj.name, countryName: obj.countryName };
          })
        );

        this.setState({
          soDependencies,
          isSoDependencyLoading: false,
          isSoDependencyDisabled: false,
        });
      });

      // Get scDependencies by soId
      listScDependenciesBySoDependencyId(soScSelectionProps.so ? soScSelectionProps.so.id : dependencyId, (response) => {
        let initialScDependencies = [];

        if (hasScAll) {
          initialScDependencies = [{ value: -1, label: Strings.generalTerms.all }];
          // this.setState({ selectedScDependencyId: -1 });
        }

        const scDependencies = initialScDependencies.concat(
          response.data.data.map((obj) => {
            return { value: obj.id, label: obj.name, countryName: obj.countryName, currencyCodeName: obj.currencyCodeName };
          })
        );

        this.setState({
          scDependencies,
          isScDependencyLoading: false,
          isScDependencyDisabled: false,
          selectedSoDependencyId: soScSelectionProps.so ? soScSelectionProps.so.id : dependencyId,
        });
      });

      if (soScSelectionProps.sc && soScSelectionProps.sc.id !== -1) {
        this.setState({
          selectedScDependencyId: soScSelectionProps.sc.id,
        });

        listVendingMachinesByScDependencyId(soScSelectionProps.sc.id, (response) => {
          let initialVendingMachines = [];

          if (hasVmAll) {
            initialVendingMachines = [{ value: -1, label: Strings.generalTerms.all }];
            this.setState({
              selectedVendingMachineId: -1,
            });
          }
          const vendingMachines = initialVendingMachines.concat(
            response.data.data.map((obj) => ({
              value: obj.id,
              label: obj.friendlyName,
            }))
          );
          this.setState({
            vendingMachines,
            isVendingMachineLoading: false,
            isVendingMachineDisabled: false,
          });
        });
      } else {
        getAllRelatedVendingMachinesToSoDependencyId(soScSelectionProps.so ? soScSelectionProps.so.id : dependencyId, (response) => {
          const initialVendingMachines = hasVmAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
          const vendingMachines = initialVendingMachines.concat(
            response.data.data.map((obj) => ({
              value: obj.id,
              label: obj.friendlyName,
            }))
          );
          this.setState({
            vendingMachines,
            isVendingMachineLoading: false,
          });
        });
      }
    }

    // StockOwner user
    if (dependencyType === "so" && dependencyId !== 1) {
      this.setState({
        selectedSoDependencyId: dependencyId,
        isScDependencyLoading: true,
      });

      listScDependenciesBySoDependencyId(dependencyId, (response) => {
        let initialScDependencies = [];

        if (hasScAll) {
          initialScDependencies = [{ value: -1, label: Strings.generalTerms.all }];
          this.setState({
            selectedScDependencyId: -1,
          });
        }

        const scDependencies = initialScDependencies.concat(
          response.data.data.map((obj) => {
            return { value: obj.id, label: obj.name, countryName: obj.countryName, currencyCodeName: obj.currencyCodeName };
          })
        );

        this.setState({
          scDependencies,
          isScDependencyLoading: false,
          isScDependencyDisabled: false,
        });
      });

      if (hasVm) {
        if ((soScSelectionProps.sc === null || soScSelectionProps.sc.id === -1) && !VmproductSlotNotAllMachine) {
          this.setState({
            isVendingMachineLoading: false,
            isVendingMachineDisabled: true,
          });

          getAllRelatedVendingMachinesToSoDependencyId(dependencyId, (response) => {
            const initialVendingMachines = hasVmAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
            const vendingMachines = initialVendingMachines.concat(
              response.data.data.map((obj) => ({
                value: obj.id,
                label: obj.friendlyName,
              }))
            );
            this.setState({
              vendingMachines,
              isVendingMachineLoading: false,
              isVendingMachineDisabled: false,
            });
          });
        }
      }

      if (soScSelectionProps.sc !== null && soScSelectionProps.sc.id !== -1) {
        this.setState({
          selectedScDependencyId: soScSelectionProps.sc.id,
        });

        listVendingMachinesByScDependencyId(soScSelectionProps.sc.id, (response) => {
          const initialVendingMachines = hasVmAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
          const vendingMachines = initialVendingMachines.concat(
            response.data.data.map((obj) => ({
              value: obj.id,
              label: obj.friendlyName,
            }))
          );
          this.setState({
            vendingMachines,
          });
        });
        this.setState({
          isVendingMachineLoading: false,
          isVendingMachineDisabled: false,
        });
      }
    }

    // ScDependency user
    if (dependencyType === "sc") {
      this.setState({
        isVendingMachineLoading: true,
      });

      getCountryNameScDependencyById(dependencyId, (response) => {
        soScSelectionProps.sc = {
          id: response.data.data.id,
          label: response.data.data.name,
          countryName: response.data.data.countryName,
          currencyCodeName: response.data.data.currencyCodeName,
        };
        soScSelection(soScSelectionProps);
        this.setState({
          selectedScDependencyId: dependencyId,
          selectedVendingMachineId: null,
          isVendingMachineDisabled: false,
        });
      });

      if (hasVm === true) {
        listVendingMachinesByScDependencyId(dependencyId, (response) => {
          let initialVendingMachines = [];
          if (hasVmAll) {
            initialVendingMachines = [{ value: -1, label: Strings.generalTerms.all }];
            this.setState({
              selectedVendingMachineId: -1,
            });
          }
          const vendingMachines = initialVendingMachines.concat(
            response.data.data.map((obj) => ({
              value: obj.id,
              label: obj.friendlyName,
            }))
          );
          this.setState({
            vendingMachines,
            isVendingMachineLoading: false,
            isVendingMachineDisabled: false,
          });
        });
      }
    }
  }

  static getDerivedStateFromProps(props, prevState) {
    const { soDependencyId: selectedSoDependencyId, vendingMachineId: selectedVendingMachineId } = props;
    let ans = null;

    // if the prop soDependency coming from the parent is NULL, set the component to its initial state
    if (selectedSoDependencyId !== prevState.selectedSoDependencyId && selectedSoDependencyId !== undefined && selectedSoDependencyId === null) {
      ans = {
        selectedSoDependencyId,

        scDependencies: [],
        selectedScDependencyId: null,
        isScDependencyLoading: false,
        isScDependencyDisabled: true,

        vendingMachines: [],
        selectedVendingMachineId: null,
        isVendingMachineLoading: false,
        isVendingMachineDisabled: true,
      };
      // if props soDependency coming from the parent is Not NULL, set that prop to the state selectedSoDependencyId
    } else if (selectedSoDependencyId !== prevState.selectedSoDependencyId && selectedSoDependencyId !== undefined) {
      ans = { selectedSoDependencyId };
    }

    if (selectedVendingMachineId !== undefined && selectedVendingMachineId !== prevState.selectedVendingMachineId) {
      ans = { selectedVendingMachineId };
    }

    return ans;
  }

  handleSoDependencyChange = (event) => {
    const {
      onSoDependencyChange,
      soDependencyHtmlTag,
      soScSelectionProps,
      listScDependenciesBySoDependencyId,
      getAllRelatedVendingMachinesToSoDependencyId,
      loadVmsOnSoChange,
    } = this.props;

    soScSelectionProps.so = {
      id: event.value,
      label: event.label,
      countryName: event.countryName,
    };
    soScSelectionProps.sc = null;
    soScSelectionProps.vm = null;

    onSoDependencyChange(event, soDependencyHtmlTag);

    // Save soDepId in the store
    soScSelection(soScSelectionProps);

    this.setState({
      selectedSoDependencyId: event.value,

      scDependencies: [],
      selectedScDependencyId: null,
      isScDependencyDisabled: true,

      vendingMachines: [],
      selectedVendingMachineId: null,
      isVendingMachineDisabled: true,
    });

    if (event.value !== -1) {
      this.setState({
        isScDependencyLoading: true,
      });

      listScDependenciesBySoDependencyId(event.value, (response) => {
        this.setState({
          scDependencies: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
            countryName: item.countryName,
            currencyCodeName: item.currencyCodeName,
          })),
          isScDependencyLoading: false,
          isScDependencyDisabled: false,
        });
      });

      if (loadVmsOnSoChange) {
        this.setState({
          isVendingMachineLoading: true,
        });

        getAllRelatedVendingMachinesToSoDependencyId(event.value, (response) => {
          const initialVendingMachines = [{ value: -1, label: Strings.generalTerms.all }];
          const vendingMachines = initialVendingMachines.concat(
            response.data.data.map((obj) => ({
              value: obj.id,
              label: obj.friendlyName,
            }))
          );
          this.setState({
            vendingMachines,
            isVendingMachineLoading: false,
            isVendingMachineDisabled: false,
          });

          this.setState({
            vendingMachines: response.data.data.map((item) => ({
              value: item.id,
              label: item.friendlyName,
            })),
            isVendingMachineLoading: false,
            isVendingMachineDisabled: false,
          });
        });
      }
    }
  };

  handleScDependencyChange = (event) => {
    const {
      onScDependencyChange,
      scDependencyHtmlTag,
      soScSelectionProps,
      listVendingMachinesByScDependencyId,
      getAllRelatedVendingMachinesToSoDependencyId,
      hasVm,
      hasVmAll,
    } = this.props;

    const { selectedSoDependencyId } = this.state;

    onScDependencyChange(event, scDependencyHtmlTag);

    // Save soDepId in the store
    soScSelectionProps.sc = {
      id: event.value,
      label: event.label,
      countryName: event.countryName,
      currencyCodeName: event.currencyCodeName,
    };
    soScSelection(soScSelectionProps);
    this.setState({
      selectedScDependencyId: event.value,
      vendingMachines: [],
      selectedVendingMachineId: null,
      isVendingMachineDisabled: false,
    });

    if (hasVm === true) {
      if (event.value !== -1) {
        this.setState({
          isVendingMachineLoading: true,
        });

        listVendingMachinesByScDependencyId(soScSelectionProps.sc.id, (response) => {
          const initialVendingMachines = hasVmAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
          const vendingMachines = initialVendingMachines.concat(
            response.data.data.map((obj) => ({
              value: obj.id,
              label: obj.friendlyName,
            }))
          );
          this.setState({
            vendingMachines,
            isVendingMachineLoading: false,
            isVendingMachineDisabled: false,
          });
        });
      } else {
        getAllRelatedVendingMachinesToSoDependencyId(selectedSoDependencyId, (response) => {
          const initialVendingMachines = hasVmAll ? [{ value: -1, label: Strings.generalTerms.all }] : [];
          const vendingMachines = initialVendingMachines.concat(
            response.data.data.map((obj) => ({
              value: obj.id,
              label: obj.friendlyName,
            }))
          );
          this.setState({
            vendingMachines,
            isVendingMachineLoading: false,
            isVendingMachineDisabled: false,
          });
        });
      }
    }
  };

  handleVendingMachineChange = (event) => {
    const { onVendingMachineChange, vendingMachineHtmlTag } = this.props;
    this.setState({
      selectedVendingMachineId: event.value,
    });

    onVendingMachineChange(event, vendingMachineHtmlTag);
  };

  renderForNoatecSession(hasSc, hasVm) {
    const { soDependencies, selectedSoDependencyId, isSoDependencyLoading, isSoDependencyDisabled } = this.state;
    const { soDependencyHtmlTag } = this.props;

    return (
      <>
        <div className="vertSpace col-md-6">
          <SelectGeneral
            text={Strings.soDependency.directClient}
            tooltip={Strings.soDependency.directClientTooltip}
            isReq
            options={soDependencies}
            name={soDependencyHtmlTag}
            defaultValue={selectedSoDependencyId}
            onChange={this.handleSoDependencyChange}
            isLoading={isSoDependencyLoading}
            disabled={isSoDependencyDisabled}
          />
        </div>
        {this.renderScDependency(hasSc)}
        {this.renderVendingMachine(hasVm)}
      </>
    );
  }

  renderForStockOwnerSession(hasSc, hasVm) {
    const {
      userDependency: { dependencyName },
    } = this.props;
    return (
      <>
        <div className="vertSpace col-xs-12 col-md-6">
          <div className="col-md-4" style={{ textAlign: "left", padding: 0 }}>
            <span>{Strings.soDependency.directClient}:</span>
            <Tooltip title={Strings.soDependency.directClientTooltip}>
              <Icon className="icon" type="info-circle" style={{ float: "none" }} />
            </Tooltip>
          </div>
          <div className="col-md-8" style={{ textAlign: "left", padding: 0 }}>
            {dependencyName}
          </div>
        </div>

        {this.renderScDependency(hasSc)}
        {this.renderVendingMachine(hasVm)}
      </>
    );
  }

  renderForScDependencySession(hasVm) {
    const {
      userDependency: { dependencyName },
    } = this.props;
    return (
      <>
        <div className="vertSpace col-xs-12 col-md-12">
          <NormalText text={Strings.scDependency.indirectClient} tooltip={Strings.scDependency.indirectClientTooltip} name={dependencyName} />
        </div>
        {this.renderVendingMachine(hasVm)}
      </>
    );
  }

  renderScDependency(isRendered) {
    let render = null;
    if (isRendered) {
      const { scDependencies, selectedScDependencyId, isScDependencyLoading, isScDependencyDisabled } = this.state;
      const { scDependencyHtmlTag, soScSelectionProps } = this.props;
      render = (
        <div className="vertSpace col-xs-12 col-md-6">
          <SelectGeneral
            text={Strings.scDependency.indirectClient}
            tooltip={Strings.scDependency.indirectClientTooltip}
            isReq
            options={scDependencies}
            name={scDependencyHtmlTag}
            defaultValue={soScSelectionProps.sc !== null ? soScSelectionProps.sc.id : selectedScDependencyId}
            onChange={this.handleScDependencyChange}
            isLoading={isScDependencyLoading}
            disabled={isScDependencyDisabled}
          />
        </div>
      );
    }
    return render;
  }

  renderVendingMachine(isRendered) {
    let render = null;
    if (isRendered) {
      const { vendingMachines, selectedVendingMachineId, isVendingMachineLoading, isVendingMachineDisabled } = this.state;
      const { vendingMachineHtmlTag } = this.props;
      render = (
        <div className="vertSpace col-xs-12 col-md-6">
          <SelectGeneral
            text={Strings.machine.vendingMachine}
            tooltip={Strings.machine.vendingMachinesTooltip}
            options={vendingMachines}
            name={vendingMachineHtmlTag}
            defaultValue={selectedVendingMachineId}
            onChange={this.handleVendingMachineChange}
            isLoading={isVendingMachineLoading}
            disabled={isVendingMachineDisabled}
          />
        </div>
      );
    }
    return render;
  }

  render() {
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      hasSc,
      hasVm,
    } = this.props;

    let soDependencySelector = null;

    // Noatec Session
    if (dependencyType === "so" && dependencyId === 1) {
      soDependencySelector = this.renderForNoatecSession(hasSc, hasVm);
    } // Stock Owner Different to Noatec Session
    else if (dependencyType === "so" && dependencyId !== 1) {
      soDependencySelector = this.renderForStockOwnerSession(hasSc, hasVm);
    } // Indirect Client
    else if (dependencyType === "sc") {
      soDependencySelector = this.renderForScDependencySession(hasVm);
    }

    return soDependencySelector;
  }
}

SoScVmDependencySelector.propTypes = {
  userDependency: PropTypes.shape({
    dependencyName: PropTypes.string,
    dependencyType: PropTypes.string,
    dependencyId: PropTypes.number,
    dependencyTypeId: PropTypes.number,
  }).isRequired,
  soDependencyHtmlTag: PropTypes.string,
  scDependencyHtmlTag: PropTypes.string,
  vendingMachineHtmlTag: PropTypes.string,
  onSoDependencyChange: PropTypes.func.isRequired,
  onScDependencyChange: PropTypes.func,
  onVendingMachineChange: PropTypes.func,
  hasSc: PropTypes.bool,
  hasVm: PropTypes.bool,
  hasSoAll: PropTypes.bool,
  loadVmsOnSoChange: PropTypes.bool,

  soDependencyId: PropTypes.number,
  vendingMachineId: PropTypes.number,

  listScDependenciesBySoDependencyId: PropTypes.func,
  listSoDependencyBySoDependencyType0: PropTypes.func,
  listVendingMachinesByScDependencyId: PropTypes.func,
  getAllRelatedVendingMachinesToSoDependencyId: PropTypes.func,
};

SoScVmDependencySelector.defaultProps = {
  soDependencyHtmlTag: "",
  scDependencyHtmlTag: "",
  vendingMachineHtmlTag: "",
  onScDependencyChange: null,
  onVendingMachineChange: null,
  hasSc: false,
  hasVm: false,
  hasSoAll: false,

  loadVmsOnSoChange: false,

  soDependencyId: undefined,
  vendingMachineId: undefined,

  listScDependenciesBySoDependencyId: null,
  listSoDependencyBySoDependencyType0: null,
  listVendingMachinesByScDependencyId: null,
  getAllRelatedVendingMachinesToSoDependencyId: null,
};

function mapStateToProps(state) {
  return {
    userDependency: state.dependencyByUser.dependencyObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  getCountryNameScDependencyById,
  listVendingMachinesByScDependencyId,
  getAllRelatedVendingMachinesToSoDependencyId,
})(SoScVmDependencySelector);
