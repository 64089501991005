import axios from "axios";
import { host, port, path, version } from "../systemVariables";

const resource = "/countries";

export const LIST_COUNTRIES = "LIST_COUNTRIES";

export function listCountries(callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: LIST_COUNTRIES, payload: response.data });
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
