const actions2 = {
    PRODUCTS_TO_ADD: 'PRODUCTS_TO_ADD',
    PRODUCTS_TO_EDIT: 'PRODUCTS_TO_EDIT',
    PRODUCTS_TO_REMOVE: 'PRODUCTS_TO_REMOVE',
    CLEAR_PRODUCTS: 'CLEAR_PRODUCTS',

    addProduct: (productsToAdd) => (
        {
            type: actions2.PRODUCTS_TO_ADD,
            productsToAdd: productsToAdd
        }
    ),
    updateProduct: (productsToEdit) => (
        {
            type: actions2.PRODUCTS_TO_EDIT,
            productsToEdit: productsToEdit
        }
    ),
    removeProduct: (productsToRemove) => (
        {
            type: actions2.PRODUCTS_TO_REMOVE,
            productsToRemove: productsToRemove
        }
    ),
    clearProducts: (clearProducts) => (
        {
            type: actions2.CLEAR_PRODUCTS,
            clearProducts: clearProducts
        }
    )
};

export default actions2;
