import { Button, Icon, Tooltip } from "antd";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Strings from "../../../systemVariables/languageStrings";
import "./buttons.css";

export function ImportButton(props) {
  return (
    <Fragment>
      <Link
        to={{
          pathname: props.link,
          props: props.props,
        }}
      >
        <Tooltip title={props.tooltip} placement="bottom">
          <Button className="button" disabled={props.disabled} onClick={props.onClick} type="primary" size="large">
            <Icon type="upload" twoToneColor="#52c41a" />

            <span>{Strings.generalTerms.import}</span>
          </Button>
        </Tooltip>
      </Link>
    </Fragment>
  );
}
