import React from "react";

const Product = ({ width, height, fill }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 503.064 503.064"
      xmlSpace="preserve"
      width={width}
      fill={fill}
    >
      <g>
        <g>
          <g>
            <path
              d="M464.664,267.532H323.256h-16h-47.504v-24.408c62.44-4.144,112-56.128,112-119.592c0-21.984-6.04-42.544-16.408-60.28
           l30.064-30.064l-11.312-11.312l-27.888,27.888c-21.984-28.08-56.104-46.232-94.456-46.232c-66.168,0-120,53.832-120,120
           c0,63.464,49.56,115.448,112,119.592v24.408H37.808L0,363.532h35.248v136h272h16h144v-136h35.816L464.664,267.532z
            M147.752,123.532c0-57.344,46.656-104,104-104c33.944,0,64.04,16.416,83.04,41.648L227.328,168.636l-50.376-43.176
           l-10.408,12.152l61.624,52.824L343.64,74.956c7.704,14.512,12.112,31.032,12.112,48.576c0,57.344-46.656,104-104,104
           S147.752,180.876,147.752,123.532z M23.504,347.532l25.192-64H303.44l-25.6,64H23.504z M51.256,483.532v-120h237.416
           l18.584-46.464v166.464H51.256z M451.256,483.532h-128V317.068l18.584,46.464h109.416V483.532z M352.664,347.532l-25.6-64H453.84
           l25.6,64H352.664z"
            />
            <rect x="275.752" y="451.532" width="16" height="16" />
            <rect x="243.752" y="451.532" width="16" height="16" />
            <rect x="275.752" y="419.532" width="16" height="16" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Product;
