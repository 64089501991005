/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Table, Checkbox } from "antd";

// Actions
import { listWingTypes, listLockerTypes, listLockerSlotTypes, addWingToMachine } from "../../../actions";

// Components
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import { AssignButton } from "../../GenericComponents/buttons/index";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { lockerModalitiesLocalization } from "../../../Utils/LanguageLocalization";

const { Column } = Table;

class WingConfigModal extends Component {
  constructor(props) {
    super(props);
    const { vm } = this.props;
    this.state = {
      wingTypeList: [],
      lockerTypeList: [],
      lockerSlotTypeList: [],
      wingInfo: null,
      typeId: null,
      isDisabledSaveButton: true,
      vm: vm || null,
    };
  }

  componentDidMount() {
    const { listWingTypes } = this.props;
    this.setState({
      wingInfo: null,
      typeId: null,
    });

    listWingTypes((response) => {
      const wingTypes = response.data.data;
      const wingTypesMapped = [];

      if (this.props.principalWing?.wingTypeId === 3) {
        wingTypes
          .filter((wingType) => wingType.id === 3)
          .forEach((wingType) => {
            wingTypesMapped.push({
              value: wingType.id,
              label: wingType.name,
              description: wingType.description,
              maxNumSlots: wingType.maxNumSlots,
              maxNumTrays: wingType.maxNumTrays,
            });
          });
      } else {
        wingTypes.forEach((wingType) => {
          wingTypesMapped.push({
            value: wingType.id,
            label: wingType.name,
            description: wingType.description,
            maxNumSlots: wingType.maxNumSlots,
            maxNumTrays: wingType.maxNumTrays,
          });
        });
      }
      this.setState({
        wingTypeList: wingTypesMapped,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.principalWing !== prevProps.principalWing) {
      const { listWingTypes } = this.props;
      this.setState({
        wingInfo: null,
        typeId: null,
      });

      listWingTypes((response) => {
        const wingTypes = response.data.data;
        const wingTypesMapped = [];

        if (this.props.principalWing?.wingTypeId === 3) {
          wingTypes
            .filter((wingType) => wingType.id === 3)
            .forEach((wingType) => {
              wingTypesMapped.push({
                value: wingType.id,
                label: wingType.name,
                description: wingType.description,
                maxNumSlots: wingType.maxNumSlots,
                maxNumTrays: wingType.maxNumTrays,
              });
            });
        } else {
          wingTypes.forEach((wingType) => {
            wingTypesMapped.push({
              value: wingType.id,
              label: wingType.name,
              description: wingType.description,
              maxNumSlots: wingType.maxNumSlots,
              maxNumTrays: wingType.maxNumTrays,
            });
          });
        }
        this.setState({
          wingTypeList: wingTypesMapped,
        });
      });
    }
  }

  // -------------Events-----------

  wingTypeEvent(event) {
    const { value } = event;
    const { label } = event;
    const { description } = event;
    const { maxNumSlots } = event;
    const { maxNumTrays } = event;
    const { listLockerTypes } = this.props;
    const wingInfo = {
      wingTypeId: value,
      wingTypeName: label,
      wingTypeDescription: description,
      maxNumSlots,
      maxNumTrays,
      lockerTypeId: null,
      lockerTypeName: "",
      lockerSlotTypes: [],
      controlModuleSlot: null,
    };

    this.setState({
      wingInfo,
      typeId: value,
    });

    if (value !== 3) {
      this.setState({ isDisabledSaveButton: false });
    } else if (value === 3) {
      listLockerTypes((response) => {
        const lockerTypes = response.data.data;
        const lockerTypesMapped = [];
        lockerTypes.forEach((lockerType) => {
          lockerTypesMapped.push({
            value: lockerType.id,
            label: lockerType.name,
            description: lockerType.description,
          });
        });
        this.setState({
          lockerTypeList: lockerTypesMapped,
        });
      });
      this.setState({ isDisabledSaveButton: true });
    }
  }

  lockerTypeEvent(event) {
    const { listLockerSlotTypes } = this.props;
    const { value } = event;
    const { label } = event;
    const { wingInfo } = this.state;
    const wing = { ...wingInfo };
    wing.lockerTypeId = value;
    wing.lockerTypeName = label;
    wing.lockerTypeDescription = event.description;
    this.setState({ wingInfo: wing });

    listLockerSlotTypes((response) => {
      this.setState({
        lockerSlotTypeList: response.data.data,
      });
    });
  }

  selectedLockerSlotTypes(e, row) {
    const { wingInfo } = this.state;
    const wing = { ...wingInfo };

    if (e.target.checked === true) {
      wing.lockerSlotTypes.push(row);
      this.setState({ isDisabledSaveButton: false });
    } else {
      wing.lockerSlotTypes = wing.lockerSlotTypes.filter((item) => item.id !== row.id);
      if (wing.lockerSlotTypes.length === 0) {
        this.setState({ isDisabledSaveButton: true });
      }
    }
    this.setState({ wingInfo: wing });
  }

  addWingButton = () => {
    const { setIsVisible, addWingToMachine, updatePage } = this.props;
    const { wingInfo, vm } = this.state;
    this.setState({ typeId: null, isDisabledSaveButton: true });
    setIsVisible(false);

    if (vm === null) {
      // To add wing to new machine
      this.props.wingInfo(wingInfo);
    } else if (vm) {
      // To add wing to existing machine
      const lockerSlotTypeIds = [];
      if (wingInfo.lockerSlotTypes.length > 0) {
        wingInfo.lockerSlotTypes.forEach((item) => {
          lockerSlotTypeIds.push(parseInt(item.id, 10));
        });
      }

      const wingDto = {
        vendingMachineId: vm.id,
        wingTypeId: wingInfo.wingTypeId,
        lockerTypeId: wingInfo.lockerTypeId ? wingInfo.lockerTypeId : 0,
        lockerSlotTypeIds: lockerSlotTypeIds.length > 0 ? lockerSlotTypeIds : [0],
      };

      if (wingDto) {
        this.props.setStateAsync({
          isLoadingAdd: true,
        });
        addWingToMachine(wingDto, (response) => {
          this.setState({ loading: false });
          if (!response.data && String(response).includes("Error:")) {
            // Connection error
            Modal.error({
              title: Strings.generalResponses.failedTransaction,
              content: Strings.generalResponses.saveError,
            });
          } else if (response.data.code === 4008) {
            // Repeated entity error
            Modal.error({
              title: Strings.generalResponses.failedTransaction,
              content: Strings.generalResponses.alreadyExistsError,
            });
          } else if (response.data && response.data.status === "SUCCESS") {
            // Successfull entity save
            Modal.success({
              title: Strings.generalResponses.successfulTransaction,
              content: Strings.generalResponses.saveSuccess,
              onOK: updatePage(),
            });
          } else {
            // Other error
            Modal.error({
              title: Strings.generalResponses.failedTransaction,
              content: Strings.generalResponses.alreadyExistsError,
            });
          }
        });
      }
    }
  };

  handleButton = () => {
    this.setState({ typeId: null, isDisabledSaveButton: true });
    this.props.setIsVisible(false);
  };

  render() {
    return (
      <Modal
        title={
          <div className="row">
            <div className="alignLabel col-12">
              <h2>{Strings.machine.wingParameterization}</h2>
            </div>
          </div>
        }
        onOk={this.handleButton}
        onCancel={this.handleButton}
        footer={[]}
        visible={this.props.isVisible}
        centered
      >
        <div className="row">
          <div className="col-md-12">
            <div className="row" style={{ padding: 12 }}>
              <SelectGeneral
                text={<b>{Strings.machine.wingType}</b>}
                options={this.state.wingTypeList}
                isReq
                onChange={(e) => this.wingTypeEvent(e)}
                defaultValue={this.state.typeId}
              />
            </div>
            {this.state.typeId || this.state.typeId === 0 ? (
              <div className="row" style={{ padding: 4 }}>
                <div className="col-md-4" style={{ padding: 4 }}>
                  <p>
                    <b>{Strings.generalTerms.description}:</b>
                  </p>
                </div>
                <div className="col-md-8">
                  <p>{this.state.wingInfo.wingTypeDescription}</p>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.typeId && this.state.typeId === 3 ? (
              <div>
                <div className="row" style={{ padding: 10 }}>
                  <SelectGeneral
                    text={<b>{Strings.machine.lockerType}</b>}
                    options={this.state.lockerTypeList}
                    isReq
                    onChange={(e) => this.lockerTypeEvent(e)}
                    defaultValue={this.state.wingInfo.lockerTypeId}
                  />
                </div>

                {this.state.wingInfo.lockerTypeDescription ? (
                  <div>
                    <div className="row" style={{ padding: 4 }}>
                      <div className="col-md-4" style={{ padding: 4 }}>
                        <p>
                          <b>{Strings.generalTerms.description}:</b>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p>{this.state.wingInfo.lockerTypeDescription}</p>
                      </div>
                    </div>

                    <div className="row" style={{ padding: 4 }}>
                      <Table
                        dataSource={this.state.lockerSlotTypeList}
                        loading={this.state.loading}
                        pagination={false}
                        bordered
                        size="middle"
                        footer={null}
                        rowKey="id"
                        style={{ margin: "auto" }}
                      >
                        <Column key="id" width={200} title={Strings.machine.lockerModalities} render={(row) => <div>{lockerModalitiesLocalization(row.name)} </div>} />
                        <Column width={200} title={Strings.generalTerms.description} dataIndex="description" />
                        <Column width={20} render={(row) => <Checkbox defaultChecked={false} value={row.checked} onChange={(e) => this.selectedLockerSlotTypes(e, row)} />} />
                      </Table>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {this.state.typeId === 0 || (this.state.typeId && this.state.typeId !== 3) ? (
              <div>
                <div className="row" style={{ padding: 4 }}>
                  <div className="col-md-8" style={{ padding: 4 }}>
                    <p>
                      <b>{Strings.machine.maxNumSlots}:</b>
                    </p>
                  </div>
                  <div className="col-md-4" style={{ padding: 4 }}>
                    <p>{this.state.wingInfo.maxNumSlots}</p>
                  </div>
                </div>
                <div className="row" style={{ padding: 4 }}>
                  <div className="col-md-8" style={{ padding: 4 }}>
                    <p>
                      <b>{Strings.machine.maxNumTrays}:</b>
                    </p>
                  </div>
                  <div className="col-md-4" style={{ padding: 4 }}>
                    <p>{this.state.wingInfo.maxNumTrays}</p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="vertSpace col-md-8" />
          <div className="vertSpace col-md-4">
            <AssignButton onClick={(e) => this.addWingButton(e)} loading={this.state.loading} disabled={this.state.isDisabledSaveButton} />
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, {
  listWingTypes,
  listLockerTypes,
  listLockerSlotTypes,
  addWingToMachine,
})(WingConfigModal);
