// Dependencies
import React, { Component } from "react";
import "../Global/css/page404.css";
import { Icon, Typography } from "antd";
import Strings from "../../systemVariables/languageStrings";

const { Title, Text } = Typography;
const { generalResponses } = Strings;

class error500 extends Component {
  render() {
    return (
      <div className="warning-container" style={{}}>
        <Title type="warning" level={1}>
          500 <Icon type="bug" />
        </Title>
        <Text strong className="error">
          {generalResponses.isError500}
        </Text>
        <hr />
        <div className="bottom-content">
          <Text disabled className="otherwise">
            {" "}
            <a href="/">{generalResponses.clickHere}</a> {generalResponses.clickHereUndirection}
          </Text>
        </div>
      </div>
    );
  }
}

export default error500;
