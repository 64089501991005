//Dependencies
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Divider, Table, Icon} from 'antd';
import { RangeDate } from "../../GenericComponents/DateComponent/RangeDate.jsx";
import { DATE_TIME_FORMAT } from '../../../systemVariables/TimeConfig';
import moment from 'moment';
import TextWithInfoTooltip from '../../GenericComponents/textWithInfoTooltip';
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import DispensationModal from "../../transactions/transactionsreport/TransactionsModal.jsx";

// Actions
import {
    getTransactionCategories,
    getTransactionsByUserIdPaged,
    getVendingMachineByUserId
} from '../../../actions';

//Language localization
import Strings from '../../../systemVariables/languageStrings';

const { Column, ColumnGroup} = Table;

class TransactionsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
        userId: this.props.userId,
        transactionsInfo: [],
        detailsModalIsVisible: false,
        moduleTypeId: null,
        machineId: "",
        startDate: moment().subtract(3, 'months'),
        endDate: moment(),
        page: 1,
        pageSize: 10,
        attribute: "storageDate",
        order: "DESC",
        isDisabled:true,
    };
    this.dateOnChange = this.dateOnChange.bind(this);
  }

  componentDidMount() {
    this.getTransactionCategories();

    this.props.getVendingMachineByUserId(this.state.userId, response => {
      this.setState({
        vendingMachines: response.data.data.map((item) => ({
          value: item.vendingMachineId,
          label: item.vendingMachineFriendlyName,
        })),
      });
    }) 
  }

  getTransactionCategories() {
    this.setState({
      loadingInfo: true,
    });
    this.props.getTransactionCategories((response) => {
      this.setState({
        transactionCategories: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
        loadingInfo: false,
      });
    });

  }

  moduleTypeChange= (e) => {
    this.setState({
      loadingInfo: true,
      moduleTypeId: e.value,
      machineId: "",
    });

      this.props.getTransactionsByUserIdPaged(this.state.userId, "", e.value, this.state.startDate.format(), this.state.endDate.format(), this.state.page, this.state.pageSize, this.state.attribute, this.state.order, (response) => {
          this.setState({
            transactionsInfo: response.data.data.content.map((item) => ({
              vmFriendlyName: item.vmFriendlyName,
              vmSlotNumber: item.vmSlotNumber,
              productTransactionTypeName: item.productTransactionTypeName,
              provisioningId: item.provisioningId,
              soProductErpCode: item.soProductErpCode,
              soProductName: item.soProductName,
              storageDate: item.storageDate,
              transactionDate: item.transactionDate,
              productImgPathS3: item.productImgPathS3,
              scDependencyName : item.scDependencyName,
              scDepartmentName : item.scDepartmentName,
              scUserProfileName : item.scUserProfileName,
              scCostCenterName : item.scCostCenterName,
              userCompleteName : item.userCompleteName,
              userIdentityCardNumber : item.userIdentityCardNumber,
              userImgPathS3 : item.userImgPathS3,
              scDispensationRuleName : item.scDispensationRuleName,
            })),
            loadingInfo: false,
            isDisabled:false,
          });
    });  

  }

  handleVmChange= (e) => {
    this.setState({
      loadingInfo: true,
      machineId: e.value,
    });

    this.props.getTransactionsByUserIdPaged(this.state.userId, e.value, this.state.moduleTypeId, this.state.startDate.format(), this.state.endDate.format(), this.state.page, this.state.pageSize, this.state.attribute, this.state.order, (response) => {
        this.setState({
          transactionsInfo: response.data.data.content.map((item) => ({
            vmFriendlyName: item.vmFriendlyName,
            vmSlotNumber: item.vmSlotNumber,
            productTransactionTypeName: item.productTransactionTypeName,
            provisioningId: item.provisioningId,
            soProductErpCode: item.soProductErpCode,
            soProductName: item.soProductName,
            storageDate: item.storageDate,
            transactionDate: item.transactionDate,
            productImgPathS3: item.productImgPathS3,
            scDependencyName : item.scDependencyName,
            scDepartmentName : item.scDepartmentName,
            scUserProfileName : item.scUserProfileName,
            scCostCenterName : item.scCostCenterName,
            userCompleteName : item.userCompleteName,
            userIdentityCardNumber : item.userIdentityCardNumber,
            userImgPathS3 : item.userImgPathS3,
            scDispensationRuleName : item.scDispensationRuleName,
          })),
          loadingInfo: false,
        });
  }); 
  
}

  dateOnChange(date) {
     if (date.length === 0) {
       date[0] = moment().subtract(3, 'months')
       date[1] = moment()
    }

    // (date[0].format())
    this.setState({
      startDate: date[0],
      endDate: date[1],
       loadingInfo: true,
       page: 1,
       transactionsInfo: [],
    })

    this.props.getTransactionsByUserIdPaged(this.state.userId, this.state.machineId, this.state.moduleTypeId, date[0].format(), date[1].format(), this.state.page, this.state.pageSize, this.state.attribute, this.state.order, (response) => {
        this.setState({
          transactionsInfo: response.data.data.content.map((item) => ({
            vmFriendlyName: item.vmFriendlyName,
            vmSlotNumber: item.vmSlotNumber,
            productTransactionTypeName: item.productTransactionTypeName,
            provisioningId: item.provisioningId,
            soProductErpCode: item.soProductErpCode,
            soProductName: item.soProductName,
            storageDate: item.storageDate,
            transactionDate: item.transactionDate,
            productImgPathS3: item.productImgPathS3,
            scDependencyName : item.scDependencyName,
            scDepartmentName : item.scDepartmentName,
            scUserProfileName : item.scUserProfileName,
            scCostCenterName : item.scCostCenterName,
            userCompleteName : item.userCompleteName,
            userIdentityCardNumber : item.userIdentityCardNumber,
            userImgPathS3 : item.userImgPathS3,
            scDispensationRuleName : item.scDispensationRuleName,
          })),
          loadingInfo: false,
        });
  });   
 }


  render() {
    return (

      <Fragment>

         <div className="row">

          <div className='col-xs-12 col-md-12'>
            <Divider orientation="left">
               <TextWithInfoTooltip name={Strings.myAccount.transactionsInfo} tooltip={Strings.transaction.transactionsReportTooltip}></TextWithInfoTooltip>
            </Divider>

            {this.state.vendingMachines && this.state.vendingMachines.length > 0 ?
            <div>
                        <div>
                          <div className="row">
                            <div className="vertSpace col-xs-12 col-md-6">
                              <SelectGeneral
                                text={Strings.machine.wingType}
                                tooltip={Strings.machine.wingTooltip}
                                options={this.state.transactionCategories}
                                onChange={(e) => this.moduleTypeChange(e)}
                                disabled={false}
                                defaultValue={this.state.moduleTypeId}
                              />
                            </div>
                            </div>

                           <div className="row">
                            <div className="vertSpace col-xs-12 col-md-6">
                              <SelectGeneral
                                text={Strings.machine.vendingMachine}
                                tooltip={Strings.machine.machineTooltip}
                                options={this.state.vendingMachines}
                                onChange={this.handleVmChange}
                                disabled={this.state.isDisabled}
                                defaultValue={this.state.machineId}
                              />
                            </div>
                          </div> 

                             <div className="row">
                              <div className="vertSpace col-xs-12 col-md-6">
                          <RangeDate
                            text={Strings.dispensationReport.storageDate}
                            tooltip={Strings.dispensationReport.dispensationDateTooltip}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.dateOnChange}
                            format="MMMM Do YYYY, h:mm:ss a"
                            isDisabled={this.state.isDisabled}
                          />
                        </div>
                            </div>
                             </div>
 
          <div className="vertSpace col-12">

            {this.state.transactionsInfo && this.state.transactionsInfo.length > 0 ? 
              <div className="row">
                <div className="vertSpace col-xs-12 col-md-12">
                  <Table
                    dataSource={this.state.transactionsInfo}
                    loading={this.state.loadingInfo}
                    bordered={true}
                    pagination={false}
                    scroll={{ y: 500 }}
                    size="small"
                    rowKey={'id'}
                  >
                    <Column
                      title={Strings.machine.machine}
                      dataIndex="vmFriendlyName"
                    />
                    <Column
                      title={Strings.transaction.transactionType}
                      dataIndex="productTransactionTypeName"
                    />
                    <Column
                      title={Strings.planogram.slotNumber}
                      dataIndex="vmSlotNumber"
                    />
                    {this.state.moduleTypeId == 0 ?  //VM transactions
                    <ColumnGroup title={Strings.product.product}>
                      <Column title={Strings.generalTerms.code} key="soProductErpCode" render={(row) => <div>{!row.soProductErpCode ? row.scProductCode : row.soProductErpCode}</div>} />
                      <Column title={Strings.product.product} key="soProductName" render={(row) => <div>{!row.soProductName ? row.scProductName : row.soProductName}</div>} />
                    </ColumnGroup>
                    :""}
                    <Column
                    title={<TextWithInfoTooltip name={Strings.dispensationReport.storageDate} tooltip={Strings.dispensationReport.storageDateTooltip} />}
                      render={row => <span> {moment(row.storageDate).format(DATE_TIME_FORMAT)} </span>}
                    />
                    <Column
                      title={<TextWithInfoTooltip name={Strings.dispensationReport.transactionDate} tooltip={Strings.dispensationReport.transactionDateTooltip} />}
                      render={row => <span> {moment(row.transactionDate).format(DATE_TIME_FORMAT)} </span>}
                    />
                    <Column
                      title={<Icon type="profile" theme="twoTone" />}
                      width="50px"
                      align="center"
                      key="modal"
                      render={(row) => (
                        <div>
                          <DispensationModal selectedRow={row} />
                        </div>
                      )}
                    />
                  </Table>
                </div>
              </div>
                   :""}

            {this.state.transactionsInfo == null ? 
              <div className="row">
              <div className="vertSpace col-xs-12 col-md-12">
                    <p>{Strings.myAccount.noTransactionsInfo}</p>
              </div>
              </div>
            : "" }
              </div>
            
           </div>
        
        :               
              <div className="row">
                <div className="vertSpace col-xs-12 col-md-12">
                  <p>{Strings.myAccount.noTransactionsInfo2}</p>
                </div>
              </div>
         }

           </div>


        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}
export default connect(mapStateToProps, {
  getTransactionCategories,
  getTransactionsByUserIdPaged,
  getVendingMachineByUserId
})(TransactionsInfo);
