import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// Actions
import { 
  getLocationsBySoDependencySelectorContainingLocation,
  getLocationsByScDependencySelectorContainingLocation,
  disableLocation,
  exportLocationsInformation } from "../../../actions";
// Components
import { Button, Divider, Tooltip, Modal } from "antd";
import SoScVmDependencySelectorFn from "../../GenericComponents/selectComponent/SoScVmDependencySelectorFn";
import { ValidatePermissionForComponent } from '../../../Utils/validatePermissionForComponent';
import { AddButton, ExportButton } from '../../GenericComponents/buttons';
import Titles from "../../GenericComponents/titles";
import CustomTable from '../../GenericComponents/CustomTable';

// Language Localization
import Strings from "../../../systemVariables/languageStrings";

function ListLocations(props) {

  const { permissions: { data }, soScSelectionProps } = props
  
  const [soDependencyId, setSoDependencyId] = useState(soScSelectionProps?.so? soScSelectionProps.so.id : null);
  const [soDependencyName, setSoDependencyName] = useState(soScSelectionProps?.so? soScSelectionProps.so.label : null );
  const [scDependencyId, setScDependencyId] = useState(soScSelectionProps?.sc? soScSelectionProps.sc.id : null);
  const [scDependencyName, setScDependencyName] = useState(soScSelectionProps?.sc? soScSelectionProps.sc.label : null);

  const scSortingKey = "Scdependency"

  const tableColumns = [
    {name: 'location', title: Strings.smartVendingClinical.location, hasSearch: true},
    {name: 'scDependency.name', title: Strings.scDependency.indirectClient, hasSort: !scDependencyId, sortingKey: scSortingKey},
    {name: 'sector', title: Strings.smartVendingClinical.sector},
    {name: 'building', title: Strings.smartVendingClinical.building},
    {name: 'floor', title: Strings.smartVendingClinical.floor},
  ]

  const editPrivilege = "PRIVILEGE LOCATIONS POST"
  const editPath = "/editLocations"
  const editProp = "scDependency"
  const removePrivilege = "PRIVILEGE LOCATIONS DELETE"

  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [locationsInfo, setLocationsInfo] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterData, setFilterData] = useState({dataIndex: '', selectedKeys: []});
  const [sortConfig, setSortConfig] = useState({field: '', order: null});

  useEffect(() => {
    if (isSearch) { getLocationsBySoScDependencySelector();}
  }, [isSearch, page, pageSize, filterData, sortConfig])

  // Events
  const soDependencyHandleChange = (event) => {
    const { value: soDependencyId, label: soDependencyName } = event;
    setSoDependencyId(soDependencyId);
    setSoDependencyName(soDependencyName);
    setScDependencyId(null);
    setScDependencyName(null);
    setIsSearchButtonDisabled(false);
    setIsSearch(false);
    setIsAddButtonDisabled(true);
    setLocationsInfo([]);
  };

  const scDependencyHandleChange = (event) => {
    const { value: scDependencyId, label: scDependencyName } = event;
    if (scDependencyId === null) {
      setScDependencyId(null);
      setScDependencyName(null);
      setIsAddButtonDisabled(true);
    } else {
      setScDependencyId(scDependencyId);
      setScDependencyName(scDependencyName);
      setIsAddButtonDisabled(false);
    }
    setIsSearch(false);
    setLocationsInfo([]);
  };

  const getLocationsBySoScDependencySelector = async () => {
    const location = filterData.dataIndex === 'location' ? filterData.selectedKeys[0] : ''
    setIsLoadingInfo(true);
    setIsSearchButtonDisabled(true);
    if (scDependencyId === null) {
      let getLocationsInfoAns = await getLocationsBySoDependencySelectorContainingLocation(soDependencyId, location, page, pageSize, sortConfig.field, sortConfig.order);
      if (getLocationsInfoAns.status === 200) {
        setLocationsInfo(getLocationsInfoAns.data.data.content);
        setTotalElements(getLocationsInfoAns.data.data.totalElements);
      } else {
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.generalError,
        });
      }
    } else {
      let getLocationsInfoAns = await getLocationsByScDependencySelectorContainingLocation(scDependencyId, location, page, pageSize, sortConfig.field, sortConfig.order);
      if (getLocationsInfoAns.status === 200) {
        setLocationsInfo(getLocationsInfoAns.data.data.content);
        setTotalElements(getLocationsInfoAns.data.data.totalElements);
      } else {
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.generalError,
        });
      }
    }
    setIsLoadingInfo(false);
    setIsSearchButtonDisabled(false);
  }

  const onClickCheckButton = (e) => {
    setIsSearch(true);
    setPage(1);
    setPageSize(10);
    setFilterData({dataIndex: '', selectedKeys: []})
    setSortConfig({field: '', order: null})
  }

  const showPagination = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleSearch = async (dataIndex, selectedKeys) => {
    setPage(1);
    setFilterData({dataIndex, selectedKeys})
  }

  const handleReset = () => {
    setPage(1);
    setFilterData({dataIndex: '', selectedKeys: []})
  }

  const onSort = (pagination, filters, sorter) => {
    let sortKey = null;
    if (sorter.field) {
        if (sorter.field === "scDependency.name") {
            sortKey = scSortingKey;
        } else {
            sortKey = sorter.field;
        }
    }
    setPage(1);
    setSortConfig({ field: sortKey, order: sorter.order ?? null });
  };

  const handleDisableLocations = async (row) => {
    let disableLocationsAns = await disableLocation(row);
    if (!disableLocationsAns.data && String(disableLocationsAns).includes("Error:")) {
      // Connection error
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: Strings.generalResponses.generalError,
      });
    } else if (disableLocationsAns.data.code === 0) {
      // Entity successfully disabled
      Modal.success({
        title: Strings.generalResponses.successfulTransaction,
        content: Strings.generalResponses.successfulDelete,
      });
      // Force query again
      getLocationsBySoScDependencySelector();
    }
  };

  const onExport = async () => {
    setIsLoadingInfo(true)
    const columnHeaders = [
      Strings.scDependency.indirectClient,
      Strings.smartVendingClinical.location,
      Strings.smartVendingClinical.sector,
      Strings.smartVendingClinical.building,
      Strings.smartVendingClinical.floor,
      Strings.generalTerms.description,
    ]
    let exportAns = await exportLocationsInformation(soDependencyId, scDependencyId || null, 'csv', columnHeaders)
    if (!exportAns.data || String(exportAns).includes("Error:") || String(exportAns).includes("ERR_CONNECTION_REFUSED")) {
      if (String(exportAns).includes("404")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.queryResponse,
        });
      } else {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.reportGenerationError,
        });
      }
    } else {
      const url = window.URL.createObjectURL(new Blob([exportAns.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `LocationsInformation ${soDependencyName}${scDependencyId ? ' - ' + scDependencyName : ''}.csv`);
      document.body.appendChild(link);
      link.click();
    }
    setIsLoadingInfo(false)
  }

  return (
    <div className="content-container">
      <div className="row">
        <Titles title={Strings.smartVendingClinical.locations} tooltip={Strings.smartVendingClinical.locationsTootip}/>
      </div>

      <div className="row">
        <div className="vertSpace col-12">
          <Divider orientation="left">
            <h3>{Strings.generalTerms.generalParameters}</h3>
          </Divider>
        </div>
      </div>

      <SoScVmDependencySelectorFn
        onSoDependencyChange={soDependencyHandleChange}
        onScDependencyChange={scDependencyHandleChange}
        dependencyType={sessionStorage.getItem("dependencyType")}
        dependencyId={sessionStorage.getItem("dependencyId")}
        hasSc
      />
      <div className='button-space'>
        <div className="vertSpace button-margin"/>
        <div className='vertSpace button-container button-container-width'>
          <Tooltip title={Strings.transaction.getTransactionsToolTip}>
            <Button
              id="searchButton"
              style={{ margin: "5px" }}
              type="primary"
              onClick={(e) => onClickCheckButton(e)}
              icon="search"
              disabled={(isSearchButtonDisabled || !soDependencyId)}
              loading={isLoadingInfo}
            >
              {Strings.generalTerms.check}
            </Button>
          </Tooltip>
        <ValidatePermissionForComponent permission="PRIVILEGE LOCATIONS REPORT GET" permissions={data}>
          <ExportButton
            onClick={async () => await onExport()}
            isDisabled={(isSearchButtonDisabled || !soDependencyId)}
            loading={isLoadingInfo}
          />
        </ValidatePermissionForComponent>
        <ValidatePermissionForComponent permission="PRIVILEGE LOCATIONS PUT" permissions={data}>
          <AddButton
            link={"/addLocations"}
            tooltip={Strings.smartVendingClinical.addLocationInformationTooltip}
            props={{
              basicProps: {
                id: scDependencyId,
                name: scDependencyName,
              },
            }}
            disabled={(isAddButtonDisabled || !scDependencyId)}
            loading={isLoadingInfo}
          />
        </ValidatePermissionForComponent>
        </div>
      </div>

      <div className="row">
        <div className="vertSpace col-12">
          <Divider orientation="left">
            <h3>{Strings.smartVendingClinical.locations}</h3>
          </Divider>
          <CustomTable
            tableData={locationsInfo}
            isloadingInfo={isLoadingInfo}
            page={page}
            pageSize={pageSize}
            totalElements={totalElements}
            showPagination={showPagination}
            columns={tableColumns}
            editable
            editPrivilege={editPrivilege}
            editPath={editPath}
            editProps={editProp}
            removable
            removePrivilege={removePrivilege}
            handleDisable={handleDisableLocations}
            handleSearch={handleSearch}
            handleReset={handleReset}
            onSort={onSort}
            sortConfig={sortConfig}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    soScSelectionProps: state.soScSelection,
    permissions: state.userSystemPrivileges.privilegeObj
  };
}

export default connect(mapStateToProps)(ListLocations)