import React from "react";

import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";
import SortButtons from "../GenericComponents/buttons/sortButtons";

export default function ColumnTitleWithSortButtons(props) {
  return (
    <div className="row">
      <div className="col-10">{props.tooltip == null ? <span>{props.name}</span> : <TextWithInfoTooltip name={props.name} tooltip={props.tooltip} />}</div>
      <div className="col-2" style={{ paddingLeft: "0", paddingRight: "0", alignSelf: "flex-end" }}>
        <SortButtons onClickSort={(order) => props.setSortOrder(order, props.attribute)} />
      </div>
    </div>
  );
}
