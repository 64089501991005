import axios from 'axios';
import { host, port, path, version } from '../systemVariables';

const resource = '/soroles';

export function getSystemSoRoles(soDependencyId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/system`, {
        params: {
          soDependencyId
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        callback(response);
      })
      .catch(error => {
        return error;
      });
  };
}

export function getVendingMachineSoRoles(soDependencyId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/vm`, {
        params: {
          soDependencyId
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        callback(response);
      })
      .catch(error => {
        return error;
      });
  };
}
