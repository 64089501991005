// Dependencies

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { render } from "react-dom";

import { AppRoutes } from "./Routes";
import { store } from "./helpers/store";

import "./index.css";

render(
  <Provider store={store}>
    <CookiesProvider>
      <Router>
        <AppRoutes />
      </Router>
    </CookiesProvider>
  </Provider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}
