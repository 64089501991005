// Dependencies
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Divider, Checkbox, DatePicker, Modal, InputNumber } from "antd";
import _ from "lodash";

// Components
import Titles from "../../GenericComponents/titles.js";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import { SaveButton, ReturnButton } from "../../GenericComponents/buttons/index";
import NormalText from "../../GenericComponents/text/NormalText";

// Actions
import {
  listFrequencyUnits,
  listUsersWithoutCredit,
  insertCreditByUser,
  updateLockerLoanExceptionByUser,
} from "../../../actions";

// Localization
import Strings from "../../../systemVariables/languageStrings";
import { FreqUnitNameLocalizationPlural } from "../../../Utils/LanguageLocalization/frequencyUnitNameLocalization";

import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";

class EditLoanExceptionByUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingAdd: false,
      isEndDateChecked: false,
      disableAssignButton: true,

      // Flag used to redirect to /listCreditByUser if any scDependency was selected
      redirectBackToList: false,

      exceptionByUser: this.props.location.props ? this.props.location.props.exceptionByUser : null,

      fullName: this.props.location.props ? this.props.location.props.exceptionByUser.fullName : null,
      scDepartmentName: this.props.location.props.exceptionByUser.scDepartmentName ? this.props.location.props.exceptionByUser.scDepartmentName : null,
      scUserProfileName: this.props.location.props.exceptionByUser.scUserProfileName ? this.props.location.props.exceptionByUser.scUserProfileName : null,
      discountPercent: this.props.location.props ? this.props.location.props.exceptionByUser.discountPercent : null,
      frequencyUnitId: this.props.location.props ? this.props.location.props.exceptionByUser.frequencyUnitId : null,
      frequencyValue: this.props.location.props ? this.props.location.props.exceptionByUser.frequencyValue : null,
      exceptionStartDate: this.props.location.props ? this.props.location.props.exceptionByUser.exceptionStartDate : null,
      exceptionEndDate: this.props.location.props ? this.props.location.props.exceptionByUser.exceptionEndDate : null,
      disableEndDatePicker: !this.props.location.props.exceptionByUser.exceptionEndDate,
    };
    this.handleOkNoScDependencyWarning = this.handleOkNoScDependencyWarning.bind(this);
  }

  componentDidMount() {
    const { exceptionByUser } = this.state;
    const { listFrequencyUnits } = this.props;

    if (!exceptionByUser) {
      this.setState({ redirectBackToList: true });
    } else {
      // Get the list of frequency units.
      listFrequencyUnits((response) => {
        if (response.data.data) {
          const nativeFrequencyUnits = response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          this.setState({
            frequencyUnits: FreqUnitNameLocalizationPlural(nativeFrequencyUnits),
          });
        }
      });
      if (exceptionByUser.frequencyUnitId == null) {
        this.setState({ isIlimitedChecked: true });
      } else {
        this.setState({ isIlimitedChecked: false });
      }
    }
  }

  // Events

  discountInputEvent(event) {
    const { frequencyValue, exceptionStartDate, frequencyUnitId, isIlimitedChecked } = this.state;

    const value = _.trimStart(event);
    this.setState({ discountPercent: value });

    if (!value) {
      this.setState({ disableAssignButton: true });
    } else if ((value > 0 && frequencyValue > 0 && exceptionStartDate && frequencyUnitId != null) ||
    (value > 0 && isIlimitedChecked === true))  {
      this.setState({ disableAssignButton: false });
    } else {
      this.setState({ disableAssignButton: true });
    }
  }

  frequencyValueInputEvent(event) {
    const { discountPercent, exceptionStartDate, frequencyUnitId } = this.state;

    const value = _.trimStart(event);
    this.setState({ frequencyValue: value });

    if (!value) {
      this.setState({ disableAssignButton: true });
    } else if (value > 0 && discountPercent > 0 && exceptionStartDate && frequencyUnitId != null) {
      this.setState({ disableAssignButton: false });
    } else {
      this.setState({ disableAssignButton: true });
    }
  }

  frecuencyUnitSelect(event) {
    const { discountPercent, exceptionStartDate, frequencyValue } = this.state;

    const { value } = event;
    this.setState({ frequencyUnitId: value });

    if ( 
      discountPercent && 
     exceptionStartDate && 
     frequencyValue != null) {
      this.setState({
        disableAssignButton: false
      })
  }
  else {
      this.setState({
        disableAssignButton: true
      })
  }
  }

  startDateEvent = (date) => {
    const { isEndDateChecked, exceptionStartDate, exceptionEndDate } = this.state;

    const startDateLocal = moment(date).format();
    if (isEndDateChecked === true) {
      if (exceptionEndDate < startDateLocal) {
        this.setState({ exceptionStartDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidStartDateError,
        });
      } else {
        this.setState({ exceptionStartDate: _.split(startDateLocal, "T")[0] });
      }
    } else {
      this.setState({ exceptionStartDate: _.split(startDateLocal, "T")[0] });
    }
  };

  endDateEvent = (date) => {
    const { isEndDateChecked, exceptionStartDate, exceptionEndDate } = this.state;

    const endDateLocal = moment(date).format();
    if (isEndDateChecked === true) {
      if (endDateLocal < exceptionStartDate) {
        this.setState({ exceptionEndDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidEndDateError,
        });
      } else {
        this.setState({ exceptionEndDate: _.split(endDateLocal, "T")[0] });
      }
    } else {
      this.setState({ exceptionEndDate: _.split(endDateLocal, "T")[0] });
    }
  };

  nullEndDateHandleChange(e) {
    const { disableEndDatePicker, exceptionStartDate } = this.state;

    const endDateLocal = moment(exceptionStartDate).format();
    if (e.target.checked === true) {
      this.setState({
        exceptionEndDate: null,
        disableEndDatePicker: !disableEndDatePicker,
        isEndDateChecked: true,
      });
    } else {
      this.setState({
        exceptionEndDate: _.split(endDateLocal, "T")[0],
        disableEndDatePicker: !disableEndDatePicker,
        isEndDateChecked: false,
      });
    }
  }

  nullIlimitedHandleChange(e) {
    if (e.target.checked === true) {
      this.setState({
        isIlimitedChecked: true,
        frequencyValue: null,
        frequencyUnitId: null,
        exceptionStartDate: null,
        exceptionEndDate: null,
        disableEndDatePicker: true,
        disableAssignButton: false,
      });
    } else {
      const date = moment.utc(moment()).local().format();
      this.setState({
        exceptionStartDate: `${_.split(date, "T")[0]} 00:00:00`,
        isIlimitedChecked: false,
        disableAssignButton: true,
      });
    }
  }

  editExceptionByUserEvent = () => {
    const { exceptionByUser, discountPercent, frequencyUnitId, frequencyValue, exceptionStartDate, exceptionEndDate } = this.state;
    const{updateLockerLoanExceptionByUser}=this.props;

    this.setState({
      loadingAdd: true,
      disableAssignButton: true,
    });

    const infoDto = {
      userId: exceptionByUser.id,
      discountPercent,
      frequencyUnitId: frequencyUnitId !== null ? frequencyUnitId : null,
      frequencyValue,
      exceptionStartDate: exceptionStartDate ? moment(exceptionStartDate).format() : null,
      exceptionEndDate: exceptionEndDate ? moment(exceptionEndDate).format() : null,
    };

    if (infoDto) {
      updateLockerLoanExceptionByUser(infoDto, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
          });
          this.setState({ redirectBackToList: true });
        } else {
          // Other error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        }
      });
    } else {
      // Other error
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: Strings.generalResponses.alreadyExistsError,
      });
      this.setState({
        loadingAdd: false,
      });
    }
  };

  noScDependencyWarning() {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOkNoScDependencyWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  }

  handleOkNoScDependencyWarning() {
    this.setState({ redirectBackToList: true });
  }

  // Information to render
  render() {
    const{redirectBackToList, fullName, scDepartmentName, scUserProfileName, discountPercent, isIlimitedChecked, frequencyValue,
      frequencyUnits, frequencyUnitId, exceptionStartDate, isEndDateChecked, exceptionEndDate, disableEndDatePicker, disableAssignButton, loadingAdd
    }=this.state;

    if (redirectBackToList) {
      // Redirects to exceptionByUser from No Product Modal Warning
      // eslint-disable-next-line react/jsx-filename-extension
      return <Redirect to="/listLockerLoanExceptionByUser" />;
    }
    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.users.lockerLoanExceptionByUser.editLockerLoanException} tooltip={Strings.users.lockerLoanExceptionByUser.editLockerLoanExceptionTooltip} />
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.users.user} </h3>
            </Divider>

            <div className="row">
              <div className="vertSpace col-12">
                <div className="col-md-6">
                  <NormalText text={Strings.generalTerms.name} name={fullName} isReq={false} />
                </div>
              </div>
            </div>

            {scDepartmentName ? (
              <div className="row">
                <div className="vertSpace col-12">
                  <div className="col-md-6">
                    <NormalText text={Strings.scDepartment.department} name={scDepartmentName} isReq={false} />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {scUserProfileName ? (
              <div className="row">
                <div className="vertSpace col-12">
                  <div className="col-md-6">
                    <NormalText text={Strings.scUserProfile.userProfile} name={scUserProfileName} isReq={false} />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.users.lockerLoanExceptionByUser.exceptionSelection} </h3>
            </Divider>

            <div className="row">
              <div className="vertSpace col-12">
                <div className="col-md-2">
                  <TextWithInfoTooltip
                    name={`${Strings.users.lockerLoanExceptionByUser.discountPercent}: `}
                    tooltip={Strings.users.lockerLoanExceptionByUser.discountPercentTooltip}
                  />
                </div>
                <div className="alignCalendars col-md-2">
                  <InputNumber value={discountPercent} isReq min={1} mas={100} onChange={(e) => this.discountInputEvent(e)} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="vertSpace col-12">
                <div className="col-md-2">
                  <TextWithInfoTooltip name={`${Strings.generalTerms.ilimited}: `} tooltip={Strings.users.lockerLoanExceptionByUser.ilimitedTooltip} />
                </div>
                <div className="alignCalendars col-md-2">
                  <Checkbox checked={isIlimitedChecked} onChange={(e) => this.nullIlimitedHandleChange(e)} />
                  &nbsp; &nbsp;
                </div>
              </div>
            </div>

            <div className="row">
              <div className="vertSpace col-12">
                <div className="col-md-2">
                  <TextWithInfoTooltip name={`${Strings.generalTerms.time}: `} tooltip={Strings.users.lockerLoanExceptionByUser.timeTooltip}/>
                </div>
                <div className="alignCalendars col-md-2">
                  <InputNumber value={frequencyValue} isReq min={1} onChange={(e) => this.frequencyValueInputEvent(e)} disabled={isIlimitedChecked} />
                </div>
                <div className="alignCalendars col-md-4">
                  <SelectGeneral
                    text={Strings.transactionMonitor.timeUnit}
                    options={frequencyUnits}
                    defaultValue={frequencyUnitId}
                    onChange={(e) => this.frecuencyUnitSelect(e)}
                    disabled={isIlimitedChecked}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="vertSpace col-12">
                <div className="col-md-2">
                  <TextWithInfoTooltip name={`${Strings.dispensationRule.startDate}: `} tooltip={Strings.users.lockerLoanExceptionByUser.exceptionStartDateTooltip} />
                </div>
                <div className="alignCalendars col-md-2">
                  <DatePicker
                    value={moment(exceptionStartDate)}
                    onChange={(date, dateString) => this.startDateEvent(date, dateString)}
                    required
                    disabled={isIlimitedChecked}
                  />
                </div>
                <div className="col-md-2">
                  <TextWithInfoTooltip name={`${Strings.dispensationRule.endDate}: `} tooltip={Strings.users.lockerLoanExceptionByUser.exceptionStartEndTooltip} />
                </div>
                <div className="alignCalendars col-md-3">
                  <Checkbox checked={isEndDateChecked} onChange={(e) => this.nullEndDateHandleChange(e)} disabled={isIlimitedChecked} />
                  &nbsp; &nbsp;
                  <DatePicker
                    value={moment(exceptionEndDate)}
                    onChange={(date, dateString) => this.endDateEvent(date, dateString)}
                    required={false}
                    disabled={disableEndDatePicker}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="vertSpace col-6 col-md-3">
            <ReturnButton link="/listLockerLoanExceptionByUser" />
          </div>
          <div className="vertSpace col-6 col-md-3">
            <SaveButton onClick={(e) => this.editExceptionByUserEvent(e)} isDisabled={disableAssignButton} loading={loadingAdd} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {
  };
}

export default connect(mapStateToProps, {
  listFrequencyUnits,
  listUsersWithoutCredit,
  insertCreditByUser,
  updateLockerLoanExceptionByUser,
})(EditLoanExceptionByUser);
