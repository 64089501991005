import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/peripheraldevices";

export const LIST_NOAHUBKEYLIST = "LIST_NOAHUBKEYLIST";

export function getNoaHubsByIsAvailable(isAvailable, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          isAvailable,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getAllAvailableNoaHubsOrNoaEmbeddedComputersByMachineGeneration(isAvailable, machineGenerationId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          isavailable: isAvailable,
          machinegenerationid: machineGenerationId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getPeripheralDevicesByVendingMachineId(vendingMachineId, callback) {
  const params = {
    vendingmachineid: vendingMachineId,
  };
  return () => {
    axios({
      method: "get",
      url: host + port + path + version + resource,
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getPeripheralDevicesByModelId(modelId, page, size, attribute, order, filterType, altSerialFilter, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          modelid: modelId,
          page,
          size,
          attribute,
          order,
          filtertype: filterType,
          altserialfilter: altSerialFilter,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getPeripheralDevicesByModelIds(modelidstype, generatioid, page, size, attribute, order, filterType, altSerialFilter, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          modelidstype,
          generatioid,
          page,
          size,
          attribute,
          order,
          filtertype: filterType,
          altserialfilter: altSerialFilter,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export async function getPeripheralDevicesByModelIdsAsync(modelidstype, generatioid, page, size, attribute, order, filterType, altSerialFilter) {
  try {
    const response = await axios.get(host + port + path + version + resource, {
      params: {
        modelidstype,
        generatioid,
        page,
        size,
        attribute,
        order,
        filtertype: filterType,
        altserialfilter: altSerialFilter,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (e) {
    return e;
  }
}

export async function getPeripheralDevicesByModelIdAsync(modelId, page, size, attribute, order, filterType, altSerialFilter) {
  try {
    const response = await axios.get(host + port + path + version + resource, {
      params: {
        modelid: modelId,
        page,
        size,
        attribute,
        order,
        filtertype: filterType,
        altserialfilter: altSerialFilter,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (e) {
    return e;
  }
}

export function addPeripheralDevices(peripheralDevice, callback) {
  return () => {
    const data = JSON.stringify(peripheralDevice);
    axios({
      method: "post",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function disablePeripheralDevice(peripheralDeviceId, callback) {
  return () => {
    axios({
      params: { peripheralid: peripheralDeviceId },
      method: "delete",
      url: host + port + path + version + resource,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function enablePeripheralDevice(peripheralDeviceId, callback) {
  return () => {
    axios({
      params: { peripheralid: peripheralDeviceId },
      method: "put",
      url: host + port + path + version + resource,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function editPeripheralDevice(pDeviceDto, callback) {
  return () => {
    const data = JSON.stringify(pDeviceDto);
    axios({
      method: "put",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
