// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Tabs, Spin, Icon, Alert } from "antd";
import "react-datepicker/dist/react-datepicker.css";

import "./VmProductSlot.css";

// Actions
import {
  listFullVmProductSlotByWingId,
  listFullLockerVmProductSlotByWingId,
  listVmTraysByWingId,
  listWingsByVmId2,
  listFullVmProductSlotByWingId2,
  getScProductInformationByScDependency,
  addVmProductSlot,
  editVmProductSlot,
  disableVmProductSlot,
  addLockerVmProductSlot,
  updateVmTray,
  disbaleVmTrays,
} from "../../../actions";
import actions from "../../../reducers/products/actions";
import actions2 from "./Components/actions2";
import "../../GenericComponents/queryResponse/queryResponse.css";
// Components
import Titles from "../../GenericComponents/titles";
import { SaveButton } from "../../GenericComponents/buttons/index";
import SoScVmDependencySelector from "../../GenericComponents/selectComponent/SoScVmDependencySelector.jsx";

// import { ValidatePermissionForComponent } from '../../../Utils/validatePermissionForComponent'
import MachineGui from "./Components/MachineGui.jsx";
import ProductTable from "./Components/ProductTable.jsx";
import ResponseModal from "./Components/ResponseModal.jsx";
import LockerGui from "../../LockerInventory/LockerGui.jsx";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

const { fetchProducts } = actions;
const { TabPane } = Tabs;
const { clearProducts } = actions2;
const planogramSetupSize = 64;

class VmProductSlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      scDependencyId: null,

      vendingMachineId: null,
      vendingMachineName: "",

      scProducts: [],

      disabledScDependency: true,
      addSelectVmDisabled: true,

      wingTabs: [],
      isLoadingInfo: false,
      isLoadingButton: false,
      isDisabled: true,

      isModalVisible: false,
      addResponseTitle: null,
      addResponseMessage: null,
      updateResponseTitle: null,
      updateResponseMessage: null,
      deleteResponseTitle: null,
      deleteResponseMessage: null,

      isLoadingDisableButton: false,
      isLoadingSaveButton: false,

      loadingVmPlanogram: false,
      loadingLockerPlanogram: false,
    };
  }

  componentDidMount() {
    this.updatePlanogramSetupSize();
    window.addEventListener("resize", this.updatePlanogramSetupSize);

    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      // userDependency: { dependencyName },
      soScSelectionProps: { sc },

      clearProducts,
      getScProductInformationByScDependency,
      fetchProducts,
    } = this.props;

    clearProducts();

    if (sc !== null) {
      this.setState({ scDependencyId: sc.id });
      // listVendingMachinesByScDependencyId(soScSelectionProps.sc.id, (response) => {
      //   if (response.data.data.length > 0) {
      //     this.setState({
      //       soMachineList: response.data.data.map((item) => ({
      //         value: item.id,
      //         label: item.friendlyName,
      //       })),
      //     });
      //   }
      // });
    }
    if (dependencyType === "so") {
      // Get scDependencyId from store
      if (sc) {
        clearProducts();
        if (sc.id !== -1) {
          this.setState({
            scDependencyId: sc.id,
            addSelectVmDisabled: false,
          });
          getScProductInformationByScDependency(sc.id, (response) => {
            this.setState({
              scProducts: response.data.data.map((item) => ({
                value: item.soProductId,
                label: `${item.soProductName} - ${item.erpProductCode}`,
              })),
            });
          });
        }
      }
    }

    // ScDependency User
    if (dependencyType === "sc") {
      this.setState({
        scDependencyId: dependencyId,
        vendingMachineId: null,
        addSelectVmDisabled: false,
      });
      fetchProducts(dependencyId);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePlanogramSetupSize);
  }

  updatePlanogramSetupSize = () => {
    this.setState({
      height: window.innerHeight - planogramSetupSize,
    });
  };

  // -----------Events------------
  handleSoDependencyChange = (event, name) => {
    const { clearProducts } = this.props;
    clearProducts();

    this.setState({
      scDependencyId: null,
      vendingMachineId: null,
      disabledScDependency: false,
      addSelectVmDisabled: true,
      scProducts: [],
      wingTabs: [],

      currentSelector: name,
    });
  };

  handleScDependencyChange = (event, name) => {
    const { value: scDependencyId } = event;

    const { clearProducts, getScProductInformationByScDependency } = this.props;
    clearProducts();

    this.setState({
      scDependencyId,

      vendingMachineId: null,
      addSelectVmDisabled: false,
      scProducts: [],
      wingTabs: [],

      currentSelector: name,
    });
    getScProductInformationByScDependency(scDependencyId, (response) => {
      this.setState({
        scProducts: response.data.data.map((item) => ({
          value: item.soProductId,
          label: `${item.soProductName} - ${item.erpProductCode}`,
        })),
      });
    });
  };

  handleVendingMachineChange = (event, name) => {
    const { value: vendingMachineId } = event;

    const { clearProducts } = this.props;

    clearProducts();
    this.setState({
      vendingMachineName: event.label,
      vendingMachineId,

      wingTabs: [],
      isLoadingInfo: true,

      currentSelector: name,
    });

    this.listVmProductSlotByWingId(vendingMachineId);
  };

  listVmProductSlotByWingId(vendingMachineId) {
    const {
      listWingsByVmId2,
      listFullVmProductSlotByWingId2,
      listVmTraysByWingId,
      // listFullVmProductSlotByWingId,
      // listFullLockerVmProductSlotByWingId
    } = this.props;
    listWingsByVmId2(vendingMachineId, (response) => {
      const wings = response.data.data;
      const wingTabs = [];

      if (wings.length > 0) {
        wings.forEach((wing) => {
          const wingTabInfo = {
            wingInfo: wing,
            planogramInfo: {
              id: wing.wingId,
              depth: 0,
              minWidth: 0,
              minHeight: 0,
              material: 0,
              numRows: 0,
              numCols: 0,
              maxWidhtMultiplier: 0,
              maxHeightMultiplier: 0,
              modalities: [],
              slotGroups: [],
              slots: [],
            },
            wingVmTrays: [],
          };

          if (wing.wingTypeId !== 3) {
            this.setState({
              isLoadingInfo: true,
            });
            listVmTraysByWingId(wing.wingId, (response) => {
              if (response.data.data) {
                wingTabInfo.wingVmTrays = response.data.data;
                this.setState({
                  isLoadingInfo: false,
                });
              }
            });
          }
          listFullVmProductSlotByWingId2(wing.wingId, (response) => {
            if (response.data.data) {
              wingTabInfo.planogramInfo = response.data.data;
              wingTabInfo.planogramInfo.isLoaded = true;
              if (!response.data.data.slots) {
                wingTabInfo.planogramInfo.slots = [];
              }

              wingTabInfo.planogramInfo.slots.map((obj) => (obj.statusId = 0));

              this.setState({
                isLoadingInfo: false,
              });
            } else {
              this.setState({
                isLoadingInfo: false,
              });
            }
          });
          wingTabs.push(wingTabInfo);
        });
        this.setState({
          isLoadingInfo: true,
        });
      }

      this.setState({
        wingTabs: wingTabs.sort((a, b) => (a.wingInfo.wingNumber > b.wingInfo.wingNumber ? 1 : b.wingInfo.wingNumber > a.wingInfo.wingNumber ? -1 : 0)),
      });
    });
  }

  // Remove trays from the frontend and db immediately
  removeVmTrays = (wingId, vmTrays, vmTrayToRemove) => {
    const { disbaleVmTrays } = this.props;
    const { wingTabs: wingTabInfo } = this.state;

    let titleModal = Strings.generalTerms.loading;
    let contentModal = <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} />;

    this.setState({
      addResponseTitle: titleModal,
      addResponseMessage: contentModal,
      isModalVisible: true,
    });

    disbaleVmTrays(vmTrayToRemove.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        titleModal = Strings.generalResponses.connectionError;
        contentModal = Strings.generalResponses.saveError;
      } else if (response.data.code === 4008) {
        // Repeated entity error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.alreadyExistsError;
        this.setState({
          isLoadingSaveButton: true,
        });
      } else if (response.data.code !== 0) {
        // Backend error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.saveError;
        this.setState({
          isLoadingSaveButton: true,
        });
      } else {
        // Successfull entity save
        titleModal = Strings.generalResponses.successfullCreation;
        contentModal = Strings.generalResponses.saveSuccess;

        // Successfull entity save
        wingTabInfo.forEach((obj) => {
          if (obj.wingInfo.wingId === wingId) {
            obj.wingVmTrays = vmTrays;
          }
        });

        this.setState({
          wingTabInfo,
          addResponseTitle: titleModal,
          addResponseMessage: contentModal,
          isModalVisible: true,
        });
      }
    });
  };

  // Add trays from the frontend and db immediately
  addVmTray = (wingId, vmTrays) => {
    const { updateVmTray } = this.props;
    const { wingTabs: wingTabInfo, vendingMachineId } = this.state;
    let titleModal = Strings.generalTerms.loading;
    let contentModal = <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} />;

    this.setState({
      addResponseTitle: titleModal,
      addResponseMessage: contentModal,
      // isDisabled: true,
      isModalVisible: true,
    });

    const vmTraysDto = {
      wingId,
      vmId: vendingMachineId,
      vmTraysDto: vmTrays,
    };

    updateVmTray(vmTraysDto, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        titleModal = Strings.generalResponses.connectionError;
        contentModal = Strings.generalResponses.saveError;
      } else if (response.data.code === 4008) {
        // Repeated entity error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.alreadyExistsError;
        this.setState({
          isLoadingSaveButton: true,
        });
      } else if (response.data.code !== 0) {
        // Backend error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.saveError;
        this.setState({
          isLoadingSaveButton: true,
        });
      } else {
        // Successfull entity save
        titleModal = Strings.generalResponses.successfullCreation;
        contentModal = Strings.generalResponses.saveSuccess;

        // Successfull entity save
        wingTabInfo.forEach((obj) => {
          if (obj.wingInfo.wingId === wingId) {
            obj.wingVmTrays = response.data.data;
          }
        });

        this.setState({
          wingTabInfo,
          addResponseTitle: titleModal,
          addResponseMessage: contentModal,
          isModalVisible: true,
        });
      }
    });
  };

  // Add product immediately after the slot config
  saveVmProduct = (slotToAdd) => {
    const { addVmProductSlot } = this.props;
    const { vendingMachineId } = this.state;
    let titleModal = Strings.generalTerms.loading;
    let contentModal = <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} />;

    this.setState({
      isLoadingSaveButton: true,

      addResponseTitle: titleModal,
      addResponseMessage: contentModal,
      // isDisabled: true,
      isModalVisible: true,
    });

    addVmProductSlot(slotToAdd, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        titleModal = Strings.generalResponses.connectionError;
        contentModal = Strings.generalResponses.saveError;

        this.setState({
          isLoadingSaveButton: true,
        });
      } else if (response.data.code === 4008) {
        // Repeated entity error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.alreadyExistsError;
        this.setState({
          isLoadingSaveButton: true,
        });
      } else if (response.data.code !== 0) {
        // Backend error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.saveError;
        this.setState({
          isLoadingSaveButton: true,
        });
      } else {
        // Successfull entity save
        titleModal = Strings.generalResponses.successfullCreation;
        contentModal = Strings.generalResponses.saveSuccess;
      }

      this.listVmProductSlotByWingId(vendingMachineId);

      this.setState({
        addResponseTitle: titleModal,
        addResponseMessage: contentModal,
        isLoadingSaveButton: false,
        isModalVisible: true,
      });
    });
  };

  // Update product immediately after the slot config
  updateVmProduct = (slotToUpdate) => {
    const { editVmProductSlot } = this.props;

    let titleModal = Strings.generalTerms.loading;
    let contentModal = <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} />;

    this.setState({
      isLoadingSaveButton: true,
      addResponseTitle: titleModal,
      addResponseMessage: contentModal,
      // isDisabled: true,
      isModalVisible: true,
    });

    editVmProductSlot(slotToUpdate, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        titleModal = Strings.generalResponses.connectionError;
        contentModal = Strings.generalResponses.saveError;
        this.setState({
          isLoadingSaveButton: true,
        });
      } else if (response.data.code === 4008) {
        // Repeated entity error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.alreadyExistsError;
        this.setState({
          isLoadingSaveButton: true,
        });
      } else if (response.data.code !== 0) {
        // Backend error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.saveError;
        this.setState({
          isLoadingSaveButton: true,
        });
      } else {
        // Successfull entity save
        titleModal = Strings.generalResponses.successfullCreation;
        contentModal = Strings.generalResponses.saveSuccess;
      }

      this.setState({
        addResponseTitle: titleModal,
        addResponseMessage: contentModal,
        isLoadingSaveButton: false,
        isModalVisible: true,
      });
    });
  };

  // Disable products immediately after the slot config
  disableVmProduct = (slotToDisable) => {
    const { disableVmProductSlot } = this.props;
    let titleModal = Strings.generalTerms.loading;
    let contentModal = <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} />;

    this.setState({
      isLoadingDisableButton: true,
      addResponseTitle: titleModal,
      addResponseMessage: contentModal,
      isModalVisible: true,
    });

    disableVmProductSlot(slotToDisable, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        titleModal = Strings.generalResponses.connectionError;
        contentModal = Strings.generalResponses.saveError;
      } else if (response.data.code === 4008) {
        // Repeated entity error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.alreadyExistsError;
      } else if (response.data.code !== 0) {
        // Backend error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.saveError;
      } else {
        // Successfull entity save
        titleModal = Strings.generalResponses.successfulDelete;
        contentModal = Strings.generalResponses.saveSuccess;
      }

      this.setState({
        addResponseTitle: titleModal,
        addResponseMessage: contentModal,
        isLoadingDisableButton: false,
        isModalVisible: true,
      });
    });
    return true;
  };

  saveVmProductSlotEvent(wingTab) {
    let titleModal = null;
    let contentModal = null;
    const { clearProducts } = this.props;
    this.setState({
      isLoadingButton: true,
      isDisabled: false,
    });

    const { vendingMachineId } = this.state;
    const { addLockerVmProductSlot } = this.props;

    // if (wingTab.wingInfo.wingTypeId === 3) {
    // Insert Locker wing info
    const wing = {
      id: wingTab.wingInfo.wingId,
      numRows: wingTab.planogramInfo.numRows,
      numCols: wingTab.planogramInfo.numCols,
      vmId: vendingMachineId,
    };

    const slotGroup = wingTab.planogramInfo.slotGroups;

    let slots = {};
    let slotsToAdd = [];
    let slotsToUpdate = [];
    let slotsToRemove = [];

    // Get slots and orgnize it depending on the statusId
    for (let i = 0; i < wingTab.planogramInfo.slots.length; i += 1) {
      if (wingTab.planogramInfo.slots[i].statusId === 1) {
        const add = {
          vendingMachineId,
          vmTrayDenomination: wingTab.planogramInfo.slots[i].trayDenomination,
          slotNumber: wingTab.planogramInfo.slots[i].slotNumber,
          columnLId: wingTab.planogramInfo.slots[i].columnLId,
          lockerSlotType: wingTab.planogramInfo.slots[i].lockerType,
          initialSlot: wingTab.planogramInfo.slots[i].col,
          lockerRow: wingTab.planogramInfo.slots[i].row,
          lockerCol: wingTab.planogramInfo.slots[i].col,
          lockerColSpan: wingTab.planogramInfo.slots[i].mulWidth,
          lockerRowSpan: wingTab.planogramInfo.slots[i].mulHeight,
          slotGroupId: wingTab.planogramInfo.slots[i].slotGroupId,
          slotGroup: wingTab.planogramInfo.slots[i].slotGroup,
        };

        slotsToAdd.push(add);
      }
      if (wingTab.planogramInfo.slots[i].statusId === 2) {
        const update = {
          vmProductSlotId: wingTab.planogramInfo.slots[i].vmProductSlotId,
          vendingMachineId,
          vmTrayDenomination: wingTab.planogramInfo.slots[i].trayDenomination,
          slotNumber: wingTab.planogramInfo.slots[i].slotNumber,
          columnLId: wingTab.planogramInfo.slots[i].columnLId,
          lockerSlotType: wingTab.planogramInfo.slots[i].lockerType,
          initialSlot: wingTab.planogramInfo.slots[i].col,
          lockerRow: wingTab.planogramInfo.slots[i].row,
          lockerCol: wingTab.planogramInfo.slots[i].col,
          lockerColSpan: wingTab.planogramInfo.slots[i].mulWidth,
          lockerRowSpan: wingTab.planogramInfo.slots[i].mulHeight,
          slotGroupId: wingTab.planogramInfo.slots[i].slotGroupId,
          slotGroup: wingTab.planogramInfo.slots[i].slotGroup,
        };
        slotsToUpdate.push(update);
      }
      if (wingTab.planogramInfo.slots[i].statusId === 3 && wingTab.planogramInfo.slots[i].vmProductSlotId) {
        const remove = {
          vmProductSlotId: wingTab.planogramInfo.slots[i].vmProductSlotId,
          vendingMachineId,
          vmTrayDenomination: wingTab.planogramInfo.slots[i].trayDenomination,
          lockerSlotType: wingTab.planogramInfo.slots[i].lockerType,
          lockerRow: wingTab.planogramInfo.slots[i].row,
          lockerCol: wingTab.planogramInfo.slots[i].col,
          lockerColSpan: wingTab.planogramInfo.slots[i].mulWidth,
          lockerRowSpan: wingTab.planogramInfo.slots[i].mulHeight,
          slotGroupId: wingTab.planogramInfo.slots[i].slotGroupId,
          slotGroup: wingTab.planogramInfo.slots[i].slotGroup,
        };

        slotsToRemove.push(remove);
      }
      slots.wing = wing;
      slots.slotGroup = slotGroup;
      slots.slotsToAdd = slotsToAdd;
      slots.slotsToUpdate = slotsToUpdate;
      slots.slotsToRemove = slotsToRemove;
    }

    addLockerVmProductSlot(slots, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        titleModal = Strings.generalResponses.connectionError;
        contentModal = Strings.generalResponses.saveError;
      } else if (response.data.code === 4008) {
        // Repeated entity error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.alreadyExistsError;
      } else if (response.data.code !== 0) {
        // Backend error
        titleModal = Strings.generalResponses.failedTransaction;
        contentModal = Strings.generalResponses.saveError;
      } else {
        // Successfull entity save
        titleModal = Strings.generalResponses.successfullCreation;
        contentModal = Strings.generalResponses.saveSuccess;
        clearProducts();
        this.forceUpdate();

        slots = {};
        slotsToAdd = [];
        slotsToUpdate = [];
        slotsToRemove = [];
      }

      this.setState({
        addResponseTitle: titleModal,
        addResponseMessage: contentModal,
        isLoadingButton: false,
        isDisabled: true,
        isModalVisible: true,
      });
    });

    this.setState({
      isLoadingButton: false,
      isDisabled: true,
      isModalVisible: true,
    });
  }

  setDetailsModalVisible = (isModalVisible) => {
    this.setState({
      isModalVisible,
      addResponseTitle: null,
      addResponseMessage: null,
      updateResponseTitle: null,
      updateResponseMessage: null,
      deleteResponseTitle: null,
      deleteResponseMessage: null,
    });
  };

  // Function to render the wing header tabs
  renderTabHeader(wingTabs) {
    const { scProducts } = this.state;
    const res = [];

    wingTabs.forEach((wingTab) => {
      const theTab = wingTabs.length > 0 && (
        <TabPane tab={`${Strings.machine.wing} ${wingTab.wingInfo.wingNumber}: ${wingTab.wingInfo.wingTypeName}`} key={wingTab.wingInfo.wingNumber}>
          {wingTab.wingInfo.wingTypeId !== 3 ? (
            <div className="row">
              <div className="vertSpace col-md-5">
                <Divider orientation="left">
                  <h3>1. {Strings.product.productSelection}</h3>
                </Divider>
                <ProductTable scProducts2={scProducts} />
              </div>
              <div className="vertSpace col-md-7">
                <Divider orientation="left">
                  <h3>2. {Strings.product.productsAssignment}</h3>
                </Divider>
                {this.renderVmPlanogram(wingTab)}
              </div>
            </div>
          ) : (
            this.renderLockerPlanogram(wingTab)
          )}
        </TabPane>
      );
      res.push(theTab);
    });
    return res;
  }

  // /***
  //  * Function to render the wing planogram by tab
  //  */
  renderVmPlanogram(wingTab) {
    const {
      scDependencyId,
      vendingMachineId,
      vendingMachineName,
      isLoadingDisableButton,
      isLoadingSaveButton,
      // loadingVmPlanogram
    } = this.state;

    const planogram = (
      <div className="row" style={{ overflowX: "scroll" }}>
        {/* <Spin tip={Strings.generalTerms.loading} spinning={loadingVmPlanogram}> */}
        <div className="vertSpace col-md-12">
          <MachineGui
            scDependencyId={scDependencyId}
            vmId={vendingMachineId}
            vmTrays={wingTab.wingVmTrays}
            vmSetup={wingTab.wingInfo.maxNumSlots}
            vmName={vendingMachineName}
            wingInfo={wingTab.wingInfo}
            planogramInfo={wingTab.planogramInfo}
            removeVmTrays={this.removeVmTrays}
            addVmTray={this.addVmTray}
            saveVmProduct={this.saveVmProduct}
            updateVmProduct={this.updateVmProduct}
            disableVmProduct={this.disableVmProduct}
            isLoadingDisableButton={isLoadingDisableButton}
            isLoadingSaveButton={isLoadingSaveButton}
          />
        </div>
        {/* </Spin> */}
      </div>
    );
    return planogram;
  }

  /** *
   * Function to render the new wing planogram (locker type) by tab
   */
  renderLockerPlanogram(wingTab) {
    const { productsToAdd, productsToEdit, productsToRemove } = this.props;
    const { vendingMachineName, isLoadingButton, isDisabled } = this.state;

    const planogram = (
      <div className="row">
        <div className="row" style={{ width: "100%", margin: "auto" }}>
          <LockerGui lockerInfoList={wingTab} vmName={vendingMachineName} previousPathDisabled={false} planogramInfo={wingTab.planogramInfo} />
        </div>
        <div className="row" style={{ width: "100%", margin: "auto" }}>
          <div className="col-md-4" />
          <div className="col-md-8">
            <SaveButton
              onClick={() => this.saveVmProductSlotEvent(wingTab)}
              loading={isLoadingButton}
              isDisabled={!(isDisabled || productsToAdd.length > 0 || productsToEdit.length > 0 || productsToRemove.length > 0)}
            />
          </div>
        </div>
      </div>
    );
    return planogram;
  }

  // INFORMATION TO RENDER
  render() {
    const {
      height,
      isModalVisible,
      addResponseTitle,
      addResponseMessage,
      updateResponseTitle,
      updateResponseMessage,
      deleteResponseTitle,
      deleteResponseMessage,

      vendingMachineId,

      isLoadingInfo,

      wingTabs,
    } = this.state;

    return (
      <div className="content-container" style={{ minHeight: height }}>
        <ResponseModal
          isVisible={isModalVisible}
          setVisible={this.setDetailsModalVisible}
          addResponseTitle={addResponseTitle}
          addResponseMessage={addResponseMessage}
          updateResponseTitle={updateResponseTitle}
          updateResponseMessage={updateResponseMessage}
          deleteResponseTitle={deleteResponseTitle}
          deleteResponseMessage={deleteResponseMessage}
        />

        <div className="row">
          <Titles title={Strings.planogram.planogramConfiguration} tooltip={Strings.planogram.planogramConfigurationToolTip} />
        </div>

        <div className="row">
          <div className="vertSpace col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters}</h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <SoScVmDependencySelector
            onSoDependencyChange={this.handleSoDependencyChange}
            onScDependencyChange={this.handleScDependencyChange}
            onVendingMachineChange={this.handleVendingMachineChange}
            soDependencyHtmlTag="SO"
            scDependencyHtmlTag="SC"
            vendingMachineHtmlTag="VM"
            hasSc
            hasVm
            VmproductSlotNotAllMachine
            vendingMachineId={vendingMachineId}
          />
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.planogram.planogramConfiguration}</h3>
            </Divider>

            <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} spinning={isLoadingInfo}>
              {vendingMachineId !== null ? (
                <Tabs style={{ overflow: "visible" }}>{this.renderTabHeader(wingTabs)}</Tabs>
              ) : (
                <div className="style-alert-msn">
                  <Alert message={Strings.generalTerms.information} description={Strings.generalResponses.queryResponse} type="info" showIcon />
                  <div className="footer-style" />
                </div>
              )}
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDependency: state.dependencyByUser.dependencyObj,
    soDependencies: state.soDependenciesType0,
    productsToAdd: state.planogramProducts.productsToAdd,
    productsToEdit: state.planogramProducts.productsToEdit,
    productsToRemove: state.planogramProducts.productsToRemove,
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listFullVmProductSlotByWingId,
  listFullLockerVmProductSlotByWingId,
  listVmTraysByWingId,
  listWingsByVmId2,
  listFullVmProductSlotByWingId2,
  getScProductInformationByScDependency,
  addVmProductSlot,
  editVmProductSlot,
  disableVmProductSlot,
  fetchProducts,
  clearProducts,
  addLockerVmProductSlot,
  updateVmTray,
  disbaleVmTrays,
})(VmProductSlot);
