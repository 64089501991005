import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectGeneral } from "./selectGeneral";
import { soDependencyTypeLocalization } from "../../../Utils/LanguageLocalization/index";
import NormalText from "../text/NormalText";
import Strings from "../../../systemVariables/languageStrings";
import { listAllSoDependencyTypes, listSoDependencyBySoDependencyType0, getLowerRelationsBySoDependencyId } from "../../../actions/index";

import soScActions from "../../../reducers/soscselection/actions";

const { soScSelection } = soScActions;

class SoDependencySelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stockOwners: [],
      selectedStockOwnerId: null,
      isStockOwnerLoading: true,
      isStockOwnerDisabled: true,

      soDependencyTypes: [],
      selectedSoDependencyTypeId: null,
      isSoDependencyTypeLoading: false,
      isSoDependencyTypeDisabled: true,
      soDependencyTypeName: "",

      headQuarters: [],
      selectedHeadquarterId: null,
      showHeadQuarters: false,

      logisticCenters: [],
      selectedLogisticCenterId: null,
      showLogisticCenters: false,
    };
  }

  componentDidMount() {
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      userDependency: { dependencyTypeId },
      listSoDependencyBySoDependencyType0,
      listAllSoDependencyTypes,
      getLowerRelationsBySoDependencyId,
      soScSelectionProps,
    } = this.props;

    // Noatec user
    if (dependencyType === "so" && dependencyId === 1 && dependencyTypeId === 0) {
      listSoDependencyBySoDependencyType0(dependencyId, (response) => {
        this.setState({
          stockOwners: response.data.data.map((obj) => {
            return { value: obj.id, label: obj.name, dependencyType: "stockOwner" };
          }),
          isStockOwnerLoading: false,
          isStockOwnerDisabled: false,
        });
      });
      listAllSoDependencyTypes((response) => {
        this.setState({
          soDependencyTypes: response.data.data
            .filter((obj) => obj.id !== 3 && obj.id !== 0)
            .map((obj) => ({
              value: obj.id,
              label: soDependencyTypeLocalization(obj.name),
            })),
        });
      });

      if (soScSelectionProps.so) {
        this.setState({
          selectedStockOwnerId: soScSelectionProps.so.id,

          selectedSoDependencyTypeId: null,
          soDependencyTypeName: null,
          isSoDependencyTypeLoading: true,
          isSoDependencyTypeDisabled: true,

          selectedHeadquarterId: null,
          showHeadQuarters: false,

          selectedLogisticCenterId: null,
          showLogisticCenters: false,
        });

        getLowerRelationsBySoDependencyId(soScSelectionProps.so.id, (response) => {
          this.setState({
            headQuarters: response.data.data.soDependencyGetDTO
              .filter((obj) => obj.soDependenctTypeId === 1)
              .map((obj) => {
                return {
                  value: obj.id,
                  label: obj.name,
                  dependencyType: "headquarter",
                };
              }),
            logisticCenters: response.data.data.soDependencyGetDTO
              .filter((obj) => obj.soDependenctTypeId === 2)
              .map((obj) => {
                return {
                  value: obj.id,
                  label: obj.name,
                  dependencyType: "logisticCenter",
                };
              }),
            isSoDependencyTypeLoading: false,
            isSoDependencyTypeDisabled: false,
          });
        });
      }

      // User of StockOwner different to Noatec
    } else if (dependencyType === "so" && dependencyId !== 1 && dependencyTypeId === 0) {
      this.setState({ isSoDependencyTypeLoading: true, isSoDependencyTypeDisabled: true });
      listAllSoDependencyTypes((response) => {
        this.setState({
          soDependencyTypes: response.data.data
            .filter((obj) => obj.id !== 3 && obj.id !== 0)
            .map((obj) => ({
              value: obj.id,
              label: soDependencyTypeLocalization(obj.name),
            })),
        });
      });
      getLowerRelationsBySoDependencyId(dependencyId, (response) => {
        this.setState({
          headQuarters: response.data.data.soDependencyGetDTO
            .filter((obj) => obj.soDependenctTypeId === 1)
            .map((obj) => {
              return {
                value: obj.id,
                label: obj.name,
                dependencyType: "headquarter",
              };
            }),
          logisticCenters: response.data.data.soDependencyGetDTO
            .filter((obj) => obj.soDependenctTypeId === 2)
            .map((obj) => {
              return {
                value: obj.id,
                label: obj.name,
                dependencyType: "logisticCenter",
              };
            }),
          isSoDependencyTypeLoading: false,
          isSoDependencyTypeDisabled: false,
        });
      });
    } // Logistic center
    else if (dependencyType === "so" && dependencyId !== 1 && dependencyTypeId === 1) {
      getLowerRelationsBySoDependencyId(dependencyId, (response) => {
        const logisticCenters = response.data.data.soDependencyGetDTO
          .filter((obj) => obj.soDependenctTypeId === 2)
          .map((obj) => {
            return {
              value: obj.id,
              label: obj.name,
              dependencyType: "logisticCenter",
            };
          });

        if (logisticCenters.length >= 1) {
          this.setState({
            logisticCenters,
            showLogisticCenters: true,
          });
        }

        this.setState({
          isSoDependencyTypeLoading: false,
          isSoDependencyTypeDisabled: false,
        });
      });
    }
  }

  handleSelect = (event, name) => {
    const { value, label } = event;
    const { htmlTagSoDependency, htmlTagDependencyType, soScSelectionProps, onDependencyTypeChange, onSoDependencyChange, getLowerRelationsBySoDependencyId } = this.props;

    switch (name.name) {
      case "stockOwner": // SoDependency direct client select
        onSoDependencyChange(event, htmlTagSoDependency);
        this.setState({
          selectedStockOwnerId: value,

          selectedSoDependencyTypeId: null,
          soDependencyTypeName: null,
          isSoDependencyTypeLoading: true,
          isSoDependencyTypeDisabled: true,

          selectedHeadquarterId: null,
          showHeadQuarters: false,

          selectedLogisticCenterId: null,
          showLogisticCenters: false,
        });

        // eslint-disable-next-line no-case-declarations
        const so = {
          id: value,
          label,
        };

        soScSelectionProps.so = so;
        soScSelectionProps.sc = null;

        // Save soDepId in the store
        soScSelection(soScSelectionProps);

        getLowerRelationsBySoDependencyId(value, (response) => {
          this.setState({
            headQuarters: response.data.data.soDependencyGetDTO
              .filter((obj) => obj.soDependenctTypeId === 1)
              .map((obj) => {
                return {
                  value: obj.id,
                  label: obj.name,
                  dependencyType: "headquarter",
                };
              }),
            logisticCenters: response.data.data.soDependencyGetDTO
              .filter((obj) => obj.soDependenctTypeId === 2)
              .map((obj) => {
                return {
                  value: obj.id,
                  label: obj.name,
                  dependencyType: "logisticCenter",
                };
              }),
            isSoDependencyTypeLoading: false,
            isSoDependencyTypeDisabled: false,
          });
        });

        break;

      case "soDependencyType":
        if (onDependencyTypeChange !== undefined) {
          onDependencyTypeChange(event, htmlTagDependencyType);
        }
        this.setState({
          selectedSoDependencyTypeId: value,
          soDependencyTypeName: label,
          selectedHeadquarterId: null,
          selectedLogisticCenterId: null,
        });

        if (value === 1) {
          // soDependencyTypeId = 1 - Headquarter
          this.setState({
            showHeadQuarters: true,
            showLogisticCenters: false,
          });
        } else if (value === 2) {
          // soDependencyTypeId = 2 - Logistic center
          this.setState({
            showHeadQuarters: false,
            showLogisticCenters: true,
          });
        }

        break;

      case "headquarter": // soDependencyType 1
        onSoDependencyChange(event, htmlTagSoDependency);
        this.setState({
          selectedHeadquarterId: value,
          selectedLogisticCenterId: value,
        });

        break;

      case "logisticCenter": // soDependencyType 2
        onSoDependencyChange(event, htmlTagSoDependency);
        this.setState({
          selectedHeadquarterId: value,
          selectedLogisticCenterId: value,
        });

        break;

      default:
    }
  };

  renderNoatecSelector() {
    const {
      stockOwners,
      selectedStockOwnerId,
      isStockOwnerLoading,
      isStockOwnerDisabled,
      soDependencyTypes,
      selectedSoDependencyTypeId,
      isSoDependencyTypeDisabled,
      soDependencyTypeName,
      isSoDependencyTypeLoading,
      headQuarters,
      selectedHeadquarterId,
      showHeadQuarters,
      logisticCenters,
      selectedLogisticCenterId,
      showLogisticCenters,
    } = this.state;

    return (
      <>
        <div className="vertSpace col-md-6">
          <SelectGeneral
            name="stockOwner"
            text={Strings.soDependency.directClient}
            tooltip={Strings.soDependency.directClientTooltip}
            options={stockOwners}
            defaultValue={selectedStockOwnerId}
            isReq
            isLoading={isStockOwnerLoading}
            disabled={isStockOwnerDisabled}
            onChange={this.handleSelect}
          />
        </div>

        <div className="vertSpace col-md-6">
          <SelectGeneral
            name="soDependencyType"
            text={Strings.generalTerms.level}
            // tooltip={Strings.users.userSoLevel}
            options={soDependencyTypes}
            defaultValue={selectedSoDependencyTypeId}
            isReq
            isLoading={isSoDependencyTypeLoading}
            disabled={isSoDependencyTypeDisabled}
            onChange={this.handleSelect}
          />
        </div>

        {showHeadQuarters ? (
          <div className="vertSpace col-md-6">
            <SelectGeneral text={soDependencyTypeName} name="headquarter" options={headQuarters} defaultValue={selectedHeadquarterId} isReq onChange={this.handleSelect} />
          </div>
        ) : null}
        {showLogisticCenters ? (
          <div className="vertSpace col-md-6">
            <SelectGeneral text={soDependencyTypeName} name="logisticCenter" options={logisticCenters} defaultValue={selectedLogisticCenterId} isReq onChange={this.handleSelect} />
          </div>
        ) : null}
      </>
    );
  }

  renderStockOwnerSelector() {
    const { userDependency } = this.props;
    const {
      soDependencyTypes,
      selectedSoDependencyTypeId,
      isSoDependencyTypeDisabled,
      soDependencyTypeName,
      isSoDependencyTypeLoading,
      headQuarters,
      selectedHeadquarterId,
      showHeadQuarters,
      logisticCenters,
      selectedLogisticCenterId,
      showLogisticCenters,
    } = this.state;

    return (
      <>
        <div className="vertSpace col-md-6">
          <NormalText text={Strings.soDependency.directClient} tooltip={Strings.soDependency.directClientTooltip} name={userDependency.dependencyName} />
        </div>
        <div className="vertSpace col-md-6">
          <SelectGeneral
            name="soDependencyType"
            text={Strings.generalTerms.level}
            // tooltip={Strings.users.userSoLevel}
            options={soDependencyTypes}
            defaultValue={selectedSoDependencyTypeId}
            isReq
            isLoading={isSoDependencyTypeLoading}
            disabled={isSoDependencyTypeDisabled}
            onChange={this.handleSelect}
          />
        </div>
        {showHeadQuarters ? (
          <div className="vertSpace col-md-6">
            <SelectGeneral text={soDependencyTypeName} name="headquarter" options={headQuarters} defaultValue={selectedHeadquarterId} isReq onChange={this.handleSelect} />
          </div>
        ) : null}
        {showLogisticCenters ? (
          <div className="vertSpace col-md-6">
            <SelectGeneral text={soDependencyTypeName} name="logisticCenter" options={logisticCenters} defaultValue={selectedLogisticCenterId} isReq onChange={this.handleSelect} />
          </div>
        ) : null}
      </>
    );
  }

  renderHeadquarterSelector() {
    const { userDependency } = this.props;
    const { logisticCenters, selectedLogisticCenterId, showLogisticCenters } = this.state;
    return (
      <>
        <div className="vertSpace col-md-6">
          <NormalText text={Strings.soDependency.directClient} tooltip={Strings.soDependency.directClientTooltip} name={userDependency.dependencyName} />
        </div>
        {showLogisticCenters ? (
          <div className="vertSpace col-md-6">
            <SelectGeneral text="Logistic Center" name="logisticCenter" options={logisticCenters} defaultValue={selectedLogisticCenterId} isReq onChange={this.handleSelect} />
          </div>
        ) : null}
      </>
    );
  }

  renderLogisticCenterSelector() {
    const {
      userDependency: { dependencyName },
    } = this.props;
    return (
      <>
        <div className="vertSpace col-md-6">
          <NormalText text={Strings.soDependency.directClient} tooltip={Strings.soDependency.directClientTooltip} name={dependencyName} />
        </div>
      </>
    );
  }

  render() {
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      userDependency: { dependencyTypeId },
    } = this.props;

    let soDependencySelector = null;

    // Noatec soDependencyId = 1
    if (dependencyType === "so" && dependencyId === 1 && dependencyTypeId === 0) {
      soDependencySelector = this.renderNoatecSelector();
    } // Stock Owner different to Noatec
    else if (dependencyType === "so" && dependencyId !== 1 && dependencyTypeId === 0) {
      soDependencySelector = this.renderStockOwnerSelector();
    } // Headquarter
    else if (dependencyType === "so" && dependencyId !== 1 && dependencyTypeId === 1) {
      soDependencySelector = this.renderHeadquarterSelector();
    } // Logistic Center
    else if (dependencyType === "so" && dependencyId !== 1 && dependencyTypeId === 2) {
      soDependencySelector = this.renderLogisticCenterSelector();
    }

    return soDependencySelector;
  }
}

function mapStateToProps(state) {
  return {
    userDependency: state.dependencyByUser.dependencyObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listAllSoDependencyTypes,
  getLowerRelationsBySoDependencyId,
})(SoDependencySelector);
