import { LIST_DEPENDENCIES, USERDEPENDENCIES, LIST_SODEPENDENCYLOWERELEMENTS } from '../actions'


export function scDependencies(state = [], action) {
  switch (action.type) {
    case LIST_SODEPENDENCYLOWERELEMENTS:
      var soDependencyChilds = action.payload.data.scDependencyGetDTO.map(item => ({value:item.id, label:item.name}))
      return soDependencyChilds;
    case LIST_DEPENDENCIES:
      var dependencies = action.payload.data.map(item => ({value:item.id, label:item.name}))
      return dependencies;
    case USERDEPENDENCIES:
      var userDependencies = action.payload.data.map(item => ({value:item.dependencyId+"@"+item.dependencyType, label:item.dependencyName}))
      return userDependencies;
    default:
      return state
  }
}
