// Dependencies
import { Badge, Card, Icon, Popover, Spin } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import "./machineGui.css";

// Actions
import { getFile } from "../../actions/index";

// Components
import NotAvailableImg from "../Global/images/NotAvailableImg.png";

// Language localization
import Strings from "../../systemVariables/languageStrings";

const lessThan20Color = "#f08a7d";
const between21and40Color = "#f0be7d";
const between41and70Color = "#f2eb80";
const greaterThan71Color = "#a4eb88";

class MachineGui extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingImage: false,
      productImage: NotAvailableImg,
    };
  }

  mouseEnter = (value, vmProdSlot) => {
    const { getFile } = this.props;

    this.setState({
      loadingImage: true,
      productImage: NotAvailableImg,
    });
    getFile(vmProdSlot.productImgPath, (ans) => {
      if (!String(ans).includes("Error:")) {
        this.setState({
          productImage: `data:${ans.fileType};base64,${ans.data}`,
          loadingImage: false,
        });
      } else {
        this.setState({
          productImage: NotAvailableImg,
          loadingImage: false,
        });
      }
    });
  };

  mouseLeave = () => {
    this.setState({
      productImage: NotAvailableImg,
    });
  };

  // Function that render the vending machine header (10 slots)
  renderHeader() {
    const { vmSetup } = this.props;
    const res = [];

    // Encabezado de la máquina
    for (let slot = 0; slot < parseInt(vmSetup, 10); slot++) {
      const theSlot = (
        <th key={`title_${slot + 1}`} colSpan={2} className="trayHeader">
          {`${slot + 1}`}
        </th>
      );
      res.push(theSlot);
    }
    return res;
  }

  // Function that render the vending machine sub-header (20 slots)
  renderSubHeader() {
    const { vmSetup } = this.props;
    // Encabezado de la máquina
    const res = [];
    for (let slot = 0; slot < parseInt(vmSetup, 10) * 2; slot++) {
      const theSlot = (
        <th key={`title_${slot + 1}`} colSpan={0.5} className="traySubHeader">
          <div />
        </th>
      );
      res.push(theSlot);
    }
    return res;
  }

  // Function that render the vending machine slots with its products and available amount

  renderSlot() {
    const res = [];
    const slotDefaultSize = 1;
    let slotSize;
    let theSlot;
    let slotSizeName;

    const { vmTrays, vmSetup, planogramInfo } = this.props;
    const { loadingImage, productImage } = this.state;

    for (let tray = 0; tray < vmTrays.length; tray++) {
      const trayHeader = <th className="trayHeader"> {`${tray + 1}`} </th>;

      const fullTray = [];

      for (let slot = 0; slot < parseInt(vmSetup, 10) * 2; slot++) {
        const slotFound = planogramInfo.slots.filter((slotFound) => slotFound.initialSlot === slot && slotFound.trayDenomination === tray);

        if (slotFound[0]) {
          switch (slotFound[0].vmSlotSizeId) {
            case 0:
            default:
              slotFound[0].vmSlotSize = 2;
              slotSize = 2;
              slotSizeName = Strings.planogram.sizes.single;
              break;
            case 1:
              slotFound[0].vmSlotSize = 3;
              slotSize = 3;
              slotSizeName = Strings.planogram.sizes.oneAndAHalf;

              break;
            case 2:
              slotFound[0].vmSlotSize = 4;
              slotSize = 4;
              slotSizeName = Strings.planogram.sizes.double;

              break;
            case 3:
              slotFound[0].vmSlotSize = 5;
              slotSize = 5;
              slotSizeName = Strings.planogram.sizes.twoAndAHalf;

              break;
            case 4:
              slotFound[0].vmSlotSize = 6;
              slotSize = 6;
              slotSizeName = Strings.planogram.sizes.triple;

              break;
          }
          const amountAvailable = Math.round((slotFound[0].amountAvailable * 100) / slotFound[0].helixSpaces);
          let backgroundColor;

          if (amountAvailable <= 20) {
            backgroundColor = lessThan20Color;
          } else if (amountAvailable > 20 && amountAvailable <= 40) {
            backgroundColor = between21and40Color;
          } else if (amountAvailable > 40 && amountAvailable < 70) {
            backgroundColor = between41and70Color;
          } else {
            backgroundColor = greaterThan71Color;
          }

          const hasHelixAligner = slotFound[0].hasHelixAligner ? Strings.generalTerms.yes : Strings.generalTerms.no;
          const hasProductAligner = slotFound[0].hasProductAligner ? Strings.generalTerms.yes : Strings.generalTerms.no;
          const hasSlidingBase = slotFound[0].hasSlidingBase ? Strings.generalTerms.yes : Strings.generalTerms.no;
          let isWorkingProperly;

          if (slotFound[0].isWorkingProperly === 1) {
            isWorkingProperly = <Icon type="check-circle" theme="twoTone" twoToneColor="#62D32B" />;
          } else {
            isWorkingProperly = <Icon type="close-circle" theme="twoTone" twoToneColor="#F82222" />;
          }

          const content = (
            <div className="col-12 slotInfoRow" style={{ padding: "0px" }}>
              <div className="row">
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.planogram.slot}: {slotFound[0].slotNumber}
                  </p>
                </div>
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.generalTerms.tray}: {slotFound[0].trayDenomination + 1}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.machineInventory.amountAvailable}: {slotFound[0].amountAvailable}
                  </p>
                </div>
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.planogram.hasHelixAligner}: {hasHelixAligner}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.planogram.hasProductAligner}: {hasProductAligner}
                  </p>
                </div>
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.planogram.hasSlidingBase}: {hasSlidingBase}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.planogram.helixDiameter}: {slotFound[0].helixDiameter}
                  </p>
                </div>
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.planogram.helixSpaces}: {slotFound[0].helixSpaces}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.machine.isWorkingProperly}: {isWorkingProperly}
                  </p>
                </div>
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.planogram.motor}: {slotFound[0].motorLName}{" "}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.planogram.size}: {slotSizeName}{" "}
                  </p>
                </div>
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.generalTerms.tray}: {slotFound[0].trayDenomination + 1}{" "}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ padding: "3px" }}>
                  <p>
                    - {Strings.dispensationReport.productImage}:
                    {loadingImage ? null : (
                      <Card style={{ width: 100 }} bodyStyle={{ height: "0px", padding: "0px", margin: "auto" }} type="inner" cover={<img alt="product" src={productImage} />} />
                    )}
                  </p>
                </div>
              </div>
              {slotFound[0].columnHId ? (
                <div className="row">
                  <div className="col-6" style={{ padding: "3px" }}>
                    <p>
                      - {Strings.planogram.leftHarnessConnection}: {slotFound[0].columnLId}{" "}
                    </p>
                  </div>
                  <div className="col-6" style={{ padding: "3px" }}>
                    <p>
                      - {Strings.planogram.rigthHarnessConnection}: {slotFound[0].columnHId}{" "}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-6" style={{ padding: "3px" }}>
                    <p>
                      - {Strings.planogram.harnessConnection}: {slotFound[0].columnLId}{" "}
                    </p>
                  </div>
                </div>
              )}
            </div>
          );

          theSlot = (
            <Popover key={slotFound[0].soProductCode} title={`${slotFound[0].soProductCode} - ${slotFound[0].soProductName}`} content={content}>
              {slotFound[0].productImgPath ? (
                <td
                  className="slot"
                  style={{ backgroundColor }}
                  colSpan={slotSize}
                  key={tray + slot}
                  onMouseEnter={(e) => this.mouseEnter(e, slotFound[0])}
                  onMouseLeave={() => this.mouseLeave()}
                >
                  <div className="slotText1">{slotFound[0].soProductCode}</div>
                  <div className="slotText">{amountAvailable}%</div>
                  <div className="slotText">#{slotFound[0].slotNumber}</div>
                </td>
              ) : (
                <td className="slot" style={{ backgroundColor }} colSpan={slotSize} key={tray + slot} onMouseEnter={() => this.mouseLeave()} onMouseLeave={() => this.mouseLeave()}>
                  <div className="slotText1">{slotFound[0].soProductCode}</div>
                  <div className="slotText">{amountAvailable}%</div>
                  <div className="slotText">#{slotFound[0].slotNumber}</div>
                </td>
              )}
            </Popover>
          );
        } else {
          theSlot = (
            <td colSpan={slotDefaultSize} className="emptySlot" key={tray + slot}>
              <div>-</div>
            </td>
          );
        }
        fullTray.push(theSlot);
        theSlot = [];

        slotSize = 0;
        if (slotFound[0]) {
          slotSize = slotFound[0].vmSlotSize - 1;
        }

        slot += slotSize;
      }
      res.push(
        <tr key={tray}>
          {trayHeader}
          {fullTray}
        </tr>
      );
    }
    return res;
  }

  render() {
    const { vmTrays, vmSetup, scDependencyId, vmId, planogramInfo, vmName } = this.props;

    if (!scDependencyId || !vmId || vmTrays === 0 || vmSetup === 0 || !planogramInfo) {
      return (
        <h3>
          {Strings.machineInventory.vendingMachineInventory}: <span id="selectedVendingMachine">{vmName}</span>
        </h3>
      );
    }

    if (!scDependencyId || !vmId || vmTrays.length === 0 || vmSetup.length === 0 || !planogramInfo) {
      return "";
    }

    return (
      <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} spinning={!planogramInfo.isLoaded}>
        <div style={{ alignContent: "center" }}>
          <h3>
            {Strings.machineInventory.vendingMachineInventory}: <span id="selectedVendingMachine">{vmName}</span>
          </h3>
          <br />
          <div style={{ padding: 0, overflowX: "scroll" }}>
            <table className="machine" border="1" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td className="trayHeader" />
                  {this.renderHeader()}
                </tr>

                <tr>
                  <td className="trayHeader" />
                  {this.renderSubHeader()}
                </tr>
              </thead>
              <tbody>{this.renderSlot()}</tbody>
            </table>
          </div>

          <div className="vertSpace col">
            <span>{`${Strings.machineInventory.availability} `}</span>
            <span style={{ margin: "15px" }}>
              <Badge count="." style={{ width: "5px", backgroundColor: lessThan20Color }} />
              <span>{Strings.machineInventory.lessThan20}</span>
            </span>
            <span style={{ margin: "15px" }}>
              <Badge count="." style={{ width: "5px", backgroundColor: between21and40Color }} />
              <span>{Strings.machineInventory.between21and40}</span>
            </span>
            <span style={{ margin: "15px" }}>
              <Badge count="." style={{ width: "5px", backgroundColor: between41and70Color }} />
              <span>{Strings.machineInventory.between41and70}</span>
            </span>
            <span style={{ margin: "15px" }}>
              <Badge count="." style={{ width: "5px", backgroundColor: greaterThan71Color }} />
              <span>{Strings.machineInventory.greaterThan71}</span>
            </span>
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getFile,
})(MachineGui);
