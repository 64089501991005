import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Server URL
const resource = "/soproducts";

export const LIST_SOPRODUCT = "LIST_SOPRODUCT";
export const SOPRODUCTADDED = "SOPRODUCTADDED";
export const LIST_UNASSIGNEDSOPRODUCT = "LIST_UNASSIGNEDSOPRODUCT";
export const ERROR = "ERROR";

/*
Function to get soProducts by soDependencyId
Views:
soProductSlotType
*/
export function listSoProductsBySoDependency(soDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          sodependencyid: soDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

/*
Function to get soProducts by soDependencyId paginated
Views:
soProduct
*/
export function listSoProductsBySoDependencyPaginated(soDependencyId, soProdTypeId, erpCode, page, pageSize, attribute, order, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          sodependencyid: soDependencyId,
          soprodtypeid: soProdTypeId,
          erpcode: erpCode,
          page,
          size: pageSize,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

/*
Function to get soProducts report
Views:
soProduct
*/
export function listSoProductsReport(soDependencyId, erpCode, attribute, order, fileType, columnHeaders, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        responseType: "blob",
        params: {
          sodependencyid: soDependencyId,
          erpcode: erpCode,
          attribute,
          order,
          filetype: fileType,
          columnheaders: encodeURIComponent(columnHeaders),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
        return error;
      });
  };
}

export function getSoProductById(id, callback) {
  const resource = "/soproducts/";
  return () => {
    axios
      .get(host + port + path + version + resource + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function listUnassignedSoProducts(soDependencyId, scDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          soDependencyId,
          scDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function addSoProductAction(soProduct, callback) {
  return () => {
    const data = JSON.stringify(soProduct);
    axios({
      method: "post",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function editSoProductAction(soProduct, callback) {
  return () => {
    const data = JSON.stringify(soProduct);
    axios({
      method: "PUT",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}
export function disableSoProduct(soProductId, callback) {
  return () => {
    axios({
      method: "DELETE",
      url: `${host + port + path + version + resource}/${soProductId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}
