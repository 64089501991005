import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/lockerslotpricebysize";

export function getLockerSlotPriceBySizeInfo(mId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingMachineId: mId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function updateLockerSlotPriceBySizeInfo(updateDTO, callback) {
  return () => {
    let data = JSON.stringify(updateDTO);
    axios({
      method: "put",
      url: host + port + path + version + resource,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}
