import React from 'react';
import { Tooltip, Icon, TreeSelect } from 'antd';
import Strings from '../../../systemVariables/languageStrings';
import './selectComponent.css';

const TreeSelectGeneral = props => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="selectAligning col-4">
          <span>
            {props.text}
            <b>{props.isReq === true ? ': *' : ':'}</b>
          </span>
          {props.tooltip ? (
            <Tooltip title={props.tooltip}>
              <Icon className="icon" type="info-circle" style={{ float: 'none' }} />
            </Tooltip>
          ) : null}
        </div>
        <div className="col-8 input">
          <TreeSelect
            treeDataSimpleMode
            style={{ width: '100%' }}
            value={props.value}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder={Strings.generalTerms.select}
            onChange={props.onChange}
            loadData={props.loadData}
            treeData={props.treeData}
            disabled={props.disabled}
          ></TreeSelect>
        </div>
      </div>
    </div>
  );
};
export default TreeSelectGeneral;
