import { LIST_WINGTYPE } from '../actions'

const initialState = {
  wingTypesObj: []
}

export function wingTypes(state = [], action) {
  switch (action.type) {
    case LIST_WINGTYPE:
      var wingTypes = action.payload.data.map(item => ({value:item.id, label:item.name}))
      return wingTypes;
    default:
      return state
  }
}