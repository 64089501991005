import React from "react";
import { Link } from "react-router-dom";
import { Tooltip, Icon, Button } from "antd";
import "./buttons.css";
import Strings from "../../../systemVariables/languageStrings";

const EditButton = (props) => {
  return (
    <>
      <Link
        to={{
          pathname: props.link,
          props: props.props,
        }}
      >
        <Tooltip title={props.tooltip}>
          {!props.isIconButton ? (
            <Button onClick={props.onClick} disabled={props.isDisabled} loading={props.loading} className="button">
              {props.loading ? null : <Icon type="edit" theme="twoTone" twoToneColor="#52c41a" hidden={props.hideIcon} />}
              <span>{Strings.generalTerms.edit}</span>
            </Button>
          ) : (
            <Icon
              style={{ fontSize: "25px", padding: "0.2em", display: "block" }}
              type="edit"
              theme="twoTone"
              twoToneColor="#52c41a"
              hidden={props.hideIcon}
              onClick={props.onClick}
            />
          )}
        </Tooltip>
      </Link>
    </>
  );
};
export default EditButton;
