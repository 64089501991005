// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Divider, Icon, Input, Modal, Pagination, Popconfirm, Table } from "antd";

import "react-table/react-table.css";
import "./listDispensationRuleByUser.css";

// Components
import moment from "moment";
import { ValidateDependency } from "../../../Utils/validateDependency";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import { AddButton, ExportButton } from "../../GenericComponents/buttons/index";
import Loading from "../../GenericComponents/loadingComponent/loading";
import Titles from "../../GenericComponents/titles.js";

import { maxRegistersPerReport } from "../../../systemVariables/serverInformation";
import DispensationRuleDetailsModal from "../../DispensationsRules/dispensationRuleDetailsModal";
import ExportReportsModal from "../../GenericComponents/exportReportModal";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";

// Actions
import {
  disableDispensationRuleByUser,
  disableRuleByUser,
  exportDispensationRuleByUserReport,
  exportRuleByUserReport,
  getScDispensationRuleById,
  listProductAssignmentByDispensationRuleId,
  listRuleByUser,
  listScDepartmentByScDependencyId,
  listScDependenciesBySoDependencyId,
  listScDispensationRuleByUser,
  listScUserProfilesByScDependencyId,
  listSoDependencyBySoDependencyType0,
} from "../../../actions";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";

// Language Localization
import Strings from "../../../systemVariables/languageStrings";

const { Column } = Table;

const dateFormat = "DD/MM/YYYY hh:mm:ss a";

let productAssignmentsWithProductGroupId = [];
let productAssignmentsWithoutProductGroupId = [];
let vmProductGroupDispensationRule = [];

class ListDispensationRuleByUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      soDependencyId: 0,
      scDependencies: [],
      scDepartments: [],
      scUserProfiles: [],

      scDependencyId: this.props.userDependency ? (this.props.userDependency.dependencyType === "sc" ? this.props.userDependency.dependencyId : null) : null,
      scDependencyName: this.props.userDependency ? (this.props.userDependency.dependencyType === "sc" ? this.props.userDependency.dependencyName : null) : null,

      enabledScEmployee: true,

      scUserProfileId: null,
      scDepartmentId: null,

      loadingInfo: false,
      scDependencyIdFilter: "",
      scUserProfileIdFilter: "",
      scDepartmentIdFilter: "",
      identityCardNumber: "",

      /// NEW VERSION/////
      rulesByUser: [],
      rulesByUserCount: 0,

      page: 1,
      pageSize: 10,
      attribute: "id",
      order: "ASC",

      // Details Modal
      detailsModalIsVisible: false,
      scDispensationRuleInfo: [],

      // Export Report Modal
      isExportModalVisible: false,
      isExceedModalVisible: false,
      exportLoading: false,

      addButtonDisabled: true,
    };
    this.showPagination = this.showPagination.bind(this);

    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") === 1) {
      this.props.listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
    }

    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") !== 1) {
      this.setState({
        soDependencyId: sessionStorage.getItem("dependencyId"),
        scDependencyId: null,
        scDepartmentId: null,
        scUserProfileId: null,

        scDependencyIdFilter: "",
        scUserProfileIdFilter: "",
        scDepartmentIdFilter: "",
        identityCardNumber: "",

        addButtonDisabled: true,
        disabledScDependency: false,
        enabledScEmployee: true,
      });
      this.props.listScDependenciesBySoDependencyId(sessionStorage.getItem("dependencyId"), (response) => {
        this.setState({
          scDependencies: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }

    if (sessionStorage.getItem("dependencyType") === "sc") {
      const value = sessionStorage.getItem("dependencyId");
      this.setState({
        loadingInfo: true,
        scDepartmentId: null,
        scUserProfileId: null,
        scUserProfileIdFilter: "",
        scDepartmentIdFilter: "",
        identityCardNumber: "",
      });

      this.props.listRuleByUser(value, "", "", "", this.state.page, this.state.pageSize, this.state.attribute, this.state.order, (response) => {
        this.setState({
          rulesByUser: response.data.data.content,
          rulesByUserCount: response.data.data.totalElements,
          loadingInfo: false,
          enabledScEmployee: false,
          addButtonDisabled: false,
          scDependencyIdFilter: value,
          scDependencyName: this.props.userDependency.dependencyName,
        });
      });
      this.props.listScDepartmentByScDependencyId(value, (response) => {
        this.setState({
          scDepartments: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      this.props.listScUserProfilesByScDependencyId(value, (response) => {
        this.setState({
          scUserProfiles: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }
  }

  // Events
  soDependencyHandleChange(event) {
    const { value } = event;
    this.setState({
      soDependencyId: value,
      scDependencyId: null,
      scDepartmentId: null,
      scUserProfileId: null,

      scDependencyIdFilter: "",
      scUserProfileIdFilter: "",
      scDepartmentIdFilter: "",
      identityCardNumber: "",

      addButtonDisabled: true,
      disabledScDependency: false,
      enabledScEmployee: true,

      rulesByUser: [],
      rulesByUserCount: 0,
      page: 1,
      pageSize: 10,
    });
    this.props.listScDependenciesBySoDependencyId(value, (response) => {
      this.setState({
        scDependencies: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });
  }

  scDependencyHandleChange(event) {
    const { value } = event;
    this.setState({
      loadingInfo: true,

      scDependencyId: value,
      scDependencyName: event.label,
      scDepartmentId: null,
      scUserProfileId: null,

      scDependencyIdFilter: value,
      scUserProfileIdFilter: "",
      scDepartmentIdFilter: "",
      identityCardNumber: "",

      enabledScEmployee: false,
      addButtonDisabled: false,

      rulesByUser: [],
      rulesByUserCount: 0,
      page: 1,
      pageSize: 10,
    });

    this.props.listScDepartmentByScDependencyId(value, (response) => {
      this.setState({
        scDepartments: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });
    this.props.listScUserProfilesByScDependencyId(value, (response) => {
      this.setState({
        scUserProfiles: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });

    this.props.listRuleByUser(value, "", "", "", this.state.page, this.state.pageSize, this.state.attribute, this.state.order, (response) => {
      this.setState({
        rulesByUser: response.data.data.content,
        rulesByUserCount: response.data.data.totalElements,
        loadingInfo: false,
        page: 1,
        pageSize: 10,
      });
    });
  }

  scDepartmentHandleChange(event) {
    const { value } = event;
    this.setState({
      loadingInfo: true,
      scUserProfileId: null,
      scDepartmentId: value,

      scUserProfileIdFilter: "",
      scDepartmentIdFilter: value,
      identityCardNumber: "",
    });

    this.props.listRuleByUser("", value, "", "", this.state.page, this.state.pageSize, this.state.attribute, this.state.order, (response) => {
      this.setState({
        rulesByUser: response.data.data.content,
        rulesByUserCount: response.data.data.totalElements,
        loadingInfo: false,
        page: 1,
        pageSize: 10,
      });
    });
  }

  scUserProfileHandleChange(event) {
    const { value } = event;
    this.setState({
      loadingInfo: true,
      scDepartmentId: null,
      scUserProfileId: value,

      scUserProfileIdFilter: value,
      scDepartmentIdFilter: "",
      identityCardNumber: "",
    });

    this.props.listRuleByUser("", "", value, "", this.state.page, this.state.pageSize, this.state.attribute, this.state.order, (response) => {
      this.setState({
        rulesByUser: response.data.data.content,
        rulesByUserCount: response.data.data.totalElements,
        loadingInfo: false,
        page: 1,
        pageSize: 10,
      });
    });
  }

  moreInfoDetails = (event, row) => {
    // Query all productAssignment info by scDispensationRule identifier
    productAssignmentsWithProductGroupId = [];
    productAssignmentsWithoutProductGroupId = [];
    vmProductGroupDispensationRule = [];

    this.props.listProductAssignmentByDispensationRuleId(row.scDispensationRuleId, (response) => {
      if (response.data.data.length > 0) {
        productAssignmentsWithProductGroupId = response.data.data.filter((item) => item.vmProductGroupId != null);
        productAssignmentsWithoutProductGroupId = response.data.data.filter((item) => item.vmProductGroupId == null);

        productAssignmentsWithoutProductGroupId.forEach((obj) => {
          if (obj.allowedAmount == null) {
            obj.frequencyValue = "--";
            obj.frequencyUnitId = "--";
            obj.frequencyUnitName = "--";
            obj.allowedAmount = Strings.generalTerms.ilimited;
          } else {
            obj.frequencyValue = obj.frequencyValue;
            obj.frequencyUnitId = obj.frequencyUnitId;
            obj.frequencyUnitName = obj.frequencyUnitName;
            obj.allowedAmount = obj.allowedAmount;
          }
        });

        productAssignmentsWithProductGroupId.forEach((obj) => {
          if (vmProductGroupDispensationRule.filter((item) => item.name === obj.vmProductGroupName).length === 0) {
            const vmProductGroutInfo = {};
            vmProductGroutInfo.name = obj.vmProductGroupName;
            vmProductGroutInfo.description = obj.vmProductGroupDescription;
            vmProductGroutInfo.allowedAmount = obj.allowedAmount == null ? Strings.generalTerms.ilimited : obj.allowedAmount;
            vmProductGroutInfo.frequencyValue = obj.allowedAmount == null ? "--" : obj.frequencyValue;
            vmProductGroutInfo.frequencyUnitName = obj.allowedAmount == null ? "--" : obj.frequencyUnitName;
            vmProductGroupDispensationRule = vmProductGroupDispensationRule.concat(vmProductGroutInfo);
          }
        });
      }
      if (productAssignmentsWithoutProductGroupId.length > 0 || productAssignmentsWithProductGroupId.length > 0) {
        this.props.getScDispensationRuleById(row.scDispensationRuleId, (response) => {
          const scDispInfo = {};
          scDispInfo.id = response.data.data.id;
          scDispInfo.name = response.data.data.name;
          scDispInfo.description = response.data.data.description;
          scDispInfo.startDate = moment(response.data.data.startDate).format(dateFormat);
          scDispInfo.endDate = response.data.data.endDate ? moment(response.data.data.endDate).format(dateFormat) : "--";
          this.setState({
            detailsModalIsVisible: true,
            scDispensationRuleInfo: scDispInfo,
          });
        });
      }
    });
  };

  setModalVisible = (isVisible) => {
    this.setState({ detailsModalIsVisible: isVisible });
    productAssignmentsWithProductGroupId = [];
    productAssignmentsWithoutProductGroupId = [];
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearch = (selectedKeys) => {
    this.setState({
      loadingInfo: true,
      identityCardNumber: selectedKeys[0],
    });

    this.props.listRuleByUser(
      this.state.scDependencyIdFilter,
      this.state.scDepartmentIdFilter,
      this.state.scUserProfileIdFilter,
      selectedKeys[0],
      this.state.page,
      this.state.pageSize,
      this.state.attribute,
      this.state.order,
      (response) => {
        this.setState({
          rulesByUser: response.data.data.content,
          rulesByUserCount: response.data.data.totalElements,
          loadingInfo: false,
        });
      }
    );
  };

  handleReset = (clearFilters) => {
    this.setState({
      loadingInfo: true,
    });

    this.props.listRuleByUser(
      this.state.scDependencyIdFilter,
      this.state.scDepartmentIdFilter,
      this.state.scUserProfileIdFilter,
      "",
      this.state.page,
      this.state.pageSize,
      this.state.attribute,
      this.state.order,
      (response) => {
        this.setState({
          rulesByUser: response.data.data.content,
          rulesByUserCount: response.data.data.totalElements,
          loadingInfo: false,
          page: 1,
          pageSize: 10,
        });
      }
    );

    clearFilters();
    this.setState({ identityCardNumber: "" });
  };

  showPagination(page, pageSize) {
    this.setState({
      loadingInfo: true,
    });

    this.props.listRuleByUser(
      this.state.scDependencyIdFilter,
      this.state.scDepartmentIdFilter,
      this.state.scUserProfileIdFilter,
      this.state.identityCardNumber,
      page,
      pageSize,
      this.state.attribute,
      this.state.order,
      (response) => {
        this.setState({
          rulesByUser: response.data.data.content,
          rulesByUserCount: response.data.data.totalElements,
          loadingInfo: false,
          page,
          pageSize,
        });
      }
    );
  }

  // Export methods
  showExportReportsModal = () => {
    if (this.state.rulesByUserCount >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible) => {
    this.setState({
      isExportModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    const { soDependencyId, scDependencyIdFilter, scDepartmentIdFilter, scUserProfileIdFilter, identityCardNumber, attribute, order, exportLoading } = this.state;

    if (!exportLoading) {
      this.setState({ exportLoading: true });

      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
      const columnHeaders = [
        "id",
        Strings.generalTerms.name,
        Strings.dispensationRule.dispensationRule,
        Strings.scDepartment.department,
        Strings.scUserProfile.userProfile,
        Strings.scCostCenter.costCenter,
      ];

      // Start loading animation on Export button

      this.props.exportRuleByUserReport(
        soDependencyId,
        scDependencyIdFilter,
        scDepartmentIdFilter,
        scUserProfileIdFilter,
        identityCardNumber,
        attribute,
        order,
        fileType,
        columnHeaders,
        (response) => {
          if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
            // Connection error
            Modal.error({
              title: Strings.generalResponses.failedTransaction,
              content: Strings.generalResponses.reportGenerationError,
            });
            this.setState({
              exportLoading: false,
            });
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            const today = new Date();
            link.setAttribute("download", `${Strings.dispensationRuleByUser.dispensationRuleByUserReport} ${today}.csv`);
            document.body.appendChild(link);
            link.click();
            this.setState({
              exportLoading: false,
            });
          }
        }
      );
    }
  };

  disableScDispRuleByUser = (e, row) => {
    const { userId } = row;
    const { scDispensationRuleId } = row;

    this.setState({
      acceptButtonDisabled: true,
      loading: true,
    });

    /// ///////////////////////////////////OLD VERSION////////////////////////////////////////////////////////////
    if (row) {
      this.props.disableDispensationRuleByUser(userId, scDispensationRuleId, (response) => {
        this.props.listRuleByUser(
          this.state.scDependencyIdFilter,
          this.state.scDepartmentIdFilter,
          this.state.scUserProfileIdFilter,
          "",
          this.state.page,
          this.state.pageSize,
          this.state.attribute,
          this.state.order,
          (response) => {
            this.setState({
              rulesByUser: response.data.data.content,
              rulesByUserCount: response.data.data.totalElements,
              loadingInfo: false,
              page: 1,
              pageSize: 10,
            });
          }
        );
      });
    }

    /// ///////////////////////////////////NEW VERSION////////////////////////////////////////////////////////////
    if (row) {
      this.props.disableRuleByUser(row.id, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.handleOk,
          });
          this.setState({ acceptButtonDisabled: false });
        } else {
          // Other error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
        }
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
    }
  };

  // Information to render
  render() {
    const {
      permissions: { data },
    } = this.props;
    const {
      props: { soDependencies },
    } = this;
    const { soDependencyId, scDependencyId, isExportModalVisible, isExceedModalVisible, detailsModalIsVisible, scDispensationRuleInfo, scDependencyName } = this.state;

    if (!soDependencies) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    return (
      <div className="content-container">
        <ExportReportsModal
          isExportVisible={isExportModalVisible}
          isExceedVisible={isExceedModalVisible}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
        />

        <DispensationRuleDetailsModal
          isVisible={detailsModalIsVisible}
          setIsVisible={this.setModalVisible}
          scDispRuleRowSelected={scDispensationRuleInfo}
          scDependency={scDependencyName}
          productAssignmentsWithoutProductGroupId={productAssignmentsWithoutProductGroupId}
          productAssignmentsWithProductGroupId={productAssignmentsWithProductGroupId}
          vmProductGroupDispensationRule={vmProductGroupDispensationRule}
        />

        <div className="row">
          <Titles title={Strings.dispensationRuleByUser.dispensationRuleByUser} tooltip={Strings.dispensationRuleByUser.dispensationRuleByUserTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <ValidateDependency
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            soDependencyId={soDependencyId}
            scDependencyId={scDependencyId}
            disabledScDependency={this.state.disabledScDependency}
            soDependencies={this.props.soDependencies}
            scDependencies={this.state.scDependencies}
            scDependency={this.state.scDependencyName}
            soDependencyHandleChange={(e) => this.soDependencyHandleChange(e)}
            scDependencyHandleChange={(e) => this.scDependencyHandleChange(e)}
          />
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.scDepartment.department}
              tooltip={Strings.scDepartment.departmentTooltip}
              options={this.state.scDepartments}
              onChange={(e) => this.scDepartmentHandleChange(e)}
              disabled={this.state.enabledScEmployee}
              defaultValue={this.state.scDepartmentId}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.scUserProfile.userProfile}
              tooltip={Strings.scUserProfile.userProfileTooltip}
              options={this.state.scUserProfiles}
              onChange={(e) => this.scUserProfileHandleChange(e)}
              disabled={this.state.enabledScEmployee}
              defaultValue={this.state.scUserProfileId}
            />
          </div>

          <ValidatePermissionForComponent permission="PRIVILEGE DISPENSATION RULE BY USER POST" permissions={data}>
            <div className="vertSpace col">
              <div className="col-xs-0 col-md-6" />
              <div className="col-xs-12 col-md-6">
                <AddButton
                  link={this.state.addButtonDisabled ? "" : "/assignDispensationRulesToUser"}
                  tooltip={Strings.users.addExecutiveUsersTooltip}
                  props={
                    this.state.addButtonDisabled
                      ? ""
                      : {
                          scDependencyId: this.state.scDependencyId,
                          scDependencyName: this.state.scDependencyName,
                        }
                  }
                  disabled={this.state.addButtonDisabled}
                />
              </div>
            </div>
          </ValidatePermissionForComponent>
        </div>
        <Divider orientation="left">
          <h3>{Strings.dispensationRule.dispensationByUser} </h3>
        </Divider>
        <QueryResponse isLoading={this.state.loadingInfo} dataSourceLength={this.state.rulesByUser.length} callback={this.listDispensationRuleByUserTable(data)} />
      </div>
    );
  }

  listDispensationRuleByUserTable(data) {
    return (
      <div>
        <div className="row">
          <div className="col">
            <Table
              dataSource={this.state.rulesByUser} /// NEW VERSION
              bordered
              loading={this.state.loadingInfo}
              pagination={false}
              size="middle"
              scroll={{ x: "auto" }}
              rowKey="id"
            >
              <Column
                style={{ width: "20%" }}
                align="center"
                key="id"
                title={Strings.users.identityCardNumber}
                dataIndex="identityCardNumber"
                {...this.getColumnSearchProps("identityCardNumber")}
                filtered
              />
              <Column align="center" style={{ width: "20%" }} title={Strings.generalTerms.name} dataIndex="fullName" />
              <Column align="center" style={{ width: "20%" }} title={Strings.scDepartment.department} dataIndex="scDepartmentName" />
              <Column align="center" style={{ width: "10%" }} title={Strings.scUserProfile.userProfile} dataIndex="scUserProfileName" />
              <Column align="center" style={{ width: "10%" }} title={Strings.dispensationRule.dispensationRule} dataIndex="scDispensationRuleName" />

              <Column
                align="center"
                title={<div style={{ minWidth: "50px", maxWidth: "70px" }}>{Strings.generalTerms.details}</div>}
                style={{ width: "10%" }}
                render={(row) => (
                  <div align="center">
                    <Icon
                      type="profile"
                      theme="twoTone"
                      style={{ cursor: "pointer", fontSize: 16 }}
                      className="addEditRemoveButton"
                      onClick={(e) => this.moreInfoDetails(e, row)}
                    />
                  </div>
                )}
              />

              {ValidatePermissionForComponentPart("PRIVILEGE DISPENSATION RULES DELETE", data) ? (
                <Column
                  title={Strings.generalTerms.delete}
                  response
                  width="10%"
                  align="center"
                  accessor="remove"
                  render={(row) => (
                    <div align="center">
                      <Popconfirm
                        title={Strings.generalResponses.confirmDeleteMessage}
                        onConfirm={(e) => this.disableScDispRuleByUser(e, row)}
                        okText={Strings.generalTerms.yes}
                        cancelText={Strings.generalTerms.no}
                      >
                        <Icon type="delete" theme="twoTone" className="addEditRemoveButton" style={{ cursor: "pointer", fontSize: 16 }} />
                      </Popconfirm>
                    </div>
                  )}
                />
              ) : (
                ""
              )}
            </Table>
            <div className="col-xs-0 col-md-6" />
            <div className="vertSpace col-md-6">
              <Pagination
                size="small"
                total={this.state.rulesByUserCount} /// NEW VERSION
                showQuickJumper
                showSizeChanger
                onChange={this.showPagination}
                onShowSizeChange={this.showPagination}
                hideOnSinglePage={false}
                pageSizeOptions={["10", "25", "50", "100", "250"]}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                current={this.state.page}
                disabled={this.state.rulesByUser ? !(this.state.rulesByUser.length > 0) : true} /// NEW VERSION
              />
            </div>
          </div>
        </div>

        <div className="row">
          <ValidatePermissionForComponent permission="PRIVILEGE DISPENSATION RULE BY USER REPORT GET" permissions={data}>
            <div className="vertSpace col">
              <div className="col-lg-6" />
              <div className="col-lg-6">
                <ExportButton isDisabled={!this.state.scDependencyId} onClick={() => this.showExportReportsModal()} loading={this.state.exportLoading} />
              </div>
            </div>
          </ValidatePermissionForComponent>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    scDependencies: state.scDependencies,
    scDepartments: state.scDepartments,
    scUserProfiles: state.scUserProfiles,
    users: state.users.userDispRulesObj,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  listScDispensationRuleByUser,
  exportDispensationRuleByUserReport,
  listProductAssignmentByDispensationRuleId,
  getScDispensationRuleById,
  disableDispensationRuleByUser,
  listRuleByUser,
  exportRuleByUserReport,
  disableRuleByUser,
})(ListDispensationRuleByUser);
