import { LIST_HELIXTYPE, ERROR } from '../actions'

const initialState = {
  helixTypeObj: [],
  errorObj: []
}

export function helixType(state = initialState, action) {
  switch (action.type) {
    case LIST_HELIXTYPE:
      return Object.assign({}, state, {helixTypeObj: action.payload.map(item => ({value:item.id, label:item.name + " - " + item.length}))})
    case ERROR:
      return Object.assign({}, state, {errorObj: action.payload})
    default:
      return state
  }
}