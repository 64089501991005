//Dependencies
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Divider, Checkbox, DatePicker, Modal, InputNumber } from "antd";
import _ from "lodash";

//Components
import Titles from "../../GenericComponents/titles.js";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import { SaveButton, ReturnButton } from "../../GenericComponents/buttons/index";
import NormalText from "../../GenericComponents/text/NormalText";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";

//Actions
import {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  insertDispensationRulesByUser,
  listFrequencyUnits,
  listUsersWithoutCredit,
  insertCreditByUser,
  updateCreditByUser,
  listAllCurrencyCodes,
} from "../../../actions";

//Localization
import Strings from "../../../systemVariables/languageStrings";
import { FreqUnitNameLocalizationPlural } from "../../../Utils/LanguageLocalization/frequencyUnitNameLocalization";

const contentSize = 64; /* header */

class EditCreditByUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentHeight: 0,

      loadingAdd: false,
      usersChecked: false,
      isChecked: false,
      searchTerm: "",
      disabledSelectors: true,
      disableCheckbox: true,
      disableAssignButton: false,

      //Flag used to redirect to /listCreditByUser if any scDependency was selected
      redirectBackToListCreditByUser: false,

      scCountryName: this.props.location.props ? this.props.location.props.scCountryName : null,

      creditByUser: this.props.location.props ? this.props.location.props.creditByUser : null,

      fullName: this.props.location.props ? this.props.location.props.creditByUser.fullName : null,
      scDepartmentName: this.props.location.props.creditByUser.scDepartmentName ? this.props.location.props.creditByUser.scDepartmentName : null,
      scUserProfileName: this.props.location.props.creditByUser.scUserProfileName ? this.props.location.props.creditByUser.scUserProfileName : null,
      cashCredit: this.props.location.props ? this.props.location.props.creditByUser.creditAssigned : null,
      frequencyUnitId: this.props.location.props ? this.props.location.props.creditByUser.frequencyUnitId : null,
      frequencyValue: this.props.location.props ? this.props.location.props.creditByUser.frequencyValue : null,
      startDate: this.props.location.props ? this.props.location.props.creditByUser.startDate : null,
      endDate: this.props.location.props ? this.props.location.props.creditByUser.endDate : null,
      disableEndDatePicker: this.props.location.props.creditByUser.endDate ? false : true,
      currencyCodeId: this.props.location.props ? this.props.location.props.creditByUser.currencyCodeId : null,
      isEnabled: false,
    };
    this.handleOkNoScDependencyWarning = this.handleOkNoScDependencyWarning.bind(this);
  }

  componentDidMount() {
    const { listFrequencyUnits, listAllCurrencyCodes } = this.props;
    const { creditByUser } = this.state;

    listAllCurrencyCodes();

    window.addEventListener("resize", this.updateWindowDimensions);
    this.setState({
      contentHeight: window.innerHeight - contentSize,
    });

    if (!creditByUser) {
      this.setState({ redirectBackToListCreditByUser: true });
    } else {
      // Get the list of frequency units.
      listFrequencyUnits((response) => {
        if (response.data.data) {
          var nativeFrequencyUnits = response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          this.setState({
            frequencyUnits: FreqUnitNameLocalizationPlural(nativeFrequencyUnits),
          });
        }
      });
    }
  }

  updateWindowDimensions = () => {
    this.setState({
      contentHeight: window.innerHeight - contentSize,
    });
  };

  //Events
  creditInputEvent(event) {
    const { frequencyValue } = this.state;
    const value = _.trimStart(event);
    this.setState({ cashCredit: value });

    if (!value) {
      this.setState({ disableAssignButton: true });
    } else if (value > 0 && frequencyValue > 0) {
      this.setState({ disableAssignButton: false });
    } else {
      this.setState({ disableAssignButton: true });
    }
  }

  frequencyValueInputEvent(event) {
    const { cashCredit } = this.state;
    const value = _.trimStart(event);
    this.setState({ frequencyValue: value });

    if (!value) {
      this.setState({ disableAssignButton: true });
    } else if (value > 0 && cashCredit > 0) {
      this.setState({ disableAssignButton: false });
    } else {
      this.setState({ disableAssignButton: true });
    }
  }

  frecuencyUnitSelect(event) {
    this.setState({
      disabledDetails: false,
      disableCheckbox: false,
      disabledSelectors: false,
    });

    const value = event.value;
    this.setState({ frequencyUnitId: value });
  }

  startDateEvent = (date, dateString) => {
    const { endDate, startDate } = this.state;

    const startDateLocal = moment(date).format();
    if (this.state.isChecked === true) {
      if (endDate < startDateLocal) {
        this.setState({ startDate: startDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidStartDateError,
        });
      } else {
        this.setState({ startDate: _.split(startDateLocal, "T")[0] });
      }
    } else {
      this.setState({ startDate: _.split(startDateLocal, "T")[0] });
    }
  };

  endDateEvent = (date, dateString) => {
    const { isChecked, startDate, endDate } = this.state;

    const endDateLocal = moment(date).format();
    if (isChecked === true) {
      if (endDateLocal < startDate) {
        this.setState({ endDate: endDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidEndDateError,
        });
      } else {
        this.setState({ endDate: _.split(endDateLocal, "T")[0] });
      }
    } else {
      this.setState({ endDate: _.split(endDateLocal, "T")[0] });
    }
  };

  nullEndDateHandleChange(e) {
    const { startDate, disableEndDatePicker } = this.state;
    const endDateLocal = moment(startDate).format();
    if (e.target.checked === true) {
      this.setState({
        endDate: null,
        disableEndDatePicker: !disableEndDatePicker,
        isChecked: true,
      });
    } else {
      this.setState({
        endDate: _.split(endDateLocal, "T")[0],
        disableEndDatePicker: !disableEndDatePicker,
        isChecked: false,
      });
    }
  }

  onCurrencySelect = (currency) => {
    this.setState({ currencyCodeId: currency.value });
  };

  editCreditByUserEvent = (e) => {
    const { updateCreditByUser } = this.props;
    const { creditByUser, cashCredit, frequencyUnitId, frequencyValue, startDate, endDate, currencyCodeId } = this.state;
    this.setState({
      loadingAdd: true,
      disableAssignButton: true,
      disableCheckbox: true,
    });

    const infoDto = {
      userId: creditByUser.id,
      creditAssigned: cashCredit,
      frequencyUnitId: frequencyUnitId,
      frequencyValue: frequencyValue,
      cashRuleStartDate: moment(startDate).format(),
      cashRuleEndDate: endDate ? moment(endDate).format() : null,
      currencyCodeId: currencyCodeId,
    };

    if (infoDto) {
      updateCreditByUser(infoDto, (response, error) => {
        if (!response.data && String(response).includes("Error:")) {
          //Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data.code === 4008) {
          //Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          //Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
          });
          this.setState({ redirectBackToListCreditByUser: true });
        } else {
          //Other error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        }
      });
    } else {
      //Other error
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: Strings.generalResponses.alreadyExistsError,
      });
      this.setState({
        loadingAdd: false,
      });
    }
  };

  noScDependencyWarning() {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOkNoScDependencyWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  }

  handleOkNoScDependencyWarning() {
    this.setState({ redirectBackToListCreditByUser: true });
  }

  //Information to render
  render() {
    const { currencyCodes } = this.props;
    const { contentHeight, redirectBackToListCreditByUser, scCountryName, currencyCodeId } = this.state;

    if (redirectBackToListCreditByUser) {
      //Redirects to CreditByUser from No Product Modal Warning
      return <Redirect to="/listCreditByUser" />;
    }
    return (
      <div className="content-container" style={{ minHeight: contentHeight }}>
        <div className="row">
          <Titles title={Strings.users.creditByUser.editCreditByUser} tooltip={Strings.users.creditByUser.creditAssignationTooltip} />
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.users.user} </h3>
            </Divider>
          </div>
        </div>

        <div className="row vertSpace col">
          <div className="col-md-6">
            <NormalText text={Strings.generalTerms.name} name={this.state.fullName} isReq={false} />
          </div>
        </div>

        {this.state.scDepartmentName && (
          <div className="row vertSpace col">
            <div className="col-md-6">
              <NormalText text={Strings.scDepartment.department} name={this.state.scDepartmentName} isReq={false} />
            </div>
          </div>
        )}

        {this.state.scUserProfileName && (
          <div className="row vertSpace col">
            <div className="col-md-6">
              <NormalText text={Strings.scUserProfile.userProfile} name={this.state.scUserProfileName} isReq={false} />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.users.creditByUser.creditSelection} </h3>
            </Divider>
          </div>
        </div>

        <div className="row vertSpace col">
          <div className="col col-md-2 vertSpace">
            <TextWithInfoTooltip name={Strings.vmcashvaulttype.credit + ": "} tooltip={Strings.users.creditByUser.creditAssignationTooltip}></TextWithInfoTooltip>
          </div>
          <div className="col col-md-2 vertSpace">
            <InputNumber value={this.state.cashCredit} isReq={true} min={1} onChange={(e) => this.creditInputEvent(e)} />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.product.currency}
              name="currencyCodeId"
              options={currencyCodes.filter((item) => item.country === scCountryName)}
              defaultValue={currencyCodeId}
              isReq
              tooltip={Strings.product.currencyTooltip}
              onChange={(option) => this.onCurrencySelect(option)}
            />
          </div>
        </div>

        <div className="row vertSpace col">
          <div className="col-6 col-md-2 vertSpace">
            <TextWithInfoTooltip name={Strings.dispensationRule.frequency + ": "} tooltip={Strings.dispensationRule.frequencyTooltip}></TextWithInfoTooltip>
          </div>
          <div className="col-6 col-md-2 vertSpace">
            <InputNumber value={this.state.frequencyValue} isReq={true} min={1} onChange={(e) => this.frequencyValueInputEvent(e)} />
          </div>
          <div className="col col-md-6 vertSpace">
            <SelectGeneral
              text={Strings.dispensationRule.frequencyUnit}
              options={this.state.frequencyUnits}
              defaultValue={this.state.frequencyUnitId}
              isReq
              onChange={(e) => this.frecuencyUnitSelect(e)}
            />
          </div>
        </div>

        <div className="row vertSpace col">
          <div className="col-xs-6 col-md-2 vertSpace">
            <TextWithInfoTooltip name={Strings.dispensationRule.startDate + ": "}></TextWithInfoTooltip>
          </div>
          <div className="col-xs-6 col-md-2 vertSpace">
            <DatePicker
              value={moment(this.state.startDate)}
              onChange={(date, dateString) => this.startDateEvent(date, dateString)}
              required={true}
              disabled={this.state.disableStartDatePicker}
            />
          </div>
          <div className="col-xs-6 col-md-2 vertSpace" style={{ padding: "0px" }}>
            <TextWithInfoTooltip name={Strings.dispensationRule.endDate + ": "}></TextWithInfoTooltip>
          </div>
          <div className="col-xs-6 col-md-4 vertSpace">
            <Checkbox style={{ marginRight: "10px" }} checked={this.state.isChecked} onChange={(e) => this.nullEndDateHandleChange(e)} />
            <DatePicker
              value={moment(this.state.endDate)}
              onChange={(date, dateString) => this.endDateEvent(date, dateString)}
              required={false}
              disabled={this.state.disableEndDatePicker}
            />
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="vertSpace col-6 col-md-3">
            <ReturnButton link={"/listCreditByUser"} />
          </div>
          <div className="vertSpace col-6 col-md-3">
            <SaveButton onClick={(e) => this.editCreditByUserEvent(e)} isDisabled={this.state.disableAssignButton} loading={this.state.loadingAdd} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    scDependencies: state.scDependencies,
    scdepartments: state.scDepartments,
    scuserprofiles: state.scUserProfiles,
    currencyCodes: state.currencyCode,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  insertDispensationRulesByUser,
  listFrequencyUnits,
  listUsersWithoutCredit,
  insertCreditByUser,
  updateCreditByUser,
  listAllCurrencyCodes,
})(EditCreditByUser);
