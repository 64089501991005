import { Button, Divider, Icon, Input, Modal, Pagination, Table } from "antd";
import _ from "lodash";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

//Components
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { AssignButton, ExportButton } from "../../GenericComponents/buttons";
import { SelectGeneral } from "../../GenericComponents/selectComponent/selectGeneral";
import Titles from "../../GenericComponents/titles";

//Actions
import {
  disablePrivilegeByRoleId,
  editRoleWithPrivileges,
  getRoleDetailesById,
  getUsersByRoleId,
  listPermissionTypeByDependencyTypeId,
  listPrivilegesByPermissionTypeId,
  listRolesByDependencyId,
  listRolesByDependencyIdPaged,
  listScDependenciesBySoDependencyId,
  listSoDependencyBySoDependencyType0,
} from "../../../actions";

//Language Localization
import Strings from "../../../systemVariables/languageStrings";
import { ValidateDependency } from "../../../Utils/validateDependency";

const { Column } = Table;

const dependencyType = [
  { label: Strings.Roles.Executive, value: 1 },
  { label: Strings.generalTerms.customer, value: 2 },
];

class UsersByRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //Role assignation level
      dependencyType: null,
      dependencyTypeId: null,
      dependencyTypeName: null,

      //Direct client type 0
      soDependencyIdType0: null,
      soDependencyType0Name: null,
      soDependencyType0Disabled: true,

      //So dependency type
      soDependencyTypeId: null,

      //Direct client type different to 0
      lowerSoDependencies: [],
      lowerSoDependenciesId: null,
      soDependencyTypeDisabled: true,

      //Indirect Client
      scDependencies: [],
      scDependencyId: null,
      scDependencyName: null,
      scDependencyDisabled: true,

      //Roles
      roles: [],
      roleId: null,
      roleName: null,
      rolesDisabled: true,

      //Users by role
      usersByRole: [],
      usersByRoleCount: 0,

      dependencyId: null,
      identityCardNumber: "",
      pageSize: 10,
      page: 1,
      attribute: "id",
      order: "ASC",
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") === 1) {
      if (this.props.soDependencies.length <= 0) {
        this.props.listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
      }
    }
  }

  handleSelect1(event, tag) {
    const value = event.value;
    const label = event.label;
    const tagName = tag.name;

    switch (tagName) {
      case "dependencyType":
        if (value === 1) {
          this.setState({
            dependencyType: "so",
            dependencyTypeId: value,

            soDependencyIdType0: null,

            soDependencyTypeId: null,

            lowerSoDependencies: [],
            lowerSoDependenciesId: null,

            scDependencies: [],
            scDependencyId: null,

            roles: [],
            roleId: null,

            usersByRole: [],
          });
        } else {
          this.setState({
            dependencyType: "sc",
            dependencyTypeId: value,

            soDependencyIdType0: null,

            soDependencyTypeId: null,

            lowerSoDependencies: [],
            lowerSoDependenciesId: null,

            scDependencies: [],
            scDependencyId: null,

            roles: [],
            roleId: null,

            usersByRole: [],
          });
        }

        break;

      case "soDependencyType0": //SoDependency direct client select
        this.setState({
          soDependencyIdType0: value,
          soDependencyNameType0: label,

          soDependencyId: value,
          soDependencyName: label,

          soDependencyTypeId: null,

          lowerSoDependencies: [],
          lowerSoDependenciesId: null,

          scDependencies: [],
          scDependencyId: null,

          roles: [],
          roleId: null,
          rolesDisabled: true,

          usersByRole: [],
        });
        this.props.listScDependenciesBySoDependencyId(value, (response) => {
          this.setState({
            scDependencies: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            scDependencyDisabled: false,
          });
        });

        break;
      case "scDependency":
        this.setState({
          dependencyTypeName: "scDependency",

          scDependencyId: value,
          scDependencyName: label,

          dependencyId: value,
          dependencyName: label,

          roles: [],
          roleId: null,

          usersByRole: [],
        });

        this.props.listRolesByDependencyId(this.state.dependencyType, value, (response) => {
          this.setState({
            roles: response.data.data.map((obj) => ({
              value: obj.id,
              label: obj.roleName,
            })),
            rolesDisabled: false,
          });
        });
        break;
      case "role":
        this.setState({
          roleId: value,
          loadingInfo: true,
        });

        this.props.getUsersByRoleId(
          this.state.dependencyType,
          this.state.dependencyId,
          value,
          this.state.identityCardNumber,
          this.state.page,
          this.state.pageSize,
          this.state.attribute,
          this.state.order,
          (response) => {
            this.setState({
              usersByRole: response.data.data.content,
              usersByRoleCount: response.data.data.totalElements,
              loadingInfo: false,
              assignButtonDisabled: false,
            });
          }
        );
        break;
      default:
    }
  }

  handleSelect = (event, name) => {
    this.setState({
      dependencyId: event.value,
      dependencyName: event.label,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearch = (selectedKeys) => {
    this.setState({
      loadingInfo: true,
      searchText: selectedKeys[0],
    });

    this.props.getUsersByRoleId(
      this.state.dependencyType,
      this.state.dependencyId,
      this.state.roleId,
      selectedKeys[0],
      this.state.page,
      this.state.pageSize,
      this.state.attribute,
      this.state.order,
      (response) => {
        this.setState({
          usersByRole: response.data.data.content,
          usersByRoleCount: response.data.data.totalElements,
          loadingInfo: false,
        });
      }
    );
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      loadingInfo: true,
      searchText: "",
    });

    this.props.getUsersByRoleId(
      this.state.dependencyType,
      this.state.dependencyId,
      this.state.roleId,
      "",
      this.state.page,
      this.state.pageSize,
      this.state.attribute,
      this.state.order,
      (response) => {
        this.setState({
          usersByRole: response.data.data.content,
          usersByRoleCount: response.data.data.totalElements,
          loadingInfo: false,
        });
      }
    );
  };

  handleEditRole = () => {
    this.setState({
      isSaveButtonLoading: true,
    });
    const role = { ...this.state.role };
    role.roleName = _.trim(role.roleName);
    role.description = _.trim(role.description);

    let infoToAdd = {
      role: role,
      privilegesToAdd: this.state.privilegesToAdd,
      privilegesToDisable: this.state.privilegesToDisable,

      dependencyType: this.state.dependencyType,
      dependencyId: this.state.scDependencyId ? this.state.scDependencyId : this.state.soDependencyId,
    };

    this.props.editRoleWithPrivileges(infoToAdd, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        this.setState({
          isSaveButtonLoading: false,
        });
      } else if (response.data.code === 4001) {
        // Entity Already exists and is enabled
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({
          isSaveButtonLoading: false,
        });
      } else if (response.data.code === 4013) {
        // Entity Already exists and is disabled
        Modal.error({
          title: "Strings.generalResponses.failedTransaction",
          content: "Strings.generalResponses.alreadyExistsError",
        });
        this.setState({
          isSaveButtonLoading: false,
        });
      } else if (response.data.code === 0) {
        // Entity successfully saved
        Modal.success({
          title: Strings.generalResponses.successfulCreation,
          content: `${Strings.generalResponses.creationSuccess}`,
        });
        this.props.getRoleDetailesById(this.state.role.id, (response2) => {
          this.props.listPrivilegesByPermissionTypeId(this.state.role.permissiontypeId, (response3) => {
            this.setState({
              addedPrivileges: response2.data.data,
              availablePrivileges: _.differenceBy(response3.data.data, response2.data.data, "id"),
              isSaveButtonLoading: false,
            });
          });
        });
      }
    });
  };

  handleDisable = (event, row) => {
    this.props.disablePrivilegeByRoleId(this.state.role.id, row.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 4001) {
        // Entity Already exists and is enabled
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      } else if (response.data.code === 4013) {
        // Entity Already exists and is disabled
        Modal.error({
          title: "Strings.generalResponses.failedTransaction",
          content: "Strings.generalResponses.alreadyExistsError",
        });
      } else if (response.data.code === 0) {
        // Entity successfully saved
        Modal.success({
          title: Strings.generalResponses.successfulDelete,
          content: `${Strings.generalResponses.successTransactionMessage}`,
        });
        this.props.getRoleDetailesById(this.state.role.id, (response2) => {
          this.props.listPrivilegesByPermissionTypeId(this.state.role.permissiontypeId, (response3) => {
            this.setState({
              addedPrivileges: response2.data.data,
              availablePrivileges: _.differenceBy(response3.data.data, response2.data.data, "id"),
              isSaveButtonLoading: false,
            });
          });
        });
      }
    });
  };

  showNoScDependencyWarning = () => {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOkNoScDependencyWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  };

  handleOkNoScDependencyWarning = () => {
    this.setState({
      redirectBackToPrivilegeByRole: true,
    });
  };

  render() {
    const {
      permissions: { data },
    } = this.props;
    const { usersByRole } = this.state;

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.privilegeByRole.privilegeByRole} tooltip={Strings.privilegeByRole.privilegeByRoleTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          {this.props.userDependency.dependencyType !== "sc" ? (
            <div className="vertSpace col-md-6">
              <SelectGeneral
                name="dependencyType"
                text={Strings.privilegeByRole.assignationLevel}
                tooltip={Strings.privilegeByRole.assignationLevelTooltip}
                options={dependencyType}
                onChange={(e, name) => this.handleSelect1(e, name)}
                disabled={false}
                defaultValue={this.state.dependencyTypeId}
              />
            </div>
          ) : (
            ""
          )}

          {this.state.dependencyTypeId === 1 ? (
            <Fragment>
              <SelectGeneral
                name="isBasicRole"
                text={Strings.privilegeByRole.isBasicRole}
                tooltip={Strings.privilegeByRole.isBasicRoleTooltip}
                options={this.state.roles}
                onChange={(e, name) => this.handleSelect1(e, name)}
                disabled={this.state.scDependencyId ? false : true}
                defaultValue={this.state.rolesDisabled}
              />
            </Fragment>
          ) : this.state.dependencyTypeId === 2 ? (
            <Fragment>
              <ValidateDependency
                soDependencyHtmlTag={"soDependencyType0"}
                scDependencyHtmlTag={"scDependency"}
                dependencyType={sessionStorage.getItem("dependencyType")}
                dependencyId={sessionStorage.getItem("dependencyId")}
                soDependencies={this.props.soDependencies}
                soDependencyId={this.state.soDependencyIdType0}
                scDependencies={this.state.scDependencies}
                scDependencyId={this.state.scDependencyId}
                //disabledSoDependency={this.state.soDependencyDisabled}
                disabledScDependency={this.state.scDependencyDisabled}
                soDependencyHandleChange={(e, name) => this.handleSelect1(e, name)}
                scDependencyHandleChange={(e, name) => this.handleSelect1(e, name)}
              />
              <div className="vertSpace col-md-6">
                <SelectGeneral
                  name="role"
                  text={Strings.privilegeByRole.isBasicRole}
                  tooltip={Strings.privilegeByRole.isBasicRoleTooltip}
                  options={this.state.roles}
                  onChange={(e, name) => this.handleSelect1(e, name)}
                  disabled={this.state.rolesDisabled}
                  defaultValue={this.state.roleId}
                />
              </div>
            </Fragment>
          ) : (
            ""
          )}
        </div>

        <div className="row">
          <div className="vertSpace col-md-6" />
          <div className="vertSpace col-md-6">
            <ValidatePermissionForComponent permission="PRIVILEGE USERS BY ROLE POST" permissions={data}>
              <AssignButton
                link={this.state.assignButtonDisabled ? "" : "/addRoleAndAssignPrivileges"}
                tooltip={Strings.users.addClientUsersTooltip}
                props={
                  this.state.assignButtonDisabled
                    ? ""
                    : {
                        dependencyTypeId: this.state.dependencyTypeId,
                        dependencyTypeName: this.state.dependencyTypeName,

                        soDependencyId: this.state.soDependencyId,
                        soDependencyName: this.state.soDependencyName,

                        scDependencyId: this.state.scDependencyId,
                        scDependencyName: this.state.scDependencyName,
                      }
                }
                disabled={this.state.assignButtonDisabled}
              />
            </ValidatePermissionForComponent>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.privilegeByRole.privilegeByRoleList}</h3>
            </Divider>
            <Table dataSource={usersByRole} bordered={true} loading={this.state.loadingInfo} pagination={false} size="middle" scroll={{ x: "100%" }} key="id">
              <Column
                title={Strings.generalTerms.name}
                render={(row) => (
                  <Fragment>
                    {" "}
                    {row.firstName} {row.middleName} {row.lastName1} {row.lastName2}{" "}
                  </Fragment>
                )}
              />
              <Column title={Strings.users.identityCardNumber} {...this.getColumnSearchProps("identityCardNumber")} dataIndex="identityCardNumber" />
              <Column title={Strings.scDepartment.department} dataIndex="scDepartmentName" />
              <Column title={Strings.scCostCenter.costCenter} dataIndex="scCostCenterName" />
              <Column title={Strings.scUserProfile.userProfile} dataIndex="scUserProfileName" />
              {/*  (ValidatePermissionForComponentPart("PRIVILEGE USERS BY ROLE PUT", data)) ?
                                            <Column
                                                title={Strings.generalTerms.edit}
                                                maxWidth='50px'
                                                render={row => (
                                                    <div align='center'>
                                                        {this.renderEditOption(row)}
                                                    </div>
                                                )}
                                            />
                                            :
                                            ''
                           (ValidatePermissionForComponentPart("PRIVILEGE USERS BY ROLE DELETE", data)) ?
                                <Column
                                    title={Strings.generalTerms.delete}
                                    maxWidth='50px'
                                    render={row => (
                                        <div align='center'>
                                            <Popconfirm
                                                title={Strings.generalResponses.confirmDeleteMessage}
                                                onConfirm={(e) => this.handleDisableRole(e, row)}
                                                okText="Sí"
                                                cancelText="No"
                                            >
                                                <Icon
                                                    type="delete"
                                                    theme="twoTone"
                                                    className="addEditRemoveButton"
                                                />
                                            </Popconfirm>
                                        </div>
                                    )}
                                />
                                :
                                ''*/}
            </Table>
            <div className="col-xs-0 col-lg-6" />
            <div className="vertSpace col-lg-6">
              <Pagination
                size="small"
                total={this.state.usersByRoleCount}
                showQuickJumper
                showSizeChanger
                onChange={this.handlePagination}
                onShowSizeChange={this.handlePagination}
                hideOnSinglePage={false}
                pageSizeOptions={["10", "25", "50", "100", "250"]}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                current={this.state.page}
                disabled={this.state.usersByRoleCount === 0 ? true : false}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <ValidatePermissionForComponent permission="PRIVILEGE USERS BY ROLE REPORT GET" permissions={data}>
            <div className="vertSpace col-12">
              <div className="col-lg-6" />
              <div className="col-lg-6">
                <ExportButton isDisabled={this.state.rolesCount < 1} onClick={() => this.showExportReportsModal()} loading={this.state.exportLoading} />
              </div>
            </div>
          </ValidatePermissionForComponent>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soDependencyParent: state.soDependencyParent.soParent,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listRolesByDependencyIdPaged,
  listRolesByDependencyId,
  getUsersByRoleId,

  listPermissionTypeByDependencyTypeId,
  listPrivilegesByPermissionTypeId,
  getRoleDetailesById,
  editRoleWithPrivileges,
  disablePrivilegeByRoleId,
})(UsersByRole);
