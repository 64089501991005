import { LIST_VMSLOTPOSITION, ERROR } from '../actions';

const initialState = {
  vmSlotPositionObj: [],
  errorObj: []
}

export function vmSlotPosition(state = initialState, action) {
  switch (action.type) {
    case LIST_VMSLOTPOSITION:
      return Object.assign({}, state, {vmSlotPositionObj: action.payload.map(item => ({value:item.id, label:item.name}))})
    case ERROR:
      return Object.assign({}, state, {errorObj: action.payload})
    default:
      return state
  }
}