import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Icon, Button } from 'antd';
import './buttons.css';
import Strings from '../../../systemVariables/languageStrings';

const CancelButton = props => {
  return (
    <>
      <Link
        to={{
          pathname: props.link,
          props: props.props
        }}
      >
        <Tooltip title={props.tooltip}>
          <Button onClick={props.onClick} disabled={props.isDisabled} loading={props.loading} className="button">
            {props.loading ? null : <Icon type="close-circle" theme="twoTone" twoToneColor="#52c41a" hidden={props.hideIcon} />}
            <span>{Strings.generalTerms.cancel}</span>
          </Button>
        </Tooltip>
      </Link>
    </>
  );
};
export default CancelButton;
