import React from 'react';
import './selectOption.css';
//Language localization
import Strings from '../../systemVariables/languageStrings';
const SelectOption = () => {
    return (
        <div className="selectOption-container">
            <h3 className="header">{Strings.scDependency.indirectClientSelection}</h3>
        </div>
    )
}

export default SelectOption;

