import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Pagination, Divider, Button, Tooltip, Spin } from "antd";

// ? Actions
import {
  getAllMachineGenerationsAsync,
  getPeripheralDeviceModelsAsync,
  getAllPeripheralDeviceTypesAsync,
  getPeripheralDevicesByModelIdsAsync,
  getPeripheralDevicesByModelIdAsync,
} from "../../actions/index";

import ConnectionErrorComponent from "../Errors/ConnectionError";
import Error500 from "../Errors/error500";

// * Components
import Titles from "../GenericComponents/titles";
import PeripheralDeviceTable from "./PeripheralDeviceTable";
import { SelectGeneral } from "../GenericComponents/selectComponent/selectGeneral";
import { AddButton } from "../GenericComponents/buttons/index";
import { ValidatePermissionForComponent } from "../../Utils/validatePermissionForComponent";
import QueryResponse from "../GenericComponents/queryResponse/QueryResponse";

// Localization
import Strings from "../../systemVariables/languageStrings";

class PeripheralDevice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      peripheralDeviceModels: [],
      peripheralDeviceModelId: null,
      machinGenerationsId: null,
      MachinGenerations: [],
      peripheralDeviceModelName: "",
      peripheralDeviceTypes: [],
      peripheralDeviceTypeId: null,
      peripheralDeviceTypeName: "",
      peripheralDevices: [],
      peripheralFilterOptions: [],
      disablePeripheralDeviceModel: true,
      disableFilterType: true,
      addButtonDisabled: true,
      searchButtonDisabled: true,
      isTableLoading: false,
      // Pagination
      pageSize: 10,
      page: 1,

      isError500: false,
      isConnectionError: false,

      isLoadigSelectModelPeripheral: false,
      isLoadigGenerationMachine: false,
      isLoadigSelectTypelPeripheral: false,
      attribute: "id",
      order: "ASC",
      peripheralDeviceCount: 0,
      filterBy: 1, // Enabled by default
      altSerialFilter: "",
    };
  }

  async componentDidMount() {
    const FILTER_OPTIONS = [
      { value: 1, label: Strings.generalTerms.enabledPl },
      { value: 2, label: Strings.generalTerms.disabledPl },
      { value: 3, label: Strings.generalTerms.assignedPl },
      { value: 4, label: Strings.generalTerms.notAssignedPl },
    ];
    await this.setStateAsync({ isLoadigSelectTypelPeripheral: true });
    try {
      const ansGetAllPeripheralDeviceTypesAsync = await getAllPeripheralDeviceTypesAsync();
      await this.setStateAsync({
        peripheralDeviceTypes: ansGetAllPeripheralDeviceTypesAsync.data.data.map((obj) => ({
          value: obj.id,
          label: obj.name,
          peripheralScopeId: obj.peripheralScopeId,
        })),
        peripheralFilterOptions: FILTER_OPTIONS,
        isLoadigSelectTypelPeripheral: false,
      });
    } catch (e) {
      this.checkingError(e);
    }
  }

  /**
   * This function returns a promise that resolves after the state has been set.
   * @returns A promise that resolves when the state is set.
   */
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async handleSelectChange(event, selectOption) {
    const { value, label } = event;
    const { name } = selectOption;
    /* A switch statement that is being used to set the state of the component. */
    const SELECT_QUERY_SWITCH = {
      peripheralDeviceType: async () => {
        await this.setStateAsync({
          peripheralDeviceTypeId: value,
          peripheralDeviceTypeName: label,
          peripheralDeviceModelId: null,
          machinGenerationsId: -1,
          peripheralDevices: [],
          peripheralDeviceCount: 0,
          searchButtonDisabled: false,
          filterBy: 1,
          pageSize: 10,
          page: 1,
          disableFilterType: true,
          addButtonDisabled: false,
          isLoadigSelectModelPeripheral: true,
          isLoadigGenerationMachine: true,
        });
        try {
          const [ansMethodGetPeripheralDeviceModels, ansMethodGetAllMachinGenerations] = await Promise.all([
            this.methodGetPeripheralDeviceModels(),
            getAllMachineGenerationsAsync(),
          ]);
          const MachinGenerations = ansMethodGetAllMachinGenerations.data.data.map((obj) => ({
            value: obj.id,
            label: obj.generationString,
          }));
          MachinGenerations.unshift({
            value: -1,
            label: Strings.generalTerms.all,
          });
          await this.setStateAsync({
            peripheralDeviceModels: ansMethodGetPeripheralDeviceModels.data.data.map((obj) => ({
              value: obj.id,
              label: obj.reference,
              generationId: obj.peripheralDeviceModelIdsByGeneration,
            })),
            isError500: false,
            isConnectionError: false,
            pageSize: 10,
            page: 1,
            disablePeripheralDeviceModel: false,
            isLoadigSelectModelPeripheral: false,
            isLoadigGenerationMachine: false,
            MachinGenerations,
          });
        } catch (e) {
          this.checkingError(e);
        }
      },
      peripheralDeviceModel: async () => {
        await this.setStateAsync({
          peripheralDeviceModelId: value,
          addButtonDisabled: false,
          peripheralDeviceModelName: label,
          disableFilterType: false,
          peripheralDevices: [],
          peripheralDeviceCount: 0,
          pageSize: 10,
          page: 1,
        });
      },
      filterType: async () => {
        await this.setStateAsync({
          addButtonDisabled: false,
          filterBy: value,
          peripheralDevices: [],
          peripheralDeviceCount: 0,
          pageSize: 10,
          page: 1,
        });
      },
      MachinGenerations: async () => {
        await this.setStateAsync({
          machinGenerationsId: value,
          peripheralDeviceModelId: null,
          disableFilterType: true,
          peripheralDevices: [],
          peripheralDeviceCount: 0,
          filterBy: 1,
          isLoadigSelectModelPeripheral: true,
        });
        try {
          const ansMethodGetPeripheralDeviceModels = await this.methodGetPeripheralDeviceModels();
          await this.setStateAsync({
            peripheralDeviceModels: ansMethodGetPeripheralDeviceModels.data.data.map((obj) => ({
              value: obj.id,
              label: obj.reference,
              generationId: obj.peripheralDeviceModelIdsByGeneration,
            })),
            isError500: false,
            isConnectionError: false,
            pageSize: 10,
            page: 1,
            isLoadigSelectModelPeripheral: false,
            disablePeripheralDeviceModel: false,
          });
        } catch (e) {
          this.checkingError(e);
        }
      },
    };
    await SELECT_QUERY_SWITCH[name]();
  }

  checkingError(ans) {
    if (ans.message === "Network Error" || ans.message === "Cannot read properties of undefined (reading 'data')") {
      this.setState({
        isConnectionError: true,
        isLoadigSelectModelPeripheral: false,
        isLoadigSelectTypelPeripheral: false,
        isLoadigGenerationMachine: false,
        isTableLoading: false,
      });
    }
    const resultQuery =
      (ans.response.status,
      {
        500: () => {
          this.setState({
            isError500: true,
            isLoadigSelectModelPeripheral: false,
            isLoadigSelectTypelPeripheral: false,
            isLoadigGenerationMachine: false,
            isTableLoading: false,
          });
        },
      });
    resultQuery[ans.response.status]();
  }

  async methodGetPeripheralDeviceModels() {
    const { peripheralDeviceTypeId, machinGenerationsId } = this.state;
    const response = await getPeripheralDeviceModelsAsync(peripheralDeviceTypeId, machinGenerationsId);
    return response;
  }

  async getPeripheralDevicesByModelIds(peripheralDeviceTypeId, peripheralDeviceModelId, machinGenerationsId, page, pageSize, attribute, order, filterBy, altSerialFilter) {
    await this.setStateAsync({
      isTableLoading: true,
    });
    try {
      const ansGetPeripheralDevicesByModelIds = peripheralDeviceModelId
        ? await getPeripheralDevicesByModelIdAsync(peripheralDeviceModelId, page, pageSize, attribute, order, filterBy, altSerialFilter)
        : await getPeripheralDevicesByModelIdsAsync(peripheralDeviceTypeId, machinGenerationsId, page, pageSize, attribute, order, filterBy, altSerialFilter);
      await this.successfullStatesOk(ansGetPeripheralDevicesByModelIds);
    } catch (e) {
      this.checkingError(e);
    }
  }

  /**
   * This function sets the state of the component to the data returned from the server.
   */
  async successfullStatesOk(ans) {
    const {
      data: {
        data: { content, totalElements },
      },
    } = ans;
    const { altSerialFilter } = this.state;
    await this.setStateAsync({
      peripheralDevices: content,
      peripheralDeviceCount: totalElements,
      isTableLoading: false,
      altSerialFilter,
      isError500: false,
      isConnectionError: false,
    });
  }

  setAlternativeSerialFilter = async (altSerialFilter) => {
    await this.setStateAsync({
      altSerialFilter,
      page: 1,
      pageSize: 10,
    });
    const { peripheralDeviceModelId, machinGenerationsId, page, pageSize, attribute, order, filterBy, peripheralDeviceTypeId } = this.state;
    await this.getPeripheralDevicesByModelIds(peripheralDeviceTypeId, peripheralDeviceModelId, machinGenerationsId, page, pageSize, attribute, order, filterBy, altSerialFilter);
  };

  handlePagination = async (page, pageSize) => {
    await this.setStateAsync({
      page,
      pageSize,
    });
    const { attribute, order, filterBy, peripheralDeviceModelId, altSerialFilter, peripheralDeviceTypeId, machinGenerationsId } = this.state;
    await this.getPeripheralDevicesByModelIds(peripheralDeviceTypeId, peripheralDeviceModelId, machinGenerationsId, page, pageSize, attribute, order, filterBy, altSerialFilter);
  };

  reloadTableOrSearchPeripherals = async () => {
    await this.setStateAsync({
      altSerialFilter: "",
    });
    const { peripheralDeviceModelId, machinGenerationsId, page, pageSize, attribute, order, filterBy, altSerialFilter, peripheralDeviceTypeId } = this.state;
    await this.getPeripheralDevicesByModelIds(peripheralDeviceTypeId, peripheralDeviceModelId, machinGenerationsId, page, pageSize, attribute, order, filterBy, altSerialFilter);
  };

  PeripheralDeviceTable(peripheralDevices, isTableLoading, filterBy, peripheralDeviceCount, page) {
    return (
      <div className="row">
        <PeripheralDeviceTable
          peripheralDevices={peripheralDevices}
          loading={isTableLoading}
          setAlternativeSerialFilter={this.setAlternativeSerialFilter}
          filterType={filterBy}
          setSelectedDevice={this.setSelectedDevice}
          reloadTable={this.reloadTableOrSearchPeripherals}
        />
        <div className="col-lg-6" />
        <div className="vertSpace col-lg-6">
          <Pagination
            size="small"
            total={peripheralDeviceCount}
            showSizeChanger
            onChange={this.handlePagination}
            onShowSizeChange={this.handlePagination}
            hideOnSinglePage={false}
            pageSizeOptions={["10", "25", "50", "100", "250"]}
            showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total} ${Strings.generalTerms.items}`}
            current={page}
            disabled={peripheralDeviceCount === 0}
          />
        </div>
      </div>
    );
  }

  methodQueryResponseOrErrorCheck(peripheralDevices, isTableLoading, filterBy, peripheralDeviceCount, page) {
    const { isError500, isConnectionError } = this.state;
    if (isError500) {
      return <Error500 />;
    }
    if (isConnectionError) {
      return <ConnectionErrorComponent />;
    }
    return (
      <QueryResponse dataSourceLength={peripheralDevices.length} callback={this.PeripheralDeviceTable(peripheralDevices, isTableLoading, filterBy, peripheralDeviceCount, page)} />
    );
  }

  methodAddAndChek(
    addButtonDisabled,
    peripheralDeviceTypeId,
    peripheralDeviceModelId,
    peripheralDeviceModelName,
    peripheralDeviceTypeName,
    searchButtonDisabled,
    isTableLoading,
    MachinGenerations,
    machinGenerationsId,
    peripheralDeviceModels
  ) {
    return (
      <div className="row vertSpace" style={{ alignContent: "stretch" }}>
        <div className="col-md-7" />
        <div className="col-md-2" style={addButtonDisabled !== true ? {} : { marginTop: "-0.7%" }}>
          <Tooltip title={Strings.transaction.getTransactionsToolTip}>
            <Button
              type="primary"
              style={{ margin: "5px" }}
              size={addButtonDisabled !== true ? "large" : "default"}
              loading={isTableLoading}
              disabled={searchButtonDisabled}
              icon="search"
              onClick={() => this.reloadTableOrSearchPeripherals()}
            >
              {Strings.generalTerms.check}
            </Button>
          </Tooltip>
        </div>
        <div className=" col-md-2">
          <AddButton
            link="/addPeripheralDevice"
            tooltip={Strings.users.addClientUsersTooltip}
            props={{
              peripheralDeviceTypeId,
              peripheralDeviceModelId,
              peripheralDeviceModelName,
              peripheralDeviceTypeName,
              MachinGenerations,
              machinGenerationsId,
              peripheralDeviceModels,
            }}
            disabled={addButtonDisabled}
          />
          <div className="col-md-1" />
        </div>
      </div>
    );
  }

  render() {
    const {
      peripheralDeviceTypes,
      peripheralDeviceTypeId,
      peripheralDeviceTypeName,
      peripheralFilterOptions,
      disableFilterType,
      peripheralDevices,
      isTableLoading,
      peripheralDeviceModels,
      peripheralDeviceModelId,
      peripheralDeviceModelName,
      disablePeripheralDeviceModel,
      addButtonDisabled,
      filterBy,
      page,
      MachinGenerations,
      peripheralDeviceCount,
      machinGenerationsId,
      searchButtonDisabled,
      isLoadigSelectModelPeripheral,
      isLoadigSelectTypelPeripheral,
      isLoadigGenerationMachine,
    } = this.state;
    const {
      permissions: { data },
    } = this.props;

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.peripheralDevice.listPeripheralDevices} tooltip={Strings.peripheralDevice.listPeripheralDevicesTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <div className=" vertSpace col-md-6">
            <Spin spinning={isLoadigSelectTypelPeripheral} size="small" delay={50}>
              <SelectGeneral
                name="peripheralDeviceType"
                text={Strings.peripheralDevice.peripheralDeviceType}
                tooltip={Strings.peripheralDevice.peripheralFilterTooltipType}
                options={peripheralDeviceTypes}
                onChange={(e, name) => this.handleSelectChange(e, name)}
                defaultValue={peripheralDeviceTypeId}
              />
            </Spin>
          </div>
          <div className="vertSpace col-md-6">
            <Spin spinning={isLoadigGenerationMachine} size="small" delay={50}>
              <SelectGeneral
                name="MachinGenerations"
                text={Strings.machine.machineGeneration}
                tooltip={Strings.machine.machineGenerationTooltip}
                options={MachinGenerations}
                onChange={(e, selectOption) => this.handleSelectChange(e, selectOption)}
                disabled={disablePeripheralDeviceModel}
                defaultValue={machinGenerationsId}
              />
            </Spin>
          </div>
          <div className="vertSpace col-md-6">
            <Spin spinning={isLoadigSelectModelPeripheral} size="small" delay={50}>
              <SelectGeneral
                name="peripheralDeviceModel"
                text={Strings.peripheralDevice.peripheralDeviceModel}
                tooltip={Strings.peripheralDevice.peripheralFilterTooltipModel}
                options={peripheralDeviceModels}
                onChange={(e, selectOption) => this.handleSelectChange(e, selectOption)}
                disabled={disablePeripheralDeviceModel || peripheralDeviceModels.length === 0 ? true : false}
                defaultValue={peripheralDeviceModelId}
              />
            </Spin>
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              name="filterType"
              text={Strings.generalTerms.filterBy}
              tooltip={Strings.peripheralDevice.peripheralFilterTooltip}
              options={peripheralFilterOptions}
              onChange={(e, selectOption) => this.handleSelectChange(e, selectOption)}
              disabled={disableFilterType}
              defaultValue={filterBy}
            />
          </div>
        </div>
        <ValidatePermissionForComponent permission="PRIVILEGE PERIPHERAL DEVICE POST" permissions={data}>
          {this.methodAddAndChek(
            addButtonDisabled,
            peripheralDeviceTypeId,
            peripheralDeviceModelId,
            peripheralDeviceModelName,
            peripheralDeviceTypeName,
            searchButtonDisabled,
            isTableLoading,
            MachinGenerations,
            machinGenerationsId,
            peripheralDeviceModels
          )}
        </ValidatePermissionForComponent>
        <Divider orientation="left">
          <h3>{Strings.machine.configuration.peripheralDevices}</h3>
        </Divider>
        {this.methodQueryResponseOrErrorCheck(peripheralDevices, isTableLoading, filterBy, peripheralDeviceCount, page)}
      </div>
    );
  }
}

PeripheralDevice.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.any),
};

PeripheralDevice.defaultProps = {
  permissions: null,
};

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {})(PeripheralDevice);
