import { Result, Spin } from "antd";
import React from "react";

import Strings from "../../../systemVariables/languageStrings";
import "./queryResponse.css";

const QueryResponse = ({ dataSourceLength, callback, isLoading }) => {
  if (isLoading && dataSourceLength === 0) {
    return <Spin className="style-spin-msn" tip="Loading..." spinning={isLoading}></Spin>;
  }
  if (dataSourceLength > 0) return callback;
  return (
    <div className="style-alert-msn">
      <Result className="style-result-info" status="info" title={<p className="style-result-info-msn">{Strings.generalResponses.queryResponse}</p>} />
    </div>
  );
};

export default QueryResponse;
