import React from 'react';
import { Tooltip, Icon, Input } from 'antd';
//import './selectComponent.css';
import Strings from '../../../systemVariables/languageStrings'

const { TextArea } = Input;

export function DescriptionComponent(props) {
    return (
        <div className="col-md-12">
            <div className="aligning col-md-5">
                <Tooltip title={Strings.dispensationRule.scDispensationRuleDescription}>
                    <Icon className="icon" type="info-circle" />
                </Tooltip>
                <span>{Strings.generalTerms.description}</span>
            </div>

            <div className="col-md-7">
                <TextArea
                className="form-control"
                rows="2"
                defaultValue={props.description}
                placeholder="Descripción"
                onChange={props.onChange}
                />
            </div>
        </div>
    )
}
