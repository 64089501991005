import { LIST_NOAHUBKEYLIST } from '../actions'

export function noahubKeylist(state = [], action) {  
  switch (action.type) {    
    case LIST_NOAHUBKEYLIST:
      var noahubKeylist = action.payload.data.map(item => ({value:item.id, label:item.serialNumber}))
      return noahubKeylist;
    default:
      return state
  }
}