import actions from "./actions";
import _ from "lodash";

const initState = {
  isCompleted: false,
  isError: false,
  sc: null,
  items: [],
  selected_item: [],
  selected_variation: {},
  machine_products: [],
};

//                ...state.filter((item)=>(item.scDependencyId == action.sc)),

export default function (state = initState, action) {
  switch (action.type) {
    case actions.FETCH_PRODUCTS_BY_SC:
      return {
        ...state,
        ...{
          isCompleted: false,
          isError: false,
          sc: action.sc,
          items: [],
        },
      };
    case actions.RECEIVE_PRODUCTS_BY_SC:
      const items = _(action.data)
        .groupBy((item) => item.soProductId)
        .map((value, key) => ({
          name: value[0].productName,
          id: key,
          variations: value,
        }))
        .value();
      return {
        ...state,
        ...{
          isCompleted: true,
          isError: false,
          items: items,
          selected_item: items ? items[0] : [],
          selected_variation: items[0] ? items[0].variations[0] : {},
        },
      };
    case actions.SELECT_ITEM:
      return {
        ...state,
        ...{
          selected_item: state.items.find((item) => {
            return item.id + "" === action.id + "";
          }),
        },
      };
    case actions.CHANGE_SC:
      return {
        ...state,
        ...{
          sc: action.sc,
        },
      };
    case actions.SELECT_VARIATION:
      return {
        ...state,
        ...{
          selected_variation: action.variation,
        },
      };
    case actions.UPDATE_MACHINE_PRODUCTS:
      return {
        ...state,
        ...{
          machine_products: action.products,
        },
      };
    default:
      return state;
  }
}
