import React from "react";

import Select from "react-select";
import { Tooltip, Icon } from "antd";
import "./selectComponent.css";

// Language localization
import Strings from "../../../systemVariables/languageStrings";


export const SelectGeneralAux = (props) => {
  const { options, size, loading, defaultValue, isReq, text, tooltip, name, disabled, onChange } = props;

  let defVal = "default";
  let sizeSelectGeneral = "default";

  if (options) {
    defVal = options.filter((option) => option.value === defaultValue);
  }
  if (size) {
    sizeSelectGeneral = size;
  }

  const loadingSelectGeneral = loading || false;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="selectAligning col-4">
          <span>
            {text}
            <b>{isReq === true ? ": *" : ""}</b>
          </span>
          {tooltip ? (
            <Tooltip title={tooltip}>
              <Icon className="icon" type="info-circle" style={{ float: "none" }} />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        <div className="col-8 input">
          <Select
            name={name}
            loading={defVal.length > 0 ? loading : null}
            className="basic-single"
            options={options}
            placeholder={Strings.generalTerms.select}
            isDisabled={disabled}
            onChange={onChange}
            value={defVal}
            isLoading={defVal.length > 0 ? loadingSelectGeneral : null}
            size={sizeSelectGeneral}
          />
        </div>
      </div>
    </div>
  );
};
