import axios from "axios";

import { host, port } from "../systemVariables";

const resource = "/password";

/**
 * It takes a password and a callback function as arguments, and returns a function that makes an axios
 * request to the server, and then calls the callback function with the response from the server.
 * @param password - {
 * @param callback - a function that will be called after the request is made.
 * @returns A function that returns a promise.
 */
export function updateUserPassword(password, callback) {
  return () => {
    const data = JSON.stringify(password);
    axios({
      method: "put",
      url: `${host + port + resource}`,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}
