// Dependencies
import React, { Component } from 'react';
import { connect } from "react-redux";
import 'react-datepicker/dist/react-datepicker.css';
import { Divider, Modal } from 'antd';
import "./scEmployeeInformation.css";

import {
    addScUserProfile,
    addScCostCenter,
    addScDepartment
} from "../../actions/index"

// Components
import Titles from "../GenericComponents/titles";
import { GeneralInput } from "../GenericComponents/InputComponent/generalInput";

import {
    AcceptButton,
    ReturnButton
} from "../GenericComponents/buttons/index";

// Language localization
import Strings from "../../systemVariables/languageStrings";


class AddScEmployeeInformation extends Component {

    constructor(props) {
        super(props)
        const {location} = this.props;
        this.state = {
            categoryId: location.props ? location.props.categoryId : null,
            categoryName: location.props ? location.props.categoryName : null,
            scDependency: location.props ? location.props.scDependencyName : null,
            elementToAdd: {
                name: null,
                description: null,
                code: null,
                scDependencyId: location.props ? location.props.scDependencyId : null
            },
            loading: false,
        };
    }

    nameInputEvent(event) {
        const {elementToAdd} = this.state;
        elementToAdd.name = event.target.value
        if (elementToAdd.name.length === 0) {
            elementToAdd.name = null
        }
        this.setState({ elementToAdd })
    }

    descriptionInputEvent(event) {
        const {elementToAdd} = this.state;
        elementToAdd.description = event.target.value
        if (elementToAdd.description.length === 0) {
            elementToAdd.description = null
        }
        this.setState({ elementToAdd })
    }

    codeInputEvent(event) {
        const {elementToAdd} = this.state;
        elementToAdd.code = event.target.value
        if (elementToAdd.code.length === 0) {
            elementToAdd.code = null
        }
        this.setState({ elementToAdd })
    }

    handleOk = () => {
        const {elementToAdd} = this.state;
        this.setState({
            elementToAdd: {
                name: null,
                description: null,
                code: null,
                scDependencyId: elementToAdd.scDependencyId
            }
        });
    }

    addScEmployeeInfoHandleEvent() {
        const {categoryId, elementToAdd} = this.state;
        const {addScDepartment, addScUserProfile, addScCostCenter} = this.props;
        
        if (categoryId === 1) { // To departments
            if (elementToAdd.name != null) {
                this.setState({ loading: true });
                addScDepartment(elementToAdd, (response) => {
                    this.setState({ loading: false })
                    if (!response.data && String(response).includes("Error:")) {
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.saveError
                        })
                    } else if (response.data.code === 4008) { // Repeated entity error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    } else if (response.data && response.data.status === "SUCCESS") { // Successfull entity save
                        Modal.success({
                            title: Strings.generalResponses.successfulTransaction,
                            content: Strings.generalResponses.saveSuccess,
                            onOk: this.handleOk,
                        })
                    } else { // Other error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    }
                });
            } else {
                Modal.error({ // Invalid parameters
                    title: Strings.generalResponses.invalidParameters,
                    content: Strings.generalResponses.invalidError
                })
            }
        }

        if (categoryId === 2) { // To userProfiles
            if (elementToAdd.name != null) {
                this.setState({ loading: true });
                addScUserProfile(elementToAdd, (response) => {
                    this.setState({ loading: false })
                    if (!response.data && String(response).includes("Error:")) { 
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.saveError
                        })
                    } else if (response.data.code === 4008) { // Repeated entity error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    } else if (response.data && response.data.status === "SUCCESS") { // Successfull entity save
                        Modal.success({
                            title: Strings.generalResponses.successfulTransaction,
                            content: Strings.generalResponses.saveSuccess,
                            onOk: this.handleOk,
                        })
                    } else { // Other error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    }
                });
            } else {
                Modal.error({ // Invalid parameters
                    title: Strings.generalResponses.invalidParameters,
                    content: Strings.generalResponses.invalidError
                })
            }
        }

        if (categoryId === 3) { // To cost Centers
            if (elementToAdd.name != null) {
                this.setState({ loading: true });
                addScCostCenter(elementToAdd, (response) => {
                    this.setState({ loading: false })
                    if (!response.data && String(response).includes("Error:")) { 
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.saveError
                        })
                    } else if (response.data.code === 4008) { // Repeated entity error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    } else if (response.data && response.data.status === "SUCCESS") { // Successfull entity save
                        Modal.success({
                            title: Strings.generalResponses.successfulTransaction,
                            content: Strings.generalResponses.saveSuccess,
                            onOk: this.handleOk,
                        })
                    } else { // Other error
                        Modal.error({
                            title: Strings.generalResponses.failedTransaction,
                            content: Strings.generalResponses.alreadyExistsError
                        })
                    }
                });
            } else {
                Modal.error({ // Invalid parameters
                    title: Strings.generalResponses.invalidParameters,
                    content: Strings.generalResponses.invalidError
                })
            }
        }
    }

    render() {
        let nameToolTip;
        let descriptionToolTip;
        let codeToolTip;
        let titlePage;
        let titlePageToolTip;

        const {scDependency, elementToAdd, categoryId, categoryName, loading} = this.state;

        if (categoryId === 1) { // department
            nameToolTip = Strings.scDepartment.scDepartmentNameToolTip;
            descriptionToolTip = Strings.scDepartment.scDepartmentDescriptionToolTip;
            titlePage = Strings.scDepartment.newScDepartment;
            titlePageToolTip = Strings.scDepartment.newScDepartmentToolTip;
        }

        if (categoryId === 3) { // costCenter
            nameToolTip = Strings.scCostCenter.scCostCenterNameToolTip;
            descriptionToolTip = Strings.scCostCenter.scCostCenterDescriptionToolTip;
            codeToolTip = Strings.scCostCenter.scCostCenterCodeToolTip;
            titlePage = Strings.scCostCenter.newScCostCenter;
            titlePageToolTip = Strings.scCostCenter.newScCostCenterToolTip;
        }

        if (categoryId === 2) { // userProfile
            nameToolTip = Strings.scUserProfile.scUserProfileNameToolTip;
            descriptionToolTip = Strings.scUserProfile.scUserProfileDescriptionToolTip;
            titlePage = Strings.scUserProfile.newScUserProfile;
            titlePageToolTip = Strings.scUserProfile.newScUserProfileToolTip;
        }

        return (
            <div className="content-container">
                <div className="row">
                    <Titles
                        title={titlePage}
                        tooltip={titlePageToolTip}
                    />
                </div>

                <div className="row">
                    <div className="vertSpace col-12">
                        <div className="col-xs-12 col-md-6">
                            <Divider orientation="left"><h3>{Strings.scDependency.structureCategory} </h3></Divider>
                            <h3>{categoryName}</h3>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <Divider orientation="left"><h3>{Strings.scDependency.indirectClient} </h3></Divider>
                            <h3>{scDependency}</h3>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="vertSpace col-12">
                        <Divider orientation="left">
                            <h3>{Strings.generalTerms.parameterization} </h3>
                        </Divider>
                        {categoryId !== 3 ? (// !costCenter
                            <div className="col-12">
                                <div className="vertSpace col-xs-12 col-md-6">
                                    <GeneralInput
                                        text={Strings.generalTerms.name}
                                        tooltip={nameToolTip}
                                        onChange={(e) => this.nameInputEvent(e)}
                                        value={elementToAdd.name}
                                        isReq
                                        placeholder={Strings.generalTerms.name}
                                    />
                                </div>
                                <div className="vertSpace col-xs-12 col-md-6">
                                    <GeneralInput
                                        text={Strings.generalTerms.description}
                                        tooltip={descriptionToolTip}
                                        onChange={(e) => this.descriptionInputEvent(e)}
                                        value={elementToAdd.description}
                                        placeholder={Strings.generalTerms.description}
                                    />
                                </div>
                            </div>
                        ) : (
                                <div className="col-12">
                                    <div className="vertSpace col-xs-12 col-md-6">
                                        <GeneralInput
                                            text={Strings.generalTerms.name}
                                            tooltip={nameToolTip}
                                            onChange={(e) => this.nameInputEvent(e)}
                                            value={elementToAdd.name}
                                            isReq
                                            placeholder={Strings.generalTerms.name}
                                        />
                                    </div>
                                    <div className="vertSpace col-xs-12 col-md-6">
                                        <GeneralInput
                                            text={Strings.generalTerms.code}
                                            tooltip={codeToolTip}
                                            onChange={(e) => this.codeInputEvent(e)}
                                            value={elementToAdd.code}
                                            placeholder={Strings.generalTerms.code}
                                        />
                                    </div>
                                    <div className="vertSpace col-xs-12 col-md-6">
                                        <GeneralInput
                                            text={Strings.generalTerms.description}
                                            tooltip={descriptionToolTip}
                                            onChange={(e) => this.descriptionInputEvent(e)}
                                            value={elementToAdd.description}
                                            placeholder={Strings.generalTerms.description}
                                        />
                                    </div>
                                </div>
                            )}
                    </div>
                </div>

                <div className="row justify-content-end">
                    <div className="vertSpace col-6 col-md-3">
                        <ReturnButton link="/listScEmployeeInformation" />
                    </div>
                    <div className="vertSpace col-6 col-md-3">
                        <AcceptButton onClick={(e) => this.addScEmployeeInfoHandleEvent(e)} loading={loading} />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {
    }
}

export default connect(mapStateToProps, {
    addScUserProfile,
    addScCostCenter,
    addScDepartment
})(AddScEmployeeInformation)