import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/soproductbyproductgroup";

export function listSoProductByVmProductGroupId(vmProductGroupId, callback){
  return (dispatch) => {
    axios.get(host+ port + path  + version + resource,{
      params: {
        vmproductgroup_id: vmProductGroupId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      callback(error);
    });
  }
}

export async function listSoProductByVmProductGroupBySoDependencyId(soDependencyId){
  try {
    let response = await axios({
      url: host+ port + path  + version + resource,
      method: 'get',
      params: {
        sodependency_id: soDependencyId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    return response;
  } catch(error) {
    return error;
  }
}

export async function listSoProductByVmProductGroupByScDependencyId(scDependencyId){
  try {
    let response = await axios({
      url: host+ port + path  + version + resource,
      method: 'get',
      params: {
        scdependency_id: scDependencyId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    return response;
  } catch(error) {
    return error;
  }
}

// export function addSoProductGroupByProductGroup(scDepartment, callback){
//   return () => {
//     let data = JSON.stringify(scDepartment)
//     axios.post(host + port + path + version + resource, data, {
//       headers: {
//         'Content-Type': 'application/json',
//         "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
//       }
//     })
//     .then((response) => {
//       callback(response);
//     })
//     .catch(function (error) {
//       callback(error);
//     });
//   }
// }