/* eslint-disable react/no-unused-state */
// Dependencies
import { Alert, Button, Divider, Pagination, Result, Table, Tabs } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import { Redirect } from "react-router-dom";
import { financeAuditingLocalization } from "../../../Utils/LanguageLocalization/financeAuditingLocalization";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import Strings from "../../../systemVariables/languageStrings";
import SoScVmDependencySelector from "../../GenericComponents/selectComponent/SoScVmDependencySelector";
import { SelectGeneral } from "../../GenericComponents/selectComponent/selectGeneral";
import Titles from "../../GenericComponents/titles";
import VendingMachineCashAuditment from "./vendingMachineCashAuditment";

import {
  disableVendingMachineById,
  getAllMachinGenerations,
  getAllRelatedVendingMachinesToSoDependencyId,
  getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId,
  getVendingMachinesFilter,
  getVmCashTransactionByVmId,
  listScDependenciesBySoDependencyId,
  listScDependenciesByVenidngMachineIds,
  listVendingMachinesByScDependencyId,
  listVendingMachinesByScDependencyIdAndGenerationId,
} from "../../../actions";

const { TabPane } = Tabs;
const { Column } = Table;

const formatter = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  minimumFractionDigits: 0,
});

class vmFinanceAuditingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soDependencyId: null,
      scDependencyId: null,
      machineId: null,
      vmCashTransactions: [],
      isVisible: false,
      searchButtonDisabled: true,
      page: 1,
      pageSize: 10,
      attribute: "transactionDate",
      order: "DESC",
      totalElements: null,
      filterOptions: [],
      selectedFilterOption: -1,
      isLoading: false,
      redirectToVMCreation: false,
      redirectToHome: false,
    };
  }

  componentDidMount() {
    const FILTER_OPTIONS = [
      { value: -1, label: Strings.generalTerms.all },
      { value: 0, label: Strings.generalTerms.deposit },
      { value: 1, label: Strings.generalTerms.withdrawal },
    ];

    const { soScSelectionProps } = this.props;

    if (soScSelectionProps.so != null) {
      this.setState({
        soDependencyId: soScSelectionProps.so,
        searchButtonDisabled: true,
        isVisible: false,
      });
    }

    this.setState({ filterOptions: FILTER_OPTIONS });
  }

  handleSoDependencyChange = (event) => {
    this.setState({
      soDependencyId: event.value,
      searchButtonDisabled: true,
      isVisible: false,
    });
  };

  handleScDependencyChange = (event) => {
    this.setState({
      scDependencyId: event.value,
      searchButtonDisabled: true,
      isVisible: false,
    });
  };

  handleVendingMachineChange = (event) => {
    this.setState({
      vmCashTransactions: [],
      machineId: event.value,
      searchButtonDisabled: false,
      isVisible: false,
    });
  };

  onClickCheckButton() {
    const { getVmCashTransactionByVmId } = this.props;
    const { machineId, page, pageSize, attribute, order, selectedFilterOption } = this.state;

    this.setState({
      searchButtonDisabled: true,
      isVisible: true,
      page: 1,
      pageSize: 10,
      attribute: "transactionDate",
      order: "DESC",
      selectedFilterOption: -1,
      isLoading: true,
      totalElements: null,
    });
    if (machineId != null) {
      getVmCashTransactionByVmId(machineId, selectedFilterOption, page, pageSize, attribute, order, (response) => {
        this.setState({
          totalElements: response.data.data.totalElements,
          vmCashTransactions: response.data.data.content.map((item) => ({
            amount: item.amount,
            currencyCode: item.currencyCode,
            currencySymbol: item.currencySymbol,
            cashTransactionType: financeAuditingLocalization(item.cashTransactionType, item.cashTransactionTypeId),
            vmCashVaultType: financeAuditingLocalization(item.vmCashVaultType, item.vmCashVaultTypeId),
            transactionDate: item.transactionDate,
          })),
        });
      });
    }
    this.setState({ searchButtonDisabled: false });
  }

  showPagination = (currentPage, pageSize) => {
    const { getVmCashTransactionByVmId } = this.props;
    const { machineId, attribute, order, selectedFilterOption } = this.state;

    this.setState({
      page: currentPage,
      pageSize,
      attribute: "transactionDate",
      order: "DESC",
      selectedFilterOption: -1,
      isLoading: true,
      totalElements: null,
    });

    if (machineId != null) {
      getVmCashTransactionByVmId(machineId, selectedFilterOption, currentPage, pageSize, attribute, order, (response) => {
        this.setState({
          totalElements: response.data.data.totalElements,
          vmCashTransactions: response.data.data.content.map((item) => ({
            amount: item.amount,
            currencyCode: item.currencyCode,
            currencySymbol: item.currencySymbol,
            cashTransactionType: financeAuditingLocalization(item.cashTransactionType, item.cashTransactionTypeId),
            vmCashVaultType: financeAuditingLocalization(item.vmCashVaultType, item.vmCashVaultTypeId),
            transactionDate: item.transactionDate,
          })),
        });
      });
    }
    this.setState({ isLoading: false });
  };

  ShowPaginationBySizeChage = (page, pageSize) => {
    const { getVmCashTransactionByVmId } = this.props;
    const { machineId, attribute, order, selectedFilterOption } = this.state;

    this.setState({
      page: 1,
      pageSize,
      attribute: "transactionDate",
      order: "DESC",
      selectedFilterOption: -1,
      isLoading: true,
      totalElements: null,
    });

    if (machineId != null) {
      getVmCashTransactionByVmId(machineId, selectedFilterOption, page, pageSize, attribute, order, (response) => {
        this.setState({
          totalElements: response.data.data.totalElements,
          vmCashTransactions: response.data.data.content.map((item) => ({
            amount: item.amount,
            currencyCode: item.currencyCode,
            currencySymbol: item.currencySymbol,
            cashTransactionType: financeAuditingLocalization(item.cashTransactionType, item.cashTransactionTypeId),
            vmCashVaultType: financeAuditingLocalization(item.vmCashVaultType, item.vmCashVaultTypeId),
            transactionDate: item.transactionDate,
          })),
        });
      });
      this.setState({ isLoading: false });
    }

    this.setState({
      page,
      selectedFilterOption: -1,
    });
  };

  handleFilterChange = (event) => {
    const { getVmCashTransactionByVmId } = this.props;
    const { machineId, page, pageSize, attribute, order } = this.state;

    this.setState({
      selectedFilterOption: event.value,
      page: 1,
      pageSize: 10,
      attribute: "transactionDate",
      order: "DESC",
      isLoading: true,
    });

    if (machineId != null) {
      getVmCashTransactionByVmId(machineId, event.value, page, pageSize, attribute, order, (response) => {
        this.setState({
          totalElements: response.data.data.totalElements,
          vmCashTransactions: response.data.data.content.map((item) => ({
            amount: item.amount,
            currencyCode: item.currencyCode,
            currencySymbol: item.currencySymbol,
            cashTransactionType: financeAuditingLocalization(item.cashTransactionType, item.cashTransactionTypeId),
            vmCashVaultType: financeAuditingLocalization(item.vmCashVaultType, item.vmCashVaultTypeId),
            transactionDate: item.transactionDate,
          })),
        });
      });
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { permissions } = this.props;
    const {
      isVisible,
      searchButtonDisabled,
      machineId,
      vmCashTransactions,
      page,
      filterOptions,
      totalElements,
      selectedFilterOption,
      isLoading,
      redirectToHome,
      redirectToVMCreation,
    } = this.state;

    if (redirectToHome) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    if (redirectToVMCreation) {
      return (
        <Redirect
          to={{
            pathname: "/vmFinanceAuditingInfo",
          }}
        />
      );
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.machine.configuration.financeAuditing} tooltip={Strings.machine.financeAuditingTooltip} />
        </div>
        <div className="row">
          <div className="col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters}</h3>
            </Divider>
          </div>
        </div>
        <div className="row">
          <SoScVmDependencySelector
            onSoDependencyChange={this.handleSoDependencyChange}
            onScDependencyChange={this.handleScDependencyChange}
            onVendingMachineChange={this.handleVendingMachineChange}
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            hasSc
            hasVm
          />
          <div className="vertSpace col-xs-12 col-md-6">
            <Button style={{ margin: "5px" }} type="primary" onClick={(e) => this.onClickCheckButton(e)} icon="search" disabled={searchButtonDisabled}>
              {Strings.generalTerms.check}
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Divider orientation="left">
              <h3>{Strings.machine.configuration.financeAuditing}</h3>
            </Divider>
          </div>
        </div>
        {isVisible ? (
          <div className="row">
            <div className="col-12">
              <Tabs style={{ padding: "1%" }}>
                {ValidatePermissionForComponentPart("PRIVILEGE VM CASH AUDITMENT", permissions.data) && (
                  <TabPane tab={Strings.vmcashvaulttype.cashBalance} key="1">
                    <VendingMachineCashAuditment vmId={machineId} />
                  </TabPane>
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE CASH MOVEMENTS", permissions.data) && (
                  <TabPane tab={Strings.vmcashvaulttype.cashMovements} key="2">
                    {vmCashTransactions.length > 0 ? (
                      <>
                        <div className="row">
                          <div className="vertSpace col-xs-12 col-md-6">
                            <SelectGeneral
                              text={Strings.generalTerms.filterBy}
                              tooltip={Strings.machine.filterByTooltip}
                              options={filterOptions}
                              isLoading={isLoading}
                              onChange={this.handleFilterChange}
                              defaultValue={selectedFilterOption}
                            />
                            <br />
                          </div>
                          <div className="vertSpace col-xs-12 col-md-6" />
                        </div>
                        <div className="row">
                          <Table dataSource={vmCashTransactions} bordered pagination={false} size="middle" rowKey="id" scroll={{ x: "100%" }}>
                            <Column title={Strings.generalTerms.type} dataIndex="cashTransactionType" align="center" width="25%" />
                            <Column title={Strings.peripheralDevice.peripheralDeviceType} dataIndex="vmCashVaultType" align="center" width="25%" />
                            <Column
                              title={Strings.generalTerms.totalCash}
                              render={(row) => (
                                <div>
                                  {row.currencySymbol}
                                  {row.amount} {row.currencyCode}
                                </div>
                              )}
                              align="center"
                              width="25%"
                            />
                            <Column
                              title={Strings.dispensationReport.transactionDate}
                              render={(row) => <div>{new Date(row.transactionDate).toLocaleDateString("es-CO", { hour: "numeric", minute: "numeric" })} </div>}
                              align="center"
                              width="25%"
                            />
                          </Table>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-6" />
                          <div className="col-md-6">
                            <Pagination
                              size="small"
                              total={totalElements}
                              showSizeChanger
                              onChange={this.showPagination}
                              onShowSizeChange={this.ShowPaginationBySizeChage}
                              hideOnSinglePage={false}
                              pageSizeOptions={["10", "25", "50", "100"]}
                              showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total} ${Strings.generalTerms.items}`}
                              current={page}
                              disabled={totalElements ? !(totalElements > 0) : true}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="row">
                        <div className="style-alert-msn">
                          <Alert message={Strings.generalResponses.searchInformation} description={Strings.vmcashvaulttype.msgNotMovements} type="info" showIcon />
                          <div className="footer-style" />
                        </div>
                      </div>
                    )}
                  </TabPane>
                )}
              </Tabs>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="style-alert-msn">
              <Result className="style-result-info" status="info" title={<p className="style-result-info-msn">{Strings.generalResponses.queryResponse}</p>} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listScDependenciesBySoDependencyId,
  getVendingMachinesFilter,
  listScDependenciesByVenidngMachineIds,
  listVendingMachinesByScDependencyId,
  listVendingMachinesByScDependencyIdAndGenerationId,
  getAllRelatedVendingMachinesToSoDependencyId,
  getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId,
  disableVendingMachineById,
  getAllMachinGenerations,
  getVmCashTransactionByVmId,
})(vmFinanceAuditingInfo);
