import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/sodependencies";

export const LIST_SODEPENDENCYLOWERELEMENTS = "LIST_SODEPENDENCYLOWERELEMENTS";
export const LIST_SODEPENDENCIESTYPE0 = "LIST_SODEPENDENCIESTYPE0";
export const SODEPENDENCYPARENT = "SODEPENDENCYPARENT";

// List all soDependency lower relation (soDependencies, scDependencies, vendingMachines)
export function getLowerRelationsBySoDependencyId(soDependencyId, callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          sodependencyidfindlowerelements: soDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: LIST_SODEPENDENCYLOWERELEMENTS, payload: response.data });
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export async function getLowerRelationsBySoDependencyIdFn(soDependencyId) {

  try {
    let response = await axios({
      method: 'GET',
      url: host + port + path + version + resource,
      params: {
        sodependencyidfindlowerelements: soDependencyId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      }
    })
    return response
  } catch (err) {
    return err
  }
}

// get parent soDependency by soId
export function getSoDependencyParentBySoDependencyId(soDependencyId, callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          sodependencyid: soDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: SODEPENDENCYPARENT, payload: response.data });
      })
      .catch(function (error) {
        return error;
      });
  };
}

// get parent soDependency by soId
export function getSoDependencyParentByScDependencyId(scDependencyId, callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: SODEPENDENCYPARENT, payload: response.data });
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function listSoDependencyBySoDependencyType0(soDependencyTypeId, callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          soDependencyTypeId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: LIST_SODEPENDENCIESTYPE0, payload: response.data });
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export async function listSoDependencyBySoDependencyTypeFn(soDependencyTypeId) {
  try {
    let response = await axios({
      method: 'GET',
      url: host + port + path + version + resource,
      params: {
        soDependencyTypeId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      }
    })
    return response
  } catch (err) {
    return err
  }
}

export function getSoDependencyByParentIdAndTypeId(parentId, typeId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          parentid: parentId,
          typeid: typeId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
      });
  };
}

export function getHeadquartersByStockOwnerId(soDependencyId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/${soDependencyId}/headquarters`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getLogisticCentersByStockOwnerId(soDependencyId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/${soDependencyId}/logisticcenters`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}
