import { Popover, Table, Typography } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { DATE_TIME_FORMAT } from "../../../systemVariables/TimeConfig";
import Strings from "../../../systemVariables/languageStrings";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";
import DispensationAdjustmentSelection from "./dispensationadjustmentselection/DispensationAdjustmentSelectionComponent";

class UnderStockedTransactionsTable extends Component {
  getColumns = () => {
    const { removeRecordFromUnderStockedTransactionsTable, adjustmentTypes } = this.props;
    return [
      {
        title: "Id",
        dataIndex: "uuid",
        key: "id",
        align: "center",
        render: (row) => (
          <Popover placement="topLeft" title={<Typography.Text strong>Id</Typography.Text>} content={row}>
            <Typography.Text strong>{row.split("-")[0]} ...</Typography.Text>
          </Popover>
        ),
      },
      {
        title: Strings.machine.vendingMachine,
        dataIndex: "vmFriendlyName",
        key: "vmFriendlyName",
        align: "center",
      },
      {
        title: Strings.planogram.vmSlotPosition,
        dataIndex: "vmSlotNumber",
        key: "vmSlotNumber",
        align: "center",
        width: "10%",
      },
      {
        title: Strings.product.product,
        dataIndex: "soProductName",
        key: "product",
        align: "center",
      },
      {
        title: Strings.dispensationRule.amount,
        dataIndex: "amount",
        key: "amount",
        align: "center",
        width: "10%",
      },
      {
        title: Strings.users.user,
        dataIndex: "userName",
        key: "userName",
        align: "center",
        width: "10%",
      },
      {
        title: Strings.transaction.provisioningId,
        dataIndex: "provisioningId",
        key: "provisioningId",
        align: "center",
      },
      {
        title: Strings.dispensationReport.transactionDate,
        key: "transactionDate",
        align: "center",
        render: (row) => <span> {moment(row.transactionDate).format(DATE_TIME_FORMAT)} </span>,
      },
      {
        title: Strings.generalTerms.adjust,
        key: "adjust",
        align: "center",
        fixed: "right",
        width: 90,
        render: (row) => (
          <div className="row">
            <div className="col text-center">
              <DispensationAdjustmentSelection
                selectedTransactionToAdjust={row}
                removeRecordFromUnderStockedTransactionsTable={removeRecordFromUnderStockedTransactionsTable}
                adjustmentTypes={adjustmentTypes}
              />
            </div>
          </div>
        ),
      },
    ];
  };

  tableRender(tableData, isLoading) {
    return (
      <div>
        <Table rowKey="id" style={{ cursor: "pointer" }} columns={this.getColumns()} dataSource={tableData} loading={isLoading} bordered pagination={false} scroll={{ y: 240 }} />
      </div>
    );
  }

  render() {
    const { tableData, isLoading } = this.props;

    return <QueryResponse callback={this.tableRender(tableData, isLoading)} dataSourceLength={tableData.length} />;
  }
}
export default UnderStockedTransactionsTable;
