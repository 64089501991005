import Strings from "../../systemVariables/languageStrings";

export function FreqUnitNameLocalization(frequencyUnitName, frequencyValue) {
  let freqUnitName;
  switch (frequencyUnitName) {
    case "MINUTES":
      if (frequencyValue === 1) freqUnitName = Strings.timeUnits.minute;
      else freqUnitName = Strings.timeUnits.minutes;
      break;
    case "HOURS":
      if (frequencyValue === 1) freqUnitName = Strings.timeUnits.hour;
      else freqUnitName = Strings.timeUnits.hours;
      break;
    case "DAYS":
      if (frequencyValue === 1) freqUnitName = Strings.timeUnits.day;
      else freqUnitName = Strings.timeUnits.days;
      break;
    case "WEEKS":
      if (frequencyValue === 1) freqUnitName = Strings.timeUnits.week;
      else freqUnitName = Strings.timeUnits.weeks;
      break;
    case "MONTHS":
      if (frequencyValue === 1) freqUnitName = Strings.timeUnits.month;
      else freqUnitName = Strings.timeUnits.months;
      break;
    default:
      break;
  }
  return freqUnitName;
}

export function FreqUnitNameLocalizationPlural(frequencyUnitsNames) {
  let frequencyUnitsNamesCpy = [];
  frequencyUnitsNames.forEach((element) => {
    switch (element.label) {
      case "MINUTES":
        frequencyUnitsNamesCpy.push({
          value: element.value,
          label: Strings.timeUnits.minutes,
        });
        break;
      case "HOURS":
        frequencyUnitsNamesCpy.push({
          value: element.value,
          label: Strings.timeUnits.hours,
        });
        break;
      case "DAYS":
        frequencyUnitsNamesCpy.push({
          value: element.value,
          label: Strings.timeUnits.days,
        });
        break;
      case "WEEKS":
        frequencyUnitsNamesCpy.push({
          value: element.value,
          label: Strings.timeUnits.weeks,
        });
        break;
      case "MONTHS":
        frequencyUnitsNamesCpy.push({
          value: element.value,
          label: Strings.timeUnits.months,
        });
        break;
      case "YEARS":
        frequencyUnitsNamesCpy.push({
          value: element.value,
          label: Strings.timeUnits.years,
        });
        break;
      default:
        break;
    }
  });
  return frequencyUnitsNamesCpy;
}
