// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Modal } from "antd";
import { Redirect } from "react-router-dom";

// Language localization
import { identityCardTypeLocalization, rolesLocalization } from "../../../Utils/LanguageLocalization";
import Strings from "../../../systemVariables/languageStrings";

// Components
import Titles from "../../GenericComponents/titles";
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import UserForm from "../Commons/UserForm";
import AssignUsersToVendingMachineForm from "../Commons/AssignUsersToVendingMachineForm";
import SelectRoleForm from "../Commons/SelectRoleForm";

// Queries
import {
  listIdentityCardTypes,
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  getAllRelatedVendingMachinesToSoDependencyId,
  getSystemSoRoles,
  getVendingMachineSoRoles,
  insertExecutiveUser,
} from "../../../actions";

const defaultCountry = { id: 1, code: "+57" };

class AddExecutiveUser extends Component {
  constructor(props) {
    super(props);

    const {
      location,
      location: { props: { soDependencyName, isProvisioner } = {} },
    } = this.props;

    this.state = {
      soDependencyName,
      isProvisioner,
      userToAdd: {
        soDependencyId: location.props ? location.props.soDependencyId : null,
        firstName: null,
        middleName: null,
        lastName1: null,
        lastName2: null,
        identityCardTypeId: null,
        identityCardNumber: null,
        phone1: defaultCountry.code,
        phone2: null,
        countryId: defaultCountry.id,
        provinceId: null,
        cityId: null,
        address: null,
        email: null,
        birthDate: null,
        scDepartmentId: null,
        scCostCenterId: null,
        scUserProfileId: null,
        vmRoleIds: [], // So Role Ids
        sysRoleIds: [], // So Role Ids
        vendingMachineIds: [],
      },

      countryCode: "co",
      identityCardTypes: [],
      provinces: [],
      cities: [],
      soSysRoles: [],
      soVmRoles: [],
      vendingMachines: [],

      isSaveButtonLoading: false,
      redirectBackToListClientUsers: false,
    };
  }

  componentDidMount() {
    const { userToAdd } = this.state;

    const {
      location: { props: { isProvisioner } = {} },
      listIdentityCardTypes,
      listCountries,
      listProvincesByCountryId,
      getAllRelatedVendingMachinesToSoDependencyId,
      getSystemSoRoles,
      getVendingMachineSoRoles,
    } = this.props;

    if (userToAdd.soDependencyId !== null) {
      listIdentityCardTypes((response) => {
        this.setState({
          identityCardTypes: response.data.data
            .filter((obj) => obj.id !== 0)
            .map((item) => ({
              value: item.id,
              label: identityCardTypeLocalization(item.name),
            })),
        });
      });

      listCountries();

      listProvincesByCountryId(userToAdd.countryId, (response) => {
        if (response.data !== null && response.data !== undefined) {
          this.setState({
            provinces: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        }
      });

      getAllRelatedVendingMachinesToSoDependencyId(userToAdd.soDependencyId, (response) => {
        this.setState({
          vendingMachines: response.data.data.map((item) => ({
            value: item.id,
            label: `${item.friendlyName}`,
          })),
        });
      });

      getSystemSoRoles(userToAdd.soDependencyId, (response) => {
        this.setState({
          soSysRoles: response.data.data.map((item) => ({
            value: item.id,
            label: rolesLocalization(item.roleName),
          })),
        });
      });

      getVendingMachineSoRoles(userToAdd.soDependencyId, (response) => {
        if (isProvisioner) {
          const provisionerSoVmRoleId = response.data.data
            .filter((soRole) => soRole.roleId === 5)
            .map((soRole) => {
              return soRole.id;
            });
          const { userToAdd } = { ...this.state };
          userToAdd.vmRoleIds = provisionerSoVmRoleId;
          this.setState({
            userToAdd,
          });
        }
        this.setState({
          soVmRoles: response.data.data.map((item) => ({
            value: item.id,
            label: rolesLocalization(item.roleName),
          })),
        });
      });
    }
  }

  setUserToInitialState = () => {
    const { userToAdd } = { ...this.state };

    userToAdd.firstName = null;
    userToAdd.middleName = null;
    userToAdd.lastName1 = null;
    userToAdd.lastName2 = null;
    userToAdd.identityCardTypeId = null;
    userToAdd.identityCardNumber = null;
    userToAdd.phone1 = defaultCountry.code;
    userToAdd.phone2 = null;
    userToAdd.countryId = defaultCountry.id;
    userToAdd.provinceId = null;
    userToAdd.cityId = null;
    userToAdd.address = null;
    userToAdd.email = null;
    userToAdd.birthDate = null;
    userToAdd.scDepartmentId = null;
    userToAdd.scCostCenterId = null;
    userToAdd.scUserProfileId = null;
    userToAdd.vmRoleIds = []; // So Role Ids
    userToAdd.sysRoleIds = []; // So Role Ids
    userToAdd.vendingMachineIds = [];

    this.setState({
      userToAdd,
    });
  };

  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { userToAdd } = { ...this.state };

    userToAdd[id] = value;

    this.setState({
      userToAdd,
    });
  };

  handleSelect = (option, event) => {
    const { value } = option;
    const { userToAdd } = { ...this.state };

    userToAdd[event.name] = value;

    this.setState({
      userToAdd,
    });

    const { listProvincesByCountryId, listCitesByProvincesId } = this.props;

    switch (event.name) {
      case "countryId":
        this.setState({ countryCode: option.code });
        listProvincesByCountryId(value, (response) => {
          if (response.data !== null && response.data !== undefined) {
            this.setState({
              provinces: response.data.data.map((item) => ({
                value: item.id,
                label: item.name,
              })),
              cities:[],
            });
          }
        });
        break;
      case "provinceId":
        listCitesByProvincesId(value, (response) => {
          this.setState({
            cities: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        });
        break;
      default:
    }
  };

  handleMultiSelect = (options, source) => {
    const { userToAdd } = { ...this.state };

    userToAdd[source] = options.map((option) => {
      return parseInt(option, 10);
    });

    this.setState({
      userToAdd,
    });
  };

  handleAddUser = () => {
    const { userToAdd } = { ...this.state };

    let validateEmail = false;
    if (userToAdd.email === undefined || userToAdd.email === null || userToAdd.email === "" || userToAdd.email === "@") {
      userToAdd.email = "@";
      this.setState({
        userToAdd,
      });
      validateEmail = true;
    } else {
      const regexEmail = /^[\w._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      validateEmail = regexEmail.test(userToAdd.email);
    }

    if (
      userToAdd.firstName &&
      userToAdd.lastName1 &&
      userToAdd.identityCardTypeId &&
      userToAdd.identityCardNumber &&
      userToAdd.countryId &&
      userToAdd.provinceId &&
      userToAdd.cityId &&
      (userToAdd.vmRoleIds.length !== 0 || userToAdd.sysRoleIds.length !== 0) &&
      validateEmail
    ) {
      this.setState({
        isSaveButtonLoading: true,
      });
      const { insertExecutiveUser } = this.props;
      insertExecutiveUser(userToAdd, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data.code === 4001) {
          // Entity Already exists and is enabled
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: `Ya existe en alguna dependencia relacionada un usuario con la cedula: ${userToAdd.identityCardNumber}`,
          });
        } else if (response.data.code === 0) {
          // Entity successfully saved
          Modal.success({
            title: Strings.generalResponses.successfulCreation,
            content: `${Strings.generalResponses.creationSuccess}: ${response.data.data.firstName} ${response.data.data.lastName1}`,
          });
          this.setUserToInitialState();
        }
        this.setState({
          isSaveButtonLoading: false,
        });
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
    }
  };

  handleOnOkNoScDependencyWarning = () => {
    this.setState({ redirectBackToListClientUsers: true });
  };

  showNoSoDependencyWarning = () => {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOnOkNoScDependencyWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  };

  render() {
    const {
      userToAdd,
      redirectBackToListClientUsers,
      identityCardTypes,
      provinces,
      cities,
      soSysRoles,
      soVmRoles,
      vendingMachines,
      isSaveButtonLoading,
      soDependencyName,
      isProvisioner,
      countryCode,
    } = this.state;

    if (redirectBackToListClientUsers) {
      return <Redirect to="/listExecutiveUsers" />;
    }

    const { countries } = this.props;

    if (!userToAdd.soDependencyId) {
      return <div>{this.showNoSoDependencyWarning()} </div>;
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.users.addExecutiveUsers} tooltip={Strings.users.addExecutiveUsersTooltip} />
        </div>

        <div className="row">
          {soDependencyName ? (
            <div className="col-md-6">
              <Divider orientation="left">
                <h3>{Strings.soDependency.directClient} </h3>
              </Divider>
              <h3>{soDependencyName}</h3>
            </div>
          ) : null}
          {isProvisioner ? (
            <div className="col-md-6">
              <Divider orientation="left">
                <h3>{Strings.soDependency.soDependencyTypeDefinition} </h3>
              </Divider>
              <h3>{Strings.Roles.provisioner}</h3>
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.parameterization} </h3>
            </Divider>
          </div>
        </div>

        <UserForm
          user={userToAdd}
          identityCardTypes={identityCardTypes}
          countries={countries}
          provinces={provinces}
          cities={cities}
          onChange={this.handleFormChange}
          onSelect={this.handleSelect}
          countryCode={countryCode}
        />

        <SelectRoleForm user={userToAdd} sysRoles={soSysRoles} vmRoles={soVmRoles} onMultiSelect={this.handleMultiSelect} />

        {userToAdd.vmRoleIds.length !== 0 ? <AssignUsersToVendingMachineForm user={userToAdd} vendingMachines={vendingMachines} onMultiSelect={this.handleMultiSelect} /> : null}

        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link="/listExecutiveUsers" />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton loading={isSaveButtonLoading} onClick={(e) => this.handleAddUser(e)} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countries: state.countries,
  };
}

export default connect(mapStateToProps, {
  listIdentityCardTypes,
  getVendingMachineSoRoles,
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  getAllRelatedVendingMachinesToSoDependencyId,
  getSystemSoRoles,
  insertExecutiveUser,
})(AddExecutiveUser);
