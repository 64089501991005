import axios from "axios";
import { host, port, path, version } from "../systemVariables";

const resource = "/provinces";

export const LIST_PROVINCES = "LIST_PROVINCES";

export function listProvincesByCountryId(countryId, callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          countryId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: LIST_PROVINCES, payload: response.data });
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}
