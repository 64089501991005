import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Pagination, Checkbox, Modal, Alert } from "antd";

// Components
import Titles from "../../GenericComponents/titles";
import { AddButton } from "../../GenericComponents/buttons/index";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import SoDependencySelector from "../../GenericComponents/selectComponent/SoDependencySelector";
import ExecutiveUsersTable from "./ExecutiveUsersTable";

// Actions
import {
  listSoDependencyBySoDependencyType0,
  getLowerRelationsBySoDependencyId,
  listSoDSuppliersByParentId,
  listAllSoDependencyTypes,
  getSoUsersBySoDependencyIdAndSoDependencyTypeIdAndIdentityCardNumberOrUsernamePaginatedByAttribute,
  getSoDependencyByParentIdAndTypeId,
  disableUser,
} from "../../../actions/index";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

class listExecutiveUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      soDependencyId: null,
      soDependencyName: null,

      // Provisioner option
      isProvisioner: false,
      isProvisionerDisabled: true,

      identityCardNumber: "",
      username: "",
      isAddButtonDisabled: true,

      // Pagination
      pageSize: 10,
      page: 1,
      attribute: "id",
      order: "ASC",

      // Table info
      isTableLoading: false,
      users: [],
      usersCount: 0,
    };
  }

  componentDidMount() {
    const { isProvisioner } = this.state;

    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      userDependency: { dependencyName },
      soScSelectionProps,
    } = this.props;

    // User of StockOwner different to Noatec
    if (dependencyType === "so" && dependencyId !== 1) {
      this.setState({
        soDependencyId: dependencyId,
        soDependencyName: dependencyName,
      });

      this.getUsersBySoDependencyIdAndIsProvisioner(dependencyId, isProvisioner);
    } else if (soScSelectionProps.so) {
      this.setState({
        soDependencyId: soScSelectionProps.so.id,
        soDependencyName: soScSelectionProps.so.label,
      });

      this.getUsersBySoDependencyIdAndIsProvisioner(soScSelectionProps.so.id, isProvisioner);
    }
  }

  getUsersBySoDependencyIdAndIsProvisioner = (dependencyId, isProvisioner) => {
    const { pageSize, attribute, order } = this.state;
    const { getSoUsersBySoDependencyIdAndSoDependencyTypeIdAndIdentityCardNumberOrUsernamePaginatedByAttribute } = this.props;

    this.setState({
      isTableLoading: true,
      isAddButtonDisabled: true,
      isProvisionerDisabled: true,
      page: 1,
      pageSize,
    });

    getSoUsersBySoDependencyIdAndSoDependencyTypeIdAndIdentityCardNumberOrUsernamePaginatedByAttribute(
      dependencyId,
      isProvisioner,
      "",
      "",
      1,
      pageSize,
      attribute,
      order,
      (response) => {
        this.setState({
          users: response.data.data.content,
          usersCount: response.data.data.totalElements,
          isTableLoading: false,
          isAddButtonDisabled: false,
          isProvisionerDisabled: false,
        });
      }
    );
  };

  // Events
  handleSoSelectorChange = (event) => {
    const { isProvisioner, pageSize } = this.state;

    const { value: soDependencyId, label: soDependencyName } = event;

    this.setState({
      soDependencyId,
      soDependencyName,
      pageSize,
    });
    this.getUsersBySoDependencyIdAndIsProvisioner(soDependencyId, isProvisioner);
  };

  handleCheckBoxChange = (event) => {
    const { soDependencyId } = this.state;

    const {
      target: { checked },
    } = event;

    this.setState({
      isProvisioner: checked,
    });

    this.getUsersBySoDependencyIdAndIsProvisioner(soDependencyId, checked);
  };

  disableUser = (row) => {
    const { soDependencyId, isProvisioner } = this.state;
    const { disableUser } = this.props;

    this.setState({
      isTableLoading: true,
    });

    disableUser(row.id, (response) => {
      if (response.data.code === 0) {
        // Entity successfully saved
        Modal.success({
          title: Strings.generalResponses.successfulDelete,
          content: Strings.generalResponses.successfulDelete,
        });
        this.getUsersBySoDependencyIdAndIsProvisioner(soDependencyId, isProvisioner);
      } else {
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        this.setState({
          isTableLoading: false,
        });
      }
    });
  };

  handleSearch = (selectedKeys, confirm, filter) => {
    const { soDependencyId, isProvisioner, page, pageSize, attribute, order } = this.state;
    const { getSoUsersBySoDependencyIdAndSoDependencyTypeIdAndIdentityCardNumberOrUsernamePaginatedByAttribute } = this.props;
    if (selectedKeys.length > 0) {
      this.setState({
        isTableLoading: true,
        page: 1,
        pageSize: 10,
      });
    }
    if (filter === 1 && selectedKeys.length > 0) {
      getSoUsersBySoDependencyIdAndSoDependencyTypeIdAndIdentityCardNumberOrUsernamePaginatedByAttribute(
        soDependencyId,
        isProvisioner,
        selectedKeys[0],
        "",
        page,
        pageSize,
        attribute,
        order,
        (response) => {
          this.setState({
            users: response.data.data.content,
            usersCount: response.data.data.totalElements,
            isTableLoading: false,
            identityCardNumber: selectedKeys[0],
          });
        }
      );
    }
    if (filter === 2 && selectedKeys.length > 0) {
      getSoUsersBySoDependencyIdAndSoDependencyTypeIdAndIdentityCardNumberOrUsernamePaginatedByAttribute(
        soDependencyId,
        isProvisioner,
        "",
        selectedKeys[0],
        page,
        pageSize,
        attribute,
        order,
        (response) => {
          this.setState({
            users: response.data.data.content,
            usersCount: response.data.data.totalElements,
            isTableLoading: false,
            username: selectedKeys[0],
          });
        }
      );
    }
  };

  handleReset = () => {
    const { soDependencyId, isProvisioner } = this.state;
    this.getUsersBySoDependencyIdAndIsProvisioner(soDependencyId, isProvisioner);
  };

  handlePagination = (page, pageSize) => {
    const { soDependencyId, isProvisioner, identityCardNumber, username, attribute, order } = this.state;
    const { getSoUsersBySoDependencyIdAndSoDependencyTypeIdAndIdentityCardNumberOrUsernamePaginatedByAttribute } = this.props;
    this.setState({
      isTableLoading: true,
    });
    getSoUsersBySoDependencyIdAndSoDependencyTypeIdAndIdentityCardNumberOrUsernamePaginatedByAttribute(
      soDependencyId,
      isProvisioner,
      identityCardNumber,
      username,
      page,
      pageSize,
      attribute,
      order,
      (response) => {
        this.setState({
          users: response.data.data.content,
          usersCount: response.data.data.totalElements,
          page,
          pageSize,
          isTableLoading: false,
        });
      }
    );
  };

  render() {
    const { soDependencyId, soDependencyName, usersCount, isTableLoading, isAddButtonDisabled, isProvisioner, isProvisionerDisabled, page } = this.state;

    const {
      permissions: { data },
    } = this.props;

    // List vmUsers only
    let { users } = { ...this.state };
    users = users.filter((user) => user.identityCardType !== "NONE");

    return (
      <div className="content-container" style={{ margin: "0 auto" }}>
        <div className="row">
          <div className="col">
            <Titles title={Strings.users.executiveUsers} tooltip={Strings.users.listExecutiveUsersTooltip} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <SoDependencySelector onSoDependencyChange={this.handleSoSelectorChange} />

          <div className="vertSpace col-md-6">
            <div className="row">
              <div className="col-12 text-left">
                <span>{Strings.users.provisionerOnly}: </span>
                <span>
                  <Checkbox
                    checked={isProvisioner}
                    disabled={isProvisionerDisabled}
                    name={Strings.soDependency.soDependencyType.provisioner}
                    depType={3}
                    onChange={(event, name, depType) => this.handleCheckBoxChange(event, depType, name)}
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="vertSpace col-12">
            <div className="col-xs-0 col-md-6" />
            <ValidatePermissionForComponent permission="PRIVILEGE EXECUTIVE USERS POST" permissions={data}>
              <div className="vertSpace col-md-6">
                <AddButton
                  link={isAddButtonDisabled ? "" : "/addExecutiveUser"}
                  tooltip={Strings.users.addExecutiveUsersTooltip}
                  props={
                    isAddButtonDisabled
                      ? null
                      : {
                          soDependencyId,
                          soDependencyName,
                          isProvisioner,
                        }
                  }
                  disabled={isAddButtonDisabled}
                />
              </div>
            </ValidatePermissionForComponent>
          </div>
        </div>
        <Divider orientation="left">
          <h3>{Strings.users.listExecutiveUsers}</h3>
        </Divider>
        {this.executiveUsersTable(users, isTableLoading, soDependencyId, isProvisioner, usersCount, page)}
      </div>
    );
  }

  executiveUsersTable(users, isTableLoading, soDependencyId, isProvisioner, usersCount, page) {
    if (users.length > 0) {
      return (
        <div className="row">
          <div className="col-12">
            <ExecutiveUsersTable
              dataSource={users}
              loading={isTableLoading}
              soDependencyId={soDependencyId}
              isProvisioner={isProvisioner}
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
              disableUser={this.disableUser}
            />
            <div className="vertSpace col-12">
              <div className="col-md-6" />
              <div className="vertSpace col-md-6">
                <Pagination
                  size="small"
                  total={usersCount}
                  showSizeChanger
                  onChange={this.handlePagination}
                  onShowSizeChange={this.handlePagination}
                  hideOnSinglePage={false}
                  pageSizeOptions={["10", "25", "50", "100", "250"]}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  current={page}
                  disabled={users ? !(users.length > 0) : true}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{ margin: "3% auto", maxWidth: "60%" }}>
        <Alert message={Strings.generalResponses.searchInformation} description={Strings.generalResponses.queryResponse} type="info" showIcon />
        <div style={{ margin: "40% auto" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependenciesNotType0: state.soDependencies,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  getLowerRelationsBySoDependencyId,
  listSoDSuppliersByParentId,
  listAllSoDependencyTypes,
  getSoUsersBySoDependencyIdAndSoDependencyTypeIdAndIdentityCardNumberOrUsernamePaginatedByAttribute,
  getSoDependencyByParentIdAndTypeId,
  disableUser,
})(listExecutiveUsers);
