import axios from "axios";
import { host, path, port, version } from "../systemVariables";

// Method path
const resource = "/currencycodebycountry";

export const LIST_CURRENCYCODEBYCOUNTRY = "LIST_CURRENCYCODEBYCOUNTRY";

export function listAllCurrencyCodesByCountry(countryName, callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          country: countryName,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        dispatch({ type: LIST_CURRENCYCODEBYCOUNTRY, payload: response.data });
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function listAllCurrencyCodesByCountryId(countryId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          countryId: countryId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}
