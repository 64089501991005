import { LIST_FREQUENCYUNITS } from '../actions'

export function frequencyUnits(state = [], action) {
  switch (action.type) {
    case LIST_FREQUENCYUNITS:
      var frequencyUnits = action.payload.data.map(item => ({value:item.id, label:item.name}))
      return frequencyUnits;
    default:
      return state
  }
}