//Dependencies
import { Divider, Modal, Spin } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { editScDependency, listCitesByProvincesId, listCountries, listProvincesByCountryId, listScDependencyType } from "../../actions";

// Components
import { GeneralInput } from "../GenericComponents/InputComponent/generalInput";
import { ReturnButton, SaveButton } from "../GenericComponents/buttons/index";
import { GeneralCheckbox } from "../GenericComponents/checkBox/GeneralCheckBox";
import { SelectGeneral } from "../GenericComponents/selectComponent/index";
import Titles from "../GenericComponents/titles";

// Language localization
import Strings from "../../systemVariables/languageStrings";

class EditScDependency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soDependencyType0Id: this.props.location.props ? this.props.location.props.soDependencyType0Id : null,
      soDependencyType0Name: this.props.location.props ? this.props.location.props.soDependencyType0Name : null,

      soDependencyTypeId: this.props.location.props ? this.props.location.props.soDependencyTypeId : null,
      soDependencyTypeName: this.props.location.props ? this.props.location.props.soDependencyTypeName : null,

      soDependencyNotType0Id: this.props.location.props ? this.props.location.props.soDependencyNotType0Id : null,
      soDependencyNotType0Name: this.props.location.props ? this.props.location.props.soDependencyNotType0Name : null,

      uniqueIdentifierToVerify: null,
      uniqueIdentifierValidated: false,

      soDependencyId: this.props.location.props ? this.props.location.props.lastSoDependencyId : null,

      scDependencyToEdit: this.props.location.props ? this.props.location.props.scDependencytoEdit : null,
      scSoDependencyId: this.props.location.props ? this.props.location.props.scDependencytoEdit.soDependencyId : null,
      soDependencyTypes: this.props.location.props ? this.props.location.props.soDependencyTypes : null,
      headQuarters: this.props.location.props ? this.props.location.props.headQuarters : null,
      logisticCenters: this.props.location.props ? this.props.location.props.logisticCenters : null,

      redirectBackToScDependency: true, // Flag used to redirect to /listScDependency
      saveButtonIsDisabled: true,
      loading: true,
      scDependencyTypeList: [],
      selectedSoDependencyTypeId: null,
      soDependencyTypeName: null,
      selectedSoDependencyId: null,
      showHeadQuarters: false,
      showLogisticCenters: false,
      isDataLoading: false,
    };
    this.CountryHandleChange = this.countryInputEvent.bind(this);
    this.ProvinceHandleChange = this.provinceInputEvent.bind(this);
  }

  componentDidMount() {
    const { soDependencyId, scDependencyToEdit, scSoDependencyId } = this.state;

    if (soDependencyId !== null) {
      this.props.listCountries();
      this.props.listScDependencyType((response) => {
        this.setState({
          scDependencyTypeList: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });

      this.props.listProvincesByCountryId(scDependencyToEdit.countryId, (response) => {
        this.setState({
          provinces: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });

      this.props.listCitesByProvincesId(scDependencyToEdit.provinceId, (response) => {
        this.setState({
          cities: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
          loading: false,
        });
      });

      this.setState({
        isVendingAllowed: scDependencyToEdit.isVendingAllowed,
        isProvisioningAllowed: scDependencyToEdit.isProvisioningAllowed,
      });
    }
  }
  /// /////---------Events---------//////////////

  nameInputHandleChange(event) {
    let scDependency = Object.assign({}, this.state.scDependencyToEdit);
    scDependency.name = _.trimStart(event.target.value);
    if (scDependency.name.length === 0) {
      this.setState({ saveButtonIsDisabled: true });
      scDependency.name = null;
    } else {
      this.setState({ saveButtonIsDisabled: false });
    }
    this.setState({ scDependencyToEdit: scDependency });
  }

  nitInputHandleChange(event) {
    let scDependency = Object.assign({}, this.state.scDependencyToEdit);
    scDependency.uniqueIdentifier = _.trim(event.target.value);
    if (scDependency.uniqueIdentifier.length === 0) {
      scDependency.uniqueIdentifier = null;
    } else {
      this.setState({ saveButtonIsDisabled: false });
    }
    this.setState({ scDependencyToEdit: scDependency });
  }

  scDependencyTypeInputEvent(event) {
    let scDependencyTypeId = event.value;
    let scDependency = Object.assign({}, this.state.scDependencyToEdit);

    if (scDependencyTypeId === null) {
      scDependency.scDependencyTypeId = null;
    } else {
      scDependency.scDependencyTypeId = scDependencyTypeId;
    }
    this.setState({ scDependencyToEdit: scDependency });
  }

  countryInputEvent(event) {
    let countryId = event.value;
    let scDependencyToEdit = Object.assign({}, this.state.scDependencyToEdit);

    if (countryId === null) {
      scDependencyToEdit.countryId = null;
    } else {
      scDependencyToEdit.countryId = countryId;
      this.props.listProvincesByCountryId(countryId, (response) => {
        this.setState({
          provinces: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }
    this.setState({ scDependencyToEdit });
  }

  provinceInputEvent(event) {
    let provinceId = event.value;
    let scDependencyToEdit = Object.assign({}, this.state.scDependencyToEdit);

    if (provinceId === null) {
      scDependencyToEdit.provinceId = null;
    } else {
      scDependencyToEdit.provinceId = provinceId;
      this.props.listCitesByProvincesId(provinceId, (response) => {
        this.setState({
          cities: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }
    this.setState({ scDependencyToEdit });
  }

  cityInputEvent(event) {
    let cityId = event.value;
    let scDependencyToEdit = Object.assign({}, this.state.scDependencyToEdit);

    if (cityId === null) {
      scDependencyToEdit.cityId = null;
    } else {
      scDependencyToEdit.cityId = cityId;
    }

    this.setState({ scDependencyToEdit });
  }

  addressInputEvent(event) {
    let address = event.target.value;
    address = _.trim(address);

    let scDependencyToEdit = Object.assign({}, this.state.scDependencyToEdit);
    scDependencyToEdit.address = address;

    if (address.length === 0 || address === null) {
      scDependencyToEdit.address = null;
    }
    this.setState({
      scDependencyToEdit,
    });
  }

  phone1InputEvent(event) {
    let phone1 = event.target.value;
    phone1 = _.trim(phone1);

    let scDependencyToEdit = Object.assign({}, this.state.scDependencyToEdit);
    scDependencyToEdit.phone1 = phone1;

    if (phone1.length === 0 || phone1 === null) {
      scDependencyToEdit.phone1 = 0;
    } else {
      scDependencyToEdit.phone1 = phone1;
    }
    this.setState({ scDependencyToEdit });
  }
  phone2InputEvent(event) {
    let phone2 = event.target.value;
    phone2 = _.trim(phone2);

    let scDependencyToEdit = Object.assign({}, this.state.scDependencyToEdit);
    scDependencyToEdit.phone2 = phone2;
    if (phone2 === null || phone2.length === 0) {
      scDependencyToEdit.phone2 = null;
    }
    this.setState({ scDependencyToEdit });
  }

  handleIsVendingAllowedOnChange(e) {
    let isChecked = e.target.checked;
    let scDependency = Object.assign({}, this.state.scDependencyToEdit);

    if (isChecked) {
      scDependency.isVendingAllowed = 1;
    } else {
      scDependency.isVendingAllowed = 0;
    }
    this.setState({
      isVendingAllowed: isChecked,
      scDependencyToEdit: scDependency,
    });
  }

  handleIsProvisioningAllowedOnChange(e) {
    let isChecked = e.target.checked;
    let scDependency = Object.assign({}, this.state.scDependencyToEdit);

    if (isChecked) {
      scDependency.isProvisioningAllowed = 1;
    } else {
      scDependency.isProvisioningAllowed = 0;
    }
    this.setState({
      isProvisioningAllowed: isChecked,
      scDependencyToEdit: scDependency,
    });
  }

  handleSelect(event, name) {
    const { value, label } = event;
    const { scDependencyToEdit } = this.state;

    switch (name.name) {
      case "soDependencyType":
        scDependencyToEdit.soDependencyId = 2;
        this.setState({
          selectedSoDependencyTypeId: value,
          soDependencyTypeName: label,
          selectedSoDependencyId: null,
          scDependencyToEdit: scDependencyToEdit,
        });

        if (value === 1) {
          // soDependencyTypeId = 1 - Headquarter
          this.setState({
            showHeadQuarters: true,
            showLogisticCenters: false,
          });
        } else if (value === 2) {
          // soDependencyTypeId = 2 - Logistic center
          this.setState({
            showHeadQuarters: false,
            showLogisticCenters: true,
          });
        }
        break;

      case "headquarter": // soDependencyType 1
        // onSoDependencyChange(event, htmlTagSoDependency);
        scDependencyToEdit.soDependencyId = value;
        this.setState({
          selectedSoDependencyId: value,
          scDependencyToEdit: scDependencyToEdit,
        });
        break;

      case "logisticCenter": // soDependencyType 2
        // onSoDependencyChange(event, htmlTagSoDependency);
        scDependencyToEdit.soDependencyId = value;
        this.setState({
          selectedSoDependencyId: value,
          scDependencyToEdit: scDependencyToEdit,
        });
        break;

      default:
    }
  }

  editScDependencyHandleChange(event) {
    this.setState({ loadingSave: true });

    let name = this.state.scDependencyToEdit.name != null;
    let uniqueIdentifier = this.state.scDependencyToEdit.uniqueIdentifier != null;
    let scDependencyTypeId = this.state.scDependencyToEdit.scDependencyTypeId != null;
    let countryId = this.state.scDependencyToEdit.countryId != null;
    let provinceId = this.state.scDependencyToEdit.provinceId != null;
    let cityId = this.state.scDependencyToEdit.cityId != null;
    let address = this.state.scDependencyToEdit.address != null;
    let phone1 = this.state.scDependencyToEdit.phone1 != null;
    let phone2 = this.state.scDependencyToEdit.phone2 != null;
    let isVendingAllowed = this.state.scDependencyToEdit.isVendingAllowed != null;
    let isProvisioningAllowed = this.state.scDependencyToEdit.isProvisioningAllowed != null;
    let soDependencyId = this.state.soDependencyId != null;

    if (name && uniqueIdentifier && scDependencyTypeId && countryId && isVendingAllowed && isProvisioningAllowed && soDependencyId) {
      this.props.editScDependency(this.state.scDependencyToEdit, (response, error) => {
        if (!response.data && String(response).includes("Error:")) {
          //Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data.code === 4008) {
          //Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          //Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulCreation,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.handleOk,
          });
        }
        this.setState({ loadingSave: false });
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
      this.setState({ loadingSave: false });
    }
  }

  handleOkNoScDependencyWarning = () => {
    this.setState({
      redirectBackToScDependency: false,
    });
  };

  noScDependencyWarning() {
    Modal.warning({
      title: Strings.soDependency.noDirectClientSelected,
      content: Strings.soDependency.noDirectClientSelectedMsg,
      onOk: this.handleOkNoScDependencyWarning,
    });
  }

  handleOk = () => {
    this.setState({
      redirectBackToScDependency: false,
    });
  };

  render() {
    if (this.state.redirectBackToScDependency === false) {
      // Redirects to listScDependency from Modal Warning
      return <Redirect to="/listScDependency" />;
    }

    if (!this.state.soDependencyId) {
      // Shows No ScDependency Modal Warning
      return <div>{this.noScDependencyWarning()}</div>;
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.scDependency.editScDependency} tooltip={Strings.scDependency.editScDependencyTooltip} />
        </div>

        <div className="row">
          {this.state.soDependencyType0Id ? (
            <div className="vertSpace col-md-6">
              <Divider orientation="left">
                <h3>{Strings.soDependency.directClient} </h3>
              </Divider>
              <h3>{this.state.soDependencyType0Name}</h3>
            </div>
          ) : null}
          {this.state.soDependencyNotType0Id ? (
            <div className="vertSpace col-md-6">
              <Divider orientation="left">
                <h3>{Strings.soDependency.soDependencyTypeDefinition} </h3>
              </Divider>
              <h3>
                {this.state.soDependencyTypeName ? `${this.state.soDependencyTypeName}:` : ""} {this.state.soDependencyNotType0Name}{" "}
              </h3>
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="vertSpace col-md-6">
            <Divider orientation="left">
              <h3> {Strings.generalTerms.generalParameters}</h3>
            </Divider>
          </div>
        </div>

        <Spin tip={Strings.generalTerms.loading} spinning={this.state.loading}>
          <div className="row">
            <div className="vertSpace col-md-6">
              <GeneralInput
                text={Strings.generalTerms.name}
                tooltip={Strings.scDependency.scDependencyNameToolTip}
                placeholder={Strings.generalTerms.name}
                onChange={(e) => this.nameInputHandleChange(e)}
                value={this.state.scDependencyToEdit.name}
                isReq={true}
              />
            </div>

            <div className="vertSpace col-md-6">
              <GeneralInput
                text={Strings.scDependency.nit}
                tooltip={Strings.scDependency.nitTooltip}
                isReq
                placeholder={Strings.scDependency.nitTooltip}
                onChange={(e) => this.nitInputHandleChange(e)}
                value={this.state.scDependencyToEdit.uniqueIdentifier}
              />
            </div>

            {/*<div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.scDependency.scDependencyType}
              options={this.state.scDependencyTypeList}
              isReq={true}
              defaultValue={this.state.scDependencyToEdit.scDependencyTypeId}
              placeholder={Strings.scDependency.scDependencyType}
              onChange={(e) => this.scDependencyTypeInputEvent(e)}
            />
        </div>*/}

            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.generalTerms.country}
                options={this.props.countries}
                isReq
                defaultValue={this.state.scDependencyToEdit.countryId}
                placeholder={Strings.generalTerms.country}
                onChange={(e) => this.countryInputEvent(e)}
                disabled={true}
              />
            </div>

            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.generalTerms.province}
                options={this.state.provinces}
                defaultValue={this.state.scDependencyToEdit.provinceId}
                placeholder={Strings.generalTerms.province}
                onChange={(e) => this.provinceInputEvent(e)}
              />
            </div>

            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.generalTerms.city}
                options={this.state.cities}
                defaultValue={this.state.scDependencyToEdit.cityId}
                placeholder={Strings.generalTerms.city}
                onChange={(e) => this.cityInputEvent(e)}
              />
            </div>
            <div className="vertSpace col-md-6">
              <GeneralInput
                text={Strings.generalTerms.address}
                value={this.state.scDependencyToEdit.address}
                placeholder={Strings.generalTerms.address}
                onChange={(e) => this.addressInputEvent(e)}
              />
            </div>

            <div className="vertSpace col-md-6">
              <GeneralInput
                text={Strings.users.phone1}
                value={this.state.scDependencyToEdit.phone1}
                placeholder={Strings.users.phone1}
                onChange={(e) => this.phone1InputEvent(e)}
              />
            </div>
            <div className="vertSpace col-md-6">
              <GeneralInput
                text={Strings.users.phone2}
                isReq={false}
                value={this.state.scDependencyToEdit.phone2}
                placeholder={Strings.users.phone2}
                onChange={(e) => this.phone2InputEvent(e)}
              />
            </div>
          </div>

          <div className="row">
            <div className="vertSpace col-md-6">
              <GeneralCheckbox
                text={Strings.scDependency.isVendingAllowed}
                tooltip={Strings.scDependency.isVendingAllowed}
                isChecked={this.state.isVendingAllowed}
                onChange={(e) => this.handleIsVendingAllowedOnChange(e)}
              />
            </div>
            <div className="vertSpace col-md-6">
              <GeneralCheckbox
                text={Strings.scDependency.isProvisioningAllowed}
                tooltip={Strings.scDependency.isProvisioningAllowed}
                isChecked={this.state.isProvisioningAllowed}
                onChange={(e) => this.handleIsProvisioningAllowedOnChange(e)}
              />
            </div>
          </div>

          {this.state.scSoDependencyId === 2 && (
            <div className="row">
              <div className="vertSpace col-md-6">
                <SelectGeneral
                  name="soDependencyType"
                  text={Strings.generalTerms.level}
                  // tooltip={Strings.users.userSoLevel}
                  options={this.state.soDependencyTypes}
                  defaultValue={this.state.selectedSoDependencyTypeId}
                  isReq
                  isLoading={this.state.isDataLoading}
                  disabled={this.state.isDataLoading}
                  onChange={(e, n) => this.handleSelect(e, n)}
                />
              </div>
              {this.state.showHeadQuarters ? (
                <div className="vertSpace col-md-6">
                  <SelectGeneral
                    text={this.state.soDependencyTypeName}
                    name="headquarter"
                    options={this.state.headQuarters}
                    defaultValue={this.state.selectedSoDependencyId}
                    disabled={this.state.isDataLoading}
                    isReq
                    onChange={(e, n) => this.handleSelect(e, n)}
                  />
                </div>
              ) : null}
              {this.state.showLogisticCenters ? (
                <div className="vertSpace col-md-6">
                  <SelectGeneral
                    text={this.state.soDependencyTypeName}
                    name="logisticCenter"
                    options={this.state.logisticCenters}
                    defaultValue={this.state.selectedSoDependencyId}
                    disabled={this.state.isDataLoading}
                    isReq
                    onChange={(e, n) => this.handleSelect(e, n)}
                  />
                </div>
              ) : null}
            </div>
          )}
        </Spin>

        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link="/listScDependency" />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton onClick={(e) => this.editScDependencyHandleChange(e)} loading={this.state.loadingSave} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countries: state.countries,
    scdependencytype: state.scdependencytypeid,
  };
}

export default connect(mapStateToProps, {
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  listScDependencyType,
  editScDependency,
})(EditScDependency);
