import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/lockertype";

export const LIST_LOCKERTYPE  = 'LIST_LOCKERTYPE';

export function listLockerTypes(callback){
  return (dispatch) => {
    axios.get(host+ port + path + version + resource,{
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
      },
    })
    .then((response) => {
      dispatch( { type: LIST_LOCKERTYPE, payload: response.data } );
      callback(response);
    })
    .catch(function (error){
      callback(error)
      return error;
    });
  }
    }