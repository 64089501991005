import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Server URL
const resource = "/soproductslottypes";

export const LIST_SOPRODUCTSLOTTYPE = 'LIST_SOPRODUCTSLOTTYPE'
export const ERROR = 'ERROR';

export function listSoProductSlotTypeByScProductInformationId(scProductId) {
  return (dispatch) => {
    axios.get(host + port + path + version + resource, {
      params: {
        scproductid: scProductId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        dispatch({ type: LIST_SOPRODUCTSLOTTYPE, payload: response.data });
      })
      .catch(function (error) {
        return error;
      });
  }
}

/*
Function to get soProductSlotTye by soProductId
Views:
soProductSlotType
*/
export function listSoProductSlotTypeBySoProductId(soProductId, callback){
  return () => {
    axios.get(host+ port + path  + version + resource, {
      params : {
        soproductid: soProductId,
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      callback(error);
    });
  }
}

export function listSoProductSlotTypeBySoDependencyId(soDependencyId, callback) {
  return (dispatch) => {
    axios.get(host + port + path + version + resource, {
      params: {
        sodependencyid: soDependencyId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        dispatch({ type: LIST_SOPRODUCTSLOTTYPE, payload: response.data });
      })
      .catch(function (error) {
        callback(error)
        return error;
      });
  }
}

export function addSoProductSlotTypeAction(soProductSlotType, callback) {
  return (dispatch) => {
    let data = JSON.stringify(soProductSlotType)
    axios.post(host + port + path + version + resource, data, {
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
      .then((response) => {
        callback(response)
      })
      .catch(function (error) {
        callback(error)
      });
  }
}

export function deleteSoProductSlotTypeAction(soProductSlotTypeId, callback) {
  return (dispatch) => {
    axios.delete(host + port + path + version + resource, {
      params: {
        id: soProductSlotTypeId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
      .then((response) => {
        callback(response)
      })
      .catch((error) => {
        callback(error)
      });
  }
}