import { 
  USER_SESSION,
  LIST_USER, 
  LIST_USERS, 
  ERROR, 
  LIST_USERSWITHOUTDISPRULE, 
  LIST_USERSBYVENDINGMACHINE,
} from '../actions'

const initialState = {
  userSessionObj: [],
  userObj: [],
  errorObj: [],
  permissionsObj: [],
  userDependenciesObj: [],
  userWithoutDispRuleObj: [],
  userVendingMachineObj:[],
  unassignedVendingMachineUser: []
}


export function users(state = [], action) {
  switch (action.type) {

    case USER_SESSION:
      return Object.assign({}, state, {userSessionObj: action.payload})

    case LIST_USERS:
      return Object.assign({}, state, {userDependenciesObj: action.payload})
    case LIST_USERSWITHOUTDISPRULE:
      return Object.assign({}, state, {userWithoutDispRuleObj: action.payload})
    case LIST_USERSBYVENDINGMACHINE:
      return Object.assign({}, state, {userVendingMachineObj: action.payload})
    case "SWITCH_DEPENDENCY":
    let permissions = [];
      switch (action.payload){
        case "Arme":
          permissions=["listUsers", "assignContracts", "PRIVILEGE_STOCKCUSTOMERMENUCONTAINER_VISIBILITY"]
        break;
        case "Sumatec":
          permissions=["listClients", "addProducts","listVendingMachines"]
        break; 
        default: 
      }
      return Object.assign({}, state, {permissionsObj: permissions})
    case ERROR:
      return Object.assign({}, state, {errorObj: action.payload})
    default:
      return state
  }
}

export function user(state = initialState, action) {

  switch (action.type) {
    case LIST_USER:
      return Object.assign({}, state, {userObj: action.payload})
    default:
      return state
  }
}
