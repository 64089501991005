import React, { Component } from 'react';
import '../Global/css/page404.css';

class error401 extends Component {
  render() {
    return (
      <div className="warning-container">
        <h2 className="header">401</h2>
        <p className="error">Error - No Authorizado</p>
        <hr />
        <div className="bottom-content">
          <span>
            <p className="check-url">Por favor comprueba las Credenciales.</p>
            <p className="otherwise">
              De lo contrario, <a href="/">haz clic aquí</a> para ser redirigido a la página de inicio.
            </p>
          </span>
        </div>
      </div>
    );
  }
}

export default error401;
