// Dependencies
import React from "react";
import { Route, Switch } from "react-router";

// Components
import App from "./App";

import Home from "./components/Home/Home";

// MENU BAR
// User Menu
import ListExecutiveUsers from "./components/Users/ExecutiveUsers/ListExecutiveUsers";
import AddExecutiveUser from "./components/Users/ExecutiveUsers/AddExecutiveUser";
import EditExecutiveUser from "./components/Users/ExecutiveUsers/EditExecutiveUser";

import ListClientUsers from "./components/Users/ClientUsers/ListClientUsers";
import AddClientUser from "./components/Users/ClientUsers/AddClientUser";
import EditClientUser from "./components/Users/ClientUsers/EditClientUser";

import UsersByVendingMachine from "./components/Users/UsersToVendingMachine/UsersByVendingMachine";
import AssignUsersToVendingMachine from "./components/Users/UsersToVendingMachine/assignUsersToVendingMachine";

import ListCreditByUser from "./components/Users/CreditByUser/ListCreditByUser";
import AddCreditByUser from "./components/Users/CreditByUser/AddCreditByUser";
import EditCreditByUser from "./components/Users/CreditByUser/EditCreditByUser";

import ListLockerLoanExceptionByUser from "./components/Users/LockerLoanExceptionByUser/listLockerLoanExceptionByUser";
import AddLockerLoanExceptionByUser from "./components/Users/LockerLoanExceptionByUser/addLockerLoanExceptionByUser";
import EditLoanExceptionByUser from "./components/Users/LockerLoanExceptionByUser/editLockerLoanExceptionByUser";

// Vending Machine submenu
import ListVM from "./components/VendingMachines/Machines";
import CreateVendingMachine from "./components/VendingMachines/createVendingMachine";
import VendingMachineInfo from "./components/VendingMachines/VendingMachineInfo";
import VendingMachineInventory from "./components/VendingMachineInventory/VendingMachineInventory";
import VmProductSlot from "./components/Products/Planogram/vmProductSlot";
import VmEventList from "./components/VmEvents/VmEventList";
import vmFinanceAuditingInfo from "./components/VendingMachines/VmFinanceAuditing/vmFinanceAuditingInfo";

// Dispensation Rules subMenu
import DispensationRules from "./components/DispensationsRules/DispensationRules";
import AddDispensationRules from "./components/DispensationsRules/AddDispensationRules";
import EditDispensationRules from "./components/DispensationsRules/EditDispensationRule";

import ListDispensationRuleByUser from "./components/Users/DispensationRuleByUser/listDispensationRuleByUser";
import AssignDispensationRulesToUser from "./components/Users/DispensationRuleByUser/assignDispensationRulesToUser";

import ListVmProductGroups from "./components/Products/vmProductGroup/ListVmProductGroup";
import AddVmProductGroup from "./components/Products/vmProductGroup/AddVmProductGroup";
import EditVmProductGroup from "./components/Products/vmProductGroup/EditVmProductGroup";

// Products submenu
import ListSoProducts from "./components/Products/SoProduct/ListSoProducts";
import AddSoProduct from "./components/Products/SoProduct/AddSoProduct";
import EditSoProduct from "./components/Products/SoProduct/EditSoProduct";

import ScProductInformation from "./components/Products/scProductInfo/ScProductInformation";
import AssignScProductInformation from "./components/Products/scProductInfo/AssignScProductInformation";
import EditScProductInformation from "./components/Products/scProductInfo/EditScProductInformation";

import SoProductSlotType from "./components/Products/SoProductSlotType/soProductSlotType";
import AddSoProductSlotTypeForm from "./components/Products/SoProductSlotType/addSoProductSlotType";

// Indirect client
import ListScDependency from "./components/ScDependency/listScDependency";
import AddScDependency from "./components/ScDependency/addScDependency";
import EditScDependency from "./components/ScDependency/editScDependency";

import ListScEmployeeInformation from "./components/ScEmployeeInformation/listScEmployeeInformation";
import AddScEmployeeInformation from "./components/ScEmployeeInformation/addScEmployeeInformation";
import EditScEmployeeInformation from "./components/ScEmployeeInformation/editScEmployeeInformation";

// Transactions
import UnderstockedTransactionAdjusment from "./components/transactions/transactionsadjusment/UnderstockedTransactionAdjusmentComponent";

import ListAdjustmentReports from "./components/Reports/ListAdjustmentReports";

import ListProvisioningInfoByVM from "./components/Reports/ListProvisioningInfoByVM";

import TransactionsReports from "./components/transactions/transactionsreport/TransactionsReport";

import TransactionsMonitor from "./components/transactions/transactionsmonitor/TransactionsMonitor";

import LockerProductsReport from "./components/Reports/LockerProductsReport/LockerProductsReport";

// Communications
import CommunicationsMonitor from "./components/Communications/CommunicationsMonitor";

// Peripheral device
import PeripheralDevice from "./components/peripheraldevice/PeripheralDevice";
import AddPeripheralDevice from "./components/peripheraldevice/AddPeripheralDevice";

// Firmware Version
import ListFirmwareVersion from "./components/FirmwareVersion/ListFirmwareVersion";
import EditFirmwareVersion from "./components/FirmwareVersion/EditFirmwareVersion";
import AddFirmwareVersion from "./components/FirmwareVersion/AddFirmwareVersion";

// Vm Service Request
import ListVmServiceRequest from "./components/VmServiceRequest/ListVmServiceRequest";

// Privilege and Role
import PrivilegeByRole from "./components/PrivilegeAndRole/PrivilegeByRole/privilegeByRole";
import AddRoleAndPrivilegeAssignment from "./components/PrivilegeAndRole/PrivilegeByRole/addRoleAndPrivilegesAssignment";
import EditRoleAndPrivilegeAssignment from "./components/PrivilegeAndRole/PrivilegeByRole/editRoleAndPrivilegeAssigment";

// HEADER BAR
// MyAccount
import MyAccountInfo from "./components/account/MyAccount/myAccountInfo";
import AccountConfiguration from "./components/account/AccountConfigurationContainer";

import UsersByRole from "./components/Users/UsersToRole/usersByRole";

//Smart Vending Clinical
import ListPatientInformation from "./components/SmartVendingClinical/PatientsInformation/ListPatientInformation";
import PatientInformationForm from "./components/SmartVendingClinical/PatientsInformation/PatientInformationForm";

////Locations
import ListLocations from "./components/SmartVendingClinical/Locations/ListLocations";
import FormLocations from "./components/SmartVendingClinical/Locations/FormLocations";

// Error interface
import error401 from "./components/Errors/error401";
import error404 from "./components/Errors/error404";
import error500 from "./components/Errors/error500";


export const AppRoutes = () => (
  <App>
    <Switch>
      <Route exact path="/" component={Home} />

      {/* Users Routes */}
      <Route exact path="/listExecutiveUsers" component={ListExecutiveUsers} />
      <Route exact path="/addExecutiveUser" component={AddExecutiveUser} />
      <Route exact path="/editExecutiveUser" component={EditExecutiveUser} />

      <Route exact path="/listClientUsers" component={ListClientUsers} />
      <Route exact path="/addClientUser" component={AddClientUser} />
      <Route exact path="/editClientUser" component={EditClientUser} />

      <Route exact path="/UsersByVendingMachine" component={UsersByVendingMachine} />
      <Route exact path="/assignUsersToVendingMachine" component={AssignUsersToVendingMachine} />

      <Route exact path="/listCreditByUser" component={ListCreditByUser} />
      <Route exact path="/addCreditByUser" component={AddCreditByUser} />
      <Route exact path="/editCreditByUser" component={EditCreditByUser} />

      <Route exact path="/listLockerLoanExceptionByUser" component={ListLockerLoanExceptionByUser} />
      <Route exact path="/addLockerLoanExceptionByUser" component={AddLockerLoanExceptionByUser} />
      <Route exact path="/editLockerLoanExceptionByUser" component={EditLoanExceptionByUser} />

      {/* Vending Machine Routes */}
      <Route exact path="/listVM" component={ListVM} />
      <Route exact path="/createVM" component={CreateVendingMachine} />
      <Route exact path="/vminfo" component={VendingMachineInfo} />
      <Route exact path="/vendingMachineInventory" component={VendingMachineInventory} />
      <Route exact path="/vmProductSlot" component={VmProductSlot} />
      <Route exact path="/vmEventList" component={VmEventList} />
      <Route exact path="/vmFinanceAuditingInfo" component={vmFinanceAuditingInfo} />

      {/* Dispensation Rules Routes */}
      <Route exact path="/dispensationRules" component={DispensationRules} />
      <Route exact path="/addDispensationRules" component={AddDispensationRules} />
      <Route exact path="/editDispensationRules" component={EditDispensationRules} />

      <Route exact path="/listDispensationRuleByUser" component={ListDispensationRuleByUser} />
      <Route exact path="/assignDispensationRulesToUser" component={AssignDispensationRulesToUser} />

      <Route exact path="/listVmProductGroups" component={ListVmProductGroups} />
      <Route exact path="/addVmProductGroup" component={AddVmProductGroup} />
      <Route exact path="/editVmProductGroup" component={EditVmProductGroup} />

      {/* Products Routes */}
      <Route exact path="/listSoProducts" component={ListSoProducts} />
      <Route exact path="/addSoProduct" component={AddSoProduct} />
      <Route exact path="/editSoProduct" component={EditSoProduct} />

      <Route exact path="/scProductInformation" component={ScProductInformation} />
      <Route exact path="/AssignScProductInformation" component={AssignScProductInformation} />
      <Route exact path="/EditScProductInformation" component={EditScProductInformation} />

      <Route exact path="/soProductSlotType" component={SoProductSlotType} />
      <Route exact path="/addSoProductSlotType" component={AddSoProductSlotTypeForm} />

      {/* Indirect client */}
      <Route exact path="/listScDependency" component={ListScDependency} />
      <Route exact path="/addScDependency" component={AddScDependency} />
      <Route exact path="/editScDependency" component={EditScDependency} />

      <Route exact path="/listScEmployeeInformation" component={ListScEmployeeInformation} />
      <Route exact path="/addScEmployeeInformation" component={AddScEmployeeInformation} />
      <Route exact path="/editScEmployeeInformation" component={EditScEmployeeInformation} />

      {/* Transactions */}
      <Route exact path="/transactionsAdjustment" component={UnderstockedTransactionAdjusment} />
      <Route exact path="/adjustmentReports" component={ListAdjustmentReports} />
      <Route exact path="/listProvisioningInfoByVM" component={ListProvisioningInfoByVM} />
      <Route exact path="/transactionsReport" component={TransactionsReports} />
      <Route exact path="/TransactionsMonitor" component={TransactionsMonitor} />
      <Route exact path="/lockerProductsReport" component={LockerProductsReport} />

      {/* Peripheral device */}
      <Route exact path="/peripheralDevice" component={PeripheralDevice} />
      <Route exact path="/addPeripheralDevice" component={AddPeripheralDevice} />

      {/* Firmware Version */}
      <Route exact path="/listFirmwareVersion" component={ListFirmwareVersion} />
      <Route exact path="/editFirmwareVersion" component={EditFirmwareVersion} />
      <Route exact path="/addFirmwareVersion" component={AddFirmwareVersion} />

      {/* Vm Service Request */}
      <Route exact path="/listVmServiceRequest" component={ListVmServiceRequest} />

      {/* Privilege and role */}
      <Route exact path="/privilegeByRole" component={PrivilegeByRole} />
      <Route exact path="/addRoleAndAssignPrivileges" component={AddRoleAndPrivilegeAssignment} />
      <Route exact path="/editRoleAndPrivilegeAssignment" component={EditRoleAndPrivilegeAssignment} />

      {/* Communications */}
      <Route exact path="/communications" component={CommunicationsMonitor} />

      {/* My account */}
      <Route exact path="/myAccountInfo" component={MyAccountInfo} />

      {/* Account Settings */}
      <Route exact path="/account" component={AccountConfiguration} />

      <Route exact path="/usersByRole" component={UsersByRole} />
            
      {/* Smart Vending Clinical Routes */}
      <Route exact path="/listPatientInformation" component={ListPatientInformation}/>
      <Route exact path="/AddPatientInformation" component={PatientInformationForm}/>
      <Route exact path="/EditPatientInformation" component={PatientInformationForm}/>
      {/* Clinical Locations */}
      <Route exact path="/listLocations" component={ListLocations} />
      <Route exact path="/addLocations" component={FormLocations} />
      <Route exact path="/editLocations" component={FormLocations} />
      
      {/* Error Routes */}
      <Route exact path="/error401" component={error401} />
      <Route exact path="/error500" component={error500} />
      <Route component={error404} />
    </Switch>
  </App>
);
