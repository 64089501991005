// Dependencies
import { Divider, Modal } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Actions
import { editScProductInformation, listAllCurrencyCodesByCountry, listAllProductTaxTypeAction } from "../../../actions";

// Components
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import { GeneralInput } from "../../GenericComponents/InputComponent/generalInput";
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import NormalText from "../../GenericComponents/text/NormalText";
import Titles from "../../GenericComponents/titles";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

class EditScProductInformation extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      soDependency: {
        soDependencyId: location.props.scProductToEdit.soDependencyId ? location.props.scProductToEdit.soDependencyId : null,
        soDependencyName: location.props.scProductToEdit.soDependencyName ? location.props.scProductToEdit.soDependencyName : null,
      },
      scDependency: {
        scDependencyId: location.props.scProductToEdit.scDependencyId ? location.props.scProductToEdit.scDependencyId : null,
        scDependencyName: location.props.scProductToEdit.scDependencyName ? location.props.scProductToEdit.scDependencyName : null,
        scCountryName: location.props.scCountryName ? location.props.scCountryName : null,
        scCurrencyCodeName: location.props.scCurrencyCodeName ? location.props.scCurrencyCodeName : null,
      },
      soProduct: {
        id: location.props.scProductToEdit.soProductId,
        soProductName: location.props.scProductToEdit.soProductName ? location.props.scProductToEdit.soProductName : null,
        erpProductCode: location.props.scProductToEdit.erpProductCode ? location.props.scProductToEdit.erpProductCode : null,
        soProductTaxValueId: location.props.scProductToEdit.soProductTaxValueId,
        soTaxValue: location.props.scProductToEdit.soTaxValue,
        soCurrencyAlphaCode: location.props.scProductToEdit.soCurrencyAlphaCode,
        soCurrencySymbol: location.props.scProductToEdit.soCurrencySymbol,
        soCurrencyId: location.props.scProductToEdit.soCurrencyId,
        soCountryName: location.props.scProductToEdit.soCountryName,
        soPreTaxPrice: location.props.scProductToEdit.soPreTaxPrice,
      },
      scProduct: {
        id: location.props.scProductToEdit.scProductInformationId,
        scdependencyId: location.props.scProductToEdit.scDependencyId,
        soproductId: location.props.scProductToEdit.soProductId,
        scProductName: location.props.scProductToEdit.scProductName ? location.props.scProductToEdit.scProductName : null,
        scProductCode: location.props.scProductToEdit.scProductCode ? location.props.scProductToEdit.scProductCode : null,
        scCurrencySymbol: location.props.scProductToEdit.scCurrencySymbol,
        scCurrencyId: location.props.scProductToEdit.scCurrencyId,
        scCountryName: location.props.scProductToEdit.scCountryName,
        scCurrencyCode: location.props.scProductToEdit.scCurrencyAlphaCode,
        productTaxTypeId: location.props.scProductToEdit.scProductTaxValueId,
        preTaxPrice: location.props.scProductToEdit.scPreTaxPrice,
        newPreTaXprice: location.props.scProductToEdit.newPreTaXprice,
        closeDate: location.props.scProductToEdit.closeDate,
        minAgeRequired: location.props.scProductToEdit.scMinAgeRequired,
        isEnabled: 1,
      },
      loadingAdd: false,
      isSaveButtonDisabled: false,
      redirectBackToSoProd: false,
    };

    // Bind Modal button callback so View state variables can be used
    this.handleOkNoSOWarning = this.handleOkNoSOWarning.bind(this);
  }

  componentDidMount() {
    const { prodTaxType, listAllProductTaxTypeAction, listAllCurrencyCodesByCountry } = this.props;
    const { scProduct } = this.state;
    if (!prodTaxType || prodTaxType.length === 0) {
      listAllProductTaxTypeAction();
      listAllCurrencyCodesByCountry(scProduct.scCountryName);
    }
  }

  // --------Events--------
  textInputEvent(event) {
    const { scProduct } = this.state;
    const { id, value } = event.target;
    scProduct[id] = value;
    this.setState({
      scProduct,
    });
  }

  onTaxSelect(event, name) {
    const { scProduct } = this.state;
    scProduct[name.name] = event.value;
    this.setState({
      scProduct,
    });
  }

  editScProductHandleOnClick() {
    const { scProduct, soProduct } = this.state;
    if (scProduct.scCountryName == soProduct.soCountryName) {
      if (scProduct.preTaxPrice === "" || scProduct.preTaxPrice === undefined || scProduct.preTaxPrice === null) {
        scProduct.preTaxPrice = null;
        scProduct.productTaxTypeId = null;
        scProduct.scCurrencyCode = null;
        scProduct.scCurrencySymbol = null;
        scProduct.scCurrencyId = null;

        this.editScProductInformation(scProduct);
      } else {
        if (scProduct.productTaxTypeId === null || scProduct.productTaxTypeId === undefined || scProduct.scCurrencyCode === null || scProduct.scCurrencyCode === undefined) {
          Modal.error({
            title: Strings.generalResponses.invalidParameters,
            content: Strings.generalResponses.saveError3,
          });
        } else {
          this.editScProductInformation(scProduct);
        }
      }
    } else {
      if (
        scProduct.preTaxPrice === null ||
        scProduct.preTaxPrice === undefined ||
        scProduct.productTaxTypeId === null ||
        scProduct.productTaxTypeId === undefined ||
        scProduct.scCurrencyCode === null
      ) {
        Modal.error({
          title: Strings.generalResponses.invalidParameters,
          content: Strings.generalResponses.saveError2,
        });
      } else {
        this.editScProductInformation(scProduct);
      }
    }
  }

  editScProductInformation(scProductToEdit) {
    const { editScProductInformation } = this.props;
    const { scDependency } = this.state;

    this.setState({
      loadingAdd: true,
      isSaveButtonDisabled: true,
    });
    if (scProductToEdit.scCurrencyCode !== scDependency.scCurrencyCodeName) {
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: Strings.product.scCurrencyCodeErrorMsg4,
      });
      this.setState({ loadingAdd: false, isSaveButtonDisabled: false });
    } else {
      this.setState({
        scProduct: scProductToEdit,
      });
      editScProductInformation(scProductToEdit, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
        } else if (response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.handleOkNoSOWarning(true),
          });
        } else {
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        }
        this.setState({ loadingAdd: false, isSaveButtonDisabled: false });
      });
    }
  }

  noSoWarning() {
    Modal.warning({
      title: Strings.product.noProductSelected,
      onOk: this.handleOkNoSOWarning,
      content: Strings.product.noProductSelectedMsg,
    });
  }

  handleOkNoSOWarning() {
    this.setState({ redirectBackToSoProd: true });
  }

  onCurrencySelect = (currency) => {
    const { scProduct } = this.state;
    scProduct.scCurrencyId = currency.value;
    scProduct.scCurrencyCode = currency.alphaCode;
    scProduct.scCurrencySymbol = currency.symbol;

    this.setState({ scProduct });
  };

  render() {
    const { redirectBackToSoProd, scProduct, soProduct, scDependency, soDependency, loadingAdd, isSaveButtonDisabled } = this.state;
    const {
      permissions: { data },
      prodTaxType,
      currencyCodesByCountry,
    } = this.props;

    if (redirectBackToSoProd) {
      // Redirects to SoProduct from No SO Modal Warning
      return <Redirect to="/scProductInformation" />;
    }
    if (!scProduct || !scDependency.scDependencyId) {
      // Shows No SO Modal Warning
      return <div>{this.noSoWarning()}</div>;
    }
    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.product.editScProduct} tooltip={Strings.product.editScProductTooltip} />
        </div>

        <div className="row">
          <Divider orientation="left">
            <h3>{Strings.generalTerms.generalParameters} </h3>
          </Divider>
        </div>

        <div className="vertSpace row">
          <div className="col-md-12">
            <div className="  col-md-6">
              <div className="col-md-4">
                <span>
                  {Strings.soDependency.directClient}
                  <b>:</b>
                </span>
              </div>
              <div className="col-md-8">
                <h4>{soDependency.soDependencyName}</h4>
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-md-4">
                <span>
                  {Strings.scDependency.indirectClient}
                  <b>:</b>
                </span>
              </div>
              <div className="col-md-8">
                <h4>{scDependency.scDependencyName}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <Divider orientation="left">
            <h3>{Strings.product.soProduct} </h3>
          </Divider>
        </div>

        <div className="vertSpace row">
          <div className="col-md-6">
            <NormalText text={Strings.product.productName} name={soProduct.soProductName} isReq={false} />
          </div>
          <div className="col-md-6">
            <NormalText text={Strings.product.erpCode} name={soProduct.erpProductCode} isReq={false} />
          </div>
        </div>

        <div className="vertSpace row">
          <div className="col-md-6">
            <NormalText text={Strings.product.soPreTaxPrice} name={soProduct.soCurrencySymbol + `${soProduct.soPreTaxPrice} ` + soProduct.soCurrencyAlphaCode} isReq={false} />
          </div>
          <div className="col-md-6">
            <NormalText text={Strings.product.taxValue} name={soProduct.soTaxValue ? `${soProduct.soTaxValue}%` : "--"} isReq={false} />
          </div>
        </div>

        <div className="row">
          <Divider orientation="left">
            <h3>{Strings.product.scProductInfo} </h3>
          </Divider>
        </div>

        <div className="vertSpace row">
          <div className="col-md-6">
            <GeneralInput
              text={Strings.product.scProductCode}
              id="scProductCode"
              placeholder={Strings.product.scProductCode}
              onChange={(e) => this.textInputEvent(e)}
              value={scProduct.scProductCode}
            />
          </div>
          <div className="col-md-6">
            <GeneralInput
              text={Strings.product.scPreTaxPrice}
              id="preTaxPrice"
              placeholder={Strings.product.scPreTaxPrice}
              onChange={(e) => this.textInputEvent(e)}
              value={scProduct.preTaxPrice}
            />
          </div>
        </div>

        <div className="vertSpace row">
          <div className="col-md-6">
            <GeneralInput
              text={Strings.product.productName}
              id="scProductName"
              placeholder={Strings.product.productName}
              onChange={(e) => this.textInputEvent(e)}
              value={scProduct.scProductName}
            />
          </div>
          <div className="col-md-6">
            <SelectGeneral
              text={Strings.product.taxValue}
              name="productTaxTypeId"
              options={prodTaxType.filter((item) => item.countryName === scDependency.scCountryName)}
              onChange={(e, name) => this.onTaxSelect(e, name)}
              defaultValue={scProduct.productTaxTypeId}
            />
          </div>
        </div>
        <div className="vertSpace row">
          <div className="col-md-6">
            <SelectGeneral
              text={Strings.product.currency}
              name="currencyId"
              options={currencyCodesByCountry.filter((item) => item.alphaCode === scDependency.scCurrencyCodeName)}
              defaultValue={scProduct.scCurrencyId}
              isReq
              tooltip={Strings.product.currencyTooltip}
              onChange={(option) => this.onCurrencySelect(option)}
            />
          </div>
          <div className="col-md-6">
            <GeneralInput
              text={Strings.product.minAgeRequired}
              id="minAgeRequired"
              tooltip={Strings.product.minAgeRequiredTooltip}
              placeholder={Strings.product.minAgeRequired}
              onChange={(e) => this.textInputEvent(e)}
              disabled={ValidatePermissionForComponentPart("PRIVILEGE SET MINIMUM AGE REQUIRED", data) ? 0 : 1}
              value={scProduct.minAgeRequired}
            />
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="vertSpace col-6 col-md-3">
            <ReturnButton link="/scProductInformation" />
          </div>
          <div className="vertSpace col-6 col-md-3">
            <SaveButton onClick={(e) => this.editScProductHandleOnClick(e)} loading={loadingAdd} isDisabled={isSaveButtonDisabled} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    prodTaxType: state.productTaxType,
    currencyCodesByCountry: state.currencyCodeByCountry,
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  listAllProductTaxTypeAction,
  listAllCurrencyCodesByCountry,
  editScProductInformation,
})(EditScProductInformation);
