import { LIST_SCEMPLOYEEINFORMATION, ERROR } from '../actions'

const initialState = {
  scEmployeeInformationObj: [],
  errorObj: []
}

export function scEmployeeInformation(state = initialState, action) {

  switch (action.type) {
    case LIST_SCEMPLOYEEINFORMATION:
      return Object.assign({}, state, {scEmployeeInformation: action.payload})
    case ERROR:
      return Object.assign({}, state, {errorObj: action.payload})
    default:
      return state
  }
}
