// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Modal } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from "react-router-dom";
import _ from "lodash";

/// Actions
import {
  addScDependency,
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  listScDependencyType,
  listAllCurrencyCodesByCountryId,
  getScDependencyByUniqueIdentifierAndSoDependencyId,
} from "../../actions";

// Components
import Titles from "../GenericComponents/titles";
import { GeneralInput } from "../GenericComponents/InputComponent/generalInput";
import { ValidateButton, ReturnButton, SaveButton } from "../GenericComponents/buttons/index";
import { SelectGeneral } from "../GenericComponents/selectComponent/index";
import { GeneralCheckbox } from "../GenericComponents/checkBox/GeneralCheckBox";

// Language localization
import Strings from "../../systemVariables/languageStrings";

class AddScDependency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soDependencyType0Id: this.props.location.props ? this.props.location.props.soDependencyType0Id : null,
      soDependencyType0Name: this.props.location.props ? this.props.location.props.soDependencyType0Name : null,

      soDependencyTypeId: this.props.location.props ? this.props.location.props.soDependencyTypeId : null,
      soDependencyTypeName: this.props.location.props ? this.props.location.props.soDependencyTypeName : null,

      soDependencyNotType0Id: this.props.location.props ? this.props.location.props.soDependencyNotType0Id : null,
      soDependencyNotType0Name: this.props.location.props ? this.props.location.props.soDependencyNotType0Name : null,

      uniqueIdentifierToVerify: null,
      uniqueIdentifierValidated: false,
      loadingValidate: false,
      loadingSave: false,
      validationButtonDisabled: true,
      scDependencyValidated: false,

      scDependencyToAdd: {
        soDependencyId: this.props.location.props ? this.props.location.props.lastSoDependencyId : null,
        name: null,
        scDependencyTypeId: 0,
        uniqueIdentifier: null,
        phone1: null,
        phone2: null,
        countryId: null,
        provinceId: null,
        cityId: null,
        currencyCodeId: null,
        address: null,
        isVendingAllowed: 0,
        isProvisioningAllowed: 0,
      },

      redirectBackToScDependency: false, // Flag used to redirect to /listScDependency
      saveButtonIsDisabled: true,
      loading: false,
      scDependencyList: this.props.location.props ? this.props.location.props.scDependencyList : null,
      scDependencyTypeList: [],
    };
  }

  componentDidMount() {
    const { scDependencyToAdd } = this.state;

    if (scDependencyToAdd.soDependencyId !== null) {
      this.props.listScDependencyType((response) => {
        this.setState({
          scDependencyTypeList: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });

      this.props.listCountries();
    }
  }
  /// /////---------Events---------//////////////

  validateScDependencyExistEvent(event) {
    let scDependencyToVerify = _.trimStart(event.target.value);
    const scDependencyToAdd = { ...this.state.scDependencyToAdd };
    scDependencyToAdd.uniqueIdentifier = scDependencyToVerify;
    this.setState({
      scDependencyToAdd,
    });

    if (scDependencyToVerify.length === 0) {
      this.setState({ validationButtonDisabled: true });
      scDependencyToVerify = null;
    } else {
      this.setState({ validationButtonDisabled: false });
    }

    this.setState({
      uniqueIdentifierToVerify: scDependencyToVerify,
    });
  }

  onVerifyScDependencyExistEvent(event) {
    if (!event.key) {
      this.verifyScDependencyExist();
    }
    if (event.key === "Enter") {
      this.verifyScDependencyExist();
    }
  }

  verifyScDependencyExist() {
    this.setState({
      loadingValidate: true,
    });
    this.props.getScDependencyByUniqueIdentifierAndSoDependencyId(this.state.uniqueIdentifierToVerify, this.state.scDependencyToAdd.soDependencyId, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.serverError,
        });
        this.setState({
          validationButtonDisabled: false,
        });
      } else if (response.data.code === 4007) {
        this.setState({
          scDependencyValidated: true,
          uniqueIdentifierToVerify2: this.state.uniqueIdentifierToVerify,
          validationButtonDisabled: true,
        });
      } else {
        Modal.info({
          title: Strings.scDependency.scDependencyExists,
        });
        this.setState({
          validationButtonDisabled: false,
        });
      }
      this.setState({
        loadingValidate: false,
      });
    });
  }

  nameInputHandleChange(event) {
    const scDependency = { ...this.state.scDependencyToAdd };
    scDependency.name = _.trimStart(event.target.value);
    this.setState({ scDependencyToAdd: scDependency });
  }

  scDependencyTypeInputEvent(event) {
    const scDependencyTypeId = event.value;
    const scDependency = { ...this.state.scDependencyToAdd };

    if (scDependencyTypeId === null) {
      scDependency.scDependencyTypeId = null;
    } else {
      scDependency.scDependencyTypeId = scDependencyTypeId;
    }
    this.setState({ scDependencyToAdd: scDependency });
  }

  countryInputEvent(event) {
    const countryId = event.value;
    const scDependencyToAdd = { ...this.state.scDependencyToAdd };
    scDependencyToAdd.provinceId = null;
    scDependencyToAdd.currencyCodeId = null;
    if (countryId === null) {
      scDependencyToAdd.countryId = null;
    } else {
      scDependencyToAdd.countryId = countryId;
      this.props.listProvincesByCountryId(countryId, (response) => {
        this.setState({
          provinces: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      this.props.listAllCurrencyCodesByCountryId(countryId, (response) => {
        this.setState({
          currencyCodes: response.data.data.map((item) => ({
            value: item.id,
            label: item.currencyAlphaCode,
          })),
        });
      });
    }
    this.setState({
      scDependencyToAdd,
    });

    if (countryId !== null) {
      this.setState({
        isDisabledProvince: true,
      });
    } else {
      this.setState({
        isDisabledProvince: false,
      });
    }
  }

  provinceInputEvent(event) {
    const provinceId = event.value;
    const scDependencyToAdd = { ...this.state.scDependencyToAdd };
    scDependencyToAdd.cityId = null;
    if (provinceId === null) {
      scDependencyToAdd.provinceId = null;
    } else {
      scDependencyToAdd.provinceId = provinceId;
      this.props.listCitesByProvincesId(provinceId, (response) => {
        this.setState({
          cities: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }
    this.setState({
      scDependencyToAdd,
    });

    if (provinceId !== null) {
      this.setState({
        isDisabledCity: true,
      });
    } else {
      this.setState({
        isDisabledCity: false,
      });
    }
  }

  cityInputEvent(event) {
    const cityId = event.value;
    const scDependencyToAdd = { ...this.state.scDependencyToAdd };

    if (cityId === null) {
      scDependencyToAdd.cityId = null;
    } else {
      scDependencyToAdd.cityId = cityId;
    }

    this.setState({
      scDependencyToAdd,
    });
  }

  currencyCodeInputEvent(event) {
    const currencyCodeId = event.value;
    const scDependencyToAdd = { ...this.state.scDependencyToAdd };

    scDependencyToAdd.currencyCodeId = currencyCodeId;

    this.setState({
      scDependencyToAdd,
    });
  }

  addressInputEvent(event) {
    let address = event.target.value;
    address = _.trim(address);

    const scDependencyToAdd = { ...this.state.scDependencyToAdd };
    scDependencyToAdd.address = address;

    if (address.length === 0 || address === null) {
      scDependencyToAdd.address = null;
    }
    this.setState({
      scDependencyToAdd,
    });
  }

  phone1InputEvent(event) {
    let phone1 = event.target.value;
    phone1 = _.trim(phone1);

    const scDependencyToAdd = { ...this.state.scDependencyToAdd };
    scDependencyToAdd.phone1 = phone1;

    if (phone1.length === 0 || phone1 === null) {
      scDependencyToAdd.phone1 = 0;
    } else {
      scDependencyToAdd.phone1 = phone1;
    }
    this.setState({
      scDependencyToAdd,
    });
  }

  phone2InputEvent(event) {
    let phone2 = event.target.value;
    phone2 = _.trim(phone2);

    const scDependencyToAdd = { ...this.state.scDependencyToAdd };
    scDependencyToAdd.phone2 = phone2;
    if (phone2 === null || phone2.length === 0) {
      scDependencyToAdd.phone2 = null;
    }
    this.setState({
      scDependencyToAdd,
    });
  }

  handleIsVendingAllowedOnChange(e) {
    const isChecked = e.target.checked;
    const scDependency = { ...this.state.scDependencyToAdd };

    if (isChecked) {
      scDependency.isVendingAllowed = 1;
    } else {
      scDependency.isVendingAllowed = 0;
    }
    this.setState({
      isVendingAllowed: isChecked,
      scDependencyToAdd: scDependency,
    });
  }

  handleIsProvisioningAllowedOnChange(e) {
    const isChecked = e.target.checked;
    const scDependency = { ...this.state.scDependencyToAdd };

    if (isChecked) {
      scDependency.isProvisioningAllowed = 1;
    } else {
      scDependency.isProvisioningAllowed = 0;
    }
    this.setState({
      isProvisioningAllowed: isChecked,
      scDependencyToAdd: scDependency,
    });
  }

  addScDependencyHandleChange(event) {
    this.setState({
      loadingSave: true,
    });
    const { soDependencyId } = this.state.scDependencyToAdd;
    const name = this.state.scDependencyToAdd.name != null;
    const uniqueIdentifier = this.state.scDependencyToAdd.uniqueIdentifier != null;
    const countryId = this.state.scDependencyToAdd.countryId != null;
    const currencyCodeId = this.state.scDependencyToAdd.currencyCodeId != null;
    const provinceId = this.state.scDependencyToAdd.provinceId != null;
    const cityId = this.state.scDependencyToAdd.cityId != null;
    const address = this.state.scDependencyToAdd.address != null;
    const phone1 = this.state.scDependencyToAdd.phone1 != null;
    const isVendingAllowed = this.state.scDependencyToAdd.isVendingAllowed != null;
    const isProvisioningAllowed = this.state.scDependencyToAdd.isProvisioningAllowed != null;

    if (name && uniqueIdentifier && countryId && currencyCodeId && isVendingAllowed && isProvisioningAllowed) {
      this.props.addScDependency(this.state.scDependencyToAdd, (response, error) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulCreation,
            content: Strings.generalResponses.creationSuccess,
          });

          const scDependencyToAdd = { ...this.state.scDependencyToAdd };
          scDependencyToAdd.name = null;
          scDependencyToAdd.uniqueIdentifier = null;
          scDependencyToAdd.phone1 = null;
          scDependencyToAdd.phone2 = null;
          scDependencyToAdd.countryId = null;
          scDependencyToAdd.currencyCodeId = null;
          scDependencyToAdd.provinceId = null;
          scDependencyToAdd.cityId = null;
          scDependencyToAdd.address = null;
          scDependencyToAdd.isVendingAllowed = 0;
          scDependencyToAdd.isProvisioningAllowed = 0;

          this.setState({
            scDependencyAddedMessage: response,
            validateScDendencyEnabled: false,
            scDependencyValidated: false,
            uniqueIdentifierToVerify: null,
            uniqueIdentifierToVerify2: null,
            scDependencyToAdd,
          });
        }
        this.setState({
          loadingSave: false,
        });
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
      this.setState({
        loadingSave: false,
      });
    }
  }

  handleOkNoScDependencyWarning = () => {
    this.setState({ redirectBackToScDependency: true });
  };

  noScDependencyWarning() {
    Modal.warning({
      title: Strings.soDependency.noDirectClientSelected,
      content: Strings.soDependency.noDirectClientSelectedMsg,
      onOk: this.handleOkNoScDependencyWarning,
    });
  }

  render() {
    if (this.state.redirectBackToScDependency) {
      // Redirects to listScDependency from Modal Warning
      return <Redirect to="/listScDependency" />;
    }

    if (!this.state.scDependencyToAdd.soDependencyId || !this.state.scDependencyList) {
      // Shows No ScDependency Modal Warning
      return <div>{this.noScDependencyWarning()}</div>;
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.scDependency.addScDependency} tooltip={Strings.scDependency.addScDependencyTooltip} />
        </div>

        <div className="row">
          {this.state.soDependencyType0Id ? (
            <div className="vertSpace col-md-6">
              <Divider orientation="left">
                <h3>{Strings.soDependency.directClient} </h3>
              </Divider>
              <h3>{this.state.soDependencyType0Name}</h3>
            </div>
          ) : null}
          {this.state.soDependencyNotType0Id ? (
            <div className="vertSpace col-md-6">
              <Divider orientation="left">
                <h3>{Strings.soDependency.soDependencyTypeDefinition} </h3>
              </Divider>
              <h3>
                {this.state.soDependencyTypeName ? `${this.state.soDependencyTypeName}:` : ""} {this.state.soDependencyNotType0Name}{" "}
              </h3>
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="vertSpace col-md-6">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.validation} </h3>
            </Divider>
          </div>
          <div className="col-12">
            <div className="col-md-3" />
            <div className="col-xs-12 col-lg-6">
              <GeneralInput
                tooltip={Strings.scDependency.validationTooltip}
                text={Strings.scDependency.validation}
                isReq
                defaultValue={Strings.scDependency.nit}
                onChange={(e) => this.validateScDependencyExistEvent(e)}
                onKeyPress={(e) => this.onVerifyScDependencyExistEvent(e)}
                disabled={this.state.scDependencyValidated}
                value={this.state.uniqueIdentifierToVerify}
                placeholder={Strings.scDependency.nit}
              />
              <br />
              <ValidateButton
                disabled={this.state.validationButtonDisabled}
                loading={this.state.loadingValidate}
                autoFocus
                onClick={(e) => this.onVerifyScDependencyExistEvent(e)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-md-6">
            <Divider orientation="left">
              <h3> {Strings.generalTerms.generalParameters}</h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.generalTerms.name}
              placeholder={Strings.generalTerms.name}
              onChange={(e) => this.nameInputHandleChange(e)}
              value={this.state.scDependencyToAdd.name}
              isReq
              disabled={!this.state.scDependencyValidated}
            />
          </div>

          {/* <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.scDependency.scDependencyType}
                options={this.state.scDependencyTypeList}
                isReq={true}
                defaultValue={this.state.scDependencyToAdd.scDependencyTypeId}
                placeholder={Strings.scDependency.scDependencyType}
                disabled={!this.state.scDependencyValidated}
                onChange={(e) => this.scDependencyTypeInputEvent(e)}
              />
            </div> */}

          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.generalTerms.country}
              options={this.props.countries}
              isReq
              defaultValue={this.state.scDependencyToAdd.countryId}
              placeholder={Strings.generalTerms.country}
              onChange={(e) => this.countryInputEvent(e)}
              disabled={!this.state.scDependencyValidated}
            />
          </div>

          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.generalTerms.province}
              options={this.state.provinces}
              defaultValue={this.state.scDependencyToAdd.provinceId}
              placeholder={Strings.generalTerms.province}
              onChange={(e) => this.provinceInputEvent(e)}
              disabled={!this.state.isDisabledProvince}
            />
          </div>

          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.generalTerms.city}
              options={this.state.cities}
              defaultValue={this.state.scDependencyToAdd.cityId}
              placeholder={Strings.generalTerms.city}
              onChange={(e) => this.cityInputEvent(e)}
              disabled={!this.state.isDisabledCity}
            />
          </div>

          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.product.currency}
              options={this.state.currencyCodes}
              isReq
              defaultValue={this.state.scDependencyToAdd.currencyCodeId}
              placeholder={Strings.product.currency}
              onChange={(e) => this.currencyCodeInputEvent(e)}
              disabled={!this.state.isDisabledProvince}
            />
          </div>

          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.generalTerms.address}
              value={this.state.scDependencyToAdd.address}
              placeholder={Strings.generalTerms.address}
              onChange={(e) => this.addressInputEvent(e)}
              disabled={!this.state.scDependencyValidated}
            />
          </div>

          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.users.phone1}
              value={this.state.scDependencyToAdd.phone1}
              placeholder={Strings.users.phone1}
              onChange={(e) => this.phone1InputEvent(e)}
              disabled={!this.state.scDependencyValidated}
            />
          </div>

          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.users.phone2}
              value={this.state.scDependencyToAdd.phone2}
              placeholder={Strings.users.phone2}
              onChange={(e) => this.phone2InputEvent(e)}
              disabled={!this.state.scDependencyValidated}
            />
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-md-6">
            <GeneralCheckbox
              text={Strings.scDependency.isVendingAllowed}
              tooltip={Strings.scDependency.isVendingAllowed}
              isChecked={this.state.isVendingAllowed}
              onChange={(e) => this.handleIsVendingAllowedOnChange(e)}
              disabled={!this.state.scDependencyValidated}
            />
          </div>

          <div className="vertSpace col-md-6">
            <GeneralCheckbox
              text={Strings.scDependency.isProvisioningAllowed}
              tooltip={Strings.scDependency.isProvisioningAllowed}
              isChecked={this.state.isProvisioningAllowed}
              onChange={(e) => this.handleIsProvisioningAllowedOnChange(e)}
              disabled={!this.state.scDependencyValidated}
            />
          </div>
        </div>

        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link="/listScDependency" />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton
              onClick={(e) => this.addScDependencyHandleChange(e)} 
              loading={this.state.loadingSave} 
              isDisabled={!(this.state.scDependencyToAdd.countryId && this.state.scDependencyToAdd.currencyCodeId && this.state.scDependencyToAdd.name)} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countries: state.countries,
    scdependencytype: state.scdependencytypeid,
  };
}

export default connect(mapStateToProps, {
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  listScDependencyType,
  getScDependencyByUniqueIdentifierAndSoDependencyId,
  listAllCurrencyCodesByCountryId,
  addScDependency,
})(AddScDependency);
