import React from "react";
import { Modal } from "antd";

import Strings from "../../../systemVariables/languageStrings";

const ExportModalTransactionsReport = (props) => {
  const { confirm } = Modal;
  const { setVisible, onExport, isExportVisible, onCloseModal } = props;

  const showConfirm = (isExportVisible) => {
    if (isExportVisible) {
      confirm({
        title: `${Strings.generalResponses.fileExportRequest}`,
        content: (
          <>
            <br />
            <p>{`${Strings.generalResponses.confirmationMessageToExportTransactionReportPart1}. ${Strings.generalResponses.confirmationMessageToExportTransactionReportPart2}`}</p>
            <p>{`${Strings.generalResponses.confirmationMessage}`}</p>
          </>
        ),
        onOk() {
          setVisible(false, false);
          onCloseModal();
          onExport("csv");
        },
        onCancel() {
          setVisible(false, false);
          onCloseModal();
        },
      });
    }
  };

  return <>{showConfirm(isExportVisible)}</>;
};

export default ExportModalTransactionsReport;
