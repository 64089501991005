import axios from 'axios';
import { host, port, path, version } from '../systemVariables';

// Server URL
const resource = '/lockerloanexceptionbyuser';

export function listLockerLoanExceptionByUser(
    scDependencyId,
    scDepartmentId,
    scUserProfileId,
    identityCardNumber,
    page,
    size,
    attribute,
    order,
    callback
  ) {
    return () => {
      axios
        .get(host + port + path + version + resource, {
          params: {
            scdependencyid: scDependencyId,
            scdepartmentid: scDepartmentId,
            scuserprofileid: scUserProfileId,
            identitycardnumber: identityCardNumber,
            page,
            size,
            attribute,
            order
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
          }
        })
        .then(response => {
          callback(response);
        })
        .catch(error => {
          return error;
        });
    };
  }

  export function insertLockerLoanExceptionByUser(insertDTO, callback){
    return () => {
      let data = JSON.stringify(insertDTO)
      axios.post(host + port + path + version + resource, data, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
        }
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return(error);
      });
    }
  }
  
  export function updateLockerLoanExceptionByUser(updateDTO, callback){
    return () => {
      let data = JSON.stringify(updateDTO)
      axios(
        {method:'put',
        url : host+ port + path  + version + resource,
        data : data,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
        }
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error){
        return(error);
      });
    }
  }
  
  export function disableLockerLoanExceptionByUser(id, callback){
    return () => {
      let data = JSON.stringify(id)
      axios({
        method: 'delete',
        url: host + port + path + version + resource,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
        }
      }).then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return(error);
      });
    }
  }

 export function exportExceptionByUser(
    scDependencyId, 
    fileType, 
    columnHeaders, 
    callback) 
    {
    return (dispatch) => {
      axios.get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId,
          filetype: fileType,
          columnheaders: encodeURIComponent(columnHeaders)
        },
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
        },
      })
        .then((response) => {
          callback(response)
        })
        .catch(function (error) {
          return error;
        });
    }
  } 