import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";

import AvailableSoProductTable from "../Products/SoProduct/AvailableSoProductTable.jsx";
import AvailableVmProductGroupTable from "../Products/vmProductGroup/availableVmProductGroupTable";

//Language localization
import Strings from "../../systemVariables/languageStrings";

class AddProductsToRuleModal extends Component {
  handleButton = () => {
    this.props.setIsVisible(false);
  };

  render() {
    return (
      <Modal
        title={
          <div className="row">
            <div className="alignLabel col-12">
              <h2>{Strings.dispensationRule.soProductAssignmentToDispensationRule}</h2>
            </div>
          </div>
        }
        onOk={this.handleButton}
        onCancel={this.handleButton}
        width={1200}
        visible={this.props.isVisible}
        footer={null}
        centered={true}
      >
        <div className="row">
          <div className="col-md-6">
            <h3>{Strings.vmProductGroup.availableProductGroups}</h3>
            <div className="smallTable">
              <AvailableVmProductGroupTable availableVmProductGroups={this.props.vmProductGroupList} onRowClick={this.props.addVmProductGroup} />
            </div>
            <br />
          </div>
          <div className="col-md-6">
            <h3>{Strings.product.availableProducts}</h3>
            <div className="smallTable">
              <AvailableSoProductTable availableSoProducts={this.props.soProductList} onRowClick={this.props.addSoProduct} />
            </div>
            <br />
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  AvailableSoProductTable,
  AvailableVmProductGroupTable,
})(AddProductsToRuleModal);
