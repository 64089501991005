import { LIST_CURRENCYCODEBYCOUNTRY } from "../actions";

const initialState = {
  currencyCodeByCountryObj: [],
};

export function currencyCodeByCountry(state = [], action) {
  switch (action.type) {
    case LIST_CURRENCYCODEBYCOUNTRY:
      let currencyCodesByCountry = action.payload.data.map((item) => ({
        alphaCode: item.currencyAlphaCode,
        numericCode: item.currencyNumericCode,
        symbol: item.symbol,
        value: item.id,
        label: item.symbol + " " + item.currencyAlphaCode,
      }));
      return currencyCodesByCountry;
    default:
      return state;
  }
}
