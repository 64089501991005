import { Button, Icon, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Strings from "../../../systemVariables/languageStrings";
import "./buttons.css";

export function ExportButton(props) {
  const { link, tooltip, onClick, isDisabled, loading, hideIcon, style } = props;
  return (
    <Link
      to={{
        pathname: link,
        props: props.props,
      }}
    >
      <Tooltip title={tooltip} placement="bottom">
        <Button id="exportButton" onClick={onClick} disabled={isDisabled} loading={loading} type="primary" style={style}>
          {loading ? "" : <Icon type="file-text" theme="twoTone" hidden={hideIcon} />}
          <span>{Strings.generalTerms.export}</span>
        </Button>
      </Tooltip>
    </Link>
  );
}
