import { Icon, Tooltip } from "antd";
import React from "react";
import "./text.css";

// Language localization

const NormalText = (props) => {
  const { isReq, text, tooltip, name } = props;
  let req = ":*";
  if (isReq === false) {
    req = ":";
  }

  return (
    <div className="container-fluid ">
      <div className="row ">
        <div className="col-md-4" style={{ textAlign: "center" }}>
          <span className="inputText">{text + req}</span>
          {tooltip ? (
            <Tooltip title={tooltip}>
              <Icon className="icon" type="info-circle" /> <span />
            </Tooltip>
          ) : null}
        </div>
        <div className="col-md-8" style={{ textAlign: "center" }}>
          {name}
        </div>
      </div>
    </div>
  );
};

export default NormalText;
