import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Server URL
const resource = "/vwscproductinfo";

export function listVwScProductInformationByScDependency(scDependencyId, callback){
  return (dispatch) => {
    axios.get(host+ port + path  + version + resource, {
      params : {
        scdependencyid: scDependencyId,
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      callback(error);
    });
  }
}