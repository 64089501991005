// Dependencies
import { Divider, Modal } from "antd";
import React, { Component } from "react";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Actions
import { addSoProductAction, getAllSoProductTypes, postFile } from "../../../actions";

// Components
import { maxHeightImage, maxWidthImage, quality, s3Bucket } from "../../../systemVariables/serverInformation";
import { generateUUID } from "../../../Utils/GenerateUUID";
import { resizeImage } from "../../../Utils/ResizeImage";
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import Titles from "../../GenericComponents/titles";
import { IsProductValid } from "./ProductValidator";
import SoProductForm from "./SoProductForm";

// Language Localization
import Strings from "../../../systemVariables/languageStrings";
import { locateSoProductTypeName } from "../../../Utils/LanguageLocalization/soProductTypeLocalization";

class AddSoProduct extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      productToAdd: {
        soDependencyId: location.props ? location.props.soDependencyId : null,
        soCountryName: location.props ? location.props.soCountryName : null,
        soProductTypeId: 0,
        erpProductCode: null,
        name: null,
        description: null,
        details: [],
        mfgCode: null,
        productTaxTypeId: null,
        currencyCodeId: null,
        preTaxCost: null,
        preTaxPrice: null,
        newPreTaxPrice: null,
        closeDate: null,
        height: null,
        width: null,
        depth: null,
        hasStopByHome: 0,
        hasStopByTof: 0,
        hasStopByMems: 0,
        hasStopByCv: 0,
        bucketName: s3Bucket,
        productImgPath: null,
      },

      image: {
        loading: false,
        image: null,
        url: null,
        modified: false,
        value: null,
      },

      count: 0,
      soProductTypes: [],
      isSaveButtonLoading: false,
      redirectBackToListSoProducts: false,
      isFormLoading: false,
    };
  }

  componentDidMount() {
    const { productToAdd } = this.state;
    const { getAllSoProductTypes } = this.props;
    this.setState({ productToAdd });

    if (!productToAdd.soDependencyId) {
      // Shows No SO Modal Warning
      this.noSoWarning();
    }
    getAllSoProductTypes((response) => {
      if (response.data) {
        this.setState({
          soProductTypes: response.data.data.map((item) => ({
            value: item.id,
            label: locateSoProductTypeName(item.name),
          })),
        });
      }
    });
  }

  setProductToInitialState = () => {
    const { productToAdd } = { ...this.state };
    productToAdd.soProductTypeId = 0;
    productToAdd.erpProductCode = null;
    productToAdd.name = null;
    productToAdd.description = null;
    productToAdd.details = [];
    productToAdd.mfgCode = null;
    productToAdd.productTaxTypeId = null;
    productToAdd.currencyCodeId = null;
    productToAdd.preTaxCost = null;
    productToAdd.preTaxPrice = null;
    productToAdd.newPreTaxPrice = null;
    productToAdd.closeDate = null;
    productToAdd.height = null;
    productToAdd.width = null;
    productToAdd.depth = null;
    productToAdd.hasStopByHome = 0;
    productToAdd.hasStopByTof = 0;
    productToAdd.hasStopByMems = 0;
    productToAdd.hasStopByCv = 0;

    const { image } = { ...this.state };
    image.loading = false;
    image.image = null;
    image.url = null;
    image.modified = false;
    image.value = null;

    this.setState({
      productToAdd,
      image,
    });
  };

  handleDelete = (key) => {
    const { productToAdd } = { ...this.state };
    const dataSource = [...this.state.productToAdd.details];
    productToAdd.details = dataSource.filter((item) => item !== key);
    this.setState({
      productToAdd,
    });
  };

  handleAdd = () => {
    const {
      count,
      productToAdd: { details },
    } = this.state;
    const { productToAdd } = { ...this.state };
    const newData = {
      key: count,
      attribute: "",
      value: "",
    };
    productToAdd.details = [...details, newData];
    this.setState({
      productToAdd,
      count: count + 1,
    });
  };

  handleSave = (row) => {
    const newData = [...this.state.productToAdd.details];
    const { productToAdd } = { ...this.state };
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    productToAdd.details = newData;
    this.setState({ productToAdd });
  };

  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { productToAdd } = { ...this.state };
    productToAdd[id] = value;

    this.setState({
      productToAdd,
    });
  };

  handleSelect = (option, event) => {
    const { value } = option;
    const { productToAdd } = { ...this.state };

    productToAdd[event.name] = value;

    this.setState({
      productToAdd,
    });
  };

  handleImageChange = (info) => {
    const image = { ...this.state };
    const { postFile } = this.props;
    if (info.file.status === "uploading") {
      image.loading = true;
      image.url = null;
      image.image = null;
      image.modified = false;

      this.setState({
        image,
      });
      return;
    }
    if (info.file.status === "done") {
      const { productToAdd } = { ...this.state };
      const image = { ...this.state };

      Resizer.imageFileResizer(
        info.file.originFileObj,
        maxWidthImage,
        maxHeightImage,
        "JPEG",
        quality,
        0,
        (uri) => {
          const file = resizeImage(uri);
          const random = generateUUID();
          const key = `content/${productToAdd.soDependencyId}/products/img${random}`;

          image.loading = false;
          image.url = uri;
          image.image = file;
          image.modified = true;
          image.value = key;
          productToAdd.productImgPath = key;
          productToAdd.bucketName = s3Bucket;

          postFile(image.image, key, (response) => {
            if (response.data !== "OK") {
              Modal.error({
                title: Strings.generalResponses.failedTransaction,
                content: Strings.generalResponses.imageError,
              });
            }
          });

          this.setState({
            image,
            productToAdd,
          });
        },
        "base64"
      );
    }
    if (info.file.status === "error") {
      const { image } = { ...this.state };
      const { productToAdd } = { ...this.state };

      Resizer.imageFileResizer(
        info.file.originFileObj,
        maxWidthImage,
        maxHeightImage,
        "JPEG",
        quality,
        0,
        (uri) => {
          const file = resizeImage(uri);
          const random = generateUUID();
          const key = `content/${productToAdd.soDependencyId}/products/img${random}`;

          image.loading = false;
          image.url = uri;
          image.image = file;
          image.modified = true;
          image.value = key;
          productToAdd.productImgPath = key;
          productToAdd.bucketName = s3Bucket;

          postFile(image.image, key, (response) => {
            if (response.data !== "OK") {
              Modal.error({
                title: Strings.generalResponses.failedTransaction,
                content: Strings.generalResponses.imageError,
              });
            }
          });

          this.setState({
            image,
            productToAdd,
          });
        },
        "base64"
      );
    }
  };

  handleOnAddButton() {
    const { addSoProductAction } = this.props;
    const { productToAdd } = this.state;
    this.setState({
      isSaveButtonLoading: true,
    });
    if (productToAdd.details.length == 0) {
      productToAdd.details = null;
    } else {
      productToAdd.details.forEach((obj) => {
        delete obj.key;
      });
      productToAdd.details = JSON.stringify(productToAdd.details);
    }
    const isValid = IsProductValid(productToAdd);
    if (isValid) {
      // Post Object
      addSoProductAction(productToAdd, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            isSaveButtonLoading: false,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsErrorSoproduct,
          });
          this.setState({
            isSaveButtonLoading: false,
          });
        } else if (response.data.status === "SUCCESS") {
          // Successful entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
          });

          this.setProductToInitialState();
          this.setState({
            isSaveButtonLoading: false,
          });
        } else {
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            isSaveButtonLoading: false,
          });
        }
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
      this.setState({
        isSaveButtonLoading: false,
      });
    }
    if (productToAdd.details == null) {
      productToAdd.details = [];
    } else {
      productToAdd.details = JSON.parse(productToAdd.details);
      for (let count = 0; count < productToAdd.details.length; count++) {
        productToAdd.details[count].key = count;
      }
    }
  }

  noSoWarning = () => {
    Modal.warning({
      title: Strings.product.noProductSelected,
      onOk: this.handleOkNoSOWarning,
      content: Strings.product.noProductSelectedMsg,
    });
  };

  handleOkNoSOWarning = () => {
    this.setState({ redirectBackToListSoProducts: true });
  };

  render() {
    const {
      permissions: { data },
      location,
      prodTaxTypes,
      currencyCodesByCountry,
    } = this.props;
    const { redirectBackToListSoProducts, productToAdd, image, soProductTypes, isFormLoading, isSaveButtonLoading } = this.state;

    if (redirectBackToListSoProducts) {
      // Redirects to SoProduct from No SO Modal Warning
      return <Redirect to="/listSoProducts" />;
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.product.newSoPorduct} tooltip={Strings.product.addSoProductTooltip} />
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.soDependency.directClient} </h3>
            </Divider>
            <br />
            <h3>{location.props ? location.props.soDependencyName : null}</h3>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.parameterization} </h3>
            </Divider>
          </div>
        </div>

        <SoProductForm
          soProduct={productToAdd}
          image={image}
          soProductTypes={soProductTypes}
          prodTaxTypes={prodTaxTypes.filter((item) => item.countryName === productToAdd.soCountryName)}
          currencyCodes={currencyCodesByCountry}
          onChange={this.handleFormChange}
          onChangeImage={this.handleImageChange}
          onSelect={this.handleSelect}
          onChangeCell={this.handleSave}
          onDeleteRow={this.handleDelete}
          onAddRow={this.handleAdd}
          isLoading={isFormLoading}
          data={data}
        />

        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link="/listSoProducts" />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton onClick={(e) => this.handleOnAddButton(e)} loading={isSaveButtonLoading} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    prodTaxTypes: state.productTaxType,
    currencyCodesByCountry: state.currencyCodeByCountry,
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  addSoProductAction,
  getAllSoProductTypes,
  postFile,
})(AddSoProduct);
