import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import { Card, Collapse, Divider, Icon, Modal, Spin, Statistic, Timeline, Typography } from "antd";
import CashTransactionSummary from "./CashTransactionSummary";
import MachineTransactionSummary from "./MachineTransactionSummary";
import PatientInformationSummary from "./PatientInformationSummary";
import ProductTransactionSummary from "./ProductTransactionSummary";
import UserTransactionSummary from "./UserTransactionSummary";

// import LockerLoanTransactionSummary from "./LockerLoanTransactionSummary";
import moment from "moment";
import { transactionTypeLocalization } from "../../../Utils/LanguageLocalization";
import { DATE_TIME_FORMAT } from "../../../systemVariables/TimeConfig";

// Images
import NotAvailableImg from "../../Global/images/NotAvailableImg.png";

// Queries
import { getFile, getLocationInformationById, getPatientInformationById, getTransactionByUuid, getTransactionsByProvisioningId, getVmCashTransaction } from "../../../actions";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
// import { values } from "lodash";

const { Paragraph } = Typography;
const { Meta } = Card;
const { Panel } = Collapse;

let elements = [];

class DispensationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      userImage: NotAvailableImg,
      productImage: NotAvailableImg,
      cashTransaction: {
        amountReceived: 0,
        amountReturned: 0,
        depositBillBox: 0,
        depositBillRecycler: 0,
        depositCashless: 0,
        depositCoinBox: 0,
        depositCoinTubes: 0,
        depositCredit: 0,
        productPreTaxPrice: 0,
        productPrice: 0,
        taxPercentage: 0,
        taxValue: 0,
        trxIdString: "",
        withdrawalCoinTubes: 0,
        withdrawalBillRecycler: 0,
      },
      patientInformation: null,
      locationInformation: null,
      transactionLine: [],
      alternativeTransaction: null,
      isLoading: true,
      isGeneralLoading: false,
      panelKey: 1,
    };
  }

  // Events
  handleCancel = () => {
    this.setState({
      visible: false,
      userImage: NotAvailableImg,
      productImage: NotAvailableImg,
      cashTransaction: {
        amountReceived: 0,
        amountReturned: 0,
        depositBillBox: 0,
        depositBillRecycler: 0,
        depositCashless: 0,
        depositCoinBox: 0,
        depositCoinTubes: 0,
        depositCredit: 0,
        productPreTaxPrice: 0,
        productPrice: 0,
        taxPercentage: 0,
        taxValue: 0,
        trxIdString: "",
        withdrawalCoinTubes: 0,
        withdrawalBillRecycler: 0,
      },
      isLoading: true,
      panelKey: 1,
      alternativeTransaction: null,
    });
  };

  moreInformationDetails = (productImgPathS3, userImgPathS3, vmSaleId, provisioningId, patientInformationId, locationInformationId) => {
    const { getFile, getVmCashTransaction, getTransactionsByProvisioningId, getPatientInformationById, getLocationInformationById } = this.props;

    this.setState({
      cashTransaction: null,
      isLoading: true,
    });

    if (!this.state.visible) this.setState({ isGeneralLoading: true });

    const getProductImage = new Promise((resolve) =>
      getFile(productImgPathS3, (ans) => {
        resolve(ans);
      })
    );

    const getuserImage = new Promise((resolve) =>
      getFile(userImgPathS3, (ans) => {
        resolve(ans);
      })
    );

    let promises = [getProductImage, getuserImage];

    if (vmSaleId) {
      promises.push(
        new Promise((resolve) =>
          getVmCashTransaction(vmSaleId, (ans) => {
            resolve(ans);
          })
        )
      );
    } else {
      promises.push(new Promise((resolve) => resolve(undefined)));
    }

    if (provisioningId) {
      promises.push(
        new Promise((resolve) =>
          getTransactionsByProvisioningId(provisioningId, (ans) => {
            resolve(ans);
          })
        )
      );
    } else {
      promises.push(new Promise((resolve) => resolve(undefined)));
    }

    if (patientInformationId) {
      promises.push(
        new Promise((resolve) =>
          getPatientInformationById(patientInformationId, (ans) => {
            resolve(ans);
          })
        )
      );
    } else {
      promises.push(new Promise((resolve) => resolve(undefined)));
    }

    if (locationInformationId) {
      promises.push(
        new Promise((resolve) =>
          getLocationInformationById(locationInformationId, (ans) => {
            resolve(ans);
          })
        )
      );
    } else {
      promises.push(new Promise((resolve) => resolve(undefined)));
    }

    Promise.all(promises).then(([productData, userData, cashTransactionData, timelineData, patientInformationData, locationInformationData]) => {
      if (!String(productData).includes("Error:")) {
        this.setState({
          productImage: `data:${productData.fileType};base64,${productData.data}`,
        });
      } else {
        this.setState({
          productImage: NotAvailableImg,
        });
      }

      if (!String(userData).includes("Error:")) {
        this.setState({
          userImage: `data:${userData.fileType};base64,${userData.data}`,
        });
      } else {
        this.setState({
          userImage: NotAvailableImg,
        });
      }

      if (cashTransactionData !== undefined) {
        if (cashTransactionData.data != null) {
          this.setState({
            cashTransaction: cashTransactionData.data.data,
          });
        }
        if (!cashTransactionData.data && String(cashTransactionData).includes("Error:")) {
          this.setState({
            cashTransaction: null,
          });
        }
      }

      if (patientInformationData !== undefined) {
        if (patientInformationData.data != null) {
          this.setState({
            patientInformation: patientInformationData.data.data,
          });
        }
        if (!patientInformationData.data && String(patientInformationData).includes("Error:")) {
          this.setState({
            patientInformation: null,
          });
        }
      }

      if (locationInformationData !== undefined) {
        if (locationInformationData.data != null) {
          this.setState({
            locationInformation: locationInformationData.data.data,
          });
        }
        if (!locationInformationData.data && String(locationInformationData).includes("Error:")) {
          this.setState({
            locationInformation: null,
          });
        }
      }

      if (timelineData !== undefined) {
        this.setState({
          transactionLine: timelineData.data.data,
        });
      }
      if (!this.state.visible) this.setState({ isGeneralLoading: false });
      this.setState({ visible: true, isLoading: false });
    });
  };

  handleRefreshData(uuid) {
    const { getTransactionByUuid } = this.props;
    this.setState({ visible: true, isLoading: true });
    getTransactionByUuid(uuid, (response) => {
      this.setState({
        alternativeTransaction: response.data.data,
      });
      if (response !== undefined) {
        this.moreInformationDetails(
          response.data.data.productImgPathS3,
          response.data.data.userImgPathS3,
          response.data.data.vmSaleId,
          null,
          response.data.data.patientInformationId,
          response.data.data.locationInformationId
        );
      }
      this.setState({ visible: true, isLoading: false });
      this.forceUpdate();
    });
  }

  renderTitle = () => {
    return (
      <>
        <Paragraph className="paragraphTitle" style={{ whiteSpace: "normal", padding: "0.5em" }}>
          <h2 style={{ margin: 0 }}>{Strings.transaction.transactionInformation}</h2>
        </Paragraph>
      </>
    );
  };

  renderTimelineItem = (items, row, isLoading) => {
    elements = [];
    let code,
      color = "";

    items.forEach((item) => {
      if (item !== undefined) {
        if (item.productTransactionTypeId === 1 || item.productTransactionTypeId === 5 || item.productTransactionTypeId === 6) {
          code = "like";
          color = "#52c41a";
        }
        if (item.productTransactionTypeId === 28 || item.productTransactionTypeId === 33 || item.productTransactionTypeId === 4) {
          code = "dislike";
          color = "#FF5733";
        }
        if (item.productTransactionTypeId === 32 || item.productTransactionTypeId === 31) {
          code = "close-circle";
          color = "#FFC300";
        }

        elements.push(
          <Timeline.Item color="#004173">
            <Statistic
              className={row.uuid == item.uuid ? "customCollapseSelectedItem" : ""}
              prefix={<Icon type={code} theme="twoTone" twoToneColor={color} />}
              title={
                <div>
                  {Strings.dispensationReport.transactionDate}: {moment(item.transactionDate).format(DATE_TIME_FORMAT)} | {Strings.transaction.transactionIdentifier}:{" "}
                  <p role="button" tabIndex={0} style={{ color: "#004173", cursor: "pointer", display: "inline" }} onClick={() => this.handleRefreshData(item.uuid)}>
                    {item.uuid}
                  </p>{" "}
                  | {Strings.planogram.slotNumber}: {item.vmSlotNumber} {row.uuid != item.uuid ? isLoading ? <Spin style={{ paddingLeft: "1em" }} /> : null : null}
                </div>
              }
              value={Strings.transaction.transactionStatus + ": " + transactionTypeLocalization(item.productTransactionTypeName).toUpperCase()}
              style={{ padding: "0.5em" }}
            />
          </Timeline.Item>
        );
      }
    });
  };

  renderCardsInfo = (transactionInfo, productImage, userImage, cashTransaction, patientInformation, locationInformation) => {
    const { isLoading } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <h3 style={{ textAlign: "center" }}>
              <span>{Strings.transaction.transactionIdentifier + ": "}</span>
              <span className="paragraphSubTitle">{transactionInfo.uuid}</span>
              {transactionInfo.provisioningId ? <span>{" (" + transactionInfo.provisioningId + ")"}</span> : null}
            </h3>
          </div>
        </div>
        <div className="row" style={{ alignContent: "center" }}>
          <div className="contenedor">
            <ProductTransactionSummary transactionInfo={transactionInfo} productImage={productImage} isLoading={isLoading} />
            <MachineTransactionSummary transactionInfo={transactionInfo} />
            <UserTransactionSummary transactionInfo={transactionInfo} userImage={userImage} />
            {<CashTransactionSummary cashTransaction={cashTransaction} />}
            {patientInformation || locationInformation ? <PatientInformationSummary patientInformation={patientInformation} locationInformation={locationInformation} /> : <></>}
          </div>
        </div>
      </>
    );
  };

  render() {
    const { selectedRow } = this.props;
    const {
      visible,
      userImage,
      productImage,
      cashTransaction,
      isGeneralLoading,
      isLoading,
      transactionLine,
      panelKey,
      alternativeTransaction,
      patientInformation,
      locationInformation,
    } = this.state;
    return !isGeneralLoading ? (
      <>
        <Icon
          type="profile"
          className="addEditRemoveButton"
          onClick={() => {
            this.moreInformationDetails(
              selectedRow.productImgPathS3,
              selectedRow.userImgPathS3,
              selectedRow.vmSaleId,
              selectedRow.provisioningId,
              selectedRow.patientInformationId,
              selectedRow.locationInformationId
            );
          }}
        />
        <Modal title={this.renderTitle()} visible={visible} onCancel={this.handleCancel} centered width="auto" footer={null}>
          {selectedRow.provisioningId ? (
            <>
              <div style={{ padding: "1em", display: "flex", justifyContent: "center" }}>
                <Collapse className="customCollapse" width="auto">
                  <Panel
                    header={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h2 className="paragraphSubTitle" style={{ paddingLeft: "0.5em" }}>
                          {Strings.transaction.transactionFlow}
                        </h2>
                      </div>
                    }
                    key={panelKey}
                  >
                    <Timeline className="customTimeline">
                      {this.renderTimelineItem(transactionLine, alternativeTransaction === null ? selectedRow : alternativeTransaction, isLoading)}
                      {elements}
                    </Timeline>
                  </Panel>
                </Collapse>
              </div>
              <Divider style={{ marginTop: "1.5em" }} />
            </>
          ) : null}
          {this.renderCardsInfo(
            alternativeTransaction === null ? selectedRow : alternativeTransaction,
            productImage,
            userImage,
            cashTransaction,
            patientInformation,
            locationInformation
          )}
        </Modal>
      </>
    ) : (
      <Spin />
    );
  }
}

DispensationModal.propTypes = {
  selectedRow: PropTypes.shape({
    soProductName: PropTypes.string,
    soProductErpCode: PropTypes.string,
    productImgPathS3: PropTypes.string,
    scDependencyName: PropTypes.string,
    scDepartmentName: PropTypes.string,
    scUserProfileName: PropTypes.string,
    scCostCenterName: PropTypes.string,
    userCompleteName: PropTypes.string,
    userIdentityCardNumber: PropTypes.string,
    userImgPathS3: PropTypes.string,
    scDispensationRuleName: PropTypes.string,
    transactionDate: PropTypes.string,
    paymentTypeId: PropTypes.number,
    vmSaleId: PropTypes.number,
    motorsControllerCommandStatusName: PropTypes.string,
    motorsControllerCommandStatusDescription: PropTypes.string,
  }).isRequired,
  getFile: PropTypes.func,
};

DispensationModal.defaultProps = {
  getFile: null,
};

export default connect(null, {
  getFile,
  getVmCashTransaction,
  getTransactionsByProvisioningId,
  getTransactionByUuid,
  getPatientInformationById,
  getLocationInformationById,
})(DispensationModal);
