import { LIST_VMMODELS } from '../actions'


export function vmModel(state = [], action) {
  switch (action.type) {
    case LIST_VMMODELS:
      var vmModels = action.payload.data.map(item => ({value:item.id, label:item.model}))
      return vmModels;
    default:
      return state
  }
}