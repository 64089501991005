// Dependencies
import { Button, DatePicker, Divider, Icon, Input, Modal, Pagination, Popover, Spin, Table, Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";

// Actions
import { getVmServiceRequestBySoScVmDependencySelector, updateVmServiceRequest } from "../../actions";
import QueryResponse from "../GenericComponents/queryResponse/QueryResponse";
// Components
import { ValidatePermissionForComponentPart } from "../../Utils/validatePermissionForComponentPart";
import ColumnTitleWithSortButtons from "../GenericComponents/columnTitleWithSortButtons";
import SoScVmDependencySelector from "../GenericComponents/selectComponent/SoScVmDependencySelector";
import Titles from "../GenericComponents/titles";

// Language Localization
import Strings from "../../systemVariables/languageStrings";

const { Column } = Table;

const { RangePicker } = DatePicker;

const dateFormat = "DD/MM/YYYY hh:mm:ss a";

const activeColor = "#62D32B";

const expiredColor = "#B0968D";

let vmServiceRequestEditContain = [];

class VmServiceRequest extends Component {
  constructor(props) {
    super(props);

    // Select values
    this.soDependencyId = -1;
    this.scDependencyId = -1;
    this.vendingMachineId = -1;

    this.state = {
      VmServiceRequestInfo: [],
      VmServiceRequestToUpdate: {
        vmId: null,
        startDate: null,
        endDate: null,
      },
      totalElements: 0,
      page: 1,
      size: 10,
      attribute: "id",
      order: "DESC",
      searchText: "",
      selectStarDay: moment().day(),
      selectStarHour: 0,
      selectStatMinutes: 0,
      selectEndDay: moment().day(),
      selectEndHour: 16,
      serviceWindow: null,
      isVisible: false,
      isAddButtonDisabled: true,
      isSearchButtonDisabled: true,
      isloadingInfo: false,
      isLoading: false,
      flag: false,
    };
  }

  componentDidMount() {
    const {
      soScSelectionProps,
      userDependency,
      userDependency: { dependencyId },
      userDependency: { dependencyType },
    } = this.props;
    if (sessionStorage.getItem("dependencyType") === "so") {
      const soDependencyId = sessionStorage.getItem("dependencyId");
      if (soDependencyId != null) {
        this.setState({
          soDependencyId: sessionStorage.getItem("dependencyType") === "so" && soDependencyId >= 1 ? soDependencyId : null,
          soDependencyName: sessionStorage.getItem("dependencyType") === "so" && soDependencyId >= 1 ? userDependency.dependencyName : null,
        });

        this.setState({
          page: 1,
          pageSize: 10,
          attribute: "id",
          order: "DESC",
          isAddButtonDisabled: false,
          isSearchButtonDisabled: false,
          vendingMachineSelectorDisabled: false,
        });

        if (soScSelectionProps.so !== null) {
          this.setState({
            soDependencyId: soScSelectionProps.so.id,
            scDependencyId: -1,
            vendingMachineId: -1,
            isSearchButtonDisabled: false,
          });
        } else {
          this.setState({
            page: 1,
            pageSize: 10,
            attribute: "id",
            order: "DESC",
            isAddButtonDisabled: true,
            isSearchButtonDisabled: true,
            vendingMachineSelectorDisabled: true,
          });
        }
      }
    }

    if (sessionStorage.getItem("dependencyType") === "sc") {
      const value = sessionStorage.getItem("dependencyId");
      if (value != null) {
        this.setState({
          soDependencyId: userDependency.dependencyType === "sc" ? userDependency.stockOwnerParentId : null,
          scDependencyId: userDependency.dependencyType === "sc" ? userDependency.dependencyId : null,
          scDependencyName: userDependency.dependencyType === "sc" ? userDependency.dependencyName : null,
          page: 1,
          pageSize: 10,
          attribute: "id",
          order: "DESC",
          isAddButtonDisabled: false,
          isSearchButtonDisabled: false,
          vendingMachineSelectorDisabled: false,
        });
      }
    }
  }

  // Events
  soDependencyHandleChange = (event) => {
    const { value: soDependencyId } = event;
    this.setState({
      soDependencyId,
      scDependencyId: -1,
      vendingMachineId: -1,
      isSearchButtonDisabled: false,
    });
  };

  scDependencyHandleChange = (event) => {
    const { value: scDependencyId } = event;
    if (scDependencyId === -1) {
      const {
        userDependency: { dependencyId },
      } = this.props;
      this.setState({
        soDependencyId: dependencyId,
        scDependencyId: -1,
        vendingMachineId: -1,
        isSearchButtonDisabled: false,
      });
    } else {
      this.setState({
        soDependencyId: -1,
        scDependencyId,
        vendingMachineId: -1,
        isSearchButtonDisabled: false,
      });
    }
  };

  vendingMachineHandleChange = (event) => {
    const { value: vendingMachineId } = event;
    this.setState({
      soDependencyId: -1,
      scDependencyId: -1,
      vendingMachineId,
      isSearchButtonDisabled: false,
    });
  };

  showPagination = (page, size) => {
    const { soDependencyId, scDependencyId, vendingMachineId, searchText, attribute, order } = this.state;
    const { getVmServiceRequestBySoScVmDependencySelector } = this.props;
    this.setState({
      page,
      size,
      attribute: "id",
      order: "DESC",
      isLoading: true,
      isloadingInfo: true,
      isSearchButtonDisabled: true,
    });

    getVmServiceRequestBySoScVmDependencySelector(soDependencyId, scDependencyId, vendingMachineId, searchText, page, size, attribute, order, (response) => {
      this.setState({
        VmServiceRequestInfo: response.data.data.content,
        totalElements: response.data.data.totalElements,
        isLoading: false,
        isloadingInfo: false,
        isSearchButtonDisabled: false,
      });
    });
  };

  onClickCheckButton(e) {
    const { soDependencyId, scDependencyId, vendingMachineId, searchText, page, size, attribute, order } = this.state;
    const { getVmServiceRequestBySoScVmDependencySelector } = this.props;
    this.setState({
      page: 1,
      pageSize: 10,
      attribute: "id",
      order: "DESC",
      isLoading: true,
      isloadingInfo: true,
      isSearchButtonDisabled: true,
    });

    getVmServiceRequestBySoScVmDependencySelector(soDependencyId, scDependencyId, vendingMachineId, searchText, page, size, attribute, order, (response) => {
      this.setState({
        VmServiceRequestInfo: response.data.data.content,
        totalElements: response.data.data.totalElements,
        isLoading: false,
        isloadingInfo: false,
        isSearchButtonDisabled: false,
      });
    });
  }

  getColumnSearchProps = (dataIndex, text, searchText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} ${text}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />,
  });

  handleSearch = (selectedKeys) => {
    if (selectedKeys.length > 0) {
      this.setState({
        isLoading: true,
        isloadingInfo: true,
        isSearchButtonDisabled: true,
        searchText: selectedKeys[0],
      });
      const { soDependencyId, scDependencyId, vendingMachineId, page, size, attribute, order } = this.state;
      const { getVmServiceRequestBySoScVmDependencySelector } = this.props;
      getVmServiceRequestBySoScVmDependencySelector(soDependencyId, scDependencyId, vendingMachineId, selectedKeys[0], page, size, attribute, order, (response) => {
        this.setState({
          VmServiceRequestInfo: response.data.data.content,
          totalElements: response.data.data.totalElements,
          isLoading: false,
          isloadingInfo: false,
          isSearchButtonDisabled: false,
        });
      });
    }
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      isLoading: true,
      isloadingInfo: true,
      isSearchButtonDisabled: true,
      searchText: "",
    });
    const { soDependencyId, scDependencyId, vendingMachineId, page, size, attribute, order } = this.state;
    const { getVmServiceRequestBySoScVmDependencySelector } = this.props;
    getVmServiceRequestBySoScVmDependencySelector(soDependencyId, scDependencyId, vendingMachineId, "", page, size, attribute, order, (response) => {
      this.setState({
        VmServiceRequestInfo: response.data.data.content,
        totalElements: response.data.data.totalElements,
        isLoading: false,
        isloadingInfo: false,
        isSearchButtonDisabled: false,
      });
    });
    clearFilters();
  };

  setSortOrder = (order, attribute) => {
    let sortOrder = order;
    let sortAttribute = attribute;
    if (order === "NONE") {
      sortOrder = "DESC";
      sortAttribute = "id";
    }
    this.setState({
      isLoading: true,
      isloadingInfo: true,
      isSearchButtonDisabled: true,
      order: sortOrder,
      attribute: sortAttribute,
    });
    const { soDependencyId, scDependencyId, vendingMachineId, searchText, page, size } = this.state;
    const { getVmServiceRequestBySoScVmDependencySelector } = this.props;
    getVmServiceRequestBySoScVmDependencySelector(soDependencyId, scDependencyId, vendingMachineId, searchText, page, size, sortAttribute, sortOrder, (response) => {
      this.setState({
        VmServiceRequestInfo: response.data.data.content,
        totalElements: response.data.data.totalElements,
        isLoading: false,
        isloadingInfo: false,
        isSearchButtonDisabled: false,
      });
    });
  };

  vmServiceRequestEdit(row) {
    this.setState({
      flag: false,
    });
    vmServiceRequestEditContain = [];
    vmServiceRequestEditContain.push(
      <div>
        <div className="row">
          <div className="col-md-10">
            <RangePicker
              disabledDate={this.disabledDate}
              disabledTime={this.disabledRangeTime}
              showTime={{ format: "HH:mm", defaultValue: [moment("HH:mm"), moment("18:00", "HH:mm")] }}
              format="DD/MM/YYYY hh:mm"
              placeholder={[Strings.vmServiceRequest.startDate, Strings.vmServiceRequest.endDate]}
              onChange={this.onChange}
              onOk={this.onOkSetServiceWindow}
            />
          </div>
        </div>
        <div className="vertSpace row" align="rigth">
          <Button onClick={() => this.handleSave(row.vmId)} size="small" style={{ width: 90 }}>
            {Strings.generalTerms.save}
          </Button>
        </div>
      </div>
    );
    this.setState({
      flag: true,
    });
  }

  onChange = (dates, dateStrings) => {
    this.setState({
      selectStarDay: dates[0].day(),
      selectStarHour: dates[0].hour(),
      selectStatMinutes: dates[0].minutes(),
      selectEndDay: dates[1].day(),
      selectEndHour: dates[1].hour(),
    });
  };

  onOkSetServiceWindow = (value) => {
    this.setState({
      serviceWindow: value,
    });
  };

  disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  }

  disabledRangeTime = (_, type) => {
    const { selectStarDay, selectStarHour, selectStatMinutes, selectEndDay, selectEndHour } = this.state;
    if (type === "start") {
      if (selectStarHour === moment().hour()) {
        return {
          disabledHours: () => Array.apply(null, { length: moment().hour() }).map(Number.call, Number),
          disabledMinutes: () => Array.apply(null, { length: moment().minutes() }).map(Number.call, Number),
        };
      }
      return {
        disabledHours: () => Array.apply(null, { length: moment().hour() }).map(Number.call, Number),
      };
    }
    if (selectStatMinutes >= 30) {
      if (selectEndHour === selectStarHour || selectEndHour === selectStarHour + 1) {
        return {
          disabledHours: () => Array.apply(null, { length: selectStarHour + 1 }).map(Number.call, Number),
          disabledMinutes: () => Array.apply(null, { length: selectStatMinutes - 30 }).map(Number.call, Number),
        };
      }
      return {
        disabledHours: () => Array.apply(null, { length: selectStarHour + 1 }).map(Number.call, Number),
      };
    } else {
      if (selectStarDay === selectEndDay) {
        if (selectEndHour === selectStarHour) {
          return {
            disabledHours: () => Array.apply(null, { length: selectStarHour }).map(Number.call, Number),
            disabledMinutes: () => Array.apply(null, { length: selectStatMinutes + 30 }).map(Number.call, Number),
          };
        }
        return {
          disabledHours: () => Array.apply(null, { length: selectStarHour }).map(Number.call, Number),
        };
      }
    }
  };

  handleSave(vmId) {
    const { serviceWindow, VmServiceRequestToUpdate, isVisible } = this.state;
    this.setState({
      isSaveButtonLoading: true,
      isVisible: true,
    });
    VmServiceRequestToUpdate.vmId = vmId;
    VmServiceRequestToUpdate.startDate = moment(serviceWindow[0]._d).unix();
    VmServiceRequestToUpdate.endDate = moment(serviceWindow[1]._d).unix();

    const { updateVmServiceRequest } = this.props;
    updateVmServiceRequest(VmServiceRequestToUpdate, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        this.setState({ isSaveButtonLoading: false });
      } else if (response.data && response.data.code === 0) {
        //  Entity Successfully saved
        Modal.success({
          title: Strings.generalResponses.successfulUpdate,
          content: Strings.generalResponses.successfulUpdate,
          onOk: this.onClickCheckButton(null),
        });
      }
      this.setState({ isSaveButtonLoading: false, isVisible: false });
    });
  }

  vmServiceRequestTableAndPagination(VmServiceRequestInfo, isloadingInfo, searchText, page, totalElements, data, flag) {
    return (
      <div className="vertSpace col-12">
        <Table dataSource={VmServiceRequestInfo} bordered loading={isloadingInfo} pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="id__">
          <Column
            width="15%"
            title={Strings.machine.machine}
            dataIndex="vendingmachineName"
            key="vendingmachineName"
            align="center"
            {...this.getColumnSearchProps("VendingMachineName", "", searchText)}
          />
          <Column
            width="10%"
            title={<ColumnTitleWithSortButtons name={Strings.generalTerms.state} attribute="endDate" setSortOrder={(order) => this.setSortOrder(order, "endDate")} />}
            align="center"
            dataIndex="endDate"
            render={(endDate) => (
              <span>
                <Tooltip title={moment(endDate).unix() > moment().unix() ? Strings.vmServiceRequest.active : Strings.vmServiceRequest.expired}>
                  <Icon type="alert" theme="twoTone" style={{ fontSize: 16 }} twoToneColor={moment(endDate).unix() > moment().unix() ? activeColor : expiredColor} />
                </Tooltip>
              </span>
            )}
          />
          <Column
            width="15%"
            title={<ColumnTitleWithSortButtons name={Strings.vmServiceRequest.startDate} attribute="startDate" setSortOrder={(order) => this.setSortOrder(order, "startDate")} />}
            align="center"
            render={(row) => moment(row.startDate).format(dateFormat)}
          />
          <Column
            width="15%"
            title={<ColumnTitleWithSortButtons name={Strings.vmServiceRequest.endDate} attribute="endDate" setSortOrder={(order) => this.setSortOrder(order, "endDate")} />}
            align="center"
            render={(row) => moment(row.endDate).format(dateFormat)}
          />
          <Column width="10%" title={Strings.soDependency.directClient} align="center" dataIndex="scName" />
          {ValidatePermissionForComponentPart("PRIVILEGE VM SERVICE REQUEST LIST CREDENTIAL", data) ? (
            <Column
              width="15%"
              title={Strings.vmServiceRequest.user}
              align="center"
              render={(row) => (moment(row.endDate).unix() > moment().unix() ? row.value.split(",")[0] : "--")}
            />
          ) : (
            ""
          )}
          {ValidatePermissionForComponentPart("PRIVILEGE VM SERVICE REQUEST LIST CREDENTIAL", data) ? (
            <Column
              width="15%"
              title={Strings.vmServiceRequest.password}
              align="center"
              render={(row) => (moment(row.endDate).unix() > moment().unix() ? row.value.split(",")[1] : "--")}
            />
          ) : (
            ""
          )}
          {ValidatePermissionForComponentPart("PRIVILEGE VM SERVICE REQUEST POST", data) ? (
            <Column
              width="7%"
              align="center"
              placement="left"
              cursor="pointer"
              title={<div>{Strings.generalTerms.edit}</div>}
              render={(row) => (
                <div style={{ alignContent: "center", color: "#004173", cursor: "pointer" }} onClick={() => this.vmServiceRequestEdit(row)}>
                  <Popover content={flag ? vmServiceRequestEditContain : <Spin />} trigger="click" placement="left" title={<div>{"Ventana de servicio"}</div>}>
                    <Icon type="edit" theme="twoTone" className="addEditRemoveButton" />
                  </Popover>
                </div>
              )}
            />
          ) : (
            ""
          )}
        </Table>
        <div className="row">
          <div className="col-xs-0 col-lg-6" />
          <div className="vertSpace col-lg-6">
            <Pagination
              size="small"
              total={totalElements}
              showSizeChanger
              onChange={this.showPagination}
              onShowSizeChange={this.showPagination}
              hideOnSinglePage={false}
              pageSizeOptions={["10", "25", "50", "100", "250"]}
              showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total}  ${Strings.generalTerms.items}`}
              current={page}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      permissions: { data },
    } = this.props;
    const { VmServiceRequestInfo, page, isloadingInfo, searchText, isSearchButtonDisabled, dependencyId, flag, totalElements } = this.state;

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.vmServiceRequest.serviceRequest} tooltip={Strings.vmServiceRequest.serviceRequestTooltip} />
        </div>

        <div className="vertSpace row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <SoScVmDependencySelector
            onSoDependencyChange={this.soDependencyHandleChange}
            onScDependencyChange={this.scDependencyHandleChange}
            onVendingMachineChange={this.vendingMachineHandleChange}
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            hasSc
            hasVm
          />
          <div className="vertSpace col-md-6">
            <Tooltip title={Strings.transaction.getTransactionsToolTip}>
              <Button
                style={{ margin: "5px" }}
                type="primary"
                size={!isSearchButtonDisabled ? "large" : "default"}
                onClick={(e) => this.onClickCheckButton(e)}
                icon="search"
                disabled={!!(isSearchButtonDisabled || dependencyId === null)}
                loading={isloadingInfo}
              >
                {Strings.generalTerms.check}
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.vmServiceRequest.serviceRequest} </h3>
            </Divider>
            <QueryResponse
              callback={this.vmServiceRequestTableAndPagination(VmServiceRequestInfo, isloadingInfo, searchText, page, totalElements, data, flag)}
              dataSourceLength={VmServiceRequestInfo.length}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
    userDependency: state.dependencyByUser.dependencyObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  getVmServiceRequestBySoScVmDependencySelector,
  updateVmServiceRequest,
})(VmServiceRequest);
