import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Server URL
const resource = "/vmperipheraldevices";

export function insertVmPeripheralDevice(peripheralDeviceId, vendingMachineId, wingId, slotGroupId, callback) {
  const data = JSON.stringify({ peripheralDeviceId, vendingMachineId, wingId, slotGroupId });
  return () => {
    axios({
      method: "post",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function deleteVmPeripheralDevice(vmPeripheralDeviceId, callback) {
  const params = {
    vmPeripheralDeviceId,
  };
  return () => {
    axios({
      method: "delete",
      url: host + port + path + version + resource,
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function changeVmPeripheralDeviceEnableStatus(vendingMachineId, peripheralDeviceId, enableStatus, callback) {
  const params = {
    vendingmachineid: vendingMachineId,
    enablestatus: enableStatus,
    peripheraldeviceid: peripheralDeviceId,
  };
  return () => {
    axios({
      method: "put",
      url: host + port + path + version + resource,
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}
