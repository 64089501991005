import axios from "axios";

import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/vmcashtransaction";

export function getVmCashTransaction(vmSaleId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vmsaleid: vmSaleId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
      });
  };
}

export function getVmCashTransactionByVmId(vendingMachineId, selectedFilterOption, page, pageSize, attribute, order, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingmachineid: vendingMachineId,
          selectedfilteroption: selectedFilterOption,
          page,
          size: pageSize,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}
