import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal, Divider, Pagination, Tooltip, Button } from "antd";

import {
  listScDependenciesBySoDependencyId,
  getVendingMachinesFilter,
  listScDependenciesByVenidngMachineIds,
  listVendingMachinesByScDependencyId,
  listVendingMachinesByScDependencyIdAndGenerationId,
  getAllRelatedVendingMachinesToSoDependencyId,
  getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId,
  disableVendingMachineById,
  getAllMachinGenerations,
  getLowerRelationsBySoDependencyId,
  listAllSoDependencyTypes,
} from "../../actions";

// Components
import Titles from "../GenericComponents/titles";
import MachineTable from "./VendingMachineTable";
import QueryResponse from "../GenericComponents/queryResponse/QueryResponse";
import SoScVmDependencySelector from "../GenericComponents/selectComponent/SoScVmDependencySelector";
import { SelectGeneral } from "../GenericComponents/selectComponent/selectGeneral";
import { AddButton } from "../GenericComponents/buttons/index";
import { ValidatePermissionForComponent } from "../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../Utils/validatePermissionForComponentPart";

// Language localization
import Strings from "../../systemVariables/languageStrings";
import { soDependencyTypeLocalization } from "../../Utils/LanguageLocalization";

class ListVM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soDependencyId: null,
      soDependencyName: null,
      scDependencyList: [],
      scDependencyId: null,
      scDependencyName: null,
      soDependencyType: 0,

      vmSerialNumber: "",
      page: 1,
      pageSize: 10,
      attribute: "id",
      order: "DESC",

      vendingMachines: [],
      filterOptions: [],
      machineGenerations: [],
      soMachineList: [],

      soDependencyTypes: [],
      headQuarters: [],
      logisticCenters: [],

      selectedFilterOption: null,
      selectedMachineGeneration: null,
      selectedSoMachine: null,
      selectedSoMachineName: "",

      addButtonDisabled: true,
      searchButtonDisabled: true,
      vendingMachineSelectorDisabled: true,
      filterOptionSelectorDisabled: true,
      machineGenerationSelectorDisabled: true,
      onChangeStateSoScVm: false,

      isLoading: false,
      redirectToVMCreation: false,
      redirectToHome: false,
    };
  }

  componentDidMount() {
    const FILTER_OPTIONS = [{ value: -1, label: Strings.generalTerms.all }];
    const {
      soScSelectionProps,
      userDependency,
      userDependency: { dependencyId },
      permissions,
      getAllMachinGenerations,
      getAllRelatedVendingMachinesToSoDependencyId,
      listVendingMachinesByScDependencyId,
    } = this.props;

    if (ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONTRACT GET", permissions.data)) {
      FILTER_OPTIONS.push({ value: 0, label: Strings.machine.listNoContractMachines });
    }
    if (ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONFIGURATION GET", permissions.data)) {
      FILTER_OPTIONS.push({ value: 1, label: Strings.machine.listNoConfiguredMachines });
    }

    this.setState({ filterOptions: FILTER_OPTIONS });

    // Machine generation for creation
    getAllMachinGenerations((response) => {
      const machineGenerations = response.data.data.map((obj) => ({
        value: obj.id,
        label: obj.generationString,
      }));
      machineGenerations.unshift({
        value: -1,
        label: Strings.generalTerms.all,
      });
      this.setState({ machineGenerations });
    });

    if (sessionStorage.getItem("dependencyType") === "so") {
      const soDependencyId = sessionStorage.getItem("dependencyId");
      if (soDependencyId != null) {
        this.setState({
          soDependencyId: sessionStorage.getItem("dependencyType") === "so" && soDependencyId >= 1 ? soDependencyId : null,
          soDependencyName: sessionStorage.getItem("dependencyType") === "so" && soDependencyId >= 1 ? userDependency.dependencyName : null,
        });

        this.setState({
          page: 1,
          pageSize: 10,
          attribute: "id",
          order: "DESC",
          addButtonDisabled: false,
          searchButtonDisabled: false,
          vendingMachineSelectorDisabled: false,
          filterOptionSelectorDisabled: false,
          machineGenerationSelectorDisabled: false,
        });

        if (soScSelectionProps.so !== null) {
          this.setState({ soDependencyId: soScSelectionProps.so.id === -1 ? null : soScSelectionProps.so.id });
          getAllRelatedVendingMachinesToSoDependencyId(soScSelectionProps.so.id, (response) => {
            if (!response.data && String(response).includes("Error:")) {
              // Connection error
              Modal.error({
                title: Strings.generalResponses.connectionError,
                content: Strings.generalResponses.connectionErrorTooltip,
                onOk: this.setState({
                  redirectToHome: true,
                }),
                isLoading: false,
              });
            } else {
              this.setState({
                soMachineList: response.data.data.map((obj) => ({
                  value: obj.id,
                  label: obj.friendlyName,
                })),
              });
            }
          });
          if (soScSelectionProps.sc !== null) {
            this.setState({ scDependencyId: soScSelectionProps.sc.id });
            if (soScSelectionProps.sc !== -1) {
              listVendingMachinesByScDependencyId(soScSelectionProps.sc.id, (response) => {
                if (response.data.data.length > 0) {
                  this.setState({
                    soMachineList: response.data.data.map((item) => ({
                      value: item.id,
                      label: item.friendlyName,
                    })),
                  });
                }
              });
            } else {
              getAllRelatedVendingMachinesToSoDependencyId(soScSelectionProps.so.id, (response) => {
                if (!response.data && String(response).includes("Error:")) {
                  // Connection error
                  Modal.error({
                    title: Strings.generalResponses.connectionError,
                    content: Strings.generalResponses.connectionErrorTooltip,
                    onOk: this.setState({
                      redirectToHome: true,
                    }),
                    isLoading: false,
                  });
                } else {
                  this.setState({
                    soMachineList: response.data.data.map((obj) => ({
                      value: obj.id,
                      label: obj.friendlyName,
                    })),
                  });
                }
              });
            }
          }
        } else {
          this.setState({
            page: 1,
            pageSize: 10,
            attribute: "id",
            order: "DESC",
            addButtonDisabled: true,
            searchButtonDisabled: true,
            vendingMachineSelectorDisabled: true,
            filterOptionSelectorDisabled: true,
            machineGenerationSelectorDisabled: true,
          });
          if (soScSelectionProps.sc !== null) {
            this.setState({ scDependencyId: soScSelectionProps.sc.id });
            listVendingMachinesByScDependencyId(soScSelectionProps.sc.id, (response) => {
              if (response.data.data.length > 0) {
                this.setState({
                  soMachineList: response.data.data.map((item) => ({
                    value: item.id,
                    label: item.friendlyName,
                  })),
                });
              }
            });
            this.setState({
              page: 1,
              pageSize: 10,
              attribute: "id",
              order: "DESC",
              addButtonDisabled: false,
              searchButtonDisabled: false,
              vendingMachineSelectorDisabled: false,
              filterOptionSelectorDisabled: false,
              machineGenerationSelectorDisabled: false,
            });
          }
        }
      }
    }

    if (sessionStorage.getItem("dependencyType") === "sc") {
      const value = sessionStorage.getItem("dependencyId");
      if (value != null) {
        this.setState({
          soDependencyId: userDependency.dependencyType === "sc" ? userDependency.stockOwnerParentId : null,
          scDependencyId: userDependency.dependencyType === "sc" ? userDependency.dependencyId : null,
          scDependencyName: userDependency.dependencyType === "sc" ? userDependency.dependencyName : null,
          page: 1,
          pageSize: 10,
          attribute: "id",
          order: "DESC",
          addButtonDisabled: false,
          searchButtonDisabled: false,
          vendingMachineSelectorDisabled: false,
          filterOptionSelectorDisabled: true,
          machineGenerationSelectorDisabled: false,
        });
        listVendingMachinesByScDependencyId(dependencyId, (response) => {
          if (!response.data && String(response).includes("Error:")) {
            // Connection error
            Modal.error({
              title: Strings.generalResponses.connectionError,
              content: Strings.generalResponses.connectionErrorTooltip,
              onOk: this.setState({
                redirectToHome: true,
              }),
              isLoading: false,
            });
          } else {
            this.setState({
              soMachineList: response.data.data.map((obj) => ({
                value: obj.id,
                label: obj.friendlyName,
              })),
            });
          }
        });
      }
    }
  }

  // Events
  onClickAddButton() {
    const { scDependencyId } = this.state;
    Modal.confirm({
      title: Strings.machine.newMachine,
      content: !scDependencyId ? Strings.machine.machineNoContractCreation : Strings.machine.machineContractCreation,
      onOk: this.handleOkVMCreation,
    });
  }

  handleOkVMCreation = () => {
    this.setState({ redirectToVMCreation: true });
  };

  onClickCheckButton = async () => {
    const { soDependencyId, scDependencyId, selectedMachineGeneration, selectedSoMachineName, selectedFilterOption, vmSerialNumber, attribute, order } = this.state;
    const { getVendingMachinesFilter, getLowerRelationsBySoDependencyId, listAllSoDependencyTypes, listScDependenciesBySoDependencyId, permissions } = this.props;

    await new Promise((resolve) =>
      this.setState(
        {
          page: 1,
          pageSize: 10,
          attribute: "id",
          order: "DESC",
          isLoading: true,
        },
        resolve
      )
    );

    if (soDependencyId != null) {
      listScDependenciesBySoDependencyId(soDependencyId, (response) => {
        if (response.data.data.length > 0) {
          this.setState({
            scDependencyList: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        }
      });
      const { page, pageSize } = this.state;
      await getVendingMachinesFilter(
        soDependencyId,
        scDependencyId === -1 ? "" : scDependencyId || "",
        parseInt(selectedMachineGeneration, 10) > -1 ? parseInt(selectedMachineGeneration, 10) : "",
        parseInt(selectedFilterOption, 10) === 0 ? 1 : 0, // To show only vms without vmContract
        parseInt(selectedFilterOption, 10) === 1 ? 1 : 0, // To show only unconfigured vms
        selectedSoMachineName || "",
        vmSerialNumber || "",
        ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONTRACT GET", permissions.data) ? 1 : 0,
        ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONFIGURATION GET", permissions.data) ? 1 : 0,
        page,
        pageSize,
        attribute,
        order,
        (response) => {
          if (!response.data && String(response).includes("Error:")) {
            // Connection error
            Modal.error({
              title: Strings.generalResponses.connectionError,
              content: Strings.generalResponses.connectionErrorTooltip,
              onOk: this.setState({
                redirectToHome: true,
              }),
            });
          } else {
            this.setState({
              vendingMachines: response.data.data,
              isLoading: false,
              onChangeStateSoScVm: false,
            });
          }
        }
      );

      if (soDependencyId === 2) {
        await listAllSoDependencyTypes((response) => {
          this.setState({
            soDependencyTypes: response.data.data
              .filter((obj) => obj.id !== 3 && obj.id !== 0)
              .map((obj) => ({
                value: obj.id,
                label: soDependencyTypeLocalization(obj.name),
              })),
          });
        });
        await getLowerRelationsBySoDependencyId(soDependencyId, (response) => {
          this.setState({
            headQuarters: response.data.data.soDependencyGetDTO
              .filter((obj) => obj.soDependenctTypeId === 1)
              .map((obj) => {
                return {
                  value: obj.id,
                  label: obj.name,
                  dependencyType: "headquarter",
                };
              }),
            logisticCenters: response.data.data.soDependencyGetDTO
              .filter((obj) => obj.soDependenctTypeId === 2)
              .map((obj) => {
                return {
                  value: obj.id,
                  label: obj.name,
                  dependencyType: "logisticCenter",
                };
              }),
            isSoDependencyTypeLoading: false,
            isSoDependencyTypeDisabled: false,
          });
        });
      }
    }
  };

  handleSoDependencyChange = (event) => {
    const { listScDependenciesBySoDependencyId, getAllRelatedVendingMachinesToSoDependencyId } = this.props;
    const { page, pageSize } = this.state;
    this.setState({
      soDependencyId: event.value,
      soDependencyName: event.label,
      scDependencyId: null,
      scDependencyName: null,
      selectedFilterOption: null,
      selectedMachineGeneration: null,
      selectedSoMachine: null,
      selectedSoMachineName: "",
      addButtonDisabled: false,
      searchButtonDisabled: false,
      vendingMachineSelectorDisabled: false,
      filterOptionSelectorDisabled: false,
      machineGenerationSelectorDisabled: false,
      page,
      onChangeStateSoScVm: true,
      pageSize,
      attribute: "id",
      order: "DESC",
    });

    listScDependenciesBySoDependencyId(event.value, (response) => {
      if (response.data.data.length > 0) {
        this.setState({
          scDependencyList: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
        response.data.data.unshift({
          id: 0,
          name: Strings.generalTerms.all,
        });
      }
    });

    getAllRelatedVendingMachinesToSoDependencyId(event.value, (response) => {
      if (response.data.data.length > 0) {
        this.setState({
          soMachineList: response.data.data.map((item) => ({
            value: item.id,
            label: item.friendlyName,
          })),
        });
      }
    });
  };

  handleScDependencyChange = (event) => {
    const { listVendingMachinesByScDependencyId } = this.props;
    const { vendingMachines, page, pageSize } = this.state;

    this.setState({
      scDependencyId: event.value,
      scDependencyName: event.label,
      selectedMachineGeneration: null,
      selectedSoMachine: null,
      selectedSoMachineName: "",
      selectedFilterOption: null,
      page,
      pageSize,
      attribute: "id",
      order: "DESC",
      addButtonDisabled: false,
      searchButtonDisabled: false,
      vendingMachineSelectorDisabled: false,
      filterOptionSelectorDisabled: false,
      machineGenerationSelectorDisabled: false,
      vendingMachines,
      soMachineList: [],
      onChangeStateSoScVm: true,
    });

    listVendingMachinesByScDependencyId(event.value, (response) => {
      if (response.data.data.length > 0) {
        this.setState({
          soMachineList: response.data.data.map((item) => ({
            value: item.id,
            label: item.friendlyName,
          })),
        });
      }
    });
  };

  handleMachineGenerationChange = (event) => {
    const { scDependencyId, soDependencyId, page, pageSize } = this.state;
    const {
      getAllRelatedVendingMachinesToSoDependencyId,
      listVendingMachinesByScDependencyIdAndGenerationId,
      listVendingMachinesByScDependencyId,
      getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId,
    } = this.props;

    this.setState({
      selectedMachineGeneration: event.value,
      selectedSoMachine: null,
      selectedFilterOption: null,
      page,
      pageSize,
      attribute: "id",
      order: "DESC",
      onChangeStateSoScVm: true,
    });

    if (scDependencyId) {
      if (event.value === -1) {
        listVendingMachinesByScDependencyId(scDependencyId, (response) => {
          if (response.data.data.length > 0) {
            this.setState({
              vendingMachineSelectorDisabled: false,
              soMachineList: response.data.data.map((item) => ({
                value: item.id,
                label: item.friendlyName,
              })),
            });
          } else {
            this.setState({
              vendingMachineSelectorDisabled: true,
            });
          }
        });
      } else {
        listVendingMachinesByScDependencyIdAndGenerationId(scDependencyId, event.value, (response) => {
          if (response.data.data.length > 0) {
            this.setState({
              vendingMachineSelectorDisabled: false,
              soMachineList: response.data.data.map((item) => ({
                value: item.id,
                label: item.friendlyName,
              })),
            });
          } else {
            this.setState({
              vendingMachineSelectorDisabled: true,
            });
          }
        });
      }
    } else if (event.value === -1) {
      getAllRelatedVendingMachinesToSoDependencyId(soDependencyId, (response) => {
        if (response.data.data.length > 0) {
          this.setState({
            soMachineList: response.data.data.map((item) => ({
              value: item.id,
              label: item.friendlyName,
            })),
          });
        }
      });
    } else {
      getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId(soDependencyId, event.value, (response) => {
        this.setState({
          soMachineList: response.data.data.map((item) => ({
            value: item.id,
            label: item.friendlyName,
          })),
        });
      });
    }
  };

  handleVendingMachineChange = (event) => {
    const { page, pageSize } = this.state;
    this.setState({
      selectedSoMachineName: event.label,
      selectedSoMachine: event.value,
      selectedFilterOption: null,
      page,
      pageSize,
      attribute: "id",
      order: "DESC",
    });
  };

  handleFilterChange = (event) => {
    const { page, pageSize } = this.state;
    this.setState({
      selectedMachineGeneration: null,
      selectedSoMachine: null,
      selectedFilterOption: event.value,
      page,
      pageSize,
      attribute: "id",
      order: "DESC",
    });
  };

  setSortBy = (attribute, sortOrder) => {
    const { soDependencyId, scDependencyId, selectedMachineGeneration, selectedSoMachineName, selectedFilterOption, vmSerialNumber, page, pageSize } = this.state;
    const { getVendingMachinesFilter, permissions } = this.props;

    this.setState({
      page,
      pageSize,
      attribute: "id",
      order: "DESC",
    });

    if (soDependencyId !== null || scDependencyId !== null) {
      getVendingMachinesFilter(
        soDependencyId,
        scDependencyId === -1 ? "" : scDependencyId || "",
        parseInt(selectedMachineGeneration, 10) > -1 ? parseInt(selectedMachineGeneration, 10) : "",
        parseInt(selectedFilterOption, 10) === 0 ? 1 : 0, // To show only vms without vmContract
        parseInt(selectedFilterOption, 10) === 1 ? 1 : 0, // To show only unconfigured vms
        selectedSoMachineName || "",
        vmSerialNumber || "",
        ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONTRACT GET", permissions.data) ? 1 : 0,
        ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONFIGURATION GET", permissions.data) ? 1 : 0,
        page,
        pageSize,
        attribute,
        sortOrder,
        (response) => {
          if (!response.data && String(response).includes("Error:")) {
            Modal.error({
              // Connection error
              title: Strings.generalResponses.connectionError,
              content: Strings.generalResponses.connectionErrorTooltip,
              onOk: this.setState({
                redirectToHome: true,
              }),
            });
          } else {
            this.setState({
              vendingMachines: response.data.data,
              attribute,
              order: sortOrder,
            });
          }
        }
      );
    }
  };

  disableHandleEvent(vm) {
    const { soDependencyId, scDependencyId, selectedMachineGeneration, selectedSoMachineName, selectedFilterOption, vmSerialNumber, page, pageSize, attribute, order } = this.state;
    const { disableVendingMachineById, getVendingMachinesFilter, permissions } = this.props;

    this.setState({
      page,
      pageSize,
      attribute: "id",
      order: "DESC",
    });

    disableVendingMachineById(vm.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        Modal.error({
          title: Strings.generalResponses.connectionError,
          content: Strings.machine.disableMachineError,
          isLoading: false,
        });
      } else {
        getVendingMachinesFilter(
          soDependencyId,
          scDependencyId === -1 ? "" : scDependencyId || "",
          parseInt(selectedMachineGeneration, 10) > -1 ? parseInt(selectedMachineGeneration, 10) : "",
          parseInt(selectedFilterOption, 10) === 0 ? 1 : 0, // To show only vms without vmContract
          parseInt(selectedFilterOption, 10) === 1 ? 1 : 0, // To show only unconfigured vms
          selectedSoMachineName || "",
          vmSerialNumber || "",
          ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONTRACT GET", permissions.data) ? 1 : 0,
          ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONFIGURATION GET", permissions.data) ? 1 : 0,
          page,
          pageSize,
          attribute,
          order,
          (response) => {
            if (!response.data && String(response).includes("Error:")) {
              // Connection error
              Modal.error({
                title: Strings.generalResponses.connectionError,
                content: Strings.generalResponses.connectionErrorTooltip,
                onOk: this.setState({ redirectToHome: true }),
              });
            } else {
              this.setState({
                vendingMachines: response.data.data,
              });
            }
          }
        );
      }
    });
  }

  showPagination = (currentPage, pageSize) => {
    const { soDependencyId, scDependencyId, selectedMachineGeneration, selectedSoMachineName, selectedFilterOption, vmSerialNumber, attribute, order, page } = this.state;
    const { getVendingMachinesFilter, permissions } = this.props;
    this.setState({
      page,
      pageSize,
    });
    getVendingMachinesFilter(
      soDependencyId,
      scDependencyId === -1 ? "" : scDependencyId || "",
      parseInt(selectedMachineGeneration, 10) > -1 ? parseInt(selectedMachineGeneration, 10) : "",
      parseInt(selectedFilterOption, 10) === 0 ? 1 : 0, // To show only vms without vmContract
      parseInt(selectedFilterOption, 10) === 1 ? 1 : 0, // To show only unconfigured vms
      selectedSoMachineName || "",
      vmSerialNumber || "",
      ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONTRACT GET", permissions.data) ? 1 : 0,
      ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONFIGURATION GET", permissions.data) ? 1 : 0,
      currentPage,
      pageSize,
      attribute,
      order,
      (response) => {
        this.setState({
          vendingMachines: response.data.data,
          page: currentPage,
          pageSize,
        });
      }
    );
  };

  ShowPaginationBySizeChage = (page, pageSize) => {
    const { soDependencyId, scDependencyId, selectedMachineGeneration, selectedSoMachineName, selectedFilterOption, vmSerialNumber, attribute, order } = this.state;
    const { getVendingMachinesFilter, permissions } = this.props;

    this.setState({
      page,
      pageSize,
    });

    getVendingMachinesFilter(
      soDependencyId,
      scDependencyId === -1 ? "" : scDependencyId || "",
      parseInt(selectedMachineGeneration, 10) > -1 ? parseInt(selectedMachineGeneration, 10) : "",
      parseInt(selectedFilterOption, 10) === 0 ? 1 : 0, // To show only vms without vmContract
      parseInt(selectedFilterOption, 10) === 1 ? 1 : 0, // To show only unconfigured vms
      selectedSoMachineName || "",
      vmSerialNumber || "",
      ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONTRACT GET", permissions.data) ? 1 : 0,
      ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONFIGURATION GET", permissions.data) ? 1 : 0,
      page,
      pageSize,
      attribute,
      order,
      (response) => {
        this.setState({
          vendingMachines: response.data.data,
        });
      }
    );
  };

  queryRefresh = () => {
    const { soDependencyId, scDependencyId, selectedMachineGeneration, selectedSoMachineName, selectedFilterOption, vmSerialNumber, page, pageSize, attribute, order } = this.state;
    const { getVendingMachinesFilter, permissions } = this.props;
    this.setState({
      page,
      pageSize,
    });
    getVendingMachinesFilter(
      soDependencyId,
      scDependencyId === -1 ? "" : scDependencyId || "",
      parseInt(selectedMachineGeneration, 10) > -1 ? parseInt(selectedMachineGeneration, 10) : "",
      parseInt(selectedFilterOption, 10) === 0 ? 1 : 0, // To show only vms without vmContract
      parseInt(selectedFilterOption, 10) === 1 ? 1 : 0, // To show only unconfigured vms
      selectedSoMachineName || "",
      vmSerialNumber || "",
      ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONTRACT GET", permissions.data) ? 1 : 0,
      ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE WITHOUT CONFIGURATION GET", permissions.data) ? 1 : 0,
      page,
      pageSize,
      attribute,
      order,
      (response) => {
        this.setState({
          vendingMachines: response.data.data,
        });
      }
    );
  };

  machineFullTable(scDependencyList, vendingMachines, soDependencyType, isLoading, page, pageSize, onChangeStateSoScVm, soDependencyTypes, headQuarters, logisticCenters) {
    return (
      <div>
        <div className="col-12">
          <MachineTable
            scDependencyList={scDependencyList}
            machines={vendingMachines}
            isLoading={isLoading}
            flag={soDependencyType}
            setSortBy={this.setSortBy}
            vmToDisable={(row) => this.disableHandleEvent(row)}
            queryRefresh={this.queryRefresh}
            soDependencyTypes={soDependencyTypes}
            headQuarters={headQuarters}
            logisticCenters={logisticCenters}
          />
          <br />
        </div>
        <div className="col-md-6" />
        <div className="col-md-6">
          <Pagination
            size="small"
            total={vendingMachines.totalElements}
            showSizeChanger
            onChange={this.showPagination}
            onShowSizeChange={this.ShowPaginationBySizeChage}
            hideOnSinglePage={false}
            pageSizeOptions={["10", "25", "50", "100"]}
            showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total} ${Strings.generalTerms.items}`}
            current={page}
            pageSize={pageSize}
            disabled={(vendingMachines.content ? !(vendingMachines.content.length > 0) : true) || onChangeStateSoScVm === true}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      onChangeStateSoScVm,
      soDependencyId,
      soDependencyName,
      soDependencyType,
      scDependencyId,
      scDependencyName,
      scDependencyList,

      soMachineList,
      vendingMachines,
      machineGenerations,
      filterOptions,
      isLoading,

      headQuarters,
      logisticCenters,
      soDependencyTypes,

      selectedFilterOption,
      selectedMachineGeneration,
      selectedSoMachine,

      addButtonDisabled,
      searchButtonDisabled,
      vendingMachineSelectorDisabled,
      filterOptionSelectorDisabled,
      machineGenerationSelectorDisabled,

      redirectToHome,
      redirectToVMCreation,

      page,
      pageSize,
    } = this.state;

    const {
      userDependency: { dependencyTypeId },
      userDependency: { dependencyId },
      permissions: { data },
    } = this.props;

    if (redirectToHome) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    if (redirectToVMCreation) {
      return (
        <Redirect
          to={{
            pathname: "/createVM",
            props: {
              soDependencyId,
              soDependencyName,
              scDependencyId,
              scDependencyName,
            },
          }}
        />
      );
    }

    return (
      <div className="content-container">
        <div className="row" onClick={() => console.log(headQuarters, logisticCenters, soDependencyTypes)}>
          <Titles title={Strings.machine.machines} tooltip={Strings.machine.machinesTooltip} />
        </div>
        <div className="row">
          <div className="col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters}</h3>
            </Divider>
          </div>
        </div>
        <div className="row">
          <SoScVmDependencySelector
            onSoDependencyChange={this.handleSoDependencyChange}
            onScDependencyChange={this.handleScDependencyChange}
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            hasSc
            hasScAll
          />
        </div>
        <div className="row">
          <div className="vertSpace col-xs-12 col-md-6">
            <SelectGeneral
              text={Strings.machine.machineGeneration}
              tooltip={Strings.machine.machineGenerationTooltip}
              options={machineGenerations}
              disabled={machineGenerationSelectorDisabled}
              onChange={this.handleMachineGenerationChange}
              defaultValue={selectedMachineGeneration}
            />
          </div>
          <div className="vertSpace col-xs-12 col-md-6">
            <SelectGeneral
              text={Strings.machine.machine}
              tooltip={Strings.machine.machinesFilterTooltip}
              options={soMachineList}
              disabled={!!(vendingMachineSelectorDisabled || soMachineList.length === 0)}
              onChange={this.handleVendingMachineChange}
              defaultValue={selectedSoMachine}
            />
          </div>
        </div>

        <div className="row">
          {filterOptions.length === 1 ? (
            <div className="vertSpace col-xs-12 col-md-6" />
          ) : (
            <div className="vertSpace col-xs-12 col-md-6">
              <SelectGeneral
                text={Strings.generalTerms.filterBy}
                tooltip={Strings.machine.filterByTooltip}
                options={filterOptions}
                disabled={filterOptionSelectorDisabled}
                onChange={this.handleFilterChange}
                defaultValue={selectedFilterOption}
              />
            </div>
          )}
          <div className="vertSpace col-xs-12 col-md-6">
            <div className="row">
              <div className="col-md-7">
                <Tooltip title={Strings.transaction.getTransactionsToolTip}>
                  <Button
                    style={{ margin: "5px" }}
                    type="primary"
                    size={!searchButtonDisabled ? "large" : "default"}
                    onClick={(e) => this.onClickCheckButton(e)}
                    icon="search"
                    disabled={(searchButtonDisabled === true && dependencyId === null) || soDependencyId === null}
                    loading={isLoading}
                  >
                    {Strings.generalTerms.check}
                  </Button>
                </Tooltip>
              </div>
              <div className="col-md-5">
                {dependencyTypeId === 0 ? (
                  <ValidatePermissionForComponent permission="PRIVILEGE VENDING MACHINE POST" permissions={data}>
                    <AddButton disabled={addButtonDisabled} tooltip={Strings.machine.newMachineTooltip} onClick={(e) => this.onClickAddButton(e)} />
                  </ValidatePermissionForComponent>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Divider orientation="left">
              <h3>{Strings.machine.machines}</h3>
            </Divider>
          </div>
        </div>
        <QueryResponse
          dataSourceLength={vendingMachines.numberOfElements}
          callback={this.machineFullTable(
            scDependencyList,
            vendingMachines,
            soDependencyType,
            isLoading,
            page,
            pageSize,
            onChangeStateSoScVm,
            soDependencyTypes,
            headQuarters,
            logisticCenters
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    vendingMachines: state.vendingMachines,
    vmKeylist: state.vmKeylist,
    noahub: state.noahubKeylist,
    firmwareVersion: state.firmwareVersion,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listScDependenciesBySoDependencyId,
  getVendingMachinesFilter,
  getLowerRelationsBySoDependencyId,
  listAllSoDependencyTypes,
  listScDependenciesByVenidngMachineIds,
  listVendingMachinesByScDependencyId,
  listVendingMachinesByScDependencyIdAndGenerationId,
  getAllRelatedVendingMachinesToSoDependencyId,
  getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId,
  disableVendingMachineById,
  getAllMachinGenerations,
})(ListVM);
