import React, { Fragment } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Tooltip, Icon, Button, Spin } from "antd";
import "./buttons.css";
import Strings from "../../../systemVariables/languageStrings";

export function DeleteButton({ isIconButton, link, props, tooltip, isDisabled, loading, onClick, hideIcon }) {
  return (
    <>
      <Link
        to={{
          pathname: link,
          props,
        }}
      >
        <Tooltip title={tooltip}>
          {!isIconButton ? (
            <Button onClick={onClick} disabled={isDisabled} loading={loading} className="button">
              {loading ? "" : <Icon type="delete" theme="twoTone" twoToneColor="#c4541a" hidden={hideIcon} />}
              <span>{Strings.generalTerms.delete}</span>
            </Button>
          ) : (
            <Spin spinning={loading}>
              <Icon style={{ fontSize: "25px", padding: "0.2em", display: "block" }} onClick={isDisabled ? null : onClick} type="delete" theme="twoTone" twoToneColor="#c4541a" hidden={hideIcon} />
            </Spin>
          )}
        </Tooltip>
      </Link>
    </>
  );
}
