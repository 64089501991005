import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from '../reducers';

export const store = createStore(
    
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
    )
);


/*export default function configureStore(initialState){
    const createStoreWithMidleware = applyMiddleware(ReduxPromise)(createStore);
    return createStoreWithMidleware(rootReducer)
}*/

