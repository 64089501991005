import languageStrings from '../../systemVariables/languageStrings';
import _ from "lodash";

export function helixTypeLocalization(helixL, helixH, spaces, turnTypeL, length){
    var motorName;

    if(turnTypeL === "RIGHT"){
        motorName = helixL + "mm-" + spaces + "-" + languageStrings.generalTerms.rightShort + " " + length;
    } else{
        motorName = helixL + "mm-" + spaces + "-" + languageStrings.generalTerms.leftShort + " - " + length;
    }        

    return motorName;
}

export function helixTypeLocalizationArray(helixArray){
    helixArray.forEach( item =>{
        if(item.label.includes("RIGHT")){
            let label = _.split(item.label, " - ")
            label[0]=label[0].substring(0,label[0].lastIndexOf("-")) + "-" + languageStrings.generalTerms.rightShort

            item.label = label[0] + " - " + label[1];
        } else {
            let label = _.split(item.label, " - ")
            label[0]=label[0].substring(0,label[0].lastIndexOf("-")) + "-" + languageStrings.generalTerms.leftShort

            item.label = label[0] + " - " + label[1];
        }
    })
    return helixArray;
}