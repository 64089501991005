import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/vendingmachine";

export const LIST_VENDINGMACHINES = "LIST_VENDINGMACHINES";
export const LIST_VENDINGMACHINE = "LIST_VENDINGMACHINE";
export const LIST_VENDINGMACHINESPLANOGRAMASSIGNED = "LIST_VENDINGMACHINESPLANOGRAMASSIGNED";
export const ERROR = "ERROR";

export function getVendingMachinesFilter(
  soDependencyId,
  scDependencyId,
  generationId,
  showOnlyNotAssigned,
  showOnlyNotConfigured,
  vmName,
  vmSerial,
  hasPermissionToShowUnassignedVm,
  hasPermissionToShowUnconfiguredVm,
  page,
  size,
  attribute,
  order,
  callback
) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          sodependencyid: soDependencyId,
          scdependencyid: scDependencyId,
          generationid: generationId,
          isnotassigned: showOnlyNotAssigned,
          isnotconfig: showOnlyNotConfigured,
          vmname: vmName,
          vmserial: vmSerial,
          haspermissiontoshowunassignedvm: hasPermissionToShowUnassignedVm,
          haspermissiontoshowunconfiguredvm: hasPermissionToShowUnconfiguredVm,
          page,
          size,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getAllRelatedVendingMachinesToSoDependencyId(soDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          soDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export async function getAllRelatedVendingMachinesToSoDependencyIdFn(soDependencyId) {
  try {
    let response = await axios({
      method: 'GET',
      url: host + port + path + version + resource,
      params: {
        soDependencyId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (error) {
    return error
  }
}

export function getAllRelatedVendingMachinesToSoDependencyIdAndGenerationId(soDependencyId, generationId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          soDependencyId,
          generationId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function listVendingMachinesByScDependencyId(scDependencyId, callback) {
  return async () => {
    await axios
      .get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export async function listVendingMachinesByScDependencyIdFn(scDependencyId) {
  try {
    let response = await axios({
      method: 'GET',
      url: host + port + path + version + resource,
      params: {
        scdependencyid: scDependencyId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (error) {
    return error
  }
}

export function listVendingMachinesByScDependencyIdAndGenerationId(scDependencyId, generationId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scdependencyid: scDependencyId,
          generationid: generationId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function listAllVendingMachines(callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getVendingMachinesByIsPlanogramAssigned(scDependencyId, IsPlanogramAssigned, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scDependencyId,
          IsPlanogramAssigned,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
export function getVendingMachineInfoById(vmId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vmid: vmId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// Create Vending Machine
export function addVendingMachine(machineDto, callback) {
  return () => {
    axios({
      method: "post",
      url: host + port + path + version + resource,
      data: JSON.stringify(machineDto),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function listLockerLoanMachinesByScDependency(scDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          scdependency: scDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
export function disableVendingMachineById(vmId, callback) {
  return () => {
    const data = JSON.stringify(vmId);
    axios({
      method: "DELETE",
      url: `${host + port + path + version + resource}`,
      data,
      params: {
        id: vmId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function recoverVendingMachine(vmId, firmwareId, callback) {
  return async () => {
    const data = JSON.stringify(vmId);
    const recovery = "/recovery";
    await axios({
      method: "post",
      url: host + port + path + version + resource + recovery,
      params: {
        id: vmId,
        firmwareId,
      },
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export async function editFriendlyNameVmAsync(vmId, friendlyName) {
  try {
    const editFriendlyNameVm = "/editFriendlyNameVm";
    const response = await axios({
      method: "put",
      url: host + port + path + version + resource + editFriendlyNameVm,
      params: {
        id: vmId,
        friendlyName,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (e) {
    return e;
  }
}

export async function updateSoDependencyVmAsync(vmId, soDependencyId) {
  try {
    const updateSoDependency = "/directClient/update";
    const response = await axios({
      method: "put",
      url: host + port + path + version + resource + updateSoDependency,
      params: {
        id: vmId,
        soDependencyId: soDependencyId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (e) {
    return e;
  }
}

export function getRecoverVendingMachine(vmId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/getrecovery`, {
        params: {
          id: vmId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
export function cleanTestRegister(vmId, fromDate, toDate, cleanVmEvent, callback) {
  return async () => {
    const cleanVmEventByte = cleanVmEvent === true ? 1 : 0;
    const cleantest = "/cleantest";
    await axios({
      method: "post",
      url: host + port + path + version + resource + cleantest,
      params: {
        id: vmId,
        fromDate,
        toDate,
        cleanVmEvent: cleanVmEventByte,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
