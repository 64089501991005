import actions2 from './actions2'

const initState = {
    productsToAdd: [],
    productsToEdit: [],
    productsToRemove: []
};

export default function (state = initState, action) {
    switch (action.type) {
        case actions2.PRODUCTS_TO_ADD:
            return {
                ...state,
                ...{
                    productsToAdd: action.productsToAdd
                }
            };
        case actions2.PRODUCTS_TO_EDIT:
            return {
                ...state,
                ...{
                    productsToEdit: action.productsToEdit
                }
            };
        case actions2.PRODUCTS_TO_REMOVE:
            return {
                ...state,
                ...{
                    productsToRemove: action.productsToRemove
                }
        };
        case actions2.CLEAR_PRODUCTS:
            return {
                productsToAdd: [],
                productsToEdit: [],
                productsToRemove: []
            }

        default:
            return state;
    }
}











