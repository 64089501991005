/* eslint-disable react/no-unused-state */
// Dependencies
import { Icon, InputNumber, Popover, Radio, Spin, Table } from "antd";
import React, { Component } from "react";

import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";

// Language localization
import Strings from "../../systemVariables/languageStrings";
import { lockerModalitiesLocalization } from "../../Utils/LanguageLocalization";

class WingsConfigToAddTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
  }

  wingInfoDetail(e, row) {
    const wingDetails = [];

    if (row.wingTypeId === 3) {
      wingDetails.push(
        <div>
          <div className="vertSpace col">
            <p>
              <b>{Strings.machine.lockerType}:</b>{" "}
            </p>
          </div>
          <div className="vertSpace col">
            <p>{row.lockerTypeName}</p>
          </div>
          <div className="vertSpace col">
            <p>
              <b>{Strings.machine.lockerModalities}:</b>{" "}
            </p>
          </div>
        </div>
      );
      row.lockerSlotTypes.forEach((row) => {
        wingDetails.push(
          <div className="vertSpace col">
            <p>{lockerModalitiesLocalization(row.name)}</p>
          </div>
        );
      });
    } else {
      wingDetails.push(
        <div>
          <div className="vertSpace col">
            <p>
              <b>{Strings.machine.maxNumSlots}:</b> {row.maxNumSlots}
            </p>
          </div>
          <div className="vertSpace col">
            <p>
              <b>{Strings.machine.maxNumTrays}:</b> {row.maxNumTrays}
            </p>
          </div>
        </div>
      );

      row.lockerSlotTypes.forEach((row) => {
        wingDetails.push(
          <div className="vertSpace col">
            <p>{row.name}</p>
          </div>
        );
      });
    }
    this.setState({ wingDetails });
  }

  render() {
    const { isLoading, wingInfo, keyPrincipalModule } = this.props;
    const { wingDetails } = this.state;
    var isVisibleControlModuleSlotConfiguration = true;
    wingInfo.length > 1 ? (isVisibleControlModuleSlotConfiguration = false) : (isVisibleControlModuleSlotConfiguration = true);
    const contentControlModuleSlotConfiguration =
      wingInfo[keyPrincipalModule].wingTypeId === 3 && wingInfo.map((wingInfo) => wingInfo.wingTypeId !== 3).includes(true) ? (
        <p>{Strings.machine.lockerWarningVendingWing}</p>
      ) : (
        <InputNumber min={1} max={60} defaultValue={this.props.controlModuleSlotNumber} onChange={(value) => this.props.onChangeControlModuleSlotNumber(value)} />
      );
    const columns = [
      {
        title: <TextWithInfoTooltip name={Strings.machine.wingType} tooltip={Strings.machine.wingTooltip} />,
        dataIndex: "wingTypeName",
        width: "20%",
        align: "center",
      },
      {
        title: Strings.generalTerms.description,
        dataIndex: "wingTypeDescription",
        width: "50%",
        align: "center",
      },
      {
        title: Strings.generalTerms.details,
        align: "center",
        width: "10%",
        render: (row) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div style={{ align: "center", color: "#004173", cursor: "pointer" }} onClick={(e) => this.wingInfoDetail(e, row)}>
            <Popover content={wingDetails || <Spin />} trigger="click" placement="left" title={<b>{Strings.generalTerms.details}</b>}>
              <Icon type="profile" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
            </Popover>
          </div>
        ),
      },
      {
        title: "Control Module",
        align: "center",
        width: "10%",
        render: (row) => (
          <div style={{ align: "center", color: "#004173", cursor: "pointer" }} onClick={(e) => this.wingInfoDetail(e, row)}>
            {row.wingTypeId === 3 ? (
              <Popover
                content={contentControlModuleSlotConfiguration}
                title={Strings.machine.lockerModalitiesLocalization.controlModule}
                trigger="click"
                placement="left"
                defaultVisible={isVisibleControlModuleSlotConfiguration}
              >
                <Radio value={wingInfo.indexOf(row)} />
              </Popover>
            ) : (
              <Radio value={wingInfo.indexOf(row)} />
            )}
          </div>
        ),
      },
      {
        title: Strings.generalTerms.remove,
        align: "center",
        width: "10%",
        render: (row) => (
          <div>
            <Icon style={{ cursor: "pointer", fontSize: 20 }} type="close-circle" theme="twoTone" className="addEditRemoveButton" onClick={() => this.props.removeWing(row)} />
          </div>
        ),
      },
    ];

    return (
      <div className="row">
        <div className="col">
          <Radio.Group onChange={this.props.togglePrincipalModule} value={this.props.keyPrincipalModule} style={{ width: "100%" }}>
            <Table dataSource={wingInfo} pagination={false} columns={columns} loading={isLoading} bordered width="100%" rowKey="tempWingId" />
          </Radio.Group>
        </div>
      </div>
    );
  }
}

export default WingsConfigToAddTable;
