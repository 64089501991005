// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal, Divider } from "antd";

// Language localization
import { identityCardTypeLocalization, rolesLocalization } from "../../../Utils/LanguageLocalization";
import Strings from "../../../systemVariables/languageStrings";

// Components
import Titles from "../../GenericComponents/titles";
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import UserForm from "../Commons/UserForm";
import AssignUsersToVendingMachineForm from "../Commons/AssignUsersToVendingMachineForm";
import SelectRoleForm from "../Commons/SelectRoleForm";
import ScEmployeeInfoForm from "../Commons/ScEmployeeInfoForm";

// Queries
import {
  getScDependencyById,
  listIdentityCardTypes,
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  getSystemScRoles,
  getVendingMachineScRoles,
  listScCostCentersByScDependencyId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  listVendingMachinesByScDependencyId,
  insertClientUser,
} from "../../../actions";

const defaultCountry = { id: 1, code: "+57" };

class AddClientUser extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props;

    this.state = {
      scDependencyId: location.props ? location.props.scDependency.value : null,
      scDependencyName: location.props ? location.props.scDependency.label : null,

      userToAdd: {
        scDependencyId: location.props ? location.props.scDependency.value : null,
        firstName: null,
        middleName: null,
        lastName1: null,
        lastName2: null,
        identityCardTypeId: null,
        identityCardNumber: null,
        phone1: defaultCountry.code,
        phone2: null,
        countryId: defaultCountry.id,
        provinceId: null,
        cityId: null,
        address: null,
        email: null,
        birthDate: null,
        scDepartmentId: null,
        scCostCenterId: null,
        scUserProfileId: null,
        vmRoleIds: [], // So Role Ids
        sysRoleIds: [], // So Role Ids
        vendingMachineIds: [],
      },

      countryCode: "co",
      identityCardTypes: [],
      provinces: [],
      cities: [],
      scCostCenters: [],
      scDepartments: [],
      scUserProfiles: [],
      scSysRoles: [],
      scVmRoles: [],
      vendingMachines: [],

      isSaveButtonLoading: false,
      redirectBackToListClientUsers: false,
    };
  }

  componentDidMount() {
    const {
      getScDependencyById,
      listIdentityCardTypes,
      listCountries,
      listProvincesByCountryId,
      listScCostCentersByScDependencyId,
      listScDepartmentByScDependencyId,
      listScUserProfilesByScDependencyId,
      getSystemScRoles,
      getVendingMachineScRoles,
      listVendingMachinesByScDependencyId,
    } = this.props;
    const { scDependencyId } = this.state;
    if (scDependencyId != null) {
      getScDependencyById(scDependencyId, (response) => {
        this.setState({
          scDependencyName: response.data.data.name,
        });
      });
      listIdentityCardTypes((response) => {
        this.setState({
          identityCardTypes: response.data.data
            .filter((obj) => obj.id !== 0)
            .map((item) => ({
              value: item.id,
              label: identityCardTypeLocalization(item.name),
            })),
        });
      });

      listCountries();

      listProvincesByCountryId(defaultCountry.id, (response) => {
        this.setState({
          provinces: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });

      listScCostCentersByScDependencyId(scDependencyId, (response) => {
        this.setState({
          scCostCenters: response.data.data.map((item) => ({
            value: item.id,
            label: item.name + (item.code ? ` - ${item.code}` : ""),
          })),
        });
      });
      listScDepartmentByScDependencyId(scDependencyId, (response) => {
        this.setState({
          scDepartments: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      listScUserProfilesByScDependencyId(scDependencyId, (response) => {
        this.setState({
          scUserProfiles: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      getSystemScRoles(scDependencyId, (response) => {
        response.data.data.forEach((elem) => {
          elem.roleName = rolesLocalization(elem.roleName);
        });
        this.setState({
          scSysRoles: response.data.data.map((item) => ({
            value: item.id,
            label: item.roleName,
          })),
        });
      });
      getVendingMachineScRoles(scDependencyId, (response) => {
        response.data.data.forEach((elem) => {
          elem.roleName = rolesLocalization(elem.roleName);
        });
        this.setState({
          scVmRoles: response.data.data.map((item) => ({
            value: item.id,
            label: item.roleName,
          })),
        });
      });
      listVendingMachinesByScDependencyId(scDependencyId, (response) => {
        this.setState({
          vendingMachines: response.data.data.map((item) => ({
            value: item.id,
            label: item.friendlyName,
          })),
        });
      });
    }
  }

  setUserToInitialState = () => {
    const { userToAdd } = { ...this.state };

    userToAdd.firstName = null;
    userToAdd.middleName = null;
    userToAdd.lastName1 = null;
    userToAdd.lastName2 = null;
    userToAdd.identityCardTypeId = null;
    userToAdd.identityCardNumber = null;
    userToAdd.phone1 = defaultCountry.code;
    userToAdd.phone2 = null;
    userToAdd.countryId = defaultCountry.id;
    userToAdd.provinceId = null;
    userToAdd.cityId = null;
    userToAdd.address = null;
    userToAdd.email = null;
    userToAdd.birthDate = null;
    userToAdd.scDepartmentId = null;
    userToAdd.scCostCenterId = null;
    userToAdd.scUserProfileId = null;
    userToAdd.vmRoleIds = []; // So Role Ids
    userToAdd.sysRoleIds = []; // So Role Ids
    userToAdd.vendingMachineIds = [];

    this.setState({
      userToAdd,
    });
  };

  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { userToAdd } = { ...this.state };

    userToAdd[id] = value;

    this.setState({
      userToAdd,
    });
  };

  handleSelect = (option, event) => {
    const { value } = option;
    const { listProvincesByCountryId, listCitesByProvincesId } = this.props;
    const { userToAdd } = { ...this.state };

    userToAdd[event.name] = value;

    this.setState({
      userToAdd,
    });

    switch (event.name) {
      case "countryId":
        this.setState({ countryCode: option.code });
        listProvincesByCountryId(value, (response) => {
          this.setState({
            provinces: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            cities: [],
          });
        });
        break;
      case "provinceId":
        listCitesByProvincesId(value, (response) => {
          this.setState({
            cities: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        });
        break;
      default:
    }
  };

  handleMultiSelect = (options, source) => {
    const { userToAdd } = { ...this.state };

    userToAdd[source] = options.map((option) => {
      return parseInt(option, 10);
    });

    this.setState({
      userToAdd,
    });
  };

  addUserEvent = () => {
    const { insertClientUser } = this.props;
    const { userToAdd } = { ...this.state };

    let validateEmail = false;
    if (userToAdd.email === undefined || userToAdd.email === null || userToAdd.email === "" || userToAdd.email === "@") {
      userToAdd.email = "@";
      this.setState({
        userToAdd,
      });
      validateEmail = true;
    } else {
      const regexEmail = /^[\w._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      validateEmail = regexEmail.test(userToAdd.email);
    }

    if (
      userToAdd.firstName &&
      userToAdd.lastName1 &&
      userToAdd.identityCardTypeId &&
      userToAdd.identityCardNumber &&
      userToAdd.countryId &&
      userToAdd.provinceId &&
      userToAdd.cityId &&
      validateEmail &&
      (userToAdd.vmRoleIds.length !== 0 || userToAdd.sysRoleIds.length !== 0)
    ) {
      this.setState({
        isSaveButtonLoading: true,
      });
      insertClientUser(userToAdd, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data.code === 4001) {
          // Entity Already exists and is enabled
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
        } else if (response.data.code === 4013) {
          // Entity Already exists and is disabled
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
        } else if (response.data.code === 0) {
          // Entity successfully saved
          Modal.success({
            title: Strings.generalResponses.successfullCreation,
            content: `${Strings.generalResponses.creationSuccess}: ${response.data.data.firstName} ${response.data.data.lastName1}`,
          });
          this.setUserToInitialState();
        }
      });
      this.setState({
        isSaveButtonLoading: false,
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
    }
  };

  showNoScDependencyWarning = () => {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOkNoScDependencyWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  };

  handleOkNoScDependencyWarning = () => {
    this.setState({
      redirectBackToListClientUsers: true,
    });
  };

  render() {
    const { countries } = this.props;
    const {
      userToAdd,
      countryCode,
      redirectBackToListClientUsers,
      scDependencyId,
      scDependencyName,
      scDepartments,
      scUserProfiles,
      scCostCenters,
      identityCardTypes,
      provinces,
      cities,
      scSysRoles,
      scVmRoles,
      vendingMachines,
      isSaveButtonLoading,
    } = this.state;

    if (redirectBackToListClientUsers) {
      return <Redirect to="/listClientUsers" />;
    }
    if (!scDependencyId) {
      return <div> {this.showNoScDependencyWarning()} </div>;
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.users.addClientUsers} tooltip={Strings.users.addClientUsersTooltip} />
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.scDependency.indirectClient} </h3>
            </Divider>
            <h3>{scDependencyName}</h3>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.parameterization} </h3>
            </Divider>
          </div>
        </div>

        <UserForm
          user={userToAdd}
          identityCardTypes={identityCardTypes}
          countries={countries}
          provinces={provinces}
          cities={cities}
          onChange={this.handleFormChange}
          onSelect={this.handleSelect}
          countryCode={countryCode}
        />

        <ScEmployeeInfoForm user={userToAdd} scDepartments={scDepartments} scUserProfiles={scUserProfiles} scCostCenters={scCostCenters} onSelect={this.handleSelect} />

        <SelectRoleForm user={userToAdd} sysRoles={scSysRoles} vmRoles={scVmRoles} onMultiSelect={this.handleMultiSelect} />

        {userToAdd.vmRoleIds.length !== 0 ? <AssignUsersToVendingMachineForm user={userToAdd} vendingMachines={vendingMachines} onMultiSelect={this.handleMultiSelect} /> : null}

        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link="/listClientUsers" />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton loading={isSaveButtonLoading} onClick={(e) => this.addUserEvent(e)} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countries: state.countries,
  };
}

export default connect(mapStateToProps, {
  getScDependencyById,
  listIdentityCardTypes,
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  getSystemScRoles,
  getVendingMachineScRoles,
  listScDepartmentByScDependencyId,
  listScCostCentersByScDependencyId,
  listScUserProfilesByScDependencyId,
  listVendingMachinesByScDependencyId,
  insertClientUser,
})(AddClientUser);
