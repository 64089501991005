// Dependencies
import { Button, Divider, Icon, Input, Pagination, Popconfirm, Popover, Table } from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
// import { SelectGeneral} from "../GenericComponents/selectComponent/index";

import Column from "antd/lib/table/Column";
import {
  getScDependencyById,
  listScDependenciesBySoDependencyId,
  listScDependencyBySoDependencyIdPaginated,
  listScDependencyType,
  listAllSoDependencyTypes,
  getLowerRelationsBySoDependencyId,
} from "../../actions";

// Components
import { AddButton } from "../GenericComponents/buttons/index";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";
import Titles from "../GenericComponents/titles";
// import { ValidateDependency } from "../../Utils/validateDependency";
import { ValidatePermissionForComponent } from "../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../Utils/validatePermissionForComponentPart";
import QueryResponse from "../GenericComponents/queryResponse/QueryResponse";
import SoDependencySelector from "../GenericComponents/selectComponent/SoDependencySelector";

// Language localization
import Strings from "../../systemVariables/languageStrings";
import { soDependencyTypeLocalization } from "../../Utils/LanguageLocalization";
import { listSoDependencyBySoDependencyType0 } from './../../actions/soDependency.actions';

let scDependencyInfo = [];

class ListScDependency extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Fill direct client info
      soDependencyType0Id: null,
      soDependencyType0Name: null,

      // Fill soDependencyType info
      soDependencyTypeId: null,
      soDependencyTypeName: null,

      // Fill soDependency lower info
      soDependencyNotType0Id: null,
      soDependencyNotType0Name: null,

      // Variables to query
      soDependencyIdQuery: null,

      // Info to pass to Add view
      lastSoDependencyId: null,

      soDependencyTypes: [],
      headQuarters: [],
      logisticCenters: [],

      addButtonDisabled: true,

      editConfirm: false,
      searchText: "",
      scDependencyTypeId: 0,
      scDependencyList: [],

      page: 1,
      pageSize: 10,
      attribute: "name",
      order: "ASC",
      totalElements: 0,
      isLoading: false,
      scDependencytoEdit: null,

      isVendingAllowed: null,
      isProvisioningAllowed: null,

      searchNameText: "",
      searchUniqueIdentifierText: "",
    };
    this.showPagination = this.showPagination.bind(this);
    this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
  }

  componentDidMount() {
    const { userDependency } = this.props;
    const dependencyType = sessionStorage.getItem("dependencyType");
    const dependencyId = parseInt(sessionStorage.getItem("dependencyId"), 10);
    if (dependencyId === 1) {
      this.setState({
        soDependencyIdQuery: dependencyId,
        soDependencyType0Id: dependencyId,
        soDependencyType0Name: userDependency ? (dependencyType === "so" && dependencyId !== 1 ? userDependency.dependencyName : null) : null,
        lastSoDependencyId: userDependency ? (userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyId : null) : null,
      });
    }

    if (dependencyId !== 1) {
      const soDependencyName = userDependency ? (dependencyType === "so" && dependencyId !== 1 ? userDependency.dependencyName : null) : null;
      this.queryScDependencies(dependencyId, soDependencyName);
    }
  }

  /* -----------------------Events-----------------------*/

  handleSelect = async (event) => {
    const soDependencyId = event.value;
    const soDependencyName = event.label;
    this.queryScDependencies(soDependencyId, soDependencyName);

    if (soDependencyId === 2) {
      const { getLowerRelationsBySoDependencyId, listAllSoDependencyTypes } = this.props;
      await listAllSoDependencyTypes((response) => {
        this.setState({
          soDependencyTypes: response.data.data
            .filter((obj) => obj.id !== 3 && obj.id !== 0)
            .map((obj) => ({
              value: obj.id,
              label: soDependencyTypeLocalization(obj.name),
            })),
        });
      });
      await getLowerRelationsBySoDependencyId(soDependencyId, (response) => {
        this.setState({
          headQuarters: response.data.data.soDependencyGetDTO
            .filter((obj) => obj.soDependenctTypeId === 1)
            .map((obj) => {
              return {
                value: obj.id,
                label: obj.name,
                dependencyType: "headquarter",
              };
            }),
          logisticCenters: response.data.data.soDependencyGetDTO
            .filter((obj) => obj.soDependenctTypeId === 2)
            .map((obj) => {
              return {
                value: obj.id,
                label: obj.name,
                dependencyType: "logisticCenter",
              };
            }),
          isSoDependencyTypeLoading: false,
          isSoDependencyTypeDisabled: false,
        });
      });
    }
  };

  queryScDependencies = (soDependencyId, soDependencyName) => {
    const { listScDependencyBySoDependencyIdPaginated, listScDependenciesBySoDependencyId } = this.props;
    const { scDependencyTypeId, pageSize, attribute, order } = this.state;
    this.setState({
      soDependencyIdQuery: soDependencyId,

      soDependencyType0Id: soDependencyId,
      soDependencyType0Name: soDependencyName,

      soDependencyTypeId: null,
      soDependencyTypeName: null,

      soDependencyNotType0Id: null,
      soDependencyNotType0Name: null,

      addButtonDisabled: false,

      isLoading: true,
      lastSoDependencyId: soDependencyId,
    });

    listScDependencyBySoDependencyIdPaginated(soDependencyId, scDependencyTypeId, "", "", 1, pageSize, attribute, order, (response) => {
      this.setState({
        scDependency: response.data.data.content,
        totalElements: response.data.data.totalElements,
        addButtonDisabled: false,
        isLoading: false,
        searchText: "",
        page: 1,
      });
    });

    listScDependenciesBySoDependencyId(soDependencyId, (response) => {
      if (response.data.data) {
        const scDependencyList = response.data.data.map((obj) => {
          const scDependency = {};
          scDependency.id = obj.id;
          scDependency.scDependencyName = obj.name;
          scDependency.scDependencyTypeId = obj.scDependencyTypeId;
          scDependency.phone1 = obj.phone1;
          scDependency.phone2 = obj.phone2;
          scDependency.address = obj.address;
          scDependency.countryId = obj.countryId;
          scDependency.provinceId = obj.provinceId;
          scDependency.cityId = obj.cityId;
          scDependency.ciiuClassId = obj.ciiuClassId;
          scDependency.isVendingAllowed = obj.isVendingAllowed;
          scDependency.isProvisioningAllowed = obj.isProvisioningAllowed;
          return scDependency;
        });
        this.setState({ scDependencyList });
      }
    });
  };

  handleSelectType = () => {
    this.setState({
      scDependency: [],
      page: 1,
      totalElements: 0,
      pageSize: 10,
    });
  };

  scDependencyDetail(row) {
    this.setState({
      isVendingAllowed: 0,
      isProvisioningAllowed: 0,
    });
    const isProvisioningAllowed = row.isProvisioningAllowed > 0 ? Strings.generalTerms.yes : Strings.generalTerms.no;
    const isVendingAllowed = row.isVendingAllowed > 0 ? Strings.generalTerms.yes : Strings.generalTerms.no;

    scDependencyInfo = [];
    scDependencyInfo.push(
      <div>
        <p>
          <b>{Strings.generalTerms.province}:</b> {row.provinceName}
        </p>
        <p>
          <b>{Strings.generalTerms.city}:</b> {row.cityName}
        </p>
        <p>
          <b>{Strings.generalTerms.address}:</b> {row.address}
        </p>
        <p>
          <b>{Strings.generalTerms.phone}:</b> {row.phone1}
        </p>
        <p>
          <b>{Strings.scDependency.isProvisioningAllowed}:</b> {isProvisioningAllowed}
        </p>
        <p>
          <b>{Strings.scDependency.isVendingAllowed}:</b> {isVendingAllowed}
        </p>
      </div>
    );
  }

  getColumnSearchProps = (dataIndex, text) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} by ${text}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(dataIndex, selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(dataIndex, selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => {
      const { searchText } = this.state;
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text !== undefined ? text.toString() : "--"}
        />
      );
    },
  });

  handleSearch = (dataIndex, selectedKeys) => {
    const { listScDependencyBySoDependencyIdPaginated } = this.props;
    const { soDependencyIdQuery, scDependencyTypeId, pageSize, attribute, order } = this.state;
    if (selectedKeys.length > 0) {
      this.setState({
        isLoading: true,
        searchText: selectedKeys[0],
      });

      if (dataIndex === "name") {
        listScDependencyBySoDependencyIdPaginated(soDependencyIdQuery, scDependencyTypeId, selectedKeys[0], "", 1, pageSize, attribute, order, (response) => {
          this.setState({
            scDependency: response.data.data.content,
            totalElements: response.data.data.totalElements,
            isLoading: false,
            searchNameText: selectedKeys[0],
            searchUniqueIdentifierText: "",
            page: 1,
          });
        });
      }

      if (dataIndex === "uniqueIdentifier") {
        listScDependencyBySoDependencyIdPaginated(soDependencyIdQuery, scDependencyTypeId, "", selectedKeys[0], 1, pageSize, attribute, order, (response) => {
          this.setState({
            scDependency: response.data.data.content,
            totalElements: response.data.data.totalElements,
            addButtonDisabled: false,
            isLoading: false,
            searchText: "",
            page: 1,
          });
        });
      }
    }
  };

  handleReset = (clearFilters) => {
    const { listScDependencyBySoDependencyIdPaginated } = this.props;
    const { soDependencyIdQuery, scDependencyTypeId, pageSize, attribute, order } = this.state;
    this.setState({
      isLoading: true,
    });
    clearFilters();
    this.setState({
      searchNameText: "",
      searchUniqueIdentifierText: "",
    });
    listScDependencyBySoDependencyIdPaginated(soDependencyIdQuery, scDependencyTypeId, "", "", 1, pageSize, attribute, order, (response) => {
      this.setState({
        scDependency: response.data.data.content,
        totalElements: response.data.data.totalElements,
        addButtonDisabled: false,
        isLoading: false,
        searchText: "",
        page: 1,
      });
    });
  };

  showPagination(page, pageSize) {
    const { listScDependencyBySoDependencyIdPaginated } = this.props;
    const { soDependencyIdQuery, scDependencyTypeId, attribute, order, searchNameText, searchUniqueIdentifierText } = this.state;
    this.setState({
      isLoading: true,
      page,
      pageSize,
    });

    listScDependencyBySoDependencyIdPaginated(soDependencyIdQuery, scDependencyTypeId, searchNameText, searchUniqueIdentifierText, page, pageSize, attribute, order, (response) => {
      this.setState({
        scDependency: response.data.data.content,
        totalElements: response.data.data.totalElements,
        isLoading: false,
      });
    });
  }

  handleEditConfirm(row) {
    const { getScDependencyById } = this.props;
    getScDependencyById(row.id, (response) => {
      if (response.data.data) {
        this.setState({
          scDependencytoEdit: response.data.data,
          editConfirm: true,
        });
      } else {
        this.setState({
          editConfirm: false,
          scDependencyToEdit: null,
        });
      }
    });
  }

  // disableScDependency(e, row) {
  //   // let vmProductGroupToDisable = {
  //   //   id: null,
  //   //   name: null,
  //   //   description: null,
  //   //   isEnabled: null
  //   // }
  //   // vmProductGroupToDisable.id= row.id;
  //   // vmProductGroupToDisable.name= row.name;
  //   // vmProductGroupToDisable.description = !row.description ? null : row.description;
  //   // vmProductGroupToDisable.isEnabled= 0
  //   // //Validate Object
  //   // this.props.disableVmProductGroup(vmProductGroupToDisable, (response) => {
  //   //   if (!response.data && String(response).includes("Error:")) { //Connection error
  //   //     Modal.error({
  //   //       title: Strings.generalResponses.failedTransaction,
  //   //       content: Strings.generalResponses.saveError
  //   //     })
  //   //   } else if (response.data.code === 4008) { //Repeated entity error
  //   //     Modal.error({
  //   //       title: Strings.generalResponses.failedTransaction,
  //   //       content: Strings.generalResponses.alreadyExistsError
  //   //     })
  //   //   } else if (response.data && response.data.status === "SUCCESS") { //Successfull entity save
  //   //     Modal.success({
  //   //       title: Strings.generalResponses.successfulTransaction,
  //   //       content: Strings.generalResponses.successfulDelete,
  //   //     })
  //   //     this.props.listVmProductGroupBySoDependencyIdPaginated("", this.state.soDependency.id, this.state.page, this.state.pageSize, this.state.attribute, this.state.order, response=>{
  //   //       this.setState({
  //   //       vmProductGroups: response.data.data.content,
  //   //       totalElements: response.data.data.totalElements,
  //   //       addButtonDisabled: false,
  //   //       isLoading: false,
  //   //       searchText: ""
  //   //       });
  //   //     })
  //   //   } else { //Other error
  //   //     Modal.error({
  //   //       title: Strings.generalResponses.failedTransaction,
  //   //       content: Strings.generalResponses.alreadyExistsError
  //   //     })
  //   //   }
  //   // }
  //   //   )
  // }
  methodTableRender(data, isLoading, scDependency, totalElements, page) {
    console.log(scDependency);
    return (
      <div className="row">
        <div className="col-12">
          <Table bordered loading={isLoading} pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="id" dataSource={scDependency}>
            <Column align="center" title={Strings.generalTerms.name} dataIndex="name" key="id" width="25%" {...this.getColumnSearchProps("name", Strings.generalTerms.name)} />

            <Column
              align="center"
              title={<TextWithInfoTooltip name={Strings.scDependency.nit} tooltip={Strings.scDependency.nitTooltip} />}
              dataIndex="uniqueIdentifier"
              width="15% "
              {...this.getColumnSearchProps("uniqueIdentifier", Strings.scDependency.nit)}
            />
            <Column align="center" title={Strings.generalTerms.country} dataIndex="countryName" width="15% " />
            <Column align="center" title={Strings.product.currency} dataIndex="currencyCodeName" width="15% " />
            {/* <Column align="center" title={Strings.generalTerms.phone} dataIndex="phone1" width="15% " /> */}
            {/* <Column align="center" title={Strings.generalTerms.address} dataIndex="address" width="10% " /> */}

            {/* <Column align="center" title={Strings.generalTerms.city} dataIndex="cityName" width="15% " /> */}
            <Column
              align="center"
              title={Strings.generalTerms.details}
              width="15% "
              render={(row) => (
                <div style={{ textAlign: "center", color: "#004173", cursor: "pointer" }} onClick={(e) => this.scDependencyDetail(row)}>
                  <Popover content={scDependencyInfo} trigger="click" placement="left" title={<TextWithInfoTooltip name={Strings.generalTerms.details} />}>
                    <Icon type="profile" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
                  </Popover>
                </div>
              )}
            />

            {ValidatePermissionForComponentPart("PRIVILEGE SC DEPENDENCIES PUT", data) ? (
              <Column
                title={Strings.generalTerms.edit}
                align="center"
                width="15%"
                render={(row) => (
                  <div style={{ cursor: "pointer" }}>
                    <Popconfirm
                      title={Strings.scDependency.editScDependencyWarn}
                      onConfirm={() => this.handleEditConfirm(row)}
                      okText={Strings.generalTerms.yes}
                      cancelText={Strings.generalTerms.no}
                    >
                      <Icon type="edit" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
                    </Popconfirm>
                  </div>
                )}
              />
            ) : null}
          </Table>
        </div>
        <div className="col-xs-0  col-md-6" />
        <div className="vertSpace col-md-6">
          <Pagination
            size="small"
            total={totalElements}
            showSizeChanger
            onChange={this.showPagination}
            onShowSizeChange={this.showPagination}
            pageSizeOptions={["10", "25", "50", "100"]}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            current={page}
            disabled={!(totalElements > 0)}
          />
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    clearInterval(this.scDependency);
  }

  // Information to render
  render() {
    const {
      permissions: { data },
    } = this.props;

    const {
      scDependency,
      editConfirm,
      soDependencyTypes,
      scDependencytoEdit,
      soDependencyType0Id,
      soDependencyType0Name,
      soDependencyTypeId,
      soDependencyTypeName,
      soDependencyNotType0Id,
      soDependencyNotType0Name,
      lastSoDependencyId,
      scDependencyList,
      addButtonDisabled,
      isLoading,
      totalElements,
      page,
      headQuarters,
      logisticCenters,
    } = this.state;
    // Redirects to editScDependency for editing
    if (editConfirm) {
      return (
        <Redirect
          to={{
            pathname: "/editScDependency",
            props: {
              scDependencytoEdit,
              soDependencyTypes,
              headQuarters,
              logisticCenters,
              soDependencyType0Id,
              soDependencyType0Name,
              soDependencyTypeId,
              soDependencyTypeName,
              soDependencyNotType0Id,
              soDependencyNotType0Name,
              lastSoDependencyId,
            },
          }}
        />
      );
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.scDependency.indirectClient} tooltip={Strings.scDependency.indirectClientTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <SoDependencySelector onSoDependencyChange={this.handleSelect} onDependencyTypeChange={this.handleSelectType} />
          </div>
        </div>

        <ValidatePermissionForComponent permission="PRIVILEGE SC DEPENDENCIES POST" permissions={data}>
          <div className="vertSpace row">
            <div className="vertSpace col-md-6" />
            <div className="vertSpace col-md-6">
              <AddButton
                tooltip={Strings.scDependency.addScDependencyTooltip}
                link={addButtonDisabled ? "" : "/addScDependency"}
                props={
                  addButtonDisabled
                    ? ""
                    : {
                        scDependencyList,
                        soDependencyType0Id,
                        soDependencyType0Name,
                        soDependencyTypeId,
                        soDependencyTypeName,
                        soDependencyNotType0Id,
                        soDependencyNotType0Name,
                        lastSoDependencyId,
                      }
                }
                disabled={addButtonDisabled}
              />
            </div>
          </div>
        </ValidatePermissionForComponent>
        <Divider orientation="left">
          <h3>{Strings.scDependency.indirectClients} </h3>
        </Divider>
        <QueryResponse isLoading={isLoading} callback={this.methodTableRender(data, isLoading, scDependency, totalElements, page)} dataSourceLength={totalElements} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    soDependenciesNotType0: state.soDependencies,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    scdependencytype: state.scdependencytypeid,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listScDependencyBySoDependencyIdPaginated,
  getScDependencyById,
  listScDependencyType,
  listAllSoDependencyTypes,
  getLowerRelationsBySoDependencyId
})(ListScDependency);
