import React, { Fragment } from "react";
import { Button } from "antd";
import "./buttons.css";

//Language localization
import Strings from "../../../systemVariables/languageStrings";

export function YesNoButton(props) {
  var isDisabled = false;
  if (props.isDisabled != null) {
    isDisabled = props.isDisabled;
  }
  var status = false;
  if (props.status != null) {
    status = props.status;
  }
  var yesText = Strings.generalTerms.yes;
  if (props.yesText != null) {
    yesText = props.yesText;
  }
  var noText = Strings.generalTerms.no;
  if (props.noText != null) {
    noText = props.noText;
  }

  return (
    <Fragment>
      <Button onClick={props.onClick} className="button" disabled={isDisabled}>
        <span>{status ? yesText : noText}</span>
      </Button>
    </Fragment>
  );
}
