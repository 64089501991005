import React from 'react';
import { Tooltip, Icon } from 'antd';
import "./generalComponents.css"

export default function TextWithInfoTooltip(props){
    return (
        <div className="aligning" style={props.style}>            
            <span>{props.name}</span>
            <Tooltip title={props.tooltip}>
                <Icon className="icon" type="info-circle" />
            </Tooltip>
        </div>
    )
}