import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAllAdjustmentTypes,
  getNotAdjustedUnderstockedVmProductTransactionsByIdAndTransactionDateAfter,
  getUnderstockedVmProductTransactionWithoutAdjustment,
} from "../../../actions";

import { Button, Collapse, Divider, Tooltip } from "antd";
import Strings from "../../../systemVariables/languageStrings";
import { RangeDate } from "../../GenericComponents/DateComponent/RangeDate";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";
import SoScVmDependencySelector from "../../GenericComponents/selectComponent/SoScVmDependencySelector";
import Titles from "../../GenericComponents/titles";
import UnderStockedTransactionsTable from "./UnderStockedTransactionsTableComponent";
import "./UnderstockedTransactionAdjustment.css";

const INITIAL_START_DATE = moment().hour(0).minutes(0).second(0);
const INITIAL_END_DATE = moment().hour(23).minutes(59);
const { Panel } = Collapse;

class UnderstockedTransactionAdjusment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selector: "",
      selectedSoDependencyId: null,
      selectedScDependencyId: null,
      selectedVendingMachine: null,

      isLoading: false,
      isDateSelectorDisabled: false,
      startDate: INITIAL_START_DATE,
      endDate: INITIAL_END_DATE,

      tableData: [],
      adjustmentTypes: [],
      underStockedTransactionsGroupByScDependencyName: [],
    };
  }

  componentDidMount() {
    /* Destructuring the props object and assigning the values to variables. */
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      getAllAdjustmentTypes,
      soScSelectionProps: { so, sc },
    } = this.props;
    getAllAdjustmentTypes((response) => {
      this.setState({
        adjustmentTypes: response.data.data,
      });
    });
    // StockOwner User different to Noatec
    if (dependencyType === "so" && dependencyId !== null && dependencyId !== 1) {
      this.setState({
        selectedSoDependencyId: dependencyId,
        selector: "SO",
      });
    }

    if (so !== null) {
      this.setState({
        selectedSoDependencyId: so.id,
        selector: "SO",
      });
    }

    if (sc !== null) {
      if (sc.id !== -1) {
        this.setState({
          selectedScDependencyId: sc.id,
          selector: "SC",
        });
      } else {
        this.setState({
          selectedScDependencyId: sc.id,
          selector: "SO",
        });
      }
    }
  }

  handleSoDependencyChange = (event, name) => {
    const { value: soDependencyId } = event;
    const { pageSize } = this.state;
    this.setState({
      selector: name,
      selectedSoDependencyId: soDependencyId,
      underStockedTransactionsGroupByScDependencyName: [],
    });
  };

  handleScDependencyChange = (event, name) => {
    const { value: scDependencyId } = event;
    if (scDependencyId === -1) {
      this.setState({
        selector: "SO",
        underStockedTransactionsGroupByScDependencyName: [],
      });
    } else {
      this.setState({
        selector: name,
        selectedScDependencyId: scDependencyId,
        underStockedTransactionsGroupByScDependencyName: [],
      });
    }
  };

  handleVendingMachineChange = (event, name) => {
    const { value: vendingMachineId } = event;
    this.setState({
      selector: name,
      selectedVendingMachine: vendingMachineId,
      underStockedTransactionsGroupByScDependencyName: [],
    });
  };

  handleDateChange = (date) => {
    const startDate = date[0];
    const endDate = date[1];
    this.setState({
      startDate,
      endDate,
      underStockedTransactionsGroupByScDependencyName: [],
    });
  };

  onChangeClick = () => {
    const { selector, selectedSoDependencyId, selectedScDependencyId, selectedVendingMachine } = this.state;
    this.setState({ underStockedTransactionsGroupByScDependencyName: [] });
    this.newMethodSelector(selector, selectedSoDependencyId, selectedScDependencyId, selectedVendingMachine);
  };

  getUnderstockedTransactionWithoutAdjustment(soDependencyId, scDependencyId, vendingMachineId, startDate, endDate) {
    const { getUnderstockedVmProductTransactionWithoutAdjustment } = this.props;

    getUnderstockedVmProductTransactionWithoutAdjustment(soDependencyId, scDependencyId, vendingMachineId, startDate, endDate, (ans) => {
      if (ans.data.data) {
        const underStockedTransactionsGroupByScDependencyName = _.groupBy(ans.data.data, "scDependencyName");
        this.setState({
          underStockedTransactionsGroupByScDependencyName,
        });
      }

      this.setState({
        isLoading: false,
      });
    });
  }

  compareByVendingMachineName(a, b) {
    if (a.vmFriendlyName < b.vmFriendlyName) {
      return -1;
    }
    if (a.vmFriendlyName > b.vmFriendlyName) {
      return 1;
    }
    return 0;
  }

  renderCustomeCollapse(underStockedTransactions) {
    const { isLoading, adjustmentTypes } = this.state;
    const scDependencyNames = Object.keys(underStockedTransactions);

    const amountByScDependency = _.mapValues(underStockedTransactions, (scDependency) => scDependency.length);

    return (
      <div className="row vertSpace" style={{ justifyContent: "center" }}>
        <Collapse accordion className="customCollapse">
          {scDependencyNames.map((panelName, index) => (
            <Panel
              header={
                <div className="customPanel">
                  <p className="panelTitle" style={{ paddingLeft: "0.5em" }}>
                    {panelName}
                  </p>
                  <p className="panelSubTitle" style={{ paddingLeft: "0.5em" }}>
                    {"(" + Strings.dispensationRule.amount + ": " + amountByScDependency[panelName] + ")"}
                  </p>
                </div>
              }
              key={index}
            >
              <div className="row">{this.resultTableMethod(panelName, underStockedTransactions[panelName].sort(this.compareByVendingMachineName), isLoading, adjustmentTypes)}</div>
            </Panel>
          ))}
        </Collapse>
      </div>
    );
  }

  newMethodSelector(selector, selectedSoDependencyId, selectedScDependencyId, selectedVendingMachine) {
    const { startDate, endDate } = this.state;
    this.setState({
      isLoading: true,
      underStockedTransactionsGroupByScDependencyName: [],
    });

    switch (selector) {
      case "SO":
        this.getUnderstockedTransactionWithoutAdjustment(selectedSoDependencyId, null, null, startDate.format(), endDate.format());
        break;
      case "SC":
        this.getUnderstockedTransactionWithoutAdjustment(selectedSoDependencyId, selectedScDependencyId, null, startDate.format(), endDate.format());
        break;
      case "VM":
        this.getUnderstockedTransactionWithoutAdjustment(selectedSoDependencyId, selectedScDependencyId, selectedVendingMachine, startDate.format(), endDate.format());
        break;
    }
  }

  resultTableMethod(panelName, underStockedTransactions, isLoading, adjustmentTypes) {
    return (
      <div className="col">
        <UnderStockedTransactionsTable
          tableData={underStockedTransactions}
          isLoading={isLoading}
          adjustmentTypes={adjustmentTypes}
          removeRecordFromUnderStockedTransactionsTable={(id) => this.removeRecordFromUnderStockedTransactionsTable(id, panelName)}
        />
      </div>
    );
  }

  removeRecordFromUnderStockedTransactionsTable = (id, panelName) => {
    const { underStockedTransactionsGroupByScDependencyName } = this.state;
    underStockedTransactionsGroupByScDependencyName[panelName] = underStockedTransactionsGroupByScDependencyName[panelName].filter((object) => object.id !== id);
    this.setState({
      underStockedTransactionsGroupByScDependencyName,
    });
  };

  render() {
    const { selectedSoDependencyId, isLoading, selector, startDate, endDate, isDateSelectorDisabled, underStockedTransactionsGroupByScDependencyName } = this.state;

    return (
      <>
        <div className="content-container" style={{ height: "100%" }}>
          <div className="row">
            <Titles title={Strings.transaction.underStockedTransactionAdjustment} tooltip={Strings.transaction.underStockedTransactionAdjustmentTooltip} />
          </div>

          <div className="row vertSpace">
            <div className="col">
              <Divider orientation="left">
                <h3>{Strings.generalTerms.generalParameters} </h3>
              </Divider>
            </div>
          </div>

          <div className="row vertSpace">
            <SoScVmDependencySelector
              onSoDependencyChange={this.handleSoDependencyChange}
              onScDependencyChange={this.handleScDependencyChange}
              onVendingMachineChange={this.handleVendingMachineChange}
              soDependencyHtmlTag="SO"
              scDependencyHtmlTag="SC"
              vendingMachineHtmlTag="VM"
              hasSc
              hasVm
              hasScAll
              soDependencyId={selectedSoDependencyId}
            />

            <div className="col-md-6 vertSpace">
              <RangeDate
                text={Strings.dispensationReport.transactionDate}
                tooltip={Strings.dispensationReport.transactionDateTooltip}
                startDate={startDate}
                endDate={endDate}
                onChange={this.handleDateChange}
                isDisabled={isDateSelectorDisabled}
                format="MMMM Do YYYY, h:mm:ss a"
              />
            </div>
          </div>
          <div className="row vertSpace">
            <div className="col-md-6 vertSpace" />
            <div className="col-md-6 vertSpace">
              <Tooltip title={Strings.transaction.getTransactionsToolTip} placement="bottom">
                <Button type="primary" size="large" icon="search" onClick={this.onChangeClick} disabled={selector === ""} loading={isLoading}>
                  {Strings.generalTerms.check}
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className="row vertSpace">
            <div className="col">
              <Divider orientation="left" width="100%">
                <h3>{Strings.transaction.transactionsToAdjust} </h3>
              </Divider>
              <br />
            </div>
          </div>
          <QueryResponse
            isLoading={isLoading}
            callback={this.renderCustomeCollapse(underStockedTransactionsGroupByScDependencyName)}
            dataSourceLength={Object.keys(underStockedTransactionsGroupByScDependencyName).length}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDependency: state.dependencyByUser.dependencyObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  getNotAdjustedUnderstockedVmProductTransactionsByIdAndTransactionDateAfter,
  getAllAdjustmentTypes,
  getUnderstockedVmProductTransactionWithoutAdjustment,
})(UnderstockedTransactionAdjusment);
