import React, { Component } from "react";
import { Modal } from "antd";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../systemVariables/TimeConfig";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

class MoreInfoDetailsModal extends Component {
  handleOk = () => {
    const { setVisible, clearImage, clearModal } = this.props;

    setVisible(false);
    clearImage();
    clearModal();
  };

  handleCancel = () => {
    const { setVisible, clearImage, clearModal } = this.props;

    setVisible(false);
    clearImage();
    clearModal();
  };

  renderTitle = (rowDetails) => {
    return (
      <>
        <h2 className="text-center">
          {Strings.transaction.transactionType}: {rowDetails.lockerSlotFunctionality}
        </h2>
        <div className="text-center">
          {Strings.planogram.slotNumber}: {rowDetails.slotNumber}
        </div>
      </>
    );
  };

  render() {
    const { rowDetails, isVisible, image } = this.props;
    return (
      <Modal title={this.renderTitle(rowDetails)} visible={isVisible} onOk={this.handleOk} onCancel={this.handleCancel} width="50%" footer={[]}>
        <div className="row">
          <div className="col-md-6">
            {Strings.product.soProduct}: {rowDetails.soProductName ? rowDetails.soProductName : "--"}
          </div>
          <div className="col-md-6">
            {Strings.product.erpCode}: {rowDetails.soProductCode ? rowDetails.soProductCode : "--"}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {Strings.Roles.provisioner}: {rowDetails.provisionerName ? rowDetails.provisionerName : "--"}
          </div>
          <div className="col-md-6">
            {Strings.dispensationReport.transactionDate}: {moment(rowDetails.transactionDate).format(DATE_TIME_FORMAT)}
          </div>
        </div>
        {rowDetails.userName && (
          <div className="row">
            <div className="col-md-6">
              {Strings.transaction.recipient}: {rowDetails.userName ? rowDetails.userName : "--"}
            </div>
            <div className="col-md-6">
              {Strings.generalTerms.phone}: {rowDetails.userPhone}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            <img alt="Product" src={image} style={{ maxHeight: "150px", margin: "auto", display: "block" }} />
          </div>
        </div>
      </Modal>
    );
  }
}
export default MoreInfoDetailsModal;
