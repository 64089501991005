import React from "react";
import { connect } from "react-redux";

// Components
import { Card, Timeline } from "antd";
import moment from "moment";
import { motorsControllerCommandStatusLocalization, transactionTypeLocalization } from "../../../Utils/LanguageLocalization";
import { DATE_TIME_FORMAT } from "../../../systemVariables/TimeConfig";
import Strings from "../../../systemVariables/languageStrings";

// Images
import VendigMachine from "../../Global/images/ComponentsSVG/VendigMachine";

const { Meta } = Card;

const MachineTransactionSummary = ({ transactionInfo }) => {
  const MachineTitleRender = () => {
    return (
      <>
        <div className="row" style={{ margin: "10px" }}>
          <Meta
            avatar={<VendigMachine width={40} fill={"#004173"} />}
            style={{ margin: "5px" }}
            title={
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-12">
                  <h2 className="paragraphSubTitle">{Strings.machine.machineInformation}</h2>
                </div>
              </div>
            }
          />
        </div>
      </>
    );
  };

  const MachineTransactionInfoRender = () => {
    return (
      <div className="row">
        <Card className="customCard" title={MachineTitleRender()}>
          <Meta className="internalContent" title={<strong>{transactionInfo?.vmFriendlyName}</strong>} description={MachineInfoRender()} />
        </Card>
      </div>
    );
  };

  const MachineInfoRender = () => {
    return (
      <>
        <br />
        <Timeline>
          {transactionInfo.vmSlotNumber ? <Timeline.Item color="green">{Strings.planogram.slotNumber + ": " + transactionInfo.vmSlotNumber}</Timeline.Item> : null}
          {transactionInfo.scDependencyName ? <Timeline.Item color="green">{Strings.scDependency.indirectClient + ": " + transactionInfo.scDependencyName}</Timeline.Item> : null}
          {transactionInfo.transactionDate ? (
            <Timeline.Item color="green">{Strings.dispensationReport.transactionDate + ": " + moment(transactionInfo.transactionDate).format(DATE_TIME_FORMAT)}</Timeline.Item>
          ) : null}
          {transactionInfo.storageDate ? (
            <Timeline.Item color="green">{Strings.dispensationReport.syncDate + ": " + moment(transactionInfo.storageDate).format(DATE_TIME_FORMAT)}</Timeline.Item>
          ) : null}
          {transactionInfo.productTransactionTypeName ? (
            <Timeline.Item color="green">
              {Strings.transaction.transactionStatus + ": " + transactionTypeLocalization(transactionInfo.productTransactionTypeName).toUpperCase()}
            </Timeline.Item>
          ) : null}
          {transactionInfo.motorsControllerCommandStatusName ? (
            <Timeline.Item color="green">
              {Strings.motorsControllerCommandStatus.motorCommandStatus + ": " + motorsControllerCommandStatusLocalization(transactionInfo.motorsControllerCommandStatusName)}
            </Timeline.Item>
          ) : null}
        </Timeline>
      </>
    );
  };

  return MachineTransactionInfoRender();
};

const mapStateToProps = (state) => ({
  permissions: state.userSystemPrivileges.privilegeObj,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MachineTransactionSummary);
