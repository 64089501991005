import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/userenrollmentinfo";

export function getUserEnrollmentInfoByUserId(userId, callback) {
  return () => {
    axios.get(host + port + path + version + resource, {
      params: {
        userid: userId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${sessionStorage.getItem("access_token")}`,
      }
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  }
}

export function disableUserEnrollmentInfoById(id, callback) {
  axios({
    method: 'DELETE',
    url: host + port + path + version + resource,
    params: {
      id
    },
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${sessionStorage.getItem("access_token")}`,
    }
  })
    .then((response) => {
      callback(response);
    })
    .catch(error => {
      callback(error);
    });
}