import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip, Icon } from "antd";
import PhoneInput from "react-phone-input-2";
import { listCountries } from "../../../actions";

import "react-phone-input-2/lib/high-res.css";
import "./input.css"

class PhoneNumberInput extends Component {
  constructor(props) {
    super(props);

    this.state = { value: null, countryCode: "co" };
  }

  static getDerivedStateFromProps(props, prevState) {
    const { value, countryCode } = props;
    let ans = null;

    if (value !== undefined && value !== prevState.value) {
      ans = { value };
    }
    if (countryCode !== undefined && countryCode !== prevState.countryCode) {
      ans = { countryCode };
    }

    return ans;
  }

  onChange = (phone, country) => {
    const { countryCode } = country;
    const value = phone;

    this.setState({ value, countryCode });

    const { id, onChange } = this.props;
    const event = { target: { id, value: `+${value}` } };
    onChange(event);
  };

  render() {
    const { countryCode, value } = this.state;
    const { text, isReq, tooltip } = this.props;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="selectAligning col-4">
            <span className="inputText">
              {text}
              <b>{isReq === true ? ": *" : ":"}</b>
            </span>
            {tooltip ? (
              <Tooltip title={tooltip}>
                <Icon className="icon" type="info-circle" /> <span />
              </Tooltip>
            ) : null}
          </div>
          <div className="col-8 input">
            <PhoneInput
              country={countryCode}
              value={value}
              autoFormat={false}
              disableDropdown
              countryCodeEditable={false}
              onChange={(phone, country) => this.onChange(phone, country)}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countries: state.countries,
  };
}

export default connect(mapStateToProps, {
  listCountries,
})(PhoneNumberInput);
