import Strings from '../../systemVariables/languageStrings'

export function permissionTypeLocalization(permissionType) {
    switch (permissionType) {
        case 'NOA':
            permissionType = "Noatec";
            break;
        case 'SO':
            permissionType = Strings.soDependency.directClient;
            break;
        case 'SC':
            permissionType = Strings.scDependency.indirectClient;
            break;
        case 'VM':
            permissionType = Strings.machine.vendingMachine;
            break;
        case 'WS':
            permissionType = Strings.generalTerms.webService;
            break;
        case 'SO_HQ_LC':
            permissionType = Strings.soDependency.soDependencyType.headquarter + ' / ' + Strings.soDependency.soDependencyType.logisticCenter;
            break;
        case 'SOP':
            permissionType = Strings.soDependency.soDependencyType.provisioner;
            break;
        default:
            break;
    }
    return permissionType;
}