import { Button, Collapse, Icon, Input, Popconfirm, Table } from "antd";
import moment from "moment";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import { DATE_TIME_FORMAT } from "../../../systemVariables/TimeConfig";
import Strings from "../../../systemVariables/languageStrings";

class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
    };
  }

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[this.state.searchText]} autoEscape textToHighlight={text.toString()} />
    ),
  });

  customExpandIcon = (props) => {
    const { isActive } = props;
    if (isActive) {
      return <Icon type="minus" />;
    }
    return <Icon type="eye" theme="twoTone" />;
  };

  renderUserMachines = (row) => {
    const { Panel } = Collapse;
    const { disableUserFromVM } = this.props;
    const text = row.vendingMachines.map((obj) => {
      return <div className="userTableHeaderElement">{obj.vendingMachineFriendlyName}</div>;
    });

    const header = <div className="userTableHeader">{text}</div>;

    const data = row.vendingMachines.map((obj) => {
      const option = (
        <tr>
          <th>{obj.vendingMachineFriendlyName}</th>
          <th>{obj.vendingMachineSerialNumber}</th>
          <th>{moment(obj.assignationDate).format(DATE_TIME_FORMAT)}</th>
          <th>
            <Popconfirm title={Strings.UsersByVendingMachine.confirmRemoveUserFromVM} onConfirm={() => disableUserFromVM(row, obj)} okText="Sí" cancelText="No">
              <Icon type="delete" className="addEditRemoveButton" />
            </Popconfirm>
          </th>
        </tr>
      );
      return option;
    });

    return (
      <Collapse bordered={false} expandIcon={(props) => this.customExpandIcon(props)} expandIconPosition="right" defaultActiveKey={["0"]}>
        <Panel header={header} key="1">
          <table>
            <tbody>
              <tr>
                <th className="font-weight-bold">{Strings.machine.machine}</th>
                <th className="font-weight-bold">{Strings.machine.serialNumber}</th>
                <th className="font-weight-bold">Assignation Date</th>
              </tr>
              {data}
            </tbody>
          </table>
        </Panel>
      </Collapse>
    );
  };

  render() {
    const { permissions, dataSource, loading, disableUserFromAllVms } = this.props;
    const { Column } = Table;

    return (
      <>
        <Table dataSource={dataSource} bordered loading={loading} size="middle" style={{ width: "100%" }} scroll={{ x: "auto" }} rowKey="id">
          <Column title={Strings.users.identityCardNumber} dataIndex="identityCardNumber" style={{ with: "25%" }} {...this.getColumnSearchProps("identityCardNumber")} />
          <Column title={Strings.generalTerms.name} dataIndex="name" style={{ with: "25%" }} />
          <Column title={Strings.machine.assignedMachine} render={(row) => this.renderUserMachines(row)} style={{ with: "25%" }} />
          {ValidatePermissionForComponentPart("PRIVILEGE USERS BY VENDING MACHINE DELETE", permissions) ? (
            <Column
              title={Strings.generalTerms.delete}
              style={{ with: "25%" }}
              align="center"
              render={(row) => (
                <Popconfirm title={Strings.UsersByVendingMachine.confirmRemoveUserFromVM} onConfirm={() => disableUserFromAllVms(row)} okText="Sí" cancelText="No">
                  <Icon type="delete" className="addEditRemoveButton" />
                </Popconfirm>
              )}
            />
          ) : null}
        </Table>
      </>
    );
  }
}
export default UsersTable;
