import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/scuserprofiles";

export function listScUserProfilesByScDependencyId(scDependencyId, callback){
  return (dispatch) => {
    axios.get(host+ port + path  + version + resource,{
      params: {
        scdependency_id: scDependencyId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      callback(error);
      return error;
    });
  }
}

export function listScUserProfileByScDependencyIdPaginated (scDependencyId, name, page,pageSize,attribute,order,callback){ 
  return () => {
    axios.get(host+ port + path  + version + resource,{
      params: {
        scdependency_id: scDependencyId,
        name: name,
        page: page,
        pageSize: pageSize,
        attribute: attribute,
        order: order
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      return error;
    });
  }
}

export function addScUserProfile(scUserProfileDTO, callback){
  return () => {
    let data = JSON.stringify(scUserProfileDTO)
    axios.post(host + port + path + version + resource, data, {
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
      });
  }
}

export function editScUserProfile(scUserProfileDTO, callback){
  return () => {
    let data = JSON.stringify(scUserProfileDTO)
    axios(
      {method:'put',
      url : host+ port + path  + version + resource,
      data : data,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      return(error);
    });
  }
}

export function disableScUserProfileById(scUserProfileId, callback){
  return () => {
    let data = JSON.stringify(scUserProfileId)
    axios({
      method: 'delete',
      url: host + port + path + version + resource,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    }).then((response) => {
      callback(response);
    })
    .catch(function (error) {
      return(error);
    });
  }
}
