import React from "react";
import "../Global/css/page404.css";
import { Icon, Typography } from "antd";
import Strings from "../../systemVariables/languageStrings";
const { Title, Text } = Typography;

const { generalResponses } = Strings;

const ConnectionError = () => {
  return (
    <>
      <div className="warning-container">
        <Title type="warning" level={1} className="header">
          {generalResponses.connectionError} <Icon type="api" />{" "}
        </Title>
        <Text strong className="error">
          {generalResponses.connectionErrorTooltip}
        </Text>
        <hr />
        <div className="bottom-content">
          <Text disabled className="otherwise">
            {" "}
            <a href="/">{generalResponses.clickHere}</a> {generalResponses.clickHereUndirection}
          </Text>
        </div>
      </div>
    </>
  );
};

export default ConnectionError;
