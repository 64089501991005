import { Button, Icon, Spin, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Strings from "../../../systemVariables/languageStrings";
import "./buttons.css";

export function AddButton(props) {
  return (
    <>
      <Link
        disabled={props.disabled}
        to={{
          pathname: props.link,
          props: props.props,
        }}
      >
        <Tooltip title={props.tooltip} placement="bottom">
          {!props.isIconButton ? (
            <Button id="addButton" className="button" disabled={props.disabled} onClick={props.onClick} type="primary" size="large">
              <Icon type="plus-circle" theme="twoTone" twoToneColor="#52c41a" />
              <span>{Strings.generalTerms.add}</span>
            </Button>
          ) : (
            <Spin spinning={props.loading}>
              <Icon style={{ fontSize: "25px", padding: "0.2em" }} type="plus-circle" theme="twoTone" twoToneColor="#52c41a" disabled={props.disabled} onClick={props.onClick} />
            </Spin>
          )}
        </Tooltip>
      </Link>
    </>
  );
}
