import axios from 'axios';
import { host, port, path, version } from '../systemVariables';

const resource = '/locationsInformation';

export async function getLocationsBySoDependencySelectorContainingLocation(
  soDependencyId, 
  location, 
  page, 
  pageSize, 
  sortingKey, 
  sortDirection, 
) {
  try {
    const response = await axios.get(`${host}${port}${path}${version}${resource}`, {
      params: {
        soDependencyId: soDependencyId,
        location: location,
        page: page,
        size: pageSize,
        sortingKey: sortingKey,
        sortDirection: sortDirection
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getLocationsByScDependencySelectorContainingLocation(
  scDependencyId, 
  location, 
  page, 
  pageSize, 
  sortingKey, 
  sortDirection, 
) {
  try {
    const response = await axios.get(`${host}${port}${path}${version}${resource}`, {
      params: {
        scDependencyId: scDependencyId,
        location: location,
        page: page,
        size: pageSize,
        sortingKey: sortingKey,
        sortDirection: sortDirection
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getAllLocationsByScDependency(scDependencyId) {
  try {
    let response = await axios({
      method: 'GET',
      url: host + port + path + version + resource + "/all",
      params: {scDependencyId: scDependencyId}, 
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      }
    })
    return response
  } catch (error) {
    return error
  }
}

export async function getAvailableLocationsByScDependencyOrUuid(scDependencyId, locationUuid) {
  try {
    let response = await axios({
      method: 'GET',
      url: host + port + path + version + resource + "/available",
      params: {scDependencyId, locationUuid}, 
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      }
    })
    return response
  } catch (error) {
    return error
  }
}

export async function insertLocation(location) {
  try {
    const response = await axios.post(`${host}${port}${path}${version}${resource}`, location, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateLocation(locationToUpdate) {
  try {
    const response = await axios.put(`${host}${port}${path}${version}${resource}`, locationToUpdate, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function disableLocation(location) {
  try {
    const response = await axios.delete(`${host}${port}${path}${version}${resource}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
      data: location,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function exportLocationsInformation(
  soDependencyId,
  scDependencyId,
  fileType, 
  columnHeaders,
) {
  try {
    const response = await axios.get(`${host}${port}${path}${version}${resource}`, {
      params: {
        soDependencyId: soDependencyId,
        scDependencyId: scDependencyId,
        fileType: fileType,
        columnHeaders: encodeURIComponent(columnHeaders),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export function getLocationInformationById(
  locationInformationId,
  callback,
) {
  return () => { 
    axios.get(`${host}${port}${path}${version}${resource}/${locationInformationId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    }).then((response) => {
      callback(response);
    })
    .catch(function (error) {
      callback(error);
    });
  };
}