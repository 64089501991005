import React from "react";
import { connect } from "react-redux";

// Components
import { Alert, Card, Timeline } from "antd";
import Strings from "../../../systemVariables/languageStrings";

// Images
import Cash from "../../Global/images/ComponentsSVG/Cash";

const { Meta } = Card;

const CashTransactionSummary = ({ cashTransaction }) => {
  const cashTransactionTitleRender = () => {
    return (
      <>
        <div className="row" style={{ margin: "10px" }}>
          <Meta
            avatar={<Cash width={40} fill={"#004173"} />}
            style={{ margin: "5px" }}
            title={
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-12">
                  <h2 className="paragraphSubTitle">{Strings.transaction.purchaseTransaction}</h2>
                </div>
              </div>
            }
          />
        </div>
      </>
    );
  };

  const cashTransactionInfoRender = (cashTransaction) => {
    return (
      <div className="row">
        <Card className="customCard" title={cashTransactionTitleRender()}>
          {cashTransaction ? (
            <>
              <Meta
                title={
                  <strong>
                    {Strings.product.price.toUpperCase() + `: ${cashTransaction.currencySymbol}${cashTransaction.productPrice.toFixed(0)} ${cashTransaction.currencyCode}`}
                  </strong>
                }
                description={productPriceInfoRender()}
              />
              <Meta
                title={
                  <strong>
                    {Strings.vmcashvaulttype.amountReceived.toUpperCase() +
                      `: ${cashTransaction.currencySymbol}${cashTransaction.amountReceived.toFixed(0)} ${cashTransaction.currencyCode}`}
                  </strong>
                }
                description={amountReceivedInfoRender()}
              />
              <Meta
                title={
                  <strong>
                    {Strings.vmcashvaulttype.amountReturned.toUpperCase() +
                      `: ${cashTransaction.currencySymbol}${cashTransaction.amountReturned.toFixed(0)} ${cashTransaction.currencyCode}`}
                  </strong>
                }
                description={amountReturnedInfoRender()}
              />
            </>
          ) : (
            <Alert message={Strings.generalTerms.informationNotAvailable} type="info" showIcon style={{ alignContent: "center" }} />
          )}
        </Card>
      </div>
    );
  };

  const productPriceInfoRender = () => {
    return (
      <div style={{ padding: "10px" }}>
        <Timeline>
          {cashTransaction.taxValue !== undefined ? <Timeline.Item color="green">{Strings.product.tax + ": " + cashTransaction?.taxValue + "%"}</Timeline.Item> : null}
          {cashTransaction.taxValue !== undefined ? (
            <Timeline.Item color="green">
              {Strings.product.taxAmount +
                `: ${cashTransaction.currencySymbol}${((cashTransaction.productPrice * cashTransaction?.taxValue) / 100).toFixed(0)} ${cashTransaction.currencyCode}`}
            </Timeline.Item>
          ) : null}
          {cashTransaction.productPrice ? (
            <Timeline.Item color="green">
              {Strings.product.preTaxPriceAlternative +
                `: ${cashTransaction.currencySymbol}${(cashTransaction?.productPrice - (cashTransaction.productPrice * cashTransaction?.taxValue) / 100).toFixed(0)} ${
                  cashTransaction.currencyCode
                }`}
            </Timeline.Item>
          ) : null}
        </Timeline>
      </div>
    );
  };

  const amountReceivedInfoRender = () => {
    return (
      <div style={{ padding: "10px" }}>
        <Timeline>
          {cashTransaction.depositCoinBox !== 0 ? (
            <Timeline.Item color="green">
              {Strings.vmcashvaulttype.coinBox + `: ${cashTransaction.currencySymbol}${cashTransaction?.depositCoinBox} ${cashTransaction.currencyCode}`}
            </Timeline.Item>
          ) : null}
          {cashTransaction.depositCoinTubes !== 0 ? (
            <Timeline.Item color="green">
              {Strings.vmcashvaulttype.coinTubes + `: ${cashTransaction.currencySymbol}${cashTransaction?.depositCoinTubes} ${cashTransaction.currencyCode}`}
            </Timeline.Item>
          ) : null}
          {cashTransaction.depositBillBox !== 0 ? (
            <Timeline.Item color="green">
              {Strings.vmcashvaulttype.billBox + `: ${cashTransaction.currencySymbol}${cashTransaction?.depositBillBox} ${cashTransaction.currencyCode}`}
            </Timeline.Item>
          ) : null}
          {cashTransaction.depositBillRecycler !== 0 ? (
            <Timeline.Item>
              {Strings.vmcashvaulttype.recycler + `: ${cashTransaction.currencySymbol}${cashTransaction?.depositBillRecycler} ${cashTransaction.currencyCode}`}
            </Timeline.Item>
          ) : null}
          {cashTransaction.depositCredit !== 0 ? (
            <Timeline.Item color="green">
              {Strings.vmcashvaulttype.credit + `: ${cashTransaction.currencySymbol}${cashTransaction?.depositCredit} ${cashTransaction.currencyCode}`}
            </Timeline.Item>
          ) : null}
          {cashTransaction.depositCashless ? (
            <Timeline.Item color="green">
              {Strings.paymentTypes.cashless + `: ${cashTransaction.currencySymbol}${cashTransaction?.depositCashless} ${cashTransaction.currencyCode}`}
            </Timeline.Item>
          ) : null}
          {cashTransaction.trxIdString !== "" && cashTransaction.trxIdString ? (
            <Timeline.Item color="green">{Strings.transaction.approvalCode + ": " + cashTransaction?.trxIdString}</Timeline.Item>
          ) : null}
        </Timeline>
      </div>
    );
  };

  const amountReturnedInfoRender = () => {
    return (
      <Timeline>
        {cashTransaction.withdrawalBillRecycler !== 0 ? (
          <Timeline.Item color="green">
            {Strings.vmcashvaulttype.recycler + `: ${cashTransaction.currencySymbol}${cashTransaction?.withdrawalBillRecycler} ${cashTransaction.currencyCode}`}
          </Timeline.Item>
        ) : null}
        {cashTransaction.withdrawalCoinTubes !== 0 ? (
          <Timeline.Item color="green">
            {Strings.vmcashvaulttype.coinTubes + `: ${cashTransaction.currencySymbol}${cashTransaction?.withdrawalCoinTubes} ${cashTransaction.currencyCode}`}
          </Timeline.Item>
        ) : null}
      </Timeline>
    );
  };

  return cashTransactionInfoRender(cashTransaction);
};

const mapStateToProps = (state) => ({
  permissions: state.userSystemPrivileges.privilegeObj,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CashTransactionSummary);
