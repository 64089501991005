/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import { Button, Icon, Input, Table } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { paymentTypeLocalization, transactionTypeLocalization } from "../../../Utils/LanguageLocalization";
import { DATE_TIME_FORMAT } from "../../../systemVariables/TimeConfig";
import Strings from "../../../systemVariables/languageStrings";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";
import DispensationModal from "./TransactionsModal";

const { Column, ColumnGroup } = Table;

class DispensationReportTable extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? undefined : "#004173" }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === this.row ? (
        <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[this.state.searchText]} autoEscape textToHighlight={text.toString()} />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, dataIndex, confirm) => {
    const { filterTable } = this.props;
    confirm();
    if (selectedKeys.length !== 0) {
      filterTable(selectedKeys, dataIndex);
      this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    }
  };

  handleReset = (clearFilters) => {
    const { clearSearchFilterTable } = this.props;
    clearFilters();
    clearSearchFilterTable();
    this.setState({ searchText: "" });
  };

  render() {
    const { dataSource, isLoading, transactionCategoryId, scDependencyId, moduleType, transactionType, filterTable } = this.props;
    const lockerLoanId = 15;
    const lockerDeliveryStart = 11;
    const lockerReturnId = 16;
    const lockerProductDeliveredId = 12;

    return (
      <Table dataSource={dataSource} bordered loading={isLoading} pagination={false} size="middle" style={{ width: "100%" }} scroll={{ x: "auto" }} rowKey="id">
        <Column title={Strings.transaction.transactionIdentifier} dataIndex="uuid" key="uuid" align="center" />
        <ColumnGroup title={Strings.users.user}>
          <Column title="Id" dataIndex="userIdentityCardNumber" key="userIdentityCardNumber" align="center" {...this.getColumnSearchProps("userIdentityCardNumber", filterTable)} />
          <Column title={Strings.generalTerms.name} dataIndex="userCompleteName" key="userCompleteName" align="center" />
        </ColumnGroup>
        {moduleType === 0 ? (
          <ColumnGroup title={Strings.product.product}>
            <Column
              title={Strings.generalTerms.code}
              key="soProductErpCode"
              align="center"
              render={(row) => <div>{!row.soProductErpCode ? (row.scProductCode ? row.scProductCode : "--") : row.soProductErpCode}</div>}
            />
            <Column
              title={Strings.product.product}
              key="soProductName"
              align="center"
              render={(row) => <div>{!row.soProductName ? (row.scProductName ? row.scProductName : "--") : row.soProductName}</div>}
            />
          </ColumnGroup>
        ) : null}

        {!scDependencyId ? (
          <Column
            title={<TextWithInfoTooltip name={Strings.scDependency.indirectClient} tooltip={Strings.scDependency.indirectClientTooltip} />}
            dataIndex="scDependencyName"
            key="scDependencyName"
            align="center"
          />
        ) : null}
        <Column
          title={<TextWithInfoTooltip name={Strings.machine.vendingMachine} tooltip={Strings.machine.machineTooltip} />}
          key="vmFriendlyName"
          align="center"
          render={(row) => <div>{row.vmFriendlyName}</div>}
        />
        <Column title={Strings.planogram.slotNumber} key="vmSlotNumber" align="center" render={(row) => <div>{row.vmSlotNumber ? row.vmSlotNumber : "--"}</div>} />
        <Column title={Strings.transaction.paymentType} key="paymemntTypeId" align="center" render={(row) => <div>{paymentTypeLocalization(row.paymentTypeId)}</div>} />
        <Column
          title={Strings.transaction.transactionType}
          key="productTransactionTypeName"
          align="center"
          render={(row) => <div>{transactionTypeLocalization(row.productTransactionTypeName)}</div>}
        />
        <Column
          title={<TextWithInfoTooltip name={Strings.generalTerms.transaction} tooltip={Strings.dispensationReport.transactionDateTooltip} />}
          key="transactionDate"
          render={(row) => <div>{moment(row.transactionDate).format(DATE_TIME_FORMAT)}</div>}
        />
        <Column
          title={<TextWithInfoTooltip name={Strings.generalTerms.storage} tooltip={Strings.dispensationReport.storageDateTooltip} />}
          key="storageDate"
          render={(row) => <div>{moment(row.storageDate).format(DATE_TIME_FORMAT)}</div>}
        />
        {transactionType[0] === lockerLoanId && (
          <Column
            title={Strings.dispensationReport.returned}
            render={(row) => {
              let responseText = null;

              if (row.loanTransactionsDto.length > 0) {
                const returnTransactions = row.loanTransactionsDto.filter((transaction) => transaction.productTransactionTypeId === lockerReturnId);
                if (returnTransactions.length > 0) {
                  responseText = <p>{Strings.generalTerms.yes}</p>;
                } else {
                  responseText = <p>{Strings.generalTerms.no}</p>;
                }
              } else {
                responseText = <p>{Strings.generalTerms.no}</p>;
              }
              return responseText;
            }}
          />
        )}
        {transactionType[0] === lockerDeliveryStart && (
          <Column
            title={Strings.dispensationReport.delivered}
            render={(row) =>
              row.loanTransactionsDto.length > 0 ? (
                row.loanTransactionsDto.map((obj) => {
                  return obj.productTransactionTypeId === lockerProductDeliveredId ? <p>{Strings.generalTerms.yes}</p> : <p>{Strings.generalTerms.no}</p>;
                })
              ) : (
                <p>{Strings.generalTerms.no}</p>
              )
            }
          />
        )}

        <Column
          title={Strings.generalTerms.details}
          // width="68px"
          align="center"
          key="modal"
          render={(row) => <DispensationModal selectedRow={row} />}
        />
      </Table>
    );
  }
}

DispensationReportTable.propTypes = {
  dataSource: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
  transactionCategoryId: PropTypes.number,
  scDependencyId: PropTypes.number,
};

DispensationReportTable.defaultProps = { scDependencyId: null };

export default DispensationReportTable;
