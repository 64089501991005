import React from "react";
import Select from "react-select";
import { Tooltip, Icon, Input, Checkbox } from "antd";

// Language localization
import Strings from "../../systemVariables/languageStrings";

// css
import "./selectComponent/selectComponent.css";

export default function InputWitDropdown(props) {
  let defVal;
  if (props.options) {
    defVal = props.options.filter((option) => option.value === props.defaultValue);
  } else {
    defVal = null;
  }

  return (
    <div className="container-fluid">
      <div className="row selectAligning">
        <div className="col-4 selectAligning ">
          <span>
            {Strings.generalTerms.elapsedTime}
            <b>{props.isReq === true ? ": *" : ":"}</b>
          </span>
          {props.tooltip && (
            <Tooltip title={Strings.generalTerms.elapsedTimeTooltip}>
              <Icon className="icon" type="info-circle" style={{ float: "none" }} />
            </Tooltip>
          )}
        </div>
        <div className="col-1 input">
          <Checkbox checked={props.isChecked} onChange={props.onChangeCheckbox} disabled={props.disabled} />
        </div>
        <div className="col-xs-7 col-md-2" style={{ padding: "0 3px", height: "40px" }}>
          <Input min={0} value={props.numberValue} onChange={props.onChangeNumber} disabled={props.isDisabled} onKeyDown={props.handleKeyDown} />
        </div>
        <div className="col-md-5 input">
          <Select
            className="basic-single"
            options={props.options}
            placeholder={Strings.generalTerms.select}
            isDisabled={props.isDisabled}
            onChange={props.onSelect}
            value={defVal}
            size="default"
          />
        </div>
      </div>
    </div>
  );
}
