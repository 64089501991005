/* eslint-disable no-nested-ternary */
// Dependencies
import { Button, Divider, Icon, Input, Modal, Pagination, Popconfirm, Table } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Components
import { ValidateDependency } from "../../../Utils/validateDependency";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import { AssignButton, ExportButton } from "../../GenericComponents/buttons/index";
import Loading from "../../GenericComponents/loadingComponent/loading";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";
import SoDependencySelector from "../../GenericComponents/selectComponent/SoDependencySelector";
import { SelectGeneral } from "../../GenericComponents/selectComponent/selectGeneral";
import Titles from "../../GenericComponents/titles";
import RoleDetailesModal from "./roleDetailesModal";
// Actions
import {
  disableSoScRole,
  getLowerRelationsBySoDependencyId,
  getRoleDetailesById,
  listAllSoDependencyTypes,
  listRolesByDependencyIdPaged,
  listScDependenciesBySoDependencyId,
  listSoDependencyBySoDependencyType0,
} from "../../../actions";

// Language Localization
import Strings from "../../../systemVariables/languageStrings";

const { Column } = Table;

const dependencyType = [
  { label: Strings.Roles.Executive, value: 1 },
  { label: Strings.generalTerms.customer, value: 2 },
];

const roleNature = [
  { label: Strings.generalTerms.all, value: -1 },
  { label: Strings.generalTerms.yes, value: 1 },
  { label: Strings.generalTerms.no, value: 0 },
];

class PrivilegeByRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Role assignation level
      dependencyType: null,
      dependencyTypeId: null,
      dependencyTypeName: null,
      // Indirect Client
      scDependencies: [],
      scDependencyId: null,
      scDependencyName: null,
      scDependencyDisabled: true,
      // Filter
      isBasicRoleId: -1,
      roleName: "",
      dependencyId: null,
      // Buttons
      assignButtonDisabled: true,
      // Info
      roles: [],
      rolesCount: 0,
      // Info selection
      selectedRole: null,
      roleDetailes: null,
      loadingInfo: false,
      // Pagination
      pageSize: 10,
      page: 1,
      attribute: "id",
      order: "DESC",
    };
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const {
      soDependencies,
      listSoDependencyBySoDependencyType0,
      userDependency,
      listScDependenciesBySoDependencyId,
      listRolesByDependencyIdPaged,
      soDependencyParent,
    } = this.props;

    const { isBasicRoleId, roleName, page, pageSize, attribute, order } = this.state;

    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") === "1") {
      if (soDependencies.length <= 0) {
        listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
      }
    }
    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") !== "1") {
      const value = userDependency ? (userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyId : null) : null;
      this.setState({
        soDependencyId: userDependency ? (userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyId : null) : null,
        soDependencyName: userDependency ? (userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyName : null) : null,
        dependencyId: userDependency ? (userDependency.dependencyType === "so" && userDependency.dependencyId !== 1 ? userDependency.dependencyId : null) : null,
        dependencyTypeId: 1,
        dependencyTypeName: "stockOwner",
      });
      listScDependenciesBySoDependencyId(value, (response) => {
        this.setState({
          scDependencies: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
          scDependencyDisabled: false,
        });
      });

      listRolesByDependencyIdPaged("so", value, isBasicRoleId, roleName, page, pageSize, attribute, order, (response) => {
        this.setState({
          roles: response.data.data.content,
          rolesCount: response.data.data.totalElements,
          loadingInfo: false,
          assignButtonDisabled: false,
        });
      });
    }

    if (sessionStorage.getItem("dependencyType") === "sc") {
      const value = userDependency ? (userDependency.dependencyType === "sc" && userDependency.dependencyId !== 1 ? userDependency.dependencyId : "") : "";

      this.setState({
        dependencyType: "sc",
        dependencyTypeId: 2,
        soDependencyId: soDependencyParent,
        scDependencyId: userDependency ? (userDependency.dependencyType === "sc" ? userDependency.dependencyId : null) : null,
        scDependencyName: userDependency ? (userDependency.dependencyType === "sc" ? userDependency.dependencyName : null) : null,
        dependencyId: userDependency ? (userDependency.dependencyType === "sc" ? userDependency.dependencyId : null) : null,
        loadingInfo: true,
      });
      listRolesByDependencyIdPaged("sc", value, isBasicRoleId, roleName, page, pageSize, attribute, order, (response) => {
        this.setState({
          roles: response.data.data.content,
          rolesCount: response.data.data.totalElements,
          loadingInfo: false,
          assignButtonDisabled: false,
        });
      });
    }
  }

  // Events
  handleSelect1(event, tag) {
    const { value, label } = event;
    const tagName = tag.name;
    const { listScDependenciesBySoDependencyId, listRolesByDependencyIdPaged } = this.props;
    const { dependencyType, dependencyId, isBasicRoleId, roleName, page, pageSize, attribute, order } = this.state;

    switch (tagName) {
      case "dependencyType":
        if (value === 1) {
          this.setState({
            dependencyType: "so",
            dependencyTypeId: value,
          });
        } else {
          this.setState({
            dependencyType: "sc",
            dependencyTypeId: value,
          });
        }
        break;
      case "soDependencyType0": // SoDependency direct client select
        this.setState({
          soDependencyIdType0: value,
          soDependencyId: value,
          soDependencyName: label,
          scDependencyId: null,
          scDependencyName: null,
        });
        listScDependenciesBySoDependencyId(value, (response) => {
          this.setState({
            scDependencies: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            scDependencyDisabled: false,
          });
        });
        break;
      case "scDependency":
        this.setState({
          dependencyTypeName: "scDependency",
          dependencyId: value,
          scDependencyId: value,
          scDependencyName: label,
          loadingInfo: true,
        });

        listRolesByDependencyIdPaged(dependencyType, value, isBasicRoleId, roleName, page, pageSize, attribute, order, (response) => {
          this.setState({
            roles: response.data.data.content,
            rolesCount: response.data.data.totalElements,
            loadingInfo: false,
            assignButtonDisabled: false,
          });
        });
        break;
      case "isBasicRole":
        this.setState({
          isBasicRoleId: value,
          loadingInfo: true,
        });

        listRolesByDependencyIdPaged(dependencyType, dependencyId, value, roleName, page, pageSize, attribute, order, (response) => {
          this.setState({
            roles: response.data.data.content,
            rolesCount: response.data.data.totalElements,
            loadingInfo: false,
            assignButtonDisabled: false,
          });
        });
        break;
      default:
    }
  }

  handleSelect = (event) => {
    const { value, label } = event;
    const { listRolesByDependencyIdPaged } = this.props;
    const { isBasicRoleId, roleName, page, pageSize, attribute, order } = this.state;

    this.setState({
      loadingInfo: true,
      dependencyTypeName: event.dependencyType,
      dependencyType: "so",
      dependencyId: value,
      soDependencyId: value,
      soDependencyName: label,
      assignButtonDisabled: true,
    });

    listRolesByDependencyIdPaged("so", value, isBasicRoleId, roleName, page, pageSize, attribute, order, (response) => {
      this.setState({
        roles: response.data.data.content,
        rolesCount: response.data.data.totalElements,
        loadingInfo: false,
        assignButtonDisabled: false,
      });
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      // eslint-disable-next-line react/jsx-filename-extension
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearch = (selectedKeys) => {
    const { listRolesByDependencyIdPaged } = this.props;
    const { dependencyType, dependencyId, isBasicRoleId, page, pageSize, attribute, order } = this.state;
    this.setState({
      loadingInfo: true,
      // eslint-disable-next-line react/no-unused-state
      searchText: selectedKeys[0],
    });

    listRolesByDependencyIdPaged(dependencyType, dependencyId, isBasicRoleId, selectedKeys[0], page, pageSize, attribute, order, (response) => {
      this.setState({
        roles: response.data.data.content,
        rolesCount: response.data.data.totalElements,
        loadingInfo: false,
        assignButtonDisabled: false,
      });
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    const { listRolesByDependencyIdPaged } = this.props;
    const { dependencyType, dependencyId, isBasicRoleId, page, pageSize, attribute, order } = this.state;
    listRolesByDependencyIdPaged(dependencyType, dependencyId, isBasicRoleId, "", page, pageSize, attribute, order, (response) => {
      this.setState({
        roles: response.data.data.content,
        rolesCount: response.data.data.totalElements,
        loadingInfo: false,
        assignButtonDisabled: false,
      });
    });
    // eslint-disable-next-line react/no-unused-state
    this.setState({ searchText: "" });
  };

  handlePagination(page, pageSize) {
    const { listRolesByDependencyIdPaged } = this.props;
    const { dependencyType, dependencyId, isBasicRoleId, roleName, attribute, order } = this.state;

    this.setState({
      loadingInfo: true,
      page,
      pageSize,
    });
    if (dependencyType === null) {
      listRolesByDependencyIdPaged("so", dependencyId, isBasicRoleId, roleName, page, pageSize, attribute, order, (response) => {
        this.setState({
          roles: response.data.data.content,
          rolesCount: response.data.data.totalElements,
          loadingInfo: false,
        });
      });
    } else {
      listRolesByDependencyIdPaged(dependencyType, dependencyId, isBasicRoleId, roleName, page, pageSize, attribute, order, (response) => {
        this.setState({
          roles: response.data.data.content,
          rolesCount: response.data.data.totalElements,
          loadingInfo: false,
        });
      });
    }
  }

  handleInfoDetails = (event, row) => {
    const { getRoleDetailesById } = this.props;
    this.setState({
      detailsModalIsVisible: true,
    });
    getRoleDetailesById(row.id, (response) => {
      this.setState({
        selectedRole: row,
        roleDetailes: response.data.data,
      });
    });
  };

  setModalVisible = (isVisible) => {
    this.setState({
      detailsModalIsVisible: isVisible,
      selectedRole: null,
      roleDetailes: null,
    });
  };

  handleDisableRole = (e, row) => {
    const { disableSoScRole, listRolesByDependencyIdPaged } = this.props;
    const { dependencyType, dependencyTypeId, soDependencyId, scDependencyId, isBasicRoleId, roleName, page, pageSize, attribute, order } = this.state;
    disableSoScRole(dependencyType, dependencyTypeId, row.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 0) {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
        });
        if (dependencyTypeId === 1) {
          listRolesByDependencyIdPaged("so", soDependencyId, isBasicRoleId, roleName, page, pageSize, attribute, order, (response) => {
            this.setState({
              roles: response.data.data.content,
              rolesCount: response.data.data.totalElements,
              loadingInfo: false,
              assignButtonDisabled: false,
            });
          });
        } else if (dependencyTypeId === 2) {
          listRolesByDependencyIdPaged("sc", scDependencyId, isBasicRoleId, roleName, page, pageSize, attribute, order, (response) => {
            this.setState({
              roles: response.data.data.content,
              rolesCount: response.data.data.totalElements,
              loadingInfo: false,
              assignButtonDisabled: false,
            });
          });
        }
      } else {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      }
    });
  };

  // Information to render
  renderEditOption(row) {
    const { dependencyTypeId, dependencyTypeName, soDependencyId, soDependencyName, scDependencyId, scDependencyName } = this.state;
    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") === 1) {
      return (
        <Link
          to={{
            pathname: "/editRoleAndPrivilegeAssignment",
            props: {
              dependencyTypeId,
              dependencyTypeName,
              soDependencyId,
              soDependencyName,
              scDependencyId,
              scDependencyName,
              roleInfo: row,
            },
          }}
        >
          <Icon type="edit" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
        </Link>
      );
    }
    return (
      <Link
        to={{
          pathname: "/editRoleAndPrivilegeAssignment",
          props: {
            dependencyTypeId,
            dependencyTypeName,
            soDependencyId,
            soDependencyName,
            scDependencyId,
            scDependencyName,
            roleInfo: row,
          },
        }}
      >
        <Icon type="edit" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
      </Link>
    );
  }

  tableRederMethod(roles, loadingInfo, data, rolesCount, page, exportLoading) {
    return (
      <div>
        <div className="row">
          <div className="vertSpace col-12">
            <div className="vertSpace col-12">
              <Table dataSource={roles} bordered loading={loadingInfo} pagination={false} size="middle" scroll={{ x: "auto" }} key="id">
                <Column title={Strings.generalTerms.name} render={(row) => <>{_.capitalize(row.roleName)}</>} />
                <Column title={Strings.generalTerms.description} dataIndex="description" />
                <Column
                  title={Strings.privilegeByRole.roleType}
                  render={(row) => (
                    <>{row.isBasicRole === 1 ? (row.permissiontypeId === 3 ? Strings.Roles.vmRol : Strings.Roles.basicSystemRole) : Strings.Roles.customSystemRole}</>
                  )}
                />
                <Column
                  title={Strings.generalTerms.details}
                  align="center"
                  render={(row) => (
                    <div align="center">
                      <Icon
                        type="profile"
                        theme="twoTone"
                        className="addEditRemoveButton"
                        style={{ cursor: "pointer", fontSize: 16 }}
                        onClick={(e) => this.handleInfoDetails(e, row)}
                      />
                    </div>
                  )}
                />

                {ValidatePermissionForComponentPart("PRIVILEGE PRIVILEGE BY ROLE PUT", data) ? (
                  <Column
                    title={Strings.generalTerms.edit}
                    align="center"
                    render={(row) =>
                      row.permissiontypeId === 3 ? (
                        "-"
                      ) : row.isBasicRole ? (
                        ValidatePermissionForComponentPart("PRIVILEGE PRIVILEGE BY BASIC ROLE PUT", data) ? (
                          <div align="center">{this.renderEditOption(row)}</div>
                        ) : (
                          "-"
                        )
                      ) : (
                        <div align="center">{this.renderEditOption(row)}</div>
                      )
                    }
                  />
                ) : (
                  ""
                )}

                {ValidatePermissionForComponentPart("PRIVILEGE PRIVILEGE BY ROLE DELETE", data) ? (
                  <Column
                    title={Strings.generalTerms.remove}
                    align="center"
                    render={(row) =>
                      row.isBasicRole ? (
                        "-"
                      ) : (
                        <div align="center">
                          <Popconfirm title={Strings.generalResponses.confirmDeleteMessage} onConfirm={(e) => this.handleDisableRole(e, row)} okText="Sí" cancelText="No">
                            <Icon type="delete" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
                          </Popconfirm>
                        </div>
                      )
                    }
                  />
                ) : (
                  ""
                )}
              </Table>
            </div>

            <div className="col-xs-0 col-lg-6" />
            <div className="vertSpace col-lg-6">
              <Pagination
                size="small"
                total={rolesCount}
                showQuickJumper
                showSizeChanger
                onChange={this.handlePagination}
                onShowSizeChange={this.handlePagination}
                hideOnSinglePage={false}
                pageSizeOptions={["10", "25", "50", "100", "250"]}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                current={page}
                disabled={rolesCount === 0}
              />
            </div>
          </div>
        </div>

        <ValidatePermissionForComponent permission="PRIVILEGE PRIVILEGE BY ROLE REPORT GET" permissions={data}>
          <div className="row">
            <div className="vertSpace col">
              <div className="col-md-6" />
              <div className="col-md-6">
                <ExportButton isDisabled={rolesCount < 1} onClick={() => this.showExportReportsModal()} loading={exportLoading} />
              </div>
            </div>
          </div>
        </ValidatePermissionForComponent>
      </div>
    );
  }

  render() {
    const {
      permissions: { data },
      userDependency,
      soDependencies,
    } = this.props;

    const {
      roles,
      detailsModalIsVisible,
      dependencyTypeId,
      dependencyTypeName,
      soDependencyId,
      soDependencyName,
      scDependencyId,
      scDependencyName,
      selectedRole,
      roleDetailes,
      isBasicRoleId,
      soDependencyIdType0,
      scDependencies,
      assignButtonDisabled,
      scDependencyDisabled,
      loadingInfo,
      rolesCount,
      page,
      exportLoading,
    } = this.state;

    if (!soDependencies) {
      return (
        <>
          <Loading />
        </>
      );
    }

    return (
      <div className="content-container">
        <RoleDetailesModal
          isVisible={detailsModalIsVisible}
          setIsVisible={this.setModalVisible}
          dependencyTypeId={dependencyTypeId}
          dependencyTypeName={dependencyTypeName}
          soDependencyId={soDependencyId}
          soDependencyName={soDependencyName}
          scDependencyId={scDependencyId}
          scDependencyName={scDependencyName}
          selectedRole={selectedRole}
          roleDetailes={roleDetailes}
        />

        <div className="row">
          <Titles title={Strings.privilegeByRole.privilegeByRole} tooltip={Strings.privilegeByRole.privilegeByRoleTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          {userDependency.dependencyType !== "sc" ? (
            <div className="vertSpace col-md-6">
              <SelectGeneral
                name="dependencyType"
                text={Strings.privilegeByRole.assignationLevel}
                tooltip={Strings.privilegeByRole.assignationLevelTooltip}
                options={dependencyType}
                onChange={(e, name) => this.handleSelect1(e, name)}
                disabled={false}
                defaultValue={dependencyTypeId}
              />
            </div>
          ) : (
            ""
          )}

          {dependencyTypeId === 1 ? (
            <>
              <SoDependencySelector onSoDependencyChange={this.handleSelect} />
              <div className="vertSpace col-md-6">
                <SelectGeneral
                  name="isBasicRole"
                  text={Strings.privilegeByRole.isBasicRole}
                  tooltip={Strings.privilegeByRole.isBasicRoleTooltip}
                  options={roleNature}
                  onChange={(e, name) => this.handleSelect1(e, name)}
                  disabled={!soDependencyId}
                  defaultValue={isBasicRoleId}
                />
              </div>
            </>
          ) : (
            ""
          )}

          {dependencyTypeId === 2 ? (
            <>
              <ValidateDependency
                soDependencyHtmlTag="soDependencyType0"
                scDependencyHtmlTag="scDependency"
                dependencyType={sessionStorage.getItem("dependencyType")}
                dependencyId={sessionStorage.getItem("dependencyId")}
                soDependencies={soDependencies}
                soDependencyId={soDependencyIdType0}
                soDependency={soDependencyName}
                scDependencies={scDependencies}
                scDependencyId={scDependencyId}
                scDependency={scDependencyName}
                disabledScDependency={scDependencyDisabled}
                soDependencyHandleChange={(e, name) => this.handleSelect1(e, name)}
                scDependencyHandleChange={(e, name) => this.handleSelect1(e, name)}
              />
              <div className="vertSpace col-md-6">
                <SelectGeneral
                  name="isBasicRole"
                  text={Strings.privilegeByRole.isBasicRole}
                  tooltip={Strings.privilegeByRole.isBasicRoleTooltip}
                  options={roleNature}
                  onChange={(e, name) => this.handleSelect1(e, name)}
                  disabled={!scDependencyId}
                  defaultValue={isBasicRoleId}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="vertSpace col-md-6" />
          <div className="vertSpace col-md-6">
            <AssignButton
              link={assignButtonDisabled ? "" : "/addRoleAndAssignPrivileges"}
              tooltip={Strings.users.addClientUsersTooltip}
              props={
                assignButtonDisabled
                  ? ""
                  : {
                      dependencyTypeId,
                      dependencyTypeName,
                      soDependencyId,
                      soDependencyName,
                      scDependencyId,
                      scDependencyName,
                    }
              }
              disabled={assignButtonDisabled}
            />
          </div>
        </div>
        <Divider orientation="left">
          <h3>{Strings.Roles.roleList}</h3>
        </Divider>
        <QueryResponse isLoading={loadingInfo} callback={this.tableRederMethod(roles, loadingInfo, data, rolesCount, page, exportLoading)} dataSourceLength={roles.length} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soDependencyParent: state.soDependencyParent.soParent,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  getLowerRelationsBySoDependencyId,
  listAllSoDependencyTypes,
  listRolesByDependencyIdPaged,
  getRoleDetailesById,
  disableSoScRole,
  listScDependenciesBySoDependencyId,
})(PrivilegeByRole);
