import { LIST_CITIES } from "../actions";

export function city(state = [], action) {
  switch (action.type) {
    case LIST_CITIES:
      return action.payload.data.map((item) => ({ value: item.id, label: item.name }));
    default:
      return state;
  }
}
