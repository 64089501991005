// Dependencies
import React, { Component } from "react";

// import homeImage from "../Global/images/homeNoatec.png";
// import homeImagePortriat from "../Global/images/homeImagePortriat.png";
import homeImage from "../Global/images/homeNoatecGeneral.png";
import homeImagePortriat from "../Global/images/homeNoatecLat.png";
// import homeImage from "../Global/images/homeNoatecNAVIDAD2024.png";
// import homeImagePortriat from "../Global/images/homeNoatecNAVIDAD2024Lat.png";

import "./home.css";

const contentSize = 64 + 75; /* header + footer + contentPadding */

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageHeight: 0,
      image: homeImage,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("change", this.sizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (window.innerHeight > window.innerWidth) {
      this.setState({
        image: homeImagePortriat,
      });
    }
    if (window.innerWidth >= window.innerHeight) {
      this.setState({
        image: homeImage,
      });
    }
    this.setState({
      imageHeight: window.innerHeight - contentSize,
    });
  };

  render() {
    const { image, imageHeight } = this.state;
    return (
      <>
        <div className="home">
          <img id="home__image" style={{ height: imageHeight }} src={image} alt="homeImage" />
        </div>
      </>
    );
  }
}

export default Home;
