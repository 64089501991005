import React from "react";

const User = ({ width, height, fill }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 612 612"
      xmlSpace="preserve"
      width={width}
      fill={fill}
    >
      <g>
        <g>
          <g>
            <path
              d="M378.804,462.677c-24.318-17.148-53.202-29.8-84.635-37.392c29.154-25.252,48.073-65.647,48.073-111.086
				c0-76.325-53.373-138.419-118.978-138.419c-65.602,0-118.976,62.094-118.976,138.419c0,45.44,18.919,85.835,48.073,111.086
				c-31.433,7.59-60.317,20.244-84.638,37.392C24.051,493.469,0,535.018,0,579.67c0,9.838,7.976,17.814,17.814,17.814h410.9
				c9.838,0,17.814-7.976,17.814-17.814C446.529,535.018,422.478,493.469,378.804,462.677z M139.915,314.199
				c0-56.679,37.389-102.791,83.348-102.791s83.35,46.113,83.35,102.791c0,56.682-37.392,102.794-83.35,102.794
				C177.307,416.994,139.915,370.881,139.915,314.199z M342.241,561.856V530.04c0-9.838-7.976-17.814-17.814-17.814
				c-9.838,0-17.814,7.976-17.814,17.814v31.816H139.915V530.04c0-9.838-7.976-17.814-17.814-17.814s-17.814,7.976-17.814,17.814
				v31.816H37.49c5.503-26.125,23.047-50.517,50.763-70.059c35.829-25.262,83.777-39.175,135.009-39.175
				c51.237,0,99.185,13.911,135.009,39.175c27.716,19.542,45.263,43.936,50.763,70.059H342.241z"
            />
            <path
              d="M397.226,50.144h196.959c9.838,0,17.814-7.976,17.814-17.814s-7.976-17.814-17.814-17.814H397.226
				c-9.838,0-17.814,7.976-17.814,17.814S387.389,50.144,397.226,50.144z"
            />
            <path
              d="M594.186,123.709h-196.96c-9.838,0-17.814,7.976-17.814,17.814c0,9.841,7.976,17.814,17.814,17.814h196.959
				c9.838,0,17.814-7.973,17.814-17.814C612,131.685,604.024,123.709,594.186,123.709z"
            />
            <path
              d="M594.186,232.905h-196.96c-9.838,0-17.814,7.976-17.814,17.814c0,9.838,7.976,17.814,17.814,17.814h196.959
				c9.838,0,17.814-7.976,17.814-17.814C612,240.882,604.024,232.905,594.186,232.905z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default User;
