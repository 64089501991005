// Dependencies
import { Alert, Button, Divider, Icon, Input, Pagination, Popover, Spin, Table } from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Column from "antd/lib/table/Column";
import moment from "moment";
import { getAllMachinGenerations, getFirmwareVersionsByMachineGenerationIdAndVersionString } from "../../actions";

// Components
import { ValidatePermissionForComponent } from "../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../Utils/validatePermissionForComponentPart";
import { AddButton } from "../GenericComponents/buttons/index";
import { SelectGeneral } from "../GenericComponents/selectComponent/selectGeneral";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";
import Titles from "../GenericComponents/titles";

// Language localization
import Strings from "../../systemVariables/languageStrings";

let firmwareInfo = [];
const dateFormat = "DD/MM/YYYY hh:mm:ss a";

class ListfirmwareVersion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firmwareVersionInfo: [],
      firmwareVersionCount: 0,
      MachinGenerations: [],
      searchText: "",
      machineGenerationId: -1,
      versionString: "",
      page: 1,
      size: 10,
      attribute: "id",
      order: "DESC",
      addButtonDisabled: true,
      flag: false,
      loadingInfo: true,
    };
  }

  componentDidMount() {
    const { getFirmwareVersionsByMachineGenerationIdAndVersionString, getAllMachinGenerations } = this.props;
    const { machineGenerationId, versionString, page, size, attribute, order } = this.state;

    getAllMachinGenerations((response) => {
      const MachinGenerations = response.data.data.map((obj) => ({
        value: obj.id,
        label: obj.generationString,
      }));
      MachinGenerations.unshift({
        value: -1,
        label: Strings.generalTerms.all,
      });
      this.setState({ MachinGenerations });
    });

    this.setState({ loadingInfo: true });
    getFirmwareVersionsByMachineGenerationIdAndVersionString(machineGenerationId, versionString, page, size, attribute, order, (response) => {
      this.setState({
        firmwareVersionInfo: response.data.data.content,
        firmwareVersionCount: response.data.data.totalElements,
      });
      this.setState({
        addButtonDisabled: false,
        loadingInfo: false,
      });
    });
  }

  /* ----------------------- Events -----------------------*/
  handleSelectChangeGeneration(event) {
    const { value } = event;
    const { getFirmwareVersionsByMachineGenerationIdAndVersionString } = this.props;
    const { versionString, size, attribute, order } = this.state;
    getFirmwareVersionsByMachineGenerationIdAndVersionString(value, versionString, 1, size, attribute, order, (response) => {
      this.setState({
        firmwareVersionInfo: response.data.data.content,
        firmwareVersionCount: response.data.data.totalElements,
        addButtonDisabled: false,
        loadingInfo: false,
      });
    });
    this.setState({ machineGenerationId: value, loadingInfo: true, page: 1 });
  }

  getColumnSearchProps = (dataIndex, text, searchText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} ${text}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />,
  });

  handleSearch = (selectedKeys) => {
    if (selectedKeys.length > 0) {
      this.setState({
        loadingInfo: true,
        searchText: selectedKeys[0],
      });
      const { getFirmwareVersionsByMachineGenerationIdAndVersionString } = this.props;
      const { machineGenerationId, page, size, attribute, order } = this.state;
      getFirmwareVersionsByMachineGenerationIdAndVersionString(machineGenerationId, selectedKeys[0], page, size, attribute, order, (response) => {
        this.setState({
          firmwareVersionInfo: response.data.data.content,
          firmwareVersionCount: response.data.data.totalElements,
          addButtonDisabled: false,
          loadingInfo: false,
        });
      });
    }
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      loadingInfo: true,
      searchText: "",
    });
    const { getFirmwareVersionsByMachineGenerationIdAndVersionString } = this.props;
    const { machineGenerationId, page, size, attribute, order } = this.state;
    getFirmwareVersionsByMachineGenerationIdAndVersionString(machineGenerationId, "", page, size, attribute, order, (response) => {
      this.setState({
        firmwareVersionInfo: response.data.data.content,
        firmwareVersionCount: response.data.data.totalElements,
        addButtonDisabled: false,
        loadingInfo: false,
      });
    });
    clearFilters();
    this.setState({ searchText: "" });
  };

  showPagination = (page, size) => {
    const { getFirmwareVersionsByMachineGenerationIdAndVersionString } = this.props;
    const { machineGenerationId, versionString, attribute, order } = this.state;
    this.setState({
      loadingInfo: true,
      page,
      size,
    });
    getFirmwareVersionsByMachineGenerationIdAndVersionString(machineGenerationId, versionString, page, size, attribute, order, (response) => {
      this.setState({
        firmwareVersionInfo: response.data.data.content,
        firmwareVersionCount: response.data.data.totalElements,
        addButtonDisabled: false,
        loadingInfo: false,
      });
    });
  };

  additionalFileMethod = (row) => {
    if (row.additionalFiles !== undefined) {
      return row.additionalFiles.length > 0 ? (
        <div className="vertSpace col-12">
          <Table dataSource={row.additionalFiles} bordered pagination={false} size="small" width="75%" rowKey="id_">
            <Column width="50%" title={Strings.firmwareVersion.additionalFile} dataIndex="additionalFileName" key="additionalFileName" align="center" />
            <Column width="50%" title={Strings.firmwareVersion.additionalFileComment} dataIndex="description" key="description" align="center" />
          </Table>
        </div>
      ) : null;
    }
  };

  firmwareVersionDetail(row) {
    this.setState({
      flag: false,
    });

    firmwareInfo = [];
    firmwareInfo.push(
      <div>
        <p>
          {Strings.generalTerms.creationDate}: {moment.utc(row.createDate).local().format(dateFormat)}
        </p>
        <div className="row">
          <div className="vertSpace col-12">
            {row.noaVmcVersionName !== undefined ? (
              <Divider orientation="left">
                <h5>{Strings.firmwareVersion.noaVmcJar} </h5>
              </Divider>
            ) : null}
          </div>
        </div>
        {typeof row.noaVmcVersionName !== "undefined" ? (
          <div className="row">
            <div className="vertSpace col-4">
              <p>
                <b>{Strings.generalTerms.file}:</b>
              </p>
              <p>
                <b>{Strings.generalTerms.description}:</b>
              </p>
            </div>
            <div className="vertSpace col-8">
              <p>{row.noaVmcVersionName}</p>
              <p>{row.noaVmcVersiondescription}</p>
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="vertSpace col-12">
            {row.noaVmcManagerName !== undefined ? (
              <Divider orientation="left">
                <h5>{Strings.firmwareVersion.noaVmcManager} </h5>
              </Divider>
            ) : null}
          </div>
        </div>
        {typeof row.noaVmcManagerName !== "undefined" ? (
          <div className="row">
            <div className="vertSpace col-4">
              <p>
                <b>{Strings.generalTerms.file}:</b>
              </p>
              <p>
                <b>{Strings.generalTerms.description}:</b>
              </p>
            </div>
            <div className="vertSpace col-8">
              <p>{row.noaVmcManagerName}</p>
              <p>{row.noaVmcManagerdescription}</p>
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="vertSpace col-12">
            {row.sqlScriptName !== undefined ? (
              <>
                <Divider orientation="left">
                  <h5>{Strings.firmwareVersion.sqlScript} </h5>
                </Divider>
                <div className="row">
                  <div className="vertSpace col-4">
                    <p>
                      <b>{Strings.generalTerms.file}:</b>
                    </p>
                    <p>
                      <b>{Strings.generalTerms.description}:</b>
                    </p>
                  </div>
                  <div className="vertSpace col-8">
                    <p>{row.sqlScriptName}</p>
                    <p>{row.sqlScriptdescription}</p>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="vertSpace col-12">
            {row.libScriptName !== undefined ? (
              <Divider orientation="left">
                <h5>{Strings.firmwareVersion.libScript} </h5>
              </Divider>
            ) : null}
          </div>
        </div>
        {typeof row.libScriptName !== "undefined" ? (
          <div className="row">
            <div className="vertSpace col-4">
              <p>
                <b>{Strings.generalTerms.file}:</b>
              </p>
              <p>
                <b>{Strings.generalTerms.description}:</b>
              </p>
            </div>
            <div className="vertSpace col-8">
              <p>{row.libScriptName}</p>
              <p>{row.libScriptdescription}</p>
            </div>
          </div>
        ) : null}

        <div className="row">{this.additionalFileMethod(row)}</div>
      </div>
    );
    this.setState({
      flag: true,
    });
  }

  detailsAlert = () => {
    const { firmwareVersionInfo } = this.state;
    if (firmwareVersionInfo.length === 0) {
      return <Alert message={Strings.generalResponses.searchInformation} description={Strings.generalResponses.queryResponse} type="info" showIcon />;
    }
  };

  // Information to render
  render() {
    const {
      permissions: { data },
    } = this.props;
    const { page, size, firmwareVersionCount, flag, loadingInfo, addButtonDisabled, searchText, firmwareVersionInfo, MachinGenerations, machineGenerationId } = this.state;
    // Redirects to ..... for editing
    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.machine.firmwareVersion} tooltip={Strings.machine.firmwareVersionTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-6">
            <SelectGeneral
              text={Strings.machine.machineGeneration}
              tooltip={Strings.machine.machineGenerationTooltip}
              options={MachinGenerations}
              onChange={(e) => this.handleSelectChangeGeneration(e)}
              defaultValue={machineGenerationId}
            />
          </div>

          <ValidatePermissionForComponent permission="PRIVILEGE FIRMWARE VERSION PUT" permissions={data}>
            <div className="vertSpace col-6">
              <AddButton tooltip={Strings.machine.firmwareVersionTooltip} link="/addFirmwareVersion" props={addButtonDisabled} disabled={addButtonDisabled} />
            </div>
          </ValidatePermissionForComponent>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.machine.firmwareVersion} </h3>
            </Divider>
            {this.firmwareVersionTablaAndPagination(firmwareVersionInfo, loadingInfo, searchText, flag, data, firmwareVersionCount, page, size)}
          </div>
        </div>
      </div>
    );
  }

  firmwareVersionTablaAndPagination(firmwareVersionInfo, loadingInfo, searchText, flag, data, firmwareVersionCount, page) {
    return (
      <div className="vertSpace col-12">
        <Table dataSource={firmwareVersionInfo} bordered loading={loadingInfo} pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="id">
          <Column
            width="14%"
            title={<TextWithInfoTooltip name={Strings.firmwareVersion.version} tooltip={Strings.firmwareVersion.versionTooltip} />}
            dataIndex="versionString"
            key="versionString"
            align="center"
            {...this.getColumnSearchProps("versionString", Strings.firmwareVersion.version, searchText)}
          />
          <Column width="14%" title={<div>{Strings.machine.machineGeneration}</div>} dataIndex="generationString" key="generationString" align="center" />
          <Column
            width="45%"
            title={<TextWithInfoTooltip name={Strings.generalTerms.description} tooltip={Strings.firmwareVersion.descriptionToolTip} />}
            dataIndex="description"
            align="center"
            key="description"
          />
          <Column
            width="11%"
            title={<TextWithInfoTooltip name={Strings.generalTerms.details} tooltip={Strings.firmwareVersion.detailsTooltip} />}
            align="center"
            render={(row) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div style={{ alignContent: "center", color: "#004173", cursor: "pointer" }} onClick={() => this.firmwareVersionDetail(row)}>
                <Popover content={flag ? firmwareInfo : <Spin />} trigger="click" placement="left" title={<div>{Strings.firmwareVersion.detailsTooltip}</div>}>
                  <Icon type="profile" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
                </Popover>
              </div>
            )}
          />
          {ValidatePermissionForComponentPart("PRIVILEGE FIRMWARE VERSION POST", data) ? (
            <Column
              width="7%"
              align="center"
              placement="left"
              cursor="pointer"
              title={<div>{Strings.generalTerms.edit}</div>}
              render={(row) => (
                <Link
                  to={{
                    pathname: "/editFirmwareVersion",
                    state: row,
                  }}
                >
                  <Icon type="edit" theme="twoTone" className="addEditRemoveButton" />
                </Link>
              )}
            />
          ) : (
            ""
          )}
        </Table>
        <div className="row">
          <div className="col-xs-0 col-lg-6" />
          <div className="vertSpace col-lg-6">
            <Pagination
              size="small"
              total={firmwareVersionCount}
              showSizeChanger
              onChange={this.showPagination}
              onShowSizeChange={this.showPagination}
              hideOnSinglePage={false}
              pageSizeOptions={["10", "25", "50", "100", "250"]}
              showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total}  ${Strings.generalTerms.items}`}
              current={page}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  getFirmwareVersionsByMachineGenerationIdAndVersionString,
  getAllMachinGenerations,
})(ListfirmwareVersion);
