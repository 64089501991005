import React, { Fragment, Component } from "react";
import "./buttons.css";
import { Icon } from "antd";

class SortButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortAscSel: false,
      sortDescSel: false,
    };
  }

  setSortAsc = () => {
    if (!this.state.sortAscSel) {
      this.setState({
        sortAscSel: true,
        sortDescSel: false,
      });
      if (this.props.onClickSort != null) {
        this.props.onClickSort("ASC");
      }
    } else {
      this.setState({
        sortAscSel: false,
        sortDescSel: false,
      });
      if (this.props.onClickSort != null) {
        this.props.onClickSort("NONE");
      }
    }
  };
  setSortDesc = () => {
    if (!this.state.sortDescSel) {
      this.setState({
        sortAscSel: false,
        sortDescSel: true,
      });
      if (this.props.onClickSort != null) {
        this.props.onClickSort("DESC");
      }
    } else {
      this.setState({
        sortAscSel: false,
        sortDescSel: false,
      });
      if (this.props.onClickSort != null) {
        this.props.onClickSort("NONE");
      }
    }
  };

  render() {
    //Default values
    var hideSortAsc = false;
    if (this.props.hideSortAsc != null) {
      hideSortAsc = this.props.hideSortAsc;
    }
    var hideSortDesc = false;
    if (this.props.hideSortDesc != null) {
      hideSortDesc = this.props.hideSortDesc;
    }
    var loading = false;
    if (this.props.loading != null) {
      loading = this.props.loading;
    }

    return (
      <Fragment>
        {loading ? (
          <Icon type="loading" style={{ fontSize: "20px", color: "#08c" }} />
        ) : (
          <Fragment>
            <div style={{ marginTop: "-10px", cursor: "pointer" }} onClick={this.setSortAsc}>
              <Icon type="caret-up" hidden={hideSortAsc} style={{ fontSize: "11px", color: this.state.sortAscSel ? "#08c" : "#222" }} />
            </div>
            <div style={{ marginTop: "-6px", cursor: "pointer" }} onClick={this.setSortDesc}>
              <Icon type="caret-down" hidden={hideSortDesc} style={{ fontSize: "11px", color: this.state.sortDescSel ? "#08c" : "#222" }} />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default SortButtons;
