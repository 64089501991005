import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Server URL
const resource = "/vmevent";

// Get vmEvents information
export function getVmEvents(
  stockOwnerId,
  scDependencyId,
  vendingMachineId,
  lowerPriority,
  higherPriority,
  eventCategory,
  eventType,
  startDate,
  endDate,
  page,
  size,
  attribute,
  order,
  callback
) {
  return async (dispatch) => {
    await axios
      .get(host + port + path + version + resource, {
        params: {
          stockownerid: stockOwnerId ? stockOwnerId : "",
          scdependencyid: scDependencyId ? scDependencyId : "",
          vendingmachineid: vendingMachineId ? vendingMachineId : "",
          lowerpriority: lowerPriority ? lowerPriority : "",
          higherpriority: higherPriority ? higherPriority : "",
          eventcategory: eventCategory ? eventCategory : "",
          eventtype: eventType ? eventType : "",
          startdate: startDate ? startDate : "",
          enddate: endDate ? endDate : "",
          page,
          size,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        // callback(error);
        return error;
      });
  };
}

// Get vmEvents information grouped (Used for charts)
export function getVmEventsGrouped(stockOwnerId, scDependencyId, vendingMachineId, lowerPriority, higherPriority, eventCategory, eventType, startDate, endDate, callback) {
  return async (dispatch) => {
    await axios
      .get(host + port + path + version + resource, {
        params: {
          stockownerid: stockOwnerId ? stockOwnerId : "",
          scdependencyid: scDependencyId ? scDependencyId : "",
          vendingmachineid: vendingMachineId ? vendingMachineId : "",
          lowerpriority: lowerPriority ? lowerPriority : "",
          higherpriority: higherPriority ? higherPriority : "",
          eventcategory: eventCategory ? eventCategory : "",
          eventtype: eventType ? eventType : "",
          startdate: startDate ? startDate : "",
          enddate: endDate ? endDate : "",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);

        return error;
      });
  };
}

//Get vmEvents notifications
export function getVmEventsNotifications(stockOwnerId, scDependencyId, vendingMachineId, lowerPriority, higherPriority, eventCategory, eventType, date, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          stockownerid: stockOwnerId,
          scdependencyid: scDependencyId,
          vendingmachineid: vendingMachineId,
          lowerpriority: lowerPriority,
          higherpriority: higherPriority,
          eventcategory: eventCategory,
          eventtype: eventType,
          date: date,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
        return error;
      });
  };
}
