import Strings from "../../systemVariables/languageStrings";

export function productTransactionTypeLocalizationLocalization(productTrans) {
  let response = "";
  switch (productTrans) {
    case "STOCKED":
      response = Strings.transactionType.stocked;
      break;
    case "DISPENSED":
      response = Strings.transactionType.dispensed;
      break;
    case "UNDERSTOCK AMENDMENT":
      response = Strings.transactionType.understockAmendment;
      break;
    case "OVERSTOCK AMENDMENT":
      response = Strings.transactionType.overstockAmendment;
      break;
    case "DISPENSATION FAILED":
      response = Strings.transactionType.dispensationFailed;
      break;
    case "DISPENSED WITH LAST PUSH":
      response = Strings.transactionType.dispensedWithLastPush;
      break;
    case "DISPENSATION ADJUSTMENT":
      response = Strings.transactionType.dispensationAdjustment;
      break;
    case "LOCKER PRODUCT SELL":
      response = Strings.transactionType.lockerProductSell;
      break;
    case "LOCKER PRODUCT PROVISIONING":
      response = Strings.transactionType.lockerProductProvisioning;
      break;
    case "LOCKER PRODUCT BORROW":
      response = Strings.transactionType.lockerProductBorrow;
      break;
    case "LOCKER PRODUCT RETURN":
      response = Strings.transactionType.lockerProductReturn;
      break;
    case "LOCKER PRODUCT DELIVERY START":
      response = Strings.transactionType.lockerProductDeliveryStart;
      break;
    case "LOCKER PRODUCT DELIVERY FINISH":
      response = Strings.transactionType.lockerProductDeliveryFinish;
      break;
    case "LOCKER PRODUCT SEND":
      response = Strings.transactionType.lockerProductSend;
      break;
    case "LOCKER PRODUCT PICK UP":
      response = Strings.transactionType.lockerProductPickUp;
      break;
    case "LOCKER LOAN":
      response = Strings.transactionType.lockerLoan;
      break;
    case "LOCKER RETURN":
      response = Strings.transactionType.lockerReturn;
      break;
    case "LOCKER OPEN":
      response = Strings.transactionType.lockerOpen;
      break;
    case "LOCKER AUDIT MATCH":
      response = Strings.transactionType.lockerAuditMatch;
      break;
    case "LOCKER AUDIT EMPTY BUT PRODUCT":
      response = Strings.transactionType.lockerAuditEmptyButProduct;
      break;
    case "LOCKER AUDIT PRODUCT BUT EMPTY":
      response = Strings.transactionType.lockerAuditProductButEmpty;
      break;
    case "LOCKER AUDIT PRODUCT BUT PRODUCT":
      response = Strings.transactionType.lockerAuditProductButProduct;
      break;
    case "LOCKER AUDIT ERROR":
      response = Strings.transactionType.lockerAuditError;
      break;
    case "LOCKER RESERVED DELIVERY START":
      response = Strings.transactionType.lockerReservedDeliveryStart;
      break;
    case "LOCKER LOAN OPEN":
      response = Strings.transactionType.lockerLoanOpen;
      break;
    default:
      break;
  }
  return response.toUpperCase();
}
