import React from "react";
import { Typography, Divider, Table, Alert } from "antd";

import Loading from "../../GenericComponents/loadingComponent/loading";
import { lockerModalitiesLocalization } from "../../../Utils/LanguageLocalization/index";
// Language localization
import Strings from "../../../systemVariables/languageStrings";

const { Paragraph } = Typography;
const { Column } = Table;

const WingTypeId = ({ wingType, lockerTypeDTO, lockerSlotTypeDTO, loading, loading2, productReferences, productsAmountAvailable, vmTraysTotal }) => {
  const productReferencesMethod = (
    <div>
      {productReferences ? (
        <div>
          <div className="row">
            <div className="col-md-6">
              <Paragraph strong> {Strings.product.productQuantity}</Paragraph>
            </div>
            <div className="col-md-6">{productReferences} </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Paragraph strong> {Strings.product.availableProducts} </Paragraph>
            </div>
            <div className="col-md-6">{productsAmountAvailable}</div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Paragraph strong> {Strings.machine.trayQuantity} </Paragraph>
            </div>
            <div className="col-md-6">{vmTraysTotal}</div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <Alert message={Strings.generalTerms.information} description={Strings.planogram.noWingPlanogram} type="info" showIcon />
          </div>
        </div>
      )}
    </div>
  );

  const WingTypeIdMethod = () => {
    if (wingType && wingType.id !== undefined) {
      const { id } = wingType;
      if (id === 3) {
        return (
          <div>
            <div className="row">
              <div className="col-md-6">
                <Paragraph strong> {Strings.machine.wingType} </Paragraph>
              </div>
              <div className="col-md-6">{wingType.name}</div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Paragraph strong> {Strings.machine.lockerType} </Paragraph>
              </div>

              <div className="col-md-6">{lockerTypeDTO.name}</div>
            </div>
            <Divider />
            {lockerSlotTypeDTO ? (
              <>
                <Table dataSource={lockerSlotTypeDTO} loading={loading} pagination={false} bordered size="middle" footer={null} rowKey="id" style={{ margin: "auto" }}>
                  <Column key="id" MaxWidth={170} title={Strings.machine.lockerModalities} render={(row) => <div>{lockerModalitiesLocalization(row.name)} </div>} />
                  <Column MaxWidth={230} title={Strings.generalTerms.description} dataIndex="description" />
                </Table>
                <Divider />
              </>
            ) : (
              ""
            )}
            {loading2 ? (
              <div className="row">
                <Loading />
              </div>
            ) : (
              productReferencesMethod
            )}
          </div>
        );
      }
      return (
        <div>
          <div className="row">
            <div className="col-md-6">
              <Paragraph strong> {Strings.machine.wingType} </Paragraph>
            </div>
            <div className="col-md-6">{wingType.name}</div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Paragraph strong> {Strings.generalTerms.description} </Paragraph>
            </div>
            <div className="col-md-6">{wingType.description}</div>
          </div>
          <Divider />
          {loading2 ? (
            <div className="row">
              <Loading />
            </div>
          ) : (
            productReferencesMethod
          )}
        </div>
      );
    }
    return <div />;
  };
  return WingTypeIdMethod();
};

export default WingTypeId;
