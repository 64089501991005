import Strings from '../../systemVariables/languageStrings';

export function rolesLocalization(role) {
    switch (role) {
        case 'VM SUPERUSER':
            role = Strings.Roles.vmSuperuser;
            break;
        case 'SERVICE STAFF':
            role = Strings.Roles.serviceStaff;
            break;
        case 'CASH AUDITOR':
            role = Strings.Roles.cashAuditor;
            break;
        case 'ENROLLER':
            role = Strings.Roles.enroller;
            break;
        case 'VM BUYER':
            role = Strings.Roles.vmBuyer;
            break;
        case 'PROVISIONER':
            role = Strings.Roles.vmProvisioner;
            break;
        case 'VM ADMIN':
            role = Strings.Roles.vmAdmin;
            break;
        case 'NOA SYS SUPERUSER':
            role = Strings.Roles.noaSysSuperuser;
            break;
        case 'NOA SYS ADMIN':
            role = Strings.Roles.noaSysAdmin;
            break;
        case 'NOA PRODUCTION MANAGER':
            role = Strings.Roles.noaProductionManager;
            break;
        case 'NOA PRODUCTION ATTENDANT':
            role = Strings.Roles.noaProductionAttendant;
            break;
        case 'NOA LOGISTIC MANAGER':
            role = Strings.Roles.noaLogisticManager;
            break;
        case 'NOA LOGISTIC ATTENDANT':
            role = Strings.Roles.noaLogisticAttendant;
            break;
        case 'NOA SUPPORT MANAGER':
            role = Strings.Roles.noaSupportManager;
            break;
        case 'NOA SUPPORT ATTENDANT':
            role = Strings.Roles.noaSupportAttendant;
            break;
        case 'NOA USER TEST':
            role = Strings.Roles.noaUserTest;
            break;
        case 'EXECUTIVE':
            role = Strings.Roles.Executive;
            break;
        case 'LOGISTIC MANAGER':
            role = Strings.Roles.logisticManager;
            break;
        case 'LOGISTIC ATTENDANT':
            role = Strings.Roles.logisticAttendant;
            break;
        case 'PICKING ATTENDANT':
            role = Strings.Roles.pickingAttendant;
            break;
        case 'BILLING ATTENDANT':
            role = Strings.Roles.billingAttendant;
            break;
        case 'VENDING MANAGER':
            role = Strings.Roles.vendingManager;
            break;
        case 'VM PROVISIONER':
            role = Strings.Roles.vmProvisioner;
            break;
        case 'VM CASH AUDITOR':
            role = Strings.Roles.vmCashAuditor;
            break;
        case 'HUMAN RESOURCES':
            role = Strings.Roles.humanResources;
            break;
        case 'REPORT MANAGER':
            role = Strings.Roles.reportManager;
            break;
        case 'API USER':
            role = Strings.Roles.apiUser;
            break;
        case 'EXECUTIVE LOGISTIC':
            role = Strings.Roles.executiveLogistic;
            break;
        case 'SYSTEM BUYER':
            role = Strings.Roles.systemBuyer;
            break;
        case 'MACHINE BUYER':
            role = Strings.Roles.buyer;
            break;
        case 'LOCKER BUYER':
            role = Strings.Roles.lockerBuyer;
            break;
        case 'MACHINE PROVISIONER':
            role = Strings.Roles.provisioner;
            break;
        case 'LOCKER PROVISIONER':
            role = Strings.Roles.lockerProvisioner;
            break;
        case 'SC SYSTEM ADMIN':
            role = Strings.Roles.scSystemAdmin;
            break;
        default:
            break;
    }
    return role;
}