/* eslint-disable no-param-reassign */
import Strings from "../../systemVariables/languageStrings";

export function motorsControllerCommandStatusLocalization(motorsControllerCommandStatus) {
  switch (motorsControllerCommandStatus) {
    case "OK":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.ok;
      break;
    case "MOTORL_OVERCURRENT":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorOvercurrent;
      break;
    case "MOTORL_UNDERCURRENT":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorUndercurrent;
      break;
    case "MOTORH_OVERCURRENT":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorOvercurrent;
      break;
    case "MOTORH_UNDERCURRENT":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorUndercurrent;
      break;
    case "MOTORL_HOME_TIMEOUT":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorHomeTimeout;
      break;
    case "MOTORH_HOME_TIMEOUT":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorHomeTimeout;
      break;
    case "MOTORL_NOT_PRESENT":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorNotPresent;
      break;
    case "MOTORH_NOT_PRESENT":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorNotPresent;
      break;
    case "MOTORL_HOME_UNEXPECTED":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorHomeUnexpected;
      break;
    case "MOTORH_HOME_UNEXPECTED":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorHomeUnexpected;
      break;
    case "TIMEOUT_PARAMETER_ERROR":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.timeoutParameterError;
      break;
    case "MOTOR_AT_HOME_OR_DOOR_CLOSED":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorAtHomeOrDoorClosed;
      break;
    case "MOTOR_OUT_OF_HOME_OR_DOOR_OPEN":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorOutOfHomeOrDoorOpen;
      break;
    case "MOTORL_POSITIVE_RAIL_FAILURE":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorPositiveRailFailure;
      break;
    case "MOTOR_POSITIVE_RAIL_FAILURE":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorPositiveRailFailure;
      break;
    case "MOTOR_NEGATIVE_RAIL_FAILURE":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.motorNegativeRailFailure;
      break;
    case "DOOR_IS_ALREADY_OPEN":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.doorIsAlreadyOpen;
      break;
    case "IN_PROGRESS":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.inProgress;
      break;
    case "UNKNOWN_COMMAND":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.unknownCommand;
      break;
    case "RESET":
      motorsControllerCommandStatus = Strings.motorsControllerCommandStatus.reset;
      break;
    default:
      break;
  }
  return motorsControllerCommandStatus;
}
