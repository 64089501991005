/* eslint-disable no-param-reassign */
// Language localization
import Strings from "../../systemVariables/languageStrings";

export function paymentTypeLocalization(paymentTypeId) {
  switch (paymentTypeId) {
    case 0:
      paymentTypeId = Strings.paymentTypes.cash;
      break;
    case 1:
      paymentTypeId = Strings.paymentTypes.cashless;
      break;
    case 2:
      paymentTypeId = Strings.paymentTypes.token;
      break;
    case 3:
      paymentTypeId = Strings.paymentTypes.rfid;
      break;
    case 4:
      paymentTypeId = Strings.paymentTypes.fingerprint;
      break;
    case 5:
      paymentTypeId = Strings.paymentTypes.barcode;
      break;
    case 7:
      paymentTypeId = Strings.paymentTypes.qr;
      break;
    case 8:
      paymentTypeId = Strings.paymentTypes.httpServer;
      break;
    case 9:
      paymentTypeId = Strings.paymentTypes.nequi;
      break;
    default:
      paymentTypeId = "--";
      break;
  }
  return paymentTypeId;
}
