import React, { Component } from "react";
import { Spin, Skeleton, Alert } from "antd";

import "./loading.css";
// Language localization
import Strings from "../../../systemVariables/languageStrings";

class Loading extends Component {
  state = {};
  render() {
    return (
      <div className="loading">
        {this.props.alert ? <Alert message={Strings.generalResponses.searchInformation} style={{margin:'0% auto',maxWidth:'60%'}} description={Strings.machine.vmEventListLoading} /> : null}
        <Spin tip={Strings.generalTerms.loading} size="large" className="spinner">
          {this.props.skeleton ? <Skeleton active size="default" /> : null}
        </Spin>
        <br />
      </div>
    );
  }
}

export default Loading;
