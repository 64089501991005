import axios from "axios";

import {
    host,
    port,
    path,
    version
} from "../systemVariables"

// Method path
const resource = "/dependecies";

export const DEPENDENCY = 'DEPENDENCY';

export function getDependencyBySystemUser(callback) {
    return (dispatch) => {
        axios(host + port + path + version + resource, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            sessionStorage.setItem("dependencyTypeId", response.data.data.dependencyTypeId);

            dispatch({
                type: DEPENDENCY,
                payload: response.data.data
            });
            callback(response)
        })
        .catch(function (error) {
            return error;
        });
    }
}

