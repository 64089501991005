import { Card, Collapse, Icon, Table } from "antd";
import React, { Component } from "react";
import Strings from "../../../../systemVariables/languageStrings";
const { Panel } = Collapse;
class AdjustmentSummary extends Component {
  selectedAdjustTransactionsColumns = [
    {
      title: Strings.dispensationRule.amount,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      with: "20%",
    },
    {
      title: Strings.transaction.adjustmentType,
      dataIndex: "adjustmentTypeName",
      key: "adjustmentTypeName",
      align: "center",
      with: "30%",
    },
    {
      title: Strings.transaction.transactionIdentifier,
      dataIndex: "adjustmentTypeUuid",
      key: "adjustmentTypeUuid",
      align: "center",
      with: "30%",
    },
    {
      title: Strings.generalTerms.remove,
      key: "remove",
      align: "center",
      with: "20%",
      render: (row) => this.renderRemoveOneAdjustmentTransaction(row),
    },
  ];

  renderRemoveOneAdjustmentTransaction = (row) => {
    const { onRemoveAdjustment } = this.props;
    return (
      <div className="row">
        <div className="col text-center">
          <Icon type="minus-circle" style={{ fontSize: 15, color: "#004173" }} onClick={() => onRemoveAdjustment(row)} />
        </div>
      </div>
    );
  };

  renderPanel() {
    const { adjustmentSummaryTableData } = this.props;
    let panel = <h3 style={{ padding: "1rem" }}>{Strings.transaction.errorMsj.errorMsj6}</h3>;
    if (adjustmentSummaryTableData.length !== 0) {
      panel = (
        <div className="adjustmentSummary">
          <Table size="small" pagination dataSource={adjustmentSummaryTableData} columns={this.selectedAdjustTransactionsColumns} />
        </div>
      );
    }
    return panel;
  }

  render() {
    const { totalAdjustmentsAmount } = this.props;
    return (
      <div>
        <Card>
          <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Icon type="interaction" theme="twoTone" style={{ fontSize: "16px", paddingRight: "0.5em" }} />
            <h2 className="paragraphSubTitle" style={{ alignItems: "center" }}>
              {Strings.transaction.summaryAdjust}
            </h2>
          </div>
          <br />
          <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Collapse className="customCollapse">
              <Panel
                header={
                  <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <h3>{Strings.transaction.totalAmount + ": " + totalAdjustmentsAmount}</h3>
                  </div>
                }
                key={1}
              >
                <div style={{ textAlign: "center" }}>{this.renderPanel()}</div>
              </Panel>
            </Collapse>
          </div>
        </Card>
      </div>
    );
  }
}
export default AdjustmentSummary;
