import { LIST_VENDINGMACHINES, LIST_VENDINGMACHINE, LIST_SODEPENDENCYLOWERELEMENTS } from '../actions'


export function vendingMachines(state = [], action) {
  var vendingMachines;

  switch (action.type) {
    case LIST_SODEPENDENCYLOWERELEMENTS: //Get all the vending machines by a soDependency (their vending machines and those of their childs)
    var vendingMachinesLower = action.payload.data.vendingMachinesShortDto.map(item => ({value:item.id, label:item.friendlyName}))
      return vendingMachinesLower;

    case LIST_VENDINGMACHINES:
      vendingMachines = action.payload.data.map(item => ({value:item.id, label:item.friendlyName}))
      return vendingMachines;
    case LIST_VENDINGMACHINE:
      return Object.assign({}, state, {vendingMachineObj: action.payload})
    default:
      return state
  }
}
