import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/vmproducttransactions";

export const LIST_USERTRANSACTION  = 'LIST_USERTRANSACTION';
export const ERROR = 'ERROR';

export function listUserTransaction(){
  return (dispatch) => {
    axios.get(host+ port + path  + version + resource,{
      method:'GET',
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': 'true',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
      credentials: 'same-origin',
    })
    .then((response) => {
      dispatch( { type: LIST_USERTRANSACTION, payload: response.data } );
    })
    .catch(function (error){
      dispatch( { type: ERROR, payload: error } );
    });
  }
}
