import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Tooltip, Icon, Button } from "antd";
//import AcceptIcon from '../../Global/images/icons/accept.png';
import "./buttons.css";
import Strings from "../../../systemVariables/languageStrings";

export function AcceptButton(props) {
  return (
    <Fragment>
      <Link
        to={{
          pathname: props.link,
          props: props.props,
        }}
      >
        <Tooltip title={props.tooltip}>
          <Button onClick={props.onClick} disabled={props.isDisabled} loading={props.loading} className="button">
            {props.loading ? "" : <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" hidden={props.hideIcon} />}
            <span>{Strings.generalTerms.accept}</span>
          </Button>
        </Tooltip>
      </Link>
    </Fragment>
  );
}
