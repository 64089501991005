//Language localization
import Strings from "../../systemVariables/languageStrings";

export function vmParameterCategoryNameLocalizationArray(vmParameterCategories) {
  vmParameterCategories.forEach((item) => {
    switch (item.label) {
      case "General":
        item.label = Strings.vmSetup.categories.general;
        break;
      case "Dispensation":
        item.label = Strings.vmSetup.categories.dispensation;
        break;
      case "Localization":
        item.label = Strings.vmSetup.categories.localization;
        break;
      case "Blocking":
        item.label = Strings.vmSetup.categories.blocking;
        break;
      case "Synchronization":
        item.label = Strings.vmSetup.categories.synchronization;
        break;
      case "Provisioning":
        item.label = Strings.vmSetup.categories.provisioning;
        break;
      case "Cash Management":
        item.label = Strings.vmSetup.categories.cashManagement;
        break;
      case "Enrollment":
        item.label = Strings.vmSetup.categories.enrollment;
        break;
      case "CV Algorithm":
        item.label = Strings.vmSetup.categories.cvAlgorithm;
        break;
      case "Refrigeration":
        item.label = Strings.vmSetup.categories.refrigeration;
        break;
      default:
        break;
    }
  });
  return vmParameterCategories;
}
