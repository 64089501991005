import React from 'react';
import { Tooltip, Icon, Select } from 'antd';
import './selectComponent.css';

export function SelectGeneralMultiple(props) {
  let options = props.options.map(obj => {
    let option = <Select.Option key={obj.value}>{obj.label}</Select.Option>;
    return option;
  });

  let selectedOptions = props.selectedOptions.map(obj => {
    return obj.toString();
  });

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="selectAligning col-4">
          <span>
            {props.text}
            <b>{props.isReq === true ? ': *' : ':'}</b>
          </span>
          {props.tooltip ? (
            <Tooltip title={props.tooltip}>
              <Icon className="icon" type="info-circle" /> <span />
            </Tooltip>
          ) : null}
        </div>
        <div className="col-8 input">
          <Select
            className="input"
            mode="multiple"
            style={props.style}
            placeholder={props.placeholder}
            value={selectedOptions}
            onChange={props.onChange}
            disabled={props.disabled}
            allowClear={props.allowClear}
          >
            {options}
          </Select>
        </div>
      </div>
    </div>

  );
}
