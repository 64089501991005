import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/privilegebyrole";

//disable role with privilege to a Sc or So
export function disablePrivilegeByRoleId(
    roleId,
    privilegeId,
    callback) {
    return () => {
        axios(
            {
                params: {
                    roleid: roleId,
                    privilegeid: privilegeId
                },
                method: 'DELETE',
                url: host + port + path + version + resource,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
                },
            })
            .then((response) => {
                callback(response);
            })
            .catch(function (error) {
                callback(error);
            });
    }
}