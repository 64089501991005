import axios from "axios";

import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/patientsInformation";

export async function getPatientsBySoDependencyId(soDependencyId, page, size, sortingKey, sortDirection) {
  try {
    let response = await axios({
      method: 'GET',
      url: host + port + path + version + resource,
      params: {
        soDependencyId: soDependencyId,
        page: page,
        size: size,
        sortingKey: sortingKey,
        sortDirection: sortDirection
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (e) {
    return e
  }
}

export async function getPatientsBySoDependencyIdContainingFilter(soDependencyId, identityCardNumber, medicalHistoryNumber, page, size, sortingKey, sortDirection) {
  try {
    let response = await axios({
      method: 'GET',
      url: host + port + path + version + resource + "/filter",
      params: {
        soDependencyId: soDependencyId,
        identityCardNumber: identityCardNumber,
        medicalHistoryNumber: medicalHistoryNumber,
        page: page,
        size: size,
        sortingKey: sortingKey,
        sortDirection: sortDirection
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (e) {
    return e
  }
}

export async function getPatientsByScDependencyId(scDependencyId, page, size, sortingKey, sortDirection) {
  try {
    let response = await axios({
      method: 'GET',
      url: host + port + path + version + resource,
      params: {
        scDependencyId: scDependencyId,
        page: page,
        size: size,
        sortingKey: sortingKey,
        sortDirection: sortDirection
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (e) {
    return e
  }
}

export async function getPatientsByScDependencyIdContainingFilter(scDependencyId, identityCardNumber, medicalHistoryNumber, page, size, sortingKey, sortDirection) {
  try {
    let response = await axios({
      method: 'GET',
      url: host + port + path + version + resource + "/filter",
      params: {
        scDependencyId: scDependencyId,
        identityCardNumber: identityCardNumber,
        medicalHistoryNumber: medicalHistoryNumber,
        page: page,
        size: size,
        sortingKey: sortingKey,
        sortDirection: sortDirection
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (e) {
    return e
  }
}

export async function editPatientInformation(patient) {
  try {
    let response = await axios({
      method: 'PUT',
      url: host + port + path + version + resource,
      data: JSON.stringify(patient),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (e) {
    return e
  }
}

export async function freePatientLocation(patient) {
  try {
    let response = await axios({
      method: 'PUT',
      url: host + port + path + version + resource + "/discharge",
      data: JSON.stringify(patient),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (e) {
    return e
  }
}

export async function addPatientInformation(patient) {
  try {
    let response = await axios({
      method: 'POST',
      url: host + port + path + version + resource,
      data: JSON.stringify(patient),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (e) {
    return e
  }
}

export async function deletePatientInformationByUuid(patientUuid) {
  try {
    let response = await axios({
      method: 'DELETE',
      url: host + port + path + version + resource,
      data: {uuid: patientUuid},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (e) {
    return e
  }
}

export async function exportPatientInformation(soDependencyId, scDependencyId, fileType, columnHeaders) {
  try {
    let response = await axios({
      method: 'GET',
      url: host + port + path + version + resource,
      params: {
        soDependencyId: soDependencyId,
        scDependencyId: scDependencyId,
        fileType: fileType,
        columnHeaders: encodeURIComponent(columnHeaders),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (e) {
    return e
  }
}

export function getPatientInformationById(
  patientInformationId,
  callback,
) {
  return () => { 
    axios.get(`${host}${port}${path}${version}${resource}/${patientInformationId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    }).then((response) => {
      callback(response);
    })
    .catch(function (error) {
      callback(error);
    });
  };
}

// Bash import users method
export async function bashImportPatients(scDependencyId, patientsData) {
  try {
    let response = await axios({
      method: 'POST',
      url: host + port + path + version + resource,
      data: patientsData,
      params: {
        scdependencyid: scDependencyId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
    return response
  } catch (e) {
    return e
  }
}