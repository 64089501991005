import React from "react";
import { Tooltip, DatePicker, Icon } from "antd";
import "./rangeDate.css";

const { RangePicker } = DatePicker;

export function RangeDate(props) {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="selectAligning col-4">
          {props.inputText ? (
            " "
          ) : (
            <span className="inputText">
              {props.text}
              <b>{props.isReq === true ? ": *" : ":"}</b>
            </span>
          )}
          {props.tooltip ? (
            <Tooltip title={props.tooltip}>
              <Icon className="icon" type="info-circle" />
              <span />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        <div className="col-8 datePickerComponent">
          <RangePicker
            onChange={props.onChange}
            placeholder={["Start Time", "End Time"]}
            value={[props.startDate, props.endDate]}
            defaultValue={[props.startDate, props.endDate]}
            format={props.dateFormat}
            disabled={props.isDisabled}
            size="default"
          />
        </div>
      </div>
    </div>
  );
}
