//Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";

//Components
import Titles from "../../GenericComponents/titles.js";
import { Tabs, Divider } from "antd";
import UserInformation from "../general/UserInformationComponent.jsx";
import CreditInfo from "./creditInfo";
import TransactionsInfo from "./transactionsInfo";
import DispensationRulesInfo from "./dispensationRulesInfo";
import { identityCardTypeLocalization } from "../../../Utils/LanguageLocalization";

//Language localization
import Strings from "../../../systemVariables/languageStrings";

import { getUserById } from "../../../actions";

const { TabPane } = Tabs;

class MyAccountInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    const { userSessionInfo } = this.props;
    this.props.getUserById(userSessionInfo.id, (response) => {
      this.setState({
        user: this.mapUserInfo(response.data.data),
        isUserDataLoaded: true,
      });
    });
  }

  //-----Events-----

  mapUserInfo = (userInitialInfo) => {
    const { user } = { ...this.state };

    user.id = userInitialInfo.id;
    user.firstName = userInitialInfo.firstName;
    user.middleName = userInitialInfo.middleName;
    user.lastName1 = userInitialInfo.lastName1;
    user.lastName2 = userInitialInfo.lastName2;
    user.identityCardNumber = userInitialInfo.identityCardNumber;
    user.identityCardTypeId = userInitialInfo.identityCardType.id;
    user.identityCardTypeName = identityCardTypeLocalization(userInitialInfo.identityCardType.name);
    user.phone1 = userInitialInfo.phone1;
    user.phone2 = userInitialInfo.phone2;
    user.countryId = userInitialInfo.country.id;
    user.countryName = userInitialInfo.country.name;
    user.provinceId = userInitialInfo.province.id;
    user.provinceName = userInitialInfo.province.name;
    user.cityId = userInitialInfo.city.id;
    user.cityName = userInitialInfo.city.name;
    user.address = userInitialInfo.address;
    user.email = userInitialInfo.email;
    user.birthDate = userInitialInfo.birthDate;

    return user;
  };

  render() {
    const { user } = this.state;
    return (
      <div className="content-container">
        <div className="row">
          <div className="col-md-12">
            <Titles title={Strings.myAccount.myAccount} tooltip={Strings.machine.setupTooltip} />
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            {this.state.user ? (
              <Divider orientation="left">
                <h2>
                  {this.state.user.firstName} {this.state.user.middleName} {this.state.user.lastName1} {this.state.user.lastName2}{" "}
                </h2>
                <br />
              </Divider>
            ) : (
              ""
            )}

            <div className="col-12">
              <Tabs style={{ overflow: "visible" }}>
                <TabPane tab={Strings.machine.configuration.generalInformation} key="1">
                  <div className="row align-items-center">
                    <div className="col-md-9 text-left">
                      <UserInformation user={user} />
                    </div>
                  </div>
                </TabPane>

                <TabPane tab={Strings.vmcashvaulttype.credit} key="2">
                  <div className="row align-items-center">
                    <div className="col-md-9 text-left">
                      <CreditInfo userId={user.id} />
                    </div>
                  </div>
                </TabPane>

                <TabPane tab={Strings.dispensationRule.dispensationRule} key="3">
                  <div className="row align-items-center">
                    <div className="col-md-9 text-left">
                      <DispensationRulesInfo userId={user.id} />
                    </div>
                  </div>
                </TabPane>

                <TabPane tab={Strings.transaction.transactions} key="4">
                  <TransactionsInfo userId={user.id} />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
    userSessionInfo: state.users.userSessionObj.data,
  };
}

export default connect(mapStateToProps, {
  getUserById,
})(MyAccountInfo);
