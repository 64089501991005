import axios from "axios";
import {
  host,
  port,
  path,
  version
} from "../systemVariables"

// Method path
const resource = "/vmcoinassignation";

export function getMoneyInCoinsByVmId(vmId, callback) {
  return () => {
    axios.get(host + port + path + version + resource, {
        params: {
          vendingmachineid: vmId,
        },
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
        }
      })
      .then((response) => {
        callback(response)
      })
      .catch(function (error) {
        return error;
      });
  }
}

export function clearCoinBoxOrTube(dto, callback) {
  return () => {
    const data = JSON.stringify(dto);
    axios({
      method: 'put',
      url: `${host + port + path + version + resource}/cleaner`,
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error);
      });
  };
}