import axios from "axios";

import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/permissiontype";

export function listPermissionTypeByDependencyTypeId(dependencyTypeId, callback) {
    return () => {
        axios.get(host + port + path + version + resource, {
            params: {
                dependencytype: dependencyTypeId,
            },
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
            },
        })
            .then((response) => {
                callback(response)
            })
            .catch((error) => {
                return (error)
            });
    }
}
