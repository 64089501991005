import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/vmmodel";

export const LIST_VMMODELS  = 'LIST_VMMODELS';

export function listVmModels(){
  return (dispatch) => {
    axios.get(host+ port + path + version  + resource,{
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    .then((response) => {
      dispatch( { type: LIST_VMMODELS, payload: response.data } );
    })
    .catch(function (error){
      return error;
    });
  }
}