import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Table, Icon, Popconfirm, Modal } from "antd";
import { Redirect } from "react-router-dom";
import Column from "antd/lib/table/Column";

// Action
import { listSoProductSlotTypeBySoProductId, deleteSoProductSlotTypeAction } from "../../../actions/index";

import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { TrayPositionNameLocalization } from "../../../Utils/LanguageLocalization/trayPositionNameLocalization";
import { SlotSizeNameLocalization } from "../../../Utils/LanguageLocalization/slotSizeNameLocalization";
import { motorTypeConfigLocalization, helixTypeLocalization } from "../../../Utils/LanguageLocalization/index";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";

class SoProductSlotTypeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editConfirm: false,
      prodToEdit: null,
      soProdSlotTypes: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.soProductSlotTypes && props.soProductSlotTypes !== state.soProdSlotTypes) {
      const i = 0
      return {
        soProdSlotTypes: props.soProductSlotTypes.map((obj) => {
          obj.key = i+1
          return obj
        })
      }
    } else {
      return {soProdSlotTypes: []}
    }
  }

  // --------Events--------
  handleEditConfirm(row) {
    this.setState({
      editConfirm: true,
      prodToEdit: row,
    });
  }

  handleDeleteConfirm(row) {
    const { deleteSoProductSlotTypeAction, listSoProductSlotTypeBySoProductId, soProductId } = this.props;
    deleteSoProductSlotTypeAction(row.id, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code == 0) {
        // Successfull entity save

        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
        });
        listSoProductSlotTypeBySoProductId(soProductId, (ans) => {
          const soProductSlotTypeBySo = ans.data.data;

          // Filter to get only one SoProductSlotType
          const soProductSlotTypeBySoTotal = [];
          soProductSlotTypeBySo.forEach((elem) => {
            if (soProductSlotTypeBySoTotal.filter((e) => e.id === elem.id) == 0) {
              soProductSlotTypeBySoTotal.push(elem);
            }
          });

          // Map motors to MotorConfig in motorH
          soProductSlotTypeBySoTotal.forEach((elem) => {
            elem.motorConfig = motorTypeConfigLocalization(elem.motorL, elem.motorH).label;
          });

          // Map helix type
          soProductSlotTypeBySoTotal.forEach((elem) => {
            elem.helixL = helixTypeLocalization(elem.helixL, elem.helixH, elem.spaces, elem.turnTypeNameL, elem.length);
          });

          this.setState({
            soProdSlotTypes: soProductSlotTypeBySoTotal,
          });
        });
      } else {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      }
    });
  }

  methodTableRender(soProdSlotTypes, isLoading, data) {
    return (
      <>
        <Table dataSource={soProdSlotTypes} bordered loading={isLoading} pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="id">
          <Column
            style={{ width: "20%" }}
            title={<TextWithInfoTooltip name={Strings.planogram.size} tooltip={Strings.planogram.sizeTooltip} />}
            dataIndex="slotSizeName"
            render={(slotSize) => <span>{SlotSizeNameLocalization(slotSize)}</span>}
          />
          <Column
            style={{ width: "15%" }}
            title={<TextWithInfoTooltip name={Strings.planogram.motorConfig} tooltip={Strings.planogram.motorConfigTooltip} />}
            dataIndex="motorConfig"
          />
          <Column style={{ width: "20%" }} title={<TextWithInfoTooltip name={Strings.planogram.motor} tooltip={Strings.planogram.motorTooltip} />} dataIndex="motorL" />
          <Column style={{ width: "20%" }} title={<TextWithInfoTooltip name={Strings.planogram.helix} tooltip={Strings.planogram.helixTooltip} />} dataIndex="helixL" />
          <Column
            style={{ width: "10%" }}
            title={<TextWithInfoTooltip name={Strings.planogram.defaultTrayPosition} tooltip={Strings.planogram.defaultTrayPositionTooltip} />}
            dataIndex="defaultVmTrayPositionName"
            render={(defaultVmTrayPositionName) => <span>{TrayPositionNameLocalization(defaultVmTrayPositionName)}</span>}
          />
          <Column
            style={{ width: "10%" }}
            title={<TextWithInfoTooltip name={Strings.planogram.productAligner} tooltip={Strings.planogram.productAlignerTooltip} />}
            dataIndex="hasProductAligner"
            render={(row) => (row == 1 ? <div>{Strings.generalTerms.yes}</div> : <div>{Strings.generalTerms.no}</div>)}
          />

          <Column
            style={{ width: "5%" }}
            title={<TextWithInfoTooltip name={Strings.planogram.slidingBase} tooltip={Strings.planogram.slidingBaseTooltip} />}
            dataIndex="hasSlidingBase"
            render={(row) => (row == 1 ? <div>{Strings.generalTerms.yes}</div> : <div>{Strings.generalTerms.no}</div>)}
          />
          {ValidatePermissionForComponentPart("PRIVILEGE SO PRODUCT SLOT TYPE PUT", data) ? (
            <Column
              style={{ width: "2.5%" }}
              align="center"
              title={Strings.generalTerms.edit}
              render={(row) => (
                <div style={{ cursor: "pointer" }}>
                  <Popconfirm title={Strings.product.editSoProductSlotTypeWarn} onConfirm={() => this.handleEditConfirm(row)} okText="Sí" cancelText="No">
                    <Icon type="edit" theme="twoTone" className="addEditRemoveButton" />
                  </Popconfirm>
                </div>
              )}
            />
          ) : (
            ""
          )}
          {ValidatePermissionForComponentPart("PRIVILEGE SO PRODUCT SLOT TYPE DELETE", data) ? (
            <Column
              title={Strings.generalTerms.delete}
              align="center"
              style={{ width: "2.5%" }}
              accessor="remove"
              render={(row) => (
                <div>
                  <Popconfirm title={Strings.product.deleteSoProductSlotTypeWarn} onConfirm={() => this.handleDeleteConfirm(row)} okText="Sí" cancelText="No">
                    <Icon type="delete" className="addEditRemoveButton" />
                  </Popconfirm>
                </div>
              )}
            />
          ) : (
            ""
          )}
        </Table>
      </>
    );
  }

  render() {
    // Redirects to addSoProductSlotType for editing
    const { editConfirm, prodToEdit, soProdSlotTypes } = this.state;
    const { isLoading } = this.props;
    if (editConfirm) {
      return (
        <Redirect
          to={{
            pathname: "/addSoProductSlotType",
            props: { soProdSlotTypeToEdit: prodToEdit, selectedProd: { value: prodToEdit.soProductId, label: prodToEdit.productName } },
          }}
        />
      );
    }
    const {
      permissions: { data },
    } = this.props;

    return <QueryResponse isLoading={isLoading} callback={this.methodTableRender(soProdSlotTypes, isLoading, data)} dataSourceLength={soProdSlotTypes.length} />;
  }
}

const mapStateToProps = (state) => ({
  permissions: state.userSystemPrivileges.privilegeObj,
});

export default connect(mapStateToProps, {
  deleteSoProductSlotTypeAction,
  listSoProductSlotTypeBySoProductId,
})(SoProductSlotTypeTable);
