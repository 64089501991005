import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/vmkeylist";

export const LIST_VMKEYLIST  = 'LIST_VMKEYLIST';

export function listVmKeylist(keyStatusId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          status: keyStatusId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}