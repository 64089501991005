/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
// Dependencies
import { Button, Divider, Icon, Input, Modal, Pagination, Table, Tooltip } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import moment from "moment";
import { maxRegistersPerReport } from "../../systemVariables/serverInformation";
import { ValidateDependency } from "../../Utils/validateDependency";
import { ValidatePermissionForComponent } from "../../Utils/validatePermissionForComponent";
import { ExportButton } from "../GenericComponents/buttons/index";
import { RangeDate } from "../GenericComponents/DateComponent/RangeDate";
import ExportReportsModal from "../GenericComponents/exportReportModal";
import Loading from "../GenericComponents/loadingComponent/loading";
import QueryResponse from "../GenericComponents/queryResponse/QueryResponse";
import { SelectGeneral } from "../GenericComponents/selectComponent/index";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";
import Titles from "../GenericComponents/titles";
import ProvisioningSummaryModal from "./ProvisioningSummaryModal";
// Actions
import {
  exportProvisioningInfoReport,
  getLockerProvisioningDetailsByProvisioningIdAndFilter,
  getLockerProvisioningTransactionTypes,
  getModuleTypesByMachine,
  getProvisioningDetailsByProvisioningId,
  getProvisioningDetailsByProvisioningIdAndFilterSelected,
  getProvisioningListByMachineType,
  listScDependenciesBySoDependencyId,
  listSoDependencyBySoDependencyType0,
  listVendingMachinesByScDependencyId,
} from "../../actions";

import { DATE_TIME_FORMAT } from "../../systemVariables/TimeConfig";

// Language Localization
import Strings from "../../systemVariables/languageStrings";
import { productTransactionTypeLocalizationLocalization } from "../../Utils/LanguageLocalization";

const { Column } = Table;

const moduleTypeOptions = [
  { value: 2, label: Strings.generalTerms.all },
  { value: 1, label: Strings.machine.vendingMachines },
  { value: 0, label: Strings.machine.lockers },
];

class ListProvisioningInfoByVM extends Component {
  constructor(props) {
    super(props);
    const { userDependency } = this.props;
    this.state = {
      // Disable selects
      disabledScDependencyFilter: true,
      disabledVendingMachineFilter: true,
      disabledCalendarFilter: true,

      // Selects arrays information
      scDependencies: [],
      vendingMachines: [],

      // Select values
      soDependencyId: userDependency.dependencyType === "so" && userDependency.dependencyId === 1 ? userDependency.dependencyId : null,
      scDependencyId: userDependency.dependencyType === "sc" ? userDependency.dependencyId : null,
      vendingMachineId: null,
      vendingMachineName: null,
      startDate: moment().subtract(3, "months"),
      endDate: moment(),
      userName: "",
      provisioningId: "",
      provisioningInfo: null,
      provisioningList: [],
      totalElements: 0,
      isDisabled: true,

      // Details Modal
      isDetailsModalVisible: false,
      isLoading: false,
      vmTransactionInfo: null,
      lockerTransactionInfo: null,
      totalStockedAmount: 0,
      totalUnderstockAmendmentAmount: 0,
      totalOverstockAmendmentAmount: 0,

      // Export Report
      isExportModalVisible: false,
      isExceedModalVisible: false,
      exportLoading: false,
      isExportDisabled: true,

      // Pagination
      pageSize: 10,
      page: 1,
      attribute: "transactionDate",
      order: "DESC",
      loadingInfo: false,

      // Modules
      lockerTypes: null,
      vmTypes: null,
      moduleType: 2,
    };
    this.showPagination = this.showPagination.bind(this);
    this.dateOnChange = this.dateOnChange.bind(this);
  }

  async componentDidMount() {
    const { listSoDependencyBySoDependencyType0, listScDependenciesBySoDependencyId, listVendingMachinesByScDependencyId, soDependencies, userDependency } = this.props;
    const {
      soScSelectionProps: { so, sc },
    } = this.props;
    const { soDependencyId } = this.state;

    // * User StockOwner Noatec
    /* Checking if the sessionStorage has a dependencyType of "so" and a dependencyId of 1. If it does, it
 will call the listSoDependencyBySoDependencyType0 function and set the soDependencyId to 1. Then it
 will call the queryListScDependenciesBySoDependencyId function. */
    if (sessionStorage.getItem("dependencyType") === "so" && parseInt(sessionStorage.getItem("dependencyId"), 10) === 1 && so === null) {
      listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
      await new Promise((resolve) =>
        this.setState(
          {
            soDependencyId: 1,
          },
          resolve
        )
      );
      await this.queryListScDependenciesBySoDependencyId(listScDependenciesBySoDependencyId, soDependencyId);
    } else if (so !== null && so !== -1) {
      await new Promise((resolve) =>
        this.setState(
          {
            soDependencyId: so.id,
          },
          resolve
        )
      );
      await this.queryListScDependenciesBySoDependencyId(listScDependenciesBySoDependencyId, so.id);
      if (sc !== null && sc !== -1) {
        await new Promise((resolve) =>
          this.setState(
            {
              scDependencyId: sc.id,
            },
            resolve
          )
        );
        const { scDependencyId } = this.state;
        await this.querylistVendingMachinesByScDependencyId(listVendingMachinesByScDependencyId, scDependencyId);
      }
    }

    // ? User of StockOwner different to Noatec
    /* Checking if the dependencyType is so and the dependencyId is not 1. If it is, then it is setting the
    soDependencyId to the dependencyId. */
    if (sessionStorage.getItem("dependencyType") === "so" && parseInt(sessionStorage.getItem("dependencyId"), 10) !== 1) {
      const value = (await userDependency.dependencyType) === "so" && (await userDependency.dependencyId) !== 1 ? await userDependency.dependencyId : "";

      await new Promise((resolve) =>
        this.setState(
          {
            soDependencyId: value,
          },
          resolve
        )
      );
      const { soDependencyId } = this.state;
      await this.queryListScDependenciesBySoDependencyId(listScDependenciesBySoDependencyId, soDependencyId);
    }

    /*  User stockCustomer  */
    /* Setting the state of the component. */
    if (sessionStorage.getItem("dependencyType") === "sc") {
      const value = sessionStorage.getItem("dependencyId");

      await new Promise((resolve) =>
        this.setState(
          {
            loadingInfo: true,
            scDependencyId: userDependency.dependencyType === "sc" ? userDependency.dependencyId : null,
            scDependencyName: userDependency.dependencyType === "sc" ? userDependency.dependencyName : null,
          },
          resolve
        )
      );

      await this.querylistVendingMachinesByScDependencyId(listVendingMachinesByScDependencyId, value);
    }
  }

  async querylistVendingMachinesByScDependencyId(listVendingMachinesByScDependencyId, value) {
    await listVendingMachinesByScDependencyId(value, async (response) => {
      await new Promise((resolve) =>
        this.setState(
          {
            disabledVendingMachineFilter: false,
            loadingInfo: false,
            vendingMachines: response.data.data.map((item) => ({
              value: item.id,
              label: item.friendlyName,
            })),
          },
          resolve
        )
      );
    });
  }

  async queryListScDependenciesBySoDependencyId(listScDependenciesBySoDependencyId, soDependencyId) {
    if (soDependencyId !== -1) {
      await listScDependenciesBySoDependencyId(soDependencyId, async (response) => {
        await new Promise((resolve) =>
          this.setState(
            {
              scDependencies: response.data.data.map((item) => ({
                value: item.id,
                label: item.name,
              })),
              disabledScDependencyFilter: false,
            },
            resolve
          )
        );
      });
    }
  }

  // Filters Events
  soDependencyHandleChange(event) {
    const { listScDependenciesBySoDependencyId } = this.props;
    listScDependenciesBySoDependencyId(event.value, (response) => {
      this.setState({
        scDependencies: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });

    this.setState({
      soDependencyId: event.value,
      scDependencyId: null,
      scDependencyName: null,
      disabledScDependencyFilter: false,
      vendingMachineId: null,
      disabledVendingMachineFilter: true,
      provisioningList: [],
      isExportDisabled: true,
      isDisabled: true,
      totalElements: 0,
      // Clean module type selector
      lockerTypes: null,
      vmTypes: null,
      moduleType: 2,
    });
  }

  scDependencyHandleChange(event) {
    const { listVendingMachinesByScDependencyId } = this.props;
    this.setState({
      scDependencyId: event.value,
      scDependencyName: event.label,
      startDate: moment().subtract(3, "months"),
      endDate: moment(),
      disabledCalendarFilter: true,
      vendingMachineId: null,
      provisioningList: [],
      isExportDisabled: true,
      isDisabled: true,
      totalElements: 0,
      // Clean module type selector
      lockerTypes: null,
      vmTypes: null,
      moduleType: 2,
    });

    listVendingMachinesByScDependencyId(event.value, (response) => {
      this.setState({
        disabledVendingMachineFilter: false,
        vendingMachines: response.data.data.map((item) => ({
          value: item.id,
          label: item.friendlyName,
        })),
      });
    });
  }

  vendingMachineHandleChange(event) {
    const { getModuleTypesByMachine } = this.props;
    this.setState({
      vendingMachineId: event.value,
      vendingMachineName: event.label,
      disabledCalendarFilter: false,
      provisioningList: [],
      isExportDisabled: true,
      isDisabled: false,
      totalElements: 0,
      // Clean module type selector
      lockerTypes: null,
      vmTypes: null,
      moduleType: 2,
    });

    getModuleTypesByMachine(event.value, (response) => {
      if (response.data && response.data.status === "SUCCESS") {
        this.setState({
          lockerTypes: response.data.data.data[0],
          vmTypes: response.data.data.data[1],
        });

        if (response.data.data.data[0] > 0 && response.data.data.data[1] > 0) {
          this.setState({ isExportDisabled: true });
        } else if (response.data.data.data[0] > 0 && response.data.data.data[1] === 0) {
          this.setState({ isExportDisabled: false, moduleType: 0 });
        } else if (response.data.data.data[0] === 0 && response.data.data.data[1] > 0) {
          this.setState({ isExportDisabled: false, moduleType: 1 });
        }
      }
    });
  }

  moduleTypeHandleChange(event) {
    this.setState({
      provisioningList: [],
      moduleType: event.value,
      isExportDisabled: true,
    });
  }

  dateOnChange(date) {
    if (date.length === 0) {
      date[0] = moment().subtract(3, "months");
      date[1] = moment();
    }
    this.setState({
      provisioningList: [],
      startDate: date[0],
      endDate: date[1],
      isExportDisabled: true,
    });
  }

  onClickCheckButton = () => {
    const { getProvisioningListByMachineType } = this.props;
    const { moduleType, startDate, endDate, vendingMachineId, page, pageSize, attribute, order } = this.state;
    this.setState({
      provisioningList: [],
      loadingInfo: true,
      page: 1,
    });

    this.setState({ isExportDisabled: moduleType === 2 });
    getProvisioningListByMachineType(moduleType, vendingMachineId, startDate.format(), endDate.format(), "", "", 1, pageSize, attribute, order, (response) => {
      this.setState({
        provisioningList: response.data.data.content,
        totalElements: response.data.data.totalElements,
        loadingInfo: false,
      });
    });
  };

  // Details modal methods
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} by ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearch = (selectedKeys, dataIndex) => {
    const { moduleType, vendingMachineId, startDate, endDate, page, pageSize, attribute, order } = this.state;
    const { getProvisioningListByMachineType } = this.props;
    if (selectedKeys.length > 0) {
      this.setState({ loadingInfo: true });
      if (dataIndex === "user") {
        getProvisioningListByMachineType(moduleType, vendingMachineId, startDate.format(), endDate.format(), selectedKeys[0], "", page, pageSize, attribute, order, (response) => {
          this.setState({
            page: 1,
            provisioningList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            loadingInfo: false,
            userName: selectedKeys[0],
            provisioningId: "",
          });
        });
      }
      if (dataIndex === "provisioningId") {
        getProvisioningListByMachineType(moduleType, vendingMachineId, startDate.format(), endDate.format(), "", selectedKeys[0], page, pageSize, attribute, order, (response) => {
          this.setState({
            page: 1,
            provisioningList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            loadingInfo: false,
            userName: "",
            provisioningId: selectedKeys[0],
          });
        });
      }
    }
  };

  handleReset = (clearFilters) => {
    clearFilters();
    const { getProvisioningListByMachineType } = this.props;
    const { moduleType, vendingMachineId, startDate, endDate, page, pageSize, attribute, order } = this.state;
    this.setState({ loadingInfo: true });
    getProvisioningListByMachineType(moduleType, vendingMachineId, startDate.format(), endDate.format(), "", "", page, pageSize, attribute, order, (response) => {
      this.setState({
        page: 1,
        provisioningList: response.data.data.content,
        totalElements: response.data.data.totalElements,
        loadingInfo: false,
        userName: "",
        provisioningId: "",
      });
    });
  };

  // searchObj in Modal
  searchObj = (obj) => {
    const { getProvisioningDetailsByProvisioningIdAndFilterSelected, getProvisioningDetailsByProvisioningId, getLockerProvisioningDetailsByProvisioningIdAndFilter } = this.props;
    const { provisioningInfo } = this.state;
    this.setState({ vmTransactionInfo: null, lockerTransactionInfo: null, isLoading: true });

    // in case VM
    if (obj.dataIndex === "soProductName") {
      getProvisioningDetailsByProvisioningIdAndFilterSelected(provisioningInfo.provisioningId, obj.selectedKeys[0], "", "", (response) => {
        this.setState({
          vmTransactionInfo: response.data.data.sort((a, b) => (a.slotNumber > b.slotNumber ? 1 : b.slotNumber > a.slotNumber ? -1 : 0)),
          isLoading: false,
        });
      });
    } else if (obj.dataIndex === "scProductName") {
      getProvisioningDetailsByProvisioningIdAndFilterSelected(provisioningInfo.provisioningId, "", obj.selectedKeys[0], "", (response) => {
        this.setState({
          vmTransactionInfo: response.data.data.sort((a, b) => (a.slotNumber > b.slotNumber ? 1 : b.slotNumber > a.slotNumber ? -1 : 0)),
          isLoading: false,
        });
      });
    } else if (obj.dataIndex === "vmSlotNumber") {
      getProvisioningDetailsByProvisioningIdAndFilterSelected(provisioningInfo.provisioningId, "", "", obj.selectedKeys[0], (response) => {
        this.setState({
          vmTransactionInfo: response.data.data.sort((a, b) => (a.slotNumber > b.slotNumber ? 1 : b.slotNumber > a.slotNumber ? -1 : 0)),
          isLoading: false,
        });
      });
    }

    // In case locker
    else if (obj.dataIndex === "lockerSlotNumber") {
      getLockerProvisioningDetailsByProvisioningIdAndFilter(provisioningInfo.provisioningId, obj.selectedKeys[0], "", (response) => {
        this.setState({
          lockerTransactionInfo: response.data.data.sort((a, b) => (a.slotNumber > b.slotNumber ? 1 : b.slotNumber > a.slotNumber ? -1 : 0)),
          isLoading: false,
        });
      });
    } else if (String(provisioningInfo.productTransactionTypeName).includes("LOCKER")) {
      getLockerProvisioningDetailsByProvisioningIdAndFilter(provisioningInfo.provisioningId, "", "", (response) => {
        this.setState({
          lockerTransactionInfo: response.data.data.sort((a, b) => (a.slotNumber > b.slotNumber ? 1 : b.slotNumber > a.slotNumber ? -1 : 0)),
          isLoading: false,
        });
      });
    } else {
      getProvisioningDetailsByProvisioningId(provisioningInfo.provisioningId, (response) => {
        this.setState({
          vmTransactionInfo: response.data.data.sort((a, b) => (a.slotNumber > b.slotNumber ? 1 : b.slotNumber > a.slotNumber ? -1 : 0)),
          isLoading: false,
        });
      });
    }
    this.setState({
      isDetailsModalVisible: true,
    });
  };

  moreInformationDetails(event, rowSelected) {
    const { getProvisioningDetailsByProvisioningId, getLockerProvisioningDetailsByProvisioningIdAndFilter, getLockerProvisioningTransactionTypes } = this.props;

    this.setState({
      vmTransactionInfo: null,
      lockerTransactionInfo: null,
      loadingInfo: true,
    });

    if (String(rowSelected.productTransactionTypeName).includes("LOCKER")) {
      getLockerProvisioningDetailsByProvisioningIdAndFilter(rowSelected.provisioningId, "", "", (response) => {
        this.setState({
          lockerTransactionInfo: response.data.data.sort((a, b) => (a.slotNumber > b.slotNumber ? 1 : b.slotNumber > a.slotNumber ? -1 : 0)),
          provisioningInfo: rowSelected,
          isDetailsModalVisible: true,
          loadingInfo: false,
        });
      });
      getLockerProvisioningTransactionTypes((response) => {
        this.setState({
          lockerProvisioningTransactionTypes: response.data.data.map((item) => ({
            value: item.id,
            text: productTransactionTypeLocalizationLocalization(item.name),
          })),
        });
      });
    }

    let totalStockedAmount = 0;
    let totalUnderstockAmendmentAmount = 0;
    let totalOverstockAmendmentAmount = 0;

    if (!String(rowSelected.productTransactionTypeName).includes("LOCKER")) {
      getProvisioningDetailsByProvisioningId(rowSelected.provisioningId, (response) => {
        const details = response.data.data;
        details.forEach((item) => {
          if (item.stockedAmount) {
            totalStockedAmount += parseInt(item.stockedAmount, 10);
          }
          if (item.overstockAmendmentAmount) {
            totalOverstockAmendmentAmount += parseInt(item.overstockAmendmentAmount, 10);
          }
          if (item.understockAmendmentAmount) {
            totalUnderstockAmendmentAmount += parseInt(item.understockAmendmentAmount, 10);
          }
        });
        this.setState({
          vmTransactionInfo: response.data.data.sort((a, b) => (a.slotNumber > b.slotNumber ? 1 : b.slotNumber > a.slotNumber ? -1 : 0)),
          provisioningInfo: rowSelected,
          isDetailsModalVisible: true,
          loadingInfo: false,
          totalStockedAmount: String(totalStockedAmount),
          totalUnderstockAmendmentAmount: String(totalUnderstockAmendmentAmount),
          totalOverstockAmendmentAmount: String(totalOverstockAmendmentAmount),
        });
      });
    }
  }

  setModalVisible = (isVisible) => {
    this.setState({
      isDetailsModalVisible: isVisible,
    });
  };

  showPagination(page, pageSize) {
    const { getProvisioningListByMachineType } = this.props;
    const { soDependencyId, scDependencyId, vendingMachineId, moduleType, startDate, endDate, userName, provisioningId, attribute, order } = this.state;
    if (soDependencyId !== "" || scDependencyId !== "" || vendingMachineId !== "") {
      this.setState({
        loadingInfo: true,
        pageSize,
        page,
      });
      getProvisioningListByMachineType(
        moduleType,
        vendingMachineId,
        startDate.format(),
        endDate.format(),
        userName,
        provisioningId,
        page,
        pageSize,
        attribute,
        order,
        (response) => {
          this.setState({
            provisioningList: response.data.data.content,
            totalElements: response.data.data.totalElements,
            loadingInfo: false,
          });
        }
      );
    }
  }

  // Export methods
  showExportReportsModal = () => {
    const { totalElements } = this.state;
    if (totalElements >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible, isExceedModalVisible) => {
    this.setState({
      isExportModalVisible,
      isExceedModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    const { exportLoading, startDate, endDate, moduleType, vendingMachineId } = this.state;
    const { exportProvisioningInfoReport } = this.props;
    if (!exportLoading) {
      const DATE_OPTIONS = { year: "numeric", month: "numeric", day: "numeric" };
      const startDateStr = new Date(startDate).toLocaleDateString("es-CO", DATE_OPTIONS);
      const endDateStr = new Date(endDate).toLocaleDateString("es-CO", DATE_OPTIONS);

      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE) according to module type
      let columnHeaders = [];
      if (moduleType === 1) {
        // if vending Machine
        columnHeaders = [
          Strings.transaction.transactionType,
          Strings.planogram.slot,
          Strings.product.soProduct,
          Strings.product.scProductInfo,
          Strings.dispensationRule.amount,
          Strings.dispensationReport.transactionDate,
          Strings.machine.vendingMachine,
          Strings.Roles.provisioner,
          Strings.transaction.provisioningId,
        ];
      } else if (moduleType === 0) {
        // if locker
        columnHeaders = [
          Strings.transaction.transactionType,
          Strings.planogram.slot,
          Strings.machineInventory.productAuditOrProvisioned,
          Strings.machineInventory.productFound,
          Strings.dispensationReport.transactionDate,
          Strings.machine.vendingMachine,
          Strings.Roles.provisioner,
          Strings.transaction.provisioningId,
        ];
      }

      // Start loading animation on Export button
      this.setState({ exportLoading: true });

      // Consume report export method
      exportProvisioningInfoReport(vendingMachineId, moduleType, startDate.format(), endDate.format(), fileType, columnHeaders, (response) => {
        if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.reportGenerationError,
          });
          this.setState({
            exportLoading: false,
          });
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", `${Strings.transaction.provisioningReport} ${startDateStr} - ${endDateStr}.csv`);
          document.body.appendChild(link);
          link.click();
          this.setState({
            exportLoading: false,
          });
        }
      });
    }
  };

  tableRenderMethod(provisioningList, loadingInfo, moduleType) {
    return (
      <div className="vertSpace col-12">
        <Table dataSource={provisioningList} loading={loadingInfo} bordered pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="provisioningId">
          {moduleType === 2 ? (
            <Column
              width="15%"
              title={Strings.machine.wingType}
              align="center"
              render={(row) => <div>{String(row.productTransactionTypeName).includes("LOCKER") ? Strings.machine.locker : Strings.machine.vendingMachine}</div>}
            />
          ) : null}

          <Column
            width="25%"
            title={Strings.transaction.provisioningProcessDate}
            align="center"
            render={(row) => {
              return <div> {moment(row.transactionDate).local().format(DATE_TIME_FORMAT)} </div>;
            }}
          />
          <Column
            width="25%"
            title={<TextWithInfoTooltip name={Strings.transaction.provisioningProcessId} tooltip={Strings.transaction.provisioningProcessIdTooltip} />}
            dataIndex="provisioningId"
            align="center"
            {...this.getColumnSearchProps("provisioningId")}
          />
          <Column title={Strings.Roles.provisioner} dataIndex="user" align="center" {...this.getColumnSearchProps("user")} width="25%" filtered />
          <Column
            title={Strings.generalTerms.details}
            align="center"
            width="10%"
            render={(row) => (
              <div>
                <Icon type="profile" className="addEditRemoveButton" onClick={(e) => this.moreInformationDetails(e, row)} />
              </div>
            )}
          />
        </Table>
      </div>
    );
  }

  // Information to render
  render() {
    const {
      provisioningList,
      isDetailsModalVisible,
      provisioningInfo,
      vendingMachineName,
      vendingMachineId,
      vmTransactionInfo,
      lockerTransactionInfo,
      lockerProvisioningTransactionTypes,
      isLoading,
      isExportModalVisible,
      isExceedModalVisible,
      soDependencyId,
      scDependencyId,
      moduleType,
      startDate,
      endDate,
      scDependencyName,
      disabledScDependencyFilter,
      disabledCalendarFilter,
      loadingInfo,
      scDependencies,
      vendingMachines,
      disabledVendingMachineFilter,
      lockerTypes,
      vmTypes,
      totalElements,
      page,
      exportLoading,
      isExportDisabled,
      isDisabled,
      totalStockedAmount,
      totalUnderstockAmendmentAmount,
      totalOverstockAmendmentAmount,
    } = this.state;
    const {
      permissions: { data },
      soDependencies,
    } = this.props;

    if (!soDependencies) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    {
    }
    return (
      <div className="content-container">
        {!loadingInfo ? (
          <>
            <ProvisioningSummaryModal
              isVisible={isDetailsModalVisible}
              setIsVisible={this.setModalVisible}
              searchObj={(e) => this.searchObj(e)}
              provisioningInfo={provisioningInfo}
              vendingMachineName={vendingMachineName}
              vmTransactionInfo={vmTransactionInfo || null}
              lockerTransactionInfo={lockerTransactionInfo || null}
              lockerProvisioningTransactionTypes={lockerProvisioningTransactionTypes || null}
              isLoading={isLoading}
              totalStockedAmount={totalStockedAmount}
              totalUnderstockAmendmentAmount={totalUnderstockAmendmentAmount}
              totalOverstockAmendmentAmount={totalOverstockAmendmentAmount}
            />

            <ExportReportsModal
              isExportVisible={isExportModalVisible}
              isExceedVisible={isExceedModalVisible}
              setVisible={this.setExportReportsModalVisible}
              onExport={this.handleOnExport}
            />
          </>
        ) : null}

        <div className="row">
          <Titles title={Strings.transaction.provisioningReport} tooltip={Strings.transaction.provisioningReportTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <ValidateDependency
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            soDependencyId={soDependencyId}
            scDependencyId={scDependencyId}
            scDependency={scDependencyName}
            disabledScDependency={disabledScDependencyFilter}
            soDependencies={soDependencies}
            scDependencies={scDependencies}
            soDependencyHandleChange={(e) => this.soDependencyHandleChange(e)}
            scDependencyHandleChange={(e) => this.scDependencyHandleChange(e)}
          />

          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.machine.machine}
              tooltip={Strings.machine.vendingMachinesTooltip}
              options={vendingMachines}
              onChange={(e) => this.vendingMachineHandleChange(e)}
              disabled={disabledVendingMachineFilter}
              defaultValue={vendingMachineId}
              value={vendingMachineId}
            />
          </div>

          {lockerTypes > 0 && vmTypes > 0 ? (
            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.machine.wingType}
                tooltip={Strings.machine.wingTooltip}
                options={moduleTypeOptions}
                onChange={(e) => this.moduleTypeHandleChange(e)}
                defaultValue={moduleType}
                value={moduleType}
              />
            </div>
          ) : (
            ""
          )}

          <div className="vertSpace col-md-6">
            <RangeDate
              text={Strings.generalTerms.date}
              tooltip={Strings.dispensationReport.dispensationDateTooltip}
              startDate={startDate}
              endDate={endDate}
              onChange={this.dateOnChange}
              isDisabled={disabledCalendarFilter}
              format="MMMM Do YYYY, h:mm:ss a"
            />
          </div>

          {lockerTypes > 0 && vmTypes > 0 ? null : <div className="vertSpace col-md-6" />}

          <div className="vertSpace col-md-6">
            <Tooltip title={Strings.transaction.getTransactionsToolTip}>
              <Button
                style={{ margin: "5px" }}
                type="primary"
                size={!true ? "large" : "default"}
                onClick={(e) => this.onClickCheckButton(e)}
                icon="search"
                disabled={isDisabled /* (searchButtonDisabled === true && dependencyId === null) || soDependencyId === null */}
                loading={false}
              >
                {Strings.generalTerms.check}
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.transaction.provisioningReport}</h3>
            </Divider>

            <>
              <QueryResponse isLoading={loadingInfo} callback={this.tableRenderMethod(provisioningList, loadingInfo, moduleType)} dataSourceLength={provisioningList.length} />
              {totalElements > 0 ? (
                <>
                  <div>
                    <div className="col-xs-0 col-lg-6" />
                    <div className="vertSpace col-lg-6">
                      <Pagination
                        size="small"
                        total={totalElements}
                        showSizeChanger
                        onChange={this.showPagination}
                        onShowSizeChange={this.showPagination}
                        hideOnSinglePage={false}
                        pageSizeOptions={["10", "25", "50", "100", "250"]}
                        showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total} ${Strings.generalTerms.items}`}
                        current={page}
                        disabled={provisioningList ? !(provisioningList.length > 0) : true}
                      />
                    </div>
                  </div>

                  <ValidatePermissionForComponent permission="PRIVILEGE PROVISIONING REPORT GET" permissions={data}>
                    <div className="row">
                      <div className="vertSpace col-12">
                        <div className="col-md-6" />
                        <div className="col-md-6">
                          <ExportButton
                            onClick={() => this.showExportReportsModal()}
                            loading={exportLoading}
                            isDisabled={isExportDisabled}
                            tooltip={isExportDisabled ? Strings.transaction.exportReportTooltip : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </ValidatePermissionForComponent>
                </>
              ) : null}
            </>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    soDependencyParent: state.soDependencyParent.soParent,
    permissions: state.userSystemPrivileges.privilegeObj,
    soDependenciesChilds: state.soDependencies,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listVendingMachinesByScDependencyId,
  getProvisioningDetailsByProvisioningId,
  getProvisioningDetailsByProvisioningIdAndFilterSelected,
  exportProvisioningInfoReport,
  getModuleTypesByMachine,
  getProvisioningListByMachineType,
  getLockerProvisioningDetailsByProvisioningIdAndFilter,
  getLockerProvisioningTransactionTypes,
})(ListProvisioningInfoByVM);
