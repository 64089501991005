// Dependencies
import { Alert, Divider, Icon, Modal, Popconfirm, Table, Tooltip } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import EditCashAuditModal from "../EditCashAuditModal";

// Actions
import { clearBillBoxOrRecycler, clearCoinBoxOrTube, getBillsByVmId, getMoneyInCoinsByVmId } from "../../../actions";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

const { Column, ColumnGroup } = Table;
let totalBills = [];
let totalCoins = [];

const formatter = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  minimumFractionDigits: 0,
});

class VendingMachineCashAuditment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coins: 0,
      bills: 0,
      totalCoins: 0,
      totalCash: 0,
      loadingCoinsInfo: true,
      loadingBillsInfo: true,
    };
  }

  componentDidMount() {
    const { vmId, getMoneyInCoinsByVmId, getBillsByVmId } = this.props;

    if (vmId) {
      getMoneyInCoinsByVmId(vmId, (response) => {
        let tubeCoins = 0;
        let boxCoins = 0;

        response.data.data.forEach((obj) => {
          tubeCoins = obj.tubeMoneyAmount + tubeCoins;
          boxCoins = obj.boxMoneyAmount + boxCoins;
        });
        totalCoins = {
          coinDenomination: Strings.generalTerms.total,
          tubeMoneyAmount: tubeCoins,
          boxMoneyAmount: boxCoins,
          currencyCode: response.data.data[0].currencyCode,
          currencySymbol: response.data.data[0].currencySymbol,
        };
        response.data.data.push(totalCoins);

        this.setState({
          coins: response.data.data,
          totalCoins: tubeCoins + boxCoins,
          loadingCoinsInfo: false,
        });
      });
      getBillsByVmId(vmId, (response) => {
        let billBox = 0;
        let billEscrow = 0;

        response.data.data.forEach((obj) => {
          billBox = obj.boxQuantityAmount + billBox;
          billEscrow = obj.recyclerQuantityAmount + billEscrow;
        });
        totalBills = {
          billDenomination: Strings.generalTerms.total,
          boxQuantityAmount: billBox,
          recyclerQuantityAmount: billEscrow,
          currencyCode: response.data.data[0].currencyCode,
          currencySymbol: response.data.data[0].currencySymbol,
        };
        response.data.data.push(totalBills);

        this.setState({
          bills: response.data.data,
          totalCash: billBox + billEscrow,
          loadingBillsInfo: false,
        });
      });
    }
  }

  // -------Function--------

  updatePage = () => {
    const { vmId, getMoneyInCoinsByVmId, getBillsByVmId } = this.props;
    this.setState({
      loadingCoinsInfo: true,
      loadingBillsInfo: true,
    });

    getMoneyInCoinsByVmId(vmId, (response) => {
      let tubeCoins = 0;
      let boxCoins = 0;

      response.data.data.forEach((obj) => {
        tubeCoins = obj.tubeMoneyAmount + tubeCoins;
        boxCoins = obj.boxMoneyAmount + boxCoins;
      });
      totalCoins = { coinDenomination: <b>{Strings.generalTerms.total}</b>, tubeMoneyAmount: tubeCoins, boxMoneyAmount: boxCoins };
      response.data.data.push(totalCoins);

      this.setState({
        coins: response.data.data,
        totalCoins: tubeCoins + boxCoins,
        loadingCoinsInfo: false,
      });
    });

    getBillsByVmId(vmId, (response) => {
      let billBox = 0;
      let billEscrow = 0;

      response.data.data.forEach((obj) => {
        billBox = obj.boxQuantityAmount + billBox;
        billEscrow = obj.recyclerQuantityAmount + billEscrow;
      });
      totalBills = { billDenomination: <b>{Strings.generalTerms.total}</b>, boxQuantityAmount: billBox, recyclerQuantityAmount: billEscrow };
      response.data.data.push(totalBills);

      this.setState({
        bills: response.data.data,
        totalCash: billBox + billEscrow,
        loadingBillsInfo: false,
      });
    });
  };

  cleanBoxesEvent = (e, dataIndex) => {
    const { vmId, clearCoinBoxOrTube, clearBillBoxOrRecycler } = this.props;

    if (dataIndex === "coinTube") {
      const dto = {
        vmId,
        isBoxOrTubeFlag: 2,
      };

      clearCoinBoxOrTube(dto, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.updatePage(),
          });
        } else {
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        }
      });
    }

    if (dataIndex === "coinBox") {
      const dto = {
        vmId,
        isBoxOrTubeFlag: 1,
      };

      clearCoinBoxOrTube(dto, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.updatePage(),
          });
        } else {
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        }
      });
    }

    if (dataIndex === "billBox") {
      const dto = {
        vmId,
        isBoxOrRecyclerFlag: 1,
      };

      clearBillBoxOrRecycler(dto, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.updatePage(),
          });
        } else {
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        }
      });
    }

    if (dataIndex === "billRecycler") {
      const dto = {
        vmId,
        isBoxOrRecyclerFlag: 2,
      };

      clearBillBoxOrRecycler(dto, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.updatePage(),
          });
        } else {
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        }
      });
    }
  };

  // INFORMATION TO RENDER
  render() {
    const {
      permissions: { data },
    } = this.props;
    const { coins, totalCoins, bills, totalCash, loadingCoinsInfo, loadingBillsInfo } = this.state;

    return (
      <>
        <div className="col-12">
          <div className="row">
            <div className="vertSpace col-md-8">
              <Table
                dataSource={bills}
                loading={loadingBillsInfo}
                bordered
                pagination={false}
                size="middle"
                rowKey="id"
                scroll={{ x: "100%" }}
                footer={() => {
                  return (
                    <div className="ant-table-row  ant-table-row-level-0">
                      <div>
                        <b>
                          {Strings.vmcashvaulttype.totalBillBox}: {bills[0]?.currencySymbol} {totalCash} {bills[0]?.currencyCode}
                        </b>
                      </div>
                    </div>
                  );
                }}
              >
                <ColumnGroup title={<b>{Strings.vmcashvaulttype.billBox}</b>} key="id">
                  <Column
                    key="id"
                    title={Strings.generalTerms.denomination}
                    align="center"
                    width="20%"
                    render={(row) =>
                      row.billDenomination === Strings.generalTerms.total ? (
                        <div>
                          <b>{row.billDenomination}</b>
                        </div>
                      ) : (
                        <div>
                          <span>
                            {row.currencySymbol} {row.billDenomination} {row.currencyCode}
                          </span>
                        </div>
                      )
                    }
                  />
                  <Column
                    title={
                      ValidatePermissionForComponentPart("PRIVILEGE CASH AUDITMENT PUT", data) ? (
                        <>
                          <Tooltip title={Strings.vmcashvaulttype.boxTooltip}>
                            <span>{Strings.vmcashvaulttype.box}</span>
                          </Tooltip>
                          <Popconfirm title={Strings.generalResponses.confirmDeleteAllMessage} onConfirm={(e) => this.cleanBoxesEvent(e, "billBox")} okText="Sí" cancelText="No">
                            <br />
                            <Icon type="delete" theme="twoTone" className="addEditRemoveButton" />
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title={Strings.vmcashvaulttype.boxTooltip}>
                          <span>{Strings.vmcashvaulttype.box}</span>
                        </Tooltip>
                      )
                    }
                    render={(row) => (
                      <div>
                        <span>
                          {row.currencySymbol} {row.boxQuantityAmount} {row.currencyCode}
                        </span>
                      </div>
                    )}
                    align="center"
                  />

                  <Column
                    title={
                      ValidatePermissionForComponentPart("PRIVILEGE CASH AUDITMENT PUT", data) ? (
                        <>
                          <Tooltip title={Strings.vmcashvaulttype.billRecyclerQuantityTooltip}>
                            <span>{Strings.vmcashvaulttype.recycler}</span>
                          </Tooltip>
                          <Popconfirm
                            title={Strings.generalResponses.confirmDeleteAllMessage}
                            onConfirm={(e) => this.cleanBoxesEvent(e, "billRecycler")}
                            okText="Sí"
                            cancelText="No"
                          >
                            <br />
                            <Icon type="delete" theme="twoTone" className="addEditRemoveButton" />
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title={Strings.vmcashvaulttype.billRecyclerQuantityTooltip}>
                          <span>{Strings.vmcashvaulttype.recycler}</span>
                        </Tooltip>
                      )
                    }
                    render={(row) => (
                      <div>
                        <span>
                          {row.currencySymbol} {row.recyclerQuantityAmount} {row.currencyCode}
                        </span>
                      </div>
                    )}
                    align="center"
                  />
                  <Column
                    title={
                      <Tooltip title={Strings.vmcashvaulttype.escrowTooltip}>
                        <span>{Strings.vmcashvaulttype.escrow}</span>
                      </Tooltip>
                    }
                    align="center"
                    render={(row) => (row.id && row.escrow === 1 ? "SI" : "NO")}
                  />
                  <Column
                    title={
                      <Tooltip title={Strings.vmcashvaulttype.recycleTooltip}>
                        <span>{Strings.vmcashvaulttype.recycler}</span>
                      </Tooltip>
                    }
                    align="center"
                    render={(row) => (row.id && row.recycle ? "SI" : "NO")}
                  />
                  <Column
                    title={
                      <Tooltip title={Strings.vmcashvaulttype.highSecurityTooltip}>
                        <span>{Strings.vmcashvaulttype.highSecurity}</span>
                      </Tooltip>
                    }
                    align="center"
                    render={(row) => (row.id && row.highSecurity ? "SI" : "NO")}
                  />
                  <Column
                    title={
                      <Tooltip title={Strings.vmcashvaulttype.enabledTooltip}>
                        <span>{Strings.generalTerms.enabled}</span>
                      </Tooltip>
                    }
                    align="center"
                    render={(row) => (row.id && row.isEnabled ? "SI" : "NO")}
                  />
                  {ValidatePermissionForComponentPart("PRIVILEGE CASH AUDITMENT PUT", data) ? (
                    <Column title={Strings.generalTerms.edit} align="center" render={(row) => (row.id ? <EditCashAuditModal data={row} updatePage={this.updatePage} /> : "--")} />
                  ) : (
                    ""
                  )}
                </ColumnGroup>
              </Table>
              <br />
            </div>
            <div className="vertSpace col-md-4">
              <Table
                dataSource={coins}
                loading={loadingCoinsInfo}
                bordered
                pagination={false}
                size="medium"
                rowKey="id"
                footer={() => {
                  return (
                    <div className="ant-table-row  ant-table-row-level-0">
                      <div>
                        <b>
                          {Strings.vmcashvaulttype.totalCoinBox}: {coins[0]?.currencySymbol} {totalCoins} {bills[0]?.currencyCode}
                        </b>
                      </div>
                    </div>
                  );
                }}
              >
                <ColumnGroup title={<b>{Strings.vmcashvaulttype.coinBox} </b>} key="id">
                  <Column
                    title={Strings.generalTerms.denomination}
                    align="center"
                    render={(row) =>
                      row.coinDenomination === Strings.generalTerms.total ? (
                        <div>
                          <b>{row.coinDenomination}</b>
                        </div>
                      ) : (
                        <div>
                          <span>
                            {row.currencySymbol} {row.coinDenomination} {row.currencyCode}
                          </span>
                        </div>
                      )
                    }
                  />
                  <Column
                    title={
                      ValidatePermissionForComponentPart("PRIVILEGE CASH AUDITMENT PUT", data) ? (
                        <>
                          <Tooltip title={Strings.vmcashvaulttype.coinTubesTooltip}>
                            <span>{Strings.vmcashvaulttype.coinTubes}</span>
                          </Tooltip>
                          <Popconfirm title={Strings.generalResponses.confirmDeleteAllMessage} onConfirm={(e) => this.cleanBoxesEvent(e, "coinTube")} okText="Sí" cancelText="No">
                            <br />
                            <Icon type="delete" theme="twoTone" className="addEditRemoveButton" />
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title={Strings.vmcashvaulttype.coinTubesTooltip}>
                          <span>{Strings.vmcashvaulttype.coinTubes}</span>
                        </Tooltip>
                      )
                    }
                    render={(row) => (
                      <div>
                        <span>
                          {row.currencySymbol} {row.tubeMoneyAmount} {row.currencyCode}
                        </span>
                      </div>
                    )}
                    align="center"
                  />
                  <Column
                    title={
                      ValidatePermissionForComponentPart("PRIVILEGE CASH AUDITMENT PUT", data) ? (
                        <>
                          <Tooltip title={Strings.vmcashvaulttype.coinContainerTooltip}>
                            <span>{Strings.vmcashvaulttype.coinBox}</span>
                          </Tooltip>
                          <Popconfirm title={Strings.generalResponses.confirmDeleteAllMessage} onConfirm={(e) => this.cleanBoxesEvent(e, "coinBox")} okText="Sí" cancelText="No">
                            <br />
                            <Icon type="delete" theme="twoTone" className="addEditRemoveButton" />
                          </Popconfirm>
                        </>
                      ) : (
                        <Tooltip title={Strings.vmcashvaulttype.coinContainerTooltip}>
                          <span>{Strings.vmcashvaulttype.coinBox}</span>
                        </Tooltip>
                      )
                    }
                    render={(row) => (
                      <div>
                        <span>
                          {row.currencySymbol} {row.boxMoneyAmount} {row.currencyCode}
                        </span>
                      </div>
                    )}
                    align="center"
                  />
                </ColumnGroup>
              </Table>
              <br />
              <Alert
                message={
                  <span style={{ fontSize: "16px" }}>
                    <b>
                      {Strings.generalTerms.totalCash}: {bills[0]?.currencySymbol} {totalCoins + totalCash} {bills[0]?.currencyCode}
                    </b>
                  </span>
                }
                type="success"
              />
            </div>
          </div>
        </div>
        <Divider />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  getMoneyInCoinsByVmId,
  getBillsByVmId,
  clearBillBoxOrRecycler,
  clearCoinBoxOrTube,
})(VendingMachineCashAuditment);
