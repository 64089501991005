import { Button, Divider, Icon, Input, Modal, Table } from "antd";
import _ from "lodash";
import React, { Component, Fragment } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import "./privilegeByRole.css";

//Components
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import { GeneralInput } from "../../GenericComponents/InputComponent/generalInput";
import { SelectGeneral } from "../../GenericComponents/selectComponent/selectGeneral";
import Titles from "../../GenericComponents/titles";

//Actions
import { addRoleWithPrivileges, listPermissionTypeByDependencyTypeId, listPrivilegesByPermissionTypeId } from "../../../actions";

//Language Localization
import Strings from "../../../systemVariables/languageStrings";
import { permissionTypeLocalization } from "../../../Utils/LanguageLocalization";

const { Column } = Table;

class AddRoleAndPrivilegeAssignment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dependencyType: this.props.location.props ? this.props.location.props.dependencyTypeId : null,

      dependencyTypeName: this.props.location.props ? this.props.location.props.dependencyTypeName : null,

      soDependencyId: null,
      soDependencyName: null,

      scDependencyId: null,
      scDependencyName: null,

      permissionType: [],

      privileges: [],

      role: {
        id: null,
        roleName: "",
        description: "",
        permissiontypeId: null,
      },
      privilegesToAdd: [],

      loadingInfo: false,
      isSaveButtonLoading: false,
      redirectBackToPrivilegeByRole: false,
      addButtonDisabled: true,
    };
  }

  componentDidMount() {
    if (this.state.dependencyType) {
      if (this.state.dependencyType === 1) {
        this.setState({
          soDependencyId: this.props.location.props ? this.props.location.props.soDependencyId : null,
          soDependencyName: this.props.location.props ? this.props.location.props.soDependencyName : null,
        });
      }
      if (this.state.dependencyType === 2) {
        this.setState({
          soDependencyId: this.props.location.props ? this.props.location.props.soDependencyId : null,
          soDependencyName: this.props.location.props ? this.props.location.props.soDependencyName : null,
          scDependencyId: this.props.location.props ? this.props.location.props.scDependencyId : null,
          scDependencyName: this.props.location.props ? this.props.location.props.scDependencyName : null,
        });
      }

      //Get permission types by dependency type
      if (this.state.dependencyTypeName === "stockOwner" && this.props.location.props.soDependencyId === 1) {
        this.props.listPermissionTypeByDependencyTypeId(1, (response) => {
          this.setState({
            permissionType: response.data.data.map((item) => ({
              value: item.id,
              label: permissionTypeLocalization(item.name),
            })),
          });
        });
      }
      if (this.state.dependencyTypeName === "stockOwner" && this.props.location.props.soDependencyId !== 1) {
        this.props.listPermissionTypeByDependencyTypeId(2, (response) => {
          this.setState({
            permissionType: response.data.data.map((item) => ({
              value: item.id,
              label: permissionTypeLocalization(item.name),
            })),
          });
        });
      }

      if (this.state.dependencyTypeName === "headquarter" || this.state.dependencyTypeName === "logisticCenter") {
        this.props.listPermissionTypeByDependencyTypeId(3, (response) => {
          this.setState({
            permissionType: response.data.data.map((item) => ({
              value: item.id,
              label: permissionTypeLocalization(item.name),
            })),
          });
        });
      }

      if (this.state.dependencyTypeName === "scDependency") {
        this.props.listPermissionTypeByDependencyTypeId(4, (response) => {
          this.setState({
            permissionType: response.data.data.map((item) => ({
              value: item.id,
              label: permissionTypeLocalization(item.name),
            })),
          });
        });
      }
    }
  }

  handleInput = (event) => {
    const { id, value } = event.target;
    const { role } = { ...this.state };

    role[id] = value;

    if (role.roleName.length > 0 && this.state.privilegesToAdd.length > 0 && this.state.role.permissiontypeId != null) {
      this.setState({
        addButtonDisabled: false,
      });
    } else {
      this.setState({
        addButtonDisabled: true,
      });
    }

    this.setState({
      role,
    });
  };

  handleSelect = (event, name) => {
    const { role } = { ...this.state };

    role[name.name] = event.value;

    this.setState({
      role,
      loadingInfo: true,
    });
    this.props.listPrivilegesByPermissionTypeId(event.value, (response) => {
      this.setState({
        privileges: response.data.data,
        loadingInfo: false,
      });
    });

    if (role.roleName.length > 0 && this.state.privilegesToAdd.length > 0 && this.state.role.permissiontypeId != null) {
      this.setState({
        addButtonDisabled: false,
      });
    } else {
      this.setState({
        addButtonDisabled: true,
      });
    }
  };

  handleAddRole = () => {
    this.setState({
      isSaveButtonLoading: true,
    });
    const role = { ...this.state.role };
    role.roleName = _.trim(role.roleName);
    role.description = _.trim(role.description);

    let privilegeIds = [];

    this.state.privilegesToAdd.map((obj) => {
      privilegeIds.push(obj.id);
    });

    let infoToAdd = {
      role: role,
      privilegeIds: _.uniq(privilegeIds),
      dependencyType: this.state.dependencyType,
      dependencyId: this.state.scDependencyId ? this.state.scDependencyId : this.state.soDependencyId,
    };

    this.props.addRoleWithPrivileges(infoToAdd, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        this.setState({
          isSaveButtonLoading: false,
        });
      } else if (response.data.code === 4001) {
        // Entity Already exists and is enabled
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({
          isSaveButtonLoading: false,
        });
      } else if (response.data.code === 4013) {
        // Entity Already exists and is disabled
        Modal.error({
          title: "Strings.generalResponses.failedTransaction",
          content: "Strings.generalResponses.alreadyExistsError",
        });
        this.setState({
          isSaveButtonLoading: false,
        });
      } else if (response.data.code === 0) {
        // Entity successfully saved
        Modal.success({
          title: Strings.generalResponses.successfulCreation,
          content: `${Strings.generalResponses.creationSuccess}`,
        });
        this.setState({
          privileges: [],
          isSaveButtonLoading: false,
        });
      }
    });
  };

  showNoScDependencyWarning = () => {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOkNoScDependencyWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  };

  handleOkNoScDependencyWarning = () => {
    this.setState({
      redirectBackToPrivilegeByRole: true,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[this.state.searchText]} autoEscape textToHighlight={text.toString()} />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (this.state.role.roleName.length > 0 && selectedRows.length > 0) {
        this.setState({
          addButtonDisabled: false,
        });
      } else {
        this.setState({
          addButtonDisabled: true,
        });
      }
      this.setState({
        privilegesToAdd: selectedRows,
      });
    },
  };

  render() {
    const {
      state: { privileges },
    } = this;

    if (this.state.redirectBackToPrivilegeByRole) {
      return <Redirect to="/privilegeByRole" />;
    }
    if (!this.state.dependencyType) {
      return <div> {this.showNoScDependencyWarning()} </div>;
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.privilegeByRole.createRoleAndAssigPrivileges} tooltip={Strings.privilegeByRole.createRoleAndAssigPrivilegesTooltip} />
        </div>

        <div className="row">
          <div className="col-12">
            <Divider orientation="left">
              <h3>{Strings.soDependency.directClient} </h3>
            </Divider>
            <h3>{this.state.soDependencyName}</h3>
          </div>
          {this.state.dependencyType === 2 ? (
            <Fragment>
              <div className="col-md-6">
                <Divider orientation="left">
                  <h3>{Strings.soDependency.directClient} </h3>
                </Divider>
                <h3>{this.state.soDependencyName}</h3>
              </div>
              <div className="col-md-6">
                <Divider orientation="left">
                  <h3>{Strings.scDependency.indirectClient} </h3>
                </Divider>
                <h3>{this.state.scDependencyName}</h3>
              </div>
            </Fragment>
          ) : (
            ""
          )}
        </div>

        <div className="row">
          <div className="col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters}</h3>
            </Divider>
          </div>

          <div className="vertSpace col-md-6">
            <GeneralInput
              id="roleName"
              text={Strings.generalTerms.name}
              tooltip={Strings.dispensationRule.dispensationRuleNameToolTip}
              placeholder={Strings.generalTerms.name}
              onChange={(e) => this.handleInput(e)}
              value={this.state.role.roleName}
              isReq={true}
            />
          </div>
          <div className="vertSpace col-md-6">
            <GeneralInput
              id="description"
              text={Strings.generalTerms.description}
              tooltip={Strings.dispensationRule.dispensationRuleDescriptionToolTip}
              placeholder={Strings.generalTerms.description}
              onChange={(e) => this.handleInput(e)}
              value={this.state.role.description}
              isReq={false}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              name={"permissiontypeId"}
              text={Strings.permissionType.permissionType}
              tooltip={Strings.permissionType.permissionTypeTooltip}
              options={this.state.permissionType}
              onChange={(e, name) => this.handleSelect(e, name)}
              defaultValue={this.state.role.permissiontypeId}
              isReq={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.privilegeByRole.availablePrivileges}</h3>
            </Divider>
          </div>

          <div className="vertSpace col-12">
            <Table
              dataSource={privileges}
              bordered={true}
              loading={this.state.loadingInfo}
              pagination={false}
              size="middle"
              scroll={{ x: "auto" }}
              key="id"
              rowSelection={this.rowSelection}
            >
              <Column title={Strings.generalTerms.name} dataIndex="name" />
              <Column title={Strings.generalTerms.description} dataIndex="description" ellipsis={true} />
            </Table>
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="vertSpace col-6 col-md-3">
            <ReturnButton link="/privilegeByRole" />
          </div>
          <div className="vertSpace col-6 col-md-3">
            <SaveButton loading={this.state.isSaveButtonLoading} onClick={(e) => this.handleAddRole(e)} isDisabled={this.state.addButtonDisabled} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soDependencyParent: state.soDependencyParent.soParent,
  };
}

export default connect(mapStateToProps, {
  listPermissionTypeByDependencyTypeId,
  listPrivilegesByPermissionTypeId,
  addRoleWithPrivileges,
})(AddRoleAndPrivilegeAssignment);
