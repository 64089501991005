import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Server URL
const resource = "/scdispensationrule";

export const DISPENSATIONRULE = 'DISPENSATIONRULE';
export const LIST_DISPENSATIONRULES = 'LIST_DISPENSATIONRULES';
export const LIST_DISPENSATIONRULESTABLE = 'LIST_DISPENSATIONRULESTABLE';

export const ERROR = 'ERROR';


export function listDispensationRulesByScIdPaginated(scId, dispRuleName, isEnabled, page, pageSize, attribute, order, callback) {
  return () => {
    axios.get(host + port + path + version + resource, {
      params: {
        scdependencyid: scId,
        dispname: dispRuleName,
        isenabled: isEnabled,
        page,
        size: pageSize,
        attribute,
        order
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
      }
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  }
}

export function listDispensationRulesByScId(scId, callback) {
  return () => {
    axios.get(`${host + port + path + version + resource  }/${  scId}`, {
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
      }
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  }
}

export function disableScDispensationRule(scDispensationRule, callback) {
  return (dispatch) => {
    let data = JSON.stringify(scDispensationRule)
    axios({
      method: 'delete',
      url: host + port + path + version + resource,
      data,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
      }
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  }
}

export function addScDispensationRuleAndProductAssignment(dispensationRuleDTO, callback) {
  return (dispatch) => {
    let data = JSON.stringify(dispensationRuleDTO)
    axios(
      {
        method: 'post',
        url: host + port + path + version + resource,
        data,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
        }
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error
      });
  }
}

export function updateDispensationRuleAndProductAssignment(dispensationRuleDTO, callback) {
  return (dispatch) => {
    let data = JSON.stringify(dispensationRuleDTO)
    axios({
      method: 'put',
      url: host + port + path + version + resource,
      data,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
      }
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error
      });
  }
}

export function exportDispensationRuleReport(
  soId,
  scId,
  dispRuleName,
  attribute,
  order,
  fileType,
  columnHeaders,
  callback
) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        responseType: 'blob',
        params: {
          sodependencyid: soId,
          scdependencyid: scId,
          dispname: dispRuleName,
          attribute,
          order,
          filetype: fileType,
          columnheaders: encodeURIComponent(columnHeaders)
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${  sessionStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
        return error;
      });
  };
}

export function getScDispensationRuleById(scDispensationRuleId, callback) {
  return () => {
    axios.get(host + port + path + version + resource, {
      params: {
        scdispensationruleid: scDispensationRuleId,
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${  sessionStorage.getItem("access_token")}`,
      }
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
        return error;
      });
  }
}




