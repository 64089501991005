import React, { Component } from "react";
import { Modal } from "antd";

import { GeneralCheckbox } from "./checkBox/GeneralCheckBox";
import { maxRegistersPerReport } from "../../systemVariables/serverInformation";
import Strings from "../../systemVariables/languageStrings";

const ExportModalTransactionsReport = (props) => {
  const { confirm } = Modal;
  const maxRegistersPerReport = 30000;
  const { setVisible, onExport, registersAmount, isExceedVisible, isExportVisible } = props;

  const handleCancel = () => {
    setVisible(false, false);
  };

  const dispensationAmountError = (isVisible) => {
    if (isVisible) {
      Modal.warning({
        title: `${registersAmount} ${Strings.generalResponses.transactionReportRegisterModalWarningTitle}`,
        content: `${Strings.generalResponses.transactionReportRegisterModalWarningContent} ${maxRegistersPerReport}`,
        onOk: handleCancel,
      });
    }
  };
  const showConfirm = (isExportVisible) => {
    if (isExportVisible) {
      confirm({
        title: `${Strings.generalResponses.transactionReportRegister} ${Strings.fileImport.registersTransaction}?`,
        content: `${Strings.fileImport.registersTransactionContent} `,
        onOk() {
          setVisible(false, false);
          onExport("csv");
        },
        onCancel() {
          setVisible(false, false);
        },
      });
    }
  };

  return (
    <>
      {dispensationAmountError(isExceedVisible)}
      {showConfirm(isExportVisible)}
    </>
  );
};

export default ExportModalTransactionsReport;
