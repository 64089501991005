import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/peripheralsdevicemodel";

export function getPeripheralDeviceModels(peripheralDeviceTypeId, generationPeripheralDeviceByModel, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          peripheraldevicetypeid: peripheralDeviceTypeId,
          generationperipheraldevicebymodel: generationPeripheralDeviceByModel,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

/**
 * It's a function that makes a GET request to an API endpoint.
 * @param peripheralDeviceTypeId - 1
 * @param generationPeripheralDeviceByModel - true or false
 * @returns The response is an array of objects.
 */
export async function getPeripheralDeviceModelsAsync(peripheralDeviceTypeId, generationPeripheralDeviceByModel) {
  try {
    const response = axios.get(host + port + path + version + resource, {
      params: {
        peripheraldevicetypeid: peripheralDeviceTypeId,
        generationperipheraldevicebymodel: generationPeripheralDeviceByModel,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}
