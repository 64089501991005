import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/role";

export function listRolesByDependencyIdPaged(dependencyType, dependencyId, isBasicRole, roleName, page, size, attribute, order, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          dependencytype: dependencyType,
          dependencyid: dependencyId,
          isbasicrole: isBasicRole,
          rolename: roleName,
          page,
          size,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function listRolesByDependencyId(dependencyType, dependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          dependencytype: dependencyType,
          dependencyid: dependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getRoleDetailesById(roleId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          roleid: roleId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// Add role with privilege to a Sc or So
export function addRoleWithPrivileges(info, callback) {
  return () => {
    const data = JSON.stringify(info);
    axios({
      method: "POST",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// Edit role with privilege to a Sc or So
export function editRoleWithPrivileges(info, callback) {
  return () => {
    const data = JSON.stringify(info);
    axios({
      method: "PUT",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

// disable role with privilege to a Sc or So
export function disableSoScRole(dependencyType, dependencyId, roleId, callback) {
  return () => {
    axios({
      params: {
        dependencyid: dependencyId,
        dependencytype: dependencyType,
        roleid: roleId,
      },
      method: "DELETE",
      url: host + port + path + version + resource,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
