import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
// Actions
import { insertLocation, updateLocation } from "../../../actions";
// Components
import { Divider, Modal } from "antd";
import { GeneralInput } from "../../GenericComponents/InputComponent/index";
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import Titles from "../../GenericComponents/titles";
// Language Localization
import Strings from "../../../systemVariables/languageStrings";

function FormLocations(props) {
  const { isEdit } = props.location.props ?? false;
  const { row } = props.location.props ?? {};
  const { basicProps } = props.location.props ?? {};
  const { id: scDependencyId, name: scDependencyName } = basicProps ?? { scDependencyId: "", scDependencyName: "" };
  const [locationInfo, setLocationInfo] = useState(
    row
      ? {
          scDependency: basicProps,
          uuid: row.uuid,
          location: row.location || "",
          building: row.building || "",
          floor: row.floor || "",
          sector: row.sector || "",
          description: row.description || "",
        }
      : {
          scDependency: basicProps,
          location: "",
          building: "",
          floor: "",
          sector: "",
          description: "",
        }
  );
  const [isDisableSaveButton, setIsDisableSaveButton] = useState(true);
  const [isLoadingSaveButton, setIsLoadingSaveButton] = useState(false);
  const [redirectBackToListLocations, setRedirectBackToListLocations] = useState(false);

  useEffect(() => {
    if (scDependencyId && locationInfo.location) {
      setIsDisableSaveButton(false);
    } else {
      setIsDisableSaveButton(true);
    }
  }, [locationInfo]);

  // Events
  const handleFormChange = (event) => {
    const { id, value } = event.target;
    setLocationInfo((prevlocationInfo) => ({
      ...prevlocationInfo,
      [id]: value,
    }));
  };

  function replaceEmptyStringsWithNull(obj) {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value === "" ? null : value]));
  }

  const onClick = async (data) => {
    const cleanLocationInfo = replaceEmptyStringsWithNull(locationInfo);
    setIsLoadingSaveButton(true);
    if (isEdit) {
      try {
        let updateLocationsAns = await updateLocation(cleanLocationInfo);
        if (!updateLocationsAns.data && String(updateLocationsAns).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.generalError,
          });
        } else if (updateLocationsAns.data.code === 0) {
          // Entity successfully disabled
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.successfulUpdate,
          });
        }
      } catch (error) {
        console.log(error); // Log any errors that occur during the insertion process
      }
    } else {
      try {
        let insetLocationsAns = await insertLocation(cleanLocationInfo);
        if (!insetLocationsAns.data && String(insetLocationsAns).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.generalError,
          });
        } else if (insetLocationsAns.data.code === 0) {
          // Entity successfully disabled
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.successfullCreation,
          });
        }
      } catch (error) {
        console.log(error); // Log any errors that occur during the insertion process
      }
    }
    setIsLoadingSaveButton(false);
    setRedirectBackToListLocations(true);
  };

  if (redirectBackToListLocations) {
    return <Redirect to="/listLocations" />;
  }

  return (
    <div className="content-container">
      <div className="row">
        <Titles 
          title={isEdit? Strings.smartVendingClinical.editLocationInformation : Strings.smartVendingClinical.addLocationInformation} 
          tooltip={isEdit? Strings.smartVendingClinical.editLocationInformationTooltip : Strings.smartVendingClinical.addLocationInformationTooltip} 
        />
      </div>

      <div className="row">
        <div className="col">
          <Divider orientation="left">
            <h3>{Strings.scDependency.indirectClient} </h3>
          </Divider>
          <h3>{scDependencyName}</h3>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Divider orientation="left">
            <h3>{Strings.generalTerms.parameterization} </h3>
          </Divider>
        </div>
      </div>

      <div className="row">
        <div className="vertSpace col-md-6">
          <GeneralInput
            text={Strings.smartVendingClinical.location}
            tooltip={Strings.smartVendingClinical.locationTootip}
            id="location"
            value={locationInfo.location}
            defaultValue={locationInfo.location}
            placeholder={Strings.smartVendingClinical.location}
            isReq
            onChange={(e) => handleFormChange(e)}
          />
        </div>
        <div className="vertSpace col-md-6">
          <GeneralInput
            text={Strings.smartVendingClinical.sector}
            id="sector"
            value={locationInfo.sector}
            defaultValue={locationInfo.sector}
            placeholder={Strings.smartVendingClinical.sector}
            onChange={(e) => handleFormChange(e)}
          />
        </div>
      </div>

      <div className="row">
        <div className="vertSpace col-md-6">
          <GeneralInput
            text={Strings.smartVendingClinical.building}
            id="building"
            value={locationInfo.building}
            defaultValue={locationInfo.building}
            placeholder={Strings.smartVendingClinical.building}
            onChange={(e) => handleFormChange(e)}
          />
        </div>
        <div className="vertSpace col-md-6">
          <GeneralInput
            text={Strings.smartVendingClinical.floor}
            id="floor"
            value={locationInfo.floor}
            defaultValue={locationInfo.floor}
            placeholder={Strings.smartVendingClinical.floor}
            onChange={(e) => handleFormChange(e)}
          />
        </div>
      </div>

      <div className="row">
        <div className="vertSpace col-md-6">
          <GeneralInput
            text={Strings.generalTerms.description}
            id="description"
            value={locationInfo.description}
            defaultValue={locationInfo.description}
            placeholder={Strings.generalTerms.description}
            onChange={(e) => handleFormChange(e)}
          />
        </div>
      </div>

      <div className="vertSpace row justify-content-end">
        <div className="col-6 col-md-3">
          <ReturnButton link="/listlocations" />
        </div>

        <div className="col-6 col-md-3">
          <SaveButton loading={isLoadingSaveButton} isDisabled={isDisableSaveButton} onClick={(e) => onClick(e)} />
        </div>
      </div>
    </div>
  );
}

export default FormLocations;
