import React from "react";

const VendigMachine = ({ width, height, fill }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512.002 512.002"
      xmlSpace="preserve"
      width={width}
      fontSize="2px"
      fill={fill}
    >
      <g transform="translate(0 -1)">
        <g>
          <g>
            <path
              d="M136.534,342.333h170.667c4.713,0,8.533-3.82,8.533-8.533v-85.333v-85.333V77.8c0-4.713-3.82-8.533-8.533-8.533H136.534
            c-4.713,0-8.533,3.82-8.533,8.533v85.333v85.333V333.8C128.001,338.513,131.821,342.333,136.534,342.333z M298.668,239.933
            h-153.6v-68.267h153.6V239.933z M298.668,325.267h-153.6V257h153.6V325.267z M145.068,86.333h153.6V154.6h-153.6V86.333z"
            />
            <path
              d="M341.334,129h34.133c4.713,0,8.533-3.821,8.533-8.533V86.333c0-4.713-3.82-8.533-8.533-8.533h-34.133
            c-4.713,0-8.533,3.82-8.533,8.533v34.133C332.801,125.179,336.621,129,341.334,129z M349.868,94.867h17.067v17.067h-17.067
            V94.867z"
            />
            <path
              d="M341.334,197.267h34.133c4.713,0,8.533-3.82,8.533-8.533V154.6c0-4.713-3.82-8.533-8.533-8.533h-34.133
            c-4.713,0-8.533,3.82-8.533,8.533v34.133C332.801,193.446,336.621,197.267,341.334,197.267z M349.868,163.133h17.067V180.2
            h-17.067V163.133z"
            />
            <path
              d="M341.334,265.533h34.133c4.713,0,8.533-3.82,8.533-8.533v-34.133c0-4.713-3.82-8.533-8.533-8.533h-34.133
            c-4.713,0-8.533,3.82-8.533,8.533V257C332.801,261.713,336.621,265.533,341.334,265.533z M349.868,231.4h17.067v17.067h-17.067
            V231.4z"
            />
            <path
              d="M341.334,333.8h34.133c4.713,0,8.533-3.82,8.533-8.533v-34.133c0-4.713-3.82-8.533-8.533-8.533h-34.133
            c-4.713,0-8.533,3.82-8.533,8.533v34.133C332.801,329.98,336.621,333.8,341.334,333.8z M349.868,299.667h17.067v17.067h-17.067
            V299.667z"
            />
            <path
              d="M136.534,427.667h238.933c4.713,0,8.533-3.821,8.533-8.533v-51.2c0-4.713-3.82-8.533-8.533-8.533H136.534
            c-4.713,0-8.533,3.821-8.533,8.533v51.2C128.001,423.846,131.821,427.667,136.534,427.667z M145.068,376.467h221.867V410.6
            H145.068V376.467z"
            />
            <path
              d="M503.468,495.935h-102.4V461.8h8.533c4.713,0,8.533-3.82,8.533-8.533v-409.6V9.533c0-4.713-3.82-8.533-8.533-8.533
            h-307.2c-4.713,0-8.533,3.821-8.533,8.533v34.133v409.6c0,4.713,3.82,8.533,8.533,8.533h8.533v34.135H8.534
            c-4.713,0-8.533,3.821-8.533,8.533s3.82,8.533,8.533,8.533h494.933c4.713,0,8.533-3.821,8.533-8.533
            S508.18,495.935,503.468,495.935z M401.068,444.733h-8.533H119.468h-8.533V52.2h290.133V444.733z M110.934,18.067h290.133v17.067
            H110.934V18.067z M384.001,461.8v34.133h-256V461.8H384.001z"
            />
            <path
              d="M170.668,103.402c-4.713,0-8.533,3.82-8.533,8.533v17.067c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533
            v-17.067C179.201,107.222,175.38,103.402,170.668,103.402z"
            />
            <path
              d="M204.801,103.402c-4.713,0-8.533,3.82-8.533,8.533v17.067c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533
            v-17.067C213.334,107.222,209.514,103.402,204.801,103.402z"
            />
            <path
              d="M238.934,103.402c-4.713,0-8.533,3.82-8.533,8.533v17.067c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533
            v-17.067C247.468,107.222,243.647,103.402,238.934,103.402z"
            />
            <path
              d="M273.068,103.402c-4.713,0-8.533,3.82-8.533,8.533v17.067c0,4.713,3.821,8.533,8.533,8.533
            c4.713,0,8.533-3.82,8.533-8.533v-17.067C281.601,107.222,277.78,103.402,273.068,103.402z"
            />
            <path
              d="M170.668,222.868c4.713,0,8.533-3.82,8.533-8.533v-17.067c0-4.713-3.82-8.533-8.533-8.533s-8.533,3.821-8.533,8.533
            v17.067C162.134,219.048,165.955,222.868,170.668,222.868z"
            />
            <path
              d="M204.801,222.868c4.713,0,8.533-3.82,8.533-8.533v-17.067c0-4.713-3.821-8.533-8.533-8.533s-8.533,3.821-8.533,8.533
            v17.067C196.268,219.048,200.088,222.868,204.801,222.868z"
            />
            <path
              d="M238.934,222.868c4.713,0,8.533-3.82,8.533-8.533v-17.067c0-4.713-3.82-8.533-8.533-8.533s-8.533,3.821-8.533,8.533
            v17.067C230.401,219.048,234.221,222.868,238.934,222.868z"
            />
            <path
              d="M273.068,222.868c4.713,0,8.533-3.82,8.533-8.533v-17.067c0-4.713-3.821-8.533-8.533-8.533
            c-4.713,0-8.533,3.821-8.533,8.533v17.067C264.534,219.048,268.355,222.868,273.068,222.868z"
            />
            <path
              d="M170.668,308.202c4.713,0,8.533-3.82,8.533-8.533v-17.067c0-4.713-3.82-8.533-8.533-8.533s-8.533,3.82-8.533,8.533
            v17.067C162.134,304.381,165.955,308.202,170.668,308.202z"
            />
            <path
              d="M204.801,308.202c4.713,0,8.533-3.82,8.533-8.533v-17.067c0-4.713-3.821-8.533-8.533-8.533s-8.533,3.82-8.533,8.533
            v17.067C196.268,304.381,200.088,308.202,204.801,308.202z"
            />
            <path
              d="M238.934,308.202c4.713,0,8.533-3.82,8.533-8.533v-17.067c0-4.713-3.82-8.533-8.533-8.533s-8.533,3.82-8.533,8.533
            v17.067C230.401,304.381,234.221,308.202,238.934,308.202z"
            />
            <path
              d="M273.068,308.202c4.713,0,8.533-3.82,8.533-8.533v-17.067c0-4.713-3.821-8.533-8.533-8.533
            c-4.713,0-8.533,3.82-8.533,8.533v17.067C264.534,304.381,268.355,308.202,273.068,308.202z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default VendigMachine;
