// Dependencies
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Icon, Pagination, Tooltip } from "antd";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../systemVariables/TimeConfig";
import QueryResponse from "../GenericComponents/queryResponse/QueryResponse";
// Actions
import { getVmEvents } from "../../actions";

// Language Localization
import Strings from "../../systemVariables/languageStrings";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";

const { Column } = Table;

const VmEventTable = (props) => {
  const {
    soDependencyId,
    scDependencyId,
    vendingMachineId,
    priorityLow,
    priorityHigh,
    eventCategoryTypeId,
    eventTypeId,
    startDate,
    endDate,
    refreshTableInfo,
    getVmEvents,
    eventClick,
  } = props;

  const [vmEvents, setvmEvents] = useState([]);
  const [vmEventsCount, setvmEventsCount] = useState(null);
  const [loadingInfo, setloadingInfo] = useState(true);
  const [pageSize, setpageSize] = useState(10);
  const [page, setpage] = useState(1);
  const [attribute, setattribute] = useState("id");
  const [order, setorder] = useState("DESC");

  useEffect(() => {
    setloadingInfo(true);

    function methodCallback(loadingInfo, response) {
      // eslint-disable-next-line react/destructuring-assignment
      props.methodCallbackLoadingInfo(loadingInfo, response);
    }

    getVmEvents(
      soDependencyId,
      scDependencyId,
      vendingMachineId,
      priorityLow,
      priorityHigh,
      eventCategoryTypeId,
      eventTypeId,
      moment(startDate).format(),
      moment(endDate).format(),
      page,
      pageSize,
      attribute,
      order,
      (response) => {
        if (soDependencyId !== null) {
          setvmEvents(response.data.data.content);
          setvmEventsCount(response.data.data.totalElements);
          setloadingInfo(false);
          methodCallback(loadingInfo, response);
        } else {
          setloadingInfo(false);
          methodCallback(loadingInfo, response);
        }
      }
    );
    return () => {
      clearInterval(getVmEvents);
      clearInterval(methodCallback());
    };
  }, [eventClick, page, pageSize]);

  const showPagination = (page, pageSize) => {
    setpage(page);
    setpageSize(pageSize);
  };

  const priorityStatus = (priority) => {
    let statusColorCode = "";
    if (priority >= 0 && priority < 39) {
      statusColorCode = "#F3F033";
    } else if (priority > 40 && priority <= 70) {
      statusColorCode = "#F57409";
    } else {
      statusColorCode = "#F82222";
    }
    return statusColorCode;
  };

  const renderTableVmEvent = (refreshTableInfo, vmEvents, loadingInfo, soDependencyId, scDependencyId, vendingMachineId, priorityStatus, vmEventsCount, showPagination, page) => {
    return (
      <div style={{ display: refreshTableInfo ? "block" : "none" }}>
        <Table dataSource={vmEvents} bordered loading={loadingInfo} pagination={false} size="middle" scroll={{ x: "auto" }} rowKey="id">
          {soDependencyId === null ? (
            <Column
              align="center"
              title={Strings.soDependency.directClient}
              dataIndex="soDependencyName"
              // {...this.getColumnSearchProps("identityCardNumber")}
              filtered
            />
          ) : (
            ""
          )}
          {scDependencyId == null ? <Column align="center" title={Strings.scDependency.indirectClient} dataIndex="scDependencyName" /> : ""}
          {vendingMachineId == null ? (
            <Column align="center" title={<TextWithInfoTooltip name={Strings.machine.machine} tooltip={Strings.machine.machineTooltip} />} dataIndex="vendingMachine" />
          ) : (
            ""
          )}
          <Column align="center" title={Strings.eventType.eventTypeCategoryName} dataIndex="eventTypeCategoryName" />
          <Column align="center" title={Strings.eventType.eventType} dataIndex="eventType" />
          <Column
            align="center"
            title={Strings.generalTerms.message}
            dataIndex="vmEventMessage"
            render={(vmEventMessage) =>
              vmEventMessage.length > 150 ? (
                <span>
                  <Tooltip title={vmEventMessage}>{`${vmEventMessage.substring(0, 149)}...`}</Tooltip>
                </span>
              ) : (
                <span>{vmEventMessage}</span>
              )
            }
          />
          <Column
            align="center"
            title={Strings.eventType.priority}
            render={(row) => (
              <>
                {" "}
                {row.eventPriority} <Icon type="exclamation-circle" theme="twoTone" twoToneColor={priorityStatus(row.eventPriority)} />{" "}
              </>
            )}
          />
          <Column align="center" title={Strings.generalTerms.date} render={(row) => <>{moment(row.eventDate).format(DATE_TIME_FORMAT)} </>} />
        </Table>
        <div className="col-xs-0 col-md-6" />
        <div className="vertSpace col-md-6">
          <Pagination
            size="small"
            total={vmEventsCount}
            showSizeChanger
            onChange={showPagination}
            onShowSizeChange={showPagination}
            hideOnSinglePage={false}
            pageSizeOptions={["10", "25", "50", "100", "250"]}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            current={page}
            disabled={vmEventsCount === 0 ? true : false}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <QueryResponse
        callback={renderTableVmEvent(
          refreshTableInfo,
          vmEvents,
          loadingInfo,
          soDependencyId,
          scDependencyId,
          vendingMachineId,
          priorityStatus,
          vmEventsCount,
          showPagination,
          page
        )}
        dataSourceLength={vmEvents.length}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  getVmEvents,
})(VmEventTable);
