import React from "react";

import Select from "react-select";
import { Tooltip, Icon } from "antd";
import "./selectComponent.css";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

export const SelectGeneral = ({ id, isNotText, options, size, loading, defaultValue, isReq, text, tooltip, name, disabled, onChange }) => {
  let defVal = "default";
  let sizeSelectGeneral = "default";

  if (options) {
    defVal = options.filter((option) => option.value === defaultValue);
  }
  if (size) {
    sizeSelectGeneral = size;
  }

  const loadingSelectGeneral = loading || false;

  return (
    <div className={isNotText ? "" : "container-fluid"}>
      <div className={isNotText ? "" : "row"}>
        <div className={isNotText ? "" : "selectAligning col-4"}>
          {!isNotText ? (
            <span>
              {text}
              <b>{isReq === true ? ": *" : ":"}</b>
            </span>
          ) : null}
          {tooltip ? (
            <Tooltip title={tooltip}>
              <Icon className="icon" type="info-circle" style={{ float: "none" }} />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        <div className={isNotText ? "col-12 input" : "col-8 input"}>
          <Select
            id={id}
            name={name}
            loading={defVal.length > 0 ? loading : null}
            className="basic-single"
            options={options}
            placeholder={Strings.generalTerms.select}
            isDisabled={disabled}
            onChange={onChange}
            value={defVal}
            isLoading={defVal.length > 0 ? loadingSelectGeneral : null}
            size={sizeSelectGeneral}
          />
        </div>
      </div>
    </div>
  );
};
