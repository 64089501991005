import Strings from "../../systemVariables/languageStrings";

export function soDependencyTypeLocalization(soDependencyType) {
  switch (soDependencyType) {
    case "STOCK OWNER":
      soDependencyType = Strings.soDependency.soDependencyType.stockOwner;
      break;
    case "SO HEADQUARTER":
      soDependencyType = Strings.soDependency.soDependencyType.headquarter;
      break;
    case "SO LOGISTIC CENTER":
      soDependencyType = Strings.soDependency.soDependencyType.logisticCenter;
      break;
    case "SO PROVISIONER":
      soDependencyType = Strings.soDependency.soDependencyType.provisioner;
      break;
    default:
      break;
  }
  return soDependencyType;
}
