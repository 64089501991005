import { LIST_MOTORTYPE, ERROR } from '../actions';

const initialState = {
  motorTypeObj: [],
  errorObj: []
}

export function motorType(state = initialState, action) {
  switch (action.type) {
    case LIST_MOTORTYPE:
      return Object.assign({}, state, {motorTypeObj: action.payload})
    case ERROR:
      return Object.assign({}, state, {errorObj: action.payload})
    default:
      return state
  }
}