import React from "react";
import vmAmbienti from "../../Global/images/vm_ambienti1.png";
import vmChillFrozen from "../../Global/images/vm_chill_frozen.png";
import vmLockerStandAlone from "../../Global/images/vmLockerStandAlone.png";

const MachinesForWingTypeImages = ({ wingType }) => {
  const IMAGES_SWITCH = {
    0: () => <img src={vmAmbienti} id="vendingMachine" alt="" width={220} height={120} />,
    1: () => <img src={vmChillFrozen} id="vendingMachine" alt="" width={290} height={100} />,
    2: () => <img src={vmChillFrozen} id="vendingMachine" alt="" width={290} height={100} />,
    3: () => <img src={vmLockerStandAlone} id="locker" alt="" width={290} height={300} />,
    4: () => <img src={vmAmbienti} id="vendingMachine" alt="" width={220} height={120} />,
  };
  function wingTypeSelection() {
    if (wingType) {
      return <div> {IMAGES_SWITCH[wingType.id]()}</div>;
    }
    return <div />;
  }
  return wingTypeSelection();
};

export default MachinesForWingTypeImages;
