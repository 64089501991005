import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Typography, Card, Divider } from "antd";
import Strings from "../../../systemVariables/languageStrings";
import EditNameVm from "./EditNameVm";

// Machine images
import VendigMachine from "../../Global/images/ComponentsSVG/VendigMachine";
import LockerComponentSVG from "../../Global/images/ComponentsSVG/LockerComponentSVG";

const { Paragraph } = Typography;
const { Meta } = Card;
const dateFormat = "YYYY/MM/DD hh:mm:ss";

const CardBasicInformation = ({ wingType, vmInfo, vm, getResponseChangefriendlyName, actions }) => {
  const {
    generalTerms: { name },
  } = Strings;
  const { friendlyName } = vmInfo;

  const ObjectParagraph = [
    [Strings.machine.machineSerial, vm?.serialNumber],
    [Strings.machine.vmFirmware, vmInfo?.vmFirmware],
    [Strings.machine.machineGeneration, vmInfo?.machineGeneration],
    [Strings.scDependency.indirectClient, vmInfo?.scDependencyName ? vmInfo?.scDependencyName : Strings.generalTerms.notAssigned],
    [Strings.generalTerms.creationDate, vmInfo?.creationDate ? moment(vmInfo?.creationDate).format(dateFormat) : Strings.generalTerms.notAssigned],
    [Strings.machine.contractDate, vmInfo?.contractDate ? moment(vmInfo?.contractDate).format(dateFormat) : Strings.generalTerms.notAssigned],
  ];

  return (
    <Card
      style={{
        Maxwidth: 530,
      }}
      actions={actions}
    >
      <Meta
        avatar={wingType?.id === 3 ? <LockerComponentSVG width={40} /> : <VendigMachine width={40} />}
        title={<EditNameVm name={friendlyName} generalTermsName={name} vmId={vm.id} callbackFriendlyName={getResponseChangefriendlyName} />}
        description={<div style={{ textAlign: "left" }}> {Strings.generalTerms.basicInformation}</div>}
      />
      <Divider />
      {ObjectParagraph.map(([key, value]) => {
        return (
          <div className="row">
            <div className="col-md-6">
              <Paragraph strong>{key}:</Paragraph>
            </div>
            <div className="col-md-5" style={{ AlingText: "left" }}>
              {value}
            </div>
          </div>
        );
      })}
    </Card>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CardBasicInformation);
