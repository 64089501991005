// Language localization
import Strings from '../../systemVariables/languageStrings';

export function setVmSetupWithMeasurementUnit(vmSetupVal, measurementUnit) {
    let setupVal;

    if (!vmSetupVal) {
        vmSetupVal = '--';
    }

    // vmSetupVal=parseInt(vmSetupVal)
    switch (measurementUnit) {
        case undefined:
            setupVal = vmSetupVal;
            break;
        case 'boolean':
            if (  parseInt (vmSetupVal) === 1) {
                setupVal = Strings.generalTerms.yes;
            } else {
                setupVal = Strings.generalTerms.no;
            }
            break;
        case 'money':
            setupVal = `$${  vmSetupVal}`;
            break;
        case 'pctg':
            setupVal = `${vmSetupVal  }%`;
            break;
        case 'h [local]':
            setupVal=`${vmSetupVal  }h`
            break;
        default:
            setupVal = `${vmSetupVal  } ${  measurementUnit}`;
            break;
    }
    return setupVal;
}