import axios from "axios";
import { s3Bucket } from "../systemVariables/serverInformation"
import { host, port } from "../systemVariables"

const resource = "/storage";

export function getFile(key, callback) {
    return () => {
        axios.get(host + port + resource + "/download", {
            responseType: 'arraybuffer',
            params: {
                bucket: s3Bucket,
                key: key
            },
            headers: {
                // 'Content-Type': 'image/jpeg',
                // "responseType": 'blob',
                // "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
            },
        }).then((response) => {
            let ans = {
                data: new Buffer(response.data, 'binary').toString('base64'),
                fileType: response.headers["content-type"]
            }
            callback(ans);
        }).catch((error) => {
            callback(error);
        });
    }
}

export function postFile(file, key, callback) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('bucket', s3Bucket);
    formData.append('key', key);

    return () => {
        axios({
            method: 'post',
            url: host + port + "/storage/upload",
            data: formData,
            headers: {
                'content-Type': 'multipart/form-data',
                Authorization: "Bearer" + sessionStorage.getItem('access_token')
            }
        })
            .then(response => {
                callback(response);
            })
            .catch(error => {
                callback(error);
            });
    }
}
