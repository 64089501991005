import { 
    LIST_DISPENSATIONRULES 
} from '../actions'
  
export function dispRulesByUser(state = [], action) {
    switch (action.type) {
        case LIST_DISPENSATIONRULES:
            return Object.assign({}, state = [], {dispRulesObj: action.payload})
        default:
            return state
    }
}


  