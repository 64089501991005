import { LIST_VMTRAYPOSITION, ERROR } from '../actions'

const initialState = {
  vmTrayPositionObj: [],
  errorObj: []
}

export function vmTrayPosition(state = initialState, action) {
  switch (action.type) {
    case LIST_VMTRAYPOSITION:
      return Object.assign({}, state, {vmTrayPositionObj: action.payload.map(item => ({value:item.id, label:item.name}))})
    case ERROR:
      return Object.assign({}, state, {errorObj: action.payload})
    default:
      return state
  }
}