/* eslint-disable no-unused-vars */
// Dependencies
import { Button, Divider, Modal, Pagination, Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import { RangeDate } from "../../GenericComponents/DateComponent/RangeDate";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import SoScVmDependencySelector from "../../GenericComponents/selectComponent/SoScVmDependencySelector";
import Titles from "../../GenericComponents/titles";
import DispensationReportTable from "./TransactionsReportTable";

import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { ExportButton } from "../../GenericComponents/buttons/index";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";
import ExportModalTransactionsReport from "./ExportModalTransactionsReport";
import "./TransactionsReport.css";

// Error messages to answer
import ConnectionErrorComponent from "../../Errors/ConnectionError";
import Error500 from "../../Errors/error500";

// Language Localization
import Strings from "../../../systemVariables/languageStrings";
import { paymentTypeLocalization } from "../../../Utils/LanguageLocalization";

// Action imports
import {
  getAllPaymentTypes,
  // Only to export file
  getDispensationReportFile,
  getPagedVmProductTransactionsByScDepartmentIdAndTransactionTypeIdsAndStorageDateBetween,
  getPagedVmProductTransactionsByScDependencyIdAndTransactionTypeIdsAndStorageDateBetween,
  getPagedVmProductTransactionsByScUserProfileIdAndTransactionTypeIdsAndStorageDateBetween,
  // Only to show table (to search transactions)
  getPagedVmProductTransactionsByStockOwnerIdAndTransactionTypeIdsAndStorageDateBetween,
  getPagedVmProductTransactionsByVendingMachineIdAndTransactionTypeIdsAndStorageDateBetween,
  getProductTransactionTypesByTransactionCategoryId,
  getTransactionCategories,
  getUserById,
  getVmSetupByVmIdAndSetupParameterName,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
} from "../../../actions";

const INITIAL_START_DATE = moment().hour(0).minutes(0).second(0);
const INITIAL_END_DATE = moment().hour(23).minutes(59);
const ATTRIBUTE = "storageDate";
const ORDER = "DESC";
const TARGETSOURCE = "email";

class TransactionsReports extends Component {
  constructor(props) {
    super(props);

    const VM_TRANSACTION_TYPES_FILTER = [
      { value: 0, label: Strings.transaction.dispensationOk.toUpperCase() },
      { value: 1, label: Strings.transaction.dispensationFail.toUpperCase() },
      { value: 2, label: Strings.transaction.dispensationRefunded.toUpperCase() },
      { value: 3, label: Strings.transaction.dispensationCancelled.toUpperCase() },
    ];

    const LOCKER_TRANSACTION_TYPES_FILTER = [
      { value: 0, label: Strings.transaction.dispensationOk.toUpperCase() },
      { value: 1, label: Strings.transaction.transactionsFail.toUpperCase() },
      { value: 2, label: Strings.transaction.dispensationRefunded.toUpperCase() },
      { value: 3, label: Strings.transactionType.lockerProductDelivery.toUpperCase() },
      { value: 4, label: Strings.transactionType.lockerLoan.toUpperCase() },
      { value: 5, label: Strings.transactionType.lockerAudit.toUpperCase() },
    ];

    const LOCKER_USER_TYPES_FILTER = [
      { value: 0, label: "REGISTER USER" },
      { value: 1, label: "ANONYMOUS USER" },
    ];

    const soDependenciesToIgnoreFields = [2]

    this.state = {
      moduleTypeOptions: [],
      moduleTypeSelected: 0,
      scDepartments: [],
      scUserProfiles: [],
      paymentTypes: [],
      soDependencyId: null,
      scDependencyId: null,
      scDepartmentId: null,
      scUserProfileId: null,
      vendingMachineId: null,
      currentSelector: "SO",
      isFilterDisabled: true,
      stateChange: false,
      startDate: INITIAL_START_DATE,
      endDate: INITIAL_END_DATE,
      paymentTypeId: -1,
      isDateSelectorDisabled: true,
      identityCardNumber: null,
      erpProductCode: null,
      successfulTransactionTypeIds: [1, 5, 6],
      failedTransactionTypeId: [4],
      cashlessPaymentRefundedTransactionTypeIds: [28, 33],
      cancelledTypeIds: [31, 32],
      vmTransactionTypeFilterValues: VM_TRANSACTION_TYPES_FILTER,
      lockerSuccessfulTransactionTypeIds: [7],
      lockerFailedTransactionTypeIds: [29],
      lockerCashlessPaymentRefundedTransactionTypeIds: [30],
      lockerDeliveryTransactionTypeIds: [11, 12, 24, 25, 26, 27],
      lockerLoanTransactionTypeIds: [15, 16, 17, 23],
      lockerAuditTransactionTypeIds: [8, 18, 19, 20, 21, 22],
      lockerTransactionTypeFilterValues: LOCKER_TRANSACTION_TYPES_FILTER,
      productTransactionTypeIdsToRequest: [],
      selectedProductTransactionTypeToRequest: 0,
      prodTransactionsTypes: VM_TRANSACTION_TYPES_FILTER,
      lockerUserType: LOCKER_USER_TYPES_FILTER,
      selectedLockerUserType: 0,
      dispensations: [],
      isTableLoading: false,
      isExportModalVisible: false,
      isSearchButtonDisabled: false,
      isExportButtonDisabled: false,
      isExportLoading: false,
      page: 1,
      pageSize: 10,
      totalElements: 0,
      isError500: false,
      isConnectionError: false,
      soDependenciesToIgnoreFields: soDependenciesToIgnoreFields,
    };
  }

  componentDidMount() {
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      soScSelectionProps,
      getTransactionCategories,
      getAllPaymentTypes,
      listScUserProfilesByScDependencyId,
      listScDepartmentByScDependencyId,
    } = this.props;

    // Get all data to initialize the category and paymentType filters
    getTransactionCategories((response) => {
      this.setState({
        moduleTypeOptions: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });

    getAllPaymentTypes((response) => {
      const paymentTypes = response.data.data.map((item) => ({
        value: item.id,
        label: paymentTypeLocalization(item.id),
      }));
      paymentTypes.unshift({
        value: -1,
        label: Strings.generalTerms.all,
      });
      this.setState({ paymentTypes });
    });

    // Initialize data when the session is for a Noatec so user
    if (dependencyType === "so") {
      this.setState({
        soDependencyId: soScSelectionProps.so ? soScSelectionProps.so.id : dependencyId,
        scDependencyId: soScSelectionProps.sc ? soScSelectionProps.sc.id : null,
        scDepartmentId: null,
        scUserProfileId: null,
        vendingMachineId: null,
        isDateSelectorDisabled: false,
        isExportLoading: false,
        page: 1,
      });

      if (soScSelectionProps.sc !== null && soScSelectionProps.sc.id !== -1) {
        // Get all sc employ information to initialize scUserProfile and scDepartment filters and states for sc
        this.methodListScUserProfilesAndListDepartmentSc(soScSelectionProps.sc.id, listScUserProfilesByScDependencyId, listScDepartmentByScDependencyId);
        this.setState({
          currentSelector: "SC",
          isFilterDisabled: false,
        });
      } else {
        this.setState({
          currentSelector: "SO",
          vendingMachineId: null,
          scDepartmentId: null,
          scUserProfileId: null,
          isFilterDisabled: true,
        });
      }
    }

    // Initialize data when the session is for a sc user different
    if (dependencyType === "sc") {
      this.handleScDependencyChange({ value: dependencyId }, "SC");
      this.setState({
        vendingMachineId: -1,
      });
    }
  }

  // --------------- Events and functions to fill filters ---------------

  // Module type change event to fill transaction type selector
  handleModuleTypeSelector = (event) => {
    const { value: moduleTypeSelected } = event;
    const { currentSelector, vmTransactionTypeFilterValues, lockerTransactionTypeFilterValues } = this.state;
    this.setState({
      moduleTypeSelected,
      currentSelector,
      dispensations: [],
      totalElements: 0,
      paymentTypeId: -1,
      prodTransactionsTypes: moduleTypeSelected === 0 ? vmTransactionTypeFilterValues : lockerTransactionTypeFilterValues,
      selectedProductTransactionTypeToRequest: 0,
    });
  };

  // Transaction type change event for transactions in vending type module
  handleTransactionTypeSelector = (event) => {
    const { currentSelector } = this.state;
    this.setState({
      selectedProductTransactionTypeToRequest: event.value,
      page: 1,
      dispensations: [],
      pageSize: 10,
      totalElements: 0,
      paymentTypeId: -1,
      isExportButtonDisabled: false,
      isSearchButtonDisabled: false,
    });

    if ((event.value !== 0 && currentSelector === "DEP") || currentSelector === "PROF") {
      this.setState({
        currentSelector: "SC",
        scDepartmentId: null,
        scUserProfileId: null,
        page: 1,
        pageSize: 10,
        totalElements: 0,
      });
    }
  };

  // So dependency change event
  handleSoDependencyChange = (event) => {
    const { value: soDependencyId } = event;
    const { selectedProductTransactionTypeToRequest } = this.state;

    this.setState({
      soDependencyId,
      scDependencyId: null,
      scDepartmentId: null,
      scUserProfileId: null,
      vendingMachineId: null,
      isFilterDisabled: true,
      isDateSelectorDisabled: false,
      isExportLoading: false,
      isExportButtonDisabled: false,
      isSearchButtonDisabled: false,
      currentSelector: "SO",
      page: 1,
      stateChange: true,
      dispensations: [],
      selectedProductTransactionTypeToRequest,
    });
  };

  // Sc dependency change event
  handleScDependencyChange = (event) => {
    const {
      listScUserProfilesByScDependencyId,
      listScDepartmentByScDependencyId,
      userDependency: { dependencyId },
    } = this.props;
    const { selectedProductTransactionTypeToRequest } = this.state;
    if (event.value !== -1) {
      // Get all sc employ information to fill scUserProfile and scDepartment filters and states for sc
      this.methodListScUserProfilesAndListDepartmentSc(event.value, listScUserProfilesByScDependencyId, listScDepartmentByScDependencyId);
      this.setState({
        scDependencyId: event.value,
        vendingMachineId: null,
        isDateSelectorDisabled: false,
        isExportLoading: false,
        isExportButtonDisabled: false,
        isSearchButtonDisabled: false,
        currentSelector: "SC",
        page: 1,
        stateChange: true,
        selectedProductTransactionTypeToRequest,
      });
    } else {
      this.setState({
        soDependencyId: dependencyId,
        currentSelector: "SO",
        stateChange: false,
        isFilterDisabled: true,
        vendingMachineId: null,
        scDepartmentId: null,
        scUserProfileId: null,
      });
    }
  };

  // Vending machine change event
  handleVendingMachineChange = (event, name) => {
    const { scDependencyId } = this.state;
    const { selectedProductTransactionTypeToRequest } = this.state;
    if (event.value !== -1) {
      this.setState({
        vendingMachineId: event.value,
        scDepartmentId: null,
        scUserProfileId: null,
        stateChange: true,
        page: 1,
        currentSelector: name,
        isExportButtonDisabled: false,
        isSearchButtonDisabled: false,
        selectedProductTransactionTypeToRequest,
      });
    } else {
      // All selected in the vending machine selector
      this.handleScDependencyChange({ value: scDependencyId }, "SC");
      this.setState({
        vendingMachineId: -1,
        selectedProductTransactionTypeToRequest,
      });
    }
  };

  // Sc department change event
  handleScDepartmentChange = (event, option) => {
    const { value: scDepartmentId } = event;
    const { name: currentSelector } = option;
    const { scDependencyId } = this.state;
    this.setState({
      scDepartmentId,
      scUserProfileId: null,
      vendingMachineId: null,
      scDependencyId,
      page: 1,
      stateChange: true,
      currentSelector,
      isExportButtonDisabled: false,
      isSearchButtonDisabled: false,
    });
  };

  // Sc userProfiler change event
  handleScUserProfileChange = (event, option) => {
    const { value: scUserProfileId } = event;
    const { name: currentSelector } = option;
    const { scDependencyId } = this.state;

    this.setState({
      scUserProfileId,
      scDepartmentId: null,
      vendingMachineId: null,
      scDependencyId,
      page: 1,
      stateChange: true,
      currentSelector,
      isExportButtonDisabled: false,
      isSearchButtonDisabled: false,
    });
  };

  // Start and end date change event
  handleDateChange = (date) => {
    const startDate = date[0];
    const endDate = date[1];
    this.setState({
      startDate,
      endDate,
      page: 1,
      dispensations: [],
      totalElements: 0,
    });
  };

  // Payment type change event
  handlePaymentTypeChange = (event) => {
    const { value } = event;
    this.setState({
      paymentTypeId: value,
      page: 1,
      isExportButtonDisabled: false,
      isSearchButtonDisabled: false,
    });
  };

  // Locker user type change event
  handleLockerUserTypeChange = (event) => {
    const { value } = event;
    this.setState({
      selectedLockerUserType: value,
      page: 1,
      isExportButtonDisabled: false,
      isSearchButtonDisabled: false,
    });
  };

  // Function to get scUserProfiler and scDepartments by scId
  methodListScUserProfilesAndListDepartmentSc(scDependencyId, listScUserProfilesByScDependencyId, listScDepartmentByScDependencyId) {
    this.setState({
      scDepartmentId: null,
      scUserProfileId: null,
    });
    listScUserProfilesByScDependencyId(scDependencyId, (response) => {
      this.setState({
        scUserProfiles: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });

    listScDepartmentByScDependencyId(scDependencyId, (response) => {
      this.setState({
        scDepartments: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });
    this.setState({
      isFilterDisabled: false,
    });
  }

  // --------------- Events and functions to fill table from search button ---------------

  // Pagination change event
  handlePagination = (page, pageSize) => {
    this.setState({
      pageSize,
      page,
      isTableLoading: true,
    });
    const {
      currentSelector,
      soDependencyId,
      scDependencyId,
      scDepartmentId,
      scUserProfileId,
      vendingMachineId,
      identityCardNumber,
      startDate,
      endDate,
      paymentTypeId,
      productTransactionTypeIdsToRequest,
      successfulTransactionTypeIds,
      failedTransactionTypeId,
      cashlessPaymentRefundedTransactionTypeIds,
      cancelledTypeIds,
      lockerSuccessfulTransactionTypeIds,
      lockerFailedTransactionTypeIds,
      lockerCashlessPaymentRefundedTransactionTypeIds,
      lockerDeliveryTransactionTypeIds,
      lockerLoanTransactionTypeIds,
      lockerAuditTransactionTypeIds,
    } = this.state;

    this.getDispensations(
      currentSelector,
      soDependencyId,
      scDependencyId,
      scDepartmentId,
      scUserProfileId,
      vendingMachineId,
      identityCardNumber,
      productTransactionTypeIdsToRequest,
      successfulTransactionTypeIds,
      failedTransactionTypeId,
      cashlessPaymentRefundedTransactionTypeIds,
      cancelledTypeIds,
      lockerSuccessfulTransactionTypeIds,
      lockerFailedTransactionTypeIds,
      lockerCashlessPaymentRefundedTransactionTypeIds,
      lockerDeliveryTransactionTypeIds,
      lockerLoanTransactionTypeIds,
      lockerAuditTransactionTypeIds,
      startDate,
      endDate,
      paymentTypeId,
      page,
      pageSize
    );
  };

  // Function search button
  handleSearchButtonOnClick = () => {
    const {
      currentSelector,
      productTransactionTypeIdsToRequest,
      successfulTransactionTypeIds,
      failedTransactionTypeId,
      cashlessPaymentRefundedTransactionTypeIds,
      cancelledTypeIds,
      lockerSuccessfulTransactionTypeIds,
      lockerFailedTransactionTypeIds,
      lockerCashlessPaymentRefundedTransactionTypeIds,
      lockerDeliveryTransactionTypeIds,
      lockerLoanTransactionTypeIds,
      lockerAuditTransactionTypeIds,
      pageSize,
      soDependencyId,
      scDependencyId,
      scDepartmentId,
      scUserProfileId,
      vendingMachineId,
      paymentTypeId,
      startDate,
      endDate,
      page,
    } = this.state;

    this.setState({
      isTableLoading: true,
      currentSelector,
      soDependencyId,
      scDependencyId,
      scDepartmentId,
      scUserProfileId,
      vendingMachineId,
      startDate,
      endDate,
      paymentTypeId,
      page: 1,
      pageSize,
      isExportButtonDisabled: true,
    });

    this.getDispensations(
      currentSelector,
      soDependencyId,
      scDependencyId,
      scDepartmentId,
      scUserProfileId,
      vendingMachineId,
      "",
      productTransactionTypeIdsToRequest,
      successfulTransactionTypeIds,
      failedTransactionTypeId,
      cashlessPaymentRefundedTransactionTypeIds,
      cancelledTypeIds,
      lockerSuccessfulTransactionTypeIds,
      lockerFailedTransactionTypeIds,
      lockerCashlessPaymentRefundedTransactionTypeIds,
      lockerDeliveryTransactionTypeIds,
      lockerLoanTransactionTypeIds,
      lockerAuditTransactionTypeIds,
      startDate,
      endDate,
      paymentTypeId,
      page,
      pageSize
    );
  };

  // Function search by filter table
  getFilteredTransactions = (value, attributeToFilter) => {
    const {
      currentSelector,
      productTransactionTypeIdsToRequest,
      successfulTransactionTypeIds,
      failedTransactionTypeId,
      cashlessPaymentRefundedTransactionTypeIds,
      cancelledTypeIds,
      lockerSuccessfulTransactionTypeIds,
      lockerFailedTransactionTypeIds,
      lockerCashlessPaymentRefundedTransactionTypeIds,
      lockerDeliveryTransactionTypeIds,
      lockerLoanTransactionTypeIds,
      lockerAuditTransactionTypeIds,
      pageSize,
      soDependencyId,
      scDependencyId,
      scDepartmentId,
      scUserProfileId,
      vendingMachineId,
      paymentTypeId,
      startDate,
      endDate,
      page,
    } = this.state;

    this.setState({
      isTableLoading: true,
      currentSelector,
      soDependencyId,
      scDependencyId,
      scDepartmentId,
      scUserProfileId,
      vendingMachineId,
      productTransactionTypeIdsToRequest,
      startDate,
      endDate,
      paymentTypeId,
      page: 1,
      pageSize,
      isExportButtonDisabled: true,
    });

    if (attributeToFilter === "userIdentityCardNumber") {
      this.getDispensations(
        currentSelector,
        soDependencyId,
        scDependencyId,
        scDepartmentId,
        scUserProfileId,
        vendingMachineId,
        value[0] !== "--" ? value[0] : "9998",
        productTransactionTypeIdsToRequest,
        successfulTransactionTypeIds,
        failedTransactionTypeId,
        cashlessPaymentRefundedTransactionTypeIds,
        cancelledTypeIds,
        lockerSuccessfulTransactionTypeIds,
        lockerFailedTransactionTypeIds,
        lockerCashlessPaymentRefundedTransactionTypeIds,
        lockerDeliveryTransactionTypeIds,
        lockerLoanTransactionTypeIds,
        lockerAuditTransactionTypeIds,
        startDate,
        endDate,
        paymentTypeId,
        page,
        pageSize
      );
    }
  };

  // Function to get transaction report (paginated) by filter selection
  getDispensations(
    currentSelector,
    soDependencyId,
    scDependencyId,
    scDepartmentId,
    scUserProfileId,
    vendingMachineId,
    identityCardNumber,
    productTransactionTypeIdsToRequest,
    successfulTransactionTypeIds,
    failedTransactionTypeId,
    cashlessPaymentRefundedTransactionTypeIds,
    cancelledTypeIds,
    lockerSuccessfulTransactionTypeIds,
    lockerFailedTransactionTypeIds,
    lockerCashlessPaymentRefundedTransactionTypeIds,
    lockerDeliveryTransactionTypeIds,
    lockerLoanTransactionTypeIds,
    lockerAuditTransactionTypeIds,
    startDate,
    endDate,
    paymentTypeId,
    page,
    pageSize
  ) {
    if (scDependencyId === -1) {
      this.setState({
        currentSelector: "SO",
      });
    }

    this.setState({
      currentSelector,
      soDependencyId,
      scDependencyId,
      identityCardNumber,
    });

    const { selectedProductTransactionTypeToRequest, moduleTypeSelected } = this.state;
    const objectSwitchDispensations = {
      SO: () => {
        if (moduleTypeSelected === 0) {
          if (selectedProductTransactionTypeToRequest === 0) {
            // Get successful transactions by soDependencyId
            this.getDispensationsBySoId(soDependencyId, successfulTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 1) {
            // Get failed transactions by soDependencyId
            this.getDispensationsBySoId(soDependencyId, failedTransactionTypeId, identityCardNumber, startDate, endDate, -1, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 2) {
            // Get cashless payments refunds by soDependencyId
            this.getDispensationsBySoId(soDependencyId, cashlessPaymentRefundedTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
          if (selectedProductTransactionTypeToRequest === 3) {
            // Get cancelled transactions by soDependencyId
            this.getDispensationsBySoId(soDependencyId, cancelledTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
        } else {
          if (selectedProductTransactionTypeToRequest === 0) {
            // Get locker successful transactions by soDependencyId
            this.getDispensationsBySoId(soDependencyId, lockerSuccessfulTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 1) {
            // Get locker failed transactions by soDependencyId
            this.getDispensationsBySoId(soDependencyId, lockerFailedTransactionTypeIds, identityCardNumber, startDate, endDate, -1, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 2) {
            // Get locker cashless payments refunds by soDependencyId
            this.getDispensationsBySoId(soDependencyId, lockerCashlessPaymentRefundedTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 3) {
            // Get delivery transactions by soDependencyId
            this.getDispensationsBySoId(soDependencyId, lockerDeliveryTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 4) {
            // Get loan transactions by soDependencyId
            this.getDispensationsBySoId(soDependencyId, lockerLoanTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 5) {
            // Get audit transactions by soDependencyId
            this.getDispensationsBySoId(soDependencyId, lockerAuditTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
        }
      },
      SC: () => {
        if (moduleTypeSelected === 0) {
          if (selectedProductTransactionTypeToRequest === 0) {
            // Get successful transactions by scDependencyId
            this.getDispensationsByScId(scDependencyId, successfulTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 1) {
            // Get failed transactions by scDependencyId
            this.getDispensationsByScId(scDependencyId, failedTransactionTypeId, identityCardNumber, startDate, endDate, -1, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 2) {
            // Get cashless payments refunds by scDependencyId
            this.getDispensationsByScId(scDependencyId, cashlessPaymentRefundedTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
          if (selectedProductTransactionTypeToRequest === 3) {
            // Get cancelled transactions by scDependencyId
            this.getDispensationsByScId(scDependencyId, cancelledTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
        } else {
          if (selectedProductTransactionTypeToRequest === 0) {
            // Get locker successful transactions by scDependencyId
            this.getDispensationsByScId(scDependencyId, lockerSuccessfulTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 1) {
            // Get locker failed transactions by scDependencyId
            this.getDispensationsByScId(scDependencyId, lockerFailedTransactionTypeIds, identityCardNumber, startDate, endDate, -1, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 2) {
            // Get locker cashless payments refunds by scDependencyId
            this.getDispensationsByScId(scDependencyId, lockerCashlessPaymentRefundedTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 3) {
            // Get delivery transactions by scDependencyId
            this.getDispensationsByScId(scDependencyId, lockerDeliveryTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 4) {
            // Get loan transactions by scDependencyId
            this.getDispensationsByScId(scDependencyId, lockerLoanTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 5) {
            // Get audit transactions by scDependencyId
            this.getDispensationsByScId(scDependencyId, lockerAuditTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
        }
      },
      DEP: () => {
        if (moduleTypeSelected === 0) {
          if (selectedProductTransactionTypeToRequest === 0) {
            // Get successful transactions by scDepartmentId
            this.getDispensationsByScDepartmentId(scDepartmentId, successfulTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 1) {
            // Get failed transactions by scDepartmentId
            this.getDispensationsByScDepartmentId(scDepartmentId, failedTransactionTypeId, identityCardNumber, startDate, endDate, -1, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 2) {
            // Get cashless payments refunds by scDepartmentId
            this.getDispensationsByScDepartmentId(scDepartmentId, cashlessPaymentRefundedTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
          if (selectedProductTransactionTypeToRequest === 3) {
            // Get cancelled transactions by scDepartmentId
            this.getDispensationsByScDepartmentId(scDepartmentId, cancelledTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
        } else {
          if (selectedProductTransactionTypeToRequest === 0) {
            // Get locker successful transactions by scDepartmentId
            this.getDispensationsByScDepartmentId(scDepartmentId, lockerSuccessfulTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 1) {
            // Get locker failed transactions by scDepartmentId
            this.getDispensationsByScDepartmentId(scDepartmentId, lockerFailedTransactionTypeIds, identityCardNumber, startDate, endDate, -1, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 2) {
            // Get locker cashless payments refunds by scDepartmentId
            this.getDispensationsByScDepartmentId(
              scDepartmentId,
              lockerCashlessPaymentRefundedTransactionTypeIds,
              identityCardNumber,
              startDate,
              endDate,
              paymentTypeId,
              page,
              pageSize
            );
          }

          if (selectedProductTransactionTypeToRequest === 3) {
            // Get delivery transactions by scDepartmentId
            this.getDispensationsByScDepartmentId(scDepartmentId, lockerDeliveryTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 4) {
            // Get loan transactions by scDepartmentId
            this.getDispensationsByScDepartmentId(scDepartmentId, lockerLoanTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 5) {
            // Get audit transactions by scDepartmentId
            this.getDispensationsByScDepartmentId(scDepartmentId, lockerAuditTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
        }
      },
      PROF: () => {
        if (moduleTypeSelected === 0) {
          if (selectedProductTransactionTypeToRequest === 0) {
            // Get successful transactions by scUserProfiler
            this.getDispensationsByScUserProfileId(scUserProfileId, successfulTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 1) {
            // Get failed transactions by scUserProfiler
            this.getDispensationsByScUserProfileId(scUserProfileId, failedTransactionTypeId, identityCardNumber, startDate, endDate, -1, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 2) {
            // Get cashless payments refunds by scUserProfiler
            this.getDispensationsByScUserProfileId(
              scUserProfileId,
              cashlessPaymentRefundedTransactionTypeIds,
              identityCardNumber,
              startDate,
              endDate,
              paymentTypeId,
              page,
              pageSize
            );
          }
          if (selectedProductTransactionTypeToRequest === 3) {
            // Get cancelled transactions by scUserProfiler
            this.getDispensationsByScUserProfileId(scUserProfileId, cancelledTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
        } else {
          if (selectedProductTransactionTypeToRequest === 0) {
            // Get locker successful transactions by scUserProfiler
            this.getDispensationsByScUserProfileId(scUserProfileId, lockerSuccessfulTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 1) {
            // Get locker failed transactions by scUserProfiler
            this.getDispensationsByScUserProfileId(scUserProfileId, lockerFailedTransactionTypeIds, identityCardNumber, startDate, endDate, -1, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 2) {
            // Get locker cashless payments refunds by scUserProfiler
            this.getDispensationsByScUserProfileId(
              scUserProfileId,
              lockerCashlessPaymentRefundedTransactionTypeIds,
              identityCardNumber,
              startDate,
              endDate,
              paymentTypeId,
              page,
              pageSize
            );
          }

          if (selectedProductTransactionTypeToRequest === 3) {
            // Get delivery transactions by scUserProfiler
            this.getDispensationsByScUserProfileId(scUserProfileId, lockerDeliveryTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 4) {
            // Get loan transactions by scUserProfiler
            this.getDispensationsByScUserProfileId(scUserProfileId, lockerLoanTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 5) {
            // Get audit transactions by scUserProfiler
            this.getDispensationsByScUserProfileId(scUserProfileId, lockerAuditTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
        }
      },
      VM: () => {
        if (moduleTypeSelected === 0) {
          if (selectedProductTransactionTypeToRequest === 0) {
            // Get successful transactions by vendingMachine
            this.getDispensationsByVmId(vendingMachineId, successfulTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 1) {
            // Get failed transactions by vendingMachine
            this.getDispensationsByVmId(vendingMachineId, failedTransactionTypeId, identityCardNumber, startDate, endDate, -1, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 2) {
            // Get cashless payments refunds by vendingMachine
            this.getDispensationsByVmId(vendingMachineId, cashlessPaymentRefundedTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
          if (selectedProductTransactionTypeToRequest === 3) {
            // Get cancelled transactions by vendingMachine
            this.getDispensationsByVmId(vendingMachineId, cancelledTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
        } else {
          if (selectedProductTransactionTypeToRequest === 0) {
            // Get locker successful transactions by vendingMachine
            this.getDispensationsByVmId(vendingMachineId, lockerSuccessfulTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 1) {
            // Get locker failed transactions by vendingMachine
            this.getDispensationsByVmId(vendingMachineId, lockerFailedTransactionTypeIds, identityCardNumber, startDate, endDate, -1, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 2) {
            // Get locker cashless payments refunds by vendingMachine
            this.getDispensationsByVmId(vendingMachineId, lockerCashlessPaymentRefundedTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 3) {
            // Get delivery transactions by vendingMachine
            this.getDispensationsByVmId(vendingMachineId, lockerDeliveryTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 4) {
            // Get loan transactions by vendingMachine
            this.getDispensationsByVmId(vendingMachineId, lockerLoanTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }

          if (selectedProductTransactionTypeToRequest === 5) {
            // Get audit transactions by vendingMachine
            this.getDispensationsByVmId(vendingMachineId, lockerAuditTransactionTypeIds, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize);
          }
        }
      },
      default: () => {},
    };
    // eslint-disable-next-line
    objectSwitchDispensations[currentSelector]() || objectSwitchDispensations["default"]();
  }

  // Function to get transactions by so, payment type and date range
  getDispensationsBySoId(soDependencyId, productTransactionTypeIdsToRequest, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize) {
    const { getPagedVmProductTransactionsByStockOwnerIdAndTransactionTypeIdsAndStorageDateBetween } = this.props;
    getPagedVmProductTransactionsByStockOwnerIdAndTransactionTypeIdsAndStorageDateBetween(
      soDependencyId,
      productTransactionTypeIdsToRequest,
      identityCardNumber,
      startDate.format(),
      endDate.format(),
      paymentTypeId,
      page,
      pageSize,
      ORDER,
      (response) => {
        this.responseQueryService(response);
      }
    );
  }

  // Function to get transactions by sc, payment type and date range
  getDispensationsByScId(scDependencyId, productTransactionTypeIdsToRequest, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize) {
    const { getPagedVmProductTransactionsByScDependencyIdAndTransactionTypeIdsAndStorageDateBetween } = this.props;
    getPagedVmProductTransactionsByScDependencyIdAndTransactionTypeIdsAndStorageDateBetween(
      scDependencyId,
      productTransactionTypeIdsToRequest,
      identityCardNumber,
      startDate.format(),
      endDate.format(),
      paymentTypeId,
      page,
      pageSize,
      ORDER,
      (response) => {
        this.responseQueryService(response);
      }
    );
  }

  // Function to get transactions by sc department, payment type and date range
  getDispensationsByScDepartmentId(scDepartmentId, productTransactionTypeIdsToRequest, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize) {
    const { getPagedVmProductTransactionsByScDepartmentIdAndTransactionTypeIdsAndStorageDateBetween } = this.props;
    getPagedVmProductTransactionsByScDepartmentIdAndTransactionTypeIdsAndStorageDateBetween(
      scDepartmentId,
      productTransactionTypeIdsToRequest,
      identityCardNumber,
      startDate.format(),
      endDate.format(),
      paymentTypeId,
      page,
      pageSize,
      ORDER,
      (response) => {
        this.responseQueryService(response);
      }
    );
  }

  // Function to get transactions by sc userProfiler, payment type and date range
  getDispensationsByScUserProfileId(scUserProfile, productTransactionTypeIdsToRequest, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize) {
    const { getPagedVmProductTransactionsByScUserProfileIdAndTransactionTypeIdsAndStorageDateBetween } = this.props;
    getPagedVmProductTransactionsByScUserProfileIdAndTransactionTypeIdsAndStorageDateBetween(
      scUserProfile,
      productTransactionTypeIdsToRequest,
      identityCardNumber,
      startDate.format(),
      endDate.format(),
      paymentTypeId,
      page - 1,
      pageSize,
      ORDER,
      (response) => {
        this.responseQueryService(response);
      }
    );
  }

  // Function to get transactions by vending machine, payment type and date range
  getDispensationsByVmId(vendingMachineId, productTransactionTypeIdsToRequest, identityCardNumber, startDate, endDate, paymentTypeId, page, pageSize) {
    const { getPagedVmProductTransactionsByVendingMachineIdAndTransactionTypeIdsAndStorageDateBetween } = this.props;
    getPagedVmProductTransactionsByVendingMachineIdAndTransactionTypeIdsAndStorageDateBetween(
      vendingMachineId,
      productTransactionTypeIdsToRequest,
      identityCardNumber,
      startDate.format(),
      endDate.format(),
      paymentTypeId,
      page,
      pageSize,
      ORDER,
      (response) => {
        this.responseQueryService(response);
      }
    );
  }

  // Export button action
  handleOnExport = (fileType) => {
    const {
      soDependencyId,
      scDependencyId,
      scDepartmentId,
      scUserProfileId,
      vendingMachineId,
      moduleTypeSelected,
      successfulTransactionTypeIds,
      failedTransactionTypeId,
      cashlessPaymentRefundedTransactionTypeIds,
      cancelledTypeIds,
      lockerSuccessfulTransactionTypeIds,
      lockerFailedTransactionTypeIds,
      lockerCashlessPaymentRefundedTransactionTypeIds,
      lockerDeliveryTransactionTypeIds,
      lockerLoanTransactionTypeIds,
      lockerAuditTransactionTypeIds,
      identityCardNumber,
      erpProductCode,
      startDate,
      endDate,
      paymentTypeId,
      isExportLoading,
      selectedProductTransactionTypeToRequest,
      isExportRequest,
      soDependenciesToIgnoreFields,
    } = this.state;

    const { getDispensationReportFile, userSessionInfo } = this.props;

    if (!isExportLoading) {
      const DATE_OPTIONS = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };

      const startDateStr = new Date(startDate.format()).toLocaleDateString("es-CO", DATE_OPTIONS);
      const endDateStr = new Date(endDate.format()).toLocaleDateString("es-CO", DATE_OPTIONS);

      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
      const columnHeaders = [
        Strings.generalTerms.customer,
        Strings.machine.machine,
        Strings.scDepartment.department,
        Strings.scCostCenter.costCenter,
        Strings.scUserProfile.userProfile,
        Strings.dispensationReport.userId,
        Strings.generalTerms.name,
        Strings.product.productName,
        Strings.product.productDescription,
        Strings.product.erpCode,
        Strings.dispensationRule.dispensationRule,
        Strings.product.scProductCode,
        Strings.dispensationRule.amount,
        Strings.planogram.slot,
        Strings.dispensationReport.transactionId,
        Strings.dispensationReport.transactionDate,
        Strings.dispensationReport.storageDate,
        Strings.transaction.transactionType,
        Strings.transaction.paymentType,
        Strings.transaction.approvalCode,
        Strings.product.currency,
        Strings.product.scPreTaxPriceTooltip,
        Strings.product.taxValue,
        Strings.product.taxAmount,
        Strings.product.price,
        Strings.vmcashvaulttype.amountReceived,
        Strings.vmcashvaulttype.coinBox,
        Strings.vmcashvaulttype.coinTubes,
        Strings.vmcashvaulttype.billBox,
        Strings.vmcashvaulttype.recycler,
        Strings.vmcashvaulttype.credit,
        Strings.vmcashvaulttype.amountReturned,
        Strings.vmcashvaulttype.coinTubes,
        Strings.vmcashvaulttype.recycler,
        ... !soDependenciesToIgnoreFields.includes(soDependencyId) ? [
          Strings.smartVendingClinical.patientIdentityCardNumber,
          Strings.smartVendingClinical.patientName,
          Strings.smartVendingClinical.patientLastName,
          Strings.smartVendingClinical.location,
          Strings.smartVendingClinical.sector,
          Strings.smartVendingClinical.building,
          Strings.smartVendingClinical.floor
        ] : [
        ]
      ];

      // Start loading animation on Export button
      this.setState({
        isExportLoading: true,
        isSearchButtonDisabled: true,
        isExportRequest: true,
      });

      if (moduleTypeSelected === 0) {
        // Get successful transactions to export
        if (selectedProductTransactionTypeToRequest === 0) {
          this.getDispensationReportFileMethod(
            getDispensationReportFile,
            soDependencyId,
            scDependencyId,
            vendingMachineId,
            scDepartmentId,
            scUserProfileId,
            identityCardNumber,
            erpProductCode,
            successfulTransactionTypeIds,
            startDate,
            endDate,
            userSessionInfo.email,
            TARGETSOURCE,
            userSessionInfo.id,
            paymentTypeId,
            fileType,
            columnHeaders,
            startDateStr,
            endDateStr,
            isExportRequest
          );
        }
        // Get failed transactions to export
        if (selectedProductTransactionTypeToRequest === 1) {
          this.getDispensationReportFileMethod(
            getDispensationReportFile,
            soDependencyId,
            scDependencyId,
            vendingMachineId,
            scDepartmentId,
            scUserProfileId,
            identityCardNumber,
            erpProductCode,
            failedTransactionTypeId,
            startDate,
            endDate,
            userSessionInfo.email,
            TARGETSOURCE,
            userSessionInfo.id,
            paymentTypeId,
            fileType,
            columnHeaders,
            startDateStr,
            endDateStr,
            isExportRequest
          );
        }
        // Get cashless payment refunds to export
        if (selectedProductTransactionTypeToRequest === 2) {
          this.getDispensationReportFileMethod(
            getDispensationReportFile,
            soDependencyId,
            scDependencyId,
            vendingMachineId,
            scDepartmentId,
            scUserProfileId,
            identityCardNumber,
            erpProductCode,
            cashlessPaymentRefundedTransactionTypeIds,
            startDate,
            endDate,
            userSessionInfo.email,
            TARGETSOURCE,
            userSessionInfo.id,
            paymentTypeId,
            fileType,
            columnHeaders,
            startDateStr,
            endDateStr,
            isExportRequest
          );
        }
        // Get cancelled transactions to export
        if (selectedProductTransactionTypeToRequest === 3) {
          this.getDispensationReportFileMethod(
            getDispensationReportFile,
            soDependencyId,
            scDependencyId,
            vendingMachineId,
            scDepartmentId,
            scUserProfileId,
            identityCardNumber,
            erpProductCode,
            cancelledTypeIds,
            startDate,
            endDate,
            userSessionInfo.email,
            TARGETSOURCE,
            userSessionInfo.id,
            paymentTypeId,
            fileType,
            columnHeaders,
            startDateStr,
            endDateStr,
            isExportRequest
          );
        }
      }

      if (moduleTypeSelected === 1) {
        // Get locker successful transactions to export
        if (selectedProductTransactionTypeToRequest === 0) {
          this.getDispensationReportFileMethod(
            getDispensationReportFile,
            soDependencyId,
            scDependencyId,
            vendingMachineId,
            scDepartmentId,
            scUserProfileId,
            identityCardNumber,
            erpProductCode,
            lockerSuccessfulTransactionTypeIds,
            startDate,
            endDate,
            userSessionInfo.email,
            TARGETSOURCE,
            userSessionInfo.id,
            paymentTypeId,
            fileType,
            columnHeaders,
            startDateStr,
            endDateStr,
            isExportRequest
          );
        }
        // Get locker failed transactions to export
        if (selectedProductTransactionTypeToRequest === 1) {
          this.getDispensationReportFileMethod(
            getDispensationReportFile,
            soDependencyId,
            scDependencyId,
            vendingMachineId,
            scDepartmentId,
            scUserProfileId,
            identityCardNumber,
            erpProductCode,
            lockerFailedTransactionTypeIds,
            startDate,
            endDate,
            userSessionInfo.email,
            TARGETSOURCE,
            userSessionInfo.id,
            paymentTypeId,
            fileType,
            columnHeaders,
            startDateStr,
            endDateStr,
            isExportRequest
          );
        }
        // Get locker cashless payment refunded to export
        if (selectedProductTransactionTypeToRequest === 2) {
          this.getDispensationReportFileMethod(
            getDispensationReportFile,
            soDependencyId,
            scDependencyId,
            vendingMachineId,
            scDepartmentId,
            scUserProfileId,
            identityCardNumber,
            erpProductCode,
            lockerCashlessPaymentRefundedTransactionTypeIds,
            startDate,
            endDate,
            userSessionInfo.email,
            TARGETSOURCE,
            userSessionInfo.id,
            paymentTypeId,
            fileType,
            columnHeaders,
            startDateStr,
            endDateStr,
            isExportRequest
          );
        }
        // Get locker delivery transactions to export
        if (selectedProductTransactionTypeToRequest === 3) {
          this.getDispensationReportFileMethod(
            getDispensationReportFile,
            soDependencyId,
            scDependencyId,
            vendingMachineId,
            scDepartmentId,
            scUserProfileId,
            identityCardNumber,
            erpProductCode,
            lockerDeliveryTransactionTypeIds,
            startDate,
            endDate,
            userSessionInfo.email,
            TARGETSOURCE,
            userSessionInfo.id,
            paymentTypeId,
            fileType,
            columnHeaders,
            startDateStr,
            endDateStr,
            isExportRequest
          );
        }
        // Get locker loan transactions to export
        if (selectedProductTransactionTypeToRequest === 4) {
          this.getDispensationReportFileMethod(
            getDispensationReportFile,
            soDependencyId,
            scDependencyId,
            vendingMachineId,
            scDepartmentId,
            scUserProfileId,
            identityCardNumber,
            erpProductCode,
            lockerLoanTransactionTypeIds,
            startDate,
            endDate,
            userSessionInfo.email,
            TARGETSOURCE,
            userSessionInfo.id,
            paymentTypeId,
            fileType,
            columnHeaders,
            startDateStr,
            endDateStr,
            isExportRequest
          );
        }
        // Get locker audit transactions to export
        if (selectedProductTransactionTypeToRequest === 5) {
          this.getDispensationReportFileMethod(
            getDispensationReportFile,
            soDependencyId,
            scDependencyId,
            vendingMachineId,
            scDepartmentId,
            scUserProfileId,
            identityCardNumber,
            erpProductCode,
            lockerAuditTransactionTypeIds,
            startDate,
            endDate,
            userSessionInfo.email,
            TARGETSOURCE,
            userSessionInfo.id,
            paymentTypeId,
            fileType,
            columnHeaders,
            startDateStr,
            endDateStr,
            isExportRequest
          );
        }
      }
    }
  };

  // Functions to show export modal
  showExportReportsModal = () => {
    this.setState({
      isExportModalVisible: true,
      isSearchButtonDisabled: true,
      isExportButtonDisabled: true,
    });
  };

  setExportReportsModalVisible = (isExportModalVisible) => {
    this.setState({
      isExportModalVisible,
    });
  };

  // Function on close export modal
  onCloseModal = () => {
    this.setState({
      isSearchButtonDisabled: false,
      isExportButtonDisabled: false,
    });
  };

  // Function to get all data and export - Export button action
  getDispensationReportFileMethod(
    getDispensationReportFile,
    soDependencyId,
    scDependencyId,
    vendingMachineId,
    scDepartmentId,
    scUserProfileId,
    identityCardNumber,
    erpProductCode,
    productTransactionTypeIdsToRequest,
    startDate,
    endDate,
    target,
    targetsource,
    requestedby,
    paymentTypeId,
    fileType,
    columnHeaders,
    startDateStr,
    endDateStr,
    isExportRequest
  ) {
    getDispensationReportFile(
      soDependencyId,
      scDependencyId,
      vendingMachineId,
      scDepartmentId,
      scUserProfileId,
      null,
      identityCardNumber,
      erpProductCode,
      productTransactionTypeIdsToRequest,
      startDate.format(),
      endDate.format(),
      target,
      targetsource,
      requestedby,
      paymentTypeId,
      ATTRIBUTE,
      ORDER,
      fileType,
      columnHeaders,
      (response) => {
        if (String(response).includes("Error:") && String(response).includes("ERR_CONNECTION_REFUSED")) {
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.reportGenerationError,
          });
          this.setState({
            isExportLoading: false,
            isSearchButtonDisabled: false,
            isExportButtonDisabled: false,
          });
        } else if (response.data && response.data.code === 4009) {
          Modal.warning({
            title: Strings.generalResponses.searchInformation,
            content: `${Strings.generalResponses.haveAsyncRequestInProcess}. ${Strings.generalResponses.notificationOfAutomaticReportSubmissionPart3}`,
          });
          this.setState({
            isExportLoading: false,
            isSearchButtonDisabled: false,
            isExportButtonDisabled: false,
          });
        } else if (response.status === 204 || (String(response).includes("Error:") && String(response).includes("417"))) {
          Modal.warning({
            title: Strings.generalResponses.searchInformation,
            content: Strings.generalResponses.queryResponse,
          });
          this.setState({
            isExportLoading: false,
            isSearchButtonDisabled: false,
            isExportButtonDisabled: false,
          });
        } else if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${Strings.transaction.transactionsReport} ${startDateStr} - ${endDateStr}.csv`);
          document.body.appendChild(link);
          link.click();
          this.setState({
            isExportLoading: false,
            isSearchButtonDisabled: false,
            isExportButtonDisabled: false,
          });
        } else if (response.status === 201) {
          Modal.info({
            title: Strings.generalResponses.notificationOfAutomaticReportSubmissionTitle,
            content: (
              <>
                <br />
                <p>{`${Strings.generalResponses.notificationOfAutomaticReportSubmissionPart1}.`}</p>
                <p>{`${Strings.generalResponses.notificationOfAutomaticReportSubmissionPart2} ${Strings.generalResponses.notificationOfAutomaticReportSubmissionPart3}`}</p>
              </>
            ),
          });
          this.setState({
            isExportLoading: false,
            isSearchButtonDisabled: false,
            isExportButtonDisabled: false,
          });
        }
      }
    );
  }

  // Function to response control
  responseQueryService = (ans) => {
    if (ans?.message === "Network Error") {
      this.setState({
        isTableLoading: false,
        isConnectionError: true,
      });
    }
    const resultQuery =
      (ans?.status || ans?.response?.status,
      {
        200: () => {
          this.setState({
            dispensations: ans.data.data.content,
            totalElements: ans.data.data.totalElements,
            isTableLoading: false,
            stateChange: false,
            isError500: false,
            isConnectionError: false,
            isExportButtonDisabled: false,
            isSearchButtonDisabled: false,
          });
        },
        500: () => {
          this.setState({
            isTableLoading: false,
            isError500: true,
            isExportButtonDisabled: false,
            isSearchButtonDisabled: false,
          });
        },
      });
    resultQuery[ans.status || ans.response.status]();
  };

  // --------------- Functions to render ---------------

  // Render table information
  methodDispensationReportTable(
    dispensations,
    isTableLoading,
    moduleTypeSelected,
    soDependencyId,
    scDependencyId,
    productTransactionTypeIdsToRequest,
    totalElements,
    page,
    stateChange,
    data,
    isExportButtonDisabled,
    isExportLoading,
    isError500,
    isConnectionError,
    pageSize,
    startDate,
    endDate,
    scUserProfileId,
    scDepartmentId,
    vendingMachineId,
    paymentTypeId
  ) {
    if (isConnectionError === true) return <ConnectionErrorComponent />;
    if (isError500 === true) return <Error500 />;
    return (
      <div>
        <DispensationReportTable
          dataSource={dispensations}
          isLoading={isTableLoading}
          moduleType={moduleTypeSelected}
          scDependencyId={scDependencyId}
          transactionType={productTransactionTypeIdsToRequest}
          filterTable={(value, attributeToFilter) => this.getFilteredTransactions(value, attributeToFilter)}
          clearSearchFilterTable={() => this.handleSearchButtonOnClick()}
        />

        <div className="col vertSpace">
          <div className="col-md-6" />
          <div className="col-md-6 vertSpace">
            <Pagination
              size="small"
              showPageSizeOptions
              total={totalElements}
              showSizeChanger
              onChange={this.handlePagination}
              onShowSizeChange={this.handlePagination}
              hideOnSinglePage={false}
              pageSizeOptions={["10", "25", "50", "100"]}
              showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total}  ${Strings.generalTerms.items}`}
              current={page}
              disabled={!!(totalElements === 0 || stateChange === true)}
            />
          </div>
        </div>
      </div>
    );
  }

  // Information to render
  render() {
    const {
      dispensations,
      isExportModalVisible,
      moduleTypeOptions,
      moduleTypeSelected,
      prodTransactionsTypes,
      scDependencyId,
      scDepartments,
      scDepartmentId,
      scUserProfileId,
      scUserProfiles,
      paymentTypes,
      paymentTypeId,
      soDependencyId,
      vendingMachineId,
      isFilterDisabled,
      isTableLoading,
      startDate,
      endDate,
      isDateSelectorDisabled,
      totalElements,
      page,
      isExportButtonDisabled,
      isExportLoading,
      productTransactionTypeIdsToRequest,
      stateChange,
      currentSelector,
      scDependency,
      selectedProductTransactionTypeToRequest,
      isError500,
      isConnectionError,
      pageSize,
      isSearchButtonDisabled,
      lockerUserType,
      selectedLockerUserType,
    } = this.state;

    const {
      permissions: { data },
    } = this.props;

    return (
      <div className="content-container">
        {/* Render the export modal */}
        <ExportModalTransactionsReport
          isExportVisible={isExportModalVisible}
          registersAmount={totalElements}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
          onCloseModal={this.onCloseModal}
        />

        <div className="row vertSpace">
          <Titles title={Strings.transaction.transactionsReport} tooltip={Strings.transaction.transactionsReportTooltip} />
        </div>

        <div className="row vertSpace">
          <div className="col-md-6">
            <SelectGeneral
              text={Strings.machine.wingType}
              tooltip={Strings.machine.wingTooltip}
              options={moduleTypeOptions}
              onChange={this.handleModuleTypeSelector}
              disabled={false}
              defaultValue={moduleTypeSelected}
            />
          </div>

          <div className="col-md-6">
            <SelectGeneral
              text={Strings.transaction.transactionType}
              options={prodTransactionsTypes}
              onChange={this.handleTransactionTypeSelector}
              tooltip={Strings.transaction.transactionTypeTooltip}
              defaultValue={selectedProductTransactionTypeToRequest}
            />
          </div>
        </div>

        <div className="row vertSpace">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        {/* Render filters options */}
        <div className="row vertSpace">
          <SoScVmDependencySelector
            onSoDependencyChange={this.handleSoDependencyChange}
            onScDependencyChange={this.handleScDependencyChange}
            onVendingMachineChange={this.handleVendingMachineChange}
            soDependencyHtmlTag="SO"
            scDependencyHtmlTag="SC"
            vendingMachineHtmlTag="VM"
            hasSc
            hasVm
            hasScAll
            hasVmAll
            soDependencyId={soDependencyId}
            vendingMachineId={vendingMachineId}
          />
          {selectedProductTransactionTypeToRequest === 0 ? (
            <div className="col-md-6 vertSpace">
              <SelectGeneral
                text={Strings.scDepartment.department}
                tooltip={Strings.scDepartment.departmentTooltip}
                options={scDepartments}
                onChange={this.handleScDepartmentChange}
                disabled={isFilterDisabled}
                defaultValue={scDepartmentId}
                name="DEP"
              />
            </div>
          ) : null}
          {selectedProductTransactionTypeToRequest === 0 ? (
            <div className="col-md-6 vertSpace">
              <SelectGeneral
                text={Strings.scUserProfile.userProfile}
                tooltip={Strings.scUserProfile.userProfileTooltip}
                options={scUserProfiles}
                onChange={this.handleScUserProfileChange}
                disabled={isFilterDisabled}
                defaultValue={scUserProfileId}
                name="PROF"
              />
            </div>
          ) : null}
          <div className="col-md-6 vertSpace">
            <RangeDate
              text={Strings.dispensationReport.syncDate}
              tooltip={Strings.dispensationReport.dispensationDateTooltip}
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleDateChange}
              isDisabled={isDateSelectorDisabled}
              format="MMMM Do YYYY, h:mm:ss a"
            />
          </div>

          {selectedProductTransactionTypeToRequest === 0 || selectedProductTransactionTypeToRequest === 2 ? (
            <div className="col-md-6 vertSpace">
              <SelectGeneral
                text={Strings.transaction.paymentType}
                tooltip={Strings.transaction.paymentTypeTooltip}
                options={
                  selectedProductTransactionTypeToRequest === 2
                    ? paymentTypes.filter((item) => item.value === -1 || item.value === 1 || item.value === 7 || item.value === 9)
                    : paymentTypes
                }
                onChange={this.handlePaymentTypeChange}
                disabled={isDateSelectorDisabled}
                defaultValue={paymentTypeId}
                name="PROF"
              />
            </div>
          ) : null}

          {moduleTypeSelected === 1 && (selectedProductTransactionTypeToRequest === 3 || selectedProductTransactionTypeToRequest === 4) ? (
            <div className="col-md-6 vertSpace">
              <SelectGeneral
                text={"TEST"}
                tooltip={Strings.transaction.paymentTypeTooltip}
                options={lockerUserType}
                onChange={this.handleLockerUserTypeChange}
                defaultValue={selectedLockerUserType}
              />
            </div>
          ) : null}

          {/* Render buttons Search and Export */}
          {selectedProductTransactionTypeToRequest !== 0 ? <div className="col-md-6 vertSpace" /> : null}
          <div className="col-md-1 vertSpace" />
          <div className="col-md-2 vertSpace" style={{ alignItems: "center", margin: "0" }}>
            <Tooltip title={Strings.transaction.getTransactionsToolTip} placement="bottom">
              <Button
                type="primary"
                size="large"
                icon="search"
                onClick={this.handleSearchButtonOnClick}
                disabled={!!(currentSelector === null || scDependency === null || isSearchButtonDisabled === true || selectedProductTransactionTypeToRequest === null)}
                loading={isTableLoading}
              >
                {Strings.generalTerms.check}
              </Button>
            </Tooltip>
          </div>
          <div className="col-md-2 vertSpace" style={{ alignItems: "center", margin: "0" }}>
            <ValidatePermissionForComponent permission="PRIVILEGE DISPENSATION REPORT GET" permissions={data}>
              <Tooltip title={Strings.transaction.getTransactionsToolTip} placement="bottom">
                <ExportButton isDisabled={isExportButtonDisabled} onClick={() => this.showExportReportsModal()} loading={isExportLoading} />
              </Tooltip>
            </ValidatePermissionForComponent>
          </div>
          <div className="col-md-1 vertSpace" />
        </div>

        {/* Render table */}
        <div className="row vertSpace">
          <div className="col-12">
            <Divider orientation="left">
              <h3>{Strings.transaction.transactions}</h3>
            </Divider>
            <QueryResponse
              dataSourceLength={dispensations.length}
              isLoading={isTableLoading}
              callback={this.methodDispensationReportTable(
                dispensations,
                isTableLoading,
                moduleTypeSelected,
                soDependencyId,
                scDependencyId,
                productTransactionTypeIdsToRequest,
                totalElements,
                page,
                stateChange,
                data,
                isExportButtonDisabled,
                isExportLoading,
                isError500,
                isConnectionError,
                pageSize,
                startDate,
                endDate,
                scUserProfileId,
                scDepartmentId,
                vendingMachineId,
                paymentTypeId
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userSessionInfo: state.users.userSessionObj.data,
    soDependencies: state.soDependenciesType0,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  getTransactionCategories,
  getProductTransactionTypesByTransactionCategoryId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  getAllPaymentTypes,
  getDispensationReportFile,
  getPagedVmProductTransactionsByStockOwnerIdAndTransactionTypeIdsAndStorageDateBetween,
  getPagedVmProductTransactionsByScDependencyIdAndTransactionTypeIdsAndStorageDateBetween,
  getPagedVmProductTransactionsByVendingMachineIdAndTransactionTypeIdsAndStorageDateBetween,
  getPagedVmProductTransactionsByScDepartmentIdAndTransactionTypeIdsAndStorageDateBetween,
  getPagedVmProductTransactionsByScUserProfileIdAndTransactionTypeIdsAndStorageDateBetween,
  getUserById,
})(TransactionsReports);
