import React from "react";

const Cash = ({ width, height, fill }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 300.078 300.078"
      width={width}
      xmlSpace="preserve"
      fill={fill}
    >
      <g>
        <g>
          <path
            d="M230.997,163.103l-6.846-6.846c3.452-3.452,3.452-9.068,0-12.52c-3.345-3.345-9.174-3.345-12.52,0
			l-6.846-6.846l4.841-4.841c3.452-3.452,3.452-9.068,0-12.52c-3.345-3.345-9.174-3.345-12.52,0l-4.841,4.841l-6.846-6.846
			l9.683-9.683c3.452-3.452,3.452-9.068,0-12.52s-9.068-3.452-12.52,0l-9.683,9.683l-6.846-6.846l4.841-4.841
			c1.675-1.675,2.59-3.897,2.59-6.26s-0.915-4.585-2.59-6.26c-3.452-3.452-9.068-3.452-12.52,0l-4.841,4.841l-6.846-6.846
			l4.841-4.841c7.223-7.223,18.988-7.223,26.211,0c2.842,2.842,4.56,6.381,5.17,10.07c6.458-2.164,13.894-0.687,19.036,4.454
			c5.141,5.141,6.623,12.573,4.454,19.036c3.689,0.61,7.228,2.329,10.07,5.17c5.141,5.141,6.623,12.573,4.454,19.036
			c3.689,0.61,7.228,2.329,10.07,5.17C238.225,144.115,238.225,155.88,230.997,163.103z"
          />
          <path
            d="M156.374,219.194l-13.865,13.861L108.74,247.53l-49.008,44.104l6.478,7.194l16.46-14.814
			c1.966,0.992,3.921,2.014,5.902,2.9l3.96-8.831c-0.707-0.32-1.409-0.707-2.116-1.041l23.606-21.248l34.01-14.572l15.182-15.187
			L156.374,219.194z"
          />
          <path
            d="M124.673,51.168l-73.389,73.389l16.872,16.872l16.586-16.039l0.058-0.058
			c3.98-3.98,10.457-3.98,14.437,0c3.428,3.428,3.965,8.845,1.278,12.878l-14.233,21.34l89.278,89.278l73.389-73.389L124.673,51.168
			z M167.731,167.615c-9.726,9.726-25.499,9.726-35.23,0c-9.726-9.726-9.726-25.499,0-35.23c9.726-9.726,25.499-9.726,35.23,0
			C177.457,142.111,177.457,157.884,167.731,167.615z"
          />
          <path
            d="M150.119,179.752c-7.949,0-15.424-3.098-21.04-8.719l0,0l0,0l0,0
			c-5.621-5.616-8.719-13.091-8.719-21.035c0-7.954,3.098-15.424,8.719-21.04c5.616-5.621,13.086-8.719,21.04-8.719
			c7.949,0,15.42,3.098,21.035,8.719c5.621,5.616,8.719,13.086,8.719,21.04c0,7.949-3.098,15.419-8.719,21.035
			C165.538,176.658,158.068,179.752,150.119,179.752z M150.119,129.926c-5.359,0-10.399,2.091-14.195,5.882
			c-3.796,3.791-5.882,8.83-5.882,14.195c0,5.354,2.091,10.394,5.882,14.19l0,0c3.791,3.791,8.831,5.882,14.195,5.882
			c5.354,0,10.394-2.091,14.19-5.882s5.882-8.831,5.882-14.19c0-5.359-2.091-10.399-5.882-14.195
			C160.518,132.012,155.473,129.926,150.119,129.926z"
          />
          <path
            d="M78.308,171.517l8.056,5.369l22.207-33.308c5.238-7.862,4.193-18.416-2.488-25.097
			c-7.756-7.756-20.377-7.756-28.133,0l-29.125,29.125l-9.683,48.413l-15.289,15.298c-0.412-0.847-0.876-1.67-1.268-2.522
			l-8.792,4.057c0.886,1.912,1.883,3.786,2.856,5.669L1.452,233.718l6.846,6.846l39.766-39.766l9.683-48.413L84.8,125.331
			c3.98-3.98,10.457-3.98,14.437,0c3.428,3.428,3.965,8.845,1.278,12.878L78.308,171.517z"
          />
          <path
            d="M19.811,97.625l-8.981-3.612c-2.527,6.289-4.648,12.81-6.294,19.37l9.387,2.353
			C15.468,109.597,17.448,103.502,19.811,97.625z"
          />
          <path
            d="M38.648,64.621L30.97,58.72c-4.125,5.364-7.916,11.072-11.271,16.959l8.404,4.793
			C31.246,74.977,34.794,69.637,38.648,64.621z"
          />
          <path
            d="M1.946,174.001c1.07,6.676,2.614,13.352,4.594,19.835l9.257-2.827
			c-1.849-6.061-3.292-12.297-4.294-18.537L1.946,174.001z"
          />
          <path
            d="M9.716,149.998c0-5.185,0.29-10.409,0.852-15.531l-9.624-1.06c-0.605,5.471-0.91,11.048-0.91,16.61
			c0.005,1.249,0.019,2.484,0.048,3.718l9.683-0.228C9.736,152.336,9.721,151.159,9.716,149.998z"
          />
          <path
            d="M274.956,66.684c-3.762-5.626-7.945-11.043-12.418-16.107l-7.252,6.41
			c4.193,4.74,8.104,9.813,11.624,15.076L274.956,66.684z"
          />
          <path
            d="M65.624,37.854l-5.834-7.727c-5.393,4.072-10.569,8.559-15.376,13.323l6.817,6.875
			C55.733,45.861,60.574,41.669,65.624,37.854z"
          />
          <path
            d="M290.371,143.568l9.673-0.445c-0.305-6.773-1.08-13.58-2.29-20.212l-9.523,1.728
			C289.364,130.855,290.08,137.217,290.371,143.568z"
          />
          <path
            d="M292.738,103.177c-2.111-6.429-4.682-12.776-7.644-18.862l-8.709,4.236
			c2.774,5.698,5.18,11.629,7.155,17.642L292.738,103.177z"
          />
          <path
            d="M98.743,19.297l-3.544-9.01c-6.289,2.474-12.471,5.408-18.378,8.719l4.74,8.443
			C87.075,24.352,92.861,21.607,98.743,19.297z"
          />
          <path
            d="M154.669,9.673c6.342,0.203,12.713,0.842,18.944,1.893l1.607-9.552
			C168.564,0.896,161.752,0.213,154.979,0L154.669,9.673z"
          />
          <path
            d="M221.218,271.093l4.919,8.342c5.819-3.428,11.469-7.291,16.79-11.484l-5.993-7.601
			C231.951,264.266,226.669,267.883,221.218,271.093z"
          />
          <path
            d="M226.5,32.18c5.311,3.447,10.433,7.296,15.231,11.435l6.328-7.339
			c-5.137-4.42-10.607-8.535-16.281-12.219L226.5,32.18z"
          />
          <path
            d="M192.126,15.991c6.047,1.893,12.011,4.222,17.743,6.918l4.125-8.758
			c-6.124-2.89-12.51-5.374-18.973-7.397L192.126,15.991z"
          />
          <path
            d="M273.552,216.943l8.511,4.623c3.234-5.95,6.09-12.181,8.482-18.508l-9.058-3.423
			C279.25,205.556,276.583,211.38,273.552,216.943z"
          />
          <path
            d="M286.982,181.403l9.436,2.154c1.51-6.589,2.576-13.352,3.176-20.101l-9.644-0.862
			C289.393,168.912,288.391,175.24,286.982,181.403z"
          />
          <path
            d="M251.055,247.578l6.962,6.734c4.701-4.856,9.116-10.084,13.13-15.555l-7.799-5.732
			C259.58,238.143,255.45,243.037,251.055,247.578z"
          />
          <path
            d="M186.186,285.718l2.488,9.363c6.541-1.733,13.018-3.936,19.263-6.55l-3.733-8.937
			C198.367,282.034,192.306,284.096,186.186,285.718z"
          />
          <path
            d="M108.198,294.142c6.512,1.883,13.202,3.336,19.888,4.328l1.409-9.581
			c-6.255-0.92-12.515-2.285-18.605-4.052L108.198,294.142z"
          />
          <path
            d="M114.598,4.149l2.28,9.407c6.139-1.486,12.447-2.571,18.765-3.219l-0.992-9.634
			C127.897,1.399,121.153,2.561,114.598,4.149z"
          />
          <path
            d="M150.114,290.395l-1.646-0.01l-0.087,9.683l1.733,0.01c6.211,0,12.461-0.382,18.576-1.138
			l-1.191-9.605C161.781,290.037,155.933,290.395,150.114,290.395z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Cash;
