import axios from "axios";

import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/paymenttype";

export function getAllPaymentTypes(callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
      });
  };
}
