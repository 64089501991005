import React from 'react';
import Strings from '../../../systemVariables/languageStrings';

const UserInformation = props => {
  const { user } = props;
  return (
    <>
      <div className="row">
        <div className="col-6 col-md-4">{Strings.generalTerms.name}:</div>
        <div className="col-6 col-md-5">
          {user.firstName} {user.middleName} {user.lastName1} {user.lastName2}
        </div>
      </div>
      
      <div className="row">
        <div className="col-6 col-md-4">{user.identityCardTypeName}:</div>
        <div className="col-6 col-md-5">{user.identityCardNumber}</div>
      </div>

      {user.email ? 
      <div className="row">
        <div className="col-6 col-md-4">{Strings.generalTerms.email}:</div>
        <div className="col-6 col-md-5">{user.email}</div>
      </div>
      : ""}

      {/* <div className="row">
        <div className="col-6 col-md-4">{Strings.users.dateOfBirth}:</div>
        <div className="col-6 col-md-5">{user.birthDate}</div>
      </div> */}

      {user.address ? 
      <div className="row">
        <div className="col-6 col-md-4">{Strings.generalTerms.address}:</div>
        <div className="col-6 col-md-5">{user.address}</div>
      </div>
      : ""}

      <div className="row">
        <div className="col-6 col-md-4">{Strings.generalTerms.country}:</div>
        <div className="col-6 col-md-5">{user.countryName}</div>
      </div>

      <div className="row">
        <div className="col-6 col-md-4">{Strings.generalTerms.province}:</div>
        <div className="col-6 col-md-5">{user.provinceName}</div>
      </div>

      <div className="row">
        <div className="col-6 col-md-4">{Strings.generalTerms.city}:</div>
        <div className="col-6 col-md-5">{user.cityName}</div>
      </div>

      {user.phone1 ? 
      <div className="row">
        <div className="col-6 col-md-4">{Strings.users.phone1}:</div>
        <div className="col-6 col-md-5">{user.phone1}</div>
      </div>
      : ""}

      {user.phone2 ? 
      <div className="row">
        <div className="col-6 col-md-4">{Strings.users.phone2}:</div>
        <div className="col-6 col-md-5">{user.phone2}</div>
      </div>
      : ""}
    </>
  );
};
export default UserInformation;
