// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Components
import { Button, Divider, Icon, Input, Modal, Table } from "antd";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import Select from "react-select";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";
import Titles from "../../GenericComponents/titles";

// Actions
import { addScproductInformationAction, listAllCurrencyCodesByCountry, listAllProductTaxTypeAction, listUnassignedSoProducts } from "../../../actions/index";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

const { Column, ColumnGroup } = Table;

class AssignScProductInformation extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      soDependencyId: location.props ? location.props.soDependencyId : null,
      soDependencyName: location.props ? location.props.soDependencyName : null,
      scDependencyId: location.props ? location.props.scDependencyId : null,
      scDependencyName: location.props ? location.props.scDependencyName : null,
      soCountryName: location.props ? location.props.soCountryName : null,
      scCountryName: location.props ? location.props.scCountryName : null,
      scCurrencyCodeName: location.props ? location.props.scCurrencyCodeName : null,
      soProducts: [],
      scProducts: [],
      searchText: "",
      loadingAddButton: false,
      isLoading: true,
      redirectBackToScProductInfoList: false,
      isSaveButtonDisabled: true,
    };
  }

  componentDidMount() {
    const { soDependencyId, scDependencyId } = this.state;
    const { listUnassignedSoProducts, listAllProductTaxTypeAction, listAllCurrencyCodesByCountry, location } = this.props;
    if (soDependencyId != null) {
      listUnassignedSoProducts(soDependencyId, scDependencyId, (response) => {
        const soProductList = [];
        response.data.data.forEach((item) => {
          const soProduct = {
            soCurrencyCode: item.currencyCode ? item.currencyCode : null,
            soCurrencySymbol: item.currencySymbol ? item.currencySymbol : null,
            erpProductCode: item.erpProductCode,
            isEnabled: item.isEnabled,
            name: item.name,
            soPreTaxPrice: item.preTaxPrice ? item.preTaxPrice : null,
            minAgeRequired: item.scMinAgeRequired ? item.scMinAgeRequired : null,
            productTaxType: item.producttaxtype,
            id: item.id,
          };
          soProductList.push(soProduct);
        });

        this.setState({
          soProducts: soProductList,
          isLoading: false,
        });
      });
      listAllProductTaxTypeAction();
      listAllCurrencyCodesByCountry(location.props.scCountryName);
    }
  }

  soProductClickHandleEvent(row) {
    let { soProducts } = this.state;
    const { scProducts } = this.state;
    scProducts.push(row);
    soProducts = _.differenceBy(soProducts, scProducts, "erpProductCode");

    this.setState({ scProducts, soProducts, isSaveButtonDisabled: scProducts.length > 0 ? false : true });
  }

  removeScProductHandleEvent(e, row) {
    const { soProducts } = this.state;
    let { scProducts } = this.state;

    if (soProducts.filter((item) => item.erpProductCode === row.erpProductCode).length < 1) {
      soProducts.push(row);
      scProducts = _.differenceBy(scProducts, soProducts, "erpProductCode");

      this.setState({ scProducts, soProducts, isSaveButtonDisabled: scProducts.length > 0 ? false : true });
    }
  }

  getColumnSearchProps = (dataIndex, text, searchText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} by ${text}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />,
  });

  handleSearch = (selectedKeys, comfirm) => {
    comfirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  textHandleEvent = (e, scProdInfo) => {
    scProdInfo[e.target.id] = e.target.value;
    if (scProdInfo[e.target.id] !== "preTaxPrice") {
      if (scProdInfo[e.target.id].length === 0) {
        scProdInfo[e.target.id] = null;
      }
    }
  };

  onTaxSelect = (e, scProdInfo) => {
    scProdInfo.productTaxTypeId = e.value;
  };

  onCurrencySelect = (e, scProdInfo) => {
    scProdInfo.scCurrencyCode = e.alphaCode;
    scProdInfo.scCurrencySymbol = e.symbol;
  };

  addScProducts() {
    const { soCountryName, scCountryName, scProducts, scDependencyId } = this.state;
    this.setState({ loadingAddButton: true });
    const scProductInfoToAdd = [];
    scProducts.forEach((item) => {
      const scProductToAdd = {
        scdependencyId: scDependencyId,
        soproductId: item.id,
        soProductName: item.name,
        soProductCode: item.erpProductCode,
        scProductName: item.scProductName ? item.scProductName : null,
        scProductCode: item.scProductCode ? item.scProductCode : null,
        preTaxPrice: item.preTaxPrice ? item.preTaxPrice : null,
        scCurrencyCode: item.scCurrencyCode ? item.scCurrencyCode : null,
        scCurrencySymbol: item.scCurrencySymbol ? item.scCurrencySymbol : null,
        newPreTaXprice: null,
        productTaxTypeId: item.productTaxTypeId,
        minAgeRequired: item.scMinAgeRequired ? item.scMinAgeRequired : null,
        closeDate: null,
        isEnabled: 1,
      };
      scProductInfoToAdd.push(scProductToAdd);
    });
    if (scCountryName != soCountryName) {
      this.setState({ isSaveButtonDisabled: false });
      if (scProductInfoToAdd.some((item) => item.preTaxPrice == null)) {
        Modal.error({
          title: Strings.generalResponses.invalidParameters,
          content: `${Strings.product.preTaxPriceErrorMsg1}.`,
        });
        this.setState({ loadingAddButton: false });
      } else if (scProductInfoToAdd.some((item) => item.productTaxTypeId == null)) {
        Modal.error({
          title: Strings.generalResponses.invalidParameters,
          content: `${Strings.product.scTaxValueErrorMsg1}.`,
        });
        this.setState({ loadingAddButton: false });
      } else if (scProductInfoToAdd.some((item) => item.scCurrencyCode == null)) {
        Modal.error({
          title: Strings.generalResponses.invalidParameters,
          content: `${Strings.product.scCurrencyCodeErrorMsg1}.`,
        });
        this.setState({ loadingAddButton: false });
      } else {
        this.addScProductInformations(scProductInfoToAdd);
      }
    } else {
      if (scProductInfoToAdd.some((item) => item.preTaxPrice != null && item.productTaxTypeId == null)) {
        Modal.error({
          title: Strings.generalResponses.invalidParameters,
          content: `${Strings.product.scTaxValueErrorMsg2}.`,
        });
        this.setState({ loadingAddButton: false });
      } else if (scProductInfoToAdd.some((item) => item.preTaxPrice != null && item.scCurrencyCode == null)) {
        Modal.error({
          title: Strings.generalResponses.invalidParameters,
          content: `${Strings.product.scCurrencyCodeErrorMsg2}.`,
        });
        this.setState({ loadingAddButton: false });
      } else if (scProductInfoToAdd.some((item) => item.scCurrencyCode != null && item.preTaxPrice == null)) {
        Modal.error({
          title: Strings.generalResponses.invalidParameters,
          content: `${Strings.product.scCurrencyCodeErrorMsg3}.`,
        });
        this.setState({ loadingAddButton: false });
      } else if (scProductInfoToAdd.some((item) => item.productTaxTypeId != null && item.preTaxPrice == null)) {
        Modal.error({
          title: Strings.generalResponses.invalidParameters,
          content: `${Strings.product.scTaxValueErrorMsg3}.`,
        });
        this.setState({ loadingAddButton: false });
      } else {
        this.addScProductInformations(scProductInfoToAdd);
      }
    }
    this.setState({ loadingAddButton: false });
  }

  addScProductInformations(scProductInfoToAdd) {
    const { addScproductInformationAction } = this.props;
    addScproductInformationAction(scProductInfoToAdd, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        this.setState({ loadingAddButton: false });
      } else if (response.data.code === 4008) {
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({ loadingAddButton: false });
      } else if (response.data && response.data.status === "SUCCESS") {
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
        });
        this.setState({ loadingAddButton: false, isSaveButtonDisabled: true, scProducts: [] });
      } else {
        // Other error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({ loadingAddButton: false });
      }
    });
  }

  noScWarning() {
    Modal.warning({
      title: Strings.machine.noSoSelected,
      onOk: this.handleOkNoSCWarning,
      content: Strings.machine.noSoSelectedMsg,
    });
  }

  handleOkNoSCWarning = () => {
    this.setState({ redirectBackToScProductInfoList: true });
  };

  // Information to render
  renderSoProductsTable() {
    const { soProducts, searchText, isLoading } = this.state;
    const columns = [
      {
        title: <TextWithInfoTooltip name={Strings.product.erpCode} tooltip={Strings.product.erpCodeTooltip} />,
        dataIndex: "erpProductCode",
        align: "center",
        width: "40%",
        ...this.getColumnSearchProps("erpProductCode", Strings.product.erpCode, searchText),
      },
      {
        title: <TextWithInfoTooltip name={Strings.generalTerms.name} tooltip={Strings.product.soProductNameTooltip} />,
        dataIndex: "name",
        align: "center",
        width: "60%",
        ...this.getColumnSearchProps("name", Strings.generalTerms.name, searchText),
      },
    ];

    return (
      <Table
        dataSource={soProducts}
        columns={columns}
        pagination={false}
        loading={isLoading}
        bordered
        size="default"
        scroll={{ y: 250 }}
        rowKey="erpProductCode"
        onRowClick={(e) => this.soProductClickHandleEvent(e)}
      />
    );
  }

  renderScProductsTable(data) {
    const { prodTaxType, currencyCodesByCountry } = this.props;
    const { scCountryName, scCurrencyCodeName, scProducts, isLoading } = this.state;

    return (
      <Table dataSource={scProducts} bordered loading={isLoading} pagination={false} scroll={{ y: 300 }} rowKey="erpProductCode">
        <ColumnGroup title={Strings.product.soProduct} key="soProductId">
          <Column
            title={<TextWithInfoTooltip name={Strings.product.erpCode} tooltip={Strings.product.erpCodeTooltip} />}
            key={"erpProductCode"}
            dataIndex="erpProductCode"
            align="center"
            width="10%"
          />
          <Column
            title={<TextWithInfoTooltip name={Strings.generalTerms.name} tooltip={Strings.product.soProductNameTooltip} />}
            key={"name"}
            dataIndex="name"
            align="center"
            width="10%"
          />
        </ColumnGroup>
        <ColumnGroup title={Strings.product.scProductInfo} key="scProductInformationId">
          <Column
            title={<TextWithInfoTooltip name={Strings.generalTerms.nameToAssign} tooltip={Strings.product.scProductNameTooltip} />}
            key={"name2"}
            align="center"
            width="16%"
            render={(row) => <Input id="scProductName" placeholder={Strings.generalTerms.name} onChange={(e) => this.textHandleEvent(e, row)} />}
          />
          <Column
            title={<TextWithInfoTooltip name={Strings.generalTerms.codeToAssign} tooltip={Strings.product.scProductCodeToltip} />}
            key={"codeToAssign"}
            align="center"
            width="11%"
            render={(row) => <Input id="scProductCode" placeholder={Strings.generalTerms.code} onChange={(e) => this.textHandleEvent(e, row)} />}
          />
          <Column
            align="center"
            key={"scPreTaxPrice"}
            width="11%"
            title={<TextWithInfoTooltip name={Strings.product.scPreTaxPrice} tooltip={Strings.product.scPreTaxPriceTooltip} />}
            render={(row) => <Input id="preTaxPrice" placeholder={Strings.generalTerms.price} onChange={(e) => this.textHandleEvent(e, row)} />}
          />
          <Column
            align="center"
            key={"taxValue"}
            width="12%"
            title={<TextWithInfoTooltip name={Strings.product.taxValue} tooltip={Strings.product.scTaxValueTooltip} />}
            render={(row) => (
              <Select
                id="productTaxTypeId"
                options={prodTaxType.filter((item) => item.countryName === scCountryName)}
                placeholder={Strings.generalTerms.select}
                onChange={(e) => this.onTaxSelect(e, row)}
              />
            )}
          />
          <Column
            align="center"
            key={"currency"}
            width="12%"
            title={<TextWithInfoTooltip name={Strings.product.currency} tooltip={Strings.product.currencyTooltip} />}
            render={(row) => (
              <Select
                id="currencyId"
                options={currencyCodesByCountry.filter((item) => item.alphaCode === scCurrencyCodeName)}
                placeholder={Strings.generalTerms.select}
                onChange={(e) => this.onCurrencySelect(e, row)}
              />
            )}
          />
          {ValidatePermissionForComponentPart("PRIVILEGE SET MINIMUM AGE REQUIRED", data) ? (
            <Column
              title={<TextWithInfoTooltip name={Strings.product.minAgeRequired} tooltip={Strings.product.minAgeRequiredTooltip} />}
              key={"minAgeRequired"}
              align="center"
              width="10%"
              render={(row) => <Input id="scMinAgeRequired" placeholder={Strings.generalTerms.age} onChange={(e) => this.textHandleEvent(e, row)} />}
            />
          ) : null}
          <Column
            title={Strings.generalTerms.remove}
            key={"remove"}
            align="center"
            width="8%"
            render={(row) => <Icon style={{ cursor: "pointer", fontSize: 20 }} type="close-circle" theme="twoTone" onClick={(e) => this.removeScProductHandleEvent(e, row)} />}
          />
        </ColumnGroup>
      </Table>
    );
  }

  render() {
    const {
      permissions: { data },
    } = this.props;
    const { redirectBackToScProductInfoList, scDependencyId, soDependencyName, scDependencyName, loadingAddButton, scProducts, isSaveButtonDisabled } = this.state;

    // Redirects to VMList from No SC Modal Warning
    if (redirectBackToScProductInfoList) {
      return <Redirect to="/scProductInformation" />;
    }
    // Shows No SC Modal Warning
    if (!scDependencyId) {
      return <div>{this.noScWarning()}</div>;
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.product.assignProductsToClient} tooltip={Strings.product.assignProductsToClientTooltip} />
        </div>

        <div className="row">
          <Divider orientation="left">
            <h3>{Strings.generalTerms.generalParameters} </h3>
          </Divider>
        </div>

        <div className="vertSpace row">
          <div className="col-md-12">
            <div className="  col-md-6">
              <div className="col-md-4">
                <span>
                  {Strings.soDependency.directClient}
                  <b>:</b>
                </span>
              </div>
              <div className="col-md-8">
                <h4>{soDependencyName}</h4>
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-md-4">
                <span>
                  {Strings.scDependency.indirectClient}
                  <b>:</b>
                </span>
              </div>
              <div className="col-md-8">
                <h4>{scDependencyName}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <Divider orientation="left">
            <h3>{Strings.product.productsAssignment} </h3>
          </Divider>
        </div>

        <div className="vertSpace row">
          <h3>
            1. {Strings.product.availableProducts}: {Strings.product.noProductSelectedMsg2}.{" "}
          </h3>
        </div>

        <div className="vertSpace row">
          <div className="col-12">
            {this.renderSoProductsTable()}
            <br />
          </div>
        </div>

        {scProducts.length !== 0 ? (
          <div>
            <div className="vertSpace row">
              <h3>2. {Strings.product.productParameterization}:</h3>
            </div>
            <div className="vertSpace row">
              <div className="col-12">{this.renderScProductsTable(data)}</div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row justify-content-end">
          <div className="vertSpace col-6 col-md-3">
            <ReturnButton link="/scProductInformation" />
          </div>
          <div className="vertSpace col-6 col-md-3">
            <SaveButton isDisabled={isSaveButtonDisabled} onClick={(e) => this.addScProducts(e)} loading={loadingAddButton} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    soProduct: state.soProduct.listUnassignedSoProductObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    prodTaxType: state.productTaxType,
    currencyCodesByCountry: state.currencyCodeByCountry,
  };
}

export default connect(mapStateToProps, {
  listUnassignedSoProducts,
  listAllProductTaxTypeAction,
  listAllCurrencyCodesByCountry,
  addScproductInformationAction,
})(AssignScProductInformation);
