import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/wingtype";

export const LIST_WINGTYPE  = 'LIST_WINGTYPE';

export function listWingTypes(callback){
  return (dispatch) => {
    axios.get(host+ port + path + version + resource,{
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    .then((response) => {
      dispatch( { type: LIST_WINGTYPE, payload: response.data } );
      callback(response);
    })
    .catch(function (error){
      callback(error)
      return error;
    });
  }
}

export function getWingTypeById(id, callback){
  return () => {
      axios.get(host+ port + path  + version + resource, {
          params : {
            wingtypeid: id
          },
          headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
          },
      })
      .then((response) => {
          callback(response)
      })
      .catch(function (error){
          return error;
      });
  }   
}

export function getModuleTypesByMachine(machineId, callback){
  return () => {
      axios.get(host+ port + path  + version + resource, {
          params : {
            machineid: machineId
          },
          headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
          },
      })
      .then((response) => {
          callback(response)
      })
      .catch(function (error){
          return error;
      });
  }   
}
