// Dependencies
import React, { Component } from "react";
import { Modal, Button } from "antd";

// Components
import Strings from "../../../../systemVariables/languageStrings";
import Loading from "../../../GenericComponents/loadingComponent/loading";

class ResponseModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addResponseTitle: "",
      addResponseMessage: "",
      updateResponseTitle: "",
      updateResponseMessage: "",
      deleteResponseTitle: "",
      deleteResponseMessage: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      addResponseTitle: nextProps.addResponseTitle,
      addResponseMessage: nextProps.addResponseMessage,
      updateResponseTitle: nextProps.updateResponseTitle,
      updateResponseMessage: nextProps.updateResponseMessage,
      deleteResponseTitle: nextProps.deleteResponseTitle,
      deleteResponseMessage: nextProps.deleteResponseMessage,
    });
  }

  handleOk = () => {
    const { setVisible } = this.props;
    setVisible(false, "");

    this.setState({
      addResponseTitle: "",
      addResponseMessage: "",
      updateResponseTitle: "",
      updateResponseMessage: "",
      deleteResponseTitle: "",
      deleteResponseMessage: "",
    });
  };

  render() {
    const { isVisible } = this.props;
    const { addResponseTitle, updateResponseTitle, deleteResponseTitle, addResponseMessage, updateResponseMessage, deleteResponseMessage } = this.state;

    if (isVisible !== true) {
      return "";
    } else {
      return (
        <Modal
          visible={isVisible}
          title={Strings.generalResponses.answer}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              {Strings.generalTerms.accept}
            </Button>,
          ]}
        >
          {addResponseTitle != null || updateResponseTitle != null || deleteResponseTitle ? (
            <div className="col-12 slotInfoRow">
              {addResponseTitle != null && (
                <div className="row">
                  <p>
                    {addResponseTitle}: {addResponseMessage}
                  </p>
                </div>
              )}

              {updateResponseTitle != null && (
                <div className="row">
                  <p>
                    {updateResponseTitle}: {updateResponseMessage}
                  </p>
                </div>
              )}

              {deleteResponseTitle != null && (
                <div className="row">
                  <p>
                    {deleteResponseTitle}: {deleteResponseMessage}
                  </p>
                </div>
              )}
            </div>
          ) : (
            <Loading />
          )}
        </Modal>
      );
    }
  }
}

export default ResponseModal;
