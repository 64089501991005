// Dependencies
import { Divider, Modal } from "antd";
import React, { Component } from "react";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Components
import { generateUUID } from "../../../Utils/GenerateUUID";
import { resizeImage } from "../../../Utils/ResizeImage";
import { s3Bucket } from "../../../systemVariables/serverInformation";
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import Titles from "../../GenericComponents/titles";
import { IsProductValid } from "./ProductValidator";
import SoProductForm from "./SoProductForm";

// Actions
import { editSoProductAction, getAllSoProductTypes, getFile, getSoProductById, postFile } from "../../../actions";

// Language localization
import { locateSoProductTypeName } from "../../../Utils/LanguageLocalization/soProductTypeLocalization";
import Strings from "../../../systemVariables/languageStrings";

class EditSoProduct extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      productToEdit: {
        id: null,
        soDependencyId: null,
        soProductTypeId: null,
        erpProductCode: null,
        name: null,
        description: null,
        details: [],
        mfgCode: null,
        productTaxTypeId: null,
        currencyCodeId: null,
        preTaxCost: null,
        preTaxPrice: null,
        newPreTaxPrice: null,
        closeDate: null,
        weight: null,
        height: null,
        width: null,
        depth: null,
        hasStopByHome: null,
        hasStopByTof: null,
        hasStopByMems: null,
        hasStopByCv: null,
        bucketName: s3Bucket,
        productImgPath: null,
      },

      image: {
        loading: false,
        image: null,
        url: null,
        modified: false,
        value: null,
      },

      count: 0,
      soProductTypes: [],
      isSaveButtonLoading: false,
      redirectBackToSoProd: false,
      isFormLoading: true,

      soCountryName: location.props ? location.props.soCountryName : null,
    };
  }

  componentDidMount() {
    const { getAllSoProductTypes, location, getSoProductById, getFile } = this.props;

    getAllSoProductTypes((response) => {
      this.setState({
        soProductTypes: response.data.data.map((item) => ({
          value: item.id,
          label: locateSoProductTypeName(item.name),
        })),
      });
    });

    const soProductId = location.props ? location.props.soProductId : null;

    if (!soProductId) {
      // Shows SO Modal Warning
      this.noSoWarning();
    }

    if (soProductId !== null) {
      getSoProductById(soProductId, (response) => {
        if (response.data) {
          if (response.data.data.details === "") {
            response.data.data.details = [];
            var count = 0;
          } else {
            let json = JSON.parse(response.data.data.details);
            for (var count = 0; count < json.length; count++) {
              json[count].key = count;
            }
            response.data.data.details = json;
          }
          if (response.data.data.productImgPath) {
            getFile(response.data.data.productImgPath, (ans) => {
              const { image } = { ...this.state };
              if (response.data) {
                response.data.data.bucketName = s3Bucket;
                image.url = `data:${ans.fileType};base64,${ans.data}`;
                this.setState({
                  image,
                  productToEdit: response.data.data,
                  isFormLoading: false,
                  count: count,
                });
              }
            });
          } else {
            response.data.data.bucketName = s3Bucket;
            this.setState({
              productToEdit: response.data.data,
              isFormLoading: false,
              count: count,
            });
          }
        }
      });
    }
  }

  // --------Events--------
  handleDelete = (key) => {
    const { productToEdit } = { ...this.state };
    const dataSource = [...this.state.productToEdit.details];
    productToEdit.details = dataSource.filter((item) => item !== key);
    this.setState({
      productToEdit,
    });
  };

  handleAdd = () => {
    const {
      count,
      productToEdit: { details },
    } = this.state;
    const { productToEdit } = { ...this.state };
    const newData = {
      key: count,
      attribute: "",
      value: "",
    };
    productToEdit.details = [...details, newData];
    this.setState({
      productToEdit,
      count: count + 1,
    });
  };

  handleSave = (row) => {
    const newData = [...this.state.productToEdit.details];
    const { productToEdit } = { ...this.state };
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    productToEdit.details = newData;
    this.setState({ productToEdit });
  };

  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { productToEdit } = { ...this.state };
    productToEdit[id] = value;

    this.setState({
      productToEdit,
    });
  };

  handleSelect = (option, event) => {
    const { value } = option;
    const { productToEdit } = { ...this.state };

    productToEdit[event.name] = value;

    this.setState({
      productToEdit,
    });
  };

  handleImageChange = (info) => {
    const { postFile } = this.props;
    const image = { ...this.state };
    if (info.file.status === "uploading") {
      image.loading = true;
      image.url = null;
      image.image = null;
      image.modified = false;

      this.setState({
        image,
      });
      return;
    }
    if (info.file.status === "done") {
      const { productToEdit } = { ...this.state };
      const image = { ...this.state };

      Resizer.imageFileResizer(
        info.file.originFileObj,
        300,
        300,
        "JPEG",
        50,
        0,
        (uri) => {
          const file = resizeImage(uri);
          const random = generateUUID();
          const key = `content/${productToEdit.soDependencyId}/products/img${random}`;

          image.loading = false;
          image.url = uri;
          image.image = file;
          image.modified = true;
          image.value = key;
          productToEdit.productImgPath = key;

          postFile(image.image, key, () => {});

          this.setState({
            image,
            productToEdit,
          });
        },
        "base64"
      );
    }
    if (info.file.status === "error") {
      const { image } = { ...this.state };
      const { productToEdit } = { ...this.state };

      Resizer.imageFileResizer(
        info.file.originFileObj,
        300,
        300,
        "JPEG",
        50,
        0,
        (uri) => {
          const file = resizeImage(uri);
          const random = generateUUID();
          const key = `content/${productToEdit.soDependencyId}/products/img${random}`;

          image.loading = false;
          image.url = uri;
          image.image = file;
          image.modified = true;
          image.value = key;
          productToEdit.productImgPath = key;

          postFile(image.image, key, () => {});

          this.setState({
            image,
            productToEdit,
          });
        },
        "base64"
      );
    }
  };

  handleOnEditButton() {
    const { productToEdit } = this.state;
    const { editSoProductAction } = this.props;

    this.setState({
      isSaveButtonLoading: true,
    });

    if (productToEdit.details.length === 0) {
      productToEdit.details = null;
    } else {
      productToEdit.details.forEach((obj) => {
        delete obj.key;
      });
      productToEdit.details = JSON.stringify(productToEdit.details);
    }

    let isValid = IsProductValid(productToEdit);
    if (isValid) {
      // Post Object
      editSoProductAction(productToEdit, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            isSaveButtonLoading: false,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            isSaveButtonLoading: false,
          });
        } else if (response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
          });

          this.setState({
            isSaveButtonLoading: false,
          });
        } else {
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            isSaveButtonLoading: false,
          });
        }
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
      this.setState({
        isSaveButtonLoading: false,
      });
    }
    if (productToEdit.details == null) {
      productToEdit.details = [];
    } else {
      productToEdit.details = JSON.parse(productToEdit.details);
      for (var count = 0; count < productToEdit.details.length; count++) {
        productToEdit.details[count].key = count;
      }
    }
  }

  noSoWarning() {
    Modal.warning({
      title: Strings.product.noProductSelected,
      onOk: this.handleOkNoSOWarning,
      content: Strings.product.noProductSelectedMsg,
    });
  }

  handleOkNoSOWarning = () => {
    this.setState({ redirectBackToSoProd: true });
  };

  render() {
    const {
      permissions: { data },
      location,
      prodTaxTypes,
      currencyCodesByCountry,
    } = this.props;
    const { redirectBackToSoProd, productToEdit, soCountryName, image, soProductTypes, isFormLoading, isSaveButtonLoading } = this.state;

    if (redirectBackToSoProd) {
      // Redirects to SoProduct from No SO Modal Warning
      return <Redirect to="/listSoProducts" />;
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.product.editSoProduct} tooltip={Strings.product.editSoProductTooltip} />
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.soDependency.directClient} </h3>
            </Divider>
            <br />
            <h3>{location.props ? location.props.soDependencyName : null}</h3>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.parameterization} </h3>
            </Divider>
          </div>
        </div>
        <SoProductForm
          soProduct={productToEdit}
          image={image}
          soProductTypes={soProductTypes}
          prodTaxTypes={prodTaxTypes.filter((item) => item.countryName === soCountryName)}
          currencyCodes={currencyCodesByCountry}
          onChange={this.handleFormChange}
          onChangeImage={this.handleImageChange}
          onSelect={this.handleSelect}
          onChangeCell={this.handleSave}
          onDeleteRow={this.handleDelete}
          onAddRow={this.handleAdd}
          isLoading={isFormLoading}
          data={data}
        />

        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link="/listSoProducts" />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton onClick={(e) => this.handleOnEditButton(e)} loading={isSaveButtonLoading} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    prodTaxTypes: state.productTaxType,
    currencyCodesByCountry: state.currencyCodeByCountry,
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  getAllSoProductTypes,
  getSoProductById,
  editSoProductAction,
  getFile,
  postFile,
})(EditSoProduct);
