// Dependencies
import { Button, Icon, Input, Table } from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";

// Components
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

class AvailableSoProductTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
  }

  getColumnSearchProps = (dataIndex, text, searchText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${Strings.generalTerms.search} by ${text}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />,
  });

  handleSearch = (selectedKeys, comfirm) => {
    comfirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const { availableSoProducts, onRowClick } = this.props;
    const { searchText } = this.state;
    const columns = [
      {
        title: <TextWithInfoTooltip name={Strings.product.erpCode} tooltip={Strings.product.erpCodeTooltip} />,
        dataIndex: "erpProductCode",
        key: "id",
        width: 250,
        ...this.getColumnSearchProps("erpProductCode", Strings.product.erpCode, searchText),
      },
      {
        title: Strings.product.productName,
        dataIndex: "soProductName",
        ...this.getColumnSearchProps("soProductName", Strings.generalTerms.name, searchText),
      },
    ];

    return (
      <div>
        <div className="row">
          <div className="col-md-12 ">
            <Table
              pagination={false}
              columns={columns}
              dataSource={availableSoProducts}
              // loading={this.props.isLoading}
              bordered
              onRow={onRowClick}
              scroll={{ x: "auto" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AvailableSoProductTable;
