import { Alert, Card, Divider, Icon, Modal, Spin, Typography } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  getFile,
  getNVmProductTransactionAfterVmProductTransactionId,
  getNVmProductTransactionBeforeVmProductTransactionId,
  getVmProductTransactionsByVmProductTransactionIdBetweenProvisioning,
  insertVmProductTransactionAdjustment,
} from "../../../../actions";
import Strings from "../../../../systemVariables/languageStrings";
import Titles from "../../../GenericComponents/titles";
import NotAvailableImg from "../../../Global/images/NotAvailableImg.png";
import SelectImg from "../../../Global/images/SelectImg.png";
import AdjustmentSelectComponent from "./AdjustmentSelectComponent";
import AdjustmentSummary from "./AdjustmentSummaryComponent";
import AdjustmentTable from "./AdjustmentTableComponent";

const { Paragraph } = Typography;
const { Meta } = Card;

const getInitialState = () => {
  return {
    isModalVisible: false,
    isModalOkLoading: false,
    adjustmentTableData: [],
    adjustmentSummaryTableData: [],
    totalAdjustmentsAmount: 0,
    isAdjustmentTableLoading: false,
    isTransactionSelected: 0,
    selectedTransactionId: null,
    image: SelectImg,
    isLoading: false,
    isImageLoading: false,
    error: {
      isErrorVisible: false,
      errorMsj: "",
    },
    toError401: false,
    toError500: false,
  };
};

class DispensationAdjustmentSelection extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.adjustmentTableKey = 0;
    this.adjustmentSummaryTableKey = 0;
  }

  onAddAdjustmentFromAdjustmentTable = (row) => {
    const { adjustmentTypes, selectedTransactionToAdjust } = this.props;
    const { adjustmentSummaryTableData } = this.state;
    const { amount } = row;

    let adjustmentType;
    if (row.productTransactionTypeName === "DISPENSED" || row.productTransactionTypeName === "DISPENSED WITH LAST PUSH") {
      adjustmentType = adjustmentTypes[0];
    } else if (row.productTransactionTypeName === "DISPENSATION FAILED") {
      adjustmentType = adjustmentTypes[1];
    } else {
      adjustmentType = adjustmentTypes[5];
    }
    const currentAdjustmentsAmount = this.getCurrentTotalAdjustments();
    if (currentAdjustmentsAmount === selectedTransactionToAdjust.amount) {
      this.setError(Strings.transaction.errorMsj.errorMsj2);
    } else if (currentAdjustmentsAmount + amount - 1 > selectedTransactionToAdjust.amount) {
      const selectedTransactionToAdjustAmount = selectedTransactionToAdjust.amount.toString();
      this.setError(Strings.transaction.errorMsj.errorMsj3 + " (" + selectedTransactionToAdjustAmount + ")");
    } else {
      const ids = adjustmentSummaryTableData.map((obj) => {
        return obj.id;
      });
      if (!ids.includes(row.uuid)) {
        const dismissTransaction = {
          key: this.adjustmentSummaryTableKey,
          amount,
          adjustmentTypeName: adjustmentType.name,
          adjustmentTypeId: adjustmentType.id,
          adjustmentTypeUuid: row.uuid,
          id: row.id,
        };
        this.setState({
          adjustmentSummaryTableData: adjustmentSummaryTableData.concat(dismissTransaction),
          totalAdjustmentsAmount: currentAdjustmentsAmount + amount,
        });
        this.adjustmentSummaryTableKey += 1;
      }
      this.deleteError();
    }
  };

  onAddAdjustmentFromSelect = (amount, adjustmentType) => {
    const { selectedTransactionToAdjust } = this.props;
    const { adjustmentSummaryTableData } = this.state;
    const currentAdjustmentsAmount = this.getCurrentTotalAdjustments();
    if (adjustmentType === null) {
      this.setError(Strings.transaction.errorMsj.errorMsj1);
    } else if (currentAdjustmentsAmount === selectedTransactionToAdjust.amount) {
      this.setError(Strings.transaction.errorMsj.errorMsj2);
    } else if (currentAdjustmentsAmount + amount - 1 > selectedTransactionToAdjust.amount) {
      const selectedTransactionToAdjustAmount = selectedTransactionToAdjust.amount.toString();
      this.setError(Strings.transaction.errorMsj.errorMsj3 + " (" + selectedTransactionToAdjustAmount + ")");
    } else if (amount === 0) {
      this.setError(Strings.transaction.errorMsj.errorMsj7);
    } else {
      const adjustment = adjustmentSummaryTableData.filter((obj) => obj.adjustmentTypeId === adjustmentType.id);
      if (adjustment.length === 0) {
        const dismissTransaction = {
          key: this.adjustmentSummaryTableKey,
          amount,
          adjustmentTypeName: adjustmentType.name,
          adjustmentTypeId: adjustmentType.id,
          adjustmentTypeUuid: "N/A",
          id: "N/A",
        };
        this.setState({
          adjustmentSummaryTableData: adjustmentSummaryTableData.concat(dismissTransaction),
        });
        this.adjustmentSummaryTableKey += 1;
      } else {
        this.setState({
          adjustmentSummaryTableData: adjustmentSummaryTableData.map((obj) => {
            if (obj.adjustmentTypeId === adjustmentType.id) {
              obj.amount += amount;
            }
            return obj;
          }),
        });
      }
      this.setState({
        totalAdjustmentsAmount: currentAdjustmentsAmount + amount,
      });
      this.deleteError();
    }
  };

  getCurrentTotalAdjustments() {
    let amount = 0;
    const { adjustmentSummaryTableData } = this.state;
    adjustmentSummaryTableData.forEach((obj) => {
      amount += obj.amount;
    });
    return amount;
  }

  onRemoveAdjustment = (row) => {
    const { adjustmentSummaryTableData, totalAdjustmentsAmount } = this.state;
    this.setState({
      adjustmentSummaryTableData: adjustmentSummaryTableData.filter((obj) => obj.key !== row.key),
      totalAdjustmentsAmount: totalAdjustmentsAmount - row.amount,
    });
    this.deleteError();
  };

  onRemoveAllAdjustments = () => {
    this.setState({
      adjustmentSummaryTableData: [],
      totalAdjustmentsAmount: 0,
    });
    this.deleteError();
  };

  onBringOneVmTransactionBefore = (row) => {
    const { getNVmProductTransactionBeforeVmProductTransactionId } = this.props;
    const { adjustmentTableData } = this.state;
    this.deleteError();
    this.setState({
      isAdjustmentTableLoading: true,
    });
    const ids = adjustmentTableData.map((obj) => {
      const { id } = obj;
      return id;
    });
    getNVmProductTransactionBeforeVmProductTransactionId(row.id, 1, (ans) => {
      if (ans.data.data.length !== 0 && !ids.includes(ans.data.data[0].id)) {
        this.setState({
          adjustmentTableData: adjustmentTableData
            .concat(
              ans.data.data.map((obj) => {
                this.adjustmentTableKey += 1;
                obj.key = this.adjustmentTableKey;
                return obj;
              })
            )
            .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0)),
        });
      }
      this.setState({
        isAdjustmentTableLoading: false,
      });
    });
  };

  onBringOneVmTransactionAfter = (row) => {
    const { getNVmProductTransactionAfterVmProductTransactionId } = this.props;
    const { adjustmentTableData } = this.state;
    this.deleteError();
    this.setState({
      isAdjustmentTableLoading: true,
    });
    const ids = adjustmentTableData.map((obj) => {
      const { id } = obj;
      return id;
    });
    getNVmProductTransactionAfterVmProductTransactionId(row.id, 1, (ans) => {
      if (ans.data.data.length !== 0 && !ids.includes(ans.data.data[0].id)) {
        this.setState({
          adjustmentTableData: adjustmentTableData
            .concat(
              ans.data.data.map((obj) => {
                this.adjustmentTableKey += 1;
                obj.key = this.adjustmentTableKey;
                return obj;
              })
            )
            .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0)),
        });
      }
      this.setState({
        isAdjustmentTableLoading: false,
      });
    });
  };

  onRemoveOneRecordFromAdjustmentTable = (id) => {
    const { adjustmentTableData } = this.state;
    this.setState({
      adjustmentTableData: adjustmentTableData.filter((obj) => obj.id !== id),
    });
  };

  showProductImage = (row) => {
    this.setState({
      isTransactionSelected: 1,
      selectedTransactionId: row.uuid,
      isImageLoading: true,
    });
    if (row.productImgPathS3 !== undefined && row.productImgPathS3 != null) {
      const s3Key = row.productImgPathS3;
      const { getFile } = this.props;
      getFile(s3Key, (ans) => {
        if (!String(ans).includes("Error:")) {
          this.setState({
            image: `data:${ans.fileType};base64,${ans.data}`,
            isImageLoading: false,
          });
        } else {
          this.setState({
            image: NotAvailableImg,
            isImageLoading: false,
          });
        }
      });
    } else {
      this.setState({
        image: NotAvailableImg,
        isImageLoading: false,
      });
    }
  };

  onAdjustClick = (row) => {
    this.setState({
      isModalVisible: true,
      isAdjustmentTableLoading: true,
    });

    const { getVmProductTransactionsByVmProductTransactionIdBetweenProvisioning } = this.props;
    getVmProductTransactionsByVmProductTransactionIdBetweenProvisioning(row.id, (ans) => {
      if (ans.status === 200) {
        this.setState({
          adjustmentTableData: ans.data.data.map((obj) => {
            this.adjustmentTableKey += 1;
            obj.key = this.adjustmentTableKey;
            return obj;
          }),
        });
      } else {
        this.redirectToServerError();
      }
      this.setState({ isAdjustmentTableLoading: false });
    });
  };

  onModalOk = () => {
    const { selectedTransactionToAdjust, insertVmProductTransactionAdjustment } = this.props;
    const { adjustmentSummaryTableData } = this.state;
    const currentAdjustmentsAmount = this.getCurrentTotalAdjustments();
    if (selectedTransactionToAdjust.amount > currentAdjustmentsAmount) {
      const selectedTransactionToAdjustAmount = selectedTransactionToAdjust.amount.toString();
      this.setError(Strings.transaction.errorMsj.errorMsj4 + " (" + selectedTransactionToAdjustAmount + ")");
    } else {
      this.setState({ isModalOkLoading: true });
      const dtoList = this.buildDtoList(adjustmentSummaryTableData, selectedTransactionToAdjust);
      insertVmProductTransactionAdjustment(dtoList, (ans) => {
        if (ans.status !== undefined && ans.data.code === 0) {
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.transaction.adjustmentSuccessful,
            onOk: this.onOkModalAfterClose,
          });
        } else {
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.transaction.errorMsj.errorMsj5,
          });
        }
        this.setState(getInitialState());
      });
    }
  };

  onOkModalAfterClose = () => {
    const { removeRecordFromUnderStockedTransactionsTable, selectedTransactionToAdjust } = this.props;
    removeRecordFromUnderStockedTransactionsTable(selectedTransactionToAdjust.id);
  };

  buildDtoList = (adjustments, selectedTransactionToAdjust) => {
    const dtoList = [];
    adjustments.forEach((obj) => {
      let vmProductTransactionId;
      if (obj.id === "N/A") {
        vmProductTransactionId = null;
      } else {
        vmProductTransactionId = obj.id;
      }
      const dto = {
        underStockedTransactionId: selectedTransactionToAdjust.id,
        vmProductTransactionId,
        adjustmentTypeId: obj.adjustmentTypeId,
        adjustmentTypeUuid: obj.adjustmentTypeId,
        amount: obj.amount,
      };
      dtoList.push(dto);
    });

    return dtoList;
  };

  onModalCancel = () => {
    this.setState(getInitialState());
  };

  deleteError() {
    this.setState({
      error: {
        isErrorVisible: false,
        errorMsj: "",
      },
    });
  }

  setError(errorMsj) {
    this.setState({
      error: {
        isErrorVisible: true,
        errorMsj,
      },
    });
  }

  redirectToServerError = () => {
    this.setState(() => ({
      toError500: true,
    }));
  };

  redirectToAuthorizationError = () => {
    this.setState(() => ({
      toError401: true,
    }));
  };

  renderTitle = () => {
    return (
      <>
        <Paragraph style={{ whiteSpace: "normal" }}>
          <Titles title={<div>{Strings.transaction.adjustmentTransaction}</div>} tooltip={Strings.transaction.underStockedTransactionAdjustmentTooltip} />
        </Paragraph>
      </>
    );
  };

  render() {
    const { selectedTransactionToAdjust, adjustmentTypes } = this.props;
    const { toError401, toError500, isModalVisible, isModalOkLoading, adjustmentTableData, isAdjustmentTableLoading } = this.state;
    const { image, isImageLoading, isLoading, selectedTransactionId, isTransactionSelected, adjustmentSummaryTableData, totalAdjustmentsAmount, error } = this.state;

    if (toError500 === true) {
      return <Redirect to="/error500" />;
    }
    if (toError401 === true) {
      return <Redirect to="/error401" />;
    }

    return (
      <div>
        <Icon type="fullscreen" style={{ fontSize: 20, color: "#004173" }} onClick={() => this.onAdjustClick(selectedTransactionToAdjust)} />
        {isLoading ? (
          <div>
            <Spin />
          </div>
        ) : (
          <Modal title={this.renderTitle()} visible={isModalVisible} confirmLoading={isModalOkLoading} onOk={this.onModalOk} onCancel={this.onModalCancel} centered width="80%">
            <div className="container-fluid">
              <div className="detailRow">
                <div>
                  <h2 className="paragraphSubTitle" style={{ alignItems: "center" }}>
                    {Strings.transaction.transactionIdentifier}:
                  </h2>{" "}
                  <span className="text-secondary">{selectedTransactionToAdjust.uuid}</span>
                </div>
                <div>
                  <h2 className="paragraphSubTitle">{Strings.machine.vendingMachine}:</h2> <span className="text-secondary">{selectedTransactionToAdjust.vmFriendlyName}</span>
                </div>
                <div>
                  <h2 className="paragraphSubTitle">{Strings.product.product}:</h2> <span className="text-secondary">{selectedTransactionToAdjust.soProductName}</span>
                </div>
                <div>
                  <h2 className="paragraphSubTitle">{Strings.planogram.slotNumber}:</h2> <span className="text-secondary">{selectedTransactionToAdjust.vmSlotNumber}</span>
                </div>
                <div>
                  <h2 className="paragraphSubTitle">{Strings.transaction.unitsToAdjust}:</h2> <span className="text-secondary">{selectedTransactionToAdjust.amount}</span>
                </div>
              </div>
              <Divider></Divider>
              {adjustmentTableData.length <= 0 ? (
                <Spin tip={Strings.generalTerms.loading} style={{ display: "flex", justifyContent: "center" }} />
              ) : (
                <>
                  <Card className="customCardAdj">
                    <Card style={{ marginBottom: "1em" }}>
                      <div className="row" style={{ display: "flex", justifyContent: "flex-start" }}>
                        <div className="col-12 col-lg-8">
                          <AdjustmentTable
                            tableData={adjustmentTableData}
                            isLoading={isAdjustmentTableLoading}
                            onBringOneVmTransactionBefore={this.onBringOneVmTransactionBefore}
                            onBringOneVmTransactionAfter={this.onBringOneVmTransactionAfter}
                            onRemoveOneRecordFromAdjustmentTable={this.onRemoveOneRecordFromAdjustmentTable}
                            onAddAdjustmentFromAdjustmentTable={this.onAddAdjustmentFromAdjustmentTable}
                            selectedTransactionToAdjust={selectedTransactionToAdjust}
                            selectedTransactionId={selectedTransactionId}
                            showProductImage={this.showProductImage}
                            adjustmentTypes={adjustmentTypes}
                          />
                        </div>
                        <div className="col-12 col-lg-4">
                          <Card cover={<img className="customImg" src={image == "NotAvailableImg" ? <Spin></Spin> : image} />}>
                            {isImageLoading ? (
                              <Spin></Spin>
                            ) : (
                              <Meta
                                title={
                                  isTransactionSelected == 1 ? (
                                    <p style={{ fontSize: "14px" }}>{Strings.transaction.transactionIdentifier + ": " + selectedTransactionId}</p>
                                  ) : (
                                    <p style={{ fontSize: "14px" }}>{Strings.transaction.selectTransaction}</p>
                                  )
                                }
                              />
                            )}
                          </Card>
                        </div>
                      </div>
                    </Card>
                    {error.isErrorVisible ? (
                      <div className="row" style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}>
                        <div className="col-12">
                          <Alert message={error.errorMsj} type="error" showIcon />
                        </div>
                      </div>
                    ) : null}
                    <Card>
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <AdjustmentSelectComponent
                            amountToAdjust={selectedTransactionToAdjust.amount}
                            pendingAmount={
                              adjustmentSummaryTableData.length > 0 ? selectedTransactionToAdjust.amount - adjustmentSummaryTableData.length : selectedTransactionToAdjust.amount
                            }
                            onAddAdjustmentFromSelect={this.onAddAdjustmentFromSelect}
                            adjustmentTypes={adjustmentTypes}
                          />
                        </div>
                        <div className="col-12 col-lg-6">
                          <AdjustmentSummary
                            adjustmentSummaryTableData={adjustmentSummaryTableData}
                            totalAdjustmentsAmount={totalAdjustmentsAmount}
                            onRemoveAdjustment={this.onRemoveAdjustment}
                            onRemoveAllAdjustments={this.onRemoveAllAdjustments}
                          />
                        </div>
                      </div>
                    </Card>
                  </Card>
                </>
              )}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(null, {
  getFile,
  getNVmProductTransactionAfterVmProductTransactionId,
  getNVmProductTransactionBeforeVmProductTransactionId,
  getVmProductTransactionsByVmProductTransactionIdBetweenProvisioning,
  insertVmProductTransactionAdjustment,
})(DispensationAdjustmentSelection);
