import axios from "axios";

import { host, path, port, version } from "../systemVariables";

const resource = "/vmproducttransactionadjustment";

export function insertVmProductTransactionAdjustment(vmProductTransactionAdjustmentDtoList, callback) {
  return () => {
    let data = JSON.stringify(vmProductTransactionAdjustmentDtoList);
    axios({
      method: "post",
      url: host + port + path + version + resource,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
        return error;
      });
  };
}
