import React from "react";
import { Tabs, Icon } from "antd";
import GeneralConfig from "./general/GeneralConfigComponent.jsx";
import SecurityConfig from "./security/SecurityConfigComponent.jsx";
import "./AccountConfiguration.css";
// Language localization
import Strings from "../../systemVariables/languageStrings";

const { TabPane } = Tabs;

const AccountConfiguration = () => {
  return (
    <div className="content-container">
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <Icon type="user" />
              {Strings.generalTerms.general}
            </span>
          }
          key="1"
          forceRender
        >
          <GeneralConfig />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="safety" />
              {Strings.generalTerms.security}
            </span>
          }
          key="2"
          forceRender
        >
          <SecurityConfig />
        </TabPane>
        {/* <TabPane
          tab={
            <span>
              <Icon type="setting" />
              Configuración
            </span>
          }
          key="3"
          forceRender
        >
          Configuración
        </TabPane> */}
      </Tabs>
    </div>
  );
};
export default AccountConfiguration;
