/* eslint-disable no-nested-ternary */
// Dependencies
import { Divider, Icon, Layout, Menu } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// component Images
import UserAvatarComponentSVG from "../images/ComponentsSVG/UserAvatarComponentSVG";

import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";

import "../css/menuBar.css";

import logo from "../images/NoacloudLogoBlanco.png";
// import logo from "../images/NoacloudLogoBlancoNAVIDAD.png";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

const { Sider } = Layout;
const { SubMenu } = Menu;
let selectedKey = null;
let latestOpenKey = 0;

class MenuBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openKeys: [],
      responsive: false,
      collapsed: true,
    };
  }

  rootSubmenuKeys = [
    "clients",
    "users",
    "vendingMachines",
    "dispensationRules",
    "products",
    "scInitialConfiguration",
    "dispensations",
    "configAndSupport",
    "privilegeByRole",
    "smartVendingClinical",
    "myAccount",
  ];

  onOpenChange = (openKeys2) => {
    const { openKeys } = this.state;
    latestOpenKey = openKeys2.find((key) => openKeys.indexOf(key) === -1);

    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys: openKeys2 });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  clickOption(event) {
    // eslint-disable-next-line radix
    selectedKey = parseInt(event.key);
    this.forceUpdate();
  }

  render() {
    const {
      permissions: { data },
      userInfo,
      userDependency,
    } = this.props;
    const { responsive, collapsed, openKeys } = this.state;

    if (userInfo) {
      const middleName = userInfo.data.middleName ? userInfo.data.middleName : "";
      const lastName1 = userInfo.data.lastName1 ? userInfo.data.lastName1 : "";
      const lastName2 = userInfo.data.lastName2 ? userInfo.data.lastName2 : "";
      const userName = `${userInfo.data.firstName} ${middleName} ${lastName1} ${lastName2}`;

      return (
        <div className={`menu ${responsive ? "" : "col-md-2"}`} style={{ zIndex: "1" }}>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              this.setState({
                responsive: broken,
                collapsed: broken,
              });
            }}
            onCollapse={(collapsed, type) => {
              if (type === "clickTrigger") {
                this.setState({
                  collapsed,
                });
              }
            }}
          >
            <>
              {responsive ? (
                collapsed ? (
                  ""
                ) : (
                  // Collapsed
                  <div className="menu__user-info col-xs-12">
                    <div className="row col-xs-12 vertSpace">
                      <Link to="/">
                        <img id="image-company" src={logo} alt="companyLogo" />
                      </Link>
                    </div>
                    <div className="row aligning col-xs-12 vertSpace">
                      <div className="col-xs-3" style={{ padding: "0px" }}>
                        <UserAvatarComponentSVG maxWidth={30} />
                      </div>
                      <div className="col-xs-9 menu__user-info__user">
                        <span style={{ fontSize: "16px" }}>{userName}</span>
                        <br />
                        <Divider id="smallDivider" />
                        <span style={{ fontSize: "16px" }}>{userDependency.dependencyName}</span>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                // Not collapsed
                <div className="menu__user-info col-xs-12">
                  <div className="row col-xs-12 vertSpace">
                    <Link to="/">
                      <img id="image-company" src={logo} alt="companyLogo" />
                    </Link>
                  </div>
                  <div className="row aligning col-xs-12 vertSpace">
                    <div className="col-md-3" style={{ padding: "3px" }}>
                      <UserAvatarComponentSVG maxWidth={30} />
                    </div>
                    <div className="col-md-9 menu__user-info__user">
                      <span style={{ fontSize: "16px" }}>{userName}</span>
                      <br />
                      <Divider id="small-divider" />
                      <span style={{ fontSize: "16px" }}>{userDependency.dependencyName}</span>
                    </div>
                  </div>
                </div>
              )}
            </>

            <div className="menu col-xs-12">
              <Menu mode="inline" openKeys={openKeys} onOpenChange={this.onOpenChange} id="panel" inlineCollapsed={collapsed}>
                {ValidatePermissionForComponentPart("PRIVILEGE USERS", data) ? (
                  <SubMenu
                    key="users"
                    className={latestOpenKey === "users" ? "userColor" : ""}
                    title={
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icon type="team" style={{ fontSize: "28px" }} />
                        <span style={{ fontSize: "15.4px" }}>{Strings.users.users}</span>
                      </div>
                    }
                    onClick={(e) => this.clickOption(e)}
                  >
                    {ValidatePermissionForComponentPart("PRIVILEGE EXECUTIVE USERS GET", data) ? (
                      <Menu.Item key="1" className={selectedKey === 1 ? "ant-menu-item-users" : ""}>
                        <Link to="/listExecutiveUsers">{Strings.users.executiveUsers}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE CLIENT USERS GET", data) ? (
                      <Menu.Item key="2" className={selectedKey === 2 ? "ant-menu-item-users" : ""}>
                        <Link to="/listClientUsers">{Strings.users.clientUsers}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE USERS BY VENDING MACHINE GET", data) ? (
                      <Menu.Item key="3" className={selectedKey === 3 ? "ant-menu-item-users" : ""}>
                        <Link to="/UsersByVendingMachine">{Strings.machine.vmUsers}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE CREDIT BY USER GET", data) ? (
                      <Menu.Item key="4" className={selectedKey === 4 ? "ant-menu-item-users" : ""}>
                        <Link to="/listCreditByUser">{Strings.users.creditByUser.creditByUser}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE LOCKER LOAN EXCEPTION  BY USER GET", data) ? (
                      <Menu.Item key="5" className={selectedKey === 5 ? "ant-menu-item-users" : ""}>
                        <Link to="/listLockerLoanExceptionByUser">{Strings.users.lockerLoanExceptionByUser.lockerLoanException}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </SubMenu>
                ) : (
                  ""
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINES", data) ? (
                  <SubMenu
                    key="vendingMachines"
                    className={latestOpenKey === "vendingMachines" ? "vendingMachineColor" : ""}
                    title={
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icon type="hdd" style={{ fontSize: "28px" }} />
                        <span style={{ fontSize: "15.4px" }}>{Strings.machine.machines}</span>
                      </div>
                    }
                    onClick={(e) => this.clickOption(e)}
                  >
                    {ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE GET", data) ? (
                      <Menu.Item key="6" className={selectedKey === 6 ? "ant-menu-item-vendingMachine" : ""}>
                        <Link to="/ListVM">{Strings.machine.machines}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE VENDING MACHINE INVENTORY GET", data) ? (
                      <Menu.Item key="7" className={selectedKey === 7 ? "ant-menu-item-vendingMachine" : ""}>
                        <Link to="/vendingMachineInventory">{Strings.machineInventory.vendingMachineInventory}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE VM PRODUCT SLOT POST", data) ? (
                      <Menu.Item key="8" className={selectedKey === 8 ? "ant-menu-item-vendingMachine" : ""}>
                        <Link to="/vmProductSlot">{Strings.planogram.planogramConfiguration}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE VM EVENT GET", data) ? (
                      <Menu.Item key="9" className={selectedKey === 9 ? "ant-menu-item-vendingMachine" : ""}>
                        <Link to="/vmEventList">{Strings.vmEvent.vmEvent}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE COMMS MONITOR", data) ? (
                      <Menu.Item key="10" className={selectedKey === 10 ? "ant-menu-item-vendingMachine" : ""}>
                        <Link to="/communications">{Strings.communications.communicationsMonitor}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE VM CASH AUDITMENT", data) ? (
                      <Menu.Item key="11" className={selectedKey === 11 ? "ant-menu-item-vendingMachine" : ""}>
                        <Link to="/vmFinanceAuditingInfo">
                          <span>{Strings.machine.configuration.financeAuditing}</span>
                        </Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </SubMenu>
                ) : (
                  ""
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE PRODUCTS", data) ? (
                  <SubMenu
                    key="products"
                    className={latestOpenKey === "products" ? "productosColor" : ""}
                    title={
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icon type="shop" style={{ fontSize: "28px" }} />
                        <span style={{ fontSize: "15.4px" }}>{Strings.product.products}</span>
                      </div>
                    }
                    onClick={(e) => this.clickOption(e)}
                  >
                    {ValidatePermissionForComponentPart("PRIVILEGE SO PRODUCT GET", data) ? (
                      <Menu.Item key="13" className={selectedKey === 13 ? "ant-menu-item-productos" : ""}>
                        <Link to="/listSoProducts">{Strings.product.soProduct}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE SC PRODUCT INFORMATION GET", data) ? (
                      <Menu.Item key="14" className={selectedKey === 14 ? "ant-menu-item-productos" : ""}>
                        <Link to="/scProductInformation">{Strings.product.scProductInfo}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE SO PRODUCT SLOT TYPE GET", data) ? (
                      <Menu.Item key="15" className={selectedKey === 15 ? "ant-menu-item-productos" : ""}>
                        <Link to="/soProductSlotType">{Strings.product.soProductSlotType}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </SubMenu>
                ) : (
                  ""
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE DISPENSATION RULES", data) ? (
                  <SubMenu
                    key="dispensationRules"
                    className={latestOpenKey === "dispensationRules" ? "dispensationRulesColor" : ""}
                    title={
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icon type="schedule" style={{ fontSize: "28px" }} />
                        <span style={{ fontSize: "15.4px" }}>{Strings.dispensationRule.dispensationRules}</span>
                      </div>
                    }
                    onClick={(e) => this.clickOption(e)}
                  >
                    {ValidatePermissionForComponentPart("PRIVILEGE DISPENSATION RULES GET", data) ? (
                      <Menu.Item key="10" className={selectedKey === 10 ? "ant-menu-item-dispensationRules" : ""}>
                        <Link to="/dispensationRules">{Strings.dispensationRule.dispensationRules}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE DISPENSATION RULE BY USER GET", data) ? (
                      <Menu.Item key="11" className={selectedKey === 11 ? "ant-menu-item-dispensationRules" : ""}>
                        <Link to="/listDispensationRuleByUser">{Strings.dispensationRule.dispensationByUser}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE VM PRODUCT GROUP GET", data) ? (
                      <Menu.Item key="12" className={selectedKey === 12 ? "ant-menu-item-dispensationRules" : ""}>
                        <Link to="/listVmProductGroups">{Strings.vmProductGroup.vmProductGroups}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </SubMenu>
                ) : (
                  ""
                )}

                {ValidatePermissionForComponentPart("PRIVILEGE INITIAL CONFIGURATION", data) ? (
                  <SubMenu
                    key="scInitialConfiguration"
                    className={latestOpenKey === "scInitialConfiguration" ? "initialConfigColor" : ""}
                    title={
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icon type="contacts" style={{ fontSize: "28px" }} />
                        <span style={{ fontSize: "15.4px" }}>{Strings.scDependency.indirectClient}</span>
                      </div>
                    }
                    onClick={(e) => this.clickOption(e)}
                  >
                    {ValidatePermissionForComponentPart("PRIVILEGE SC DEPENDENCIES GET", data) ? (
                      <Menu.Item key="16" className={selectedKey === 16 ? "ant-menu-item-initialConfig" : ""}>
                        <Link to="/listScDependency">{Strings.scDependency.indirectClient}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE SC EMPLOYEE INFORMATION GET", data) ? (
                      <Menu.Item key="17" className={selectedKey === 17 ? "ant-menu-item-initialConfig" : ""}>
                        <Link to="/listScEmployeeInformation">{Strings.scDependency.businessConstruct}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </SubMenu>
                ) : (
                  ""
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE DISPENSATION", data) ? (
                  <SubMenu
                    key="dispensations"
                    className={latestOpenKey === "dispensations" ? "dispensationsColor" : ""}
                    title={
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icon type="bar-chart" style={{ fontSize: "28px" }} />
                        <span style={{ fontSize: "15.4px" }}>{Strings.transaction.transactions}</span>
                      </div>
                    }
                    onClick={(e) => this.clickOption(e)}
                  >
                    {ValidatePermissionForComponentPart("PRIVILEGE UNDERSTOCKED DISPENSATIONS GET", data) ? (
                      <Menu.Item key="18" className={selectedKey === 18 ? "ant-menu-item-dispensations" : ""}>
                        <Link to="/transactionsAdjustment">{Strings.transaction.transactionsAdjustment}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE UNDERSTOCKED DISPENSATIONS GET", data) ? (
                      <Menu.Item key="19" className={selectedKey === 19 ? "ant-menu-item-dispensations" : ""}>
                        <Link to="/adjustmentReports">{Strings.transaction.adjustmentReports}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE PROVISIONING INFO GET", data) ? (
                      <Menu.Item key="20" className={selectedKey === 20 ? "ant-menu-item-dispensations" : ""}>
                        <Link to="/listProvisioningInfoByVM">{Strings.transaction.provisioningReport}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE DISPENSATION REPORT GET", data) ? (
                      <Menu.Item key="21" className={selectedKey === 21 ? "ant-menu-item-dispensations" : ""}>
                        <Link to="/transactionsReport">{Strings.transaction.transactionsReport}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE TRANSACTIONS MONITOR GET", data) ? (
                      <Menu.Item key="22" className={selectedKey === 22 ? "ant-menu-item-dispensations" : ""}>
                        <Link to="/transactionsMonitor">{Strings.transactionMonitor.transactionMonitor}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE GET UNCOLLECTED PRODUCTS", data) ? (
                      <Menu.Item key="23" className={selectedKey === 23 ? "ant-menu-item-dispensations" : ""}>
                        <Link to="/lockerProductsReport">{Strings.transaction.uncollectedProducts}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </SubMenu>
                ) : (
                  ""
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE PRIVILEGE BY ROLE", data) ? (
                  <SubMenu
                    key="privilegeByRole"
                    className={latestOpenKey === "privilegeByRole" ? "privilegeByRoleColor" : ""}
                    title={
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icon type="security-scan" style={{ fontSize: "28px" }} />
                        <span style={{ fontSize: "15.4px" }}>{Strings.privilegeByRole.rolesAndPrivileges}</span>
                      </div>
                    }
                    onClick={(e) => this.clickOption(e)}
                  >
                    {ValidatePermissionForComponentPart("PRIVILEGE PRIVILEGE BY ROLE GET", data) ? (
                      <Menu.Item key="25" className={selectedKey === 25 ? "ant-menu-item-privilege-by-role" : ""}>
                        <Link to="/privilegeByRole">{Strings.privilegeByRole.rolesAndPrivileges}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </SubMenu>
                ) : (
                  ""
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE CONFIG AND SUPPORT", data) ? (
                  <SubMenu
                    key="configAndSupport"
                    className={latestOpenKey === "configAndSupport" ? "configAndSupportColor" : ""}
                    title={
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icon type="setting" style={{ fontSize: "28px" }} />
                        <span style={{ fontSize: "15.4px" }}>{Strings.generalTerms.setupAndSupport}</span>
                      </div>
                    }
                    onClick={(e) => this.clickOption(e)}
                  >
                    {ValidatePermissionForComponentPart("PRIVILEGE PERIPHERAL DEVICE GET", data) ? (
                      <Menu.Item key="24" className={selectedKey === 24 ? "ant-menu-item-peripheral-device" : ""}>
                        <Link to="/peripheralDevice">{Strings.peripheralDevice.peripheralDevice}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE FIRMWARE VERSION GET", data) ? (
                      <Menu.Item key="27" className={selectedKey === 27 ? "ant-menu-item-firmware-version" : ""}>
                        <Link to="/listFirmwareVersion">{Strings.machine.firmwareVersion}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE VM SERVICE REQUEST GET", data) ? (
                      <Menu.Item key="28" className={selectedKey === 28 ? "ant-menu-item-vm-service-request" : ""}>
                        <Link to="/listVmServiceRequest">{Strings.vmServiceRequest.serviceRequest}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </SubMenu>
                ) : (
                  ""
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE SMART VENDING CLINICAL", data) ? (
                  <SubMenu
                    key="smartVendingClinical"
                    className={latestOpenKey === "smartVendingClinical" ? "smartVendingClinicalColor" : ""}
                    title={
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icon type="medicine-box" style={{ fontSize: "28px" }} />
                        <span style={{ fontSize: "15.4px" }}>{Strings.generalTerms.noacloudPharma}</span>
                      </div>
                    }
                    onClick={(e) => this.clickOption(e)}
                  >
                    {ValidatePermissionForComponentPart("PRIVILEGE USERS", data) ? (
                      <Menu.Item key="29" className={selectedKey === 29 ? "ant-menu-item-patient-information" : ""}>
                        <Link to="/listPatientInformation">{Strings.smartVendingClinical.patientInformation}</Link>
                      </Menu.Item>
                        ) : (
                      ""
                    )}
                    {ValidatePermissionForComponentPart("PRIVILEGE LOCATIONS GET", data) ? (
                      <Menu.Item key="30" className={selectedKey === 30 ? "ant-menu-item-locations" : ""}>
                        <Link to="/listLocations">{Strings.smartVendingClinical.locations}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </SubMenu>
                ) : (
                  ""
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE MY ACCOUNT", data) ? (
                  <SubMenu
                    key="myAccount"
                    className={latestOpenKey === "myAccount" ? "myAccountColor" : ""}
                    title={
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Icon type="key" style={{ fontSize: "28px" }} />
                        <span style={{ maxFontSize: "15.4px" }}>{Strings.myAccount.myAccount}</span>
                      </div>
                    }
                    onClick={(e) => this.clickOption(e)}
                  >
                    {ValidatePermissionForComponentPart("PRIVILEGE MY ACCOUNT GET", data) ? (
                      <Menu.Item key="26" className={selectedKey === 26 ? "ant-menu-item-my-account" : ""}>
                        <Link to="/myAccountInfo">{Strings.myAccount.myAccount}</Link>
                      </Menu.Item>
                    ) : (
                      ""
                    )}
                  </SubMenu>
                ) : (
                  ""
                )}
              </Menu>
            </div>
          </Sider>
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    users: state.users,
    permissions: state.userSystemPrivileges.privilegeObj,
    userDependency: state.dependencyByUser.dependencyObj,
    userInfo: state.users.userSessionObj,
  };
}

export default connect(mapStateToProps, undefined)(MenuBar);
