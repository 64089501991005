// Dependencies
import React, { useState, useContext } from "react";
import Select from "react-select";
import { DataContext } from "../../context/DataContext";

import { Modal, InputNumber, Alert } from "antd";

// Components
import { AcceptButton } from "../GenericComponents/buttons/acceptButton";
import { DeleteButton } from "../GenericComponents/buttons/deleteButton";

// CSS
import "./lockerSlotInfoConfig.css";

// Language localization
import Strings from "../../systemVariables/languageStrings";

const MAX_TRAY_PER_MOTORCTRL = 7;
const MAX_CONN_PER_TRAY = 9;
const MAX_SLOT_NUM = 999;

const LockerSlotInfoConfig = (props) => {
  const { showSlotConfigModal, planogramInfo, setPlanogramInfo, slotToConfig, setSlotToConfig, onCloseSlotInfoModal } = useContext(DataContext);

  const [saveSlotConfigStatus, setSaveSlotConfigStatus] = useState(0);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [saveSlotStatusMsg, setSaveSlotStatusMsg] = useState(null);

  const changeSlotNumber = (val) => {
    setSlotToConfig((slotToConfig) => ({
      ...slotToConfig,
      slotNumber: val,
    }));
    setSaveSlotConfigStatus(0);
    setEnableSaveButton(slotToConfig.slotNumber != null && slotToConfig.slotGroup != null && slotToConfig.trayDenomination != null && slotToConfig.columnLId != null);
  };

  const changeSlotGroup = (val) => {
    setSlotToConfig((slotToConfig) => ({
      ...slotToConfig,
      slotGroupId: val.value,
      slotGroup: val.label,
    }));
    setSaveSlotConfigStatus(0);
    setEnableSaveButton(slotToConfig.slotNumber != null && slotToConfig.slotGroup != null && slotToConfig.trayDenomination != null && slotToConfig.columnLId != null);
  };

  const changeSlotTray = (val) => {
    setSlotToConfig((slotToConfig) => ({
      ...slotToConfig,
      trayDenomination: val - 1,
    }));
    setSaveSlotConfigStatus(0);
    setEnableSaveButton(slotToConfig.slotNumber != null && slotToConfig.slotGroup != null && slotToConfig.trayDenomination != null && slotToConfig.columnLId != null);
  };

  const changeSlotConn = (val) => {
    setSlotToConfig((slotToConfig) => ({
      ...slotToConfig,
      columnLId: val,
    }));
    setSaveSlotConfigStatus(0);
    setEnableSaveButton(slotToConfig.slotNumber != null && slotToConfig.slotGroup != null && slotToConfig.trayDenomination != null && slotToConfig.columnLId != null);
  };

  const saveSlotConfig = () => {
    let { slots } = planogramInfo;

    // Validate if slotNumber is not already taken
    let slotNumberOk = slots.filter((s) => s.slotNumber === slotToConfig.slotNumber && !(s.row === slotToConfig.row && s.col === slotToConfig.col)).length === 0;

    // Validate if the slotGroup, connection and trayDenomination are already taken
    const slotConnConfigOk =
      slots.filter(
        (s) =>
          s.slotGroup === slotToConfig.slotGroup &&
          s.trayDenomination === slotToConfig.trayDenomination &&
          s.columnLId === slotToConfig.columnLId &&
          !(s.row === slotToConfig.row && s.col === slotToConfig.col)
      ).length === 0;

    // Set configuration info
    if (slotNumberOk && slotConnConfigOk) {
      slots.forEach((s) => {
        if (s.row === slotToConfig.row && s.col === slotToConfig.col) {
          if (s.vmProductSlotId) {
            s.statusId = 2;
          } else {
            s.statusId = 1;
          }
          s.slotNumber = slotToConfig.slotNumber;
          s.slotGroupId = null;
          s.slotGroup = slotToConfig.slotGroup;
          if (planogramInfo.slotGroups[0].id != null) {
            s.slotGroupId = slotToConfig.slotGroupId;
          }
          s.trayDenomination = slotToConfig.trayDenomination;
          s.columnLId = slotToConfig.columnLId;
          s.lockerType = slotToConfig.lockerType === "NEW SLOT" ? "FREE" : slotToConfig.lockerType;
          s.vmTrayDenomination = slotToConfig.vmTrayDenomination;
        }
      });
      setPlanogramInfo((planogramInfo) => ({
        ...planogramInfo,
        slots: slots,
      }));
      onCloseSlotInfoModal();
    } else if (slotNumberOk) {
      const slotConnConfigOccuped = slots.filter(
        (s) =>
          s.slotGroup == slotToConfig.slotGroup &&
          s.trayDenomination == slotToConfig.trayDenomination &&
          s.columnLId == slotToConfig.columnLId &&
          !(s.row == slotToConfig.row && s.col == slotToConfig.col)
      );
      setSaveSlotStatusMsg(Strings.planogram.harnessConnectionBusy + ": " + slotConnConfigOccuped[0].slotNumber);
      setSaveSlotConfigStatus(-1);
    } else {
      setSaveSlotStatusMsg(Strings.planogram.slotNumberBusy);
      setSaveSlotConfigStatus(-1);
    }
    setEnableSaveButton(false);
    // }
  };

  const deleteSlotConfig = () => {
    let { slots } = planogramInfo;

    // Slot to delete
    const slotToDelete = slots.filter((s) => s.row == slotToConfig.row && s.col == slotToConfig.col)[0];

    //Check if Slot is FREE or NEW SLOT
    if (slotToDelete.lockerType == "FREE" || slotToDelete.lockerType == "NEW SLOT") {
      slots = slots.filter((s) => !(s.row == slotToConfig.row && s.col == slotToConfig.col));

      //Add empty slots
      for (let r = slotToDelete.row; r < slotToDelete.row + slotToDelete.mulHeight; r++) {
        for (let c = slotToDelete.col; c < slotToDelete.col + slotToDelete.mulWidth; c++) {
          slots.push({
            row: r,
            col: c,
            mulWidth: 1,
            mulHeight: 1,
            lockerType: "EMPTY",
            statusId: 3,
            vmTrayDenomination: slotToDelete.vmTrayDenomination,
            vmTrayId: slotToDelete.vmTrayId,
            vmProductSlotId: slotToDelete.id,
            slotGroup: slotToDelete.slotGroup,
            slotGroupId: slotToDelete.slotGroupId,
          });
        }
      }
      setPlanogramInfo((planogramInfo) => ({
        ...planogramInfo,
        slots: slots,
      }));
      onCloseSlotInfoModal();
    } else {
      setSaveSlotStatusMsg(Strings.planogram.ocuppedSlotsNotDeleted);
      setSaveSlotConfigStatus(-1);
    }
  };

  const handleClose = () => {
    onCloseSlotInfoModal();
  };

  // Information to render
  const renderSlotConfig = (slotToConfig) => {
    const harness = slotToConfig.trayDenomination + 1;

    const slotConfig = (
      <Modal
        visible={showSlotConfigModal}
        onCancel={handleClose}
        width={400}
        title={Strings.planogram.slotConfiguration}
        footer={[
          <div className="row" key={slotToConfig.id}>
            <div className="col-6">
              <AcceptButton isDisabled={!enableSaveButton} onClick={() => saveSlotConfig()} />
            </div>
            <div className="col-6">
              <DeleteButton onClick={() => deleteSlotConfig()} />
            </div>
          </div>,
        ]}
      >
        <div className="col">
          <div className="row">
            <div className="col-6">
              <span>{Strings.planogram.slotNumber}:</span>
            </div>
            <div className="col-6">
              <InputNumber min={1} max={MAX_SLOT_NUM} value={slotToConfig.slotNumber} onChange={(val) => changeSlotNumber(val)} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <span>{Strings.machine.slotGroup}:</span>
            </div>
            <div className="col-6" style={{ paddingRight: "50px" }}>
              {planogramInfo.slotGroups.length == 1 ? (
                <div style={{ textAlign: "center" }}>
                  <span>{planogramInfo.slotGroups[0].num}</span>
                </div>
              ) : (
                <div>
                  <Select
                    className="basic-single"
                    options={planogramInfo.slotGroups.map((s) => ({ value: s.id, label: s.num }))}
                    placeholder={"Select"}
                    onChange={(val) => changeSlotGroup(val)}
                    value={{ value: slotToConfig.slotGroup, label: slotToConfig.slotGroup }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <span>{Strings.planogram.harness}:</span>
            </div>
            <div className="col-6">
              <InputNumber min={1} max={MAX_TRAY_PER_MOTORCTRL} value={harness} onChange={(val) => changeSlotTray(val)} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <span>{Strings.generalTerms.position}:</span>
            </div>
            <div className="col-6">
              <InputNumber min={0} max={MAX_CONN_PER_TRAY} value={slotToConfig.columnLId} onChange={(val) => changeSlotConn(val)} />
            </div>
          </div>
          <div className="row">
            <div className="col-12" style={{ marginTop: "10px" }}>
              {saveSlotConfigStatus == 1 ? (
                <Alert message={saveSlotStatusMsg} type="success" showIcon />
              ) : (
                saveSlotConfigStatus == -1 && <Alert message={saveSlotStatusMsg} type="error" showIcon />
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
    return slotConfig;
  };

  return <>{!props.showJustInfo && slotToConfig && renderSlotConfig(slotToConfig)}</>;
};

export default LockerSlotInfoConfig;
