// Dependencies
import React from "react";
import { Link } from "react-router-dom";
import { Icon, Layout, Menu } from "antd";

import { host, port, clientId } from "../../../systemVariables/serverInformation";

import Strings from "../../../systemVariables/languageStrings";

import "../css/header.css";

const { Header } = Layout;
const { Item } = Menu;

const HeaderContent = () => {
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = `${host + port}/logout?client=${clientId}`;
  };

  return (
    <Header className="col-12 header">
      <Menu theme="dark" mode="horizontal" style={{ lineHeight: "64px" }}>
        <Item key="0" className="header__item">
          <div>
            <Link to="/">
              <Icon style={{ fontSize: "28px" }} type="home" />
              <span style={{ fontSize: "15.4px" }}>{Strings.generalTerms.home}</span>
            </Link>
          </div>
        </Item>
        <Item key="1" className="header__item">
          <div>
            <Link to="/account">
              <Icon style={{ fontSize: "28px" }} type="user" />
              <span style={{ fontSize: "15.4px" }}>Mi cuenta</span>
            </Link>
          </div>
        </Item>
        <Item key="2" className="header__item" onClick={(e) => logout(e)}>
          <span>
            <Icon style={{ fontSize: "28px" }} type="logout" />
            <span style={{ fontSize: "15.4px" }}>{Strings.generalTerms.logout}</span>
          </span>
        </Item>
      </Menu>
    </Header>
  );
};

export default HeaderContent;
