// Dependencies
import { Divider, Icon, Modal, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import {
  getUncollectedLockerProducts,
  getUncollectedLockerProductsReport,
  listFrequencyUnits,
  listScDependenciesBySoDependencyId,
  listSoDependencyBySoDependencyType0,
  listVendingMachinesByScDependencyId,
} from "../../../actions";

// Components
import { FreqUnitNameLocalizationPlural } from "../../../Utils/LanguageLocalization/frequencyUnitNameLocalization";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { maxRegistersPerReport } from "../../../systemVariables/serverInformation";
import InputWitDropdown from "../../GenericComponents/InputWitDropdown";
import { ExportButton } from "../../GenericComponents/buttons";
import ExportReportsModal from "../../GenericComponents/exportReportModal";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";
import SoScVmDependencySelector from "../../GenericComponents/selectComponent/SoScVmDependencySelector.jsx";
import Titles from "../../GenericComponents/titles";
import NotImg from "../../Global/images/NotImg.png";
import MoreInfoDetailsModal from "./MoreInfoDetailsModal";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

const { Column } = Table;

class LockerProductsReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Selects arrays information
      frequencyUnits: [],

      // Select values
      soDependencyId: null,
      scDependencyId: null,
      scDependencyName: null,
      vendingMachineId: null,
      frequencyUnitId: null,

      checked: false,
      frequencyDisabled: true,
      minutes: 0,
      numberValue: 0,

      checkboxDisabled: true,

      soProducts: [],

      rowDetails: {
        id: null,
        slotNumber: null,
        soProductName: null,
        soProductCode: null,
        preTaxPrice: null,
        productTaxValue: null,
        provisionerId: null,
        provisionerName: null,
        transactionDate: null,
        userId: null,
        userName: null,
        lockerSlotFunctionality: null,
      },

      productImage: NotImg,

      isTableLoading: false,
    };
  }

  componentDidMount() {
    const {
      userDependency: { dependencyId },
      userDependency: { dependencyType },
      soScSelectionProps,

      listFrequencyUnits,
    } = this.props;

    listFrequencyUnits((response) => {
      if (response.data.data) {
        const nativeFrequencyUnits = response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        this.setState({
          frequencyUnits: FreqUnitNameLocalizationPlural(nativeFrequencyUnits),
        });
      }
    });

    if (dependencyType === "so") {
      if (soScSelectionProps.so) {
        this.setState({
          soDependencyId: soScSelectionProps.so.id,
        });
      }

      // Get scDependencyId from store
      if (soScSelectionProps.sc) {
        this.setState({
          scDependencyId: soScSelectionProps.sc.id,
        });
      }
    }

    // ScDependency User
    if (dependencyType === "sc") {
      this.setState({
        scDependencyId: dependencyId,

        vendingMachineId: null,
      });
    }
  }

  // ---------Events----------
  handleSoDependencyChange = (event, name) => {
    const { value: soDependencyId } = event;

    this.setState({
      soDependencyId,
      scDependencyId: null,
      vendingMachineId: null,
      frequencyUnitId: null,

      currentSelector: name,

      soProducts: [],

      isTableLoading: false,
      checkboxDisabled: true,
      frequencyDisabled: true,
      checked: false,
      minutes: 0,
      numberValue: 0,
    });
  };

  handleScDependencyChange = (event, name) => {
    const { value: scDependencyId } = event;

    this.setState({
      scDependencyId,
      vendingMachineId: null,
      frequencyUnitId: null,

      currentSelector: name,

      soProducts: [],

      isTableLoading: false,
      checkboxDisabled: true,
      frequencyDisabled: true,
      checked: false,
      minutes: 0,
      numberValue: 0,
    });
  };

  handleVendingMachineChange = (event, name) => {
    const { value } = event;
    const { getUncollectedLockerProducts } = this.props;

    this.setState({
      vendingMachineId: value,
      frequencyUnitId: null,

      currentSelector: name,

      soProducts: [],

      isTableLoading: true,
      checkboxDisabled: false,
      frequencyDisabled: true,
      checked: false,
      minutes: 0,
      numberValue: 0,
    });

    getUncollectedLockerProducts(value, 0, (response) => {
      this.setState({
        soProducts: response.data.data,
        isTableLoading: false,
      });
    });
  };

  handleOnCheckCheckbox = (e) => {
    const { checked } = e.target;
    this.setState({
      frequencyDisabled: !checked,
      checked,
      minutes: 0,
    });
  };

  handleChangeNumberValue = (event) => {
    const { value } = event.target;
    const reg = /^\d+$/;
    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      this.setState({
        numberValue: value,
      });
    }
  };

  handleKeyDown = (event) => {
    const { frequencyUnitId } = this.state;

    if (event.key === "Enter") {
      if (frequencyUnitId != null) {
        let { minutes } = this.state;
        const { vendingMachineId } = this.state;
        const { getUncollectedLockerProducts } = this.props;

        this.setState({
          soProducts: [],
          isTableLoading: true,
        });

        minutes = this.minutesValue(frequencyUnitId);

        getUncollectedLockerProducts(vendingMachineId, minutes, (response) => {
          this.setState({
            soProducts: response.data.data,
            isTableLoading: false,
            minutes,
          });
        });
      }
    }
  };

  frequencyUnitHandleChange(event) {
    const { value } = event;

    let { minutes } = this.state;
    const { vendingMachineId } = this.state;
    const { getUncollectedLockerProducts } = this.props;

    this.setState({
      frequencyUnitId: value,
      isTableLoading: true,
      soProducts: [],
    });

    minutes = this.minutesValue(value);

    getUncollectedLockerProducts(vendingMachineId, minutes, (response) => {
      this.setState({
        soProducts: response.data.data,
        isTableLoading: false,
        minutes,
      });
    });
  }

  minutesValue = (value) => {
    const { numberValue } = this.state;
    let minutes = 0;

    switch (value) {
      case 0:
        // hours
        minutes = numberValue * 60;
        break;
      case 1:
        // days
        minutes = numberValue * 60 * 24;
        break;
      case 2:
        // weeks
        minutes = numberValue * 60 * 24 * 7;
        break;
      case 3:
        // months
        minutes = numberValue * 60 * 24 * 30;
        break;
      default:
        minutes = numberValue;
    }

    return minutes;
  };

  // getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={(node) => {
  //           this.searchInput = node;
  //         }}
  //         placeholder={`${Strings.generalTerms.search}` + " " + `${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
  //         style={{ width: 188, marginBottom: 8, display: "block" }}
  //       />
  //       <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
  //         {Strings.generalTerms.search}
  //       </Button>
  //       <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
  //         {Strings.generalTerms.restart}
  //       </Button>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  //   onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => this.searchInput.select());
  //     }
  //   },
  //   render: (text) => (
  //     <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[this.state.searchText]} autoEscape textToHighlight={text.toString()} />
  //   ),
  // });

  // handleSearch = (selectedKeys) => {
  //   this.setState({
  //     isTableLoading: true,
  //     erpCode: selectedKeys[0],
  //   });
  //   this.props.listSoProductsBySoDependencyPaginated(
  //     this.state.soDependencyId,
  //     selectedKeys[0],
  //     this.state.page,
  //     this.state.pageSize,
  //     this.state.attribute,
  //     this.state.order,
  //     (response) => {
  //       this.setState({
  //         soProducts: response.data.data.content,
  //         soProductsCount: response.data.data.totalElements,
  //         isTableLoading: false,
  //       });
  //     }
  //   );
  // };

  // handleReset = (clearFilters) => {
  //   clearFilters();
  //   this.setState({
  //     isTableLoading: true,
  //     erpCode: "",
  //   });
  //   this.props.listSoProductsBySoDependencyPaginated(this.state.soDependencyId, "", this.state.page, this.state.pageSize, this.state.attribute, this.state.order, (response) => {
  //     this.setState({
  //       soProducts: response.data.data.content,
  //       soProductsCount: response.data.data.totalElements,
  //       isTableLoading: false,
  //     });
  //   });
  // };

  moreInfoDetails = (e, moreInfoDetails) => {
    this.setState({
      rowDetails: moreInfoDetails,
      isDetailsModalVisible: true,
    });
  };

  setDetailsModalVisible = (isDetailsModalVisible) => {
    this.setState({
      isDetailsModalVisible,
    });
  };

  clearImage = () => {
    this.setState({
      productImage: NotImg,
    });
  };

  clearModal = () => {
    const rowDetails = {
      id: null,
      slotNumber: null,
      soProductName: null,
      soProductCode: null,
      preTaxPrice: null,
      productTaxValue: null,
      provisionerId: null,
      provisionerName: null,
      transactionDate: null,
      userId: null,
      userName: null,
      lockerSlotFunctionality: null,
    };
    this.setState({
      rowDetails,
    });
  };

  // Export methods
  showExportReportsModal = () => {
    const { soProducts } = this.state;
    if (soProducts.length >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible, isExceedModalVisible) => {
    this.setState({
      isExportModalVisible,
      isExceedModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    const { getUncollectedLockerProductsReport } = this.props;
    const { exportLoading, soDependencyId, scDependencyId, vendingMachineId, minutes } = this.state;

    if (!exportLoading) {
      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
      const columnHeaders = [
        Strings.planogram.slotNumber,
        Strings.product.product,
        Strings.product.scProductCode,
        Strings.transaction.transactionType,
        Strings.transaction.uncollectedDays,
      ];

      // Start loading animation on Export button
      this.setState({ exportLoading: true });

      // Consume report export method
      getUncollectedLockerProductsReport(soDependencyId, scDependencyId, vendingMachineId, minutes, fileType, columnHeaders, (response) => {
        if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.reportGenerationError,
          });
          this.setState({
            exportLoading: false,
          });
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", Strings.product.soProductReport + ".csv");
          document.body.appendChild(link);
          link.click();
          this.setState({
            exportLoading: false,
          });
        }
      });
    }
  };

  // showPagination(page, pageSize) {
  //   this.setState({
  //     isTableLoading: true,
  //     page,
  //     pageSize,
  //   });
  //   this.props.listSoProductsBySoDependencyPaginated(this.state.soDependencyId, this.state.erpCode, page, pageSize, this.state.attribute, this.state.order, (response) => {
  //     this.setState({
  //       soProducts: response.data.data.content,
  //       soProductsCount: response.data.data.totalElements,
  //       isTableLoading: false,
  //     });
  //   });
  // }
  lockerRenderTable(soProducts, isTableLoading) {
    return (
      <div className="vertSpace row">
        <div className="col">
          <Table dataSource={soProducts} bordered loading={isTableLoading} pagination size="middle" style={{ width: "100%" }} scroll={{ x: "auto" }} rowKey="id">
            <Column title={Strings.planogram.slotNumber} dataIndex="slotNumber" />
            <Column title={Strings.product.product} render={(row) => <span>{row.soProductName ? row.soProductName : "--"}</span>} />
            <Column title={Strings.product.scProductCode} render={(row) => <span>{row.soProductCode ? row.soProductCode : "--"}</span>} />
            <Column title={Strings.transaction.transactionType} dataIndex="lockerSlotFunctionality" />
            <Column title={Strings.transaction.uncollectedDays} dataIndex="transactionDays" />
            <Column
              title={Strings.generalTerms.details}
              width="70px"
              align="center"
              render={(row) => <Icon type="profile" theme="twoTone" className="addEditRemoveButton" onClick={(e) => this.moreInfoDetails(e, row)} />}
            />
          </Table>
        </div>
      </div>
    );
  }

  render() {
    const {
      isDetailsModalVisible,
      rowDetails,
      productImage,
      isExportModalVisible,
      isExceedModalVisible,
      vendingMachineId,
      checkboxDisabled,
      checked,
      numberValue,
      frequencyDisabled,
      frequencyUnits,
      frequencyUnitId,
      isTableLoading,
      soProducts,
      exportLoading,
    } = this.state;
    const {
      permissions: { data },
    } = this.props;

    return (
      <div className="content-container">
        <MoreInfoDetailsModal
          isVisible={isDetailsModalVisible}
          setVisible={this.setDetailsModalVisible}
          rowDetails={rowDetails}
          image={productImage}
          clearImage={this.clearImage}
          clearModal={this.clearModal}
        />

        <ExportReportsModal
          isExportVisible={isExportModalVisible}
          isExceedVisible={isExceedModalVisible}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
        />

        <div className="row">
          <Titles title={Strings.transaction.uncollectedProducts} tooltip={Strings.transaction.uncollectedProductsTooltip} />
        </div>

        <div className="vertSpace row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <SoScVmDependencySelector
            onSoDependencyChange={this.handleSoDependencyChange}
            onScDependencyChange={this.handleScDependencyChange}
            onVendingMachineChange={this.handleVendingMachineChange}
            soDependencyHtmlTag="SO"
            scDependencyHtmlTag="SC"
            vendingMachineHtmlTag="VM"
            hasSc
            hasVm
            vendingMachineId={vendingMachineId}
          />

          <div className="vertSpace col-md-6">
            <InputWitDropdown
              disabled={checkboxDisabled}
              tooltip
              isChecked={checked}
              onChangeCheckbox={(e) => this.handleOnCheckCheckbox(e)}
              numberValue={numberValue}
              onChangeNumber={(e) => this.handleChangeNumberValue(e)}
              handleKeyDown={(e) => this.handleKeyDown(e)}
              isDisabled={frequencyDisabled}
              options={frequencyUnits}
              onSelect={(e) => this.frequencyUnitHandleChange(e)}
              defaultValue={frequencyUnitId}
            />
          </div>
        </div>
        <Divider orientation="left">
          <h3>{Strings.transaction.uncollectedProducts}</h3>
        </Divider>
        <QueryResponse callback={this.lockerRenderTable(soProducts, isTableLoading)} dataSourceLength={soProducts.length} />
        <ValidatePermissionForComponent permission="PRIVILEGE EXPORT UNCOLLECTED PRODUCTS" permissions={data}>
          <div className="row">
            <div className="vertSpace col">
              <div className="col-md-6" />
              <div className="col-md-6">
                <ExportButton isDisabled={vendingMachineId == null} onClick={() => this.showExportReportsModal()} loading={exportLoading} />
              </div>
            </div>
          </div>
        </ValidatePermissionForComponent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listVendingMachinesByScDependencyId,
  listFrequencyUnits,
  getUncollectedLockerProducts,
  getUncollectedLockerProductsReport,
})(LockerProductsReport);
