import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Table, Icon, Pagination, Popconfirm, Input, Button } from 'antd';
import QueryResponse from "../GenericComponents/queryResponse/QueryResponse";
import { ValidatePermissionForComponentPart } from "../../Utils/validatePermissionForComponentPart";

import Strings from "../../systemVariables/languageStrings";
import moment from 'moment';

const { Column } = Table; // Destructure Table.Column from Ant Design

const CustomTable = (props) => {
  const {
    tableData,
    isloadingInfo,
    page,
    pageSize,
    totalElements,
    columns,
    editable,
    editPrivilege,
    editPath,
    editProps,
    showPagination,
    removable,
    removePrivilege,
    handleDisable,
    permissions: { data },
    handleSearch,
    handleReset,
    onSort,
    sortConfig,
  } = props;

  const getColumnSearchProps = (dataIndex, placeholder, handleSearch, handleReset) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`${Strings.generalTerms.search} ${placeholder}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(dataIndex, selectedKeys)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => handleSearch(dataIndex, selectedKeys)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => [handleReset(), setSelectedKeys([])]} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  const renderTableLocations = (tableData, isloadingInfo, page, totalElements ) => {
    return (
      <div className="vertSpace col-12">
        <Table onChange={onSort} dataSource={tableData} bordered loading={isloadingInfo} pagination={false} size="middle" scroll={{ x: "auto" }} rowKey={obj => obj.uuid}>
          {columns.map((column, index) => (
            <Column
              width={column.width ?? "15%"}
              title={column.title ?? column.name}
              dataIndex={column.name}
              sorter={column.hasSort}
              sortOrder={(column.sortingKey ?? column.name) === sortConfig.field && sortConfig.order}
              key={column.name}
              align="center"
              {...column.hasSearch && getColumnSearchProps(column.name, column.title ?? column.name, handleSearch, handleReset)}
              render={(cell) => {
                return (
                  cell ?
                    (column.attributes ? 
                      column.attributes.map(attr => cell[attr] ?? '').filter(elm => elm !== '').join(' - ') :
                      (column.type === 'date' && column.format ? moment(cell, column.format).toISOString().slice(0, 10) : cell)
                    )
                  : '--'
              )}}
            />
          ))}
          {editable && ValidatePermissionForComponentPart(editPrivilege, data) ? (
            <Column
              width="7%"
              align="center"
              placement="left"
              cursor="pointer"
              key="edit"
              title={Strings.generalTerms.edit}
              render={(row) => (
                <Link
                  to={{
                    pathname: editPath,
                    props: {row, isEdit: true, basicProps: row[editProps]},
                  }}
                >
                  <Icon type="edit" theme="twoTone" className="addEditRemoveButton" />
                </Link>
              )}
            />
          ) : null}
          {removable && ValidatePermissionForComponentPart(removePrivilege, data) ? (
            <Column
              width="7%"
              align="center"
              placement="left"
              cursor="pointer"
              key="delete"
              title={Strings.generalTerms.delete}
              render={(row) => (
                <Popconfirm
                  title={Strings.generalTerms.deleteWarn}
                  onConfirm={() => handleDisable(row)}
                  okText="Sí"
                  cancelText="No"
                >
                  <Icon type="delete" theme="twoTone" className="addEditRemoveButton" />
                </Popconfirm>
              )}
            />
          ) : null}
        </Table>
        <div className="row">
          <div className="col-xs-0 col-lg-6" />
          <div className="vertSpace col-lg-6">
            <Pagination
              size="small"
              total={totalElements}
              showSizeChanger
              onChange={showPagination}
              onShowSizeChange={showPagination}
              hideOnSinglePage={false}
              pageSizeOptions={["10", "25", "50", "100", "250"]}
              showTotal={(total, range) => `${range[0]}-${range[1]} ${Strings.generalTerms.of} ${total}  ${Strings.generalTerms.items}`}
              current={page}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <QueryResponse
        dataSourceLength={tableData.length}
        callback={renderTableLocations(
          tableData,
          isloadingInfo,
          page,
          totalElements,
          showPagination
        )}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj
  };
}

export default connect(mapStateToProps)(CustomTable);
