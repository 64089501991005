import axios from "axios";
import { host, path, port, version } from "../systemVariables";

// Method path
const resource = "/scdependencies";

export const LIST_DEPENDENCIES = "LIST_DEPENDENCIES";
export const USERDEPENDENCIES = "USERDEPENDENCIES";

export function listScDependenciesBySoDependencyId(soDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          sodependency_id: soDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export async function listScDependenciesBySoDependencyIdFn(soDependencyId) {
  try {
    let response = await axios({
      method: "GET",
      url: host + port + path + version + resource,
      params: {
        sodependency_id: soDependencyId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export function listScDependenciesByVenidngMachineIds(vendingMachineIds, callback) {
  return () => {
    const qs = require("qs");
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingmachineids: vendingMachineIds,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function listScDependencyBySoDependencyIdPaginated(soDependencyId, scDependencyTypeId, scDependencyName, uniqueIdentifier, page, size, attribute, order, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          sodependencyid: soDependencyId,
          scdependencyname: scDependencyName,
          uniqueidentifier: uniqueIdentifier,
          scdependencytype: scDependencyTypeId,
          page,
          size,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function addScDependency(scDependencyToAdd, callback) {
  return () => {
    const data = JSON.stringify(scDependencyToAdd);
    axios
      .post(host + port + path + version + resource, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getScDependencyByUniqueIdentifierAndSoDependencyId(uniqueIdentifier, soDependencyId, callback) {
  const resource = "/scdependencies";
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          uniqueidentifier: uniqueIdentifier,
          sodependencyid: soDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function editScDependency(scDependencyToEdit, callback) {
  return () => {
    const data = JSON.stringify(scDependencyToEdit);
    axios({
      method: "put",
      url: host + port + path + version + resource,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getScDependencyById(scDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          id: scDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getCountryNameScDependencyById(scDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource + "/countryName", {
        params: {
          id: scDependencyId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
