import axios from "axios";
import { host, path, port, version } from "../systemVariables";

// Method path
const resource = "/vmproducttransactions";

// Adjustment process

export function getUnderstockedVmProductTransactionWithoutAdjustment(soDependencyId, scDependencyId, vendingMachineId, startDate, endDate, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/understocked/notadjusted`, {
        params: {
          soDependencyId,
          scDependencyId,
          vendingMachineId,
          startDate,
          endDate,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getNotAdjustedUnderstockedVmProductTransactionsByIdAndTransactionDateAfter(id, date, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/understocked/notadjusted/${id}`, {
        params: {
          date,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

//  Provisioning process

export function getVmProductTransactionsByVmProductTransactionIdBetweenProvisioning(vmProductTransactionId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/betweenprovisioningtransactions`, {
        params: {
          vmProductTransactionId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getNVmProductTransactionBeforeVmProductTransactionId(vmProductTransactionId, numberOfRecords, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/previousvmtransactions`, {
        params: {
          vmProductTransactionId,
          numberOfRecords,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getNVmProductTransactionAfterVmProductTransactionId(vmProductTransactionId, numberOfRecords, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/nextvmtransactions`, {
        params: {
          vmProductTransactionId,
          numberOfRecords,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getPagedVmProductTransactionsByStockOwnerIdAndTransactionTypeIdsAndStorageDateBetween(
  stockOwnerId,
  productTransactionTypeIds,
  identityCardNumber,
  startDate,
  endDate,
  paymentType,
  pageNumber,
  pageSize,
  order,
  callback
) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}`, {
        params: {
          stockOwnerId,
          productTransactionTypeIds: productTransactionTypeIds.toString(),
          identityCardNumber,
          startDate,
          endDate,
          paymentType,
          pageNumber,
          pageSize,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getPagedVmProductTransactionsByScDependencyIdAndTransactionTypeIdsAndStorageDateBetween(
  scDependencyId,
  productTransactionTypeIds,
  identityCardNumber,
  startDate,
  endDate,
  paymentType,
  pageNumber,
  pageSize,
  order,
  callback
) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}`, {
        params: {
          scDependencyId,
          productTransactionTypeIds: productTransactionTypeIds.toString(),
          identityCardNumber,
          startDate,
          endDate,
          paymentType,
          pageNumber,
          pageSize,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getPagedVmProductTransactionsByVendingMachineIdAndTransactionTypeIdsAndStorageDateBetween(
  vendingMachineId,
  productTransactionTypeIds,
  identityCardNumber,
  startDate,
  endDate,
  paymentType,
  pageNumber,
  pageSize,
  order,
  callback
) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}`, {
        params: {
          vendingMachineId,
          productTransactionTypeIds: productTransactionTypeIds.toString(),
          identityCardNumber,
          startDate,
          endDate,
          paymentType,
          pageNumber,
          pageSize,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getPagedVmProductTransactionsByScDepartmentIdAndTransactionTypeIdsAndStorageDateBetween(
  scDepartmentId,
  productTransactionTypeIds,
  identityCardNumber,
  startDate,
  endDate,
  paymentType,
  pageNumber,
  pageSize,
  order,
  callback
) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}`, {
        params: {
          scDepartmentId,
          productTransactionTypeIds: productTransactionTypeIds.toString(),
          identityCardNumber,
          startDate,
          endDate,
          paymentType,
          pageNumber,
          pageSize,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getPagedVmProductTransactionsByScUserProfileIdAndTransactionTypeIdsAndStorageDateBetween(
  scUserProfile,
  productTransactionTypeIds,
  identityCardNumber,
  startDate,
  endDate,
  paymentType,
  pageNumber,
  pageSize,
  order,
  callback
) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}`, {
        params: {
          scUserProfile,
          productTransactionTypeIds: productTransactionTypeIds.toString(),
          identityCardNumber,
          startDate,
          endDate,
          paymentType,
          pageNumber,
          pageSize,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getDispensationsReport(
  stockOwnerId,
  scDependencyId,
  vendingMachineId,
  scDepartmentId,
  scUserProfileId,
  indentityCardNumber,
  erpProductCode,
  productTransactionTypeId,
  startDate,
  endDate,
  page,
  pageSize,
  attribute,
  order,
  callback
) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          stockownerid: stockOwnerId.toString(),
          scdependencyid: scDependencyId,
          vendingmachineid: vendingMachineId,
          scdepartmentid: scDepartmentId,
          scuserprofileid: scUserProfileId,
          indentitycardnumber: indentityCardNumber,
          erpproductcode: erpProductCode,
          producttransactiontypeid: productTransactionTypeId.toString(),
          startdate: startDate,
          enddate: endDate,
          page,
          size: pageSize,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getDispensationReportFile(
  stockOwnerId,
  scDependencyId,
  vendingMachineId,
  scDepartmentId,
  scUserProfileId,
  scCostCenterId,
  identityCardNumber,
  erpProductCode,
  productTransactionTypeId,
  startDate,
  endDate,
  target,
  targetsource,
  requestedby,
  paymentTypeId,
  attribute,
  order,
  fileType,
  columnHeaders,
  callback
) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        // responseType: "blob",
        params: {
          allQueryParams: {
            stockownerid: stockOwnerId !== null ? stockOwnerId.toString() : undefined,
            scdependencyid: scDependencyId !== null ? scDependencyId.toString() : undefined,
            vendingmachineid: vendingMachineId !== null ? vendingMachineId.toString() : undefined,
            scdepartmentid: scDepartmentId !== null ? scDepartmentId.toString() : undefined,
            scuserprofileid: scUserProfileId !== null ? scUserProfileId.toString() : undefined,
            sccostcenterid: scCostCenterId !== null ? scCostCenterId.toString() : undefined,
            identitycardnumber: identityCardNumber,
            erpproductcode: erpProductCode,
            producttransactiontypeid: productTransactionTypeId !== null ? productTransactionTypeId.toString() : undefined,
            startdate: startDate,
            enddate: endDate,
            target,
            targetsource,
            requestedby,
            paymenttype: paymentTypeId,
            attribute,
            order,
            filetype: fileType,
            columnheaders: encodeURIComponent(columnHeaders),
            flagSelection: 0,
          }
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function getTransactionsByProvisioningId(provisioningId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}`, {
        params: {
          provisioningId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getTransactionByUuid(uuid, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}`, {
        params: {
          uuid,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getTransactionsStatistics(stockOwnerId, scDependencyId, vendingMachineId, startDate, endDate, freqUnit, timeZone, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/transactionstatistics`, {
        params: {
          stockownerid: stockOwnerId,
          scdependencyid: scDependencyId,
          vendingmachineid: vendingMachineId,
          startdate: startDate,
          enddate: endDate,
          frequencyunit: freqUnit,
          timezone: timeZone,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getTransactionsStatistics2(stockOwnerId, scDependencyId, vendingMachineId, startDate, endDate, freqUnit, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/transactionstatistics2`, {
        params: {
          stockownerid: stockOwnerId,
          scdependencyid: scDependencyId,
          vendingmachineid: vendingMachineId,
          startdate: startDate,
          enddate: endDate,
          frequencyunit: freqUnit,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getStatementsByStockOwnerId(stockOwnerId, startDate, endDate, frequencyUnit, timeZone, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/transactionstatistics`, {
        params: {
          stockownerid: stockOwnerId,
          startdate: startDate,
          enddate: endDate,
          frequencyunit: frequencyUnit,
          timezone: timeZone,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getStatementsByScDependencyId(scDependencyId, startDate, endDate, frequencyUnit, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/transactionstatistics`, {
        params: {
          scdependencyid: scDependencyId,
          startdate: startDate,
          enddate: endDate,
          frequencyunit: frequencyUnit,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getStatementsByVendingMachineId(vendingMachineId, startDate, endDate, frequencyUnit, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/transactionstatistics`, {
        params: {
          vendingmachineid: vendingMachineId,
          startdate: startDate,
          enddate: endDate,
          frequencyunit: frequencyUnit,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getProvisioningDetailsByProvisioningId(provisioningId, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/provisioninginfo`, {
        params: {
          provisioningId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getProvisioningDetailsByProvisioningIdAndFilterSelected(provisioningId, soProductName, scProductName, slot, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/provisioninginfo`, {
        params: {
          provisioningId,
          soProductName,
          scProductName,
          slot,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function exportProvisioningInfoReport(vendingMachineId, moduleType, startDate, endDate, fileType, columnHeaders, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/provisioninginfo`, {
        params: {
          vendingMachineId,
          moduleType,
          startDate,
          endDate,
          fileType,
          columnHeaders: encodeURIComponent(columnHeaders),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getProvisioningListByMachineType(machineType, vendingMachineId, startDate, endDate, user, provisioningId, page, pageSize, attribute, order, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/provisioninginfo`, {
        params: {
          machinetype: machineType,
          vendingmachineid: vendingMachineId,
          startdate: startDate,
          enddate: endDate,
          user,
          provisioningid: provisioningId,
          page,
          size: pageSize,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getLockerProvisioningDetailsByProvisioningIdAndFilter(lockerProvisioningId, slot, transactionTypes, callback) {
  return () => {
    axios
      .get(`${host + port + path + version + resource}/provisioninginfo`, {
        params: {
          lockerprovisioningid: lockerProvisioningId,
          slot,
          producttransactiontypeids: transactionTypes,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function getTransactionsByUserIdPaged(userId, machineId, moduleType, startDate, endDate, page, pageSize, attribute, order, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          userId,
          machineId,
          moduleType,
          startDate,
          endDate,
          page,
          pageSize,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}
