import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/sodsupplier";

export function listSoDSuppliersByParentId(parentId, callback){
  return () => {
    axios.get(host+ port + path  + version + resource,{
        params: {
            sodependencyid: parentId
        },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
    .then((response) => {
        callback(response);
    })
    .catch(function (error){

        callback("Error " + error);
    });
  }
}
