import React, { useContext } from "react"
import { DataContext } from "../../context/DataContext";

// Components
import RenderModalitiesInfo from "./RenderModalitiesInfo";
import RenderSlot from "./RenderSlot";

const LockerInventariView = (props) => {

    const { planogramInfo, slotWidthSize, slotHeightSize, startSlotCreation, dragSlotCreation, endSlotCreation, slotCreationUponClick, setSlotInfo } = useContext(DataContext);

    return (
        <div className="col-xs-12" style={{display:'flex', flexFlow:'row wrap', justifyContent:'center', alignItems:'center'}}>
            <table border="2">
            <thead />
            {props.previousPathDisabled ? (
                <tbody>
                <RenderSlot
                    planogramInfo={planogramInfo}
                    previousPathDisabled={props.previousPathDisabled}
                    slotWidthSize={slotWidthSize}
                    slotHeightSize={slotHeightSize}
                    setSlotInfo={setSlotInfo}
                />
                </tbody>
            ) : (
                <tbody
                onDragStart={(e) => startSlotCreation(e.target.id)}
                onDragOver={(e) => dragSlotCreation(e, e.target.id)}
                onDragEnd={endSlotCreation}
                onClick={(e) => slotCreationUponClick(e.target.id)}
                >
                <RenderSlot 
                    planogramInfo={planogramInfo}
                    previousPathDisabled={props.previousPathDisabled}
                    slotWidthSize={slotWidthSize}
                    slotHeightSize={slotHeightSize}
                    setSlotInfo={setSlotInfo}
                />
                </tbody>
            )}
            </table>
            <br />
            <RenderModalitiesInfo planogramInfo={planogramInfo} />
        </div>
    );
}
 
export default LockerInventariView;