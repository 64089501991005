import { Alert, Divider, Form, Icon, Input, Spin, Table } from "antd";
import React, { Component, createContext } from "react";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import Strings from "../../../systemVariables/languageStrings";
import { GeneralInput } from "../../GenericComponents/InputComponent/index";
import { AddButton } from "../../GenericComponents/buttons/index";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import { UploadImageComponent } from "../../GenericComponents/uploadImageComponent/uploadImageComponent";

const INITIAL_STATE = {
  isWeightReq: false,
  isHeightReq: false,
  isWidthReq: false,
  isDepthReq: false,
};

const EditableContext = createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  save = (e) => {
    const { record, onChangeCell } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      onChangeCell({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: title + Strings.product.isRequired,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input placeholder={title} onChange={this.save} onBlur={this.save} />)}
      </Form.Item>
    );
  };

  render() {
    const { editable, dataIndex, title, record, index, onChangeCell, children, ...restProps } = this.props;
    return <td {...restProps}>{editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}</td>;
  }
}

class SoProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.columns = [
      {
        title: Strings.generalTerms.attribute,
        dataIndex: "attribute",
        width: "40%",
        editable: true,
        align: "Center",
      },
      {
        title: Strings.generalTerms.value,
        dataIndex: "value",
        width: "40%",
        editable: true,
        align: "Center",
      },
      {
        title: Strings.generalTerms.remove,
        align: "center",
        width: "20%",
        render: (row) => (
          <a>
            <Icon style={{ cursor: "pointer", fontSize: 20 }} type="close-circle" theme="twoTone" className="addEditRemoveButton" onClick={(e) => this.props.onDeleteRow(row)} />
          </a>
        ),
      },
    ];

    this.forceUpdate();
  }

  onSelect = (option, event) => {
    const { onSelect } = this.props;
    onSelect(option, event);

    switch (event.name) {
      case "soProductTypeId":
        if (option.value !== 0) {
          this.setState({
            isWeightReq: true,
            isHeightReq: true,
            isWidthReq: true,
            isDepthReq: true,
          });
        } else {
          this.resetState();
        }
        break;
      default:
        break;
    }
  };

  resetState = () => {
    this.setState({ ...INITIAL_STATE });
  };

  render() {
    const { data, soProduct, soProductTypes, prodTaxTypes, currencyCodes, onChange, image, onChangeImage, onAddRow } = this.props;
    const { isWeightReq, isHeightReq, isWidthReq, isDepthReq, dataSource } = this.state;
    const { isLoading } = this.props;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          onChangeCell: this.props.onChangeCell,
        }),
      };
    });
    return (
      <Spin tip={Strings.generalTerms.loading} spinning={isLoading}>
        <div className="vertSpace row">
          <h3>1. {Strings.product.productInformation}</h3>
        </div>
        <div className="row">
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.product.soProductType}
              name="soProductTypeId"
              options={soProductTypes}
              defaultValue={soProduct.soProductTypeId}
              tooltip={Strings.product.soProductTypeTooltip}
              onChange={(option, event) => this.onSelect(option, event)}
            />
          </div>
          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.product.erpCode}
              id="erpProductCode"
              value={soProduct.erpProductCode}
              defaultValue={soProduct.erpProductCode}
              placeholder={Strings.product.erpCode}
              isReq
              tooltip={Strings.product.erpCodeTooltip}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.product.productName}
              id="name"
              value={soProduct.name}
              defaultValue={soProduct.name}
              isReq
              placeholder={Strings.product.productName}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.product.productDescription}
              id="description"
              value={soProduct.description}
              defaultValue={soProduct.description}
              isReq={false}
              placeholder={Strings.product.productDescription}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.product.manufacturingCode}
              id="mfgCode"
              value={soProduct.mfgCode}
              defaultValue={soProduct.mfgCode}
              isReq={false}
              placeholder={Strings.product.manufacturingCode}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.product.minAgeRequired}
              id="minAgeRequired"
              value={soProduct.minAgeRequired}
              defaultValue={soProduct.minAgeRequired}
              isReq={false}
              placeholder={Strings.product.minAgeRequiredTooltip}
              tooltip={Strings.product.minAgeRequiredTooltip}
              onChange={(e) => onChange(e)}
              disabled={ValidatePermissionForComponentPart("PRIVILEGE SET MINIMUM AGE REQUIRED", data) ? 0 : 1}
            />
          </div>
        </div>
        <div className="row">
          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.product.weight}
              id="weight"
              value={soProduct.weight}
              defaultValue={soProduct.weight}
              isReq={isWeightReq}
              placeholder={Strings.product.weight}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.product.height}
              id="height"
              value={soProduct.height}
              defaultValue={soProduct.height}
              isReq={isHeightReq}
              placeholder={Strings.product.height}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.product.width}
              id="width"
              value={soProduct.width}
              defaultValue={soProduct.width}
              isReq={isWidthReq}
              placeholder={Strings.product.width}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.product.depth}
              id="depth"
              value={soProduct.depth}
              defaultValue={soProduct.depth}
              isReq={isDepthReq}
              placeholder={Strings.product.depth}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="vertSpace col-md-6">
            <UploadImageComponent image={image} onChangeImage={onChangeImage} />
            <br />
          </div>
          <div className="col-md-6" />
        </div>
        <div className="vertSpace row">
          <h3>2. {Strings.product.priceInformation}</h3>
        </div>
        <div className="row">
          <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.product.soPreTaxPrice}
              id="preTaxPrice"
              value={soProduct.preTaxPrice}
              defaultValue={soProduct.preTaxPrice}
              isReq
              placeholder={Strings.product.soPreTaxPriceTooltip}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.product.taxValue}
              name="productTaxTypeId"
              options={prodTaxTypes}
              defaultValue={soProduct.productTaxTypeId}
              isReq
              tooltip={Strings.product.soTaxValueTooltip}
              onChange={(option, event) => this.onSelect(option, event)}
            />
          </div>
        </div>
        <div className="row">
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.product.currency}
              name="currencyCodeId"
              options={currencyCodes}
              defaultValue={soProduct.currencyCodeId}
              isReq
              tooltip={Strings.product.currencyTooltip}
              onChange={(option, event) => this.onSelect(option, event)}
            />
          </div>
          {/* <div className="vertSpace col-md-6">
            <GeneralInput
              text={Strings.product.preTaxCost}
              id="preTaxCost"
              value={soProduct.preTaxCost}
              defaultValue={soProduct.preTaxCost}
              isReq={false}
              placeholder={Strings.product.preTaxCost}
              onChange={(e) => onChange(e)}
            />
            <br />
          </div> */}
          <div className=" vertSpace col-md-6" />
        </div>
        <div>
          <div className="vertSpace row">
            <h3> 3. {Strings.generalTerms.details}</h3>
          </div>
          <div className="row">
            <div className="col" align="center">
              {soProduct.details.length > 0 ? (
                <div>
                  <Table
                    pagination={false}
                    columns={columns}
                    components={components}
                    rowClassName={() => "editable-row"}
                    dataSource={soProduct.details}
                    bordered
                    width="100%"
                    rowKey="tempWingId"
                  />
                </div>
              ) : (
                <div className="style-alert-msn">
                  <Alert message={Strings.product.noDetails} description={Strings.product.pressAddButton} type="info" showIcon />
                </div>
              )}
              <AddButton onClick={onAddRow} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Divider />
          </div>
        </div>
      </Spin>
    );
  }
}
export default SoProductForm;
