import {
    DEPENDENCY,
} from '../actions'

const initialState = {
    dependencyObj: [],
}

export function dependencyByUser(state = initialState, action) {
    switch (action.type) {
        case DEPENDENCY:
            return Object.assign({}, state, {dependencyObj: action.payload})
        default:
            return state
    }
}