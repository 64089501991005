import axios from 'axios';
import { host, port, path, version } from '../systemVariables';

// Method path
const resource = '/scroles';

export function getSystemScRoles(scDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource + '/system', {
        params: {
          scDependencyId: scDependencyId
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('access_token')
        }
      })
      .then(response => {
        callback(response);
      })
      .catch(function(error) {
        callback(error);

        return error;
      });
  };
}

export function getVendingMachineScRoles(scDependencyId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource + '/vm', {
        params: {
          scDependencyId: scDependencyId
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('access_token')
        }
      })
      .then(response => {
        callback(response);
      })
      .catch(function(error) {
        callback(error);

        return error;
      });
  };
}
