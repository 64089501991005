import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/vmservicerequest";

export const LIST_VMSERVICEREQUEST = "LIST_VMSERVICEREQUEST";

export function getVmServiceRequestBySoScVmDependencySelector(soid, scid, vmid, vmName, page, size, attribute, order, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          soid,
          scid,
          vmid,
          vmName,
          page,
          size,
          attribute,
          order,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function updateVmServiceRequest(vmServiceRequestUpdateDTO, callback) {
  return () => {
    let data = JSON.stringify(vmServiceRequestUpdateDTO);
    axios({
      method: "post",
      url: `${host + port + path + version + resource}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}
