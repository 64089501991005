import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Modal, Divider, Table, Popconfirm, Icon } from 'antd';
import _ from "lodash"

//Components
import Titles from "../../GenericComponents/titles";
import { GeneralInput } from '../../GenericComponents/InputComponent/generalInput';
import { ReturnButton, SaveButton } from '../../GenericComponents/buttons/index';
import { SelectGeneral } from "../../GenericComponents/selectComponent/selectGeneral"
import { NormalText } from "../../GenericComponents/text/NormalText"

//Actions
import {
    listPermissionTypeByDependencyTypeId,
    listPrivilegesByPermissionTypeId,
    getRoleDetailesById,
    editRoleWithPrivileges,
    disablePrivilegeByRoleId
} from "../../../actions";

//Language Localization
import Strings from '../../../systemVariables/languageStrings'
import { permissionTypeLocalization } from '../../../Utils/LanguageLocalization'

const { Column } = Table;

class EditRoleAndPrivilegeAssignment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dependencyType: this.props.location.props ? this.props.location.props.dependencyTypeId : null,

            dependencyTypeName: this.props.location.props ? this.props.location.props.dependencyTypeName : null,

            soDependencyId: null,
            soDependencyName: null,

            scDependencyId: null,
            scDependencyName: null,

            permissionType: [],

            privileges: [],

            role: {
                id: this.props.location.props ? this.props.location.props.roleInfo ? this.props.location.props.roleInfo.id : null : null,
                roleName: this.props.location.props ? this.props.location.props.roleInfo ? this.props.location.props.roleInfo.roleName : null : null,
                description: this.props.location.props ? this.props.location.props.roleInfo ? this.props.location.props.roleInfo.description : null : null,
                permissiontypeId: this.props.location.props ? this.props.location.props.roleInfo ? this.props.location.props.roleInfo.permissiontypeId : null : null,
            },
            addedPrivileges: [],
            availablePrivileges: [],

            privilegesToDisable: [],
            privilegesToAdd: [],

            loadingInfo: false,
            isSaveButtonLoading: false,
            redirectBackToPrivilegeByRole: false,
            addButtonDisabled: false
        };
    }

    componentDidMount() {
        if (this.state.dependencyType) {
            if (this.state.dependencyType == 1) {
                this.setState({
                    soDependencyId: this.props.location.props ? this.props.location.props.soDependencyId : null,
                    soDependencyName: this.props.location.props ? this.props.location.props.soDependencyName : null,
                })
            }
            if (this.state.dependencyType == 2) {
                this.setState({
                    soDependencyId: this.props.location.props ? this.props.location.props.soDependencyId : null,
                    soDependencyName: this.props.location.props ? this.props.location.props.soDependencyName : null,
                    scDependencyId: this.props.location.props ? this.props.location.props.scDependencyId : null,
                    scDependencyName: this.props.location.props ? this.props.location.props.scDependencyName : null,

                })
            }

            //Get permission types by dependency type
            if (this.state.dependencyTypeName === "stockOwner" && this.props.location.props.soDependencyId == 1) {
                this.props.listPermissionTypeByDependencyTypeId(1, response => {
                    this.setState({
                        permissionType: response.data.data.map(item => ({
                            value: item.id,
                            label: permissionTypeLocalization(item.name)
                        }))
                    })
                })
            }
            if (this.state.dependencyTypeName === "stockOwner" && this.props.location.props.soDependencyId != 1) {
                this.props.listPermissionTypeByDependencyTypeId(2, response => {
                    this.setState({
                        permissionType: response.data.data.map(item => ({
                            value: item.id,
                            label: permissionTypeLocalization(item.name)
                        }))
                    })
                })
            }

            if (this.state.dependencyTypeName === "headquarter" || this.state.dependencyTypeName === "logisticCenter") {
                this.props.listPermissionTypeByDependencyTypeId(3, response => {
                    this.setState({
                        permissionType: response.data.data.map(item => ({
                            value: item.id,
                            label: permissionTypeLocalization(item.name)
                        }))
                    })
                })
            }

            if (this.state.dependencyTypeName === "scDependency") {
                this.props.listPermissionTypeByDependencyTypeId(4, response => {
                    this.setState({
                        permissionType: response.data.data.map(item => ({
                            value: item.id,
                            label: permissionTypeLocalization(item.name)
                        }))
                    })
                })
            }

            this.props.getRoleDetailesById(this.props.location.props.roleInfo.id, response => {
                this.props.listPrivilegesByPermissionTypeId(this.state.role.permissiontypeId, response2 => {
                    this.setState({
                        addedPrivileges: response.data.data,
                        availablePrivileges: _.differenceBy(response2.data.data, response.data.data, "id"),
                        loadingInfo: false
                    })
                })
            })
        }
    }

    handleInput = (event) => {
        const { id, value } = event.target;
        const { role } = { ...this.state };

        role[id] = value;

        if (role.roleName.length > 0) {
            this.setState({
                addButtonDisabled: false
            })
        } else {
            this.setState({
                addButtonDisabled: true
            })
        }

        this.setState({
            role
        });
    };

    handleSelect = (event, name) => {
        const { role } = { ...this.state };

        role[name.name] = event.value;

        this.setState({
            role,
            loadingInfo: true
        })
        this.props.listPrivilegesByPermissionTypeId(event.value, response => {
            this.setState({
                privileges: response.data.data,
                loadingInfo: false
            })
        })

        if (role.roleName.length > 0 && this.state.privilegesToAdd.length > 0 && this.state.role.permissionTypeId != null) {
            this.setState({
                addButtonDisabled: false
            })
        } else {
            this.setState({
                addButtonDisabled: true
            })
        }
    };

    handleEditRole = () => {
        this.setState({
            isSaveButtonLoading: true
        })
        const role = { ...this.state.role }
        role.roleName = _.trim(role.roleName)
        role.description = _.trim(role.description)

        let infoToAdd = {
            role: role,
            privilegesToAdd: this.state.privilegesToAdd,
            privilegesToDisable: this.state.privilegesToDisable,

            dependencyType: this.state.dependencyType,
            dependencyId: this.state.scDependencyId ? this.state.scDependencyId : this.state.soDependencyId
        }

        this.props.editRoleWithPrivileges(infoToAdd, response => {
            if (!response.data && String(response).includes('Error:')) {
                // Connection error
                Modal.error({
                    title: Strings.generalResponses.failedTransaction,
                    content: Strings.generalResponses.saveError
                });
                this.setState({
                    isSaveButtonLoading: false
                })
            } else if (response.data.code === 4001) {
                // Entity Already exists and is enabled
                Modal.error({
                    title: Strings.generalResponses.failedTransaction,
                    content: Strings.generalResponses.alreadyExistsError
                });
                this.setState({
                    isSaveButtonLoading: false
                })
            } else if (response.data.code === 4013) {
                // Entity Already exists and is disabled
                Modal.error({
                    title: 'Strings.generalResponses.failedTransaction',
                    content: 'Strings.generalResponses.alreadyExistsError'
                });
                this.setState({
                    isSaveButtonLoading: false
                })
            } else if (response.data.code === 0) {
                // Entity successfully saved
                Modal.success({
                    title: Strings.generalResponses.successfulCreation,
                    content: `${Strings.generalResponses.creationSuccess}`
                });
                this.props.getRoleDetailesById(this.state.role.id, response2 => {
                    this.props.listPrivilegesByPermissionTypeId(this.state.role.permissiontypeId, response3 => {
                        this.setState({
                            addedPrivileges: response2.data.data,
                            availablePrivileges: _.differenceBy(response3.data.data, response2.data.data, "id"),
                            isSaveButtonLoading: false
                        })
                    })
                })
            }
        })
    }

    handleDisable = (event, row) => {
        this.props.disablePrivilegeByRoleId(this.state.role.id, row.id, response => {
            if (!response.data && String(response).includes('Error:')) {
                // Connection error
                Modal.error({
                    title: Strings.generalResponses.failedTransaction,
                    content: Strings.generalResponses.saveError
                });
            } else if (response.data.code === 4001) {
                // Entity Already exists and is enabled
                Modal.error({
                    title: Strings.generalResponses.failedTransaction,
                    content: Strings.generalResponses.alreadyExistsError
                });
            } else if (response.data.code === 4013) {
                // Entity Already exists and is disabled
                Modal.error({
                    title: 'Strings.generalResponses.failedTransaction',
                    content: 'Strings.generalResponses.alreadyExistsError'
                });
            } else if (response.data.code === 0) {
                // Entity successfully saved
                Modal.success({
                    title: Strings.generalResponses.successfulDelete,
                    content: `${Strings.generalResponses.successTransactionMessage}`
                });
                this.props.getRoleDetailesById(this.state.role.id, response2 => {
                    this.props.listPrivilegesByPermissionTypeId(this.state.role.permissiontypeId, response3 => {
                        this.setState({
                            addedPrivileges: response2.data.data,
                            availablePrivileges: _.differenceBy(response3.data.data, response2.data.data, "id"),
                            isSaveButtonLoading: false
                        })
                    })
                })
            }
        })
    };

    showNoScDependencyWarning = () => {
        Modal.warning({
            title: Strings.scDependency.noDependencySelected,
            onOk: this.handleOkNoScDependencyWarning,
            content: Strings.scDependency.noDependencySelectedMsg
        });
    };

    handleOkNoScDependencyWarning = () => {
        this.setState({
            redirectBackToPrivilegeByRole: true
        });
    };

    render() {
        const { state: { addedPrivileges, availablePrivileges } } = this;

        const privilegesToAdd = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    privilegesToAdd: selectedRowKeys
                })
            }
        };

        if (this.state.redirectBackToPrivilegeByRole) {
            return <Redirect to="/privilegeByRole" />;
        }
        if (!this.state.dependencyType) {
            return <div> {this.showNoScDependencyWarning()} </div>;
        }

        return (
            <div className="content-container">

                <div className="row">
                    <Titles
                        title={Strings.privilegeByRole.editRoleAndAssigPrivileges}
                        tooltip={Strings.privilegeByRole.editRoleAndAssigPrivilegesTooltip}
                    />
                </div>

                <div className="row">
                    <div className="col-12">
                        <Divider orientation="left">
                            <h3>{Strings.soDependency.directClient} </h3>
                        </Divider>
                        <h3>{this.state.soDependencyName}</h3>
                    </div>
                    {
                        this.state.dependencyType == 2 ?
                            <Fragment>
                                <div className="col-md-6">
                                    <Divider orientation="left">
                                        <h3>{Strings.soDependency.directClient} </h3>
                                    </Divider>
                                    <h3>{this.state.soDependencyName}</h3>
                                </div>
                                <div className="col-md-6">
                                    <Divider orientation="left">
                                        <h3>{Strings.scDependency.indirectClient} </h3>
                                    </Divider>
                                    <h3>{this.state.scDependencyName}</h3>
                                </div>
                            </Fragment>
                            :
                            ''
                    }
                </div>

                <div className='row'>
                    <div className="col-12">
                        <Divider orientation="left">
                            <h3> {Strings.generalTerms.generalParameters}</h3>
                        </Divider>
                    </div>

                    <div className="vertSpace col-md-6">
                        <GeneralInput
                            id='roleName'
                            text={Strings.generalTerms.name}
                            tooltip={Strings.dispensationRule.dispensationRuleNameToolTip}
                            placeholder={Strings.generalTerms.name}
                            onChange={(e) => this.handleInput(e)}
                            value={this.state.role.roleName}
                            isReq={true}
                        />
                    </div>
                    <div className="vertSpace col-md-6">
                        <GeneralInput
                            id='description'
                            text={Strings.generalTerms.description}
                            tooltip={Strings.dispensationRule.dispensationRuleDescriptionToolTip}
                            placeholder={Strings.generalTerms.description}
                            onChange={(e) => this.handleInput(e)}
                            value={this.state.role.description}
                            isReq={false}
                        />
                    </div>
                    <div className="vertSpace col-md-6">
                        <SelectGeneral
                            name={'permissiontypeId'}
                            text={Strings.permissionType.permissionType}
                            tooltip={Strings.permissionType.permissionTypeTooltip}
                            options={this.state.permissionType}
                            onChange={(e, name) => this.handleSelect(e, name)}
                            defaultValue={this.state.role.permissiontypeId}
                            disabled={true}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className="vertSpace col-12">
                        <Divider orientation="left">
                            <h3>{Strings.privilegeByRole.assignedPrivileges}</h3>
                        </Divider>
                    </div>

                    <div className="vertSpace col-12">
                        <Table
                            dataSource={addedPrivileges}
                            bordered={true}
                            loading={this.state.loadingInfo}
                            pagination={false}
                            size="middle"
                            scroll={{ x: 'auto' }}
                            rowKey='id'
                        >
                            <Column
                                title={
                                    <Icon
                                        type="delete"
                                        theme="twoTone"
                                    />
                                }
                                width={'50px'}
                                render={row => (
                                    <div align='center'>
                                        <Popconfirm
                                            title={Strings.generalResponses.confirmDeleteMessage}
                                            onConfirm={(e) => this.handleDisable(e, row)}
                                            okText="Sí"
                                            cancelText="No"
                                        >
                                            <Icon
                                                type="delete"
                                                theme="twoTone"
                                                className="addEditRemoveButton"
                                            />
                                        </Popconfirm>
                                    </div>
                                )}
                            />
                            <Column
                                title={Strings.generalTerms.name}
                                dataIndex='name'
                            />
                            <Column
                                title={Strings.generalTerms.description}
                                dataIndex='description'
                            />
                        </Table>
                    </div>
                </div>

                <div className='row'>
                    <div className="vertSpace col-12">
                        <Divider orientation="left">
                            <h3>{Strings.privilegeByRole.availablePrivileges}</h3>
                        </Divider>
                    </div>

                    <div className="vertSpace col-12" style={{ height: '400px', overflowY: 'scroll' }}>
                        <Table
                            dataSource={availablePrivileges}
                            bordered={true}
                            loading={this.state.loadingInfo}
                            pagination={false}
                            size="middle"
                            scroll={{ x: '100%' }}
                            rowKey='id'
                            rowSelection={privilegesToAdd}
                        >
                            <Column
                                title={Strings.generalTerms.name}
                                dataIndex='name'
                            />
                            <Column
                                title={Strings.generalTerms.description}
                                dataIndex='description'
                            />
                        </Table>
                    </div>
                </div>

                <div className="row justify-content-end">
                    <div className="vertSpace col-6 col-md-3">
                        <ReturnButton link="/privilegeByRole" />
                    </div>
                    <div className="vertSpace col-6 col-md-3">
                        <SaveButton
                            loading={this.state.isSaveButtonLoading}
                            onClick={e => this.handleEditRole(e)}
                            isDisabled={this.state.addButtonDisabled}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        soDependencies: state.soDependenciesType0,
        userDependency: state.dependencyByUser.dependencyObj,
        permissions: state.userSystemPrivileges.privilegeObj,
        soDependencyParent: state.soDependencyParent.soParent
    }
}

export default connect(mapStateToProps, {
    listPermissionTypeByDependencyTypeId,
    listPrivilegesByPermissionTypeId,
    getRoleDetailesById,
    editRoleWithPrivileges,
    disablePrivilegeByRoleId
})(EditRoleAndPrivilegeAssignment)