// Dependencies
import React from "react";
import { Divider } from "antd";
import moment from "moment";

import "../css/footer.css";
import logo from "../images/noatecLogo1.png";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

function Footer() {
  return (
    <div className="col-12" id="footer">
      <div className="col-md-10">
        <a className="footer__link" href="https://www.noatec.co/quienes-somos/">
          {Strings.generalTerms.companyInfo.aboutUs}
        </a>{" "}
        {/* <Divider className="footer__link-section__divider" type="vertical" />
        <Link className="footer__link" to="/">
          {Strings.generalTerms.companyInfo.termnsAndConditions}
        </Link>{" "} */}
        <Divider className="footer__link-section__divider" type="vertical" />
        <a className="footer__link" href="https://www.noatec.co/datapolicy">
          {Strings.generalTerms.companyInfo.privacyPolicies}
        </a>
        <Divider className="footer__link-section__divider" type="vertical" />
        <a className="footer__link" href="https://www.noatec.co/contacto/">
          {Strings.generalTerms.companyInfo.contactUs}
        </a>
        <p id="footer-copy-right">
          &copy; {moment().year()} Noatec S.A.S, {Strings.generalTerms.companyInfo.copyRight}.
        </p>
      </div>

      <div className="col-md-2">
        <img className="company-logo" src={logo} alt="companyLogo" />
      </div>
    </div>
  );
}

export default Footer;
