import { LIST_NOAHUBKEYLIST } from '../actions'

export function noahub(state = [], action) {
    switch (action.type) {
        case LIST_NOAHUBKEYLIST:
            var nohub = action.payload.data.map(item => ({value:item.peripheralDeviceReferenceId, label:item.serialNumber}))
            return nohub;
        default:
            return state
    }
}