import Strings from "../../systemVariables/languageStrings";

export function TrayPositionNameLocalization(trayPositionName) {
  let trayPositionNameL = "";
  switch (trayPositionName) {
    case "Careless":
      trayPositionNameL = Strings.planogram.trayPositions.careless;
      break;
    case "MostTop":
      trayPositionNameL = Strings.planogram.trayPositions.mostTop;
      break;
    case "Top":
      trayPositionNameL = Strings.planogram.trayPositions.top;
      break;
    case "Middle":
      trayPositionNameL = Strings.planogram.trayPositions.middle;
      break;
    case "Bottom":
      trayPositionNameL = Strings.planogram.trayPositions.bottom;
      break;
    case "MostBottom":
      trayPositionNameL = Strings.planogram.trayPositions.mostBottom;
      break;
    default:
      break;
  }
  return trayPositionNameL;
}

export function trayPositionNameLocalizationArray(trayPositions) {
  trayPositions.forEach((item) => {
    switch (item.label) {
      case "Careless":
        item.label = Strings.planogram.trayPositions.careless;
        break;
      case "MostTop":
        item.label = Strings.planogram.trayPositions.mostTop;
        break;
      case "Top":
        item.label = Strings.planogram.trayPositions.top;
        break;
      case "Middle":
        item.label = Strings.planogram.trayPositions.middle;
        break;
      case "Bottom":
        item.label = Strings.planogram.trayPositions.bottom;
        break;
      case "MostBottom":
        item.label = Strings.planogram.trayPositions.mostBottom;
        break;
      default:
        break;
    }
  });
  return trayPositions;
}
