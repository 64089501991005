import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/vmsetup";

export const LIST_VMSETUP = "LIST_VMSETUP";

export function listMaxTrayCapacityByVmId(vmId, callback) {
  // To delete
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vendingmachineid: vmId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: LIST_VMSETUP, payload: response.data });
        callback(response);
      })
      .catch(function (error) {
        return error;
      });
  };
}

export function listVmSetupByVmId(vmId, accessLevelId, vmParameterCategoryId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          vmid: vmId,
          accesslevelid: accessLevelId,
          vmparametercategoryid: vmParameterCategoryId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export function updateVmSetupById(vmSetupId, newValue, callback) {
  return () => {
    axios({
      url: host + port + path + version + resource,
      method: "put",
      params: {
        vmsetupid: vmSetupId,
        newvalue: newValue,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}
