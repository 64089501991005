// Dependencies
import React, { Component } from "react";
import "../Global/css/page404.css";
import { Icon, Typography } from "antd";
import Strings from "../../systemVariables/languageStrings";

const { Title, Text } = Typography;
const { generalResponses } = Strings;

class error404 extends Component {
  render() {
    return (
      <div className="warning-container">
        <Title type="danger" level={1}>
          404 <Icon type="file-unknown" />
        </Title>
        <Text strong className="error">
          {generalResponses.pageNoFound}
        </Text>
        <hr />
        <div className="bottom-content">
          <span>
            <Text disabled className="check-url">
              {generalResponses.pageNoFoundUrl}
            </Text>
            <Text disabled className="otherwise">
              {" "}
              <a href="/">{generalResponses.clickHere}</a> {generalResponses.clickHereUndirection}
            </Text>
          </span>
        </div>
      </div>
    );
  }
}

export default error404;
