// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal, Divider, Button, Icon, Tooltip } from "antd";

import {
  addVendingMachine,
  getAllAvailableNoaHubsOrNoaEmbeddedComputersByMachineGeneration,
  listFirmwareVersions,
  getAllMachinGenerations,
  getFirmwareVersionsByMachineGenerationId,
} from "../../actions";

// Components
import { SelectGeneral } from "../GenericComponents/selectComponent/index";
import Titles from "../GenericComponents/titles";
import { SaveButton, ReturnButton } from "../GenericComponents/buttons/index";
import { GeneralInput } from "../GenericComponents/InputComponent/generalInput";
import WingConfigModal from "./VMInfoPanelViews/wingConfigModal";
import WingsConfigToAddTable from "./wingsConfigToAddTable";

// Language localization
import Strings from "../../systemVariables/languageStrings";

import "./createVendingMachine.css";

let modalVisible = false;

class CreateVendingMachine extends Component {
  constructor(props) {
    super(props);
    const { location, getAllMachinGenerations } = this.props;
    this.state = {
      machineToCreate: {
        stockOwnerId: location.props ? location.props.soDependencyId : null,
        stockOwnerName: location.props ? location.props.soDependencyName : null,
        scDependencyId: location.props ? location.props.scDependencyId : 0,
        scDependencyName: location.props ? location.props.scDependencyName : null,
        wingsNumber: null,
        friendlyName: null,
        vmKeyListId: null,
        peripheralDeviceId: null,
      },
      machineGenerations: [],
      firmwareVersion: [],
      isMachineGenerationDisabled: true,
      isFirmwareVersionDisabled: true,
      isNoahubKeyDisabled: true,
      loading: false,
      wingInfoList: [],
      wingModalIsVisible: false,
      tempWingId: 0,
      redirectBackToVmList: false,
      noahubList: [],
      keyPrincipalModule: 0,
      controlModuleSlotNumber: 2,
    };

    // Bind Modal button callback so View state variables can be used
    this.handleOkNoSOWarning = this.handleOkNoSOWarning.bind(this);

    // Machine generation for vm creation
    getAllMachinGenerations((response) => {
      this.setState({
        machineGenerations: response.data.data.map((obj) => ({
          value: obj.id,
          label: obj.generationString,
        })),
        isMachineGenerationDisabled: false,
      });
    });
  }

  // Events
  nameInputHandleChang(event) {
    const { value } = event.target;
    const { machineToCreate } = { ...this.state };
    machineToCreate.friendlyName = value;
    this.setState({ machineToCreate });
  }

  handleMachineGenerationChange(event) {
    const { getFirmwareVersionsByMachineGenerationId, getAllAvailableNoaHubsOrNoaEmbeddedComputersByMachineGeneration } = this.props;
    const { machineToCreate } = { ...this.state };
    machineToCreate.machineGenerationId = event.value;
    machineToCreate.vmFirmwareId = null;
    machineToCreate.peripheralDeviceId = null;

    this.setState({
      machineToCreate,
      isMachineGenerationDisabled: true,
      isFirmwareVersionDisabled: true,
      isNoahubKeyDisabled: true,
    });

    getFirmwareVersionsByMachineGenerationId(event.value, (response) => {
      this.setState({
        firmwareVersion: response.data.data.map((item) => ({
          value: item.id,
          label: item.versionString,
        })),
        isMachineGenerationDisabled: false,
        isFirmwareVersionDisabled: false,
      });
    });

    getAllAvailableNoaHubsOrNoaEmbeddedComputersByMachineGeneration(1, event.value, (response) => {
      const noahubsMapped = [];
      response.data.data.forEach((noahub) => {
        noahubsMapped.push({
          value: noahub.id,
          label: noahub.alternativeSerialNumber
            ? `${noahub.serialNumber} / ${noahub.alternativeSerialNumber}`
            : `${noahub.serialNumber} / ${Strings.peripheralDevice.noAlternativeSerial}`,
        });
      });
      this.setState({ noahubList: noahubsMapped, isNoahubKeyDisabled: false });
    });
  }

  handleFirmwareChange(event) {
    const { machineToCreate } = { ...this.state };
    machineToCreate.vmFirmwareId = event.value;
    this.setState({ machineToCreate });
  }

  handleNoahubKeylistChange(event) {
    const { machineToCreate } = { ...this.state };
    machineToCreate.peripheralDeviceId = event.value;
    this.setState({ machineToCreate });
  }

  wingConfigModal() {
    this.setState({ wingModalIsVisible: true });
  }

  setWingModalVisible = (isVisible) => {
    this.setState({ wingModalIsVisible: isVisible });
  };

  wingInfo = (wingInfo) => {
    const { wingInfoList, tempWingId } = this.state;
    const wingsInfo = wingInfoList;
    const wingInfoTemp = wingInfo;
    wingInfoTemp.tempWingId = tempWingId + 1;
    wingsInfo.push(wingInfoTemp);

    this.setState({
      wingInfoList: wingsInfo,
      tempWingId: wingInfoTemp.tempWingId,
    });

    const { machineToCreate } = { ...this.state };
    machineToCreate.wingsNumber = wingInfoList.length;
    this.setState({ machineToCreate });
  };

  removeWing(row) {
    const { wingInfoList, keyPrincipalModule } = this.state;
    
    if (keyPrincipalModule >= wingInfoList.indexOf(row) && keyPrincipalModule != 0) {
      var newKeyPrincipalModule = keyPrincipalModule - 1
    } else {
      var newKeyPrincipalModule = keyPrincipalModule
    }
    this.setState({
      wingInfoList: wingInfoList.filter((item) => item.tempWingId !== row.tempWingId),
      keyPrincipalModule: newKeyPrincipalModule
      //   isDisabledAddButton: false,
    });
  }

  togglePrincipalModule = e => {
    this.setState({
      keyPrincipalModule: e.target.value,
    });
  };

  onChangeControlModuleSlotNumber = (value) => {
    this.setState({
      controlModuleSlotNumber: value,
    });
  }

  addVMEvent() {
    const { machineToCreate, wingInfoList, keyPrincipalModule, controlModuleSlotNumber} = this.state;
    const { addVendingMachine } = this.props;

    this.setState({
      loading: true,
    });

    // Build machine dto to create Object
    const soDepOk = machineToCreate.stockOwnerId != null;
    const machineGenerationOk = machineToCreate.machineGenerationId != null;
    const nameOk = machineToCreate.friendlyName != null;
    const firmwareVersionOk = machineToCreate.vmFirmwareId != null;
    const noahubOk = machineToCreate.peripheralDeviceId != null;
    const wingDtoOK = wingInfoList.length > 0;

    if (wingInfoList[keyPrincipalModule].wingTypeId === 3) {
      wingInfoList[keyPrincipalModule].controlModuleSlot = controlModuleSlotNumber;
    } else {
      wingInfoList[keyPrincipalModule].controlModuleSlot = 0;
    }

    // Build wing info Object
    const wingDtoList = [];
    wingInfoList.forEach((wingInfo) => {
      const lockerSlotTypeIds = [];
      if (wingInfo.lockerSlotTypes.length > 0) {
        wingInfo.lockerSlotTypes.forEach((item) => {
          lockerSlotTypeIds.push(parseInt(item.id, 10));
        });
      }
      const wingDto = {
        wingNumber: wingDtoList.length + 1,
        wingTypeId: wingInfo.wingTypeId,
        lockerTypeId: wingInfo.lockerTypeId ? wingInfo.lockerTypeId : 0,
        lockerSlotTypeIds: lockerSlotTypeIds.length > 0 ? lockerSlotTypeIds : [0],
        controlModuleSlot: wingInfo.controlModuleSlot,
      };
      wingDtoList.push(wingDto);
    });

    // Build final object
    const machineDto = {
      vendingMachineDto: machineToCreate || 0,
      wingDtoList: wingDtoList.length > 0 ? wingDtoList : 0,
    };

    // Validate Object
    if (soDepOk && machineGenerationOk && nameOk && wingDtoOK && firmwareVersionOk && noahubOk) {
      // Post Object
      addVendingMachine(machineDto, (response) => {
        this.setState({ loading: false });
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            loading: false,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.setState({ redirectBackToVmList: true }),
          });
          this.setState({
            loading: false,
          });
        } else {
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            loading: false,
          });
        }
      });
    } else if (wingInfoList.length === 0) {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.machine.wingInvalidParameter,
      });
      this.setState({
        loading: false,
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
      this.setState({
        loading: false,
      });
    }
  }

  noSoWarning() {
    modalVisible = true;
    Modal.warning({
      title: Strings.machine.noSoSelected,
      onOk: this.handleOkNoSOWarning,
      content: Strings.machine.noSoSelectedMsg,
    });
  }

  handleOkNoSOWarning() {
    this.setState({ redirectBackToVmList: true });
  }

  render() {
    const {
      firmwareVersion,
      isMachineGenerationDisabled,
      isFirmwareVersionDisabled,
      isNoahubKeyDisabled,
      redirectBackToVmList,
      machineToCreate,
      wingModalIsVisible,
      noahubList,
      wingInfoList,
      loading,
      machineGenerations,
      keyPrincipalModule,
      controlModuleSlotNumber
    } = this.state;

    if (redirectBackToVmList) {
      // Redirects to VMList from No SO Modal Warning
      modalVisible = false;
      // eslint-disable-next-line react/jsx-filename-extension
      return <Redirect to="/listVM" />;
    }

    if (!machineToCreate.stockOwnerId && !modalVisible) {
      // Shows No SO Modal Warning
      return <div>{this.noSoWarning()}</div>;
    }

    return (
      <div className="content-container">
        <WingConfigModal setIsVisible={this.setWingModalVisible} isVisible={wingModalIsVisible} wingInfo={this.wingInfo} principalWing={wingInfoList.length > 0 ? wingInfoList[keyPrincipalModule] : null}/>

        <div className="row">
          <div className="col-12">
            <Titles title={Strings.machine.newMachine} tooltip={Strings.machine.newMachineTooltip} />
          </div>
        </div>

        <div className="row">
          {machineToCreate.stockOwnerId && machineToCreate.scDependencyId ? (
            <div className="vertSpace col-12 col-md-12">
              <div className="col-md-6">
                <Divider orientation="left">
                  <h3>{Strings.soDependency.directClient} </h3>
                </Divider>
                <div className="col-md-12">
                  <h3>{machineToCreate.stockOwnerName}</h3>
                </div>
              </div>
              <div className="col-md-6">
                <Divider orientation="left">
                  <h3>{Strings.scDependency.indirectClient} </h3>
                </Divider>
                <div className="col-md-12">
                  <h3>{machineToCreate.scDependencyName}</h3>
                </div>
              </div>
            </div>
          ) : null}

          {machineToCreate.stockOwnerId && !machineToCreate.scDependencyId ? (
            <div className="vertSpace col-12 col-md-12">
              <div className="col-md-12">
                <Divider orientation="left">
                  <h3>{Strings.soDependency.directClient} </h3>
                </Divider>
                <div className="col-md-12">
                  <h3>{machineToCreate.stockOwnerName}</h3>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <div className="col-md-6">
              <GeneralInput
                text={Strings.generalTerms.name}
                tooltip={Strings.machine.nameTooltip}
                isReq
                placeholder={Strings.generalTerms.name}
                onChange={(e) => this.nameInputHandleChang(e)}
                value={machineToCreate.friendlyName}
              />
            </div>
            <div className="col-md-6">
              <SelectGeneral
                text={Strings.machine.machineGeneration}
                tooltip={Strings.machine.machineGenerationTooltip}
                options={machineGenerations}
                isReq
                onChange={(e) => this.handleMachineGenerationChange(e)}
                defaultValue={machineToCreate.machineGenerationId}
                disabled={isMachineGenerationDisabled || machineGenerations.length === 0}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.machine.noahubSerial}
                tooltip={Strings.machine.noahubSerialTooltip}
                options={noahubList}
                isReq
                onChange={(e) => this.handleNoahubKeylistChange(e)}
                defaultValue={machineToCreate.peripheralDeviceId}
                disabled={isNoahubKeyDisabled || noahubList.length === 0}
              />
            </div>
            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.machine.firmwareVersion}
                tooltip={Strings.machine.firmwareVersionTooltip}
                options={firmwareVersion}
                isReq
                onChange={(e) => this.handleFirmwareChange(e)}
                defaultValue={machineToCreate.vmFirmwareId}
                disabled={isFirmwareVersionDisabled || firmwareVersion.length === 0}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <div className="vertSpace col-md-12">
              <Tooltip placement="top" title={Strings.machine.addWingTooltip}>
                <Button onClick={() => this.wingConfigModal()} size="large" style={{ width: "40%" }}>
                  <Icon type="plus-circle" theme="twoTone" twoToneColor="#52c41a" />
                  {Strings.machine.wingAddition}
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>

        {wingInfoList.length > 0 ? (
          <div>
            <div className="row">
              <div className="col-12">
                <Divider orientation="left">
                  <h3>{Strings.machine.wingsConfigured} </h3>
                </Divider>
              </div>
            </div>

            <div>
              <div className="row">
                <div className="col-12">
                  <WingsConfigToAddTable 
                    wingInfo={wingInfoList} 
                    removeWing={(row) => this.removeWing(row)} 
                    keyPrincipalModule={keyPrincipalModule} 
                    togglePrincipalModule={e => this.togglePrincipalModule(e)} 
                    onChangeControlModuleSlotNumber={value => this.onChangeControlModuleSlotNumber(value)}
                    controlModuleSlotNumber={controlModuleSlotNumber}/>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-12">
            <Divider />
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="vertSpace col-6 col-md-3">
            <ReturnButton link="/listVM" isDisabled={loading} />
          </div>
          <div className="vertSpace col-6 col-md-3">
            <SaveButton
              onClick={(e) => this.addVMEvent(e)}
              loading={loading}
              isDisabled={
                machineToCreate.friendlyName === null ||
                machineToCreate.machineGenerationId === null ||
                machineToCreate.firmwareVersion === null ||
                machineToCreate.peripheralDeviceId === null ||
                wingInfoList.length === 0 ||
                wingInfoList[keyPrincipalModule].wingTypeId == 3 && wingInfoList.map(wingInfo => wingInfo.wingTypeId != 3).includes(true)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    noahub: state.noahubKeylist,
    firmwareVersion: state.firmwareVersion,
  };
}

export default connect(mapStateToProps, {
  addVendingMachine,
  getAllAvailableNoaHubsOrNoaEmbeddedComputersByMachineGeneration,
  listFirmwareVersions,
  getAllMachinGenerations,
  getFirmwareVersionsByMachineGenerationId,
})(CreateVendingMachine);
