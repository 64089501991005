import axios from "axios";
import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/slotgroups";

export function getSlotGroupByWingId(wingId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          wingId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error);
      });
  };
}
