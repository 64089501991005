import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Table, Divider } from "antd";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";

//Actions
import { listProductAssignmentByDispensationRuleId } from "../../actions/index.js";

// Components
import Loading from "../GenericComponents/loadingComponent/loading";

//Language localization
import Strings from "../../systemVariables/languageStrings";

//CSS
import "./dispensationRule.css";
import "./addDispensationRules.css";

class DispensationRuleDetailsModal extends Component {
  handleButton = () => {
    this.props.setIsVisible(false);
  };

  render() {
    const soProductColumns = [
      {
        title: <TextWithInfoTooltip name={Strings.product.erpCode} tooltip={Strings.product.erpCodeTooltip} />,
        dataIndex: "erpProductCode",
      },
      {
        title: Strings.product.productName,
        dataIndex: "soProductName",
      },
      {
        title: Strings.dispensationRule.amount,
        align: "center",
        dataIndex: "allowedAmount",
      },
      {
        title: Strings.dispensationRule.frequency,
        align: "center",
        dataIndex: "frequencyValue",
      },
      {
        title: Strings.dispensationRule.frequencyUnit,
        align: "center",
        dataIndex: "frequencyUnitName",
      },
    ];

    const vmProductGroupColumns = [
      {
        title: <TextWithInfoTooltip name={Strings.vmProductGroup.vmProductGroup} tooltip={Strings.vmProductGroup.vmProductGroupNameToolTip} />,
        dataIndex: "name",
      },
      {
        title: Strings.generalTerms.description,
        dataIndex: "description",
      },
      {
        title: Strings.dispensationRule.amount,
        align: "center",
        dataIndex: "allowedAmount",
      },
      {
        title: Strings.dispensationRule.frequency,
        align: "center",
        dataIndex: "frequencyValue",
      },
      {
        title: Strings.dispensationRule.frequencyUnit,
        align: "center",
        dataIndex: "frequencyUnitName",
      },
    ];

    return this.props.scDispRuleRowSelected != null ? (
      <Modal
        title={
          <div className="row">
            <div className="alignLabel col-12">
              <h2>{Strings.dispensationRule.dispenationRuleDetails}</h2>
            </div>
          </div>
        }
        onCancel={this.handleButton}
        width={1200}
        visible={this.props.isVisible}
        footer={null}
        centered={true}
      >
        <div className="vertSpace row col-12">
          <div className="col-6">
            <div className="row">
              <div className="col-md-6">
                <span>
                  <b>{Strings.dispensationRule.dispensationRule + ": "}</b>
                </span>
              </div>
              <div className="col-md-6">
                <span>{this.props.scDispRuleRowSelected.name}</span>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-md-6">
                <span>
                  <b>{Strings.scDependency.indirectClient + ": "}</b>
                </span>
              </div>
              <div className="col-md-6">
                <span>{this.props.scDependency}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="vertSpace row col-12">
          <div className="col-6">
            <div className="row">
              <div className="col-md-6">
                <span>
                  <b>{Strings.dispensationRule.startDate + ": "}</b>
                </span>
              </div>
              <div className="col-md-6">
                <span>{this.props.scDispRuleRowSelected.startDate}</span>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-md-6">
                <span>
                  <b>{Strings.dispensationRule.endDate + ": "}</b>
                </span>
              </div>
              <div className="col-md-6">
                <span>{this.props.scDispRuleRowSelected.endDate}</span>
              </div>
            </div>
          </div>
        </div>

        <Divider></Divider>

        <div className="row">
          {(this.props.productAssignmentsWithoutProductGroupId ? (this.props.productAssignmentsWithoutProductGroupId.length === 0 ? false : true) : false) ? (
            <div className="vertSpace col-12">
              <div className="vertSpace col-12"></div>

              <div>
                {this.props.productAssignmentsWithoutProductGroupId.lenght}
                <h3>{Strings.product.individualConfigBySelectedSoProduct}</h3>
                <Table
                  dataSource={this.props.productAssignmentsWithoutProductGroupId}
                  bordered={true}
                  loading={this.props.isLoading}
                  pagination={false}
                  rowKey={"erpProductCode"}
                  columns={soProductColumns}
                  scroll={{ x: "auto" }}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {(this.props.vmProductGroupDispensationRule ? (this.props.vmProductGroupDispensationRule.length === 0 ? false : true) : false) ? (
            <div className="vertSpace col-12">
              <div className="vertSpace col-12"></div>

              <div>
                <h3>{Strings.vmProductGroup.configurationBySelectedVmProductGroup}</h3>
                <Table
                  dataSource={this.props.vmProductGroupDispensationRule}
                  bordered={true}
                  loading={this.props.isLoading}
                  pagination={false}
                  rowKey={"name"}
                  columns={vmProductGroupColumns}
                  scroll={{ x: "auto" }}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
    ) : (
      <Modal
        title={Strings.dispensationRule.dispensationRule + ": " + this.props.scDispRuleName + " (" + this.props.scDependency + ")"}
        onCancel={this.handleButton}
        width={1200}
        visible={this.props.isVisible}
        footer={null}
        centered={true}
      >
        <div className="row">
          <Loading />
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  listProductAssignmentByDispensationRuleId,
})(DispensationRuleDetailsModal);
