/* eslint-disable react/no-unused-state */
// Dependencies
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, Modal, Divider } from "antd";

// Components
import Titles from "../GenericComponents/titles";
import { ReturnButton } from "../GenericComponents/buttons";
import VendingMachineSetup from "./VMInfoPanelViews/VendingMachineSetup";
import VendingMachineGeneralInfo from "./VMInfoPanelViews/vendingMachineGeneralInfo";
import VendingMachinePeripheralDevices from "./VMInfoPanelViews/VendingMachinePeripheralDevices";
import MachineSync from "./VMInfoPanelViews/machineSync";
import LockerLoanConfig from "./VMInfoPanelViews/lockerLoanConfig";
import VmEventMessaging from "./VMInfoPanelViews/VmEventMessaging";
import EditNameVm from "./VMInfoPanelViews/EditNameVm";
import { ValidatePermissionForComponentPart } from "../../Utils/validatePermissionForComponentPart";

// Language localization
import Strings from "../../systemVariables/languageStrings";

// Actions
import { getLockerSlotPriceBySizeInfo, listVmSetupByVmId } from "../../actions";

const { TabPane } = Tabs;

const VENDING_MACHINE_SETUP = "2";
const ACCESS_LEVEL_ID = 4;
const VM_PARAMETER_CATEGORY = 0;

class VendingMachineInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      machine: null,
      vmId: null,
      lockerLoanConfigInfo: [],
      machineIsConfing: null,
      machineIsConfingIsLoading: false,
      isChangeFriendlyName: null,
    };
  }

  componentDidMount() {
    const { location, getLockerSlotPriceBySizeInfo } = this.props;

    if (location.props && location.props.vm) {
      this.setState({
        machine: location.props.vm,
        vmId: location.props.vm.id,
      });

      getLockerSlotPriceBySizeInfo(location.props.vm.id, (response) => {
        if (response.data.data.length > 0) {
          this.setState({ lockerLoanConfigInfo: response.data.data });
        }
      });
    } else {
      this.noVMWarning();
    }
  }

  // -----Events-----
  handleNoVMAcceptButton = () => {
    this.setState({ redirectToVMList: true });
  };

  noVMWarning = () => {
    Modal.warning({
      title: Strings.machine.noVMSelected,
      onOk: this.handleNoVMAcceptButton,
      content: Strings.machine.noVMSelectedMsg,
    });
  };

  callback = async (key) => {
    const { listVmSetupByVmId } = this.props;
    const { vmId } = this.state;
    if (key === VENDING_MACHINE_SETUP) {
      this.setState({ machineIsConfingIsLoading: true });
      await listVmSetupByVmId(vmId, ACCESS_LEVEL_ID, VM_PARAMETER_CATEGORY, (response) => {
        this.responseQueryService(response);
      });
    }
  };

  responseQueryService = async (ans) => {
    const resultQuery =
      (ans.status || ans.message,
      {
        200: () => {
          this.setState({
            machineIsConfing: ans.data.data.filter((obj) => obj.setupparameterid === 22).slice(-1)[0].stringValue,
          });
          this.setState({ machineIsConfingIsLoading: false });
        },
        "Network Error": () => {
          Modal.error({
            title: Strings.generalResponses.connectionError,
            content: Strings.generalResponses.connectionErrorTooltip,
          });
          this.setState({ machineIsConfingIsLoading: false });
        },
      });
    await resultQuery[ans.status || ans.message]();
  };

  callbackParentFinal = (result) => {
    this.setState({
      isChangeFriendlyName: result,
    });
  };

  render() {
    const { permissions } = this.props;
    const { redirectToVMList, machine, lockerLoanConfigInfo, machineIsConfing, machineIsConfingIsLoading, isChangeFriendlyName } = this.state;
    if (redirectToVMList) {
      return (
        <Redirect
          to={{
            pathname: "/listVM",
          }}
        />
      );
    }
    if (!machine) {
      return null;
    }

    return (
      <div className="content-container">
        <div className="row">
          <div className="col">
            <Titles title={Strings.machine.vmSetup} tooltip={Strings.machine.setupTooltip} />
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col">
            <Divider orientation="left">
              <h2>
                {" "}
                {Strings.machine.machine}: {isChangeFriendlyName === null ? machine.friendlyName : isChangeFriendlyName}
              </h2>
              <br />
            </Divider>
            <div className="col-12">
              <Tabs style={{ overflow: "visible" }} onChange={this.callback}>
                <TabPane tab={Strings.machine.configuration.generalInformation} key="1">
                  <VendingMachineGeneralInfo vm={machine} getResponseChangefriendlyName={this.callbackParentFinal} />
                </TabPane>
                {ValidatePermissionForComponentPart("PRIVILEGE VMSETUP ACCESS", permissions.data) && (
                  <TabPane tab={Strings.machine.configuration.configurationParameters} key="2">
                    <VendingMachineSetup vm={machine} vmIsConfig={machineIsConfing} vmIsConfigIsLoading={machineIsConfingIsLoading} />
                  </TabPane>
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE VM PERIPHERAL DEVICE ACCESS", permissions.data) && (
                  <TabPane tab={Strings.machine.configuration.peripheralDevices} key="3">
                    <VendingMachinePeripheralDevices vm={machine} />
                  </TabPane>
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE VM SYNC INFO", permissions.data) && (
                  <TabPane tab={Strings.machine.configuration.synchronization} key="4">
                    <MachineSync vm={machine} />
                  </TabPane>
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE VM LOCKER LOAN CONFIG", permissions.data) && lockerLoanConfigInfo && lockerLoanConfigInfo.length > 0 && (
                  <TabPane tab={Strings.machine.lockerSlotPriceBySize.lockerLoanPriceConfig} key="5">
                    <LockerLoanConfig vm={machine} lockerLoanConfigInfo={lockerLoanConfigInfo} />
                  </TabPane>
                )}
                {ValidatePermissionForComponentPart("PRIVILEGE VM EVENT MESSAGING", permissions.data) && (
                  <TabPane tab={Strings.vmEventMessaging.vmEventMessaging} key="7">
                    <VmEventMessaging vm={machine} />
                  </TabPane>
                )}
              </Tabs>
            </div>
          </div>
        </div>

        <div className="vertSpace row justify-content-end">
          <div className="col-6" />
          <div className="col-6">
            <ReturnButton link="/listVM" />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  getLockerSlotPriceBySizeInfo,
  listVmSetupByVmId,
})(VendingMachineInfo);
