import { LIST_IDENTITYCARDTYPES } from '../actions'

const initialState = {
  identityCardTypesObj: []
}

export function identityCardTypes(state = [], action) {
  switch (action.type) {
    case LIST_IDENTITYCARDTYPES:
      var identityCardTypes = action.payload.data.map(item => ({value:item.id, label:item.name}))
      return identityCardTypes;
    default:
      return state
  }
}

// export function product(state = initialState, action) {
//
//   switch (action.type) {
//     case LIST_PRODUCTSLIST:
//       return Object.assign({}, state, {productObj: action.payload})
//     default:
//       return state
//   }
// }
