import { LIST_SOPRODUCTSLOTTYPE, ERROR } from '../actions'

const initialState = {
  soProductSlotTypeObj: [],
  errorObj: []
}

export function soProductSlotType(state = [], action) {
  switch (action.type) {
    case LIST_SOPRODUCTSLOTTYPE:
      return Object.assign({}, state, {soProductSlotTypeObj: action.payload})
    case ERROR:
      return Object.assign({}, state, {errorObj: action.payload})
    default:
      return state
  }
}