// Dependencies
import { Divider, Icon, Modal, Spin, Table } from "antd";
import Column from "antd/lib/table/Column";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
// Language localization
import moment from "moment";
import Strings from "../../systemVariables/languageStrings";
// Components
import { GeneralInput } from "../GenericComponents/InputComponent/index";
import { ReturnButton, SaveButton } from "../GenericComponents/buttons/index";
import Titles from "../GenericComponents/titles";

// Actions
import { getFirmwareVersionsByMachineGenerationIdAndVersionString, updateFirmwareVersion } from "../../actions";

const dateFormat = "DD/MM/YYYY hh:mm:ss a";

class EditFirmwareVersion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firmwareVersionInfo: [],
      editConfirm: false,
      searchText: "",
      machineGenerationId: -1,
      versionString: "",
      isFirmwareLoaded: true,
      redirectBackToListFirmwareVersion: false,
      isSaveButtonLoading: false,
      isDisableSaveButton: true,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.setState({
      firmwareVersionInfo: location.state ? location.state : null,
    });
  }

  updateFirmwareVersionEvent() {
    const { firmwareVersionInfo } = { ...this.state };
    this.setState({
      isSaveButtonLoading: true,
    });
    const { updateFirmwareVersion } = this.props;
    updateFirmwareVersion(firmwareVersionInfo, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        this.setState({ isSaveButtonLoading: false });
      } else if (response.data && response.data.code === 0) {
        //  Entity Successfully saved
        Modal.success({
          title: Strings.generalResponses.successfulUpdate,
          content: Strings.generalResponses.successfulUpdate,
          onOk: this.redirectBackToListFirmwareVersion,
        });
      }
      this.setState({ isSaveButtonLoading: false });
    });
  }

  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { firmwareVersionInfo } = { ...this.state };

    firmwareVersionInfo[id] = value;
    this.setState({ firmwareVersionInfo });
    if (firmwareVersionInfo[id] == "") {
      this.setState({ isDisableSaveButton: true });
    } else {
      this.setState({ isDisableSaveButton: false });
    }
  };

  additionalFileMethod = (firmwareVersionInfo) => {
    if (firmwareVersionInfo.additionalFiles !== undefined) {
      return (
        <div>
          <Divider orientation="left" className="vertSpace col-12">
            <h3>{Strings.firmwareVersion.additionalFile} </h3>
          </Divider>
          <div className="vertSpace col-12">
            <Table dataSource={firmwareVersionInfo.additionalFiles} bordered pagination={false} size="middle" width="75%" rowKey="id_">
              <Column width="50%" title={<p>{Strings.firmwareVersion.additionalFile}</p>} dataIndex="additionalFileName" key="additionalFileName" align="center" />
              <Column width="50%" title={<p>{Strings.firmwareVersion.additionalFileComment}</p>} dataIndex="description" key="description" align="center" />
            </Table>
          </div>
        </div>
      );
    }
    return null;
  };

  redirectBackToListFirmwareVersion = () => {
    this.setState({
      redirectBackToListFirmwareVersion: true,
    });
  };

  render() {
    const { isDisableSaveButton, redirectBackToListFirmwareVersion, firmwareVersionInfo, isFirmwareLoaded, isSaveButtonLoading } = this.state;

    if (redirectBackToListFirmwareVersion) {
      return <Redirect to="/listFirmwareVersion" />;
    }

    return (
      <div className="content-container">
        <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} spinning={!isFirmwareLoaded}>
          <div className="row">
            <Titles title={Strings.firmwareVersion.editFirmwareVersion} tooltip={Strings.firmwareVersion.editFirmwareVersionTooltip} />
          </div>

          <div className="row">
            <div className="vertSpace col-12">
              <Divider orientation="left">
                <h3>{Strings.generalTerms.generalParameters} </h3>
              </Divider>
            </div>
          </div>
          {firmwareVersionInfo ? (
            <>
              <div className="row">
                <div className="col-md-2" align="left">
                  <p>{Strings.firmwareVersion.version}:</p>
                </div>
                <div className="col-md-3" align="center">
                  <p>{firmwareVersionInfo.versionString}</p>
                </div>
                <div className="col-7">
                  <div className="col-md-4" align="left">
                    <p>{Strings.machine.machineGeneration}:</p>
                  </div>
                  <div className="col-md-4" align="left">
                    <p>{firmwareVersionInfo.generationString}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="vertSpace col-md-2" align="left">
                  <p>{Strings.generalTerms.creationDate}:</p>
                </div>
                <div className="vertSpace col-md-3" align="center">
                  <p>{moment.utc(firmwareVersionInfo.createDate).local().format(dateFormat)}</p>
                </div>
                <div className="col-md-7" align="left">
                  <GeneralInput
                    text={Strings.generalTerms.description}
                    id="description"
                    value={firmwareVersionInfo.description}
                    defaultValue={firmwareVersionInfo.description}
                    placeholder={Strings.generalTerms.description}
                    isReq
                    onChange={(e) => this.handleFormChange(e)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="vertSpace col-12">
                  <Divider orientation="left">
                    <h3>{Strings.firmwareVersion.versionRequirements} </h3>
                  </Divider>
                </div>
              </div>

              {typeof firmwareVersionInfo.noaVmcVersionName !== "undefined" ? (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.noaVmcJar}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{firmwareVersionInfo.noaVmcVersionName}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      id="noaVmcVersiondescription"
                      value={firmwareVersionInfo.noaVmcVersiondescription}
                      defaultValue={firmwareVersionInfo.noaVmcVersiondescription}
                      placeholder={Strings.generalTerms.description}
                      isReq
                      onChange={(e) => this.handleFormChange(e)}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.noaVmc}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{Strings.generalTerms.doesNotApply}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      value={Strings.generalTerms.doesNotApply}
                      defaultValue={Strings.generalTerms.doesNotApply}
                      placeholder={Strings.generalTerms.description}
                      disabled
                    />
                  </div>
                </div>
              )}

              {typeof firmwareVersionInfo.noaVmcManagerName !== "undefined" ? (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.noaVmcManager}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{firmwareVersionInfo.noaVmcManagerName}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      id="noaVmcManagerdescription"
                      value={firmwareVersionInfo.noaVmcManagerdescription}
                      defaultValue={firmwareVersionInfo.noaVmcManagerdescription}
                      placeholder={Strings.generalTerms.description}
                      isReq
                      onChange={(e) => this.handleFormChange(e)}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.noaVmcManager}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{Strings.generalTerms.doesNotApply}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      value={Strings.generalTerms.doesNotApply}
                      defaultValue={Strings.generalTerms.doesNotApply}
                      placeholder={Strings.generalTerms.description}
                      disabled
                    />
                  </div>
                </div>
              )}

              {typeof firmwareVersionInfo.noaVmcCashlessName !== "undefined" ? (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.noaVmcCashless}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{firmwareVersionInfo.noaVmcCashlessName}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      id="noaVmcCashlessdescription"
                      value={firmwareVersionInfo.noaVmcCashlessdescription}
                      defaultValue={firmwareVersionInfo.noaVmcCashlessdescription}
                      placeholder={Strings.generalTerms.description}
                      isReq
                      onChange={(e) => this.handleFormChange(e)}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.noaVmcCashless}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{Strings.generalTerms.doesNotApply}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      value={Strings.generalTerms.doesNotApply}
                      defaultValue={Strings.generalTerms.doesNotApply}
                      placeholder={Strings.generalTerms.description}
                      disabled
                    />
                  </div>
                </div>
              )}

              {typeof firmwareVersionInfo.noaVmcAIDetectorName !== "undefined" ? (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.noaVmcAIDetector}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{firmwareVersionInfo.noaVmcAIDetectorName}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      id="noaVmcAIDetectorDescription"
                      value={firmwareVersionInfo.noaVmcAIDetectorDescription}
                      defaultValue={firmwareVersionInfo.noaVmcAIDetectorDescription}
                      placeholder={Strings.generalTerms.description}
                      isReq
                      onChange={(e) => this.handleFormChange(e)}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.noaVmcAIDetector}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{Strings.generalTerms.doesNotApply}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      value={Strings.generalTerms.doesNotApply}
                      defaultValue={Strings.generalTerms.doesNotApply}
                      placeholder={Strings.generalTerms.description}
                      disabled
                    />
                  </div>
                </div>
              )}

              {typeof firmwareVersionInfo.sqlScriptName !== "undefined" ? (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.sqlScript}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{firmwareVersionInfo.sqlScriptName}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      id="sqlScriptdescription"
                      value={firmwareVersionInfo.sqlScriptdescription}
                      defaultValue={firmwareVersionInfo.sqlScriptdescription}
                      placeholder={Strings.generalTerms.description}
                      isReq
                      onChange={(e) => this.handleFormChange(e)}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.sqlScript}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{Strings.generalTerms.doesNotApply}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      value={Strings.generalTerms.doesNotApply}
                      defaultValue={Strings.generalTerms.doesNotApply}
                      placeholder={Strings.generalTerms.description}
                      disabled
                    />
                  </div>
                </div>
              )}

              {typeof firmwareVersionInfo.libScriptName !== "undefined" ? (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.libScript}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{firmwareVersionInfo.libScriptName}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      id="libScriptdescription"
                      value={firmwareVersionInfo.libScriptdescription}
                      defaultValue={firmwareVersionInfo.libScriptdescription}
                      placeholder={Strings.generalTerms.description}
                      isReq
                      onChange={(e) => this.handleFormChange(e)}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="vertSpace col-md-2" align="left">
                    <p>{Strings.firmwareVersion.libScript}:</p>
                  </div>
                  <div className="vertSpace col-md-3" align="center">
                    <p>{Strings.generalTerms.doesNotApply}</p>
                  </div>
                  <div className="col-md-7" align="left">
                    <GeneralInput
                      text={Strings.generalTerms.description}
                      value={Strings.generalTerms.doesNotApply}
                      defaultValue={Strings.generalTerms.doesNotApply}
                      placeholder={Strings.generalTerms.description}
                      disabled
                    />
                  </div>
                </div>
              )}

              <div>{this.additionalFileMethod(firmwareVersionInfo)}</div>
            </>
          ) : null}
        </Spin>

        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link="/listFirmwareVersion" />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton isDisabled={isDisableSaveButton} loading={isSaveButtonLoading} onClick={(e) => this.updateFirmwareVersionEvent(e)} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    firmwareVersionInfo: state,
  };
}

export default connect(mapStateToProps, {
  getFirmwareVersionsByMachineGenerationIdAndVersionString,
  updateFirmwareVersion,
})(EditFirmwareVersion);
