import axios from "axios";

import { host, port, path, version } from "../systemVariables";

// Method path
const resource = "/noahubkeylist";

export function getNoahubKeyListsByKeyStatus(keyStatusId, callback) {
  return () => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          keystatusid: keyStatusId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
