import React from "react";
import moment from "moment";
import {Card} from "antd";

// Components
import { DATE_TIME_FORMAT } from "../../systemVariables/TimeConfig";
import NotAvailableImg from "../Global/images/NotAvailableImg.png";

// Language localization
import Strings from "../../systemVariables/languageStrings";
import { lockerModalitiesLocalization } from "../../Utils/LanguageLocalization/lockerModalitiesLocalization";


const RenderSlotInfo = ({slotToConfig}) => {
        
    return (
        <>
            <div className="col-12 slotInfoRow" style={{ padding: "0px" }}>
            <div className="row">
                <div className="col-6" style={{ padding: "3px" }}>
                <span>{Strings.planogram.slotNumber}:</span>
                <span> {slotToConfig.slotNumber} </span>
                </div>
                <div className="col-6" style={{ padding: "3px" }}>
                <span>{Strings.machine.lockerModality}:</span>
                <span> {lockerModalitiesLocalization(slotToConfig.lockerType)} </span>
                </div>
            </div>
            <div className="row">
                <div className="col-6" style={{ padding: "3px" }}>
                <span>{Strings.machine.slotGroup}:</span>
                <span> {slotToConfig.slotGroup} </span>
                </div>
                <div className="col-6" style={{ padding: "3px" }}>
                <span>{Strings.generalTerms.tray}:</span>
                <span> {slotToConfig.trayDenomination} </span>
                </div>
            </div>
            {slotToConfig.soProductCode && slotToConfig.soProductName && (
                <div className="row">
                {slotToConfig.soProductCode && (
                    <div className="col-6" style={{ padding: "3px" }}>
                    <span>{Strings.product.scProductCode}:</span>
                    <span> {slotToConfig.soProductCode} </span>
                    </div>
                )}
                {slotToConfig.soProductName && (
                    <div className="col-6" style={{ padding: "3px" }}>
                    <span>{Strings.product.product}:</span>
                    <br />
                    <span> {slotToConfig.soProductName} </span>
                    </div>
                )}
                </div>
            )}
            {!slotToConfig.soProductCode && slotToConfig.soProductName && (
                <div className="row">
                {slotToConfig.soProductName && (
                    <div className="col-6" style={{ padding: "3px" }}>
                    <span>{Strings.product.product}:</span>
                    <br />
                    <span> {slotToConfig.soProductName} </span>
                    </div>
                )}
                </div>
            )}
            {slotToConfig.provisionerName && slotToConfig.transactionDate && (
                <div className="row">
                {slotToConfig.provisionerName && (
                    <div className="col-6" style={{ padding: "3px" }}>
                    <span>{Strings.Roles.provisioner}:</span>
                    <br />
                    <span> {slotToConfig.provisionerName} </span>
                    </div>
                )}
                {slotToConfig.transactionDate && (
                    <div className="col-6" style={{ padding: "3px" }}>
                    <span>{Strings.dispensationReport.transactionDate}:</span>
                    <br />
                    <span> {moment(slotToConfig.transactionDate).format(DATE_TIME_FORMAT)} </span>
                    </div>
                )}
                </div>
            )}
            {slotToConfig.userName && slotToConfig.transactionDate && (
                <div className="row">
                {slotToConfig.userName && (
                    <div className="col-6" style={{ padding: "3px" }}>
                    <span>{Strings.users.user}:</span>
                    <br />
                    <span> {slotToConfig.userName} </span>
                    </div>
                )}
                {slotToConfig.transactionDate && !slotToConfig.provisionerName && (
                    <div className="col-6" style={{ padding: "3px" }}>
                    <span>{Strings.dispensationReport.transactionDate}:</span>
                    <br />
                    <span> {moment(slotToConfig.transactionDate).format(DATE_TIME_FORMAT)} </span>
                    </div>
                )}
                </div>
            )}
            <div className="row">
                <div className="col-6" style={{ padding: "3px" }}>
                <span>{Strings.planogram.harnessConnection}:</span>
                <span> {slotToConfig.columnLId} </span>
                </div>
                {slotToConfig.soProductCode && slotToConfig.soProductName && (
                <div className="col-6 inventory" style={{ padding: "3px" }}>
                    <span>{Strings.dispensationReport.productImage}:</span>
                    <Card style={{ width: 100 }} bodyStyle={{ height: "0px", padding: "0px", margin: "auto" }} type="inner" cover={<img alt="product" src={NotAvailableImg} />} />
                </div>
                )}
            </div>
            </div>
        </>
    );
}
 
export default RenderSlotInfo;