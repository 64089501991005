// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal, Spin, Icon, Divider } from "antd";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { identityCardTypeLocalization, rolesLocalization } from "../../../Utils/LanguageLocalization";

// Components
import UserForm from "../Commons/UserForm";
import AssignUsersToVendingMachineForm from "../Commons/AssignUsersToVendingMachineForm";
import SelectRoleForm from "../Commons/SelectRoleForm";
import Loading from "../../GenericComponents/loadingComponent/loading";
import Titles from "../../GenericComponents/titles";
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import ScEmployeeInfoForm from "../Commons/ScEmployeeInfoForm";

// Queries
import {
  getClientUserById,
  listIdentityCardTypes,
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  getSystemScRoles,
  getVendingMachineScRoles,
  listScCostCentersByScDependencyId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  listVendingMachinesByScDependencyId,
  updateClientUser,
} from "../../../actions";

class EditClientUser extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      userToUpdate: {
        id: location.state ? location.state.userId : null,
        scDependencyId: location.state ? location.state.scDependencyId : null,
        firstName: null,
        middleName: null,
        lastName1: null,
        lastName2: null,
        identityCardTypeId: null,
        identityCardNumber: null,
        phone1: null,
        phone2: null,
        countryId: location.state ? location.state.countryId : null,
        provinceId: location.state ? location.state.provinceId : null,
        cityId: location.state ? location.state.cityId : null,
        address: null,
        email: null,
        birthDate: null,
        scDepartmentId: location.state ? location.state.scDepartmentId : null,
        scCostCenterId: location.state ? location.state.scCostCenterId : null,
        scUserProfileId: location.state ? location.state.scUserProfileId : null,
        vmRoleIds: [], // So Role Ids
        sysRoleIds: [], // So Role Ids
        vendingMachineIds: [],
      },

      countryCode: "co",
      identityCardTypes: [],
      provinces: [],
      cities: [],
      scCostCenters: [],
      scDepartments: [],
      scUserProfiles: [],
      scSysRoles: [],
      scVmRoles: [],
      vendingMachines: [],

      isUserDataLoaded: false,
      redirectBackToListClientUsers: false,
      isSaveButtonLoading: false,
    };
  }

  componentDidMount() {
    const {
      listIdentityCardTypes,
      listCountries,
      listProvincesByCountryId,
      listCitesByProvincesId,
      listScCostCentersByScDependencyId,
      listScDepartmentByScDependencyId,
      listScUserProfilesByScDependencyId,
      listVendingMachinesByScDependencyId,
      getVendingMachineScRoles,
      getSystemScRoles,
      getClientUserById,
    } = this.props;
    const { userToUpdate } = this.state;
    if (userToUpdate.id != null) {
      listIdentityCardTypes((response) => {
        this.setState({
          identityCardTypes: response.data.data.map((item) => ({
            value: item.id,
            label: identityCardTypeLocalization(item.name),
          })),
        });
      });

      listCountries();

      listProvincesByCountryId(userToUpdate.countryId, (response) => {
        this.setState({
          provinces: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      listCitesByProvincesId(userToUpdate.provinceId, (response) => {
        this.setState({
          cities: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });

      listScCostCentersByScDependencyId(userToUpdate.scDependencyId, (response) => {
        this.setState({
          scCostCenters: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      listScDepartmentByScDependencyId(userToUpdate.scDependencyId, (response) => {
        this.setState({
          scDepartments: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      listScUserProfilesByScDependencyId(userToUpdate.scDependencyId, (response) => {
        this.setState({
          scUserProfiles: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      listVendingMachinesByScDependencyId(userToUpdate.scDependencyId, (response) => {
        this.setState({
          vendingMachines: response.data.data.map((item) => ({
            value: item.id,
            label: item.friendlyName,
          })),
        });
      });
      getSystemScRoles(userToUpdate.scDependencyId, (response) => {
        this.setState({
          scSysRoles: response.data.data.map((item) => ({
            value: item.id,
            label: rolesLocalization(item.roleName),
          })),
        });
      });
      getVendingMachineScRoles(userToUpdate.scDependencyId, (response) => {
        this.setState({
          scVmRoles: response.data.data.map((item) => ({
            value: item.id,
            label: rolesLocalization(item.roleName),
          })),
        });
      });
      getClientUserById(userToUpdate.id, (response, error) => {
        if (!error) {
          const { userToUpdate } = { ...this.state };

          Object.keys(response.data.data).forEach((key) => {
            userToUpdate[key] = response.data.data[key];
          });

          if (userToUpdate.phone1 !== undefined && userToUpdate.phone1.startsWith("+")) {
            const phone = userToUpdate.phone1;
            userToUpdate.phone1 = phone.substring(1);
          }

          this.setState({
            isUserDataLoaded: true,
            userToUpdate,
          });
        }
      });
    }
  }

  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { userToUpdate } = { ...this.state };

    userToUpdate[id] = value;

    this.setState({
      userToUpdate,
    });
  };

  handleSelect = (option, event) => {
    const { value } = option;

    const { listProvincesByCountryId, listCitesByProvincesId } = this.props;

    const { userToUpdate } = { ...this.state };
    userToUpdate[event.name] = value;

    this.setState({
      userToUpdate,
    });

    switch (event.name) {
      case "countryId":
        this.setState({ countryCode: option.code });
        listProvincesByCountryId(value, (response) => {
          this.setState({
            provinces: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            cities:[]
          });
        });
        break;
      case "provinceId":
        listCitesByProvincesId(value, (response) => {
          this.setState({
            cities: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        });
        break;
      default:
    }
  };

  handleMultiSelect = (options, source) => {
    const { userToUpdate } = { ...this.state };

    userToUpdate[source] = options.map((option) => {
      return parseInt(option, 10);
    });

    this.setState({
      userToUpdate,
    });
  };

  updateUserEvent = () => {
    const { userToUpdate } = { ...this.state };

    let validateEmail = false;
    if (userToUpdate.email === undefined || userToUpdate.email === null || userToUpdate.email === "" || userToUpdate.email === "@") {
      userToUpdate.email = "@";
      this.setState({
        userToUpdate,
      });
      validateEmail = true;
    } else {
      const regexEmail = /^[\w._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      validateEmail = regexEmail.test(userToUpdate.email);
    }

    if (
      userToUpdate.firstName &&
      userToUpdate.lastName1 &&
      userToUpdate.identityCardTypeId &&
      userToUpdate.identityCardNumber &&
      userToUpdate.countryId &&
      userToUpdate.provinceId &&
      userToUpdate.cityId &&
      (userToUpdate.vmRoleIds.length !== 0 || userToUpdate.sysRoleIds.length !== 0) &&
      validateEmail
    ) {
      this.setState({
        isSaveButtonLoading: true,
      });
      const { updateClientUser } = this.props;
      updateClientUser(userToUpdate, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data.code === 4001) {
          // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
        } else if (response.data.code === 0) {
          // Entity Successfully  saved
          Modal.success({
            title: Strings.generalResponses.successfulCreation,
            content: `${Strings.generalResponses.saveSuccess}: ${response.data.data.identityCardNumber}`,
          });
        }
        this.setState({
          isSaveButtonLoading: false,
        });
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
      this.setState({
        isSaveButtonLoading: false,
      });
    }
  };

  showNoScDependencyWarning = () => {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOkNoScDependencyWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  };

  handleOkNoScDependencyWarning = () => {
    this.setState({
      redirectBackToListClientUsers: true,
    });
  };

  render() {
    const { countries } = this.props;
    const {
      userToUpdate,
      isUserDataLoaded,
      countryCode,
      redirectBackToListClientUsers,
      scDepartments,
      scUserProfiles,
      scCostCenters,
      identityCardTypes,
      provinces,
      cities,
      scSysRoles,
      scVmRoles,
      vendingMachines,
      isSaveButtonLoading,
    } = this.state;

    if (redirectBackToListClientUsers) {
      return <Redirect to="/listClientUsers" />;
    }
    if (!userToUpdate.id) {
      return <div> {this.showNoScDependencyWarning()} </div>;
    }

    if (!userToUpdate) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.users.editClientUsers} tooltip={Strings.users.editClientUsersTooltip} />
        </div>

        <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} spinning={!isUserDataLoaded}>
          <div className="row">
            <div className="vertSpace col-12">
              <Divider orientation="left">
                <h3>{Strings.generalTerms.parameterization} </h3>
              </Divider>
            </div>
          </div>

          <UserForm
            user={userToUpdate}
            identityCardTypes={identityCardTypes}
            countries={countries}
            provinces={provinces}
            cities={cities}
            onChange={this.handleFormChange}
            onSelect={this.handleSelect}
            countryCode={countryCode}
          />

          <ScEmployeeInfoForm user={userToUpdate} scDepartments={scDepartments} scUserProfiles={scUserProfiles} scCostCenters={scCostCenters} onSelect={this.handleSelect} />

          <SelectRoleForm user={userToUpdate} sysRoles={scSysRoles} vmRoles={scVmRoles} onMultiSelect={this.handleMultiSelect} />

          {userToUpdate.vmRoleIds.length !== 0 ? (
            <AssignUsersToVendingMachineForm user={userToUpdate} vendingMachines={vendingMachines} onMultiSelect={this.handleMultiSelect} />
          ) : null}
        </Spin>

        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link="/listClientUsers" />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton loading={isSaveButtonLoading} onClick={(e) => this.updateUserEvent(e)} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependencies,
    countries: state.countries,
  };
}

export default connect(mapStateToProps, {
  getClientUserById,
  listIdentityCardTypes,
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  getSystemScRoles,
  getVendingMachineScRoles,
  listScCostCentersByScDependencyId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  listVendingMachinesByScDependencyId,
  updateClientUser,
})(EditClientUser);
