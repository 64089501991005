import React from "react";
import { Divider } from "antd";
import { SelectGeneralMultiple } from "../../GenericComponents/selectComponent/index";
import Strings from "../../../systemVariables/languageStrings";

const SelectRoleForm = (props) => {
  const { sysRoles, user, vmRoles } = props;
  return (
    <>
      <div className="row">
        <div className="vertSpace col">
          <Divider orientation="left">
            <h3>{Strings.Roles.role}* </h3>
          </Divider>
        </div>
      </div>

      <div className="row">
        <div className="vertSpace col-md-6">
          <SelectGeneralMultiple
            tooltip={Strings.Roles.sysRolTooltip}
            text={Strings.Roles.sysRol}
            style={{ width: "100%" }}
            options={sysRoles}
            selectedOptions={user.sysRoleIds}
            placeholder={Strings.generalTerms.select}
            onChange={(options) => props.onMultiSelect(options, "sysRoleIds")}
            allowClear
          />
        </div>
        <div className="vertSpace col-md-6">
          <SelectGeneralMultiple
            tooltip={Strings.machine.vmRolTooltip}
            text={Strings.Roles.vmRol}
            style={{ width: "100%", padding: "0px auto" }}
            options={vmRoles}
            selectedOptions={user.vmRoleIds}
            placeholder={Strings.generalTerms.select}
            onChange={(options) => props.onMultiSelect(options, "vmRoleIds")}
            allowClear
          />
        </div>
      </div>
    </>
  );
};
export default SelectRoleForm;
