// Dependencies
import { Divider, Icon, InputNumber, Modal, Spin, Table, Tooltip } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Language localization
import Strings from "../../systemVariables/languageStrings";
// Components
import { GeneralInput } from "../GenericComponents/InputComponent/index";
import { AddButton, ReturnButton, SaveButton } from "../GenericComponents/buttons/index";
import { SelectGeneral } from "../GenericComponents/selectComponent/selectGeneral";
import Titles from "../GenericComponents/titles";

// Queries
import { addFirmwareVersion, getAllMachinGenerations, getFirmwareVersionsByMachineGenerationId } from "../../actions";

class EditFirmwareVersion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firmwareVersion: {
        fwName: "",
        machineGenerationId: null,
        vmcJarName: "",
        managerJarName: "",
        cashlessJarName: "",
        aiDetectorJarName: "",
        instructionsName: "",
        libScriptName: "",
        sqlScriptName: "",
        additionalFileName: [],
        versionsLimit: 0,
        fwComment: "",
        vmcJarComment: "",
        managerJarComment: "",
        cashlessJarComment: "",
        aiDetectorJarComment: "",
        instructionsComment: "",
        libScriptComment: "",
        sqlScriptComment: "",
        additionalFileComment: [],
      },
      firmwareVersionToUpload: {},
      additionalFile: [],
      MachinGenerations: [],
      firmwareVersionCount: 0,
      additionalObj: {
        fileName: "",
        fileComment: "",
      },
      redirectBackToListFirmwareVersion: false,
      isAddButtonLoading: false,
      isDisableAddButton: true,
      isDisableVersionLimit: true,
      isFirmwareLoaded: true,
      isSaveButtonLoading: false,
      isDisableSaveButton: true,
    };
  }

  componentDidMount() {
    const { getAllMachinGenerations } = this.props;
    getAllMachinGenerations((response) => {
      const MachinGenerations = response.data.data.map((obj) => ({
        value: obj.id,

        label: obj.generationString,
      }));
      this.setState({ MachinGenerations });
    });
  }

  onClickAddFirmwareVersion() {
    this.setState({
      isSaveButtonLoading: true,
    });
    const { addFirmwareVersion } = this.props;
    const { firmwareVersion } = { ...this.state };
    const { additionalFile, firmwareVersionToUpload } = this.state;

    additionalFile.forEach((obj) => {
      this.setState({
        firmwareVersion: { additionalFileName: firmwareVersion.additionalFileName.push(obj.fileName) },
        firmwareVersion: { additionalFileComment: firmwareVersion.additionalFileComment.push(obj.fileComment) },
      });
    });

    Object.entries(firmwareVersion).map((obj) => {
      if (obj[0] === "machineGenerationId" || obj[0] === "versionsLimit" || obj[0] === "additionalFileName" || obj[0] === "additionalFileComment" || obj[1].length > 0) {
        firmwareVersionToUpload[obj[0]] = obj[1];
      } else {
        firmwareVersionToUpload[obj[0]] = null;
      }
    });

    addFirmwareVersion(firmwareVersionToUpload, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
        this.setState({
          isSaveButtonLoading: false,
          additionalFile: [],
          isDisableSaveButton: true,
        });
      } else if (response.data.code === 4008) {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
        this.setState({
          isSaveButtonLoading: false,
          additionalFile: [],
          isDisableSaveButton: true,
        });
      } else if (response.data.code === 4012) {
        // eerror de archivos download
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: "Error downloading files",
        });
        this.setState({
          isSaveButtonLoading: false,
          additionalFile: [],
          isDisableSaveButton: true,
        });
      } else if (response.data && response.data.code === 0) {
        //  Entity Successfully saved
        Modal.success({
          title: Strings.generalResponses.creationSuccess,
          content: Strings.generalResponses.creationSuccess,
          onOk: this.redirectBackToListFirmwareVersion,
        });
      }
    });
  }

  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { firmwareVersion } = { ...this.state };

    firmwareVersion[id] = value;
    this.setState({ firmwareVersion });

    if (firmwareVersion[id] === "") {
      this.setState({ isDisableSaveButton: true });
    } else {
      this.setState({ isDisableSaveButton: false });
    }
    this.saveButtonLogic();
  };

  handleFormChangeAdditionalFiles = (event) => {
    const { id, value } = event.target;
    const { additionalObj } = { ...this.state };

    additionalObj[id] = value;
    this.setState({ additionalObj });

    if (additionalObj[id] === "") {
      this.setState({ isDisableAddButton: true });
    } else {
      this.setState({ isDisableAddButton: false });
    }
  };

  handleSelectChangeGeneration(event) {
    const { getFirmwareVersionsByMachineGenerationId } = this.props;
    const { firmwareVersion } = { ...this.state };
    this.setState({ isDisableSaveButton: true });
    getFirmwareVersionsByMachineGenerationId(event.value, (response) => {
      this.setState({ firmwareVersionCount: response.data.data.length });
      if (response.data.data.length > 0) {
        firmwareVersion.versionsLimit = 1;
        this.setState({ isDisableVersionLimit: false });
      } else {
        firmwareVersion.versionsLimit = 0;
        this.setState({
          firmwareVersionCount: response.data.data.length,
          isDisableVersionLimit: true,
        });
      }
      this.saveButtonLogic();
    });
    firmwareVersion.machineGenerationId = event.value;
    this.setState({
      firmwareVersion,
    });
  }

  handleFormChangeNumber(value) {
    const { firmwareVersion } = { ...this.state };
    firmwareVersion.versionsLimit = value;
    this.setState({
      firmwareVersion,
    });
    this.saveButtonLogic();
  }

  onClickAppendFiles() {
    const { additionalObj, additionalFile } = this.state;

    additionalFile.push(additionalObj);

    this.setState({
      additionalFile,
      additionalObj: {
        fileName: "",
        fileComment: "",
      },
      isDisableAddButton: true,
    });
  }

  handleDelete = (row) => {
    const { additionalFile } = this.state;
    const dataSource = [...additionalFile];
    this.setState({
      additionalFile: dataSource.filter((item) => item !== row),
    });
  };

  saveButtonLogic() {
    if (!this.checkObligatoryFields() && !this.checkDescriptionField()) {
      this.setState({
        isDisableSaveButton: false,
      });
    } else {
      this.setState({
        isDisableSaveButton: true,
      });
    }
  }

  checkObligatoryFields() {
    const { firmwareVersion, firmwareVersionCount } = { ...this.state };
    if (firmwareVersionCount > 0) {
      if (firmwareVersion.machineGenerationId !== null && firmwareVersion.fwName.length > 0 && firmwareVersion.vmcJarName.length > 0) {
        return false;
      }
      return true;
    }
    if (firmwareVersion.machineGenerationId !== null && firmwareVersion.fwName.length > 0 && firmwareVersion.vmcJarName.length > 0 && firmwareVersion.managerJarName.length > 0) {
      return false;
    }
    return true;
  }

  checkDescriptionField() {
    const { firmwareVersion } = { ...this.state };
    if (
      firmwareVersion.fwName.length > 0 === firmwareVersion.fwComment.length > 0 &&
      firmwareVersion.vmcJarName.length > 0 === firmwareVersion.vmcJarComment.length > 0 &&
      firmwareVersion.managerJarName.length > 0 === firmwareVersion.managerJarComment.length > 0 &&
      firmwareVersion.instructionsName.length > 0 === firmwareVersion.instructionsComment.length > 0 &&
      firmwareVersion.libScriptName.length > 0 === firmwareVersion.libScriptComment.length > 0 &&
      firmwareVersion.sqlScriptName.length > 0 === firmwareVersion.sqlScriptComment.length > 0
    ) {
      return false;
    }
    return true;
  }

  redirectBackToListFirmwareVersion = () => {
    this.setState({
      redirectBackToListFirmwareVersion: true,
    });
  };

  render() {
    const {
      redirectBackToListFirmwareVersion,
      MachinGenerations,
      firmwareVersion,
      firmwareVersionCount,
      isFirmwareLoaded,
      isDisableAddButton,
      isSaveButtonLoading,
      isDisableSaveButton,
      additionalFile,
      additionalObj,
      isDisableVersionLimit,
    } = this.state;

    const columns = [
      {
        title: Strings.generalTerms.file,
        dataIndex: "fileName",
        MaxWidth: "40%",
        align: "center",
      },
      {
        title: Strings.generalTerms.description,
        dataIndex: "fileComment",
        MaxWidth: "40%",
        align: "center",
      },
      {
        title: Strings.generalTerms.remove,
        align: "center",
        MaxWidth: "20%",
        render: (row) => (
          <div>
            <Icon style={{ cursor: "pointer", fontSize: 20 }} type="close-circle" theme="twoTone" className="addEditRemoveButton" onClick={() => this.handleDelete(row)} />
          </div>
        ),
      },
    ];

    if (redirectBackToListFirmwareVersion) {
      return <Redirect to="/listFirmwareVersion" />;
    }

    return (
      <div className="content-container">
        <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} spinning={!isFirmwareLoaded}>
          <div className="row">
            <Titles title={Strings.machine.firmwareVersion} tooltip={Strings.firmwareVersion.addFirmwareVersionTooltip} />
          </div>

          <div className="row">
            <div className="vertSpace col-12">
              <Divider orientation="left">
                <h3>{Strings.generalTerms.generalParameters} </h3>
              </Divider>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.machine.firmwareVersion}
                id="fwName"
                value={firmwareVersion.fwName}
                defaultValue={firmwareVersion.fwName}
                placeholder={Strings.machine.firmwareVersion}
                isReq
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.generalTerms.description}
                id="fwComment"
                value={firmwareVersion.fwComment}
                defaultValue={firmwareVersion.fwComment}
                placeholder={Strings.firmwareVersion.descriptionToolTip}
                isReq
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.firmwareVersion.instructionsFile}
                id="instructionsName"
                value={firmwareVersion.instructionsName}
                defaultValue={firmwareVersion.instructionsName}
                placeholder={Strings.firmwareVersion.instructionsFile}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.generalTerms.description}
                id="instructionsComment"
                value={firmwareVersion.instructionsComment}
                defaultValue={firmwareVersion.instructionsComment}
                placeholder={Strings.firmwareVersion.descriptionInstructionsFile}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <SelectGeneral
                text={Strings.machine.machineGeneration}
                tooltip={Strings.machine.machineGenerationTooltip}
                options={MachinGenerations}
                onChange={(e) => this.handleSelectChangeGeneration(e)}
                defaultValue={firmwareVersion.machineGenerationId}
              />
            </div>

            <div className="col-md-6" align="left">
              <div className="col-md-4 selectAligning">
                <span className="inputText">
                  {Strings.firmwareVersion.versionsLimit}
                  <b>:*</b>
                </span>
                <Tooltip title={Strings.firmwareVersion.versionsLimitTooltip}>
                  <Icon className="icon" type="info-circle" /> <span />
                </Tooltip>
              </div>

              <div className="col-md-8 input">
                <InputNumber
                  id="versionsLimit"
                  min={1}
                  max={firmwareVersionCount}
                  value={firmwareVersion.versionsLimit}
                  defaultValue={firmwareVersion.versionsLimit}
                  placeholder={Strings.firmwareVersion.versionsLimit}
                  isReq
                  onChange={(e) => this.handleFormChangeNumber(e)}
                  disabled={isDisableVersionLimit}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="vertSpace col-12">
              <Divider orientation="left">
                <h3>{Strings.firmwareVersion.noaVmc} </h3>
              </Divider>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.firmwareVersion.noaVmcJar}
                id="vmcJarName"
                value={firmwareVersion.vmcJarName}
                defaultValue={firmwareVersion.vmcJarName}
                placeholder={Strings.firmwareVersion.noaVmcPlaceholder}
                isReq
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.generalTerms.description}
                id="vmcJarComment"
                value={firmwareVersion.vmcJarComment}
                defaultValue={firmwareVersion.vmcJarComment}
                placeholder={Strings.firmwareVersion.descriptionNoaVmcJar}
                isReq
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.firmwareVersion.noaVmcmanagerJar}
                id="managerJarName"
                value={firmwareVersion.managerJarName}
                defaultValue={firmwareVersion.managerJarName}
                placeholder={Strings.firmwareVersion.noaVmcManagerPlaceholder}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.generalTerms.description}
                id="managerJarComment"
                value={firmwareVersion.managerJarComment}
                defaultValue={firmwareVersion.managerJarComment}
                placeholder={Strings.firmwareVersion.descriptionNoaVmcmanagerJar}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.firmwareVersion.noaVmcCashlessJar}
                id="cashlessJarName"
                value={firmwareVersion.cashlessJarName}
                defaultValue={firmwareVersion.cashlessJarName}
                placeholder={Strings.firmwareVersion.noaVmcCashlessPlaceholder}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.generalTerms.description}
                id="cashlessJarComment"
                value={firmwareVersion.cashlessJarComment}
                defaultValue={firmwareVersion.cashlessJarComment}
                placeholder={Strings.firmwareVersion.descriptionNoaVmcCashlessJar}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.firmwareVersion.noaVmcAIDetectorJar}
                id="aiDetectorJarName"
                value={firmwareVersion.aiDetectorJarName}
                defaultValue={firmwareVersion.aiDetectorJarName}
                placeholder={Strings.firmwareVersion.noaVmcAIDetectorPlaceholder}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.generalTerms.description}
                id="aiDetectorJarComment"
                value={firmwareVersion.aiDetectorJarComment}
                defaultValue={firmwareVersion.aiDetectorJarComment}
                placeholder={Strings.firmwareVersion.descriptionNoaVmcAIDetectorJar}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
          </div>

          <div className="row">
            <div className="vertSpace col-12">
              <Divider orientation="left">
                <h3>{Strings.firmwareVersion.sqlScript} </h3>
              </Divider>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.firmwareVersion.sqlScript}
                id="sqlScriptName"
                value={firmwareVersion.sqlScriptName}
                defaultValue={firmwareVersion.sqlScriptName}
                placeholder={Strings.firmwareVersion.sqlScriptPlaceholder}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.generalTerms.description}
                id="sqlScriptComment"
                value={firmwareVersion.sqlScriptComment}
                defaultValue={firmwareVersion.sqlScriptComment}
                placeholder={Strings.firmwareVersion.descriptionSqlScript}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
          </div>

          <div className="row">
            <div className="vertSpace col-12">
              <Divider orientation="left">
                <h3>{Strings.firmwareVersion.libScript} </h3>
              </Divider>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.firmwareVersion.libScript}
                id="libScriptName"
                value={firmwareVersion.libScriptName}
                defaultValue={firmwareVersion.libScriptName}
                placeholder={Strings.firmwareVersion.libScriptPlaceholder}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
            <div className="col-md-6" align="left">
              <GeneralInput
                text={Strings.generalTerms.description}
                id="libScriptComment"
                value={firmwareVersion.libScriptComment}
                defaultValue={firmwareVersion.libScriptComment}
                placeholder={Strings.firmwareVersion.descriptionLibScript}
                onChange={(e) => this.handleFormChange(e)}
              />
            </div>
          </div>

          {firmwareVersion.libScriptName !== "" ? (
            <div>
              <div className="row">
                <div className="vertSpace col-12">
                  <Divider orientation="left">
                    <h3>{Strings.firmwareVersion.additionalFile}</h3>
                  </Divider>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6" align="left">
                  <GeneralInput
                    text={Strings.firmwareVersion.additionalFile}
                    id="fileName"
                    value={additionalObj.fileName}
                    placeholder={Strings.firmwareVersion.additionalFilePlaceholder}
                    onChange={(e) => this.handleFormChangeAdditionalFiles(e)}
                  />
                </div>
                <div className="col-md-6" align="left">
                  <GeneralInput
                    text={Strings.firmwareVersion.additionalFileComment}
                    id="fileComment"
                    value={additionalObj.fileComment}
                    placeholder={Strings.firmwareVersion.additionalFileComment}
                    onChange={(e) => this.handleFormChangeAdditionalFiles(e)}
                  />
                </div>
              </div>
              <div className="vertSpace row justify-content-end">
                <div className="col-6 col-md-3">
                  <AddButton disabled={isDisableAddButton} onClick={(e) => this.onClickAppendFiles(e)} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  {additionalFile.length > 0 ? <Table pagination={false} columns={columns} dataSource={additionalFile} bordered width="100%" rowKey="tempWingId" /> : null}
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="vertSpace col-12">
              <Divider orientation="left" />
            </div>
          </div>
        </Spin>
        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link="/listFirmwareVersion" />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton loading={isSaveButtonLoading} isDisabled={isDisableSaveButton} onClick={(e) => this.onClickAddFirmwareVersion(e)} />{" "}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  addFirmwareVersion,
  getAllMachinGenerations,
  getFirmwareVersionsByMachineGenerationId,
})(EditFirmwareVersion);
