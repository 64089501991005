// Dependencies
import { Alert, Divider, Icon, Modal, Popconfirm, Table, Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import {
  deleteVmPeripheralDevice,
  getMissingPeripheralDevicesInSlotGroups,
  getMissingPeripheralDevicesInVendingMachine,
  getMissingPeripheralDevicesInWings,
  getPendingRegistersByVm,
  getPeripheralDevicesByVendingMachineId,
} from "../../../actions";

// Components
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

const { Column } = Table;

class VendingMachinePeripheralDevices extends Component {
  constructor(props) {
    super(props);
    const { vm } = this.props;
    this.state = {
      machine: vm,
      vmPeripherals: [],
      missingPeripheralsInVm: [],
      missingPeripheralsInWings: [],
      missingPeripheralsInSlotGroups: [],
      regsToUpload: [],
    };
  }

  componentDidMount() {
    const { getPendingRegistersByVm, vm } = this.props;

    this.getPeripheralDevicesByVendingMachineId();

    getPendingRegistersByVm(vm.id, (response) => {
      if (response.data !== null && response.data !== undefined) {
        this.setState({
          regsToUpload: response.data.data.regsToUpload,
        });
      }
    });
  }

  getPeripheralDevicesByVendingMachineId() {
    const {
      machine: { id },
    } = this.state;
    const {
      getPeripheralDevicesByVendingMachineId,
      getMissingPeripheralDevicesInVendingMachine,
      getMissingPeripheralDevicesInWings,
      getMissingPeripheralDevicesInSlotGroups,
    } = this.props;
    this.setState({
      loadingInfo: true,
      missingPeripheralsInVm: [],
    });
    getPeripheralDevicesByVendingMachineId(id, (response) => {
      if (response.data !== null && response.data !== undefined) {
        this.setState({
          vmPeripherals: response.data.data,
          loadingInfo: false,
        });
        getMissingPeripheralDevicesInVendingMachine(id, (response) => {
          if (response.data !== null && response.data !== undefined) {
            this.setState({
              missingPeripheralsInVm: response.data.data,
            });
          }
        });
        getMissingPeripheralDevicesInWings(id, (response) => {
          if (response.data !== null && response.data !== undefined) {
            this.setState({
              missingPeripheralsInWings: response.data.data,
            });
          }
        });
        getMissingPeripheralDevicesInSlotGroups(id, (response) => {
          if (response.data !== null && response.data !== undefined) {
            this.setState({
              missingPeripheralsInSlotGroups: response.data.data,
            });
          }
        });
      }
    });
  }

  deleteVmPeripheralDevice(vmPeripheralDeviceId) {
    this.setState({
      loadingInfo: true,
      missingPeripheralsInVm: [],
    });
    const { deleteVmPeripheralDevice } = this.props;
    deleteVmPeripheralDevice(vmPeripheralDeviceId, (response) => {
      if ((!response.data && String(response).includes("Error:")) || response.data.status === "FAILURE") {
        Modal.error({
          title: Strings.generalResponses.connectionError,
          content: Strings.generalResponses.connectionErrorTooltip,
        });
        this.setState({
          loadingInfo: false,
        });
      } else {
        this.getPeripheralDevicesByVendingMachineId();
      }
    });
  }

  WarnigInfoMessage = (peripheralDeviceTypeId) => {
    const { regsToUpload } = this.state;

    if (peripheralDeviceTypeId == 35) {
      return (
        <>
          <span>{Strings.peripheralDevice.unassignPeripheralDeviceWarn}</span>
          <br />
          <span>{Strings.peripheralDevice.unassingnNecWarning}</span>
          <br />
          <span>{<b>{Strings.vmSetup.regsQty + ": " + regsToUpload.length}</b>}</span>
        </>
      );
    } else {
      return Strings.peripheralDevice.unassignPeripheralDeviceWarn;
    }
  };

  renderWing = (wingNumber, wingTypeName) => {
    let render = " -- ";
    if (wingNumber !== null && wingNumber !== undefined && wingTypeName !== null && wingTypeName !== undefined) {
      render = <span>{`${Strings.machine.wing}: ${wingNumber} - ${wingTypeName}`}</span>;
    }
    return render;
  };

  renderSlotGroup = (slotGroupNumber) => {
    let render = " -- ";
    if (slotGroupNumber !== null && slotGroupNumber !== undefined) {
      render = <span>{slotGroupNumber}</span>;
    }
    return render;
  };

  renderMissingPeripheralsInVmWarning = (missingPeripheralsInVm) => {
    let render = null;
    if (missingPeripheralsInVm !== null && missingPeripheralsInVm !== undefined && missingPeripheralsInVm.length >= 1) {
      const missingPeripheralNames = missingPeripheralsInVm.map((obj) => obj.name);
      render = (
        <small>
          <Alert message="Error" description={` ${Strings.peripheralDevice.missingPeripheralsinVm}: ${missingPeripheralNames}`} type="error" showIcon />
          <br />
        </small>
      );
    }
    return render;
  };

  renderMissingPeripheralsInWings = (missingPeripheralsInWings) => {
    let render = null;
    if (missingPeripheralsInWings !== null && missingPeripheralsInWings !== undefined) {
      render = missingPeripheralsInWings.map((obj) => {
        if (obj.wingPeripheralTypes.length >= 1) {
          const missingPeripheralNames = obj.wingPeripheralTypes.map((p) => p.name);
          return (
            <small>
              <Alert
                message="Error"
                description={`${Strings.peripheralDevice.missingPeripheralsinwing}  ${obj.wingNumber} - ${obj.wingTypeName}: ${missingPeripheralNames}`}
                type="error"
                showIcon
              />
              <br />
            </small>
          );
        }
        return null;
      });
    }
    return render;
  };

  renderMissingPeripheralsInSlotGroups = (missingPeripheralsInSlotGroups) => {
    let render = null;
    if (missingPeripheralsInSlotGroups !== null && missingPeripheralsInSlotGroups !== undefined) {
      render = missingPeripheralsInSlotGroups.map((obj) => {
        if (obj.peripheralType != null) {
          const { name } = obj.peripheralType;
          return (
            <small>
              <Alert
                message="Error"
                description={` ${Strings.peripheralDevice.MissingperipheralsinSlotGroup} ${obj.slotGroupNumber} - ${Strings.machine.wing} ${obj.wingNumber}: ${name}`}
                type="error"
                showIcon
              />
              <br />
            </small>
          );
        }
        return null;
      });
    }
    return render;
  };

  renderUnassignPeripheral(row) {
    return (
      <Popconfirm
        title={<span>{this.WarnigInfoMessage(row.peripheralDeviceTypeId)}</span>}
        onConfirm={() => this.deleteVmPeripheralDevice(row.vmPeripheralDeviceId)}
        okText={Strings.generalTerms.accept}
        cancelText={Strings.generalTerms.cancel}
      >
        <span style={{ color: "#004173", cursor: "pointer" }}>
          <Icon type="close-circle" theme="twoTone" className="addEditRemoveButton" />
        </span>
      </Popconfirm>
    );
  }

  render() {
    const { vmPeripherals, loadingInfo, missingPeripheralsInVm, missingPeripheralsInWings, missingPeripheralsInSlotGroups } = this.state;
    const {
      permissions: { data },
    } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Divider orientation="left">
              <h3>{Strings.machine.configuration.peripheralDevices} </h3>
            </Divider>
          </div>
          <div className="col-12">
            <Table dataSource={vmPeripherals} pagination={false} loading={loadingInfo} rowKey="id" scroll={{ y: 250 }} bordered size="middle">
              <Column title={Strings.peripheralDevice.peripheralDeviceType} key="name" dataIndex="name" align="center" />
              <Column title={Strings.peripheralDevice.peripheralDeviceModel} key="reference" dataIndex="reference" align="center" />
              <Column
                title={Strings.machine.machineGeneration}
                key="currentPeripheralDevicesGeneration"
                dataIndex="currentPeripheralDevicesGeneration"
                render={(peripheralDev) => (peripheralDev !== undefined ? peripheralDev : Strings.peripheralDevice.peripheralAllGeneration)}
                align="center"
              />
              <Column
                title={Strings.peripheralDevice.peripheralDeviceSerialNumber}
                key="peripheralDeviceSerialNumber"
                render={(peripheralDev) =>
                  peripheralDev.noaHubKeyListSerialNumber != null ? (
                    <span>{`${peripheralDev.noaHubKeyListSerialNumber} `}</span>
                  ) : (
                    <span>{peripheralDev.peripheralDeviceSerialNumber}</span>
                  )
                }
                align="center"
              />
              <Column
                title={Strings.peripheralDevice.peripheralDeviceAlternativeSerialNumber}
                key="peripheralDeviceAlternativeSerialNumber"
                dataIndex="peripheralDeviceAlternativeSerialNumber"
                filtered
                align="center"
              />
              <Column title="Modbus" dataIndex="modbusId" render={(modbusId) => (modbusId != null ? modbusId : "--")} align="center" />
              <Column
                title={Strings.peripheralDevice.peripheralDeviceModelManufacturer}
                key="peripheralDeviceModelManufacturer"
                render={(peripheralDev) => (
                  <span>
                    <Tooltip title={`${Strings.peripheralDevice.mpn}: ${peripheralDev.mpn ? peripheralDev.mpn : "--"}`}>{peripheralDev.manufacturer}</Tooltip>
                  </span>
                )}
                align="center"
              />
              <Column title={Strings.machine.wing} key="wing" render={(row) => this.renderWing(row.wingNumber, row.wingTypeName)} align="center" />
              <Column title={Strings.machine.slotGroup} key="slotGroup" render={(row) => this.renderSlotGroup(row.slotGroupNumber)} align="center" />
              <Column
                title={Strings.generalTerms.assignationDate}
                key="assignationDate"
                render={(row) => <span>{new Date(row.assignationDate).toLocaleDateString("es-CO", { hour: "numeric", minute: "numeric" })}</span>}
                align="center"
              />
              {ValidatePermissionForComponentPart("PRIVILEGE VM PERIPHERAL DEVICE ENABLE STATUS", data) ? (
                <Column title={Strings.generalTerms.remove} key="unAssign" render={(row) => this.renderUnassignPeripheral(row)} align="center" />
              ) : null}
            </Table>
            <br />
          </div>
        </div>
        <div className="row" style={{ margin: "0% auto", maxWidth: "60%" }}>
          <div className="col-12">{this.renderMissingPeripheralsInVmWarning(missingPeripheralsInVm)}</div>
        </div>
        <div className="row" style={{ margin: "0% auto", maxWidth: "60%" }}>
          <div className="col-12 ">{this.renderMissingPeripheralsInWings(missingPeripheralsInWings)}</div>
        </div>
        <div className="row" style={{ margin: "0% auto", maxWidth: "60%" }}>
          <div className="col-12 ">{this.renderMissingPeripheralsInSlotGroups(missingPeripheralsInSlotGroups)}</div>
        </div>
        <Divider />
      </>
    );
  }
}

VendingMachinePeripheralDevices.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.any),
  vm: PropTypes.objectOf(PropTypes.any).isRequired,
  getPeripheralDevicesByVendingMachineId: PropTypes.func,
  deleteVmPeripheralDevice: PropTypes.func,
  getMissingPeripheralDevicesInVendingMachine: PropTypes.func,
  getMissingPeripheralDevicesInWings: PropTypes.func,
  getMissingPeripheralDevicesInSlotGroups: PropTypes.func,
};

VendingMachinePeripheralDevices.defaultProps = {
  permissions: null,
  getPeripheralDevicesByVendingMachineId: null,
  deleteVmPeripheralDevice: null,
  getMissingPeripheralDevicesInVendingMachine: null,
  getMissingPeripheralDevicesInWings: null,
  getMissingPeripheralDevicesInSlotGroups: null,
};

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}
export default connect(mapStateToProps, {
  getPeripheralDevicesByVendingMachineId,
  deleteVmPeripheralDevice,
  getMissingPeripheralDevicesInVendingMachine,
  getMissingPeripheralDevicesInWings,
  getMissingPeripheralDevicesInSlotGroups,
  getPendingRegistersByVm,
})(VendingMachinePeripheralDevices);
