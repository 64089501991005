// Dependencies
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import { Divider, Icon, Popconfirm, Table } from "antd";
import QRCode from "qrcode.react";

// Actions
import { getAllAccessLevels, getAllVmParameterCategories, getVendingMachineInfoById, listVmSetupByVmId } from "../../../actions";

// ------Components-----
import { accessLevelsNameLocalizationArray } from "../../../Utils/LanguageLocalization/accessLevelsLocalization";
import { vmParameterCategoryNameLocalizationArray } from "../../../Utils/LanguageLocalization/vmParameterCategoriesLocalization";
import { setVmSetupWithMeasurementUnit } from "../../../Utils/LanguageLocalization/vmSetupMeasurementUnit";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import Loading from "../../GenericComponents/loadingComponent/loading";
import { SelectGeneral } from "../../GenericComponents/selectComponent/selectGeneral";
import VendingMachineUpdateSetupModal from "./vendingMachineUpdateSetupModal";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

const { Column } = Table;

class VendingMachineSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      machine: props.vm,

      accessLevels: [],
      vmParameterCategories: [],
      accessLevelId: 0,
      vmParameterCategoryId: 0,
      vmSetups: null,

      vmSetupUpdateModalVisible: false,
      vmSetupSel: null,
    };
  }

  // -----Lyfecicle-----
  componentDidMount() {
    const { machine } = this.state;
    const { getAllAccessLevels, getAllVmParameterCategories, permissions } = this.props;
    if (machine) {
      getAllAccessLevels((response) => {
        const accessLevels = response.data.data;
        accessLevels.pop(); // Remove unmodifiable accessLevel from options (It is always included in query result)

        // Check if has privilege for expert level
        if (!ValidatePermissionForComponentPart("PRIVILEGE VMSETUP EXPERT ACCESS", permissions.data)) {
          accessLevels.pop(); // Remove expert level
        }

        this.setState({
          accessLevels: accessLevelsNameLocalizationArray(accessLevels.map((item) => ({ value: item.id, label: item.name }))),
        });
      });
      getAllVmParameterCategories((response) => {
        this.setState({
          vmParameterCategories: vmParameterCategoryNameLocalizationArray(response.data.data.map((item) => ({ value: item.id, label: item.name }))),
        });
      });
      this.queryVMSetup(0, 0);
    }
  }

  // -----Events-----
  queryVMSetup = (accessLevelId, vmParameterCategoryId) => {
    const { listVmSetupByVmId } = this.props;
    const { machine } = this.state;
    this.setState({
      isLoading: true,
    });
    listVmSetupByVmId(machine.id, accessLevelId, vmParameterCategoryId, (response) => {
      this.setState({
        vmSetups: _.orderBy(response.data.data, ["name"], ["asc"]),
        isLoading: false,
      });
    });
  };

  handleAccessLevelChange = (accessLevel) => {
    const { vmParameterCategoryId } = this.state;
    this.queryVMSetup(accessLevel.value, vmParameterCategoryId);
    this.setState({
      accessLevelId: accessLevel.value,
    });
  };

  handleVmParameterCategoryChange = (vmParameterCategory) => {
    const { accessLevelId } = this.state;
    this.queryVMSetup(accessLevelId, vmParameterCategory.value);
    this.setState({
      vmParameterCategoryId: vmParameterCategory.value,
    });
  };

  handleVmsetupUpdate = (vmSetupSel) => {
    this.setState({
      vmSetupUpdateModalVisible: true,
      vmSetupSel,
    });
  };

  hideVmsetupUpdateModal = (refreshQuery) => {
    const { accessLevelId, vmParameterCategoryId } = this.state;

    if (refreshQuery) {
      this.queryVMSetup(accessLevelId, vmParameterCategoryId);
    }
    this.setState({
      vmSetupSel: null,
      vmSetupUpdateModalVisible: false,
    });
  };

  render() {
    const {
      permissions: { data },
      vmIsConfig,
      vmIsConfigIsLoading,
    } = this.props;

    const { vmSetupUpdateModalVisible, machine, accessLevels, accessLevelId, vmParameterCategories, vmParameterCategoryId, vmSetups, isLoading, vmSetupSel } = this.state;
    return (
      <>
        <VendingMachineUpdateSetupModal isVisible={vmSetupUpdateModalVisible} hideModal={this.hideVmsetupUpdateModal} vmSetupSel={vmSetupSel} />
        {vmIsConfigIsLoading === true ? (
          <Loading />
        ) : (
          <div style={{ display: vmIsConfig === "0" ? "" : "none" }}>
            <div className="row">
              <div className="col-md-12">
                <Divider orientation="left">
                  <h3> {Strings.machine.initialSetup} </h3>
                </Divider>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6 text-justify">{Strings.machine.initialSetupInfo}</div>
              <div className="col-md-3">
                <QRCode value={machine.vmPassword} />
              </div>
              <div className="col-md-3">
                <h3>{Strings.machine.password}:</h3>
                <br />
                <h3 style={{ fontFamily: "courier" }}>{machine.vmPassword}</h3>
              </div>
            </div>
            <br />
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <Divider orientation="left">
              <h3> {Strings.machine.configurationParameters} </h3>
            </Divider>
          </div>
        </div>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-xs-12 col-md-5">
            <SelectGeneral
              text={Strings.vmSetup.accessLevel}
              tooltip={Strings.vmSetup.accessLevelTooltip}
              options={accessLevels}
              onChange={(e) => this.handleAccessLevelChange(e)}
              defaultValue={accessLevelId}
            />
          </div>
          <div className="col-xs-0 col-md-1" />
          <div className="col-xs-12 col-md-5">
            <SelectGeneral
              text={Strings.vmSetup.category}
              tooltip={Strings.vmSetup.categoryTooltip}
              options={vmParameterCategories}
              onChange={(e) => this.handleVmParameterCategoryChange(e)}
              defaultValue={vmParameterCategoryId}
            />
          </div>
          <div className="col-xs-0 col-md-1" />
        </div>
        <div className="row">
          <div className="col-12">
            <Table dataSource={vmSetups} loading={isLoading} key="id" scroll={{ y: 250 }} bordered size="middle">
              <Column title={Strings.vmSetup.parameter} dataIndex="name" style={{ width: "25%" }} align="center" />
              <Column title={Strings.generalTerms.description} dataIndex="description" style={{ width: "25%" }} align="center" />
              <Column
                title={Strings.generalTerms.value}
                style={{ width: "20%" }}
                render={(row) => setVmSetupWithMeasurementUnit(row.stringValue, row.measurementUnit)}
                align="center"
              />
              <Column
                title={Strings.generalTerms.updateDate}
                style={{ width: "20%" }}
                dataIndex="lastUpdateDate"
                render={(lastUpdateDate) => new Date(lastUpdateDate).toLocaleDateString("es-CO", { hour: "numeric", minute: "numeric" })}
                align="center"
              />
              {ValidatePermissionForComponentPart("PRIVILEGE VMSETUP UPDATE", data) ? (
                <Column
                  title={Strings.generalTerms.edit}
                  style={{ width: "10%" }}
                  align="center"
                  render={(row) =>
                    row.isModifiable ? (
                      <div>
                        <Popconfirm title={Strings.vmSetup.editVmsetupWarn} onConfirm={() => this.handleVmsetupUpdate(row)} okText="Sí" cancelText="No">
                          <Icon type="edit" className="addEditRemoveButton" />
                        </Popconfirm>
                      </div>
                    ) : (
                      ""
                    )
                  }
                />
              ) : (
                ""
              )}
            </Table>
          </div>
        </div>
        <Divider />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  listVmSetupByVmId,
  getAllAccessLevels,
  getAllVmParameterCategories,
  getVendingMachineInfoById,
})(VendingMachineSetup);
