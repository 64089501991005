// Dependencies
import { Checkbox, DatePicker, Divider, Icon, Modal, Popconfirm, message } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Components
import { GeneralInput } from "../GenericComponents/InputComponent/generalInput";
import { AcceptButton, AddButton, ReturnButton } from "../GenericComponents/buttons/index";
import Loading from "../GenericComponents/loadingComponent/loading";
import TextWithInfoTooltip from "../GenericComponents/textWithInfoTooltip";
import Titles from "../GenericComponents/titles";
import SoProductConfigToDispensationRuleTable from "./SoProductConfigToDispensationRuleTable.jsx";
import VmProductGroupConfigToDispensationRuleTable from "./VmProductGroupConfigToDispensationRuleTable.jsx";
import AddProductsToRuleModal from "./addProductsToRuleModal";

// Language localization
import Strings from "../../systemVariables/languageStrings";

// CSS
import "./editDispensationRule.css";

import {
  listFrequencyUnits,
  listProductAssignmentByDispensationRuleId,
  listSoProductByVmProductGroupByScDependencyId,
  listSoProductByVmProductGroupBySoDependencyId,
  listVmProductGroup,
  listVmProductGroupByScDependencyId,
  listVwScProductInformationByScDependency,
  updateDispensationRuleAndProductAssignment,
} from "../../actions/index";

import { FreqUnitNameLocalization, FreqUnitNameLocalizationPlural } from "../../Utils/LanguageLocalization/frequencyUnitNameLocalization";

class EditDispensationRules extends Component {
  scDispRuleProducts = [
    {
      key: "productassignmentbyruleId",
      title: <TextWithInfoTooltip name={Strings.product.product} tooltip={Strings.product.soProductTooltip} />,
      dataIndex: "productName",
    },
    {
      title: <TextWithInfoTooltip name={Strings.dispensationRule.availableAmount} tooltip={Strings.dispensationRule.availableAmountTooltip} />,
      render: (row) => {
        return <div>{!row.allowedAmount ? Strings.generalTerms.ilimited : row.allowedAmount + " " + Strings.generalTerms.units}</div>;
      },
    },
    {
      title: <TextWithInfoTooltip name={Strings.dispensationRule.frequencyUnit} tooltip={Strings.dispensationRule.frequencyUnitTooltip} />,
      render: (row) => {
        return (
          <div>
            <span className="class-for-name">Cada {`${row.frequencyValue} ${row.frequencyUnitName ? FreqUnitNameLocalization(row.frequencyUnitName) : "..."}`}</span>
          </div>
        );
      },
    },
    {
      title: <TextWithInfoTooltip name={Strings.dispensationRule.productGroup} tooltip={Strings.dispensationRule.productGroupTooltip} />,
      accessor: "vmProductGroupName",
      render: (row) => {
        return (
          <div>
            <span className="class-for-name">{row.vmProductGroupName ? row.vmProductGroupName : "Sin Grupo"}</span>
          </div>
        );
      },
    },
    {
      width: 50,
      render: (row) => (
        <div>
          <Icon type="edit" style={{ cursor: "pointer" }} theme="twoTone" className="addEditRemoveButton" onClick={() => this.showEditProductAssigmentModal(row)} />
        </div>
      ),
    },
    {
      accessor: "remove",
      width: 50,
      render: (row) => (
        <div>
          <Popconfirm title="Está seguro que desea eliminar?" onConfirm={() => this.disableProductRule(row)} okText="Sí" cancelText="No">
            <Icon style={{ cursor: "pointer" }} type="delete" className="addEditRemoveButton" />
          </Popconfirm>
        </div>
      ),
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      soDependencyId: this.props.location.props ? this.props.location.props.soDependencyId : null,
      scDependency: {
        id: this.props.location.props ? this.props.location.props.scDependency.id : null,
        name: this.props.location.props ? this.props.location.props.scDependency.name : null,
      },

      scDispensationRule: {
        id: this.props.location.props ? this.props.location.props.scDispensationRule.id : null,
        stockCustomerId: this.props.location.props ? this.props.location.props.scDependency.id : null,
        name: this.props.location.props ? this.props.location.props.scDispensationRule.name : null,
        description: this.props.location.props ? this.props.location.props.scDispensationRule.description : null,
        isEnabled: this.props.location.props ? this.props.location.props.scDispensationRule.isEnabled : null,
      },

      startDate: null,
      endDate: null,
      frequencyUnits: [],
      soProductList: [],
      vmProductGroupList: [],
      soProductByVmProductGroupList: [],

      soProductConfigured: [],
      vmProductGroupConfigured: [],
      productAssignmentsWithProductGroupId: [],

      soProductToAdd: [],
      vmProductGroupToAdd: [],
      productAssignmentByRuleToRemove: [],
      vmProductGroupToRemove: [],

      redirectBackToDispRules: false,
      modalIsVisible: false,

      isLoading: true,
      isLoadingButton: false,
      isChecked: false,

      isDisabledAddButton: true,
      isDisabledSaveButton: false,
      isDisabledReturnButton: false,
      disableStartDatePicker: false,
      disableEndDatePicker: true,
    };
  }

  /* --------------------Life cycle----------------------*/
  componentDidMount() {
    const { listFrequencyUnits, listVwScProductInformationByScDependency, listVmProductGroupByScDependencyId, listProductAssignmentByDispensationRuleId } = this.props;

    if (!this.state.scDispensationRule.id) {
      return <div>{this.noProductWarning()}</div>;
    }
    // Put initial dates
    const startDate = _.split(this.props.location.props.scDispensationRule.startDate2, "T")[0];
    this.setState({ startDate });

    const endDate = _.split(this.props.location.props.scDispensationRule.endDate2, "T")[0];
    if (endDate !== "--") {
      this.setState({
        endDate,
        isChecked: true,
        disableEndDatePicker: false,
      });
    } else {
      this.setState({
        endDate: moment(startDate).format(),
        isChecked: false,
        disableEndDatePicker: true,
      });
    }

    // Get the list of frequency units.
    listFrequencyUnits((response) => {
      // Allows only HOURS(0), DAYS(1), WEEKS(2), MONTH(3)
      const allowedFrequencyUnitIds = [0, 1, 2, 3];
      if (response.data.data) {
        let nativeFrequencyUnits = response.data.data
          .filter((item) => allowedFrequencyUnitIds.includes(item.id))
          .map((item) => ({
            value: item.id,
            label: item.name,
          }));
        this.setState({
          frequencyUnits: FreqUnitNameLocalizationPlural(nativeFrequencyUnits),
        });
      }
    });

    // Get the list of products associated with a specific stock customer.
    listVwScProductInformationByScDependency(this.state.scDependency.id, (response) => {
      this.setState({
        soProductList: response.data.data
          .map((item) => {
            return {
              id: item.soProductId,
              scProductInformationId: item.id,
              soProductName: item.soProductName,
              erpProductCode: item.erpProductCode,
              selectedAmount: 1,
              selectedFrequency: 1,
              selectedFrequencyUnit: null,
              productAssignmentByRuleId: null,
              vmProductGroupId: null,
            };
          })
          .sort((a, b) => (a.erpProductCode > b.erpProductCode ? 1 : b.erpProductCode > a.erpProductCode ? -1 : 0)),
      });
    });

    // Get the list of product groups associated with a specific stock customer
    listVmProductGroupByScDependencyId(this.state.scDependency.id, (response) => {
      this.setState({
        vmProductGroupList: response.data.data
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
              description: item.description,
              selectedAmount: 1,
              selectedFrequency: 1,
              selectedFrequencyUnit: null,
            };
          })
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
      });
    });

    // Get all product groups information by stock owner to validate the assignation of products.
    if (this.state.soDependencyId !== 0) {
      listSoProductByVmProductGroupBySoDependencyId(this.state.soDependencyId).then((value) => {
        this.setState({
          soProductByVmProductGroupList: value.data.data,
        });
      });
    } else {
      listSoProductByVmProductGroupByScDependencyId(this.state.scDependency.id).then((value) => {
        this.setState({
          soProductByVmProductGroupList: value.data.data,
        });
      });
    }
    // Get the products assigned to an specific dispensation rule (individual or by group)
    listProductAssignmentByDispensationRuleId(this.state.scDispensationRule.id, (response) => {
      if (response.data.data.length > 0) {
        // Get only the products assigned without product group.
        const productAssignmentsWithoutProductGroupId = response.data.data.filter((item) => item.vmProductGroupId == null);
        if (productAssignmentsWithoutProductGroupId.length > 0) {
          this.setState({ productAssignmentsWithoutProductGroupId });
          productAssignmentsWithoutProductGroupId.forEach((obj) => {
            const product = {};
            product.id = obj.soProductId;
            product.scProductInformationId = obj.scProductInformationId;
            product.soProductName = obj.soProductName;
            product.erpProductCode = obj.erpProductCode;
            product.selectedAmount = obj.allowedAmount;
            product.selectedFrequency = obj.allowedAmount ? obj.frequencyValue : null;
            product.selectedFrequencyUnit = { label: obj.frequencyUnitName, value: obj.frequencyUnitId };
            product.productAssignmentByRuleId = obj.productassignmentbyruleId;
            product.vmProductGroupId = null;
            product.isIlimited = obj.allowedAmount ? false : true;
            this.setState({ soProductConfigured: this.state.soProductConfigured.concat(product) });
          });
        }

        // Get only the products assigned with product group. The product groups assigned to the rule are listed with their dispensing conditions.
        const productAssignmentsWithProductGroupId = response.data.data.filter((item) => item.vmProductGroupId != null);
        if (productAssignmentsWithProductGroupId.length > 0) {
          this.setState({ productAssignmentsWithProductGroupId });
          productAssignmentsWithProductGroupId.forEach((obj) => {
            if (this.state.vmProductGroupConfigured.filter((item) => item.id === obj.vmProductGroupId).length === 0) {
              const vmProductGroup = {};
              vmProductGroup.id = obj.vmProductGroupId;
              vmProductGroup.name = obj.vmProductGroupName;
              vmProductGroup.description = obj.vmProductGroupDescription;
              vmProductGroup.selectedAmount = obj.allowedAmount;
              vmProductGroup.selectedFrequency = obj.allowedAmount ? obj.frequencyValue : null;
              vmProductGroup.selectedFrequencyUnit = { label: obj.frequencyUnitName, value: obj.frequencyUnitId };
              vmProductGroup.isIlimited = obj.allowedAmount ? false : true;
              this.setState({ vmProductGroupConfigured: this.state.vmProductGroupConfigured.concat(vmProductGroup) });
            }
          });
        }
      }
      if (this.state.vmProductGroupConfigured.length > 0 || this.state.soProductConfigured.length > 0) {
        this.setState({
          isDisabledAddButton: false,
          isLoading: false,
        });
      }
    });
  }

  /* -----------------------Events-----------------------*/
  nameInputHandleChange(event) {
    const value = event.target.value;
    const scDispensationRule = { ...this.state.scDispensationRule };
    scDispensationRule.name = value;
    this.setState({ scDispensationRule });
    if (value.length > 0) {
      this.setState({ isDisabledSaveButton: false });
    } else {
      this.setState({ isDisabledSaveButton: true });
    }
  }

  descriptionInputHandleChange(event) {
    const value = event.target.value;
    const scDispensationRule = { ...this.state.scDispensationRule };
    scDispensationRule.description = value;
    this.setState({ scDispensationRule });
  }

  startDateEvent = (date, dateString) => {
    const startDateLocal = moment(date).format();
    if (this.state.isChecked === true) {
      if (this.state.endDate < startDateLocal) {
        this.setState({ startDate: this.state.startDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidStartDateError,
        });
      } else {
        this.setState({ startDate: _.split(startDateLocal, "T")[0] });
      }
    } else {
      this.setState({ startDate: _.split(startDateLocal, "T")[0] });
    }
  };

  endDateEvent = (date, dateString) => {
    const endDateLocal = moment(date).format();
    if (this.state.isChecked === true) {
      if (endDateLocal < this.state.startDate) {
        this.setState({ endDate: this.state.endDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidEndDateError,
        });
      } else {
        this.setState({ endDate: _.split(endDateLocal, "T")[0] });
      }
    } else {
      this.setState({ endDate: _.split(endDateLocal, "T")[0] });
    }
  };

  nullEndDateHandleChange(e) {
    var endDateLocal = this.state.startDate;
    if (e.target.checked === true) {
      this.setState({
        endDate: _.split(endDateLocal, "T")[0],
        disableEndDatePicker: !this.state.disableEndDatePicker,
        isChecked: true,
      });
    } else {
      this.setState({
        endDate: _.split(endDateLocal, "T")[0],
        disableEndDatePicker: !this.state.disableEndDatePicker,
        isChecked: false,
      });
    }
  }

  noProductWarning() {
    Modal.warning({
      title: Strings.dispensationRule.noDispRuleSelected,
      onOk: this.handleOk,
      content: Strings.dispensationRule.noDispRuleSelectedMsg,
    });
  }

  handleOk = () => {
    this.setState({ redirectBackToDispRules: true });
  };

  showScProductsModal() {
    // Initial listings are filtered (products and product groups associated with the selected customer).
    if (this.state.soProductConfigured.length > 0) {
      let soProductListFiltered = this.state.soProductList.filter((item) => !this.state.soProductConfigured.map((obj) => obj.id).includes(item.id));
      this.setState({
        soProductList: soProductListFiltered.sort((a, b) => (a.erpProductCode > b.erpProductCode ? 1 : b.erpProductCode > a.erpProductCode ? -1 : 0)),
      });
    } else {
      this.setState({
        soProductList: this.state.soProductList.sort((a, b) => (a.erpProductCode > b.erpProductCode ? 1 : b.erpProductCode > a.erpProductCode ? -1 : 0)),
      });
    }

    if (this.state.vmProductGroupConfigured.length > 0 && this.state.soProductConfigured.length !== 0) {
      let vmProductGroupListFiltered = this.state.vmProductGroupList.filter((item) => !this.state.vmProductGroupConfigured.map((obj) => obj.id).includes(item.id));
      this.setState({
        vmProductGroupList: vmProductGroupListFiltered.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
      });
    } else {
      this.setState({
        vmProductGroupList: this.state.vmProductGroupList.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
      });
    }

    this.setState({ modalIsVisible: true });
  }

  addSelectedSoProduct = (row) => ({
    onClick: () => {
      let soProductByVmProductGroupFilteredBySoProductId = this.state.soProductByVmProductGroupList.filter((item) => item.soProductId === row.id);
      // Validate if the selected product is associated with any of the selected product groups
      let validationList = [];
      let soProductFlag = false;
      let vmProductGroupName = null;

      if (soProductByVmProductGroupFilteredBySoProductId.length > 0) {
        this.state.vmProductGroupConfigured.forEach((element) => {
          validationList = soProductByVmProductGroupFilteredBySoProductId.filter((item) => item.vmProductGroupId === element.id);
          if (validationList.length > 0) {
            soProductFlag = true;
            vmProductGroupName = element.name;
          }
        });
      }

      if (soProductFlag === true) {
        message.warning(Strings.dispensationRule.selectedProductWarningMsg1 + vmProductGroupName + Strings.dispensationRule.selectedProductWarningMsg2);
      } else {
        let product = {};
        product.id = row.id;
        product.scProductInformationId = row.scProductInformationId;
        product.soProductName = row.soProductName;
        product.erpProductCode = row.erpProductCode;
        product.selectedAmount = 1;
        product.selectedFrequency = 1;
        product.selectedFrequencyUnit = null;
        product.productAssignmentByRuleId = null;
        product.vmProductGroupId = null;
        // product.isIlimited = true;

        this.setState({
          soProductConfigured: this.state.soProductConfigured.concat(product),
          soProductToAdd: this.state.soProductToAdd.concat(product),
          soProductList: this.state.soProductList
            .filter((item) => item.id !== row.id)
            .sort((a, b) => (a.erpProductCode > b.erpProductCode ? 1 : b.erpProductCode > a.erpProductCode ? -1 : 0)),
        });
      }
    },
  });

  addSelectedVmProductGroup = (row) => ({
    onClick: () => {
      let soProductByVmProductGroupFilteredByGroupId = this.state.soProductByVmProductGroupList.filter((item) => item.vmProductGroupId === row.id);
      // Validate if the selected product group contain some product assigned individually.
      let soProductFlag = false;
      let vmProductGroupFlag = false;
      let soProductName = null;
      if (soProductByVmProductGroupFilteredByGroupId.length > 0 && this.state.vmProductGroupList.length > 0) {
        let validationList = [];
        let validationList2 = [];
        soProductByVmProductGroupFilteredByGroupId.forEach((element) => {
          validationList = this.state.soProductConfigured.filter((item) => item.id === element.soProductId && item.productAssignmentByRuleId != null);
          if (validationList.length > 0) {
            soProductFlag = true;
            vmProductGroupFlag = false;
            soProductName = validationList[0].soProductName;
          }
          validationList2 = this.state.soProductConfigured.filter((item) => item.id === element.soProductId && item.productAssignmentByRuleId == null);
          if (validationList2.length > 0) {
            soProductFlag = true;
            vmProductGroupFlag = false;
            soProductName = validationList2[0].soProductName;
          }
        });
      }

      // Validate if the selected product group containing products in common with other product group.
      let vmProductGroupToConfigFiltered = null;
      let vmProductGroupId = null;

      if (this.state.vmProductGroupConfigured.length > 0) {
        this.state.vmProductGroupConfigured.forEach((element) => {
          let soProductByVmProductGroup = this.state.soProductByVmProductGroupList.filter((item) => item.vmProductGroupId === element.id);
          let soProductByVmProductGroupIntersected = _.intersectionBy(soProductByVmProductGroup, soProductByVmProductGroupFilteredByGroupId, "soProductId");
          if (soProductByVmProductGroupIntersected.length > 0) {
            vmProductGroupFlag = true;
            soProductFlag = false;
            vmProductGroupId = soProductByVmProductGroupIntersected[0].vmProductGroupId;
            vmProductGroupToConfigFiltered = this.state.vmProductGroupConfigured.filter((item) => item.id === vmProductGroupId);
          }
        });
      }

      if (soProductFlag === true) {
        message.warning(Strings.dispensationRule.selectedVmProductGroupWarningMsg1 + soProductName + Strings.dispensationRule.selectedVmProductGroupWarningMsg2);
      }
      if (vmProductGroupFlag === true) {
        message.warning(Strings.dispensationRule.selectedVmProductGroupWarningMsg3 + " (" + vmProductGroupToConfigFiltered[0].name + ").");
      }
      if (soProductFlag !== true && vmProductGroupFlag !== true) {
        this.setState({
          vmProductGroupList: this.state.vmProductGroupList.filter((item) => item.id !== row.id).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
        });

        if (this.state.vmProductGroupToAdd.filter((item) => item.id === row.id) == 0) {
          this.setState({
            vmProductGroupToAdd: this.state.vmProductGroupToAdd.concat(row),
          });
        }

        if (this.state.vmProductGroupConfigured.filter((item) => item.id === row.id) == 0) {
          this.setState({
            vmProductGroupConfigured: this.state.vmProductGroupConfigured.concat(row),
          });
        }
      }
    },
  });

  selectHandleEvent(row, name) {
    switch (name) {
      case "soProductToAdd":
        this.setState({
          soProductConfigured: this.state.soProductConfigured.map((item) => {
            if (item.id === row.id) {
              item = row;
            }
            return item;
          }),
        });
        let soProductToAdd = this.state.soProductToAdd;
        // Capture rule changes by individual products.
        let productValidation = this.state.soProductToAdd.filter((item) => item.id === row.id);
        if (productValidation.length > 0) {
          _.remove(soProductToAdd, (obj) => obj.id === row.id);
        }
        soProductToAdd.push(row);
        this.setState({ soProductToAdd });
        break;
      default:
      case "vmProductGroupToAdd":
        this.setState({
          vmProductGroupConfigured: this.state.vmProductGroupConfigured.map((item) => {
            if (item.id === row.id) {
              item = row;
            }
            return item;
          }),
        });
        let vmProductGroupToAdd = this.state.vmProductGroupToAdd;
        // Capture rule changes by product groups.
        let vmProductGroupValidation = vmProductGroupToAdd.filter((item) => item.id === row.id);
        if (vmProductGroupValidation.length > 0) {
          _.remove(vmProductGroupToAdd, (obj) => obj.id === row.id);
        }
        vmProductGroupToAdd.push(row);
        this.setState({ vmProductGroupToAdd });

        break;
    }
  }

  removeHandleEvent(row, name) {
    switch (name) {
      case "soProductToAdd":
        let product = {};
        product.id = row.id;
        product.scProductInformationId = row.scProductInformationId;
        product.soProductName = row.soProductName;
        product.erpProductCode = row.erpProductCode;
        product.selectedAmount = 1;
        product.selectedFrequency = 1;
        product.selectedFrequencyUnit = null;
        product.productAssignmentByRuleId = null;
        product.vmProductGroupId = null;
        // product.isIlimited = true;

        this.setState({
          soProductConfigured: this.state.soProductConfigured.filter((item) => item.id !== product.id),
          soProductToAdd: this.state.soProductToAdd.filter((item) => item.id !== product.id),
        });

        if (this.state.soProductList.filter((item) => item.id === product.id) == 0) {
          this.setState({
            soProductList: this.state.soProductList.concat(product).sort((a, b) => (a.erpProductCode > b.erpProductCode ? 1 : b.erpProductCode > a.erpProductCode ? -1 : 0)),
          });
        }

        if (!this.state.productAssignmentByRuleToRemove.filter((item) => item.id === product.id).length && row.productAssignmentByRuleId !== null) {
          this.setState({
            productAssignmentByRuleToRemove: this.state.productAssignmentByRuleToRemove.concat(row),
          });
        }
        break;
      default:
      case "vmProductGroupToAdd":
        let vmProductGroup = {};
        vmProductGroup.id = row.id;
        vmProductGroup.name = row.name;
        vmProductGroup.description = row.description;
        vmProductGroup.selectedAmount = 1;
        vmProductGroup.selectedFrequency = 1;
        vmProductGroup.selectedFrequencyUnit = null;

        this.setState({
          vmProductGroupConfigured: this.state.vmProductGroupConfigured.filter((item) => item.id !== vmProductGroup.id),
          vmProductGroupToAdd: this.state.vmProductGroupToAdd.filter((item) => item.id !== vmProductGroup.id),
        });

        if (this.state.vmProductGroupList.filter((item) => item.id === vmProductGroup.id) == 0) {
          this.setState({
            vmProductGroupList: this.state.vmProductGroupList.concat(vmProductGroup).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
          });
        }

        if (!this.state.vmProductGroupToRemove.filter((item) => item.id === vmProductGroup.id).length) {
          this.setState({
            vmProductGroupToRemove: this.state.vmProductGroupToRemove.concat(row),
          });
        }
        break;
    }
  }

  setModalVisible = (isVisible) => {
    this.setState({ modalIsVisible: isVisible });
  };

  ilimithandleEvent(row, name) {
    switch (name) {
      case "soProductToAdd":
        this.setState({
          soProductConfigured: this.state.soProductConfigured.map((item) => {
            if (item.id === row.id) {
              item = row;
            }
            return item;
          }),
        });
        if (this.state.soProductToAdd.some((item) => item.id === row.id)) {
          let soProdToAdd = this.state.soProductToAdd.map((item) => {
            if (item.id === row.id) {
              item = row;
            }
            return item;
          });
          this.setState({
            soProductToAdd: soProdToAdd,
          });
        } else {
          let soProdToAdd = this.state.soProductToAdd.concat(row);
          this.setState({
            soProductToAdd: soProdToAdd,
          });
        }
        break;
      default:
      case "vmProductGroupToAdd":
        this.setState({
          vmProductGroupConfigured: this.state.vmProductGroupConfigured.map((item) => {
            if (item.id === row.id) {
              item = row;
            }
            return item;
          }),
        });

        if (this.state.vmProductGroupToAdd.filter((item) => item.id === row.id) > 0) {
          this.setState({
            vmProductGroupToAdd: this.state.vmProductGroupToAdd.map((item) => {
              if (item.id === row.id) {
                item = row;
              }
              return item;
            }),
          });
        } else if (this.state.vmProductGroupToAdd.concat(row).length <= 1) {
          this.setState({
            vmProductGroupToAdd: this.state.vmProductGroupToAdd.concat(row),
          });
        } else {
          let vmProductGroupToAdd = this.state.vmProductGroupToAdd.concat(row).filter((item, index) => {
            return this.state.vmProductGroupToAdd.concat(row).indexOf(item) === index;
          });

          this.setState({
            vmProductGroupToAdd: vmProductGroupToAdd,
          });
        }

        break;
    }
  }

  editScDispensationRuleHandleChange() {
    let msg = null;
    let frequencyUnitflag = false;
    this.setState({
      isLoadingButton: true,
      isDisabledReturnButton: true,
    });

    const { soProductConfigured, vmProductGroupConfigured } = this.state;

    // Validate that at least one product is assigned to the rule
    if (soProductConfigured.length === 0 && vmProductGroupConfigured.length === 0) {
      this.setState({
        isLoadingButton: false,
        isDisabledReturnButton: false,
      });
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.dispensationRule.errorNoProducts,
      });
      return;
    }

    // Validate if all individually assigned products have selected frequency unit
    if (soProductConfigured.length > 0) {
      frequencyUnitflag = soProductConfigured.some((item) => {
        if (item.selectedFrequencyUnit == null) {
          msg = Strings.dispensationRule.editScDispensationRuleErrorMsg1 + item.soProductName;
          return true;
        }
        return false;
      });
    }

    // Validate if all assigned product groups have frequency unit selected
    if (vmProductGroupConfigured.length > 0 && frequencyUnitflag === false) {
      frequencyUnitflag = vmProductGroupConfigured.some((item) => {
        if (item.selectedFrequencyUnit == null) {
          msg = Strings.dispensationRule.editScDispensationRuleErrorMsg2 + item.name;
          return true;
        }
      });
    }

    // Show error message by frequency unit selection
    if (frequencyUnitflag === true) {
      this.setState({
        isLoadingButton: false,
        isDisabledReturnButton: false,
      });
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: msg + Strings.dispensationRule.editScDispensationRuleErrorMsg3,
      });
    } else {
      this.setState({
        isLoadingButton: true,
        // isDisabledReturnButton: false
      });
      const productAssignmentByRuleToAdd = [];
      let productAssignmentByRuleToUpdate = [];
      const productAssignmentByRuleToRemove = [];
      let flagAux = true;
      const { soProductToAdd, vmProductGroupToAdd, productAssignmentsWithProductGroupId, soProductByVmProductGroupList } = this.state;
      // Organize lists of product assignments to add, update and disable

      if (soProductToAdd.length > 0) {
        soProductToAdd.forEach((item) => {
          const productAssignment = {};
          productAssignment.id = item.productAssignmentByRuleId;
          productAssignment.scDispensationRuleId = this.state.scDispensationRule.id;
          productAssignment.scProductInformationId = item.scProductInformationId;
          productAssignment.vmProductGroupId = item.vmProductGroupId;
          productAssignment.allowedAmount = item.selectedAmount;
          productAssignment.frequencyValue = item.selectedFrequency;
          productAssignment.frequencyUnitId = item.selectedFrequencyUnit.value;
          productAssignment.isEnabled = 1;
          if (item.productAssignmentByRuleId == null) productAssignmentByRuleToAdd.push(productAssignment);
          else productAssignmentByRuleToUpdate.push(productAssignment);
        });
      }
      
      if (vmProductGroupToAdd.length > 0) {
        
        // Update changes in product assignments by product group
        vmProductGroupToAdd.forEach((vmProductGroup) => {
          const productAssignmentsWithProductGroup = productAssignmentsWithProductGroupId.filter(
            (item) => item.vmProductGroupId === vmProductGroup.id
          );
          const productsByProductGroup = soProductByVmProductGroupList.filter(
            (item) => item.vmProductGroupId === vmProductGroup.id
          );
      
          if (
            productAssignmentsWithProductGroup.length > 0 && 
            productsByProductGroup[0].vmProductGroupId === productAssignmentsWithProductGroup[0].vmProductGroupId &&
            !this.state.vmProductGroupToRemove.find(item => item.id === productsByProductGroup[0].vmProductGroupId)
          ) {
            productAssignmentsWithProductGroup.forEach((item) => {
              const productAssignmentToUpdate = {};
              productAssignmentToUpdate.id = item.productassignmentbyruleId;
              productAssignmentToUpdate.scDispensationRuleId = this.state.scDispensationRule.id;
              productAssignmentToUpdate.scProductInformationId = item.scProductInformationId;
              productAssignmentToUpdate.vmProductGroupId = item.vmProductGroupId;
              productAssignmentToUpdate.allowedAmount = vmProductGroup.selectedAmount;
              productAssignmentToUpdate.frequencyValue = vmProductGroup.selectedFrequency;
              productAssignmentToUpdate.frequencyUnitId = vmProductGroup.selectedFrequencyUnit.value;
              productAssignmentToUpdate.isEnabled = 1;
              productAssignmentByRuleToUpdate.push(productAssignmentToUpdate);
            });
          }
      
          // Add new product assignments by product group
          else {
            productsByProductGroup.forEach((productByGroup) => {
              const productInfo = this.state.soProductList.filter((item) => item.id === productByGroup.soProductId);
              if (productsByProductGroup.length > 0 && productInfo[0] !== undefined) {
                const productAssignmentToAdd = {};
                productAssignmentToAdd.id = null;
                productAssignmentToAdd.scDispensationRuleId = this.state.scDispensationRule.id;
                productAssignmentToAdd.scProductInformationId = productInfo[0].scProductInformationId;

                productAssignmentToAdd.vmProductGroupId = vmProductGroup.id;
                productAssignmentToAdd.allowedAmount = vmProductGroup.selectedAmount;
                productAssignmentToAdd.frequencyValue = vmProductGroup.selectedFrequency;
                productAssignmentToAdd.frequencyUnitId = vmProductGroup.selectedFrequencyUnit.value;
                productAssignmentToAdd.isEnabled = 1;
                productAssignmentByRuleToAdd.push(productAssignmentToAdd);
              }
            });
          }
        });
      }

      if (this.state.productAssignmentByRuleToRemove.length > 0) {
        this.state.productAssignmentByRuleToRemove.forEach((productAssignment) => {
          const productAssignmentToRemove = {};
          productAssignmentToRemove.id = productAssignment.productAssignmentByRuleId;
          productAssignmentToRemove.scDispensationRuleId = this.state.scDispensationRule.id;
          productAssignmentToRemove.soProductId = productAssignment.id;
          productAssignmentToRemove.scProductInformationId = productAssignment.scProductInformationId;
          productAssignmentToRemove.vmProductGroupId = productAssignment.vmProductGroupId;
          productAssignmentToRemove.isEnabled = 0;
          productAssignmentByRuleToRemove.push(productAssignmentToRemove);
        });
      }

      if (this.state.vmProductGroupToRemove.length > 0) {
        this.state.vmProductGroupToRemove.forEach((vmProductGroup) => {
          const productAssignmentsWithProductGroup = this.state.productAssignmentsWithProductGroupId.filter(
            (item) => item.vmProductGroupId === vmProductGroup.id
          );
          if (productAssignmentsWithProductGroup.length > 0) {
            productAssignmentsWithProductGroup.forEach((item) => {
              const productAssignmentToRemove = {};
              productAssignmentToRemove.id = item.productassignmentbyruleId;
              productAssignmentToRemove.scDispensationRuleId = item.scDispensationRuleId;
              productAssignmentToRemove.soProductId = item.soProductId;
              productAssignmentToRemove.scProductInformationId = item.scProductInformationId;
              productAssignmentToRemove.vmProductGroupId = item.vmProductGroupId;
              productAssignmentToRemove.isEnabled = 0;
              productAssignmentByRuleToRemove.push(productAssignmentToRemove);
            });
          }
        });
      }

      const scDispensationRule = { ...this.state.scDispensationRule };
      scDispensationRule.startDate = moment(this.state.startDate).utc().format();
      if (this.state.isChecked === true) {
        scDispensationRule.endDate = moment(this.state.endDate).utc().format();
      } else {
        scDispensationRule.endDate = null;
      }

      let dispensationRuleDto = {};
      dispensationRuleDto.scDispensationRule = scDispensationRule;
      dispensationRuleDto.productAssignmentByRuleToAdd = productAssignmentByRuleToAdd.filter((item, index) => {
        return productAssignmentByRuleToAdd.indexOf(item) === index;
      });
      dispensationRuleDto.productAssignmentByRuleToUpdate = productAssignmentByRuleToUpdate.filter((item, index) => {
        return productAssignmentByRuleToUpdate.indexOf(item) === index;
      });
      dispensationRuleDto.productAssignmentByRuleToRemove = productAssignmentByRuleToRemove;

      // Post Object
      this.props.updateDispensationRuleAndProductAssignment(dispensationRuleDto, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          this.setState({
            isLoadingButton: false,
            isDisabledReturnButton: false,
          });
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: `${Strings.generalResponses.saveError} ${Strings.generalResponses.connectionError}`,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          this.setState({
            isLoadingButton: false,
            isDisabledReturnButton: false,
          });
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: `${Strings.dispensationRule.alreadyExistsDispensationRuleError} ${dispensationRuleDto.scDispensationRule.name}.`,
          });
        } else if (response.data && response.data.code === 0) {
          // Successfull entity save
          dispensationRuleDto = {};
          this.setState({
            isLoadingButton: false,
            isDisabledReturnButton: false,
            vmProductGroupsAssigned: [],
          });
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
            onOk: this.handleOk,
          });
        } else {
          // Other error
          this.setState({
            isLoadingButton: false,
            isDisabledReturnButton: false,
          });
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.serverError,
          });
        }
      });
    }
  }

  render() {
    if (this.state.redirectBackToDispRules) {
      // Redirects to SoProductSlotType from No Product Modal Warning
      return <Redirect to="/dispensationRules" />;
    }

    const {
      permissions: { data },
    } = this.props;
    return (
      <div className="content-container">
        <AddProductsToRuleModal
          setIsVisible={this.setModalVisible}
          isVisible={this.state.modalIsVisible}
          soProductList={this.state.soProductList}
          vmProductGroupList={this.state.vmProductGroupList}
          addSoProduct={this.addSelectedSoProduct}
          addVmProductGroup={this.addSelectedVmProductGroup}
        />
        <div className="row">
          <Titles title={Strings.dispensationRule.editScDispensationRule} tooltip={Strings.dispensationRule.editScDispensationRuleTooltip} />
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.scDependency.indirectClient}</h3>
            </Divider>
            <div>
              <h3>{this.state.scDependency.name}</h3>
            </div>
          </div>
        </div>

        {/* General parameterization of the dispensing rule: name, description and start and end dates */}
        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3> 1. {Strings.generalTerms.generalParameters}</h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="vertSpace col-md-6">
              <GeneralInput
                text={Strings.generalTerms.name}
                tooltip={Strings.dispensationRule.dispensationRuleNameToolTip}
                placeholder={Strings.generalTerms.name}
                onChange={(e) => this.nameInputHandleChange(e)}
                value={this.state.scDispensationRule.name}
                isReq
              />
            </div>
            <div className="vertSpace col-md-6">
              <GeneralInput
                text={Strings.generalTerms.description}
                tooltip={Strings.dispensationRule.dispensationRuleDescriptionToolTip}
                placeholder={Strings.generalTerms.description}
                onChange={(e) => this.descriptionInputHandleChange(e)}
                value={this.state.scDispensationRule.description}
                isReq={false}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <div className="vertSpace col-md-2">
              <TextWithInfoTooltip name={`${Strings.dispensationRule.startDate}: `} tooltip={Strings.dispensationRule.startDateTooltip} />
            </div>
            <div className="vertSpace alignCalendars col-md-4">
              <DatePicker
                value={moment(this.state.startDate)}
                onChange={(date, dateString) => this.startDateEvent(date, dateString)}
                required
                disabled={this.state.disableStartDatePicker}
              />
            </div>
            <div className="vertSpace col-md-2">
              <TextWithInfoTooltip name={`${Strings.dispensationRule.endDate}: `} tooltip={Strings.dispensationRule.endDateTooltip} />
            </div>
            <div className="vertSpace alignCalendars col-md-4">
              <Checkbox checked={this.state.isChecked} onChange={(e) => this.nullEndDateHandleChange(e)} />
              &nbsp; &nbsp;
              <DatePicker
                value={moment(this.state.endDate)}
                onChange={(date, dateString) => this.endDateEvent(date, dateString)}
                required={false}
                disabled={this.state.disableEndDatePicker}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col">
            <div className="col">
              <Divider orientation="left">
                <h3>
                  {" "}
                  <TextWithInfoTooltip name={`2. ${Strings.dispensationRule.configProducts}`} tooltip={Strings.dispensationRule.configProductsTooltip} />
                </h3>
              </Divider>
            </div>

            {!this.state.isLoading ? (
              // Shows the button that allows adding a new product group or individual product.
              <div className="vertSpace col">
                <div className="row">
                  <div className="col-md-6" />
                  <div className="col-md-6">
                    <AddButton disabled={this.state.isDisabledAddButton} tooltip={Strings.dispensationRule.addProductsTooltip} onClick={(e) => this.showScProductsModal(e)} />
                  </div>
                </div>
                {/* Setting the rule dispensing parameters: Quantity, frequency, frequency unit (fixed or unlimited). 
                It is displayed when a product or group of products is selected. If at least one of the individual products was selected, populate the configuration table by product */}
                {this.state.soProductConfigured.length > 0 ? (
                  <div className="vertSpace row">
                    <div className="col-12">
                      <h3>{Strings.product.individualConfigBySelectedSoProduct}</h3>
                      <SoProductConfigToDispensationRuleTable
                        isEditFlag
                        soProductToConfig={this.state.soProductConfigured}
                        frequencyUnits={this.state.frequencyUnits}
                        editSelectedProduct={(row) => this.selectHandleEvent(row, "soProductToAdd")}
                        removeSelectedProduct={(row) => this.removeHandleEvent(row, "soProductToAdd")}
                        ilimitedProductSelected={(row) => this.ilimithandleEvent(row, "soProductToAdd")}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* If at least one of the product groups was selected, populate the configuration table by group */}
                {this.state.vmProductGroupConfigured.length > 0 ? (
                  <div className="vertSpace row">
                    <div className="vertSpace col-12">
                      <h3>{Strings.vmProductGroup.configurationBySelectedVmProductGroup}</h3>
                      <VmProductGroupConfigToDispensationRuleTable
                        isEditFlag
                        vmProductGroupsAssigned={this.state.vmProductGroupConfigured}
                        frequencyUnits={this.state.frequencyUnits}
                        editSelectedProductGroup={(row) => this.selectHandleEvent(row, "vmProductGroupToAdd")}
                        removeSelectedVmProductGroup={(row) => this.removeHandleEvent(row, "vmProductGroupToAdd")}
                        ilimitedProductGroupSelected={(row) => this.ilimithandleEvent(row, "vmProductGroupToAdd")}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row justify-content-end">
                  <div className="vertSpace col-6 col-md-3">
                    <ReturnButton link="/dispensationRules" isDisabled={this.state.isDisabledReturnButton} />
                  </div>
                  <div className="vertSpace col-6 col-md-3">
                    <AcceptButton onClick={() => this.editScDispensationRuleHandleChange()} loading={this.state.isLoadingButton} isDisabled={this.state.isDisabledSaveButton} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  listProductAssignmentByDispensationRuleId,
  listVwScProductInformationByScDependency,
  listSoProductByVmProductGroupBySoDependencyId,
  listSoProductByVmProductGroupByScDependencyId,
  listVmProductGroupByScDependencyId,
  listVmProductGroup,
  listFrequencyUnits,
  updateDispensationRuleAndProductAssignment,
})(EditDispensationRules);
