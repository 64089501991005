export function IsProductValid(product) {
  let isValid = true;

  if (product.erpProductCode === undefined || product.erpProductCode === null || product.erpProductCode === '') {
    isValid = false;
  }

  if (product.name === undefined || product.name === null || product.name === '') {
    isValid = false;
  }

  if (product.productTaxTypeId === undefined || product.productTaxTypeId === null || product.productTaxTypeId === '') {
    isValid = false;
  }

  if (product.preTaxPrice === undefined || product.preTaxPrice === null || product.preTaxPrice === '') {
    isValid = false;
  }

  if (product.soProductTypeId !== 0 && (product.weight === undefined || product.weight === null || product.weight === '')) {
    isValid = false;
  }

  if (product.soProductTypeId !== 0 && (product.height === undefined || product.height === null || product.height === '')) {
    isValid = false;
  }

  if (product.soProductTypeId !== 0 && (product.width === undefined || product.width === null || product.width === '')) {
    isValid = false;
  }

  if (product.soProductTypeId !== 0 && (product.depth === undefined || product.depth === null || product.depth === '')) {
    isValid = false;
  }

  return isValid;
}
