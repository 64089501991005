import Strings from "../../systemVariables/languageStrings";

export function SlotSizeNameLocalization(slotSizeName) {
  let slotSizeNameL = "";
  switch (slotSizeName) {
    case "SINGLE":
      slotSizeNameL = Strings.planogram.sizes.single;
      break;
    case "ONE_AND_A_HALF":
      slotSizeNameL = Strings.planogram.sizes.oneAndAHalf;
      break;
    case "DOUBLE":
      slotSizeNameL = Strings.planogram.sizes.double;
      break;
    case "TWO_AND_A_HALF":
      slotSizeNameL = Strings.planogram.sizes.twoAndAHalf;
      break;
    case "TRIPLE":
      slotSizeNameL = Strings.planogram.sizes.triple;
      break;
    default:
      break;
  }
  return slotSizeNameL;
}

export function SlotSizeNameLocalizationArray(slotSizeNames) {
  var slotSizeNamesLocal = [];
  slotSizeNames.forEach((item) => {
    switch (item.label) {
      case "SINGLE":
        slotSizeNamesLocal.push({ value: item.value, label: Strings.planogram.sizes.single });
        break;
      case "ONE_AND_A_HALF":
        slotSizeNamesLocal.push({ value: item.value, label: Strings.planogram.sizes.oneAndAHalf });
        break;
      case "DOUBLE":
        slotSizeNamesLocal.push({ value: item.value, label: Strings.planogram.sizes.double });
        break;
      case "TWO_AND_A_HALF":
        slotSizeNamesLocal.push({ value: item.value, label: Strings.planogram.sizes.twoAndAHalf });
        break;
      case "TRIPLE":
        slotSizeNamesLocal.push({ value: item.value, label: Strings.planogram.sizes.triple });
        break;
      default:
        break;
    }
  });
  return slotSizeNamesLocal;
}
