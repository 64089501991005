// Dependencies
import { Button, Checkbox, DatePicker, Divider, Icon, Input, InputNumber, Modal, Table } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Components
import { ReturnButton, SaveButton } from "../../GenericComponents/buttons/index";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import Titles from "../../GenericComponents/titles.js";

// Actions
import {
  insertLockerLoanExceptionByUser,
  listFrequencyUnits,
  listLockerLoanMachinesByScDependency,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  listSoDependencyBySoDependencyType0,
  listUsersWithoutLockerLoanException,
} from "../../../actions";

// Localization
import Strings from "../../../systemVariables/languageStrings";
import { FreqUnitNameLocalizationPlural } from "../../../Utils/LanguageLocalization/frequencyUnitNameLocalization";

import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";

const { Column } = Table;

class AddLoanExceptionByUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersArray: [],
      usersWithoutException: null,
      scDepartments: [],
      scDepartmentSelected: null,
      scUserProfiles: [],
      scUserProfileSelected: null,
      exceptionStartDate: null,
      exceptionEndDate: null,
      loadingAdd: false,
      isEndDateChecked: false,
      isIlimitedChecked: false,
      disableAssignButton: true,
      scDependencyId: this.props.location.props ? this.props.location.props.scDependencyId : null, // variable to filter the users
      scDependencyName: this.props.location.props ? this.props.location.props.scDependencyName : null, // variable to filter the users
      // Flag used to redirect to /listLockerLoanExceptionByUser if any scDependency was selected
      redirectBackToListLockerLoanExceptionByUser: false,

      // Object to add
      discountPercent: null,
      frequencyUnitId: null,
      frequencyValue: null,
      vendingMachineId: 0,

      disableEndDatePicker: true,
    };
    this.handleOkNoScDependencyWarning = this.handleOkNoScDependencyWarning.bind(this);
  }

  componentDidMount() {
    const { scDependencyId } = this.state;
    const {
      listSoDependencyBySoDependencyType0,
      listScDepartmentByScDependencyId,
      listUsersWithoutLockerLoanException,
      listScUserProfilesByScDependencyId,
      listLockerLoanMachinesByScDependency,
      listFrequencyUnits,
    } = this.props;

    if (!scDependencyId) {
      // Shows No Product Modal Warning
      return <div> {this.noScDependencyWarning()} </div>;
    }

    listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
    if (scDependencyId != null) {
      listUsersWithoutLockerLoanException(scDependencyId, "", "", "", (response) => {
        this.setState({
          usersWithoutException: response.data.data,
        });
      });
      listScDepartmentByScDependencyId(scDependencyId, (response) => {
        this.setState({
          scDepartments: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      listScUserProfilesByScDependencyId(scDependencyId, (response) => {
        this.setState({
          scUserProfiles: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });

      listLockerLoanMachinesByScDependency(scDependencyId, (response) => {
        response.data.data.unshift({
          id: 0,
          friendlyName: Strings.generalTerms.all,
        });
        this.setState({
          vendingMachines: response.data.data.map((item) => ({
            value: item.id,
            label: item.friendlyName,
          })),
        });
      });
    }

    listFrequencyUnits((response) => {
      if (response.data.data) {
        const nativeFrequencyUnits = response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        this.setState({
          frequencyUnits: FreqUnitNameLocalizationPlural(nativeFrequencyUnits),
        });
      }
    });

    const date = moment.utc(moment()).local().format();
    this.setState({
      exceptionStartDate: `${_.split(date, "T")[0]} 00:00:00`,
      exceptionEndDate: null,
    });
  }

  // Events
  scDepartmentInputEvent(event) {
    const { listUsersWithoutLockerLoanException } = this.props;

    const { value } = event;
    this.setState({
      scDepartmentSelected: value,
      scUserProfileSelected: null,
    });

    listUsersWithoutLockerLoanException("", value, "", "", (response) => {
      this.setState({
        usersWithoutException: response.data.data,
      });
    });
  }

  scUserProfileInputEvent(event) {
    const { listUsersWithoutLockerLoanException } = this.props;

    const { value } = event;
    this.setState({
      scUserProfileSelected: value,
      scDepartmentSelected: null,
    });

    listUsersWithoutLockerLoanException("", "", value, "", (response) => {
      this.setState({
        usersWithoutException: response.data.data,
      });
    });
  }

  machineSelectionEvent(event) {
    const { value } = event;
    this.setState({
      vendingMachineId: value,
    });
  }

  discountInputEvent(event) {
    const { usersArray, frequencyUnitId, frequencyValue, exceptionStartDate, isIlimitedChecked } = this.state;

    const value = _.trimStart(event);
    this.setState({ discountPercent: value });

    if (
      (usersArray && // Flags to verify disableAssignButton
        usersArray.length > 0 &&
        value > 0 &&
        frequencyUnitId &&
        frequencyValue &&
        exceptionStartDate) ||
      (usersArray && usersArray.length > 0 && value > 0 && isIlimitedChecked === true)
    ) {
      this.setState({
        disableAssignButton: false,
      });
    } else {
      this.setState({
        disableAssignButton: true,
      });
    }
  }

  frequencyValueInputEvent(event) {
    const { usersArray, frequencyUnitId, exceptionStartDate, discountPercent } = this.state;

    const value = _.trimStart(event);
    this.setState({ frequencyValue: value });

    // Flags to verify disableAssignButton
    if (usersArray && usersArray.length > 0 && discountPercent && exceptionStartDate && frequencyUnitId != null && value > 0) {
      this.setState({
        disableAssignButton: false,
      });
    } else {
      this.setState({
        disableAssignButton: true,
      });
    }
  }

  frecuencyUnitSelect(event) {
    const { usersArray, frequencyValue, exceptionStartDate, discountPercent } = this.state;

    const { value } = event;
    this.setState({ frequencyUnitId: value });

    // Flags to verify disableAssignButton
    if (usersArray && usersArray.length > 0 && discountPercent && exceptionStartDate && frequencyValue != null) {
      this.setState({
        disableAssignButton: false,
      });
    } else {
      this.setState({
        disableAssignButton: true,
      });
    }
  }

  startDateEvent = (date) => {
    const { isEndDateChecked, exceptionEndDate, exceptionStartDate } = this.state;

    const startDateLocal = moment(date).format();
    if (isEndDateChecked === true) {
      if (exceptionEndDate < startDateLocal) {
        this.setState({ exceptionStartDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidStartDateError,
        });
      } else {
        this.setState({ exceptionStartDate: _.split(startDateLocal, "T")[0] });
      }
    } else {
      this.setState({ exceptionStartDate: _.split(startDateLocal, "T")[0] });
    }
  };

  endDateEvent = (date) => {
    const { isEndDateChecked, exceptionEndDate, exceptionStartDate } = this.state;

    const endDateLocal = moment(date).format();
    if (isEndDateChecked === true) {
      if (endDateLocal < exceptionStartDate) {
        this.setState({ exceptionEndDate });
        Modal.error({
          title: Strings.generalResponses.invalidValue,
          content: Strings.generalResponses.invalidEndDateError,
        });
      } else {
        this.setState({ exceptionEndDate: _.split(endDateLocal, "T")[0] });
      }
    } else {
      this.setState({ exceptionEndDate: _.split(endDateLocal, "T")[0] });
    }
  };

  nullEndDateHandleChange(e) {
    const { disableEndDatePicker, exceptionStartDate } = this.state;

    const endDateLocal = moment(exceptionStartDate).format();
    if (e.target.checked === true) {
      this.setState({
        exceptionEndDate: _.split(endDateLocal, "T")[0],
        disableEndDatePicker: !disableEndDatePicker,
        isEndDateChecked: true,
      });
    } else {
      this.setState({
        exceptionEndDate: null,
        disableEndDatePicker: !disableEndDatePicker,
        isEndDateChecked: false,
      });
    }
  }

  nullIlimitedHandleChange(e) {
    const { usersArray, discountPercent } = this.state;

    if (e.target.checked === true) {
      this.setState({
        isIlimitedChecked: true,
        isEndDateChecked: true,
        frequencyValue: null,
        frequencyUnitId: null,
        exceptionStartDate: null,
        exceptionEndDate: null,
        disableEndDatePicker: true,
      });
    } else {
      const date = moment.utc(moment()).local().format();
      this.setState({
        exceptionStartDate: `${_.split(date, "T")[0]} 00:00:00`,
        isIlimitedChecked: false,
        isEndDateChecked: false,
        disableAssignButton: true,
      });
    }

    // Flags to verify disableAssignButton
    if (usersArray && usersArray.length > 0 && e.target.checked === true && discountPercent) {
      this.setState({
        disableAssignButton: false,
      });
    } else {
      this.setState({
        disableAssignButton: true,
      });
    }
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const { discountPercent, exceptionStartDate, frequencyUnitId, frequencyValue, isIlimitedChecked } = this.state;

      this.setState({
        usersArray: selectedRows.map((row) => row.id),
      });

      // this.setState({ usersArray: selectedRowKeys })

      // Flags to verify disableAssignButton
      if (
        (selectedRows.length > 0 && discountPercent && exceptionStartDate && frequencyUnitId != null && frequencyValue) ||
        (selectedRows.length > 0 && isIlimitedChecked && discountPercent)
      ) {
        this.setState({
          disableAssignButton: false,
        });
      } else {
        this.setState({
          disableAssignButton: true,
        });
      }
    },
  };

  addCreditByUserEvent = () => {
    const { usersArray, scDependencyId, vendingMachineId, discountPercent, frequencyUnitId, frequencyValue, exceptionStartDate, exceptionEndDate } = this.state;
    const { insertLockerLoanExceptionByUser, listUsersWithoutLockerLoanException } = this.props;

    this.setState({
      usersWithoutException: [],
      loadingAdd: true,
      disableAssignButton: true,
    });

    const infoDto = {
      userIds: usersArray,
      scDependencyId,
      vendingMachineId: vendingMachineId !== 0 ? vendingMachineId : null,
      discountPercent,
      frequencyUnitId: frequencyUnitId !== null ? frequencyUnitId : null,
      frequencyValue,
      exceptionStartDate: exceptionStartDate ? moment(exceptionStartDate).format() : null,
      exceptionEndDate: exceptionEndDate ? moment(exceptionEndDate).format() : null,
    };

    this.setState({
      usersArray: [],
    });

    if (infoDto) {
      insertLockerLoanExceptionByUser(infoDto, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        } else if (response.data && response.data.status === "SUCCESS") {
          // Successfull entity save
          Modal.success({
            title: Strings.generalResponses.successfulTransaction,
            content: Strings.generalResponses.saveSuccess,
          });
          this.setState({
            usersWithoutException: [],
            disableAssignButton: true,
            isEndDateChecked: false,
            discountPercent: null,
            frequencyUnitId: null,
            frequencyValue: null,
            scDepartmentSelected: null,
            scUserProfileSelected: null,
            exceptionStartDate: null,
            exceptionEndDate: null,
          });

          listUsersWithoutLockerLoanException(scDependencyId, "", "", "", (response) => {
            this.setState({
              usersWithoutException: response.data.data,
              loadingAdd: false,
            });
          });
          this.forceUpdate();
        } else {
          // Other error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            loadingAdd: false,
          });
        }
      });
    } else {
      // Other error
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: Strings.generalResponses.alreadyExistsError,
      });
      this.setState({
        loadingAdd: false,
      });
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearch = (selectedKeys) => {
    const { scDependencyId } = this.state;
    const { listUsersWithoutLockerLoanException } = this.props;

    listUsersWithoutLockerLoanException(scDependencyId, "", "", selectedKeys[0], (response) => {
      this.setState({
        usersWithoutException: response.data.data,
      });
    });
    this.setState({});
  };

  handleReset = (clearFilters) => {
    const { scDependencyId } = this.state;
    const { listUsersWithoutLockerLoanException } = this.props;

    listUsersWithoutLockerLoanException(scDependencyId, "", "", "", (response) => {
      this.setState({
        usersWithoutException: response.data.data,
        usersArray: [],
      });
    });

    clearFilters();
  };

  noScDependencyWarning() {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOkNoScDependencyWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  }

  handleOkNoScDependencyWarning() {
    this.setState({ redirectBackToListLockerLoanExceptionByUser: true });
  }

  // Information to render
  render() {
    const {
      redirectBackToListLockerLoanExceptionByUser,
      scDependencyName,
      vendingMachines,
      vendingMachineId,
      discountPercent,
      disableAssignButton,
      isIlimitedChecked,
      frequencyValue,
      frequencyUnits,
      frequencyUnitId,
      exceptionStartDate,
      isEndDateChecked,
      exceptionEndDate,
      disableEndDatePicker,
      scDepartments,
      scDepartmentSelected,
      loadingAdd,
      scUserProfiles,
      scUserProfileSelected,
      usersWithoutException,
      loading,
      usersArray,
    } = this.state;

    if (redirectBackToListLockerLoanExceptionByUser) {
      // Redirects to CreditByUser from No Product Modal Warning
      return <Redirect to="/listLockerLoanExceptionByUser" />;
    }
    return (
      <div className="content-container">
        <div className="row">
          <Titles
            title={Strings.users.lockerLoanExceptionByUser.lockerLoanExceptionAssignation}
            tooltip={Strings.users.lockerLoanExceptionByUser.lockerLoanExceptionAssignationTooltip}
          />
        </div>

        <div className="row">
          <div className="vertSpace col">
            <Divider orientation="left">
              <h3>{Strings.scDependency.indirectClient} </h3>
            </Divider>
            <h3>{scDependencyName}</h3>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.users.lockerLoanExceptionByUser.exceptionSelection} </h3>
            </Divider>

            <div className="row">
              <div className="col">
                {vendingMachines ? (
                  <div className="vertSpace col-md-5">
                    <SelectGeneral
                      text={Strings.machine.vendingMachine}
                      tooltip={Strings.users.lockerLoanExceptionByUser.vendingMachineSelectionTooltip}
                      name="vendingMachine"
                      options={vendingMachines}
                      onChange={(e, name) => this.machineSelectionEvent(e, name)}
                      defaultValue={vendingMachineId}
                      isReq
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="vertSpace col-md-3">
                  <TextWithInfoTooltip
                    name={`${Strings.users.lockerLoanExceptionByUser.discountPercent}: `}
                    tooltip={Strings.users.lockerLoanExceptionByUser.discountPercentTooltip}
                  />
                </div>
                <div className="vertSpace col-md-2">
                  <InputNumber value={discountPercent} min={1} max={100} onChange={(e) => this.discountInputEvent(e)} />
                  &nbsp; &nbsp;%
                </div>
              </div>
            </div>

            <div className="row">
              <div className="vertSpace col-12">
                <div className="col-md-2">
                  <TextWithInfoTooltip name={`${Strings.generalTerms.ilimited}: `} tooltip={Strings.users.lockerLoanExceptionByUser.ilimitedTooltip} />
                </div>
                <div className="alignCalendars col-md-2">
                  <Checkbox checked={isIlimitedChecked} onChange={(e) => this.nullIlimitedHandleChange(e)} />
                  &nbsp; &nbsp;
                </div>
              </div>
            </div>

            <div className="row">
              <div className="vertSpace col-12">
                <div className="col-md-2">
                  <TextWithInfoTooltip name={`${Strings.generalTerms.time}: `} tooltip={Strings.users.lockerLoanExceptionByUser.timeTooltip} />
                </div>
                <div className="alignCalendars col-md-2">
                  <InputNumber value={frequencyValue} isReq min={1} onChange={(e) => this.frequencyValueInputEvent(e)} disabled={isIlimitedChecked} />
                </div>
                <div className="alignCalendars col-md-4">
                  <SelectGeneral
                    text={Strings.transactionMonitor.timeUnit}
                    options={frequencyUnits}
                    defaultValue={frequencyUnitId}
                    onChange={(e) => this.frecuencyUnitSelect(e)}
                    disabled={isIlimitedChecked}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="vertSpace col-12">
                <div className="col-md-2">
                  <TextWithInfoTooltip name={`${Strings.dispensationRule.startDate}: `} tooltip={Strings.users.lockerLoanExceptionByUser.exceptionStartDateTooltip} />
                </div>
                <div className="alignCalendars col-md-2">
                  <DatePicker value={moment(exceptionStartDate)} onChange={(date, dateString) => this.startDateEvent(date, dateString)} required disabled={isIlimitedChecked} />
                </div>
                <div className="col-md-2">
                  <TextWithInfoTooltip name={`${Strings.dispensationRule.endDate}: `} tooltip={Strings.users.lockerLoanExceptionByUser.exceptionStartEndTooltip} />
                </div>
                <div className="alignCalendars col-md-3">
                  <Checkbox checked={isEndDateChecked} onChange={(e) => this.nullEndDateHandleChange(e)} disabled={isIlimitedChecked} />
                  &nbsp; &nbsp;
                  <DatePicker
                    value={moment(exceptionEndDate)}
                    onChange={(date, dateString) => this.endDateEvent(date, dateString)}
                    required={false}
                    disabled={disableEndDatePicker}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Divider orientation="left">
              <h3>{Strings.users.userSelection}</h3>
            </Divider>
            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.scDepartment.department}
                tooltip={Strings.scDepartment.departmentTooltip}
                options={scDepartments}
                onChange={(e) => this.scDepartmentInputEvent(e)}
                defaultValue={scDepartmentSelected}
              />
            </div>
            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.scUserProfile.userProfile}
                tooltip={Strings.scUserProfile.userProfileTooltip}
                options={scUserProfiles}
                onChange={(e) => this.scUserProfileInputEvent(e)}
                defaultValue={scUserProfileSelected}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Table
              dataSource={usersWithoutException}
              bordered
              loading={loading}
              pagination={false}
              size="middle"
              scroll={{ x: "100%", y: 300 }}
              rowKey="id"
              rowSelection={this.rowSelection}
            >
              <Column key="id" title={Strings.users.id} dataIndex="identityCardNumber" {...this.getColumnSearchProps("identityCardNumber")} />
              <Column
                title={Strings.users.user}
                render={(row) => (
                  <div>
                    {row.firstName} {row.middleName ? row.middleName : ""} {row.lastName1} {row.lastName2 ? row.lastName2 : ""}
                  </div>
                )}
              />
              <Column title={Strings.scDepartment.department} render={(row) => <div>{!row.scDepartmentName ? "--" : row.scDepartmentName}</div>} />
              <Column title={Strings.scUserProfile.userProfile} render={(row) => <div>{!row.scUserProfileName ? "--" : row.scUserProfileName}</div>} />
            </Table>
          </div>
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            {usersArray && usersArray.length > 0 ? (
              <div>
                <h5 align="left">
                  <b>{`${Strings.users.usersSelected}:`}</b> {` ${usersArray.length}`}
                </h5>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="vertSpace col-6 col-md-3">
            <ReturnButton link="/listLockerLoanExceptionByUser" />
          </div>
          <div className="vertSpace col-6 col-md-3">
            <SaveButton onClick={(e) => this.addCreditByUserEvent(e)} isDisabled={disableAssignButton} loading={loadingAdd} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    scdepartments: state.scDepartments,
    scuserprofiles: state.scUserProfiles,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  listFrequencyUnits,
  listUsersWithoutLockerLoanException,
  insertLockerLoanExceptionByUser,
  listLockerLoanMachinesByScDependency,
})(AddLoanExceptionByUser);
