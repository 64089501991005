import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Divider, Modal } from "antd";
import { Redirect } from "react-router-dom";

// Actions
import { getNoahubKeyListsByKeyStatus, addPeripheralDevices, getPeripheralDeviceModels, getAllMachinGenerations, getPeripheralDevicesByModelIds } from "../../actions/index";

// Components
import Titles from "../GenericComponents/titles";
import { GeneralInput } from "../GenericComponents/InputComponent/index";
import { SelectGeneral } from "../GenericComponents/selectComponent/index";
import { SaveButton, ReturnButton } from "../GenericComponents/buttons/index";

// Localization
import Strings from "../../systemVariables/languageStrings";

const PERIPHERAL_TYPES = { MEMS_SENSOR: 9, MOTORS_CONTROLLER: 20, TEMPERATURE_CONTROLLER: 29 };
const NOAHUB_TYPE = 21;
const NOA_EMBEDDED_COMPUTER = 35;

class AddPeripheralDevice extends Component {
  constructor(props) {
    super(props);

    const {
      location: {
        props: {
          peripheralDeviceTypeId,
          peripheralDeviceModelId,
          peripheralDeviceModelName,
          peripheralDeviceTypeName,
          MachinGenerations,
          machinGenerationsId,
          peripheralDeviceModels,
        } = "",
      },
    } = this.props;

    this.state = {
      noahubFreeKeyList: [],

      peripheralDevice: {
        peripheralDeviceTypeId,
        peripheralDeviceModelId,
        machinGenerationsId,
        noahubKeyListId: null,
        alternativeSerial: null,
        mpn: null,
        modbusId: null,
      },
      peripheralDeviceModels,
      MachinGenerations,
      peripheralDeviceModelName,
      peripheralDeviceTypeName,

      isSaveButtonLoading: false,
      saveButtonDisabled: true,
      redirectBackToPeripheralDevice: false,
      isDisabledNoahub: false,
    };
  }

  componentDidMount() {
    const {
      peripheralDevice: { peripheralDeviceTypeId, machinGenerationsId },
    } = this.state;

    const { getNoahubKeyListsByKeyStatus, location, getPeripheralDeviceModels } = this.props;
    if (peripheralDeviceTypeId === NOAHUB_TYPE) {
      getNoahubKeyListsByKeyStatus(0, (response) => {
        this.setState({
          noahubFreeKeyList: response.data.data.map((obj) => ({
            value: obj.id,
            label: obj.serialNumber,
          })),
        });
      });
    }
    if (peripheralDeviceTypeId === NOA_EMBEDDED_COMPUTER) {
      getNoahubKeyListsByKeyStatus(0, (response) => {
        this.setState({
          noahubFreeKeyList: response.data.data.map((obj) => ({
            value: obj.id,
            label: obj.serialNumber,
          })),
        });
      });
    }
    if (machinGenerationsId === -1) {
      getPeripheralDeviceModels(location.props.peripheralDeviceTypeId, machinGenerationsId, (response) => {
        this.setState({
          peripheralDeviceModels: response.data.data.map((obj) => ({
            value: obj.id,
            label: obj.reference,
            generationId: obj.peripheralDeviceModelIdsByGeneration,
          })),
        });
      });
    }
  }

  handleSelectChangeGeneration(event, selectOption) {
    const { value } = event;
    const {
      peripheralDevice: { machinGenerationsId, peripheralDeviceTypeId },
    } = this.state;
    const { getPeripheralDeviceModels } = this.props;
    switch (selectOption.name) {
      case "MachinGenerations":
        this.setState({
          peripheralDevice: {
            machinGenerationsId: event.value,
            peripheralDeviceTypeId,
          },
        });
        if (value === -1) {
          getPeripheralDeviceModels(peripheralDeviceTypeId, value, (response) => {
            this.setState({
              peripheralDeviceModels: response.data.data.map((obj) => ({
                value: obj.id,
                label: obj.reference,
                generationId: obj.peripheralDeviceModelIdsByGeneration,
              })),
            });
          });
        } else {
          getPeripheralDeviceModels(peripheralDeviceTypeId, value, (response) => {
            this.setState({
              peripheralDeviceModels: response.data.data.map((obj) => ({
                value: obj.id,
                label: obj.reference,
                generationId: obj.peripheralDeviceModelIdsByGeneration,
              })),
            });
          });
        }
        break;
      case "peripheralDeviceModel":
        this.setState({
          peripheralDevice: {
            peripheralDeviceModelId: value,
            machinGenerationsId,
            peripheralDeviceTypeId,
          },
        });
        break;
      default:
    }
  }

  handleSelectChange = (event, option) => {
    const { value } = event;
    const { name } = option;
    const { peripheralDevice } = { ...this.state };
    peripheralDevice[name] = value;
    this.setState({
      peripheralDevice,
    });
  };

  inputHandleChange = (event) => {
    const { id, value } = event.target;
    const { peripheralDevice } = { ...this.state };
    peripheralDevice[id] = value;
    this.setState({ peripheralDevice });
  };

  addPeripheralEvent = () => {
    this.setState({
      isSaveButtonLoading: true,
    });
    const {
      peripheralDevice,
      peripheralDevice: { noahubKeyListId, modbusId, peripheralDeviceModelId },
    } = this.state;

    const {
      peripheralDevice: { peripheralDeviceTypeId },
    } = this.state;

    const { addPeripheralDevices } = this.props;

    let isNoaHubKeyValid = true;
    if (peripheralDeviceTypeId === NOAHUB_TYPE) {
      isNoaHubKeyValid = noahubKeyListId !== undefined && noahubKeyListId !== null;
    }

    let isNoaHubKeyValidNoaEmbedded = true;
    if (peripheralDeviceTypeId === NOA_EMBEDDED_COMPUTER) {
      isNoaHubKeyValidNoaEmbedded = noahubKeyListId !== undefined && noahubKeyListId !== null;
    }

    let isModbusValid = true;
    if (Object.values(PERIPHERAL_TYPES).includes(peripheralDeviceTypeId)) {
      isModbusValid = modbusId !== undefined && modbusId !== null;
    }

    if (isNoaHubKeyValid && isModbusValid && isNoaHubKeyValidNoaEmbedded && peripheralDeviceModelId !== undefined) {
      addPeripheralDevices(peripheralDevice, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
          this.setState({
            isSaveButtonLoading: false,
          });
        } else if (response.data.code === 4008) {
          // Repeated entity error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
          this.setState({
            isSaveButtonLoading: false,
          });
        } else if (response.data && response.data.code === 0) {
          //  Entity Successfully saved
          Modal.success({
            title: Strings.generalResponses.successfullCreation,
            content: Strings.generalResponses.creationSuccess,
            onOk: this.renderToUuid(peripheralDeviceTypeId),
          });
        }
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
      this.setState({
        isSaveButtonLoading: false,
      });
    }
  };

  renderToUuid = (peripheralDeviceTypeId) => {
    const { getNoahubKeyListsByKeyStatus } = this.props;
    const {
      peripheralDevice: { peripheralDeviceModelId, machinGenerationsId },
    } = this.state;
    if (peripheralDeviceTypeId === NOAHUB_TYPE) {
      getNoahubKeyListsByKeyStatus(0, (response) => {
        this.setState({
          noahubFreeKeyList: response.data.data.map((obj) => ({
            value: obj.id,
            label: obj.serialNumber,
          })),
          noahubKeyListId: null,
        });
      });
    }
    if (peripheralDeviceTypeId === NOA_EMBEDDED_COMPUTER) {
      getNoahubKeyListsByKeyStatus(0, (response) => {
        this.setState({
          noahubFreeKeyList: response.data.data.map((obj) => ({
            value: obj.id,
            label: obj.serialNumber,
          })),
          noahubKeyListId: null,
        });
      });
    }
    this.setState({
      peripheralDevice: {
        mpn: null,
        alternativeSerial: null,
        peripheralDeviceModelId,
        machinGenerationsId,
        peripheralDeviceTypeId,
      },
      isSaveButtonLoading: false,
    });
  };

  render() {
    const {
      redirectBackToPeripheralDevice,
      noahubFreeKeyList,
      isSaveButtonLoading,
      peripheralDevice: { machinGenerationsId, peripheralDeviceModelId, noahubKeyListId, alternativeSerial, mpn, modbusId },
      peripheralDeviceTypeName,
      peripheralDeviceModels,
      MachinGenerations,
    } = this.state;

    const {
      peripheralDevice: { peripheralDeviceTypeId },
    } = this.state;

    if (redirectBackToPeripheralDevice) {
      // Redirects to SoProductSlotType from No Product Modal Warning
      return <Redirect to="/peripheralDevice" />;
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.peripheralDevice.addPeripheralDevice} tooltip={Strings.peripheralDevice.addPeripheralDeviceTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <div className="col-xs-12 col-md-12">
              <Divider orientation="left">
                <h3>{Strings.peripheralDevice.peripheralDevice} </h3>
              </Divider>
              <h3>{peripheralDeviceTypeName}</h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.parameterization} </h3>
            </Divider>

            <div className="vertSpace col-md-6">
              <SelectGeneral
                name="MachinGenerations"
                text={Strings.machine.machineGeneration}
                tooltip={Strings.peripheralDevice.peripheralTooltipByGenerationAddPeripheralDevice}
                options={MachinGenerations}
                onChange={(e, selectOption) => this.handleSelectChangeGeneration(e, selectOption)}
                defaultValue={machinGenerationsId}
              />
            </div>

            <div className="vertSpace col-md-6">
              <SelectGeneral
                name="peripheralDeviceModel"
                text={Strings.peripheralDevice.peripheralDeviceModel}
                tooltip={Strings.peripheralDevice.peripheralFilterTooltipModel}
                options={peripheralDeviceModels}
                disabled={!(peripheralDeviceModels.length > 0)}
                onChange={(e, selectOption) => this.handleSelectChangeGeneration(e, selectOption)}
                defaultValue={peripheralDeviceModelId}
              />
            </div>

            {peripheralDeviceTypeId === NOAHUB_TYPE && machinGenerationsId === 0 ? (
              <div className="vertSpace col-xs-12 col-md-6">
                <SelectGeneral
                  name="noahubKeyListId"
                  text={Strings.machine.noahubSerial}
                  // tooltip={Strings.scDepartment.departmentTooltip}
                  options={noahubFreeKeyList}
                  onChange={this.handleSelectChange}
                  defaultValue={noahubKeyListId}
                  disabled={peripheralDeviceModelId === undefined ? true : false || peripheralDeviceModelId === null ? true : false}
                />
              </div>
            ) : null}
            {peripheralDeviceTypeId === NOA_EMBEDDED_COMPUTER && machinGenerationsId === 1 ? (
              <div className="vertSpace col-xs-12 col-md-6">
                <SelectGeneral
                  name="noahubKeyListId"
                  text={Strings.machine.noahubSerial}
                  // tooltip={Strings.scDepartment.departmentTooltip}
                  options={noahubFreeKeyList}
                  onChange={this.handleSelectChange}
                  defaultValue={noahubKeyListId}
                  disabled={peripheralDeviceModelId === undefined ? true : false || peripheralDeviceModelId === null ? true : false}
                />
              </div>
            ) : null}
            {/* {alert(peripheralDeviceModelId)} */}
            <div className="vertSpace col-xs-12 col-md-6">
              <GeneralInput
                text={Strings.peripheralDevice.alternativeSerial}
                id="alternativeSerial"
                value={alternativeSerial}
                defaultValue={alternativeSerial}
                placeholder={Strings.peripheralDevice.alternativeSerial}
                onChange={(e) => this.inputHandleChange(e)}
              />
            </div>
            <div className="vertSpace col-xs-12 col-md-6">
              <GeneralInput
                text={Strings.peripheralDevice.mpn}
                id="mpn"
                value={mpn}
                defaultValue={mpn}
                placeholder={Strings.peripheralDevice.mpn}
                onChange={(e) => this.inputHandleChange(e)}
              />
            </div>
            {Object.values(PERIPHERAL_TYPES).includes(peripheralDeviceTypeId) ? (
              <div className="vertSpace col-xs-12 col-md-6">
                <GeneralInput isReq text="Modbus" id="modbusId" value={modbusId} defaultValue={modbusId} placeholder="Modbus id" onChange={(e) => this.inputHandleChange(e)} />
              </div>
            ) : null}
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="vertSpace col-6 col-md-3">
            <ReturnButton link="/peripheralDevice" />
          </div>
          {peripheralDeviceTypeId === NOAHUB_TYPE ? (
            <div className="vertSpace col-6 col-md-3">
              <SaveButton
                loading={isSaveButtonLoading}
                isDisabled={!noahubKeyListId || peripheralDeviceModelId === undefined || peripheralDeviceModelId === null}
                onClick={(e) => this.addPeripheralEvent(e)}
              />
            </div>
          ) : null}
          {peripheralDeviceTypeId === NOA_EMBEDDED_COMPUTER ? (
            <div className="vertSpace col-6 col-md-3">
              <SaveButton
                loading={isSaveButtonLoading}
                isDisabled={!noahubKeyListId || peripheralDeviceModelId === undefined || peripheralDeviceModelId === null}
                onClick={(e) => this.addPeripheralEvent(e)}
              />
            </div>
          ) : null}
          {peripheralDeviceTypeId !== NOA_EMBEDDED_COMPUTER && peripheralDeviceTypeId !== NOAHUB_TYPE ? (
            <div className="vertSpace col-6 col-md-3">
              <SaveButton
                loading={isSaveButtonLoading}
                isDisabled={peripheralDeviceModelId === null || peripheralDeviceModelId === undefined}
                onClick={(e) => this.addPeripheralEvent(e)}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

AddPeripheralDevice.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  getNoahubKeyListsByKeyStatus: PropTypes.func,
  addPeripheralDevices: PropTypes.func,
};

AddPeripheralDevice.defaultProps = {
  location: null,
  getNoahubKeyListsByKeyStatus: null,
  addPeripheralDevices: null,
};

export default connect(null, {
  getNoahubKeyListsByKeyStatus,
  addPeripheralDevices,
  getAllMachinGenerations,
  getPeripheralDevicesByModelIds,
  getPeripheralDeviceModels,
})(AddPeripheralDevice);
