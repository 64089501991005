// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Modal, Spin, Icon } from "antd";
import { Redirect } from "react-router-dom";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { identityCardTypeLocalization, rolesLocalization } from "../../../Utils/LanguageLocalization";


import UserForm from "../Commons/UserForm";
import AssignUsersToVendingMachineForm from "../Commons/AssignUsersToVendingMachineForm";
import SelectRoleForm from "../Commons/SelectRoleForm";
import Loading from "../../GenericComponents/loadingComponent/loading";
import Titles from "../../GenericComponents/titles";
import { SaveButton, ReturnButton } from "../../GenericComponents/buttons/index";

// Queries
import {
  getExecutiveUserById,
  listIdentityCardTypes,
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  getAllRelatedVendingMachinesToSoDependencyId,
  getSystemSoRoles,
  getVendingMachineSoRoles,
  updateExecutiveUser,
} from "../../../actions";

class EditExecutiveUser extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props;

    this.state = {
      userToUpdate: {
        id: location.props ? location.props.user.id : null,
        soDependencyId: location.props ? location.props.user.soDependencyId : null,
        soDependencyName: location.props ? location.props.user.soDependencyName : null,
        firstName: null,
        middleName: null,
        lastName1: null,
        lastName2: null,
        identityCardTypeId: null,
        identityCardNumber: null,
        phone1: null,
        phone2: null,
        countryId: location.props ? location.props.user.countryId : null,
        provinceId: location.props ? location.props.user.provinceId : null,
        cityId: location.props ? location.props.user.cityId : null,
        address: null,
        email: null,
        birthDate: null,
        vmRoleIds: [], // So Role Ids
        sysRoleIds: [], // So Role Ids
        vendingMachineIds: [],
      },

      countryCode: "co",
      identityCardTypes: [],
      countries: [],
      provinces: [],
      cities: [],
      soSysRoles: [],
      soVmRoles: [],
      vendingMachines: [],

      isUserDataLoaded: false,
      isSaveButtonLoading: false,
      redirectBackToListExecutiveUsers: false,
    };
  }

  componentDidMount() {
    const { userToUpdate } = this.state;

    const {
      listIdentityCardTypes,
      listCountries,
      getAllRelatedVendingMachinesToSoDependencyId,
      getSystemSoRoles,
      getVendingMachineSoRoles,
      listProvincesByCountryId,
      listCitesByProvincesId,
      getExecutiveUserById,
    } = this.props;

    if (userToUpdate.id !== null) {
      listIdentityCardTypes((response) => {
        this.setState({
          identityCardTypes: response.data.data.map((item) => ({
            value: item.id,
            label: identityCardTypeLocalization(item.name),
          })),
        });
      });

      listCountries((response) => {
        this.setState({
          countries: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
            code: item.alpha2Code,
          })),
        });
      });

      getAllRelatedVendingMachinesToSoDependencyId(userToUpdate.soDependencyId, (response) => {
        this.setState({
          vendingMachines: response.data.data.map((item) => ({
            value: item.id,
            label: `${item.friendlyName}`,
          })),
        });
      });

      getSystemSoRoles(userToUpdate.soDependencyId, (response) => {
        this.setState({
          soSysRoles: response.data.data.map((item) => ({
            value: item.id,
            label: rolesLocalization(item.roleName),
          })),
        });
      });
      getVendingMachineSoRoles(userToUpdate.soDependencyId, (response) => {
        this.setState({
          soVmRoles: response.data.data.map((item) => ({
            value: item.id,
            label: rolesLocalization(item.roleName),
          })),
        });
      });

      listProvincesByCountryId(userToUpdate.countryId, (response) => {
        if (response.data !== undefined) {
          this.setState({
            provinces: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        }
      });
      listCitesByProvincesId(userToUpdate.provinceId, (response) => {
        this.setState({
          cities: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }
    getExecutiveUserById(userToUpdate.id, (response, error) => {
      if (!error) {
        const { userToUpdate } = { ...this.state };

        Object.keys(response.data.data).forEach((key) => {
          userToUpdate[key] = response.data.data[key];
        });

        if (userToUpdate.phone1 !== undefined && userToUpdate.phone1.startsWith("+")) {
          const phone = userToUpdate.phone1;
          userToUpdate.phone1 = phone.substring(1);
        }

        this.setState({
          isUserDataLoaded: true,
          userToUpdate,
        });
      }
    });
  }

  handleFormChange = (event) => {
    const { id, value } = event.target;
    const { userToUpdate } = { ...this.state };

    userToUpdate[id] = value;

    this.setState({
      userToUpdate,
    });
  };

  handleSelect = (option, event) => {
    const { value } = option;

    const { userToUpdate } = { ...this.state };
    userToUpdate[event.name] = value;

    this.setState({
      userToUpdate,
    });

    const { listProvincesByCountryId, listCitesByProvincesId } = this.props;

    switch (event.name) {
      case "countryId":
        this.setState({ countryCode: option.code });
        listProvincesByCountryId(value, (response) => {
          this.setState({
            provinces: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
            cities:[]
          });
        });
        break;
      case "provinceId":
        listCitesByProvincesId(value, (response) => {
          this.setState({
            cities: response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          });
        });
        break;
      default:
    }
  };

  handleMultiSelect = (options, source) => {
    const { userToUpdate } = { ...this.state };

    userToUpdate[source] = options.map((option) => {
      return parseInt(option, 10);
    });

    this.setState({
      userToUpdate,
    });
  };

  handleOnOkNoScDependencyWarning = () => {
    this.setState({ redirectBackToListExecutiveUsers: true });
  };

  showNoSoDependencyWarning = () => {
    Modal.warning({
      title: Strings.scDependency.noDependencySelected,
      onOk: this.handleOnOkNoScDependencyWarning,
      content: Strings.scDependency.noDependencySelectedMsg,
    });
  };

  updateUserEvent() {
    const { userToUpdate } = { ...this.state };

    let validateEmail = false;
    if (userToUpdate.email === undefined || userToUpdate.email === null || userToUpdate.email === "" || userToUpdate.email === "@") {
      userToUpdate.email = "@";
      this.setState({
        userToUpdate,
      });
      validateEmail = true;
    } else {
      const regexEmail = /^[\w._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      validateEmail = regexEmail.test(userToUpdate.email);
    }

    if (
      userToUpdate.firstName &&
      userToUpdate.lastName1 &&
      userToUpdate.identityCardTypeId &&
      userToUpdate.identityCardNumber &&
      userToUpdate.countryId &&
      userToUpdate.provinceId &&
      userToUpdate.cityId &&
      (userToUpdate.vmRoleIds.length !== 0 || userToUpdate.sysRoleIds.length !== 0) &&
      validateEmail
    ) {
      this.setState({
        isSaveButtonLoading: true,
      });
      const { updateExecutiveUser } = this.props;
      updateExecutiveUser(userToUpdate, (response) => {
        if (!response.data && String(response).includes("Error:")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.saveError,
          });
        } else if (response.data.code === 4001) {
          // Identity card number already exists in the So
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.alreadyExistsError,
          });
        } else if (response.data.code === 0) {
          // Entity Successfully saved
          Modal.success({
            title: Strings.generalResponses.successfulCreation,
            content: `${Strings.generalResponses.creationSuccess}: ${response.data.data.firstName} ${response.data.data.lastName1}`,
          });
        }
        this.setState({
          isSaveButtonLoading: false,
        });
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.invalidParameters,
        content: Strings.generalResponses.invalidError,
      });
      this.setState({
        isSaveButtonLoading: false,
      });
    }
  }

  render() {
    const {
      userToUpdate,
      isUserDataLoaded,
      redirectBackToListExecutiveUsers,
      identityCardTypes,
      countries,
      provinces,
      cities,
      soSysRoles,
      soVmRoles,
      vendingMachines,
      isSaveButtonLoading,
      countryCode,
    } = this.state;

    const {
      location: { props: { isProvisioner } = {} },
    } = this.props;

    if (redirectBackToListExecutiveUsers) {
      return <Redirect to="/listExecutiveUsers" />;
    }

    if (!userToUpdate.id || !userToUpdate.soDependencyId) {
      return <div> {this.showNoSoDependencyWarning()} </div>;
    }

    if (!userToUpdate) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.users.editExecutiveUsers} tooltip={Strings.users.editExecutiveUsersTooltip} />
        </div>

        <Spin indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} spinning={!isUserDataLoaded}>
          <div className="row">
            <div className="vertSpace col-12">
              {userToUpdate.soDependencyName ? (
                <div className="vertSpace col-md-6">
                  <Divider orientation="left">
                    <h3>{Strings.soDependency.directClient} </h3>
                  </Divider>
                  <h3>{userToUpdate.soDependencyName}</h3>
                </div>
              ) : null}
              <div className="vertSpace col-md-6">
                {isProvisioner ? (
                  <>
                    <Divider orientation="left">
                      <h3>{Strings.soDependency.soDependencyTypeDefinition} </h3>
                    </Divider>
                    <h3>{Strings.Roles.provisioner}</h3>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Divider orientation="left">
                <h3>{Strings.generalTerms.parameterization} </h3>
              </Divider>
            </div>
          </div>

          <UserForm
            user={userToUpdate}
            identityCardTypes={identityCardTypes}
            countries={countries}
            provinces={provinces}
            cities={cities}
            onChange={this.handleFormChange}
            onSelect={this.handleSelect}
            countryCode={countryCode}
          />

          <SelectRoleForm user={userToUpdate} sysRoles={soSysRoles} vmRoles={soVmRoles} onMultiSelect={this.handleMultiSelect} />

          {userToUpdate.vmRoleIds.length !== 0 ? (
            <AssignUsersToVendingMachineForm user={userToUpdate} vendingMachines={vendingMachines} onMultiSelect={this.handleMultiSelect} />
          ) : null}
        </Spin>

        <div className="vertSpace row justify-content-end">
          <div className="col-6 col-md-3">
            <ReturnButton link="/listExecutiveUsers" />
          </div>
          <div className="col-6 col-md-3">
            <SaveButton loading={isSaveButtonLoading} onClick={(e) => this.updateUserEvent(e)} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  getExecutiveUserById,
  listIdentityCardTypes,
  listCountries,
  listProvincesByCountryId,
  listCitesByProvincesId,
  getAllRelatedVendingMachinesToSoDependencyId,
  getSystemSoRoles,
  getVendingMachineSoRoles,
  updateExecutiveUser,
})(EditExecutiveUser);
