import axios from "axios";

import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/motortypes";

export const LIST_MOTORTYPE  = 'LIST_MOTORTYPE';

export function listMotorTypes(){
  return (dispatch) => {
    axios.get(host+ port + path  + version + resource,{
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
      credentials: 'same-origin',
    })
    .then((response) => {
      dispatch( { type: LIST_MOTORTYPE, payload: response.data.data } );
    })
    .catch(function (error){
      return error;
    });
  }
}
