// Dependencies
import { Button, Divider, Icon, Input, Modal, Pagination, Popconfirm, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "react-table/react-table.css";
// import "./listDispensationRuleByUser.css";

// Components
import moment from "moment";
import { ValidateDependency } from "../../../Utils/validateDependency";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { ValidatePermissionForComponentPart } from "../../../Utils/validatePermissionForComponentPart";
import { AddButton, ExportButton } from "../../GenericComponents/buttons/index";
import Loading from "../../GenericComponents/loadingComponent/loading";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse.jsx";
import Titles from "../../GenericComponents/titles.js";

import { maxRegistersPerReport } from "../../../systemVariables/serverInformation";
import { DATE_TIME_FORMAT } from "../../../systemVariables/TimeConfig";
import ExportReportsModal from "../../GenericComponents/exportReportModal";

// Actions
import {
  disableLockerLoanExceptionByUser,
  exportExceptionByUser,
  listLockerLoanExceptionByUser,
  listScDepartmentByScDependencyId,
  listScDependenciesBySoDependencyId,
  listScUserProfilesByScDependencyId,
  listSoDependencyBySoDependencyType0,
} from "../../../actions";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";

// Language Localization
import Strings from "../../../systemVariables/languageStrings";
import { FreqUnitNameLocalization } from "../../../Utils/LanguageLocalization/frequencyUnitNameLocalization";
import TextWithInfoTooltip from "../../GenericComponents/textWithInfoTooltip";

const { Column } = Table;

class ListLockerLoanExceptionByUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scDependencies: [],
      scDepartments: [],
      scUserProfiles: [],

      scDependencyId: this.props.userDependency ? (this.props.userDependency.dependencyType === "sc" ? this.props.userDependency.dependencyId : null) : null,
      scDependencyName: this.props.userDependency ? (this.props.userDependency.dependencyType === "sc" ? this.props.userDependency.dependencyName : null) : null,

      enabledScEmployee: true,

      scUserProfileId: null,
      scDepartmentId: null,

      loadingInfo: false,
      scDependencyIdFilter: "",
      scUserProfileIdFilter: "",
      scDepartmentIdFilter: "",
      identityCardNumber: "",

      loanExceptionsByUser: [],
      loanExceptionsByUserCount: 0,

      page: 1,
      pageSize: 10,
      attribute: "id",
      order: "ASC",

      // Export Report Modal
      isExportModalVisible: false,
      isExceedModalVisible: false,
      exportLoading: false,

      addButtonDisabled: true,
    };
    this.showPagination = this.showPagination.bind(this);

    const {
      listSoDependencyBySoDependencyType0,
      listScDependenciesBySoDependencyId,
      listLockerLoanExceptionByUser,
      listScDepartmentByScDependencyId,
      listScUserProfilesByScDependencyId,
    } = this.props;

    const { page, pageSize, attribute, order } = this.state;

    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") === 1) {
      listSoDependencyBySoDependencyType0(sessionStorage.getItem("dependencyId"));
    }

    if (sessionStorage.getItem("dependencyType") === "so" && sessionStorage.getItem("dependencyId") !== 1) {
      this.setState({
        soDependencyId: sessionStorage.getItem("dependencyId"),
        scDependencyId: null,
        scDepartmentId: null,
        scUserProfileId: null,

        scDependencyIdFilter: "",
        scUserProfileIdFilter: "",
        scDepartmentIdFilter: "",
        identityCardNumber: "",

        addButtonDisabled: true,
        disabledScDependency: false,
        enabledScEmployee: true,
      });
      listScDependenciesBySoDependencyId(sessionStorage.getItem("dependencyId"), (response) => {
        this.setState({
          scDependencies: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }

    if (sessionStorage.getItem("dependencyType") === "sc") {
      const value = sessionStorage.getItem("dependencyId");
      this.setState({
        loadingInfo: true,

        scDepartmentId: null,
        scUserProfileId: null,

        scDependencyIdFilter: value,
        scUserProfileIdFilter: "",
        scDepartmentIdFilter: "",
        identityCardNumber: "",
      });
      listLockerLoanExceptionByUser(value, "", "", page, pageSize, attribute, order, (response) => {
        this.setState({
          loanExceptionsByUser: response.data.data.content,
          loanExceptionsByUserCount: response.data.data.totalElements,
          loadingInfo: false,
          enabledScEmployee: false,
          addButtonDisabled: false,
        });
      });

      listScDepartmentByScDependencyId(value, (response) => {
        this.setState({
          scDepartments: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
      listScUserProfilesByScDependencyId(value, (response) => {
        this.setState({
          scUserProfiles: response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      });
    }
  }

  // Events
  soDependencyHandleChange(event) {
    const { listScDependenciesBySoDependencyId } = this.props;

    const { value } = event;
    this.setState({
      soDependencyId: value,
      scDependencyId: null,
      scDepartmentId: null,
      scUserProfileId: null,

      scDependencyIdFilter: "",
      scUserProfileIdFilter: "",
      scDepartmentIdFilter: "",
      identityCardNumber: "",

      scDepartments: [],
      scUserProfiles: [],

      addButtonDisabled: true,
      disabledScDependency: false,
      enabledScEmployee: true,

      loanExceptionsByUser: [],
      loanExceptionsByUserCount: 0,
      page: 1,
      pageSize: 10,
    });
    listScDependenciesBySoDependencyId(value, (response) => {
      this.setState({
        scDependencies: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });
  }

  scDependencyHandleChange(event) {
    const { listScDepartmentByScDependencyId, listScUserProfilesByScDependencyId, listLockerLoanExceptionByUser } = this.props;
    const { page, pageSize, attribute, order } = this.state;

    const { value } = event;
    this.setState({
      loadingInfo: true,

      scDependencyId: value,
      scDependencyName: event.label,
      scDepartmentId: null,
      scUserProfileId: null,

      scDependencyIdFilter: value,
      scUserProfileIdFilter: "",
      scDepartmentIdFilter: "",
      identityCardNumber: "",

      enabledScEmployee: false,
      addButtonDisabled: false,

      loanExceptionsByUser: [],
      loanExceptionsByUserCount: 0,
      page: 1,
      pageSize: 10,
    });

    listScDepartmentByScDependencyId(value, (response) => {
      this.setState({
        scDepartments: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });
    listScUserProfilesByScDependencyId(value, (response) => {
      this.setState({
        scUserProfiles: response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
    });

    listLockerLoanExceptionByUser(value, "", "", "", page, pageSize, attribute, order, (response) => {
      this.setState({
        loanExceptionsByUser: response.data.data.content,
        loanExceptionsByUserCount: response.data.data.totalElements,
        loadingInfo: false,
        page: 1,
        pageSize: 10,
      });
    });
  }

  scDepartmentHandleChange(event) {
    const { listLockerLoanExceptionByUser } = this.props;
    const { page, pageSize, attribute, order } = this.state;

    const { value } = event;
    this.setState({
      loanExceptionsByUser: [],
      loadingInfo: true,
      scUserProfileId: null,
      scDepartmentId: value,
      scUserProfileIdFilter: "",
      scDepartmentIdFilter: value,
      identityCardNumber: "",
    });

    listLockerLoanExceptionByUser("", value, "", "", page, pageSize, attribute, order, (response) => {
      this.setState({
        loanExceptionsByUser: response.data.data.content,
        loanExceptionsByUserCount: response.data.data.totalElements,
        loadingInfo: false,
        page: 1,
        pageSize: 10,
      });
    });
  }

  scUserProfileHandleChange(event) {
    const { listLockerLoanExceptionByUser } = this.props;
    const { page, pageSize, attribute, order } = this.state;

    const { value } = event;
    this.setState({
      loanExceptionsByUser: [],
      loadingInfo: true,
      scDepartmentId: null,
      scUserProfileId: value,
      scUserProfileIdFilter: value,
      scDepartmentIdFilter: "",
      identityCardNumber: "",
    });

    listLockerLoanExceptionByUser("", "", value, "", page, pageSize, attribute, order, (response) => {
      this.setState({
        loanExceptionsByUser: response.data.data.content,
        loanExceptionsByUserCount: response.data.data.totalElements,
        loadingInfo: false,
        page: 1,
        pageSize: 10,
      });
    });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      // eslint-disable-next-line react/jsx-filename-extension
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
          {Strings.generalTerms.search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {Strings.generalTerms.restart}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#004173" : undefined }} />,
  });

  handleSearch = (selectedKeys) => {
    const { listLockerLoanExceptionByUser } = this.props;
    const { scDependencyIdFilter, scDepartmentIdFilter, scUserProfileIdFilter, page, pageSize, attribute, order } = this.state;

    this.setState({
      loadingInfo: true,
      identityCardNumber: selectedKeys[0],
    });

    listLockerLoanExceptionByUser(scDependencyIdFilter, scDepartmentIdFilter, scUserProfileIdFilter, selectedKeys[0], page, pageSize, attribute, order, (response) => {
      this.setState({
        loanExceptionsByUser: response.data.data.content,
        loanExceptionsByUserCount: response.data.data.totalElements,
        loadingInfo: false,
      });
    });

    this.setState({});
  };

  handleReset = (clearFilters) => {
    const { listLockerLoanExceptionByUser } = this.props;
    const { scDependencyIdFilter, scDepartmentIdFilter, scUserProfileIdFilter, page, pageSize, attribute, order } = this.state;

    this.setState({
      loadingInfo: true,
    });

    listLockerLoanExceptionByUser(scDependencyIdFilter, scDepartmentIdFilter, scUserProfileIdFilter, "", page, pageSize, attribute, order, (response) => {
      this.setState({
        loanExceptionsByUser: response.data.data.content,
        loanExceptionsByUserCount: response.data.data.totalElements,
        loadingInfo: false,
        page: 1,
        pageSize: 10,
      });
    });

    clearFilters();
    this.setState({ identityCardNumber: "" });
  };

  showPagination(page, pageSize) {
    const { listLockerLoanExceptionByUser } = this.props;
    const { scDependencyIdFilter, scDepartmentIdFilter, scUserProfileIdFilter, attribute, order, identityCardNumber } = this.state;

    this.setState({
      loadingInfo: true,
    });

    listLockerLoanExceptionByUser(scDependencyIdFilter, scDepartmentIdFilter, scUserProfileIdFilter, identityCardNumber, page, pageSize, attribute, order, (response) => {
      this.setState({
        loanExceptionsByUser: response.data.data.content,
        loanExceptionsByUserCount: response.data.data.totalElements,
        loadingInfo: false,
        page,
        pageSize,
      });
    });
  }

  // Export methods
  showExportReportsModal = () => {
    const { loanExceptionsByUserCount } = this.state;

    if (loanExceptionsByUserCount >= maxRegistersPerReport) {
      this.setState({
        isExportModalVisible: false,
        isExceedModalVisible: true,
      });
    } else {
      this.setState({
        isExportModalVisible: true,
        isExceedModalVisible: false,
      });
    }
  };

  setExportReportsModalVisible = (isExportModalVisible) => {
    this.setState({
      isExportModalVisible,
    });
  };

  handleOnExport = (fileType) => {
    const { exportExceptionByUser } = this.props;
    const { exportLoading, scDependencyIdFilter } = this.state;

    if (!exportLoading) {
      // Conform list of columnNames for export file (Order must match CsvBinPosition of export dto in BE)
      const columnHeaders = [
        Strings.generalTerms.name,
        Strings.users.identityCardType,
        Strings.scDepartment.department,
        Strings.scUserProfile.userProfile,
        Strings.users.lockerLoanExceptionByUser.discountPercent,
        Strings.dispensationRule.frequency,
        Strings.dispensationRule.frequencyUnit,
        Strings.dispensationRule.startDate,
        Strings.dispensationRule.endDate,
      ];

      // Start loading animation on Export button
      this.setState({ exportLoading: true });

      exportExceptionByUser(scDependencyIdFilter, fileType, columnHeaders, (response) => {
        if (!response.data || String(response).includes("Error:") || String(response).includes("ERR_CONNECTION_REFUSED")) {
          // Connection error
          Modal.error({
            title: Strings.generalResponses.failedTransaction,
            content: Strings.generalResponses.reportGenerationError,
          });
          this.setState({
            exportLoading: false,
          });
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          const today = new Date();
          link.setAttribute("download", `${Strings.users.lockerLoanExceptionByUser.lockerLoanExceptionByUserReport} ${today}.csv`);
          document.body.appendChild(link);
          link.click();
          this.setState({
            exportLoading: false,
          });
        }
      });
    }
  };

  disableExceptionByUserEvent = (e, row) => {
    const { disableLockerLoanExceptionByUser, listLockerLoanExceptionByUser } = this.props;
    const { loanExceptionsByUser, scDependencyIdFilter, scDepartmentIdFilter, scUserProfileIdFilter, page, pageSize, attribute, order } = this.state;

    this.setState({
      loadingInfo: true,
    });

    const userIds = [];

    if (row) {
      userIds.push(row.id);
    } else if (!row) {
      loanExceptionsByUser.forEach((item) => {
        userIds.push(item.id);
      });
    }

    disableLockerLoanExceptionByUser(userIds, (response) => {
      if (!response.data && String(response).includes("Error:")) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 4008) {
        // Repeated entity error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      } else if (response.data && response.data.status === "SUCCESS") {
        // Successfull entity save
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.saveSuccess,
          onOk: this.handleOk,
        });
        listLockerLoanExceptionByUser(scDependencyIdFilter, scDepartmentIdFilter, scUserProfileIdFilter, "", page, pageSize, attribute, order, (response) => {
          this.setState({
            loanExceptionsByUser: response.data.data.content,
            loanExceptionsByUserCount: response.data.data.totalElements,
            loadingInfo: false,
            page: 1,
            pageSize: 10,
          });
        });
      } else {
        // Other error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      }
    });
  };

  // Information to render
  render() {
    const {
      permissions: { data },
      soDependencies,
    } = this.props;
    const {
      isExportModalVisible,
      isExceedModalVisible,
      soDependencyId,
      scDependencyId,
      disabledScDependency,
      scDependencies,
      scDepartments,
      enabledScEmployee,
      scDependencyName,
      scDepartmentId,
      scUserProfiles,
      scUserProfileId,
      addButtonDisabled,
      loanExceptionsByUser,
      page,
      loadingInfo,
      scDependency,
      loanExceptionsByUserCount,
      exportLoading,
    } = this.state;

    if (!soDependencies) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    return (
      <div className="content-container">
        <ExportReportsModal
          isExportVisible={isExportModalVisible}
          isExceedVisible={isExceedModalVisible}
          setVisible={this.setExportReportsModalVisible}
          onExport={this.handleOnExport}
        />

        <div className="row">
          <Titles title={Strings.users.lockerLoanExceptionByUser.lockerLoanExceptionByUser} tooltip={Strings.users.lockerLoanExceptionByUser.lockerLoanExceptionByUserTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <ValidateDependency
            dependencyType={sessionStorage.getItem("dependencyType")}
            dependencyId={sessionStorage.getItem("dependencyId")}
            soDependencyId={soDependencyId}
            scDependencyId={scDependencyId}
            disabledScDependency={disabledScDependency}
            soDependencies={soDependencies}
            scDependencies={scDependencies}
            soDependencyHandleChange={(e) => this.soDependencyHandleChange(e)}
            scDependencyHandleChange={(e) => this.scDependencyHandleChange(e)}
          />
          {scDepartments.length > 0 ? (
            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.scDepartment.department}
                tooltip={Strings.scDepartment.departmentTooltip}
                options={scDepartments}
                onChange={(e) => this.scDepartmentHandleChange(e)}
                disabled={enabledScEmployee}
                defaultValue={scDepartmentId}
              />
            </div>
          ) : (
            ""
          )}
          {scUserProfiles.length > 0 ? (
            <div className="vertSpace col-md-6">
              <SelectGeneral
                text={Strings.scUserProfile.userProfile}
                tooltip={Strings.scUserProfile.userProfileTooltip}
                options={scUserProfiles}
                onChange={(e) => this.scUserProfileHandleChange(e)}
                disabled={enabledScEmployee}
                defaultValue={scUserProfileId}
              />
            </div>
          ) : (
            ""
          )}

          <ValidatePermissionForComponent permission="PRIVILEGE LOCKER LOAN EXCEPTION BY USER PUT" permissions={data}>
            <div className="vertSpace col">
              <div className="col-xs-0 col-md-6" />
              <div className="col-xs-12 col-md-6">
                <AddButton
                  link={addButtonDisabled ? "" : "/addLockerLoanExceptionByUser"}
                  tooltip={Strings.users.lockerLoanExceptionByUser.lockerLoanExceptionAssignationTooltip}
                  props={
                    addButtonDisabled
                      ? ""
                      : {
                          scDependencyId,
                          scDependencyName,
                        }
                  }
                  disabled={addButtonDisabled}
                />
              </div>
            </div>
          </ValidatePermissionForComponent>
        </div>

        <Divider orientation="left">
          <h3>{Strings.users.lockerLoanExceptionByUser.lockerLoanExceptionByUser} </h3>
        </Divider>
        <QueryResponse
          callback={this.methodListLocker(
            loanExceptionsByUser,
            loadingInfo,
            scDepartmentId,
            scDepartments,
            scUserProfileId,
            scUserProfiles,
            data,
            soDependencyId,
            scDependency,
            loanExceptionsByUserCount,
            page,
            scDependencyId,
            exportLoading
          )}
          dataSourceLength={loanExceptionsByUser.length}
        />
      </div>
    );
  }

  methodListLocker(
    loanExceptionsByUser,
    loadingInfo,
    scDepartmentId,
    scDepartments,
    scUserProfileId,
    scUserProfiles,
    data,
    soDependencyId,
    scDependency,
    loanExceptionsByUserCount,
    page,
    scDependencyId,
    exportLoading
  ) {
    return (
      <div>
        <div className="row">
          <div className="col">
            <Table dataSource={loanExceptionsByUser} bordered loading={loadingInfo} pagination={false} size="middle" scroll={{ x: "100%" }} rowKey="id">
              <Column style={{ width: "10%" }} key="id" title={Strings.users.id} dataIndex="identityCardNumber" {...this.getColumnSearchProps("identityCardNumber")} filtered />
              <Column style={{ width: "15%" }} title={Strings.generalTerms.name} dataIndex="fullName" />
              {!scDepartmentId && scDepartments.length > 0 ? (
                <Column style={{ width: "20%" }} title={Strings.scDepartment.department} render={(row) => <div>{!row.scDepartmentName ? "--" : row.scDepartmentName}</div>} />
              ) : (
                ""
              )}
              {!scUserProfileId && scUserProfiles.length > 0 ? (
                <Column style={{ width: "15%" }} title={Strings.scUserProfile.userProfile} render={(row) => <div>{!row.scUserProfileName ? "--" : row.scUserProfileName}</div>} />
              ) : (
                ""
              )}
              <Column
                style={{ width: "10%" }}
                title={
                  <TextWithInfoTooltip name={Strings.users.lockerLoanExceptionByUser.discountPercent} tooltip={Strings.users.lockerLoanExceptionByUser.discountPercentTooltip} />
                }
                dataIndex="discountPercent"
                sorter={(a, b) => a.discountPercent - b.discountPercent}
              />
              <Column
                style={{ width: "10%" }}
                title={<TextWithInfoTooltip name={Strings.generalTerms.time} tooltip={Strings.users.lockerLoanExceptionByUser.timeTooltip} />}
                render={(row) => (
                  <div>
                    {!row.frequencyValue ? Strings.generalTerms.ilimited : row.frequencyValue} {!row.frequencyUnitName ? "" : FreqUnitNameLocalization(row.frequencyUnitName)}
                  </div>
                )}
              />
              <Column
                title={Strings.dispensationRule.startDate}
                dataIndex="exceptionStartDate"
                render={(startDate) => <div>{!startDate ? "--" : moment(startDate).format(DATE_TIME_FORMAT)}</div>}
              />
              <Column
                title={Strings.dispensationRule.endDate}
                dataIndex="exceptionEndDate"
                render={(endDate) => <div>{!endDate ? "--" : moment(endDate).format(DATE_TIME_FORMAT)}</div>}
              />

              {ValidatePermissionForComponentPart("PRIVILEGE LOCKER LOAN EXCEPTION BY USER PUT", data) ? (
                <Column
                  title={Strings.generalTerms.edit}
                  style={{ width: "10%" }}
                  align="center"
                  render={(row) => (
                    <div align="center">
                      <Link
                        to={{
                          pathname: " ",
                          props: {
                            soDependencyId,
                            scDependency,
                            exceptionByUser: row,
                          },
                        }}
                      >
                        <Icon type="edit" theme="twoTone" style={{ cursor: "pointer", fontSize: 16 }} className="addEditRemoveButton" />
                      </Link>
                    </div>
                  )}
                />
              ) : (
                ""
              )}

              {ValidatePermissionForComponentPart("PRIVILEGE LOCKER LOAN EXCEPTION BY USER PUT", data) ? (
                <Column
                  style={{ width: "10%", padding: "2.5%" }}
                  align="center"
                  title={
                    <div>
                      <Popconfirm
                        title={Strings.generalResponses.confirmDeleteAllMessage}
                        onConfirm={(e) => this.disableExceptionByUserEvent(e)}
                        okText={Strings.generalTerms.yes}
                        cancelText={Strings.generalTerms.no}
                      >
                        <div className="row" align="center">
                          <div className="col">
                            <p> {Strings.generalTerms.delete} </p>
                          </div>
                          <div className="col">
                            <Icon type="delete" theme="twoTone" className="addEditRemoveButton" style={{ cursor: "pointer", fontSize: 16 }} />
                          </div>
                        </div>
                      </Popconfirm>
                    </div>
                  }
                  accessor="remove"
                  render={(row) => (
                    <div align="center">
                      <Popconfirm
                        title={Strings.generalResponses.confirmDeleteMessage}
                        onConfirm={(e) => this.disableExceptionByUserEvent(e, row)}
                        okText={Strings.generalTerms.yes}
                        cancelText={Strings.generalTerms.no}
                      >
                        <Icon type="delete" theme="twoTone" className="addEditRemoveButton" style={{ cursor: "pointer", fontSize: 16 }} />
                      </Popconfirm>
                    </div>
                  )}
                />
              ) : (
                ""
              )}
            </Table>
            <div className="col-xs-0 col-md-6" />
            <div className="vertSpace col-md-6">
              <Pagination
                size="small"
                total={loanExceptionsByUserCount}
                showQuickJumper
                showSizeChanger
                onChange={this.showPagination}
                onShowSizeChange={this.showPagination}
                hideOnSinglePage={false}
                pageSizeOptions={["10", "25", "50", "100", "250"]}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                current={page}
                disabled={loanExceptionsByUser ? !(loanExceptionsByUser.length > 0) : true}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <ValidatePermissionForComponent permission="PRIVILEGE LOCKER LOAN EXCEPTION BY USER REPORT GET" permissions={data}>
            <div className="vertSpace col">
              <div className="col-lg-6" />
              <div className="col-lg-6">
                <ExportButton isDisabled={!scDependencyId} onClick={() => this.showExportReportsModal()} loading={exportLoading} />
              </div>
            </div>
          </ValidatePermissionForComponent>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    soDependencies: state.soDependenciesType0,
    scDependencies: state.scDependencies,
    scDepartments: state.scDepartments,
    scUserProfiles: state.scUserProfiles,
    users: state.users.userDispRulesObj,
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  listSoDependencyBySoDependencyType0,
  listScDependenciesBySoDependencyId,
  listScDepartmentByScDependencyId,
  listScUserProfilesByScDependencyId,
  listLockerLoanExceptionByUser,
  disableLockerLoanExceptionByUser,
  exportExceptionByUser,
})(ListLockerLoanExceptionByUser);
