import axios from "axios";
import { host, port, path, version } from "../systemVariables"

// Method path
const resource = "/vmproductgroup";

export const LIST_VMPRODUCTGROUP  = 'LIST_VMPRODUCTGROUP';
export const LIST_VMPRODUCTGROUPTABLE  = 'LIST_VMPRODUCTGROUPTABLE';
export const ERROR = 'ERROR';

export function listVmProductGroup(callback){
  return (dispatch) => {
    axios.get(host+ port + path  + version + resource,{
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      dispatch( { type: LIST_VMPRODUCTGROUP, payload: response.data } );
      callback(response);
    })
    .catch(function (error){
      return error;
    });
  }
}

export function listVmProductGroupBySoDependencyIdPaginated (vmProductGroupName,soDependencyId,page,size,attribute,order,callback){ 
  return () => {
    axios.get(host+ port + path  + version + resource,{
      params: {
        vmproductgroupname: vmProductGroupName,
        sodependencyid: soDependencyId,
        page: page,
        size: size,
        attribute: attribute,
        order: order
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
  }
}

export function listVmProductGroupBySoDependencyId(soDependencyId, callback){
  return () => {
    axios.get(host+ port + path  + version + resource,{
      params: {
        sodependency_id: soDependencyId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      callback(error);
    });
  }
}

export function listVmProductGroupByScDependencyId(scDependencyId, callback){
  return () => {
    axios.get(host+ port + path  + version + resource,{
      params: {
        scdependency_id: scDependencyId
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      callback(error);
    });
  }
}

export function addVmProductGroup(vmProductGroupDTO, callback){
  return () => {
    let data = JSON.stringify(vmProductGroupDTO)
    axios(
      {method:'post',
      url : host+ port + path  + version + resource,
      data : data,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      callback(error);
    });
  }
}

export function editVmProductGroup2(vmProductGroup, callback){
  return () => {
    let data = JSON.stringify(vmProductGroup)
    axios(
      {method:'put',
      url : host+ port + path  + version + resource,
      data : data,
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      }
    })
    .then((response) => {
      callback(response);
    })
    .catch(function (error){
      callback(error);
    });
  }
}

export async function editVmProductGroup(vmProductGroup) {
  try {
    let data = JSON.stringify(vmProductGroup)
    const response = await axios({
      method: "put",
      url: host + port + path + version + resource,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    return response;
  } catch (e) {
    return e;
  }
}

export async function editVmProductGroupInsertProducts(vmProductGroupId, soProductIds){
  let data = JSON.stringify(soProductIds).slice(1,-1)
  try {
    const response = await axios({
      method: "put",
      url: host + port + path + version + resource + "/" + vmProductGroupId,
      params: {
        soProductIds: data
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    return response;
  } catch (e) {
    return e;
  }
}

export async function editVmProductGroupRemoveProducts(vmProductGroupId, soProductIds){
  let data = JSON.stringify(soProductIds).slice(1,-1)
  try {
    const response = await axios({
      method: "delete",
      url: host + port + path + version + resource + "/" + vmProductGroupId,
      params: {
        soProductIds: data
      },
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
      },
    });
    return response;
  } catch (e) {
    return e;
  }
}

export function disableVmProductGroup(vmProductGroup, callback) {
  return () => {
    let data = JSON.stringify(vmProductGroup)
    axios({
        method: 'delete',
        url: host + port + path + version + resource,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + sessionStorage.getItem("access_token"),
        }
      }).then((response) => {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
      });
  }
}