import React from "react";
import {Badge} from "antd";

// Language localization
import Strings from "../../systemVariables/languageStrings";

const RenderModalitiesInfo = ({planogramInfo}) => {

    const FREE_SLOT_COLOR = "#ade08b";
    const FIXED_SLOT_COLOR = "#f7c8a1";
    const BORROW_SLOT_COLOR = "#f7a1bf";
    const LOAN_SLOT_COLOR = "#98d5d9";
    const SELL_SLOT_COLOR = "#a395bf";
    const DELIVERY_SLOT_COLOR = "#f7f0a1";
    const EMPTY_SLOT_COLOR = "#EEEEEE";
    const RESERVED_SLOT_COLOR = "#ff9292";
    const CONTROL_MODULE_SLOT_COLOR = "#949494";
    const NEW_SLOT_COLOR_DRAG = "#CED9EE";
    const NEW_SLOT_COLOR = "#BACBF5";
    const ERROR_SLOT_COLOR_DRAG = "#EED0CE";

    return (
        <div style={{display:'flex', flexFlow:'column wrap', alignItems:'flex-start'}}>
            {/* <span>{Strings.generalTerms.funcionalities}: </span> */}
            <span style={{ margin: "15px" }}>
            <Badge count="." style={{ width: "5px", backgroundColor: EMPTY_SLOT_COLOR }} />
            <span> {Strings.machine.lockerModalitiesLocalization.empty}</span>
            </span>
            <span style={{ margin: "15px" }}>
            <Badge count="." style={{ width: "5px", backgroundColor: NEW_SLOT_COLOR }} />
            <span> {Strings.machine.lockerModalitiesLocalization.newSlot}</span>
            </span>
            <span style={{ margin: "15px" }}>
            <Badge count="." style={{ width: "5px", backgroundColor: FREE_SLOT_COLOR }} />
            <span> {Strings.machine.lockerModalitiesLocalization.free}</span>
            </span>
            {planogramInfo.modalities.includes("CONTROL MODULE") && (
            <span style={{ margin: "15px" }}>
                <Badge count="." style={{ width: "5px", backgroundColor: CONTROL_MODULE_SLOT_COLOR }} />
                <span> {Strings.machine.lockerModalitiesLocalization.controlModule}</span>
            </span>
            )}
            {planogramInfo.modalities.includes("SELL") && (
            <span style={{ margin: "15px" }}>
                <Badge count="." style={{ width: "5px", backgroundColor: SELL_SLOT_COLOR }} />
                <span> {Strings.machine.lockerModalitiesLocalization.sell}</span>
            </span>
            )}
            {planogramInfo.modalities.includes("LOAN") && (
            <span style={{ margin: "15px" }}>
                <Badge count="." style={{ width: "5px", backgroundColor: LOAN_SLOT_COLOR }} />
                <span> {Strings.machine.lockerModalitiesLocalization.loan}</span>
            </span>
            )}
            {planogramInfo.modalities.includes("BORROW") && (
            <span style={{ margin: "15px" }}>
                <Badge count="." style={{ width: "5px", backgroundColor: BORROW_SLOT_COLOR }} />
                <span> {Strings.machine.lockerModalitiesLocalization.borrow}</span>
            </span>
            )}
            {planogramInfo.modalities.includes("DELIVERY") && (
            <span style={{ margin: "15px" }}>
                <Badge count="." style={{ width: "5px", backgroundColor: DELIVERY_SLOT_COLOR }} />
                <span> {Strings.machine.lockerModalitiesLocalization.delivery}</span>
            </span>
            )}
            {planogramInfo.modalities.includes("FIXED") && (
            <span style={{ margin: "15px" }}>
                <Badge count="." style={{ width: "5px", backgroundColor: FIXED_SLOT_COLOR }} />
                <span> {Strings.machine.lockerModalitiesLocalization.fixed}</span>
            </span>
            )}
            {planogramInfo.modalities.includes("RESERVED") && (
            <span style={{ margin: "15px" }}>
                <Badge count="." style={{ width: "5px", backgroundColor: RESERVED_SLOT_COLOR }} />
                <span> {Strings.machine.lockerModalitiesLocalization.reserved}</span>
            </span>
            )}
        </div>
    );
}
 
export default RenderModalitiesInfo;