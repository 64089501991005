// Dependencies
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Modal, Table, Checkbox, Icon } from 'antd';
import _ from 'lodash';
import { SaveButton } from "../../GenericComponents/buttons/index";

// Actions
import {
    listLockerTypes,
    listLockerSlotTypes,
    updateLocker
} from "../../../actions";

// Components
import { SelectGeneral } from "../../GenericComponents/selectComponent/index"

// Language localization
import Strings from '../../../systemVariables/languageStrings'
import { lockerModalitiesLocalization } from '../../../Utils/LanguageLocalization';

const { Column } = Table;

class EditLockerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lockerTypeList: [],
            lockerSlotTypeList: [],
            isDisabledSaveButton: false,
            lockerSlotTypeInfo: null,
            addedLockerSlotTypes: [],
            availableLockerSlotTypes: [],
            added: [],
        };
    }

    componentDidMount() {
        this.props.listLockerTypes(response => {
            const lockerTypes = response.data.data;
            const lockerTypesMapped = [];
            lockerTypes.forEach(lockerType => {
                lockerTypesMapped.push({
                    value: lockerType.id,
                    label: lockerType.name,
                    description: lockerType.description
                });
            });
            this.setState({ lockerTypeList: lockerTypesMapped })
        });
    }

    componentWillReceiveProps() {
        this.setState({
            lockerType: this.props.lockerType ? this.props.lockerType : null,
            addedLockerSlotTypes: this.props.lockerSlotType ? this.props.lockerSlotType : null,
            wingId: this.props.wingId ? this.props.wingId : null,
            isDisabledSaveButton: false,
        })

        this.props.listLockerSlotTypes(response => {
            this.setState({
                availableLockerSlotTypes: _.differenceBy(response.data.data, this.state.addedLockerSlotTypes, "id"),
            })
        });
    };

    // -------------Events-----------

    lockerTypeEvent(event) {
        const lockerType = { ...this.state.lockerType};
        lockerType.id = event.value;
        lockerType.name = event.label;
        lockerType.description = event.description;
        this.setState({ lockerType });
    }

    handleDisable(e, row) {
        const availableLockerSlotTypes = [...this.state.availableLockerSlotTypes];
        availableLockerSlotTypes.push(row);

       const addedLockerSlotTypes = this.state.addedLockerSlotTypes.filter(item => item.id !== row.id)

        this.setState({
            addedLockerSlotTypes,
            availableLockerSlotTypes
        });

        this.forceUpdate();
       
        if (addedLockerSlotTypes.length < 1) {
            this.setState({ isDisabledSaveButton: true });
        }
    };

    selectedLockerSlotTypes(e, row) {
        const addedLockerSlotTypes = [...this.state.addedLockerSlotTypes];

        if (e.target.checked === true) {
            addedLockerSlotTypes.push(row);
            this.setState({
                addedLockerSlotTypes,
                availableLockerSlotTypes: this.state.availableLockerSlotTypes.filter(item => item.id !== row.id),
                isDisabledSaveButton: false
            });
        }
        this.forceUpdate();
    };

    editButton = () => {
        const {addedLockerSlotTypes} = this.state

        this.setState({ isDisabledSaveButton: true })
        this.props.setIsVisible(false);

        // Build lockerSlotTypeIds 
        const lockerSlotTypeIds = [];
        if (addedLockerSlotTypes.length > 0) {
            addedLockerSlotTypes.forEach(item => {
                lockerSlotTypeIds.push(parseInt(item.id, 10))
            })
        }

        // Build lockerDto
        const lockerDto ={
            id: this.state.wingId,
            lockerTypeId : this.state.lockerType.id,
            lockerSlotTypeIds,
        }

        if (lockerSlotTypeIds) {
            this.props.updateLocker(lockerDto, response => {
                this.setState({ messageResponse: response, loading: false })
                if (!response.data && String(response).includes("Error:")) { // Connection error
                    Modal.error({
                        title: Strings.generalResponses.failedTransaction,
                        content: Strings.generalResponses.saveError
                    })
                } else if (response.data.code === 4008) { // Repeated entity error
                    Modal.error({
                        title: Strings.generalResponses.failedTransaction,
                        content: Strings.generalResponses.alreadyExistsError
                    })
                } else if (response.data && response.data.status === "SUCCESS") { // Successfull entity save
                    Modal.success({
                        title: Strings.generalResponses.successfulTransaction,
                        content: Strings.generalResponses.saveSuccess,
                        onOK:  this.props.updatePage(),
                    })
                } else { // Other error
                    Modal.error({
                        title: Strings.generalResponses.failedTransaction,
                        content: Strings.generalResponses.alreadyExistsError
                    })
                } 
            }
            )
        };
    }

    handleCancel= () => {
        this.setState({ isDisabledSaveButton: false })
        this.props.setIsVisible(false);
    }

    render() {
        return (
            <Modal
                title={
                <div className="row" >
                    <div className="alignLabel col-12">
                        <h2>{Strings.machine.wingParameterization}</h2>
                    </div>
                </div>}
                onOk={this.handleCancel}
                onCancel={this.handleCancel}
                width="40%"
                footer={[]}
                visible={this.props.isVisible}
                centered
            >
                <div className="row">
                    <div className="col-md-12">
                        {this.state.lockerType ?
                            <div>
                                <div className="row" style={{ padding: 12 }}>
                                <div className="col-md-4" style={{ padding: 4 }} >
                                        <p><b>{Strings.machine.lockerType}:</b></p>
                                    </div>
                                    <div className="col-md-8">
                                        <p>{this.state.lockerType.name}</p>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: 4 }}>
                                    <div className="col-md-4" style={{ padding: 4 }} >
                                        <p><b>{Strings.generalTerms.description}:</b></p>
                                    </div>
                                    <div className="col-md-8">
                                        <p>{this.state.lockerType.description}</p>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: 4 }}>
                                    <div className="col-md-12" style={{ padding: 4 }} >
                                        <h5><b>{Strings.machine.lockerModalitiesAssigned}:</b></h5>
                                    </div>
                                </div>
                                {this.state.addedLockerSlotTypes && this.state.addedLockerSlotTypes.length > 0 ?
                                    <div className="row" style={{ padding: 4 }}>
                                        <Table
                                            dataSource={this.state.addedLockerSlotTypes}
                                            loading={this.state.loading}
                                            pagination={false}
                                            bordered
                                            size="middle"
                                            showHeader={null}
                                            footer={null}
                                            rowKey='id'
                                            style={{ margin: 'auto' }}
                                        >
                                            <Column
                                                key='id'
                                                width={170}
                                                render={row => (
                                                    <div>{lockerModalitiesLocalization(row.name)} </div>
                                                  )}
                                            />
                                            <Column
                                                width={260}
                                                dataIndex='description'
                                            />
                                            <Column
                                                maxWidth={40}
                                                render={row => (
                                                    <div align='center'>
                                                        <Icon
                                                            onClick={(e) => this.handleDisable(e, row)}
                                                            type="delete"
                                                            theme="twoTone"
                                                            className="addEditRemoveButton"
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </Table>
                                    </div>
                                    :
                                    <div>
                                        <div className="row">
                                            <div className="vertSpace col-md-12">
                                                <p>{Strings.machine.lockerModalitiesAssignedMessage}</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row" style={{ padding: 4 }}>
                                    <div className="col-md-12" style={{ padding: 4 }} >
                                        <h5><b>{Strings.machine.lockerModalitiesAvailable}:</b></h5>
                                    </div>
                                </div>
                                {this.state.availableLockerSlotTypes && this.state.availableLockerSlotTypes.length > 0 ?
                                    <div className="row" style={{ padding: 4 }}>
                                        <Table
                                            dataSource={this.state.availableLockerSlotTypes}
                                            loading={this.state.loading}
                                            pagination={false}
                                            bordered
                                            size="middle"
                                            showHeader={null}
                                            footer={null}
                                            rowKey='id'
                                            style={{ margin: 'auto' }}
                                        >
                                            <Column
                                                key='id'
                                                width={170}
                                                render={row => (
                                                    <div>{lockerModalitiesLocalization(row.name)} </div>
                                                  )}
                                            />
                                            <Column
                                                width={260}
                                                dataIndex='description'
                                            />
                                            <Column
                                                maxWidth={40}
                                                render={row => (
                                                    <Checkbox
                                                        defaultChecked={false}
                                                        value={row.checked}
                                                        onChange={(e) => this.selectedLockerSlotTypes(e, row)}
                                                    />
                                                )}
                                            />
                                        </Table>
                                    </div>
                                    :
                                    <div>
                                        <div className="row">
                                            <div className="vertSpace col-md-12">
                                                <p>{Strings.machine.lockerModalitiesAvailableMessage}</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="justify-content-end">
                                    <div className="vertSpace col-6 col-md-3">
                                        <SaveButton
                                            onClick={(e) => this.editButton(e)}
                                            loading={this.state.loading}
                                            isDisabled={this.state.isDisabledSaveButton}
                                        />
                                    </div>
                                </div>
                            </div>
                            : ''}
                    </div>
                </div>

            </Modal>
        );
    }
}

export default connect(null, {
    listLockerTypes,
    listLockerSlotTypes,
    updateLocker,
})(EditLockerModal)
