/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Table, Divider } from "antd";
import _ from "lodash";

// Components
import Loading from "../../GenericComponents/loadingComponent/loading";

// Language localization
import Strings from "../../../systemVariables/languageStrings";
import { rolesLocalization, permissionTypeLocalization } from "../../../Utils/LanguageLocalization";

const { Column } = Table;

class RoleDetailesModal extends Component {
  handleButton = () => {
    const { setIsVisible } = this.props;
    setIsVisible(false);
  };

  render() {
    const { roleDetailes, selectedRole, isVisible, isLoading } = this.props;
    return roleDetailes != null ? (
      // eslint-disable-next-line react/jsx-filename-extension
      <Modal
        title={
          <div className="col-12">
            <div className="row">
              <div className="alignLabel col-12">
                <h2>{_.capitalize(rolesLocalization(selectedRole.roleName))}</h2>
              </div>
            </div>
            <Divider />
            <div className="row">
              <div className="col-md-3">
                <h5>
                  <b>{Strings.privilegeByRole.roleType}:</b>
                </h5>
              </div>
              <div className="col-md-9">
                {selectedRole.isBasicRole === 1 ? 
                    selectedRole.permissiontypeId === 3 ? 
                       <h5>{_.capitalize(permissionTypeLocalization(selectedRole.permissiontypeName))}</h5>
                    : <h5>{Strings.Roles.basicSystemRole}</h5> 
                : <h5>{Strings.Roles.customSystemRole}</h5>
                }
              </div>
            </div>
          </div>
        }
        onCancel={this.handleButton}
        visible={isVisible}
        footer={null}
        centered
      >
        <div className="row">
          <div className="vertSpace col">
            <Table dataSource={roleDetailes} pagination={false} loading={isLoading} bordered rowKey="id">
              <Column title={Strings.privilege.privileges} dataIndex="name" filtered />
              <Column title={Strings.generalTerms.description} dataIndex="description" />
            </Table>
          </div>
        </div>
      </Modal>
    ) : (
      <Modal
        title={Strings.Roles.role}
        onCancel={this.handleButton}
        visible={isVisible}
        footer={null}
        // width="55%"
        centered
      >
        <div className="row">
          <Loading />
        </div>
      </Modal>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(RoleDetailesModal);
