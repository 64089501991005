import React, { Fragment } from "react";
import { Tooltip, Icon, Button } from "antd";
import "./buttons.css";
import Strings from "../../../systemVariables/languageStrings";

export function ValidateButton(props) {
  return (
    <Fragment>
      <Tooltip title={props.tooltip}>
        <Button className="button" loading={props.loading} onClick={props.onClick} disabled={props.disabled}>
          {props.loading ? "" : <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />}
          <span>{Strings.generalTerms.validate}</span>
        </Button>
      </Tooltip>
    </Fragment>
  );
}
