import { Button, Divider, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import {
  bashImportPatients,
  deletePatientInformationByUuid,
  exportPatientInformation,
  getPatientsByScDependencyId,
  getPatientsByScDependencyIdContainingFilter,
  getPatientsBySoDependencyId,
  getPatientsBySoDependencyIdContainingFilter,
} from "../../../actions";
import Strings from "../../../systemVariables/languageStrings";
import CustomTable from "../../GenericComponents/CustomTable";
import { AddButton, ExportButton, ImportButton } from "../../GenericComponents/buttons";
import ImportReportModal from "../../GenericComponents/importReportModal";
import SoScVmDependencySelectorFn from "../../GenericComponents/selectComponent/SoScVmDependencySelectorFn";
import Titles from "../../GenericComponents/titles";

const ListPatientInformation = (props) => {
  const tableColumns = [
    { name: "identityCardNumber", title: Strings.users.identityCardNumber, width: "10%", hasSearch: true, hasSort: true },
    { name: "medicalHistoryNumber", title: Strings.smartVendingClinical.medicalHistory, width: "10%", hasSearch: true },
    { name: "admissionCode", title: Strings.smartVendingClinical.admission, width: "10%", hasSearch: false },
    { name: "name", title: Strings.users.name },
    { name: "lastname", title: Strings.users.lastName, hasSort: true },
    { name: "gender", width: "5%", title: Strings.users.gender },
    { name: "birthDate", type: "date", format: "YYYY/MM/DD", width: "10%", title: Strings.users.dateOfBirth },
    { name: "locationInformationDto", title: Strings.smartVendingClinical.location, width: "10%", attributes: ["location", "sector", "building", "floor"] },
  ];

  const editPath = "/EditPatientInformation";
  const editProp = "scDependency";
  const editPrivilege = "PRIVILEGE PATIENTS INFORMATION PUT";
  const removePrivilege = "PRIVILEGE PATIENTS INFORMATION DELETE";

  const {
    permissions: { data },
  } = props;
  const { soScSelectionProps } = props;

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isloadingInfo, setIsLoadingInfo] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [soDependencyId, setSoDependencyId] = useState("");
  const [soDependencyName, setSoDependencyName] = useState(soScSelectionProps.so?.label ?? "");
  const [scDependencyName, setScDependencyName] = useState(soScSelectionProps.sc?.label ?? "");
  const [scDependencyId, setScDependencyId] = useState(soScSelectionProps.sc?.id ?? "");
  const [addButtonDisabled, setAddButtonDisabled] = useState(soScSelectionProps.sc?.label ? true : false);
  const [importButtonDisabled, setImportButtonDisabled] = useState(soScSelectionProps.sc?.label ? true : false);
  const [checkButtonDisabled, setCheckButtonDisabled] = useState(true);
  const [checkPressed, setCheckPressed] = useState(false);
  const [isDataFiltered, setIsDataFiltered] = useState(false);
  const [filterData, setFilterData] = useState({ dataIndex: "", selectedKeys: [] });
  const [sortConfig, setSortConfig] = useState({ field: "", order: null });
  const [onCheckPressed, setOnCheckPressed] = useState(false);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [importFinished, setImportFinished] = useState(false);
  const [failedImportRegs, setFailedImportRegs] = useState([]);
  const [omitedImportRegs, setOmitedImportRegs] = useState([]);

  useEffect(() => {
    if (checkPressed) {
      isDataFiltered ? getFilteredData(filterData.dataIndex, filterData.selectedKeys) : getTableData();
    }
  }, [checkPressed, page, pageSize, sortConfig.field, sortConfig.order, onCheckPressed]);

  const soDependencyHandleChange = (event) => {
    setSoDependencyId(event.value);
    setSoDependencyName(event.label);
    setAddButtonDisabled(true);
    setImportButtonDisabled(true);
    setCheckButtonDisabled(false);
  };

  const scDependencyHandleChange = (event) => {
    setAddButtonDisabled(false);
    setImportButtonDisabled(false);
    setCheckButtonDisabled(false);
    setScDependencyId(event.value);
    setScDependencyName(event.label);
  };

  const showPagination = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const getTableData = async () => {
    setIsLoadingInfo(true);
    setIsDataFiltered(false);
    let patientsAns;
    if (scDependencyId) {
      patientsAns = await getPatientsByScDependencyId(scDependencyId, page, pageSize, sortConfig.field, sortConfig.order);
    } else if (soDependencyId) {
      patientsAns = await getPatientsBySoDependencyId(soDependencyId, page, pageSize, sortConfig.field, sortConfig.order);
    }
    if (patientsAns?.data && patientsAns?.data.status === "SUCCESS") {
      setPageSize(patientsAns.data.data.size);
      setTotalElements(patientsAns.data.data.totalElements);
      setTableData(patientsAns.data.data.content);
    } else {
      console.log("Error");
    }
    setIsLoadingInfo(false);
  };

  const onCheck = async () => {
    setPage(1);
    setSortConfig({ field: "", order: null });
    setCheckPressed(true);
    setOnCheckPressed((value) => !value);
  };

  const handleSearch = async (dataIndex, selectedKeys) => {
    setPage(1);
    getFilteredData(dataIndex, selectedKeys);
    setFilterData({ dataIndex, selectedKeys });
    setIsDataFiltered(true);
  };

  const getFilteredData = async (dataIndex, selectedKeys) => {
    setIsLoadingInfo(true);
    let patientsAns;
    const idNumber = dataIndex === "identityCardNumber" ? selectedKeys[0] : null;
    const medicalHistory = dataIndex === "medicalHistoryNumber" ? selectedKeys[0] : null;
    if (scDependencyId) {
      patientsAns = await getPatientsByScDependencyIdContainingFilter(scDependencyId, idNumber, medicalHistory, page, pageSize, sortConfig.field, sortConfig.order);
    } else if (soDependencyId) {
      patientsAns = await getPatientsBySoDependencyIdContainingFilter(soDependencyId, idNumber, medicalHistory, page, pageSize, sortConfig.field, sortConfig.order);
    }
    if (patientsAns.data.status === "SUCCESS") {
      if (patientsAns.data.data.totalElements === 0) {
        const tableCol = tableColumns.find((col) => col.name === dataIndex);
        Modal.error({
          title: Strings.generalResponses.noResults,
          content: (
            <div style={{ flexDirection: "column", alignItems: "center" }}>
              <p>{Strings.generalResponses.queryResponse}.</p>
              <p>
                {`${tableCol?.title ?? tableCol?.name}: `}
                <strong>{selectedKeys[0]}</strong>
              </p>
            </div>
          ),
        });
      } else {
        setPageSize(patientsAns.data.data.size);
        setTotalElements(patientsAns.data.data.totalElements);
        setTableData(patientsAns.data.data.content);
      }
    } else {
      console.log("Error");
    }
    setIsLoadingInfo(false);
  };

  const handleReset = () => {
    setFilterData({ dataIndex: "", selectedKeys: [] });
    setIsDataFiltered(false);
    onCheck();
  };

  const handleDisable = async (row) => {
    let deletePatientAns = await deletePatientInformationByUuid(row.uuid);
    if (deletePatientAns.data.status === "SUCCESS") {
      Modal.success({
        title: Strings.generalResponses.successfulDelete,
        content: Strings.generalResponses.deletionSuccess,
        onOk: async () => await getTableData(),
      });
    } else {
      Modal.error({
        title: Strings.generalResponses.deletionErrorTitle,
        content: Strings.generalResponses.deletionError,
      });
    }
  };

  const onSort = (pagination, filters, sorter) => {
    setSortConfig({ field: sorter.field ?? null, order: sorter.order ?? null });
  };

  const onExport = async () => {
    setIsLoadingInfo(true);
    const columnHeaders = [
      Strings.scDependency.indirectClient,
      Strings.users.identityCardNumber,
      Strings.users.name,
      Strings.users.lastName,
      Strings.users.dateOfBirth,
      Strings.users.gender,
      Strings.smartVendingClinical.medicalHistory,
      Strings.smartVendingClinical.location,
      Strings.smartVendingClinical.sector,
      Strings.smartVendingClinical.building,
      Strings.smartVendingClinical.floor,
      Strings.smartVendingClinical.admission,
      Strings.smartVendingClinical.admissionDate,
      Strings.smartVendingClinical.egressDate,
    ];
    let exportAns = await exportPatientInformation(soDependencyId, scDependencyId ? scDependencyId : null, "csv", columnHeaders);

    if (!exportAns.data || String(exportAns).includes("Error:") || String(exportAns).includes("ERR_CONNECTION_REFUSED")) {
      // Connection error
      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: Strings.generalResponses.reportGenerationError,
      });
    } else {
      const url = window.URL.createObjectURL(new Blob([exportAns.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `PatientInformation ${soDependencyName}${scDependencyId ? " - " + scDependencyName : ""}.csv`);
      document.body.appendChild(link);
      link.click();
      Modal.success({
        title: Strings.generalResponses.successfulTransaction,
        content: Strings.generalResponses.downloadConfirmation,
      });
    }
    setIsLoadingInfo(false);
  };

  const onImport = () => {
    setColumnHeaders([
      { key: "name", value: Strings.users.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "lastname", value: Strings.users.lastName.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "identityCardNumber", value: Strings.users.identityCardNumber.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "medicalHistoryNumber", value: Strings.smartVendingClinical.medicalHistory.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "admissionCode", value: Strings.smartVendingClinical.admission.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "gender", value: Strings.users.gender.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
      { key: "birthDate", value: Strings.users.dateOfBirth.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), isReq: false },
    ]);
    setIsImportModalVisible(true);
    setImportFinished(false);
  };

  const onDataImport = async (data) => {
    data.forEach((info) => {
      Object.keys(info).forEach((key) => {
        info[key] = info[key] ? info[key] : null;
      });
    });

    const response = await bashImportPatients(scDependencyId, data);

    if (!response.data && String(response).includes("Error:")) {
      // *! Connection error

      Modal.error({
        title: Strings.generalResponses.failedTransaction,
        content: Strings.generalResponses.saveError,
      });
    } else if (response.data.code === 0) {
      // ** Successfull entity save
      setFailedImportRegs(response.data.data.failedRegIds);
      setOmitedImportRegs(response.data.data.repeatedRegIds);
    }
    setImportFinished(true);
  };

  return (
    <div className="content-container">
      <div className="row">
        <Titles title={Strings.smartVendingClinical.patientsInformation} tooltip={Strings.smartVendingClinical.patientsInformationTooltip} />
      </div>

      <div className="row">
        <div className="vertSpace col-12">
          <Divider orientation="left">
            <h3>{Strings.generalTerms.generalParameters} </h3>
          </Divider>
        </div>
      </div>
      <div>
        {/* <SoDependencySelectorFn onSoDependencyChange={handleSoSelectorChange} /> */}
        <SoScVmDependencySelectorFn hasSc onSoDependencyChange={soDependencyHandleChange} onScDependencyChange={scDependencyHandleChange} />
      </div>

      <div className="button-space">
        <div className="vertSpace button-margin" />
        <div className="vertSpace button-container button-container-width">
          <div>
            <ValidatePermissionForComponent permission="PRIVILEGE PATIENTS INFORMATION GET" permissions={data}>
              <Tooltip title={Strings.transaction.getTransactionsToolTip}>
                <Button style={{ margin: "5px" }} type="primary" onClick={(e) => onCheck(e)} icon="search" disabled={checkButtonDisabled} loading={isloadingInfo}>
                  {Strings.generalTerms.check}
                </Button>
              </Tooltip>
            </ValidatePermissionForComponent>
          </div>
          <div>
            <ValidatePermissionForComponent permission="PRIVILEGE PATIENTS REPORT GET" permissions={data}>
              <ExportButton onClick={async () => await onExport()} loading={isloadingInfo} isDisabled={isloadingInfo} style={{ margin: "5px" }} />
            </ValidatePermissionForComponent>
          </div>
          <div>
            <ValidatePermissionForComponent permission="PRIVILEGE MASS CLIENT PATIENTS INFORMATION IMPORT" permissions={data}>
              <ImportButton
                tooltip={Strings.fileImport.importRegistersCSV}
                onClick={() => onImport()}
                loading={isloadingInfo}
                isDisabled={isloadingInfo || importButtonDisabled}
                style={{ margin: "5px" }}
              />
            </ValidatePermissionForComponent>
          </div>
          <div>
            <ValidatePermissionForComponent permission="PRIVILEGE PATIENTS INFORMATION POST" permissions={data}>
              <AddButton
                link={addButtonDisabled ? "" : "/addPatientInformation"}
                tooltip={Strings.users.addClientUsersTooltip}
                props={
                  addButtonDisabled
                    ? null
                    : {
                        basicProps: {
                          id: scDependencyId,
                          name: scDependencyName,
                        },
                      }
                }
                disabled={addButtonDisabled}
              />
            </ValidatePermissionForComponent>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="vertSpace col-12">
          <Divider orientation="left">
            <h3>{Strings.smartVendingClinical.Patients} </h3>
          </Divider>

          <CustomTable
            tableData={tableData}
            isloadingInfo={isloadingInfo}
            page={page}
            pageSize={pageSize}
            totalElements={totalElements}
            columns={tableColumns}
            editable
            editPrivilege={editPrivilege}
            editPath={editPath}
            editProps={editProp}
            showPagination={showPagination}
            removable
            removePrivilege={removePrivilege}
            handleDisable={handleDisable}
            handleSearch={handleSearch}
            handleReset={handleReset}
            onSort={onSort}
            sortConfig={sortConfig}
          />
        </div>
      </div>

      <ImportReportModal
        importTitle={Strings.smartVendingClinical.importPatientsBash}
        importTitleTooltip={Strings.smartVendingClinical.importPatientsBashTooltip}
        isImportVisible={isImportModalVisible}
        setVisible={setIsImportModalVisible}
        columnHeaders={columnHeaders}
        onDataImport={onDataImport}
        importEntitySpecificsFlag="patientsInformation"
        importFinished={importFinished}
        failedImportRegs={failedImportRegs}
        omitedImportRegs={omitedImportRegs}
        customDateFormat={"YYYY-MM-DD"}
        importOptions={false}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    permissions: state.userSystemPrivileges.privilegeObj,
    soScSelectionProps: state.soScSelection,
  };
}

export default connect(mapStateToProps)(ListPatientInformation);
