import axios from "axios";
import { host, port, path, version } from "../systemVariables";

const resource = "/cities";

export const LIST_CITIES = "LIST_CITIES";

export function listCitesByProvincesId(provinceId, callback) {
  return (dispatch) => {
    axios
      .get(host + port + path + version + resource, {
        params: {
          provinceId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        dispatch({ type: LIST_CITIES, payload: response.data });
        callback(response);
      })
      .catch((error) => {
        return error;
      });
  };
}
