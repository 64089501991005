import React, { Fragment } from 'react';
import { Tooltip, Icon, Upload } from 'antd';
import '../text/text.css';
import './imagePicker.css';
import { Link } from 'react-router-dom';


//Language localization
import Strings from '../../../systemVariables/languageStrings'

export function UploadImageComponent(props) {
    const { image, onChangeImage } = props;

    const uploadButton = (
        <div>
            <Icon type={image.loading ? 'loading' : 'plus'} />
            <div className="ant-upload-text">{image.loading ? Strings.generalTerms.uploading : Strings.generalTerms.upload}</div>
        </div>
    );
    return (
        <div className="imagePickerSelectAligning col-md-12">
            <div className="imagePickerSelectAligning col-md-4">
                <span className="inputText">
                    {Strings.generalTerms.uploadImage} :*
                </span>
                <Tooltip title={Strings.generalTerms.uploadImageTooltip}>
                    <Icon className="icon" type="info-circle" /> <span />
                </Tooltip>
            </div>
            <div className="col-md-8 text aligning">
                <div className="col-md-12">
                    <Upload
                        key={1}
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={onChangeImage}
                    >
                        {image.url ? <img src={image.url} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </div>
            </div>
        </div>
    );
}
