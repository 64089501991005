import { Modal } from "antd"
import React, { useState } from "react"
import { SelectGeneral } from "../GenericComponents/selectComponent"
import { updateSoDependencyVmAsync } from "../../actions";
import strings from "../../systemVariables/languageStrings";

const VmSoDependencyModal = (props) => {

  const {
    queryRefresh,
    handleCloseDirectClientModal,
    directClientSelectorVisible,
    machineToAssignSoDependency,
    soDependencyTypes,
    headQuarters,
    logisticCenters
  } = props

  const [selectedSoDependencyTypeId, setSelectedSoDependencyTypeId] = useState(null)
  const [soDependencyTypeName, setSoDependencyTypeName] = useState(null)
  const [selectedSoDependencyId, setSelectedSoDependencyId] = useState(null)
  const [showHeadQuarters, setShowHeadQuarters] = useState(false)
  const [showLogisticCenters, setShowLogisticCenters] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)

  const handleSelect = (event, name) => {
    const { value, label } = event;

    switch (name.name) {

      case "soDependencyType":
        setSelectedSoDependencyTypeId(value)
        setSoDependencyTypeName(label)
        setSelectedSoDependencyId(null)

        if (value === 1) {
          // soDependencyTypeId = 1 - Headquarter
          setShowHeadQuarters(true)
          setShowLogisticCenters(false)
        } else if (value === 2) {
          // soDependencyTypeId = 2 - Logistic center
          setShowHeadQuarters(false)
          setShowLogisticCenters(true)
        }
        break;

      case "headquarter": // soDependencyType 1
        // onSoDependencyChange(event, htmlTagSoDependency);
          setSelectedSoDependencyId(value)
        break;

      case "logisticCenter": // soDependencyType 2
        // onSoDependencyChange(event, htmlTagSoDependency);
        setSelectedSoDependencyId(value)
        break;

      default:
    }
  };

  const onUpdateSoDependency = async () => {
    if (directClientSelectorVisible) {
      if (selectedSoDependencyId) {
        setIsDataLoading(true)
        await updateSoDependencyVmAsync(machineToAssignSoDependency, selectedSoDependencyId)
        setIsDataLoading(false)
        queryRefresh()
        handleCloseDirectClientModal()
        resetStates()
      }
    }
  };

  const resetStates = () => {
    setSelectedSoDependencyTypeId(null)
    setSoDependencyTypeName(null)
    setSelectedSoDependencyId(null)
    setShowHeadQuarters(false)
    setShowLogisticCenters(false)
  }

  const onClose = () => {
    handleCloseDirectClientModal()
    resetStates()
  }

  return (
    <Modal title={"Cambiar cliente directo"} onOk={() => onUpdateSoDependency()} onCancel={() => onClose()} okButtonProps={{ loading: isDataLoading }} visible={directClientSelectorVisible} centered>
      <div>
        <div className="row">
          <div className="col-md-12">
            <SelectGeneral
              name="soDependencyType"
              text={strings.generalTerms.level}
              // tooltip={Strings.users.userSoLevel}
              options={soDependencyTypes}
              defaultValue={selectedSoDependencyTypeId}
              isReq
              isLoading={isDataLoading}
              disabled={isDataLoading}
              onChange={handleSelect}
            />
          </div>
          {showHeadQuarters ? (
            <div className="vertSpace col-md-12">
              <SelectGeneral text={soDependencyTypeName} name="headquarter" options={headQuarters} defaultValue={selectedSoDependencyId} disabled={isDataLoading} isReq onChange={handleSelect} />
            </div>
          ) : null}
          {showLogisticCenters ? (
            <div className="vertSpace col-md-12">
              <SelectGeneral text={soDependencyTypeName} name="logisticCenter" options={logisticCenters} defaultValue={selectedSoDependencyId} disabled={isDataLoading} isReq onChange={handleSelect} />
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default VmSoDependencyModal