//Language localization
import Strings from "../../systemVariables/languageStrings";

export function accessLevelsNameLocalizationArray(accessLevels) {
  accessLevels.forEach((item) => {
    switch (item.label) {
      case "Basic":
        item.label = Strings.vmSetup.accessLevels.basic;
        break;
      case "Intermediate":
        item.label = Strings.vmSetup.accessLevels.intermediate;
        break;
      case "Advanced":
        item.label = Strings.vmSetup.accessLevels.advanced;
        break;
      case "Expert":
        item.label = Strings.vmSetup.accessLevels.experto;
        break;
      default:
        break;
    }
  });
  return accessLevels;
}
