// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Modal } from "antd";

// Components
import Titles from "../../GenericComponents/titles";
import { AddButton } from "../../GenericComponents/buttons/index";
import { ValidatePermissionForComponent } from "../../../Utils/validatePermissionForComponent";
import { SelectGeneral } from "../../GenericComponents/selectComponent/index";
import SoScFullDependencySelector from "../../GenericComponents/selectComponent/FullSoScDependencySelector";
import UsersTable from "./UsersTable.jsx";
import QueryResponse from "../../GenericComponents/queryResponse/QueryResponse";

// Actions
import {
  listVendingMachinesByScDependencyId,
  getAllRelatedVendingMachinesToSoDependencyId,
  getVendingMachineUsersByScDependencyId,
  getVendingMachineUsersBySoDependencyId,
  getVmSoDependencyUsersByIsAssigned,
  getVmScDependencyUsersByIsAssigned,
  disableUserFromVM,
  disableUserFromAllVms,
} from "../../../actions/index";

// CSS
import "./assignUsersToVendingMachine.css";

// Language localization
import Strings from "../../../systemVariables/languageStrings";

class UsersByVendingMachineContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedClientType: null,

      selectedSoDependencyId: null,

      selectedScDependencyId: null,
      selectedScDependencyName: null,

      vendingMachines: [],
      selectedVendingMachineId: null,
      selectedVendingMachineName: null,
      isVendingMachineDisabled: true,

      usersByVM: [],
      isTableLoading: false,

      addButtonDisabled: true,
    };
  }

  componentDidMount() {
    const { dependencyId, dependencyName, dependencyType } = this.props.userDependency;

    if (dependencyType === "so" && dependencyId !== 1) {
      this.setState({
        selectedSoDependencyId: dependencyId,
      });
      this.props.getVendingMachineUsersBySoDependencyId(dependencyId, (response) => {
        this.setState({
          usersByVM: response.data.data,
          isTableLoading: false,
        });
      });

      this.props.getAllRelatedVendingMachinesToSoDependencyId(dependencyId, (response) => {
        this.setState({
          vendingMachines: response.data.data.map((item) => ({
            value: item.id,
            label: `${item.friendlyName}`,
          })),
          isVendingMachineDisabled: false,
        });
      });
    } else if (dependencyType === "sc") {
      this.setState({
        selectedScDependencyId: dependencyId,
        selectedScDependencyName: dependencyName,
        selectedClientType: "sc",
      });
      this.props.getVendingMachineUsersByScDependencyId(dependencyId, (response) => {
        this.setState({
          usersByVM: response.data.data,
          isTableLoading: false,
          isVendingMachineDisabled: false,
        });
      });

      this.props.listVendingMachinesByScDependencyId(dependencyId, (response) => {
        this.setState({
          vendingMachines: response.data.data.map((item) => ({
            value: item.id,
            label: `${item.friendlyName}`,
          })),
        });
      });
    }
  }

  handleSoDependencyChange = (event) => {
    const { value } = event;
    this.setState({
      selectedClientType: "so",
      selectedSoDependencyId: value,

      selectedScDependencyId: null,
      selectedScDependencyName: null,

      vendingMachines: [],
      selectedVendingMachineId: null,
      selectedVendingMachineName: null,
      isVendingMachineDisabled: true,

      usersByVM: [],
      isTableLoading: true,

      addButtonDisabled: true,
    });

    this.props.getVendingMachineUsersBySoDependencyId(value, (response) => {
      this.setState({
        usersByVM: response.data.data,
        isTableLoading: false,
      });
    });

    this.props.getAllRelatedVendingMachinesToSoDependencyId(value, (response) => {
      this.setState({
        vendingMachines: response.data.data.map((item) => ({
          value: item.id,
          label: `${item.friendlyName}`,
        })),
        isVendingMachineDisabled: false,
      });
    });
  };

  handleScDependencyChange = (event) => {
    const { value } = event;
    this.setState({
      selectedClientType: "sc",
      selectedScDependencyId: value,
      selectedScDependencyName: event.label,

      vendingMachines: [],
      selectedVendingMachineId: null,
      selectedVendingMachineName: null,

      isTableLoading: true,
      usersByVM: [],

      isVendingMachineDisabled: false,
      addButtonDisabled: true,
    });

    this.props.getVendingMachineUsersByScDependencyId(value, (response) => {
      this.setState({
        usersByVM: response.data.data,
        isTableLoading: false,
      });
    });

    this.props.listVendingMachinesByScDependencyId(value, (response) => {
      this.setState({
        vendingMachines: response.data.data.map((item) => ({
          value: item.id,
          label: `${item.friendlyName}`,
        })),
      });
    });
  };

  handleVmChange = (event) => {
    const { value } = event;
    const { selectedClientType, selectedSoDependencyId, selectedScDependencyId } = this.state;

    this.setState({
      selectedVendingMachineId: value,
      selectedVendingMachineName: event.label,
      isTableLoading: true,
    });
    if (selectedClientType === "so") {
      this.props.getVmSoDependencyUsersByIsAssigned(value, selectedSoDependencyId, "true", (response) => {
        this.setState({
          usersByVM: response.data.data,
          isTableLoading: false,
        });
      });
    } else if (selectedClientType === "sc") {
      this.props.getVmScDependencyUsersByIsAssigned(value, selectedScDependencyId, "true", (response) => {
        this.setState({
          addButtonDisabled: false,
          usersByVM: response.data.data,
          isTableLoading: false,
        });
      });
    }
  };

  disableUserFromVM = (user, vm) => {
    this.props.disableUserFromVM(user.id, vm.vendingMachineId, (response) => {
      if (response.data === undefined) {
        // Connection Error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 0) {
        // Successful Transaction
        this.deleteVmFromUserInTable(vm.vendingMachineId, user.id);
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.successTransactionMessage,
        });
      } else {
        // Failed Transaction
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      }
    });
  };

  disableUserFromAllVms = (user) => {
    this.props.disableUserFromAllVms(user.id, (response) => {
      if (response.data === undefined) {
        // Connection error
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.saveError,
        });
      } else if (response.data.code === 0) {
        // Successful transaction
        this.deleteUserRecordFromTable(user.id);
        Modal.success({
          title: Strings.generalResponses.successfulTransaction,
          content: Strings.generalResponses.successTransactionMessage,
        });
      } else {
        // Failed Transaction
        Modal.error({
          title: Strings.generalResponses.failedTransaction,
          content: Strings.generalResponses.alreadyExistsError,
        });
      }
    });
  };

  deleteVmFromUserInTable(vmId, userId) {
    const { usersByVM } = this.state;

    if (usersByVM.find((obj) => obj.id === userId).vendingMachines.length <= 1) {
      this.deleteUserRecordFromTable(userId);
    } else {
      this.setState({
        usersByVM: usersByVM.map((user) => {
          const newUser = user;
          if (user.id === userId) {
            newUser.vendingMachines = user.vendingMachines.filter((vm) => vm.vendingMachineId !== vmId);
          }
          return newUser;
        }),
      });
    }
  }

  deleteUserRecordFromTable(userId) {
    const { usersByVM } = this.state;
    this.setState({ usersByVM: usersByVM.filter((obj) => obj.id !== userId) });
  }

  // Information to render
  render() {
    const {
      permissions: { data },
    } = this.props;
    const {
      vendingMachines,
      isVendingMachineDisabled,
      selectedVendingMachineId,
      selectedVendingMachineName,
      selectedScDependencyId,
      selectedScDependencyName,
      addButtonDisabled,
      usersByVM,
      isTableLoading,
    } = this.state;
    return (
      <div className="content-container">
        <div className="row">
          <Titles title={Strings.UsersByVendingMachine.UsersByVendingMachine} tooltip={Strings.UsersByVendingMachine.UsersByVendingMachineTooltip} />
        </div>

        <div className="row">
          <div className="vertSpace col-12">
            <Divider orientation="left">
              <h3>{Strings.generalTerms.generalParameters} </h3>
            </Divider>
          </div>
        </div>

        <div className="row">
          <SoScFullDependencySelector
            hasOnlySO={false}
            soDependencyHtmlTag="soDependencyId"
            scDependencyHtmlTag="scDependencyId"
            onSoDependencyChange={this.handleSoDependencyChange}
            onScDependencyChange={this.handleScDependencyChange}
          />
          <div className="vertSpace col-md-6">
            <SelectGeneral
              text={Strings.machine.vendingMachine}
              tooltip={Strings.machine.machineTooltip}
              isReq
              options={vendingMachines}
              onChange={this.handleVmChange}
              disabled={isVendingMachineDisabled}
              defaultValue={selectedVendingMachineId}
            />
          </div>
        </div>

        <div className="vertSpace col-12">
          <div className="col-xs-0 col-md-6" />
          <ValidatePermissionForComponent permission="PRIVILEGE USERS BY VENDING MACHINE POST" permissions={data}>
            <div className="col-md-6">
              <AddButton
                link={addButtonDisabled ? "" : "./assignUsersToVendingMachine"}
                tooltip={Strings.machine.assignUsersToVendingMachineTooltip}
                props={
                  addButtonDisabled
                    ? ""
                    : {
                        vendingMachineId: selectedVendingMachineId,
                        vendingMachineName: selectedVendingMachineName,
                        scDependencyId: selectedScDependencyId,
                        scDependencyName: selectedScDependencyName,
                      }
                }
                disabled={addButtonDisabled}
              />
            </div>
          </ValidatePermissionForComponent>
        </div>
        <Divider orientation="left">
          <h3>{Strings.UsersByVendingMachine.UsersByVendingMachine}</h3>
        </Divider>
        <QueryResponse isLoading={isTableLoading} dataSourceLength={usersByVM.length} callback={this.methodUserTable(data, usersByVM, isTableLoading)} />
      </div>
    );
  }

  methodUserTable(data, usersByVM, isTableLoading) {
    return (
      <div className="row">
        <div className="col">
          <UsersTable
            permissions={data}
            dataSource={usersByVM}
            loading={isTableLoading}
            disableUserFromVM={this.disableUserFromVM}
            disableUserFromAllVms={this.disableUserFromAllVms}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDependency: state.dependencyByUser.dependencyObj,
    permissions: state.userSystemPrivileges.privilegeObj,
  };
}

export default connect(mapStateToProps, {
  listVendingMachinesByScDependencyId,
  getAllRelatedVendingMachinesToSoDependencyId,
  getVendingMachineUsersByScDependencyId,
  getVendingMachineUsersBySoDependencyId,
  getVmSoDependencyUsersByIsAssigned,
  getVmScDependencyUsersByIsAssigned,
  disableUserFromVM,
  disableUserFromAllVms,
})(UsersByVendingMachineContainer);
